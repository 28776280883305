import type { MembershipTieringInfo } from '@engage/api-v2';
import type { CopyIDs, Tiering as TieringCopyType } from '@members/copy';

export type TierContextType = {
  tier: Tier;
  modal: Modal;
  setModal: (modal: Modal) => void;
  modalClassHandler: ModalClassHandler;
  setModalClassHandler: React.Dispatch<React.SetStateAction<ModalClassHandler>>;
  marketingData: MarketingData;
  rawTieringData: MembershipTieringInfo | undefined;
  isEligibleForFreeTrial: boolean | undefined;
  refetch: () => void;
};

export type Tier = {
  numLimitedClassesTaken: number;
  numLimitedClassesTotal: number;
  limitedClassesResetDate: number;
  tierType: TierType;
  isDefault: boolean;
  tierLabelID: CopyIDs<typeof TieringCopyType>;
};

export type ApiTierType =
  | 'no_membership'
  | 'digital_basic'
  | 'digital'
  | 'digital_plus'
  | 'guide'
  | 'all_access';

export type TierType = 'FREE' | 'APP' | 'APP_PLUS' | 'GUIDE' | 'ALL_ACCESS';

export type Modal = {
  modalType: TIERING_MODAL_TYPE;
  isOpen: boolean;
  suggestedClickHandler?: () => void;
  source?: string;
};

export type ModalClassHandler = {
  suggestedOnClick: () => void;
  classId: string;
  pelotonId: string;
  isOnDemand: boolean;
};

export type MarketingData = {
  digitalLimitedDisciplines: string[];
  digitalLimitedDisciplinesMarketing: string[];
};

export type TIERING_MODAL_TYPE =
  | 'quota_available_modal'
  | 'no_quota_available_modal'
  | 'upgrade_modal'
  | 'quota_reminder_modal'
  | 'exclusive_upgrade_modal'
  | 'programs_gating_modal'
  | 'challenges_gating_modal';

export const DEFAULT_TIER: Tier = {
  numLimitedClassesTaken: 0,
  numLimitedClassesTotal: 0,
  limitedClassesResetDate: 0,
  tierType: 'APP',
  isDefault: true,
  tierLabelID: 'displayNameDigital' as CopyIDs<typeof TieringCopyType>,
};

export const DEFAULT_MODAL = {
  modalType: 'quota_reminder_modal' as const,
  isOpen: false,
  source: 'default_source',
};

export const DEFAULT_MARKETING_DATA = {
  digitalLimitedDisciplines: [] as string[],
  digitalLimitedDisciplinesMarketing: [] as string[],
};

export const DEFAULT_MODAL_CLASS_HANDLER = {
  suggestedOnClick: () => void 0,
  classId: '', // ondemand - 0c168716f3b443968eb39503fa4d9c57, live - 71e0cc8dca644b5a90306fb6f5966549
  pelotonId: '', // live - daf8afaf03044651b29bcfb674659c75
  isOnDemand: true,
};

export const DEFAULT_TIER_CONTEXT = {
  tier: DEFAULT_TIER,
  modal: DEFAULT_MODAL,
  setModal: () => {},
  modalClassHandler: DEFAULT_MODAL_CLASS_HANDLER,
  setModalClassHandler: () => {},
  marketingData: DEFAULT_MARKETING_DATA,
  rawTieringData: undefined,
  isEligibleForFreeTrial: false,
  refetch: () => {},
};

export enum CLASS_ACCESS_TYPE {
  FREE = 'free',
  SAMPLE = 'sample',
  APP = 'app',
  PREMIUM = 'premium',
  EXCLUSIVE = 'exclusive',
  ALL_ACCESS_ONLY = 'all_access_only',
}

export const QUOTA_REMINDER_MODAL = 'members/tiering/modals/QuotaReminderModal';

export type ClassAvailabilityProps = {
  contentAvailability: string;
  freeForLimitedTime: boolean;
  isLimitedRide: boolean;
};
