/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureModule } from '../../../api-v2-models/generated/FeatureModule';
import type { FeatureModuleV2 } from '../../../api-v2-models/generated/FeatureModuleV2';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Gets all feature modules
 * Return all feature modules to display for logged-in user
 * @param pelotonClientDate Timestamp on the user's device in ISO-8601 format. It is recommended to add the IANA
 * time zone in addition to the time zone offset. i.e. the full TZD would be "+05:30[Asia/Kolkata]"
 * See "TZ database name" for IANA zone:
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param productModel Can be used to identify the different bike and tread models. If not provided then the product_model will default to:
 * - v1_bike for home_bike and commercial_bike.
 * - aurora for home_tread.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param subscriptionCountry Created country of the subscription that the user is currently using or attached to current device. If not include, the value will be default as US.
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getHomescreenFeatureModules = (
    api: BaseAPI,
    pelotonClientDate: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    productModel?: 'v1_bike' | 'titan' | 'aurora' | 'prism',
    acceptLanguage?: string,
    subscriptionCountry?: 'US' | 'GB' | 'CA' | 'DE',
    options: any = {},
): AxiosPromise<{
    /**
     * Slug for the layout of feature modules. Different layouts will include different lists of modules.
     */
    layoutName?: string,
    /**
     * A list of feature modules to display
     */
    data?: Array<FeatureModuleV2>,
}> => {
    const localVarPath = `/api/v2/featured`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof subscriptionCountry !== 'undefined') {
        localVarQueryParameter['subscription_country'] = subscriptionCountry;
    }

    localVarHeaderParameter['Peloton-Client-Date'] = String(pelotonClientDate);
    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Product-Model'] = String(productModel);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets all feature modules
 * return all feature modules for logged-in user
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getFeatureModules = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * total number of feature modules
     */
    total?: number,
    /**
     * a list of feature modules
     */
    data?: Array<FeatureModule>,
}> => {
    const localVarPath = `/api/featured`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

