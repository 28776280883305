import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import { getScreenPropsSaga } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getUser } from '@peloton/auth';
import { failAction, successAction } from '@peloton/redux-fetch';
import { membersListSuccess } from '@engage/members';
import { openModal } from '@members/modal';
import { loadUser } from '@members/user-mapper';
import {
  addFacebookCredentials,
  fetchFacebookFriends,
  fetchFacebookId,
  login,
  logout,
  removeFacebookCredentials,
  getLoginStatus,
} from './api';
import type { FacebookCredentials } from './models';
import {
  FACEBOOK_MODAL_NAME,
  FacebookActionTypes,
  FacebookModalState,
  facebookNamespace,
  isFacebookFriendsPermissionError,
  updateFacebookModalState,
} from './redux';
import { facebookRerequest } from './redux/facebookConnect';

// Open Modal + Find friends
export const facebookFlowSaga = function* (client: Client) {
  yield put(openModal(FACEBOOK_MODAL_NAME));
  yield call(findFacebookFriendsSaga, client);
};

const findFacebookFriendsSaga = function* (client: Client): SagaIterator {
  const facebookId = yield call(fetchFacebookId, client);
  if (facebookId) {
    yield call(loadFacebookFriendsSaga, client);
  } else {
    yield call(facebookConnectPromptSaga, client);
  }
};

// No Facebook Id
export const facebookConnectPromptSaga = function* (client: Client) {
  yield put(updateFacebookModalState(FacebookModalState.ConnectPrompt));
  yield take(FacebookActionTypes.Connect);
  yield call(facebookLoginSaga, client, facebookRerequest());
};

export const facebookLoginSaga = function* (
  client: Client,
  action: ReturnType<typeof facebookRerequest>,
): SagaIterator {
  const credentials = yield call(login);
  yield call(addFacebookCredentialsSaga, client, credentials);
  yield call(loadFacebookFriendsSaga, client);
};

export const addFacebookCredentialsSaga = function* (
  client: Client,
  credentials: FacebookCredentials,
): SagaIterator {
  const user = yield select(getUser);
  const screenProps = yield call(getScreenPropsSaga);
  yield call(addFacebookCredentials, client, user.id, credentials, screenProps);
  yield put(loadUser());
};

export const loadFacebookFriendsSaga = function* (client: Client): SagaIterator {
  try {
    const user = yield select(getUser);
    const payload = yield call(fetchFacebookFriends, user.id, client);
    yield put(membersListSuccess(payload.members));
    yield put(
      successAction(facebookNamespace, { ...payload, friends: payload.membersFbInfo }),
    );
    yield put(updateFacebookModalState(FacebookModalState.FBFriends));
  } catch (e) {
    yield call(facebookErrorSaga, client, e);
  }
};

export const facebookDisconnectSaga = function* (
  client: Client,
  action: any,
): SagaIterator {
  const user = yield select(getUser);
  yield call(removeFacebookCredentials, client, user.id);

  const isFacebookLoggedIn = yield call(getLoginStatus);
  if (!!isFacebookLoggedIn) {
    yield call(logout);
  }

  yield put(loadUser());
};

export const facebookErrorSaga = function* (client: Client, error: Error): SagaIterator {
  yield put(failAction(facebookNamespace, error));
  const isPermissionError = yield select(isFacebookFriendsPermissionError);
  if (isPermissionError) {
    yield put(updateFacebookModalState(FacebookModalState.PermissionError));
  } else {
    const user = yield select(getUser);
    yield call(removeFacebookCredentials, client, user.id);
    yield put(updateFacebookModalState(FacebookModalState.Error));
  }
};

export default function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(FacebookActionTypes.FacebookFlow, facebookFlowSaga, client);
  yield takeEvery(FacebookActionTypes.FindFriends, findFacebookFriendsSaga, client);
  yield takeEvery(FacebookActionTypes.Rerequest, facebookLoginSaga, client);
  yield takeEvery(FacebookActionTypes.Disconnect, facebookDisconnectSaga, client);
}
