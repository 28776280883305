/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Cursor: { input: string; output: string };
  DateTime: { input: string; output: string };
  JoinToken: { input: any; output: any };
  URL: { input: string; output: string };
  _FieldSet: { input: any; output: any };
};

export type AbandonOdysseyWorkoutResponse =
  | OdysseyInvalidWorkoutMutationError
  | OdysseyItemNotFoundError
  | OdysseyWorkoutSuccessResponse;

export type AchievementAwardedEventPayload = ActivityFeedEventPayload & {
  __typename: 'AchievementAwardedEventPayload';
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /** @deprecated Use pelotonClass in PelotonClassFeedWorkout instead */
  pelotonClass?: Maybe<PelotonClass>;
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']['output']>;
};

/** A class that users from some group are currently taking. */
export type ActiveTagClass = {
  __typename: 'ActiveTagClass';
  /** The users in the group who are taking this class */
  activeUsers: ActiveTagUserPaginatedList;
  joinToken: Scalars['JoinToken']['output'];
  pelotonClass: PelotonClass;
};

/** A class that users from some group are currently taking. */
export type ActiveTagClassActiveUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: Scalars['Int']['input'];
};

/** An element in a paginated ActiveClass result set. */
export type ActiveTagClassEdge = {
  __typename: 'ActiveTagClassEdge';
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor']['output'];
  /** The ActiveClass element. */
  node: ActiveTagClass;
};

/** A list of ActiveClass results that can be paged over. */
export type ActiveTagClassesPaginatedList = PaginatedList & {
  __typename: 'ActiveTagClassesPaginatedList';
  /** The elements of the current page. */
  edges: Array<ActiveTagClassEdge>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
  /** Total number of active classes. */
  totalCount: Scalars['Int']['output'];
};

export type ActiveTagUserEdge = {
  __typename: 'ActiveTagUserEdge';
  cursor: Scalars['Cursor']['output'];
  node: User;
};

export type ActiveTagUserPaginatedList = {
  __typename: 'ActiveTagUserPaginatedList';
  edges: Array<ActiveTagUserEdge>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
  /** The total number of results in the underlying result set. */
  totalCount: Scalars['Int']['output'];
};

export type ActiveWorkout = {
  __typename: 'ActiveWorkout';
  actualStartTime?: Maybe<Scalars['DateTime']['output']>;
  isSkipIntroAvailable: Scalars['Boolean']['output'];
  pauseInfo?: Maybe<PauseInfo>;
  peloton: Peloton;
  workout: Workout;
};

export type ActivityFeedErrorResponse = {
  __typename: 'ActivityFeedErrorResponse';
  message: Scalars['String']['output'];
  type: ActivityFeedErrorType;
};

export enum ActivityFeedErrorType {
  DatabaseFailure = 'DATABASE_FAILURE',
  InvalidCursor = 'INVALID_CURSOR',
  InvalidUserId = 'INVALID_USER_ID',
  ItemNotFound = 'ITEM_NOT_FOUND',
  Unexpected = 'UNEXPECTED',
}

export type ActivityFeedEvent = {
  __typename: 'ActivityFeedEvent';
  /** Timestamp of activity feed event */
  createdAt: Scalars['DateTime']['output'];
  /** Event type used for card display, e.g. workout type or achievement type */
  eventType: EventType;
  /** @deprecated Use eventType instead to determine feed event type */
  feedEventType?: Maybe<FeedEventType>;
  /** Sublist of highfived users whose avatars will be shown on a activity feed event */
  highFivedUsers: Array<User>;
  /** Paginated response of high fives for the activity feed event */
  highFives: Scalars['Int']['output'];
  /** Id of activity feed event */
  id: Scalars['ID']['output'];
  /** If the current user has highfived the activity feed event */
  isHighFived: Scalars['Boolean']['output'];
  /** Payload data of the activity feed event */
  payload: Payload;
  /** Federated user object of the user for the activity feed event */
  user: User;
  /**
   * enum that maps to workout metrics to be displayed on client
   * @deprecated Use workoutMetricsType in WorkoutFinalizedEventPayload instead
   */
  workoutMetricsType: WorkoutMetricsType;
};

export type ActivityFeedEventEdge = {
  __typename: 'ActivityFeedEventEdge';
  activityFeedEvent: ActivityFeedEvent;
  cursor: Scalars['Cursor']['output'];
};

export type ActivityFeedEventPaginationResponse = PaginatedList & {
  __typename: 'ActivityFeedEventPaginationResponse';
  edges: Array<ActivityFeedEventEdge>;
  pageInfo: PageInfo;
};

export type ActivityFeedEventPayload = {
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']['output']>;
};

export type ActivityFeedEventResponse =
  | ActivityFeedErrorResponse
  | ActivityFeedEvent
  | ActivityFeedEventPaginationResponse;

export type AddClassToStackInput = {
  pelotonClassId: Scalars['ID']['input'];
};

/** Input for addTagToUser operation. */
export type AddTagToUserInput = {
  /** Case insensitive name of the tag to add. */
  tagName: Scalars['String']['input'];
};

export type AddTagToUserResponse =
  | AddTagToUserSuccess
  | DoesNotExistError
  | TagAlreadyExistsError
  | TagCapacityOverLimitError
  | TagNameInvalidCharacterError
  | TagNameLengthError
  | TagNameProhibitedError;

/** Response for addTagToUser operation. */
export type AddTagToUserSuccess = {
  __typename: 'AddTagToUserSuccess';
  /** The tag. */
  tag?: Maybe<Tag>;
};

export type Album = {
  __typename: 'Album';
  id: Scalars['String']['output'];
  image?: Maybe<Media>;
  name: Scalars['String']['output'];
};

export type AllEntertainmentChannelsWithTranslationsSuccess = {
  __typename: 'AllEntertainmentChannelsWithTranslationsSuccess';
  edges: Array<EntertainmentChannelWithTranslations>;
};

export type Artist = {
  __typename: 'Artist';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AwardedProgramAchievementTier = {
  __typename: 'AwardedProgramAchievementTier';
  achievementTier: ProgramAchievementTier;
  awardedAt: Scalars['DateTime']['output'];
  numClassesCompleted: Scalars['Int']['output'];
};

export type BasePlaylist = {
  id: Scalars['ID']['output'];
  isInClassMusicShown: Scalars['Boolean']['output'];
  playbackSongs: Array<PlaylistPlaybackSong>;
  topArtists: Array<Artist>;
};

/** Best Runs by difficulty for Score and Completion Percentage for an Odyssey Level */
export type BestRuns = {
  __typename: 'BestRuns';
  bestRunsByCompletionPercentage: Array<OdysseyWorkout>;
  bestRunsByScore: Array<OdysseyWorkout>;
  starsEarned: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CannotScheduleClassError = Error & {
  __typename: 'CannotScheduleClassError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Captions = {
  __typename: 'Captions';
  locales: Array<LocaleCode>;
};

/** Assets of the Peloton class. */
export type ClassAssets = {
  __typename: 'ClassAssets';
  /** Thumbnal image of the class. */
  thumbnailImage: Media;
};

export type ClassSegment = {
  __typename: 'ClassSegment';
  icon: Media;
  iconDetails: IconDetails;
  id: Scalars['ID']['output'];
  intensityInMets: Scalars['Float']['output'];
  isDrill?: Maybe<Scalars['Boolean']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  metricsType: ClassSegmentMetric;
  name?: Maybe<Scalars['String']['output']>;
  startTimeOffset: Scalars['Int']['output'];
  subsegments?: Maybe<Array<Maybe<ClassSubsegment>>>;
};

export enum ClassSegmentMetric {
  Caesar = 'CAESAR',
  Cycling = 'CYCLING',
  Floor = 'FLOOR',
  None = 'NONE',
  Running = 'RUNNING',
  Walking = 'WALKING',
}

export type ClassSubsegment = {
  __typename: 'ClassSubsegment';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  length?: Maybe<Scalars['Int']['output']>;
  movements: Array<Maybe<Movement>>;
  offset?: Maybe<Scalars['Int']['output']>;
  rounds?: Maybe<Scalars['Int']['output']>;
  scheduledOffset?: Maybe<Scalars['Int']['output']>;
  trackableMovementsDisabled?: Maybe<Scalars['Boolean']['output']>;
  type: Scalars['String']['output'];
};

export type ClassTimeline = {
  __typename: 'ClassTimeline';
  classSegments: Array<ClassSegment>;
  endOffset?: Maybe<Scalars['Int']['output']>;
  startOffset?: Maybe<Scalars['Int']['output']>;
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  videoEndOffset?: Maybe<Scalars['Int']['output']>;
};

/** A wrapper type for class related tags info */
export type ClassTopTag = {
  __typename: 'ClassTopTag';
  tag?: Maybe<Tag>;
};

export type ClassType = {
  __typename: 'ClassType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Color = {
  __typename: 'Color';
  hexCode: Scalars['String']['output'];
};

export type CompleteOdysseyWorkoutInput = {
  /** DEPRECATED: use <platform>CompletionDetails instead */
  beatCompletionPercent?: InputMaybe<Scalars['Float']['input']>;
  bikeCompletionDetails?: InputMaybe<OdysseyBikeCompletionInput>;
  /** DEPRECATED: use <platform>CompletionDetails instead */
  breakerCompletionPercent?: InputMaybe<Scalars['Float']['input']>;
  completionPercent: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  score: Scalars['Int']['input'];
  /** DEPRECATED: use <platform>CompletionDetails instead */
  streamCompletionPercent?: InputMaybe<Scalars['Float']['input']>;
  treadCompletionDetails?: InputMaybe<OdysseyTreadCompletionInput>;
};

export type CompleteOdysseyWorkoutResponse =
  | OdysseyBadRequestError
  | OdysseyInvalidWorkoutMutationError
  | OdysseyItemNotFoundError
  | OdysseyWorkoutSuccessResponse;

export type CompletedStats = {
  __typename: 'CompletedStats';
  avgHeartRate?: Maybe<Scalars['Float']['output']>;
  calories?: Maybe<Scalars['Float']['output']>;
  striveScore?: Maybe<Scalars['Float']['output']>;
  workoutId: Scalars['ID']['output'];
  zoneScore: ZoneScore;
};

/** The values for the supported content formats */
export enum ContentFormat {
  /** audio */
  Audio = 'audio',
  /** video */
  Video = 'video',
}

/** Input for creating an invite from a live/encore class */
export type CreateLiveInviteInput = {
  /** fitness discipline associated with the class */
  fitnessDiscipline: Scalars['String']['input'];
  /** host of the invite */
  host: UserInput;
  /** list of users to invite */
  invitedUsers: Array<UserInput>;
  /** join token associated with the class */
  joinToken: Scalars['ID']['input'];
  /** Occasion slug of the invite */
  occasionSlug?: InputMaybe<Scalars['String']['input']>;
};

/** Input for creating an invite from an on demand class */
export type CreateNewOnDemandInviteInput = {
  /** classId associated with the invite */
  classId: Scalars['ID']['input'];
  /** fitness discipline associated with the class */
  fitnessDiscipline: Scalars['String']['input'];
  /** host of the invite */
  host: UserInput;
  /** list of users to invite */
  invitedUsers: Array<UserInput>;
  /** Occasion slug of the invite */
  occasionSlug?: InputMaybe<Scalars['String']['input']>;
  /** scheduledEndTime of class associated with invite */
  scheduledEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** scheduledStartTime of class associated with invite */
  scheduledStartTime: Scalars['DateTime']['input'];
};

export type CreateOdysseyWorkoutInput = {
  difficulty: OdysseyDifficulty;
  levelId: Scalars['ID']['input'];
};

export type CreateOdysseyWorkoutResponse = OdysseyWorkoutSuccessResponse;

/** Input for creating an invite from an already scheduled on demand class */
export type CreateScheduledOnDemandInviteInput = {
  /** fitness discipline associated with the class */
  fitnessDiscipline: Scalars['String']['input'];
  /** host of the invite */
  host: UserInput;
  /** list of users to invite */
  invitedUsers: Array<UserInput>;
  /** Occasion slug of the invite */
  occasionSlug?: InputMaybe<Scalars['String']['input']>;
  /** scheduledClassId of class associated with the invite */
  scheduledClassId: Scalars['ID']['input'];
  /** scheduledEndTime of class associated with invite */
  scheduledEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** scheduledStartTime of class associated with invite */
  scheduledStartTime: Scalars['DateTime']['input'];
};

export type DeletedEntertainmentChannel = {
  __typename: 'DeletedEntertainmentChannel';
  uuid: Scalars['String']['output'];
};

export type DeletedEntertainmentChannelResponse =
  | DeletedEntertainmentChannelSuccess
  | EntityNotFoundError
  | PermissionError;

export type DeletedEntertainmentChannelSuccess = {
  __typename: 'DeletedEntertainmentChannelSuccess';
  channel?: Maybe<DeletedEntertainmentChannel>;
};

/** Difficulty Level of the Peloton Class */
export type DifficultyLevel = {
  __typename: 'DifficultyLevel';
  /** Display name of the difficulty level. */
  displayName: Scalars['String']['output'];
  /** Slug of the difficulty level. */
  slug: DifficultyLevelSlug;
};

/** The possible values for difficulty level slug */
export enum DifficultyLevelSlug {
  /** slug for advanced */
  Advanced = 'ADVANCED',
  /** slug for beginner */
  Beginner = 'BEGINNER',
  /** slug for intermediate */
  Intermediate = 'INTERMEDIATE',
}

/** Display tags for Gym Movement Groups */
export type DisplayTag = {
  __typename: 'DisplayTag';
  /** Name of the display tag property */
  slug: Scalars['String']['output'];
  /** Value of the identified slug */
  value: Scalars['String']['output'];
};

/** The possible systems for measuring distance */
export enum DistanceUnit {
  /** Measure distance in miles */
  Imperial = 'IMPERIAL',
  /** Measure distance in kilometers */
  Metric = 'METRIC',
}

export type DoesNotExistError = Error & {
  __typename: 'DoesNotExistError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  resource: Scalars['String']['output'];
};

/** Type for an entertainment streaming provider */
export type EntertainmentChannel = {
  __typename: 'EntertainmentChannel';
  allowedUrls?: Maybe<Array<Scalars['String']['output']>>;
  backgroundImage: Scalars['URL']['output'];
  id: Scalars['ID']['output'];
  location: Scalars['URL']['output'];
  rank: Scalars['Int']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  userAgent: Scalars['String']['output'];
};

/** A list of EntertainmentChannels that can be paged over. */
export type EntertainmentChannelPaginatedList = PaginatedList & {
  __typename: 'EntertainmentChannelPaginatedList';
  /** The channels of the current page. */
  edges: Array<EntertainmentChannel>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

export type EntertainmentChannelPresignedUrl = {
  __typename: 'EntertainmentChannelPresignedUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type EntertainmentChannelPresignedUrlQueryResponse =
  | EntertainmentChannelPresignedUrl
  | PermissionError;

/** Type for an entertainment streaming provider translation */
export type EntertainmentChannelTranslation = {
  __typename: 'EntertainmentChannelTranslation';
  locale: Scalars['String']['output'];
  location: Scalars['URL']['output'];
};

export type EntertainmentChannelTranslationInput = {
  locale: Scalars['String']['input'];
  location: Scalars['String']['input'];
};

export type EntertainmentChannelWithTranslationSuccess = {
  __typename: 'EntertainmentChannelWithTranslationSuccess';
  channel?: Maybe<EntertainmentChannelWithTranslations>;
};

/** Type for an entertainment streaming provider with translations */
export type EntertainmentChannelWithTranslations = {
  __typename: 'EntertainmentChannelWithTranslations';
  allowedChannelGroups: Array<Scalars['String']['output']>;
  backgroundImage: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  minEnabledAppVersion?: Maybe<Scalars['Int']['output']>;
  rank: Scalars['Int']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  translations: Array<EntertainmentChannelTranslation>;
  userAgent: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type EntertainmentChannelWithTranslationsInput = {
  allowedChannelGroups: Array<Scalars['String']['input']>;
  backgroundImage: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
  minEnabledAppVersion?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  subtitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  translations: Array<EntertainmentChannelTranslationInput>;
  userAgent: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type EntertainmentChannelWithTranslationsResponse =
  | AllEntertainmentChannelsWithTranslationsSuccess
  | EntertainmentChannelWithTranslationSuccess
  | EntityNotFoundError
  | PermissionError;

export type EntertainmentChannelsQueryResponse =
  | EntertainmentChannelPaginatedList
  | PermissionError;

export type EntityNotFoundError = Error & {
  __typename: 'EntityNotFoundError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Equipment = {
  __typename: 'Equipment';
  icon?: Maybe<Media>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** Represents an error. */
export type Error = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ErrorVideoStream = {
  __typename: 'ErrorVideoStream';
  joinError: JoinError;
};

export type EventDetails = {
  __typename: 'EventDetails';
  activityFeedEvent: ActivityFeedEvent;
  highFiveDetails: HighFiveDetails;
};

export type EventDetailsResponse = ActivityFeedErrorResponse | EventDetails;

export enum EventType {
  Class = 'CLASS',
  JustCardio = 'JUST_CARDIO',
  JustLift = 'JUST_LIFT',
  JustMeditate = 'JUST_MEDITATE',
  JustRide = 'JUST_RIDE',
  JustRow = 'JUST_ROW',
  JustRun = 'JUST_RUN',
  JustStretch = 'JUST_STRETCH',
  JustWalk = 'JUST_WALK',
  JustYoga = 'JUST_YOGA',
  Lanebreak = 'LANEBREAK',
  Scenic = 'SCENIC',
}

export enum FeedEventType {
  PrivacyUpdated = 'PRIVACY_UPDATED',
  WorkoutDeleted = 'WORKOUT_DELETED',
  WorkoutFinalized = 'WORKOUT_FINALIZED',
}

export enum FeedPrivacy {
  MyFollowers = 'my_followers',
  OnlyMe = 'only_me',
}

export type FeedWorkout = {
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']['output']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']['output']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']['output']>;
  /** split pace of the workout (aka time per 500m for caesar) */
  avgSplitPace?: Maybe<Scalars['Float']['output']>;
  /** stroke rate of the workout (aka spm for caesar) */
  avgStrokeRate?: Maybe<Scalars['Float']['output']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']['output']>;
  completedAt: Scalars['Int']['output'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']['output']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']['output']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID']['output'];
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']['output']>;
  /** zone 1 HR score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']['output']>;
  /** zone 2 HR score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']['output']>;
  /** zone 3 HR score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']['output']>;
  /** zone 4 HR score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']['output']>;
  /** zone 5 HR score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']['output']>;
};

export type FilterOption =
  | GymBodyActivityGroupFilter
  | GymBooleanFilter
  | GymInstructorFilter
  | GymIntFilter
  | GymStringFilter;

/** Fitness discipline. */
export type FitnessDiscipline = {
  __typename: 'FitnessDiscipline';
  /** Display name of the fitness discipline. */
  displayName: Scalars['String']['output'];
  /** Slug of the fitness discipline. */
  slug: FitnessDisciplineSlug;
};

/** The possible values for fitness discipline slug */
export enum FitnessDisciplineSlug {
  /** slug for beyond the ride */
  BeyondTheRide = 'BEYOND_THE_RIDE',
  /** slug for bike bootcamp */
  BikeBootcamp = 'BIKE_BOOTCAMP',
  /** slug for caesar */
  Caesar = 'CAESAR',
  /** slug for caesar bootcamp */
  CaesarBootcamp = 'CAESAR_BOOTCAMP',
  /** slug for cardio */
  Cardio = 'CARDIO',
  /** slug for circuit */
  Circuit = 'CIRCUIT',
  /** slug for cycling */
  Cycling = 'CYCLING',
  /** slug for meditation */
  Meditation = 'MEDITATION',
  /** slug for running */
  Running = 'RUNNING',
  /** slug for strength */
  Strength = 'STRENGTH',
  /** slug for stretching */
  Stretching = 'STRETCHING',
  /** slug for walking */
  Walking = 'WALKING',
  /** slug for yoga */
  Yoga = 'YOGA',
}

/** The possible states for whether one user (user1) follows another (user2). */
export enum FollowStatus {
  /** user1 follows user2. */
  Following = 'FOLLOWING',
  /** user1 has requested to follow user2, but this has not been accepted yet. */
  FollowPending = 'FOLLOW_PENDING',
  /** user1 does not follow user2. */
  None = 'NONE',
  /** user1 is the same user as user2. */
  Self = 'SELF',
}

/** A list of FollowingIds that can be paged over. */
export type FollowingUsersPaginatedList = PaginatedList & {
  __typename: 'FollowingUsersPaginatedList';
  /** The elements of the current page. */
  edges: Array<User>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

export type FreestyleFeedWorkout = FeedWorkout & {
  __typename: 'FreestyleFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']['output']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']['output']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']['output']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']['output']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']['output']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']['output']>;
  completedAt: Scalars['Int']['output'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']['output']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  fitnessDiscipline: Scalars['String']['output'];
  freestyleActivityTitle: Scalars['String']['output'];
  freestyleImageUrl: Scalars['String']['output'];
  isOutdoor?: Maybe<Scalars['Boolean']['output']>;
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']['output']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID']['output'];
  /** Platform of the FeedWorkout */
  platform?: Maybe<Scalars['String']['output']>;
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']['output']>;
};

export type FreestyleMetadata = {
  __typename: 'FreestyleMetadata';
  musicProviders: Array<MusicProvider>;
};

export type FreestyleWorkoutGoalInput = {
  /** Specify the target value of the goal. */
  targetValue?: InputMaybe<Scalars['Float']['input']>;
  /** Specify the type of goal. Eg. Distance, time etc.. */
  type?: InputMaybe<FreestyleWorkoutGoalType>;
  /** Specify the unit of goal. */
  unit?: InputMaybe<FreestyleWorkoutGoalUnit>;
};

export enum FreestyleWorkoutGoalType {
  Distance = 'DISTANCE',
  Time = 'TIME',
}

export enum FreestyleWorkoutGoalUnit {
  Kilometers = 'KILOMETERS',
  Meters = 'METERS',
  Miles = 'MILES',
  Minutes = 'MINUTES',
}

export enum GoalFrequency {
  Weekly = 'WEEKLY',
}

export type GoalProgress = {
  __typename: 'GoalProgress';
  frequency: GoalFrequency;
  newProgress: Scalars['Int']['output'];
  oldProgress: Scalars['Int']['output'];
  target: Scalars['Int']['output'];
  type: GoalType;
  units: Scalars['String']['output'];
};

export type GoalProgressDetails = {
  __typename: 'GoalProgressDetails';
  goalProgress: Array<GoalProgress>;
};

export enum GoalType {
  ActiveDays = 'ACTIVE_DAYS',
  Time = 'TIME',
  Workouts = 'WORKOUTS',
}

/** Instructional tags for Gym Movement Groups */
export type GroupTags = {
  __typename: 'GroupTags';
  /** Active time in seconds spent on each movement */
  activeTimeSeconds?: Maybe<Scalars['Int']['output']>;
  /** Suggested time of the movement group referenced in seconds */
  durationSeconds?: Maybe<Scalars['Int']['output']>;
  /** Group Type like Warm Up or Cool Down */
  groupType?: Maybe<Scalars['String']['output']>;
  /** Composition of a ladder Movement Group */
  ladderComposition?: Maybe<Scalars['String']['output']>;
  /** Ascending, Descending Ascend-Descend */
  movementOrder?: Maybe<Scalars['String']['output']>;
  /** Rest time in seconds spent between movements */
  restTimeSeconds?: Maybe<Scalars['Int']['output']>;
  /** Name of the movement referenced */
  splits?: Maybe<Scalars['String']['output']>;
  /** Description of the movement referenced */
  totalRounds?: Maybe<Scalars['Int']['output']>;
};

/** Gym filter option with List of String values */
export type GymBodyActivityGroupFilter = {
  __typename: 'GymBodyActivityGroupFilter';
  subFilters: Array<GymStringFilter>;
  subHeader: Scalars['String']['output'];
};

/** Input for Bookmarking a Gym Plan */
export type GymBookmarkInput = {
  gymPlanId: Scalars['String']['input'];
};

/** Gym Bookmark Response */
export type GymBookmarkResponse = GymErrorResponse | GymSuccessfulResponse;

/** Gym filter option with Boolean value */
export type GymBooleanFilter = {
  __typename: 'GymBooleanFilter';
  iconUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

/** Equipment required for a Gym Plan */
export type GymEquipment = {
  __typename: 'GymEquipment';
  /** Translated value to display to users */
  displayValue: Scalars['String']['output'];
  /** Value that corresponds to the type of equipment */
  slug: Scalars['String']['output'];
};

/** Generic error response structure */
export type GymErrorResponse = {
  __typename: 'GymErrorResponse';
  message: Scalars['String']['output'];
  type: GymErrorType;
};

export enum GymErrorType {
  BadRequestException = 'BAD_REQUEST_EXCEPTION',
  HttpException = 'HTTP_EXCEPTION',
  InvalidCursor = 'INVALID_CURSOR',
  InvalidGymPlan = 'INVALID_GYM_PLAN',
  InvalidInput = 'INVALID_INPUT',
  InvalidMovementGroupType = 'INVALID_MOVEMENT_GROUP_TYPE',
  InvalidWorkoutType = 'INVALID_WORKOUT_TYPE',
  ItemNotFound = 'ITEM_NOT_FOUND',
  JsonParseFailure = 'JSON_PARSE_FAILURE',
  Unexpected = 'UNEXPECTED',
}

export type GymFeedWorkout = FeedWorkout & {
  __typename: 'GymFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']['output']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']['output']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']['output']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']['output']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']['output']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']['output']>;
  completedAt: Scalars['Int']['output'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']['output']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  /** ID of the Gym Plan referenced */
  gymPlanId: Scalars['String']['output'];
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']['output']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID']['output'];
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']['output']>;
};

/** Categories within Gym filter options */
export type GymFilterCategory = {
  __typename: 'GymFilterCategory';
  category: Scalars['String']['output'];
  filters: Array<FilterOption>;
  header: Scalars['String']['output'];
  iconUrl: Scalars['String']['output'];
};

/** Gym filter option with Instructor value */
export type GymInstructorFilter = {
  __typename: 'GymInstructorFilter';
  instructor: Instructor;
  value: Scalars['String']['output'];
};

/** Gym filter option with Int value */
export type GymIntFilter = {
  __typename: 'GymIntFilter';
  value: Scalars['Int']['output'];
};

/** Gym Movement and associated instruction */
export type GymMovement = {
  __typename: 'GymMovement';
  /** Description of the movement referenced */
  description: Scalars['String']['output'];
  /** ID of the movement referenced */
  id: Scalars['ID']['output'];
  /** Movement thumbnail imageUrl */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by movementPattern */
  isRepsMirrored: Scalars['Boolean']['output'];
  /** Long movement video data */
  longVideo?: Maybe<MovementVideo>;
  /** @deprecated Moved to MovementVideo type */
  longVideoId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Moved to MovementVideo type */
  longVideoUrl?: Maybe<Scalars['String']['output']>;
  /** Pattern in which unilateral movements should be performed */
  movementPattern: MovementPattern;
  muscleGroups: Array<MovementMuscleGroup>;
  /** Name of the movement referenced */
  name: Scalars['String']['output'];
  /** Number of the movement referenced */
  reps?: Maybe<Scalars['Int']['output']>;
  /** Short movement video data */
  shortVideo?: Maybe<MovementVideo>;
  /** @deprecated Moved to MovementVideo type */
  shortVideoId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Moved to MovementVideo type */
  shortVideoUrl?: Maybe<Scalars['String']['output']>;
  /** Suggested time of the movement referenced in seconds */
  suggestedTimeInSeconds?: Maybe<Scalars['Int']['output']>;
};

/** Gym Movement group has at least one movement */
export type GymMovementGroup = {
  __typename: 'GymMovementGroup';
  /** Movement group info description */
  description?: Maybe<Scalars['String']['output']>;
  /** Display tags of movement group info, e.g. duration, ladderComposition */
  displayTags: Array<DisplayTag>;
  fitnessDiscipline: Scalars['String']['output'];
  /** Tags of movement group, e.g. warm up */
  groupTags?: Maybe<GroupTags>;
  /** Movements in the movement group */
  gymMovements: Array<GymMovement>;
  /** ID of the movement referenced */
  id: Scalars['ID']['output'];
  /** Intensity metric in Mets */
  intensity: Scalars['Int']['output'];
  /** Type of movement group, e.g. EMOM, AMRAP */
  movementGroupType: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Gym Plan and associated metadata for gym content */
export type GymPlan = {
  __typename: 'GymPlan';
  /** Timestamp of plan creation */
  createdAt: Scalars['DateTime']['output'];
  /** Instructor notes */
  description?: Maybe<Scalars['String']['output']>;
  difficulty?: Maybe<Scalars['String']['output']>;
  /** Translated string for difficulty */
  difficultyDisplayName?: Maybe<Scalars['String']['output']>;
  /** the approximate time of a gym workout */
  durationSeconds?: Maybe<Scalars['Int']['output']>;
  /** Equipment required for the gym plan */
  equipment: Array<GymEquipment>;
  fitnessDiscipline: Scalars['String']['output'];
  /** Movement groups associated with the gym plan */
  gymMovementGroups: Array<GymMovementGroup>;
  id: Scalars['ID']['output'];
  instructor?: Maybe<Instructor>;
  /** Has the user bookmarked the plan */
  isBookmarked: Scalars['Boolean']['output'];
  /** Has the user taken the gym plan */
  isTaken: Scalars['Boolean']['output'];
  /** Muscle group score for body activity */
  muscleGroupScore: Array<MuscleGroupScore>;
  name: Scalars['String']['output'];
  /** Movement group count */
  numberOfMovementGroups: Scalars['Int']['output'];
  /** Total Movement count */
  numberOfMovements: Scalars['Int']['output'];
  /** Timestamp of plan publication */
  publishedAt: Scalars['DateTime']['output'];
  /** type / focus of gym workout */
  workoutType: Scalars['String']['output'];
  /** Translated string for workoutType */
  workoutTypeDisplayName: Scalars['String']['output'];
};

export type GymPlanEdge = {
  __typename: 'GymPlanEdge';
  cursor: Scalars['Cursor']['output'];
  gymPlan: GymPlan;
};

/** Data driving the Gym Plan filter chips */
export type GymPlanFilterChip = {
  __typename: 'GymPlanFilterChip';
  iconUrl: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Gym Plan query filter */
export type GymPlanFilterInput = {
  slug: Scalars['String']['input'];
};

export type GymPlanPaginationResponse = PaginatedList & {
  __typename: 'GymPlanPaginationResponse';
  edges: Array<GymPlanEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Filter options for Gym Plans */
export type GymPlanQueryFilterOptions = {
  __typename: 'GymPlanQueryFilterOptions';
  categories: Array<GymFilterCategory>;
};

/** Input for filtering Gym Plans */
export type GymPlanQueryFiltersInput = {
  bodyActivity: Array<Scalars['String']['input']>;
  difficulty: Array<Scalars['String']['input']>;
  duration: Array<Scalars['Int']['input']>;
  equipment: Array<Scalars['String']['input']>;
  instructors: Array<Scalars['String']['input']>;
  isBookmarked: Scalars['Boolean']['input'];
  isTaken?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GymPlanResponse = GymErrorResponse | GymPlan | GymPlanPaginationResponse;

/** Gym filter option with String value */
export type GymStringFilter = {
  __typename: 'GymStringFilter';
  slug: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Gym Successful Response */
export type GymSuccessfulResponse = {
  __typename: 'GymSuccessfulResponse';
  message: Scalars['String']['output'];
};

export type GymWorkout = {
  __typename: 'GymWorkout';
  id: Scalars['ID']['output'];
};

export type HeroImageAssets = {
  __typename: 'HeroImageAssets';
  center: Media;
  progress: Media;
  right: Media;
  tv: Media;
};

export type HighFiveDetails = {
  __typename: 'HighFiveDetails';
  users: Array<User>;
};

export type HighFiveDetailsResponse = ActivityFeedErrorResponse | HighFiveDetails;

export type IconDetails = {
  __typename: 'IconDetails';
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** An Instructor who teaches classes. */
export type Instructor = {
  __typename: 'Instructor';
  /** The assets that represent this instructor. */
  assets: InstructorAssets;
  firstName?: Maybe<Scalars['String']['output']>;
  /** The instructor's unique identifier. */
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  /** The instructor's name. */
  name: Scalars['String']['output'];
};

/** Instructor's assets. */
export type InstructorAssets = {
  __typename: 'InstructorAssets';
  aboutImage?: Maybe<Media>;
  /** The url of an image that represents this instructor. */
  profileImage: Media;
};

export type InstructorCue = {
  __typename: 'InstructorCue';
  cadenceRange: RangeCue;
  offsets: OffsetsCue;
  resistanceRange: RangeCue;
};

export type InviteDetails = {
  __typename: 'InviteDetails';
  /** Data related to the user who is invited to this event */
  authedInvitedUser?: Maybe<InviteUser>;
  /** Timestamp of when the invite was issued */
  createdAt: Scalars['DateTime']['output'];
  /** Get friends of this user who have accepted this invite, ordered by username and paginated. */
  getInvitedAcceptedFollowing: InvitedUsersPaginationResponse;
  /** Get users who are invited, ordered by self,invitee status,username and paginated. */
  getInvitedUsers: InvitedUsersPaginationResponse;
  /** Federated user object of the user who hosted this event */
  hostUser: User;
  /** Id of the invite */
  inviteId: Scalars['ID']['output'];
  /** Post MVP: Indicates if an invite has been created or cancelled */
  inviteStatus: InviteStatus;
  /** Occasion for this invite, if applicable */
  occasion?: Maybe<Occasion>;
  /** Referenced class related to this invitation */
  scheduledClass: ScheduledClass;
  /** Referenced scheduled class id of the invited class */
  scheduledClassId: Scalars['ID']['output'];
  /** Availability of link to invitation */
  visibility: Visibility;
};

export type InviteDetailsGetInvitedAcceptedFollowingArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type InviteDetailsGetInvitedUsersArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/** Error thrown if token was too long for Database writes */
export type InviteFriendsBadRequest = InviteFriendsError & {
  __typename: 'InviteFriendsBadRequest';
  message?: Maybe<Scalars['String']['output']>;
};

/** Error thrown if a user attempts to create a share link for an invite while the feature is disabled */
export type InviteFriendsCreateShareLinkDisabledError = InviteFriendsError & {
  __typename: 'InviteFriendsCreateShareLinkDisabledError';
  message?: Maybe<Scalars['String']['output']>;
};

/** Invite Friends Error Interface */
export type InviteFriendsError = {
  message?: Maybe<Scalars['String']['output']>;
};

/** Error thrown when HTTP exception calling endpoint */
export type InviteFriendsHttpException = InviteFriendsError & {
  __typename: 'InviteFriendsHttpException';
  message?: Maybe<Scalars['String']['output']>;
};

/** Error thrown when invalid data format, especially when calling endpoint */
export type InviteFriendsInvalidDataFormat = InviteFriendsError & {
  __typename: 'InviteFriendsInvalidDataFormat';
  message?: Maybe<Scalars['String']['output']>;
};

/** Error thrown if invited user size limit exceeded when attempting to create or add users to an invite */
export type InviteFriendsInvitedUserSizeLimitExceeded = InviteFriendsError & {
  __typename: 'InviteFriendsInvitedUserSizeLimitExceeded';
  invitedUserSizeLimit: Scalars['Float']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

/** Error thrown when querying or mutating a model that doesn't exist */
export type InviteFriendsItemNotFoundError = InviteFriendsError & {
  __typename: 'InviteFriendsItemNotFoundError';
  message?: Maybe<Scalars['String']['output']>;
};

/** Error thrown if a user attempts to create a share link for an invite without correct permission */
export type InviteFriendsNoPermissionToCreateShareLinkError = InviteFriendsError & {
  __typename: 'InviteFriendsNoPermissionToCreateShareLinkError';
  message?: Maybe<Scalars['String']['output']>;
};

/** Error thrown when not authorized for the invite friends action */
export type InviteFriendsNotAuthorized = InviteFriendsError & {
  __typename: 'InviteFriendsNotAuthorized';
  message?: Maybe<Scalars['String']['output']>;
};

/** Returns InviteDetails or Error */
export type InviteResponse =
  | InviteDetails
  | InviteFriendsHttpException
  | InviteFriendsInvalidDataFormat
  | InviteFriendsInvitedUserSizeLimitExceeded
  | InviteFriendsItemNotFoundError
  | InviteFriendsNotAuthorized;

/** Returns an shareable link for an invite or Error */
export type InviteShareUrlResponse =
  | InviteFriendsCreateShareLinkDisabledError
  | InviteFriendsInvalidDataFormat
  | InviteFriendsItemNotFoundError
  | InviteFriendsNoPermissionToCreateShareLinkError
  | ShareUrl;

export enum InviteStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
}

export type InviteUser = {
  __typename: 'InviteUser';
  /** Federated user object of the user who was invited */
  invitedUser: User;
  invitedUserStatus: InvitedUserStatus;
  /** Federated user object of the user that did the inviting */
  invitorUser: User;
};

/** An element in a paginated InviteUser result set. */
export type InviteUserEdge = {
  __typename: 'InviteUserEdge';
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor']['output'];
  /** Data related to the user who is invited to this event */
  inviteUserItem: InviteUser;
};

/** Input for inviting users to an already existing invite */
export type InviteUsersToInviteInput = {
  /** id of the already existing invite */
  inviteId: Scalars['ID']['input'];
  /** list of users to be added to invite */
  invitedUsers: Array<UserInput>;
};

export enum InvitedUserStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Dismissed = 'DISMISSED',
  NotInvited = 'NOT_INVITED',
  NoInvite = 'NO_INVITE',
  Pending = 'PENDING',
  PendingCancelled = 'PENDING_CANCELLED',
}

/** paginated InviteUserEdge result set. */
export type InvitedUsersPaginatedList = {
  __typename: 'InvitedUsersPaginatedList';
  /** List of InviteUserEdge */
  edges: Array<InviteUserEdge>;
  /** Information about a page of results returned */
  pageInfo: PageInfo;
  /** total number of users */
  totalCount: Scalars['Int']['output'];
};

/** Returns InvitedUsersPaginatedList or Error */
export type InvitedUsersPaginationResponse =
  | InviteFriendsHttpException
  | InviteFriendsNotAuthorized
  | InvitedUsersPaginatedList;

/** Error thrown if video is live and an attempt was made to join it by a non-authorized user */
export type JoinAuthorizationError = JoinError & {
  __typename: 'JoinAuthorizationError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Error thrown if video content is not yet live and an attempt was made to join it */
export type JoinContentNotLiveError = JoinError &
  RetryableError & {
    __typename: 'JoinContentNotLiveError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    retryAfterSeconds?: Maybe<Scalars['Int']['output']>;
    retryMaxDuration: Scalars['Int']['output'];
  };

export type JoinError = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Error thrown if video is live and an attempt was made to join it by a non-subscribed user */
export type JoinNoActiveSubscriptionError = JoinError & {
  __typename: 'JoinNoActiveSubscriptionError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Error thrown if video stream content limit has been reached and and an attempt was made to join it */
export type JoinStreamLimitError = JoinError & {
  __typename: 'JoinStreamLimitError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type JoinableClass = {
  __typename: 'JoinableClass';
  activeWorkout?: Maybe<ActiveWorkout>;
  id: Scalars['ID']['output'];
  pelotonClass: PelotonClass;
  videoStream: VideoStream;
  videoStreamOrError: VideoStreamOrError;
};

export type LanebreakClass = PelotonClass & {
  __typename: 'LanebreakClass';
  assets: ClassAssets;
  classId: Scalars['String']['output'];
  classPreviewVideo?: Maybe<Scalars['String']['output']>;
  classTypes: Array<ClassType>;
  contentAvailability?: Maybe<Scalars['String']['output']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']['output']>>;
  fitnessDiscipline: FitnessDiscipline;
  flags?: Maybe<Array<Scalars['String']['output']>>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isLimitedRide?: Maybe<Scalars['Boolean']['output']>;
  joinToken: Scalars['JoinToken']['output'];
  levelId: Scalars['String']['output'];
  metrics: Array<MetricName>;
  originLocale: Locale;
  playableOnPlatform: Scalars['Boolean']['output'];
  shopTheClass: Scalars['Boolean']['output'];
  timeline?: Maybe<ClassTimeline>;
  title: Scalars['String']['output'];
};

/** Represents a leaderboard entry */
export type LeaderboardEntry = {
  __typename: 'LeaderboardEntry';
  completionPercent: Scalars['Float']['output'];
  difficulty: OdysseyDifficulty;
  rank?: Maybe<Scalars['Int']['output']>;
  score: Scalars['Int']['output'];
  /** @deprecated Use startDate in WorkoutDetails */
  startTime: Scalars['DateTime']['output'];
  workoutDetails?: Maybe<LeaderboardWorkoutDetails>;
  workoutId: Scalars['ID']['output'];
};

/** A leaderboard for an Odyssey level */
export type LeaderboardRequestInput = {
  /** Optional: If provided, filter leaderboard based on difficulty */
  difficulty?: InputMaybe<OdysseyDifficulty>;
  /**
   * If provided, will have extraWorkoutRank and extraLeaderboardEntry returned for easy access to given workout.
   *             This can be used to conveniently grab information for a specific workout without it needing to be on the returned leaderboard
   */
  extraWorkoutId?: InputMaybe<Scalars['ID']['input']>;
  /** Where to start the LB; note that this should not exceed 10k */
  from: Scalars['Int']['input'];
  levelId: Scalars['ID']['input'];
  /** How many values to fetch */
  size: Scalars['Int']['input'];
  /** Optional: Whether to sort user leaderboard by score or date descending */
  sortBy?: InputMaybe<OdysseyLeaderboardSortBy>;
  /** Optional: offset: If provided, only shows workouts that started at this point or later */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeaderboardWorkoutDetails = {
  __typename: 'LeaderboardWorkoutDetails';
  avatar: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use startDate instead */
  startTime?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['ID']['output'];
  username: Scalars['String']['output'];
  workoutId: Scalars['ID']['output'];
};

/** Type for a playable Odyssey level */
export type Level = {
  __typename: 'Level';
  assets: OdysseyLevelAssets;
  availability: LevelAvailability;
  bestRuns?: Maybe<BestRuns>;
  bookmarked: Scalars['Boolean']['output'];
  difficultyDetails?: Maybe<Array<OdysseyLevelDifficultyDetail>>;
  duration?: Maybe<Scalars['Int']['output']>;
  durationCategory?: Maybe<Scalars['Int']['output']>;
  explicit: Scalars['Boolean']['output'];
  featuredArtists: Scalars['String']['output'];
  filterOptionIds: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  musicProvider?: Maybe<MusicProvider>;
  playlistId?: Maybe<Scalars['ID']['output']>;
  theme: LevelTheme;
  title: Scalars['String']['output'];
  volume?: Maybe<Scalars['Int']['output']>;
};

export enum LevelAvailability {
  Archived = 'Archived',
  Available = 'Available',
  Draft = 'Draft',
  Testing = 'Testing',
}

export type LevelBrowseFiltersSuccessResponse = {
  __typename: 'LevelBrowseFiltersSuccessResponse';
  browseFilters: Array<OdysseyLevelBrowseFilter>;
  /** @deprecated Use browseFilters instead */
  filters: Array<OdysseyLevelFilter>;
};

export type LevelFiltersQueryResponse =
  | LevelBrowseFiltersSuccessResponse
  | OdysseyBadRequestError;

export type LevelListQueryResponse = LevelListSuccessResponse | OdysseyNotAuthorizedError;

/** Successful response from the service after querying multiple levels */
export type LevelListSuccessResponse = {
  __typename: 'LevelListSuccessResponse';
  levels: Array<Level>;
};

export type LevelQueryResponse = LevelSuccessResponse | OdysseyItemNotFoundError;

/** Successful response from the service after querying or mutating a level */
export type LevelSuccessResponse = {
  __typename: 'LevelSuccessResponse';
  level: Level;
};

/** Odyssey level theme info */
export type LevelTheme = {
  __typename: 'LevelTheme';
  colorThemeId?: Maybe<Scalars['String']['output']>;
};

export type LiveClass = PelotonClass & {
  __typename: 'LiveClass';
  /** This is the actual time at which the workout begins and leaderboard metrics start, typically a few to one minute after the video starts */
  actualStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** corresponds to original air time for class, newly upcoming live classes substitutes in scheduled_start_time */
  airTime: Scalars['DateTime']['output'];
  assets: ClassAssets;
  captions?: Maybe<Captions>;
  classId: Scalars['String']['output'];
  classPreviewVideo?: Maybe<Scalars['String']['output']>;
  classTypes: Array<ClassType>;
  contentAvailability?: Maybe<Scalars['String']['output']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  difficultyLevel?: Maybe<DifficultyLevel>;
  duration: Scalars['Int']['output'];
  equipment: Array<Equipment>;
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']['output']>>;
  /** explicitness of a class */
  explicitRating: Scalars['Int']['output'];
  fitnessDiscipline: FitnessDiscipline;
  flags?: Maybe<Array<Scalars['String']['output']>>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  instructor: Instructor;
  instructorCues?: Maybe<Array<InstructorCue>>;
  /** whether user has favorited/bookmarked this class */
  isBookmarked: Scalars['Boolean']['output'];
  /** @deprecated use liveClassType equal to ENCORE instead of isEncore */
  isEncore: Scalars['Boolean']['output'];
  isExplicit: Scalars['Boolean']['output'];
  /** whether this class is an FTP test class that will be used to determine the user's power zones */
  isFtpTest: Scalars['Boolean']['output'];
  isLimitedRide?: Maybe<Scalars['Boolean']['output']>;
  joinToken: Scalars['JoinToken']['output'];
  /** Whether this class is live, encore, session, or premiere */
  liveClassCategory: LiveClassCategory;
  metrics: Array<MetricName>;
  originLocale: Locale;
  peloton: PelotonResult;
  playableOnPlatform: Scalars['Boolean']['output'];
  playlist?: Maybe<Playlist>;
  /** For live class, this is the official scheduled time (like 5:00pm) but for Sessions this is the time the video starts, which is typically 1 minute before the workout starts at the scheduled time */
  scheduledStartTime: Scalars['DateTime']['output'];
  /** This is 50 seconds after the scheduledStartTime for Live recorded classes due to the 50 second stream delay */
  scheduledStartTimeWithTapeDelay: Scalars['DateTime']['output'];
  shopTheClass: Scalars['Boolean']['output'];
  /** time at which the video stream should start, which is usually a couple mins before the scheduled workout start time */
  streamStartTime?: Maybe<Scalars['DateTime']['output']>;
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  targetMetricsData?: Maybe<TargetMetricsData>;
  timeline?: Maybe<ClassTimeline>;
  title: Scalars['String']['output'];
};

/** The possible values live class category */
export enum LiveClassCategory {
  /** encore class on Peloton live class schedule */
  Encore = 'ENCORE',
  /** live class on Peloton live class schedule */
  Live = 'LIVE',
  /**
   * Premiere class on Peloton live class schedule
   * Currently only returned for specific platforms as gated by a feature flag `premiere_show_user_classes_on_platforms`
   */
  Premiere = 'PREMIERE',
  /** session class */
  Session = 'SESSION',
}

/** Locale object representing a language */
export type Locale = {
  __typename: 'Locale';
  /** iso code for this locale */
  code: LocaleCode;
  /** display name for the locale's language (i.e. English, German, Deutsch, etc) */
  language: Scalars['String']['output'];
};

/** The values for the supported locale codes */
export enum LocaleCode {
  /** french - beta */
  CyCy = 'CY_CY',
  /** german */
  DeDe = 'DE_DE',
  /** english */
  EnUs = 'EN_US',
  /** spanish */
  EsEs = 'ES_ES',
  /** spanish (mexico) */
  EsMx = 'ES_MX',
  /** spanish (united states) */
  EsUs = 'ES_US',
  /** french (canada) */
  FrCa = 'FR_CA',
  /** french */
  FrFr = 'FR_FR',
  /** hindi - beta */
  TkTk = 'TK_TK',
  /** english - beta */
  UzUz = 'UZ_UZ',
  /** german - beta */
  ZuZu = 'ZU_ZU',
}

/** Describes a media asset */
export type Media = {
  __typename: 'Media';
  location: Scalars['URL']['output'];
};

/** MetaTag of a tag */
export type MetaTag = {
  __typename: 'MetaTag';
  /** The name to use when displaying this meta tag. */
  displayName: Scalars['String']['output'];
};

export type Metric = {
  __typename: 'Metric';
  lower?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<MetricName>;
  upper?: Maybe<Scalars['Float']['output']>;
};

export enum MetricName {
  Altitude = 'ALTITUDE',
  Cadence = 'CADENCE',
  Calories = 'CALORIES',
  Distance = 'DISTANCE',
  Elevation = 'ELEVATION',
  Gps = 'GPS',
  GpsAccuracy = 'GPS_ACCURACY',
  HeartRate = 'HEART_RATE',
  Incline = 'INCLINE',
  PaceIntensity = 'PACE_INTENSITY',
  PowerZone = 'POWER_ZONE',
  Resistance = 'RESISTANCE',
  Speed = 'SPEED',
  StrokeRate = 'STROKE_RATE',
}

export type ModifyStackInput = {
  pelotonClassIdList: Array<Scalars['ID']['input']>;
};

export type Movement = {
  __typename: 'Movement';
  id: Scalars['ID']['output'];
  leonardoExerciseIds?: Maybe<Array<Scalars['String']['output']>>;
  movementVariationId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  repetitionType?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  subsegmentMappingId?: Maybe<Scalars['String']['output']>;
  talkbackDescription?: Maybe<Scalars['String']['output']>;
  targetNumber?: Maybe<Scalars['Int']['output']>;
  trackingType?: Maybe<Scalars['String']['output']>;
  weightLevel?: Maybe<Scalars['String']['output']>;
  weightsAndArms?: Maybe<Scalars['String']['output']>;
};

/** Muscle Group Score for individual movement group */
export type MovementMuscleGroup = {
  __typename: 'MovementMuscleGroup';
  /** display name for users */
  displayName: Scalars['String']['output'];
  /** muscle group on the movement */
  muscleGroup: Scalars['String']['output'];
  /** 3, 2 or 1 */
  ranking: Scalars['Int']['output'];
};

export enum MovementPattern {
  Alternating = 'alternating',
  Each = 'each',
  Left = 'left',
  Reciprocating = 'reciprocating',
  Right = 'right',
  Simultaneous = 'simultaneous',
  Total = 'total',
}

/** Gym Movement Video data */
export type MovementVideo = {
  __typename: 'MovementVideo';
  /** Id of Video */
  id: Scalars['ID']['output'];
  /** Timestamp of token expiration */
  tokenExpiresAt: Scalars['DateTime']['output'];
  /** Type of video (Short or Long) */
  type: MovementVideoType;
  /** Tokenized url */
  url: Scalars['String']['output'];
};

export enum MovementVideoType {
  Long = 'LONG',
  Short = 'SHORT',
}

/** Muscle Group Score for top level plan */
export type MuscleGroupScore = {
  __typename: 'MuscleGroupScore';
  /** 3, 2 or 1 */
  bucket: Scalars['Int']['output'];
  /** display name for users */
  displayName: Scalars['String']['output'];
  /** muscle group on the movement */
  muscleGroup: Scalars['String']['output'];
  /** precentage of muscle group of total class plan */
  percentage: Scalars['Int']['output'];
  /** Score for muscle group */
  score: Scalars['Int']['output'];
};

export type MusicProvider = PlaylistMusicProvider;

export type Mutation = {
  __typename: 'Mutation';
  /** Abandon an Odyssey workout */
  abandonOdysseyWorkout: AbandonOdysseyWorkoutResponse;
  /** adds class to user's schedule (i.e. counts in to the class) */
  addClassToSchedule: ScheduledClassResponse;
  /** Add a single class to the UserStack for the current logged in user */
  addClassToStack: StackResponse;
  /**
   * Bookmarks a Gym Plan
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  addGymBookmark: GymBookmarkResponse;
  /** Add a high five to an activity feed event */
  addHighFive: ActivityFeedEventResponse;
  /** Add a tag to the current logged in user. */
  addTagToUser: AddTagToUserResponse;
  /** Adds bookmark for lanebreak level */
  bookmarkOdysseyLevel: OdysseyBookmarkResponse;
  /** Allows the invite host to cancel the invite */
  cancelInvite: YourScheduleListResponse;
  /** Complete an Odyssey workout */
  completeOdysseyWorkout: CompleteOdysseyWorkoutResponse;
  /** Create a public share link for the invite, making the invite URL accessible to all users, including those who are not invited */
  createInviteShareLink: InviteShareUrlResponse;
  /** Create a new invite from a live/encore class */
  createLiveInvite: InviteResponse;
  /** Create a new invite from a scheduled class */
  createNewOnDemandInvite: InviteResponse;
  /** Create a new Odyssey workout */
  createOdysseyWorkout: CreateOdysseyWorkoutResponse;
  /** Create a new invite from an already scheduled on-demand class */
  createScheduledOnDemandInvite: InviteResponse;
  /** delete entertainment channel */
  deleteEntertainmentChannel: DeletedEntertainmentChannelResponse;
  /** Invites users to an already existing invite */
  inviteUsersToInvite: InviteResponse;
  joinProgram: ProgramProgressResponse;
  leaveProgram: ProgramProgressResponse;
  /** Modify whole UserStack for the current logged in user. Used when removing a single class and saves current order of all remaining classes, and used when saving all changes to the UserStack */
  modifyStack: StackResponse;
  /** Pause workout for the currently logged in user. Only valid for on-demand non-digital workouts */
  pauseWorkout: Scalars['Int']['output'];
  /** Remove first class in UserStack for the current logged in user */
  playClassFromStack: StackResponse;
  programWeekSeen: ProgramProgressResponse;
  /** removes class from the user's schedule (i.e. counts out of the class) */
  removeClassFromSchedule: ScheduledClassResponse;
  /**
   * Removes a Gym Plan Bookmark
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  removeGymBookmark: GymBookmarkResponse;
  /** Remove a high five from an activity feed event */
  removeHighFive: ActivityFeedEventResponse;
  /** Remove a tag from the current logged in user. */
  removeTagFromUser: RemoveTagFromUserResponse;
  /** Replace the logged in user's tag with new tag. */
  replaceTag: ReplaceTagResponse;
  /** removes and re-adds class at a new time to the user's schedule */
  rescheduleClass: ScheduledClassResponse;
  /** Respond to an invite */
  respondToInvite: InviteResponse;
  /** create or update an entertainment channel */
  saveEntertainmentChannel: EntertainmentChannelWithTranslationsResponse;
  /** Set a tag as current logged in user's primary tag. */
  setTagAsPrimary: SetTagAsPrimaryResponse;
  skipProgramClass: ProgramProgressResponse;
  startFreestyleWorkout: StartFreestyleWorkoutResponse;
  /**
   * Starts a new Gym Workout
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  startGymWorkout: StartGymWorkoutResponse;
  startPelotonClass: JoinableClass;
  startProgramClass: ProgramProgressResponse;
  /** Removes bookmark for lanebreak level */
  unBookmarkOdysseyLevel: OdysseyBookmarkResponse;
  /** Unpause workout for the currently logged in user. Only valid for on-demand non-digital workouts */
  unpauseWorkout: UnpauseWorkoutResponse;
};

export type MutationAbandonOdysseyWorkoutArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAddClassToScheduleArgs = {
  scheduledClassInput: ScheduledClassInput;
};

export type MutationAddClassToStackArgs = {
  input: AddClassToStackInput;
};

export type MutationAddGymBookmarkArgs = {
  input: GymBookmarkInput;
};

export type MutationAddHighFiveArgs = {
  createdAtForEvent: Scalars['DateTime']['input'];
  userIdForEvent: Scalars['ID']['input'];
  userIdForHighFive: Scalars['ID']['input'];
};

export type MutationAddTagToUserArgs = {
  input: AddTagToUserInput;
};

export type MutationBookmarkOdysseyLevelArgs = {
  input: OdysseyBookmarkInput;
};

export type MutationCancelInviteArgs = {
  inviteId: Scalars['ID']['input'];
};

export type MutationCompleteOdysseyWorkoutArgs = {
  input: CompleteOdysseyWorkoutInput;
};

export type MutationCreateInviteShareLinkArgs = {
  inviteId: Scalars['ID']['input'];
};

export type MutationCreateLiveInviteArgs = {
  createLiveInviteInput: CreateLiveInviteInput;
};

export type MutationCreateNewOnDemandInviteArgs = {
  createNewOnDemandInviteInput: CreateNewOnDemandInviteInput;
};

export type MutationCreateOdysseyWorkoutArgs = {
  input: CreateOdysseyWorkoutInput;
};

export type MutationCreateScheduledOnDemandInviteArgs = {
  createScheduledOnDemandInviteInput: CreateScheduledOnDemandInviteInput;
};

export type MutationDeleteEntertainmentChannelArgs = {
  uuid: Scalars['String']['input'];
};

export type MutationInviteUsersToInviteArgs = {
  inviteUsersToInviteInput: InviteUsersToInviteInput;
};

export type MutationJoinProgramArgs = {
  programId: Scalars['String']['input'];
};

export type MutationLeaveProgramArgs = {
  programProgressId: Scalars['String']['input'];
};

export type MutationModifyStackArgs = {
  input: ModifyStackInput;
};

export type MutationPauseWorkoutArgs = {
  input: PauseWorkoutInput;
};

export type MutationPlayClassFromStackArgs = {
  input: PlayClassFromStackInput;
};

export type MutationProgramWeekSeenArgs = {
  programProgressId: Scalars['String']['input'];
  programWeekId: Scalars['String']['input'];
};

export type MutationRemoveClassFromScheduleArgs = {
  scheduledClassId: Scalars['String']['input'];
};

export type MutationRemoveGymBookmarkArgs = {
  input: GymBookmarkInput;
};

export type MutationRemoveHighFiveArgs = {
  createdAtForEvent: Scalars['DateTime']['input'];
  userIdForEvent: Scalars['ID']['input'];
  userIdForHighFive: Scalars['ID']['input'];
};

export type MutationRemoveTagFromUserArgs = {
  input: RemoveTagFromUserInput;
};

export type MutationReplaceTagArgs = {
  input: ReplaceTagInput;
};

export type MutationRescheduleClassArgs = {
  rescheduleClassInput: RescheduleClassInput;
};

export type MutationRespondToInviteArgs = {
  inviteId: Scalars['ID']['input'];
  status: InvitedUserStatus;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSaveEntertainmentChannelArgs = {
  entertainmentChannelWithTranslationsInput: EntertainmentChannelWithTranslationsInput;
};

export type MutationSetTagAsPrimaryArgs = {
  input: SetTagAsPrimaryInput;
};

export type MutationSkipProgramClassArgs = {
  programClassId: Scalars['String']['input'];
  programProgressId: Scalars['String']['input'];
};

export type MutationStartFreestyleWorkoutArgs = {
  input: StartFreestyleWorkoutInput;
};

export type MutationStartGymWorkoutArgs = {
  input: StartGymWorkoutInput;
};

export type MutationStartPelotonClassArgs = {
  input: StartClassInput;
};

export type MutationStartProgramClassArgs = {
  programClassId: Scalars['String']['input'];
  programProgressId: Scalars['String']['input'];
  workoutId: Scalars['String']['input'];
};

export type MutationUnBookmarkOdysseyLevelArgs = {
  input: OdysseyBookmarkInput;
};

export type MutationUnpauseWorkoutArgs = {
  input: UnpauseWorkoutInput;
};

/**
 * Error thrown when attempting to get Peloton data for a PelotonClass
 * that is not available for the current platform.
 */
export type NoClassPelotonForPlatformError = Error & {
  __typename: 'NoClassPelotonForPlatformError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Notification = {
  __typename: 'Notification';
  /** Activity feed event associated with the notification */
  activityFeedEvent: ActivityFeedEvent;
  /** Timestamp of when an external user high fived the activity feed event */
  highFivedAt: Scalars['DateTime']['output'];
  /** Boolean to check if a notification has been seen by the user */
  isSeen: Scalars['Boolean']['output'];
  /** Federated object of the external user that the notification is associated with */
  user: User;
};

export type NotificationDetails = {
  __typename: 'NotificationDetails';
  /** List of notifications for a user */
  notifications: Array<Notification>;
};

export type NotificationDetailsResponse = ActivityFeedErrorResponse | NotificationDetails;

/** Optional reason for scheduling an event */
export type Occasion = {
  __typename: 'Occasion';
  /** Occasion image icon assets */
  assets: OccasionAssets;
  /** Fitness discipline of the invite's class */
  fitnessDiscipline: Scalars['String']['output'];
  /**
   * S3 url of the occasion's icon
   * @deprecated Use assets instead
   */
  imageUrl: Scalars['String']['output'];
  /** Slug of the occasion name */
  occasionSlug: Scalars['String']['output'];
  /** Used to order the occasions in the UI */
  rank: Scalars['Int']['output'];
  /** Translated string of occasion name */
  translatedOccasionName: Scalars['String']['output'];
};

export type OccasionAssets = {
  __typename: 'OccasionAssets';
  /** large Occasion image icon url */
  largeImage: Media;
  /** small Occasion image icon url */
  smallImage: Media;
};

export type OccasionsList = {
  __typename: 'OccasionsList';
  /** List of Occasions */
  occasions: Array<Occasion>;
};

export type OccasionsResponse = OccasionsList;

export type OdysseyAccordionLevelFilter = {
  __typename: 'OdysseyAccordionLevelFilter';
  icon: OdysseyIcon;
  id: Scalars['String']['output'];
  numColumns: Scalars['Int']['output'];
  options: Array<OdysseyLevelFilterOption>;
  selectMultiple: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type OdysseyAccordionLevelFilterInfo = {
  __typename: 'OdysseyAccordionLevelFilterInfo';
  icon: OdysseyIcon;
  id: Scalars['String']['output'];
  numColumns: Scalars['Int']['output'];
  selectMultiple: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

/** Error thrown when request inputs are invalid */
export type OdysseyBadRequestError = OdysseyError & {
  __typename: 'OdysseyBadRequestError';
  message: Scalars['String']['output'];
};

export type OdysseyBasicLevelFilterOption = {
  __typename: 'OdysseyBasicLevelFilterOption';
  listOrder: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OdysseyBikeCompletionInput = {
  beatCompletionPercent: Scalars['Float']['input'];
  breakerCompletionPercent: Scalars['Float']['input'];
  streamCompletionPercent: Scalars['Float']['input'];
};

export type OdysseyBikeConfiguration = {
  __typename: 'OdysseyBikeConfiguration';
  difficultyConfigs: Array<OdysseyBikeDifficultyConfiguration>;
  scoringConfig: OdysseyBikeScoringConfiguration;
};

export type OdysseyBikeDifficultyConfiguration = {
  __typename: 'OdysseyBikeDifficultyConfiguration';
  displayName: Scalars['String']['output'];
  laneConfigs: Array<OdysseyBikeLaneConfiguration>;
  slug: OdysseyDifficulty;
};

export type OdysseyBikeLaneConfiguration = {
  __typename: 'OdysseyBikeLaneConfiguration';
  breakerAverageOutput: Scalars['Int']['output'];
  index: Scalars['Int']['output'];
  maxResistance: Scalars['Int']['output'];
  minResistance: Scalars['Int']['output'];
};

export type OdysseyBikeScoringConfiguration = {
  __typename: 'OdysseyBikeScoringConfiguration';
  starThresholds: Array<OdysseyStarThreshold>;
};

export type OdysseyBookmarkInput = {
  levelId: Scalars['ID']['input'];
};

export type OdysseyBookmarkResponse =
  | OdysseyBookmarkSuccessResponse
  | OdysseyItemNotFoundError
  | OdysseyNotAuthorizedError;

export type OdysseyBookmarkSuccessResponse = {
  __typename: 'OdysseyBookmarkSuccessResponse';
  levelId: Scalars['ID']['output'];
};

export type OdysseyConfigurationResponse =
  | OdysseyBikeConfiguration
  | OdysseyItemNotFoundError
  | OdysseyTreadConfiguration;

export enum OdysseyDifficulty {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Expert = 'EXPERT',
  Extreme = 'EXTREME',
  Intermediate = 'INTERMEDIATE',
  Normal = 'NORMAL',
}

/** Type for Odyssey level difficulty metadata */
export type OdysseyDifficultyMetadata = {
  __typename: 'OdysseyDifficultyMetadata';
  displayName: Scalars['String']['output'];
  slug: OdysseyDifficulty;
};

export type OdysseyDurationLevelFilterOption = {
  __typename: 'OdysseyDurationLevelFilterOption';
  listOrder: Scalars['Int']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Generic error type structure */
export type OdysseyError = {
  message: Scalars['String']['output'];
};

export type OdysseyFeedWorkout = FeedWorkout & {
  __typename: 'OdysseyFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']['output']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']['output']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']['output']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']['output']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']['output']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']['output']>;
  completedAt: Scalars['Int']['output'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']['output']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  odysseyImageUrl: Scalars['String']['output'];
  odysseySubtitle: Scalars['String']['output'];
  /** odyssey workout information */
  odysseyWorkoutTitle: Scalars['String']['output'];
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']['output']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID']['output'];
  /** platform of the odyssey feed workout */
  platform?: Maybe<Scalars['String']['output']>;
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']['output']>;
};

export type OdysseyGridLevelFilter = {
  __typename: 'OdysseyGridLevelFilter';
  id: Scalars['String']['output'];
  numColumns: Scalars['Int']['output'];
  options: Array<OdysseyLevelFilterOption>;
  selectMultiple: Scalars['Boolean']['output'];
};

export type OdysseyGridLevelFilterInfo = {
  __typename: 'OdysseyGridLevelFilterInfo';
  id: Scalars['String']['output'];
  numColumns: Scalars['Int']['output'];
  selectMultiple: Scalars['Boolean']['output'];
};

export type OdysseyIcon = {
  __typename: 'OdysseyIcon';
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** Error thrown when attempting an invalid mutation on an Odyssey Workout */
export type OdysseyInvalidWorkoutMutationError = OdysseyError & {
  __typename: 'OdysseyInvalidWorkoutMutationError';
  message: Scalars['String']['output'];
  workoutId: Scalars['String']['output'];
};

/** Error thrown when querying or mutating a model that doesn't exist */
export type OdysseyItemNotFoundError = OdysseyError & {
  __typename: 'OdysseyItemNotFoundError';
  entityId: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Information needed to render a leaderboard */
export type OdysseyLeaderboard = {
  __typename: 'OdysseyLeaderboard';
  canFetchNextPage: Scalars['Boolean']['output'];
  count: Scalars['Int']['output'];
  extraWorkoutEntry?: Maybe<LeaderboardEntry>;
  leaderboard: Array<LeaderboardEntry>;
  startRank: Scalars['Int']['output'];
};

/** Generic error type structure */
export type OdysseyLeaderboardGraphqlError = {
  message: Scalars['String']['output'];
};

/** Error thrown when an invalid request is received */
export type OdysseyLeaderboardInvalidRequest = OdysseyLeaderboardGraphqlError & {
  __typename: 'OdysseyLeaderboardInvalidRequest';
  message: Scalars['String']['output'];
};

/** Successful response from service after querying for leaderboard */
export type OdysseyLeaderboardListSuccessResponse = {
  __typename: 'OdysseyLeaderboardListSuccessResponse';
  leaderboard: OdysseyLeaderboard;
};

export type OdysseyLeaderboardQueryResponse =
  | OdysseyLeaderboardInvalidRequest
  | OdysseyLeaderboardListSuccessResponse;

export enum OdysseyLeaderboardSortBy {
  Date = 'Date',
  Score = 'Score',
}

/** Odyssey level assets */
export type OdysseyLevelAssets = {
  __typename: 'OdysseyLevelAssets';
  detailImageUrl?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  unityBundleUrl?: Maybe<Scalars['String']['output']>;
};

export type OdysseyLevelBrowseFilter = {
  __typename: 'OdysseyLevelBrowseFilter';
  info: OdysseyLevelFilterInfo;
  options: Array<OdysseyLevelFilterOption>;
};

/** Type for level specific difficulty details */
export type OdysseyLevelDifficultyDetail = {
  __typename: 'OdysseyLevelDifficultyDetail';
  midiFileUrl: Scalars['String']['output'];
  odysseyLevelTargetMetricsData?: Maybe<OdysseyLevelTargetMetricsData>;
  slug: OdysseyDifficulty;
};

export type OdysseyLevelFilter =
  | OdysseyAccordionLevelFilter
  | OdysseyGridLevelFilter
  | OdysseyToggleLevelFilter;

export type OdysseyLevelFilterInfo =
  | OdysseyAccordionLevelFilterInfo
  | OdysseyGridLevelFilterInfo
  | OdysseyToggleLevelFilterInfo;

export type OdysseyLevelFilterOption =
  | OdysseyBasicLevelFilterOption
  | OdysseyDurationLevelFilterOption
  | OdysseyPlayedLevelFilterOption
  | OdysseyStarsLevelFilterOption;

export type OdysseyLevelMetric = {
  __typename: 'OdysseyLevelMetric';
  lower?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<OdysseyLevelMetricName>;
  upper?: Maybe<Scalars['Float']['output']>;
};

export enum OdysseyLevelMetricName {
  Altitude = 'ALTITUDE',
  Cadence = 'CADENCE',
  Calories = 'CALORIES',
  Distance = 'DISTANCE',
  Elevation = 'ELEVATION',
  Gps = 'GPS',
  GpsAccuracy = 'GPS_ACCURACY',
  HeartRate = 'HEART_RATE',
  Incline = 'INCLINE',
  PaceIntensity = 'PACE_INTENSITY',
  PowerZone = 'POWER_ZONE',
  Resistance = 'RESISTANCE',
  Speed = 'SPEED',
  StrokeRate = 'STROKE_RATE',
}

export type OdysseyLevelOffset = {
  __typename: 'OdysseyLevelOffset';
  end?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['Int']['output']>;
};

export type OdysseyLevelPaceIntensity = {
  __typename: 'OdysseyLevelPaceIntensity';
  displayName?: Maybe<Scalars['String']['output']>;
  paceLevels: Array<Maybe<OdysseyLevelPaceLevel>>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type OdysseyLevelPaceLevel = {
  __typename: 'OdysseyLevelPaceLevel';
  fastPace?: Maybe<Scalars['Float']['output']>;
  slowPace?: Maybe<Scalars['Float']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export enum OdysseyLevelSegmentTypes {
  Caesar = 'CAESAR',
  Cycling = 'CYCLING',
  Floor = 'FLOOR',
  FreeMode = 'FREE_MODE',
  PowerZone = 'POWER_ZONE',
  Running = 'RUNNING',
}

export type OdysseyLevelTargetMetrics = {
  __typename: 'OdysseyLevelTargetMetrics';
  metrics: Array<OdysseyLevelMetric>;
  offsets: OdysseyLevelOffset;
  segmentType?: Maybe<OdysseyLevelSegmentTypes>;
};

export type OdysseyLevelTargetMetricsData = {
  __typename: 'OdysseyLevelTargetMetricsData';
  paceIntensities: Array<Maybe<OdysseyLevelPaceIntensity>>;
  targetMetrics: Array<OdysseyLevelTargetMetrics>;
  totalExpectedOutput?: Maybe<OdysseyLevelTargetMetricsOutput>;
};

export type OdysseyLevelTargetMetricsOutput = {
  __typename: 'OdysseyLevelTargetMetricsOutput';
  expectedLowerOutput?: Maybe<Scalars['Int']['output']>;
  expectedUpperOutput?: Maybe<Scalars['Int']['output']>;
};

export type OdysseyNotAuthorizedError = OdysseyError & {
  __typename: 'OdysseyNotAuthorizedError';
  message: Scalars['String']['output'];
};

export type OdysseyPlayedLevelFilterOption = {
  __typename: 'OdysseyPlayedLevelFilterOption';
  icon: OdysseyIcon;
  listOrder: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OdysseyStarThreshold = {
  __typename: 'OdysseyStarThreshold';
  completionPercent: Scalars['Float']['output'];
  starsEarned: Scalars['Float']['output'];
};

export type OdysseyStarsLevelFilterOption = {
  __typename: 'OdysseyStarsLevelFilterOption';
  icon: OdysseyIcon;
  listOrder: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OdysseyToggleLevelFilter = {
  __typename: 'OdysseyToggleLevelFilter';
  defaultValue: Scalars['Boolean']['output'];
  icon: OdysseyIcon;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type OdysseyToggleLevelFilterInfo = {
  __typename: 'OdysseyToggleLevelFilterInfo';
  defaultValue: Scalars['Boolean']['output'];
  icon: OdysseyIcon;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type OdysseyTreadCompletionInput = {
  beatCompletionPercent: Scalars['Float']['input'];
  blastCompletionPercent: Scalars['Float']['input'];
  hillCompletionPercent: Scalars['Float']['input'];
};

export type OdysseyTreadConfiguration = {
  __typename: 'OdysseyTreadConfiguration';
  difficultyConfigs: Array<OdysseyTreadDifficultyConfiguration>;
  scoringConfig: OdysseyTreadScoringConfiguration;
};

export type OdysseyTreadDifficultyConfiguration = {
  __typename: 'OdysseyTreadDifficultyConfiguration';
  displayName: Scalars['String']['output'];
  intensities: Array<OdysseyTreadDifficultyIntensity>;
  laneConfigs: Array<OdysseyTreadLaneConfiguration>;
  slug: OdysseyDifficulty;
  /** @deprecated Use intensities instead */
  speedModifier: Scalars['Float']['output'];
};

export type OdysseyTreadDifficultyIntensity = {
  __typename: 'OdysseyTreadDifficultyIntensity';
  /** This will correspond to the midi note velocity */
  key: Scalars['Int']['output'];
  maxSpeed: Scalars['Float']['output'];
  minSpeed: Scalars['Float']['output'];
};

export type OdysseyTreadLaneConfiguration = {
  __typename: 'OdysseyTreadLaneConfiguration';
  index: Scalars['Int']['output'];
  maxIncline: Scalars['Float']['output'];
  minIncline: Scalars['Float']['output'];
};

export type OdysseyTreadScoringConfiguration = {
  __typename: 'OdysseyTreadScoringConfiguration';
  starThresholds: Array<OdysseyStarThreshold>;
};

/** Type for a workout taken from Odyssey */
export type OdysseyWorkout = {
  __typename: 'OdysseyWorkout';
  /** 0 to 1 */
  beatCompletionPercent?: Maybe<Scalars['Float']['output']>;
  /** 0 to 1 */
  blastCompletionPercent?: Maybe<Scalars['Float']['output']>;
  /** 0 to 1 */
  breakerCompletionPercent?: Maybe<Scalars['Float']['output']>;
  /** 0 to 1 */
  completionPercent?: Maybe<Scalars['Float']['output']>;
  difficulty: OdysseyDifficulty;
  /** 0 to 1 */
  hillCompletionPercent?: Maybe<Scalars['Float']['output']>;
  /** Hyphen-less id format to allow references back to Workouts in the monolith */
  id: Scalars['ID']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  startTime: Scalars['DateTime']['output'];
  /** 0 to 1 */
  streamCompletionPercent?: Maybe<Scalars['Float']['output']>;
};

export type OdysseyWorkoutQueryResponse =
  | OdysseyItemNotFoundError
  | OdysseyNotAuthorizedError
  | OdysseyWorkoutSuccessResponse;

/** Successful response from the service after querying or mutating an odyssey workout */
export type OdysseyWorkoutSuccessResponse = {
  __typename: 'OdysseyWorkoutSuccessResponse';
  workout: OdysseyWorkout;
};

export type Offset = {
  __typename: 'Offset';
  end?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['Int']['output']>;
};

export type OffsetsCue = {
  __typename: 'OffsetsCue';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type OnDemandInstructorClass = PelotonClass & {
  __typename: 'OnDemandInstructorClass';
  airTime: Scalars['DateTime']['output'];
  assets: ClassAssets;
  captions?: Maybe<Captions>;
  classId: Scalars['String']['output'];
  classPreviewVideo?: Maybe<Scalars['String']['output']>;
  classTypes: Array<ClassType>;
  contentAvailability?: Maybe<Scalars['String']['output']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']['output']>;
  /** whether the user is video or audio-only */
  contentFormat: ContentFormat;
  description: Scalars['String']['output'];
  difficultyLevel?: Maybe<DifficultyLevel>;
  duration: Scalars['Int']['output'];
  equipment: Array<Equipment>;
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']['output']>>;
  /** explicitness of a class */
  explicitRating: Scalars['Int']['output'];
  fitnessDiscipline: FitnessDiscipline;
  flags?: Maybe<Array<Scalars['String']['output']>>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  instructor: Instructor;
  instructorCues?: Maybe<Array<InstructorCue>>;
  isBookmarked: Scalars['Boolean']['output'];
  isExplicit: Scalars['Boolean']['output'];
  isFtpTest: Scalars['Boolean']['output'];
  isLimitedRide?: Maybe<Scalars['Boolean']['output']>;
  joinToken: Scalars['JoinToken']['output'];
  metrics: Array<MetricName>;
  originLocale: Locale;
  /** @deprecated use airTime instead of originalAirTime */
  originalAirTime: Scalars['DateTime']['output'];
  playableOnPlatform: Scalars['Boolean']['output'];
  playlist?: Maybe<Playlist>;
  shopTheClass: Scalars['Boolean']['output'];
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  targetMetricsData?: Maybe<TargetMetricsData>;
  timeline?: Maybe<ClassTimeline>;
  title: Scalars['String']['output'];
  /**
   * Top tags for this joinToken. Get $limit tags randomly from the $sampledFrom top Tags.
   * When sampledFrom = 0, simply return top $limit tags.
   * @deprecated Not implemented yet.
   */
  topTags: Array<ClassTopTag>;
  /** total count of number of workouts for this class */
  totalUserWorkouts?: Maybe<Scalars['Int']['output']>;
};

export type OnDemandInstructorClassTopTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sampledFrom?: InputMaybe<Scalars['Int']['input']>;
};

export type PaceIntensity = {
  __typename: 'PaceIntensity';
  displayName?: Maybe<Scalars['String']['output']>;
  paceLevels?: Maybe<Array<Maybe<PaceLevel>>>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type PaceLevel = {
  __typename: 'PaceLevel';
  fastPace?: Maybe<Scalars['Float']['output']>;
  slowPace?: Maybe<Scalars['Float']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PaginatedList = {
  pageInfo: PageInfo;
};

export type PauseInfo = {
  __typename: 'PauseInfo';
  isPaused: Scalars['Boolean']['output'];
  /** Pause time elapsed (in seconds) */
  pauseTimeElapsed: Scalars['Int']['output'];
  /** Pause time limit (in seconds) */
  pauseTimeLimit: Scalars['Int']['output'];
};

export type PauseWorkoutInput = {
  /** ISO-8601 timestamp */
  pauseStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  workoutId: Scalars['String']['input'];
};

export type Payload =
  | AchievementAwardedEventPayload
  | StreakAwardedEventPayload
  | WorkoutFinalizedEventPayload;

export type Peloton = {
  __typename: 'Peloton';
  pelotonId: Scalars['String']['output'];
};

export type PelotonClass = {
  /** visual media assets for peloton class */
  assets: ClassAssets;
  classId: Scalars['String']['output'];
  classPreviewVideo?: Maybe<Scalars['String']['output']>;
  classTypes: Array<ClassType>;
  contentAvailability?: Maybe<Scalars['String']['output']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  /** length of class video in seconds */
  duration: Scalars['Int']['output'];
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']['output']>>;
  fitnessDiscipline: FitnessDiscipline;
  flags?: Maybe<Array<Scalars['String']['output']>>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']['output']>;
  /** joinToken identifier for peloton class */
  id: Scalars['ID']['output'];
  isLimitedRide?: Maybe<Scalars['Boolean']['output']>;
  joinToken: Scalars['JoinToken']['output'];
  /** A list of metrics that we track during a workout and devices can include in packets they post for the workout */
  metrics: Array<MetricName>;
  /** locale language in which class was originally filmed */
  originLocale: Locale;
  /** whether this class is available for the clients membership on the requesting platform. The use of this field in connection to audio only formats is deprecated. */
  playableOnPlatform: Scalars['Boolean']['output'];
  shopTheClass: Scalars['Boolean']['output'];
  /** in-class segments timeline */
  timeline?: Maybe<ClassTimeline>;
  title: Scalars['String']['output'];
};

export type PelotonClassFeedWorkout = FeedWorkout & {
  __typename: 'PelotonClassFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']['output']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']['output']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']['output']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']['output']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']['output']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']['output']>;
  completedAt: Scalars['Int']['output'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']['output']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  fitnessDiscipline?: Maybe<Scalars['String']['output']>;
  isOutdoor?: Maybe<Scalars['Boolean']['output']>;
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']['output']>;
  pelotonClass?: Maybe<PelotonClass>;
  pelotonClassId: Scalars['String']['output'];
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID']['output'];
  /** Platform of the FeedWorkout */
  platform?: Maybe<Scalars['String']['output']>;
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']['output']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']['output']>;
};

/**
 * Error thrown when attempting to get a SessionClass when a Peloton
 * does not exist for that class.
 */
export type PelotonNotCreatedError = Error & {
  __typename: 'PelotonNotCreatedError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type PelotonResult =
  | NoClassPelotonForPlatformError
  | Peloton
  | PelotonNotCreatedError;

export type PendingInvites = {
  __typename: 'PendingInvites';
  countOfPendingInvites: Scalars['Int']['output'];
  pendingInvitesList: Array<InviteDetails>;
};

/** Returns PendingInvites or Error */
export type PendingInvitesResponse = InviteFriendsItemNotFoundError | PendingInvites;

export type PermissionError = Error & {
  __typename: 'PermissionError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type PlayClassFromStackInput = {
  pelotonClassId: Scalars['ID']['input'];
};

export type Playlist = BasePlaylist & {
  __typename: 'Playlist';
  id: Scalars['ID']['output'];
  isInClassMusicShown: Scalars['Boolean']['output'];
  playbackSongs: Array<PlaylistPlaybackSong>;
  /** @deprecated Use playbackSongs instead of songs */
  songs: Array<Song>;
  topArtists: Array<Artist>;
};

export type PlaylistMusicProvider = {
  __typename: 'PlaylistMusicProvider';
  isFeatured: Scalars['Boolean']['output'];
  playlist: StreamablePlaylist;
};

export type PlaylistPlaybackSong = {
  __typename: 'PlaylistPlaybackSong';
  cueOffset: Scalars['Int']['output'];
  index: Scalars['Int']['output'];
  song: Song;
  streamOffset: Scalars['Int']['output'];
};

/** A class that users from a tag have taken. */
export type PopularTagClass = {
  __typename: 'PopularTagClass';
  /** Count of the users in the group who have taken this class */
  countUsers: Scalars['Int']['output'];
  joinToken: Scalars['JoinToken']['output'];
  /** pelotonClass can be null if class removed from onDemand after PopularTagClasses was calculated */
  pelotonClass?: Maybe<PelotonClass>;
};

/** A list of PopularTagClass results. */
export type PopularTagClassesList = {
  __typename: 'PopularTagClassesList';
  count: Scalars['Int']['output'];
  popularClasses: Array<PopularTagClass>;
};

export type Program = {
  __typename: 'Program';
  achievements: Array<ProgramAchievementTier>;
  assets: ProgramAssets;
  classes: Array<ProgramClass>;
  contentAvailability?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  equipment: Array<Equipment>;
  fitnessDisciplines: Array<ProgramFitnessDiscipline>;
  flags: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructors: Array<Instructor>;
  isLimitedContent?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  programClassesLocale: Locale;
  programMetaData: ProgramMetaData;
  programSchedule: ProgramSchedule;
};

export type ProgramAchievementAssets = {
  __typename: 'ProgramAchievementAssets';
  badgeImage: Media;
  color: Color;
};

export type ProgramAchievementTier = {
  __typename: 'ProgramAchievementTier';
  assets: ProgramAchievementAssets;
  badgeDescription: Scalars['String']['output'];
  badgeName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  numClassesRequired: Scalars['Int']['output'];
  tierName: Scalars['String']['output'];
};

export type ProgramAssets = {
  __typename: 'ProgramAssets';
  heroImage: HeroImageAssets;
  multipleInstructorOnboardingImage?: Maybe<Media>;
  thumbnailImage: Media;
};

export type ProgramClass = {
  __typename: 'ProgramClass';
  classNumber: Scalars['Int']['output'];
  classProgress?: Maybe<ProgramClassProgress>;
  id: Scalars['ID']['output'];
  pelotonClass: PelotonClass;
  pelotonClassId: Scalars['ID']['output'];
};

export type ProgramClassClassProgressArgs = {
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
  programProgressId: Scalars['String']['input'];
};

export type ProgramClassProgress = {
  __typename: 'ProgramClassProgress';
  classNumber: Scalars['Int']['output'];
  programClass: ProgramClass;
  status: ProgramClassProgressStatus;
};

export enum ProgramClassProgressStatus {
  NotTaken = 'NOT_TAKEN',
  Skipped = 'SKIPPED',
  Taken = 'TAKEN',
}

export type ProgramDay = {
  __typename: 'ProgramDay';
  classes: Array<ProgramClass>;
  dayNumber: Scalars['Int']['output'];
};

export type ProgramFiltersInput = {
  isMfbt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramFitnessDiscipline = {
  __typename: 'ProgramFitnessDiscipline';
  slug: Scalars['String']['output'];
};

export type ProgramMetaData = {
  __typename: 'ProgramMetaData';
  daysPerWeek: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  highMinsPerDay: Scalars['Int']['output'];
  lowMinsPerDay: Scalars['Int']['output'];
  totalNumClasses: Scalars['Int']['output'];
};

export type ProgramParamsInput = {
  filters?: InputMaybe<ProgramFiltersInput>;
  sorting?: InputMaybe<ProgramSortingInput>;
};

export type ProgramProgress = {
  __typename: 'ProgramProgress';
  awardedAchievement: AwardedProgramAchievementTier;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  program: Program;
  startDate: Scalars['DateTime']['output'];
  weeklyProgress: Array<ProgramWeekProgress>;
};

/** Error thrown when active program progress for program and user already exists */
export type ProgramProgressAlreadyExistsError = {
  __typename: 'ProgramProgressAlreadyExistsError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Error thrown when no program progress with a given id exists */
export type ProgramProgressNotFoundError = {
  __typename: 'ProgramProgressNotFoundError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Returns program progress for user */
export type ProgramProgressResponse =
  | ProgramProgressAlreadyExistsError
  | ProgramProgressNotFoundError
  | ProgramProgressResponseSuccess
  | UserNotAuthorizedError;

/** Successful program progress response from the service */
export type ProgramProgressResponseSuccess = {
  __typename: 'ProgramProgressResponseSuccess';
  programProgress?: Maybe<ProgramProgress>;
};

export type ProgramSchedule = {
  __typename: 'ProgramSchedule';
  weeks: Array<ProgramWeek>;
};

export type ProgramSortingInput = {
  notCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramWeek = {
  __typename: 'ProgramWeek';
  days: Array<ProgramDay>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  weekNumber: Scalars['Int']['output'];
  weekProgress?: Maybe<ProgramWeekProgress>;
};

export type ProgramWeekWeekProgressArgs = {
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
  programProgressId: Scalars['String']['input'];
};

export type ProgramWeekProgress = {
  __typename: 'ProgramWeekProgress';
  classesProgress: Array<Maybe<ProgramClassProgress>>;
  endDate: Scalars['DateTime']['output'];
  seen: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
  status: ProgramWeekProgressStatus;
  weekNumber: Scalars['Int']['output'];
};

export enum ProgramWeekProgressStatus {
  Current = 'CURRENT',
  Future = 'FUTURE',
  Previous = 'PREVIOUS',
}

export type Query = {
  __typename: 'Query';
  /** Get entertainment channels for admins */
  allEntertainmentChannelsWithTranslations: EntertainmentChannelWithTranslationsResponse;
  /** Returns true if class is already in currently logged in user's stack, false otherwise */
  checkDoesClassExistInUserStack: Scalars['Boolean']['output'];
  completedPrograms: Array<ProgramProgress>;
  /** Get the current user's tag list */
  currentUserTags: UserTags;
  /** Get entertainment channels */
  entertainmentChannels: EntertainmentChannelsQueryResponse;
  filterPrograms: Array<Program>;
  findEntertainmentChannelWithTranslationById: EntertainmentChannelWithTranslationsResponse;
  /** get metadata for a freestyle experience by platform */
  freestyleMetadata: FreestyleMetadata;
  /** Get the details for an activity feed event. Can only fetch logged in user's events. */
  getEventDetails: EventDetailsResponse;
  /** Get high five details for an activity feed event */
  getHighFiveDetailsForEvent: HighFiveDetailsResponse;
  /** Get a list response of a user's notification details */
  getNotificationDetails: NotificationDetailsResponse;
  /** Get a count of a user's unseen notifications */
  getNumberOfUnreadNotifications: UnreadNotificationsResponse;
  /** Gets the presigned url for a channel by id */
  getPresignedUrl: EntertainmentChannelPresignedUrlQueryResponse;
  getWorkoutOfTheDay?: Maybe<WorkoutOfTheDayDetails>;
  /**
   * Fetch list of bookmarked Gym Plans
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  gymBookmarks: GymPlanResponse;
  /**
   * Fetch a Gym Plan by ID
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  gymPlan: GymPlanResponse;
  /**
   * Fetch list of Gym Plan Filter Chips
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  gymPlanFilterChips: Array<GymPlanFilterChip>;
  /**
   * Fetch list of Gyn Plan Filter Options
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  gymPlanQueryFilterOptions: GymPlanQueryFilterOptions;
  /**
   * Fetch Gym Plans
   * Owner: App Cloud Workouts
   * Slack: #app-gym
   */
  gymPlans: GymPlanResponse;
  /** View a single Invite's details */
  inviteDetails: YourScheduleItem;
  joinableClass: JoinableClass;
  /** Get a level by id */
  level: LevelQueryResponse;
  /** Get all levels */
  levels: LevelListQueryResponse;
  nextProgramClass?: Maybe<ProgramClass>;
  /** Show options for occasions by language */
  occasions: OccasionsResponse;
  /** Odyssey platform configurations */
  odysseyConfiguration: OdysseyConfigurationResponse;
  /**
   * Get difficulty metadata
   * @deprecated use odysseyConfiguration instead
   */
  odysseyDifficultyMetadata: Array<OdysseyDifficultyMetadata>;
  /** Get a leaderboard populated by the requesting user's friends */
  odysseyFriendsLeaderboard: OdysseyLeaderboardQueryResponse;
  /** Get a leaderboard */
  odysseyLeaderboard: OdysseyLeaderboardQueryResponse;
  /** Get level browse filters */
  odysseyLevelBrowseFilters: LevelFiltersQueryResponse;
  /** A leaderboard made up of a single user's workout */
  odysseyUserLeaderboard: OdysseyLeaderboardQueryResponse;
  /** Get an Odyssey workout by id */
  odysseyWorkout: OdysseyWorkoutQueryResponse;
  /** Get a pelotonClass based from joinToken */
  pelotonClass: PelotonClass;
  program: Program;
  programProgress: ProgramProgress;
  programs: Array<Program>;
  programsById: Array<Program>;
  progressOfPrograms: Array<ProgramProgress>;
  /** get info about a specific scheduled class */
  scheduledClass: ScheduledClassResponse;
  /**
   * Search for tags based on a substring in tag. Results are sorted with
   * an exact match first and then by popularity of the tag.
   */
  searchTag: TagPaginatedList;
  /**
   * Gets next available session for a specified PelotonClass identified by ID (aka JoinToken).
   * Returned Session class is nullable since it is possible for a class to be ineligible for a Session
   */
  session?: Maybe<SessionClass>;
  /** get info about a specific streamable playlist */
  streamablePlaylist: StreamablePlaylist;
  /** Get a tag based on its case insensitive name. */
  tag?: Maybe<Tag>;
  /** Get a tag category by its slug. */
  tagBrowseCategory: TagBrowseCategory;
  /** Get a user by the id. Default to return the current logged in user. */
  user?: Maybe<User>;
  /** Get a paginated response of a user's activity feed */
  userActivityFeed: ActivityFeedEventResponse;
  userProgramAchievements: Array<AwardedProgramAchievementTier>;
  /** Returns a user's pending invites and list of invites and scheduled classes */
  userScheduledItemsList: YourScheduleListResponse;
  /** Get a UserStack for the current logged in user */
  viewUserStack: StackResponse;
  workoutPostClassDetails: WorkoutPostClassResponse;
  workoutProgramAchievements: Array<AwardedProgramAchievementTier>;
};

export type QueryCheckDoesClassExistInUserStackArgs = {
  pelotonClassId: Scalars['ID']['input'];
};

export type QueryCompletedProgramsArgs = {
  completedAfterDate?: InputMaybe<Scalars['DateTime']['input']>;
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryEntertainmentChannelsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
};

export type QueryFilterProgramsArgs = {
  programParams: ProgramParamsInput;
};

export type QueryFindEntertainmentChannelWithTranslationByIdArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryGetEventDetailsArgs = {
  activityFeedEventId: Scalars['ID']['input'];
};

export type QueryGetHighFiveDetailsForEventArgs = {
  eventCreatedAt: Scalars['DateTime']['input'];
  eventUserId: Scalars['ID']['input'];
};

export type QueryGetPresignedUrlArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryGymBookmarksArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGymPlanArgs = {
  gymPlanId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGymPlanFilterChipsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGymPlansArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  gymPlanFilter?: InputMaybe<GymPlanFilterInput>;
  gymPlanQueryFilters?: InputMaybe<GymPlanQueryFiltersInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInviteDetailsArgs = {
  inviteId: Scalars['ID']['input'];
};

export type QueryJoinableClassArgs = {
  id: Scalars['ID']['input'];
  streamUrlType?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLevelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNextProgramClassArgs = {
  programClassId?: InputMaybe<Scalars['String']['input']>;
  programProgressId: Scalars['String']['input'];
};

export type QueryOccasionsArgs = {
  fitnessDiscipline: Scalars['String']['input'];
};

export type QueryOdysseyFriendsLeaderboardArgs = {
  leaderboardRequest: LeaderboardRequestInput;
};

export type QueryOdysseyLeaderboardArgs = {
  leaderboardRequest: LeaderboardRequestInput;
};

export type QueryOdysseyUserLeaderboardArgs = {
  leaderboardRequest?: InputMaybe<LeaderboardRequestInput>;
  userLeaderboardRequest?: InputMaybe<UserLeaderboardRequestInput>;
};

export type QueryOdysseyWorkoutArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPelotonClassArgs = {
  joinToken: Scalars['String']['input'];
};

export type QueryProgramArgs = {
  programId: Scalars['String']['input'];
};

export type QueryProgramProgressArgs = {
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
  programProgressId: Scalars['String']['input'];
};

export type QueryProgramsByIdArgs = {
  programIds: Array<Scalars['String']['input']>;
};

export type QueryProgressOfProgramsArgs = {
  currentDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryScheduledClassArgs = {
  scheduledClassId: Scalars['String']['input'];
};

export type QuerySearchTagArgs = {
  input: SearchTagInput;
};

export type QuerySessionArgs = {
  sessionInput: SessionInput;
};

export type QueryStreamablePlaylistArgs = {
  playlistId: Scalars['String']['input'];
};

export type QueryTagArgs = {
  tagName: Scalars['String']['input'];
};

export type QueryTagBrowseCategoryArgs = {
  categorySlug: TagBrowseCategorySlug;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserActivityFeedArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserScheduledItemsListArgs = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type QueryWorkoutPostClassDetailsArgs = {
  isWorkoutFromStack: Scalars['Boolean']['input'];
  programClassId?: InputMaybe<Scalars['ID']['input']>;
  programProgressId?: InputMaybe<Scalars['ID']['input']>;
  workoutId: Scalars['ID']['input'];
};

export type QueryWorkoutProgramAchievementsArgs = {
  workoutId: Scalars['String']['input'];
};

export type RangeCue = {
  __typename: 'RangeCue';
  lower: Scalars['Int']['output'];
  upper: Scalars['Int']['output'];
};

export type ReadyVideoStream = {
  __typename: 'ReadyVideoStream';
  videoStream: VideoStream;
};

/** Input for removeTagFromUser operation. */
export type RemoveTagFromUserInput = {
  /** Case insensitive name of the tag to remove. */
  tagName: Scalars['String']['input'];
};

/** TODO: make sure type name is on mutations before implementation */
export type RemoveTagFromUserResponse = DoesNotExistError | RemoveTagFromUserSuccess;

/** Response for removeTagFromUser operation. */
export type RemoveTagFromUserSuccess = {
  __typename: 'RemoveTagFromUserSuccess';
  /** The tag. */
  tag?: Maybe<Tag>;
};

/** Input for replaceTag operation. */
export type ReplaceTagInput = {
  /** Case insensitive name of the tag to add. */
  newTagName: Scalars['String']['input'];
  /** Case insensitive name of the tag to replace. */
  oldTagName: Scalars['String']['input'];
};

export type ReplaceTagResponse =
  | DoesNotExistError
  | ReplaceTagSuccess
  | TagAlreadyExistsError
  | TagNameInvalidCharacterError
  | TagNameLengthError
  | TagNameProhibitedError;

export type ReplaceTagSuccess = {
  __typename: 'ReplaceTagSuccess';
  /** The newly added tag. */
  tag?: Maybe<Tag>;
};

export type RescheduleClassInput = {
  /** ScheduledClass.id (aka JoinToken) */
  id: Scalars['ID']['input'];
  scheduledStartTime: Scalars['DateTime']['input'];
};

export type RetryableError = {
  retryAfterSeconds?: Maybe<Scalars['Int']['output']>;
  retryMaxDuration: Scalars['Int']['output'];
};

export type ScenicClass = PelotonClass & {
  __typename: 'ScenicClass';
  assets: ClassAssets;
  captions?: Maybe<Captions>;
  classId: Scalars['String']['output'];
  classPreviewVideo?: Maybe<Scalars['String']['output']>;
  classTypes: Array<ClassType>;
  contentAvailability?: Maybe<Scalars['String']['output']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  distance?: Maybe<Scalars['Float']['output']>;
  distanceUnit?: Maybe<DistanceUnit>;
  duration: Scalars['Int']['output'];
  dynamicVideoRecordedSpeedInMph?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']['output']>>;
  fitnessDiscipline: FitnessDiscipline;
  flags?: Maybe<Array<Scalars['String']['output']>>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  instructor?: Maybe<Instructor>;
  isBookmarked: Scalars['Boolean']['output'];
  isDynamicVideoEligible?: Maybe<Scalars['Boolean']['output']>;
  isFixedDistance?: Maybe<Scalars['Boolean']['output']>;
  isLimitedRide?: Maybe<Scalars['Boolean']['output']>;
  joinToken: Scalars['JoinToken']['output'];
  metrics: Array<MetricName>;
  musicProviders: Array<Maybe<MusicProvider>>;
  originLocale: Locale;
  playableOnPlatform: Scalars['Boolean']['output'];
  shopTheClass: Scalars['Boolean']['output'];
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  targetMetricsData?: Maybe<TargetMetricsData>;
  thumbnailLocation?: Maybe<Scalars['String']['output']>;
  thumbnailTitle?: Maybe<Scalars['String']['output']>;
  timeline?: Maybe<ClassTimeline>;
  title: Scalars['String']['output'];
  /**
   * Top tags for this joinToken. Get $limit tags randomly from the $sampledFrom top Tags.
   * When sampledFrom = 0, simply return top $limit tags.
   * @deprecated Not implemented yet.
   */
  topTags: Array<ClassTopTag>;
  totalUserWorkouts?: Maybe<Scalars['Int']['output']>;
  videoEmbeddedPlaylist?: Maybe<Playlist>;
};

export type ScenicClassTopTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sampledFrom?: InputMaybe<Scalars['Int']['input']>;
};

/** scheduled class source */
export enum ScheduleSource {
  /** scheduled by Peloton content team (i.e. Live and Encore classes) */
  Peloton = 'PELOTON',
  /** Session class schedule */
  Session = 'SESSION',
  /** scheduled by user */
  User = 'USER',
}

export type ScheduledClass = {
  __typename: 'ScheduledClass';
  /** identifier for the ScheduledClass */
  id: Scalars['ID']['output'];
  /** refers to whether current user rsvp-ed */
  isScheduled: Scalars['Boolean']['output'];
  /** joinToken which should be used to start the workout for the scheduled class. This is the same as the id for Live and Session classes, but will be different for scheduled on-demand classes which are not sessions eligible */
  joinToken: Scalars['JoinToken']['output'];
  /** joinToken for the on-demand version of this class, to be used for taking this class as an on-demand workout */
  onDemandJoinToken?: Maybe<Scalars['JoinToken']['output']>;
  pelotonClass: PelotonClass;
  /** UUID for the peloton for this scheduled class */
  pelotonId: Scalars['String']['output'];
  /** refers to whether this scheduled class is an official Peloton live schedule class, a scheduled on-demand class for the user, or a session */
  scheduleSource: ScheduleSource;
  /** scheduled time for the class to end */
  scheduledEndTime: Scalars['DateTime']['output'];
  /** scheduled time for the class to begin */
  scheduledStartTime: Scalars['DateTime']['output'];
  /** query to get all users who have scheduled/counted-in to this class */
  scheduledUsers: UserPaginatedList;
  /** total number of users on a scheduled class, corresponds to API total_home_reservations */
  totalUsersCountedIn: Scalars['Int']['output'];
  /** The number of all user workouts who took this specific scheduled class */
  totalWorkouts: Scalars['Int']['output'];
};

export type ScheduledClassScheduledUsersArgs = {
  cursor?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduledClassDoesNotExistError = Error & {
  __typename: 'ScheduledClassDoesNotExistError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ScheduledClassInput = {
  /** PelotonClass ID (aka JoinToken) */
  id: Scalars['ID']['input'];
  /**
   * must be specified for on-demand class so we know what time to schedule for since OD class has no inherent start time.
   * Can be null for LiveClass since this is redundant
   */
  scheduledStartTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScheduledClassList = {
  __typename: 'ScheduledClassList';
  scheduledClasses: Array<ScheduledClass>;
};

export type ScheduledClassListResponse = ScheduledClassList | UserPrivacyError;

export type ScheduledClassResponse =
  | CannotScheduleClassError
  | ScheduledClass
  | ScheduledClassDoesNotExistError;

export type ScheduledClassesInput = {
  /** latest scheduled end time to fetch window of scheduled classes */
  endTime: Scalars['DateTime']['input'];
  /** earliest scheduled start time to fetch window of scheduled classes */
  startTime: Scalars['DateTime']['input'];
};

/** Input for searchTag query */
export type SearchTagInput = {
  /** Opaque cursor string for the part of a result set to search after */
  after?: InputMaybe<Scalars['Cursor']['input']>;
  /** Number of results to return */
  limit?: Scalars['Int']['input'];
  /** Query string to search for. */
  query: Scalars['String']['input'];
};

export enum SegmentTypes {
  Caesar = 'CAESAR',
  Cycling = 'CYCLING',
  Floor = 'FLOOR',
  FreeMode = 'FREE_MODE',
  PowerZone = 'POWER_ZONE',
  Running = 'RUNNING',
}

export type SessionClass = {
  __typename: 'SessionClass';
  liveClass: LiveClass;
  onDemandJoinToken: Scalars['JoinToken']['output'];
};

export type SessionInput = {
  id: Scalars['ID']['input'];
  requestTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Input for SetTagAsPrimary operation. */
export type SetTagAsPrimaryInput = {
  /** Case insensitive name of the tag to set to be primary. */
  tagName: Scalars['String']['input'];
};

export type SetTagAsPrimaryResponse = SetTagAsPrimarySuccess | TagDoesNotExistOnUserError;

/** Response for SetTagAsPrimary operation. */
export type SetTagAsPrimarySuccess = {
  __typename: 'SetTagAsPrimarySuccess';
  /** The tag. */
  tag?: Maybe<Tag>;
};

export type ShareUrl = {
  __typename: 'ShareURL';
  url: Scalars['String']['output'];
};

export type Song = {
  __typename: 'Song';
  album: Album;
  artists: Array<Artist>;
  /** @deprecated Use cueOffset on the PlaylistPlaybackSong */
  cueTimeOffset?: Maybe<Scalars['Int']['output']>;
  explicitRating: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  liked: Scalars['Boolean']['output'];
  /** @deprecated Use streamOffset on the PlaylistPlaybackSong */
  startTimeOffset?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

/** Error thrown when connecting to stacks */
export type StackConnectionError = Error &
  StackResponse & {
    __typename: 'StackConnectionError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown when querying for a stackId that is not associated to the current session's user, userStack will return the userId and an empty stackedClassList */
export type StackDoesNotExist = Error &
  StackResponse & {
    __typename: 'StackDoesNotExist';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown when querying stacks */
export type StackError = Error &
  StackResponse & {
    __typename: 'StackError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown when querying stacks without permission */
export type StackPermissionError = Error &
  StackResponse & {
    __typename: 'StackPermissionError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Returns number of classes and the sum of their scheduled duration from the user's stack */
export type StackResponse = {
  numClasses: Scalars['Int']['output'];
  totalTime: Scalars['Int']['output'];
  userStack: UserStack;
};

/** Successful response from the service. When there are no classes in the stack, userStack will return the userId and an empty stackedClassList */
export type StackResponseSuccess = StackResponse & {
  __typename: 'StackResponseSuccess';
  numClasses: Scalars['Int']['output'];
  totalTime: Scalars['Int']['output'];
  userStack: UserStack;
};

/** Type for an individual class that is placed in a UserStack */
export type StackedClass = {
  __typename: 'StackedClass';
  pelotonClass: PelotonClass;
  pelotonClassId: Scalars['String']['output'];
  playOrder: Scalars['Int']['output'];
  stackId: Scalars['ID']['output'];
};

/** Error thrown on adding a class to a stack when that class already exists in the stack */
export type StackedClassAlreadyExistsError = Error &
  StackResponse & {
    __typename: 'StackedClassAlreadyExistsError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown when querying stacks */
export type StackedClassCannotBeAddedError = Error &
  StackResponse & {
    __typename: 'StackedClassCannotBeAddedError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown when adding a class to the user's stack violates a database constraint */
export type StackedClassConstraintViolationError = Error &
  StackResponse & {
    __typename: 'StackedClassConstraintViolationError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown when modifying a stack with a class that does not already exist in the user's stack */
export type StackedClassDoesNotExistInStackError = Error &
  StackResponse & {
    __typename: 'StackedClassDoesNotExistInStackError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown when attempting to add a class using an invalid pelotonClassId */
export type StackedClassInvalidPelotonClassIdError = Error &
  StackResponse & {
    __typename: 'StackedClassInvalidPelotonClassIdError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

/** Error thrown on adding a class to a stack when the maximum number of classes (10) already exist in the stack */
export type StackedClassLimitReachedError = Error &
  StackResponse & {
    __typename: 'StackedClassLimitReachedError';
    code: Scalars['String']['output'];
    message: Scalars['String']['output'];
    numClasses: Scalars['Int']['output'];
    totalTime: Scalars['Int']['output'];
    userStack: UserStack;
  };

export type StartClassInput = {
  deviceType: Scalars['String']['input'];
  /** Defaults to false if null */
  isHeartRateMonitorConnected?: InputMaybe<Scalars['Boolean']['input']>;
  joinToken: Scalars['String']['input'];
  streamUrlType?: InputMaybe<Scalars['String']['input']>;
  /**
   * Should always be sent if available.
   *         Optional for the case of guest users on activated devices.
   */
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  /** Defaults to false if null */
  willManuallyEndWorkout?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StartFreestyleWorkoutInput = {
  deviceType: Scalars['String']['input'];
  fitnessDiscipline: Scalars['String']['input'];
  /**  Optional for users if they want to set their own goals. */
  goal?: InputMaybe<FreestyleWorkoutGoalInput>;
  isDigital: Scalars['Boolean']['input'];
  /** Defaults to false if null */
  isHeartRateMonitorConnected?: InputMaybe<Scalars['Boolean']['input']>;
  isOutdoor: Scalars['Boolean']['input'];
  /**
   * Should always be sent if available.
   *         Optional for the case of guest users on activated devices.
   */
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  /** Defaults to false if null */
  willManuallyEndWorkout?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Returns Freestyle workout for user */
export type StartFreestyleWorkoutResponse =
  | StartFreestyleWorkoutResponseSuccess
  | SubscriptionIdNotFoundError
  | UserNotAuthorizedError;

/** Successful freestyle workout creation response from the service */
export type StartFreestyleWorkoutResponseSuccess = {
  __typename: 'StartFreestyleWorkoutResponseSuccess';
  id: Scalars['ID']['output'];
  startTime: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

/** Input for starting a Gym Workout */
export type StartGymWorkoutInput = {
  deviceType: Scalars['String']['input'];
  fitnessDiscipline?: InputMaybe<Scalars['String']['input']>;
  gymPlanId: Scalars['String']['input'];
  isDigital?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

/** Response for starting a gym workout */
export type StartGymWorkoutResponse = GymErrorResponse | StartGymWorkoutResponseSuccess;

/** Successful gym workout creation response */
export type StartGymWorkoutResponseSuccess = {
  __typename: 'StartGymWorkoutResponseSuccess';
  startTime: Scalars['DateTime']['output'];
  workout: GymWorkout;
};

export type StreakAwardedEventPayload = ActivityFeedEventPayload & {
  __typename: 'StreakAwardedEventPayload';
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /** @deprecated Use pelotonClass in PelotonClassFeedWorkout instead */
  pelotonClass?: Maybe<PelotonClass>;
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']['output']>;
  /** Streak Number for StreakAwardedEvent */
  streakNumber: Scalars['Int']['output'];
};

export type StreamablePlaylist = BasePlaylist & {
  __typename: 'StreamablePlaylist';
  artImage?: Maybe<Media>;
  id: Scalars['ID']['output'];
  isInClassMusicShown: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  playbackSongs: Array<PlaylistPlaybackSong>;
  previewArtImage?: Maybe<Media>;
  /** This URL will have an Akamai token appended to it to give the user permission to access the resource */
  stream: Media;
  streamId: Scalars['ID']['output'];
  topArtists: Array<Artist>;
};

/** Error thrown when subscription ID does not exist */
export type SubscriptionIdNotFoundError = {
  __typename: 'SubscriptionIdNotFoundError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** A tag that users can add to themselves. */
export type Tag = {
  __typename: 'Tag';
  /**
   * An active class that users with this tag are currently taking with the
   * specified joinToken.
   */
  activeClass: ActiveTagClass;
  /**
   * A paginated list of classes that users with this tag are currently taking.
   * sorted by classes with more users working out right now first. after is an
   * opaque cursor string referring to the end of the result page.
   */
  activeClasses: ActiveTagClassesPaginatedList;
  /** The assets that represent this tag. */
  assets: TagAssets;
  /** Whether the current tag already exists. */
  exists: Scalars['Boolean']['output'];
  /**
   * The number of users followed by current logged in user who have
   * this tag.
   */
  followingCount: Scalars['Int']['output'];
  /** Whether the current logged in user has added the tag. */
  hasAdded: Scalars['Boolean']['output'];
  /** The meta tag of this tag. */
  metaTag?: Maybe<MetaTag>;
  /** The case sensitive name of the tag. */
  name: Scalars['String']['output'];
  /** A list of classes that have been popular with users in this tag. */
  popularClasses: PopularTagClassesList;
  /** The total number of in progress workout. */
  totalInProgressWorkouts: Scalars['Int']['output'];
  /**
   * A paginated list of users who have this tag. sorted by username. with
   * users followed by the current logged in user first. after is an
   * opaque cursor string referring to the end of the result page.
   */
  users: UserPaginatedList;
};

/** A tag that users can add to themselves. */
export type TagActiveClassArgs = {
  joinToken: Scalars['JoinToken']['input'];
};

/** A tag that users can add to themselves. */
export type TagActiveClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: Scalars['Int']['input'];
};

/** A tag that users can add to themselves. */
export type TagPopularClassesArgs = {
  limit?: Scalars['Int']['input'];
};

/** A tag that users can add to themselves. */
export type TagUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: Scalars['Int']['input'];
};

export type TagAlreadyExistsError = Error & {
  __typename: 'TagAlreadyExistsError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Tag's assets */
export type TagAssets = {
  __typename: 'TagAssets';
  /** The row background image that represents this tag. */
  backgroundImage: Media;
  /** The tag details view background image. */
  detailBackgroundImage: Media;
};

/** A grouping of tags, such as the most popular tags. */
export type TagBrowseCategory = {
  __typename: 'TagBrowseCategory';
  /** The assets that represent this category. */
  assets: TagBrowseCategoryAssets;
  /** The name to use when displaying this category. */
  displayName: Scalars['String']['output'];
  /** The unique unchanging human-readable id for this category. */
  slug: TagBrowseCategorySlug;
  /**
   * A paginated list of tags in this category. The results for some categories
   * depend of the current logged in user. after is an opaque cursor string referring
   * to the end of the result page.
   */
  tags: TagPaginatedList;
};

/** A grouping of tags, such as the most popular tags. */
export type TagBrowseCategoryTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  limit?: Scalars['Int']['input'];
};

/** TagBrowseCategory's assets */
export type TagBrowseCategoryAssets = {
  __typename: 'TagBrowseCategoryAssets';
  /** The icon that represents this category. */
  icon: Media;
};

/** The possible values for tag browse category slug */
export enum TagBrowseCategorySlug {
  /** slug for Featured */
  Featured = 'FEATURED',
  /** slug for Friends */
  Friends = 'FRIENDS',
  /** slug for Popular */
  Popular = 'POPULAR',
  /** slug for Trending */
  Trending = 'TRENDING',
}

export type TagCapacityOverLimitError = Error & {
  __typename: 'TagCapacityOverLimitError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type TagDoesNotExistOnUserError = Error & {
  __typename: 'TagDoesNotExistOnUserError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** An element in a paginated Tag result set. */
export type TagEdge = {
  __typename: 'TagEdge';
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor']['output'];
  /** The Tag element. */
  node: Tag;
};

export type TagNameInvalidCharacterError = Error & {
  __typename: 'TagNameInvalidCharacterError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type TagNameLengthError = Error & {
  __typename: 'TagNameLengthError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type TagNameProhibitedError = Error & {
  __typename: 'TagNameProhibitedError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** A list of Tag results that can be paged over. */
export type TagPaginatedList = PaginatedList & {
  __typename: 'TagPaginatedList';
  /** The elements of the current page. */
  edges: Array<TagEdge>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

export type TargetMetrics = {
  __typename: 'TargetMetrics';
  metrics?: Maybe<Array<Metric>>;
  offsets: Offset;
  segmentType?: Maybe<SegmentTypes>;
};

export type TargetMetricsData = {
  __typename: 'TargetMetricsData';
  paceIntensities?: Maybe<Array<Maybe<PaceIntensity>>>;
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  totalExpectedOutput?: Maybe<TargetMetricsOutput>;
};

export type TargetMetricsOutput = {
  __typename: 'TargetMetricsOutput';
  expectedLowerOutput?: Maybe<Scalars['Int']['output']>;
  expectedUpperOutput?: Maybe<Scalars['Int']['output']>;
};

export type UnpauseWorkoutInput = {
  joinToken?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 timestamp */
  pauseEndTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** ISO-8601 timestamp */
  pauseStartTime?: InputMaybe<Scalars['DateTime']['input']>;
  streamUrlType?: InputMaybe<Scalars['String']['input']>;
  /** Defaults to true if null */
  userManuallyResumed?: InputMaybe<Scalars['Boolean']['input']>;
  workoutId: Scalars['String']['input'];
};

export type UnpauseWorkoutResponse = {
  __typename: 'UnpauseWorkoutResponse';
  /** Pause duration (in seconds) */
  duration: Scalars['Int']['output'];
  joinableClass?: Maybe<JoinableClass>;
  /** Amount of times paused */
  pauseCount: Scalars['Int']['output'];
  pauseInfo?: Maybe<PauseInfo>;
  /** Pause time remaining (in seconds) */
  pauseTimeRemaining: Scalars['Int']['output'];
};

export type UnreadNotifications = {
  __typename: 'UnreadNotifications';
  /** Number count of unread notifications for a user */
  count: Scalars['Int']['output'];
};

export type UnreadNotificationsResponse = ActivityFeedErrorResponse | UnreadNotifications;

/** A Peloton user */
export type User = {
  __typename: 'User';
  /** The assets that represent this user. */
  assets?: Maybe<UserAssets>;
  /** Whether the current logged in user follows this user. */
  followStatus: FollowStatus;
  /** list of user ids (sorted by username) that the current logged in user follows */
  followingList?: Maybe<FollowingUsersPaginatedList>;
  getInvitedUserStatus: InvitedUserStatus;
  /** The user's unique identifier. */
  id: Scalars['ID']['output'];
  /** is user's profile private */
  isProfilePrivate?: Maybe<Scalars['Boolean']['output']>;
  /** Location of the user. */
  location?: Maybe<Scalars['String']['output']>;
  /** Total workout counts of the user. */
  protectedFields?: Maybe<UserProtectedFieldsResult>;
  /** Remaining number of tag slots that the user can add. */
  remainingTagSlotCount: Scalars['Int']['output'];
  /**
   * returns user's RSVPed classes - including scheduled sessions, live and encore classes.
   * currently returns max 500 scheduled classes
   */
  scheduledClasses: ScheduledClassListResponse;
  /** User's all tags. */
  userTags: UserTags;
  /** The user's name to display. */
  username: Scalars['String']['output'];
};

/** A Peloton user */
export type UserFollowingListArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
};

/** A Peloton user */
export type UserGetInvitedUserStatusArgs = {
  inviteId?: InputMaybe<Scalars['ID']['input']>;
};

/** A Peloton user */
export type UserScheduledClassesArgs = {
  input: ScheduledClassesInput;
};

/** User's assets */
export type UserAssets = {
  __typename: 'UserAssets';
  /** The user's profile image. */
  image: Media;
};

/** An element in a paginated User result set. */
export type UserEdge = {
  __typename: 'UserEdge';
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor']['output'];
  /** The User element. */
  node: User;
};

export type UserInput = {
  /** ID of the user */
  userId: Scalars['ID']['input'];
  /** Username of the user */
  username: Scalars['String']['input'];
};

/** A leaderboard for an Odyssey level for a given user's workouts */
export type UserLeaderboardRequestInput = {
  /** Optional: If provided, filter leaderboard based on difficulty */
  difficulty?: InputMaybe<OdysseyDifficulty>;
  /**
   * Optional: If provided, will have extraWorkoutRank and extraLeaderboardEntry returned for easy access to given workout.
   *             This can be used to conveniently grab information for a specific workout without it needing to be on the returned leaderboard
   */
  extraWorkoutId?: InputMaybe<Scalars['ID']['input']>;
  /** Where to start the LB; note that this should not exceed 10k */
  from: Scalars['Int']['input'];
  levelId: Scalars['ID']['input'];
  /** How many values to fetch */
  size: Scalars['Int']['input'];
  /** Optional: Whether to sort user leaderboard by score or date descending */
  sortBy?: InputMaybe<UserLeaderboardSortBy>;
  /** Optional: difficulty: If provided, only shows workouts that started at this point or later */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** User to get leaderboard for */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum UserLeaderboardSortBy {
  Date = 'Date',
  Score = 'Score',
}

/** Error thrown when user is not authorized to perform an action */
export type UserNotAuthorizedError = {
  __typename: 'UserNotAuthorizedError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** A list of User results that can be paged over. */
export type UserPaginatedList = PaginatedList & {
  __typename: 'UserPaginatedList';
  /** The elements of the current page. */
  edges: Array<UserEdge>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
  /** The total number of results in the underlying result set. */
  totalCount: Scalars['Int']['output'];
};

export type UserPrivacyError = Error & {
  __typename: 'UserPrivacyError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** User fields that is protected by privacy controls */
export type UserProtectedFields = {
  __typename: 'UserProtectedFields';
  totalWorkoutCounts: Scalars['Int']['output'];
};

export type UserProtectedFieldsResult = UserPrivacyError | UserProtectedFields;

export type UserStack = {
  __typename: 'UserStack';
  stackId?: Maybe<Scalars['ID']['output']>;
  stackedClassList: Array<StackedClass>;
  userId: Scalars['ID']['output'];
};

/** A User's relationship with a tag. */
export type UserTag = {
  __typename: 'UserTag';
  /** True if this is the user's primary tag. */
  isPrimary: Scalars['Boolean']['output'];
  /** The tag. */
  tag: Tag;
};

/** User's all tags. include primary tag and all tags */
export type UserTags = {
  __typename: 'UserTags';
  /** All of user's tags and relationship with them. */
  allTags: Array<UserTag>;
  /** The user's primary tag, if they have one. */
  primary?: Maybe<Tag>;
};

export type VideoStream = {
  __typename: 'VideoStream';
  multiChannel?: Maybe<Media>;
  singleChannel?: Maybe<Media>;
  streamHistoryId?: Maybe<Scalars['String']['output']>;
};

export type VideoStreamOrError = ErrorVideoStream | ReadyVideoStream;

export enum Visibility {
  LinkManualPrivate = 'LINK_MANUAL_PRIVATE',
  LinkPrivate = 'LINK_PRIVATE',
  LinkPublic = 'LINK_PUBLIC',
}

export type Workout = {
  __typename: 'Workout';
  id: Scalars['ID']['output'];
};

export type WorkoutFinalizedEventPayload = ActivityFeedEventPayload & {
  __typename: 'WorkoutFinalizedEventPayload';
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /** Boolean to check if class is in the user's stack already */
  isClassInUserStack: Scalars['Boolean']['output'];
  /** @deprecated Use pelotonClass in PelotonClassFeedWorkout instead */
  pelotonClass?: Maybe<PelotonClass>;
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']['output']>;
  /** Enum that maps to workout metrics to be displayed on client */
  workoutMetricsType: WorkoutMetricsType;
};

export type WorkoutMetricDetails = {
  __typename: 'WorkoutMetricDetails';
  avgHeartRate?: Maybe<Scalars['Float']['output']>;
  avgPace?: Maybe<Scalars['Float']['output']>;
  avgSpeed?: Maybe<Scalars['Float']['output']>;
  calories?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  output?: Maybe<Scalars['Float']['output']>;
  striveScore?: Maybe<Scalars['Float']['output']>;
  zone1Score?: Maybe<Scalars['Float']['output']>;
  zone2Score?: Maybe<Scalars['Float']['output']>;
  zone3Score?: Maybe<Scalars['Float']['output']>;
  zone4Score?: Maybe<Scalars['Float']['output']>;
  zone5Score?: Maybe<Scalars['Float']['output']>;
};

export enum WorkoutMetricsType {
  CycleBike = 'CYCLE_BIKE',
  CycleDigital = 'CYCLE_DIGITAL',
  CycleFreestyle = 'CYCLE_FREESTYLE',
  Floor = 'FLOOR',
  Gym = 'GYM',
  JustRide = 'JUST_RIDE',
  JustRunWalk = 'JUST_RUN_WALK',
  JustWorkOutIndoor = 'JUST_WORK_OUT_INDOOR',
  Meditation = 'MEDITATION',
  Outdoor = 'OUTDOOR',
  RowDigital = 'ROW_DIGITAL',
  RowFreestyle = 'ROW_FREESTYLE',
  RowRower = 'ROW_ROWER',
  RunDigital = 'RUN_DIGITAL',
  RunFreestyle = 'RUN_FREESTYLE',
  RunTread = 'RUN_TREAD',
}

export type WorkoutOfTheDayDetails = {
  __typename: 'WorkoutOfTheDayDetails';
  class?: Maybe<OnDemandInstructorClass>;
  completedParticipants?: Maybe<Array<User>>;
  /** If completedStats is null, user has not completed today’s Circadian */
  completedStats?: Maybe<CompletedStats>;
  expiresAt: Scalars['Int']['output'];
  rideId: Scalars['ID']['output'];
  totalCompletedByCommunityToday: Scalars['Int']['output'];
  totalCompletedByUser: Scalars['Int']['output'];
};

export type WorkoutPostClassResponse = {
  __typename: 'WorkoutPostClassResponse';
  /** User's goals details */
  goalProgressDetails?: Maybe<GoalProgressDetails>;
  /** Next Class in program if workout was from a program */
  programClass?: Maybe<ProgramClass>;
  /** Next Class in users stack if workout was from a stack */
  stackedClass?: Maybe<StackedClass>;
  workoutDetails: WorkoutMetricDetails;
  /** Field indicating relevant metrics for workout details */
  workoutMetricsType: WorkoutMetricsType;
};

/** Returns a ScheduledClass or InviteDetails */
export type YourScheduleItem =
  | InviteDetails
  | InviteFriendsHttpException
  | InviteFriendsInvalidDataFormat
  | InviteFriendsItemNotFoundError
  | InviteFriendsNotAuthorized
  | ScheduledClass;

/** Returns list of Pending Invites and list of YourScheduleItems */
export type YourScheduleItemList = {
  __typename: 'YourScheduleItemList';
  /** user's pending invites ordered by ‘createdAt’ most recent */
  pendingInvites: PendingInvitesResponse;
  /** Returns a list of user's invites and scheduled classes ordered chronologically by scheduledStartTime */
  yourScheduleItems: Array<YourScheduleItem>;
};

/** Returns YourScheduleItemList or PendingInvitesResponse */
export type YourScheduleListResponse =
  | InviteFriendsBadRequest
  | InviteFriendsItemNotFoundError
  | InviteFriendsNotAuthorized
  | YourScheduleItemList;

export type ZoneScore = {
  __typename: 'ZoneScore';
  /** zone 1 HR score (aka heart_rate_z1_duration) */
  zone1?: Maybe<Scalars['Float']['output']>;
  zone2?: Maybe<Scalars['Float']['output']>;
  zone3?: Maybe<Scalars['Float']['output']>;
  zone4?: Maybe<Scalars['Float']['output']>;
  zone5?: Maybe<Scalars['Float']['output']>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<RefType extends Record<string, unknown>> = {
  AbandonOdysseyWorkoutResponse:
    | OdysseyInvalidWorkoutMutationError
    | OdysseyItemNotFoundError
    | OdysseyWorkoutSuccessResponse;
  ActivityFeedEventResponse:
    | ActivityFeedErrorResponse
    | (Omit<ActivityFeedEvent, 'payload'> & { payload: RefType['Payload'] })
    | ActivityFeedEventPaginationResponse;
  AddTagToUserResponse:
    | AddTagToUserSuccess
    | DoesNotExistError
    | TagAlreadyExistsError
    | TagCapacityOverLimitError
    | TagNameInvalidCharacterError
    | TagNameLengthError
    | TagNameProhibitedError;
  CompleteOdysseyWorkoutResponse:
    | OdysseyBadRequestError
    | OdysseyInvalidWorkoutMutationError
    | OdysseyItemNotFoundError
    | OdysseyWorkoutSuccessResponse;
  CreateOdysseyWorkoutResponse: OdysseyWorkoutSuccessResponse;
  DeletedEntertainmentChannelResponse:
    | DeletedEntertainmentChannelSuccess
    | EntityNotFoundError
    | PermissionError;
  EntertainmentChannelPresignedUrlQueryResponse:
    | EntertainmentChannelPresignedUrl
    | PermissionError;
  EntertainmentChannelWithTranslationsResponse:
    | AllEntertainmentChannelsWithTranslationsSuccess
    | EntertainmentChannelWithTranslationSuccess
    | EntityNotFoundError
    | PermissionError;
  EntertainmentChannelsQueryResponse: EntertainmentChannelPaginatedList | PermissionError;
  EventDetailsResponse: ActivityFeedErrorResponse | EventDetails;
  FilterOption:
    | GymBodyActivityGroupFilter
    | GymBooleanFilter
    | GymInstructorFilter
    | GymIntFilter
    | GymStringFilter;
  GymBookmarkResponse: GymErrorResponse | GymSuccessfulResponse;
  GymPlanResponse: GymErrorResponse | GymPlan | GymPlanPaginationResponse;
  HighFiveDetailsResponse: ActivityFeedErrorResponse | HighFiveDetails;
  InviteResponse:
    | (Omit<InviteDetails, 'getInvitedAcceptedFollowing' | 'getInvitedUsers'> & {
        getInvitedAcceptedFollowing: RefType['InvitedUsersPaginationResponse'];
        getInvitedUsers: RefType['InvitedUsersPaginationResponse'];
      })
    | InviteFriendsHttpException
    | InviteFriendsInvalidDataFormat
    | InviteFriendsInvitedUserSizeLimitExceeded
    | InviteFriendsItemNotFoundError
    | InviteFriendsNotAuthorized;
  InviteShareURLResponse:
    | InviteFriendsCreateShareLinkDisabledError
    | InviteFriendsInvalidDataFormat
    | InviteFriendsItemNotFoundError
    | InviteFriendsNoPermissionToCreateShareLinkError
    | ShareUrl;
  InvitedUsersPaginationResponse:
    | InviteFriendsHttpException
    | InviteFriendsNotAuthorized
    | InvitedUsersPaginatedList;
  LevelFiltersQueryResponse:
    | (Omit<LevelBrowseFiltersSuccessResponse, 'filters'> & {
        filters: Array<RefType['OdysseyLevelFilter']>;
      })
    | OdysseyBadRequestError;
  LevelListQueryResponse: LevelListSuccessResponse | OdysseyNotAuthorizedError;
  LevelQueryResponse: LevelSuccessResponse | OdysseyItemNotFoundError;
  MusicProvider: PlaylistMusicProvider;
  NotificationDetailsResponse: ActivityFeedErrorResponse | NotificationDetails;
  OccasionsResponse: OccasionsList;
  OdysseyBookmarkResponse:
    | OdysseyBookmarkSuccessResponse
    | OdysseyItemNotFoundError
    | OdysseyNotAuthorizedError;
  OdysseyConfigurationResponse:
    | OdysseyBikeConfiguration
    | OdysseyItemNotFoundError
    | OdysseyTreadConfiguration;
  OdysseyLeaderboardQueryResponse:
    | OdysseyLeaderboardInvalidRequest
    | OdysseyLeaderboardListSuccessResponse;
  OdysseyLevelFilter:
    | (Omit<OdysseyAccordionLevelFilter, 'options'> & {
        options: Array<RefType['OdysseyLevelFilterOption']>;
      })
    | (Omit<OdysseyGridLevelFilter, 'options'> & {
        options: Array<RefType['OdysseyLevelFilterOption']>;
      })
    | OdysseyToggleLevelFilter;
  OdysseyLevelFilterInfo:
    | OdysseyAccordionLevelFilterInfo
    | OdysseyGridLevelFilterInfo
    | OdysseyToggleLevelFilterInfo;
  OdysseyLevelFilterOption:
    | OdysseyBasicLevelFilterOption
    | OdysseyDurationLevelFilterOption
    | OdysseyPlayedLevelFilterOption
    | OdysseyStarsLevelFilterOption;
  OdysseyWorkoutQueryResponse:
    | OdysseyItemNotFoundError
    | OdysseyNotAuthorizedError
    | OdysseyWorkoutSuccessResponse;
  Payload:
    | AchievementAwardedEventPayload
    | StreakAwardedEventPayload
    | WorkoutFinalizedEventPayload;
  PelotonResult: NoClassPelotonForPlatformError | Peloton | PelotonNotCreatedError;
  PendingInvitesResponse: InviteFriendsItemNotFoundError | PendingInvites;
  ProgramProgressResponse:
    | ProgramProgressAlreadyExistsError
    | ProgramProgressNotFoundError
    | ProgramProgressResponseSuccess
    | UserNotAuthorizedError;
  RemoveTagFromUserResponse: DoesNotExistError | RemoveTagFromUserSuccess;
  ReplaceTagResponse:
    | DoesNotExistError
    | ReplaceTagSuccess
    | TagAlreadyExistsError
    | TagNameInvalidCharacterError
    | TagNameLengthError
    | TagNameProhibitedError;
  ScheduledClassListResponse: ScheduledClassList | UserPrivacyError;
  ScheduledClassResponse:
    | CannotScheduleClassError
    | ScheduledClass
    | ScheduledClassDoesNotExistError;
  SetTagAsPrimaryResponse: SetTagAsPrimarySuccess | TagDoesNotExistOnUserError;
  StartFreestyleWorkoutResponse:
    | StartFreestyleWorkoutResponseSuccess
    | SubscriptionIdNotFoundError
    | UserNotAuthorizedError;
  StartGymWorkoutResponse: GymErrorResponse | StartGymWorkoutResponseSuccess;
  UnreadNotificationsResponse: ActivityFeedErrorResponse | UnreadNotifications;
  UserProtectedFieldsResult: UserPrivacyError | UserProtectedFields;
  VideoStreamOrError: ErrorVideoStream | ReadyVideoStream;
  YourScheduleItem:
    | (Omit<InviteDetails, 'getInvitedAcceptedFollowing' | 'getInvitedUsers'> & {
        getInvitedAcceptedFollowing: RefType['InvitedUsersPaginationResponse'];
        getInvitedUsers: RefType['InvitedUsersPaginationResponse'];
      })
    | InviteFriendsHttpException
    | InviteFriendsInvalidDataFormat
    | InviteFriendsItemNotFoundError
    | InviteFriendsNotAuthorized
    | ScheduledClass;
  YourScheduleListResponse:
    | InviteFriendsBadRequest
    | InviteFriendsItemNotFoundError
    | InviteFriendsNotAuthorized
    | (Omit<YourScheduleItemList, 'pendingInvites' | 'yourScheduleItems'> & {
        pendingInvites: RefType['PendingInvitesResponse'];
        yourScheduleItems: Array<RefType['YourScheduleItem']>;
      });
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<RefType extends Record<string, unknown>> = {
  ActivityFeedEventPayload:
    | AchievementAwardedEventPayload
    | StreakAwardedEventPayload
    | WorkoutFinalizedEventPayload;
  BasePlaylist: Playlist | StreamablePlaylist;
  Error:
    | CannotScheduleClassError
    | DoesNotExistError
    | EntityNotFoundError
    | NoClassPelotonForPlatformError
    | PelotonNotCreatedError
    | PermissionError
    | ScheduledClassDoesNotExistError
    | StackConnectionError
    | StackDoesNotExist
    | StackError
    | StackPermissionError
    | StackedClassAlreadyExistsError
    | StackedClassCannotBeAddedError
    | StackedClassConstraintViolationError
    | StackedClassDoesNotExistInStackError
    | StackedClassInvalidPelotonClassIdError
    | StackedClassLimitReachedError
    | TagAlreadyExistsError
    | TagCapacityOverLimitError
    | TagDoesNotExistOnUserError
    | TagNameInvalidCharacterError
    | TagNameLengthError
    | TagNameProhibitedError
    | UserPrivacyError;
  FeedWorkout:
    | FreestyleFeedWorkout
    | GymFeedWorkout
    | OdysseyFeedWorkout
    | PelotonClassFeedWorkout;
  InviteFriendsError:
    | InviteFriendsBadRequest
    | InviteFriendsCreateShareLinkDisabledError
    | InviteFriendsHttpException
    | InviteFriendsInvalidDataFormat
    | InviteFriendsInvitedUserSizeLimitExceeded
    | InviteFriendsItemNotFoundError
    | InviteFriendsNoPermissionToCreateShareLinkError
    | InviteFriendsNotAuthorized;
  JoinError:
    | JoinAuthorizationError
    | JoinContentNotLiveError
    | JoinNoActiveSubscriptionError
    | JoinStreamLimitError;
  OdysseyError:
    | OdysseyBadRequestError
    | OdysseyInvalidWorkoutMutationError
    | OdysseyItemNotFoundError
    | OdysseyNotAuthorizedError;
  OdysseyLeaderboardGraphqlError: OdysseyLeaderboardInvalidRequest;
  PaginatedList:
    | ActiveTagClassesPaginatedList
    | ActivityFeedEventPaginationResponse
    | EntertainmentChannelPaginatedList
    | FollowingUsersPaginatedList
    | GymPlanPaginationResponse
    | TagPaginatedList
    | UserPaginatedList;
  PelotonClass:
    | LanebreakClass
    | (Omit<LiveClass, 'peloton'> & { peloton: RefType['PelotonResult'] })
    | OnDemandInstructorClass
    | (Omit<ScenicClass, 'musicProviders'> & {
        musicProviders: Array<Maybe<RefType['MusicProvider']>>;
      });
  RetryableError: JoinContentNotLiveError;
  StackResponse:
    | StackConnectionError
    | StackDoesNotExist
    | StackError
    | StackPermissionError
    | StackResponseSuccess
    | StackedClassAlreadyExistsError
    | StackedClassCannotBeAddedError
    | StackedClassConstraintViolationError
    | StackedClassDoesNotExistInStackError
    | StackedClassInvalidPelotonClassIdError
    | StackedClassLimitReachedError;
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AbandonOdysseyWorkoutResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AbandonOdysseyWorkoutResponse']
  >;
  AchievementAwardedEventPayload: ResolverTypeWrapper<AchievementAwardedEventPayload>;
  ActiveTagClass: ResolverTypeWrapper<ActiveTagClass>;
  ActiveTagClassEdge: ResolverTypeWrapper<ActiveTagClassEdge>;
  ActiveTagClassesPaginatedList: ResolverTypeWrapper<ActiveTagClassesPaginatedList>;
  ActiveTagUserEdge: ResolverTypeWrapper<ActiveTagUserEdge>;
  ActiveTagUserPaginatedList: ResolverTypeWrapper<ActiveTagUserPaginatedList>;
  ActiveWorkout: ResolverTypeWrapper<ActiveWorkout>;
  ActivityFeedErrorResponse: ResolverTypeWrapper<ActivityFeedErrorResponse>;
  ActivityFeedErrorType: ActivityFeedErrorType;
  ActivityFeedEvent: ResolverTypeWrapper<
    Omit<ActivityFeedEvent, 'payload'> & { payload: ResolversTypes['Payload'] }
  >;
  ActivityFeedEventEdge: ResolverTypeWrapper<ActivityFeedEventEdge>;
  ActivityFeedEventPaginationResponse: ResolverTypeWrapper<ActivityFeedEventPaginationResponse>;
  ActivityFeedEventPayload: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['ActivityFeedEventPayload']
  >;
  ActivityFeedEventResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ActivityFeedEventResponse']
  >;
  AddClassToStackInput: AddClassToStackInput;
  AddTagToUserInput: AddTagToUserInput;
  AddTagToUserResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AddTagToUserResponse']
  >;
  AddTagToUserSuccess: ResolverTypeWrapper<AddTagToUserSuccess>;
  Album: ResolverTypeWrapper<Album>;
  AllEntertainmentChannelsWithTranslationsSuccess: ResolverTypeWrapper<AllEntertainmentChannelsWithTranslationsSuccess>;
  Artist: ResolverTypeWrapper<Artist>;
  AwardedProgramAchievementTier: ResolverTypeWrapper<AwardedProgramAchievementTier>;
  BasePlaylist: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['BasePlaylist']
  >;
  BestRuns: ResolverTypeWrapper<BestRuns>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CannotScheduleClassError: ResolverTypeWrapper<CannotScheduleClassError>;
  Captions: ResolverTypeWrapper<Captions>;
  ClassAssets: ResolverTypeWrapper<ClassAssets>;
  ClassSegment: ResolverTypeWrapper<ClassSegment>;
  ClassSegmentMetric: ClassSegmentMetric;
  ClassSubsegment: ResolverTypeWrapper<ClassSubsegment>;
  ClassTimeline: ResolverTypeWrapper<ClassTimeline>;
  ClassTopTag: ResolverTypeWrapper<ClassTopTag>;
  ClassType: ResolverTypeWrapper<ClassType>;
  Color: ResolverTypeWrapper<Color>;
  CompleteOdysseyWorkoutInput: CompleteOdysseyWorkoutInput;
  CompleteOdysseyWorkoutResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CompleteOdysseyWorkoutResponse']
  >;
  CompletedStats: ResolverTypeWrapper<CompletedStats>;
  ContentFormat: ContentFormat;
  CreateLiveInviteInput: CreateLiveInviteInput;
  CreateNewOnDemandInviteInput: CreateNewOnDemandInviteInput;
  CreateOdysseyWorkoutInput: CreateOdysseyWorkoutInput;
  CreateOdysseyWorkoutResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['CreateOdysseyWorkoutResponse']
  >;
  CreateScheduledOnDemandInviteInput: CreateScheduledOnDemandInviteInput;
  Cursor: ResolverTypeWrapper<Scalars['Cursor']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DeletedEntertainmentChannel: ResolverTypeWrapper<DeletedEntertainmentChannel>;
  DeletedEntertainmentChannelResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['DeletedEntertainmentChannelResponse']
  >;
  DeletedEntertainmentChannelSuccess: ResolverTypeWrapper<DeletedEntertainmentChannelSuccess>;
  DifficultyLevel: ResolverTypeWrapper<DifficultyLevel>;
  DifficultyLevelSlug: DifficultyLevelSlug;
  DisplayTag: ResolverTypeWrapper<DisplayTag>;
  DistanceUnit: DistanceUnit;
  DoesNotExistError: ResolverTypeWrapper<DoesNotExistError>;
  EntertainmentChannel: ResolverTypeWrapper<EntertainmentChannel>;
  EntertainmentChannelPaginatedList: ResolverTypeWrapper<EntertainmentChannelPaginatedList>;
  EntertainmentChannelPresignedUrl: ResolverTypeWrapper<EntertainmentChannelPresignedUrl>;
  EntertainmentChannelPresignedUrlQueryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['EntertainmentChannelPresignedUrlQueryResponse']
  >;
  EntertainmentChannelTranslation: ResolverTypeWrapper<EntertainmentChannelTranslation>;
  EntertainmentChannelTranslationInput: EntertainmentChannelTranslationInput;
  EntertainmentChannelWithTranslationSuccess: ResolverTypeWrapper<EntertainmentChannelWithTranslationSuccess>;
  EntertainmentChannelWithTranslations: ResolverTypeWrapper<EntertainmentChannelWithTranslations>;
  EntertainmentChannelWithTranslationsInput: EntertainmentChannelWithTranslationsInput;
  EntertainmentChannelWithTranslationsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['EntertainmentChannelWithTranslationsResponse']
  >;
  EntertainmentChannelsQueryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['EntertainmentChannelsQueryResponse']
  >;
  EntityNotFoundError: ResolverTypeWrapper<EntityNotFoundError>;
  Equipment: ResolverTypeWrapper<Equipment>;
  Error: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Error']>;
  ErrorVideoStream: ResolverTypeWrapper<ErrorVideoStream>;
  EventDetails: ResolverTypeWrapper<EventDetails>;
  EventDetailsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['EventDetailsResponse']
  >;
  EventType: EventType;
  FeedEventType: FeedEventType;
  FeedPrivacy: FeedPrivacy;
  FeedWorkout: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['FeedWorkout']
  >;
  FilterOption: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['FilterOption']>;
  FitnessDiscipline: ResolverTypeWrapper<FitnessDiscipline>;
  FitnessDisciplineSlug: FitnessDisciplineSlug;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FollowStatus: FollowStatus;
  FollowingUsersPaginatedList: ResolverTypeWrapper<FollowingUsersPaginatedList>;
  FreestyleFeedWorkout: ResolverTypeWrapper<FreestyleFeedWorkout>;
  FreestyleMetadata: ResolverTypeWrapper<
    Omit<FreestyleMetadata, 'musicProviders'> & {
      musicProviders: Array<ResolversTypes['MusicProvider']>;
    }
  >;
  FreestyleWorkoutGoalInput: FreestyleWorkoutGoalInput;
  FreestyleWorkoutGoalType: FreestyleWorkoutGoalType;
  FreestyleWorkoutGoalUnit: FreestyleWorkoutGoalUnit;
  GoalFrequency: GoalFrequency;
  GoalProgress: ResolverTypeWrapper<GoalProgress>;
  GoalProgressDetails: ResolverTypeWrapper<GoalProgressDetails>;
  GoalType: GoalType;
  GroupTags: ResolverTypeWrapper<GroupTags>;
  GymBodyActivityGroupFilter: ResolverTypeWrapper<GymBodyActivityGroupFilter>;
  GymBookmarkInput: GymBookmarkInput;
  GymBookmarkResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GymBookmarkResponse']
  >;
  GymBooleanFilter: ResolverTypeWrapper<GymBooleanFilter>;
  GymEquipment: ResolverTypeWrapper<GymEquipment>;
  GymErrorResponse: ResolverTypeWrapper<GymErrorResponse>;
  GymErrorType: GymErrorType;
  GymFeedWorkout: ResolverTypeWrapper<GymFeedWorkout>;
  GymFilterCategory: ResolverTypeWrapper<
    Omit<GymFilterCategory, 'filters'> & {
      filters: Array<ResolversTypes['FilterOption']>;
    }
  >;
  GymInstructorFilter: ResolverTypeWrapper<GymInstructorFilter>;
  GymIntFilter: ResolverTypeWrapper<GymIntFilter>;
  GymMovement: ResolverTypeWrapper<GymMovement>;
  GymMovementGroup: ResolverTypeWrapper<GymMovementGroup>;
  GymPlan: ResolverTypeWrapper<GymPlan>;
  GymPlanEdge: ResolverTypeWrapper<GymPlanEdge>;
  GymPlanFilterChip: ResolverTypeWrapper<GymPlanFilterChip>;
  GymPlanFilterInput: GymPlanFilterInput;
  GymPlanPaginationResponse: ResolverTypeWrapper<GymPlanPaginationResponse>;
  GymPlanQueryFilterOptions: ResolverTypeWrapper<GymPlanQueryFilterOptions>;
  GymPlanQueryFiltersInput: GymPlanQueryFiltersInput;
  GymPlanResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GymPlanResponse']
  >;
  GymStringFilter: ResolverTypeWrapper<GymStringFilter>;
  GymSuccessfulResponse: ResolverTypeWrapper<GymSuccessfulResponse>;
  GymWorkout: ResolverTypeWrapper<GymWorkout>;
  HeroImageAssets: ResolverTypeWrapper<HeroImageAssets>;
  HighFiveDetails: ResolverTypeWrapper<HighFiveDetails>;
  HighFiveDetailsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['HighFiveDetailsResponse']
  >;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IconDetails: ResolverTypeWrapper<IconDetails>;
  Instructor: ResolverTypeWrapper<Instructor>;
  InstructorAssets: ResolverTypeWrapper<InstructorAssets>;
  InstructorCue: ResolverTypeWrapper<InstructorCue>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  InviteDetails: ResolverTypeWrapper<
    Omit<InviteDetails, 'getInvitedAcceptedFollowing' | 'getInvitedUsers'> & {
      getInvitedAcceptedFollowing: ResolversTypes['InvitedUsersPaginationResponse'];
      getInvitedUsers: ResolversTypes['InvitedUsersPaginationResponse'];
    }
  >;
  InviteFriendsBadRequest: ResolverTypeWrapper<InviteFriendsBadRequest>;
  InviteFriendsCreateShareLinkDisabledError: ResolverTypeWrapper<InviteFriendsCreateShareLinkDisabledError>;
  InviteFriendsError: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['InviteFriendsError']
  >;
  InviteFriendsHttpException: ResolverTypeWrapper<InviteFriendsHttpException>;
  InviteFriendsInvalidDataFormat: ResolverTypeWrapper<InviteFriendsInvalidDataFormat>;
  InviteFriendsInvitedUserSizeLimitExceeded: ResolverTypeWrapper<InviteFriendsInvitedUserSizeLimitExceeded>;
  InviteFriendsItemNotFoundError: ResolverTypeWrapper<InviteFriendsItemNotFoundError>;
  InviteFriendsNoPermissionToCreateShareLinkError: ResolverTypeWrapper<InviteFriendsNoPermissionToCreateShareLinkError>;
  InviteFriendsNotAuthorized: ResolverTypeWrapper<InviteFriendsNotAuthorized>;
  InviteResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['InviteResponse']
  >;
  InviteShareURLResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['InviteShareURLResponse']
  >;
  InviteStatus: InviteStatus;
  InviteUser: ResolverTypeWrapper<InviteUser>;
  InviteUserEdge: ResolverTypeWrapper<InviteUserEdge>;
  InviteUsersToInviteInput: InviteUsersToInviteInput;
  InvitedUserStatus: InvitedUserStatus;
  InvitedUsersPaginatedList: ResolverTypeWrapper<InvitedUsersPaginatedList>;
  InvitedUsersPaginationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['InvitedUsersPaginationResponse']
  >;
  JoinAuthorizationError: ResolverTypeWrapper<JoinAuthorizationError>;
  JoinContentNotLiveError: ResolverTypeWrapper<JoinContentNotLiveError>;
  JoinError: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['JoinError']>;
  JoinNoActiveSubscriptionError: ResolverTypeWrapper<JoinNoActiveSubscriptionError>;
  JoinStreamLimitError: ResolverTypeWrapper<JoinStreamLimitError>;
  JoinToken: ResolverTypeWrapper<Scalars['JoinToken']['output']>;
  JoinableClass: ResolverTypeWrapper<
    Omit<JoinableClass, 'videoStreamOrError'> & {
      videoStreamOrError: ResolversTypes['VideoStreamOrError'];
    }
  >;
  LanebreakClass: ResolverTypeWrapper<LanebreakClass>;
  LeaderboardEntry: ResolverTypeWrapper<LeaderboardEntry>;
  LeaderboardRequestInput: LeaderboardRequestInput;
  LeaderboardWorkoutDetails: ResolverTypeWrapper<LeaderboardWorkoutDetails>;
  Level: ResolverTypeWrapper<
    Omit<Level, 'musicProvider'> & {
      musicProvider?: Maybe<ResolversTypes['MusicProvider']>;
    }
  >;
  LevelAvailability: LevelAvailability;
  LevelBrowseFiltersSuccessResponse: ResolverTypeWrapper<
    Omit<LevelBrowseFiltersSuccessResponse, 'filters'> & {
      filters: Array<ResolversTypes['OdysseyLevelFilter']>;
    }
  >;
  LevelFiltersQueryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['LevelFiltersQueryResponse']
  >;
  LevelListQueryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['LevelListQueryResponse']
  >;
  LevelListSuccessResponse: ResolverTypeWrapper<LevelListSuccessResponse>;
  LevelQueryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['LevelQueryResponse']
  >;
  LevelSuccessResponse: ResolverTypeWrapper<LevelSuccessResponse>;
  LevelTheme: ResolverTypeWrapper<LevelTheme>;
  LiveClass: ResolverTypeWrapper<
    Omit<LiveClass, 'peloton'> & { peloton: ResolversTypes['PelotonResult'] }
  >;
  LiveClassCategory: LiveClassCategory;
  Locale: ResolverTypeWrapper<Locale>;
  LocaleCode: LocaleCode;
  Media: ResolverTypeWrapper<Media>;
  MetaTag: ResolverTypeWrapper<MetaTag>;
  Metric: ResolverTypeWrapper<Metric>;
  MetricName: MetricName;
  ModifyStackInput: ModifyStackInput;
  Movement: ResolverTypeWrapper<Movement>;
  MovementMuscleGroup: ResolverTypeWrapper<MovementMuscleGroup>;
  MovementPattern: MovementPattern;
  MovementVideo: ResolverTypeWrapper<MovementVideo>;
  MovementVideoType: MovementVideoType;
  MuscleGroupScore: ResolverTypeWrapper<MuscleGroupScore>;
  MusicProvider: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['MusicProvider']
  >;
  Mutation: ResolverTypeWrapper<{}>;
  NoClassPelotonForPlatformError: ResolverTypeWrapper<NoClassPelotonForPlatformError>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationDetails: ResolverTypeWrapper<NotificationDetails>;
  NotificationDetailsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['NotificationDetailsResponse']
  >;
  Occasion: ResolverTypeWrapper<Occasion>;
  OccasionAssets: ResolverTypeWrapper<OccasionAssets>;
  OccasionsList: ResolverTypeWrapper<OccasionsList>;
  OccasionsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OccasionsResponse']
  >;
  OdysseyAccordionLevelFilter: ResolverTypeWrapper<
    Omit<OdysseyAccordionLevelFilter, 'options'> & {
      options: Array<ResolversTypes['OdysseyLevelFilterOption']>;
    }
  >;
  OdysseyAccordionLevelFilterInfo: ResolverTypeWrapper<OdysseyAccordionLevelFilterInfo>;
  OdysseyBadRequestError: ResolverTypeWrapper<OdysseyBadRequestError>;
  OdysseyBasicLevelFilterOption: ResolverTypeWrapper<OdysseyBasicLevelFilterOption>;
  OdysseyBikeCompletionInput: OdysseyBikeCompletionInput;
  OdysseyBikeConfiguration: ResolverTypeWrapper<OdysseyBikeConfiguration>;
  OdysseyBikeDifficultyConfiguration: ResolverTypeWrapper<OdysseyBikeDifficultyConfiguration>;
  OdysseyBikeLaneConfiguration: ResolverTypeWrapper<OdysseyBikeLaneConfiguration>;
  OdysseyBikeScoringConfiguration: ResolverTypeWrapper<OdysseyBikeScoringConfiguration>;
  OdysseyBookmarkInput: OdysseyBookmarkInput;
  OdysseyBookmarkResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OdysseyBookmarkResponse']
  >;
  OdysseyBookmarkSuccessResponse: ResolverTypeWrapper<OdysseyBookmarkSuccessResponse>;
  OdysseyConfigurationResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OdysseyConfigurationResponse']
  >;
  OdysseyDifficulty: OdysseyDifficulty;
  OdysseyDifficultyMetadata: ResolverTypeWrapper<OdysseyDifficultyMetadata>;
  OdysseyDurationLevelFilterOption: ResolverTypeWrapper<OdysseyDurationLevelFilterOption>;
  OdysseyError: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OdysseyError']
  >;
  OdysseyFeedWorkout: ResolverTypeWrapper<OdysseyFeedWorkout>;
  OdysseyGridLevelFilter: ResolverTypeWrapper<
    Omit<OdysseyGridLevelFilter, 'options'> & {
      options: Array<ResolversTypes['OdysseyLevelFilterOption']>;
    }
  >;
  OdysseyGridLevelFilterInfo: ResolverTypeWrapper<OdysseyGridLevelFilterInfo>;
  OdysseyIcon: ResolverTypeWrapper<OdysseyIcon>;
  OdysseyInvalidWorkoutMutationError: ResolverTypeWrapper<OdysseyInvalidWorkoutMutationError>;
  OdysseyItemNotFoundError: ResolverTypeWrapper<OdysseyItemNotFoundError>;
  OdysseyLeaderboard: ResolverTypeWrapper<OdysseyLeaderboard>;
  OdysseyLeaderboardGraphqlError: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['OdysseyLeaderboardGraphqlError']
  >;
  OdysseyLeaderboardInvalidRequest: ResolverTypeWrapper<OdysseyLeaderboardInvalidRequest>;
  OdysseyLeaderboardListSuccessResponse: ResolverTypeWrapper<OdysseyLeaderboardListSuccessResponse>;
  OdysseyLeaderboardQueryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OdysseyLeaderboardQueryResponse']
  >;
  OdysseyLeaderboardSortBy: OdysseyLeaderboardSortBy;
  OdysseyLevelAssets: ResolverTypeWrapper<OdysseyLevelAssets>;
  OdysseyLevelBrowseFilter: ResolverTypeWrapper<
    Omit<OdysseyLevelBrowseFilter, 'info' | 'options'> & {
      info: ResolversTypes['OdysseyLevelFilterInfo'];
      options: Array<ResolversTypes['OdysseyLevelFilterOption']>;
    }
  >;
  OdysseyLevelDifficultyDetail: ResolverTypeWrapper<OdysseyLevelDifficultyDetail>;
  OdysseyLevelFilter: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OdysseyLevelFilter']
  >;
  OdysseyLevelFilterInfo: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OdysseyLevelFilterInfo']
  >;
  OdysseyLevelFilterOption: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OdysseyLevelFilterOption']
  >;
  OdysseyLevelMetric: ResolverTypeWrapper<OdysseyLevelMetric>;
  OdysseyLevelMetricName: OdysseyLevelMetricName;
  OdysseyLevelOffset: ResolverTypeWrapper<OdysseyLevelOffset>;
  OdysseyLevelPaceIntensity: ResolverTypeWrapper<OdysseyLevelPaceIntensity>;
  OdysseyLevelPaceLevel: ResolverTypeWrapper<OdysseyLevelPaceLevel>;
  OdysseyLevelSegmentTypes: OdysseyLevelSegmentTypes;
  OdysseyLevelTargetMetrics: ResolverTypeWrapper<OdysseyLevelTargetMetrics>;
  OdysseyLevelTargetMetricsData: ResolverTypeWrapper<OdysseyLevelTargetMetricsData>;
  OdysseyLevelTargetMetricsOutput: ResolverTypeWrapper<OdysseyLevelTargetMetricsOutput>;
  OdysseyNotAuthorizedError: ResolverTypeWrapper<OdysseyNotAuthorizedError>;
  OdysseyPlayedLevelFilterOption: ResolverTypeWrapper<OdysseyPlayedLevelFilterOption>;
  OdysseyStarThreshold: ResolverTypeWrapper<OdysseyStarThreshold>;
  OdysseyStarsLevelFilterOption: ResolverTypeWrapper<OdysseyStarsLevelFilterOption>;
  OdysseyToggleLevelFilter: ResolverTypeWrapper<OdysseyToggleLevelFilter>;
  OdysseyToggleLevelFilterInfo: ResolverTypeWrapper<OdysseyToggleLevelFilterInfo>;
  OdysseyTreadCompletionInput: OdysseyTreadCompletionInput;
  OdysseyTreadConfiguration: ResolverTypeWrapper<OdysseyTreadConfiguration>;
  OdysseyTreadDifficultyConfiguration: ResolverTypeWrapper<OdysseyTreadDifficultyConfiguration>;
  OdysseyTreadDifficultyIntensity: ResolverTypeWrapper<OdysseyTreadDifficultyIntensity>;
  OdysseyTreadLaneConfiguration: ResolverTypeWrapper<OdysseyTreadLaneConfiguration>;
  OdysseyTreadScoringConfiguration: ResolverTypeWrapper<OdysseyTreadScoringConfiguration>;
  OdysseyWorkout: ResolverTypeWrapper<OdysseyWorkout>;
  OdysseyWorkoutQueryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['OdysseyWorkoutQueryResponse']
  >;
  OdysseyWorkoutSuccessResponse: ResolverTypeWrapper<OdysseyWorkoutSuccessResponse>;
  Offset: ResolverTypeWrapper<Offset>;
  OffsetsCue: ResolverTypeWrapper<OffsetsCue>;
  OnDemandInstructorClass: ResolverTypeWrapper<OnDemandInstructorClass>;
  PaceIntensity: ResolverTypeWrapper<PaceIntensity>;
  PaceLevel: ResolverTypeWrapper<PaceLevel>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaginatedList: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['PaginatedList']
  >;
  PauseInfo: ResolverTypeWrapper<PauseInfo>;
  PauseWorkoutInput: PauseWorkoutInput;
  Payload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Payload']>;
  Peloton: ResolverTypeWrapper<Peloton>;
  PelotonClass: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['PelotonClass']
  >;
  PelotonClassFeedWorkout: ResolverTypeWrapper<PelotonClassFeedWorkout>;
  PelotonNotCreatedError: ResolverTypeWrapper<PelotonNotCreatedError>;
  PelotonResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['PelotonResult']
  >;
  PendingInvites: ResolverTypeWrapper<PendingInvites>;
  PendingInvitesResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['PendingInvitesResponse']
  >;
  PermissionError: ResolverTypeWrapper<PermissionError>;
  PlayClassFromStackInput: PlayClassFromStackInput;
  Playlist: ResolverTypeWrapper<Playlist>;
  PlaylistMusicProvider: ResolverTypeWrapper<PlaylistMusicProvider>;
  PlaylistPlaybackSong: ResolverTypeWrapper<PlaylistPlaybackSong>;
  PopularTagClass: ResolverTypeWrapper<PopularTagClass>;
  PopularTagClassesList: ResolverTypeWrapper<PopularTagClassesList>;
  Program: ResolverTypeWrapper<Program>;
  ProgramAchievementAssets: ResolverTypeWrapper<ProgramAchievementAssets>;
  ProgramAchievementTier: ResolverTypeWrapper<ProgramAchievementTier>;
  ProgramAssets: ResolverTypeWrapper<ProgramAssets>;
  ProgramClass: ResolverTypeWrapper<ProgramClass>;
  ProgramClassProgress: ResolverTypeWrapper<ProgramClassProgress>;
  ProgramClassProgressStatus: ProgramClassProgressStatus;
  ProgramDay: ResolverTypeWrapper<ProgramDay>;
  ProgramFiltersInput: ProgramFiltersInput;
  ProgramFitnessDiscipline: ResolverTypeWrapper<ProgramFitnessDiscipline>;
  ProgramMetaData: ResolverTypeWrapper<ProgramMetaData>;
  ProgramParamsInput: ProgramParamsInput;
  ProgramProgress: ResolverTypeWrapper<ProgramProgress>;
  ProgramProgressAlreadyExistsError: ResolverTypeWrapper<ProgramProgressAlreadyExistsError>;
  ProgramProgressNotFoundError: ResolverTypeWrapper<ProgramProgressNotFoundError>;
  ProgramProgressResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ProgramProgressResponse']
  >;
  ProgramProgressResponseSuccess: ResolverTypeWrapper<ProgramProgressResponseSuccess>;
  ProgramSchedule: ResolverTypeWrapper<ProgramSchedule>;
  ProgramSortingInput: ProgramSortingInput;
  ProgramWeek: ResolverTypeWrapper<ProgramWeek>;
  ProgramWeekProgress: ResolverTypeWrapper<ProgramWeekProgress>;
  ProgramWeekProgressStatus: ProgramWeekProgressStatus;
  Query: ResolverTypeWrapper<{}>;
  RangeCue: ResolverTypeWrapper<RangeCue>;
  ReadyVideoStream: ResolverTypeWrapper<ReadyVideoStream>;
  RemoveTagFromUserInput: RemoveTagFromUserInput;
  RemoveTagFromUserResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['RemoveTagFromUserResponse']
  >;
  RemoveTagFromUserSuccess: ResolverTypeWrapper<RemoveTagFromUserSuccess>;
  ReplaceTagInput: ReplaceTagInput;
  ReplaceTagResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ReplaceTagResponse']
  >;
  ReplaceTagSuccess: ResolverTypeWrapper<ReplaceTagSuccess>;
  RescheduleClassInput: RescheduleClassInput;
  RetryableError: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['RetryableError']
  >;
  ScenicClass: ResolverTypeWrapper<
    Omit<ScenicClass, 'musicProviders'> & {
      musicProviders: Array<Maybe<ResolversTypes['MusicProvider']>>;
    }
  >;
  ScheduleSource: ScheduleSource;
  ScheduledClass: ResolverTypeWrapper<ScheduledClass>;
  ScheduledClassDoesNotExistError: ResolverTypeWrapper<ScheduledClassDoesNotExistError>;
  ScheduledClassInput: ScheduledClassInput;
  ScheduledClassList: ResolverTypeWrapper<ScheduledClassList>;
  ScheduledClassListResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ScheduledClassListResponse']
  >;
  ScheduledClassResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ScheduledClassResponse']
  >;
  ScheduledClassesInput: ScheduledClassesInput;
  SearchTagInput: SearchTagInput;
  SegmentTypes: SegmentTypes;
  SessionClass: ResolverTypeWrapper<SessionClass>;
  SessionInput: SessionInput;
  SetTagAsPrimaryInput: SetTagAsPrimaryInput;
  SetTagAsPrimaryResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SetTagAsPrimaryResponse']
  >;
  SetTagAsPrimarySuccess: ResolverTypeWrapper<SetTagAsPrimarySuccess>;
  ShareURL: ResolverTypeWrapper<ShareUrl>;
  Song: ResolverTypeWrapper<Song>;
  StackConnectionError: ResolverTypeWrapper<StackConnectionError>;
  StackDoesNotExist: ResolverTypeWrapper<StackDoesNotExist>;
  StackError: ResolverTypeWrapper<StackError>;
  StackPermissionError: ResolverTypeWrapper<StackPermissionError>;
  StackResponse: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['StackResponse']
  >;
  StackResponseSuccess: ResolverTypeWrapper<StackResponseSuccess>;
  StackedClass: ResolverTypeWrapper<StackedClass>;
  StackedClassAlreadyExistsError: ResolverTypeWrapper<StackedClassAlreadyExistsError>;
  StackedClassCannotBeAddedError: ResolverTypeWrapper<StackedClassCannotBeAddedError>;
  StackedClassConstraintViolationError: ResolverTypeWrapper<StackedClassConstraintViolationError>;
  StackedClassDoesNotExistInStackError: ResolverTypeWrapper<StackedClassDoesNotExistInStackError>;
  StackedClassInvalidPelotonClassIdError: ResolverTypeWrapper<StackedClassInvalidPelotonClassIdError>;
  StackedClassLimitReachedError: ResolverTypeWrapper<StackedClassLimitReachedError>;
  StartClassInput: StartClassInput;
  StartFreestyleWorkoutInput: StartFreestyleWorkoutInput;
  StartFreestyleWorkoutResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['StartFreestyleWorkoutResponse']
  >;
  StartFreestyleWorkoutResponseSuccess: ResolverTypeWrapper<StartFreestyleWorkoutResponseSuccess>;
  StartGymWorkoutInput: StartGymWorkoutInput;
  StartGymWorkoutResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['StartGymWorkoutResponse']
  >;
  StartGymWorkoutResponseSuccess: ResolverTypeWrapper<StartGymWorkoutResponseSuccess>;
  StreakAwardedEventPayload: ResolverTypeWrapper<StreakAwardedEventPayload>;
  StreamablePlaylist: ResolverTypeWrapper<StreamablePlaylist>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SubscriptionIdNotFoundError: ResolverTypeWrapper<SubscriptionIdNotFoundError>;
  Tag: ResolverTypeWrapper<Tag>;
  TagAlreadyExistsError: ResolverTypeWrapper<TagAlreadyExistsError>;
  TagAssets: ResolverTypeWrapper<TagAssets>;
  TagBrowseCategory: ResolverTypeWrapper<TagBrowseCategory>;
  TagBrowseCategoryAssets: ResolverTypeWrapper<TagBrowseCategoryAssets>;
  TagBrowseCategorySlug: TagBrowseCategorySlug;
  TagCapacityOverLimitError: ResolverTypeWrapper<TagCapacityOverLimitError>;
  TagDoesNotExistOnUserError: ResolverTypeWrapper<TagDoesNotExistOnUserError>;
  TagEdge: ResolverTypeWrapper<TagEdge>;
  TagNameInvalidCharacterError: ResolverTypeWrapper<TagNameInvalidCharacterError>;
  TagNameLengthError: ResolverTypeWrapper<TagNameLengthError>;
  TagNameProhibitedError: ResolverTypeWrapper<TagNameProhibitedError>;
  TagPaginatedList: ResolverTypeWrapper<TagPaginatedList>;
  TargetMetrics: ResolverTypeWrapper<TargetMetrics>;
  TargetMetricsData: ResolverTypeWrapper<TargetMetricsData>;
  TargetMetricsOutput: ResolverTypeWrapper<TargetMetricsOutput>;
  URL: ResolverTypeWrapper<Scalars['URL']['output']>;
  UnpauseWorkoutInput: UnpauseWorkoutInput;
  UnpauseWorkoutResponse: ResolverTypeWrapper<UnpauseWorkoutResponse>;
  UnreadNotifications: ResolverTypeWrapper<UnreadNotifications>;
  UnreadNotificationsResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UnreadNotificationsResponse']
  >;
  User: ResolverTypeWrapper<
    Omit<User, 'protectedFields' | 'scheduledClasses'> & {
      protectedFields?: Maybe<ResolversTypes['UserProtectedFieldsResult']>;
      scheduledClasses: ResolversTypes['ScheduledClassListResponse'];
    }
  >;
  UserAssets: ResolverTypeWrapper<UserAssets>;
  UserEdge: ResolverTypeWrapper<UserEdge>;
  UserInput: UserInput;
  UserLeaderboardRequestInput: UserLeaderboardRequestInput;
  UserLeaderboardSortBy: UserLeaderboardSortBy;
  UserNotAuthorizedError: ResolverTypeWrapper<UserNotAuthorizedError>;
  UserPaginatedList: ResolverTypeWrapper<UserPaginatedList>;
  UserPrivacyError: ResolverTypeWrapper<UserPrivacyError>;
  UserProtectedFields: ResolverTypeWrapper<UserProtectedFields>;
  UserProtectedFieldsResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['UserProtectedFieldsResult']
  >;
  UserStack: ResolverTypeWrapper<UserStack>;
  UserTag: ResolverTypeWrapper<UserTag>;
  UserTags: ResolverTypeWrapper<UserTags>;
  VideoStream: ResolverTypeWrapper<VideoStream>;
  VideoStreamOrError: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['VideoStreamOrError']
  >;
  Visibility: Visibility;
  Workout: ResolverTypeWrapper<Workout>;
  WorkoutFinalizedEventPayload: ResolverTypeWrapper<WorkoutFinalizedEventPayload>;
  WorkoutMetricDetails: ResolverTypeWrapper<WorkoutMetricDetails>;
  WorkoutMetricsType: WorkoutMetricsType;
  WorkoutOfTheDayDetails: ResolverTypeWrapper<WorkoutOfTheDayDetails>;
  WorkoutPostClassResponse: ResolverTypeWrapper<WorkoutPostClassResponse>;
  YourScheduleItem: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['YourScheduleItem']
  >;
  YourScheduleItemList: ResolverTypeWrapper<
    Omit<YourScheduleItemList, 'pendingInvites' | 'yourScheduleItems'> & {
      pendingInvites: ResolversTypes['PendingInvitesResponse'];
      yourScheduleItems: Array<ResolversTypes['YourScheduleItem']>;
    }
  >;
  YourScheduleListResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['YourScheduleListResponse']
  >;
  ZoneScore: ResolverTypeWrapper<ZoneScore>;
  _FieldSet: ResolverTypeWrapper<Scalars['_FieldSet']['output']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AbandonOdysseyWorkoutResponse: ResolversUnionTypes<ResolversParentTypes>['AbandonOdysseyWorkoutResponse'];
  AchievementAwardedEventPayload: AchievementAwardedEventPayload;
  ActiveTagClass: ActiveTagClass;
  ActiveTagClassEdge: ActiveTagClassEdge;
  ActiveTagClassesPaginatedList: ActiveTagClassesPaginatedList;
  ActiveTagUserEdge: ActiveTagUserEdge;
  ActiveTagUserPaginatedList: ActiveTagUserPaginatedList;
  ActiveWorkout: ActiveWorkout;
  ActivityFeedErrorResponse: ActivityFeedErrorResponse;
  ActivityFeedEvent: Omit<ActivityFeedEvent, 'payload'> & {
    payload: ResolversParentTypes['Payload'];
  };
  ActivityFeedEventEdge: ActivityFeedEventEdge;
  ActivityFeedEventPaginationResponse: ActivityFeedEventPaginationResponse;
  ActivityFeedEventPayload: ResolversInterfaceTypes<ResolversParentTypes>['ActivityFeedEventPayload'];
  ActivityFeedEventResponse: ResolversUnionTypes<ResolversParentTypes>['ActivityFeedEventResponse'];
  AddClassToStackInput: AddClassToStackInput;
  AddTagToUserInput: AddTagToUserInput;
  AddTagToUserResponse: ResolversUnionTypes<ResolversParentTypes>['AddTagToUserResponse'];
  AddTagToUserSuccess: AddTagToUserSuccess;
  Album: Album;
  AllEntertainmentChannelsWithTranslationsSuccess: AllEntertainmentChannelsWithTranslationsSuccess;
  Artist: Artist;
  AwardedProgramAchievementTier: AwardedProgramAchievementTier;
  BasePlaylist: ResolversInterfaceTypes<ResolversParentTypes>['BasePlaylist'];
  BestRuns: BestRuns;
  Boolean: Scalars['Boolean']['output'];
  CannotScheduleClassError: CannotScheduleClassError;
  Captions: Captions;
  ClassAssets: ClassAssets;
  ClassSegment: ClassSegment;
  ClassSubsegment: ClassSubsegment;
  ClassTimeline: ClassTimeline;
  ClassTopTag: ClassTopTag;
  ClassType: ClassType;
  Color: Color;
  CompleteOdysseyWorkoutInput: CompleteOdysseyWorkoutInput;
  CompleteOdysseyWorkoutResponse: ResolversUnionTypes<ResolversParentTypes>['CompleteOdysseyWorkoutResponse'];
  CompletedStats: CompletedStats;
  CreateLiveInviteInput: CreateLiveInviteInput;
  CreateNewOnDemandInviteInput: CreateNewOnDemandInviteInput;
  CreateOdysseyWorkoutInput: CreateOdysseyWorkoutInput;
  CreateOdysseyWorkoutResponse: ResolversUnionTypes<ResolversParentTypes>['CreateOdysseyWorkoutResponse'];
  CreateScheduledOnDemandInviteInput: CreateScheduledOnDemandInviteInput;
  Cursor: Scalars['Cursor']['output'];
  DateTime: Scalars['DateTime']['output'];
  DeletedEntertainmentChannel: DeletedEntertainmentChannel;
  DeletedEntertainmentChannelResponse: ResolversUnionTypes<ResolversParentTypes>['DeletedEntertainmentChannelResponse'];
  DeletedEntertainmentChannelSuccess: DeletedEntertainmentChannelSuccess;
  DifficultyLevel: DifficultyLevel;
  DisplayTag: DisplayTag;
  DoesNotExistError: DoesNotExistError;
  EntertainmentChannel: EntertainmentChannel;
  EntertainmentChannelPaginatedList: EntertainmentChannelPaginatedList;
  EntertainmentChannelPresignedUrl: EntertainmentChannelPresignedUrl;
  EntertainmentChannelPresignedUrlQueryResponse: ResolversUnionTypes<ResolversParentTypes>['EntertainmentChannelPresignedUrlQueryResponse'];
  EntertainmentChannelTranslation: EntertainmentChannelTranslation;
  EntertainmentChannelTranslationInput: EntertainmentChannelTranslationInput;
  EntertainmentChannelWithTranslationSuccess: EntertainmentChannelWithTranslationSuccess;
  EntertainmentChannelWithTranslations: EntertainmentChannelWithTranslations;
  EntertainmentChannelWithTranslationsInput: EntertainmentChannelWithTranslationsInput;
  EntertainmentChannelWithTranslationsResponse: ResolversUnionTypes<ResolversParentTypes>['EntertainmentChannelWithTranslationsResponse'];
  EntertainmentChannelsQueryResponse: ResolversUnionTypes<ResolversParentTypes>['EntertainmentChannelsQueryResponse'];
  EntityNotFoundError: EntityNotFoundError;
  Equipment: Equipment;
  Error: ResolversInterfaceTypes<ResolversParentTypes>['Error'];
  ErrorVideoStream: ErrorVideoStream;
  EventDetails: EventDetails;
  EventDetailsResponse: ResolversUnionTypes<ResolversParentTypes>['EventDetailsResponse'];
  FeedWorkout: ResolversInterfaceTypes<ResolversParentTypes>['FeedWorkout'];
  FilterOption: ResolversUnionTypes<ResolversParentTypes>['FilterOption'];
  FitnessDiscipline: FitnessDiscipline;
  Float: Scalars['Float']['output'];
  FollowingUsersPaginatedList: FollowingUsersPaginatedList;
  FreestyleFeedWorkout: FreestyleFeedWorkout;
  FreestyleMetadata: Omit<FreestyleMetadata, 'musicProviders'> & {
    musicProviders: Array<ResolversParentTypes['MusicProvider']>;
  };
  FreestyleWorkoutGoalInput: FreestyleWorkoutGoalInput;
  GoalProgress: GoalProgress;
  GoalProgressDetails: GoalProgressDetails;
  GroupTags: GroupTags;
  GymBodyActivityGroupFilter: GymBodyActivityGroupFilter;
  GymBookmarkInput: GymBookmarkInput;
  GymBookmarkResponse: ResolversUnionTypes<ResolversParentTypes>['GymBookmarkResponse'];
  GymBooleanFilter: GymBooleanFilter;
  GymEquipment: GymEquipment;
  GymErrorResponse: GymErrorResponse;
  GymFeedWorkout: GymFeedWorkout;
  GymFilterCategory: Omit<GymFilterCategory, 'filters'> & {
    filters: Array<ResolversParentTypes['FilterOption']>;
  };
  GymInstructorFilter: GymInstructorFilter;
  GymIntFilter: GymIntFilter;
  GymMovement: GymMovement;
  GymMovementGroup: GymMovementGroup;
  GymPlan: GymPlan;
  GymPlanEdge: GymPlanEdge;
  GymPlanFilterChip: GymPlanFilterChip;
  GymPlanFilterInput: GymPlanFilterInput;
  GymPlanPaginationResponse: GymPlanPaginationResponse;
  GymPlanQueryFilterOptions: GymPlanQueryFilterOptions;
  GymPlanQueryFiltersInput: GymPlanQueryFiltersInput;
  GymPlanResponse: ResolversUnionTypes<ResolversParentTypes>['GymPlanResponse'];
  GymStringFilter: GymStringFilter;
  GymSuccessfulResponse: GymSuccessfulResponse;
  GymWorkout: GymWorkout;
  HeroImageAssets: HeroImageAssets;
  HighFiveDetails: HighFiveDetails;
  HighFiveDetailsResponse: ResolversUnionTypes<ResolversParentTypes>['HighFiveDetailsResponse'];
  ID: Scalars['ID']['output'];
  IconDetails: IconDetails;
  Instructor: Instructor;
  InstructorAssets: InstructorAssets;
  InstructorCue: InstructorCue;
  Int: Scalars['Int']['output'];
  InviteDetails: Omit<
    InviteDetails,
    'getInvitedAcceptedFollowing' | 'getInvitedUsers'
  > & {
    getInvitedAcceptedFollowing: ResolversParentTypes['InvitedUsersPaginationResponse'];
    getInvitedUsers: ResolversParentTypes['InvitedUsersPaginationResponse'];
  };
  InviteFriendsBadRequest: InviteFriendsBadRequest;
  InviteFriendsCreateShareLinkDisabledError: InviteFriendsCreateShareLinkDisabledError;
  InviteFriendsError: ResolversInterfaceTypes<ResolversParentTypes>['InviteFriendsError'];
  InviteFriendsHttpException: InviteFriendsHttpException;
  InviteFriendsInvalidDataFormat: InviteFriendsInvalidDataFormat;
  InviteFriendsInvitedUserSizeLimitExceeded: InviteFriendsInvitedUserSizeLimitExceeded;
  InviteFriendsItemNotFoundError: InviteFriendsItemNotFoundError;
  InviteFriendsNoPermissionToCreateShareLinkError: InviteFriendsNoPermissionToCreateShareLinkError;
  InviteFriendsNotAuthorized: InviteFriendsNotAuthorized;
  InviteResponse: ResolversUnionTypes<ResolversParentTypes>['InviteResponse'];
  InviteShareURLResponse: ResolversUnionTypes<ResolversParentTypes>['InviteShareURLResponse'];
  InviteUser: InviteUser;
  InviteUserEdge: InviteUserEdge;
  InviteUsersToInviteInput: InviteUsersToInviteInput;
  InvitedUsersPaginatedList: InvitedUsersPaginatedList;
  InvitedUsersPaginationResponse: ResolversUnionTypes<ResolversParentTypes>['InvitedUsersPaginationResponse'];
  JoinAuthorizationError: JoinAuthorizationError;
  JoinContentNotLiveError: JoinContentNotLiveError;
  JoinError: ResolversInterfaceTypes<ResolversParentTypes>['JoinError'];
  JoinNoActiveSubscriptionError: JoinNoActiveSubscriptionError;
  JoinStreamLimitError: JoinStreamLimitError;
  JoinToken: Scalars['JoinToken']['output'];
  JoinableClass: Omit<JoinableClass, 'videoStreamOrError'> & {
    videoStreamOrError: ResolversParentTypes['VideoStreamOrError'];
  };
  LanebreakClass: LanebreakClass;
  LeaderboardEntry: LeaderboardEntry;
  LeaderboardRequestInput: LeaderboardRequestInput;
  LeaderboardWorkoutDetails: LeaderboardWorkoutDetails;
  Level: Omit<Level, 'musicProvider'> & {
    musicProvider?: Maybe<ResolversParentTypes['MusicProvider']>;
  };
  LevelBrowseFiltersSuccessResponse: Omit<
    LevelBrowseFiltersSuccessResponse,
    'filters'
  > & { filters: Array<ResolversParentTypes['OdysseyLevelFilter']> };
  LevelFiltersQueryResponse: ResolversUnionTypes<ResolversParentTypes>['LevelFiltersQueryResponse'];
  LevelListQueryResponse: ResolversUnionTypes<ResolversParentTypes>['LevelListQueryResponse'];
  LevelListSuccessResponse: LevelListSuccessResponse;
  LevelQueryResponse: ResolversUnionTypes<ResolversParentTypes>['LevelQueryResponse'];
  LevelSuccessResponse: LevelSuccessResponse;
  LevelTheme: LevelTheme;
  LiveClass: Omit<LiveClass, 'peloton'> & {
    peloton: ResolversParentTypes['PelotonResult'];
  };
  Locale: Locale;
  Media: Media;
  MetaTag: MetaTag;
  Metric: Metric;
  ModifyStackInput: ModifyStackInput;
  Movement: Movement;
  MovementMuscleGroup: MovementMuscleGroup;
  MovementVideo: MovementVideo;
  MuscleGroupScore: MuscleGroupScore;
  MusicProvider: ResolversUnionTypes<ResolversParentTypes>['MusicProvider'];
  Mutation: {};
  NoClassPelotonForPlatformError: NoClassPelotonForPlatformError;
  Notification: Notification;
  NotificationDetails: NotificationDetails;
  NotificationDetailsResponse: ResolversUnionTypes<ResolversParentTypes>['NotificationDetailsResponse'];
  Occasion: Occasion;
  OccasionAssets: OccasionAssets;
  OccasionsList: OccasionsList;
  OccasionsResponse: ResolversUnionTypes<ResolversParentTypes>['OccasionsResponse'];
  OdysseyAccordionLevelFilter: Omit<OdysseyAccordionLevelFilter, 'options'> & {
    options: Array<ResolversParentTypes['OdysseyLevelFilterOption']>;
  };
  OdysseyAccordionLevelFilterInfo: OdysseyAccordionLevelFilterInfo;
  OdysseyBadRequestError: OdysseyBadRequestError;
  OdysseyBasicLevelFilterOption: OdysseyBasicLevelFilterOption;
  OdysseyBikeCompletionInput: OdysseyBikeCompletionInput;
  OdysseyBikeConfiguration: OdysseyBikeConfiguration;
  OdysseyBikeDifficultyConfiguration: OdysseyBikeDifficultyConfiguration;
  OdysseyBikeLaneConfiguration: OdysseyBikeLaneConfiguration;
  OdysseyBikeScoringConfiguration: OdysseyBikeScoringConfiguration;
  OdysseyBookmarkInput: OdysseyBookmarkInput;
  OdysseyBookmarkResponse: ResolversUnionTypes<ResolversParentTypes>['OdysseyBookmarkResponse'];
  OdysseyBookmarkSuccessResponse: OdysseyBookmarkSuccessResponse;
  OdysseyConfigurationResponse: ResolversUnionTypes<ResolversParentTypes>['OdysseyConfigurationResponse'];
  OdysseyDifficultyMetadata: OdysseyDifficultyMetadata;
  OdysseyDurationLevelFilterOption: OdysseyDurationLevelFilterOption;
  OdysseyError: ResolversInterfaceTypes<ResolversParentTypes>['OdysseyError'];
  OdysseyFeedWorkout: OdysseyFeedWorkout;
  OdysseyGridLevelFilter: Omit<OdysseyGridLevelFilter, 'options'> & {
    options: Array<ResolversParentTypes['OdysseyLevelFilterOption']>;
  };
  OdysseyGridLevelFilterInfo: OdysseyGridLevelFilterInfo;
  OdysseyIcon: OdysseyIcon;
  OdysseyInvalidWorkoutMutationError: OdysseyInvalidWorkoutMutationError;
  OdysseyItemNotFoundError: OdysseyItemNotFoundError;
  OdysseyLeaderboard: OdysseyLeaderboard;
  OdysseyLeaderboardGraphqlError: ResolversInterfaceTypes<ResolversParentTypes>['OdysseyLeaderboardGraphqlError'];
  OdysseyLeaderboardInvalidRequest: OdysseyLeaderboardInvalidRequest;
  OdysseyLeaderboardListSuccessResponse: OdysseyLeaderboardListSuccessResponse;
  OdysseyLeaderboardQueryResponse: ResolversUnionTypes<ResolversParentTypes>['OdysseyLeaderboardQueryResponse'];
  OdysseyLevelAssets: OdysseyLevelAssets;
  OdysseyLevelBrowseFilter: Omit<OdysseyLevelBrowseFilter, 'info' | 'options'> & {
    info: ResolversParentTypes['OdysseyLevelFilterInfo'];
    options: Array<ResolversParentTypes['OdysseyLevelFilterOption']>;
  };
  OdysseyLevelDifficultyDetail: OdysseyLevelDifficultyDetail;
  OdysseyLevelFilter: ResolversUnionTypes<ResolversParentTypes>['OdysseyLevelFilter'];
  OdysseyLevelFilterInfo: ResolversUnionTypes<ResolversParentTypes>['OdysseyLevelFilterInfo'];
  OdysseyLevelFilterOption: ResolversUnionTypes<ResolversParentTypes>['OdysseyLevelFilterOption'];
  OdysseyLevelMetric: OdysseyLevelMetric;
  OdysseyLevelOffset: OdysseyLevelOffset;
  OdysseyLevelPaceIntensity: OdysseyLevelPaceIntensity;
  OdysseyLevelPaceLevel: OdysseyLevelPaceLevel;
  OdysseyLevelTargetMetrics: OdysseyLevelTargetMetrics;
  OdysseyLevelTargetMetricsData: OdysseyLevelTargetMetricsData;
  OdysseyLevelTargetMetricsOutput: OdysseyLevelTargetMetricsOutput;
  OdysseyNotAuthorizedError: OdysseyNotAuthorizedError;
  OdysseyPlayedLevelFilterOption: OdysseyPlayedLevelFilterOption;
  OdysseyStarThreshold: OdysseyStarThreshold;
  OdysseyStarsLevelFilterOption: OdysseyStarsLevelFilterOption;
  OdysseyToggleLevelFilter: OdysseyToggleLevelFilter;
  OdysseyToggleLevelFilterInfo: OdysseyToggleLevelFilterInfo;
  OdysseyTreadCompletionInput: OdysseyTreadCompletionInput;
  OdysseyTreadConfiguration: OdysseyTreadConfiguration;
  OdysseyTreadDifficultyConfiguration: OdysseyTreadDifficultyConfiguration;
  OdysseyTreadDifficultyIntensity: OdysseyTreadDifficultyIntensity;
  OdysseyTreadLaneConfiguration: OdysseyTreadLaneConfiguration;
  OdysseyTreadScoringConfiguration: OdysseyTreadScoringConfiguration;
  OdysseyWorkout: OdysseyWorkout;
  OdysseyWorkoutQueryResponse: ResolversUnionTypes<ResolversParentTypes>['OdysseyWorkoutQueryResponse'];
  OdysseyWorkoutSuccessResponse: OdysseyWorkoutSuccessResponse;
  Offset: Offset;
  OffsetsCue: OffsetsCue;
  OnDemandInstructorClass: OnDemandInstructorClass;
  PaceIntensity: PaceIntensity;
  PaceLevel: PaceLevel;
  PageInfo: PageInfo;
  PaginatedList: ResolversInterfaceTypes<ResolversParentTypes>['PaginatedList'];
  PauseInfo: PauseInfo;
  PauseWorkoutInput: PauseWorkoutInput;
  Payload: ResolversUnionTypes<ResolversParentTypes>['Payload'];
  Peloton: Peloton;
  PelotonClass: ResolversInterfaceTypes<ResolversParentTypes>['PelotonClass'];
  PelotonClassFeedWorkout: PelotonClassFeedWorkout;
  PelotonNotCreatedError: PelotonNotCreatedError;
  PelotonResult: ResolversUnionTypes<ResolversParentTypes>['PelotonResult'];
  PendingInvites: PendingInvites;
  PendingInvitesResponse: ResolversUnionTypes<ResolversParentTypes>['PendingInvitesResponse'];
  PermissionError: PermissionError;
  PlayClassFromStackInput: PlayClassFromStackInput;
  Playlist: Playlist;
  PlaylistMusicProvider: PlaylistMusicProvider;
  PlaylistPlaybackSong: PlaylistPlaybackSong;
  PopularTagClass: PopularTagClass;
  PopularTagClassesList: PopularTagClassesList;
  Program: Program;
  ProgramAchievementAssets: ProgramAchievementAssets;
  ProgramAchievementTier: ProgramAchievementTier;
  ProgramAssets: ProgramAssets;
  ProgramClass: ProgramClass;
  ProgramClassProgress: ProgramClassProgress;
  ProgramDay: ProgramDay;
  ProgramFiltersInput: ProgramFiltersInput;
  ProgramFitnessDiscipline: ProgramFitnessDiscipline;
  ProgramMetaData: ProgramMetaData;
  ProgramParamsInput: ProgramParamsInput;
  ProgramProgress: ProgramProgress;
  ProgramProgressAlreadyExistsError: ProgramProgressAlreadyExistsError;
  ProgramProgressNotFoundError: ProgramProgressNotFoundError;
  ProgramProgressResponse: ResolversUnionTypes<ResolversParentTypes>['ProgramProgressResponse'];
  ProgramProgressResponseSuccess: ProgramProgressResponseSuccess;
  ProgramSchedule: ProgramSchedule;
  ProgramSortingInput: ProgramSortingInput;
  ProgramWeek: ProgramWeek;
  ProgramWeekProgress: ProgramWeekProgress;
  Query: {};
  RangeCue: RangeCue;
  ReadyVideoStream: ReadyVideoStream;
  RemoveTagFromUserInput: RemoveTagFromUserInput;
  RemoveTagFromUserResponse: ResolversUnionTypes<ResolversParentTypes>['RemoveTagFromUserResponse'];
  RemoveTagFromUserSuccess: RemoveTagFromUserSuccess;
  ReplaceTagInput: ReplaceTagInput;
  ReplaceTagResponse: ResolversUnionTypes<ResolversParentTypes>['ReplaceTagResponse'];
  ReplaceTagSuccess: ReplaceTagSuccess;
  RescheduleClassInput: RescheduleClassInput;
  RetryableError: ResolversInterfaceTypes<ResolversParentTypes>['RetryableError'];
  ScenicClass: Omit<ScenicClass, 'musicProviders'> & {
    musicProviders: Array<Maybe<ResolversParentTypes['MusicProvider']>>;
  };
  ScheduledClass: ScheduledClass;
  ScheduledClassDoesNotExistError: ScheduledClassDoesNotExistError;
  ScheduledClassInput: ScheduledClassInput;
  ScheduledClassList: ScheduledClassList;
  ScheduledClassListResponse: ResolversUnionTypes<ResolversParentTypes>['ScheduledClassListResponse'];
  ScheduledClassResponse: ResolversUnionTypes<ResolversParentTypes>['ScheduledClassResponse'];
  ScheduledClassesInput: ScheduledClassesInput;
  SearchTagInput: SearchTagInput;
  SessionClass: SessionClass;
  SessionInput: SessionInput;
  SetTagAsPrimaryInput: SetTagAsPrimaryInput;
  SetTagAsPrimaryResponse: ResolversUnionTypes<ResolversParentTypes>['SetTagAsPrimaryResponse'];
  SetTagAsPrimarySuccess: SetTagAsPrimarySuccess;
  ShareURL: ShareUrl;
  Song: Song;
  StackConnectionError: StackConnectionError;
  StackDoesNotExist: StackDoesNotExist;
  StackError: StackError;
  StackPermissionError: StackPermissionError;
  StackResponse: ResolversInterfaceTypes<ResolversParentTypes>['StackResponse'];
  StackResponseSuccess: StackResponseSuccess;
  StackedClass: StackedClass;
  StackedClassAlreadyExistsError: StackedClassAlreadyExistsError;
  StackedClassCannotBeAddedError: StackedClassCannotBeAddedError;
  StackedClassConstraintViolationError: StackedClassConstraintViolationError;
  StackedClassDoesNotExistInStackError: StackedClassDoesNotExistInStackError;
  StackedClassInvalidPelotonClassIdError: StackedClassInvalidPelotonClassIdError;
  StackedClassLimitReachedError: StackedClassLimitReachedError;
  StartClassInput: StartClassInput;
  StartFreestyleWorkoutInput: StartFreestyleWorkoutInput;
  StartFreestyleWorkoutResponse: ResolversUnionTypes<ResolversParentTypes>['StartFreestyleWorkoutResponse'];
  StartFreestyleWorkoutResponseSuccess: StartFreestyleWorkoutResponseSuccess;
  StartGymWorkoutInput: StartGymWorkoutInput;
  StartGymWorkoutResponse: ResolversUnionTypes<ResolversParentTypes>['StartGymWorkoutResponse'];
  StartGymWorkoutResponseSuccess: StartGymWorkoutResponseSuccess;
  StreakAwardedEventPayload: StreakAwardedEventPayload;
  StreamablePlaylist: StreamablePlaylist;
  String: Scalars['String']['output'];
  SubscriptionIdNotFoundError: SubscriptionIdNotFoundError;
  Tag: Tag;
  TagAlreadyExistsError: TagAlreadyExistsError;
  TagAssets: TagAssets;
  TagBrowseCategory: TagBrowseCategory;
  TagBrowseCategoryAssets: TagBrowseCategoryAssets;
  TagCapacityOverLimitError: TagCapacityOverLimitError;
  TagDoesNotExistOnUserError: TagDoesNotExistOnUserError;
  TagEdge: TagEdge;
  TagNameInvalidCharacterError: TagNameInvalidCharacterError;
  TagNameLengthError: TagNameLengthError;
  TagNameProhibitedError: TagNameProhibitedError;
  TagPaginatedList: TagPaginatedList;
  TargetMetrics: TargetMetrics;
  TargetMetricsData: TargetMetricsData;
  TargetMetricsOutput: TargetMetricsOutput;
  URL: Scalars['URL']['output'];
  UnpauseWorkoutInput: UnpauseWorkoutInput;
  UnpauseWorkoutResponse: UnpauseWorkoutResponse;
  UnreadNotifications: UnreadNotifications;
  UnreadNotificationsResponse: ResolversUnionTypes<ResolversParentTypes>['UnreadNotificationsResponse'];
  User: Omit<User, 'protectedFields' | 'scheduledClasses'> & {
    protectedFields?: Maybe<ResolversParentTypes['UserProtectedFieldsResult']>;
    scheduledClasses: ResolversParentTypes['ScheduledClassListResponse'];
  };
  UserAssets: UserAssets;
  UserEdge: UserEdge;
  UserInput: UserInput;
  UserLeaderboardRequestInput: UserLeaderboardRequestInput;
  UserNotAuthorizedError: UserNotAuthorizedError;
  UserPaginatedList: UserPaginatedList;
  UserPrivacyError: UserPrivacyError;
  UserProtectedFields: UserProtectedFields;
  UserProtectedFieldsResult: ResolversUnionTypes<ResolversParentTypes>['UserProtectedFieldsResult'];
  UserStack: UserStack;
  UserTag: UserTag;
  UserTags: UserTags;
  VideoStream: VideoStream;
  VideoStreamOrError: ResolversUnionTypes<ResolversParentTypes>['VideoStreamOrError'];
  Workout: Workout;
  WorkoutFinalizedEventPayload: WorkoutFinalizedEventPayload;
  WorkoutMetricDetails: WorkoutMetricDetails;
  WorkoutOfTheDayDetails: WorkoutOfTheDayDetails;
  WorkoutPostClassResponse: WorkoutPostClassResponse;
  YourScheduleItem: ResolversUnionTypes<ResolversParentTypes>['YourScheduleItem'];
  YourScheduleItemList: Omit<
    YourScheduleItemList,
    'pendingInvites' | 'yourScheduleItems'
  > & {
    pendingInvites: ResolversParentTypes['PendingInvitesResponse'];
    yourScheduleItems: Array<ResolversParentTypes['YourScheduleItem']>;
  };
  YourScheduleListResponse: ResolversUnionTypes<ResolversParentTypes>['YourScheduleListResponse'];
  ZoneScore: ZoneScore;
  _FieldSet: Scalars['_FieldSet']['output'];
};

export type AbandonOdysseyWorkoutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AbandonOdysseyWorkoutResponse'] = ResolversParentTypes['AbandonOdysseyWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyInvalidWorkoutMutationError'
    | 'OdysseyItemNotFoundError'
    | 'OdysseyWorkoutSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type AchievementAwardedEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AchievementAwardedEventPayload'] = ResolversParentTypes['AchievementAwardedEventPayload'],
> = {
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveTagClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveTagClass'] = ResolversParentTypes['ActiveTagClass'],
> = {
  activeUsers?: Resolver<
    ResolversTypes['ActiveTagUserPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<ActiveTagClassActiveUsersArgs, 'after' | 'limit'>
  >;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveTagClassEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveTagClassEdge'] = ResolversParentTypes['ActiveTagClassEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ActiveTagClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveTagClassesPaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveTagClassesPaginatedList'] = ResolversParentTypes['ActiveTagClassesPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['ActiveTagClassEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveTagUserEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveTagUserEdge'] = ResolversParentTypes['ActiveTagUserEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveTagUserPaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveTagUserPaginatedList'] = ResolversParentTypes['ActiveTagUserPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['ActiveTagUserEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveWorkout'] = ResolversParentTypes['ActiveWorkout'],
> = {
  actualStartTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isSkipIntroAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pauseInfo?: Resolver<Maybe<ResolversTypes['PauseInfo']>, ParentType, ContextType>;
  peloton?: Resolver<ResolversTypes['Peloton'], ParentType, ContextType>;
  workout?: Resolver<ResolversTypes['Workout'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedErrorResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityFeedErrorResponse'] = ResolversParentTypes['ActivityFeedErrorResponse'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ActivityFeedErrorType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityFeedEvent'] = ResolversParentTypes['ActivityFeedEvent'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  feedEventType?: Resolver<
    Maybe<ResolversTypes['FeedEventType']>,
    ParentType,
    ContextType
  >;
  highFivedUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  highFives?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isHighFived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  payload?: Resolver<ResolversTypes['Payload'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  workoutMetricsType?: Resolver<
    ResolversTypes['WorkoutMetricsType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedEventEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityFeedEventEdge'] = ResolversParentTypes['ActivityFeedEventEdge'],
> = {
  activityFeedEvent?: Resolver<
    ResolversTypes['ActivityFeedEvent'],
    ParentType,
    ContextType
  >;
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedEventPaginationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityFeedEventPaginationResponse'] = ResolversParentTypes['ActivityFeedEventPaginationResponse'],
> = {
  edges?: Resolver<
    Array<ResolversTypes['ActivityFeedEventEdge']>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFeedEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityFeedEventPayload'] = ResolversParentTypes['ActivityFeedEventPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'AchievementAwardedEventPayload'
    | 'StreakAwardedEventPayload'
    | 'WorkoutFinalizedEventPayload',
    ParentType,
    ContextType
  >;
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type ActivityFeedEventResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityFeedEventResponse'] = ResolversParentTypes['ActivityFeedEventResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'ActivityFeedErrorResponse'
    | 'ActivityFeedEvent'
    | 'ActivityFeedEventPaginationResponse',
    ParentType,
    ContextType
  >;
};

export type AddTagToUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddTagToUserResponse'] = ResolversParentTypes['AddTagToUserResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'AddTagToUserSuccess'
    | 'DoesNotExistError'
    | 'TagAlreadyExistsError'
    | 'TagCapacityOverLimitError'
    | 'TagNameInvalidCharacterError'
    | 'TagNameLengthError'
    | 'TagNameProhibitedError',
    ParentType,
    ContextType
  >;
};

export type AddTagToUserSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddTagToUserSuccess'] = ResolversParentTypes['AddTagToUserSuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlbumResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Album'] = ResolversParentTypes['Album'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllEntertainmentChannelsWithTranslationsSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllEntertainmentChannelsWithTranslationsSuccess'] = ResolversParentTypes['AllEntertainmentChannelsWithTranslationsSuccess'],
> = {
  edges?: Resolver<
    Array<ResolversTypes['EntertainmentChannelWithTranslations']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArtistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Artist'] = ResolversParentTypes['Artist'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwardedProgramAchievementTierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AwardedProgramAchievementTier'] = ResolversParentTypes['AwardedProgramAchievementTier'],
> = {
  achievementTier?: Resolver<
    ResolversTypes['ProgramAchievementTier'],
    ParentType,
    ContextType
  >;
  awardedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  numClassesCompleted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BasePlaylistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BasePlaylist'] = ResolversParentTypes['BasePlaylist'],
> = {
  __resolveType: TypeResolveFn<
    'Playlist' | 'StreamablePlaylist',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInClassMusicShown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playbackSongs?: Resolver<
    Array<ResolversTypes['PlaylistPlaybackSong']>,
    ParentType,
    ContextType
  >;
  topArtists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
};

export type BestRunsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BestRuns'] = ResolversParentTypes['BestRuns'],
> = {
  bestRunsByCompletionPercentage?: Resolver<
    Array<ResolversTypes['OdysseyWorkout']>,
    ParentType,
    ContextType
  >;
  bestRunsByScore?: Resolver<
    Array<ResolversTypes['OdysseyWorkout']>,
    ParentType,
    ContextType
  >;
  starsEarned?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CannotScheduleClassErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CannotScheduleClassError'] = ResolversParentTypes['CannotScheduleClassError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Captions'] = ResolversParentTypes['Captions'],
> = {
  locales?: Resolver<Array<ResolversTypes['LocaleCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassAssets'] = ResolversParentTypes['ClassAssets'],
> = {
  thumbnailImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassSegmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassSegment'] = ResolversParentTypes['ClassSegment'],
> = {
  icon?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  iconDetails?: Resolver<ResolversTypes['IconDetails'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intensityInMets?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isDrill?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  metricsType?: Resolver<ResolversTypes['ClassSegmentMetric'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeOffset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subsegments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassSubsegment']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassSubsegmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassSubsegment'] = ResolversParentTypes['ClassSubsegment'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  movements?: Resolver<Array<Maybe<ResolversTypes['Movement']>>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rounds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scheduledOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  trackableMovementsDisabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassTimelineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassTimeline'] = ResolversParentTypes['ClassTimeline'],
> = {
  classSegments?: Resolver<
    Array<ResolversTypes['ClassSegment']>,
    ParentType,
    ContextType
  >;
  endOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  videoEndOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassTopTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassTopTag'] = ResolversParentTypes['ClassTopTag'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassType'] = ResolversParentTypes['ClassType'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Color'] = ResolversParentTypes['Color'],
> = {
  hexCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompleteOdysseyWorkoutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompleteOdysseyWorkoutResponse'] = ResolversParentTypes['CompleteOdysseyWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyBadRequestError'
    | 'OdysseyInvalidWorkoutMutationError'
    | 'OdysseyItemNotFoundError'
    | 'OdysseyWorkoutSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type CompletedStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompletedStats'] = ResolversParentTypes['CompletedStats'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workoutId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  zoneScore?: Resolver<ResolversTypes['ZoneScore'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOdysseyWorkoutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateOdysseyWorkoutResponse'] = ResolversParentTypes['CreateOdysseyWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<'OdysseyWorkoutSuccessResponse', ParentType, ContextType>;
};

export interface CursorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Cursor'], any> {
  name: 'Cursor';
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeletedEntertainmentChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedEntertainmentChannel'] = ResolversParentTypes['DeletedEntertainmentChannel'],
> = {
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedEntertainmentChannelResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedEntertainmentChannelResponse'] = ResolversParentTypes['DeletedEntertainmentChannelResponse'],
> = {
  __resolveType: TypeResolveFn<
    'DeletedEntertainmentChannelSuccess' | 'EntityNotFoundError' | 'PermissionError',
    ParentType,
    ContextType
  >;
};

export type DeletedEntertainmentChannelSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedEntertainmentChannelSuccess'] = ResolversParentTypes['DeletedEntertainmentChannelSuccess'],
> = {
  channel?: Resolver<
    Maybe<ResolversTypes['DeletedEntertainmentChannel']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DifficultyLevelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DifficultyLevel'] = ResolversParentTypes['DifficultyLevel'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['DifficultyLevelSlug'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisplayTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DisplayTag'] = ResolversParentTypes['DisplayTag'],
> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DoesNotExistErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DoesNotExistError'] = ResolversParentTypes['DoesNotExistError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntertainmentChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannel'] = ResolversParentTypes['EntertainmentChannel'],
> = {
  allowedUrls?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  backgroundImage?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userAgent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntertainmentChannelPaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelPaginatedList'] = ResolversParentTypes['EntertainmentChannelPaginatedList'],
> = {
  edges?: Resolver<
    Array<ResolversTypes['EntertainmentChannel']>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntertainmentChannelPresignedUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelPresignedUrl'] = ResolversParentTypes['EntertainmentChannelPresignedUrl'],
> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntertainmentChannelPresignedUrlQueryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelPresignedUrlQueryResponse'] = ResolversParentTypes['EntertainmentChannelPresignedUrlQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'EntertainmentChannelPresignedUrl' | 'PermissionError',
    ParentType,
    ContextType
  >;
};

export type EntertainmentChannelTranslationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelTranslation'] = ResolversParentTypes['EntertainmentChannelTranslation'],
> = {
  locale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntertainmentChannelWithTranslationSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelWithTranslationSuccess'] = ResolversParentTypes['EntertainmentChannelWithTranslationSuccess'],
> = {
  channel?: Resolver<
    Maybe<ResolversTypes['EntertainmentChannelWithTranslations']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntertainmentChannelWithTranslationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelWithTranslations'] = ResolversParentTypes['EntertainmentChannelWithTranslations'],
> = {
  allowedChannelGroups?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  backgroundImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  minEnabledAppVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translations?: Resolver<
    Array<ResolversTypes['EntertainmentChannelTranslation']>,
    ParentType,
    ContextType
  >;
  userAgent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntertainmentChannelWithTranslationsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelWithTranslationsResponse'] = ResolversParentTypes['EntertainmentChannelWithTranslationsResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'AllEntertainmentChannelsWithTranslationsSuccess'
    | 'EntertainmentChannelWithTranslationSuccess'
    | 'EntityNotFoundError'
    | 'PermissionError',
    ParentType,
    ContextType
  >;
};

export type EntertainmentChannelsQueryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntertainmentChannelsQueryResponse'] = ResolversParentTypes['EntertainmentChannelsQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'EntertainmentChannelPaginatedList' | 'PermissionError',
    ParentType,
    ContextType
  >;
};

export type EntityNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntityNotFoundError'] = ResolversParentTypes['EntityNotFoundError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EquipmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Equipment'] = ResolversParentTypes['Equipment'],
> = {
  icon?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error'],
> = {
  __resolveType: TypeResolveFn<
    | 'CannotScheduleClassError'
    | 'DoesNotExistError'
    | 'EntityNotFoundError'
    | 'NoClassPelotonForPlatformError'
    | 'PelotonNotCreatedError'
    | 'PermissionError'
    | 'ScheduledClassDoesNotExistError'
    | 'StackConnectionError'
    | 'StackDoesNotExist'
    | 'StackError'
    | 'StackPermissionError'
    | 'StackedClassAlreadyExistsError'
    | 'StackedClassCannotBeAddedError'
    | 'StackedClassConstraintViolationError'
    | 'StackedClassDoesNotExistInStackError'
    | 'StackedClassInvalidPelotonClassIdError'
    | 'StackedClassLimitReachedError'
    | 'TagAlreadyExistsError'
    | 'TagCapacityOverLimitError'
    | 'TagDoesNotExistOnUserError'
    | 'TagNameInvalidCharacterError'
    | 'TagNameLengthError'
    | 'TagNameProhibitedError'
    | 'UserPrivacyError',
    ParentType,
    ContextType
  >;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type ErrorVideoStreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ErrorVideoStream'] = ResolversParentTypes['ErrorVideoStream'],
> = {
  joinError?: Resolver<ResolversTypes['JoinError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventDetails'] = ResolversParentTypes['EventDetails'],
> = {
  activityFeedEvent?: Resolver<
    ResolversTypes['ActivityFeedEvent'],
    ParentType,
    ContextType
  >;
  highFiveDetails?: Resolver<ResolversTypes['HighFiveDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventDetailsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventDetailsResponse'] = ResolversParentTypes['EventDetailsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'EventDetails',
    ParentType,
    ContextType
  >;
};

export type FeedWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeedWorkout'] = ResolversParentTypes['FeedWorkout'],
> = {
  __resolveType: TypeResolveFn<
    | 'FreestyleFeedWorkout'
    | 'GymFeedWorkout'
    | 'OdysseyFeedWorkout'
    | 'PelotonClassFeedWorkout',
    ParentType,
    ContextType
  >;
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
};

export type FilterOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FilterOption'] = ResolversParentTypes['FilterOption'],
> = {
  __resolveType: TypeResolveFn<
    | 'GymBodyActivityGroupFilter'
    | 'GymBooleanFilter'
    | 'GymInstructorFilter'
    | 'GymIntFilter'
    | 'GymStringFilter',
    ParentType,
    ContextType
  >;
};

export type FitnessDisciplineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FitnessDiscipline'] = ResolversParentTypes['FitnessDiscipline'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['FitnessDisciplineSlug'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowingUsersPaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowingUsersPaginatedList'] = ResolversParentTypes['FollowingUsersPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FreestyleFeedWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FreestyleFeedWorkout'] = ResolversParentTypes['FreestyleFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  freestyleActivityTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  freestyleImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isOutdoor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FreestyleMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FreestyleMetadata'] = ResolversParentTypes['FreestyleMetadata'],
> = {
  musicProviders?: Resolver<
    Array<ResolversTypes['MusicProvider']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoalProgressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GoalProgress'] = ResolversParentTypes['GoalProgress'],
> = {
  frequency?: Resolver<ResolversTypes['GoalFrequency'], ParentType, ContextType>;
  newProgress?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  oldProgress?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GoalType'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoalProgressDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GoalProgressDetails'] = ResolversParentTypes['GoalProgressDetails'],
> = {
  goalProgress?: Resolver<Array<ResolversTypes['GoalProgress']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupTagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupTags'] = ResolversParentTypes['GroupTags'],
> = {
  activeTimeSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  durationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  groupType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ladderComposition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  movementOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  restTimeSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  splits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalRounds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymBodyActivityGroupFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymBodyActivityGroupFilter'] = ResolversParentTypes['GymBodyActivityGroupFilter'],
> = {
  subFilters?: Resolver<
    Array<ResolversTypes['GymStringFilter']>,
    ParentType,
    ContextType
  >;
  subHeader?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymBookmarkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymBookmarkResponse'] = ResolversParentTypes['GymBookmarkResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GymErrorResponse' | 'GymSuccessfulResponse',
    ParentType,
    ContextType
  >;
};

export type GymBooleanFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymBooleanFilter'] = ResolversParentTypes['GymBooleanFilter'],
> = {
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymEquipmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymEquipment'] = ResolversParentTypes['GymEquipment'],
> = {
  displayValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymErrorResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymErrorResponse'] = ResolversParentTypes['GymErrorResponse'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GymErrorType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymFeedWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymFeedWorkout'] = ResolversParentTypes['GymFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gymPlanId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymFilterCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymFilterCategory'] = ResolversParentTypes['GymFilterCategory'],
> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filters?: Resolver<Array<ResolversTypes['FilterOption']>, ParentType, ContextType>;
  header?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymInstructorFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymInstructorFilter'] = ResolversParentTypes['GymInstructorFilter'],
> = {
  instructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymIntFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymIntFilter'] = ResolversParentTypes['GymIntFilter'],
> = {
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymMovementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymMovement'] = ResolversParentTypes['GymMovement'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isRepsMirrored?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  longVideo?: Resolver<Maybe<ResolversTypes['MovementVideo']>, ParentType, ContextType>;
  longVideoId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  longVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  movementPattern?: Resolver<ResolversTypes['MovementPattern'], ParentType, ContextType>;
  muscleGroups?: Resolver<
    Array<ResolversTypes['MovementMuscleGroup']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shortVideo?: Resolver<Maybe<ResolversTypes['MovementVideo']>, ParentType, ContextType>;
  shortVideoId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  shortVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suggestedTimeInSeconds?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymMovementGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymMovementGroup'] = ResolversParentTypes['GymMovementGroup'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayTags?: Resolver<Array<ResolversTypes['DisplayTag']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupTags?: Resolver<Maybe<ResolversTypes['GroupTags']>, ParentType, ContextType>;
  gymMovements?: Resolver<Array<ResolversTypes['GymMovement']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intensity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  movementGroupType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymPlan'] = ResolversParentTypes['GymPlan'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  difficultyDisplayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  durationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipment?: Resolver<Array<ResolversTypes['GymEquipment']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gymMovementGroups?: Resolver<
    Array<ResolversTypes['GymMovementGroup']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<Maybe<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTaken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  muscleGroupScore?: Resolver<
    Array<ResolversTypes['MuscleGroupScore']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfMovementGroups?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfMovements?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publishedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workoutType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workoutTypeDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymPlanEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymPlanEdge'] = ResolversParentTypes['GymPlanEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  gymPlan?: Resolver<ResolversTypes['GymPlan'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymPlanFilterChipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymPlanFilterChip'] = ResolversParentTypes['GymPlanFilterChip'],
> = {
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymPlanPaginationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymPlanPaginationResponse'] = ResolversParentTypes['GymPlanPaginationResponse'],
> = {
  edges?: Resolver<Array<ResolversTypes['GymPlanEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymPlanQueryFilterOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymPlanQueryFilterOptions'] = ResolversParentTypes['GymPlanQueryFilterOptions'],
> = {
  categories?: Resolver<
    Array<ResolversTypes['GymFilterCategory']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymPlanResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymPlanResponse'] = ResolversParentTypes['GymPlanResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GymErrorResponse' | 'GymPlan' | 'GymPlanPaginationResponse',
    ParentType,
    ContextType
  >;
};

export type GymStringFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymStringFilter'] = ResolversParentTypes['GymStringFilter'],
> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymSuccessfulResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymSuccessfulResponse'] = ResolversParentTypes['GymSuccessfulResponse'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GymWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GymWorkout'] = ResolversParentTypes['GymWorkout'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HeroImageAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroImageAssets'] = ResolversParentTypes['HeroImageAssets'],
> = {
  center?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  progress?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  right?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  tv?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HighFiveDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HighFiveDetails'] = ResolversParentTypes['HighFiveDetails'],
> = {
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HighFiveDetailsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HighFiveDetailsResponse'] = ResolversParentTypes['HighFiveDetailsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'HighFiveDetails',
    ParentType,
    ContextType
  >;
};

export type IconDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IconDetails'] = ResolversParentTypes['IconDetails'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Instructor'] = ResolversParentTypes['Instructor'],
> = {
  assets?: Resolver<ResolversTypes['InstructorAssets'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstructorAssets'] = ResolversParentTypes['InstructorAssets'],
> = {
  aboutImage?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstructorCueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstructorCue'] = ResolversParentTypes['InstructorCue'],
> = {
  cadenceRange?: Resolver<ResolversTypes['RangeCue'], ParentType, ContextType>;
  offsets?: Resolver<ResolversTypes['OffsetsCue'], ParentType, ContextType>;
  resistanceRange?: Resolver<ResolversTypes['RangeCue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteDetails'] = ResolversParentTypes['InviteDetails'],
> = {
  authedInvitedUser?: Resolver<
    Maybe<ResolversTypes['InviteUser']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  getInvitedAcceptedFollowing?: Resolver<
    ResolversTypes['InvitedUsersPaginationResponse'],
    ParentType,
    ContextType,
    Partial<InviteDetailsGetInvitedAcceptedFollowingArgs>
  >;
  getInvitedUsers?: Resolver<
    ResolversTypes['InvitedUsersPaginationResponse'],
    ParentType,
    ContextType,
    Partial<InviteDetailsGetInvitedUsersArgs>
  >;
  hostUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  inviteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inviteStatus?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  occasion?: Resolver<Maybe<ResolversTypes['Occasion']>, ParentType, ContextType>;
  scheduledClass?: Resolver<ResolversTypes['ScheduledClass'], ParentType, ContextType>;
  scheduledClassId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['Visibility'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsBadRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsBadRequest'] = ResolversParentTypes['InviteFriendsBadRequest'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsCreateShareLinkDisabledErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsCreateShareLinkDisabledError'] = ResolversParentTypes['InviteFriendsCreateShareLinkDisabledError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsError'] = ResolversParentTypes['InviteFriendsError'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsBadRequest'
    | 'InviteFriendsCreateShareLinkDisabledError'
    | 'InviteFriendsHttpException'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsInvitedUserSizeLimitExceeded'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNoPermissionToCreateShareLinkError'
    | 'InviteFriendsNotAuthorized',
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type InviteFriendsHttpExceptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsHttpException'] = ResolversParentTypes['InviteFriendsHttpException'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsInvalidDataFormatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsInvalidDataFormat'] = ResolversParentTypes['InviteFriendsInvalidDataFormat'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsInvitedUserSizeLimitExceededResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsInvitedUserSizeLimitExceeded'] = ResolversParentTypes['InviteFriendsInvitedUserSizeLimitExceeded'],
> = {
  invitedUserSizeLimit?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsItemNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsItemNotFoundError'] = ResolversParentTypes['InviteFriendsItemNotFoundError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsNoPermissionToCreateShareLinkErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsNoPermissionToCreateShareLinkError'] = ResolversParentTypes['InviteFriendsNoPermissionToCreateShareLinkError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteFriendsNotAuthorizedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteFriendsNotAuthorized'] = ResolversParentTypes['InviteFriendsNotAuthorized'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteResponse'] = ResolversParentTypes['InviteResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteDetails'
    | 'InviteFriendsHttpException'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsInvitedUserSizeLimitExceeded'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNotAuthorized',
    ParentType,
    ContextType
  >;
};

export type InviteShareUrlResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteShareURLResponse'] = ResolversParentTypes['InviteShareURLResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsCreateShareLinkDisabledError'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNoPermissionToCreateShareLinkError'
    | 'ShareURL',
    ParentType,
    ContextType
  >;
};

export type InviteUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteUser'] = ResolversParentTypes['InviteUser'],
> = {
  invitedUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  invitedUserStatus?: Resolver<
    ResolversTypes['InvitedUserStatus'],
    ParentType,
    ContextType
  >;
  invitorUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteUserEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteUserEdge'] = ResolversParentTypes['InviteUserEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  inviteUserItem?: Resolver<ResolversTypes['InviteUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitedUsersPaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvitedUsersPaginatedList'] = ResolversParentTypes['InvitedUsersPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['InviteUserEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitedUsersPaginationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvitedUsersPaginationResponse'] = ResolversParentTypes['InvitedUsersPaginationResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsHttpException'
    | 'InviteFriendsNotAuthorized'
    | 'InvitedUsersPaginatedList',
    ParentType,
    ContextType
  >;
};

export type JoinAuthorizationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinAuthorizationError'] = ResolversParentTypes['JoinAuthorizationError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JoinContentNotLiveErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinContentNotLiveError'] = ResolversParentTypes['JoinContentNotLiveError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  retryAfterSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  retryMaxDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JoinErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinError'] = ResolversParentTypes['JoinError'],
> = {
  __resolveType: TypeResolveFn<
    | 'JoinAuthorizationError'
    | 'JoinContentNotLiveError'
    | 'JoinNoActiveSubscriptionError'
    | 'JoinStreamLimitError',
    ParentType,
    ContextType
  >;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type JoinNoActiveSubscriptionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinNoActiveSubscriptionError'] = ResolversParentTypes['JoinNoActiveSubscriptionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JoinStreamLimitErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinStreamLimitError'] = ResolversParentTypes['JoinStreamLimitError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JoinTokenScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JoinToken'], any> {
  name: 'JoinToken';
}

export type JoinableClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinableClass'] = ResolversParentTypes['JoinableClass'],
> = {
  activeWorkout?: Resolver<
    Maybe<ResolversTypes['ActiveWorkout']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  videoStream?: Resolver<ResolversTypes['VideoStream'], ParentType, ContextType>;
  videoStreamOrError?: Resolver<
    ResolversTypes['VideoStreamOrError'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanebreakClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LanebreakClass'] = ResolversParentTypes['LanebreakClass'],
> = {
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  levelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardEntry'] = ResolversParentTypes['LeaderboardEntry'],
> = {
  completionPercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  difficulty?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workoutDetails?: Resolver<
    Maybe<ResolversTypes['LeaderboardWorkoutDetails']>,
    ParentType,
    ContextType
  >;
  workoutId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardWorkoutDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardWorkoutDetails'] = ResolversParentTypes['LeaderboardWorkoutDetails'],
> = {
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workoutId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LevelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Level'] = ResolversParentTypes['Level'],
> = {
  assets?: Resolver<ResolversTypes['OdysseyLevelAssets'], ParentType, ContextType>;
  availability?: Resolver<ResolversTypes['LevelAvailability'], ParentType, ContextType>;
  bestRuns?: Resolver<Maybe<ResolversTypes['BestRuns']>, ParentType, ContextType>;
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  difficultyDetails?: Resolver<
    Maybe<Array<ResolversTypes['OdysseyLevelDifficultyDetail']>>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  durationCategory?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  explicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  featuredArtists?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filterOptionIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  musicProvider?: Resolver<
    Maybe<ResolversTypes['MusicProvider']>,
    ParentType,
    ContextType
  >;
  playlistId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  theme?: Resolver<ResolversTypes['LevelTheme'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  volume?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LevelBrowseFiltersSuccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelBrowseFiltersSuccessResponse'] = ResolversParentTypes['LevelBrowseFiltersSuccessResponse'],
> = {
  browseFilters?: Resolver<
    Array<ResolversTypes['OdysseyLevelBrowseFilter']>,
    ParentType,
    ContextType
  >;
  filters?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilter']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LevelFiltersQueryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelFiltersQueryResponse'] = ResolversParentTypes['LevelFiltersQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'LevelBrowseFiltersSuccessResponse' | 'OdysseyBadRequestError',
    ParentType,
    ContextType
  >;
};

export type LevelListQueryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelListQueryResponse'] = ResolversParentTypes['LevelListQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'LevelListSuccessResponse' | 'OdysseyNotAuthorizedError',
    ParentType,
    ContextType
  >;
};

export type LevelListSuccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelListSuccessResponse'] = ResolversParentTypes['LevelListSuccessResponse'],
> = {
  levels?: Resolver<Array<ResolversTypes['Level']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LevelQueryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelQueryResponse'] = ResolversParentTypes['LevelQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'LevelSuccessResponse' | 'OdysseyItemNotFoundError',
    ParentType,
    ContextType
  >;
};

export type LevelSuccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelSuccessResponse'] = ResolversParentTypes['LevelSuccessResponse'],
> = {
  level?: Resolver<ResolversTypes['Level'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LevelThemeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LevelTheme'] = ResolversParentTypes['LevelTheme'],
> = {
  colorThemeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClass'] = ResolversParentTypes['LiveClass'],
> = {
  actualStartTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  airTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  captions?: Resolver<Maybe<ResolversTypes['Captions']>, ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  difficultyLevel?: Resolver<
    Maybe<ResolversTypes['DifficultyLevel']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  equipment?: Resolver<Array<ResolversTypes['Equipment']>, ParentType, ContextType>;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  explicitRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType>;
  instructorCues?: Resolver<
    Maybe<Array<ResolversTypes['InstructorCue']>>,
    ParentType,
    ContextType
  >;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEncore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExplicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFtpTest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  liveClassCategory?: Resolver<
    ResolversTypes['LiveClassCategory'],
    ParentType,
    ContextType
  >;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  peloton?: Resolver<ResolversTypes['PelotonResult'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playlist?: Resolver<Maybe<ResolversTypes['Playlist']>, ParentType, ContextType>;
  scheduledStartTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  scheduledStartTimeWithTapeDelay?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  streamStartTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  targetMetricsData?: Resolver<
    Maybe<ResolversTypes['TargetMetricsData']>,
    ParentType,
    ContextType
  >;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocaleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Locale'] = ResolversParentTypes['Locale'],
> = {
  code?: Resolver<ResolversTypes['LocaleCode'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media'],
> = {
  location?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetaTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MetaTag'] = ResolversParentTypes['MetaTag'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Metric'] = ResolversParentTypes['Metric'],
> = {
  lower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['MetricName']>, ParentType, ContextType>;
  upper?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MovementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Movement'] = ResolversParentTypes['Movement'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  leonardoExerciseIds?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  movementVariationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repetitionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subsegmentMappingId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  talkbackDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  targetNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  trackingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightsAndArms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MovementMuscleGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MovementMuscleGroup'] = ResolversParentTypes['MovementMuscleGroup'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  muscleGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ranking?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MovementVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MovementVideo'] = ResolversParentTypes['MovementVideo'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tokenExpiresAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MovementVideoType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MuscleGroupScoreResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MuscleGroupScore'] = ResolversParentTypes['MuscleGroupScore'],
> = {
  bucket?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  muscleGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MusicProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MusicProvider'] = ResolversParentTypes['MusicProvider'],
> = {
  __resolveType: TypeResolveFn<'PlaylistMusicProvider', ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  abandonOdysseyWorkout?: Resolver<
    ResolversTypes['AbandonOdysseyWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAbandonOdysseyWorkoutArgs, 'id'>
  >;
  addClassToSchedule?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddClassToScheduleArgs, 'scheduledClassInput'>
  >;
  addClassToStack?: Resolver<
    ResolversTypes['StackResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddClassToStackArgs, 'input'>
  >;
  addGymBookmark?: Resolver<
    ResolversTypes['GymBookmarkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddGymBookmarkArgs, 'input'>
  >;
  addHighFive?: Resolver<
    ResolversTypes['ActivityFeedEventResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAddHighFiveArgs,
      'createdAtForEvent' | 'userIdForEvent' | 'userIdForHighFive'
    >
  >;
  addTagToUser?: Resolver<
    ResolversTypes['AddTagToUserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTagToUserArgs, 'input'>
  >;
  bookmarkOdysseyLevel?: Resolver<
    ResolversTypes['OdysseyBookmarkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationBookmarkOdysseyLevelArgs, 'input'>
  >;
  cancelInvite?: Resolver<
    ResolversTypes['YourScheduleListResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelInviteArgs, 'inviteId'>
  >;
  completeOdysseyWorkout?: Resolver<
    ResolversTypes['CompleteOdysseyWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCompleteOdysseyWorkoutArgs, 'input'>
  >;
  createInviteShareLink?: Resolver<
    ResolversTypes['InviteShareURLResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateInviteShareLinkArgs, 'inviteId'>
  >;
  createLiveInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateLiveInviteArgs, 'createLiveInviteInput'>
  >;
  createNewOnDemandInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateNewOnDemandInviteArgs, 'createNewOnDemandInviteInput'>
  >;
  createOdysseyWorkout?: Resolver<
    ResolversTypes['CreateOdysseyWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOdysseyWorkoutArgs, 'input'>
  >;
  createScheduledOnDemandInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateScheduledOnDemandInviteArgs,
      'createScheduledOnDemandInviteInput'
    >
  >;
  deleteEntertainmentChannel?: Resolver<
    ResolversTypes['DeletedEntertainmentChannelResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteEntertainmentChannelArgs, 'uuid'>
  >;
  inviteUsersToInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationInviteUsersToInviteArgs, 'inviteUsersToInviteInput'>
  >;
  joinProgram?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinProgramArgs, 'programId'>
  >;
  leaveProgram?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLeaveProgramArgs, 'programProgressId'>
  >;
  modifyStack?: Resolver<
    ResolversTypes['StackResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationModifyStackArgs, 'input'>
  >;
  pauseWorkout?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MutationPauseWorkoutArgs, 'input'>
  >;
  playClassFromStack?: Resolver<
    ResolversTypes['StackResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationPlayClassFromStackArgs, 'input'>
  >;
  programWeekSeen?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationProgramWeekSeenArgs, 'programProgressId' | 'programWeekId'>
  >;
  removeClassFromSchedule?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveClassFromScheduleArgs, 'scheduledClassId'>
  >;
  removeGymBookmark?: Resolver<
    ResolversTypes['GymBookmarkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveGymBookmarkArgs, 'input'>
  >;
  removeHighFive?: Resolver<
    ResolversTypes['ActivityFeedEventResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationRemoveHighFiveArgs,
      'createdAtForEvent' | 'userIdForEvent' | 'userIdForHighFive'
    >
  >;
  removeTagFromUser?: Resolver<
    ResolversTypes['RemoveTagFromUserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveTagFromUserArgs, 'input'>
  >;
  replaceTag?: Resolver<
    ResolversTypes['ReplaceTagResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationReplaceTagArgs, 'input'>
  >;
  rescheduleClass?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRescheduleClassArgs, 'rescheduleClassInput'>
  >;
  respondToInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRespondToInviteArgs, 'inviteId' | 'status'>
  >;
  saveEntertainmentChannel?: Resolver<
    ResolversTypes['EntertainmentChannelWithTranslationsResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationSaveEntertainmentChannelArgs,
      'entertainmentChannelWithTranslationsInput'
    >
  >;
  setTagAsPrimary?: Resolver<
    ResolversTypes['SetTagAsPrimaryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetTagAsPrimaryArgs, 'input'>
  >;
  skipProgramClass?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSkipProgramClassArgs, 'programClassId' | 'programProgressId'>
  >;
  startFreestyleWorkout?: Resolver<
    ResolversTypes['StartFreestyleWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStartFreestyleWorkoutArgs, 'input'>
  >;
  startGymWorkout?: Resolver<
    ResolversTypes['StartGymWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStartGymWorkoutArgs, 'input'>
  >;
  startPelotonClass?: Resolver<
    ResolversTypes['JoinableClass'],
    ParentType,
    ContextType,
    RequireFields<MutationStartPelotonClassArgs, 'input'>
  >;
  startProgramClass?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationStartProgramClassArgs,
      'programClassId' | 'programProgressId' | 'workoutId'
    >
  >;
  unBookmarkOdysseyLevel?: Resolver<
    ResolversTypes['OdysseyBookmarkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUnBookmarkOdysseyLevelArgs, 'input'>
  >;
  unpauseWorkout?: Resolver<
    ResolversTypes['UnpauseWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpauseWorkoutArgs, 'input'>
  >;
};

export type NoClassPelotonForPlatformErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NoClassPelotonForPlatformError'] = ResolversParentTypes['NoClassPelotonForPlatformError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification'],
> = {
  activityFeedEvent?: Resolver<
    ResolversTypes['ActivityFeedEvent'],
    ParentType,
    ContextType
  >;
  highFivedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationDetails'] = ResolversParentTypes['NotificationDetails'],
> = {
  notifications?: Resolver<
    Array<ResolversTypes['Notification']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationDetailsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationDetailsResponse'] = ResolversParentTypes['NotificationDetailsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'NotificationDetails',
    ParentType,
    ContextType
  >;
};

export type OccasionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Occasion'] = ResolversParentTypes['Occasion'],
> = {
  assets?: Resolver<ResolversTypes['OccasionAssets'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  occasionSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  translatedOccasionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OccasionAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OccasionAssets'] = ResolversParentTypes['OccasionAssets'],
> = {
  largeImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  smallImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OccasionsListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OccasionsList'] = ResolversParentTypes['OccasionsList'],
> = {
  occasions?: Resolver<Array<ResolversTypes['Occasion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OccasionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OccasionsResponse'] = ResolversParentTypes['OccasionsResponse'],
> = {
  __resolveType: TypeResolveFn<'OccasionsList', ParentType, ContextType>;
};

export type OdysseyAccordionLevelFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyAccordionLevelFilter'] = ResolversParentTypes['OdysseyAccordionLevelFilter'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  options?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilterOption']>,
    ParentType,
    ContextType
  >;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyAccordionLevelFilterInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyAccordionLevelFilterInfo'] = ResolversParentTypes['OdysseyAccordionLevelFilterInfo'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyBadRequestErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBadRequestError'] = ResolversParentTypes['OdysseyBadRequestError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyBasicLevelFilterOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBasicLevelFilterOption'] = ResolversParentTypes['OdysseyBasicLevelFilterOption'],
> = {
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyBikeConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBikeConfiguration'] = ResolversParentTypes['OdysseyBikeConfiguration'],
> = {
  difficultyConfigs?: Resolver<
    Array<ResolversTypes['OdysseyBikeDifficultyConfiguration']>,
    ParentType,
    ContextType
  >;
  scoringConfig?: Resolver<
    ResolversTypes['OdysseyBikeScoringConfiguration'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyBikeDifficultyConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBikeDifficultyConfiguration'] = ResolversParentTypes['OdysseyBikeDifficultyConfiguration'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  laneConfigs?: Resolver<
    Array<ResolversTypes['OdysseyBikeLaneConfiguration']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyBikeLaneConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBikeLaneConfiguration'] = ResolversParentTypes['OdysseyBikeLaneConfiguration'],
> = {
  breakerAverageOutput?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxResistance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minResistance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyBikeScoringConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBikeScoringConfiguration'] = ResolversParentTypes['OdysseyBikeScoringConfiguration'],
> = {
  starThresholds?: Resolver<
    Array<ResolversTypes['OdysseyStarThreshold']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyBookmarkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBookmarkResponse'] = ResolversParentTypes['OdysseyBookmarkResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyBookmarkSuccessResponse'
    | 'OdysseyItemNotFoundError'
    | 'OdysseyNotAuthorizedError',
    ParentType,
    ContextType
  >;
};

export type OdysseyBookmarkSuccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyBookmarkSuccessResponse'] = ResolversParentTypes['OdysseyBookmarkSuccessResponse'],
> = {
  levelId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyConfigurationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyConfigurationResponse'] = ResolversParentTypes['OdysseyConfigurationResponse'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyBikeConfiguration' | 'OdysseyItemNotFoundError' | 'OdysseyTreadConfiguration',
    ParentType,
    ContextType
  >;
};

export type OdysseyDifficultyMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyDifficultyMetadata'] = ResolversParentTypes['OdysseyDifficultyMetadata'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyDurationLevelFilterOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyDurationLevelFilterOption'] = ResolversParentTypes['OdysseyDurationLevelFilterOption'],
> = {
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyError'] = ResolversParentTypes['OdysseyError'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyBadRequestError'
    | 'OdysseyInvalidWorkoutMutationError'
    | 'OdysseyItemNotFoundError'
    | 'OdysseyNotAuthorizedError',
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OdysseyFeedWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyFeedWorkout'] = ResolversParentTypes['OdysseyFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  odysseyImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odysseySubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odysseyWorkoutTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyGridLevelFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyGridLevelFilter'] = ResolversParentTypes['OdysseyGridLevelFilter'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  options?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilterOption']>,
    ParentType,
    ContextType
  >;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyGridLevelFilterInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyGridLevelFilterInfo'] = ResolversParentTypes['OdysseyGridLevelFilterInfo'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyIconResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyIcon'] = ResolversParentTypes['OdysseyIcon'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyInvalidWorkoutMutationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyInvalidWorkoutMutationError'] = ResolversParentTypes['OdysseyInvalidWorkoutMutationError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workoutId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyItemNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyItemNotFoundError'] = ResolversParentTypes['OdysseyItemNotFoundError'],
> = {
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLeaderboardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLeaderboard'] = ResolversParentTypes['OdysseyLeaderboard'],
> = {
  canFetchNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  extraWorkoutEntry?: Resolver<
    Maybe<ResolversTypes['LeaderboardEntry']>,
    ParentType,
    ContextType
  >;
  leaderboard?: Resolver<
    Array<ResolversTypes['LeaderboardEntry']>,
    ParentType,
    ContextType
  >;
  startRank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLeaderboardGraphqlErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardGraphqlError'] = ResolversParentTypes['OdysseyLeaderboardGraphqlError'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyLeaderboardInvalidRequest',
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OdysseyLeaderboardInvalidRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardInvalidRequest'] = ResolversParentTypes['OdysseyLeaderboardInvalidRequest'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLeaderboardListSuccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardListSuccessResponse'] = ResolversParentTypes['OdysseyLeaderboardListSuccessResponse'],
> = {
  leaderboard?: Resolver<ResolversTypes['OdysseyLeaderboard'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLeaderboardQueryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardQueryResponse'] = ResolversParentTypes['OdysseyLeaderboardQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyLeaderboardInvalidRequest' | 'OdysseyLeaderboardListSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type OdysseyLevelAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelAssets'] = ResolversParentTypes['OdysseyLevelAssets'],
> = {
  detailImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unityBundleUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelBrowseFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelBrowseFilter'] = ResolversParentTypes['OdysseyLevelBrowseFilter'],
> = {
  info?: Resolver<ResolversTypes['OdysseyLevelFilterInfo'], ParentType, ContextType>;
  options?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilterOption']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelDifficultyDetailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelDifficultyDetail'] = ResolversParentTypes['OdysseyLevelDifficultyDetail'],
> = {
  midiFileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odysseyLevelTargetMetricsData?: Resolver<
    Maybe<ResolversTypes['OdysseyLevelTargetMetricsData']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelFilter'] = ResolversParentTypes['OdysseyLevelFilter'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyAccordionLevelFilter' | 'OdysseyGridLevelFilter' | 'OdysseyToggleLevelFilter',
    ParentType,
    ContextType
  >;
};

export type OdysseyLevelFilterInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelFilterInfo'] = ResolversParentTypes['OdysseyLevelFilterInfo'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyAccordionLevelFilterInfo'
    | 'OdysseyGridLevelFilterInfo'
    | 'OdysseyToggleLevelFilterInfo',
    ParentType,
    ContextType
  >;
};

export type OdysseyLevelFilterOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelFilterOption'] = ResolversParentTypes['OdysseyLevelFilterOption'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyBasicLevelFilterOption'
    | 'OdysseyDurationLevelFilterOption'
    | 'OdysseyPlayedLevelFilterOption'
    | 'OdysseyStarsLevelFilterOption',
    ParentType,
    ContextType
  >;
};

export type OdysseyLevelMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelMetric'] = ResolversParentTypes['OdysseyLevelMetric'],
> = {
  lower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<
    Maybe<ResolversTypes['OdysseyLevelMetricName']>,
    ParentType,
    ContextType
  >;
  upper?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelOffsetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelOffset'] = ResolversParentTypes['OdysseyLevelOffset'],
> = {
  end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelPaceIntensityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelPaceIntensity'] = ResolversParentTypes['OdysseyLevelPaceIntensity'],
> = {
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paceLevels?: Resolver<
    Array<Maybe<ResolversTypes['OdysseyLevelPaceLevel']>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelPaceLevelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelPaceLevel'] = ResolversParentTypes['OdysseyLevelPaceLevel'],
> = {
  fastPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  slowPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelTargetMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelTargetMetrics'] = ResolversParentTypes['OdysseyLevelTargetMetrics'],
> = {
  metrics?: Resolver<
    Array<ResolversTypes['OdysseyLevelMetric']>,
    ParentType,
    ContextType
  >;
  offsets?: Resolver<ResolversTypes['OdysseyLevelOffset'], ParentType, ContextType>;
  segmentType?: Resolver<
    Maybe<ResolversTypes['OdysseyLevelSegmentTypes']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelTargetMetricsDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelTargetMetricsData'] = ResolversParentTypes['OdysseyLevelTargetMetricsData'],
> = {
  paceIntensities?: Resolver<
    Array<Maybe<ResolversTypes['OdysseyLevelPaceIntensity']>>,
    ParentType,
    ContextType
  >;
  targetMetrics?: Resolver<
    Array<ResolversTypes['OdysseyLevelTargetMetrics']>,
    ParentType,
    ContextType
  >;
  totalExpectedOutput?: Resolver<
    Maybe<ResolversTypes['OdysseyLevelTargetMetricsOutput']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyLevelTargetMetricsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyLevelTargetMetricsOutput'] = ResolversParentTypes['OdysseyLevelTargetMetricsOutput'],
> = {
  expectedLowerOutput?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expectedUpperOutput?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyNotAuthorizedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyNotAuthorizedError'] = ResolversParentTypes['OdysseyNotAuthorizedError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyPlayedLevelFilterOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyPlayedLevelFilterOption'] = ResolversParentTypes['OdysseyPlayedLevelFilterOption'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyStarThresholdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyStarThreshold'] = ResolversParentTypes['OdysseyStarThreshold'],
> = {
  completionPercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  starsEarned?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyStarsLevelFilterOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyStarsLevelFilterOption'] = ResolversParentTypes['OdysseyStarsLevelFilterOption'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyToggleLevelFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyToggleLevelFilter'] = ResolversParentTypes['OdysseyToggleLevelFilter'],
> = {
  defaultValue?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyToggleLevelFilterInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyToggleLevelFilterInfo'] = ResolversParentTypes['OdysseyToggleLevelFilterInfo'],
> = {
  defaultValue?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyTreadConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyTreadConfiguration'] = ResolversParentTypes['OdysseyTreadConfiguration'],
> = {
  difficultyConfigs?: Resolver<
    Array<ResolversTypes['OdysseyTreadDifficultyConfiguration']>,
    ParentType,
    ContextType
  >;
  scoringConfig?: Resolver<
    ResolversTypes['OdysseyTreadScoringConfiguration'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyTreadDifficultyConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyTreadDifficultyConfiguration'] = ResolversParentTypes['OdysseyTreadDifficultyConfiguration'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  intensities?: Resolver<
    Array<ResolversTypes['OdysseyTreadDifficultyIntensity']>,
    ParentType,
    ContextType
  >;
  laneConfigs?: Resolver<
    Array<ResolversTypes['OdysseyTreadLaneConfiguration']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  speedModifier?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyTreadDifficultyIntensityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyTreadDifficultyIntensity'] = ResolversParentTypes['OdysseyTreadDifficultyIntensity'],
> = {
  key?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxSpeed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minSpeed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyTreadLaneConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyTreadLaneConfiguration'] = ResolversParentTypes['OdysseyTreadLaneConfiguration'],
> = {
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxIncline?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minIncline?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyTreadScoringConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyTreadScoringConfiguration'] = ResolversParentTypes['OdysseyTreadScoringConfiguration'],
> = {
  starThresholds?: Resolver<
    Array<ResolversTypes['OdysseyStarThreshold']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyWorkout'] = ResolversParentTypes['OdysseyWorkout'],
> = {
  beatCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blastCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breakerCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  completionPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  hillCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  streamCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OdysseyWorkoutQueryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyWorkoutQueryResponse'] = ResolversParentTypes['OdysseyWorkoutQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyItemNotFoundError'
    | 'OdysseyNotAuthorizedError'
    | 'OdysseyWorkoutSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type OdysseyWorkoutSuccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OdysseyWorkoutSuccessResponse'] = ResolversParentTypes['OdysseyWorkoutSuccessResponse'],
> = {
  workout?: Resolver<ResolversTypes['OdysseyWorkout'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OffsetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Offset'] = ResolversParentTypes['Offset'],
> = {
  end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OffsetsCueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OffsetsCue'] = ResolversParentTypes['OffsetsCue'],
> = {
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnDemandInstructorClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnDemandInstructorClass'] = ResolversParentTypes['OnDemandInstructorClass'],
> = {
  airTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  captions?: Resolver<Maybe<ResolversTypes['Captions']>, ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentFormat?: Resolver<ResolversTypes['ContentFormat'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  difficultyLevel?: Resolver<
    Maybe<ResolversTypes['DifficultyLevel']>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  equipment?: Resolver<Array<ResolversTypes['Equipment']>, ParentType, ContextType>;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  explicitRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType>;
  instructorCues?: Resolver<
    Maybe<Array<ResolversTypes['InstructorCue']>>,
    ParentType,
    ContextType
  >;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExplicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFtpTest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  originalAirTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playlist?: Resolver<Maybe<ResolversTypes['Playlist']>, ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  targetMetricsData?: Resolver<
    Maybe<ResolversTypes['TargetMetricsData']>,
    ParentType,
    ContextType
  >;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topTags?: Resolver<
    Array<ResolversTypes['ClassTopTag']>,
    ParentType,
    ContextType,
    RequireFields<OnDemandInstructorClassTopTagsArgs, 'limit' | 'sampledFrom'>
  >;
  totalUserWorkouts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaceIntensityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaceIntensity'] = ResolversParentTypes['PaceIntensity'],
> = {
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paceLevels?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaceLevel']>>>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaceLevelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaceLevel'] = ResolversParentTypes['PaceLevel'],
> = {
  fastPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  slowPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginatedList'] = ResolversParentTypes['PaginatedList'],
> = {
  __resolveType: TypeResolveFn<
    | 'ActiveTagClassesPaginatedList'
    | 'ActivityFeedEventPaginationResponse'
    | 'EntertainmentChannelPaginatedList'
    | 'FollowingUsersPaginatedList'
    | 'GymPlanPaginationResponse'
    | 'TagPaginatedList'
    | 'UserPaginatedList',
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
};

export type PauseInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PauseInfo'] = ResolversParentTypes['PauseInfo'],
> = {
  isPaused?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pauseTimeElapsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pauseTimeLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Payload'] = ResolversParentTypes['Payload'],
> = {
  __resolveType: TypeResolveFn<
    | 'AchievementAwardedEventPayload'
    | 'StreakAwardedEventPayload'
    | 'WorkoutFinalizedEventPayload',
    ParentType,
    ContextType
  >;
};

export type PelotonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Peloton'] = ResolversParentTypes['Peloton'],
> = {
  pelotonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PelotonClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PelotonClass'] = ResolversParentTypes['PelotonClass'],
> = {
  __resolveType: TypeResolveFn<
    'LanebreakClass' | 'LiveClass' | 'OnDemandInstructorClass' | 'ScenicClass',
    ParentType,
    ContextType
  >;
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type PelotonClassFeedWorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PelotonClassFeedWorkout'] = ResolversParentTypes['PelotonClassFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isOutdoor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PelotonNotCreatedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PelotonNotCreatedError'] = ResolversParentTypes['PelotonNotCreatedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PelotonResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PelotonResult'] = ResolversParentTypes['PelotonResult'],
> = {
  __resolveType: TypeResolveFn<
    'NoClassPelotonForPlatformError' | 'Peloton' | 'PelotonNotCreatedError',
    ParentType,
    ContextType
  >;
};

export type PendingInvitesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingInvites'] = ResolversParentTypes['PendingInvites'],
> = {
  countOfPendingInvites?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pendingInvitesList?: Resolver<
    Array<ResolversTypes['InviteDetails']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingInvitesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingInvitesResponse'] = ResolversParentTypes['PendingInvitesResponse'],
> = {
  __resolveType: TypeResolveFn<
    'InviteFriendsItemNotFoundError' | 'PendingInvites',
    ParentType,
    ContextType
  >;
};

export type PermissionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PermissionError'] = ResolversParentTypes['PermissionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaylistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Playlist'] = ResolversParentTypes['Playlist'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInClassMusicShown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playbackSongs?: Resolver<
    Array<ResolversTypes['PlaylistPlaybackSong']>,
    ParentType,
    ContextType
  >;
  songs?: Resolver<Array<ResolversTypes['Song']>, ParentType, ContextType>;
  topArtists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaylistMusicProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlaylistMusicProvider'] = ResolversParentTypes['PlaylistMusicProvider'],
> = {
  isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playlist?: Resolver<ResolversTypes['StreamablePlaylist'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaylistPlaybackSongResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlaylistPlaybackSong'] = ResolversParentTypes['PlaylistPlaybackSong'],
> = {
  cueOffset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  song?: Resolver<ResolversTypes['Song'], ParentType, ContextType>;
  streamOffset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PopularTagClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PopularTagClass'] = ResolversParentTypes['PopularTagClass'],
> = {
  countUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PopularTagClassesListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PopularTagClassesList'] = ResolversParentTypes['PopularTagClassesList'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  popularClasses?: Resolver<
    Array<ResolversTypes['PopularTagClass']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Program'] = ResolversParentTypes['Program'],
> = {
  achievements?: Resolver<
    Array<ResolversTypes['ProgramAchievementTier']>,
    ParentType,
    ContextType
  >;
  assets?: Resolver<ResolversTypes['ProgramAssets'], ParentType, ContextType>;
  classes?: Resolver<Array<ResolversTypes['ProgramClass']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  equipment?: Resolver<Array<ResolversTypes['Equipment']>, ParentType, ContextType>;
  fitnessDisciplines?: Resolver<
    Array<ResolversTypes['ProgramFitnessDiscipline']>,
    ParentType,
    ContextType
  >;
  flags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isLimitedContent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  programClassesLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  programMetaData?: Resolver<ResolversTypes['ProgramMetaData'], ParentType, ContextType>;
  programSchedule?: Resolver<ResolversTypes['ProgramSchedule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramAchievementAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramAchievementAssets'] = ResolversParentTypes['ProgramAchievementAssets'],
> = {
  badgeImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['Color'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramAchievementTierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramAchievementTier'] = ResolversParentTypes['ProgramAchievementTier'],
> = {
  assets?: Resolver<ResolversTypes['ProgramAchievementAssets'], ParentType, ContextType>;
  badgeDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  badgeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  numClassesRequired?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tierName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramAssets'] = ResolversParentTypes['ProgramAssets'],
> = {
  heroImage?: Resolver<ResolversTypes['HeroImageAssets'], ParentType, ContextType>;
  multipleInstructorOnboardingImage?: Resolver<
    Maybe<ResolversTypes['Media']>,
    ParentType,
    ContextType
  >;
  thumbnailImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramClass'] = ResolversParentTypes['ProgramClass'],
> = {
  classNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  classProgress?: Resolver<
    Maybe<ResolversTypes['ProgramClassProgress']>,
    ParentType,
    ContextType,
    RequireFields<ProgramClassClassProgressArgs, 'programProgressId'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  pelotonClassId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramClassProgressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramClassProgress'] = ResolversParentTypes['ProgramClassProgress'],
> = {
  classNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  programClass?: Resolver<ResolversTypes['ProgramClass'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['ProgramClassProgressStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramDayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramDay'] = ResolversParentTypes['ProgramDay'],
> = {
  classes?: Resolver<Array<ResolversTypes['ProgramClass']>, ParentType, ContextType>;
  dayNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramFitnessDisciplineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramFitnessDiscipline'] = ResolversParentTypes['ProgramFitnessDiscipline'],
> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramMetaDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramMetaData'] = ResolversParentTypes['ProgramMetaData'],
> = {
  daysPerWeek?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  highMinsPerDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lowMinsPerDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramProgressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramProgress'] = ResolversParentTypes['ProgramProgress'],
> = {
  awardedAchievement?: Resolver<
    ResolversTypes['AwardedProgramAchievementTier'],
    ParentType,
    ContextType
  >;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  program?: Resolver<ResolversTypes['Program'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  weeklyProgress?: Resolver<
    Array<ResolversTypes['ProgramWeekProgress']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramProgressAlreadyExistsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramProgressAlreadyExistsError'] = ResolversParentTypes['ProgramProgressAlreadyExistsError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramProgressNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramProgressNotFoundError'] = ResolversParentTypes['ProgramProgressNotFoundError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramProgressResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramProgressResponse'] = ResolversParentTypes['ProgramProgressResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'ProgramProgressAlreadyExistsError'
    | 'ProgramProgressNotFoundError'
    | 'ProgramProgressResponseSuccess'
    | 'UserNotAuthorizedError',
    ParentType,
    ContextType
  >;
};

export type ProgramProgressResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramProgressResponseSuccess'] = ResolversParentTypes['ProgramProgressResponseSuccess'],
> = {
  programProgress?: Resolver<
    Maybe<ResolversTypes['ProgramProgress']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramScheduleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramSchedule'] = ResolversParentTypes['ProgramSchedule'],
> = {
  weeks?: Resolver<Array<ResolversTypes['ProgramWeek']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramWeekResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramWeek'] = ResolversParentTypes['ProgramWeek'],
> = {
  days?: Resolver<Array<ResolversTypes['ProgramDay']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  weekNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekProgress?: Resolver<
    Maybe<ResolversTypes['ProgramWeekProgress']>,
    ParentType,
    ContextType,
    RequireFields<ProgramWeekWeekProgressArgs, 'programProgressId'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramWeekProgressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramWeekProgress'] = ResolversParentTypes['ProgramWeekProgress'],
> = {
  classesProgress?: Resolver<
    Array<Maybe<ResolversTypes['ProgramClassProgress']>>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  seen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ProgramWeekProgressStatus'], ParentType, ContextType>;
  weekNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  allEntertainmentChannelsWithTranslations?: Resolver<
    ResolversTypes['EntertainmentChannelWithTranslationsResponse'],
    ParentType,
    ContextType
  >;
  checkDoesClassExistInUserStack?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<QueryCheckDoesClassExistInUserStackArgs, 'pelotonClassId'>
  >;
  completedPrograms?: Resolver<
    Array<ResolversTypes['ProgramProgress']>,
    ParentType,
    ContextType,
    Partial<QueryCompletedProgramsArgs>
  >;
  currentUserTags?: Resolver<ResolversTypes['UserTags'], ParentType, ContextType>;
  entertainmentChannels?: Resolver<
    ResolversTypes['EntertainmentChannelsQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryEntertainmentChannelsArgs, 'cursor' | 'limit'>
  >;
  filterPrograms?: Resolver<
    Array<ResolversTypes['Program']>,
    ParentType,
    ContextType,
    RequireFields<QueryFilterProgramsArgs, 'programParams'>
  >;
  findEntertainmentChannelWithTranslationById?: Resolver<
    ResolversTypes['EntertainmentChannelWithTranslationsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryFindEntertainmentChannelWithTranslationByIdArgs, 'uuid'>
  >;
  freestyleMetadata?: Resolver<
    ResolversTypes['FreestyleMetadata'],
    ParentType,
    ContextType
  >;
  getEventDetails?: Resolver<
    ResolversTypes['EventDetailsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetEventDetailsArgs, 'activityFeedEventId'>
  >;
  getHighFiveDetailsForEvent?: Resolver<
    ResolversTypes['HighFiveDetailsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetHighFiveDetailsForEventArgs, 'eventCreatedAt' | 'eventUserId'>
  >;
  getNotificationDetails?: Resolver<
    ResolversTypes['NotificationDetailsResponse'],
    ParentType,
    ContextType
  >;
  getNumberOfUnreadNotifications?: Resolver<
    ResolversTypes['UnreadNotificationsResponse'],
    ParentType,
    ContextType
  >;
  getPresignedUrl?: Resolver<
    ResolversTypes['EntertainmentChannelPresignedUrlQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetPresignedUrlArgs, 'uuid'>
  >;
  getWorkoutOfTheDay?: Resolver<
    Maybe<ResolversTypes['WorkoutOfTheDayDetails']>,
    ParentType,
    ContextType
  >;
  gymBookmarks?: Resolver<
    ResolversTypes['GymPlanResponse'],
    ParentType,
    ContextType,
    Partial<QueryGymBookmarksArgs>
  >;
  gymPlan?: Resolver<
    ResolversTypes['GymPlanResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGymPlanArgs, 'gymPlanId'>
  >;
  gymPlanFilterChips?: Resolver<
    Array<ResolversTypes['GymPlanFilterChip']>,
    ParentType,
    ContextType,
    Partial<QueryGymPlanFilterChipsArgs>
  >;
  gymPlanQueryFilterOptions?: Resolver<
    ResolversTypes['GymPlanQueryFilterOptions'],
    ParentType,
    ContextType
  >;
  gymPlans?: Resolver<
    ResolversTypes['GymPlanResponse'],
    ParentType,
    ContextType,
    Partial<QueryGymPlansArgs>
  >;
  inviteDetails?: Resolver<
    ResolversTypes['YourScheduleItem'],
    ParentType,
    ContextType,
    RequireFields<QueryInviteDetailsArgs, 'inviteId'>
  >;
  joinableClass?: Resolver<
    ResolversTypes['JoinableClass'],
    ParentType,
    ContextType,
    RequireFields<QueryJoinableClassArgs, 'id'>
  >;
  level?: Resolver<
    ResolversTypes['LevelQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryLevelArgs, 'id'>
  >;
  levels?: Resolver<ResolversTypes['LevelListQueryResponse'], ParentType, ContextType>;
  nextProgramClass?: Resolver<
    Maybe<ResolversTypes['ProgramClass']>,
    ParentType,
    ContextType,
    RequireFields<QueryNextProgramClassArgs, 'programProgressId'>
  >;
  occasions?: Resolver<
    ResolversTypes['OccasionsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOccasionsArgs, 'fitnessDiscipline'>
  >;
  odysseyConfiguration?: Resolver<
    ResolversTypes['OdysseyConfigurationResponse'],
    ParentType,
    ContextType
  >;
  odysseyDifficultyMetadata?: Resolver<
    Array<ResolversTypes['OdysseyDifficultyMetadata']>,
    ParentType,
    ContextType
  >;
  odysseyFriendsLeaderboard?: Resolver<
    ResolversTypes['OdysseyLeaderboardQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOdysseyFriendsLeaderboardArgs, 'leaderboardRequest'>
  >;
  odysseyLeaderboard?: Resolver<
    ResolversTypes['OdysseyLeaderboardQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOdysseyLeaderboardArgs, 'leaderboardRequest'>
  >;
  odysseyLevelBrowseFilters?: Resolver<
    ResolversTypes['LevelFiltersQueryResponse'],
    ParentType,
    ContextType
  >;
  odysseyUserLeaderboard?: Resolver<
    ResolversTypes['OdysseyLeaderboardQueryResponse'],
    ParentType,
    ContextType,
    Partial<QueryOdysseyUserLeaderboardArgs>
  >;
  odysseyWorkout?: Resolver<
    ResolversTypes['OdysseyWorkoutQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOdysseyWorkoutArgs, 'id'>
  >;
  pelotonClass?: Resolver<
    ResolversTypes['PelotonClass'],
    ParentType,
    ContextType,
    RequireFields<QueryPelotonClassArgs, 'joinToken'>
  >;
  program?: Resolver<
    ResolversTypes['Program'],
    ParentType,
    ContextType,
    RequireFields<QueryProgramArgs, 'programId'>
  >;
  programProgress?: Resolver<
    ResolversTypes['ProgramProgress'],
    ParentType,
    ContextType,
    RequireFields<QueryProgramProgressArgs, 'programProgressId'>
  >;
  programs?: Resolver<Array<ResolversTypes['Program']>, ParentType, ContextType>;
  programsById?: Resolver<
    Array<ResolversTypes['Program']>,
    ParentType,
    ContextType,
    RequireFields<QueryProgramsByIdArgs, 'programIds'>
  >;
  progressOfPrograms?: Resolver<
    Array<ResolversTypes['ProgramProgress']>,
    ParentType,
    ContextType,
    Partial<QueryProgressOfProgramsArgs>
  >;
  scheduledClass?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryScheduledClassArgs, 'scheduledClassId'>
  >;
  searchTag?: Resolver<
    ResolversTypes['TagPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<QuerySearchTagArgs, 'input'>
  >;
  session?: Resolver<
    Maybe<ResolversTypes['SessionClass']>,
    ParentType,
    ContextType,
    RequireFields<QuerySessionArgs, 'sessionInput'>
  >;
  streamablePlaylist?: Resolver<
    ResolversTypes['StreamablePlaylist'],
    ParentType,
    ContextType,
    RequireFields<QueryStreamablePlaylistArgs, 'playlistId'>
  >;
  tag?: Resolver<
    Maybe<ResolversTypes['Tag']>,
    ParentType,
    ContextType,
    RequireFields<QueryTagArgs, 'tagName'>
  >;
  tagBrowseCategory?: Resolver<
    ResolversTypes['TagBrowseCategory'],
    ParentType,
    ContextType,
    RequireFields<QueryTagBrowseCategoryArgs, 'categorySlug'>
  >;
  user?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<QueryUserArgs>
  >;
  userActivityFeed?: Resolver<
    ResolversTypes['ActivityFeedEventResponse'],
    ParentType,
    ContextType,
    Partial<QueryUserActivityFeedArgs>
  >;
  userProgramAchievements?: Resolver<
    Array<ResolversTypes['AwardedProgramAchievementTier']>,
    ParentType,
    ContextType
  >;
  userScheduledItemsList?: Resolver<
    ResolversTypes['YourScheduleListResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryUserScheduledItemsListArgs, 'endTime' | 'startTime'>
  >;
  viewUserStack?: Resolver<ResolversTypes['StackResponse'], ParentType, ContextType>;
  workoutPostClassDetails?: Resolver<
    ResolversTypes['WorkoutPostClassResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryWorkoutPostClassDetailsArgs, 'isWorkoutFromStack' | 'workoutId'>
  >;
  workoutProgramAchievements?: Resolver<
    Array<ResolversTypes['AwardedProgramAchievementTier']>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkoutProgramAchievementsArgs, 'workoutId'>
  >;
};

export type RangeCueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RangeCue'] = ResolversParentTypes['RangeCue'],
> = {
  lower?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  upper?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReadyVideoStreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReadyVideoStream'] = ResolversParentTypes['ReadyVideoStream'],
> = {
  videoStream?: Resolver<ResolversTypes['VideoStream'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveTagFromUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveTagFromUserResponse'] = ResolversParentTypes['RemoveTagFromUserResponse'],
> = {
  __resolveType: TypeResolveFn<
    'DoesNotExistError' | 'RemoveTagFromUserSuccess',
    ParentType,
    ContextType
  >;
};

export type RemoveTagFromUserSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveTagFromUserSuccess'] = ResolversParentTypes['RemoveTagFromUserSuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReplaceTagResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReplaceTagResponse'] = ResolversParentTypes['ReplaceTagResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'DoesNotExistError'
    | 'ReplaceTagSuccess'
    | 'TagAlreadyExistsError'
    | 'TagNameInvalidCharacterError'
    | 'TagNameLengthError'
    | 'TagNameProhibitedError',
    ParentType,
    ContextType
  >;
};

export type ReplaceTagSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReplaceTagSuccess'] = ResolversParentTypes['ReplaceTagSuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RetryableErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RetryableError'] = ResolversParentTypes['RetryableError'],
> = {
  __resolveType: TypeResolveFn<'JoinContentNotLiveError', ParentType, ContextType>;
  retryAfterSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  retryMaxDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type ScenicClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScenicClass'] = ResolversParentTypes['ScenicClass'],
> = {
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  captions?: Resolver<Maybe<ResolversTypes['Captions']>, ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distanceUnit?: Resolver<Maybe<ResolversTypes['DistanceUnit']>, ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dynamicVideoRecordedSpeedInMph?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<Maybe<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDynamicVideoEligible?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isFixedDistance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  musicProviders?: Resolver<
    Array<Maybe<ResolversTypes['MusicProvider']>>,
    ParentType,
    ContextType
  >;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  targetMetricsData?: Resolver<
    Maybe<ResolversTypes['TargetMetricsData']>,
    ParentType,
    ContextType
  >;
  thumbnailLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topTags?: Resolver<
    Array<ResolversTypes['ClassTopTag']>,
    ParentType,
    ContextType,
    RequireFields<ScenicClassTopTagsArgs, 'limit' | 'sampledFrom'>
  >;
  totalUserWorkouts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoEmbeddedPlaylist?: Resolver<
    Maybe<ResolversTypes['Playlist']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledClass'] = ResolversParentTypes['ScheduledClass'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isScheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  onDemandJoinToken?: Resolver<
    Maybe<ResolversTypes['JoinToken']>,
    ParentType,
    ContextType
  >;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  pelotonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduleSource?: Resolver<ResolversTypes['ScheduleSource'], ParentType, ContextType>;
  scheduledEndTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  scheduledStartTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  scheduledUsers?: Resolver<
    ResolversTypes['UserPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<ScheduledClassScheduledUsersArgs, 'limit'>
  >;
  totalUsersCountedIn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalWorkouts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledClassDoesNotExistErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledClassDoesNotExistError'] = ResolversParentTypes['ScheduledClassDoesNotExistError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledClassListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledClassList'] = ResolversParentTypes['ScheduledClassList'],
> = {
  scheduledClasses?: Resolver<
    Array<ResolversTypes['ScheduledClass']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledClassListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledClassListResponse'] = ResolversParentTypes['ScheduledClassListResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ScheduledClassList' | 'UserPrivacyError',
    ParentType,
    ContextType
  >;
};

export type ScheduledClassResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledClassResponse'] = ResolversParentTypes['ScheduledClassResponse'],
> = {
  __resolveType: TypeResolveFn<
    'CannotScheduleClassError' | 'ScheduledClass' | 'ScheduledClassDoesNotExistError',
    ParentType,
    ContextType
  >;
};

export type SessionClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionClass'] = ResolversParentTypes['SessionClass'],
> = {
  liveClass?: Resolver<ResolversTypes['LiveClass'], ParentType, ContextType>;
  onDemandJoinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetTagAsPrimaryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SetTagAsPrimaryResponse'] = ResolversParentTypes['SetTagAsPrimaryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'SetTagAsPrimarySuccess' | 'TagDoesNotExistOnUserError',
    ParentType,
    ContextType
  >;
};

export type SetTagAsPrimarySuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SetTagAsPrimarySuccess'] = ResolversParentTypes['SetTagAsPrimarySuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShareUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShareURL'] = ResolversParentTypes['ShareURL'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SongResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Song'] = ResolversParentTypes['Song'],
> = {
  album?: Resolver<ResolversTypes['Album'], ParentType, ContextType>;
  artists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
  cueTimeOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  explicitRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startTimeOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackConnectionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackConnectionError'] = ResolversParentTypes['StackConnectionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackDoesNotExistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackDoesNotExist'] = ResolversParentTypes['StackDoesNotExist'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackError'] = ResolversParentTypes['StackError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackPermissionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackPermissionError'] = ResolversParentTypes['StackPermissionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackResponse'] = ResolversParentTypes['StackResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'StackConnectionError'
    | 'StackDoesNotExist'
    | 'StackError'
    | 'StackPermissionError'
    | 'StackResponseSuccess'
    | 'StackedClassAlreadyExistsError'
    | 'StackedClassCannotBeAddedError'
    | 'StackedClassConstraintViolationError'
    | 'StackedClassDoesNotExistInStackError'
    | 'StackedClassInvalidPelotonClassIdError'
    | 'StackedClassLimitReachedError',
    ParentType,
    ContextType
  >;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
};

export type StackResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackResponseSuccess'] = ResolversParentTypes['StackResponseSuccess'],
> = {
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackedClass'] = ResolversParentTypes['StackedClass'],
> = {
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  pelotonClassId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stackId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedClassAlreadyExistsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackedClassAlreadyExistsError'] = ResolversParentTypes['StackedClassAlreadyExistsError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedClassCannotBeAddedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackedClassCannotBeAddedError'] = ResolversParentTypes['StackedClassCannotBeAddedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedClassConstraintViolationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackedClassConstraintViolationError'] = ResolversParentTypes['StackedClassConstraintViolationError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedClassDoesNotExistInStackErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackedClassDoesNotExistInStackError'] = ResolversParentTypes['StackedClassDoesNotExistInStackError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedClassInvalidPelotonClassIdErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackedClassInvalidPelotonClassIdError'] = ResolversParentTypes['StackedClassInvalidPelotonClassIdError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedClassLimitReachedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StackedClassLimitReachedError'] = ResolversParentTypes['StackedClassLimitReachedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartFreestyleWorkoutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StartFreestyleWorkoutResponse'] = ResolversParentTypes['StartFreestyleWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'StartFreestyleWorkoutResponseSuccess'
    | 'SubscriptionIdNotFoundError'
    | 'UserNotAuthorizedError',
    ParentType,
    ContextType
  >;
};

export type StartFreestyleWorkoutResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StartFreestyleWorkoutResponseSuccess'] = ResolversParentTypes['StartFreestyleWorkoutResponseSuccess'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartGymWorkoutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StartGymWorkoutResponse'] = ResolversParentTypes['StartGymWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GymErrorResponse' | 'StartGymWorkoutResponseSuccess',
    ParentType,
    ContextType
  >;
};

export type StartGymWorkoutResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StartGymWorkoutResponseSuccess'] = ResolversParentTypes['StartGymWorkoutResponseSuccess'],
> = {
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workout?: Resolver<ResolversTypes['GymWorkout'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreakAwardedEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreakAwardedEventPayload'] = ResolversParentTypes['StreakAwardedEventPayload'],
> = {
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streakNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamablePlaylistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreamablePlaylist'] = ResolversParentTypes['StreamablePlaylist'],
> = {
  artImage?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInClassMusicShown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playbackSongs?: Resolver<
    Array<ResolversTypes['PlaylistPlaybackSong']>,
    ParentType,
    ContextType
  >;
  previewArtImage?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  stream?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  streamId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  topArtists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionIdNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionIdNotFoundError'] = ResolversParentTypes['SubscriptionIdNotFoundError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag'],
> = {
  activeClass?: Resolver<
    ResolversTypes['ActiveTagClass'],
    ParentType,
    ContextType,
    RequireFields<TagActiveClassArgs, 'joinToken'>
  >;
  activeClasses?: Resolver<
    ResolversTypes['ActiveTagClassesPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<TagActiveClassesArgs, 'after' | 'limit'>
  >;
  assets?: Resolver<ResolversTypes['TagAssets'], ParentType, ContextType>;
  exists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  followingCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasAdded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metaTag?: Resolver<Maybe<ResolversTypes['MetaTag']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularClasses?: Resolver<
    ResolversTypes['PopularTagClassesList'],
    ParentType,
    ContextType,
    RequireFields<TagPopularClassesArgs, 'limit'>
  >;
  totalInProgressWorkouts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  users?: Resolver<
    ResolversTypes['UserPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<TagUsersArgs, 'after' | 'limit'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagAlreadyExistsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagAlreadyExistsError'] = ResolversParentTypes['TagAlreadyExistsError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagAssets'] = ResolversParentTypes['TagAssets'],
> = {
  backgroundImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  detailBackgroundImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagBrowseCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagBrowseCategory'] = ResolversParentTypes['TagBrowseCategory'],
> = {
  assets?: Resolver<ResolversTypes['TagBrowseCategoryAssets'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['TagBrowseCategorySlug'], ParentType, ContextType>;
  tags?: Resolver<
    ResolversTypes['TagPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<TagBrowseCategoryTagsArgs, 'after' | 'limit'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagBrowseCategoryAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagBrowseCategoryAssets'] = ResolversParentTypes['TagBrowseCategoryAssets'],
> = {
  icon?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagCapacityOverLimitErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagCapacityOverLimitError'] = ResolversParentTypes['TagCapacityOverLimitError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagDoesNotExistOnUserErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagDoesNotExistOnUserError'] = ResolversParentTypes['TagDoesNotExistOnUserError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagEdge'] = ResolversParentTypes['TagEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagNameInvalidCharacterErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagNameInvalidCharacterError'] = ResolversParentTypes['TagNameInvalidCharacterError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagNameLengthErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagNameLengthError'] = ResolversParentTypes['TagNameLengthError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagNameProhibitedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagNameProhibitedError'] = ResolversParentTypes['TagNameProhibitedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagPaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagPaginatedList'] = ResolversParentTypes['TagPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['TagEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetMetrics'] = ResolversParentTypes['TargetMetrics'],
> = {
  metrics?: Resolver<Maybe<Array<ResolversTypes['Metric']>>, ParentType, ContextType>;
  offsets?: Resolver<ResolversTypes['Offset'], ParentType, ContextType>;
  segmentType?: Resolver<Maybe<ResolversTypes['SegmentTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetMetricsDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetMetricsData'] = ResolversParentTypes['TargetMetricsData'],
> = {
  paceIntensities?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaceIntensity']>>>,
    ParentType,
    ContextType
  >;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  totalExpectedOutput?: Resolver<
    Maybe<ResolversTypes['TargetMetricsOutput']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetMetricsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetMetricsOutput'] = ResolversParentTypes['TargetMetricsOutput'],
> = {
  expectedLowerOutput?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expectedUpperOutput?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export type UnpauseWorkoutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpauseWorkoutResponse'] = ResolversParentTypes['UnpauseWorkoutResponse'],
> = {
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  joinableClass?: Resolver<
    Maybe<ResolversTypes['JoinableClass']>,
    ParentType,
    ContextType
  >;
  pauseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pauseInfo?: Resolver<Maybe<ResolversTypes['PauseInfo']>, ParentType, ContextType>;
  pauseTimeRemaining?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnreadNotificationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnreadNotifications'] = ResolversParentTypes['UnreadNotifications'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnreadNotificationsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnreadNotificationsResponse'] = ResolversParentTypes['UnreadNotificationsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'UnreadNotifications',
    ParentType,
    ContextType
  >;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  assets?: Resolver<Maybe<ResolversTypes['UserAssets']>, ParentType, ContextType>;
  followStatus?: Resolver<ResolversTypes['FollowStatus'], ParentType, ContextType>;
  followingList?: Resolver<
    Maybe<ResolversTypes['FollowingUsersPaginatedList']>,
    ParentType,
    ContextType,
    RequireFields<UserFollowingListArgs, 'cursor' | 'limit'>
  >;
  getInvitedUserStatus?: Resolver<
    ResolversTypes['InvitedUserStatus'],
    ParentType,
    ContextType,
    Partial<UserGetInvitedUserStatusArgs>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isProfilePrivate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  protectedFields?: Resolver<
    Maybe<ResolversTypes['UserProtectedFieldsResult']>,
    ParentType,
    ContextType
  >;
  remainingTagSlotCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scheduledClasses?: Resolver<
    ResolversTypes['ScheduledClassListResponse'],
    ParentType,
    ContextType,
    RequireFields<UserScheduledClassesArgs, 'input'>
  >;
  userTags?: Resolver<ResolversTypes['UserTags'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAssets'] = ResolversParentTypes['UserAssets'],
> = {
  image?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNotAuthorizedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNotAuthorizedError'] = ResolversParentTypes['UserNotAuthorizedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPaginatedList'] = ResolversParentTypes['UserPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPrivacyErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPrivacyError'] = ResolversParentTypes['UserPrivacyError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProtectedFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserProtectedFields'] = ResolversParentTypes['UserProtectedFields'],
> = {
  totalWorkoutCounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProtectedFieldsResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserProtectedFieldsResult'] = ResolversParentTypes['UserProtectedFieldsResult'],
> = {
  __resolveType: TypeResolveFn<
    'UserPrivacyError' | 'UserProtectedFields',
    ParentType,
    ContextType
  >;
};

export type UserStackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserStack'] = ResolversParentTypes['UserStack'],
> = {
  stackId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  stackedClassList?: Resolver<
    Array<ResolversTypes['StackedClass']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserTag'] = ResolversParentTypes['UserTag'],
> = {
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserTags'] = ResolversParentTypes['UserTags'],
> = {
  allTags?: Resolver<Array<ResolversTypes['UserTag']>, ParentType, ContextType>;
  primary?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoStreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoStream'] = ResolversParentTypes['VideoStream'],
> = {
  multiChannel?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  singleChannel?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  streamHistoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoStreamOrErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoStreamOrError'] = ResolversParentTypes['VideoStreamOrError'],
> = {
  __resolveType: TypeResolveFn<
    'ErrorVideoStream' | 'ReadyVideoStream',
    ParentType,
    ContextType
  >;
};

export type WorkoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Workout'] = ResolversParentTypes['Workout'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkoutFinalizedEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkoutFinalizedEventPayload'] = ResolversParentTypes['WorkoutFinalizedEventPayload'],
> = {
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  isClassInUserStack?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workoutMetricsType?: Resolver<
    ResolversTypes['WorkoutMetricsType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkoutMetricDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkoutMetricDetails'] = ResolversParentTypes['WorkoutMetricDetails'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkoutOfTheDayDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkoutOfTheDayDetails'] = ResolversParentTypes['WorkoutOfTheDayDetails'],
> = {
  class?: Resolver<
    Maybe<ResolversTypes['OnDemandInstructorClass']>,
    ParentType,
    ContextType
  >;
  completedParticipants?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType
  >;
  completedStats?: Resolver<
    Maybe<ResolversTypes['CompletedStats']>,
    ParentType,
    ContextType
  >;
  expiresAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rideId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  totalCompletedByCommunityToday?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  totalCompletedByUser?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkoutPostClassResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkoutPostClassResponse'] = ResolversParentTypes['WorkoutPostClassResponse'],
> = {
  goalProgressDetails?: Resolver<
    Maybe<ResolversTypes['GoalProgressDetails']>,
    ParentType,
    ContextType
  >;
  programClass?: Resolver<Maybe<ResolversTypes['ProgramClass']>, ParentType, ContextType>;
  stackedClass?: Resolver<Maybe<ResolversTypes['StackedClass']>, ParentType, ContextType>;
  workoutDetails?: Resolver<
    ResolversTypes['WorkoutMetricDetails'],
    ParentType,
    ContextType
  >;
  workoutMetricsType?: Resolver<
    ResolversTypes['WorkoutMetricsType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YourScheduleItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourScheduleItem'] = ResolversParentTypes['YourScheduleItem'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteDetails'
    | 'InviteFriendsHttpException'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNotAuthorized'
    | 'ScheduledClass',
    ParentType,
    ContextType
  >;
};

export type YourScheduleItemListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourScheduleItemList'] = ResolversParentTypes['YourScheduleItemList'],
> = {
  pendingInvites?: Resolver<
    ResolversTypes['PendingInvitesResponse'],
    ParentType,
    ContextType
  >;
  yourScheduleItems?: Resolver<
    Array<ResolversTypes['YourScheduleItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YourScheduleListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourScheduleListResponse'] = ResolversParentTypes['YourScheduleListResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsBadRequest'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNotAuthorized'
    | 'YourScheduleItemList',
    ParentType,
    ContextType
  >;
};

export type ZoneScoreResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoneScore'] = ResolversParentTypes['ZoneScore'],
> = {
  zone1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface _FieldSetScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['_FieldSet'], any> {
  name: '_FieldSet';
}

export type Resolvers<ContextType = any> = {
  AbandonOdysseyWorkoutResponse?: AbandonOdysseyWorkoutResponseResolvers<ContextType>;
  AchievementAwardedEventPayload?: AchievementAwardedEventPayloadResolvers<ContextType>;
  ActiveTagClass?: ActiveTagClassResolvers<ContextType>;
  ActiveTagClassEdge?: ActiveTagClassEdgeResolvers<ContextType>;
  ActiveTagClassesPaginatedList?: ActiveTagClassesPaginatedListResolvers<ContextType>;
  ActiveTagUserEdge?: ActiveTagUserEdgeResolvers<ContextType>;
  ActiveTagUserPaginatedList?: ActiveTagUserPaginatedListResolvers<ContextType>;
  ActiveWorkout?: ActiveWorkoutResolvers<ContextType>;
  ActivityFeedErrorResponse?: ActivityFeedErrorResponseResolvers<ContextType>;
  ActivityFeedEvent?: ActivityFeedEventResolvers<ContextType>;
  ActivityFeedEventEdge?: ActivityFeedEventEdgeResolvers<ContextType>;
  ActivityFeedEventPaginationResponse?: ActivityFeedEventPaginationResponseResolvers<ContextType>;
  ActivityFeedEventPayload?: ActivityFeedEventPayloadResolvers<ContextType>;
  ActivityFeedEventResponse?: ActivityFeedEventResponseResolvers<ContextType>;
  AddTagToUserResponse?: AddTagToUserResponseResolvers<ContextType>;
  AddTagToUserSuccess?: AddTagToUserSuccessResolvers<ContextType>;
  Album?: AlbumResolvers<ContextType>;
  AllEntertainmentChannelsWithTranslationsSuccess?: AllEntertainmentChannelsWithTranslationsSuccessResolvers<ContextType>;
  Artist?: ArtistResolvers<ContextType>;
  AwardedProgramAchievementTier?: AwardedProgramAchievementTierResolvers<ContextType>;
  BasePlaylist?: BasePlaylistResolvers<ContextType>;
  BestRuns?: BestRunsResolvers<ContextType>;
  CannotScheduleClassError?: CannotScheduleClassErrorResolvers<ContextType>;
  Captions?: CaptionsResolvers<ContextType>;
  ClassAssets?: ClassAssetsResolvers<ContextType>;
  ClassSegment?: ClassSegmentResolvers<ContextType>;
  ClassSubsegment?: ClassSubsegmentResolvers<ContextType>;
  ClassTimeline?: ClassTimelineResolvers<ContextType>;
  ClassTopTag?: ClassTopTagResolvers<ContextType>;
  ClassType?: ClassTypeResolvers<ContextType>;
  Color?: ColorResolvers<ContextType>;
  CompleteOdysseyWorkoutResponse?: CompleteOdysseyWorkoutResponseResolvers<ContextType>;
  CompletedStats?: CompletedStatsResolvers<ContextType>;
  CreateOdysseyWorkoutResponse?: CreateOdysseyWorkoutResponseResolvers<ContextType>;
  Cursor?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DeletedEntertainmentChannel?: DeletedEntertainmentChannelResolvers<ContextType>;
  DeletedEntertainmentChannelResponse?: DeletedEntertainmentChannelResponseResolvers<ContextType>;
  DeletedEntertainmentChannelSuccess?: DeletedEntertainmentChannelSuccessResolvers<ContextType>;
  DifficultyLevel?: DifficultyLevelResolvers<ContextType>;
  DisplayTag?: DisplayTagResolvers<ContextType>;
  DoesNotExistError?: DoesNotExistErrorResolvers<ContextType>;
  EntertainmentChannel?: EntertainmentChannelResolvers<ContextType>;
  EntertainmentChannelPaginatedList?: EntertainmentChannelPaginatedListResolvers<ContextType>;
  EntertainmentChannelPresignedUrl?: EntertainmentChannelPresignedUrlResolvers<ContextType>;
  EntertainmentChannelPresignedUrlQueryResponse?: EntertainmentChannelPresignedUrlQueryResponseResolvers<ContextType>;
  EntertainmentChannelTranslation?: EntertainmentChannelTranslationResolvers<ContextType>;
  EntertainmentChannelWithTranslationSuccess?: EntertainmentChannelWithTranslationSuccessResolvers<ContextType>;
  EntertainmentChannelWithTranslations?: EntertainmentChannelWithTranslationsResolvers<ContextType>;
  EntertainmentChannelWithTranslationsResponse?: EntertainmentChannelWithTranslationsResponseResolvers<ContextType>;
  EntertainmentChannelsQueryResponse?: EntertainmentChannelsQueryResponseResolvers<ContextType>;
  EntityNotFoundError?: EntityNotFoundErrorResolvers<ContextType>;
  Equipment?: EquipmentResolvers<ContextType>;
  Error?: ErrorResolvers<ContextType>;
  ErrorVideoStream?: ErrorVideoStreamResolvers<ContextType>;
  EventDetails?: EventDetailsResolvers<ContextType>;
  EventDetailsResponse?: EventDetailsResponseResolvers<ContextType>;
  FeedWorkout?: FeedWorkoutResolvers<ContextType>;
  FilterOption?: FilterOptionResolvers<ContextType>;
  FitnessDiscipline?: FitnessDisciplineResolvers<ContextType>;
  FollowingUsersPaginatedList?: FollowingUsersPaginatedListResolvers<ContextType>;
  FreestyleFeedWorkout?: FreestyleFeedWorkoutResolvers<ContextType>;
  FreestyleMetadata?: FreestyleMetadataResolvers<ContextType>;
  GoalProgress?: GoalProgressResolvers<ContextType>;
  GoalProgressDetails?: GoalProgressDetailsResolvers<ContextType>;
  GroupTags?: GroupTagsResolvers<ContextType>;
  GymBodyActivityGroupFilter?: GymBodyActivityGroupFilterResolvers<ContextType>;
  GymBookmarkResponse?: GymBookmarkResponseResolvers<ContextType>;
  GymBooleanFilter?: GymBooleanFilterResolvers<ContextType>;
  GymEquipment?: GymEquipmentResolvers<ContextType>;
  GymErrorResponse?: GymErrorResponseResolvers<ContextType>;
  GymFeedWorkout?: GymFeedWorkoutResolvers<ContextType>;
  GymFilterCategory?: GymFilterCategoryResolvers<ContextType>;
  GymInstructorFilter?: GymInstructorFilterResolvers<ContextType>;
  GymIntFilter?: GymIntFilterResolvers<ContextType>;
  GymMovement?: GymMovementResolvers<ContextType>;
  GymMovementGroup?: GymMovementGroupResolvers<ContextType>;
  GymPlan?: GymPlanResolvers<ContextType>;
  GymPlanEdge?: GymPlanEdgeResolvers<ContextType>;
  GymPlanFilterChip?: GymPlanFilterChipResolvers<ContextType>;
  GymPlanPaginationResponse?: GymPlanPaginationResponseResolvers<ContextType>;
  GymPlanQueryFilterOptions?: GymPlanQueryFilterOptionsResolvers<ContextType>;
  GymPlanResponse?: GymPlanResponseResolvers<ContextType>;
  GymStringFilter?: GymStringFilterResolvers<ContextType>;
  GymSuccessfulResponse?: GymSuccessfulResponseResolvers<ContextType>;
  GymWorkout?: GymWorkoutResolvers<ContextType>;
  HeroImageAssets?: HeroImageAssetsResolvers<ContextType>;
  HighFiveDetails?: HighFiveDetailsResolvers<ContextType>;
  HighFiveDetailsResponse?: HighFiveDetailsResponseResolvers<ContextType>;
  IconDetails?: IconDetailsResolvers<ContextType>;
  Instructor?: InstructorResolvers<ContextType>;
  InstructorAssets?: InstructorAssetsResolvers<ContextType>;
  InstructorCue?: InstructorCueResolvers<ContextType>;
  InviteDetails?: InviteDetailsResolvers<ContextType>;
  InviteFriendsBadRequest?: InviteFriendsBadRequestResolvers<ContextType>;
  InviteFriendsCreateShareLinkDisabledError?: InviteFriendsCreateShareLinkDisabledErrorResolvers<ContextType>;
  InviteFriendsError?: InviteFriendsErrorResolvers<ContextType>;
  InviteFriendsHttpException?: InviteFriendsHttpExceptionResolvers<ContextType>;
  InviteFriendsInvalidDataFormat?: InviteFriendsInvalidDataFormatResolvers<ContextType>;
  InviteFriendsInvitedUserSizeLimitExceeded?: InviteFriendsInvitedUserSizeLimitExceededResolvers<ContextType>;
  InviteFriendsItemNotFoundError?: InviteFriendsItemNotFoundErrorResolvers<ContextType>;
  InviteFriendsNoPermissionToCreateShareLinkError?: InviteFriendsNoPermissionToCreateShareLinkErrorResolvers<ContextType>;
  InviteFriendsNotAuthorized?: InviteFriendsNotAuthorizedResolvers<ContextType>;
  InviteResponse?: InviteResponseResolvers<ContextType>;
  InviteShareURLResponse?: InviteShareUrlResponseResolvers<ContextType>;
  InviteUser?: InviteUserResolvers<ContextType>;
  InviteUserEdge?: InviteUserEdgeResolvers<ContextType>;
  InvitedUsersPaginatedList?: InvitedUsersPaginatedListResolvers<ContextType>;
  InvitedUsersPaginationResponse?: InvitedUsersPaginationResponseResolvers<ContextType>;
  JoinAuthorizationError?: JoinAuthorizationErrorResolvers<ContextType>;
  JoinContentNotLiveError?: JoinContentNotLiveErrorResolvers<ContextType>;
  JoinError?: JoinErrorResolvers<ContextType>;
  JoinNoActiveSubscriptionError?: JoinNoActiveSubscriptionErrorResolvers<ContextType>;
  JoinStreamLimitError?: JoinStreamLimitErrorResolvers<ContextType>;
  JoinToken?: GraphQLScalarType;
  JoinableClass?: JoinableClassResolvers<ContextType>;
  LanebreakClass?: LanebreakClassResolvers<ContextType>;
  LeaderboardEntry?: LeaderboardEntryResolvers<ContextType>;
  LeaderboardWorkoutDetails?: LeaderboardWorkoutDetailsResolvers<ContextType>;
  Level?: LevelResolvers<ContextType>;
  LevelBrowseFiltersSuccessResponse?: LevelBrowseFiltersSuccessResponseResolvers<ContextType>;
  LevelFiltersQueryResponse?: LevelFiltersQueryResponseResolvers<ContextType>;
  LevelListQueryResponse?: LevelListQueryResponseResolvers<ContextType>;
  LevelListSuccessResponse?: LevelListSuccessResponseResolvers<ContextType>;
  LevelQueryResponse?: LevelQueryResponseResolvers<ContextType>;
  LevelSuccessResponse?: LevelSuccessResponseResolvers<ContextType>;
  LevelTheme?: LevelThemeResolvers<ContextType>;
  LiveClass?: LiveClassResolvers<ContextType>;
  Locale?: LocaleResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  MetaTag?: MetaTagResolvers<ContextType>;
  Metric?: MetricResolvers<ContextType>;
  Movement?: MovementResolvers<ContextType>;
  MovementMuscleGroup?: MovementMuscleGroupResolvers<ContextType>;
  MovementVideo?: MovementVideoResolvers<ContextType>;
  MuscleGroupScore?: MuscleGroupScoreResolvers<ContextType>;
  MusicProvider?: MusicProviderResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NoClassPelotonForPlatformError?: NoClassPelotonForPlatformErrorResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationDetails?: NotificationDetailsResolvers<ContextType>;
  NotificationDetailsResponse?: NotificationDetailsResponseResolvers<ContextType>;
  Occasion?: OccasionResolvers<ContextType>;
  OccasionAssets?: OccasionAssetsResolvers<ContextType>;
  OccasionsList?: OccasionsListResolvers<ContextType>;
  OccasionsResponse?: OccasionsResponseResolvers<ContextType>;
  OdysseyAccordionLevelFilter?: OdysseyAccordionLevelFilterResolvers<ContextType>;
  OdysseyAccordionLevelFilterInfo?: OdysseyAccordionLevelFilterInfoResolvers<ContextType>;
  OdysseyBadRequestError?: OdysseyBadRequestErrorResolvers<ContextType>;
  OdysseyBasicLevelFilterOption?: OdysseyBasicLevelFilterOptionResolvers<ContextType>;
  OdysseyBikeConfiguration?: OdysseyBikeConfigurationResolvers<ContextType>;
  OdysseyBikeDifficultyConfiguration?: OdysseyBikeDifficultyConfigurationResolvers<ContextType>;
  OdysseyBikeLaneConfiguration?: OdysseyBikeLaneConfigurationResolvers<ContextType>;
  OdysseyBikeScoringConfiguration?: OdysseyBikeScoringConfigurationResolvers<ContextType>;
  OdysseyBookmarkResponse?: OdysseyBookmarkResponseResolvers<ContextType>;
  OdysseyBookmarkSuccessResponse?: OdysseyBookmarkSuccessResponseResolvers<ContextType>;
  OdysseyConfigurationResponse?: OdysseyConfigurationResponseResolvers<ContextType>;
  OdysseyDifficultyMetadata?: OdysseyDifficultyMetadataResolvers<ContextType>;
  OdysseyDurationLevelFilterOption?: OdysseyDurationLevelFilterOptionResolvers<ContextType>;
  OdysseyError?: OdysseyErrorResolvers<ContextType>;
  OdysseyFeedWorkout?: OdysseyFeedWorkoutResolvers<ContextType>;
  OdysseyGridLevelFilter?: OdysseyGridLevelFilterResolvers<ContextType>;
  OdysseyGridLevelFilterInfo?: OdysseyGridLevelFilterInfoResolvers<ContextType>;
  OdysseyIcon?: OdysseyIconResolvers<ContextType>;
  OdysseyInvalidWorkoutMutationError?: OdysseyInvalidWorkoutMutationErrorResolvers<ContextType>;
  OdysseyItemNotFoundError?: OdysseyItemNotFoundErrorResolvers<ContextType>;
  OdysseyLeaderboard?: OdysseyLeaderboardResolvers<ContextType>;
  OdysseyLeaderboardGraphqlError?: OdysseyLeaderboardGraphqlErrorResolvers<ContextType>;
  OdysseyLeaderboardInvalidRequest?: OdysseyLeaderboardInvalidRequestResolvers<ContextType>;
  OdysseyLeaderboardListSuccessResponse?: OdysseyLeaderboardListSuccessResponseResolvers<ContextType>;
  OdysseyLeaderboardQueryResponse?: OdysseyLeaderboardQueryResponseResolvers<ContextType>;
  OdysseyLevelAssets?: OdysseyLevelAssetsResolvers<ContextType>;
  OdysseyLevelBrowseFilter?: OdysseyLevelBrowseFilterResolvers<ContextType>;
  OdysseyLevelDifficultyDetail?: OdysseyLevelDifficultyDetailResolvers<ContextType>;
  OdysseyLevelFilter?: OdysseyLevelFilterResolvers<ContextType>;
  OdysseyLevelFilterInfo?: OdysseyLevelFilterInfoResolvers<ContextType>;
  OdysseyLevelFilterOption?: OdysseyLevelFilterOptionResolvers<ContextType>;
  OdysseyLevelMetric?: OdysseyLevelMetricResolvers<ContextType>;
  OdysseyLevelOffset?: OdysseyLevelOffsetResolvers<ContextType>;
  OdysseyLevelPaceIntensity?: OdysseyLevelPaceIntensityResolvers<ContextType>;
  OdysseyLevelPaceLevel?: OdysseyLevelPaceLevelResolvers<ContextType>;
  OdysseyLevelTargetMetrics?: OdysseyLevelTargetMetricsResolvers<ContextType>;
  OdysseyLevelTargetMetricsData?: OdysseyLevelTargetMetricsDataResolvers<ContextType>;
  OdysseyLevelTargetMetricsOutput?: OdysseyLevelTargetMetricsOutputResolvers<ContextType>;
  OdysseyNotAuthorizedError?: OdysseyNotAuthorizedErrorResolvers<ContextType>;
  OdysseyPlayedLevelFilterOption?: OdysseyPlayedLevelFilterOptionResolvers<ContextType>;
  OdysseyStarThreshold?: OdysseyStarThresholdResolvers<ContextType>;
  OdysseyStarsLevelFilterOption?: OdysseyStarsLevelFilterOptionResolvers<ContextType>;
  OdysseyToggleLevelFilter?: OdysseyToggleLevelFilterResolvers<ContextType>;
  OdysseyToggleLevelFilterInfo?: OdysseyToggleLevelFilterInfoResolvers<ContextType>;
  OdysseyTreadConfiguration?: OdysseyTreadConfigurationResolvers<ContextType>;
  OdysseyTreadDifficultyConfiguration?: OdysseyTreadDifficultyConfigurationResolvers<ContextType>;
  OdysseyTreadDifficultyIntensity?: OdysseyTreadDifficultyIntensityResolvers<ContextType>;
  OdysseyTreadLaneConfiguration?: OdysseyTreadLaneConfigurationResolvers<ContextType>;
  OdysseyTreadScoringConfiguration?: OdysseyTreadScoringConfigurationResolvers<ContextType>;
  OdysseyWorkout?: OdysseyWorkoutResolvers<ContextType>;
  OdysseyWorkoutQueryResponse?: OdysseyWorkoutQueryResponseResolvers<ContextType>;
  OdysseyWorkoutSuccessResponse?: OdysseyWorkoutSuccessResponseResolvers<ContextType>;
  Offset?: OffsetResolvers<ContextType>;
  OffsetsCue?: OffsetsCueResolvers<ContextType>;
  OnDemandInstructorClass?: OnDemandInstructorClassResolvers<ContextType>;
  PaceIntensity?: PaceIntensityResolvers<ContextType>;
  PaceLevel?: PaceLevelResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaginatedList?: PaginatedListResolvers<ContextType>;
  PauseInfo?: PauseInfoResolvers<ContextType>;
  Payload?: PayloadResolvers<ContextType>;
  Peloton?: PelotonResolvers<ContextType>;
  PelotonClass?: PelotonClassResolvers<ContextType>;
  PelotonClassFeedWorkout?: PelotonClassFeedWorkoutResolvers<ContextType>;
  PelotonNotCreatedError?: PelotonNotCreatedErrorResolvers<ContextType>;
  PelotonResult?: PelotonResultResolvers<ContextType>;
  PendingInvites?: PendingInvitesResolvers<ContextType>;
  PendingInvitesResponse?: PendingInvitesResponseResolvers<ContextType>;
  PermissionError?: PermissionErrorResolvers<ContextType>;
  Playlist?: PlaylistResolvers<ContextType>;
  PlaylistMusicProvider?: PlaylistMusicProviderResolvers<ContextType>;
  PlaylistPlaybackSong?: PlaylistPlaybackSongResolvers<ContextType>;
  PopularTagClass?: PopularTagClassResolvers<ContextType>;
  PopularTagClassesList?: PopularTagClassesListResolvers<ContextType>;
  Program?: ProgramResolvers<ContextType>;
  ProgramAchievementAssets?: ProgramAchievementAssetsResolvers<ContextType>;
  ProgramAchievementTier?: ProgramAchievementTierResolvers<ContextType>;
  ProgramAssets?: ProgramAssetsResolvers<ContextType>;
  ProgramClass?: ProgramClassResolvers<ContextType>;
  ProgramClassProgress?: ProgramClassProgressResolvers<ContextType>;
  ProgramDay?: ProgramDayResolvers<ContextType>;
  ProgramFitnessDiscipline?: ProgramFitnessDisciplineResolvers<ContextType>;
  ProgramMetaData?: ProgramMetaDataResolvers<ContextType>;
  ProgramProgress?: ProgramProgressResolvers<ContextType>;
  ProgramProgressAlreadyExistsError?: ProgramProgressAlreadyExistsErrorResolvers<ContextType>;
  ProgramProgressNotFoundError?: ProgramProgressNotFoundErrorResolvers<ContextType>;
  ProgramProgressResponse?: ProgramProgressResponseResolvers<ContextType>;
  ProgramProgressResponseSuccess?: ProgramProgressResponseSuccessResolvers<ContextType>;
  ProgramSchedule?: ProgramScheduleResolvers<ContextType>;
  ProgramWeek?: ProgramWeekResolvers<ContextType>;
  ProgramWeekProgress?: ProgramWeekProgressResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RangeCue?: RangeCueResolvers<ContextType>;
  ReadyVideoStream?: ReadyVideoStreamResolvers<ContextType>;
  RemoveTagFromUserResponse?: RemoveTagFromUserResponseResolvers<ContextType>;
  RemoveTagFromUserSuccess?: RemoveTagFromUserSuccessResolvers<ContextType>;
  ReplaceTagResponse?: ReplaceTagResponseResolvers<ContextType>;
  ReplaceTagSuccess?: ReplaceTagSuccessResolvers<ContextType>;
  RetryableError?: RetryableErrorResolvers<ContextType>;
  ScenicClass?: ScenicClassResolvers<ContextType>;
  ScheduledClass?: ScheduledClassResolvers<ContextType>;
  ScheduledClassDoesNotExistError?: ScheduledClassDoesNotExistErrorResolvers<ContextType>;
  ScheduledClassList?: ScheduledClassListResolvers<ContextType>;
  ScheduledClassListResponse?: ScheduledClassListResponseResolvers<ContextType>;
  ScheduledClassResponse?: ScheduledClassResponseResolvers<ContextType>;
  SessionClass?: SessionClassResolvers<ContextType>;
  SetTagAsPrimaryResponse?: SetTagAsPrimaryResponseResolvers<ContextType>;
  SetTagAsPrimarySuccess?: SetTagAsPrimarySuccessResolvers<ContextType>;
  ShareURL?: ShareUrlResolvers<ContextType>;
  Song?: SongResolvers<ContextType>;
  StackConnectionError?: StackConnectionErrorResolvers<ContextType>;
  StackDoesNotExist?: StackDoesNotExistResolvers<ContextType>;
  StackError?: StackErrorResolvers<ContextType>;
  StackPermissionError?: StackPermissionErrorResolvers<ContextType>;
  StackResponse?: StackResponseResolvers<ContextType>;
  StackResponseSuccess?: StackResponseSuccessResolvers<ContextType>;
  StackedClass?: StackedClassResolvers<ContextType>;
  StackedClassAlreadyExistsError?: StackedClassAlreadyExistsErrorResolvers<ContextType>;
  StackedClassCannotBeAddedError?: StackedClassCannotBeAddedErrorResolvers<ContextType>;
  StackedClassConstraintViolationError?: StackedClassConstraintViolationErrorResolvers<ContextType>;
  StackedClassDoesNotExistInStackError?: StackedClassDoesNotExistInStackErrorResolvers<ContextType>;
  StackedClassInvalidPelotonClassIdError?: StackedClassInvalidPelotonClassIdErrorResolvers<ContextType>;
  StackedClassLimitReachedError?: StackedClassLimitReachedErrorResolvers<ContextType>;
  StartFreestyleWorkoutResponse?: StartFreestyleWorkoutResponseResolvers<ContextType>;
  StartFreestyleWorkoutResponseSuccess?: StartFreestyleWorkoutResponseSuccessResolvers<ContextType>;
  StartGymWorkoutResponse?: StartGymWorkoutResponseResolvers<ContextType>;
  StartGymWorkoutResponseSuccess?: StartGymWorkoutResponseSuccessResolvers<ContextType>;
  StreakAwardedEventPayload?: StreakAwardedEventPayloadResolvers<ContextType>;
  StreamablePlaylist?: StreamablePlaylistResolvers<ContextType>;
  SubscriptionIdNotFoundError?: SubscriptionIdNotFoundErrorResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  TagAlreadyExistsError?: TagAlreadyExistsErrorResolvers<ContextType>;
  TagAssets?: TagAssetsResolvers<ContextType>;
  TagBrowseCategory?: TagBrowseCategoryResolvers<ContextType>;
  TagBrowseCategoryAssets?: TagBrowseCategoryAssetsResolvers<ContextType>;
  TagCapacityOverLimitError?: TagCapacityOverLimitErrorResolvers<ContextType>;
  TagDoesNotExistOnUserError?: TagDoesNotExistOnUserErrorResolvers<ContextType>;
  TagEdge?: TagEdgeResolvers<ContextType>;
  TagNameInvalidCharacterError?: TagNameInvalidCharacterErrorResolvers<ContextType>;
  TagNameLengthError?: TagNameLengthErrorResolvers<ContextType>;
  TagNameProhibitedError?: TagNameProhibitedErrorResolvers<ContextType>;
  TagPaginatedList?: TagPaginatedListResolvers<ContextType>;
  TargetMetrics?: TargetMetricsResolvers<ContextType>;
  TargetMetricsData?: TargetMetricsDataResolvers<ContextType>;
  TargetMetricsOutput?: TargetMetricsOutputResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UnpauseWorkoutResponse?: UnpauseWorkoutResponseResolvers<ContextType>;
  UnreadNotifications?: UnreadNotificationsResolvers<ContextType>;
  UnreadNotificationsResponse?: UnreadNotificationsResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAssets?: UserAssetsResolvers<ContextType>;
  UserEdge?: UserEdgeResolvers<ContextType>;
  UserNotAuthorizedError?: UserNotAuthorizedErrorResolvers<ContextType>;
  UserPaginatedList?: UserPaginatedListResolvers<ContextType>;
  UserPrivacyError?: UserPrivacyErrorResolvers<ContextType>;
  UserProtectedFields?: UserProtectedFieldsResolvers<ContextType>;
  UserProtectedFieldsResult?: UserProtectedFieldsResultResolvers<ContextType>;
  UserStack?: UserStackResolvers<ContextType>;
  UserTag?: UserTagResolvers<ContextType>;
  UserTags?: UserTagsResolvers<ContextType>;
  VideoStream?: VideoStreamResolvers<ContextType>;
  VideoStreamOrError?: VideoStreamOrErrorResolvers<ContextType>;
  Workout?: WorkoutResolvers<ContextType>;
  WorkoutFinalizedEventPayload?: WorkoutFinalizedEventPayloadResolvers<ContextType>;
  WorkoutMetricDetails?: WorkoutMetricDetailsResolvers<ContextType>;
  WorkoutOfTheDayDetails?: WorkoutOfTheDayDetailsResolvers<ContextType>;
  WorkoutPostClassResponse?: WorkoutPostClassResponseResolvers<ContextType>;
  YourScheduleItem?: YourScheduleItemResolvers<ContextType>;
  YourScheduleItemList?: YourScheduleItemListResolvers<ContextType>;
  YourScheduleListResponse?: YourScheduleListResponseResolvers<ContextType>;
  ZoneScore?: ZoneScoreResolvers<ContextType>;
  _FieldSet?: GraphQLScalarType;
};
