import type { UserNavRouteOrRedirect } from '@members/layout';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import {
  LEGACY_WORKOUTS_ROUTE,
  MEMBER_WORKOUTS_ROUTE,
  PROFILE_WORKOUTS_ROUTE,
} from './urls';

const Routes: UserNavRouteOrRedirect[] = [
  {
    route: {
      path: PROFILE_WORKOUTS_ROUTE,
      component: lazyLoader(
        () => import('./WorkoutsPage' /* webpackChunkName: "WorkoutsPage" */),
      ),
    },
  },
  {
    route: {
      path: MEMBER_WORKOUTS_ROUTE,
      component: lazyLoader(
        () => import('./WorkoutsPage' /* webpackChunkName: "WorkoutsPage" */),
      ),
    },
  },
  {
    redirect: {
      from: LEGACY_WORKOUTS_ROUTE,
      to: PROFILE_WORKOUTS_ROUTE,
    },
  },
];

export default Routes;
