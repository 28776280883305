import { css } from 'styled-components';

const truncateFallback = css`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const truncate = (rows: number) => css`
  overflow: hidden;

  /* webkit (chrome & safari) */
  @supports (-webkit-line-clamp: ${rows}) {
    -webkit-line-clamp: ${rows};
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  @supports not (-webkit-line-clamp: ${rows}) {
    ${truncateFallback}
  }

  /* ie10+ */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${truncateFallback}
  }
`;
