import type { Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { userReducer, USER_NAMESPACE } from '@peloton/auth';
import { reducer as driftReducer } from '@peloton/drift';
import { extLinkEnvReducer } from '@peloton/external-links';
import { fetchReducer } from '@peloton/redux-fetch';
import { reducer as plansReducer } from '@peloton/subscription-plans';
import { challengesReducer as challenges } from '@engage/challenges';
import { CLASS_DETAIL_NAMESPACE, classDetailReducer } from '@engage/class-detail';
import { libraryReducer } from '@engage/library';
import { memberReducer, MEMBERS_NAMESPACE } from '@engage/members';
import {
  friendCountsReducer,
  friendsReducer,
  overviewReducer,
  OVERVIEW_NAMESPACE,
} from '@engage/overview';
import { SCHEDULE_NAMESPACE, scheduleReducer } from '@engage/scheduled-classes/redux';
import { SETTINGS_NAMESPACE, settingsReducer } from '@engage/settings';
import { subscriptionReducer } from '@engage/subscriptions/redux';
import {
  VIDEO_REDUCER_NAMESPACE,
  BUFFER_METRICS_NAMESPACE,
  bufferMetricsReducer,
} from '@engage/video';
import { workoutHistoryReducer } from '@engage/workout-history';
import { workoutsReducer } from '@engage/workouts';
import { classesReducer, CLASSES_NAMESPACE } from '@members/classes';
import { isProdEnv } from '@members/env';
import { facebookModalReducer } from '@members/facebook/redux';
import {
  Feature,
  variables,
  mergeEnabledFeaturesWithEnv,
  mergeVariablesWithEnv,
} from '@members/feature-toggles';
import { FEATURE_TOGGLE_NAMESPACE } from '@members/feature-toggles/redux';
import { toFeatureToggleReducer } from '@members/feature-toggles/state';
import { guestPassSkuReducer, GUEST_PASS_SKU_NAMESPACE } from '@members/guest-pass/redux';
import { earlyExitReducer } from '@members/library/Classes/redux';
import { meReducer } from '@members/me';
import { memberSearchReducer } from '@members/member-search';
import { modalReducer } from '@members/modal';
import { NOTIFICATION_NAMESPACE, notificationReducer } from '@members/notifications';
import { filtersReducer } from '@members/pg-library/Filters';
import {
  cardsReducer,
  SUB_TRANSACTION_NAMESPACE,
  transactionsReducer,
} from '@members/pg-preferences/redux';
import { programsReducer, PROGRAMS_NAMESPACE } from '@members/pg-programs/redux';
import { invitesReducer, INVITES_NAMESPACE } from '@members/pg-schedule/redux';
import { videoReducer } from '@members/pg-video';
import { referralsReducer } from '@members/referrals';
import { viewedMemberReducer } from '@members/viewed-member';

export const toStoreReducers = (): Record<string, Reducer> => ({
  [CLASS_DETAIL_NAMESPACE]: classDetailReducer,
  [PROGRAMS_NAMESPACE]: programsReducer,
  [FEATURE_TOGGLE_NAMESPACE]: isProdEnv()
    ? toFeatureToggleReducer(Object.values(Feature), variables)
    : toFeatureToggleReducer(
        Object.values(Feature),
        variables,
        mergeEnabledFeaturesWithEnv,
        mergeVariablesWithEnv,
      ),
  [MEMBERS_NAMESPACE]: memberReducer,
  [NOTIFICATION_NAMESPACE]: notificationReducer,
  [SCHEDULE_NAMESPACE]: scheduleReducer,
  [SETTINGS_NAMESPACE]: settingsReducer,
  [SUB_TRANSACTION_NAMESPACE]: transactionsReducer,
  [GUEST_PASS_SKU_NAMESPACE]: guestPassSkuReducer,
  [VIDEO_REDUCER_NAMESPACE]: videoReducer,
  [BUFFER_METRICS_NAMESPACE]: bufferMetricsReducer,
  cards: cardsReducer,
  challenges,
  chat: driftReducer,
  [CLASSES_NAMESPACE]: classesReducer,
  extLinkEnv: extLinkEnvReducer,
  facebookModal: facebookModalReducer,
  fetched: fetchReducer,
  filters: filtersReducer,
  form: formReducer,
  friendCounts: friendCountsReducer,
  friends: friendsReducer,
  [INVITES_NAMESPACE]: invitesReducer,
  library: libraryReducer,
  me: meReducer,
  memberSearch: memberSearchReducer,
  modal: modalReducer,
  [OVERVIEW_NAMESPACE]: overviewReducer,
  referrals: referralsReducer,
  subscriptionPlans: plansReducer,
  subscriptions: subscriptionReducer,
  [USER_NAMESPACE]: userReducer,
  viewedMember: viewedMemberReducer,
  workoutHistory: workoutHistoryReducer,
  workouts: workoutsReducer,
  earlyExit: earlyExitReducer,
});
