import { getExtLinkEnv } from '@peloton/external-links';
import { toHref } from '@peloton/external-links/models';
import {
  FREE_SUB_ID,
  switchPlanAccount,
  upgrade1wFreeAccount,
} from '@peloton/links/account';
import { useReduxState } from '@engage/redux';

export const useUpgradeFromFreePath = (
  hasBenefit: boolean = false,
  trackingParams?: { source: string; sourceDetail?: string },
) => {
  const linkEnv = useReduxState(getExtLinkEnv);
  let linkWithTrackingParams = toHref(
    hasBenefit ? upgrade1wFreeAccount() : switchPlanAccount(FREE_SUB_ID),
    linkEnv,
  );

  if (trackingParams) {
    const { source, sourceDetail } = trackingParams;
    const encodedSourceParam = `source=${encodeURIComponent(source)}`;
    const encodedSourceDetailParam =
      sourceDetail && `sourceDetail=${encodeURIComponent(sourceDetail)}`;

    linkWithTrackingParams = linkWithTrackingParams + '?' + encodedSourceParam;
    if (encodedSourceDetailParam) {
      linkWithTrackingParams = linkWithTrackingParams + '&' + encodedSourceDetailParam;
    }
  }

  return linkWithTrackingParams;
};
