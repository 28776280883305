import React from 'react';

export const Replace: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg {...props} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <rect width="32" height="32" fill="#FFF" fillOpacity=".8" rx="16" />
      <g stroke="#697180" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="M21.23 11.02L10.916 21.334M21.23 21.333L10.916 11.02" />
      </g>
    </g>
  </svg>
);
