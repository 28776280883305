import 'isomorphic-fetch';
import { toEncodedClientDetails } from '@peloton/analytics';
import { toError, isHttpError, getApiErrorType, ApiErrors } from '@peloton/api';
import type { ChangeRelationshipRequest } from '@engage/api-v2';
import { userChangeRelationship } from '@engage/api-v2';
import { logError } from '@engage/error-reporting/logError';
import { engageApi } from '@members/data-fetch/singletons';

const updateRelationshipRequest = async (input: any) => {
  const baseApi = engageApi;
  const source = window.location.pathname.includes('feed')
    ? 'Feed Post Details'
    : 'Tag Details';
  const clientDetailsHeader = toEncodedClientDetails({ Source: source });
  try {
    const { data } = await userChangeRelationship(
      baseApi,
      {
        userId: input.userId,
        action: input.action as ChangeRelationshipRequest['action'],
      },
      'web',
      clientDetailsHeader,
    );

    return {
      __typename: 'UpdateRelationshipSuccess',
      meToUser: data.meToUser as string,
      userToMe: data.userToMe as string,
    };
  } catch (axiosError) {
    const error = toError(axiosError);
    logError(error, 'updateRelationshipRequest');

    if (isHttpError(error)) {
      const errorType = getApiErrorType(error);
      if (errorType === ApiErrors.BAD_REQUEST) {
        const errorData = error.response.data;
        if (errorData.errorCode === 150) {
          return { __typename: 'CantUnfollow' };
        }
        if (errorData.errorCode === 151) {
          return { __typename: 'AlreadyExists' };
        }
      }
    }

    throw axiosError;
  }
};

export default updateRelationshipRequest;
