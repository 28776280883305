import type { History } from 'history';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import type { ab } from 'typings/appboy';
import { getUserId } from '@peloton/auth';
import { Language } from '@peloton/internationalize';
import { media } from '@peloton/styles';
import { unorphanLast } from '@peloton/text';
import { isDefined } from '@peloton/types';

import { analyticsReady, track } from '@engage/analytics';
import { slate3, slate1, gray2, white } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxState, useReduxAction } from '@engage/redux';

import { spaces } from '@engage/styles';
import {
  title28,
  body16,
  title24,
  body14,
  label12Caps,
  label14Caps,
} from '@engage/typography';
import { Button1Large } from '@members/buttons';
import { Image } from '@members/images';
import { getPersistedOrBrowserDisplayLanguage } from '@members/localization';
import { Modal as MembersModal } from '@members/modal';
import { messageNameKey, trackViewedInAppAnnouncement } from './analytics';

const IMAGE_SIZE = {
  mobile: { height: 240, width: 240 },
};

const getLocalizedLabel = (extras: Record<string, string>, userId: string) => {
  const prefersGerman =
    getPersistedOrBrowserDisplayLanguage()(userId) === Language.German;

  if (prefersGerman && extras.label_de) {
    return extras.label_de;
  }
  return extras.label;
};

const InAppMessageContainer: React.FC<
  React.PropsWithChildren<{
    inAppMessage: ab.InAppMessage;
    onClose: () => void;
    history: History;
  }>
> = ({ inAppMessage, onClose, history }) => {
  const trackAction = useReduxAction(track);
  const userId = useReduxState(getUserId);

  React.useEffect(() => {
    window.appboy?.logInAppMessageImpression?.(inAppMessage);
    if (isDefined(inAppMessage.extras) && inAppMessage.extras[messageNameKey]) {
      trackAction(trackViewedInAppAnnouncement(inAppMessage.extras[messageNameKey]));
    }
  }, []);

  return (
    <Container>
      <If
        condition={isDefined(inAppMessage.imageUrl) && inAppMessage.imageUrl.length > 0}
      >
        <ImageWrapper>
          <Image
            src={inAppMessage.imageUrl}
            alt={inAppMessage.header}
            breakpointOptions={IMAGE_SIZE}
          />
        </ImageWrapper>
      </If>
      {inAppMessage.extras && userId && (
        <If
          condition={
            isDefined(inAppMessage?.extras?.label_de) ||
            isDefined(inAppMessage?.extras?.label)
          }
        >
          <NewFeature>{getLocalizedLabel(inAppMessage.extras, userId)}</NewFeature>
        </If>
      )}
      <Title>{unorphanLast(inAppMessage.header)}</Title>
      <Description>{unorphanLast(inAppMessage.message)}</Description>
      {inAppMessage.buttons.map(button => (
        <StyledButton
          onClick={() => {
            onClose();
            if (button.uri) {
              history.push(button.uri);
            }
            window.appboy?.logInAppMessageButtonClick(button, inAppMessage);
          }}
          key={button.id}
          data-test-id={button.id}
        >
          {button.text}
        </StyledButton>
      ))}
    </Container>
  );
};

const Modal: React.FC<React.PropsWithChildren<{ history: History }>> = ({ history }) => {
  const [inAppMessage, setInAppMessage] = React.useState<ab.InAppMessage>();
  const isOpen = React.useMemo(() => isDefined(inAppMessage), [inAppMessage]);

  React.useEffect(() => {
    analyticsReady().then(() => {
      window.appboy?.subscribeToInAppMessage?.(inAppMessageSub => {
        setInAppMessage(inAppMessageSub);
      });
    });
  }, []);

  const close = React.useCallback(() => {
    setInAppMessage(undefined);
  }, []);

  return (
    <StyledModal
      contentLabel="brazeModal"
      isOpen={isOpen}
      closeHandler={() => {
        close();
        if (inAppMessage) {
          window.appboy?.logInAppMessageClick(inAppMessage);
        }
      }}
    >
      <If condition={isDefined(inAppMessage)}>
        <InAppMessageContainer
          inAppMessage={inAppMessage!}
          onClose={close}
          history={history}
        />
      </If>
    </StyledModal>
  );
};

export const BrazeModal = withRouter(Modal);

const StyledModal = styled(MembersModal)`
  ${media.tablet`
    max-width: 560px;
    width: 560px;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${gray2};
  min-height: 100%;
  padding: 0 ${spaces.large}px;

  ${media.tablet`
    padding: ${spaces.xHuge}px ${spaces.huge}px;
    justify-content: normal;
  `}
`;

const Title = styled.h1`
  ${title24}
  font-weight: 100;
  color: ${slate3};
  margin-top: ${spaces.small}px;
  text-align: center;

  ${media.tablet`
    ${title28}
    font-weight: 100;
    margin-top: ${spaces.medium}px;
  `}
`;

const Description = styled.p`
  ${body14}
  color: ${slate1};
  margin-top: ${spaces.small}px;
  text-align: center;

  ${media.tablet`
    ${body16}
    margin-top: ${spaces.medium}px;
  `}
`;

const StyledButton = styled(Button1Large)`
  margin-top: ${spaces.xxxLarge}px;
  height: 48px;
  width: 280px;
  padding: 0 ${spaces.medium}px;
`;

const ImageWrapper = styled.div`
  border-radius: 100%;
  overflow: hidden;
  img {
    display: block;
  }
`;

const NewFeature = styled.div`
  ${label12Caps}
  height: 24px;
  border-radius: ${spaces.xxxxSmall}px;
  background-color: #5a6474;
  color: ${white};
  margin-top: ${spaces.xxxLarge}px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 ${spaces.medium}px;

  ${media.tablet`
    ${label14Caps}
    height: 28px;
    border-radius: ${spaces.xxxSmall}px;
  `}
`;
