import React, { useMemo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  toClientDetails,
  toEncodedClientDetails,
  track as peloTrack,
} from '@peloton/analytics';
import { isEmpty } from '@peloton/helpers/isEmpty';
import { isDefined } from '@peloton/types';
import { track } from '@engage/analytics';
import { useReduxAction } from '@engage/redux';
import { toBaseProperties } from '../webBaseProperties';

export enum AnalyticsEvent {
  ViewedLoginError = 'Viewed Login Error',
  UnlinkedSocialAccount = 'Unlinked Social Account',
  LinkedSocialAccount = 'Linked Social Account',
}

export type TrackingFn = (event: AnalyticsEvent, properties?: object) => void;

export const useTrackingCallback = () => {
  const segmentTrack: TrackingFn = useCallback((event, properties) => {
    peloTrack({
      event,
      ...toBaseProperties(),
      properties,
    });
  }, []);

  return { segmentTrack };
};

export const useTrackPageView: TrackingFn = (event, properties) => {
  const { segmentTrack } = useTrackingCallback();
  useEffect(() => {
    segmentTrack(event, properties);
  }, []);
};

export const useSendAnalyticOnMount = (
  analyticCallBack: (...args: any[]) => any,
  analyticOptionalParams: any[] = [],
) => {
  const trackAnalytics = useReduxAction(track);
  const [hasSentAnalytic, setHasSentAnalytic] = React.useState(false);

  React.useEffect(() => {
    if (
      analyticOptionalParams.every(e => isDefined(e) && !isEmpty(e) && e) &&
      !hasSentAnalytic
    ) {
      trackAnalytics(analyticCallBack(...analyticOptionalParams));
      setHasSentAnalytic(true);
    }
  }, [...analyticOptionalParams, hasSentAnalytic]);

  return null;
};

export const useClientDetailsHeader = (
  source: string,
  additionalDetails?: { [K: string]: string },
) => {
  return useMemo(() => {
    const baseProps = toBaseProperties();
    return toEncodedClientDetails({
      ...toClientDetails(baseProps, source),
      ...additionalDetails,
    });
  }, [source, additionalDetails]);
};

export const useUtmParams = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  return {
    utm_source: queryParams?.get('utm_source') ?? '',
    utm_medium: queryParams?.get('utm_medium') ?? '',
    utm_content: queryParams?.get('utm_content') ?? '',
  };
};
