import { css } from 'styled-components';

// This class will set the visibility of an element, resulting in percy not showing it
export const HIDE_IN_VISUAL_E2E = 'hide-in-percy';
// This class will remove an element from the flow (ex: we want to hide something with dynamic size)
export const REMOVE_IN_VISUAL_E2E = 'remove-in-percy';

export const visualRegressionHelpers = css`
  @media only percy {
    .${HIDE_IN_VISUAL_E2E} {
      visibility: hidden !important;
    }
    .${REMOVE_IN_VISUAL_E2E} {
      display: none !important;
    }
  }
`;
