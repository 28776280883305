import type { Action } from 'redux';

export const loadMember = <T extends string>(
  userIdOrUsername: string,
  onError?: (error: Error) => Action<T>,
) => ({
  type: MemberMapperActionType.Request,
  payload: { userIdOrUsername, onError },
});

export const loadMemberSuccess = () => ({
  type: MemberMapperActionType.Success,
});

export const loadMemberRequestSuccess = (member: FetchedMember) => ({
  type: MemberMapperActionType.RequestSuccess,
  payload: member,
});

export enum MemberMapperActionType {
  Request = 'pelo/member/Request',
  RequestSuccess = 'pelo/member/RequestSuccess',
  Success = 'pelo/member/Success',
}

type FetchedMember = any;
