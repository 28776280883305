import * as cachedDatafiles from './cachedDatafiles';
import { OptimizelyDataFilePaths as Paths } from './paths';

export enum Environment {
  QA = 'QA',
  Production = 'Production',
}

// QA enviroment is non production (includes local, uat, api qa)
export type Environments = Environment.QA | Environment.Production;

// Provide types for imported JS module - see `module.exports` in ./cachedDatafiles
export const OptimizelyCachedDataFile = (cachedDatafiles as any) as Readonly<
  Record<Environments, Object>
>;

export const OptimizelyDataFilePaths = Paths as Readonly<Record<Environments, string>>;
