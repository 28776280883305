import { useOauth } from '@peloton/auth';
import type { MembershipTieringInfo } from '@engage/api-v2-models';
import type { CopyIDs, Tiering as TieringCopyType } from '@members/copy';
import { UsersFetchers } from '@members/data-fetch';
import { useDecoratedSWR } from '@members/data-fetch/useDecoratedSWR';
import type { Tier, ApiTierType } from '../models';
import { DEFAULT_MARKETING_DATA, DEFAULT_TIER } from '../models';

export const useMembershipTieringInfo = () => {
  const { isAuthenticated } = useOauth();
  const {
    data: tieringData,
    error: fetchError,
    mutate: mutateTieringInfo,
  } = useDecoratedSWR(...UsersFetchers.GetMembershipTieringInfo({}));

  const isFreeMember = tieringData?.membershipTieringInfo?.tierType === 'no_membership';
  const { data: freeTrialEligibilityInfo } = useDecoratedSWR(
    ...UsersFetchers.CheckFreeTrialEligibility({}),
  );
  const isEligibleForFreeTrial = freeTrialEligibilityInfo?.isEligibleForFreeTrial;

  const isLoading = !tieringData && !fetchError && isAuthenticated;
  const maybeRawTieringData = tieringData?.membershipTieringInfo;
  const computedTierData = computeUserTierData(maybeRawTieringData, isFreeMember);
  const marketingData = {
    digitalLimitedDisciplines:
      tieringData?.digitalLimitedDisciplines ??
      DEFAULT_MARKETING_DATA.digitalLimitedDisciplines,
    digitalLimitedDisciplinesMarketing:
      tieringData?.digitalLimitedDisciplinesMarketing ??
      DEFAULT_MARKETING_DATA.digitalLimitedDisciplinesMarketing,
  };

  return {
    maybeRawTieringData,
    isLoading,
    isEligibleForFreeTrial,
    computedTierData,
    marketingData,
    mutateTieringInfo,
  };
};

// Return user Tier given MembershipTieringInfo returned from the API
export const computeUserTierData = (
  membershipTieringInfo: MembershipTieringInfo | undefined,
  isFreeMember: boolean,
) => {
  if (membershipTieringInfo === undefined) {
    if (isFreeMember) {
      const { tierLabelID } = getTierType('no_membership');
      return {
        tierType: 'FREE',
        numLimitedClassesTaken: 0,
        numLimitedClassesTotal: 0,
        limitedClassesResetDate: 0,
        isDefault: false,
        tierLabelID,
      } as Tier;
    }
    return DEFAULT_TIER;
  }

  const { computedTierType, tierLabelID } = getTierType(
    membershipTieringInfo.tierType as ApiTierType,
  );
  return {
    tierType: computedTierType,
    numLimitedClassesTaken: membershipTieringInfo.limitedClassesTaken!!,
    numLimitedClassesTotal: membershipTieringInfo.limitedClassesTotal!!,
    limitedClassesResetDate: membershipTieringInfo.limitedClassesResetDate!!,
    isDefault: false,
    tierLabelID,
  } as Tier;
};

// Given a tierType (as returned from API), returns an object containing
// the corresponding TierType and display label to go with it
const getTierType = (tierType: ApiTierType) => {
  switch (tierType) {
    case 'no_membership':
      return {
        computedTierType: 'FREE',
        tierLabelID: 'displayNameFree' as CopyIDs<typeof TieringCopyType>,
      };
    case 'digital_basic':
      return {
        computedTierType: 'APP',
        tierLabelID: 'displayNameApp' as CopyIDs<typeof TieringCopyType>,
      };
    case 'digital':
    case 'digital_plus':
      return {
        computedTierType: 'APP_PLUS',
        tierLabelID: 'displayNameAppPlus' as CopyIDs<typeof TieringCopyType>,
      };
    case 'guide':
      return {
        computedTierType: 'GUIDE',
        tierLabelID: 'displayNameGuide' as CopyIDs<typeof TieringCopyType>,
      };
    case 'all_access':
      return {
        computedTierType: 'ALL_ACCESS',
        tierLabelID: 'displayNameAllAccess' as CopyIDs<typeof TieringCopyType>,
      };
    default:
      return {
        computedTierType: 'FREE',
        tierLabelID: 'displayNameFree' as CopyIDs<typeof TieringCopyType>,
      };
  }
};
