import React from 'react';
import { ME } from '@engage/members';
import { ContentWithSlideover } from '@members/animations';
import { FindFbFriendsModal } from '@members/facebook';
import { modalHeight } from '@members/modal';
import type { MemberSearchProps } from './MemberSearch';
import { MemberSearch } from './MemberSearch';

type Props = Omit<MemberSearchProps, 'handleFbClick'>;

export const ContentWithFacebookSlideover: React.FC<
  React.PropsWithChildren<Props>
> = props => {
  return (
    <ContentWithSlideover
      maxHeightCss={modalHeight}
      slideOverContent={setIsIn => (
        <FindFbFriendsModal
          userId={ME}
          handleBackClick={() => setIsIn(false)}
          clearMemberSearch={props.handleClickClear}
        />
      )}
      mainContent={setIsIn => (
        <MemberSearch handleFbClick={() => setIsIn(true)} {...props} />
      )}
    />
  );
};
