import { connect } from 'react-redux';
import type { UserSelectorState } from '@peloton/auth';
import type { ExtLinkEnvState } from '@peloton/external-links';
import { toMapStateToLinkProps } from '@peloton/external-links';
import { Links } from '@peloton/links';
import type { TrackingComponent } from '@engage/analytics';
import { track as trackAction } from '@engage/analytics';
import type { ReferralSelectorState } from '@members/referrals';
import { getReferralEligibility } from '@members/referrals';
import { Source } from '../../../../stacks/analytics';
import { openMemberSearchFromNav } from '../MemberSearchFromNav';
import { openNotificationsFromNav } from '../NotificationsFromNav';
import { openStackFromNav } from '../StackFromNav';
import { MobileNavDrawerView } from './MobileNavDrawerView';

export type MobileNavViewProps = StateProps & DispatchProps & OwnProps;

type StateProps = LinkProps & {
  canRefer: boolean;
};

type DispatchProps = TrackingComponent<{ source?: string }> & {
  handleSearchClick(): void;
  handleStackClick(): void;
  handleNotificationsClick: () => void;
};

type LinkProps = {
  boutiqueHref: string;
  supportHref: string;
  orderHistoryHref: string;
  orderHistoryAccountHref: string;
};

type OwnProps = {
  isOpen: boolean;
  subscriptionResults?: subscriptionResultsProps;
  handleMenuClose: () => void;
  handleOpenGetApp: () => void;
};

type subscriptionResultsProps = {
  type: string;
  value?: any;
};

const mapStateToLinkProps = toMapStateToLinkProps({
  boutiqueHref: Links.boutique,
  supportHref: Links.support,
  orderHistoryHref: Links.orderhistory,
  orderHistoryAccountHref: Links.orderHistoryAccount,
});

type SelectorState = ExtLinkEnvState & ReferralSelectorState & UserSelectorState;

export const MobileNavDrawer = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  SelectorState
>(
  state => ({
    ...mapStateToLinkProps(state),
    canRefer: getReferralEligibility(state),
  }),
  {
    handleSearchClick: openMemberSearchFromNav,
    track: trackAction,
    handleStackClick: () => openStackFromNav(Source.Navigation),
    handleNotificationsClick: () => openNotificationsFromNav(Source.Navigation),
  },
)(MobileNavDrawerView);
