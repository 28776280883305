import React from 'react';

import { isUserSignedIn as isUserSignedInSelector, useOauth } from '@peloton/auth';
import { getIsBreakpointLessThan } from '@peloton/responsive';
import { getTopNavUserInfo, loadMember as loadMemberAction, ME } from '@engage/members';
import { useReduxState, useReduxAction } from '@engage/redux';
import type { SkipLinksProps } from '@members/layout';
import { openModal as openModalAction } from '@members/modal';
import TopNavView from './TopNavView';
const TopNav: React.FC<React.PropsWithChildren<SkipLinksProps>> = ({ skipLinks }) => {
  const { isLoading, isAuthenticated } = useOauth();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<null | ReturnType<typeof setTimeout>>(
    null,
  );

  const isMobile = useReduxState(getIsBreakpointLessThan, 'tabletXLarge');
  const user = useReduxState(getTopNavUserInfo);
  const hasSessionAuth = useReduxState(isUserSignedInSelector);
  const loadMember = useReduxAction(loadMemberAction);
  const openModal = useReduxAction(openModalAction);

  const isLoggedInWithSessionOrOauth = (!isLoading && isAuthenticated) || hasSessionAuth;

  React.useEffect(() => {
    if (isLoggedInWithSessionOrOauth) {
      loadMember(ME);
    }
  }, [isLoggedInWithSessionOrOauth, loadMember]);

  React.useEffect(() => {
    return () => {
      if (!!timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handleMenuOpen = React.useCallback((e?: Event) => {
    if (e) {
      e.stopPropagation();
    }
    // Clicking open menu signifies that we want the menu animation
    window.document.body.classList.add('allow-menu-animation');
    setIsMenuOpen(true);
  }, []);

  const handleMenuClose = React.useCallback(
    (e?: Event) => {
      if (e) {
        e.stopPropagation();
      }

      // Clicking close menu signifies that we want the menu animation
      window.document.body.classList.add('allow-menu-animation');
      if (isMobile) {
        setIsMenuOpen(false);
        const timeoutRef = setTimeout(() => setIsMenuOpen(false), 250);
        setTimeoutId(timeoutRef);
      } else {
        setIsMenuOpen(false);
      }
    },
    [isMobile],
  );

  return (
    <TopNavView
      skipLinks={skipLinks}
      user={user}
      isUserSignedIn={isLoggedInWithSessionOrOauth}
      isMenuOpen={isMenuOpen}
      openModal={openModal}
      handleMenuOpen={handleMenuOpen}
      handleMenuClose={handleMenuClose}
    />
  );
};

export default TopNav;
