import { ContractType } from './acceptTermsEnums';

const SOURCE = 'Contract Update';
export const contractTypeMap: Record<ContractType, string> = {
  [ContractType.PrivacyPolicy]: 'Privacy Policy',
  [ContractType.MembershipTerms]: 'Subscription Terms',
  [ContractType.TermsOfService]: 'Terms of Service',
};
export const mapTermTypes = (terms: string[]) =>
  terms.map((t: string) => contractTypeMap?.[t] ?? t);

export const ANALYTICS_OPTIONS = {
  source: SOURCE,
};

export const agreedToTerms = (termTypes: string[], termIds: string[]) => ({
  event: 'Agreed to Terms',
  Terms: mapTermTypes(termTypes),
  '[Terms IDs]': termIds,
  ...ANALYTICS_OPTIONS,
});

export const viewedUpdatedTerms = (termTypes: string[], termsIds: string[]) => ({
  event: 'Viewed Updated Terms',
  UpdatedTerms: mapTermTypes(termTypes),
  '[Terms IDs]': termsIds,
});

export const tappedTermsLink = (termType: string, termId: string) => ({
  event: 'Tapped Terms Link',
  Terms: contractTypeMap[termType],
  '[Terms ID]': termId,
});

export const tappedOnepelotonLink = () => ({
  event: 'Tapped Onepeloton Link',
  ...ANALYTICS_OPTIONS,
});
