import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { gray1, gray3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { header4Large, label7Large } from '@engage/typography';
import { Button2Small } from '@members/buttons';
import { anyOverrides, clearAll, getEnv } from '@members/env';
import { LightInput } from '@members/form/input';
import type { ModalProps } from '@members/modal';
import { Modal } from '@members/modal';
import { clipboardCopy } from './clipboardCopy';
import { EnvironmentConfiguration } from './EnvironmentConfiguration';
import MembershipDetails from './MembershipDetails';
import Toggles from './Toggles';

const DEFAULT_STRING = '📋 Copy as URL 📋';

const CopyLocalEnv: React.FC<React.PropsWithChildren<unknown>> = () => {
  const env = getEnv();
  const [copyString, setCopyString] = useState(DEFAULT_STRING);
  const [copyUrl, setCopyUrl] = useState(window.location.href);

  useEffect(() => {
    const url = new URL(window.location.href);
    Object.keys(env).forEach(key => url.searchParams.set(key, env[key]));
    setCopyUrl(url.href);
  }, [env]);

  const onClick = useCallback(() => {
    clipboardCopy(copyUrl)
      ?.then(() => {
        setCopyString('Copied!');
      })
      .catch(() => {
        setCopyString('Failed :(');
      });
    setTimeout(() => setCopyString(copyString), 1000);
  }, [copyUrl]);

  return (
    <>
      <LightInput
        data-test-id="localStorageEnv"
        type="text"
        value={copyUrl}
        isEmptyField={false}
        disabled={false}
        error={false}
        readOnly
      />
      <br />
      <Button2Small onClick={onClick}>{copyString}</Button2Small>
      <br />
    </>
  );
};

const ClearLocalEnv: React.FC<React.PropsWithChildren<unknown>> = () => {
  const hasAnyOverrides = anyOverrides();

  if (!hasAnyOverrides) {
    return null;
  }

  return (
    <>
      <Button2Small onClick={clearAll}>
        <span role="img" aria-label="warning">
          🚧
        </span>{' '}
        Clear All Overrides{' '}
        <span role="img" aria-label="warning">
          🚧
        </span>
      </Button2Small>
      <br />
    </>
  );
};

const Overlay: React.FC<React.PropsWithChildren<Omit<ModalProps, 'contentLabel'>>> = ({
  isOpen,
  closeHandler,
}) => (
  <Modal
    isOpen={isOpen}
    closeHandler={closeHandler}
    style={{ closeButton: { display: 'none' }, content: { minWidth: '700px' } }}
    contentLabel="feature toggle overlay"
  >
    <Container>
      <Title>
        <span role="img" aria-label="expiriment">
          🧪
        </span>{' '}
        QA Environment Configuration Tool{' '}
        <span role="img" aria-label="expiriment">
          🧪
        </span>
      </Title>
      <MembershipDetails />
      <Divider />
      <EnvironmentConfiguration />
      <br />
      <ClearLocalEnv />
      <Subtext>(Changes above automatically reload the window.)</Subtext>
      <Divider />
      <CopyLocalEnv />
      <Subtext>(Copy environment and feature toggle configurations.)</Subtext>
      <Divider />
      <Title>
        <span role="img" aria-label="expiriment">
          🧪
        </span>{' '}
        Feature Toggles{' '}
        <span role="img" aria-label="expiriment">
          🧪
        </span>
      </Title>
      <Toggles />
    </Container>
  </Modal>
);

const Title = styled.h1`
  ${header4Large}
  margin-bottom: ${spaces.large}px;
`;

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${gray1};
  padding: ${spaces.huge}px ${spaces.small}px ${spaces.xxLarge}px;
  width: auto;
  max-width: 100vw;

  ${media.tablet`
    padding: ${spaces.huge}px ${spaces.large}px ${spaces.xxLarge}px;
  `}

  ${media.tabletLarge`
  `}
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px ${gray3} solid;
  margin: ${spaces.small}px;
`;

const Subtext = styled.span`
  ${label7Large}
  pointer-events: auto;
`;

export default Overlay;
