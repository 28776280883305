import { getRideRecommendations } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type Params = {
  acceptLanguage?: string;
  pelotonPlatform?:
    | 'default'
    | 'commercial_bike'
    | 'home_bike'
    | 'aurora'
    | 'home_tread'
    | 'studio_tread'
    | 'iOS_app'
    | 'pos'
    | 'studio_bike'
    | 'web'
    | 'android'
    | 'fire_tv'
    | 'tiger'
    | 'android_tv'
    | 'fire_tablet'
    | 'apple_tv'
    | 'apple_watch'
    | 'roku'
    | 'comcast_x1'
    | 'sky_tv'
    | 'lg'
    | 'samsung';
  rideId: string;
};

export const toRecommendationsFetchers = (api: typeof engageApi = engageApi) => ({
  RideRecommendations: toFetcher(
    (params: Params) => `RideRecommendations${params.rideId}`,
    (params: Params) =>
      unwrap(() =>
        getRideRecommendations(
          api,
          params.rideId,
          params.pelotonPlatform,
          params.acceptLanguage,
        ),
      ),
  ),
});
