import React from 'react';
import styled from 'styled-components';
import { useReduxState } from '@engage/redux';
import { label7Large } from '@engage/typography';
import { Button2Small } from '@members/buttons';
import { StorageKeys, getFullStorageKey } from '@members/env';
import { getVariables } from '@members/feature-toggles/redux';
import {
  useFeatureToggles,
  anyOverrides,
  useClearLocalStorageFeatureToggles,
} from '../featureToggles';
import type { Feature } from '../toggles';
import { MaybeExperiments } from './Experiments';
import Toggle from './Toggle';
import Variables from './Variables';

const List = styled.ul`
  width: 100%;
`;

const Subtext = styled.span`
  ${label7Large}
  pointer-events: auto;
`;

const ClearOverridesButton: React.FC<
  React.PropsWithChildren<{ ifAnyOverrides: boolean }>
> = ({ ifAnyOverrides }) => {
  const clearStorage = useClearLocalStorageFeatureToggles();

  if (!ifAnyOverrides) {
    return null;
  }

  return (
    <Button2Small onClick={clearStorage}>
      <span role="img" aria-label="warning">
        🚧
      </span>{' '}
      Clear Toggle Overrides{' '}
      <span role="img" aria-label="warning">
        🚧
      </span>
    </Button2Small>
  );
};

const Toggles: React.FC<React.PropsWithChildren<unknown>> = () => {
  const features = useFeatureToggles();
  // The default order of the feature toggle array is chronological,
  // but reverse chron display improves usability of the panel.
  const reverseChronFeatureKeys = Object.keys(features).slice().reverse();
  const variables = useReduxState(getVariables);
  const ifAnyOverrides = anyOverrides(features, variables);
  return (
    <>
      <ClearOverridesButton ifAnyOverrides={ifAnyOverrides} />
      <br />
      <Subtext>(localStorage: {getFullStorageKey(StorageKeys.FeatureToggles)})</Subtext>
      <List>{reverseChronFeatureKeys.map(toToggle(features))}</List>
    </>
  );
};

// eslint-disable-next-line react/display-name
const toToggle = (features: ReturnType<typeof useFeatureToggles>) => (
  feature: Feature,
) => (
  <React.Fragment key={feature}>
    <Toggle feature={feature} toggle={features[feature]} />
    <Variables for={feature} />
    <MaybeExperiments for={feature} />
  </React.Fragment>
);

export default Toggles;
