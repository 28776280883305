import React from 'react';
import { unorphan } from '@peloton/text';
import { isDefined } from '@peloton/types';

export type CopyValues = Record<
  string,
  string | number | boolean | null | JSX.Element | undefined | Date
>;

export type CopyHook<ContentType extends string> = (
  id: ContentType,
  values?: CopyValues,
) => string;

export type CopyHookOr<ContentType extends string> = (
  defaultValue: string,
  id: ContentType,
  values?: CopyValues,
) => string;

export type CopyComponent<ContentType extends string> = React.FC<
  React.PropsWithChildren<{
    id: ContentType;
    values?: CopyValues;
    unorphanCount?: number;
  }>
>;

export const toCopyComponent = <ContentType extends string>(
  hook: CopyHook<ContentType>,
  // eslint-disable-next-line react/display-name
): CopyComponent<ContentType> => ({ id, values, unorphanCount }) => {
  if (isDefined(unorphanCount)) {
    return <span>{unorphan(unorphanCount, hook(id, values))}</span>;
  }

  return <span>{hook(id, values)}</span>;
};
