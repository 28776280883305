import React from 'react';

const PlusIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 12 12" {...props}>
    <path d="M6.8,5h4.24545a.75.75,0,1,1,0,1.5H6.8v4.3393a.87313.87313,0,0,1-.75.9607.8795.8795,0,0,1-.75-.9607V6.5H1.05455a.75.75,0,1,1,0-1.5H5.3V1.2607A.87313.87313,0,0,1,6.05.3a.8795.8795,0,0,1,.75.9607Z" />
  </svg>
);

export default PlusIcon;
