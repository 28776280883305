import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { gray1, gray2, slate1, gray4 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label14 } from '@engage/typography';
import { TagsCopy, Tags } from '@members/copy';
import { share } from '@members/facebook';
import { copyToClipboard } from '@members/helpers';
import { Link } from '@members/icons';
import FBIcon from '@members/pg-schedule/components/confirm-modal/icons/FBIcon';
import { createTooltip, Position } from '@members/tags/useTooltip';
import type { ShareTooltipSource } from './shared';

export const {
  Tooltip: ShareTooltipBase,
  TooltipContextProvider: ShareTooltipContextProvider,
  useTooltip: useShareTooltip,
} = createTooltip({
  tooltipWidth: 200,
  tooltipHeight: 113,
  arrowWidth: 10,
});

export type ShareTooltipProps = {
  source: ShareTooltipSource;
  position: Position;
};

type ShareTooltipInternalProps = {
  source: ShareTooltipSource;
  shareUrl: string;
  trackShareToFacebook: (source: ShareTooltipSource) => void;
  trackShareToClipboard: (source: ShareTooltipSource) => void;
  position: Position;
};

const useArrowHovered = () => {
  const [arrowHovered, setArrowHovered] = React.useState(false);
  return [
    arrowHovered,
    (enabled: boolean, isHovered: boolean) =>
      enabled ? () => setArrowHovered(isHovered) : () => null,
  ] as const;
};

export const ShareTooltip: React.FC<
  React.PropsWithChildren<ShareTooltipInternalProps>
> = ({ source, shareUrl, trackShareToFacebook, trackShareToClipboard, position }) => {
  const [arrowHovered, setArrowHovered] = useArrowHovered();

  // Click handler for clicking Share to Facebook in Share tooltip.
  const onShareToFacebookClick = React.useCallback(() => {
    share({ href: shareUrl });
    trackShareToFacebook(source);
  }, [source, shareUrl, trackShareToFacebook]);

  return (
    <ShareTooltipBase arrowHovered={arrowHovered} position={position}>
      <Button
        isTop={true}
        onClick={onShareToFacebookClick}
        data-test-id="shareFacebookButton"
        onMouseEnter={setArrowHovered(position === Position.Bottom, true)}
        onMouseLeave={setArrowHovered(position === Position.Bottom, false)}
      >
        <StyledSchedulingFBIcon />
        <Text>
          <TagsCopy id={Tags.ShareOnFacebook} />
        </Text>
      </Button>
      <HorizontalDivider />
      <CopyLinkButton
        shareUrl={shareUrl}
        setArrowHovered={setArrowHovered}
        source={source}
        trackShareToClipboard={trackShareToClipboard}
        position={position}
      />
    </ShareTooltipBase>
  );
};

const CopyLinkButton: React.FC<
  React.PropsWithChildren<{
    shareUrl: string;
    setArrowHovered: (b: boolean, c: boolean) => () => void;
    source: ShareTooltipSource;
    trackShareToClipboard: (source: ShareTooltipSource) => void;
    position: Position;
  }>
> = ({ shareUrl, setArrowHovered, source, trackShareToClipboard, position }) => {
  const [copied, setCopied] = React.useState(false);

  // Click handler for copying link to clipboard in Share tooltip.
  const onCopyLinkToClipboard = React.useCallback(() => {
    copyToClipboard(shareUrl);
    setCopied(true);
    trackShareToClipboard(source);
  }, [source, shareUrl, setCopied, trackShareToClipboard]);

  return (
    <Button
      isTop={false}
      onClick={onCopyLinkToClipboard}
      data-test-id="copyLinkButton"
      onMouseEnter={setArrowHovered(position === Position.Top, true)}
      onMouseLeave={setArrowHovered(position === Position.Top, false)}
    >
      <SchedulingStyledLink />
      <Text>
        {copied ? (
          <TagsCopy id={Tags.LinkCopiedCta} />
        ) : (
          <TagsCopy id={Tags.CopyLinkCta} />
        )}
      </Text>
    </Button>
  );
};

const HorizontalDivider = styled.div`
  height: 1px;
  background-color: ${transparentize(0.6, gray4)};
  width: 100%;
`;

const Button = styled.button<{ isTop: boolean }>`
  border-radius: ${({ isTop }) =>
    isTop
      ? `${spaces.xxxSmall}px ${spaces.xxxSmall}px 0 0`
      : `0 0 ${spaces.xxxSmall}px ${spaces.xxxSmall}px`};
  height: 57px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  background-color: ${gray1};

  z-index: 1;
  ${hoverTransition({
    property: 'background-color',
    from: gray1,
    to: gray2,
  })}
`;

const StyledSchedulingFBIcon = styled(FBIcon)`
  margin-right: ${spaces.small}px;
  margin-left: ${spaces.medium}px;
`;

const SchedulingStyledLink = styled(Link)`
  width: 20px;
  height: 20px;
  margin-left: ${spaces.medium}px;
  margin-right: ${spaces.small}px;
`;

const Text = styled.span`
  ${label14}
  color: ${slate1};
`;
