import useSWR from 'swr';
import { getUserId } from '@peloton/auth';
import { useReduxState } from '@engage/redux';
import { TooltipsFetchers } from '@members/data-fetch';
import { useLocale } from '@members/localization';

export const useTooltipsData = (tooltipId: string) => {
  const locale = useLocale();
  const userId = useReduxState(getUserId);
  return useSWR(
    ...TooltipsFetchers.Tooltips({ acceptLanguage: locale, userId, tooltipId }),
  );
};
