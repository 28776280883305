import type { AxiosPromise } from 'axios';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type Params = { acceptLanguage?: string; userId?: string; tooltipId?: string };

function getTooltips(
  api: typeof engageApi,
  acceptLanguage?: string,
  userId?: string,
  tooltipId?: string,
): AxiosPromise<boolean> {
  return api.axios
    .get(`/tooltips/${userId}/seen/${tooltipId}`, {
      headers: { 'Accept-Language': acceptLanguage },
    })
    .catch(err => {
      if (err.response.status === 404) {
        return {
          ...err,
          data: true,
        };
      }
      throw err;
    });
}

export const toTooltipsFetchers = (api: typeof engageApi = engageApi) => ({
  Tooltips: toFetcher(
    (params: Params) => `Tooltips[${params.userId}]/seen/[${params.tooltipId}]`,
    (params: Params) =>
      unwrap(() =>
        getTooltips(api, params.acceptLanguage, params.userId, params.tooltipId),
      ),
  ),
});
