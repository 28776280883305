import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1, tiersGradient, white } from '@engage/colors';
import { spaces } from '@engage/styles/spaces';
import { label16 } from '@engage/typography';
import { largeButtonStyles, sharedButtonStyles } from '@members/buttons';
import { Tiering, useTiering } from '@members/copy';
import type { Modal, TIERING_MODAL_TYPE } from '../models';
import { getModalName } from '../utils';
import TieringModalView from './TieringModalView';

const ExclusiveUpgradeModal: React.FC<
  React.PropsWithChildren<{
    modal: Modal;
    setModal: React.Dispatch<React.SetStateAction<Modal>>;
  }>
> = ({ modal, setModal }) => {
  const modalHeaderCopy = useTiering(Tiering.UpgradeToTakeClass);
  const modalDescriptionCopy = useTiering(Tiering.ExclusiveUpgradeModalDescription);
  const upgradeCTACopy = useTiering(Tiering.Upgrade);
  const buttonCopy = useTiering(Tiering.Close);

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const openUpgradeModal = () => {
    setModal({
      ...modal,
      modalType: 'upgrade_modal',
      isOpen: true,
      source: getModalName('quota_available_modal' as TIERING_MODAL_TYPE),
    });
  };

  return (
    <TieringModalView
      modal={modal}
      setModal={setModal}
      modalHeaderCopy={modalHeaderCopy}
      modalDescriptionCopy={modalDescriptionCopy}
      ctaChildren={
        <>
          <UpgradeCTA onClick={openUpgradeModal}>{upgradeCTACopy} </UpgradeCTA>
          <CTAButton onClick={closeModal}>{buttonCopy}</CTAButton>
        </>
      }
    />
  );
};

const UpgradeCTA = styled.button`
  ${tiersGradient}
  ${sharedButtonStyles}
  ${largeButtonStyles}
  color: ${white};
  margin: ${spaces.xLarge}px 0;
  width: 280px;
  ${media.tablet`
    width: 320px;`}
`;

const CTAButton = styled.button`
  ${label16}
  color: ${slate1};
  text-transform: capitalize;
`;

export default ExclusiveUpgradeModal;
