import React from 'react';

const ErrorIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8358 0.716109C12.3446 -0.194429 13.6548 -0.194435 14.1636 0.716107L25.6941 21.3495C26.1907 22.2382 25.5483 23.3332 24.5301 23.3332L1.4693 23.3332C0.451181 23.3332 -0.19129 22.2382 0.305371 21.3495L11.8358 0.716109ZM24.5301 21.9999L12.9997 1.36654L1.4693 21.9999L24.5301 21.9999ZM13.9999 19.0003C13.9999 19.5528 13.5522 20.0006 12.9999 20.0006C12.4476 20.0006 11.9999 19.5528 11.9999 19.0003C11.9999 18.4478 12.4476 17.9999 12.9999 17.9999C13.5522 17.9999 13.9999 18.4478 13.9999 19.0003ZM11.7512 8.66391L12.2124 15.9273C12.2387 16.343 12.5835 16.6666 13 16.6666C13.4165 16.6666 13.7613 16.343 13.7877 15.9273L14.2489 8.6639C14.2946 7.94305 13.7223 7.33325 13 7.33325C12.2777 7.33325 11.7054 7.94306 11.7512 8.66391Z"
      fill="#222529"
    />
  </svg>
);

export default ErrorIcon;
