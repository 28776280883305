import type { Reducer } from 'redux';
import type { BrowseCategory } from '@engage/browse-categories';
import type { UpdateClassesActionWithOptionalPayload } from '@engage/classes';
import { ClassesActionTypes } from '@engage/classes';
import type { PendingResult } from '@engage/result';
import { pending, ok, Kind as ResultKind, error as engageError } from '@engage/result';

export enum ActionTypes {
  Update = 'pelo/library/browseCategories/update',
  Failure = 'pelo/library/browseCategories/failure',
}

export const browseCategoriesReducer: Reducer<BrowseCategoriesState> = (
  state: BrowseCategoriesState = pending,
  action: Actions,
) => {
  switch (action.type) {
    case ClassesActionTypes.Update:
      if (action.browseCategories) {
        return ok(action.browseCategories);
      }
      return state;
    case ActionTypes.Update:
      return ok(action.payload.browseCategories);
    case ActionTypes.Failure:
      if (state.type === ResultKind.Ok) {
        return state;
      } else {
        return engageError(undefined);
      }
    default:
      return state;
  }
};

type LoadBrowseCategoriesSuccess = {
  type: ActionTypes.Update;
  payload: { browseCategories: BrowseCategory[] };
};

export const loadBrowseCategories = (
  browseCategories: BrowseCategory[],
): LoadBrowseCategoriesSuccess => ({
  type: ActionTypes.Update,
  payload: { browseCategories },
});

type LoadBrowseCategoriesFailure = {
  type: ActionTypes.Failure;
};

export const loadBrowseCategoriesFailure = (): LoadBrowseCategoriesFailure => ({
  type: ActionTypes.Failure,
});

export type Actions =
  | LoadBrowseCategoriesFailure
  | LoadBrowseCategoriesSuccess
  | UpdateClassesActionWithOptionalPayload<{ browseCategories: BrowseCategory[] }>;

export type BrowseCategoriesState = PendingResult<BrowseCategory[], undefined>;
