import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { white, gray1, slate2, slate3, superRed, divider1, gray3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { title24Bold, title20Bold, label14Caps, body14 } from '@engage/typography';
import type { ViewUserStackQuery } from '@members/graphql-swr/schemas/stacked-classes/queries/ViewUserStack.generated';
import { SimpleAvatar } from '@members/images';
import { modalHeight } from '@members/modal';
import { media, ieHideScroll } from '../../../@peloton/styles';
import { Save } from '../icons/Save';
import type { StackedOnDemandClass } from './ActiveState';
import { CTAComponent, ThumbnailClass } from './ActiveState';

type Props = {
  data: ViewUserStackQuery | undefined;
};

const SharedStack: React.FC<React.PropsWithChildren<Props>> = ({ data }) => {
  const numClasses = data?.viewUserStack?.numClasses ?? 0;
  const stackDuration = data?.viewUserStack?.totalTime
    ? data?.viewUserStack?.totalTime / 60
    : 0;
  const stackList =
    (data?.viewUserStack.__typename === 'StackResponseSuccess' &&
      data?.viewUserStack.userStack.stackedClassList) ??
    undefined;

  const pelotonClassList = stackList
    ? (stackList.reduce((acc, stackedClass) => {
        if (
          stackedClass.pelotonClass.__typename === 'OnDemandInstructorClass' ||
          stackedClass.pelotonClass.__typename === 'ScenicClass' ||
          stackedClass.pelotonClass.__typename === 'LanebreakClass'
        )
          acc.push(stackedClass.pelotonClass);
        return acc;
      }, [] as StackedOnDemandClass[]) as StackedOnDemandClass[])
    : [];

  const [pressedSave, setPressedSave] = React.useState(false);
  return (
    <Container>
      <HeaderBackground>
        <Header>
          <SimpleAvatar
            size={68}
            url={
              'https://res.cloudinary.com/peloton-cycle/image/fetch/ar_1,c_fill,dpr_1.0,f_auto,g_face,h_56,q_auto,r_max,w_56/https://res.cloudinary.com/peloton-cycle/image/upload/l_default_user_images:chars:a%2Cf_jpg/default_user_images/backgrounds/14'
            }
            alt={'avatar'}
          />
          {pressedSave ? (
            <>
              <ConfirmText data-test-id="headerText">
                All classes in your current stack will be replaced with yayconnie’s stack.
                Do you want to continue?
              </ConfirmText>
              <ConfirmContainer>
                <CancelButton onClick={() => setPressedSave(false)}>
                  <SaveText>Cancel</SaveText>
                </CancelButton>
                <ConfirmButton>
                  <SaveText>Yes, Save New Stack</SaveText>
                </ConfirmButton>
              </ConfirmContainer>
            </>
          ) : (
            <>
              <HeaderText data-test-id="headerText">
                pelotonleaderboardname12345 shared their Stack with you
              </HeaderText>
              <SaveStackButton onClick={() => setPressedSave(true)}>
                <Save />
                <SaveText>Save this Stack</SaveText>
              </SaveStackButton>
            </>
          )}
        </Header>
        <SubHeader>
          <CTAComponent stackDuration={stackDuration} numberOfClasses={numClasses} />
        </SubHeader>
      </HeaderBackground>
      <ScrollContainer>
        <ClassContainer>
          {pelotonClassList.map(stackedClass => (
            <div key={stackedClass.classId}>
              <Divider />
              <ThumbnailClass key={stackedClass.title} classDetails={stackedClass} />
            </div>
          ))}
        </ClassContainer>
      </ScrollContainer>
    </Container>
  );
};

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #17191c;
  overflow-y: hidden;
  overflow-x: auto;

  ${media.tablet`
    padding: 0;
    height: ${modalHeight};
    max-height: 700px;
  `}
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;

const HeaderBackground = styled.div`
  height: 284px;
  width: 100%;
  background-color: ${slate3};
  position: absolute;
  z-index: 1;

  ${media.tablet`
    height: 300px;
  `}
`;

const Header = styled.div`
  height: 233px;
  width: 100%;
  background-color: ${transparentize(0.75, slate2)};
  padding-left: 0px;
  padding-right: 0px;
  padding-top: ${spaces.large}px;
  padding-bottom: ${spaces.large}px;
  text-align: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;

  ${media.tablet`
    height: 246px;
    width: 560px;
    position: absolute;
    padding-left: ${spaces.small}px;
    padding-right: ${spaces.small}px;
    padding-top: 33px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `}
`;

const HeaderText = styled.p`
  color: ${gray1};
  margin-top: 12px;
  ${title20Bold}

  ${media.tablet`
    color: ${white};
    ${title24Bold}
  `}
`;

const SaveStackButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: ${white};
  width: 191px;
  height: 40px;
  background: ${superRed};
  border-radius: 9999px;
  margin: 12px auto auto auto;
  flex-direction: row;
  justify-content: space-between;
`;

const SaveText = styled.p`
  color: ${white};
  ${label14Caps}
`;

const Divider = styled.div`
  border-bottom: 1px solid ${divider1};
  display: block;
  width: 100%;
`;
const SubHeader = styled.div`
  height: 48px;
  width: 100%;
  background-color: ${slate3};
  color: ${gray3};
  margin-top: ${spaces.xxxHuge}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  svg {
    stroke: ${gray3};
    height: 16px;
    width: 16px;
  }
  z-index: 1;
  ${media.tablet`
    width: 560px;
    margin-top: 250px;
  `};
`;

const ClassContainer = styled.div`
  margin-top: 300px;
`;

const CancelButton = styled.button`
  background-color: rgba(255, 255, 255, 0.08);
  height: 40px;
  border-radius: 9999px;
  width: 82px;
  text-align: center;
`;

const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${body14}
`;

const ConfirmText = styled.p`
  color: ${white};
  margin: 20px 40px 10px 40px;
  ${body14}
`;

const ConfirmButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${white};
  width: 191px;
  height: 40px;
  background: ${superRed};
  border-radius: 9999px;
  margin-left: 10px;
`;
export default SharedStack;
