export const PROSPECTS_ROUTE = '/classes';
export const CLASS_ID_PARAM = 'classId';
const CLASS_ID_LENGTH = 32;

/**
 * Redirects to the prospects app if the queryParams contain a valid classId param.
 * @param queryParams full queryParams string.
 * @param redirectEnabled whether or not to redirect.
 * @returns class slug with queryParams appended or null if there is no corresponding page.
 */
export const useProspectsRedirect = (
  queryParams: string,
  redirectEnabled: boolean,
): string | null => {
  const urlParams = new URLSearchParams(queryParams);
  const classId = urlParams.get(CLASS_ID_PARAM);
  const isValidClassId = classId !== null && classId.length === CLASS_ID_LENGTH;
  const shouldNotRedirect = isValidClassId === false || redirectEnabled === false;

  if (shouldNotRedirect) {
    return null;
  }

  return `${PROSPECTS_ROUTE}/${classId}?${urlParams.toString()}`;
};
