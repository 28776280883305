import React from 'react';

export const MiniHashtag: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity="0" d="M0 0h16v16H0z" />
      <g stroke="#A8ACB1" strokeLinecap="round" strokeWidth="1.5">
        <path d="M4.637 10.392l-.698 4.36M6.207 1.2L4.756 9.868M11.166 5.1l-1.607 9.652M11.786 1.2l-.615 3.899M6.28 5.009h8.09M1.938 5.009h3.609M10.096 10.532h4.044M1.2 10.532h8.296" />
      </g>
    </g>
  </svg>
);
