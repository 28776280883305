import React from 'react';

const Youtube: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <path d="M17.82 5.804s-.176-1.273-.715-1.834c-.684-.737-1.452-.74-1.803-.784C12.782 3 9.004 3 9.004 3h-.008s-3.778 0-6.298.186c-.35.044-1.119.047-1.803.784C.356 4.53.18 5.804.18 5.804S0 7.3 0 8.795v1.402c0 1.497.18 2.991.18 2.991s.176 1.274.715 1.835c.686.736 1.585.713 1.985.79C4.32 15.956 9 16 9 16s3.782-.005 6.302-.194c.352-.043 1.119-.047 1.803-.783.539-.56.715-1.835.715-1.835s.18-1.496.18-2.99V8.794c0-1.496-.18-2.99-.18-2.99zM7.14 11.896V6.704l4.865 2.605-4.864 2.587z" />
  </svg>
);

export { Youtube };
