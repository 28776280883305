import { getExtLinkEnv } from '@peloton/external-links';
import { toHref } from '@peloton/external-links/models';
import { upgradePlanAccount, switch1wPlanAccount } from '@peloton/links/account';
import { useReduxState } from '@engage/redux';
import { getSubscriptionsResult, toPrimarySub } from '@engage/subscriptions';

export const useUpgradeMembershipPath = (hasBenefit: boolean = false) => {
  const subs = useReduxState(getSubscriptionsResult);
  const primarySub = toPrimarySub(subs.value);
  const linkEnv = useReduxState(getExtLinkEnv);

  return toHref(
    hasBenefit
      ? switch1wPlanAccount(primarySub?.id ?? '')
      : upgradePlanAccount(primarySub?.id ?? ''),
    linkEnv,
  );
};
