/* eslint-disable @typescript-eslint/no-shadow */
import type { LocationChangeAction } from 'connected-react-router';
import { equals } from 'ramda';
import type { VideoErrorState } from 'react-jw-player';
import type { Reducer } from 'redux';
import { combineReducers } from 'redux';
import type { Time } from '@peloton/time';
import { toNowTime, formatDurationBetween } from '@peloton/time';
import { DeviceType } from '@engage/workouts';
import type { Timeline, Packet, Casting } from './models';

export const VIDEO_REDUCER_NAMESPACE = 'video';

// Action Creators
export const videoStreamSuccess = (stream: WorkoutStreamState, startTime: number) => ({
  type: VideoStreamActionType.videoStreamSuccess,
  payload: { stream, startTime },
});

export const loadVodPage = (
  classId: string,
  deviceType: DeviceType = DeviceType.Web,
  source?: string,
) => ({
  type: VideoLoadActionType.LoadVOD,
  payload: { classId, deviceType, source },
});

export const loadLiveVideoPage = (
  pelotonId: string,
  classId: string,
  deviceType: DeviceType = DeviceType.Web,
  source?: string,
  isEncore?: boolean,
) => ({
  type: VideoLoadActionType.LoadLiveVideo,
  payload: { pelotonId, classId, deviceType, source, isEncore },
});

export const exitWorkout = (workoutId: string) => ({
  type: VideoExitActionType.Exit,
  payload: { workoutId },
});

export const toggleIsVideoPaused = () => ({
  type: VideoStreamActionType.ToggleIsVideoPaused,
});

export const videoHeartbeat = (streamHistoryId: string) => ({
  type: VideoLoadActionType.Heartbeat,
  payload: { streamHistoryId },
});

export const videoError = (errorType: VideoErrorState) => ({
  type: VideoStreamActionType.Error,
  payload: errorType,
});

export const updateTimecode = (
  timecode: number,
  timeline: Timeline,
  isScrubbing = false,
) => ({
  type: TimecodeActionType.Update,
  payload: { timecode, isScrubbing, timeline },
});

export const castingStarted = () => ({
  type: CastingActionType.Started as CastingActionType.Started,
});

export const castingEnded = () => ({
  type: CastingActionType.Ended as CastingActionType.Ended,
});

export const castingToggle = (isCastingActive: boolean) =>
  isCastingActive ? castingStarted() : castingEnded();

export const setCastingDeviceNickname = (name: string) => ({
  type: CastingActionType.SetDeviceNickname as CastingActionType.SetDeviceNickname,
  payload: name,
});

export const loadTimeline = (id: string) => ({
  type: TimelineActionType.RequestTimeline,
  payload: { id },
});

export const sendPackets = (id: string, packet: Packet) => ({
  type: PacketActionType.Send,
  payload: {
    id,
    packet,
  },
});

export const seekOffsetByStartTime = (startTime: Time) => ({
  type: SeekActionType.Update,
  payload: {
    timecode: formatDurationBetween(startTime, toNowTime(), 'seconds'),
  },
});

export const updateVideoActiveView = (
  activeView: VideoActiveViewState,
  isStackModalClosing: boolean = false,
) => ({
  type: VideoActiveViewActionType.Update,
  payload: { activeView, isStackModalClosing },
});

export const clearWorkoutStream = () => ({
  type: VideoLoadActionType.Clear,
});

export const showVideoPlayer = () => updateVideoActiveView(VideoActiveViewState.Player);

export const reportHeartRate = (
  heartRate: number,
  avgHeartRate: number,
  maxHeartRate: number,
) => ({
  type: MetricsActionType.ReportHeartRate,
  payload: { heartRate, avgHeartRate, maxHeartRate },
});

export const reportCumulativeCalories = (calories: number) => ({
  type: MetricsActionType.ReportCalories,
  payload: { calories },
});

export const trackTimeWithCaptions = (captionsOn: boolean) => ({
  type: CaptionsActionType.TrackTime,
  payload: { captionsOn },
});

export const trackTimeWithAudio = (audioOn: boolean) => ({
  type: AudioActionType.TrackTime,
  payload: { audioOn },
});

export const setHereNowExpanded = (expanded: boolean) => ({
  type: HereNowActionType.ToggleExpand,
  payload: { expanded },
});

enum MetricsActionType {
  ReportCalories = '@engage/video/metrics/calories',
  ReportHeartRate = '@engage/video/metrics/heartRate',
}

export enum VideoStreamActionType {
  videoStreamSuccess = 'pelo/video/workoutStream/success',
  Error = 'pelo/video/error',
  ToggleIsVideoPaused = 'pelo/video/pause',
}

export enum VideoLoadActionType {
  LoadVOD = 'pelo/page/video/vod/load',
  LoadLiveVideo = 'pelo/page/video/live/load',
  Clear = 'members/video/CLEAR',
  Heartbeat = 'members/video/HEARTBEAT',
}

export enum TimecodeActionType {
  Update = 'pelo/video/timecode/update',
}

export enum CastingActionType {
  Started = 'pelo/video/casting/started',
  Ended = 'pelo/video/casting/ended',
  SetDeviceNickname = 'pelo/video/casting/setDeviceNickname',
}

export enum SeekActionType {
  Update = '/pelo/video/seek/update',
}

export enum VideoActiveViewActionType {
  Update = 'pelo/videoActiveView/update',
}

export enum TimelineActionType {
  RequestTimeline = 'pelo/ride/timeline',
  Success = 'pelo/ride/timeline/sucess',
  Failure = 'pelo/ride/timeline/failure',
}

export enum VideoExitActionType {
  Exit = 'pelo/pages/video/exit',
}

export enum PacketActionType {
  Send = 'pelo/stats/workout/id/packets`',
}

export enum CaptionsActionType {
  TrackTime = '@engage/video/captions/trackTime',
}

export enum AudioActionType {
  TrackTime = '@engage/video/audio/trackTime',
}

export enum HereNowActionType {
  ToggleExpand = '@engage/video/hereNow/toggleExpand',
}

type SeekAction = ReturnType<typeof seekOffsetByStartTime>;
export type TimecodeAction = ReturnType<typeof updateTimecode>;
type VideoActiveViewUpdateAction = ReturnType<typeof updateVideoActiveView>;
export type VODPageAction = ReturnType<typeof loadVodPage>;
export type LiveVideoPageAction = ReturnType<typeof loadLiveVideoPage>;

type LoadPageAction = VODPageAction | LiveVideoPageAction;
export type VideoStreamSuccessAction = ReturnType<typeof videoStreamSuccess>;
export type VideoErrorAction = ReturnType<typeof videoError>;

export type RideTimelineAction = ReturnType<typeof loadTimeline>;
export type SendPacketAction = ReturnType<typeof sendPackets>;

type MetricsAction = ReturnType<typeof reportCumulativeCalories & typeof reportHeartRate>;

type CastingAction =
  | ReturnType<typeof castingStarted>
  | ReturnType<typeof castingEnded>
  | ReturnType<typeof setCastingDeviceNickname>;

type ToggleIsVideoPausedAction = ReturnType<typeof toggleIsVideoPaused>;
type CaptionsAction = ReturnType<typeof trackTimeWithCaptions>;
type AudioAction = ReturnType<typeof trackTimeWithAudio>;
type SetHereNowExpandedAction = ReturnType<typeof setHereNowExpanded>;

// Selectors
export const getVideoUrl = (state: VideoSelectorState) => state.video.workoutStream.url;
export const getVideoIsPaused = (state: VideoSelectorState) =>
  state.video.videoViewState.isVideoPaused;
export const getWorkoutId = (state: VideoSelectorState) =>
  state.video.workoutStream.workoutId;
export const getStartTime = (state: VideoSelectorState) => state.video.startTime;
export const getCurrentTime = (state: VideoSelectorState) => state.video.currentTimecode;
export const getSeekTime = (state: VideoSelectorState) => state.video.seekTimecode;
export const getVideoErrorMessage = (state: VideoSelectorState) =>
  state.video.errorMessage;
export const getVideoActiveView = (state: VideoSelectorState) => state.video.activeView;
export const getTimeline = (state: VideoSelectorState) => state.video.timeline;
export const getStreamHistoryId = (state: VideoSelectorState) =>
  state.video.workoutStream.streamHistoryId;
export const getTimePlaying = (state: VideoSelectorState) => state.video.timePlaying;
export const getIntroTimePlaying = (state: VideoSelectorState) =>
  state.video.introTimePlaying;
export const getMetrics = (state: VideoSelectorState) => state.video.metrics;
export const getIsCasting = (state: VideoSelectorState) => state.video.casting.isCasting;
export const getCastingDeviceNickname = (state: VideoSelectorState) =>
  state.video.casting.deviceNickname;
export const getTimeCasting = (state: VideoSelectorState) =>
  state.video.casting.timeCasting;
export const getCaptions = (state: VideoSelectorState) => state.video.captions;
export const getAudio = (state: VideoSelectorState) => state.video.audio;
export const getIsHereNowExpanded = (state: VideoSelectorState) =>
  state.video.hereNow.expanded;

export type VideoSelectorState = { video: State };

// Reducers

const streamReducer: Reducer<WorkoutStreamState> = (
  state: WorkoutStreamState = {},
  action: VideoStreamSuccessAction | LoadPageAction,
) => {
  switch (action.type) {
    case VideoStreamActionType.videoStreamSuccess:
      return action.payload.stream;
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
      return { ...DEFAULT_STATE.workoutStream };
    case VideoLoadActionType.Clear:
      return DEFAULT_STATE.workoutStream;
    default:
      return state;
  }
};

const videoViewStateReducer: Reducer<VideoViewState> = (
  state: VideoViewState = {},
  action: ToggleIsVideoPausedAction | LoadPageAction,
) => {
  switch (action.type) {
    case VideoStreamActionType.ToggleIsVideoPaused:
      return { ...state, isVideoPaused: !state.isVideoPaused };
    case VideoLoadActionType.Clear:
      return DEFAULT_STATE.videoViewState;
    default:
      return state;
  }
};

const startTimeReducer: Reducer<number | null> = (
  state: number | null = DEFAULT_STATE.startTime,
  action: VideoStreamSuccessAction | LocationChangeAction | LoadPageAction,
) => {
  switch (action.type) {
    case VideoStreamActionType.videoStreamSuccess:
      return action.payload.startTime;
    case VideoLoadActionType.Clear:
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
      return DEFAULT_STATE.startTime;
    default:
      return state;
  }
};

const seekReducer: Reducer<number | null> = (
  state: number | null = DEFAULT_STATE.seekTimecode,
  action: SeekAction | LoadPageAction,
) => {
  switch (action.type) {
    case SeekActionType.Update:
      return action.payload.timecode;
    case VideoLoadActionType.Clear:
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
      return DEFAULT_STATE.seekTimecode;
    default:
      return state;
  }
};

const timecodeReducer: Reducer<number | null> = (
  state: number | null = DEFAULT_STATE.currentTimecode,
  action: TimecodeAction | LoadPageAction,
) => {
  switch (action.type) {
    case TimecodeActionType.Update:
      return action.payload.timecode;
    case VideoLoadActionType.Clear:
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
      return DEFAULT_STATE.currentTimecode;
    default:
      return state;
  }
};

const isCastingReducer: Reducer<Casting['isCasting']> = (
  state = DEFAULT_STATE.casting.isCasting,
  action: CastingAction,
) => {
  switch (action.type) {
    case CastingActionType.Started:
      return true;
    case CastingActionType.Ended:
      return false;
    default:
      return state;
  }
};

const deviceNicknameReducer: Reducer<Casting['deviceNickname']> = (
  state = DEFAULT_STATE.casting.deviceNickname,
  action: CastingAction,
) => {
  switch (action.type) {
    case CastingActionType.SetDeviceNickname:
      return action.payload;
    case CastingActionType.Ended:
      return DEFAULT_STATE.casting.deviceNickname;
    default:
      return state;
  }
};

const timeCastingReducer = (
  state: Casting['timeCasting'] = DEFAULT_STATE.casting.timeCasting,
  isCasting: boolean,
  action: TimecodeAction | LoadPageAction,
) => {
  switch (action.type) {
    case TimecodeActionType.Update:
      return isCasting ? state + 1 : state;
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
    case VideoLoadActionType.Clear:
      return DEFAULT_STATE.casting.timeCasting;
    default:
      return state;
  }
};

const castingReducer: Reducer<Casting> = (
  state = DEFAULT_STATE.casting,
  action: CastingAction | TimecodeAction | LoadPageAction,
) => {
  const nextState = {
    isCasting: isCastingReducer(state.isCasting, action),
    deviceNickname: deviceNicknameReducer(state.deviceNickname, action),
    timeCasting: timeCastingReducer(
      state.timeCasting,
      state.isCasting,
      action as TimecodeAction | LoadPageAction,
    ),
  };

  return equals(state, nextState) ? state : nextState;
};

export const videoActiveViewReducer: Reducer<VideoActiveViewState | null> = (
  state: VideoActiveViewState | null = DEFAULT_STATE.activeView,
  action: VideoActiveViewUpdateAction | LoadPageAction | VideoStreamSuccessAction,
) => {
  switch (action.type) {
    case VideoStreamActionType.Error:
      return VideoActiveViewState.Error;
    case VideoActiveViewActionType.Update:
      return action.payload.activeView;
    case VideoLoadActionType.Clear:
      return DEFAULT_STATE.activeView;
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
      return VideoActiveViewState.Loading;
    case VideoStreamActionType.videoStreamSuccess:
      return VideoActiveViewState.Player;
    default:
      return state;
  }
};

export const timelineReducer: Reducer<Timeline> = (
  state: Timeline = DEFAULT_STATE.timeline,
  action: TimelineSuccessAction | LoadPageAction,
) => {
  switch (action.type) {
    case TimelineActionType.Success:
      return action.payload;
    case VideoLoadActionType.Clear:
      return DEFAULT_STATE.timeline;
    default:
      return state;
  }
};

export const isTimelineEmpty = (timeline: Timeline) =>
  equals(timeline, DEFAULT_STATE.timeline);

const introTimePlayingReducer: Reducer<number> = (
  state: State['introTimePlaying'] = DEFAULT_STATE.introTimePlaying,
  action: TimecodeAction | LoadPageAction,
) => {
  switch (action.type) {
    case TimecodeActionType.Update: {
      const { timecode, timeline } = action.payload;
      const classStartOffset = timeline?.classStartOffset ?? 0;

      if (timecode < classStartOffset) {
        return state + 1;
      }
      return state;
    }
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
    case VideoLoadActionType.Clear:
      return DEFAULT_STATE.introTimePlaying;
    default:
      return state;
  }
};

export const timePlayingReducer: Reducer<number> = (
  state: State['timePlaying'] = DEFAULT_STATE.timePlaying,
  action: TimecodeAction | LoadPageAction,
) => {
  switch (action.type) {
    case TimecodeActionType.Update:
      return state + 1;
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
    case VideoLoadActionType.Clear:
      return DEFAULT_STATE.timePlaying;
    default:
      return state;
  }
};

export const loadTimelineSuccess = (klass: Timeline) => ({
  type: TimelineActionType.Success,
  payload: klass,
});

export type TimelineSuccessAction = {
  type: TimelineActionType.Success;
  payload: Timeline;
};

type CaptionsState = {
  captionsOn: boolean;
  timeWithCaptions: number;
};

type AudioState = {
  audioOn: boolean;
  timeWithAudio: number;
};

type HereNowState = {
  expanded: boolean;
};

export type State = {
  workoutStream: WorkoutStreamState;
  casting: Casting;
  startTime: number | null;
  errorMessage: VideoErrorState;
  currentTimecode: number | null;
  seekTimecode: number | null;
  activeView: VideoActiveViewState | null;
  timeline: Timeline;
  timePlaying: number;
  introTimePlaying: number;
  metrics: MetricsState;
  videoViewState: VideoViewState;
  captions: CaptionsState;
  audio: AudioState;
  hereNow: HereNowState;
};

export const DEFAULT_STATE: State = Object.freeze({
  workoutStream: {},
  casting: {
    isCasting: false,
    deviceNickname: null,
    timeCasting: 0,
  },
  errorMessage: {},
  startTime: null,
  currentTimecode: null,
  seekTimecode: null,
  activeView: null,
  timeline: {
    classStartOffset: undefined,
    classEndOffset: undefined,
    videoEndOffset: undefined,
    segments: [],
  },
  timePlaying: 0,
  introTimePlaying: 0,
  metrics: {
    calories: 0,
    heartRate: 0,
    avgHeartRate: 0,
    maxHeartRate: 0,
  },
  videoViewState: {},
  captions: {
    captionsOn: false,
    timeWithCaptions: 0,
  },
  audio: {
    audioOn: false,
    timeWithAudio: 0,
  },
  hereNow: {
    expanded: true,
  },
});

export type WorkoutStreamState = {
  url?: string;
  workoutId?: string;
  streamHistoryId?: string;
};

export type VideoViewState = {
  isVideoPaused?: boolean;
};

export type TimelineState = {
  timeline?: Timeline;
};

export enum VideoActiveViewState {
  Loading = 'Loading',
  Player = 'Player',
  Exit = 'Exit',
  Complete = 'Complete',
  Paywall = 'Paywall',
  StreamLimit = 'StreamLimit',
  Error = 'Error',
  StackComplete = 'Stack Complete',
}

export const videoErrorReducer: Reducer<VideoErrorState> = (
  state: VideoErrorState = {},
  action: VideoErrorAction | LoadPageAction,
) => {
  switch (action.type) {
    case VideoStreamActionType.Error:
      return action.payload;
    case VideoLoadActionType.Clear:
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
      return DEFAULT_STATE.errorMessage;
    case VideoStreamActionType.videoStreamSuccess:
      return DEFAULT_STATE.errorMessage;
    default:
      return state;
  }
};

export type MetricsState = {
  calories: number;
  heartRate: number;
  avgHeartRate: number;
  maxHeartRate: number;
};

export const metricsReducer: Reducer<MetricsState> = (
  state: MetricsState = DEFAULT_STATE.metrics,
  action: MetricsAction | LoadPageAction,
) => {
  switch (action.type) {
    case MetricsActionType.ReportHeartRate:
      return { ...state, ...action.payload };
    case MetricsActionType.ReportCalories:
      return { ...state, ...action.payload };
    case VideoLoadActionType.Clear:
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
      return DEFAULT_STATE.metrics;
    default:
      return state;
  }
};

export const captionsReducer: Reducer<CaptionsState> = (
  state: CaptionsState = DEFAULT_STATE.captions,
  action: CaptionsAction | TimecodeAction | LoadPageAction,
) => {
  switch (action.type) {
    case CaptionsActionType.TrackTime:
      return { ...state, captionsOn: action.payload.captionsOn };
    case TimecodeActionType.Update:
      return {
        ...state,
        timeWithCaptions: state.captionsOn
          ? state.timeWithCaptions + 1
          : state.timeWithCaptions,
      };
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
    case VideoLoadActionType.Clear:
      return { ...state, timeWithCaptions: DEFAULT_STATE.captions.timeWithCaptions };
    default:
      return state;
  }
};

export const audioReducer: Reducer<AudioState> = (
  state: AudioState = DEFAULT_STATE.audio,
  action: AudioAction | TimecodeAction | LoadPageAction,
) => {
  switch (action.type) {
    case AudioActionType.TrackTime:
      return { ...state, audioOn: action.payload.audioOn };
    case TimecodeActionType.Update:
      return {
        ...state,
        timeWithAudio: state.audioOn ? state.timeWithAudio + 1 : state.timeWithAudio,
      };
    case VideoLoadActionType.LoadVOD:
    case VideoLoadActionType.LoadLiveVideo:
    case VideoLoadActionType.Clear:
      return { ...state, timeWithAudio: DEFAULT_STATE.audio.timeWithAudio };
    default:
      return state;
  }
};

export const hereNowReducer: Reducer<HereNowState> = (
  state: HereNowState = DEFAULT_STATE.hereNow,
  action: SetHereNowExpandedAction,
) => {
  switch (action.type) {
    case HereNowActionType.ToggleExpand:
      return { ...state, expanded: action.payload.expanded };
    default:
      return state;
  }
};

export const sharedReducers = {
  workoutStream: streamReducer,
  errorMessage: videoErrorReducer,
  startTime: startTimeReducer,
  currentTimecode: timecodeReducer,
  seekTimecode: seekReducer,
  activeView: videoActiveViewReducer,
  timeline: timelineReducer,
  timePlaying: timePlayingReducer,
  introTimePlaying: introTimePlayingReducer,
  metrics: metricsReducer,
  casting: castingReducer,
  videoViewState: videoViewStateReducer,
  captions: captionsReducer,
  audio: audioReducer,
  hereNow: hereNowReducer,
};

// This is no longer used by Members app
export const videoReducer = combineReducers<State>(sharedReducers);
