import React from 'react';
import { getExtLinkEnv } from '@peloton/external-links';
import { useReduxState } from '@engage/redux';
import { useTagNameFromLocation } from '@members/tags/hooks';
import { useTrackShareTagToFacebook, useTrackTagCopyLink } from './analytics';
import type { ShareTooltipProps } from './ShareTooltip';
import { ShareTooltip } from './ShareTooltip';
import { toShareUrl } from './toShareUrl';

export const ShareTagTooltip: React.FC<React.PropsWithChildren<ShareTooltipProps>> = ({
  source,
  position,
}) => {
  const tagName = useTagNameFromLocation();
  const env = useReduxState(getExtLinkEnv);
  const trackShareTagToFacebook = useTrackShareTagToFacebook();
  const trackShareTagToClipboard = useTrackTagCopyLink();
  const path = `/tags/${tagName}`;

  return (
    <ShareTooltip
      source={source}
      shareUrl={toShareUrl(env, path)}
      trackShareToFacebook={trackShareTagToFacebook}
      trackShareToClipboard={trackShareTagToClipboard}
      position={position}
    />
  );
};
