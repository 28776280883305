import React from 'react';
import styled, { css } from 'styled-components';
import { gray2 } from '@engage/colors';
import { spaces } from '@engage/styles';
import type { Tag } from '@members/graphql-swr/types.generated';
import { MetaTag } from '../models';
import { Tag as CreateTag } from '../Tag';

const CreateTagView: React.FC<
  React.PropsWithChildren<{
    withBorder: boolean;
    tag?: Partial<Tag>;
  }>
> = ({ tag, withBorder }) => {
  const tagName = tag?.name ?? '';
  return (
    <Container data-test-id="createTagView" withBorder={withBorder}>
      <Wrapper withBorder={withBorder}>
        <CreateTag
          backgroundImageUrl="https://cdn.zeplin.io/5df2715148865ea64a86dd2b/assets/9543D3D4-3019-4A23-9730-9CEDD1CCDF84.png"
          hasAdded={false}
          isCreatingTag={true}
          isPrimary={false}
          key={tagName}
          metaTag={MetaTag.NONE}
          numberOfFollowing={0}
          numberOfMembers={0}
          tagName={tagName}
          viewingOwnTag={false}
        />
      </Wrapper>
    </Container>
  );
};

export { CreateTagView };

const Container = styled.div<{ withBorder: boolean }>`
  padding: ${({ withBorder }) =>
    withBorder
      ? css`
          ${spaces.xxxSmall}px 0 ${spaces.large}px
        `
      : css`
          ${spaces.xxxSmall}px ${spaces.small}px ${spaces.large}px ${spaces.small}px
        `};
`;

const Wrapper = styled.div<{ withBorder: boolean }>`
  width: 100%;
  border-bottom: ${({ withBorder }) => (withBorder ? `1px solid ${gray2}` : 'none')};
  padding-bottom: ${spaces.large}px;
`;
