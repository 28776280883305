import React from 'react';

const StackedPlay: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#4C525E"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3C3.67157 3 3 3.67157 3 4.5V19C3 19.2761 2.77614 19.5 2.5 19.5C2.22386 19.5 2 19.2761 2 19V4.5C2 3.11929 3.11929 2 4.5 2H19C19.2761 2 19.5 2.22386 19.5 2.5C19.5 2.77614 19.2761 3 19 3H4.5ZM5.5 7C5.5 6.17157 6.17157 5.5 7 5.5H20.5C21.3284 5.5 22 6.17157 22 7V20.5C22 21.3284 21.3284 22 20.5 22H7C6.17157 22 5.5 21.3284 5.5 20.5V7ZM7 6.5C6.72386 6.5 6.5 6.72386 6.5 7V20.5C6.5 20.7761 6.72386 21 7 21H20.5C20.7761 21 21 20.7761 21 20.5V7C21 6.72386 20.7761 6.5 20.5 6.5H7ZM16.5986 14L12 17.0658V10.9343L16.5986 14ZM12.166 9.8431C11.6676 9.51083 11 9.86812 11 10.4671V17.5329C11 18.1319 11.6676 18.4892 12.166 18.1569L17.4653 14.6241C17.9106 14.3272 17.9106 13.6728 17.4653 13.376L12.166 9.8431Z"
    />
  </svg>
);

export { StackedPlay };
