import React from 'react';

const ExploreTags: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#222529"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5ZM9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19C11.9435 19 14.1714 18.0775 15.8547 16.5618L19.1465 19.8536C19.3417 20.0489 19.6583 20.0489 19.8536 19.8536C20.0489 19.6583 20.0489 19.3417 19.8536 19.1465L16.5618 15.8547C18.0775 14.1714 19 11.9435 19 9.5C19 4.25329 14.7467 0 9.5 0ZM8.97737 5.64915C9.05974 5.38558 8.91284 5.10514 8.64927 5.02277C8.3857 4.94041 8.10526 5.0873 8.02289 5.35087L7.50754 7H6C5.72386 7 5.5 7.22386 5.5 7.5C5.5 7.77614 5.72386 8 6 8H7.19504L6.25754 11H5C4.72386 11 4.5 11.2239 4.5 11.5C4.5 11.7761 4.72386 12 5 12H5.94504L5.52289 13.3509C5.44053 13.6144 5.58742 13.8949 5.851 13.9773C6.11457 14.0596 6.39501 13.9127 6.47737 13.6491L6.99273 12H10.445L10.0229 13.3509C9.94053 13.6144 10.0874 13.8949 10.351 13.9773C10.6146 14.0596 10.895 13.9127 10.9774 13.6491L11.4927 12H13C13.2761 12 13.5 11.7761 13.5 11.5C13.5 11.2239 13.2761 11 13 11H11.8052L12.7427 8H14C14.2761 8 14.5 7.77614 14.5 7.5C14.5 7.22386 14.2761 7 14 7H13.0552L13.4774 5.64915C13.5597 5.38558 13.4128 5.10514 13.1493 5.02277C12.8857 4.94041 12.6053 5.0873 12.5229 5.35087L12.0075 7H8.55523L8.97737 5.64915ZM10.7575 11H7.30523L8.24273 8H11.695L10.7575 11Z"
    />
  </svg>
);

export { ExploreTags };
