import styled from 'styled-components';
import { media } from '@peloton/styles';
import { gray1 } from '@engage/colors';
import { spaces } from '@engage/styles';

export const StandardModalContainer = styled.div`
  background-color: ${gray1};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spaces.huge}px 25px;
  text-align: center;
  ${media.tablet`
    padding: 50px 30px;
  `}
`;

export const modalHeight = 'calc(100vh - 55px - 40px)';
export const tagsModalMaxHeight = '792px';
export const tagsModalMinHeight = '296px';
