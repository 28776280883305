import type { Client, UserAttributes } from '@optimizely/optimizely-sdk';
import { useEffect, useState } from 'react';
import type { PendingResult } from '@engage/result';
import { ok, isOk, isError, getOkValue, pending } from '@engage/result';
import { useClient } from './client';

const getToggles = (client: Client, memberId: string, attributes?: UserAttributes) =>
  client.getEnabledFeatures(memberId, attributes);

type EnabledFeatures = PendingResult<string[], undefined>;
export const useEnabledFeatures = (
  userId: string,
  attributes?: UserAttributes,
): EnabledFeatures => {
  const [remoteToggles, setRemoteToggles] = useState<EnabledFeatures>(pending);
  const [client] = useClient();

  useEffect(() => {
    if (isOk(client)) {
      setRemoteToggles(ok(getToggles(getOkValue(client)!, userId, attributes)));
    } else if (isError(client)) {
      setRemoteToggles(ok([])); // disable all features
    }
  }, [client, userId, attributes]);

  return remoteToggles;
};
