import React from 'react';
import styled from 'styled-components';
import { slate1, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label10Caps } from '@engage/typography';
import { Classes, ClassesCopy } from '@members/copy';

const AudioLabel: React.FC<React.PropsWithChildren<unknown>> = props => (
  <Container {...props}>
    <ClassesCopy id={Classes.AudioOnly} />
  </Container>
);

const Container = styled.div`
  ${label10Caps};
  color: ${white};
  width: fit-content;
  border-radius: ${spaces.xxxSmall}px;
  text-align: center;
  padding: ${spaces.tiny}px ${spaces.xxxSmall}px;
  background-color: ${slate1};
  cursor: default;
`;

export default AudioLabel;
