import type { LocationSource } from '@members/pg-schedule/helpers/type-helpers';

export const SUPPORTED_INVITE_PARAMS = ['id', 'invite_id', 'join_token'];
export const IS_SHARED_SESSION_STORAGE_KEY = 'isInviteShareLink';
export type InviteParams = {
  id?: string;
  invite_id?: string;
  join_token?: string;
  source?: LocationSource;
};
