import React from 'react';
import { GRAPHQL_LOCALE_CODE_TO_REST_LOCALE_CODE_MAP_FOR_MEMBERS_PG_SCHEDULE_HELPERS } from '@peloton/internationalize';
import { useReduxState, useReduxAction } from '@engage/redux';
import type { PendingInviteCountQuery } from '@members/graphql-swr/schemas/scheduled-classes/queries/PendingInviteCount.generated';
import type { YourScheduleItemsQuery } from '@members/graphql-swr/schemas/scheduled-classes/queries/YourScheduleItems.generated';
import { LocaleCode } from '@members/graphql-swr/types.generated';
import { useRemoveClassFromScheduleMutationSWR } from '../hooks/useRemoveClassFromScheduleMutationSWR';
import { getNumOfPendingInvites, setNumOfPendingInvites } from '../redux';

export const useRemoveScheduleById = (id: string | null) => {
  const {
    removeClassFromScheduleMutation,
    isLoading: isRemoveClassLoading,
    error: removeClassError,
  } = useRemoveClassFromScheduleMutationSWR();

  const removeFromClassAction = React.useCallback(
    () =>
      removeClassFromScheduleMutation({
        id: id || '',
      }),
    [id, removeClassFromScheduleMutation],
  );

  return {
    removeClassFromScheduleMutation,
    removeFromClassAction,
    isRemoveClassLoading,
    removeClassError,
  };
};

export const formatGraphqlLocale = (localeCode: LocaleCode = LocaleCode.EsUs) =>
  GRAPHQL_LOCALE_CODE_TO_REST_LOCALE_CODE_MAP_FOR_MEMBERS_PG_SCHEDULE_HELPERS[localeCode];

export const useManagePendingInvites = (
  data: YourScheduleItemsQuery | PendingInviteCountQuery | undefined,
) => {
  const numOfPendingInvites = useReduxState(getNumOfPendingInvites);
  const setPendingInvitesAction = useReduxAction(setNumOfPendingInvites);
  const valueToShow = numOfPendingInvites > 99 ? 99 : numOfPendingInvites;

  React.useEffect(() => {
    if (data?.userScheduledItemsList?.__typename === 'YourScheduleItemList') {
      const itemList = data?.userScheduledItemsList?.pendingInvites;
      if (itemList.__typename === 'PendingInvites') {
        setPendingInvitesAction(itemList.countOfPendingInvites);
      }
    }
  }, [data]);

  return {
    valueToShow,
    numOfPendingInvites,
  };
};
