import { replace } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import {
  call,
  getContext,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { isMe } from '@peloton/auth';
import { toOverviewUrl } from '@peloton/links/members';
import { getLocation } from '@peloton/redux';
import { track } from '@engage/analytics';
import { logError } from '@engage/error-reporting/logError';
import {
  getMember,
  isMembersSuccessActionForMember,
  loadMember,
  shouldShowPrivateInfo,
} from '@engage/members';
import { toWorkoutHistoryPath } from '@engage/overview';
import type { RequestNextWorkoutsPageAction } from '@engage/workout-history';
import {
  getTotalWorkouts,
  loadWorkoutNextHistoryPage,
  requestNextWorkoutsPage,
  resetWorkouts,
} from '@engage/workout-history';

import { isMemberPath } from '@members/profile/urls';
import { updateViewedMember } from '@members/viewed-member';
import { trackViewedWorkoutHistoryPage } from '../analytics';
import { getViewedMemberIdFromPath } from '../urls';
import { WorkoutHistoryPageActionTypes } from './actions';

export const loadWorkoutsForWorkoutHistory = function* (
  client: Client,
  action: any,
): SagaIterator {
  const location = yield select(getLocation);
  const memberId = getViewedMemberIdFromPath(location.pathname)!; // We know we're on the workouts page
  const member = yield select(getMember, memberId);

  yield call(
    loadWorkoutNextHistoryPage,
    client,
    requestNextWorkoutsPage(member.id, action.payload) as RequestNextWorkoutsPageAction,
  );
};

export const loadWorkoutHistoryPageSaga = function* (
  client: Client,
  action: any,
): SagaIterator {
  try {
    const location = yield select(getLocation);
    const viewedMemberId = getViewedMemberIdFromPath(location.pathname)!; // We know we're on the workouts page
    yield put(updateViewedMember(viewedMemberId));
    let member = yield select(getMember, viewedMemberId);

    if (!member) {
      yield put(loadMember(viewedMemberId));
      const membersAction = yield take(isMembersSuccessActionForMember(viewedMemberId));
      member = membersAction.payload;
    }

    const isViewedMemberMe = yield select(isMe, member.id);
    if (isViewedMemberMe && isMemberPath(location.pathname)) {
      yield put(replace(toWorkoutHistoryPath(isViewedMemberMe, viewedMemberId)));
    }

    if (!shouldShowPrivateInfo(member)) {
      yield put(replace(toOverviewUrl(member.username)));
    } else {
      yield put(resetWorkouts());
      yield call(loadWorkoutsForWorkoutHistory, client, action);
    }
    const totalWorkouts = yield select(getTotalWorkouts);
    const sharedLeaderboardTag = action.payload.sharedLeaderboardTag;
    yield put(
      track(
        trackViewedWorkoutHistoryPage(
          member,
          location,
          totalWorkouts,
          sharedLeaderboardTag,
        ),
      ),
    );
  } catch (err) {
    logError(err, 'loadWorkoutHistoryPageSaga');
  }
};

export const workoutHistoryPageSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeLatest(
    WorkoutHistoryPageActionTypes.RequestNewWorkouts,
    loadWorkoutHistoryPageSaga,
    client,
  );
  yield takeEvery(
    WorkoutHistoryPageActionTypes.RequestMoreWorkouts,
    loadWorkoutsForWorkoutHistory,
    client,
  );
};
