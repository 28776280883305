import { combineReducers } from 'redux';
import type { ActiveCategoryState } from './activeCategory';
import { activeCategoryReducer } from './activeCategory';
import type { BrowseCategoriesState } from './browseCategories';
import { browseCategoriesReducer } from './browseCategories';
import type { LibraryState } from './classes';
import { classIdsReducer } from './classes';

export * from './classes';
export * from './browseCategories';
export * from './activeCategory';

export const libraryReducer = combineReducers<State>({
  activeCategory: activeCategoryReducer,
  classIds: classIdsReducer,
  browseCategories: browseCategoriesReducer,
});

export type State = {
  activeCategory: ActiveCategoryState;
  classIds: LibraryState;
  browseCategories: BrowseCategoriesState;
};
