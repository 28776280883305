import { ANALYTICS_CONTEXT } from '@peloton/analytics';
import { CLIENT_CONTEXT as API_CLIENT, toClient } from '@peloton/api';
import { ERROR_REPORTING_CLIENT_CONTEXT } from '@peloton/error-reporting';
import type { ErrorHandler } from '@peloton/error-reporting';
import { EXT_LINK_ENV_CONTEXT } from '@peloton/external-links';
import { toStore } from '@peloton/redux';
import { toWebBaseProperties } from '@members/analytics';
import { toStoreReducers, toStoreSagas } from '@members/bootstrapping';
import { toPersistentRootReducer, createPersistentStore } from '@members/persist';
export const generateStore = ({
  env,
  errorHandler,
}: {
  env: any;
  errorHandler: ErrorHandler;
}) => {
  const client = toClient({
    env: env.extLink,
    withCredentials: false,
  });

  const store = toStore({
    initialState: {
      extLinkEnv: env.extLink,
    },

    reducers: toStoreReducers(),
    sagas: toStoreSagas(),

    context: {
      [API_CLIENT]: client,
      [ERROR_REPORTING_CLIENT_CONTEXT]: errorHandler.reportError,
      [EXT_LINK_ENV_CONTEXT]: env.extLink,
      [ANALYTICS_CONTEXT]: toWebBaseProperties(),
    },
    onError: errorHandler.sagaOnError,

    rootReducer: toPersistentRootReducer,

    createStore: createPersistentStore,
  });

  return { client, store };
};
