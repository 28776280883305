import type { RoutingState } from '@peloton/redux';
import { getQueryParams } from '@peloton/redux';
import { isDefined } from '@peloton/types';
import { TAGS_VIEW_PARAM, TAG_NAME_PARAM, TAG_BROWSE_CATEGORY_PARAM } from '@engage/tags';
import { TagModalView } from './shared';

export const getTagNameFromLocation = (state: RoutingState) =>
  getQueryParams(state)?.[TAG_NAME_PARAM];

export const getTagViewFromLocation = (state: RoutingState) => {
  const tagViewParam = getTagViewParamFromLocation(state);
  if (isDefined(tagViewParam)) {
    return toTagModalView(tagViewParam.toLowerCase());
  } else {
    return undefined;
  }
};

const getTagViewParamFromLocation = (state: RoutingState) =>
  getQueryParams(state)?.[TAGS_VIEW_PARAM];

const toTagModalView = (view: string) => {
  const values = Object.keys(TagModalView).map(k => ({
    k,
    v: TagModalView[k].toLowerCase(),
  }));
  const found = values.find(({ v }) => view === v);
  return found ? TagModalView[found.k] : TagModalView.BrowseTags;
};

export const getLastTagView = (state: RoutingState) => {
  const stack = state.router.location.state?.viewStack ?? [];
  return stack.length > 0 ? stack[stack.length - 1] : undefined;
};

export const getTagBrowseCategoryFromLocation = (state: RoutingState) =>
  getQueryParams(state)?.[TAG_BROWSE_CATEGORY_PARAM];
