import React from 'react';

const StackedPlayAlert: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3C3.67157 3 3 3.67157 3 4.5V19C3 19.2761 2.77614 19.5 2.5 19.5C2.22386 19.5 2 19.2761 2 19V4.5C2 3.11929 3.11929 2 4.5 2H15.5C15.7761 2 16 2.22386 16 2.5C16 2.77614 15.7761 3 15.5 3H4.5ZM7 6.5C6.72386 6.5 6.5 6.72386 6.5 7V20.5C6.5 20.7761 6.72386 21 7 21H20.5C20.7761 21 21 20.7761 21 20.5V8.5C21 8.22386 21.2239 8 21.5 8C21.7761 8 22 8.22386 22 8.5V20.5C22 21.3284 21.3284 22 20.5 22H7C6.17157 22 5.5 21.3284 5.5 20.5V7C5.5 6.17157 6.17157 5.5 7 5.5H15.5C15.7761 5.5 16 5.72386 16 6C16 6.27614 15.7761 6.5 15.5 6.5H7ZM16.5986 14L12 17.0658V10.9343L16.5986 14ZM12.166 9.8431C11.6676 9.51083 11 9.86812 11 10.4671V17.5329C11 18.1319 11.6676 18.4892 12.166 18.1569L17.4653 14.6241C17.9106 14.3272 17.9106 13.6728 17.4653 13.376L12.166 9.8431Z"
      fill="#4C525E"
    />
    <path
      data-test-id="redDotIcon"
      d="M19.5 7C20.8807 7 22 5.88071 22 4.5C22 3.11929 20.8807 2 19.5 2C18.1193 2 17 3.11929 17 4.5C17 5.88071 18.1193 7 19.5 7Z"
      fill="#FF3347"
    />
  </svg>
);

export { StackedPlayAlert };
