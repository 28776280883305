import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { useOauth } from '@peloton/auth';
import { Language, toLocale } from '@peloton/internationalize';
import { useReduxState } from '@engage/redux';
import { getDisplayLanguage } from '@engage/settings';
import { toClient } from './client';

export const GraphqlProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const language = useReduxState(getDisplayLanguage);
  const { getAccessTokenSilently, isAuthenticated } = useOauth();

  const toggledGetAccessTokenSilently = React.useCallback(() => {
    if (isAuthenticated) {
      return getAccessTokenSilently();
    }

    return Promise.resolve('');
  }, [isAuthenticated]);

  const client = toClient(
    {
      headers: {
        'Accept-Language': toLocale(language ?? Language.English),
      },
    },
    toggledGetAccessTokenSilently,
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
