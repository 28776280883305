import React from 'react';

const ReferFriends: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#222529"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 0C6.50736 0 5.5 1.00736 5.5 2.25C5.5 2.71254 5.63957 3.14248 5.87889 3.5L1 3.5C0.447715 3.5 0 3.94772 0 4.5L2.563e-06 7.5C3.06964e-06 8.05229 0.44772 8.5 1 8.5H1.5V19C1.5 19.5523 1.94772 20 2.5 20H17.5C18.0523 20 18.5 19.5523 18.5 19V8.5H19C19.5523 8.5 20 8.05228 20 7.5V4.5C20 3.94771 19.5523 3.5 19 3.5L14.1211 3.5C14.3604 3.14248 14.5 2.71254 14.5 2.25C14.5 1.00736 13.4926 0 12.25 0H12C11.1822 0 10.4561 0.392672 10 0.999758C9.54389 0.392672 8.8178 0 8 0H7.75ZM9.5 2.5C9.5 1.67157 8.82843 1 8 1H7.75C7.05964 1 6.5 1.55964 6.5 2.25C6.5 2.94036 7.05964 3.5 7.75 3.5H9.5V2.5ZM9.5 4.5H7.75L1 4.5L1 7.5H9.5V4.5ZM12.25 4.5H10.5V7.5H18H19V4.5H12.25ZM12.25 3.5C12.9404 3.5 13.5 2.94036 13.5 2.25C13.5 1.55964 12.9404 1 12.25 1H12C11.1716 1 10.5 1.67157 10.5 2.5V3.5L12.25 3.5ZM2.5 8.5H9.5V19H2.5V8.5ZM10.5 8.5V19H17.5V8.5H10.5Z"
    />
  </svg>
);

export { ReferFriends };
