import type { Location } from 'history';
import { REDIRECT_URL_QUERY_PARAM } from '@peloton/external-links/urls';

export const CALLBACK = '/callback';
export const LOGIN_ROUTE = '/login';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const REMOTE_ACTIVATION_ROUTE = '/activate';
export const REMOTE_RESET_SUCCESS_ROUTE = '/remote-success';
export const INTERACTIVE_VOICE_RESPONSE_ROUTE = '/interactive-voice-response';
export const toLoginRoute = (location: Location<any>) =>
  `${LOGIN_ROUTE}?${REDIRECT_URL_QUERY_PARAM}=${btoa(
    location.pathname + location.search,
  )}`;

export const TAGS_LOGIN_ROUTE = (tagName?: string) =>
  `/tag-login/${tagName ? tagName : ':tagName'}`;
export const toTagsLoginRoute = (tagName: string, location: Location<any>) =>
  `${TAGS_LOGIN_ROUTE(tagName)}?${REDIRECT_URL_QUERY_PARAM}=${btoa(
    location.pathname + location.search,
  )}`;

export const REACTIVATE_ACCOUNT_ROUTE = '/reactivate-account/:resetHashId';
