import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { gray3, gray5, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { title24, body16, label16Caps } from '@engage/typography';
import { Button3Large } from '@members/buttons';
import { Stacks, StacksCopy } from '@members/copy';
import { LIBRARY_CATEGORY_SELECTION_ROUTE } from '@members/pg-library/urls';
import { ANALYTICS_SOURCE_STACKED_CLASSES } from '@members/stacks/analytics';
import { Stack } from '../icons';

const EmptyState: React.FC<React.PropsWithChildren<RouteComponentProps<{}>>> = ({
  history,
}) => {
  const onClick = () => {
    const location = {
      pathname: LIBRARY_CATEGORY_SELECTION_ROUTE,
      state: { source: ANALYTICS_SOURCE_STACKED_CLASSES },
    };
    history.push(location);
  };

  return (
    <StackContainer data-test-id="emptyStackContainer">
      <Stack />
      <EmptyStackText data-test-id="emptyStackText">
        <StacksCopy id={Stacks.EmptyStack} />
      </EmptyStackText>
      <AddText data-test-id="emptyStackAddText">
        <StacksCopy id={Stacks.AddStack} />
      </AddText>
      <ModalButton onClick={onClick} data-test-id="browseClassesButton">
        <StacksCopy id={Stacks.BrowseClasses} />
      </ModalButton>
    </StackContainer>
  );
};

const EmptyStateRouted = withRouter(EmptyState);

const StackContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: ${spaces.xxxHuge}px;
  flex-direction: column;
  padding: ${spaces.medium}px ${spaces.small}px;
  svg {
    height: 64px;
    width: 64px;
    stroke: ${white};
  }

  ${media.tablet`
    padding-left: ${spaces.medium}px;
    padding-right: ${spaces.medium}px;
    margin-top: 78px;
  `};
`;

const EmptyStackText = styled.p`
  margin-top: ${spaces.medium}px;
  color: ${gray3};
  ${title24}
`;

const AddText = styled.p`
  margin-top: ${spaces.medium}px;
  color: ${gray5};
  text-align: center;
  ${body16}
`;

const ModalButton = styled(Button3Large)`
  ${label16Caps}
  margin-top: ${spaces.xxLarge}px;
  width: 280px;
  height: 48px;
  stroke: ${gray3};
  color: ${white};
`;

export default EmptyStateRouted;
