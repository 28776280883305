import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import type { UserSelectorState } from '@peloton/auth';
import { getSignedInUserId } from '@peloton/auth';

const withViewedMemberId = compose(
  connect((state: UserSelectorState) => ({
    meUserId: getSignedInUserId(state),
  })),
  mapProps(({ meUserId, ...props }) => ({
    viewedMemberId: props.match.params.userId || meUserId,
    ...props,
  })),
);

export default withViewedMemberId;

export type ViewedMemberProps = {
  viewedMemberId: string;
};
