import React from 'react';
import type { Store } from 'redux';
import { persistStore } from 'redux-persist';

export const PersistGate: React.FC<React.PropsWithChildren<{ store: Store }>> = props => {
  const [isBootstrapped, setIsBootstrapped] = React.useState(false);
  React.useEffect(() => {
    const persistor = persistStore(props.store);
    const handlePersistorStateChange = (): void => {
      const { bootstrapped } = persistor.getState();
      if (bootstrapped && !isBootstrapped) {
        setIsBootstrapped(true);
        unsubscribe();
      }
    };

    const unsubscribe = persistor.subscribe(handlePersistorStateChange);
    return () => unsubscribe();
  }, []);

  if (!isBootstrapped) {
    return null;
  }
  return <>{props.children}</>;
};
