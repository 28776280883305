import React from 'react';
import { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import type { TagModalView } from './shared';

type Context = {
  setView: (
    view: TagModalView,
    tagName?: string,
    browseCategory?: TagBrowseCategorySlug,
  ) => void;
  goBack: () => void;
  setShowErrorToast: (b: boolean) => void;
  showBackButton: boolean;
  goBackTo: string | undefined;
  lastScroll: number;
  setLastScroll: (lastScroll: number) => void;
  lastActiveBrowseCategory: TagBrowseCategorySlug;
  setLastActiveBrowseCategory: (category: TagBrowseCategorySlug) => void;
  persistentNavOffset: number;
  setPersistentNavOffset: (offset: number) => void;
  setLastActiveTagName: (tagName: string) => void;
};

export const TagsContext = React.createContext<Context>({
  setView: (
    view: TagModalView,
    tagName?: string,
    browseCategory?: TagBrowseCategorySlug,
  ) => {},
  goBack: () => {},
  setShowErrorToast: (b: boolean) => null,
  showBackButton: true,
  goBackTo: undefined,
  lastScroll: 0,
  setLastScroll: (lastScroll: number) => {},
  lastActiveBrowseCategory: TagBrowseCategorySlug.Trending,
  setLastActiveBrowseCategory: (category: TagBrowseCategorySlug) => {},
  persistentNavOffset: 0,
  setPersistentNavOffset: (offset: number) => {},
  setLastActiveTagName: (tagName: string) => {},
});
