export const FEED_SOURCE = 'Activity Feed';
export const EMPTY_FEED_SOURCE = 'Empty Feed';
export const FEED_QUICK_ACTIONS_SOURCE = 'Feed Quick Actions';
export const FEED_CLASS_DETAILS_SOURCE = 'Feed Class Details';
export const FEED_NOTIFICATIONS_SOURCE = 'Feed Notifications';

export enum NotificationSource {
  Feed = 'Feed',
  Homescreen = 'Homescreen',
  Challenges = 'Challenges',
  Workouts = 'On-Demand Library',
  Schedule = 'Schedule',
}
