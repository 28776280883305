import type { FieldProps } from 'formik';
import React, { useState, useCallback } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import { gray3, superRed } from '@engage/colors';
import { spaces } from '@engage/styles';
import { header5Small, label7Large } from '@engage/typography';
import { StorageKeys, getFullStorageKey } from '@members/env';
import { OptimizelyEnv } from '../../toggle-gate/ToggleGate';
import { Environment } from '../datafiles';

const boxShadowStyle =
  '0 4px 9px 2px rgba(0, 0, 0, 0.08), 0 3.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0.5px rgba(0, 0, 0, 0.11), 0 0.5px 1px 0 rgba(0, 0, 0, 0.07)';

export const OptimizelyEnvironmentToggle: React.FC<
  React.PropsWithChildren<FieldProps<Record<string, string | number>>>
> = ({ form, field: { name } }) => {
  const [enabled, setEnabled] = useState(OptimizelyEnv === Environment.Production);

  const onChange = useCallback(() => {
    const newEnabled = !enabled;
    const newEnv = newEnabled ? Environment.Production : Environment.QA;

    setEnabled(newEnabled);
    form.setFieldValue(name, newEnv);
  }, [enabled]);

  return (
    <Item key={name}>
      <Text htmlFor={name}>
        <b>Optimizely Environment ({OptimizelyEnv})</b>
        <br />
        <Subtext>
          (param: {StorageKeys.OptimizelyEnv}; localStorage:{' '}
          {getFullStorageKey(StorageKeys.OptimizelyEnv)})
        </Subtext>
      </Text>
      <div>
        <Switch
          id={name}
          aria-checked={enabled}
          checked={enabled}
          role="switch"
          onChange={onChange}
          offColor={gray3}
          onColor={superRed}
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}
          width={40}
          boxShadow={boxShadowStyle}
        />
      </div>
    </Item>
  );
};

const Item = styled.li`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 ${spaces.small}px;
`;

const Text = styled.label`
  ${header5Small}
  pointer-events: none;
`;

const Subtext = styled.span`
  ${label7Large}
  pointer-events: auto;
`;
