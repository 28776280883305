import { titleize, capitalize } from '@peloton/text';
import type { LibrarySelectorState } from '@engage/library/selectors';
import { getTotalClassesCount } from '@engage/library/selectors';
import type {
  FilterGroup,
  CollectionFilterGroup,
  FilterValue,
  Selectable,
  AppliedFilter,
  Sort,
  SortValue,
  InstructorFilterGroup,
  ToggleFilterGroup,
} from './models';
import { FilterName, FilterType } from './models';
import type { FiltersReducerState } from './redux';

// Utils

const zipAppliedAndAvailable = <T extends FilterValue>(
  available: T[],
  applied?: AppliedFilter,
): Selectable<T>[] =>
  available.map(filter => ({
    ...filter,
    selected: Boolean(applied?.value?.length && applied.value?.includes(filter?.value)),
  }));

export type SelectorState = {
  filters: FiltersReducerState;
};

export const getToggleFilterGroups = (state: SelectorState): ToggleFilterGroup[] =>
  getAllAvailableFilters(state).filter(isToggleFilter);

export const getCollectionFilterGroups = (
  state: SelectorState,
): CollectionFilterGroup[] => getAllAvailableFilters(state).filter(isCollectionFilter);
export const getAvalilableSorts = (state: SelectorState): Sort[] =>
  state.filters.available.sorts;
export const getCurrentSort = (state: SelectorState): SortValue => state.filters.sort;
// TODO: Possibly move to redux.ts to be closer to the reducer shape itself
export const getAppliedFilters = (state: SelectorState): AppliedFilter[] =>
  state.filters.applied;

export const getAppliedFilterCount = (state: SelectorState) =>
  state.filters.applied.reduce((acc, v) => acc + v.value.length, 0);

export const getToggleFilters = (state: SelectorState): SelectableToggleFilterGroup[] =>
  toToggleFilters(getToggleFilterGroups(state), getAppliedFilters(state));

export const getCollectionFilters = (
  state: SelectorState,
): SelectableCollectionFilterGroup[] =>
  toCollectionFilters(getCollectionFilterGroups(state), getAppliedFilters(state));

export const getAllAvailableFilters = (state: SelectorState): FilterGroup[] => {
  return state.filters.available.filters;
};
const isToggleFilter = (filter: FilterGroup): filter is ToggleFilterGroup =>
  filter.type === FilterType.Toggle;
const isCollectionFilter = (filter: FilterGroup): filter is CollectionFilterGroup =>
  filter.type === FilterType.Collection;

export const toSortValues = (sorts: Sort[], { sort, desc }: SortValue) =>
  sorts.map(s => ({
    ...s,
    selected: s.value.sort === sort && s.value.desc === desc,
  }));

export const getSortGroup = (state: SelectorState): Selectable<Sort>[] =>
  toSortValues(getAvalilableSorts(state), getCurrentSort(state));

// Shared
export const toToggleFilters = (
  toggleFilterGroups: ToggleFilterGroup[],
  appliedFilters: AppliedFilter[],
) =>
  toggleFilterGroups.map(filterGroup => ({
    ...filterGroup,
    values: zipAppliedAndAvailable(
      filterGroup.values,
      appliedFilters.find(f => f.name === filterGroup.name),
    ),
    displayImageUrl: getDisplayImageUrl(filterGroup),
  }));

export const toCollectionFilters = (
  collectionFilterGroups: CollectionFilterGroup[],
  appliedFilters: AppliedFilter[],
) =>
  collectionFilterGroups.map(filterGroup => ({
    ...filterGroup,
    values: zipAppliedAndAvailable(
      filterGroup.values,
      appliedFilters.find(f => f.name === filterGroup.name),
    ),
    columns: toColumns(filterGroup),
  }));

export const toFilterAnalyticsProps = (
  availableFilters: FilterGroup[],
  appliedFilters: AppliedFilter[],
  availableSorts: Sort[],
  currentSort: SortValue,
  isClosedFilterEvent = false,
): FilterAnalyticsProps =>
  availableFilters.reduce(
    (props: FilterAnalyticsProps, filterGroup: FilterGroup) => ({
      ...props,
      ...toAnalyticsKeysAndValues(filterGroup, appliedFilters, isClosedFilterEvent),
    }),
    { sort: getCurrentSortName(availableSorts, currentSort, isClosedFilterEvent) },
  );

/**
 * Because some IDs will be localized, we should provide an ID (rather than a display name),
 * however, we can't do this exclusively, at least not *yet*
 */
const toAnalyticsKeysAndValues = (
  filterGroup: FilterGroup,
  appliedFilters: AppliedFilter[],
  isClosedFilterEvent = false,
): Record<string, string | string[]> => {
  const name = toFilterAnalyticsName(filterGroup, isClosedFilterEvent);
  const analyticsPropsName = `[${name}]`;
  const props = {
    [analyticsPropsName]: getFilterAnalyticsValue(filterGroup, appliedFilters),
  };

  if (needsIdProp(filterGroup) && !isClosedFilterEvent) {
    props[getIdPropName(filterGroup)] = getFilterAnalyticsValue(
      filterGroup,
      appliedFilters,
      true,
    );
  }

  if (!isClosedFilterEvent) {
    props[`[${filterGroup.name}]`] = getFilterAnalyticsValue(
      filterGroup,
      appliedFilters,
      true,
    );
  }

  return props;
};

const needsIdProp = (filter: FilterGroup): boolean => {
  return filter.name === FilterName.ClassTypeId;
};

const getIdPropName = (filter: FilterGroup): string => {
  return `[${filter.displayName} ID]`;
};

const toColumns = (filterGroup: FilterGroup) => {
  switch (filterGroup.name) {
    case FilterName.Duration:
    case FilterName.InstructorId:
      return 3;
    case FilterName.ClassTypeId:
    case FilterName.MusicGenre:
    case FilterName.BodyActivity:
      return 2;
    default:
      return 1;
  }
};

export const getFilterClosedAnalyticsProps = (
  state: SelectorState & LibrarySelectorState,
): FilterAnalyticsProps => ({
  ...toFilterAnalyticsProps(
    getAllAvailableFilters(state),
    getAppliedFilters(state),
    getAvalilableSorts(state),
    getCurrentSort(state),
    true,
  ),
});

const formatAnalyticsList = (list: string[]) => {
  return list.toString().replace(/,/g, ', ');
};

const getFilterCategoriesApplied = (state: SelectorState & LibrarySelectorState) => {
  const appliedFilters = getAppliedFilters(state);
  const availableFilters = getAllAvailableFilters(state);
  const list = availableFilters.reduce((total, x) => {
    const applied = appliedFilters?.find(
      (filter: AppliedFilter) => filter.name === x.name,
    );
    if (applied) {
      total.push(x.displayName);
    }
    return total;
  }, [] as string[]);
  return formatAnalyticsList(list);
};

const getAppliedListOfFilters = (state: SelectorState & LibrarySelectorState) => {
  const appliedFilters = getAppliedFilters(state);
  const availableFilters = getAllAvailableFilters(state);
  const list = availableFilters.reduce((total, x) => {
    const applied = appliedFilters?.find(
      (filter: AppliedFilter) => filter.name === x.name,
    );
    if (applied) {
      if (applied.name === 'is_favorite_ride' || applied.name === 'app-free') {
        return total.concat(x.displayName);
      } else if (applied.name === 'class_languages') {
        return total.concat(
          `${getFilterAnalyticsValue(x, appliedFilters)} ${x.displayName}`,
        );
      }
      return total.concat(getFilterAnalyticsValue(x, appliedFilters));
    }
    return total;
  }, [] as string[]);
  return formatAnalyticsList(list);
};

export const getFilterAnalyticsProps = (
  state: SelectorState & LibrarySelectorState,
): FilterAnalyticsProps => ({
  '[Class Results Count]': `${getTotalClassesCount(state)}`,
  '[Filters Applied]': `${getAppliedListOfFilters(state)}`,
  '[Number Of Filters Applied]': `${getAppliedFilterCount(state)}`,
  '[Filter Categories Applied]': `${getFilterCategoriesApplied(state)}`,
  '[Number Of Filter Categories Applied]': `${getAppliedFilters(state).length}`,
  ...toFilterAnalyticsProps(
    getAllAvailableFilters(state),
    getAppliedFilters(state),
    getAvalilableSorts(state),
    getCurrentSort(state),
  ),
});
const getFilterAnalyticsValue = (
  group: FilterGroup,
  appliedFilters: AppliedFilter[],
  useValue: boolean = false,
): string[] | string => {
  const applied = appliedFilters.find(
    (filter: AppliedFilter) => filter.name === group.name,
  );
  if (group.type === FilterType.Toggle) {
    if (group.name.toString() === 'is_favorite_ride') {
      return applied ? capitalize(applied.value[0]) : 'None';
    } else if (group.name.toString() === 'has_workout') {
      if (applied && applied.displayName) {
        return applied.value[0] === 'false' ? 'Not Taken by Me' : applied.displayName;
      }
    } else return applied ? applied.value[0] : 'None';
  } else if (!!applied && group.type === FilterType.Collection) {
    const values = group.values.filter((value: FilterValue) =>
      applied.value?.includes(value?.value),
    )!;
    if (!values) {
      // this can happen when a member alters the url manually
      return 'None';
    }

    return useValue ? values.map(x => x.value) : values.map(x => x.displayName);
  }
  return 'None';
};

const toFilterAnalyticsName = (
  filter: FilterGroup,
  isClosedFilterEvent = false,
): string => {
  switch (filter.displayName) {
    case 'Length':
      return `Class Length${isClosedFilterEvent ? '' : ' Filter'}`;
    case 'Subtitles':
      return `Subtitles Language${isClosedFilterEvent ? '' : ' Filter'}`;
    case 'Taken by Me':
      return `Classes Taken Filter`;
    default: {
      const name: string = filter?.displayName ?? '';
      return isClosedFilterEvent ? titleize(name) : `${name} Filter`;
    }
  }
};

const getCurrentSortName = (
  availableSorts: Sort[],
  currentSort: SortValue,
  isClosedFilterEvent = false,
): string => {
  const currentSortWithName = availableSorts.find(
    (sort: Sort) =>
      sort.value.sort === currentSort.sort && sort.value.desc === currentSort.desc,
  );
  return currentSortWithName
    ? isClosedFilterEvent
      ? currentSortWithName.displayName
      : currentSortWithName.slug
    : '';
};

export const isLoadingFilters = (state: SelectorState) =>
  state.filters.available.isLoading;
export const isFiltersLoadingError = (state: SelectorState) =>
  !!state.filters.available.error;

export const isInstructorFilterGroup = (
  group: FilterGroup,
): group is InstructorFilterGroup => group.name === FilterName.InstructorId;

export const getDisplayImageUrl = (group: ToggleFilterGroup) =>
  group.values[0].displayImageUrl;

export type FilterAnalyticsProps = Record<string, string | string[]>;

export type SelectableToggleFilterGroup = {
  type: FilterType.Toggle;
  name: FilterName;
  displayName: string;
  values: Selectable<FilterValue>[];
  displayImageUrl: string;
  columns?: number;
};

export type SelectableCollectionFilterGroup = {
  type: FilterType.Collection;
  name: FilterName;
  displayName: string;
  values: Selectable<FilterValue>[];
  // TODO: This seems like a view concern
  columns: number;
};

export type SelectableFilterGroup =
  | SelectableToggleFilterGroup
  | SelectableCollectionFilterGroup;
