/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultSettings } from '../../../api-v2-models/generated/DefaultSettings';
import type { DigitalSubscription } from '../../../api-v2-models/generated/DigitalSubscription';
import type { IOSSubscription } from '../../../api-v2-models/generated/IOSSubscription';
import type { PaginationResponse } from '../../../api-v2-models/generated/PaginationResponse';
import type { SerialNumbers } from '../../../api-v2-models/generated/SerialNumbers';
import type { Subscription } from '../../../api-v2-models/generated/Subscription';
import type { SubscriptionActivationCode } from '../../../api-v2-models/generated/SubscriptionActivationCode';
import type { UserPrivateBasicMapping } from '../../../api-v2-models/generated/UserPrivateBasicMapping';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Attaches a device to a user and subscription and activates the subscription for the user
 * Attaches and activates a subscription to a user and a device
 * @param userId user identifier to attach to subscription / device
 * @param subscriptionId subscription to activate / attach to user / device
 * @param deviceId
 * @param firmwareVersion
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param deviceName
 * @returns Subscription Activated subscription with user and attached to device
 * @throws ApiError
 */
export const attachDevice = (
    api: BaseAPI,
    userId: string,
    subscriptionId: string,
    deviceId: string,
    firmwareVersion: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    deviceName?: string,
    options: any = {},
): AxiosPromise<Subscription> => {
    const localVarPath = `/api/user/${userId}/subscription/${subscriptionId}/attach_device`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof deviceId !== 'undefined') {
        localVarQueryParameter['device_id'] = deviceId;
    }
    if(typeof firmwareVersion !== 'undefined') {
        localVarQueryParameter['firmware_version'] = firmwareVersion;
    }
    if(typeof deviceName !== 'undefined') {
        localVarQueryParameter['device_name'] = deviceName;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get subscription information by activation key
 * Find the subscription activation info associated with an activation key.
 * "activated_on" is set to 1 if the device has been activated,
 * null otherwise.
 * "id" is set to the value of formal_activation_code.
 * If joining on user, the only user field returned is username.
 * @param activationCode activation key
 * @returns SubscriptionActivationCode List of subscription properties
 * @throws ApiError
 */
export const getSubscriptionByActivationCode = (
    api: BaseAPI,
    activationCode: string,
    options: any = {},
): AxiosPromise<SubscriptionActivationCode> => {
    const localVarPath = `/api/subscription`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof activationCode !== 'undefined') {
        localVarQueryParameter['activation_code'] = activationCode;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Detaches a subscription from the device.
 * Detached a subscription from the device.
 * @param userId user identifier
 * @param subscriptionId subscription to detach
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @returns Subscription Detached the subscription from the device
 * @throws ApiError
 */
export const detachDevice = (
    api: BaseAPI,
    userId: string,
    subscriptionId: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    options: any = {},
): AxiosPromise<Subscription> => {
    const localVarPath = `/api/user/${userId}/subscription/${subscriptionId}/detach_device`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Attaches a device to a user and subscription and activates the subscription for the user via an activation key
 * Attaches and activates a subscription to a user and a device
 * @param userId user identifier to attach to subscription / device
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param requestBody
 * @returns Subscription Activated subscription with user and attached to device
 * @throws ApiError
 */
export const attachDeviceWithActivationKey = (
    api: BaseAPI,
    userId: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    requestBody?: {
        activationKey: string,
        /**
         * device identifier (uuid)
         */
        deviceId: string,
        /**
         * device serial
         */
        deviceSerial?: string,
        firmwareVersion?: string,
        deviceName?: string,
    },
    options: any = {},
): AxiosPromise<Subscription> => {
    const localVarPath = `/api/user/${userId}/attach_device_with_activation_key`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Subscription information
 * Allows the logged in user to gets subscription information of given account (must be users account)
 * @param subId subscription identifier
 * @returns Subscription List of subscription properties
 * @throws ApiError
 */
export const getSubscription = (
    api: BaseAPI,
    subId: string,
    options: any = {},
): AxiosPromise<Subscription> => {
    const localVarPath = `/api/subscription/${subId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get default settings for units, date format and time format based on subscription locale
 * Gets default settings for weight, height and distance unit (metric vs imperial unit) based on the created_country locale of the specified subscription. Also get language-locale for formatting date and time on client.
 * @param subId subscription identifier
 * @returns DefaultSettings List of default settings
 * @throws ApiError
 */
export const getSubscriptionDefaultSettings = (
    api: BaseAPI,
    subId: string,
    options: any = {},
): AxiosPromise<DefaultSettings> => {
    const localVarPath = `/api/subscription/${subId}/default_settings`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get Subscription Transactions of a Subscription
 * Get a list of subscription transactions of a subscription
 * @param userId user identifier
 * @param subscriptionId subscription identifier
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserSubscriptionTransactions = (
    api: BaseAPI,
    userId: string,
    subscriptionId: string,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${userId}/subscription/${subscriptionId}/subscription_transactions`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get All Types of Subscriptions that the Logged-In User owns and is a part of
 * Get all types of subscriptions that the logged-in user owns and is a part of. IOS Subscriptions with is_migrated_to_digital set to True will not be returned
 * @returns any Successful response
 * @throws ApiError
 */
export const getLoggedInUserAllSubscriptionsByTypeV2 = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * a list of user's subscriptions
     */
    subscriptions?: Array<Subscription>,
    /**
     * a list of user's shared subscriptions
     */
    sharedSubscriptions?: Array<Subscription>,
    /**
     * a list of user's ios subscriptions
     */
    iosSubscriptions?: Array<IOSSubscription>,
    /**
     * a list of user's shared ios subscriptions
     */
    sharedIosSubscriptions?: Array<IOSSubscription>,
    /**
     * a list of user's digital subscriptions
     */
    digitalSubscriptions?: Array<DigitalSubscription>,
    /**
     * a list of user's shared digital subscriptions
     */
    sharedDigitalSubscriptions?: Array<DigitalSubscription>,
}> => {
    const localVarPath = `/api/v2/user/subscriptions`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Add a shared user to the subscription
 * Add a shared user to the subscription.
 * @param subscriptionId subscription identifier.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const addSharedUser = (
    api: BaseAPI,
    subscriptionId: string,
    requestBody: {
        /**
         * username or email to add
         */
        usernameOrEmail?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * subscription identifier
     */
    subscriptionId?: string,
    addedUser?: UserPrivateBasicMapping,
    addedUsers?: Array<UserPrivateBasicMapping>,
}> => {
    const localVarPath = `/api/subscription/${subscriptionId}/shared_users`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Unpause a paused subscription
 * Cancel the pause for a subscription that has either been paused or
 * is scheduled to be paused
 * @param subscriptionId subscription identifier.
 * @returns any Subscription was successfully unpaused
 * @throws ApiError
 */
export const unpauseDeviceSubscription = (
    api: BaseAPI,
    subscriptionId: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/subscription/${subscriptionId}/unpause`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Get All Types of Subscriptions that User owns and is a part of
 * Get all types of subscriptions that the user owns and is a part of. IOS Subscriptions with is_migrated_to_digital set to True will not be returned
 * @param id user identifier
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserAllSubscriptionsByTypeV2 = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<{
    /**
     * a list of user's subscriptions
     */
    subscriptions?: Array<Subscription>,
    /**
     * a list of user's shared subscriptions
     */
    sharedSubscriptions?: Array<Subscription>,
    /**
     * a list of user's ios subscriptions
     */
    iosSubscriptions?: Array<IOSSubscription>,
    /**
     * a list of user's shared ios subscriptions
     */
    sharedIosSubscriptions?: Array<IOSSubscription>,
    /**
     * a list of user's digital subscriptions
     */
    digitalSubscriptions?: Array<DigitalSubscription>,
    /**
     * a list of user's shared digital subscriptions
     */
    sharedDigitalSubscriptions?: Array<DigitalSubscription>,
}> => {
    const localVarPath = `/api/v2/user/${id}/all_subscriptions`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Pause a subscription
 * Pause a subscription that is active, not commercial,
 * is not scheduled for pause and not in grace period
 * @param subscriptionId subscription identifier.
 * @param requestBody request body, contains month_count
 * @returns any Subscription was successfully paused
 * @throws ApiError
 */
export const pauseDeviceSubscription = (
    api: BaseAPI,
    subscriptionId: string,
    requestBody: {
        /**
         * Number of months for pause (only 1, 2 or 3 are valid).
         */
        monthCount: number,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/subscription/${subscriptionId}/pause`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Get Subscriptions of User
 * Get a list of subscriptions of the logged-in user. This endpoint is going to be deprecated; this information should be retrieved from /user/subscriptions.
 * @param id user identifier
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserSubscriptions = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${id}/subscriptions`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Remove a shared user from the subscription
 * Remove a shared user from the subscription.
 * @param subscriptionId subscription identifier
 * @param userId user identifier
 * @returns any Successful response
 * @throws ApiError
 */
export const removeSharedUser = (
    api: BaseAPI,
    subscriptionId: string,
    userId: string,
    options: any = {},
): AxiosPromise<{
    /**
     * subscription identifier
     */
    subscriptionId?: string,
    /**
     * identifier of user who was removed
     */
    removedUserId?: string,
}> => {
    const localVarPath = `/api/subscription/${subscriptionId}/shared_users/${userId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Add or Update Serial Numbers
 * Add or update serial data (frame serial, console serial).
 * Support non-aurora platforms so far.
 * @param subscriptionId subscription identifier.
 * @param requestBody request body, contains serial data (frame serial, console serial). If the tablet is not connected to the bike, the frame serial will be empty.
 * @returns any Update was successful
 * @throws ApiError
 */
export const addSerials = (
    api: BaseAPI,
    subscriptionId: string,
    requestBody: SerialNumbers,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/subscription/${subscriptionId}/serial`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

