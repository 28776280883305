import React from 'react';
import styled from 'styled-components';
import { Tooltip as PeloTooltip } from '@peloton/tooltip';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  backgroundColor: string;
  tooltipId: string;
  setIsExpanded(value: boolean): void;
  event?: keyof HTMLElementEventMap;
  eventOff?: keyof HTMLElementEventMap;
  place?: 'bottom' | 'top' | 'right' | 'left' | undefined;
};

export const Tooltip: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  tooltipId,
  setIsExpanded,
  children,
  backgroundColor,
  event = 'click',
  eventOff,
  place = 'bottom',
  ...props
}) => (
  <Wrapper id={tooltipId} backgroundColor={backgroundColor} {...props}>
    <PeloTooltip
      tooltipId={tooltipId}
      setIsExpanded={setIsExpanded}
      children={children}
      event={event}
      eventOff={eventOff}
      globalEventOff={isMobileApple() ? 'touchstart' : 'mouseup'}
      place={place}
    />
  </Wrapper>
);

// globalEventOff is 'mouseup' rather than 'click' to accommodate a known react-tooltip bug,
// to ensure that multiple tooltips are not open at the same time.
// IOS (but not Android) touch screens respond to 'touchstart' but not 'mouseup' here.
const isMobileApple = () => /iPad|iPhone|iPod/.test(navigator.platform);

const Wrapper = styled.div<{ backgroundColor: string }>`
  .tooltip {
    background-color: ${p => p.backgroundColor} !important;
    pointer-events: auto;
    white-space: normal;
  }

  // tooltip positioning
  .place-bottom {
    margin-top: 8px !important;
  }
  .place-top {
    margin-top: -8px !important;
  }
  .place-right {
    margin-left: 8px !important;
  }
  .place-left {
    margin-left: -8px !important;
  }

  // colors for tooltip arrows
  .place-bottom,
  .place-top {
    &:after {
      border-bottom-color: ${p => p.backgroundColor} !important;
      border-top-color: ${p => p.backgroundColor} !important;
    }
  }
  .place-left,
  .place-right {
    &:after {
      border-left-color: ${p => p.backgroundColor} !important;
      border-right-color: ${p => p.backgroundColor} !important;
    }
  }
`;
