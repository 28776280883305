import type { FormikErrors, FormikTouched, FormikActions } from 'formik';
import { Formik, Field, Form } from 'formik';
import { transparentize } from 'polished';
import React, { useState, useEffect, useContext } from 'react';
import type { FC } from 'react';
import styled, { css } from 'styled-components';
import useSWR from 'swr';
import * as Yup from 'yup';
import { getUserId } from '@peloton/auth';
import type { StyledCss } from '@peloton/forms';
import { toLocaleFromHostname } from '@peloton/internationalize/models/locale';
import {
  isAfter,
  toNowTime,
  isValidDateFormatValue,
  dateObjectWithDateFormat,
} from '@peloton/time';
import { track } from '@engage/analytics';
import { putUser } from '@engage/api-v2';
import { black, gray2 } from '@engage/colors';
import { DateTime } from '@engage/internationalize';
import type { LocaleGetter } from '@engage/internationalize/dateTime';
import { useReduxState, useReduxAction } from '@engage/redux';
import { interV } from '@engage/typography';
import { useBasicInfo, BasicInfo } from '@members/copy';
import { engageApi, UsersFetchers } from '@members/data-fetch';
import { DarkClearButton, LightClearButton } from '@members/form/clearButton';
import { DarkHelptext, LightHelptext } from '@members/form/helptext';
import { DarkInput, LightInput } from '@members/form/input';
import InputWithLabelView from '@members/form/InputWithLabelView';
import type { Props as InputWithLabelViewProps } from '@members/form/InputWithLabelView';
import { DarkLabel, LightLabel } from '@members/form/label';
import { DarkToggleButton, LightToggleButton } from '@members/form/togglePasswordButton';
import { BirthdateSubmitToast } from '@members/pg-profile-setup/BirthdateSubmitToast';
import { BirthdateErrorsContext } from './BirthdateErrorsContext';

type PropsWithDefaults = 'Input' | 'Label' | 'ClearButton' | 'TogglePasswordButton';
export type BirthdayInputProps = Omit<InputWithLabelViewProps, PropsWithDefaults> &
  Partial<Pick<InputWithLabelViewProps, PropsWithDefaults>> &
  InputContainerProps;
interface GenericBirthdayInputProps {
  dark?: boolean;
  submitButton: JSX.Element;
  handleNextStep: () => void;
  isNewUser: boolean;
  formStyles?: StyledCss<{}>;
  Input?: React.ComponentType<React.PropsWithChildren<BirthdayInputProps>>;
}

interface InputContainerProps {
  isDark: boolean;
  localeBirthdayInputString: string;
}
interface CustomFormProps {
  birthdateLabel: string;
  submitButton: JSX.Element;
  showErrorToast: boolean;
  setShowErrorToast: (value: boolean) => void;
  errors: FormikErrors<{
    birthdate: string;
  }>;
  touched: FormikTouched<{
    birthdate: string;
  }>;
  values: FormValuesProps;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  localeBirthdayInputString: string;
  isDark: boolean;
  setFieldValue: FormikActions<FormValuesProps>['setFieldValue'];
  isNewUser: boolean;
  futureErrorCopy: string;
  invalidDateFormatError: string;
  formStyles?: StyledCss<{}>;
  Input?: React.ComponentType<React.PropsWithChildren<BirthdayInputProps>>;
}
interface FormValuesProps {
  birthdate: string;
}
interface FieldOuterContainerProps {
  errors: FormikErrors<FormValuesProps>;
  touched: FormikTouched<FormValuesProps>;
}

interface ErrorAnaylticsProps {
  birthdayError: string;
}

const getBirthdayInputStringOrder = (localeGetter: LocaleGetter): string => {
  return DateTime.isMonthDayYearFormat(localeGetter) ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
};

const toMomentDateFormat = (dateFormat: string) => {
  return dateFormat === 'MM/DD/YYYY' ? 'M/D/YYYY' : 'D/M/YYYY';
};

export const GenericBirthdayInput: FC<
  React.PropsWithChildren<GenericBirthdayInputProps>
> = ({ dark, submitButton, handleNextStep, isNewUser, formStyles, Input }) => {
  const localeFromHostname = toLocaleFromHostname();
  const localeBirthdayInputString = getBirthdayInputStringOrder(() => localeFromHostname);

  const isDark = dark ?? false;
  const futureErrorCopy = useBasicInfo(BasicInfo.FutureBirthdateError);
  const invalidDateFormatError = useBasicInfo(BasicInfo.InvalidDateFormatError, {
    dateFormat: localeBirthdayInputString,
  });
  const birthdateLabel = useBasicInfo(BasicInfo.Birthdate);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const userId = useReduxState(getUserId) ?? '';
  const { mutate } = useSWR(
    ...UsersFetchers.GetIsUserOldEnough({ shouldSkipFetch: !userId }),
  );
  const trackAnalytics = useReduxAction(track);
  const { errorCount } = useContext(BirthdateErrorsContext);

  useEffect(() => {
    trackAnalytics({
      event: 'Viewed Age Confirmation',
      '[Is New User]': isNewUser,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackAnalytics]);

  Yup.addMethod(Yup.string, 'isValidDateString', function (errorMessage) {
    return this.test(`test-valid-date-string`, errorMessage, function (value) {
      const convertedDateFormat = toMomentDateFormat(localeBirthdayInputString);
      const isValidDateString = isValidDateFormatValue(value, convertedDateFormat);

      if (isValidDateString) {
        return true;
      }
      setShowErrorToast(true);
      return false;
    });
  });

  Yup.addMethod(Yup.string, 'isNotInFuture', function (errorMessage) {
    return this.test(`test-date-not-in-future`, errorMessage, function (value) {
      const convertedDateFormat = toMomentDateFormat(localeBirthdayInputString);
      const birthdateDateObj = dateObjectWithDateFormat(value, convertedDateFormat);
      const isDateValid = isAfter(toNowTime(), birthdateDateObj);

      if (isDateValid) {
        return true;
      }
      setShowErrorToast(true);
      return false;
    });
  });

  Yup.addMethod(Yup.string, 'isAllowedAge', function () {
    return this.test(`test-allowed-age`, '', async function (value) {
      const convertedDateFormat = toMomentDateFormat(localeBirthdayInputString);
      const birthdateDateObj = dateObjectWithDateFormat(value, convertedDateFormat);

      try {
        await putUser(engageApi, userId, 'web', {
          birthday: birthdateDateObj.unix(),
        });
        mutate();
      } catch (err) {
        setShowErrorToast(true);
        return this.createError({ message: err.response?.data?.message ?? '' });
      }
      return true;
    });
  });

  const BirthdaySchema = Yup.object().shape({
    birthdate: (Yup.string() as any)
      .required(invalidDateFormatError)
      // this regex will match mm/mm/yyyy, day position will depend on locale and handled by isValidDate
      .matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, invalidDateFormatError)
      .isValidDateString(invalidDateFormatError)
      .isNotInFuture(futureErrorCopy)
      .isAllowedAge(),
  });

  return (
    <>
      <Formik
        initialValues={{
          birthdate: '',
        }}
        onSubmit={(values, actions) => {
          handleNextStep();
          trackAnalytics({
            event: 'Submitted Age Confirmation',
            '[Number of Errors]': errorCount,
            '[Is New User]': isNewUser,
          });
          trackAnalytics({
            event: 'Updated User Age',
            '[Number of Errors]': errorCount,
            '[Is New User]': isNewUser,
          });
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={BirthdaySchema}
      >
        {({ handleSubmit, touched, errors, values, setFieldValue }) => (
          <>
            <CustomForm
              handleSubmit={handleSubmit}
              touched={touched}
              errors={errors}
              values={values}
              birthdateLabel={birthdateLabel}
              submitButton={submitButton}
              showErrorToast={showErrorToast}
              setShowErrorToast={setShowErrorToast}
              localeBirthdayInputString={localeBirthdayInputString}
              isDark={isDark}
              setFieldValue={setFieldValue}
              isNewUser={isNewUser}
              futureErrorCopy={futureErrorCopy}
              invalidDateFormatError={invalidDateFormatError}
              formStyles={formStyles}
              Input={Input}
            />
          </>
        )}
      </Formik>
    </>
  );
};

const InputContainer = (props: BirthdayInputProps) => {
  return (
    <>
      {props.isDark ? (
        <>
          <InputWithLabelView
            Input={BirthdayDarkInput}
            Label={DarkLabel}
            ClearButton={
              DarkClearButton as React.ComponentType<
                React.PropsWithChildren<React.ComponentProps<'button'>>
              >
            }
            TogglePasswordButton={DarkToggleButton}
            hideFieldErrors={true}
            {...props}
            Helptext={DarkHelptext}
          />
          <DarkDateLabel>{props.localeBirthdayInputString}</DarkDateLabel>
        </>
      ) : (
        <>
          <InputWithLabelView
            Input={BirthdayLightInput}
            Label={LightLabel}
            ClearButton={
              LightClearButton as React.ComponentType<
                React.PropsWithChildren<React.ComponentProps<'button'>>
              >
            }
            TogglePasswordButton={LightToggleButton}
            hideFieldErrors={true}
            {...props}
            Helptext={LightHelptext}
          />
          <LightDateLabel>{props.localeBirthdayInputString}</LightDateLabel>
        </>
      )}
    </>
  );
};

const CustomForm: React.FC<React.PropsWithChildren<CustomFormProps>> = ({
  errors,
  touched,
  handleSubmit,
  birthdateLabel,
  submitButton,
  showErrorToast,
  setShowErrorToast,
  localeBirthdayInputString,
  isDark,
  values,
  setFieldValue,
  isNewUser,
  futureErrorCopy,
  invalidDateFormatError,
  formStyles = defaultFormStyles,
  Input = InputContainer,
}) => {
  const trackAnalytics = useReduxAction(track);
  const { setErrorList, errorCount, setErrorCount } = useContext(BirthdateErrorsContext);

  React.useEffect(() => {
    if (values.birthdate.length > 0) {
      // only allow numeric and / to be entered as birthdate value
      setFieldValue('birthdate', values.birthdate.match(/\d+|\//g)?.join('') ?? '');
    }
  }, [values.birthdate, setFieldValue]);

  const errorAnalytics = ({ birthdayError }: ErrorAnaylticsProps) => {
    const errorsObj = {
      [invalidDateFormatError]: 'Generic Error',
      [futureErrorCopy]: 'Future Date',
    };

    if (birthdayError) {
      if (birthdayError in errorsObj) {
        trackAnalytics({
          event: 'Viewed Age Confirmation Error',
          Error: errorsObj[birthdayError],
          '[Is New User]': isNewUser,
        });
        setErrorList((prev: string[]) => [...prev, errorsObj[birthdayError]]);
      } else {
        // Underage error
        trackAnalytics({
          event: 'Viewed Age Confirmation Error',
          Error: 'Not Eligible',
          '[Is New User]': isNewUser,
        });
        setErrorList((prev: string[]) => [...prev, 'Not Eligible']);
      }
    }
  };

  useEffect(() => {
    if (errors.birthdate) {
      errorAnalytics({ birthdayError: errors.birthdate });
      trackAnalytics({
        event: 'Submitted Age Confirmation',
        '[Number of Errors]': errorCount + 1, // In case submit goes through before error count is set.
        '[Is New User]': isNewUser,
      });
      setErrorCount((prev: number) => prev + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <>
      <FieldOuterContainer errors={errors} touched={touched}>
        {/* @ts-expect-error */}
        <StyledForm formStyles={formStyles} placeholder="">
          <Field
            isDark={isDark}
            localeBirthdayInputString={localeBirthdayInputString}
            component={Input}
            label={birthdateLabel}
            id="birthdate"
            name="birthdate"
            data-test-id="birthdate"
          />
        </StyledForm>
      </FieldOuterContainer>
      {React.cloneElement(submitButton, {
        disabled: values.birthdate.length === 0,
        onClick: () => {
          handleSubmit();
        },
      })}
      {showErrorToast && (
        <BirthdateSubmitToast
          message={errors.birthdate ?? ''}
          close={() => setShowErrorToast(false)}
          autoDismiss={false}
        />
      )}
    </>
  );
};

const FieldOuterContainer = styled.div<FieldOuterContainerProps>`
  ${({ errors, touched }) =>
    errors.birthdate &&
    touched.birthdate &&
    css`
      ${BirthdayLightInput} {
        border: 1px solid #ffe28c;
        border-bottom: none;
      }
      ${BirthdayDarkInput} {
        border: 1px solid #ffe28c;
        border-bottom: none;
      }
      ${DarkDateLabel} {
        border: 1px solid #ffe28c;
        border-top: none;
      }
      ${LightDateLabel} {
        border: 1px solid #ffe28c;
        border-top: none;
      }
    `};
  display: flex;
  justify-content: center;
  font-family: ${interV};
  width: 100%;
`;

const defaultFormStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
`;

const StyledForm = styled(Form)<{ formStyles: StyledCss<{}> }>`
  ${props => props.formStyles}
`;

const BirthdayLightInput = styled(LightInput)`
  height: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid transparent;
  box-sizing: border-box;
  &:focus {
    border: 1px solid transparent;
  }
`;

const BirthdayDarkInput = styled(DarkInput)`
  height: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-sizing: border-box;
  border: 1px solid transparent;
  background: ${transparentize(0.68, black)};

  &:focus {
    background: ${transparentize(0.68, black)};
  }
`;

const DarkDateLabel = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  background-color: #e4e7eb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  line-height: 12px;
  color: #8a8d91;
  font-size: 12px;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid transparent;
  background: ${transparentize(0.12, black)};
`;

const LightDateLabel = styled(DarkDateLabel)`
  background: ${gray2};
`;
