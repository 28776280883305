import { css } from 'styled-components';
import { spaces } from '@engage/styles';
import { capsStyle, fontWeights, letterSpacing } from '../shared';

// headers
export const h1 = css`
  font-size: ${spaces.xxxLarge}px;
  font-weight: ${fontWeights.normal};
  line-height: 38px;
  letter-spacing: ${letterSpacing.xxTight};
`;

export const h2 = css`
  font-size: ${spaces.xxLarge}px;
  font-weight: ${fontWeights.bold};
  line-height: 34px;
  letter-spacing: ${letterSpacing.xTight};
`;

export const h3 = css`
  font-size: ${spaces.xLarge}px;
  font-weight: ${fontWeights.bold};
  line-height: 30px;
  letter-spacing: ${letterSpacing.tight};
`;

export const h4 = css`
  font-size: ${spaces.large}px;
  font-weight: ${fontWeights.xBold};
  line-height: ${spaces.xLarge}px;
  letter-spacing: ${letterSpacing.normal};
`;

export const h5 = css`
  font-size: 18px;
  font-weight: ${fontWeights.xBold};
  line-height: 22px;
  letter-spacing: ${letterSpacing.normal};
`;

export const h6 = css`
  font-size: ${spaces.medium}px;
  font-weight: ${fontWeights.xxBold};
  line-height: ${spaces.large}px;
  letter-spacing: ${letterSpacing.wide};
`;

export const h7 = css`
  font-size: 14px;
  font-weight: ${fontWeights.xxBold};
  line-height: 18px;
  letter-spacing: ${letterSpacing.wide};
`;

export const h8 = css`
  font-size: ${spaces.medium}px;
  font-weight: ${fontWeights.xxxxBold};
  line-height: ${spaces.large}px;
  letter-spacing: ${letterSpacing.normal};
`;

// cap headers
export const h4c = css`
  ${capsStyle}
  ${h4}
  letter-spacing: ${letterSpacing.xxxxWide};
`;

export const h7c = css`
  ${capsStyle}
  ${h7}
  letter-spacing: ${letterSpacing.xxxWide};
`;

// bold headers
export const h1Bold = css`
  ${h1}
  font-weight: ${fontWeights.bold};
`;

export const h2Bold = css`
  ${h2}
  font-weight: ${fontWeights.xBold};
`;

export const h4Bold = css`
  ${h4}
  font-weight: ${fontWeights.xxBold};
`;

export const h5Bold = css`
  ${h5}
  font-weight: ${fontWeights.xxBold};
`;

export const h6Bold = css`
  ${h6}
  font-weight: ${fontWeights.xxxBold};
`;

export const h7Bold = css`
  ${h7}
  font-weight: ${fontWeights.xxxBold};
`;

// cap bold headers
export const h4CapBold = css`
  ${capsStyle}
  ${h4Bold}
  letter-spacing: ${letterSpacing.xxxxWide};
`;

export const h5CapBold = css`
  ${capsStyle}
  ${h5Bold}
  letter-spacing: ${letterSpacing.xxxxWide};
`;

export const h6CapBold = css`
  ${capsStyle}
  ${h6Bold}
  letter-spacing: ${letterSpacing.xxxWide};
`;

export const h7CapBold = css`
  ${capsStyle}
  ${h7Bold}
  letter-spacing: ${letterSpacing.xxxWide};
`;
