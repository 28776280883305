import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { isDefined } from '@peloton/types';
import { track } from '@engage/analytics';
import { gray4, gray5 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import type { MemberCard } from '@engage/members';
import { useReduxAction, useReduxState } from '@engage/redux';
import { header5Large, header5Small } from '@engage/typography';
import { MembersCopy, Members } from '@members/copy';
import { InviteFriendsContent, ContentType } from '@members/guest-pass/components';
import { getIsElligibleGuestPassUser } from '@members/guest-pass/redux';
import { clearSearchResults } from '@members/member-search/redux';
import { MemberList, MembersListModalContainer, ScrollContainer } from '@members/members';
import { getActiveModalSource } from '@members/modal';
import { trackOpenMemberSearch } from './analytics';
import { SearchIcon } from './icons';
import type { SearchControlProps } from './MemberSearchControls';
import { MemberSearchControls } from './MemberSearchControls';

export type MemberSearchProps = SearchControlProps & {
  searchResults?: MemberCard[];
};

export const MemberSearch: React.FC<React.PropsWithChildren<MemberSearchProps>> = ({
  searchResults,
  handleFbClick,
  ...props
}) => {
  const source = useReduxState(getActiveModalSource);
  const clearSearch = useReduxAction(clearSearchResults);
  const trackAction = useReduxAction(track);
  const isElligibleGuestPassUser = useReduxState(getIsElligibleGuestPassUser);

  React.useEffect(() => {
    if (!props.inputValue && isDefined(searchResults) && searchResults?.length > 0) {
      clearSearch();
    }
  }, [props.inputValue, clearSearch, searchResults?.length]);

  React.useEffect(() => {
    trackAction(trackOpenMemberSearch(source));
  }, []);

  return (
    <MembersListModalContainer data-test-id="findMembersModal">
      <MemberSearchControls {...props} handleFbClick={handleFbClick} />
      <If condition={!isDefined(searchResults)}>
        <FixedContainer>
          <SearchIconLarge data-test-id="searchIconLarge" />
        </FixedContainer>
      </If>
      <If condition={Number(searchResults?.length) > 0}>
        <ScrollContainer>
          <MemberList members={searchResults as MemberCard[]} />
        </ScrollContainer>
      </If>
      <If condition={isDefined(searchResults) && searchResults.length === 0}>
        {isElligibleGuestPassUser ? (
          <InviteFriendsContent
            contentType={ContentType.MembersSearch}
            onClose={props.handleClickClear}
          />
        ) : (
          <FixedContainer data-test-id="noMembersFound">
            <MembersCopy id={Members.NoMembersFound} />
          </FixedContainer>
        )}
      </If>
    </MembersListModalContainer>
  );
};

const mainContentArea = css`
  flex-grow: 1;
`;

const FixedContainer = styled.div`
  ${mainContentArea}
  ${header5Small}
  color: ${gray5};
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    ${header5Large}
    color: ${gray5};
    padding-bottom: 30px;
  `}
`;

const SearchIconLarge = styled(SearchIcon)`
  width: 80px;
  height: 80px;
  fill: ${gray4};

  ${media.desktop`
    width: 100px;
    height: 100px;
  `}
`;
