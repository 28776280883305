import React from 'react';
import { ClientProvider as PClientProvider } from '@peloton/api';
import { deAuthenticate, useOauthErrorHandler, errorHandler } from '@peloton/auth';
import { redirectToNotFound } from '@peloton/navigation';
import type { PropsOf } from '@peloton/react';
import { useReportError } from '@engage/error-reporting';
import { useReduxAction } from '@engage/redux';
import { useLogout } from '@members/auth';

export const ClientProvider: React.FC<
  React.PropsWithChildren<Omit<PropsOf<typeof PClientProvider>, 'oauthEnabled'>>
> = props => {
  const logout = useLogout();
  const deAuth = useReduxAction(deAuthenticate);
  const notFoundRedirect = useReduxAction(redirectToNotFound);
  const errorReporter = useReportError();
  const { client } = props;

  const oauthErrorHandler = useOauthErrorHandler(
    errorHandler(logout, notFoundRedirect, deAuth),
    client,
    true,
  );

  React.useEffect(() => {
    if (!client) {
      return;
    }

    client.interceptors.response.use(config => {
      if (config.headers['invalidate-token'] === 'True') {
        errorReporter(new Error('Token mismatch detected'));
        logout();
      }
      return config;
    }, oauthErrorHandler);
    // eslint-disable-next-line
  }, [client]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PClientProvider {...props} client={client} />;
};
