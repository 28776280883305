/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Create token for leaderboard services
 * Creates a JWT intended to be used for leaderboard services
 * @param requestBody
 * @returns any Succesful response
 * @throws ApiError
 */
export const createStatsToken = (
    api: BaseAPI,
    requestBody?: any,
    options: any = {},
): AxiosPromise<{
    /**
     * encoded JWT
     */
    accessToken?: string,
    /**
     * expiry time of token in seconds
     */
    expiresIn?: number,
    /**
     * Token type
     */
    tokenType?: string,
}> => {
    const localVarPath = `/stats/token`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

