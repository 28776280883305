// Allowed since this is the proxy
// eslint-disable-next-line no-restricted-imports
import {
  toAuthFetchers,
  toClassGroupsFetchers,
  toClassFetchers,
  toDigitalSubscriptionsFetchers,
  toPelotonFetchers,
  toPostclassLeaderboardFetchers,
  toPresenceFetchers,
  toStatsTokenFetchers,
  toStreamFetchers,
  toWorkoutFetchers,
  toUsersFetchers,
  toSearchFetchers,
  toBrowseCategoryFetchers,
  toSubscriptionV2Fetchers,
  toMetadataFetchers,
  toHomescreenFetchers,
  toRecommendationsFetchers,
  toTooltipsFetchers,
  toGuidanceFetchers,
  toPartnershipFetchers,
} from '@engage/data-fetch-v2/fetchers';
import { toModerationFetchers } from './fetchers/moderation';
import { toProgressTrackingFetchers } from './fetchers/progressTracking';
import { toUserPreferencesFetchers } from './fetchers/userPreferences';
import { toActivityFeedFetchersGQL } from './graphql-fetchers/activityFeedFetchers';
import { toProgramsFetchersGQL } from './graphql-fetchers/programsFetchers';
import { toScheduledClassFetchersGQL } from './graphql-fetchers/scheduledClassFetchers';
import { toStackedClassesFetchersGQL } from './graphql-fetchers/stackedClassesFetchers';
import { toTagsFetchersGQL } from './graphql-fetchers/tagsFetchers';
import {
  engageApi,
  engageApiNoAuthHeader,
  engageApiTextResponseType,
  presenceApi,
  pagesApi,
  graphQlApi,
  tooltipsApi,
  guidanceApi,
  userPreferencesApi,
  progressTrackingApi,
} from './singletons';

export const AuthFetchers = toAuthFetchers(engageApi);
export const AuthFetchersNoAuthHeader = toAuthFetchers(engageApiNoAuthHeader);
export const ClassFetchers = toClassFetchers(engageApi);
export const ClassGroupsFetchers = toClassGroupsFetchers(engageApi);
export const DigitalSubscriptionsFetchers = toDigitalSubscriptionsFetchers(engageApi);
export const PelotonFetchers = toPelotonFetchers(engageApi);
export const PostclassLeaderboardFetchers = toPostclassLeaderboardFetchers(engageApi);
export const PresenceFetchers = toPresenceFetchers(presenceApi);
export const StatsTokenFetchers = toStatsTokenFetchers(engageApi);
export const StreamFetchers = toStreamFetchers(engageApi);
export const WorkoutsFetchers = toWorkoutFetchers(engageApi, 'web');
export const WorkoutsFetchersTextResponseType = toWorkoutFetchers(
  engageApiTextResponseType,
  'web',
);
export const UsersFetchers = toUsersFetchers(engageApi);
export const SearchFetchers = toSearchFetchers(engageApi);
export const BrowseCategoryFetchers = toBrowseCategoryFetchers(engageApi);
export const SubscriptionsFetchers = toSubscriptionV2Fetchers(engageApi);
export const MetadataFetchers = toMetadataFetchers(engageApi);
export const HomescreenFetchers = toHomescreenFetchers(pagesApi);
export const RecommendationFetchers = toRecommendationsFetchers(engageApi);
export const TooltipsFetchers = toTooltipsFetchers(tooltipsApi);
export const GuidanceFetchers = toGuidanceFetchers(guidanceApi);
export const ModerationFetchers = toModerationFetchers(engageApi);
export const ProgressTrackingFetchers = toProgressTrackingFetchers(progressTrackingApi);
export const PartnershipFetchers = toPartnershipFetchers(engageApi);
export const UserPreferencesFetchers = toUserPreferencesFetchers(userPreferencesApi);

/* --- GraphQL Fethchers */
export const ActivityFeedFetchersGQL = toActivityFeedFetchersGQL(graphQlApi);
export const ProgramsFetchersGQL = toProgramsFetchersGQL(graphQlApi);
export const StackedClassesFetchersGQL = toStackedClassesFetchersGQL(graphQlApi);
export const TagsFetchersGQL = toTagsFetchersGQL(graphQlApi);
export const ScheduledClassFetchersGQL = toScheduledClassFetchersGQL(graphQlApi);
