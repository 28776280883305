import { curry } from 'ramda';
import { localizeString, unlocalizeString } from './localize';

// copied from https://github.com/tryggvigy/pseudo-localization/blob/master/src/localize.js

export const pseudoLocalization = (() => {
  const blacklistedNodeName = 'STYLE';

  // Observer for dom updates. Initialization is defered to make parts
  // of the API safe to use in non-browser environments like nodejs
  let observer: any = null;
  const observerConfig = {
    characterData: true,
    childList: true,
    subtree: true,
  };

  const textNodesUnder = (element: HTMLElement) => {
    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, {
      acceptNode: node => {
        const isAllWhitespace = !/[^\s]/.test(node.nodeValue!);
        if (isAllWhitespace) {
          return NodeFilter.FILTER_REJECT;
        }

        if (node.parentElement && node.parentElement.nodeName === blacklistedNodeName) {
          return NodeFilter.FILTER_REJECT;
        }

        return NodeFilter.FILTER_ACCEPT;
      },
    });

    let currNode: Node | null;
    const textNodes: Node[] = [];
    while ((currNode = walker.nextNode())) {
      textNodes.push(currNode);
    }
    return textNodes;
  };

  const isNonEmptyString = (str: string | null) => str && typeof str === 'string';

  const pseudoLocalizeOrUnlocalize = (
    localizeOrUnlocalize: (str: string) => string,
    element: HTMLElement,
  ) => {
    const textNodesUnderElement = textNodesUnder(element);
    for (const textNode of textNodesUnderElement) {
      const nodeValue = textNode.nodeValue;
      if (nodeValue && isNonEmptyString(nodeValue)) {
        textNode.nodeValue = localizeOrUnlocalize(nodeValue);
      }
    }
  };

  const pseudoLocalize = curry(pseudoLocalizeOrUnlocalize)(localizeString);
  const unlocalize = curry(pseudoLocalizeOrUnlocalize)(unlocalizeString);

  const domMutationCallback = (mutationsList: MutationRecord[]) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        // Turn the observer off while performing dom manipulation to prevent
        // infinite dom mutation callback loops
        observer.disconnect();
        // For every node added, recurse down it's subtree and convert
        // all children as well
        mutation.addedNodes.forEach(pseudoLocalize);
        observer.observe(document.body, observerConfig);
      } else if (mutation.type === 'characterData') {
        const nodeValue = mutation.target.nodeValue!;

        if (
          isNonEmptyString(nodeValue) &&
          mutation.target.parentElement &&
          mutation.target.parentElement.nodeName !== blacklistedNodeName
        ) {
          // Turn the observer off while performing dom manipulation to prevent
          // infinite dom mutation callback loops
          observer.disconnect();
          // The target will always be a text node so it can be converted
          // directly
          mutation.target.nodeValue = localizeString(nodeValue);
          observer.observe(document.body, observerConfig);
        }
      }
    }
  };

  const start = () => {
    // Pseudo localize the DOM
    pseudoLocalize(document.body);
    // Start observing the DOM for changes and run
    // pseudo localization on any added text nodes
    observer = new MutationObserver(domMutationCallback);
    observer.observe(document.body, observerConfig);
  };

  const stop = () => {
    unlocalize(document.body);
    observer && observer.disconnect();
  };

  return {
    start,
    stop,
  };
})();
