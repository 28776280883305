import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSite } from '../copy/copy.generated';
import { Site } from '../copy/models.generated';
import type { Props as StaticProps } from './StaticSpinner';
import StaticSpinner from './StaticSpinner';

type Props = {
  isLoading: boolean;
  size: number;
} & StaticProps;

const SpinnerWithSize: React.FC<React.PropsWithChildren<Props>> = ({
  size,
  isLoading,
  title = useSite(Site.Loading),
  ...htmlProps
}) => <StaticSpinner title={title} height={size} width={size} {...htmlProps} />;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled(SpinnerWithSize)`
  ${(props: Props) => (!props.isLoading ? 'display: none;' : '')}
  height: ${(props: Props) => props.size}px;
  width: ${(props: Props) => props.size}px;
  animation: ${spin} 1.6s infinite linear;
`;

export default LoadingSpinner;
