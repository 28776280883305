// eslint-disable-next-line no-restricted-imports
import { join, map, prop } from 'ramda';
import { formatDurationBetween, isTimeValid, toIsoString } from '@peloton/time';
import type { DetailedWorkout } from './models';

export const toAnalyticsWorkoutProperties = (
  workout: Partial<
    Pick<
      DetailedWorkout,
      | 'achievementTemplates'
      | 'isTotalWorkPersonalRecord'
      | 'endTime'
      | 'startTime'
      | 'isSkipIntroAvailable'
      | 'id'
    >
  >,
): AnalyticsWorkoutProperties | undefined => {
  if (!workout) {
    return;
  }
  return {
    hasAchievements: (workout?.achievementTemplates?.length ?? 0) > 0,
    hasPersonalBest: workout?.isTotalWorkPersonalRecord ?? false,
    workoutEndTime: isTimeValid(workout.endTime)
      ? toIsoString(workout.endTime)
      : undefined,
    '[Workout ID]': workout.id ?? '',
    workoutLength:
      isTimeValid(workout.startTime) && isTimeValid(workout.endTime)
        ? Math.round(formatDurationBetween(workout.startTime, workout.endTime, 'minutes'))
        : undefined,
    workoutStartTime: workout.startTime ? toIsoString(workout.startTime) : '',
    achievementsEarned: join(', ')(
      map(prop('name'), workout?.achievementTemplates ?? []),
    ),
    '[Achievement IDs Earned]': join(', ')(
      map(prop('id'), workout?.achievementTemplates ?? []),
    ),
    skipIntroAvailable: workout.isSkipIntroAvailable,
  };
};

export type AnalyticsWorkoutProperties = {
  hasAchievements: boolean;
  hasPersonalBest: boolean;
  workoutEndTime?: string;
  '[Workout ID]': string;
  workoutLength?: number;
  workoutStartTime: string;
  achievementsEarned?: string;
  '[Achievement IDs Earned]'?: string;
  skipIntroAvailable?: boolean;
};
