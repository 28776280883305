import React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import type { CardInfo, Member, MemberSelectorState } from '@engage/members';
import { getMember } from '@engage/members';
import type { FollowDispatchProps } from '@members/members';
import { mapMembersDispatchToProps, withFollowClick } from '@members/members';
import { default as ConnectPrompt } from './ConnectPrompt';
import { FacebookModalContainer } from './Containers';
import { ErrorPrompt, PermissionsErrorPrompt } from './errorPrompts';
import FindFbFriends from './FindFbFriends';
import type { FacebookFriend, FacebookFriendInfo } from './models';
import { toFacebookCardInfo } from './models';
import type { FacebookModalSelectorState } from './redux';
import {
  connectToFacebook,
  FacebookModalState,
  facebookRerequest,
  getFacebookModalState,
  getFriendsList,
  getTotalFriends,
} from './redux';

const FIND_FACEBOOK_FRIENDS = 'Find Facebook Friends';

const Modal: React.FC<React.PropsWithChildren<Props>> = props => (
  <FacebookModalContainer handleBackClick={props.handleBackClick}>
    {props.modalState === FacebookModalState.ConnectPrompt ? (
      <ConnectPrompt onCtaClick={props.connectToFacebook} />
    ) : null}
    {props.modalState === FacebookModalState.FBFriends ? (
      <FindFbFriends {...(props as any)} onCtaClick={props.facebookRerequest} />
    ) : null}
    {props.modalState === FacebookModalState.PermissionError ? (
      <PermissionsErrorPrompt onCtaClick={props.facebookRerequest} />
    ) : null}
    {props.modalState === FacebookModalState.Error ? (
      <ErrorPrompt onCtaClick={props.facebookRerequest} />
    ) : null}
  </FacebookModalContainer>
);

const mapStateToProps = (state: State) => {
  const friendsList = getFacebookFriendList(state);
  return {
    modalState: getFacebookModalState(state),
    friends: friendsList ? toFacebookCardInfo(friendsList) : undefined,
    total: getTotalFriends(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...mapMembersDispatchToProps(dispatch, FIND_FACEBOOK_FRIENDS),
  ...bindActionCreators({ facebookRerequest, connectToFacebook }, dispatch),
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps,
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  friends: stateProps.friends
    ? withFollowClick(
        dispatchProps.toggleFollow,
        stateProps.friends,
        FIND_FACEBOOK_FRIENDS,
      )
    : undefined,
});

const FindFbFriendsModal = connect<StateProps, DispatchProps, OwnProps, Props>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Modal);

const getFacebookFriendList = (state: State): FacebookFriend[] | undefined => {
  const friends = getFriendsList(state);
  return friends
    ?.map((friendInfo: FacebookFriendInfo) =>
      mergeWithMember(friendInfo, getMember(state, friendInfo.username)),
    )
    ?.filter(friend => friend !== undefined) as FacebookFriend[];
};

const mergeWithMember = (
  friendInfo: FacebookFriendInfo,
  memberInfo?: Member,
): FacebookFriend | undefined =>
  memberInfo
    ? {
        ...memberInfo,
        facebookName: friendInfo.facebookName,
      }
    : undefined;

export const facebookModalMixin = {
  closeButton: {
    fill: 'white',
  },
};

type MaybeBackHandler = { handleBackClick?: () => void };

type OwnProps = MaybeBackHandler & {
  userId: string;
  clearMemberSearch?: () => void;
};

type StateProps = {
  friends?: CardInfo[];
  total?: number;
  modalState?: FacebookModalState;
};

type DispatchProps = FollowDispatchProps & {
  connectToFacebook(): void;
  facebookRerequest(): void;
};

type State = FetcherSelectorState & MemberSelectorState & FacebookModalSelectorState;

type Props = StateProps & DispatchProps & OwnProps;

export default FindFbFriendsModal;
