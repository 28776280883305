import { css } from 'styled-components';

const capsHeaderStyles = css`
  text-transform: uppercase;
`;

/**
 * @deprecated prefer h1 in @engage/typography/font-styles
 *
 */
export const header1Large = css`
  font-size: 40px;
  font-weight: 100;
  line-height: 1.45em;
  letter-spacing: -0.01em;
`;

/**
 * @deprecated prefer h1 in @engage/typography/font-styles
 *
 */
export const header1Small = css`
  font-size: 32px;
  font-weight: 100;
  line-height: 1.25em;
  letter-spacing: -0.02em;
`;

/**
 * @deprecated prefer h1 in @engage/typography/font-styles
 *
 */
export const header2Large = css`
  font-size: 34px;
  font-weight: 100;
  line-height: 1.45em;
  letter-spacing: -0.01em;
`;

/**
 * @deprecated prefer h3 in @engage/typography/font-styles
 *
 */
export const header2Small = css`
  font-size: 24px;
  font-weight: 100;
  line-height: 1.25em;
  letter-spacing: -0.02em;
`;

/**
 * @deprecated prefer h2 in @engage/typography/font-styles
 *
 */
export const header3Large = css`
  font-size: 30px;
  font-weight: 100;
  line-height: 1.45em;
  letter-spacing: -0.01em;
`;

/**
 * @deprecated prefer h4Bold in @engage/typography/font-styles
 *
 */
export const header3Small = css`
  font-size: 21px;
  font-weight: 200;
  line-height: 1.45em;
  letter-spacing: -0.02em;
`;

/**
 * @deprecated prefer h2bold in @engage/typography/font-styles
 *
 */
export const header4Large = css`
  font-size: 26px;
  font-weight: 200;
  line-height: 1.45em;
  letter-spacing: -0.01em;
`;

/**
 * @deprecated prefer h4Bold in @engage/typography/font-styles
 *
 */
export const header4Small = css`
  font-size: 19px;
  font-weight: 300;
  line-height: 1.45em;
  letter-spacing: -0.02em;
`;

/**
 * @deprecated prefer h4Bold in @engage/typography/font-styles
 *
 */
export const header5Large = css`
  font-size: 22px;
  font-weight: 300;
  line-height: 1.5em;
`;

/**
 * @deprecated prefer h6Bold in @engage/typography/font-styles
 *
 */
export const header5Small = css`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25em;
`;

/**
 * @deprecated prefer h5Bold in @engage/typography/font-styles
 *
 */
export const header6Large = css`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
`;

/**
 * @deprecated prefer h7Bold in @engage/typography/font-styles
 *
 */
export const header6Small = css`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.25em;
`;

/**
 * @deprecated prefer h6Bold in @engage/typography/font-styles
 *
 */
export const header7Large = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
`;

/**
 * @deprecated prefer h7Bold in @engage/typography/font-styles
 *
 */
export const header7Small = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25em;
`;

/**
 * @deprecated prefer h6CapBold in @engage/typography/font-styles
 *
 */
export const capsHeader7Large = css`
  ${capsHeaderStyles}
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25em;
`;

/**
 * @deprecated prefer h7CapBold in @engage/typography/font-styles
 *
 */
export const capsHeader7Small = css`
  ${capsHeaderStyles}
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25em;
`;

/**
 * @deprecated prefer h7 in @engage/typography/font-styles
 *
 */
export const header8Large = css`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5em;
`;

export const AlternateHeaderNames = {
  'H1-L': header1Large,
  'H1-S': header1Small,
  'H2-L': header2Large,
  'H2-S': header2Small,
  'H3-L': header3Large,
  'H3-S': header3Small,
  'H4-L': header4Large,
  'H4-S': header4Small,
  'H5-L': header5Large,
  'H5-S': header5Small,
  'H6-L': header6Large,
  'H6-S': header6Small,
  'H7-L': header7Large,
  'H7-S': header7Small,
  'H7C-L': capsHeader7Large,
  'H7C-S': capsHeader7Small,
};
