import { createPeloton } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

export const toPelotonFetchers = (api: typeof engageApi = engageApi) => ({
  CreatePeloton: toFetcher(
    (params: { rideId: string }) => `CreatePeloton${params.rideId}`,
    ({ rideId }) => unwrap(() => createPeloton(api, { rideId })),
    { revalidateIfStale: true },
  ),
});
