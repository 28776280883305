import { getRideMetadataMappings } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type RequiredParams = {
  pelotonPlatform: Parameters<typeof getRideMetadataMappings>[1];
};
type OptionalParams = { acceptLanguage?: string };

export const toMetadataFetchers = (api: typeof engageApi = engageApi) => ({
  GetRideMetadataMappings: toFetcher(
    (_req: RequiredParams, _opt: OptionalParams) => 'GetRideMetadataMappings',
    ({ pelotonPlatform }, { acceptLanguage }) =>
      unwrap(() =>
        getRideMetadataMappings(api, pelotonPlatform, acceptLanguage ?? 'en-US'),
      ),
    {
      dedupingInterval: 60 * 1000 * 30, // 30 Minutes
    },
  ),
});
