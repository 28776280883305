import type { Time } from '@peloton/time';
import type { MemberInfo } from '@engage/members/models';

export enum ChallengeStatus {
  Upcoming = 'upcoming',
  Active = 'active',
  Completed = 'completed',
}

export enum ChallengeType {
  Metric = 'metric',
}

export enum ChallengeAudienceType {
  Global = 'global',
  Company = 'company',
}

export type Challenge = {
  challengeSummary: ChallengeSummary;
  progress: ChallengeProgress;
  participants: ChallengeParticipants;
};

export type ChallengeSummary = {
  id: string;
  type: ChallengeType;
  title: string;
  description: string;
  status: ChallengeStatus;
  startTime: Time;
  endTime: Time;
  symbolImageUrl: string;
  backgroundImageUrl: string;
  digitalBackgroundImageUrl: string;
  challengeType: ChallengeParticipationType;
  communityProgress: ChallengeProgress;
  fitnessDisciplines?: string;
  challengeAudienceType: ChallengeAudienceType;
  challengeAudienceName: string;
  contentAvailability: string;
};

export type ChallengeProgress = {
  hasJoined: boolean;
  metricValue: number;
  metricDisplayValue: string;
  metricDisplayValueToNextTier: string;
  metricDisplayUnit: string;
  metricDisplayUnitToNextTier: string;
  currentTier: ChallengeTier;
  nextTier?: ChallengeTier;
};

export type ChallengeTier = {
  title: string;
  detailedBadgeImageUrl: string;
  hasReward: boolean;
  simpleBadgeImageUrl: string;
  metricValue: number;
  metricDisplayValue: string;
  metricDisplayUnit: string;
  id: string;
};

export type ChallengeDetail = {
  detailedDescription: string;
  criteriaDescription: string;
  fullCriteriaDescription: string;
  tiers: ChallengeTier[];
};

export type ChallengeWithDetail = Challenge & {
  challengeDetail: ChallengeDetail;
};

export type ChallengeFriend = {
  user: MemberInfo;
  progress: Pick<
    ChallengeProgress,
    'metricValue' | 'metricDisplayValue' | 'metricDisplayUnit'
  >;
};

export type ChallengeParticipants = {
  totalCount: number;
};

export enum ChallengeParticipationType {
  Community = 'community',
  Individual = 'individual',
  Personal = 'personalized',
}
