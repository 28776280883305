import type { match } from 'react-router';
import { matchPath } from 'react-router';
import { toProfileUrl } from '@peloton/links/members';
import { toProfileUrlTemplate } from '@engage/overview';

const ACHIEVEMENTS_SUBPATH = '/achievements';
const MEMBERS_ACHIEVEMENTS_SUBPATH = '/:userId/achievements';

export const LEGACY_ACHIEVEMENTS_ROUTE = ACHIEVEMENTS_SUBPATH;
export const LEGACY_MEMBERS_ACHIEVEMENTS_ROUTE = MEMBERS_ACHIEVEMENTS_SUBPATH;
export const ACHIEVEMENTS_ROUTE = toProfileUrlTemplate(ACHIEVEMENTS_SUBPATH);
export const MEMBERS_ACHIEVEMENTS_ROUTE = toProfileUrlTemplate(
  MEMBERS_ACHIEVEMENTS_SUBPATH,
);

export const toAchievementsUrl = (id: string, isMe: boolean) =>
  toProfileUrl(ACHIEVEMENTS_SUBPATH, MEMBERS_ACHIEVEMENTS_SUBPATH, id, isMe);

export const matchAchievementsPath = (path: string): null | MatchPath => {
  const matchedPatch = matchPath<{ userId: string }>(path, {
    path: MEMBERS_ACHIEVEMENTS_ROUTE,
  });
  if (matchedPatch) {
    return matchedPatch;
  }
  return matchPath(path, {
    path: ACHIEVEMENTS_ROUTE,
    exact: true,
  });
};

type MatchPath = match<{ userId?: string }>;
