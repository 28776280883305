/* eslint-disable */
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type TransitionScreenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TransitionScreenQuery = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'transitionScreen'>
);


export const TransitionScreenDocument = gql`
    query TransitionScreen {
  transitionScreen @client
}
    `;

/**
 * __useTransitionScreenQuery__
 *
 * To run a query within a React component, call `useTransitionScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransitionScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransitionScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransitionScreenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransitionScreenQuery, TransitionScreenQueryVariables>) {
        return ApolloReactHooks.useQuery<TransitionScreenQuery, TransitionScreenQueryVariables>(TransitionScreenDocument, baseOptions);
      }
export function useTransitionScreenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransitionScreenQuery, TransitionScreenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransitionScreenQuery, TransitionScreenQueryVariables>(TransitionScreenDocument, baseOptions);
        }
export type TransitionScreenQueryHookResult = ReturnType<typeof useTransitionScreenQuery>;
export type TransitionScreenLazyQueryHookResult = ReturnType<typeof useTransitionScreenLazyQuery>;
export type TransitionScreenQueryResult = ApolloReactCommon.QueryResult<TransitionScreenQuery, TransitionScreenQueryVariables>;