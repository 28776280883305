import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT, toSkipErrorHandlingConfig } from '@peloton/api';
import { getSignedInUsername, getUserId, setPasswordForNewUser } from '@peloton/auth';
import { track } from '@engage/analytics';
import { completeProfileSaga } from '@engage/members';
import { CompleteProfileActions } from '@engage/members/sagas';
import { WELCOME_PATH } from '../pg-profile-setup/urls';
import {
  AnalyticsSource,
  trackViewedCompleteProfileScreen,
  trackViewedCreatePasswordScreen,
  trackViewedWelcomeScreen,
} from './analytics';

enum Actions {
  CreatePassword = 'pelo/@members/pg-complete-profile/CREATE_PASSWORD',
  ViewCompleteProfileScreen = 'pelo/@members/pg-complete-profile/VIEW_COMPLETE_PROFILE_SCREEN',
  ViewWelcomeScreen = 'pelo/@members/pg-complete-profile/VIEW_WELCOME_SCREEN',
  ViewCreatePasswordScreen = 'pelo/@members/pg-complete-profile/VIEW_CREATE_PASSWORD_SCREEN',
}

type Callbacks = {
  onSuccess(): void;
  onError(msg: string): void;
};

type CreatePasswordPayload = {
  callbacks: Callbacks;
  password: string;
};

export type ViewWelcomeScreenAction = {
  type: Actions.ViewWelcomeScreen;
  payload: { source: AnalyticsSource };
};

export const createPassword = (payload: CreatePasswordPayload) => ({
  type: Actions.CreatePassword,
  payload,
});

export const viewCompleteProfileScreen = () => ({
  type: Actions.ViewCompleteProfileScreen,
});
export const viewWelcomeScreen = (
  source: AnalyticsSource = AnalyticsSource.DirectLink,
): ViewWelcomeScreenAction => ({
  type: Actions.ViewWelcomeScreen,
  payload: {
    source,
  },
});
export const viewCreatePasswordScreen = () => ({
  type: Actions.ViewCreatePasswordScreen,
});

export const viewCompleteProfileScreenSaga = function* () {
  const source = document.referrer.endsWith(WELCOME_PATH)
    ? AnalyticsSource.WelcomeScreen
    : AnalyticsSource.CreatePassword;
  yield put(track(trackViewedCompleteProfileScreen(source)));
};

export const viewCreatePasswordScreenSaga = function* () {
  yield put(track(trackViewedCreatePasswordScreen()));
};

export const viewWelcomeScreenSaga = function* (
  action: ViewWelcomeScreenAction,
): SagaIterator {
  const username = yield select(getSignedInUsername);
  yield put(
    track(
      trackViewedWelcomeScreen(
        action.payload.source,
        !!username,
        true, // assume yes until provisional users are implemented
      ),
    ),
  );
};

export const createPasswordSaga = function* (
  action: ReturnType<typeof createPassword>,
): SagaIterator {
  const client: Client = yield getContext(CLIENT_CONTEXT);
  const userId = yield select(getUserId);

  try {
    yield call(
      setPasswordForNewUser,
      client,
      userId,
      action.payload.password,
      toSkipErrorHandlingConfig(),
    );
    yield call(action.payload.callbacks.onSuccess);
  } catch (e) {
    yield call(action.payload.callbacks.onError, e.message);
  }
};

export default function* () {
  yield takeEvery(CompleteProfileActions.CompleteProfile, completeProfileSaga);
  yield takeEvery(Actions.CreatePassword, createPasswordSaga);
  yield takeEvery(Actions.ViewCompleteProfileScreen, viewCompleteProfileScreenSaga);
  yield takeEvery(Actions.ViewWelcomeScreen, viewWelcomeScreenSaga);
  yield takeEvery(Actions.ViewCreatePasswordScreen, viewCreatePasswordScreenSaga);
}
