import { transparentize } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { history } from '@peloton/redux';
import { hover, defaultTransition, media } from '@peloton/styles';
import { slate1, white, gray1, slate3, gray4 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label12 } from '@engage/typography';
import { TagsCopy } from '@members/copy';
import type { CopyIDs, Tags } from '@members/copy';
import { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import { toAnalyticsTagView } from '../analytics';
import {
  ScrollContext,
  PERSISTENT_NAV_BAR_HEIGHT,
  HEADER_MOBILE_HEIGHT,
  HEADER_TABLET_HEIGHT,
  BROWSE_NAV_BAR_HEIGHT,
} from '../MainView/ScrollContext';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import { toTagsModalQuery } from '../urls';
import type { CategoryOffsetsMap } from './BrowseTagsView';

type NavBarProps = {
  selectedCategory: TagBrowseCategorySlug;
  categoryDisplayNames: CopyIDs<typeof Tags>[];
  categoryOffsetsMap: CategoryOffsetsMap;
};

export const NavBar: React.FC<React.PropsWithChildren<NavBarProps>> = ({
  selectedCategory,
  categoryDisplayNames,
  categoryOffsetsMap,
}) => {
  const { browseCategoriesRef } = React.useContext(ScrollContext);
  const categories: TagBrowseCategorySlug[] = [
    TagBrowseCategorySlug.Trending,
    TagBrowseCategorySlug.Featured,
    TagBrowseCategorySlug.Friends,
    TagBrowseCategorySlug.Popular,
  ];
  const currentIndex = categories.indexOf(selectedCategory);
  const [selectedIndex, setSelectedIndex] = React.useState(currentIndex);

  return (
    <Container ref={browseCategoriesRef}>
      <InnerContainer>
        <SelectedPill selectedIndex={selectedIndex} data-test-id="SelectedPill" />
        {categories.map((categorySlug, i) => (
          <CategoryItem
            key={categorySlug}
            categorySlug={categorySlug}
            displayName={categoryDisplayNames[i]}
            selectedCategory={selectedCategory}
            index={i}
            setSelectedIndex={setSelectedIndex}
            categoryOffsetsMap={categoryOffsetsMap}
          />
        ))}
      </InnerContainer>
    </Container>
  );
};

type CategoryProps = {
  categorySlug: TagBrowseCategorySlug;
  selectedCategory: TagBrowseCategorySlug;
  displayName: CopyIDs<typeof Tags>;
  index: number;
  setSelectedIndex: (index: number) => void;
  categoryOffsetsMap: CategoryOffsetsMap;
};

export const onItemClick = (
  categorySlug: TagBrowseCategorySlug,
  selectedCategory: TagBrowseCategorySlug,
  setLastActiveBrowseCategory: (category: TagBrowseCategorySlug) => void,
  index: number,
  setSelectedIndex: (index: number) => void,
  setPersistentNavOffset: (n: number) => void,
  categoryOffsetsMap: CategoryOffsetsMap,
) => {
  setPersistentNavOffset(categoryOffsetsMap[categorySlug]);
  setLastActiveBrowseCategory(categorySlug);
  setSelectedIndex(index);
  history.replace(toTagsModalQuery(TagModalView.BrowseTags, undefined, categorySlug), {
    source: toAnalyticsTagView(TagModalView.BrowseTags, selectedCategory),
  });
};

export const CategoryItem: React.FC<React.PropsWithChildren<CategoryProps>> = ({
  categorySlug,
  selectedCategory,
  displayName,
  index,
  setSelectedIndex,
  categoryOffsetsMap,
}) => {
  const isSelected = categorySlug === selectedCategory;
  const { setLastActiveBrowseCategory, setPersistentNavOffset } = React.useContext(
    TagsContext,
  );

  const onClick = React.useCallback(
    () =>
      onItemClick(
        categorySlug,
        selectedCategory,
        setLastActiveBrowseCategory,
        index,
        setSelectedIndex,
        setPersistentNavOffset,
        categoryOffsetsMap,
      ),
    [categorySlug, selectedCategory, setLastActiveBrowseCategory],
  );

  return (
    <ItemContainer
      onClick={onClick}
      data-test-id={`${displayName.charAt(0).toUpperCase() + displayName.slice(1)}`}
      isSelected={isSelected}
    >
      <Text>
        <TagsCopy id={displayName} />
      </Text>
    </ItemContainer>
  );
};

const selectedHover = css``;
const notSelectedHover = css`
  ${hover`
    transition: color 250ms ease;
    color: ${slate3};
  `};
`;

const Container = styled.div`
  position: absolute;
  top: ${HEADER_MOBILE_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT}px;
  width: 100%;
  height: ${BROWSE_NAV_BAR_HEIGHT}px;
  min-height: ${BROWSE_NAV_BAR_HEIGHT}px;
  display: flex;
  justify-content: center;
  background-color: ${white};
  padding: ${spaces.small}px;
  border-bottom: 1px solid ${transparentize(0.75, gray4)};
  z-index: 3;

  ${media.tablet`
    top: ${HEADER_TABLET_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT}px;
  `}
`;

const InnerContainer = styled.div`
  border-radius: ${spaces.medium}px;
  background-color: ${gray1};
  display: flex;
  width: 100%;
  position: relative;
`;

const ItemContainer = styled.button<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 1;
  color: ${({ isSelected }) => (isSelected ? white : slate1)};
  ${({ isSelected }) => (isSelected ? selectedHover : notSelectedHover)}

  ${defaultTransition('color')}
`;
ItemContainer.displayName = 'ItemContainer';

const Text = styled.span`
  ${label12}
  text-transform: uppercase;
`;
Text.displayName = 'CategoryItemText';

const SelectedPill = styled.div<{ selectedIndex: number }>`
  height: 100%;
  width: calc(100% / 4);
  background-color: ${slate1};
  border-radius: ${spaces.medium}px;
  position: absolute;
  left: ${({ selectedIndex }) => `${selectedIndex * 25}%`};

  ${defaultTransition('left')}
`;
SelectedPill.displayName = 'SelectedPill';
