import { find } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';
import type { Locale } from '@peloton/internationalize';
import { Language, toLocale } from '@peloton/internationalize';
import { getDisplayLanguage } from '@engage/settings';

export type LanguageAnalyticsProps = {
  deviceLanguage: Language;
  deviceLocale: Locale;
};

export const getLanguageAnalyticsPropsSaga = function* (): SagaIterator {
  const language = yield select(getDisplayLanguage);
  return {
    deviceLanguage: toLanguageName(language),
    deviceLocale: toLocale(language),
  };
};

const toLanguageName = (language: Language) =>
  find(key => language === Language[key], Object.keys(Language));
