import React from 'react';
import styled from 'styled-components';
import { useReduxState } from '@engage/redux';
import { useCta, Cta, useTags, Tags } from '@members/copy';
import { BrowseTagsView } from '../BrowseTags';
import { SharedHeader } from '../Modal/TagsModalHeaderView';
import { SearchTagsView } from '../SearchAndCreate';
import { getTagViewFromLocation } from '../selectors';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import { MyTagsView } from '../UserTags';
import { PersistentNavBar } from './PersistentNavBar';
import { ScrollProvider } from './ScrollContext';

export const MainView: React.FC<React.PropsWithChildren<{ isEntered: boolean }>> = ({
  isEntered,
}) => {
  const { goBack } = React.useContext(TagsContext);
  const selectedView = useReduxState(getTagViewFromLocation) ?? TagModalView.MyTags;

  return (
    <ScrollProvider>
      <Container>
        <SharedHeader
          button={{
            props: {
              onClick: goBack,
            },
            content: useCta(Cta.Back),
          }}
          headerText={{ id: Tags.Tags, renderer: useTags }}
        />

        <PersistentNavBar selectedView={selectedView} />
        {toViewComponent(selectedView, isEntered)}
      </Container>
    </ScrollProvider>
  );
};

const toViewComponent = (view: TagModalView, isEntered: boolean) => {
  switch (view) {
    case TagModalView.SearchAndCreate:
      return <SearchTagsView isEntered={isEntered} />;
    case TagModalView.BrowseTags:
      return <BrowseTagsView />;
    case TagModalView.MyTags:
    default:
      return <MyTagsView />;
  }
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
