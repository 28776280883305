// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Settings = {
  AgeAndGenderPrivateCta: 'ageAndGenderPrivateCta',
  AgeAndGenderPrivateDescription: 'ageAndGenderPrivateDescription',
  AgeAndGenderPrivateTitle: 'ageAndGenderPrivateTitle',
  BlockExplicitCta: 'blockExplicitCta',
  BlockExplicitDescription: 'blockExplicitDescription',
  BlockExplicitTitle: 'blockExplicitTitle',
  Cancel: 'cancel',
  ChallengeCompletionDataCta: 'challengeCompletionDataCta',
  ChallengeCompletionDataDescription: 'challengeCompletionDataDescription',
  ChallengeCompletionDataTitle: 'challengeCompletionDataTitle',
  ChangeSettings: 'changeSettings',
  ClassLanguageCTA: 'classLanguageCTA',
  ClassLanguageDescription: 'classLanguageDescription',
  ClassLanguages: 'classLanguages',
  ClassLanguageTitle: 'classLanguageTitle',
  ConnectCta: 'connectCta',
  ConnectedNetworks: 'connectedNetworks',
  ConnectedToFacebookText: 'connectedToFacebookText',
  ConnectToFacebookText: 'connectToFacebookText',
  ConnectToFacebookTitle: 'connectToFacebookTitle',
  ConnectToFitbitText: 'connectToFitbitText',
  ConnectToStravaDescription: 'connectToStravaDescription',
  ConnectToStravaText: 'connectToStravaText',
  ConnectToFacebookTitleNew: 'connectToFacebookTitleNew',
  ConnectToFitbitDescription: 'connectToFitbitDescription',
  ConnectToFitbitTextNew: 'connectToFitbitTextNew',
  ConnectToStravaDescriptionNew: 'connectToStravaDescriptionNew',
  ConnectToStravaTextNew: 'connectToStravaTextNew',
  AutoShareToStrava: 'autoShareToStrava',
  ManualShareToStrava: 'manualShareToStrava',
  Continue: 'continue',
  DisconnectFromFacebookText: 'disconnectFromFacebookText',
  DisplayLanguage: 'displayLanguage',
  DisplayLanguageCanadianFrench: 'displayLanguageCanadianFrench',
  DisplayLanguageDisclaimer: 'displayLanguageDisclaimer',
  DisplayLanguageEnglish: 'displayLanguageEnglish',
  DisplayLanguageFrenchFrance: 'displayLanguageFrenchFrance',
  DisplayLanguageGerman: 'displayLanguageGerman',
  DisplayLanguageSpanishMexico: 'displayLanguageSpanishMexico',
  DisplayLanguageSpanishSpain: 'displayLanguageSpanishSpain',
  DisplayPreferences: 'displayPreferences',
  EffortZonesPrivateCta: 'effortZonesPrivateCta',
  EffortZonesPrivateDescription: 'effortZonesPrivateDescription',
  EffortZonesPrivateTitle: 'effortZonesPrivateTitle',
  ErrorToast: 'errorToast',
  Features: 'features',
  FeedPrivacy: 'feedPrivacy',
  FeedPrivacySubtext: 'feedPrivacySubtext',
  FeedPrivacySubtitle: 'feedPrivacySubtitle',
  WorkoutActivityPrivacyTitle: 'workoutActivityPrivacyTitle',
  WorkoutActivityPrivacySubtitle: 'workoutActivityPrivacySubtitle',
  OnlyMe: 'onlyMe',
  Anyone: 'anyone',
  JustMe: 'justMe',
  MyFollowers: 'myFollowers',
  WorkoutActivityPrivacy: 'workoutActivityPrivacy',
  WorkoutActivityPrivacySubtext: 'workoutActivityPrivacySubtext',
  HidePrenatalClassesCTA: 'hidePrenatalClassesCTA',
  HidePrenatalClassesDescription: 'hidePrenatalClassesDescription',
  HidePrenatalClassesTitle: 'hidePrenatalClassesTitle',
  Kilometers: 'kilometers',
  LanguagesAll: 'languagesAll',
  LanguagesModal: 'languagesModal',
  LanguagesModalDescription: 'languagesModalDescription',
  LanguagesModalTitle: 'languagesModalTitle',
  Miles: 'miles',
  ViewMyProfile: 'viewMyProfile',
  NewClassInvites: 'newClassInvites',
  NewFacebookFriendsDescription: 'newFacebookFriendsDescription',
  NewFacebookFriendsTitle: 'newFacebookFriendsTitle',
  NewFollowersDescription: 'newFollowersDescription',
  NewFollowersTitle: 'newFollowersTitle',
  NoClassesBecauseLanguagesMessage: 'noClassesBecauseLanguagesMessage',
  NotificationsTitle: 'notificationsTitle',
  PreferencesTitle: 'preferencesTitle',
  Privacy: 'privacy',
  PrivateProfileCta: 'privateProfileCta',
  PrivateProfileDescription: 'privateProfileDescription',
  PrivateProfileTitle: 'privateProfileTitle',
  PrivateProfileSubtitle: 'privateProfileSubtitle',
  PseudoLocalization: 'pseudoLocalization',
  ReceiveNotificationsNewInvite: 'receiveNotificationsNewInvite',
  SaveChanges: 'saveChanges',
  Save: 'save',
  Select: 'select',
  StravaCardDescription: 'stravaCardDescription',
  StravaCardTitle: 'stravaCardTitle',
  StriveScoreDescription: 'striveScoreDescription',
  StriveScoreEnableCta: 'striveScoreEnableCta',
  StriveScorePrivateCta: 'striveScorePrivateCta',
  StriveScoreTitle: 'striveScoreTitle',
  SubscriptionsTitle: 'subscriptionsTitle',
  Subtitles: 'subtitles',
  SuccessToast: 'successToast',
  Title: 'title',
  UnitsOfMeasurementTitle: 'unitsOfMeasurementTitle',
  UserErrorToast: 'userErrorToast',
  UserSettingsNotSaved: 'userSettingsNotSaved',
  VideoChatAll: 'videoChatAll',
  VideoChatDescription: 'videoChatDescription',
  VideoChatFollowing: 'videoChatFollowing',
  VideoChatNone: 'videoChatNone',
  VideoChatTitle: 'videoChatTitle',
  WorkoutMapsPrivateCta: 'workoutMapsPrivateCta',
  WorkoutMapsPrivateDescription: 'workoutMapsPrivateDescription',
  WorkoutMapsPrivateTitle: 'workoutMapsPrivateTitle',
  AccountLinkingDisclaimer: 'accountLinkingDisclaimer',
  AccountLinkingSubSectionTitle: 'accountLinkingSubSectionTitle',
  AccountLinkingSubSectionText: 'accountLinkingSubSectionText',
  AccountIsLinked: 'accountIsLinked',
  AccountLinkCta: 'accountLinkCta',
  AccountUnlinkCta: 'accountUnlinkCta',
  AccountLinkingErrorAlreadyLinkedHeader: 'accountLinkingErrorAlreadyLinkedHeader',
  AccountLinkingErrorAlreadyLinkedBody: 'accountLinkingErrorAlreadyLinkedBody',
  AccountLinkingErrorEmailHeader: 'accountLinkingErrorEmailHeader',
  AccountLinkingErrorEmailBody: 'accountLinkingErrorEmailBody',
  AccountLinkingInvalidAccountLinkingTokenError:
    'accountLinkingInvalidAccountLinkingTokenError',
  AccountLinkingUnlinkHeader: 'accountLinkingUnlinkHeader',
  AccountLinkingUnlinkBody: 'accountLinkingUnlinkBody',
  AccountLinkingUnlinkPrimaryCta: 'accountLinkingUnlinkPrimaryCta',
  AccountLinkingUnlinkCancel: 'accountLinkingUnlinkCancel',
  AccountLinkingHeader: 'accountLinkingHeader',
  VppaToolTipHeader: 'vppaToolTipHeader',
  VppaToolTipDetailsOne: 'vppaToolTipDetailsOne',
  VppaToolTipDetailsTwo: 'vppaToolTipDetailsTwo',
  VppaToolTipCta: 'vppaToolTipCta',
  VppaPrivacyText: 'vppaPrivacyText',
  VppaPrivateProfileDescription: 'vppaPrivateProfileDescription',
  VppaCheckbox: 'vppaCheckbox',
  LocationPrivacyUpdateProfileDescription: 'locationPrivacyUpdateProfileDescription',
  LocationPrivacyUpdatePageDescription: 'locationPrivacyUpdatePageDescription',
  LearnMore: 'learnMore',
  LocationPrivacyProfileTitle: 'locationPrivacyProfileTitle',
  LocationPrivacyProfileDescriptionP1: 'locationPrivacyProfileDescriptionP1',
  LocationPrivacyProfileDescriptionP2: 'locationPrivacyProfileDescriptionP2',
  LocationPrivacyWorkoutTitle: 'locationPrivacyWorkoutTitle',
  LocationPrivacyWorkoutDescriptionP1: 'locationPrivacyWorkoutDescriptionP1',
  LocationPrivacyWorkoutDescriptionP2: 'locationPrivacyWorkoutDescriptionP2',
  PrivacyUpdateProfileDescription: 'privacyUpdateProfileDescription',
  PrivacyUpdateProfileDescriptionWithName: 'privacyUpdateProfileDescriptionWithName',
  PrivacyUpdatePrivacySectionDescription: 'privacyUpdatePrivacySectionDescription',
  PrivacyUpdatePrivacySectionMultipleAppsDescription:
    'PrivacyUpdatePrivacySectionMultipleAppsDescription',
  PrivacyUpdatePrivacyTitle: 'privacyUpdatePrivacyTitle',
  PrivacyUpdateWorkoutActivityPrivacyAnyoneSubtitle:
    'privacyUpdateWorkoutActivityPrivacyAnyoneSubtitle',
  PrivacyUpdateRunningTitle: 'privacyUpdateRunningTitle',
  PrivacyUpdateRunningSubtitle: 'privacyUpdateRunningSubtitle',
  PrivacyUpdateAgeGenderTitle: 'privacyUpdateAgeGenderTitle',
  PrivacyUpdateAgeGenderSubtitle: 'privacyUpdateAgeGenderSubtitle',
  PrivacyUpdateNewFollowersSubtitle: 'privacyUpdateNewFollowersSubtitle',
  PrivacyUpdateHideExplicitClassesSubtitle: 'privacyUpdateHideExplicitClassesSubtitle',
  PrivacyUpdatePrePostnatalClassesSubtitle: 'privacyUpdatePrePostnatalClassesSubtitle',
  MarketingTitle: 'marketingTitle',
  MarketingSubtitle: 'marketingSubtitle',
  MarketingToggleText: 'marketingToggleText',
  PersonalizationTitle: 'personalizationTitle',
  PersonalizationSubtitle: 'personalizationSubtitle',
  PersonalizationToggleText: 'personalizationToggleText',
  PrivacyUpdateStriveScoreTitle: 'privacyUpdateStriveScoreTitle',
  PrivacyUpdateStriveScoreSubtitle: 'privacyUpdateStriveScoreSubtitle',
  PrivacyUpdateStravaSelection: 'privacyUpdateStravaSelection',
  PrivacyUpdatePublicInfo: 'privacyUpdatePublicInfo',
  PrivacyUpdateSearchBehaviorTitle: 'privacyUpdateSearchBehaviorTitle',
  PrivacyUpdateSearchBehaviorSubtitle: 'privacyUpdateSearchBehaviorSubtitle',
  SearchCheckbox: 'searchCheckbox',
  PrivacyUpdateContactsSyncTitle: 'privacyUpdateContactsSyncTitle',
  PrivacyUpdateContactsSyncSubtitle: 'privacyUpdateContactsSyncSubtitle',
  ContactsSyncCheckbox: 'contactsSyncCheckbox',
  Everyone: 'everyone',
} as const;
