import React from 'react';
import { default as PelotonInternationalize } from '@peloton/internationalize';
import type { PropsOf } from '@peloton/react';
import { getLocalizedStrings } from '@members/localized-strings';
import { useLocale } from './useLocale';

const Internationalize: React.FC<
  React.PropsWithChildren<PropsOf<typeof PelotonInternationalize>>
> = props => {
  const locale = useLocale();

  return (
    <PelotonInternationalize
      messages={getLocalizedStrings(locale)}
      locale={locale}
      {...props}
    />
  );
};

export { Internationalize };
