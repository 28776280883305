import React from 'react';

const Calendar: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#4C525E"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2.5C8.5 2.22386 8.27614 2 8 2C7.72386 2 7.5 2.22386 7.5 2.5V3.5H4C2.89543 3.5 2 4.39543 2 5.5V9V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V9V5.5C22 4.39543 21.1046 3.5 20 3.5H16.5V2.5C16.5 2.22386 16.2761 2 16 2C15.7239 2 15.5 2.22386 15.5 2.5V3.5H8.5V2.5ZM7.5 4.5V5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5V4.5H15.5V5.5C15.5 5.77614 15.7239 6 16 6C16.2761 6 16.5 5.77614 16.5 5.5V4.5H20C20.5523 4.5 21 4.94772 21 5.5V8.5H3V5.5C3 4.94772 3.44772 4.5 4 4.5H7.5ZM3 9.5V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V9.5H3Z"
    />
  </svg>
);

export { Calendar };
