import React from 'react';

const LanguageIcon: React.FC<React.PropsWithChildren<Props>> = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9993 35.8337C28.7439 35.8337 35.8327 28.7448 35.8327 20.0003C35.8327 11.2558 28.7439 4.16699 19.9993 4.16699C11.2548 4.16699 4.16602 11.2558 4.16602 20.0003C4.16602 28.7448 11.2548 35.8337 19.9993 35.8337Z"
      stroke="#A8ACB1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 4.16699C20 4.16699 26.6667 10.0003 26.6667 20.0003C26.6667 30.0003 20 35.8337 20 35.8337"
      stroke="#A8ACB1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 35.833C20 35.833 13.3333 29.9997 13.3333 19.9997C13.3333 9.99967 20 4.16634 20 4.16634"
      stroke="#A8ACB1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16602 20H35.8327"
      stroke="#A8ACB1"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export type Props = React.SVGProps<SVGSVGElement> & { title?: string };

export default LanguageIcon;
