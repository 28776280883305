import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { isDefined } from '@peloton/types';
import { useReduxAction, useReduxState } from '@engage/redux';
import type { ModalLabel, CopyIDs } from '@members/copy';
import { useModalLabel } from '@members/copy';
import type { ModalProps } from './Modal';
import { Modal } from './Modal';
import type { ModalName, ModalSelectorState } from './redux';
import { closeModal, getActiveModalName } from './redux';

const getModalContent = (modalNames: ModalMap, modalName?: ModalName) =>
  isDefined(modalName) && modalNames.hasOwnProperty(modalName)
    ? modalNames[modalName]
    : undefined;

const handleClosing = (props: DispatchProps & Partial<ModalContent>) => {
  props.closeModal();
  if (isDefined(props.onCloseModal)) {
    props.onCloseModal();
  }
};

export const ConnectedModal: React.FC<React.PropsWithChildren<OwnProps>> = props => {
  const modalName = useReduxState((state: ModalSelectorState) =>
    getActiveModalName(state),
  );

  const closeModalHandler = useReduxAction(closeModal);
  const modalContent = getModalContent(props.modalNames, modalName);

  if (isDefined(modalContent)) {
    const { component, ...modalProps } = modalContent;
    return (
      <Modal
        isOpen={true}
        children={component}
        contentLabel={modalProps.ariaLabelId}
        closeHandler={() =>
          handleClosing({
            closeModal: () => closeModalHandler(modalProps.modelIdentifiers!),
            onCloseModal: modalProps.onCloseModal,
          })
        }
        {...modalProps}
        {...props}
      />
    );
  }

  return <Modal isOpen={false} contentLabel="" />;
};

/**
 * @deprecated in favor of hook based ConnectedModal
 */

export const LegacyConnectedModal = compose<ModalProps, OwnProps & Partial<ModalProps>>(
  connect(
    (state: ModalSelectorState) => ({
      modalName: getActiveModalName(state),
    }),
    { closeModal: (modalIdentifiers: string[]) => closeModal(modalIdentifiers) },
  ),
  mapProps((props: StateProps & DispatchProps & OwnProps) => {
    const modalContent = getModalContent(props.modalNames, props.modalName);
    if (isDefined(modalContent)) {
      const { component, ...modalProps } = modalContent;
      return {
        ...props,
        ...modalProps,
        children: component,
        contentLabel: useModalLabel(modalContent.ariaLabelId),
        closeHandler: () =>
          handleClosing({
            closeModal: () => props.closeModal(modalProps.modelIdentifiers),
            onCloseModal: modalProps.onCloseModal,
          }),
        isOpen: true,
      };
    } else {
      return { isOpen: false };
    }
  }),
)(Modal);

type ModalContent = {
  component: React.ReactElement<any>;
  modelIdentifiers?: string[];
  ariaLabelId: CopyIDs<typeof ModalLabel>;
  onCloseModal?(): void;
} & Partial<ModalProps>;
export type ModalMap = Record<ModalName, ModalContent>;
type OwnProps = { modalNames: ModalMap };
type StateProps = { modalName?: ModalName };
type DispatchProps = { closeModal(modalIdentifiers?: string[]): void };
