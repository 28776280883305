import { fork, all } from 'redux-saga/effects';
import { packetSaga } from '@engage/video';
import { videoPageAnalyticsSaga } from './analytics';
import { VideoSagaActionTypes } from './sagaActions';
import { videoPageSaga } from './video';

export { viewedPlayerSaga, isTransitionAwayFromVideoPage } from './video';

export * from './sagaActions';
export { MAXIMUM_DELAY_TIME } from './analytics';

const QUEUED_PACKET_SEND_THRESHOLD = 10;

export const combinedVideoPageSaga = function* () {
  yield all([
    fork(videoPageSaga),
    fork(videoPageAnalyticsSaga),
    fork(
      packetSaga,
      QUEUED_PACKET_SEND_THRESHOLD,
      [VideoSagaActionTypes.LoadLive, VideoSagaActionTypes.LoadVOD],
      [VideoSagaActionTypes.LeaveWorkout],
    ),
  ]);
};
