import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, throttle } from 'redux-saga/effects';
import { getScreenPropsSaga, toClientDetails } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { createBookmarkForClass, deleteBookmarkForClass } from './api';
import { config } from './config';
import type { BookmarkToggleRequestAction } from './redux';
import {
  BookmarkActionTypes,
  toggleBookmarkClassFailure,
  toggleBookmarkClassSuccess,
} from './redux';

export const toggleBookmarkClassSaga = function* (
  client: Client,
  action: BookmarkToggleRequestAction,
): SagaIterator {
  const isCurrentlyBookmarked = action.payload.isCurrentlyBookmarked;
  try {
    const screenProps = yield call(getScreenPropsSaga);
    const props = yield call(config.getAnalyticsPropsForBookmark, screenProps);
    let analyticsProps = toClientDetails(props, action.payload.source);

    if (action.payload.sourceDetail) {
      analyticsProps = {
        'Source Detail': action.payload.sourceDetail,
        ...analyticsProps,
      };
    }

    isCurrentlyBookmarked
      ? yield call(deleteBookmarkForClass, client, action.payload.classId, analyticsProps)
      : yield call(
          createBookmarkForClass,
          client,
          action.payload.classId,
          analyticsProps,
        );

    yield put(toggleBookmarkClassSuccess(action.payload.classId, !isCurrentlyBookmarked));
  } catch (e) {
    yield put(toggleBookmarkClassFailure());
  }
};

export const classesSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield throttle(250, BookmarkActionTypes.TOGGLE, toggleBookmarkClassSaga, client);
};
