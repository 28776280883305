import {
  getSubscriptionStreamToken,
  streamHistoryHeartbeat,
  postVideoAssetService2,
} from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

// 30 seconds
const HEARTBEAT = 30 * 1000;

export const toStreamFetchers = (api: typeof engageApi = engageApi) => ({
  CreateStream: toFetcher(
    (params: { currentStartTime: string }) => `CreateStream${params.currentStartTime}`,
    () => unwrap(() => getSubscriptionStreamToken(api)),
    { revalidateIfStale: true },
  ),
  Hearbeat: toFetcher(
    (params: { streamHistoryId: string }) => `StreamHeartbeat${params.streamHistoryId}`,
    params => unwrap(() => streamHistoryHeartbeat(api, params.streamHistoryId)),
    {
      refreshInterval: HEARTBEAT,
      dedupingInterval: HEARTBEAT,
      shouldRetryOnError: false,
    },
  ),
  CreateAkamaiStream: toFetcher(
    (params: {
      currentStartTime: string;
      rideId: string;
      contentType: 'live' | 'on_demand' | 'encore' | 'preview';
      streamSource?: 'primary' | 'multichannel';
    }) => `GetStream${params.rideId}${params.currentStartTime}`,
    params =>
      unwrap(() =>
        postVideoAssetService2(
          api,
          params.rideId,
          params.contentType,
          params.streamSource ?? 'primary',
        ),
      ),
  ),
});
