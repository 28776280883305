import { css } from 'styled-components';
import { defaultTransition } from '@peloton/styles';
import type { TransitionState } from '@engage/animations';

export type TransitionStateProps = { transitionState: TransitionState };

export const hoverStyle = css`
  &::before {
    content: '';
    background: black;
    opacity: 0.1;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    ${defaultTransition('opacity')}
  }

  &:hover {
    &::before {
      opacity: 0;
    }
  }
`;
