import React from 'react';

const RightArrowIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    fill="none"
    height={20}
    viewBox="0 0 21 20"
    width={21}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.83398 16.187v-12.37402c0-.76927.8323-1.25046 1.49897-.86663l10.74585 6.18701c.668.38463.668 1.34864 0 1.73324l-10.74585 6.187c-.66667.3838-1.49897-.0973-1.49897-.8666z"
      fill="#fff"
    />
  </svg>
);

export default RightArrowIcon;
