import useSWR from 'swr';
import { toSubscriptionAnalytics } from '@engage/subscriptions/analytics';
import { toPrimarySub } from '@engage/subscriptions/Subscription';
import { toBaseProperties } from '@members/analytics/webBaseProperties';
import { getStartedClientSession } from '@members/api/endpoints/started-client-session';
import { engageApi } from '@members/data-fetch';
import { useAllSubscriptions } from '@members/subscriptions/hooks/useAllSubscriptions';

export const useStartedClientSession = async () => {
  let webBaseAnalyticsProps = {};
  let subAnalytics = {};

  const subscriptions = useAllSubscriptions();

  if (!!subscriptions) {
    const primarySub = toPrimarySub(subscriptions);
    subAnalytics = !!primarySub ? toSubscriptionAnalytics(primarySub) : {};
    webBaseAnalyticsProps = toBaseProperties();
  }

  useSWR(!!subscriptions ? 'getStartedClientSession' : null, () =>
    getStartedClientSession(engageApi, {
      ...webBaseAnalyticsProps,
      ...subAnalytics,
    }),
  );
};
