import { datadogRum } from '@datadog/browser-rum';
/**
 * Send an error to Datadog with an optional label for better reporting.
 * @param originalError Error
 * @param label String
 */
export const logError = (originalError: Error, label?: string) => {
  const errorWithOptionalLabel = new Error(label ?? originalError.message);
  // Tyepscript doesn't like adding a standard .cause key to errors
  (errorWithOptionalLabel as any).cause = originalError;
  datadogRum.addError(errorWithOptionalLabel);
};
