/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { Context } from './Context';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: string;
  URL: string;
  /** Opaque structure used to join a class. */
  JoinToken: any;
  Cursor: string;
  /** Federation type representing set of fields */
  _FieldSet: any;
};

export type AbandonOdysseyWorkoutResponse =
  | OdysseyInvalidWorkoutMutationError
  | OdysseyItemNotFoundError
  | OdysseyWorkoutSuccessResponse;

export type AchievementAwardedEventPayload = ActivityFeedEventPayload & {
  __typename: 'AchievementAwardedEventPayload';
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']>;
  /** @deprecated Use pelotonClass in PelotonClassFeedWorkout instead */
  pelotonClass?: Maybe<PelotonClass>;
};

/** A class that users from some group are currently taking. */
export type ActiveTagClass = {
  __typename: 'ActiveTagClass';
  joinToken: Scalars['JoinToken'];
  /** The users in the group who are taking this class */
  activeUsers: ActiveTagUserPaginatedList;
  pelotonClass: PelotonClass;
};

/** A class that users from some group are currently taking. */
export type ActiveTagClassActiveUsersArgs = {
  limit?: Scalars['Int'];
  after?: Maybe<Scalars['Cursor']>;
};

/** An element in a paginated ActiveClass result set. */
export type ActiveTagClassEdge = {
  __typename: 'ActiveTagClassEdge';
  /** The ActiveClass element. */
  node: ActiveTagClass;
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor'];
};

/** A list of ActiveClass results that can be paged over. */
export type ActiveTagClassesPaginatedList = PaginatedList & {
  __typename: 'ActiveTagClassesPaginatedList';
  /** The elements of the current page. */
  edges: Array<ActiveTagClassEdge>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
  /** Total number of active classes. */
  totalCount: Scalars['Int'];
};

export type ActiveTagUserEdge = {
  __typename: 'ActiveTagUserEdge';
  node: User;
  cursor: Scalars['Cursor'];
};

export type ActiveTagUserPaginatedList = {
  __typename: 'ActiveTagUserPaginatedList';
  edges: Array<ActiveTagUserEdge>;
  /** The total number of results in the underlying result set. */
  totalCount: Scalars['Int'];
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

export type ActiveWorkout = {
  __typename: 'ActiveWorkout';
  actualStartTime?: Maybe<Scalars['DateTime']>;
  isSkipIntroAvailable: Scalars['Boolean'];
  pauseInfo?: Maybe<PauseInfo>;
  peloton: Peloton;
  workout: Workout;
};

export type ActivityFeedErrorResponse = {
  __typename: 'ActivityFeedErrorResponse';
  message: Scalars['String'];
  type: ActivityFeedErrorType;
};

export enum ActivityFeedErrorType {
  DatabaseFailure = 'DATABASE_FAILURE',
  InvalidCursor = 'INVALID_CURSOR',
  InvalidUserId = 'INVALID_USER_ID',
  ItemNotFound = 'ITEM_NOT_FOUND',
  Unexpected = 'UNEXPECTED',
}

export type ActivityFeedEvent = {
  __typename: 'ActivityFeedEvent';
  /** Timestamp of activity feed event */
  createdAt: Scalars['DateTime'];
  /** Event type used for card display, e.g. workout type or achievement type */
  eventType: EventType;
  /** @deprecated Use eventType instead to determine feed event type */
  feedEventType?: Maybe<FeedEventType>;
  /** Sublist of highfived users whose avatars will be shown on a activity feed event */
  highFivedUsers: Array<User>;
  /** Paginated response of high fives for the activity feed event */
  highFives: Scalars['Int'];
  /** Id of activity feed event */
  id: Scalars['ID'];
  /** If the current user has highfived the activity feed event */
  isHighFived: Scalars['Boolean'];
  /** Payload data of the activity feed event */
  payload: Payload;
  /** Federated user object of the user for the activity feed event */
  user: User;
  /**
   * enum that maps to workout metrics to be displayed on client
   * @deprecated Use workoutMetricsType in WorkoutFinalizedEventPayload instead
   */
  workoutMetricsType: WorkoutMetricsType;
};

export type ActivityFeedEventEdge = {
  __typename: 'ActivityFeedEventEdge';
  activityFeedEvent: ActivityFeedEvent;
  cursor: Scalars['Cursor'];
};

export type ActivityFeedEventPaginationResponse = PaginatedList & {
  __typename: 'ActivityFeedEventPaginationResponse';
  edges: Array<ActivityFeedEventEdge>;
  pageInfo: PageInfo;
};

export type ActivityFeedEventPayload = {
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']>;
};

export type ActivityFeedEventResponse =
  | ActivityFeedErrorResponse
  | ActivityFeedEvent
  | ActivityFeedEventPaginationResponse;

export type AddClassToStackInput = {
  pelotonClassId: Scalars['ID'];
};

/** Input for addTagToUser operation. */
export type AddTagToUserInput = {
  /** Case insensitive name of the tag to add. */
  tagName: Scalars['String'];
};

export type AddTagToUserResponse =
  | AddTagToUserSuccess
  | DoesNotExistError
  | TagAlreadyExistsError
  | TagCapacityOverLimitError
  | TagNameInvalidCharacterError
  | TagNameLengthError
  | TagNameProhibitedError;

/** Response for addTagToUser operation. */
export type AddTagToUserSuccess = {
  __typename: 'AddTagToUserSuccess';
  /** The tag. */
  tag?: Maybe<Tag>;
};

export type Album = {
  __typename: 'Album';
  id: Scalars['String'];
  image?: Maybe<Media>;
  name: Scalars['String'];
};

export type AlreadyExists = {
  __typename: 'AlreadyExists';
  _?: Maybe<Scalars['Boolean']>;
};

export type Artist = {
  __typename: 'Artist';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AwardedProgramAchievementTier = {
  __typename: 'AwardedProgramAchievementTier';
  achievementTier: ProgramAchievementTier;
  awardedAt: Scalars['DateTime'];
  numClassesCompleted: Scalars['Int'];
};

export type BasePlaylist = {
  id: Scalars['ID'];
  isInClassMusicShown: Scalars['Boolean'];
  playbackSongs: Array<PlaylistPlaybackSong>;
  topArtists: Array<Artist>;
};

/** Best Runs by difficulty for Score and Completion Percentage for an Odyssey Level */
export type BestRuns = {
  __typename: 'BestRuns';
  bestRunsByCompletionPercentage: Array<OdysseyWorkout>;
  bestRunsByScore: Array<OdysseyWorkout>;
  starsEarned: Scalars['Float'];
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CannotScheduleClassError = Error & {
  __typename: 'CannotScheduleClassError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type CantUnfollow = {
  __typename: 'CantUnfollow';
  _?: Maybe<Scalars['Boolean']>;
};

export type Captions = {
  __typename: 'Captions';
  locales: Array<LocaleCode>;
};

/** Assets of the Peloton class. */
export type ClassAssets = {
  __typename: 'ClassAssets';
  /** Thumbnal image of the class. */
  thumbnailImage: Media;
};

export type ClassSegment = {
  __typename: 'ClassSegment';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startTimeOffset: Scalars['Int'];
  length?: Maybe<Scalars['Int']>;
  icon: Media;
  iconDetails: IconDetails;
  metricsType: ClassSegmentMetric;
  intensityInMets: Scalars['Float'];
  isDrill?: Maybe<Scalars['Boolean']>;
  subsegments?: Maybe<Array<Maybe<ClassSubsegment>>>;
};

export enum ClassSegmentMetric {
  Cycling = 'CYCLING',
  Caesar = 'CAESAR',
  Floor = 'FLOOR',
  Running = 'RUNNING',
  Walking = 'WALKING',
  None = 'NONE',
}

export type ClassSubsegment = {
  __typename: 'ClassSubsegment';
  id: Scalars['ID'];
  type: Scalars['String'];
  displayName: Scalars['String'];
  scheduledOffset?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  rounds?: Maybe<Scalars['Int']>;
  trackableMovementsDisabled?: Maybe<Scalars['Boolean']>;
  movements: Array<Maybe<Movement>>;
};

export type ClassTimeline = {
  __typename: 'ClassTimeline';
  startOffset?: Maybe<Scalars['Int']>;
  endOffset?: Maybe<Scalars['Int']>;
  videoEndOffset?: Maybe<Scalars['Int']>;
  classSegments: Array<ClassSegment>;
  targetMetrics?: Maybe<Array<TargetMetrics>>;
};

/** A wrapper type for class related tags info */
export type ClassTopTag = {
  __typename: 'ClassTopTag';
  tag?: Maybe<Tag>;
};

export type ClassType = {
  __typename: 'ClassType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Color = {
  __typename: 'Color';
  hexCode: Scalars['String'];
};

export type CompletedStats = {
  __typename: 'CompletedStats';
  workoutId: Scalars['ID'];
  avgHeartRate?: Maybe<Scalars['Float']>;
  calories?: Maybe<Scalars['Float']>;
  striveScore?: Maybe<Scalars['Float']>;
  zoneScore: ZoneScore;
};

export type CompleteOdysseyWorkoutInput = {
  /** DEPRECATED: use <platform>CompletionDetails instead */
  beatCompletionPercent?: Maybe<Scalars['Float']>;
  bikeCompletionDetails?: Maybe<OdysseyBikeCompletionInput>;
  /** DEPRECATED: use <platform>CompletionDetails instead */
  breakerCompletionPercent?: Maybe<Scalars['Float']>;
  completionPercent: Scalars['Float'];
  id: Scalars['ID'];
  score: Scalars['Int'];
  /** DEPRECATED: use <platform>CompletionDetails instead */
  streamCompletionPercent?: Maybe<Scalars['Float']>;
  treadCompletionDetails?: Maybe<OdysseyTreadCompletionInput>;
};

export type CompleteOdysseyWorkoutResponse =
  | OdysseyBadRequestError
  | OdysseyInvalidWorkoutMutationError
  | OdysseyItemNotFoundError
  | OdysseyWorkoutSuccessResponse;

/** The values for the supported content formats */
export enum ContentFormat {
  /** video */
  Video = 'video',
  /** audio */
  Audio = 'audio',
}

/** Input for creating an invite from a live/encore class */
export type CreateLiveInviteInput = {
  /** fitness discipline associated with the class */
  fitnessDiscipline: Scalars['String'];
  /** host of the invite */
  host: UserInput;
  /** list of users to invite */
  invitedUsers: Array<UserInput>;
  /** join token associated with the class */
  joinToken: Scalars['ID'];
  /** Occasion slug of the invite */
  occasionSlug?: Maybe<Scalars['String']>;
};

/** Input for creating an invite from an on demand class */
export type CreateNewOnDemandInviteInput = {
  /** classId associated with the invite */
  classId: Scalars['ID'];
  /** fitness discipline associated with the class */
  fitnessDiscipline: Scalars['String'];
  /** host of the invite */
  host: UserInput;
  /** list of users to invite */
  invitedUsers: Array<UserInput>;
  /** Occasion slug of the invite */
  occasionSlug?: Maybe<Scalars['String']>;
  /** scheduledEndTime of class associated with invite */
  scheduledEndTime?: Maybe<Scalars['DateTime']>;
  /** scheduledStartTime of class associated with invite */
  scheduledStartTime: Scalars['DateTime'];
};

export type CreateOdysseyWorkoutInput = {
  difficulty: OdysseyDifficulty;
  levelId: Scalars['ID'];
};

export type CreateOdysseyWorkoutResponse = OdysseyWorkoutSuccessResponse;

/** Input for creating an invite from an already scheduled on demand class */
export type CreateScheduledOnDemandInviteInput = {
  /** fitness discipline associated with the class */
  fitnessDiscipline: Scalars['String'];
  /** host of the invite */
  host: UserInput;
  /** list of users to invite */
  invitedUsers: Array<UserInput>;
  /** Occasion slug of the invite */
  occasionSlug?: Maybe<Scalars['String']>;
  /** scheduledClassId of class associated with the invite */
  scheduledClassId: Scalars['ID'];
  /** scheduledEndTime of class associated with invite */
  scheduledEndTime?: Maybe<Scalars['DateTime']>;
  /** scheduledStartTime of class associated with invite */
  scheduledStartTime: Scalars['DateTime'];
};

/** Difficulty Level of the Peloton Class */
export type DifficultyLevel = {
  __typename: 'DifficultyLevel';
  /** Slug of the difficulty level. */
  slug: DifficultyLevelSlug;
  /** Display name of the difficulty level. */
  displayName: Scalars['String'];
};

/** The possible values for difficulty level slug */
export enum DifficultyLevelSlug {
  /** slug for beginner */
  Beginner = 'BEGINNER',
  /** slug for intermediate */
  Intermediate = 'INTERMEDIATE',
  /** slug for advanced */
  Advanced = 'ADVANCED',
}

/** Display tags for Gym Movement Groups */
export type DisplayTag = {
  __typename: 'DisplayTag';
  /** Name of the display tag property */
  slug: Scalars['String'];
  /** Value of the identified slug */
  value: Scalars['String'];
};

/** The possible systems for measuring distance */
export enum DistanceUnit {
  /** Measure distance in miles */
  Imperial = 'IMPERIAL',
  /** Measure distance in kilometers */
  Metric = 'METRIC',
}

export type DoesNotExistError = Error & {
  __typename: 'DoesNotExistError';
  resource: Scalars['String'];
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Type for an entertainment streaming provider */
export type EntertainmentChannel = {
  __typename: 'EntertainmentChannel';
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  backgroundImage: Scalars['URL'];
  location: Scalars['URL'];
  userAgent: Scalars['String'];
};

/** A list of EntertainmentChannels that can be paged over. */
export type EntertainmentChannelPaginatedList = PaginatedList & {
  __typename: 'EntertainmentChannelPaginatedList';
  /** The channels of the current page. */
  edges: Array<EntertainmentChannel>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

export type EntertainmentChannelsQueryResponse =
  | EntertainmentChannelPaginatedList
  | PermissionError;

export type Equipment = {
  __typename: 'Equipment';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  icon?: Maybe<Media>;
};

/** Represents an error. */
export type Error = {
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ErrorVideoStream = {
  __typename: 'ErrorVideoStream';
  joinError: JoinError;
};

export type EventDetails = {
  __typename: 'EventDetails';
  activityFeedEvent: ActivityFeedEvent;
  highFiveDetails: HighFiveDetails;
};

export type EventDetailsResponse = ActivityFeedErrorResponse | EventDetails;

export enum EventType {
  Class = 'CLASS',
  JustCardio = 'JUST_CARDIO',
  JustLift = 'JUST_LIFT',
  JustMeditate = 'JUST_MEDITATE',
  JustRide = 'JUST_RIDE',
  JustRow = 'JUST_ROW',
  JustRun = 'JUST_RUN',
  JustStretch = 'JUST_STRETCH',
  JustWalk = 'JUST_WALK',
  JustYoga = 'JUST_YOGA',
  Lanebreak = 'LANEBREAK',
  Scenic = 'SCENIC',
}

export enum FeedEventType {
  PrivacyUpdated = 'PRIVACY_UPDATED',
  WorkoutDeleted = 'WORKOUT_DELETED',
  WorkoutFinalized = 'WORKOUT_FINALIZED',
}

export enum FeedPrivacy {
  MyFollowers = 'my_followers',
  OnlyMe = 'only_me',
}

export type FeedWorkout = {
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']>;
  /** split pace of the workout (aka time per 500m for caesar) */
  avgSplitPace?: Maybe<Scalars['Float']>;
  /** stroke rate of the workout (aka spm for caesar) */
  avgStrokeRate?: Maybe<Scalars['Float']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']>;
  completedAt: Scalars['Int'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']>;
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID'];
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']>;
  /** zone 1 HR score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']>;
  /** zone 2 HR score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']>;
  /** zone 3 HR score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']>;
  /** zone 4 HR score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']>;
  /** zone 5 HR score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']>;
};

/** Fitness discipline. */
export type FitnessDiscipline = {
  __typename: 'FitnessDiscipline';
  /** Slug of the fitness discipline. */
  slug: FitnessDisciplineSlug;
  /** Display name of the fitness discipline. */
  displayName: Scalars['String'];
};

/** The possible values for fitness discipline slug */
export enum FitnessDisciplineSlug {
  /** slug for beyond the ride */
  BeyondTheRide = 'BEYOND_THE_RIDE',
  /** slug for bike bootcamp */
  BikeBootcamp = 'BIKE_BOOTCAMP',
  /** slug for caesar */
  Caesar = 'CAESAR',
  /** slug for caesar bootcamp */
  CaesarBootcamp = 'CAESAR_BOOTCAMP',
  /** slug for cardio */
  Cardio = 'CARDIO',
  /** slug for circuit */
  Circuit = 'CIRCUIT',
  /** slug for cycling */
  Cycling = 'CYCLING',
  /** slug for meditation */
  Meditation = 'MEDITATION',
  /** slug for running */
  Running = 'RUNNING',
  /** slug for strength */
  Strength = 'STRENGTH',
  /** slug for stretching */
  Stretching = 'STRETCHING',
  /** slug for walking */
  Walking = 'WALKING',
  /** slug for yoga */
  Yoga = 'YOGA',
}

/** A list of FollowingIds that can be paged over. */
export type FollowingUsersPaginatedList = PaginatedList & {
  __typename: 'FollowingUsersPaginatedList';
  /** The elements of the current page. */
  edges: Array<User>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

/** The possible states for whether one user (user1) follows another (user2). */
export enum FollowStatus {
  /** user1 follows user2. */
  Following = 'FOLLOWING',
  /** user1 has requested to follow user2, but this has not been accepted yet. */
  FollowPending = 'FOLLOW_PENDING',
  /** user1 does not follow user2. */
  None = 'NONE',
  /** user1 is the same user as user2. */
  Self = 'SELF',
}

export type FreestyleFeedWorkout = FeedWorkout & {
  __typename: 'FreestyleFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']>;
  completedAt: Scalars['Int'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']>;
  fitnessDiscipline: Scalars['String'];
  freestyleActivityTitle: Scalars['String'];
  freestyleImageUrl: Scalars['String'];
  isOutdoor?: Maybe<Scalars['Boolean']>;
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID'];
  /** Platform of the FeedWorkout */
  platform?: Maybe<Scalars['String']>;
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']>;
};

export type FreestyleMetadata = {
  __typename: 'FreestyleMetadata';
  musicProviders: Array<MusicProvider>;
};

export type FreestyleWorkoutGoalInput = {
  /** Target value specified for the type of goal. */
  targetValue?: Maybe<Scalars['Float']>;
  /** Type of freestyle goal */
  type?: Maybe<FreestyleWorkoutGoalType>;
  /** Metric specified for the type of goal */
  unit?: Maybe<FreestyleWorkoutGoalUnit>;
};

export enum FreestyleWorkoutGoalType {
  Distance = 'DISTANCE',
  Time = 'TIME',
}

export enum FreestyleWorkoutGoalUnit {
  Kilometers = 'KILOMETERS',
  Meters = 'METERS',
  Miles = 'MILES',
  Minutes = 'MINUTES',
}

/** Instructional tags for Gym Movement Groups */
export type GroupTags = {
  __typename: 'GroupTags';
  /** Suggested time of the movement group referenced in seconds */
  durationSeconds?: Maybe<Scalars['Int']>;
  /** Group Type like Warm Up or Cool Down */
  groupType?: Maybe<Scalars['String']>;
  /** Composition of a ladder Movement Group */
  ladderComposition?: Maybe<Scalars['String']>;
  /** Ascending, Descending Ascend-Descend */
  movementOrder?: Maybe<Scalars['String']>;
  /** Name of the movement referenced */
  splits?: Maybe<Scalars['String']>;
  /** Description of the movement referenced */
  totalRounds?: Maybe<Scalars['Int']>;
};

export type GymErrorResponse = {
  __typename: 'GymErrorResponse';
  message: Scalars['String'];
  type: GymErrorType;
};

export enum GymErrorType {
  HttpException = 'HTTP_EXCEPTION',
  InvalidCursor = 'INVALID_CURSOR',
  InvalidGymPlan = 'INVALID_GYM_PLAN',
  InvalidInput = 'INVALID_INPUT',
  InvalidMovementGroupType = 'INVALID_MOVEMENT_GROUP_TYPE',
  InvalidWorkoutType = 'INVALID_WORKOUT_TYPE',
  ItemNotFound = 'ITEM_NOT_FOUND',
  JsonParseFailure = 'JSON_PARSE_FAILURE',
  Unexpected = 'UNEXPECTED',
}

export type GymFeedWorkout = FeedWorkout & {
  __typename: 'GymFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']>;
  completedAt: Scalars['Int'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']>;
  /** ID of the Gym Plan referenced */
  gymPlanId: Scalars['String'];
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID'];
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']>;
};

/** Gym Movement and associated instruction */
export type GymMovement = {
  __typename: 'GymMovement';
  /** Description of the movement referenced */
  description: Scalars['String'];
  /** ID of the movement referenced */
  id: Scalars['ID'];
  /** Movement thumbnail imageUrl */
  imageUrl?: Maybe<Scalars['String']>;
  /** @deprecated Replaced by movementPattern */
  isRepsMirrored: Scalars['Boolean'];
  /** Long movement video data */
  longVideo?: Maybe<MovementVideo>;
  /** @deprecated Moved to MovementVideo type */
  longVideoId?: Maybe<Scalars['ID']>;
  /** @deprecated Moved to MovementVideo type */
  longVideoUrl?: Maybe<Scalars['String']>;
  /** Pattern in which unilateral movements should be performed */
  movementPattern: MovementPattern;
  muscleGroups: Array<MovementMuscleGroup>;
  /** Name of the movement referenced */
  name: Scalars['String'];
  /** Number of the movement referenced */
  reps?: Maybe<Scalars['Int']>;
  /** Short movement video data */
  shortVideo?: Maybe<MovementVideo>;
  /** @deprecated Moved to MovementVideo type */
  shortVideoId?: Maybe<Scalars['ID']>;
  /** @deprecated Moved to MovementVideo type */
  shortVideoUrl?: Maybe<Scalars['String']>;
  /** Suggested time of the movement referenced in seconds */
  suggestedTimeInSeconds?: Maybe<Scalars['Int']>;
};

/** Gym Movement group has at least one movement */
export type GymMovementGroup = {
  __typename: 'GymMovementGroup';
  /** Display tags of movement group info, e.g. duration, ladderComposition */
  displayTags: Array<DisplayTag>;
  fitnessDiscipline: Scalars['String'];
  /**
   * Tags of movement group, e.g. warm up
   * @deprecated Use displayTags instead.
   */
  groupTags?: Maybe<GroupTags>;
  /** Movements in the movement group */
  gymMovements: Array<GymMovement>;
  /** ID of the movement referenced */
  id: Scalars['ID'];
  /** Intensity metric in Mets */
  intensity: Scalars['Int'];
  /** Type of movement group, e.g. EMOM, AMRAP */
  movementGroupType: Scalars['String'];
  name: Scalars['String'];
};

/** Gym Plan and associated metadata for gym content */
export type GymPlan = {
  __typename: 'GymPlan';
  /** Timestamp of plan creation */
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  /** Translated string for difficulty */
  difficultyDisplayName?: Maybe<Scalars['String']>;
  /** the approximate time of a gym workout */
  durationSeconds?: Maybe<Scalars['Int']>;
  fitnessDiscipline: Scalars['String'];
  /** Movement groups associated with the gym plan */
  gymMovementGroups: Array<GymMovementGroup>;
  id: Scalars['ID'];
  instructor?: Maybe<Instructor>;
  /** Has the user taken the gym plan */
  isTaken: Scalars['Boolean'];
  /** Muscle group score for body activity */
  muscleGroupScore: Array<MuscleGroupScore>;
  name: Scalars['String'];
  /** Movement group count */
  numberOfMovementGroups: Scalars['Int'];
  /** Total Movement count */
  numberOfMovements: Scalars['Int'];
  /** Timestamp of plan publication */
  publishedAt: Scalars['DateTime'];
  /** type / focus of gym workout */
  workoutType: Scalars['String'];
  /** Translated string for workoutType */
  workoutTypeDisplayName: Scalars['String'];
};

export type GymPlanEdge = {
  __typename: 'GymPlanEdge';
  cursor: Scalars['Cursor'];
  gymPlan: GymPlan;
};

/** Data driving the Gym Plan filter chips */
export type GymPlanFilterChip = {
  __typename: 'GymPlanFilterChip';
  iconUrl: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

/** Gym Plan query filter */
export type GymPlanFilterInput = {
  slug: Scalars['String'];
};

export type GymPlanPaginationResponse = PaginatedList & {
  __typename: 'GymPlanPaginationResponse';
  edges: Array<GymPlanEdge>;
  pageInfo: PageInfo;
};

export type GymPlanResponse = GymErrorResponse | GymPlan | GymPlanPaginationResponse;

export type GymWorkout = {
  __typename: 'GymWorkout';
  id: Scalars['ID'];
};

export type HeroImageAssets = {
  __typename: 'HeroImageAssets';
  center: Media;
  progress: Media;
  right: Media;
  tv: Media;
};

export type HighFiveDetails = {
  __typename: 'HighFiveDetails';
  users: Array<User>;
};

export type HighFiveDetailsResponse = ActivityFeedErrorResponse | HighFiveDetails;

export type IconDetails = {
  __typename: 'IconDetails';
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** An Instructor who teaches classes. */
export type Instructor = {
  __typename: 'Instructor';
  /** The instructor's unique identifier. */
  id: Scalars['ID'];
  /** The instructor's name. */
  name: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** The assets that represent this instructor. */
  assets: InstructorAssets;
};

/** Instructor's assets. */
export type InstructorAssets = {
  __typename: 'InstructorAssets';
  /** The url of an image that represents this instructor. */
  profileImage: Media;
  aboutImage?: Maybe<Media>;
};

export type InstructorCue = {
  __typename: 'InstructorCue';
  offsets: OffsetsCue;
  resistanceRange: RangeCue;
  cadenceRange: RangeCue;
};

export type InviteDetails = {
  __typename: 'InviteDetails';
  /** Data related to the user who is invited to this event */
  authedInvitedUser?: Maybe<InviteUser>;
  /** Timestamp of when the invite was issued */
  createdAt: Scalars['DateTime'];
  /** Get friends of this user who have accepted this invite, ordered by username and paginated. */
  getInvitedAcceptedFollowing: InvitedUsersPaginationResponse;
  /** Get users who are invited, ordered by self,invitee status,username and paginated. */
  getInvitedUsers: InvitedUsersPaginationResponse;
  /** Federated user object of the user who hosted this event */
  hostUser: User;
  /** Id of the invite */
  inviteId: Scalars['ID'];
  /** Post MVP: Indicates if an invite has been created or cancelled */
  inviteStatus: InviteStatus;
  /** Occasion for this invite, if applicable */
  occasion?: Maybe<Occasion>;
  /** Referenced class related to this invitation */
  scheduledClass: ScheduledClass;
  /** Referenced scheduled class id of the invited class */
  scheduledClassId: Scalars['ID'];
  /** Availability of link to invitation */
  visibility: Visibility;
};

export type InviteDetailsGetInvitedAcceptedFollowingArgs = {
  cursor?: Maybe<Scalars['Cursor']>;
  limit?: Maybe<Scalars['Int']>;
};

export type InviteDetailsGetInvitedUsersArgs = {
  cursor?: Maybe<Scalars['Cursor']>;
  limit?: Maybe<Scalars['Int']>;
};

/** paginated InviteUserEdge result set. */
export type InvitedUsersPaginatedList = {
  __typename: 'InvitedUsersPaginatedList';
  /** List of InviteUserEdge */
  edges: Array<InviteUserEdge>;
  /** Information about a page of results returned */
  pageInfo: PageInfo;
  /** total number of users */
  totalCount: Scalars['Int'];
};

/** Returns InvitedUsersPaginatedList or Error */
export type InvitedUsersPaginationResponse =
  | InviteFriendsHttpException
  | InviteFriendsNotAuthorized
  | InvitedUsersPaginatedList;

export enum InvitedUserStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Dismissed = 'DISMISSED',
  NotInvited = 'NOT_INVITED',
  NoInvite = 'NO_INVITE',
  Pending = 'PENDING',
  PendingCancelled = 'PENDING_CANCELLED',
}

/** Error thrown if token was too long for Database writes */
export type InviteFriendsBadRequest = InviteFriendsError & {
  __typename: 'InviteFriendsBadRequest';
  message?: Maybe<Scalars['String']>;
};

/** Error thrown if a user attempts to create a share link for an invite while the feature is disabled */
export type InviteFriendsCreateShareLinkDisabledError = InviteFriendsError & {
  __typename: 'InviteFriendsCreateShareLinkDisabledError';
  message?: Maybe<Scalars['String']>;
};

/** Invite Friends Error Interface */
export type InviteFriendsError = {
  message?: Maybe<Scalars['String']>;
};

/** Error thrown when HTTP exception calling endpoint */
export type InviteFriendsHttpException = InviteFriendsError & {
  __typename: 'InviteFriendsHttpException';
  message?: Maybe<Scalars['String']>;
};

/** Error thrown when invalid data format, especially when calling endpoint */
export type InviteFriendsInvalidDataFormat = InviteFriendsError & {
  __typename: 'InviteFriendsInvalidDataFormat';
  message?: Maybe<Scalars['String']>;
};

/** Error thrown if invited user size limit exceeded when attempting to create or add users to an invite */
export type InviteFriendsInvitedUserSizeLimitExceeded = InviteFriendsError & {
  __typename: 'InviteFriendsInvitedUserSizeLimitExceeded';
  invitedUserSizeLimit: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
};

/** Error thrown when querying or mutating a model that doesn't exist */
export type InviteFriendsItemNotFoundError = InviteFriendsError & {
  __typename: 'InviteFriendsItemNotFoundError';
  message?: Maybe<Scalars['String']>;
};

/** Error thrown if a user attempts to create a share link for an invite without correct permission */
export type InviteFriendsNoPermissionToCreateShareLinkError = InviteFriendsError & {
  __typename: 'InviteFriendsNoPermissionToCreateShareLinkError';
  message?: Maybe<Scalars['String']>;
};

/** Error thrown when not authorized for the invite friends action */
export type InviteFriendsNotAuthorized = InviteFriendsError & {
  __typename: 'InviteFriendsNotAuthorized';
  message?: Maybe<Scalars['String']>;
};

/** Returns InviteDetails or Error */
export type InviteResponse =
  | InviteDetails
  | InviteFriendsHttpException
  | InviteFriendsInvalidDataFormat
  | InviteFriendsInvitedUserSizeLimitExceeded
  | InviteFriendsItemNotFoundError
  | InviteFriendsNotAuthorized;

/** Returns an shareable link for an invite or Error */
export type InviteShareUrlResponse =
  | InviteFriendsCreateShareLinkDisabledError
  | InviteFriendsInvalidDataFormat
  | InviteFriendsItemNotFoundError
  | InviteFriendsNoPermissionToCreateShareLinkError
  | ShareUrl;

export enum InviteStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
}

export type InviteUser = {
  __typename: 'InviteUser';
  /** Federated user object of the user who was invited */
  invitedUser: User;
  invitedUserStatus: InvitedUserStatus;
  /** Federated user object of the user that did the inviting */
  invitorUser: User;
};

/** An element in a paginated InviteUser result set. */
export type InviteUserEdge = {
  __typename: 'InviteUserEdge';
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor'];
  /** Data related to the user who is invited to this event */
  inviteUserItem: InviteUser;
};

/** Input for inviting users to an already existing invite */
export type InviteUsersToInviteInput = {
  /** id of the already existing invite */
  inviteId: Scalars['ID'];
  /** list of users to be added to invite */
  invitedUsers: Array<UserInput>;
};

export type JoinableClass = {
  __typename: 'JoinableClass';
  activeWorkout?: Maybe<ActiveWorkout>;
  id: Scalars['ID'];
  videoStream: VideoStream;
  videoStreamOrError: VideoStreamOrError;
  pelotonClass: PelotonClass;
};

/** Error thrown if video is live and an attempt was made to join it by a non-authorized user */
export type JoinAuthorizationError = JoinError & {
  __typename: 'JoinAuthorizationError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Error thrown if video content is not yet live and an attempt was made to join it */
export type JoinContentNotLiveError = JoinError &
  RetryableError & {
    __typename: 'JoinContentNotLiveError';
    code: Scalars['String'];
    message: Scalars['String'];
    retryAfterSeconds?: Maybe<Scalars['Int']>;
    retryMaxDuration: Scalars['Int'];
  };

export type JoinError = {
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Error thrown if video is live and an attempt was made to join it by a non-subscribed user */
export type JoinNoActiveSubscriptionError = JoinError & {
  __typename: 'JoinNoActiveSubscriptionError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Error thrown if video stream content limit has been reached and and an attempt was made to join it */
export type JoinStreamLimitError = JoinError & {
  __typename: 'JoinStreamLimitError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Represents a leaderboard entry */
export type LeaderboardEntry = {
  __typename: 'LeaderboardEntry';
  completionPercent: Scalars['Float'];
  difficulty: OdysseyDifficulty;
  rank?: Maybe<Scalars['Int']>;
  score: Scalars['Int'];
  /** @deprecated Use startDate in WorkoutDetails */
  startTime: Scalars['DateTime'];
  workoutDetails?: Maybe<LeaderboardWorkoutDetails>;
  workoutId: Scalars['ID'];
};

/** A leaderboard for an Odyssey level */
export type LeaderboardRequestInput = {
  /** Optional: If provided, filter leaderboard based on difficulty */
  difficulty?: Maybe<OdysseyDifficulty>;
  /**
   * If provided, will have extraWorkoutRank and extraLeaderboardEntry returned for easy access to given workout.
   *             This can be used to conveniently grab information for a specific
   * workout without it needing to be on the returned leaderboard
   */
  extraWorkoutId?: Maybe<Scalars['ID']>;
  /** Where to start the LB; note that this should not exceed 10k */
  from: Scalars['Int'];
  levelId: Scalars['ID'];
  /** How many values to fetch */
  size: Scalars['Int'];
  /** Optional: Whether to sort user leaderboard by score or date descending */
  sortBy?: Maybe<OdysseyLeaderboardSortBy>;
  /** Optional: offset: If provided, only shows workouts that started at this point or later */
  startTime?: Maybe<Scalars['DateTime']>;
};

export type LeaderboardWorkoutDetails = {
  __typename: 'LeaderboardWorkoutDetails';
  avatar: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use startDate instead */
  startTime?: Maybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
  username: Scalars['String'];
  workoutId: Scalars['ID'];
};

/** Type for a playable Odyssey level */
export type Level = {
  __typename: 'Level';
  assets: OdysseyLevelAssets;
  availability: LevelAvailability;
  bestRuns?: Maybe<BestRuns>;
  difficultyDetails?: Maybe<Array<OdysseyLevelDifficultyDetail>>;
  duration?: Maybe<Scalars['Int']>;
  durationCategory?: Maybe<Scalars['Int']>;
  explicit: Scalars['Boolean'];
  featuredArtists: Scalars['String'];
  filterOptionIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  playlistId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  volume?: Maybe<Scalars['Int']>;
  musicProvider?: Maybe<MusicProvider>;
};

export enum LevelAvailability {
  Archived = 'Archived',
  Available = 'Available',
  Draft = 'Draft',
  Testing = 'Testing',
}

export type LevelBrowseFiltersSuccessResponse = {
  __typename: 'LevelBrowseFiltersSuccessResponse';
  browseFilters: Array<OdysseyLevelBrowseFilter>;
  /** @deprecated Use browseFilters instead */
  filters: Array<OdysseyLevelFilter>;
};

export type LevelFiltersQueryResponse =
  | LevelBrowseFiltersSuccessResponse
  | OdysseyBadRequestError;

export type LevelListQueryResponse = LevelListSuccessResponse | OdysseyNotAuthorizedError;

/** Successful response from the service after querying multiple levels */
export type LevelListSuccessResponse = {
  __typename: 'LevelListSuccessResponse';
  levels: Array<Level>;
};

export type LevelQueryResponse = LevelSuccessResponse | OdysseyItemNotFoundError;

/** Successful response from the service after querying or mutating a level */
export type LevelSuccessResponse = {
  __typename: 'LevelSuccessResponse';
  level: Level;
};

export type LiveClass = PelotonClass & {
  __typename: 'LiveClass';
  id: Scalars['ID'];
  title: Scalars['String'];
  classId: Scalars['String'];
  originLocale: Locale;
  fitnessDiscipline: FitnessDiscipline;
  joinToken: Scalars['JoinToken'];
  assets: ClassAssets;
  playableOnPlatform: Scalars['Boolean'];
  duration: Scalars['Int'];
  timeline?: Maybe<ClassTimeline>;
  classTypes: Array<ClassType>;
  description: Scalars['String'];
  instructor: Instructor;
  /** corresponds to original air time for class, newly upcoming live classes substitutes in scheduled_start_time */
  airTime: Scalars['DateTime'];
  /** time at which the video stream should start, which is usually a couple mins before the scheduled workout start time */
  streamStartTime?: Maybe<Scalars['DateTime']>;
  /**
   * For live class, this is the official scheduled time (like 5:00pm) but for
   * Sessions this is the time the video starts, which is typically 1 minute before
   * the workout starts at the scheduled time
   */
  scheduledStartTime: Scalars['DateTime'];
  /** This is 50 seconds after the scheduledStartTime for Live recorded classes due to the 50 second stream delay */
  scheduledStartTimeWithTapeDelay: Scalars['DateTime'];
  /**
   * This is the actual time at which the workout begins and leaderboard metrics
   * start, typically a few to one minute after the video starts
   */
  actualStartTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated use liveClassType equal to ENCORE instead of isEncore */
  isEncore: Scalars['Boolean'];
  peloton: PelotonResult;
  /** Whether this class is live, encore, session, or premiere */
  liveClassCategory: LiveClassCategory;
  difficultyLevel?: Maybe<DifficultyLevel>;
  equipment: Array<Equipment>;
  /** whether this class is an FTP test class that will be used to determine the user's power zones */
  isFtpTest: Scalars['Boolean'];
  /** whether user has favorited/bookmarked this class */
  isBookmarked: Scalars['Boolean'];
  playlist?: Maybe<Playlist>;
  instructorCues?: Maybe<Array<InstructorCue>>;
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  targetMetricsData?: Maybe<TargetMetricsData>;
  captions?: Maybe<Captions>;
  isExplicit: Scalars['Boolean'];
  /** explicitness of a class */
  explicitRating: Scalars['Int'];
  metrics: Array<MetricName>;
  shopTheClass: Scalars['Boolean'];
  classPreviewVideo?: Maybe<Scalars['String']>;
  contentAvailability?: Maybe<Scalars['String']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']>;
  isLimitedRide?: Maybe<Scalars['Boolean']>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']>;
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']>>;
  flags?: Maybe<Array<Scalars['String']>>;
};

/** The possible values live class category */
export enum LiveClassCategory {
  /** live class on Peloton live class schedule */
  Live = 'LIVE',
  /** encore class on Peloton live class schedule */
  Encore = 'ENCORE',
  /** session class */
  Session = 'SESSION',
  /**
   * Premiere class on Peloton live class schedule
   * Currently only returned for specific platforms as gated by a feature flag `premiere_show_user_classes_on_platforms`
   */
  Premiere = 'PREMIERE',
}

/** Locale object representing a language */
export type Locale = {
  __typename: 'Locale';
  /** iso code for this locale */
  code: LocaleCode;
  /** display name for the locale's language (i.e. English, German, Deutsch, etc) */
  language: Scalars['String'];
};

/** The values for the supported locale codes */
export enum LocaleCode {
  /** english */
  EnUs = 'EN_US',
  /** german */
  DeDe = 'DE_DE',
  /** spanish */
  EsEs = 'ES_ES',
  /** spanish (mexico) */
  EsMx = 'ES_MX',
  /** spanish (united states) */
  EsUs = 'ES_US',
  /** french */
  FrFr = 'FR_FR',
  /** french (canada) */
  FrCa = 'FR_CA',
  /** english - beta */
  UzUz = 'UZ_UZ',
  /** german - beta */
  ZuZu = 'ZU_ZU',
  /** french - beta */
  CyCy = 'CY_CY',
  /** hindi - beta */
  TkTk = 'TK_TK',
}

/** Describes a media asset */
export type Media = {
  __typename: 'Media';
  location: Scalars['URL'];
};

/** MetaTag of a tag */
export type MetaTag = {
  __typename: 'MetaTag';
  /** The name to use when displaying this meta tag. */
  displayName: Scalars['String'];
};

export type Metric = {
  __typename: 'Metric';
  name?: Maybe<MetricName>;
  upper?: Maybe<Scalars['Float']>;
  lower?: Maybe<Scalars['Float']>;
};

export enum MetricName {
  Altitude = 'ALTITUDE',
  Calories = 'CALORIES',
  Cadence = 'CADENCE',
  Distance = 'DISTANCE',
  Elevation = 'ELEVATION',
  Gps = 'GPS',
  GpsAccuracy = 'GPS_ACCURACY',
  HeartRate = 'HEART_RATE',
  Incline = 'INCLINE',
  Resistance = 'RESISTANCE',
  Speed = 'SPEED',
  StrokeRate = 'STROKE_RATE',
  PaceIntensity = 'PACE_INTENSITY',
  PowerZone = 'POWER_ZONE',
}

export type ModifyStackInput = {
  pelotonClassIdList: Array<Scalars['ID']>;
};

export type Movement = {
  __typename: 'Movement';
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  leonardoExerciseIds?: Maybe<Array<Scalars['String']>>;
  movementVariationId?: Maybe<Scalars['String']>;
  trackingType?: Maybe<Scalars['String']>;
  weightsAndArms?: Maybe<Scalars['String']>;
  repetitionType?: Maybe<Scalars['String']>;
  talkbackDescription?: Maybe<Scalars['String']>;
  subsegmentMappingId?: Maybe<Scalars['String']>;
  targetNumber?: Maybe<Scalars['Int']>;
  weightLevel?: Maybe<Scalars['String']>;
};

/** Muscle Group Score for individual movement group */
export type MovementMuscleGroup = {
  __typename: 'MovementMuscleGroup';
  /** display name for users */
  displayName: Scalars['String'];
  /** muscle group on the movement */
  muscleGroup: Scalars['String'];
  /** 3, 2 or 1 */
  ranking: Scalars['Int'];
};

export enum MovementPattern {
  Alternating = 'alternating',
  Each = 'each',
  Left = 'left',
  Reciprocating = 'reciprocating',
  Right = 'right',
  Simultaneous = 'simultaneous',
  Total = 'total',
}

/** Gym Movement Video data */
export type MovementVideo = {
  __typename: 'MovementVideo';
  /** Id of Video */
  id: Scalars['ID'];
  /** Timestamp of token expiration */
  tokenExpiresAt: Scalars['DateTime'];
  /** Type of video (Short or Long) */
  type: MovementVideoType;
  /** Tokenized url */
  url: Scalars['String'];
};

export enum MovementVideoType {
  Long = 'LONG',
  Short = 'SHORT',
}

/** Muscle Group Score for top level plan */
export type MuscleGroupScore = {
  __typename: 'MuscleGroupScore';
  /** 3, 2 or 1 */
  bucket: Scalars['Int'];
  /** display name for users */
  displayName: Scalars['String'];
  /** muscle group on the movement */
  muscleGroup: Scalars['String'];
  /** precentage of muscle group of total class plan */
  percentage: Scalars['Int'];
  /** Score for muscle group */
  score: Scalars['Int'];
};

export type MusicProvider = PlaylistMusicProvider;

export type Mutation = {
  __typename: 'Mutation';
  /** Abandon an Odyssey workout */
  abandonOdysseyWorkout: AbandonOdysseyWorkoutResponse;
  /** adds class to user's schedule (i.e. counts in to the class) */
  addClassToSchedule: ScheduledClassResponse;
  /** Add a single class to the UserStack for the current logged in user */
  addClassToStack: StackResponse;
  /** Add a high five to an activity feed event */
  addHighFive: ActivityFeedEventResponse;
  /** Add a tag to the current logged in user. */
  addTagToUser: AddTagToUserResponse;
  /** Allows the invite host to cancel the invite */
  cancelInvite: YourScheduleListResponse;
  /** Complete an Odyssey workout */
  completeOdysseyWorkout: CompleteOdysseyWorkoutResponse;
  /**
   * Create a public share link for the invite, making the invite URL accessible to
   * all users, including those who are not invited
   */
  createInviteShareLink: InviteShareUrlResponse;
  /** Create a new invite from a live/encore class */
  createLiveInvite: InviteResponse;
  /** Create a new invite from a scheduled class */
  createNewOnDemandInvite: InviteResponse;
  /** Create a new Odyssey workout */
  createOdysseyWorkout: CreateOdysseyWorkoutResponse;
  /** Create a new invite from an already scheduled on-demand class */
  createScheduledOnDemandInvite: InviteResponse;
  /** Invites users to an already existing invite */
  inviteUsersToInvite: InviteResponse;
  joinProgram: ProgramProgressResponse;
  leaveProgram: ProgramProgressResponse;
  /**
   * Modify whole UserStack for the current logged in user. Used when removing a
   * single class and saves current order of all remaining classes, and used when
   * saving all changes to the UserStack
   */
  modifyStack: StackResponse;
  pauseProgram: ProgramProgressResponse;
  /** Pause workout for the currently logged in user. Only valid for on-demand non-digital workouts */
  pauseWorkout: Scalars['Int'];
  /** Remove first class in UserStack for the current logged in user */
  playClassFromStack: StackResponse;
  programWeekSeen: ProgramProgressResponse;
  /** removes class from the user's schedule (i.e. counts out of the class) */
  removeClassFromSchedule: ScheduledClassResponse;
  /** Remove a high five from an activity feed event */
  removeHighFive: ActivityFeedEventResponse;
  /** Remove a tag from the current logged in user. */
  removeTagFromUser: RemoveTagFromUserResponse;
  /** Replace the logged in user's tag with new tag. */
  replaceTag: ReplaceTagResponse;
  /** removes and re-adds class at a new time to the user's schedule */
  rescheduleClass: ScheduledClassResponse;
  /** Respond to an invite */
  respondToInvite: InviteResponse;
  setLastTagSearch: Scalars['Boolean'];
  setModifyStacksView: Scalars['Boolean'];
  setSelectedTagName: Scalars['Boolean'];
  /** Set a tag as current logged in user's primary tag. */
  setTagAsPrimary: SetTagAsPrimaryResponse;
  setTransitionScreen: Scalars['Boolean'];
  skipProgramClass: ProgramProgressResponse;
  startFreestyleWorkout: StartFreestyleWorkoutResponse;
  /** Starts a new Gym Workout */
  startGymWorkout: StartGymWorkoutResponse;
  startPelotonClass: JoinableClass;
  startProgramClass: ProgramProgressResponse;
  unpauseProgram: ProgramProgressResponse;
  /** Unpause workout for the currently logged in user. Only valid for on-demand non-digital workouts */
  unpauseWorkout: UnpauseWorkoutResponse;
  updateRelationship: UpdateRelationshipResponse;
};

export type MutationAbandonOdysseyWorkoutArgs = {
  id: Scalars['ID'];
};

export type MutationAddClassToScheduleArgs = {
  scheduledClassInput: ScheduledClassInput;
};

export type MutationAddClassToStackArgs = {
  input: AddClassToStackInput;
};

export type MutationAddHighFiveArgs = {
  createdAtForEvent: Scalars['DateTime'];
  userIdForEvent: Scalars['ID'];
  userIdForHighFive: Scalars['ID'];
};

export type MutationAddTagToUserArgs = {
  input: AddTagToUserInput;
};

export type MutationCancelInviteArgs = {
  inviteId: Scalars['ID'];
};

export type MutationCompleteOdysseyWorkoutArgs = {
  input: CompleteOdysseyWorkoutInput;
};

export type MutationCreateInviteShareLinkArgs = {
  inviteId: Scalars['ID'];
};

export type MutationCreateLiveInviteArgs = {
  createLiveInviteInput: CreateLiveInviteInput;
};

export type MutationCreateNewOnDemandInviteArgs = {
  createNewOnDemandInviteInput: CreateNewOnDemandInviteInput;
};

export type MutationCreateOdysseyWorkoutArgs = {
  input: CreateOdysseyWorkoutInput;
};

export type MutationCreateScheduledOnDemandInviteArgs = {
  createScheduledOnDemandInviteInput: CreateScheduledOnDemandInviteInput;
};

export type MutationInviteUsersToInviteArgs = {
  inviteUsersToInviteInput: InviteUsersToInviteInput;
};

export type MutationJoinProgramArgs = {
  programId: Scalars['String'];
};

export type MutationLeaveProgramArgs = {
  programProgressId: Scalars['String'];
};

export type MutationModifyStackArgs = {
  input: ModifyStackInput;
};

export type MutationPauseProgramArgs = {
  programProgressId: Scalars['String'];
};

export type MutationPauseWorkoutArgs = {
  input: PauseWorkoutInput;
};

export type MutationPlayClassFromStackArgs = {
  input: PlayClassFromStackInput;
};

export type MutationProgramWeekSeenArgs = {
  programProgressId: Scalars['String'];
  programWeekId: Scalars['String'];
};

export type MutationRemoveClassFromScheduleArgs = {
  scheduledClassId: Scalars['String'];
};

export type MutationRemoveHighFiveArgs = {
  createdAtForEvent: Scalars['DateTime'];
  userIdForEvent: Scalars['ID'];
  userIdForHighFive: Scalars['ID'];
};

export type MutationRemoveTagFromUserArgs = {
  input: RemoveTagFromUserInput;
};

export type MutationReplaceTagArgs = {
  input: ReplaceTagInput;
};

export type MutationRescheduleClassArgs = {
  rescheduleClassInput: RescheduleClassInput;
};

export type MutationRespondToInviteArgs = {
  inviteId: Scalars['ID'];
  status: InvitedUserStatus;
  username?: Maybe<Scalars['String']>;
};

export type MutationSetLastTagSearchArgs = {
  searchTerm: Scalars['String'];
};

export type MutationSetModifyStacksViewArgs = {
  isModify: Scalars['Boolean'];
};

export type MutationSetSelectedTagNameArgs = {
  input: AddTagToUserInput;
};

export type MutationSetTagAsPrimaryArgs = {
  input: SetTagAsPrimaryInput;
};

export type MutationSetTransitionScreenArgs = {
  isTransition: Scalars['Boolean'];
};

export type MutationSkipProgramClassArgs = {
  programClassId: Scalars['String'];
  programProgressId: Scalars['String'];
};

export type MutationStartFreestyleWorkoutArgs = {
  input: StartFreestyleWorkoutInput;
};

export type MutationStartGymWorkoutArgs = {
  input: StartGymWorkoutInput;
};

export type MutationStartPelotonClassArgs = {
  input: StartClassInput;
};

export type MutationStartProgramClassArgs = {
  programClassId: Scalars['String'];
  programProgressId: Scalars['String'];
  workoutId: Scalars['String'];
};

export type MutationUnpauseProgramArgs = {
  programProgressId: Scalars['String'];
};

export type MutationUnpauseWorkoutArgs = {
  input: UnpauseWorkoutInput;
};

export type MutationUpdateRelationshipArgs = {
  input: UpdateRelationshipRequest;
};

/**
 * Error thrown when attempting to get Peloton data for a PelotonClass
 * that is not available for the current platform.
 */
export type NoClassPelotonForPlatformError = Error & {
  __typename: 'NoClassPelotonForPlatformError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Notification = {
  __typename: 'Notification';
  /** Activity feed event associated with the notification */
  activityFeedEvent: ActivityFeedEvent;
  /** Timestamp of when an external user high fived the activity feed event */
  highFivedAt: Scalars['DateTime'];
  /** Boolean to check if a notification has been seen by the user */
  isSeen: Scalars['Boolean'];
  /** Federated object of the external user that the notification is associated with */
  user: User;
};

export type NotificationDetails = {
  __typename: 'NotificationDetails';
  /** List of notifications for a user */
  notifications: Array<Notification>;
};

export type NotificationDetailsResponse = ActivityFeedErrorResponse | NotificationDetails;

/** Optional reason for scheduling an event */
export type Occasion = {
  __typename: 'Occasion';
  /** Occasion image icon assets */
  assets: OccasionAssets;
  /** Fitness discipline of the invite's class */
  fitnessDiscipline: Scalars['String'];
  /**
   * S3 url of the occasion's icon
   * @deprecated Use assets instead
   */
  imageUrl: Scalars['String'];
  /** Slug of the occasion name */
  occasionSlug: Scalars['String'];
  /** Used to order the occasions in the UI */
  rank: Scalars['Int'];
  /** Translated string of occasion name */
  translatedOccasionName: Scalars['String'];
};

export type OccasionAssets = {
  __typename: 'OccasionAssets';
  /** large Occasion image icon url */
  largeImage: Media;
  /** small Occasion image icon url */
  smallImage: Media;
};

export type OccasionsList = {
  __typename: 'OccasionsList';
  /** List of Occasions */
  occasions: Array<Occasion>;
};

export type OccasionsResponse = OccasionsList;

export type OdysseyAccordionLevelFilter = {
  __typename: 'OdysseyAccordionLevelFilter';
  icon: OdysseyIcon;
  id: Scalars['String'];
  numColumns: Scalars['Int'];
  options: Array<OdysseyLevelFilterOption>;
  selectMultiple: Scalars['Boolean'];
  title: Scalars['String'];
};

export type OdysseyAccordionLevelFilterInfo = {
  __typename: 'OdysseyAccordionLevelFilterInfo';
  icon: OdysseyIcon;
  id: Scalars['String'];
  numColumns: Scalars['Int'];
  selectMultiple: Scalars['Boolean'];
  title: Scalars['String'];
};

/** Error thrown when request inputs are invalid */
export type OdysseyBadRequestError = OdysseyError & {
  __typename: 'OdysseyBadRequestError';
  message: Scalars['String'];
};

export type OdysseyBasicLevelFilterOption = {
  __typename: 'OdysseyBasicLevelFilterOption';
  listOrder: Scalars['Int'];
  title: Scalars['String'];
  value: Scalars['String'];
};

export type OdysseyBikeCompletionInput = {
  beatCompletionPercent: Scalars['Float'];
  breakerCompletionPercent: Scalars['Float'];
  streamCompletionPercent: Scalars['Float'];
};

export type OdysseyBikeConfiguration = {
  __typename: 'OdysseyBikeConfiguration';
  difficultyConfigs: Array<OdysseyBikeDifficultyConfiguration>;
  scoringConfig: OdysseyBikeScoringConfiguration;
};

export type OdysseyBikeDifficultyConfiguration = {
  __typename: 'OdysseyBikeDifficultyConfiguration';
  displayName: Scalars['String'];
  laneConfigs: Array<OdysseyBikeLaneConfiguration>;
  slug: OdysseyDifficulty;
};

export type OdysseyBikeLaneConfiguration = {
  __typename: 'OdysseyBikeLaneConfiguration';
  breakerAverageOutput: Scalars['Int'];
  index: Scalars['Int'];
  maxResistance: Scalars['Int'];
  minResistance: Scalars['Int'];
};

export type OdysseyBikeScoringConfiguration = {
  __typename: 'OdysseyBikeScoringConfiguration';
  starThresholds: Array<OdysseyStarThreshold>;
};

export type OdysseyConfigurationResponse =
  | OdysseyBikeConfiguration
  | OdysseyItemNotFoundError
  | OdysseyTreadConfiguration;

export enum OdysseyDifficulty {
  ADVANCED = 'ADVANCED',
  Advanced = 'Advanced',
  BEGINNER = 'BEGINNER',
  Beginner = 'Beginner',
  EXPERT = 'EXPERT',
  Expert = 'Expert',
  INTERMEDIATE = 'INTERMEDIATE',
  Intermediate = 'Intermediate',
  NORMAL = 'NORMAL',
  Normal = 'Normal',
  advanced = 'advanced',
  beginner = 'beginner',
  expert = 'expert',
  intermediate = 'intermediate',
  normal = 'normal',
}

/** Type for Odyssey level difficulty metadata */
export type OdysseyDifficultyMetadata = {
  __typename: 'OdysseyDifficultyMetadata';
  displayName: Scalars['String'];
  slug: OdysseyDifficulty;
};

export type OdysseyDurationLevelFilterOption = {
  __typename: 'OdysseyDurationLevelFilterOption';
  listOrder: Scalars['Int'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
};

/** Generic error type structure */
export type OdysseyError = {
  message: Scalars['String'];
};

export type OdysseyFeedWorkout = FeedWorkout & {
  __typename: 'OdysseyFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']>;
  completedAt: Scalars['Int'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']>;
  odysseyImageUrl: Scalars['String'];
  odysseySubtitle: Scalars['String'];
  /** odyssey workout information */
  odysseyWorkoutTitle: Scalars['String'];
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']>;
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID'];
  /** platform of the odyssey feed workout */
  platform?: Maybe<Scalars['String']>;
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']>;
};

export type OdysseyGridLevelFilter = {
  __typename: 'OdysseyGridLevelFilter';
  id: Scalars['String'];
  numColumns: Scalars['Int'];
  options: Array<OdysseyLevelFilterOption>;
  selectMultiple: Scalars['Boolean'];
};

export type OdysseyGridLevelFilterInfo = {
  __typename: 'OdysseyGridLevelFilterInfo';
  id: Scalars['String'];
  numColumns: Scalars['Int'];
  selectMultiple: Scalars['Boolean'];
};

export type OdysseyIcon = {
  __typename: 'OdysseyIcon';
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Error thrown when attempting an invalid mutation on an Odyssey Workout */
export type OdysseyInvalidWorkoutMutationError = OdysseyError & {
  __typename: 'OdysseyInvalidWorkoutMutationError';
  message: Scalars['String'];
  workoutId: Scalars['String'];
};

/** Error thrown when querying or mutating a model that doesn't exist */
export type OdysseyItemNotFoundError = OdysseyError & {
  __typename: 'OdysseyItemNotFoundError';
  entityId: Scalars['String'];
  message: Scalars['String'];
};

/** Information needed to render a leaderboard */
export type OdysseyLeaderboard = {
  __typename: 'OdysseyLeaderboard';
  canFetchNextPage: Scalars['Boolean'];
  count: Scalars['Int'];
  extraWorkoutEntry?: Maybe<LeaderboardEntry>;
  leaderboard: Array<LeaderboardEntry>;
  startRank: Scalars['Int'];
};

/** Generic error type structure */
export type OdysseyLeaderboardGraphqlError = {
  message: Scalars['String'];
};

/** Error thrown when an invalid request is received */
export type OdysseyLeaderboardInvalidRequest = OdysseyLeaderboardGraphqlError & {
  __typename: 'OdysseyLeaderboardInvalidRequest';
  message: Scalars['String'];
};

/** Successful response from service after querying for leaderboard */
export type OdysseyLeaderboardListSuccessResponse = {
  __typename: 'OdysseyLeaderboardListSuccessResponse';
  leaderboard: OdysseyLeaderboard;
};

export type OdysseyLeaderboardQueryResponse =
  | OdysseyLeaderboardInvalidRequest
  | OdysseyLeaderboardListSuccessResponse;

export enum OdysseyLeaderboardSortBy {
  Date = 'Date',
  Score = 'Score',
}

/** Odyssey level assets */
export type OdysseyLevelAssets = {
  __typename: 'OdysseyLevelAssets';
  detailImageUrl?: Maybe<Scalars['String']>;
  thumbnailImageUrl?: Maybe<Scalars['String']>;
  unityBundleUrl?: Maybe<Scalars['String']>;
};

export type OdysseyLevelBrowseFilter = {
  __typename: 'OdysseyLevelBrowseFilter';
  info: OdysseyLevelFilterInfo;
  options: Array<OdysseyLevelFilterOption>;
};

/** Type for level specific difficulty details */
export type OdysseyLevelDifficultyDetail = {
  __typename: 'OdysseyLevelDifficultyDetail';
  midiFileUrl: Scalars['String'];
  slug: OdysseyDifficulty;
};

export type OdysseyLevelFilter =
  | OdysseyAccordionLevelFilter
  | OdysseyGridLevelFilter
  | OdysseyToggleLevelFilter;

export type OdysseyLevelFilterInfo =
  | OdysseyAccordionLevelFilterInfo
  | OdysseyGridLevelFilterInfo
  | OdysseyToggleLevelFilterInfo;

export type OdysseyLevelFilterOption =
  | OdysseyBasicLevelFilterOption
  | OdysseyDurationLevelFilterOption
  | OdysseyPlayedLevelFilterOption
  | OdysseyStarsLevelFilterOption;

export type OdysseyNotAuthorizedError = OdysseyError & {
  __typename: 'OdysseyNotAuthorizedError';
  message: Scalars['String'];
};

export type OdysseyPlayedLevelFilterOption = {
  __typename: 'OdysseyPlayedLevelFilterOption';
  icon: OdysseyIcon;
  listOrder: Scalars['Int'];
  title: Scalars['String'];
  value: Scalars['String'];
};

export type OdysseyStarsLevelFilterOption = {
  __typename: 'OdysseyStarsLevelFilterOption';
  icon: OdysseyIcon;
  listOrder: Scalars['Int'];
  title: Scalars['String'];
  value: Scalars['String'];
};

export type OdysseyStarThreshold = {
  __typename: 'OdysseyStarThreshold';
  completionPercent: Scalars['Float'];
  starsEarned: Scalars['Float'];
};

export type OdysseyToggleLevelFilter = {
  __typename: 'OdysseyToggleLevelFilter';
  defaultValue: Scalars['Boolean'];
  icon: OdysseyIcon;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type OdysseyToggleLevelFilterInfo = {
  __typename: 'OdysseyToggleLevelFilterInfo';
  defaultValue: Scalars['Boolean'];
  icon: OdysseyIcon;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type OdysseyTreadCompletionInput = {
  beatCompletionPercent: Scalars['Float'];
  blastCompletionPercent: Scalars['Float'];
  hillCompletionPercent: Scalars['Float'];
};

export type OdysseyTreadConfiguration = {
  __typename: 'OdysseyTreadConfiguration';
  difficultyConfigs: Array<OdysseyTreadDifficultyConfiguration>;
  scoringConfig: OdysseyTreadScoringConfiguration;
};

export type OdysseyTreadDifficultyConfiguration = {
  __typename: 'OdysseyTreadDifficultyConfiguration';
  displayName: Scalars['String'];
  intensities: Array<OdysseyTreadDifficultyIntensity>;
  laneConfigs: Array<OdysseyTreadLaneConfiguration>;
  slug: OdysseyDifficulty;
  /** @deprecated Use intensities instead */
  speedModifier: Scalars['Float'];
};

export type OdysseyTreadDifficultyIntensity = {
  __typename: 'OdysseyTreadDifficultyIntensity';
  /** This will correspond to the midi note velocity */
  key: Scalars['Int'];
  maxSpeed: Scalars['Float'];
  minSpeed: Scalars['Float'];
};

export type OdysseyTreadLaneConfiguration = {
  __typename: 'OdysseyTreadLaneConfiguration';
  index: Scalars['Int'];
  maxIncline: Scalars['Float'];
  minIncline: Scalars['Float'];
};

export type OdysseyTreadScoringConfiguration = {
  __typename: 'OdysseyTreadScoringConfiguration';
  starThresholds: Array<OdysseyStarThreshold>;
};

/** Type for a workout taken from Odyssey */
export type OdysseyWorkout = {
  __typename: 'OdysseyWorkout';
  /** 0 to 1 */
  beatCompletionPercent?: Maybe<Scalars['Float']>;
  /** 0 to 1 */
  blastCompletionPercent?: Maybe<Scalars['Float']>;
  /** 0 to 1 */
  breakerCompletionPercent?: Maybe<Scalars['Float']>;
  /** 0 to 1 */
  completionPercent?: Maybe<Scalars['Float']>;
  difficulty: OdysseyDifficulty;
  /** 0 to 1 */
  hillCompletionPercent?: Maybe<Scalars['Float']>;
  /** Hyphen-less id format to allow references back to Workouts in the monolith */
  id: Scalars['ID'];
  score?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  /** 0 to 1 */
  streamCompletionPercent?: Maybe<Scalars['Float']>;
};

export type OdysseyWorkoutQueryResponse =
  | OdysseyItemNotFoundError
  | OdysseyNotAuthorizedError
  | OdysseyWorkoutSuccessResponse;

/** Successful response from the service after querying or mutating an odyssey workout */
export type OdysseyWorkoutSuccessResponse = {
  __typename: 'OdysseyWorkoutSuccessResponse';
  workout: OdysseyWorkout;
};

export type Offset = {
  __typename: 'Offset';
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export type OffsetsCue = {
  __typename: 'OffsetsCue';
  start: Scalars['Int'];
  end: Scalars['Int'];
};

export type OnDemandInstructorClass = PelotonClass & {
  __typename: 'OnDemandInstructorClass';
  id: Scalars['ID'];
  title: Scalars['String'];
  classId: Scalars['String'];
  originLocale: Locale;
  fitnessDiscipline: FitnessDiscipline;
  joinToken: Scalars['JoinToken'];
  assets: ClassAssets;
  playableOnPlatform: Scalars['Boolean'];
  duration: Scalars['Int'];
  timeline?: Maybe<ClassTimeline>;
  classTypes: Array<ClassType>;
  description: Scalars['String'];
  instructor: Instructor;
  airTime: Scalars['DateTime'];
  /** @deprecated use airTime instead of originalAirTime */
  originalAirTime: Scalars['DateTime'];
  isBookmarked: Scalars['Boolean'];
  difficultyLevel?: Maybe<DifficultyLevel>;
  /** whether the user is video or audio-only */
  contentFormat: ContentFormat;
  /** total count of number of workouts for this class */
  totalUserWorkouts?: Maybe<Scalars['Int']>;
  equipment: Array<Equipment>;
  playlist?: Maybe<Playlist>;
  instructorCues?: Maybe<Array<InstructorCue>>;
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  targetMetricsData?: Maybe<TargetMetricsData>;
  captions?: Maybe<Captions>;
  isFtpTest: Scalars['Boolean'];
  isExplicit: Scalars['Boolean'];
  /** explicitness of a class */
  explicitRating: Scalars['Int'];
  metrics: Array<MetricName>;
  shopTheClass: Scalars['Boolean'];
  classPreviewVideo?: Maybe<Scalars['String']>;
  contentAvailability?: Maybe<Scalars['String']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']>;
  isLimitedRide?: Maybe<Scalars['Boolean']>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']>;
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']>>;
  flags?: Maybe<Array<Scalars['String']>>;
  /**
   * Top tags for this joinToken. Get $limit tags randomly from the $sampledFrom top Tags.
   * When sampledFrom = 0, simply return top $limit tags.
   * @deprecated Not implemented yet.
   */
  topTags: Array<ClassTopTag>;
};

export type OnDemandInstructorClassTopTagsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sampledFrom?: Maybe<Scalars['Int']>;
};

export type PaceIntensity = {
  __typename: 'PaceIntensity';
  value?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  paceLevels?: Maybe<Array<Maybe<PaceLevel>>>;
};

export type PaceLevel = {
  __typename: 'PaceLevel';
  slug?: Maybe<Scalars['String']>;
  fastPace?: Maybe<Scalars['Float']>;
  slowPace?: Maybe<Scalars['Float']>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PaginatedList = {
  pageInfo: PageInfo;
};

export type PauseInfo = {
  __typename: 'PauseInfo';
  isPaused: Scalars['Boolean'];
  /** Pause time elapsed (in seconds) */
  pauseTimeElapsed: Scalars['Int'];
  /** Pause time limit (in seconds) */
  pauseTimeLimit: Scalars['Int'];
};

export type PauseWorkoutInput = {
  /** ISO-8601 timestamp */
  pauseStartTime?: Maybe<Scalars['DateTime']>;
  workoutId: Scalars['String'];
};

export type Payload =
  | AchievementAwardedEventPayload
  | StreakAwardedEventPayload
  | WorkoutFinalizedEventPayload;

export type Peloton = {
  __typename: 'Peloton';
  pelotonId: Scalars['String'];
};

export type PelotonClass = {
  /** joinToken identifier for peloton class */
  id: Scalars['ID'];
  title: Scalars['String'];
  classId: Scalars['String'];
  /** locale language in which class was originally filmed */
  originLocale: Locale;
  fitnessDiscipline: FitnessDiscipline;
  joinToken: Scalars['JoinToken'];
  /** visual media assets for peloton class */
  assets: ClassAssets;
  /**
   * whether this class is available for the clients membership on the requesting
   * platform. The use of this field in connection to audio only formats is deprecated.
   */
  playableOnPlatform: Scalars['Boolean'];
  /** length of class video in seconds */
  duration: Scalars['Int'];
  /** in-class segments timeline */
  timeline?: Maybe<ClassTimeline>;
  classTypes: Array<ClassType>;
  description: Scalars['String'];
  /** A list of metrics that we track during a workout and devices can include in packets they post for the workout */
  metrics: Array<MetricName>;
  shopTheClass: Scalars['Boolean'];
  classPreviewVideo?: Maybe<Scalars['String']>;
  contentAvailability?: Maybe<Scalars['String']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']>;
  isLimitedRide?: Maybe<Scalars['Boolean']>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']>;
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']>>;
  flags?: Maybe<Array<Scalars['String']>>;
};

export type PelotonClassFeedWorkout = FeedWorkout & {
  __typename: 'PelotonClassFeedWorkout';
  /** average heart rate during workout */
  avgHeartRate?: Maybe<Scalars['Float']>;
  /** pace of the workout */
  avgPace?: Maybe<Scalars['Float']>;
  /** average speed during workout */
  avgSpeed?: Maybe<Scalars['Float']>;
  /** split pace of the workout (aka time per 500m) */
  avgSplitPace?: Maybe<Scalars['Float']>;
  /** stroke rate of the workout (aka spm) */
  avgStrokeRate?: Maybe<Scalars['Float']>;
  /** calories burnt during the workout */
  calories?: Maybe<Scalars['Float']>;
  completedAt: Scalars['Int'];
  /** Total distance travelled in the Workout referenced */
  distance?: Maybe<Scalars['Float']>;
  /** duration of workout in seconds */
  duration?: Maybe<Scalars['Int']>;
  fitnessDiscipline?: Maybe<Scalars['String']>;
  isOutdoor?: Maybe<Scalars['Boolean']>;
  /** Total output in the Workout referenced */
  output?: Maybe<Scalars['Float']>;
  pelotonClassId: Scalars['String'];
  /** ID of the Workout referenced */
  pelotonWorkoutID: Scalars['ID'];
  /** Platform of the FeedWorkout */
  platform?: Maybe<Scalars['String']>;
  /** strive score (aka hr_total_points) */
  striveScore?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z1_duration) */
  zone1Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z2_duration) */
  zone2Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z3_duration) */
  zone3Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z4_duration) */
  zone4Score?: Maybe<Scalars['Float']>;
  /** strive score (aka heart_rate_z5_duration) */
  zone5Score?: Maybe<Scalars['Float']>;
  pelotonClass?: Maybe<PelotonClass>;
};

/**
 * Error thrown when attempting to get a SessionClass when a Peloton
 * does not exist for that class.
 */
export type PelotonNotCreatedError = Error & {
  __typename: 'PelotonNotCreatedError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type PelotonResult =
  | Peloton
  | NoClassPelotonForPlatformError
  | PelotonNotCreatedError;

export type PendingInvites = {
  __typename: 'PendingInvites';
  countOfPendingInvites: Scalars['Int'];
  pendingInvitesList: Array<InviteDetails>;
};

/** Returns PendingInvites or Error */
export type PendingInvitesResponse = InviteFriendsItemNotFoundError | PendingInvites;

export type PermissionError = Error & {
  __typename: 'PermissionError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type PlayClassFromStackInput = {
  pelotonClassId: Scalars['ID'];
};

export type Playlist = BasePlaylist & {
  __typename: 'Playlist';
  id: Scalars['ID'];
  /** @deprecated Use playbackSongs instead of songs */
  songs: Array<Song>;
  isInClassMusicShown: Scalars['Boolean'];
  playbackSongs: Array<PlaylistPlaybackSong>;
  topArtists: Array<Artist>;
};

export type PlaylistMusicProvider = {
  __typename: 'PlaylistMusicProvider';
  playlist: StreamablePlaylist;
  isFeatured: Scalars['Boolean'];
};

export type PlaylistPlaybackSong = {
  __typename: 'PlaylistPlaybackSong';
  index: Scalars['Int'];
  streamOffset: Scalars['Int'];
  cueOffset: Scalars['Int'];
  song: Song;
};

/** A class that users from a tag have taken. */
export type PopularTagClass = {
  __typename: 'PopularTagClass';
  joinToken: Scalars['JoinToken'];
  /** Count of the users in the group who have taken this class */
  countUsers: Scalars['Int'];
  /** pelotonClass can be null if class removed from onDemand after PopularTagClasses was calculated */
  pelotonClass?: Maybe<PelotonClass>;
};

/** A list of PopularTagClass results. */
export type PopularTagClassesList = {
  __typename: 'PopularTagClassesList';
  popularClasses: Array<PopularTagClass>;
  count: Scalars['Int'];
};

export type Program = {
  __typename: 'Program';
  achievements: Array<ProgramAchievementTier>;
  assets: ProgramAssets;
  classes: Array<ProgramClass>;
  contentAvailability?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  equipment: Array<Equipment>;
  fitnessDisciplines: Array<ProgramFitnessDiscipline>;
  flags: Array<Scalars['String']>;
  id: Scalars['ID'];
  instructors: Array<Instructor>;
  isLimitedContent?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  programClassesLocale: Locale;
  programMetaData: ProgramMetaData;
  programSchedule: ProgramSchedule;
};

export type ProgramAchievementAssets = {
  __typename: 'ProgramAchievementAssets';
  badgeImage: Media;
  color: Color;
};

export type ProgramAchievementTier = {
  __typename: 'ProgramAchievementTier';
  assets: ProgramAchievementAssets;
  badgeDescription: Scalars['String'];
  badgeName: Scalars['String'];
  id: Scalars['ID'];
  numClassesRequired: Scalars['Int'];
  tierName: Scalars['String'];
};

export type ProgramAssets = {
  __typename: 'ProgramAssets';
  heroImage: HeroImageAssets;
  multipleInstructorOnboardingImage?: Maybe<Media>;
  thumbnailImage: Media;
};

export type ProgramClass = {
  __typename: 'ProgramClass';
  classNumber: Scalars['Int'];
  classProgress?: Maybe<ProgramClassProgress>;
  id: Scalars['ID'];
  pelotonClassId: Scalars['ID'];
  pelotonClass: PelotonClass;
};

export type ProgramClassClassProgressArgs = {
  currentDate?: Maybe<Scalars['DateTime']>;
  programProgressId: Scalars['String'];
};

export type ProgramClassProgress = {
  __typename: 'ProgramClassProgress';
  classNumber: Scalars['Int'];
  programClass: ProgramClass;
  status: ProgramClassProgressStatus;
};

export enum ProgramClassProgressStatus {
  NotTaken = 'NOT_TAKEN',
  Skipped = 'SKIPPED',
  Taken = 'TAKEN',
}

export type ProgramDay = {
  __typename: 'ProgramDay';
  classes: Array<ProgramClass>;
  dayNumber: Scalars['Int'];
};

export type ProgramFiltersInput = {
  isMfbt?: Maybe<Scalars['Boolean']>;
};

export type ProgramFitnessDiscipline = {
  __typename: 'ProgramFitnessDiscipline';
  slug: Scalars['String'];
};

export type ProgramMetaData = {
  __typename: 'ProgramMetaData';
  daysPerWeek: Scalars['Int'];
  duration: Scalars['Int'];
  highMinsPerDay: Scalars['Int'];
  lowMinsPerDay: Scalars['Int'];
  totalNumClasses: Scalars['Int'];
};

export type ProgramParamsInput = {
  filters?: Maybe<ProgramFiltersInput>;
  sorting?: Maybe<ProgramSortingInput>;
};

export type ProgramProgress = {
  __typename: 'ProgramProgress';
  awardedAchievement: AwardedProgramAchievementTier;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  pauseDate?: Maybe<Scalars['DateTime']>;
  program: Program;
  startDate: Scalars['DateTime'];
  weeklyProgress: Array<ProgramWeekProgress>;
};

/** Error thrown when active program progress for program and user already exists */
export type ProgramProgressAlreadyExistsError = {
  __typename: 'ProgramProgressAlreadyExistsError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Error thrown when no program progress with a given id exists */
export type ProgramProgressNotFoundError = {
  __typename: 'ProgramProgressNotFoundError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Returns program progress for user */
export type ProgramProgressResponse =
  | ProgramProgressAlreadyExistsError
  | ProgramProgressNotFoundError
  | ProgramProgressResponseSuccess
  | UserNotAuthorizedError;

/** Successful program progress response from the service */
export type ProgramProgressResponseSuccess = {
  __typename: 'ProgramProgressResponseSuccess';
  programProgress?: Maybe<ProgramProgress>;
};

export type ProgramSchedule = {
  __typename: 'ProgramSchedule';
  weeks: Array<ProgramWeek>;
};

export type ProgramSortingInput = {
  notCompleted?: Maybe<Scalars['Boolean']>;
};

export type ProgramWeek = {
  __typename: 'ProgramWeek';
  days: Array<ProgramDay>;
  description: Scalars['String'];
  id: Scalars['ID'];
  weekNumber: Scalars['Int'];
  weekProgress?: Maybe<ProgramWeekProgress>;
};

export type ProgramWeekWeekProgressArgs = {
  currentDate?: Maybe<Scalars['DateTime']>;
  programProgressId: Scalars['String'];
};

export type ProgramWeekProgress = {
  __typename: 'ProgramWeekProgress';
  classesProgress: Array<Maybe<ProgramClassProgress>>;
  endDate: Scalars['DateTime'];
  seen: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  status: ProgramWeekProgressStatus;
  weekNumber: Scalars['Int'];
};

export enum ProgramWeekProgressStatus {
  Current = 'CURRENT',
  Future = 'FUTURE',
  Previous = 'PREVIOUS',
}

export type Query = {
  __typename: 'Query';
  /** Returns true if class is already in currently logged in user's stack, false otherwise */
  checkDoesClassExistInUserStack: Scalars['Boolean'];
  completedPrograms: Array<ProgramProgress>;
  /** Get the current user's tag list */
  currentUserTags: UserTags;
  /** Get entertainment channels */
  entertainmentChannels: EntertainmentChannelsQueryResponse;
  filterPrograms: Array<Program>;
  /** get metadata for a freestyle experience by platform */
  freestyleMetadata: FreestyleMetadata;
  /** Get the details for an activity feed event. Can only fetch logged in user's events. */
  getEventDetails: EventDetailsResponse;
  /** Get high five details for an activity feed event */
  getHighFiveDetailsForEvent: HighFiveDetailsResponse;
  /** Get a list response of a user's notification details */
  getNotificationDetails: NotificationDetailsResponse;
  /** Get a count of a user's unseen notifications */
  getNumberOfUnreadNotifications: UnreadNotificationsResponse;
  gymPlan: GymPlanResponse;
  gymPlanFilterChips: Array<GymPlanFilterChip>;
  gymPlans: GymPlanResponse;
  /** View a single Invite's details */
  inviteDetails: YourScheduleItem;
  joinableClass: JoinableClass;
  lastTagSearch: Scalars['String'];
  /** Get a level by id */
  level: LevelQueryResponse;
  /** Get all levels */
  levels: LevelListQueryResponse;
  modifyStacksView: Scalars['Boolean'];
  /** Show options for occasions by language */
  occasions: OccasionsResponse;
  /** Odyssey platform configurations */
  odysseyConfiguration: OdysseyConfigurationResponse;
  /**
   * Get difficulty metadata
   * @deprecated use odysseyConfiguration instead
   */
  odysseyDifficultyMetadata: Array<OdysseyDifficultyMetadata>;
  /** Get a leaderboard populated by the requesting user's friends */
  odysseyFriendsLeaderboard: OdysseyLeaderboardQueryResponse;
  /** Get a leaderboard */
  odysseyLeaderboard: OdysseyLeaderboardQueryResponse;
  /** Get level browse filters */
  odysseyLevelBrowseFilters: LevelFiltersQueryResponse;
  /** A leaderboard made up of a single user's workout */
  odysseyUserLeaderboard: OdysseyLeaderboardQueryResponse;
  /** Get an Odyssey workout by id */
  odysseyWorkout: OdysseyWorkoutQueryResponse;
  /** Get a pelotonClass based from joinToken */
  pelotonClass: PelotonClass;
  program: Program;
  programProgress: ProgramProgress;
  programs: Array<Program>;
  programsById: Array<Program>;
  progressOfPrograms: Array<ProgramProgress>;
  /** get info about a specific scheduled class */
  scheduledClass: ScheduledClassResponse;
  /**
   * Search for tags based on a substring in tag. Results are sorted with
   * an exact match first and then by popularity of the tag.
   */
  searchTag: TagPaginatedList;
  selectedTagName: Scalars['String'];
  /**
   * Gets next available session for a specified PelotonClass identified by ID (aka JoinToken).
   * Returned Session class is nullable since it is possible for a class to be ineligible for a Session
   */
  session?: Maybe<SessionClass>;
  /** get info about a specific streamable playlist */
  streamablePlaylist: StreamablePlaylist;
  /** Get a tag based on its case insensitive name. */
  tag?: Maybe<Tag>;
  /** Get a tag category by its slug. */
  tagBrowseCategory: TagBrowseCategory;
  transitionScreen: Scalars['Boolean'];
  /** Get a user by the id. Default to return the current logged in user. */
  user?: Maybe<User>;
  /** Get a paginated response of a user's activity feed */
  userActivityFeed: ActivityFeedEventResponse;
  userProgramAchievements: Array<AwardedProgramAchievementTier>;
  /** Returns a user's pending invites and list of invites and scheduled classes */
  userScheduledItemsList: YourScheduleListResponse;
  /** Get a UserStack for the current logged in user */
  viewUserStack: StackResponse;
  workoutPostClassDetails: WorkoutPostClassResponse;
  workoutProgramAchievements: Array<AwardedProgramAchievementTier>;
};

export type QueryCheckDoesClassExistInUserStackArgs = {
  pelotonClassId: Scalars['ID'];
};

export type QueryCompletedProgramsArgs = {
  currentDate?: Maybe<Scalars['DateTime']>;
};

export type QueryEntertainmentChannelsArgs = {
  limit?: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
};

export type QueryFilterProgramsArgs = {
  programParams: ProgramParamsInput;
};

export type QueryGetEventDetailsArgs = {
  activityFeedEventId: Scalars['ID'];
};

export type QueryGetHighFiveDetailsForEventArgs = {
  eventCreatedAt: Scalars['DateTime'];
  eventUserId: Scalars['ID'];
};

export type QueryGymPlanArgs = {
  gymPlanId: Scalars['String'];
  userId: Scalars['String'];
};

export type QueryGymPlanFilterChipsArgs = {
  userId: Scalars['String'];
};

export type QueryGymPlansArgs = {
  cursor?: Maybe<Scalars['Cursor']>;
  gymPlanFilter?: Maybe<GymPlanFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type QueryInviteDetailsArgs = {
  inviteId: Scalars['ID'];
};

export type QueryJoinableClassArgs = {
  id: Scalars['ID'];
  streamUrlType?: Maybe<Scalars['String']>;
};

export type QueryLevelArgs = {
  id: Scalars['ID'];
};

export type QueryOccasionsArgs = {
  fitnessDiscipline: Scalars['String'];
};

export type QueryOdysseyFriendsLeaderboardArgs = {
  leaderboardRequest: LeaderboardRequestInput;
};

export type QueryOdysseyLeaderboardArgs = {
  leaderboardRequest: LeaderboardRequestInput;
};

export type QueryOdysseyUserLeaderboardArgs = {
  leaderboardRequest?: Maybe<LeaderboardRequestInput>;
  userLeaderboardRequest?: Maybe<UserLeaderboardRequestInput>;
};

export type QueryOdysseyWorkoutArgs = {
  id: Scalars['ID'];
};

export type QueryPelotonClassArgs = {
  joinToken: Scalars['String'];
};

export type QueryProgramArgs = {
  programId: Scalars['String'];
};

export type QueryProgramProgressArgs = {
  currentDate?: Maybe<Scalars['DateTime']>;
  programProgressId: Scalars['String'];
};

export type QueryProgramsByIdArgs = {
  programIds: Array<Scalars['String']>;
};

export type QueryProgressOfProgramsArgs = {
  currentDate?: Maybe<Scalars['DateTime']>;
};

export type QueryScheduledClassArgs = {
  scheduledClassId: Scalars['String'];
};

export type QuerySearchTagArgs = {
  input: SearchTagInput;
};

export type QuerySessionArgs = {
  sessionInput: SessionInput;
};

export type QueryStreamablePlaylistArgs = {
  playlistId: Scalars['String'];
};

export type QueryTagArgs = {
  tagName: Scalars['String'];
};

export type QueryTagBrowseCategoryArgs = {
  categorySlug: TagBrowseCategorySlug;
};

export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryUserActivityFeedArgs = {
  cursor?: Maybe<Scalars['Cursor']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryUserScheduledItemsListArgs = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type QueryWorkoutPostClassDetailsArgs = {
  isWorkoutFromStack: Scalars['Boolean'];
  workoutId: Scalars['ID'];
};

export type QueryWorkoutProgramAchievementsArgs = {
  workoutId: Scalars['String'];
};

export type RangeCue = {
  __typename: 'RangeCue';
  lower: Scalars['Int'];
  upper: Scalars['Int'];
};

export type ReadyVideoStream = {
  __typename: 'ReadyVideoStream';
  videoStream: VideoStream;
};

/** Input for removeTagFromUser operation. */
export type RemoveTagFromUserInput = {
  /** Case insensitive name of the tag to remove. */
  tagName: Scalars['String'];
};

/** TODO: make sure type name is on mutations before implementation */
export type RemoveTagFromUserResponse = RemoveTagFromUserSuccess | DoesNotExistError;

/** Response for removeTagFromUser operation. */
export type RemoveTagFromUserSuccess = {
  __typename: 'RemoveTagFromUserSuccess';
  /** The tag. */
  tag?: Maybe<Tag>;
};

/** Input for replaceTag operation. */
export type ReplaceTagInput = {
  /** Case insensitive name of the tag to replace. */
  oldTagName: Scalars['String'];
  /** Case insensitive name of the tag to add. */
  newTagName: Scalars['String'];
};

export type ReplaceTagResponse =
  | ReplaceTagSuccess
  | TagAlreadyExistsError
  | DoesNotExistError
  | TagNameInvalidCharacterError
  | TagNameLengthError
  | TagNameProhibitedError;

export type ReplaceTagSuccess = {
  __typename: 'ReplaceTagSuccess';
  /** The newly added tag. */
  tag?: Maybe<Tag>;
};

export type RescheduleClassInput = {
  /** ScheduledClass.id (aka JoinToken) */
  id: Scalars['ID'];
  scheduledStartTime: Scalars['DateTime'];
};

export type RetryableError = {
  retryAfterSeconds?: Maybe<Scalars['Int']>;
  retryMaxDuration: Scalars['Int'];
};

export type ScenicClass = PelotonClass & {
  __typename: 'ScenicClass';
  id: Scalars['ID'];
  title: Scalars['String'];
  classId: Scalars['String'];
  originLocale: Locale;
  fitnessDiscipline: FitnessDiscipline;
  joinToken: Scalars['JoinToken'];
  assets: ClassAssets;
  playableOnPlatform: Scalars['Boolean'];
  duration: Scalars['Int'];
  timeline?: Maybe<ClassTimeline>;
  classTypes: Array<ClassType>;
  description: Scalars['String'];
  metrics: Array<MetricName>;
  instructor?: Maybe<Instructor>;
  isBookmarked: Scalars['Boolean'];
  totalUserWorkouts?: Maybe<Scalars['Int']>;
  videoEmbeddedPlaylist?: Maybe<Playlist>;
  musicProviders: Array<Maybe<MusicProvider>>;
  isDynamicVideoEligible?: Maybe<Scalars['Boolean']>;
  isFixedDistance?: Maybe<Scalars['Boolean']>;
  dynamicVideoRecordedSpeedInMph?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<DistanceUnit>;
  thumbnailTitle?: Maybe<Scalars['String']>;
  thumbnailLocation?: Maybe<Scalars['String']>;
  captions?: Maybe<Captions>;
  shopTheClass: Scalars['Boolean'];
  classPreviewVideo?: Maybe<Scalars['String']>;
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  targetMetricsData?: Maybe<TargetMetricsData>;
  contentAvailability?: Maybe<Scalars['String']>;
  contentAvailabilityLevel?: Maybe<Scalars['String']>;
  isLimitedRide?: Maybe<Scalars['Boolean']>;
  freeForLimitedTime?: Maybe<Scalars['Boolean']>;
  /** @deprecated Used for resolution during migration only. */
  excludedPlatforms?: Maybe<Array<Scalars['String']>>;
  flags?: Maybe<Array<Scalars['String']>>;
  /**
   * Top tags for this joinToken. Get $limit tags randomly from the $sampledFrom top Tags.
   * When sampledFrom = 0, simply return top $limit tags.
   * @deprecated Not implemented yet.
   */
  topTags: Array<ClassTopTag>;
};

export type ScenicClassTopTagsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sampledFrom?: Maybe<Scalars['Int']>;
};

export type ScheduledClass = {
  __typename: 'ScheduledClass';
  /** identifier for the ScheduledClass */
  id: Scalars['ID'];
  /** UUID for the peloton for this scheduled class */
  pelotonId: Scalars['String'];
  /**
   * joinToken which should be used to start the workout for the scheduled class.
   * This is the same as the id for Live and Session classes, but will be different
   * for scheduled on-demand classes which are not sessions eligible
   */
  joinToken: Scalars['JoinToken'];
  /** joinToken for the on-demand version of this class, to be used for taking this class as an on-demand workout */
  onDemandJoinToken?: Maybe<Scalars['JoinToken']>;
  /** scheduled time for the class to begin */
  scheduledStartTime: Scalars['DateTime'];
  /** scheduled time for the class to end */
  scheduledEndTime: Scalars['DateTime'];
  /** The number of all user workouts who took this specific scheduled class */
  totalWorkouts: Scalars['Int'];
  /** query to get all users who have scheduled/counted-in to this class */
  scheduledUsers: UserPaginatedList;
  /** refers to whether current user rsvp-ed */
  isScheduled: Scalars['Boolean'];
  /**
   * refers to whether this scheduled class is an official Peloton live schedule
   * class, a scheduled on-demand class for the user, or a session
   */
  scheduleSource: ScheduleSource;
  pelotonClass: PelotonClass;
  /** total number of users on a scheduled class, corresponds to API total_home_reservations */
  totalUsersCountedIn: Scalars['Int'];
};

export type ScheduledClassScheduledUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Cursor']>;
};

export type ScheduledClassDoesNotExistError = Error & {
  __typename: 'ScheduledClassDoesNotExistError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ScheduledClassesInput = {
  /** earliest scheduled start time to fetch window of scheduled classes */
  startTime: Scalars['DateTime'];
  /** latest scheduled end time to fetch window of scheduled classes */
  endTime: Scalars['DateTime'];
};

export type ScheduledClassInput = {
  /** PelotonClass ID (aka JoinToken) */
  id: Scalars['ID'];
  /**
   * must be specified for on-demand class so we know what time to schedule for since OD class has no inherent start time.
   * Can be null for LiveClass since this is redundant
   */
  scheduledStartTime?: Maybe<Scalars['DateTime']>;
};

export type ScheduledClassList = {
  __typename: 'ScheduledClassList';
  scheduledClasses: Array<ScheduledClass>;
};

export type ScheduledClassListResponse = ScheduledClassList | UserPrivacyError;

export type ScheduledClassResponse =
  | ScheduledClass
  | ScheduledClassDoesNotExistError
  | CannotScheduleClassError;

/** scheduled class source */
export enum ScheduleSource {
  /** scheduled by Peloton content team (i.e. Live and Encore classes) */
  Peloton = 'PELOTON',
  /** scheduled by user */
  User = 'USER',
  /** Session class schedule */
  Session = 'SESSION',
}

/** Input for searchTag query */
export type SearchTagInput = {
  /** Query string to search for. */
  query: Scalars['String'];
  /** Number of results to return */
  limit: Scalars['Int'];
  /** Opaque cursor string for the part of a result set to search after */
  after?: Maybe<Scalars['Cursor']>;
};

export enum SegmentTypes {
  Caesar = 'CAESAR',
  Cycling = 'CYCLING',
  Running = 'RUNNING',
  Floor = 'FLOOR',
  FreeMode = 'FREE_MODE',
  PowerZone = 'POWER_ZONE',
}

export type SessionClass = {
  __typename: 'SessionClass';
  liveClass: LiveClass;
  onDemandJoinToken: Scalars['JoinToken'];
};

export type SessionInput = {
  id: Scalars['ID'];
  requestTime?: Maybe<Scalars['DateTime']>;
};

/** Input for SetTagAsPrimary operation. */
export type SetTagAsPrimaryInput = {
  /** Case insensitive name of the tag to set to be primary. */
  tagName: Scalars['String'];
};

export type SetTagAsPrimaryResponse = SetTagAsPrimarySuccess | TagDoesNotExistOnUserError;

/** Response for SetTagAsPrimary operation. */
export type SetTagAsPrimarySuccess = {
  __typename: 'SetTagAsPrimarySuccess';
  /** The tag. */
  tag?: Maybe<Tag>;
};

export type ShareUrl = {
  __typename: 'ShareURL';
  url: Scalars['String'];
};

export type Song = {
  __typename: 'Song';
  id: Scalars['String'];
  title: Scalars['String'];
  artists: Array<Artist>;
  album: Album;
  explicitRating: Scalars['Int'];
  /** @deprecated Use cueOffset on the PlaylistPlaybackSong */
  cueTimeOffset?: Maybe<Scalars['Int']>;
  /** @deprecated Use streamOffset on the PlaylistPlaybackSong */
  startTimeOffset?: Maybe<Scalars['Int']>;
  liked: Scalars['Boolean'];
};

/** Error thrown when connecting to stacks */
export type StackConnectionError = Error &
  StackResponse & {
    __typename: 'StackConnectionError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/**
 * Error thrown when querying for a stackId that is not associated to the current
 * session's user, userStack will return the userId and an empty stackedClassList
 */
export type StackDoesNotExist = Error &
  StackResponse & {
    __typename: 'StackDoesNotExist';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Type for an individual class that is placed in a UserStack */
export type StackedClass = {
  __typename: 'StackedClass';
  pelotonClassId: Scalars['String'];
  playOrder: Scalars['Int'];
  stackId: Scalars['ID'];
  pelotonClass: PelotonClass;
};

/** Error thrown on adding a class to a stack when that class already exists in the stack */
export type StackedClassAlreadyExistsError = Error &
  StackResponse & {
    __typename: 'StackedClassAlreadyExistsError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Error thrown when querying stacks */
export type StackedClassCannotBeAddedError = Error &
  StackResponse & {
    __typename: 'StackedClassCannotBeAddedError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Error thrown when adding a class to the user's stack violates a database constraint */
export type StackedClassConstraintViolationError = Error &
  StackResponse & {
    __typename: 'StackedClassConstraintViolationError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Error thrown when modifying a stack with a class that does not already exist in the user's stack */
export type StackedClassDoesNotExistInStackError = Error &
  StackResponse & {
    __typename: 'StackedClassDoesNotExistInStackError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Error thrown when attempting to add a class using an invalid pelotonClassId */
export type StackedClassInvalidPelotonClassIdError = Error &
  StackResponse & {
    __typename: 'StackedClassInvalidPelotonClassIdError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Error thrown on adding a class to a stack when the maximum number of classes (10) already exist in the stack */
export type StackedClassLimitReachedError = Error &
  StackResponse & {
    __typename: 'StackedClassLimitReachedError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Error thrown when querying stacks */
export type StackError = Error &
  StackResponse & {
    __typename: 'StackError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Error thrown when querying stacks without permission */
export type StackPermissionError = Error &
  StackResponse & {
    __typename: 'StackPermissionError';
    code: Scalars['String'];
    message: Scalars['String'];
    numClasses: Scalars['Int'];
    totalTime: Scalars['Int'];
    userStack: UserStack;
  };

/** Returns number of classes and the sum of their scheduled duration from the user's stack */
export type StackResponse = {
  numClasses: Scalars['Int'];
  totalTime: Scalars['Int'];
  userStack: UserStack;
};

/**
 * Successful response from the service. When there are no classes in the stack,
 * userStack will return the userId and an empty stackedClassList
 */
export type StackResponseSuccess = StackResponse & {
  __typename: 'StackResponseSuccess';
  numClasses: Scalars['Int'];
  totalTime: Scalars['Int'];
  userStack: UserStack;
};

export type StartClassInput = {
  deviceType: Scalars['String'];
  /** Defaults to false if null */
  isHeartRateMonitorConnected?: Maybe<Scalars['Boolean']>;
  joinToken: Scalars['String'];
  streamUrlType?: Maybe<Scalars['String']>;
  /**
   * Should always be sent if available.
   *         Optional for the case of guest users on activated devices.
   */
  subscriptionId?: Maybe<Scalars['String']>;
  /** Defaults to false if null */
  willManuallyEndWorkout?: Maybe<Scalars['Boolean']>;
};

export type StartFreestyleWorkoutInput = {
  deviceType: Scalars['String'];
  fitnessDiscipline: Scalars['String'];
  /**  Optional for users if they want to set their own goals. */
  goal?: Maybe<FreestyleWorkoutGoalInput>;
  isDigital: Scalars['Boolean'];
  /** Defaults to false if null */
  isHeartRateMonitorConnected?: Maybe<Scalars['Boolean']>;
  isOutdoor: Scalars['Boolean'];
  /**
   * Should always be sent if available.
   *         Optional for the case of guest users on activated devices.
   */
  subscriptionId?: Maybe<Scalars['String']>;
  /** Defaults to false if null */
  willManuallyEndWorkout?: Maybe<Scalars['Boolean']>;
};

/** Returns Freestyle workout for user */
export type StartFreestyleWorkoutResponse =
  | StartFreestyleWorkoutResponseSuccess
  | SubscriptionIdNotFoundError
  | UserNotAuthorizedError;

/** Successful freestyle workout creation response from the service */
export type StartFreestyleWorkoutResponseSuccess = {
  __typename: 'StartFreestyleWorkoutResponseSuccess';
  id: Scalars['ID'];
  startTime: Scalars['DateTime'];
  userId: Scalars['ID'];
};

/** Input for starting a Gym Workout */
export type StartGymWorkoutInput = {
  deviceType: Scalars['String'];
  fitnessDiscipline?: Maybe<Scalars['String']>;
  gymPlanId: Scalars['String'];
  isDigital?: Maybe<Scalars['Boolean']>;
  subscriptionId?: Maybe<Scalars['String']>;
};

/** Response for starting a gym workout */
export type StartGymWorkoutResponse = GymErrorResponse | StartGymWorkoutResponseSuccess;

/** Successful gym workout creation response */
export type StartGymWorkoutResponseSuccess = {
  __typename: 'StartGymWorkoutResponseSuccess';
  startTime: Scalars['DateTime'];
  workout: GymWorkout;
};

export type StreakAwardedEventPayload = ActivityFeedEventPayload & {
  __typename: 'StreakAwardedEventPayload';
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']>;
  /** Streak Number for StreakAwardedEvent */
  streakNumber: Scalars['Int'];
  /** @deprecated Use pelotonClass in PelotonClassFeedWorkout instead */
  pelotonClass?: Maybe<PelotonClass>;
};

export type StreamablePlaylist = BasePlaylist & {
  __typename: 'StreamablePlaylist';
  id: Scalars['ID'];
  isInClassMusicShown: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  artImage?: Maybe<Media>;
  previewArtImage?: Maybe<Media>;
  /** This URL will have an Akamai token appended to it to give the user permission to access the resource */
  stream: Media;
  streamId: Scalars['ID'];
  playbackSongs: Array<PlaylistPlaybackSong>;
  topArtists: Array<Artist>;
};

/** Error thrown when subscription ID does not exist */
export type SubscriptionIdNotFoundError = {
  __typename: 'SubscriptionIdNotFoundError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** A tag that users can add to themselves. */
export type Tag = {
  __typename: 'Tag';
  /** The case sensitive name of the tag. */
  name: Scalars['String'];
  /**
   * A paginated list of users who have this tag. sorted by username. with
   * users followed by the current logged in user first. after is an
   * opaque cursor string referring to the end of the result page.
   */
  users: UserPaginatedList;
  /**
   * The number of users followed by current logged in user who have
   * this tag.
   */
  followingCount: Scalars['Int'];
  /** The meta tag of this tag. */
  metaTag?: Maybe<MetaTag>;
  /** The total number of in progress workout. */
  totalInProgressWorkouts: Scalars['Int'];
  /** The assets that represent this tag. */
  assets: TagAssets;
  /** Whether the current logged in user has added the tag. */
  hasAdded: Scalars['Boolean'];
  /** Whether the current tag already exists. */
  exists: Scalars['Boolean'];
  /**
   * A paginated list of classes that users with this tag are currently taking.
   * sorted by classes with more users working out right now first. after is an
   * opaque cursor string referring to the end of the result page.
   */
  activeClasses: ActiveTagClassesPaginatedList;
  /** A list of classes that have been popular with users in this tag. */
  popularClasses: PopularTagClassesList;
  /**
   * An active class that users with this tag are currently taking with the
   * specified joinToken.
   */
  activeClass: ActiveTagClass;
};

/** A tag that users can add to themselves. */
export type TagUsersArgs = {
  limit?: Scalars['Int'];
  after?: Maybe<Scalars['Cursor']>;
};

/** A tag that users can add to themselves. */
export type TagActiveClassesArgs = {
  limit?: Scalars['Int'];
  after?: Maybe<Scalars['Cursor']>;
};

/** A tag that users can add to themselves. */
export type TagPopularClassesArgs = {
  limit?: Scalars['Int'];
};

/** A tag that users can add to themselves. */
export type TagActiveClassArgs = {
  joinToken: Scalars['JoinToken'];
};

export type TagAlreadyExistsError = Error & {
  __typename: 'TagAlreadyExistsError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Tag's assets */
export type TagAssets = {
  __typename: 'TagAssets';
  /** The row background image that represents this tag. */
  backgroundImage: Media;
  /** The tag details view background image. */
  detailBackgroundImage: Media;
};

/** A grouping of tags, such as the most popular tags. */
export type TagBrowseCategory = {
  __typename: 'TagBrowseCategory';
  /** The unique unchanging human-readable id for this category. */
  slug: TagBrowseCategorySlug;
  /** The name to use when displaying this category. */
  displayName: Scalars['String'];
  /** The assets that represent this category. */
  assets: TagBrowseCategoryAssets;
  /**
   * A paginated list of tags in this category. The results for some categories
   * depend of the current logged in user. after is an opaque cursor string referring
   * to the end of the result page.
   */
  tags: TagPaginatedList;
};

/** A grouping of tags, such as the most popular tags. */
export type TagBrowseCategoryTagsArgs = {
  limit?: Scalars['Int'];
  after?: Maybe<Scalars['Cursor']>;
};

/** TagBrowseCategory's assets */
export type TagBrowseCategoryAssets = {
  __typename: 'TagBrowseCategoryAssets';
  /** The icon that represents this category. */
  icon: Media;
};

/** The possible values for tag browse category slug */
export enum TagBrowseCategorySlug {
  /** slug for Featured */
  Featured = 'FEATURED',
  /** slug for Friends */
  Friends = 'FRIENDS',
  /** slug for Popular */
  Popular = 'POPULAR',
  /** slug for Trending */
  Trending = 'TRENDING',
}

export type TagCapacityOverLimitError = Error & {
  __typename: 'TagCapacityOverLimitError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type TagDoesNotExistOnUserError = Error & {
  __typename: 'TagDoesNotExistOnUserError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** An element in a paginated Tag result set. */
export type TagEdge = {
  __typename: 'TagEdge';
  /** The Tag element. */
  node: Tag;
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor'];
};

export type TagNameInvalidCharacterError = Error & {
  __typename: 'TagNameInvalidCharacterError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type TagNameLengthError = Error & {
  __typename: 'TagNameLengthError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type TagNameProhibitedError = Error & {
  __typename: 'TagNameProhibitedError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** A list of Tag results that can be paged over. */
export type TagPaginatedList = PaginatedList & {
  __typename: 'TagPaginatedList';
  /** The elements of the current page. */
  edges: Array<TagEdge>;
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

export type TargetMetrics = {
  __typename: 'TargetMetrics';
  offsets: Offset;
  segmentType?: Maybe<SegmentTypes>;
  metrics?: Maybe<Array<Metric>>;
};

export type TargetMetricsData = {
  __typename: 'TargetMetricsData';
  targetMetrics?: Maybe<Array<TargetMetrics>>;
  totalExpectedOutput?: Maybe<TargetMetricsOutput>;
  paceIntensities?: Maybe<Array<Maybe<PaceIntensity>>>;
};

export type TargetMetricsOutput = {
  __typename: 'TargetMetricsOutput';
  expectedUpperOutput?: Maybe<Scalars['Int']>;
  expectedLowerOutput?: Maybe<Scalars['Int']>;
};

export type UnpauseWorkoutInput = {
  joinToken?: Maybe<Scalars['String']>;
  /** ISO-8601 timestamp */
  pauseEndTime?: Maybe<Scalars['DateTime']>;
  /** ISO-8601 timestamp */
  pauseStartTime?: Maybe<Scalars['DateTime']>;
  streamUrlType?: Maybe<Scalars['String']>;
  /** Defaults to true if null */
  userManuallyResumed?: Maybe<Scalars['Boolean']>;
  workoutId: Scalars['String'];
};

export type UnpauseWorkoutResponse = {
  __typename: 'UnpauseWorkoutResponse';
  /** Pause duration (in seconds) */
  duration: Scalars['Int'];
  joinableClass?: Maybe<JoinableClass>;
  /** Amount of times paused */
  pauseCount: Scalars['Int'];
  pauseInfo?: Maybe<PauseInfo>;
  /** Pause time remaining (in seconds) */
  pauseTimeRemaining: Scalars['Int'];
};

export type UnreadNotifications = {
  __typename: 'UnreadNotifications';
  /** Number count of unread notifications for a user */
  count: Scalars['Int'];
};

export type UnreadNotificationsResponse = ActivityFeedErrorResponse | UnreadNotifications;

export type UpdateRelationshipRequest = {
  userId: Scalars['ID'];
  action: Scalars['String'];
};

export type UpdateRelationshipResponse =
  | AlreadyExists
  | CantUnfollow
  | UpdateRelationshipSuccess;

export type UpdateRelationshipSuccess = {
  __typename: 'UpdateRelationshipSuccess';
  meToUser: Scalars['String'];
  userToMe: Scalars['String'];
};

/** A Peloton user */
export type User = {
  __typename: 'User';
  /** The user's unique identifier. */
  id: Scalars['ID'];
  /** The user's name to display. */
  username: Scalars['String'];
  /** Location of the user. */
  location?: Maybe<Scalars['String']>;
  /** The assets that represent this user. */
  assets?: Maybe<UserAssets>;
  /** is user's profile private */
  isProfilePrivate?: Maybe<Scalars['Boolean']>;
  /** Whether the current logged in user follows this user. */
  followStatus: FollowStatus;
  /** Total workout counts of the user. */
  protectedFields?: Maybe<UserProtectedFieldsResult>;
  /** list of user ids (sorted by username) that the current logged in user follows */
  followingList?: Maybe<FollowingUsersPaginatedList>;
  /** Remaining number of tag slots that the user can add. */
  remainingTagSlotCount: Scalars['Int'];
  /** User's all tags. */
  userTags: UserTags;
  getInvitedUserStatus: InvitedUserStatus;
  /**
   * returns user's RSVPed classes - including scheduled sessions, live and encore classes.
   * currently returns max 500 scheduled classes
   */
  scheduledClasses: ScheduledClassListResponse;
};

/** A Peloton user */
export type UserFollowingListArgs = {
  limit?: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
};

/** A Peloton user */
export type UserGetInvitedUserStatusArgs = {
  inviteId?: Maybe<Scalars['ID']>;
};

/** A Peloton user */
export type UserScheduledClassesArgs = {
  input: ScheduledClassesInput;
};

/** User's assets */
export type UserAssets = {
  __typename: 'UserAssets';
  /** The user's profile image. */
  image: Media;
};

/** An element in a paginated User result set. */
export type UserEdge = {
  __typename: 'UserEdge';
  /** The User element. */
  node: User;
  /** An opaque cursor string referring to this element of the result set. */
  cursor: Scalars['Cursor'];
};

export type UserInput = {
  /** ID of the user */
  userId: Scalars['ID'];
  /** Username of the user */
  username: Scalars['String'];
};

/** A leaderboard for an Odyssey level for a given user's workouts */
export type UserLeaderboardRequestInput = {
  /** Optional: If provided, filter leaderboard based on difficulty */
  difficulty?: Maybe<OdysseyDifficulty>;
  /**
   * Optional: If provided, will have extraWorkoutRank and extraLeaderboardEntry returned for easy access to given workout.
   *             This can be used to conveniently grab information for a specific
   * workout without it needing to be on the returned leaderboard
   */
  extraWorkoutId?: Maybe<Scalars['ID']>;
  /** Where to start the LB; note that this should not exceed 10k */
  from: Scalars['Int'];
  levelId: Scalars['ID'];
  /** How many values to fetch */
  size: Scalars['Int'];
  /** Optional: Whether to sort user leaderboard by score or date descending */
  sortBy?: Maybe<UserLeaderboardSortBy>;
  /** Optional: difficulty: If provided, only shows workouts that started at this point or later */
  startTime?: Maybe<Scalars['DateTime']>;
  /** User to get leaderboard for */
  userId?: Maybe<Scalars['ID']>;
};

export enum UserLeaderboardSortBy {
  Date = 'Date',
  Score = 'Score',
}

/** Error thrown when user is not authorized to perform an action */
export type UserNotAuthorizedError = {
  __typename: 'UserNotAuthorizedError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** A list of User results that can be paged over. */
export type UserPaginatedList = PaginatedList & {
  __typename: 'UserPaginatedList';
  /** The elements of the current page. */
  edges: Array<UserEdge>;
  /** The total number of results in the underlying result set. */
  totalCount: Scalars['Int'];
  /** Information about the page of results returned. */
  pageInfo: PageInfo;
};

export type UserPrivacyError = Error & {
  __typename: 'UserPrivacyError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** User fields that is protected by privacy controls */
export type UserProtectedFields = {
  __typename: 'UserProtectedFields';
  totalWorkoutCounts: Scalars['Int'];
};

export type UserProtectedFieldsResult = UserProtectedFields | UserPrivacyError;

export type UserStack = {
  __typename: 'UserStack';
  stackId?: Maybe<Scalars['ID']>;
  stackedClassList: Array<StackedClass>;
  userId: Scalars['ID'];
};

/** A User's relationship with a tag. */
export type UserTag = {
  __typename: 'UserTag';
  /** The tag. */
  tag: Tag;
  /** True if this is the user's primary tag. */
  isPrimary: Scalars['Boolean'];
};

/** User's all tags. include primary tag and all tags */
export type UserTags = {
  __typename: 'UserTags';
  /** The user's primary tag, if they have one. */
  primary?: Maybe<Tag>;
  /** All of user's tags and relationship with them. */
  allTags: Array<UserTag>;
};

export type VideoStream = {
  __typename: 'VideoStream';
  multiChannel?: Maybe<Media>;
  singleChannel?: Maybe<Media>;
  streamHistoryId?: Maybe<Scalars['String']>;
};

export type VideoStreamOrError = ErrorVideoStream | ReadyVideoStream;

export enum Visibility {
  LinkManualPrivate = 'LINK_MANUAL_PRIVATE',
  LinkPrivate = 'LINK_PRIVATE',
  LinkPublic = 'LINK_PUBLIC',
}

export type Workout = {
  __typename: 'Workout';
  id: Scalars['ID'];
};

export type WorkoutFinalizedEventPayload = ActivityFeedEventPayload & {
  __typename: 'WorkoutFinalizedEventPayload';
  /** Feed privacy setting for this event */
  feedPrivacy: FeedPrivacy;
  /** Workout details for the ActivityFeedEvent */
  feedWorkout?: Maybe<FeedWorkout>;
  /** Boolean to check if class is in the user's stack already */
  isClassInUserStack: Scalars['Boolean'];
  /**
   * Referenced Class Id of the ActivityFeedEvent
   * @deprecated Use pelotonClassId in PelotonClassFeedWorkout instead
   */
  pelotonClassId?: Maybe<Scalars['String']>;
  /** Enum that maps to workout metrics to be displayed on client */
  workoutMetricsType: WorkoutMetricsType;
  /** @deprecated Use pelotonClass in PelotonClassFeedWorkout instead */
  pelotonClass?: Maybe<PelotonClass>;
};

export type WorkoutMetricDetails = {
  __typename: 'WorkoutMetricDetails';
  avgHeartRate?: Maybe<Scalars['Float']>;
  avgPace?: Maybe<Scalars['Float']>;
  avgSpeed?: Maybe<Scalars['Float']>;
  calories?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  output?: Maybe<Scalars['Float']>;
  striveScore?: Maybe<Scalars['Float']>;
  zone1Score?: Maybe<Scalars['Float']>;
  zone2Score?: Maybe<Scalars['Float']>;
  zone3Score?: Maybe<Scalars['Float']>;
  zone4Score?: Maybe<Scalars['Float']>;
  zone5Score?: Maybe<Scalars['Float']>;
};

export enum WorkoutMetricsType {
  CycleBike = 'CYCLE_BIKE',
  CycleDigital = 'CYCLE_DIGITAL',
  CycleFreestyle = 'CYCLE_FREESTYLE',
  Floor = 'FLOOR',
  Gym = 'GYM',
  JustRide = 'JUST_RIDE',
  JustRunWalk = 'JUST_RUN_WALK',
  JustWorkOutIndoor = 'JUST_WORK_OUT_INDOOR',
  Meditation = 'MEDITATION',
  Outdoor = 'OUTDOOR',
  RowDigital = 'ROW_DIGITAL',
  RowFreestyle = 'ROW_FREESTYLE',
  RowRower = 'ROW_ROWER',
  RunDigital = 'RUN_DIGITAL',
  RunFreestyle = 'RUN_FREESTYLE',
  RunTread = 'RUN_TREAD',
}

export type WorkoutPostClassResponse = {
  __typename: 'WorkoutPostClassResponse';
  /** Next Class in program if workout was from a program */
  programClass?: Maybe<ProgramClass>;
  /** Next Class in users stack if workout was from a stack */
  stackedClass?: Maybe<StackedClass>;
  workoutDetails: WorkoutMetricDetails;
  /** Field indicating relevant metrics for workout details */
  workoutMetricsType: WorkoutMetricsType;
};

/** Returns a ScheduledClass or InviteDetails */
export type YourScheduleItem =
  | InviteDetails
  | InviteFriendsHttpException
  | InviteFriendsInvalidDataFormat
  | InviteFriendsItemNotFoundError
  | InviteFriendsNotAuthorized
  | ScheduledClass;

/** Returns list of Pending Invites and list of YourScheduleItems */
export type YourScheduleItemList = {
  __typename: 'YourScheduleItemList';
  /** user's pending invites ordered by ‘createdAt’ most recent */
  pendingInvites: PendingInvitesResponse;
  /** Returns a list of user's invites and scheduled classes ordered chronologically by scheduledStartTime */
  yourScheduleItems: Array<YourScheduleItem>;
};

/** Returns YourScheduleItemList or PendingInvitesResponse */
export type YourScheduleListResponse =
  | InviteFriendsBadRequest
  | InviteFriendsItemNotFoundError
  | InviteFriendsNotAuthorized
  | YourScheduleItemList;

export type ZoneScore = {
  __typename: 'ZoneScore';
  /** zone 1 HR score (aka heart_rate_z1_duration) */
  zone1?: Maybe<Scalars['Float']>;
  zone2?: Maybe<Scalars['Float']>;
  zone3?: Maybe<Scalars['Float']>;
  zone4?: Maybe<Scalars['Float']>;
  zone5?: Maybe<Scalars['Float']>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (
  obj: T,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  ProgramProgress: ResolverTypeWrapper<ProgramProgress>;
  AwardedProgramAchievementTier: ResolverTypeWrapper<AwardedProgramAchievementTier>;
  ProgramAchievementTier: ResolverTypeWrapper<ProgramAchievementTier>;
  ProgramAchievementAssets: ResolverTypeWrapper<ProgramAchievementAssets>;
  Media: ResolverTypeWrapper<Media>;
  URL: ResolverTypeWrapper<Scalars['URL']>;
  Color: ResolverTypeWrapper<Color>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Program: ResolverTypeWrapper<Program>;
  ProgramAssets: ResolverTypeWrapper<ProgramAssets>;
  HeroImageAssets: ResolverTypeWrapper<HeroImageAssets>;
  ProgramClass: ResolverTypeWrapper<ProgramClass>;
  ProgramClassProgress: ResolverTypeWrapper<ProgramClassProgress>;
  ProgramClassProgressStatus: ProgramClassProgressStatus;
  PelotonClass:
    | ResolversTypes['OnDemandInstructorClass']
    | ResolversTypes['LiveClass']
    | ResolversTypes['ScenicClass'];
  Locale: ResolverTypeWrapper<Locale>;
  LocaleCode: LocaleCode;
  FitnessDiscipline: ResolverTypeWrapper<FitnessDiscipline>;
  FitnessDisciplineSlug: FitnessDisciplineSlug;
  JoinToken: ResolverTypeWrapper<Scalars['JoinToken']>;
  ClassAssets: ResolverTypeWrapper<ClassAssets>;
  ClassTimeline: ResolverTypeWrapper<ClassTimeline>;
  ClassSegment: ResolverTypeWrapper<ClassSegment>;
  IconDetails: ResolverTypeWrapper<IconDetails>;
  ClassSegmentMetric: ClassSegmentMetric;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ClassSubsegment: ResolverTypeWrapper<ClassSubsegment>;
  Movement: ResolverTypeWrapper<Movement>;
  TargetMetrics: ResolverTypeWrapper<TargetMetrics>;
  Offset: ResolverTypeWrapper<Offset>;
  SegmentTypes: SegmentTypes;
  Metric: ResolverTypeWrapper<Metric>;
  MetricName: MetricName;
  ClassType: ResolverTypeWrapper<ClassType>;
  Equipment: ResolverTypeWrapper<Equipment>;
  ProgramFitnessDiscipline: ResolverTypeWrapper<ProgramFitnessDiscipline>;
  Instructor: ResolverTypeWrapper<Instructor>;
  InstructorAssets: ResolverTypeWrapper<InstructorAssets>;
  ProgramMetaData: ResolverTypeWrapper<ProgramMetaData>;
  ProgramSchedule: ResolverTypeWrapper<ProgramSchedule>;
  ProgramWeek: ResolverTypeWrapper<ProgramWeek>;
  ProgramDay: ResolverTypeWrapper<ProgramDay>;
  ProgramWeekProgress: ResolverTypeWrapper<ProgramWeekProgress>;
  ProgramWeekProgressStatus: ProgramWeekProgressStatus;
  UserTags: ResolverTypeWrapper<UserTags>;
  Tag: ResolverTypeWrapper<Tag>;
  Cursor: ResolverTypeWrapper<Scalars['Cursor']>;
  UserPaginatedList: ResolverTypeWrapper<UserPaginatedList>;
  PaginatedList:
    | ResolversTypes['UserPaginatedList']
    | ResolversTypes['FollowingUsersPaginatedList']
    | ResolversTypes['ActiveTagClassesPaginatedList']
    | ResolversTypes['EntertainmentChannelPaginatedList']
    | ResolversTypes['GymPlanPaginationResponse']
    | ResolversTypes['TagPaginatedList']
    | ResolversTypes['ActivityFeedEventPaginationResponse'];
  PageInfo: ResolverTypeWrapper<PageInfo>;
  UserEdge: ResolverTypeWrapper<UserEdge>;
  User: ResolverTypeWrapper<
    Omit<User, 'protectedFields' | 'scheduledClasses'> & {
      protectedFields?: Maybe<ResolversTypes['UserProtectedFieldsResult']>;
      scheduledClasses: ResolversTypes['ScheduledClassListResponse'];
    }
  >;
  UserAssets: ResolverTypeWrapper<UserAssets>;
  FollowStatus: FollowStatus;
  UserProtectedFieldsResult:
    | ResolversTypes['UserProtectedFields']
    | ResolversTypes['UserPrivacyError'];
  UserProtectedFields: ResolverTypeWrapper<UserProtectedFields>;
  UserPrivacyError: ResolverTypeWrapper<UserPrivacyError>;
  Error:
    | ResolversTypes['UserPrivacyError']
    | ResolversTypes['PermissionError']
    | ResolversTypes['ScheduledClassDoesNotExistError']
    | ResolversTypes['CannotScheduleClassError']
    | ResolversTypes['NoClassPelotonForPlatformError']
    | ResolversTypes['PelotonNotCreatedError']
    | ResolversTypes['DoesNotExistError']
    | ResolversTypes['TagAlreadyExistsError']
    | ResolversTypes['TagCapacityOverLimitError']
    | ResolversTypes['TagNameInvalidCharacterError']
    | ResolversTypes['TagNameLengthError']
    | ResolversTypes['TagNameProhibitedError']
    | ResolversTypes['TagDoesNotExistOnUserError']
    | ResolversTypes['StackConnectionError']
    | ResolversTypes['StackDoesNotExist']
    | ResolversTypes['StackError']
    | ResolversTypes['StackPermissionError']
    | ResolversTypes['StackedClassAlreadyExistsError']
    | ResolversTypes['StackedClassCannotBeAddedError']
    | ResolversTypes['StackedClassConstraintViolationError']
    | ResolversTypes['StackedClassDoesNotExistInStackError']
    | ResolversTypes['StackedClassInvalidPelotonClassIdError']
    | ResolversTypes['StackedClassLimitReachedError'];
  FollowingUsersPaginatedList: ResolverTypeWrapper<FollowingUsersPaginatedList>;
  InvitedUserStatus: InvitedUserStatus;
  ScheduledClassesInput: ScheduledClassesInput;
  ScheduledClassListResponse:
    | ResolversTypes['ScheduledClassList']
    | ResolversTypes['UserPrivacyError'];
  ScheduledClassList: ResolverTypeWrapper<ScheduledClassList>;
  ScheduledClass: ResolverTypeWrapper<ScheduledClass>;
  ScheduleSource: ScheduleSource;
  MetaTag: ResolverTypeWrapper<MetaTag>;
  TagAssets: ResolverTypeWrapper<TagAssets>;
  ActiveTagClassesPaginatedList: ResolverTypeWrapper<ActiveTagClassesPaginatedList>;
  ActiveTagClassEdge: ResolverTypeWrapper<ActiveTagClassEdge>;
  ActiveTagClass: ResolverTypeWrapper<ActiveTagClass>;
  ActiveTagUserPaginatedList: ResolverTypeWrapper<ActiveTagUserPaginatedList>;
  ActiveTagUserEdge: ResolverTypeWrapper<ActiveTagUserEdge>;
  PopularTagClassesList: ResolverTypeWrapper<PopularTagClassesList>;
  PopularTagClass: ResolverTypeWrapper<PopularTagClass>;
  UserTag: ResolverTypeWrapper<UserTag>;
  EntertainmentChannelsQueryResponse:
    | ResolversTypes['EntertainmentChannelPaginatedList']
    | ResolversTypes['PermissionError'];
  EntertainmentChannelPaginatedList: ResolverTypeWrapper<EntertainmentChannelPaginatedList>;
  EntertainmentChannel: ResolverTypeWrapper<EntertainmentChannel>;
  PermissionError: ResolverTypeWrapper<PermissionError>;
  ProgramParamsInput: ProgramParamsInput;
  ProgramFiltersInput: ProgramFiltersInput;
  ProgramSortingInput: ProgramSortingInput;
  FreestyleMetadata: ResolverTypeWrapper<
    Omit<FreestyleMetadata, 'musicProviders'> & {
      musicProviders: Array<ResolversTypes['MusicProvider']>;
    }
  >;
  MusicProvider: ResolversTypes['PlaylistMusicProvider'];
  PlaylistMusicProvider: ResolverTypeWrapper<PlaylistMusicProvider>;
  StreamablePlaylist: ResolverTypeWrapper<StreamablePlaylist>;
  BasePlaylist: ResolversTypes['StreamablePlaylist'] | ResolversTypes['Playlist'];
  PlaylistPlaybackSong: ResolverTypeWrapper<PlaylistPlaybackSong>;
  Song: ResolverTypeWrapper<Song>;
  Artist: ResolverTypeWrapper<Artist>;
  Album: ResolverTypeWrapper<Album>;
  EventDetailsResponse:
    | ResolversTypes['ActivityFeedErrorResponse']
    | ResolversTypes['EventDetails'];
  ActivityFeedErrorResponse: ResolverTypeWrapper<ActivityFeedErrorResponse>;
  ActivityFeedErrorType: ActivityFeedErrorType;
  EventDetails: ResolverTypeWrapper<EventDetails>;
  ActivityFeedEvent: ResolverTypeWrapper<
    Omit<ActivityFeedEvent, 'payload'> & { payload: ResolversTypes['Payload'] }
  >;
  EventType: EventType;
  FeedEventType: FeedEventType;
  Payload:
    | ResolversTypes['AchievementAwardedEventPayload']
    | ResolversTypes['StreakAwardedEventPayload']
    | ResolversTypes['WorkoutFinalizedEventPayload'];
  AchievementAwardedEventPayload: ResolverTypeWrapper<AchievementAwardedEventPayload>;
  ActivityFeedEventPayload:
    | ResolversTypes['AchievementAwardedEventPayload']
    | ResolversTypes['StreakAwardedEventPayload']
    | ResolversTypes['WorkoutFinalizedEventPayload'];
  FeedPrivacy: FeedPrivacy;
  FeedWorkout:
    | ResolversTypes['FreestyleFeedWorkout']
    | ResolversTypes['GymFeedWorkout']
    | ResolversTypes['OdysseyFeedWorkout']
    | ResolversTypes['PelotonClassFeedWorkout'];
  StreakAwardedEventPayload: ResolverTypeWrapper<StreakAwardedEventPayload>;
  WorkoutFinalizedEventPayload: ResolverTypeWrapper<WorkoutFinalizedEventPayload>;
  WorkoutMetricsType: WorkoutMetricsType;
  HighFiveDetails: ResolverTypeWrapper<HighFiveDetails>;
  HighFiveDetailsResponse:
    | ResolversTypes['ActivityFeedErrorResponse']
    | ResolversTypes['HighFiveDetails'];
  NotificationDetailsResponse:
    | ResolversTypes['ActivityFeedErrorResponse']
    | ResolversTypes['NotificationDetails'];
  NotificationDetails: ResolverTypeWrapper<NotificationDetails>;
  Notification: ResolverTypeWrapper<Notification>;
  UnreadNotificationsResponse:
    | ResolversTypes['ActivityFeedErrorResponse']
    | ResolversTypes['UnreadNotifications'];
  UnreadNotifications: ResolverTypeWrapper<UnreadNotifications>;
  CompletedStats: ResolverTypeWrapper<CompletedStats>;
  ZoneScore: ResolverTypeWrapper<ZoneScore>;
  OnDemandInstructorClass: ResolverTypeWrapper<OnDemandInstructorClass>;
  DifficultyLevel: ResolverTypeWrapper<DifficultyLevel>;
  DifficultyLevelSlug: DifficultyLevelSlug;
  ContentFormat: ContentFormat;
  Playlist: ResolverTypeWrapper<Playlist>;
  InstructorCue: ResolverTypeWrapper<InstructorCue>;
  OffsetsCue: ResolverTypeWrapper<OffsetsCue>;
  RangeCue: ResolverTypeWrapper<RangeCue>;
  TargetMetricsData: ResolverTypeWrapper<TargetMetricsData>;
  TargetMetricsOutput: ResolverTypeWrapper<TargetMetricsOutput>;
  PaceIntensity: ResolverTypeWrapper<PaceIntensity>;
  PaceLevel: ResolverTypeWrapper<PaceLevel>;
  Captions: ResolverTypeWrapper<Captions>;
  ClassTopTag: ResolverTypeWrapper<ClassTopTag>;
  GymPlanResponse:
    | ResolversTypes['GymErrorResponse']
    | ResolversTypes['GymPlan']
    | ResolversTypes['GymPlanPaginationResponse'];
  GymErrorResponse: ResolverTypeWrapper<GymErrorResponse>;
  GymErrorType: GymErrorType;
  GymPlan: ResolverTypeWrapper<GymPlan>;
  GymMovementGroup: ResolverTypeWrapper<GymMovementGroup>;
  DisplayTag: ResolverTypeWrapper<DisplayTag>;
  GroupTags: ResolverTypeWrapper<GroupTags>;
  GymMovement: ResolverTypeWrapper<GymMovement>;
  MovementVideo: ResolverTypeWrapper<MovementVideo>;
  MovementVideoType: MovementVideoType;
  MovementPattern: MovementPattern;
  MovementMuscleGroup: ResolverTypeWrapper<MovementMuscleGroup>;
  MuscleGroupScore: ResolverTypeWrapper<MuscleGroupScore>;
  GymPlanPaginationResponse: ResolverTypeWrapper<GymPlanPaginationResponse>;
  GymPlanEdge: ResolverTypeWrapper<GymPlanEdge>;
  GymPlanFilterChip: ResolverTypeWrapper<GymPlanFilterChip>;
  GymPlanFilterInput: GymPlanFilterInput;
  YourScheduleItem:
    | ResolversTypes['InviteDetails']
    | ResolversTypes['InviteFriendsHttpException']
    | ResolversTypes['InviteFriendsInvalidDataFormat']
    | ResolversTypes['InviteFriendsItemNotFoundError']
    | ResolversTypes['InviteFriendsNotAuthorized']
    | ResolversTypes['ScheduledClass'];
  InviteDetails: ResolverTypeWrapper<
    Omit<InviteDetails, 'getInvitedAcceptedFollowing' | 'getInvitedUsers'> & {
      getInvitedAcceptedFollowing: ResolversTypes['InvitedUsersPaginationResponse'];
      getInvitedUsers: ResolversTypes['InvitedUsersPaginationResponse'];
    }
  >;
  InviteUser: ResolverTypeWrapper<InviteUser>;
  InvitedUsersPaginationResponse:
    | ResolversTypes['InviteFriendsHttpException']
    | ResolversTypes['InviteFriendsNotAuthorized']
    | ResolversTypes['InvitedUsersPaginatedList'];
  InviteFriendsHttpException: ResolverTypeWrapper<InviteFriendsHttpException>;
  InviteFriendsError:
    | ResolversTypes['InviteFriendsHttpException']
    | ResolversTypes['InviteFriendsNotAuthorized']
    | ResolversTypes['InviteFriendsInvalidDataFormat']
    | ResolversTypes['InviteFriendsItemNotFoundError']
    | ResolversTypes['InviteFriendsBadRequest']
    | ResolversTypes['InviteFriendsCreateShareLinkDisabledError']
    | ResolversTypes['InviteFriendsNoPermissionToCreateShareLinkError']
    | ResolversTypes['InviteFriendsInvitedUserSizeLimitExceeded'];
  InviteFriendsNotAuthorized: ResolverTypeWrapper<InviteFriendsNotAuthorized>;
  InvitedUsersPaginatedList: ResolverTypeWrapper<InvitedUsersPaginatedList>;
  InviteUserEdge: ResolverTypeWrapper<InviteUserEdge>;
  InviteStatus: InviteStatus;
  Occasion: ResolverTypeWrapper<Occasion>;
  OccasionAssets: ResolverTypeWrapper<OccasionAssets>;
  Visibility: Visibility;
  InviteFriendsInvalidDataFormat: ResolverTypeWrapper<InviteFriendsInvalidDataFormat>;
  InviteFriendsItemNotFoundError: ResolverTypeWrapper<InviteFriendsItemNotFoundError>;
  JoinableClass: ResolverTypeWrapper<
    Omit<JoinableClass, 'videoStreamOrError'> & {
      videoStreamOrError: ResolversTypes['VideoStreamOrError'];
    }
  >;
  ActiveWorkout: ResolverTypeWrapper<ActiveWorkout>;
  PauseInfo: ResolverTypeWrapper<PauseInfo>;
  Peloton: ResolverTypeWrapper<Peloton>;
  Workout: ResolverTypeWrapper<Workout>;
  VideoStream: ResolverTypeWrapper<VideoStream>;
  VideoStreamOrError:
    | ResolversTypes['ErrorVideoStream']
    | ResolversTypes['ReadyVideoStream'];
  ErrorVideoStream: ResolverTypeWrapper<ErrorVideoStream>;
  JoinError:
    | ResolversTypes['JoinAuthorizationError']
    | ResolversTypes['JoinContentNotLiveError']
    | ResolversTypes['JoinNoActiveSubscriptionError']
    | ResolversTypes['JoinStreamLimitError'];
  ReadyVideoStream: ResolverTypeWrapper<ReadyVideoStream>;
  LevelQueryResponse:
    | ResolversTypes['LevelSuccessResponse']
    | ResolversTypes['OdysseyItemNotFoundError'];
  LevelSuccessResponse: ResolverTypeWrapper<LevelSuccessResponse>;
  Level: ResolverTypeWrapper<
    Omit<Level, 'musicProvider'> & {
      musicProvider?: Maybe<ResolversTypes['MusicProvider']>;
    }
  >;
  OdysseyLevelAssets: ResolverTypeWrapper<OdysseyLevelAssets>;
  LevelAvailability: LevelAvailability;
  BestRuns: ResolverTypeWrapper<BestRuns>;
  OdysseyWorkout: ResolverTypeWrapper<OdysseyWorkout>;
  OdysseyDifficulty: OdysseyDifficulty;
  OdysseyLevelDifficultyDetail: ResolverTypeWrapper<OdysseyLevelDifficultyDetail>;
  OdysseyItemNotFoundError: ResolverTypeWrapper<OdysseyItemNotFoundError>;
  OdysseyError:
    | ResolversTypes['OdysseyItemNotFoundError']
    | ResolversTypes['OdysseyNotAuthorizedError']
    | ResolversTypes['OdysseyBadRequestError']
    | ResolversTypes['OdysseyInvalidWorkoutMutationError'];
  LevelListQueryResponse:
    | ResolversTypes['LevelListSuccessResponse']
    | ResolversTypes['OdysseyNotAuthorizedError'];
  LevelListSuccessResponse: ResolverTypeWrapper<LevelListSuccessResponse>;
  OdysseyNotAuthorizedError: ResolverTypeWrapper<OdysseyNotAuthorizedError>;
  OccasionsResponse: ResolversTypes['OccasionsList'];
  OccasionsList: ResolverTypeWrapper<OccasionsList>;
  OdysseyConfigurationResponse:
    | ResolversTypes['OdysseyBikeConfiguration']
    | ResolversTypes['OdysseyItemNotFoundError']
    | ResolversTypes['OdysseyTreadConfiguration'];
  OdysseyBikeConfiguration: ResolverTypeWrapper<OdysseyBikeConfiguration>;
  OdysseyBikeDifficultyConfiguration: ResolverTypeWrapper<OdysseyBikeDifficultyConfiguration>;
  OdysseyBikeLaneConfiguration: ResolverTypeWrapper<OdysseyBikeLaneConfiguration>;
  OdysseyBikeScoringConfiguration: ResolverTypeWrapper<OdysseyBikeScoringConfiguration>;
  OdysseyStarThreshold: ResolverTypeWrapper<OdysseyStarThreshold>;
  OdysseyTreadConfiguration: ResolverTypeWrapper<OdysseyTreadConfiguration>;
  OdysseyTreadDifficultyConfiguration: ResolverTypeWrapper<OdysseyTreadDifficultyConfiguration>;
  OdysseyTreadDifficultyIntensity: ResolverTypeWrapper<OdysseyTreadDifficultyIntensity>;
  OdysseyTreadLaneConfiguration: ResolverTypeWrapper<OdysseyTreadLaneConfiguration>;
  OdysseyTreadScoringConfiguration: ResolverTypeWrapper<OdysseyTreadScoringConfiguration>;
  OdysseyDifficultyMetadata: ResolverTypeWrapper<OdysseyDifficultyMetadata>;
  LeaderboardRequestInput: LeaderboardRequestInput;
  OdysseyLeaderboardSortBy: OdysseyLeaderboardSortBy;
  OdysseyLeaderboardQueryResponse:
    | ResolversTypes['OdysseyLeaderboardInvalidRequest']
    | ResolversTypes['OdysseyLeaderboardListSuccessResponse'];
  OdysseyLeaderboardInvalidRequest: ResolverTypeWrapper<OdysseyLeaderboardInvalidRequest>;
  OdysseyLeaderboardGraphqlError: ResolversTypes['OdysseyLeaderboardInvalidRequest'];
  OdysseyLeaderboardListSuccessResponse: ResolverTypeWrapper<OdysseyLeaderboardListSuccessResponse>;
  OdysseyLeaderboard: ResolverTypeWrapper<OdysseyLeaderboard>;
  LeaderboardEntry: ResolverTypeWrapper<LeaderboardEntry>;
  LeaderboardWorkoutDetails: ResolverTypeWrapper<LeaderboardWorkoutDetails>;
  LevelFiltersQueryResponse:
    | ResolversTypes['LevelBrowseFiltersSuccessResponse']
    | ResolversTypes['OdysseyBadRequestError'];
  LevelBrowseFiltersSuccessResponse: ResolverTypeWrapper<
    Omit<LevelBrowseFiltersSuccessResponse, 'filters'> & {
      filters: Array<ResolversTypes['OdysseyLevelFilter']>;
    }
  >;
  OdysseyLevelBrowseFilter: ResolverTypeWrapper<
    Omit<OdysseyLevelBrowseFilter, 'info' | 'options'> & {
      info: ResolversTypes['OdysseyLevelFilterInfo'];
      options: Array<ResolversTypes['OdysseyLevelFilterOption']>;
    }
  >;
  OdysseyLevelFilterInfo:
    | ResolversTypes['OdysseyAccordionLevelFilterInfo']
    | ResolversTypes['OdysseyGridLevelFilterInfo']
    | ResolversTypes['OdysseyToggleLevelFilterInfo'];
  OdysseyAccordionLevelFilterInfo: ResolverTypeWrapper<OdysseyAccordionLevelFilterInfo>;
  OdysseyIcon: ResolverTypeWrapper<OdysseyIcon>;
  OdysseyGridLevelFilterInfo: ResolverTypeWrapper<OdysseyGridLevelFilterInfo>;
  OdysseyToggleLevelFilterInfo: ResolverTypeWrapper<OdysseyToggleLevelFilterInfo>;
  OdysseyLevelFilterOption:
    | ResolversTypes['OdysseyBasicLevelFilterOption']
    | ResolversTypes['OdysseyDurationLevelFilterOption']
    | ResolversTypes['OdysseyPlayedLevelFilterOption']
    | ResolversTypes['OdysseyStarsLevelFilterOption'];
  OdysseyBasicLevelFilterOption: ResolverTypeWrapper<OdysseyBasicLevelFilterOption>;
  OdysseyDurationLevelFilterOption: ResolverTypeWrapper<OdysseyDurationLevelFilterOption>;
  OdysseyPlayedLevelFilterOption: ResolverTypeWrapper<OdysseyPlayedLevelFilterOption>;
  OdysseyStarsLevelFilterOption: ResolverTypeWrapper<OdysseyStarsLevelFilterOption>;
  OdysseyLevelFilter:
    | ResolversTypes['OdysseyAccordionLevelFilter']
    | ResolversTypes['OdysseyGridLevelFilter']
    | ResolversTypes['OdysseyToggleLevelFilter'];
  OdysseyAccordionLevelFilter: ResolverTypeWrapper<
    Omit<OdysseyAccordionLevelFilter, 'options'> & {
      options: Array<ResolversTypes['OdysseyLevelFilterOption']>;
    }
  >;
  OdysseyGridLevelFilter: ResolverTypeWrapper<
    Omit<OdysseyGridLevelFilter, 'options'> & {
      options: Array<ResolversTypes['OdysseyLevelFilterOption']>;
    }
  >;
  OdysseyToggleLevelFilter: ResolverTypeWrapper<OdysseyToggleLevelFilter>;
  OdysseyBadRequestError: ResolverTypeWrapper<OdysseyBadRequestError>;
  UserLeaderboardRequestInput: UserLeaderboardRequestInput;
  UserLeaderboardSortBy: UserLeaderboardSortBy;
  OdysseyWorkoutQueryResponse:
    | ResolversTypes['OdysseyItemNotFoundError']
    | ResolversTypes['OdysseyNotAuthorizedError']
    | ResolversTypes['OdysseyWorkoutSuccessResponse'];
  OdysseyWorkoutSuccessResponse: ResolverTypeWrapper<OdysseyWorkoutSuccessResponse>;
  ScheduledClassResponse:
    | ResolversTypes['ScheduledClass']
    | ResolversTypes['ScheduledClassDoesNotExistError']
    | ResolversTypes['CannotScheduleClassError'];
  ScheduledClassDoesNotExistError: ResolverTypeWrapper<ScheduledClassDoesNotExistError>;
  CannotScheduleClassError: ResolverTypeWrapper<CannotScheduleClassError>;
  SearchTagInput: SearchTagInput;
  TagPaginatedList: ResolverTypeWrapper<TagPaginatedList>;
  TagEdge: ResolverTypeWrapper<TagEdge>;
  SessionInput: SessionInput;
  SessionClass: ResolverTypeWrapper<SessionClass>;
  LiveClass: ResolverTypeWrapper<
    Omit<LiveClass, 'peloton'> & { peloton: ResolversTypes['PelotonResult'] }
  >;
  PelotonResult:
    | ResolversTypes['Peloton']
    | ResolversTypes['NoClassPelotonForPlatformError']
    | ResolversTypes['PelotonNotCreatedError'];
  NoClassPelotonForPlatformError: ResolverTypeWrapper<NoClassPelotonForPlatformError>;
  PelotonNotCreatedError: ResolverTypeWrapper<PelotonNotCreatedError>;
  LiveClassCategory: LiveClassCategory;
  TagBrowseCategorySlug: TagBrowseCategorySlug;
  TagBrowseCategory: ResolverTypeWrapper<TagBrowseCategory>;
  TagBrowseCategoryAssets: ResolverTypeWrapper<TagBrowseCategoryAssets>;
  ActivityFeedEventResponse:
    | ResolversTypes['ActivityFeedErrorResponse']
    | ResolversTypes['ActivityFeedEvent']
    | ResolversTypes['ActivityFeedEventPaginationResponse'];
  ActivityFeedEventPaginationResponse: ResolverTypeWrapper<ActivityFeedEventPaginationResponse>;
  ActivityFeedEventEdge: ResolverTypeWrapper<ActivityFeedEventEdge>;
  YourScheduleListResponse:
    | ResolversTypes['InviteFriendsBadRequest']
    | ResolversTypes['InviteFriendsItemNotFoundError']
    | ResolversTypes['InviteFriendsNotAuthorized']
    | ResolversTypes['YourScheduleItemList'];
  InviteFriendsBadRequest: ResolverTypeWrapper<InviteFriendsBadRequest>;
  YourScheduleItemList: ResolverTypeWrapper<
    Omit<YourScheduleItemList, 'pendingInvites' | 'yourScheduleItems'> & {
      pendingInvites: ResolversTypes['PendingInvitesResponse'];
      yourScheduleItems: Array<ResolversTypes['YourScheduleItem']>;
    }
  >;
  PendingInvitesResponse:
    | ResolversTypes['InviteFriendsItemNotFoundError']
    | ResolversTypes['PendingInvites'];
  PendingInvites: ResolverTypeWrapper<PendingInvites>;
  StackResponse:
    | ResolversTypes['StackConnectionError']
    | ResolversTypes['StackDoesNotExist']
    | ResolversTypes['StackError']
    | ResolversTypes['StackPermissionError']
    | ResolversTypes['StackResponseSuccess']
    | ResolversTypes['StackedClassAlreadyExistsError']
    | ResolversTypes['StackedClassCannotBeAddedError']
    | ResolversTypes['StackedClassConstraintViolationError']
    | ResolversTypes['StackedClassDoesNotExistInStackError']
    | ResolversTypes['StackedClassInvalidPelotonClassIdError']
    | ResolversTypes['StackedClassLimitReachedError'];
  UserStack: ResolverTypeWrapper<UserStack>;
  StackedClass: ResolverTypeWrapper<StackedClass>;
  WorkoutPostClassResponse: ResolverTypeWrapper<WorkoutPostClassResponse>;
  WorkoutMetricDetails: ResolverTypeWrapper<WorkoutMetricDetails>;
  Mutation: ResolverTypeWrapper<{}>;
  AbandonOdysseyWorkoutResponse:
    | ResolversTypes['OdysseyInvalidWorkoutMutationError']
    | ResolversTypes['OdysseyItemNotFoundError']
    | ResolversTypes['OdysseyWorkoutSuccessResponse'];
  OdysseyInvalidWorkoutMutationError: ResolverTypeWrapper<OdysseyInvalidWorkoutMutationError>;
  ScheduledClassInput: ScheduledClassInput;
  AddClassToStackInput: AddClassToStackInput;
  AddTagToUserInput: AddTagToUserInput;
  AddTagToUserResponse:
    | ResolversTypes['AddTagToUserSuccess']
    | ResolversTypes['DoesNotExistError']
    | ResolversTypes['TagAlreadyExistsError']
    | ResolversTypes['TagCapacityOverLimitError']
    | ResolversTypes['TagNameInvalidCharacterError']
    | ResolversTypes['TagNameLengthError']
    | ResolversTypes['TagNameProhibitedError'];
  AddTagToUserSuccess: ResolverTypeWrapper<AddTagToUserSuccess>;
  DoesNotExistError: ResolverTypeWrapper<DoesNotExistError>;
  TagAlreadyExistsError: ResolverTypeWrapper<TagAlreadyExistsError>;
  TagCapacityOverLimitError: ResolverTypeWrapper<TagCapacityOverLimitError>;
  TagNameInvalidCharacterError: ResolverTypeWrapper<TagNameInvalidCharacterError>;
  TagNameLengthError: ResolverTypeWrapper<TagNameLengthError>;
  TagNameProhibitedError: ResolverTypeWrapper<TagNameProhibitedError>;
  CompleteOdysseyWorkoutInput: CompleteOdysseyWorkoutInput;
  OdysseyBikeCompletionInput: OdysseyBikeCompletionInput;
  OdysseyTreadCompletionInput: OdysseyTreadCompletionInput;
  CompleteOdysseyWorkoutResponse:
    | ResolversTypes['OdysseyBadRequestError']
    | ResolversTypes['OdysseyInvalidWorkoutMutationError']
    | ResolversTypes['OdysseyItemNotFoundError']
    | ResolversTypes['OdysseyWorkoutSuccessResponse'];
  InviteShareURLResponse:
    | ResolversTypes['InviteFriendsCreateShareLinkDisabledError']
    | ResolversTypes['InviteFriendsInvalidDataFormat']
    | ResolversTypes['InviteFriendsItemNotFoundError']
    | ResolversTypes['InviteFriendsNoPermissionToCreateShareLinkError']
    | ResolversTypes['ShareURL'];
  InviteFriendsCreateShareLinkDisabledError: ResolverTypeWrapper<InviteFriendsCreateShareLinkDisabledError>;
  InviteFriendsNoPermissionToCreateShareLinkError: ResolverTypeWrapper<InviteFriendsNoPermissionToCreateShareLinkError>;
  ShareURL: ResolverTypeWrapper<ShareUrl>;
  CreateLiveInviteInput: CreateLiveInviteInput;
  UserInput: UserInput;
  InviteResponse:
    | ResolversTypes['InviteDetails']
    | ResolversTypes['InviteFriendsHttpException']
    | ResolversTypes['InviteFriendsInvalidDataFormat']
    | ResolversTypes['InviteFriendsInvitedUserSizeLimitExceeded']
    | ResolversTypes['InviteFriendsItemNotFoundError']
    | ResolversTypes['InviteFriendsNotAuthorized'];
  InviteFriendsInvitedUserSizeLimitExceeded: ResolverTypeWrapper<InviteFriendsInvitedUserSizeLimitExceeded>;
  CreateNewOnDemandInviteInput: CreateNewOnDemandInviteInput;
  CreateOdysseyWorkoutInput: CreateOdysseyWorkoutInput;
  CreateOdysseyWorkoutResponse: ResolversTypes['OdysseyWorkoutSuccessResponse'];
  CreateScheduledOnDemandInviteInput: CreateScheduledOnDemandInviteInput;
  InviteUsersToInviteInput: InviteUsersToInviteInput;
  ProgramProgressResponse:
    | ResolversTypes['ProgramProgressAlreadyExistsError']
    | ResolversTypes['ProgramProgressNotFoundError']
    | ResolversTypes['ProgramProgressResponseSuccess']
    | ResolversTypes['UserNotAuthorizedError'];
  ProgramProgressAlreadyExistsError: ResolverTypeWrapper<ProgramProgressAlreadyExistsError>;
  ProgramProgressNotFoundError: ResolverTypeWrapper<ProgramProgressNotFoundError>;
  ProgramProgressResponseSuccess: ResolverTypeWrapper<ProgramProgressResponseSuccess>;
  UserNotAuthorizedError: ResolverTypeWrapper<UserNotAuthorizedError>;
  ModifyStackInput: ModifyStackInput;
  PauseWorkoutInput: PauseWorkoutInput;
  PlayClassFromStackInput: PlayClassFromStackInput;
  RemoveTagFromUserInput: RemoveTagFromUserInput;
  RemoveTagFromUserResponse:
    | ResolversTypes['RemoveTagFromUserSuccess']
    | ResolversTypes['DoesNotExistError'];
  RemoveTagFromUserSuccess: ResolverTypeWrapper<RemoveTagFromUserSuccess>;
  ReplaceTagInput: ReplaceTagInput;
  ReplaceTagResponse:
    | ResolversTypes['ReplaceTagSuccess']
    | ResolversTypes['TagAlreadyExistsError']
    | ResolversTypes['DoesNotExistError']
    | ResolversTypes['TagNameInvalidCharacterError']
    | ResolversTypes['TagNameLengthError']
    | ResolversTypes['TagNameProhibitedError'];
  ReplaceTagSuccess: ResolverTypeWrapper<ReplaceTagSuccess>;
  RescheduleClassInput: RescheduleClassInput;
  SetTagAsPrimaryInput: SetTagAsPrimaryInput;
  SetTagAsPrimaryResponse:
    | ResolversTypes['SetTagAsPrimarySuccess']
    | ResolversTypes['TagDoesNotExistOnUserError'];
  SetTagAsPrimarySuccess: ResolverTypeWrapper<SetTagAsPrimarySuccess>;
  TagDoesNotExistOnUserError: ResolverTypeWrapper<TagDoesNotExistOnUserError>;
  StartFreestyleWorkoutInput: StartFreestyleWorkoutInput;
  FreestyleWorkoutGoalInput: FreestyleWorkoutGoalInput;
  FreestyleWorkoutGoalType: FreestyleWorkoutGoalType;
  FreestyleWorkoutGoalUnit: FreestyleWorkoutGoalUnit;
  StartFreestyleWorkoutResponse:
    | ResolversTypes['StartFreestyleWorkoutResponseSuccess']
    | ResolversTypes['SubscriptionIdNotFoundError']
    | ResolversTypes['UserNotAuthorizedError'];
  StartFreestyleWorkoutResponseSuccess: ResolverTypeWrapper<StartFreestyleWorkoutResponseSuccess>;
  SubscriptionIdNotFoundError: ResolverTypeWrapper<SubscriptionIdNotFoundError>;
  StartGymWorkoutInput: StartGymWorkoutInput;
  StartGymWorkoutResponse:
    | ResolversTypes['GymErrorResponse']
    | ResolversTypes['StartGymWorkoutResponseSuccess'];
  StartGymWorkoutResponseSuccess: ResolverTypeWrapper<StartGymWorkoutResponseSuccess>;
  GymWorkout: ResolverTypeWrapper<GymWorkout>;
  StartClassInput: StartClassInput;
  UnpauseWorkoutInput: UnpauseWorkoutInput;
  UnpauseWorkoutResponse: ResolverTypeWrapper<UnpauseWorkoutResponse>;
  UpdateRelationshipRequest: UpdateRelationshipRequest;
  UpdateRelationshipResponse:
    | ResolversTypes['AlreadyExists']
    | ResolversTypes['CantUnfollow']
    | ResolversTypes['UpdateRelationshipSuccess'];
  AlreadyExists: ResolverTypeWrapper<AlreadyExists>;
  CantUnfollow: ResolverTypeWrapper<CantUnfollow>;
  UpdateRelationshipSuccess: ResolverTypeWrapper<UpdateRelationshipSuccess>;
  RetryableError: ResolversTypes['JoinContentNotLiveError'];
  JoinAuthorizationError: ResolverTypeWrapper<JoinAuthorizationError>;
  JoinContentNotLiveError: ResolverTypeWrapper<JoinContentNotLiveError>;
  JoinNoActiveSubscriptionError: ResolverTypeWrapper<JoinNoActiveSubscriptionError>;
  JoinStreamLimitError: ResolverTypeWrapper<JoinStreamLimitError>;
  _FieldSet: ResolverTypeWrapper<Scalars['_FieldSet']>;
  StackConnectionError: ResolverTypeWrapper<StackConnectionError>;
  StackDoesNotExist: ResolverTypeWrapper<StackDoesNotExist>;
  StackError: ResolverTypeWrapper<StackError>;
  StackPermissionError: ResolverTypeWrapper<StackPermissionError>;
  StackResponseSuccess: ResolverTypeWrapper<StackResponseSuccess>;
  StackedClassAlreadyExistsError: ResolverTypeWrapper<StackedClassAlreadyExistsError>;
  StackedClassCannotBeAddedError: ResolverTypeWrapper<StackedClassCannotBeAddedError>;
  StackedClassConstraintViolationError: ResolverTypeWrapper<StackedClassConstraintViolationError>;
  StackedClassDoesNotExistInStackError: ResolverTypeWrapper<StackedClassDoesNotExistInStackError>;
  StackedClassInvalidPelotonClassIdError: ResolverTypeWrapper<StackedClassInvalidPelotonClassIdError>;
  StackedClassLimitReachedError: ResolverTypeWrapper<StackedClassLimitReachedError>;
  ScenicClass: ResolverTypeWrapper<
    Omit<ScenicClass, 'musicProviders'> & {
      musicProviders: Array<Maybe<ResolversTypes['MusicProvider']>>;
    }
  >;
  DistanceUnit: DistanceUnit;
  FreestyleFeedWorkout: ResolverTypeWrapper<FreestyleFeedWorkout>;
  GymFeedWorkout: ResolverTypeWrapper<GymFeedWorkout>;
  OdysseyFeedWorkout: ResolverTypeWrapper<OdysseyFeedWorkout>;
  PelotonClassFeedWorkout: ResolverTypeWrapper<PelotonClassFeedWorkout>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  ID: Scalars['ID'];
  Boolean: Scalars['Boolean'];
  DateTime: Scalars['DateTime'];
  ProgramProgress: ProgramProgress;
  AwardedProgramAchievementTier: AwardedProgramAchievementTier;
  ProgramAchievementTier: ProgramAchievementTier;
  ProgramAchievementAssets: ProgramAchievementAssets;
  Media: Media;
  URL: Scalars['URL'];
  Color: Color;
  String: Scalars['String'];
  Int: Scalars['Int'];
  Program: Program;
  ProgramAssets: ProgramAssets;
  HeroImageAssets: HeroImageAssets;
  ProgramClass: ProgramClass;
  ProgramClassProgress: ProgramClassProgress;
  PelotonClass:
    | ResolversParentTypes['OnDemandInstructorClass']
    | ResolversParentTypes['LiveClass']
    | ResolversParentTypes['ScenicClass'];
  Locale: Locale;
  FitnessDiscipline: FitnessDiscipline;
  JoinToken: Scalars['JoinToken'];
  ClassAssets: ClassAssets;
  ClassTimeline: ClassTimeline;
  ClassSegment: ClassSegment;
  IconDetails: IconDetails;
  Float: Scalars['Float'];
  ClassSubsegment: ClassSubsegment;
  Movement: Movement;
  TargetMetrics: TargetMetrics;
  Offset: Offset;
  Metric: Metric;
  ClassType: ClassType;
  Equipment: Equipment;
  ProgramFitnessDiscipline: ProgramFitnessDiscipline;
  Instructor: Instructor;
  InstructorAssets: InstructorAssets;
  ProgramMetaData: ProgramMetaData;
  ProgramSchedule: ProgramSchedule;
  ProgramWeek: ProgramWeek;
  ProgramDay: ProgramDay;
  ProgramWeekProgress: ProgramWeekProgress;
  UserTags: UserTags;
  Tag: Tag;
  Cursor: Scalars['Cursor'];
  UserPaginatedList: UserPaginatedList;
  PaginatedList:
    | ResolversParentTypes['UserPaginatedList']
    | ResolversParentTypes['FollowingUsersPaginatedList']
    | ResolversParentTypes['ActiveTagClassesPaginatedList']
    | ResolversParentTypes['EntertainmentChannelPaginatedList']
    | ResolversParentTypes['GymPlanPaginationResponse']
    | ResolversParentTypes['TagPaginatedList']
    | ResolversParentTypes['ActivityFeedEventPaginationResponse'];
  PageInfo: PageInfo;
  UserEdge: UserEdge;
  User: Omit<User, 'protectedFields' | 'scheduledClasses'> & {
    protectedFields?: Maybe<ResolversParentTypes['UserProtectedFieldsResult']>;
    scheduledClasses: ResolversParentTypes['ScheduledClassListResponse'];
  };
  UserAssets: UserAssets;
  UserProtectedFieldsResult:
    | ResolversParentTypes['UserProtectedFields']
    | ResolversParentTypes['UserPrivacyError'];
  UserProtectedFields: UserProtectedFields;
  UserPrivacyError: UserPrivacyError;
  Error:
    | ResolversParentTypes['UserPrivacyError']
    | ResolversParentTypes['PermissionError']
    | ResolversParentTypes['ScheduledClassDoesNotExistError']
    | ResolversParentTypes['CannotScheduleClassError']
    | ResolversParentTypes['NoClassPelotonForPlatformError']
    | ResolversParentTypes['PelotonNotCreatedError']
    | ResolversParentTypes['DoesNotExistError']
    | ResolversParentTypes['TagAlreadyExistsError']
    | ResolversParentTypes['TagCapacityOverLimitError']
    | ResolversParentTypes['TagNameInvalidCharacterError']
    | ResolversParentTypes['TagNameLengthError']
    | ResolversParentTypes['TagNameProhibitedError']
    | ResolversParentTypes['TagDoesNotExistOnUserError']
    | ResolversParentTypes['StackConnectionError']
    | ResolversParentTypes['StackDoesNotExist']
    | ResolversParentTypes['StackError']
    | ResolversParentTypes['StackPermissionError']
    | ResolversParentTypes['StackedClassAlreadyExistsError']
    | ResolversParentTypes['StackedClassCannotBeAddedError']
    | ResolversParentTypes['StackedClassConstraintViolationError']
    | ResolversParentTypes['StackedClassDoesNotExistInStackError']
    | ResolversParentTypes['StackedClassInvalidPelotonClassIdError']
    | ResolversParentTypes['StackedClassLimitReachedError'];
  FollowingUsersPaginatedList: FollowingUsersPaginatedList;
  ScheduledClassesInput: ScheduledClassesInput;
  ScheduledClassListResponse:
    | ResolversParentTypes['ScheduledClassList']
    | ResolversParentTypes['UserPrivacyError'];
  ScheduledClassList: ScheduledClassList;
  ScheduledClass: ScheduledClass;
  MetaTag: MetaTag;
  TagAssets: TagAssets;
  ActiveTagClassesPaginatedList: ActiveTagClassesPaginatedList;
  ActiveTagClassEdge: ActiveTagClassEdge;
  ActiveTagClass: ActiveTagClass;
  ActiveTagUserPaginatedList: ActiveTagUserPaginatedList;
  ActiveTagUserEdge: ActiveTagUserEdge;
  PopularTagClassesList: PopularTagClassesList;
  PopularTagClass: PopularTagClass;
  UserTag: UserTag;
  EntertainmentChannelsQueryResponse:
    | ResolversParentTypes['EntertainmentChannelPaginatedList']
    | ResolversParentTypes['PermissionError'];
  EntertainmentChannelPaginatedList: EntertainmentChannelPaginatedList;
  EntertainmentChannel: EntertainmentChannel;
  PermissionError: PermissionError;
  ProgramParamsInput: ProgramParamsInput;
  ProgramFiltersInput: ProgramFiltersInput;
  ProgramSortingInput: ProgramSortingInput;
  FreestyleMetadata: Omit<FreestyleMetadata, 'musicProviders'> & {
    musicProviders: Array<ResolversParentTypes['MusicProvider']>;
  };
  MusicProvider: ResolversParentTypes['PlaylistMusicProvider'];
  PlaylistMusicProvider: PlaylistMusicProvider;
  StreamablePlaylist: StreamablePlaylist;
  BasePlaylist:
    | ResolversParentTypes['StreamablePlaylist']
    | ResolversParentTypes['Playlist'];
  PlaylistPlaybackSong: PlaylistPlaybackSong;
  Song: Song;
  Artist: Artist;
  Album: Album;
  EventDetailsResponse:
    | ResolversParentTypes['ActivityFeedErrorResponse']
    | ResolversParentTypes['EventDetails'];
  ActivityFeedErrorResponse: ActivityFeedErrorResponse;
  EventDetails: EventDetails;
  ActivityFeedEvent: Omit<ActivityFeedEvent, 'payload'> & {
    payload: ResolversParentTypes['Payload'];
  };
  Payload:
    | ResolversParentTypes['AchievementAwardedEventPayload']
    | ResolversParentTypes['StreakAwardedEventPayload']
    | ResolversParentTypes['WorkoutFinalizedEventPayload'];
  AchievementAwardedEventPayload: AchievementAwardedEventPayload;
  ActivityFeedEventPayload:
    | ResolversParentTypes['AchievementAwardedEventPayload']
    | ResolversParentTypes['StreakAwardedEventPayload']
    | ResolversParentTypes['WorkoutFinalizedEventPayload'];
  FeedWorkout:
    | ResolversParentTypes['FreestyleFeedWorkout']
    | ResolversParentTypes['GymFeedWorkout']
    | ResolversParentTypes['OdysseyFeedWorkout']
    | ResolversParentTypes['PelotonClassFeedWorkout'];
  StreakAwardedEventPayload: StreakAwardedEventPayload;
  WorkoutFinalizedEventPayload: WorkoutFinalizedEventPayload;
  HighFiveDetails: HighFiveDetails;
  HighFiveDetailsResponse:
    | ResolversParentTypes['ActivityFeedErrorResponse']
    | ResolversParentTypes['HighFiveDetails'];
  NotificationDetailsResponse:
    | ResolversParentTypes['ActivityFeedErrorResponse']
    | ResolversParentTypes['NotificationDetails'];
  NotificationDetails: NotificationDetails;
  Notification: Notification;
  UnreadNotificationsResponse:
    | ResolversParentTypes['ActivityFeedErrorResponse']
    | ResolversParentTypes['UnreadNotifications'];
  UnreadNotifications: UnreadNotifications;
  CompletedStats: CompletedStats;
  ZoneScore: ZoneScore;
  OnDemandInstructorClass: OnDemandInstructorClass;
  DifficultyLevel: DifficultyLevel;
  Playlist: Playlist;
  InstructorCue: InstructorCue;
  OffsetsCue: OffsetsCue;
  RangeCue: RangeCue;
  TargetMetricsData: TargetMetricsData;
  TargetMetricsOutput: TargetMetricsOutput;
  PaceIntensity: PaceIntensity;
  PaceLevel: PaceLevel;
  Captions: Captions;
  ClassTopTag: ClassTopTag;
  GymPlanResponse:
    | ResolversParentTypes['GymErrorResponse']
    | ResolversParentTypes['GymPlan']
    | ResolversParentTypes['GymPlanPaginationResponse'];
  GymErrorResponse: GymErrorResponse;
  GymPlan: GymPlan;
  GymMovementGroup: GymMovementGroup;
  DisplayTag: DisplayTag;
  GroupTags: GroupTags;
  GymMovement: GymMovement;
  MovementVideo: MovementVideo;
  MovementMuscleGroup: MovementMuscleGroup;
  MuscleGroupScore: MuscleGroupScore;
  GymPlanPaginationResponse: GymPlanPaginationResponse;
  GymPlanEdge: GymPlanEdge;
  GymPlanFilterChip: GymPlanFilterChip;
  GymPlanFilterInput: GymPlanFilterInput;
  YourScheduleItem:
    | ResolversParentTypes['InviteDetails']
    | ResolversParentTypes['InviteFriendsHttpException']
    | ResolversParentTypes['InviteFriendsInvalidDataFormat']
    | ResolversParentTypes['InviteFriendsItemNotFoundError']
    | ResolversParentTypes['InviteFriendsNotAuthorized']
    | ResolversParentTypes['ScheduledClass'];
  InviteDetails: Omit<
    InviteDetails,
    'getInvitedAcceptedFollowing' | 'getInvitedUsers'
  > & {
    getInvitedAcceptedFollowing: ResolversParentTypes['InvitedUsersPaginationResponse'];
    getInvitedUsers: ResolversParentTypes['InvitedUsersPaginationResponse'];
  };
  InviteUser: InviteUser;
  InvitedUsersPaginationResponse:
    | ResolversParentTypes['InviteFriendsHttpException']
    | ResolversParentTypes['InviteFriendsNotAuthorized']
    | ResolversParentTypes['InvitedUsersPaginatedList'];
  InviteFriendsHttpException: InviteFriendsHttpException;
  InviteFriendsError:
    | ResolversParentTypes['InviteFriendsHttpException']
    | ResolversParentTypes['InviteFriendsNotAuthorized']
    | ResolversParentTypes['InviteFriendsInvalidDataFormat']
    | ResolversParentTypes['InviteFriendsItemNotFoundError']
    | ResolversParentTypes['InviteFriendsBadRequest']
    | ResolversParentTypes['InviteFriendsCreateShareLinkDisabledError']
    | ResolversParentTypes['InviteFriendsNoPermissionToCreateShareLinkError']
    | ResolversParentTypes['InviteFriendsInvitedUserSizeLimitExceeded'];
  InviteFriendsNotAuthorized: InviteFriendsNotAuthorized;
  InvitedUsersPaginatedList: InvitedUsersPaginatedList;
  InviteUserEdge: InviteUserEdge;
  Occasion: Occasion;
  OccasionAssets: OccasionAssets;
  InviteFriendsInvalidDataFormat: InviteFriendsInvalidDataFormat;
  InviteFriendsItemNotFoundError: InviteFriendsItemNotFoundError;
  JoinableClass: Omit<JoinableClass, 'videoStreamOrError'> & {
    videoStreamOrError: ResolversParentTypes['VideoStreamOrError'];
  };
  ActiveWorkout: ActiveWorkout;
  PauseInfo: PauseInfo;
  Peloton: Peloton;
  Workout: Workout;
  VideoStream: VideoStream;
  VideoStreamOrError:
    | ResolversParentTypes['ErrorVideoStream']
    | ResolversParentTypes['ReadyVideoStream'];
  ErrorVideoStream: ErrorVideoStream;
  JoinError:
    | ResolversParentTypes['JoinAuthorizationError']
    | ResolversParentTypes['JoinContentNotLiveError']
    | ResolversParentTypes['JoinNoActiveSubscriptionError']
    | ResolversParentTypes['JoinStreamLimitError'];
  ReadyVideoStream: ReadyVideoStream;
  LevelQueryResponse:
    | ResolversParentTypes['LevelSuccessResponse']
    | ResolversParentTypes['OdysseyItemNotFoundError'];
  LevelSuccessResponse: LevelSuccessResponse;
  Level: Omit<Level, 'musicProvider'> & {
    musicProvider?: Maybe<ResolversParentTypes['MusicProvider']>;
  };
  OdysseyLevelAssets: OdysseyLevelAssets;
  BestRuns: BestRuns;
  OdysseyWorkout: OdysseyWorkout;
  OdysseyLevelDifficultyDetail: OdysseyLevelDifficultyDetail;
  OdysseyItemNotFoundError: OdysseyItemNotFoundError;
  OdysseyError:
    | ResolversParentTypes['OdysseyItemNotFoundError']
    | ResolversParentTypes['OdysseyNotAuthorizedError']
    | ResolversParentTypes['OdysseyBadRequestError']
    | ResolversParentTypes['OdysseyInvalidWorkoutMutationError'];
  LevelListQueryResponse:
    | ResolversParentTypes['LevelListSuccessResponse']
    | ResolversParentTypes['OdysseyNotAuthorizedError'];
  LevelListSuccessResponse: LevelListSuccessResponse;
  OdysseyNotAuthorizedError: OdysseyNotAuthorizedError;
  OccasionsResponse: ResolversParentTypes['OccasionsList'];
  OccasionsList: OccasionsList;
  OdysseyConfigurationResponse:
    | ResolversParentTypes['OdysseyBikeConfiguration']
    | ResolversParentTypes['OdysseyItemNotFoundError']
    | ResolversParentTypes['OdysseyTreadConfiguration'];
  OdysseyBikeConfiguration: OdysseyBikeConfiguration;
  OdysseyBikeDifficultyConfiguration: OdysseyBikeDifficultyConfiguration;
  OdysseyBikeLaneConfiguration: OdysseyBikeLaneConfiguration;
  OdysseyBikeScoringConfiguration: OdysseyBikeScoringConfiguration;
  OdysseyStarThreshold: OdysseyStarThreshold;
  OdysseyTreadConfiguration: OdysseyTreadConfiguration;
  OdysseyTreadDifficultyConfiguration: OdysseyTreadDifficultyConfiguration;
  OdysseyTreadDifficultyIntensity: OdysseyTreadDifficultyIntensity;
  OdysseyTreadLaneConfiguration: OdysseyTreadLaneConfiguration;
  OdysseyTreadScoringConfiguration: OdysseyTreadScoringConfiguration;
  OdysseyDifficultyMetadata: OdysseyDifficultyMetadata;
  LeaderboardRequestInput: LeaderboardRequestInput;
  OdysseyLeaderboardQueryResponse:
    | ResolversParentTypes['OdysseyLeaderboardInvalidRequest']
    | ResolversParentTypes['OdysseyLeaderboardListSuccessResponse'];
  OdysseyLeaderboardInvalidRequest: OdysseyLeaderboardInvalidRequest;
  OdysseyLeaderboardGraphqlError: ResolversParentTypes['OdysseyLeaderboardInvalidRequest'];
  OdysseyLeaderboardListSuccessResponse: OdysseyLeaderboardListSuccessResponse;
  OdysseyLeaderboard: OdysseyLeaderboard;
  LeaderboardEntry: LeaderboardEntry;
  LeaderboardWorkoutDetails: LeaderboardWorkoutDetails;
  LevelFiltersQueryResponse:
    | ResolversParentTypes['LevelBrowseFiltersSuccessResponse']
    | ResolversParentTypes['OdysseyBadRequestError'];
  LevelBrowseFiltersSuccessResponse: Omit<
    LevelBrowseFiltersSuccessResponse,
    'filters'
  > & { filters: Array<ResolversParentTypes['OdysseyLevelFilter']> };
  OdysseyLevelBrowseFilter: Omit<OdysseyLevelBrowseFilter, 'info' | 'options'> & {
    info: ResolversParentTypes['OdysseyLevelFilterInfo'];
    options: Array<ResolversParentTypes['OdysseyLevelFilterOption']>;
  };
  OdysseyLevelFilterInfo:
    | ResolversParentTypes['OdysseyAccordionLevelFilterInfo']
    | ResolversParentTypes['OdysseyGridLevelFilterInfo']
    | ResolversParentTypes['OdysseyToggleLevelFilterInfo'];
  OdysseyAccordionLevelFilterInfo: OdysseyAccordionLevelFilterInfo;
  OdysseyIcon: OdysseyIcon;
  OdysseyGridLevelFilterInfo: OdysseyGridLevelFilterInfo;
  OdysseyToggleLevelFilterInfo: OdysseyToggleLevelFilterInfo;
  OdysseyLevelFilterOption:
    | ResolversParentTypes['OdysseyBasicLevelFilterOption']
    | ResolversParentTypes['OdysseyDurationLevelFilterOption']
    | ResolversParentTypes['OdysseyPlayedLevelFilterOption']
    | ResolversParentTypes['OdysseyStarsLevelFilterOption'];
  OdysseyBasicLevelFilterOption: OdysseyBasicLevelFilterOption;
  OdysseyDurationLevelFilterOption: OdysseyDurationLevelFilterOption;
  OdysseyPlayedLevelFilterOption: OdysseyPlayedLevelFilterOption;
  OdysseyStarsLevelFilterOption: OdysseyStarsLevelFilterOption;
  OdysseyLevelFilter:
    | ResolversParentTypes['OdysseyAccordionLevelFilter']
    | ResolversParentTypes['OdysseyGridLevelFilter']
    | ResolversParentTypes['OdysseyToggleLevelFilter'];
  OdysseyAccordionLevelFilter: Omit<OdysseyAccordionLevelFilter, 'options'> & {
    options: Array<ResolversParentTypes['OdysseyLevelFilterOption']>;
  };
  OdysseyGridLevelFilter: Omit<OdysseyGridLevelFilter, 'options'> & {
    options: Array<ResolversParentTypes['OdysseyLevelFilterOption']>;
  };
  OdysseyToggleLevelFilter: OdysseyToggleLevelFilter;
  OdysseyBadRequestError: OdysseyBadRequestError;
  UserLeaderboardRequestInput: UserLeaderboardRequestInput;
  OdysseyWorkoutQueryResponse:
    | ResolversParentTypes['OdysseyItemNotFoundError']
    | ResolversParentTypes['OdysseyNotAuthorizedError']
    | ResolversParentTypes['OdysseyWorkoutSuccessResponse'];
  OdysseyWorkoutSuccessResponse: OdysseyWorkoutSuccessResponse;
  ScheduledClassResponse:
    | ResolversParentTypes['ScheduledClass']
    | ResolversParentTypes['ScheduledClassDoesNotExistError']
    | ResolversParentTypes['CannotScheduleClassError'];
  ScheduledClassDoesNotExistError: ScheduledClassDoesNotExistError;
  CannotScheduleClassError: CannotScheduleClassError;
  SearchTagInput: SearchTagInput;
  TagPaginatedList: TagPaginatedList;
  TagEdge: TagEdge;
  SessionInput: SessionInput;
  SessionClass: SessionClass;
  LiveClass: Omit<LiveClass, 'peloton'> & {
    peloton: ResolversParentTypes['PelotonResult'];
  };
  PelotonResult:
    | ResolversParentTypes['Peloton']
    | ResolversParentTypes['NoClassPelotonForPlatformError']
    | ResolversParentTypes['PelotonNotCreatedError'];
  NoClassPelotonForPlatformError: NoClassPelotonForPlatformError;
  PelotonNotCreatedError: PelotonNotCreatedError;
  TagBrowseCategory: TagBrowseCategory;
  TagBrowseCategoryAssets: TagBrowseCategoryAssets;
  ActivityFeedEventResponse:
    | ResolversParentTypes['ActivityFeedErrorResponse']
    | ResolversParentTypes['ActivityFeedEvent']
    | ResolversParentTypes['ActivityFeedEventPaginationResponse'];
  ActivityFeedEventPaginationResponse: ActivityFeedEventPaginationResponse;
  ActivityFeedEventEdge: ActivityFeedEventEdge;
  YourScheduleListResponse:
    | ResolversParentTypes['InviteFriendsBadRequest']
    | ResolversParentTypes['InviteFriendsItemNotFoundError']
    | ResolversParentTypes['InviteFriendsNotAuthorized']
    | ResolversParentTypes['YourScheduleItemList'];
  InviteFriendsBadRequest: InviteFriendsBadRequest;
  YourScheduleItemList: Omit<
    YourScheduleItemList,
    'pendingInvites' | 'yourScheduleItems'
  > & {
    pendingInvites: ResolversParentTypes['PendingInvitesResponse'];
    yourScheduleItems: Array<ResolversParentTypes['YourScheduleItem']>;
  };
  PendingInvitesResponse:
    | ResolversParentTypes['InviteFriendsItemNotFoundError']
    | ResolversParentTypes['PendingInvites'];
  PendingInvites: PendingInvites;
  StackResponse:
    | ResolversParentTypes['StackConnectionError']
    | ResolversParentTypes['StackDoesNotExist']
    | ResolversParentTypes['StackError']
    | ResolversParentTypes['StackPermissionError']
    | ResolversParentTypes['StackResponseSuccess']
    | ResolversParentTypes['StackedClassAlreadyExistsError']
    | ResolversParentTypes['StackedClassCannotBeAddedError']
    | ResolversParentTypes['StackedClassConstraintViolationError']
    | ResolversParentTypes['StackedClassDoesNotExistInStackError']
    | ResolversParentTypes['StackedClassInvalidPelotonClassIdError']
    | ResolversParentTypes['StackedClassLimitReachedError'];
  UserStack: UserStack;
  StackedClass: StackedClass;
  WorkoutPostClassResponse: WorkoutPostClassResponse;
  WorkoutMetricDetails: WorkoutMetricDetails;
  Mutation: {};
  AbandonOdysseyWorkoutResponse:
    | ResolversParentTypes['OdysseyInvalidWorkoutMutationError']
    | ResolversParentTypes['OdysseyItemNotFoundError']
    | ResolversParentTypes['OdysseyWorkoutSuccessResponse'];
  OdysseyInvalidWorkoutMutationError: OdysseyInvalidWorkoutMutationError;
  ScheduledClassInput: ScheduledClassInput;
  AddClassToStackInput: AddClassToStackInput;
  AddTagToUserInput: AddTagToUserInput;
  AddTagToUserResponse:
    | ResolversParentTypes['AddTagToUserSuccess']
    | ResolversParentTypes['DoesNotExistError']
    | ResolversParentTypes['TagAlreadyExistsError']
    | ResolversParentTypes['TagCapacityOverLimitError']
    | ResolversParentTypes['TagNameInvalidCharacterError']
    | ResolversParentTypes['TagNameLengthError']
    | ResolversParentTypes['TagNameProhibitedError'];
  AddTagToUserSuccess: AddTagToUserSuccess;
  DoesNotExistError: DoesNotExistError;
  TagAlreadyExistsError: TagAlreadyExistsError;
  TagCapacityOverLimitError: TagCapacityOverLimitError;
  TagNameInvalidCharacterError: TagNameInvalidCharacterError;
  TagNameLengthError: TagNameLengthError;
  TagNameProhibitedError: TagNameProhibitedError;
  CompleteOdysseyWorkoutInput: CompleteOdysseyWorkoutInput;
  OdysseyBikeCompletionInput: OdysseyBikeCompletionInput;
  OdysseyTreadCompletionInput: OdysseyTreadCompletionInput;
  CompleteOdysseyWorkoutResponse:
    | ResolversParentTypes['OdysseyBadRequestError']
    | ResolversParentTypes['OdysseyInvalidWorkoutMutationError']
    | ResolversParentTypes['OdysseyItemNotFoundError']
    | ResolversParentTypes['OdysseyWorkoutSuccessResponse'];
  InviteShareURLResponse:
    | ResolversParentTypes['InviteFriendsCreateShareLinkDisabledError']
    | ResolversParentTypes['InviteFriendsInvalidDataFormat']
    | ResolversParentTypes['InviteFriendsItemNotFoundError']
    | ResolversParentTypes['InviteFriendsNoPermissionToCreateShareLinkError']
    | ResolversParentTypes['ShareURL'];
  InviteFriendsCreateShareLinkDisabledError: InviteFriendsCreateShareLinkDisabledError;
  InviteFriendsNoPermissionToCreateShareLinkError: InviteFriendsNoPermissionToCreateShareLinkError;
  ShareURL: ShareUrl;
  CreateLiveInviteInput: CreateLiveInviteInput;
  UserInput: UserInput;
  InviteResponse:
    | ResolversParentTypes['InviteDetails']
    | ResolversParentTypes['InviteFriendsHttpException']
    | ResolversParentTypes['InviteFriendsInvalidDataFormat']
    | ResolversParentTypes['InviteFriendsInvitedUserSizeLimitExceeded']
    | ResolversParentTypes['InviteFriendsItemNotFoundError']
    | ResolversParentTypes['InviteFriendsNotAuthorized'];
  InviteFriendsInvitedUserSizeLimitExceeded: InviteFriendsInvitedUserSizeLimitExceeded;
  CreateNewOnDemandInviteInput: CreateNewOnDemandInviteInput;
  CreateOdysseyWorkoutInput: CreateOdysseyWorkoutInput;
  CreateOdysseyWorkoutResponse: ResolversParentTypes['OdysseyWorkoutSuccessResponse'];
  CreateScheduledOnDemandInviteInput: CreateScheduledOnDemandInviteInput;
  InviteUsersToInviteInput: InviteUsersToInviteInput;
  ProgramProgressResponse:
    | ResolversParentTypes['ProgramProgressAlreadyExistsError']
    | ResolversParentTypes['ProgramProgressNotFoundError']
    | ResolversParentTypes['ProgramProgressResponseSuccess']
    | ResolversParentTypes['UserNotAuthorizedError'];
  ProgramProgressAlreadyExistsError: ProgramProgressAlreadyExistsError;
  ProgramProgressNotFoundError: ProgramProgressNotFoundError;
  ProgramProgressResponseSuccess: ProgramProgressResponseSuccess;
  UserNotAuthorizedError: UserNotAuthorizedError;
  ModifyStackInput: ModifyStackInput;
  PauseWorkoutInput: PauseWorkoutInput;
  PlayClassFromStackInput: PlayClassFromStackInput;
  RemoveTagFromUserInput: RemoveTagFromUserInput;
  RemoveTagFromUserResponse:
    | ResolversParentTypes['RemoveTagFromUserSuccess']
    | ResolversParentTypes['DoesNotExistError'];
  RemoveTagFromUserSuccess: RemoveTagFromUserSuccess;
  ReplaceTagInput: ReplaceTagInput;
  ReplaceTagResponse:
    | ResolversParentTypes['ReplaceTagSuccess']
    | ResolversParentTypes['TagAlreadyExistsError']
    | ResolversParentTypes['DoesNotExistError']
    | ResolversParentTypes['TagNameInvalidCharacterError']
    | ResolversParentTypes['TagNameLengthError']
    | ResolversParentTypes['TagNameProhibitedError'];
  ReplaceTagSuccess: ReplaceTagSuccess;
  RescheduleClassInput: RescheduleClassInput;
  SetTagAsPrimaryInput: SetTagAsPrimaryInput;
  SetTagAsPrimaryResponse:
    | ResolversParentTypes['SetTagAsPrimarySuccess']
    | ResolversParentTypes['TagDoesNotExistOnUserError'];
  SetTagAsPrimarySuccess: SetTagAsPrimarySuccess;
  TagDoesNotExistOnUserError: TagDoesNotExistOnUserError;
  StartFreestyleWorkoutInput: StartFreestyleWorkoutInput;
  FreestyleWorkoutGoalInput: FreestyleWorkoutGoalInput;
  StartFreestyleWorkoutResponse:
    | ResolversParentTypes['StartFreestyleWorkoutResponseSuccess']
    | ResolversParentTypes['SubscriptionIdNotFoundError']
    | ResolversParentTypes['UserNotAuthorizedError'];
  StartFreestyleWorkoutResponseSuccess: StartFreestyleWorkoutResponseSuccess;
  SubscriptionIdNotFoundError: SubscriptionIdNotFoundError;
  StartGymWorkoutInput: StartGymWorkoutInput;
  StartGymWorkoutResponse:
    | ResolversParentTypes['GymErrorResponse']
    | ResolversParentTypes['StartGymWorkoutResponseSuccess'];
  StartGymWorkoutResponseSuccess: StartGymWorkoutResponseSuccess;
  GymWorkout: GymWorkout;
  StartClassInput: StartClassInput;
  UnpauseWorkoutInput: UnpauseWorkoutInput;
  UnpauseWorkoutResponse: UnpauseWorkoutResponse;
  UpdateRelationshipRequest: UpdateRelationshipRequest;
  UpdateRelationshipResponse:
    | ResolversParentTypes['AlreadyExists']
    | ResolversParentTypes['CantUnfollow']
    | ResolversParentTypes['UpdateRelationshipSuccess'];
  AlreadyExists: AlreadyExists;
  CantUnfollow: CantUnfollow;
  UpdateRelationshipSuccess: UpdateRelationshipSuccess;
  RetryableError: ResolversParentTypes['JoinContentNotLiveError'];
  JoinAuthorizationError: JoinAuthorizationError;
  JoinContentNotLiveError: JoinContentNotLiveError;
  JoinNoActiveSubscriptionError: JoinNoActiveSubscriptionError;
  JoinStreamLimitError: JoinStreamLimitError;
  _FieldSet: Scalars['_FieldSet'];
  StackConnectionError: StackConnectionError;
  StackDoesNotExist: StackDoesNotExist;
  StackError: StackError;
  StackPermissionError: StackPermissionError;
  StackResponseSuccess: StackResponseSuccess;
  StackedClassAlreadyExistsError: StackedClassAlreadyExistsError;
  StackedClassCannotBeAddedError: StackedClassCannotBeAddedError;
  StackedClassConstraintViolationError: StackedClassConstraintViolationError;
  StackedClassDoesNotExistInStackError: StackedClassDoesNotExistInStackError;
  StackedClassInvalidPelotonClassIdError: StackedClassInvalidPelotonClassIdError;
  StackedClassLimitReachedError: StackedClassLimitReachedError;
  ScenicClass: Omit<ScenicClass, 'musicProviders'> & {
    musicProviders: Array<Maybe<ResolversParentTypes['MusicProvider']>>;
  };
  FreestyleFeedWorkout: FreestyleFeedWorkout;
  GymFeedWorkout: GymFeedWorkout;
  OdysseyFeedWorkout: OdysseyFeedWorkout;
  PelotonClassFeedWorkout: PelotonClassFeedWorkout;
};

export interface _FieldSetScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['_FieldSet'], any> {
  name: '_FieldSet';
}

export type AbandonOdysseyWorkoutResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AbandonOdysseyWorkoutResponse'] = ResolversParentTypes['AbandonOdysseyWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyInvalidWorkoutMutationError'
    | 'OdysseyItemNotFoundError'
    | 'OdysseyWorkoutSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type AchievementAwardedEventPayloadResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AchievementAwardedEventPayload'] = ResolversParentTypes['AchievementAwardedEventPayload'],
> = {
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveTagClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActiveTagClass'] = ResolversParentTypes['ActiveTagClass'],
> = {
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  activeUsers?: Resolver<
    ResolversTypes['ActiveTagUserPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<ActiveTagClassActiveUsersArgs, 'limit' | 'after'>
  >;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveTagClassEdgeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActiveTagClassEdge'] = ResolversParentTypes['ActiveTagClassEdge'],
> = {
  node?: Resolver<ResolversTypes['ActiveTagClass'], ParentType, ContextType>;
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveTagClassesPaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActiveTagClassesPaginatedList'] = ResolversParentTypes['ActiveTagClassesPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['ActiveTagClassEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveTagUserEdgeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActiveTagUserEdge'] = ResolversParentTypes['ActiveTagUserEdge'],
> = {
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveTagUserPaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActiveTagUserPaginatedList'] = ResolversParentTypes['ActiveTagUserPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['ActiveTagUserEdge']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActiveWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActiveWorkout'] = ResolversParentTypes['ActiveWorkout'],
> = {
  actualStartTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isSkipIntroAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pauseInfo?: Resolver<Maybe<ResolversTypes['PauseInfo']>, ParentType, ContextType>;
  peloton?: Resolver<ResolversTypes['Peloton'], ParentType, ContextType>;
  workout?: Resolver<ResolversTypes['Workout'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActivityFeedErrorResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActivityFeedErrorResponse'] = ResolversParentTypes['ActivityFeedErrorResponse'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ActivityFeedErrorType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActivityFeedEventResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActivityFeedEvent'] = ResolversParentTypes['ActivityFeedEvent'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  feedEventType?: Resolver<
    Maybe<ResolversTypes['FeedEventType']>,
    ParentType,
    ContextType
  >;
  highFivedUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  highFives?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isHighFived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  payload?: Resolver<ResolversTypes['Payload'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  workoutMetricsType?: Resolver<
    ResolversTypes['WorkoutMetricsType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActivityFeedEventEdgeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActivityFeedEventEdge'] = ResolversParentTypes['ActivityFeedEventEdge'],
> = {
  activityFeedEvent?: Resolver<
    ResolversTypes['ActivityFeedEvent'],
    ParentType,
    ContextType
  >;
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActivityFeedEventPaginationResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActivityFeedEventPaginationResponse'] = ResolversParentTypes['ActivityFeedEventPaginationResponse'],
> = {
  edges?: Resolver<
    Array<ResolversTypes['ActivityFeedEventEdge']>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActivityFeedEventPayloadResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActivityFeedEventPayload'] = ResolversParentTypes['ActivityFeedEventPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'AchievementAwardedEventPayload'
    | 'StreakAwardedEventPayload'
    | 'WorkoutFinalizedEventPayload',
    ParentType,
    ContextType
  >;
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type ActivityFeedEventResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActivityFeedEventResponse'] = ResolversParentTypes['ActivityFeedEventResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'ActivityFeedErrorResponse'
    | 'ActivityFeedEvent'
    | 'ActivityFeedEventPaginationResponse',
    ParentType,
    ContextType
  >;
};

export type AddTagToUserResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AddTagToUserResponse'] = ResolversParentTypes['AddTagToUserResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'AddTagToUserSuccess'
    | 'DoesNotExistError'
    | 'TagAlreadyExistsError'
    | 'TagCapacityOverLimitError'
    | 'TagNameInvalidCharacterError'
    | 'TagNameLengthError'
    | 'TagNameProhibitedError',
    ParentType,
    ContextType
  >;
};

export type AddTagToUserSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AddTagToUserSuccess'] = ResolversParentTypes['AddTagToUserSuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlbumResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Album'] = ResolversParentTypes['Album'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AlreadyExistsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AlreadyExists'] = ResolversParentTypes['AlreadyExists'],
> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ArtistResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Artist'] = ResolversParentTypes['Artist'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AwardedProgramAchievementTierResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AwardedProgramAchievementTier'] = ResolversParentTypes['AwardedProgramAchievementTier'],
> = {
  achievementTier?: Resolver<
    ResolversTypes['ProgramAchievementTier'],
    ParentType,
    ContextType
  >;
  awardedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  numClassesCompleted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BasePlaylistResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['BasePlaylist'] = ResolversParentTypes['BasePlaylist'],
> = {
  __resolveType: TypeResolveFn<
    'StreamablePlaylist' | 'Playlist',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInClassMusicShown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playbackSongs?: Resolver<
    Array<ResolversTypes['PlaylistPlaybackSong']>,
    ParentType,
    ContextType
  >;
  topArtists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
};

export type BestRunsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['BestRuns'] = ResolversParentTypes['BestRuns'],
> = {
  bestRunsByCompletionPercentage?: Resolver<
    Array<ResolversTypes['OdysseyWorkout']>,
    ParentType,
    ContextType
  >;
  bestRunsByScore?: Resolver<
    Array<ResolversTypes['OdysseyWorkout']>,
    ParentType,
    ContextType
  >;
  starsEarned?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CannotScheduleClassErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CannotScheduleClassError'] = ResolversParentTypes['CannotScheduleClassError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CantUnfollowResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CantUnfollow'] = ResolversParentTypes['CantUnfollow'],
> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CaptionsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Captions'] = ResolversParentTypes['Captions'],
> = {
  locales?: Resolver<Array<ResolversTypes['LocaleCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClassAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ClassAssets'] = ResolversParentTypes['ClassAssets'],
> = {
  thumbnailImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClassSegmentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ClassSegment'] = ResolversParentTypes['ClassSegment'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeOffset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  iconDetails?: Resolver<ResolversTypes['IconDetails'], ParentType, ContextType>;
  metricsType?: Resolver<ResolversTypes['ClassSegmentMetric'], ParentType, ContextType>;
  intensityInMets?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isDrill?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  subsegments?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassSubsegment']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClassSubsegmentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ClassSubsegment'] = ResolversParentTypes['ClassSubsegment'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduledOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rounds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  trackableMovementsDisabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  movements?: Resolver<Array<Maybe<ResolversTypes['Movement']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClassTimelineResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ClassTimeline'] = ResolversParentTypes['ClassTimeline'],
> = {
  startOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoEndOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  classSegments?: Resolver<
    Array<ResolversTypes['ClassSegment']>,
    ParentType,
    ContextType
  >;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClassTopTagResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ClassTopTag'] = ResolversParentTypes['ClassTopTag'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ClassTypeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ClassType'] = ResolversParentTypes['ClassType'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ColorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Color'] = ResolversParentTypes['Color'],
> = {
  hexCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CompletedStatsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CompletedStats'] = ResolversParentTypes['CompletedStats'],
> = {
  workoutId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zoneScore?: Resolver<ResolversTypes['ZoneScore'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CompleteOdysseyWorkoutResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CompleteOdysseyWorkoutResponse'] = ResolversParentTypes['CompleteOdysseyWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyBadRequestError'
    | 'OdysseyInvalidWorkoutMutationError'
    | 'OdysseyItemNotFoundError'
    | 'OdysseyWorkoutSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type CreateOdysseyWorkoutResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CreateOdysseyWorkoutResponse'] = ResolversParentTypes['CreateOdysseyWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<'OdysseyWorkoutSuccessResponse', ParentType, ContextType>;
};

export interface CursorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Cursor'], any> {
  name: 'Cursor';
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DifficultyLevelResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DifficultyLevel'] = ResolversParentTypes['DifficultyLevel'],
> = {
  slug?: Resolver<ResolversTypes['DifficultyLevelSlug'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DisplayTagResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DisplayTag'] = ResolversParentTypes['DisplayTag'],
> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DoesNotExistErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DoesNotExistError'] = ResolversParentTypes['DoesNotExistError'],
> = {
  resource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntertainmentChannelResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['EntertainmentChannel'] = ResolversParentTypes['EntertainmentChannel'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  backgroundImage?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  userAgent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntertainmentChannelPaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['EntertainmentChannelPaginatedList'] = ResolversParentTypes['EntertainmentChannelPaginatedList'],
> = {
  edges?: Resolver<
    Array<ResolversTypes['EntertainmentChannel']>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EntertainmentChannelsQueryResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['EntertainmentChannelsQueryResponse'] = ResolversParentTypes['EntertainmentChannelsQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'EntertainmentChannelPaginatedList' | 'PermissionError',
    ParentType,
    ContextType
  >;
};

export type EquipmentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Equipment'] = ResolversParentTypes['Equipment'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error'],
> = {
  __resolveType: TypeResolveFn<
    | 'UserPrivacyError'
    | 'PermissionError'
    | 'ScheduledClassDoesNotExistError'
    | 'CannotScheduleClassError'
    | 'NoClassPelotonForPlatformError'
    | 'PelotonNotCreatedError'
    | 'DoesNotExistError'
    | 'TagAlreadyExistsError'
    | 'TagCapacityOverLimitError'
    | 'TagNameInvalidCharacterError'
    | 'TagNameLengthError'
    | 'TagNameProhibitedError'
    | 'TagDoesNotExistOnUserError'
    | 'StackConnectionError'
    | 'StackDoesNotExist'
    | 'StackError'
    | 'StackPermissionError'
    | 'StackedClassAlreadyExistsError'
    | 'StackedClassCannotBeAddedError'
    | 'StackedClassConstraintViolationError'
    | 'StackedClassDoesNotExistInStackError'
    | 'StackedClassInvalidPelotonClassIdError'
    | 'StackedClassLimitReachedError',
    ParentType,
    ContextType
  >;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type ErrorVideoStreamResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ErrorVideoStream'] = ResolversParentTypes['ErrorVideoStream'],
> = {
  joinError?: Resolver<ResolversTypes['JoinError'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EventDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['EventDetails'] = ResolversParentTypes['EventDetails'],
> = {
  activityFeedEvent?: Resolver<
    ResolversTypes['ActivityFeedEvent'],
    ParentType,
    ContextType
  >;
  highFiveDetails?: Resolver<ResolversTypes['HighFiveDetails'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EventDetailsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['EventDetailsResponse'] = ResolversParentTypes['EventDetailsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'EventDetails',
    ParentType,
    ContextType
  >;
};

export type FeedWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FeedWorkout'] = ResolversParentTypes['FeedWorkout'],
> = {
  __resolveType: TypeResolveFn<
    | 'FreestyleFeedWorkout'
    | 'GymFeedWorkout'
    | 'OdysseyFeedWorkout'
    | 'PelotonClassFeedWorkout',
    ParentType,
    ContextType
  >;
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
};

export type FitnessDisciplineResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FitnessDiscipline'] = ResolversParentTypes['FitnessDiscipline'],
> = {
  slug?: Resolver<ResolversTypes['FitnessDisciplineSlug'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FollowingUsersPaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FollowingUsersPaginatedList'] = ResolversParentTypes['FollowingUsersPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FreestyleFeedWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FreestyleFeedWorkout'] = ResolversParentTypes['FreestyleFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  freestyleActivityTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  freestyleImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isOutdoor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FreestyleMetadataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FreestyleMetadata'] = ResolversParentTypes['FreestyleMetadata'],
> = {
  musicProviders?: Resolver<
    Array<ResolversTypes['MusicProvider']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GroupTagsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GroupTags'] = ResolversParentTypes['GroupTags'],
> = {
  durationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  groupType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ladderComposition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  movementOrder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  splits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalRounds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymErrorResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymErrorResponse'] = ResolversParentTypes['GymErrorResponse'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GymErrorType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymFeedWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymFeedWorkout'] = ResolversParentTypes['GymFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  gymPlanId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymMovementResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymMovement'] = ResolversParentTypes['GymMovement'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isRepsMirrored?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  longVideo?: Resolver<Maybe<ResolversTypes['MovementVideo']>, ParentType, ContextType>;
  longVideoId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  longVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  movementPattern?: Resolver<ResolversTypes['MovementPattern'], ParentType, ContextType>;
  muscleGroups?: Resolver<
    Array<ResolversTypes['MovementMuscleGroup']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shortVideo?: Resolver<Maybe<ResolversTypes['MovementVideo']>, ParentType, ContextType>;
  shortVideoId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  shortVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suggestedTimeInSeconds?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymMovementGroupResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymMovementGroup'] = ResolversParentTypes['GymMovementGroup'],
> = {
  displayTags?: Resolver<Array<ResolversTypes['DisplayTag']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupTags?: Resolver<Maybe<ResolversTypes['GroupTags']>, ParentType, ContextType>;
  gymMovements?: Resolver<Array<ResolversTypes['GymMovement']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intensity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  movementGroupType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymPlanResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymPlan'] = ResolversParentTypes['GymPlan'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  difficultyDisplayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  durationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gymMovementGroups?: Resolver<
    Array<ResolversTypes['GymMovementGroup']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<Maybe<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isTaken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  muscleGroupScore?: Resolver<
    Array<ResolversTypes['MuscleGroupScore']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfMovementGroups?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfMovements?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publishedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workoutType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workoutTypeDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymPlanEdgeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymPlanEdge'] = ResolversParentTypes['GymPlanEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  gymPlan?: Resolver<ResolversTypes['GymPlan'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymPlanFilterChipResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymPlanFilterChip'] = ResolversParentTypes['GymPlanFilterChip'],
> = {
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymPlanPaginationResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymPlanPaginationResponse'] = ResolversParentTypes['GymPlanPaginationResponse'],
> = {
  edges?: Resolver<Array<ResolversTypes['GymPlanEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GymPlanResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymPlanResponse'] = ResolversParentTypes['GymPlanResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GymErrorResponse' | 'GymPlan' | 'GymPlanPaginationResponse',
    ParentType,
    ContextType
  >;
};

export type GymWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GymWorkout'] = ResolversParentTypes['GymWorkout'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HeroImageAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['HeroImageAssets'] = ResolversParentTypes['HeroImageAssets'],
> = {
  center?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  progress?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  right?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  tv?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HighFiveDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['HighFiveDetails'] = ResolversParentTypes['HighFiveDetails'],
> = {
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type HighFiveDetailsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['HighFiveDetailsResponse'] = ResolversParentTypes['HighFiveDetailsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'HighFiveDetails',
    ParentType,
    ContextType
  >;
};

export type IconDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['IconDetails'] = ResolversParentTypes['IconDetails'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InstructorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Instructor'] = ResolversParentTypes['Instructor'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['InstructorAssets'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InstructorAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InstructorAssets'] = ResolversParentTypes['InstructorAssets'],
> = {
  profileImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  aboutImage?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InstructorCueResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InstructorCue'] = ResolversParentTypes['InstructorCue'],
> = {
  offsets?: Resolver<ResolversTypes['OffsetsCue'], ParentType, ContextType>;
  resistanceRange?: Resolver<ResolversTypes['RangeCue'], ParentType, ContextType>;
  cadenceRange?: Resolver<ResolversTypes['RangeCue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteDetails'] = ResolversParentTypes['InviteDetails'],
> = {
  authedInvitedUser?: Resolver<
    Maybe<ResolversTypes['InviteUser']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  getInvitedAcceptedFollowing?: Resolver<
    ResolversTypes['InvitedUsersPaginationResponse'],
    ParentType,
    ContextType,
    RequireFields<InviteDetailsGetInvitedAcceptedFollowingArgs, never>
  >;
  getInvitedUsers?: Resolver<
    ResolversTypes['InvitedUsersPaginationResponse'],
    ParentType,
    ContextType,
    RequireFields<InviteDetailsGetInvitedUsersArgs, never>
  >;
  hostUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  inviteId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inviteStatus?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  occasion?: Resolver<Maybe<ResolversTypes['Occasion']>, ParentType, ContextType>;
  scheduledClass?: Resolver<ResolversTypes['ScheduledClass'], ParentType, ContextType>;
  scheduledClassId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['Visibility'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InvitedUsersPaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InvitedUsersPaginatedList'] = ResolversParentTypes['InvitedUsersPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['InviteUserEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InvitedUsersPaginationResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InvitedUsersPaginationResponse'] = ResolversParentTypes['InvitedUsersPaginationResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsHttpException'
    | 'InviteFriendsNotAuthorized'
    | 'InvitedUsersPaginatedList',
    ParentType,
    ContextType
  >;
};

export type InviteFriendsBadRequestResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsBadRequest'] = ResolversParentTypes['InviteFriendsBadRequest'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFriendsCreateShareLinkDisabledErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsCreateShareLinkDisabledError'] = ResolversParentTypes['InviteFriendsCreateShareLinkDisabledError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFriendsErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsError'] = ResolversParentTypes['InviteFriendsError'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsHttpException'
    | 'InviteFriendsNotAuthorized'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsBadRequest'
    | 'InviteFriendsCreateShareLinkDisabledError'
    | 'InviteFriendsNoPermissionToCreateShareLinkError'
    | 'InviteFriendsInvitedUserSizeLimitExceeded',
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type InviteFriendsHttpExceptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsHttpException'] = ResolversParentTypes['InviteFriendsHttpException'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFriendsInvalidDataFormatResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsInvalidDataFormat'] = ResolversParentTypes['InviteFriendsInvalidDataFormat'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFriendsInvitedUserSizeLimitExceededResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsInvitedUserSizeLimitExceeded'] = ResolversParentTypes['InviteFriendsInvitedUserSizeLimitExceeded'],
> = {
  invitedUserSizeLimit?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFriendsItemNotFoundErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsItemNotFoundError'] = ResolversParentTypes['InviteFriendsItemNotFoundError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFriendsNoPermissionToCreateShareLinkErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsNoPermissionToCreateShareLinkError'] = ResolversParentTypes['InviteFriendsNoPermissionToCreateShareLinkError'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteFriendsNotAuthorizedResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteFriendsNotAuthorized'] = ResolversParentTypes['InviteFriendsNotAuthorized'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteResponse'] = ResolversParentTypes['InviteResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteDetails'
    | 'InviteFriendsHttpException'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsInvitedUserSizeLimitExceeded'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNotAuthorized',
    ParentType,
    ContextType
  >;
};

export type InviteShareUrlResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteShareURLResponse'] = ResolversParentTypes['InviteShareURLResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsCreateShareLinkDisabledError'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNoPermissionToCreateShareLinkError'
    | 'ShareURL',
    ParentType,
    ContextType
  >;
};

export type InviteUserResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteUser'] = ResolversParentTypes['InviteUser'],
> = {
  invitedUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  invitedUserStatus?: Resolver<
    ResolversTypes['InvitedUserStatus'],
    ParentType,
    ContextType
  >;
  invitorUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type InviteUserEdgeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['InviteUserEdge'] = ResolversParentTypes['InviteUserEdge'],
> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  inviteUserItem?: Resolver<ResolversTypes['InviteUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JoinableClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['JoinableClass'] = ResolversParentTypes['JoinableClass'],
> = {
  activeWorkout?: Resolver<
    Maybe<ResolversTypes['ActiveWorkout']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  videoStream?: Resolver<ResolversTypes['VideoStream'], ParentType, ContextType>;
  videoStreamOrError?: Resolver<
    ResolversTypes['VideoStreamOrError'],
    ParentType,
    ContextType
  >;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JoinAuthorizationErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['JoinAuthorizationError'] = ResolversParentTypes['JoinAuthorizationError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JoinContentNotLiveErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['JoinContentNotLiveError'] = ResolversParentTypes['JoinContentNotLiveError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  retryAfterSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  retryMaxDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JoinErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['JoinError'] = ResolversParentTypes['JoinError'],
> = {
  __resolveType: TypeResolveFn<
    | 'JoinAuthorizationError'
    | 'JoinContentNotLiveError'
    | 'JoinNoActiveSubscriptionError'
    | 'JoinStreamLimitError',
    ParentType,
    ContextType
  >;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type JoinNoActiveSubscriptionErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['JoinNoActiveSubscriptionError'] = ResolversParentTypes['JoinNoActiveSubscriptionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type JoinStreamLimitErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['JoinStreamLimitError'] = ResolversParentTypes['JoinStreamLimitError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface JoinTokenScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JoinToken'], any> {
  name: 'JoinToken';
}

export type LeaderboardEntryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LeaderboardEntry'] = ResolversParentTypes['LeaderboardEntry'],
> = {
  completionPercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  difficulty?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workoutDetails?: Resolver<
    Maybe<ResolversTypes['LeaderboardWorkoutDetails']>,
    ParentType,
    ContextType
  >;
  workoutId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LeaderboardWorkoutDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LeaderboardWorkoutDetails'] = ResolversParentTypes['LeaderboardWorkoutDetails'],
> = {
  avatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workoutId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LevelResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Level'] = ResolversParentTypes['Level'],
> = {
  assets?: Resolver<ResolversTypes['OdysseyLevelAssets'], ParentType, ContextType>;
  availability?: Resolver<ResolversTypes['LevelAvailability'], ParentType, ContextType>;
  bestRuns?: Resolver<Maybe<ResolversTypes['BestRuns']>, ParentType, ContextType>;
  difficultyDetails?: Resolver<
    Maybe<Array<ResolversTypes['OdysseyLevelDifficultyDetail']>>,
    ParentType,
    ContextType
  >;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  durationCategory?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  explicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  featuredArtists?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filterOptionIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  playlistId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  volume?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  musicProvider?: Resolver<
    Maybe<ResolversTypes['MusicProvider']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LevelBrowseFiltersSuccessResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LevelBrowseFiltersSuccessResponse'] = ResolversParentTypes['LevelBrowseFiltersSuccessResponse'],
> = {
  browseFilters?: Resolver<
    Array<ResolversTypes['OdysseyLevelBrowseFilter']>,
    ParentType,
    ContextType
  >;
  filters?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilter']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LevelFiltersQueryResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LevelFiltersQueryResponse'] = ResolversParentTypes['LevelFiltersQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'LevelBrowseFiltersSuccessResponse' | 'OdysseyBadRequestError',
    ParentType,
    ContextType
  >;
};

export type LevelListQueryResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LevelListQueryResponse'] = ResolversParentTypes['LevelListQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'LevelListSuccessResponse' | 'OdysseyNotAuthorizedError',
    ParentType,
    ContextType
  >;
};

export type LevelListSuccessResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LevelListSuccessResponse'] = ResolversParentTypes['LevelListSuccessResponse'],
> = {
  levels?: Resolver<Array<ResolversTypes['Level']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LevelQueryResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LevelQueryResponse'] = ResolversParentTypes['LevelQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'LevelSuccessResponse' | 'OdysseyItemNotFoundError',
    ParentType,
    ContextType
  >;
};

export type LevelSuccessResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LevelSuccessResponse'] = ResolversParentTypes['LevelSuccessResponse'],
> = {
  level?: Resolver<ResolversTypes['Level'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LiveClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LiveClass'] = ResolversParentTypes['LiveClass'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType>;
  airTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  streamStartTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  scheduledStartTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  scheduledStartTimeWithTapeDelay?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  actualStartTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEncore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  peloton?: Resolver<ResolversTypes['PelotonResult'], ParentType, ContextType>;
  liveClassCategory?: Resolver<
    ResolversTypes['LiveClassCategory'],
    ParentType,
    ContextType
  >;
  difficultyLevel?: Resolver<
    Maybe<ResolversTypes['DifficultyLevel']>,
    ParentType,
    ContextType
  >;
  equipment?: Resolver<Array<ResolversTypes['Equipment']>, ParentType, ContextType>;
  isFtpTest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playlist?: Resolver<Maybe<ResolversTypes['Playlist']>, ParentType, ContextType>;
  instructorCues?: Resolver<
    Maybe<Array<ResolversTypes['InstructorCue']>>,
    ParentType,
    ContextType
  >;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  targetMetricsData?: Resolver<
    Maybe<ResolversTypes['TargetMetricsData']>,
    ParentType,
    ContextType
  >;
  captions?: Resolver<Maybe<ResolversTypes['Captions']>, ParentType, ContextType>;
  isExplicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  explicitRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LocaleResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Locale'] = ResolversParentTypes['Locale'],
> = {
  code?: Resolver<ResolversTypes['LocaleCode'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MediaResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media'],
> = {
  location?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MetaTagResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MetaTag'] = ResolversParentTypes['MetaTag'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MetricResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Metric'] = ResolversParentTypes['Metric'],
> = {
  name?: Resolver<Maybe<ResolversTypes['MetricName']>, ParentType, ContextType>;
  upper?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lower?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MovementResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Movement'] = ResolversParentTypes['Movement'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leonardoExerciseIds?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  movementVariationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  trackingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weightsAndArms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repetitionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  talkbackDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subsegmentMappingId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  targetNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weightLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MovementMuscleGroupResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MovementMuscleGroup'] = ResolversParentTypes['MovementMuscleGroup'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  muscleGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ranking?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MovementVideoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MovementVideo'] = ResolversParentTypes['MovementVideo'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tokenExpiresAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MovementVideoType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MuscleGroupScoreResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MuscleGroupScore'] = ResolversParentTypes['MuscleGroupScore'],
> = {
  bucket?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  muscleGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MusicProviderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MusicProvider'] = ResolversParentTypes['MusicProvider'],
> = {
  __resolveType: TypeResolveFn<'PlaylistMusicProvider', ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  abandonOdysseyWorkout?: Resolver<
    ResolversTypes['AbandonOdysseyWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAbandonOdysseyWorkoutArgs, 'id'>
  >;
  addClassToSchedule?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddClassToScheduleArgs, 'scheduledClassInput'>
  >;
  addClassToStack?: Resolver<
    ResolversTypes['StackResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddClassToStackArgs, 'input'>
  >;
  addHighFive?: Resolver<
    ResolversTypes['ActivityFeedEventResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAddHighFiveArgs,
      'createdAtForEvent' | 'userIdForEvent' | 'userIdForHighFive'
    >
  >;
  addTagToUser?: Resolver<
    ResolversTypes['AddTagToUserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTagToUserArgs, 'input'>
  >;
  cancelInvite?: Resolver<
    ResolversTypes['YourScheduleListResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelInviteArgs, 'inviteId'>
  >;
  completeOdysseyWorkout?: Resolver<
    ResolversTypes['CompleteOdysseyWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCompleteOdysseyWorkoutArgs, 'input'>
  >;
  createInviteShareLink?: Resolver<
    ResolversTypes['InviteShareURLResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateInviteShareLinkArgs, 'inviteId'>
  >;
  createLiveInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateLiveInviteArgs, 'createLiveInviteInput'>
  >;
  createNewOnDemandInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateNewOnDemandInviteArgs, 'createNewOnDemandInviteInput'>
  >;
  createOdysseyWorkout?: Resolver<
    ResolversTypes['CreateOdysseyWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOdysseyWorkoutArgs, 'input'>
  >;
  createScheduledOnDemandInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateScheduledOnDemandInviteArgs,
      'createScheduledOnDemandInviteInput'
    >
  >;
  inviteUsersToInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationInviteUsersToInviteArgs, 'inviteUsersToInviteInput'>
  >;
  joinProgram?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinProgramArgs, 'programId'>
  >;
  leaveProgram?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLeaveProgramArgs, 'programProgressId'>
  >;
  modifyStack?: Resolver<
    ResolversTypes['StackResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationModifyStackArgs, 'input'>
  >;
  pauseProgram?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationPauseProgramArgs, 'programProgressId'>
  >;
  pauseWorkout?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MutationPauseWorkoutArgs, 'input'>
  >;
  playClassFromStack?: Resolver<
    ResolversTypes['StackResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationPlayClassFromStackArgs, 'input'>
  >;
  programWeekSeen?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationProgramWeekSeenArgs, 'programProgressId' | 'programWeekId'>
  >;
  removeClassFromSchedule?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveClassFromScheduleArgs, 'scheduledClassId'>
  >;
  removeHighFive?: Resolver<
    ResolversTypes['ActivityFeedEventResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationRemoveHighFiveArgs,
      'createdAtForEvent' | 'userIdForEvent' | 'userIdForHighFive'
    >
  >;
  removeTagFromUser?: Resolver<
    ResolversTypes['RemoveTagFromUserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveTagFromUserArgs, 'input'>
  >;
  replaceTag?: Resolver<
    ResolversTypes['ReplaceTagResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationReplaceTagArgs, 'input'>
  >;
  rescheduleClass?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRescheduleClassArgs, 'rescheduleClassInput'>
  >;
  respondToInvite?: Resolver<
    ResolversTypes['InviteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRespondToInviteArgs, 'inviteId' | 'status'>
  >;
  setLastTagSearch?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetLastTagSearchArgs, 'searchTerm'>
  >;
  setModifyStacksView?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetModifyStacksViewArgs, 'isModify'>
  >;
  setSelectedTagName?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetSelectedTagNameArgs, 'input'>
  >;
  setTagAsPrimary?: Resolver<
    ResolversTypes['SetTagAsPrimaryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetTagAsPrimaryArgs, 'input'>
  >;
  setTransitionScreen?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationSetTransitionScreenArgs, 'isTransition'>
  >;
  skipProgramClass?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSkipProgramClassArgs, 'programClassId' | 'programProgressId'>
  >;
  startFreestyleWorkout?: Resolver<
    ResolversTypes['StartFreestyleWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStartFreestyleWorkoutArgs, 'input'>
  >;
  startGymWorkout?: Resolver<
    ResolversTypes['StartGymWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStartGymWorkoutArgs, 'input'>
  >;
  startPelotonClass?: Resolver<
    ResolversTypes['JoinableClass'],
    ParentType,
    ContextType,
    RequireFields<MutationStartPelotonClassArgs, 'input'>
  >;
  startProgramClass?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationStartProgramClassArgs,
      'programClassId' | 'programProgressId' | 'workoutId'
    >
  >;
  unpauseProgram?: Resolver<
    ResolversTypes['ProgramProgressResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpauseProgramArgs, 'programProgressId'>
  >;
  unpauseWorkout?: Resolver<
    ResolversTypes['UnpauseWorkoutResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpauseWorkoutArgs, 'input'>
  >;
  updateRelationship?: Resolver<
    ResolversTypes['UpdateRelationshipResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRelationshipArgs, 'input'>
  >;
};

export type NoClassPelotonForPlatformErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NoClassPelotonForPlatformError'] = ResolversParentTypes['NoClassPelotonForPlatformError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type NotificationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification'],
> = {
  activityFeedEvent?: Resolver<
    ResolversTypes['ActivityFeedEvent'],
    ParentType,
    ContextType
  >;
  highFivedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isSeen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type NotificationDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NotificationDetails'] = ResolversParentTypes['NotificationDetails'],
> = {
  notifications?: Resolver<
    Array<ResolversTypes['Notification']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type NotificationDetailsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NotificationDetailsResponse'] = ResolversParentTypes['NotificationDetailsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'NotificationDetails',
    ParentType,
    ContextType
  >;
};

export type OccasionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Occasion'] = ResolversParentTypes['Occasion'],
> = {
  assets?: Resolver<ResolversTypes['OccasionAssets'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  occasionSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  translatedOccasionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OccasionAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OccasionAssets'] = ResolversParentTypes['OccasionAssets'],
> = {
  largeImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  smallImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OccasionsListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OccasionsList'] = ResolversParentTypes['OccasionsList'],
> = {
  occasions?: Resolver<Array<ResolversTypes['Occasion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OccasionsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OccasionsResponse'] = ResolversParentTypes['OccasionsResponse'],
> = {
  __resolveType: TypeResolveFn<'OccasionsList', ParentType, ContextType>;
};

export type OdysseyAccordionLevelFilterResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyAccordionLevelFilter'] = ResolversParentTypes['OdysseyAccordionLevelFilter'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  options?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilterOption']>,
    ParentType,
    ContextType
  >;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyAccordionLevelFilterInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyAccordionLevelFilterInfo'] = ResolversParentTypes['OdysseyAccordionLevelFilterInfo'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyBadRequestErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyBadRequestError'] = ResolversParentTypes['OdysseyBadRequestError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyBasicLevelFilterOptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyBasicLevelFilterOption'] = ResolversParentTypes['OdysseyBasicLevelFilterOption'],
> = {
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyBikeConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyBikeConfiguration'] = ResolversParentTypes['OdysseyBikeConfiguration'],
> = {
  difficultyConfigs?: Resolver<
    Array<ResolversTypes['OdysseyBikeDifficultyConfiguration']>,
    ParentType,
    ContextType
  >;
  scoringConfig?: Resolver<
    ResolversTypes['OdysseyBikeScoringConfiguration'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyBikeDifficultyConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyBikeDifficultyConfiguration'] = ResolversParentTypes['OdysseyBikeDifficultyConfiguration'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  laneConfigs?: Resolver<
    Array<ResolversTypes['OdysseyBikeLaneConfiguration']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyBikeLaneConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyBikeLaneConfiguration'] = ResolversParentTypes['OdysseyBikeLaneConfiguration'],
> = {
  breakerAverageOutput?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxResistance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minResistance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyBikeScoringConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyBikeScoringConfiguration'] = ResolversParentTypes['OdysseyBikeScoringConfiguration'],
> = {
  starThresholds?: Resolver<
    Array<ResolversTypes['OdysseyStarThreshold']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyConfigurationResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyConfigurationResponse'] = ResolversParentTypes['OdysseyConfigurationResponse'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyBikeConfiguration' | 'OdysseyItemNotFoundError' | 'OdysseyTreadConfiguration',
    ParentType,
    ContextType
  >;
};

export type OdysseyDifficultyMetadataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyDifficultyMetadata'] = ResolversParentTypes['OdysseyDifficultyMetadata'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyDurationLevelFilterOptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyDurationLevelFilterOption'] = ResolversParentTypes['OdysseyDurationLevelFilterOption'],
> = {
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyError'] = ResolversParentTypes['OdysseyError'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyItemNotFoundError'
    | 'OdysseyNotAuthorizedError'
    | 'OdysseyBadRequestError'
    | 'OdysseyInvalidWorkoutMutationError',
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OdysseyFeedWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyFeedWorkout'] = ResolversParentTypes['OdysseyFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  odysseyImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odysseySubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  odysseyWorkoutTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyGridLevelFilterResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyGridLevelFilter'] = ResolversParentTypes['OdysseyGridLevelFilter'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  options?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilterOption']>,
    ParentType,
    ContextType
  >;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyGridLevelFilterInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyGridLevelFilterInfo'] = ResolversParentTypes['OdysseyGridLevelFilterInfo'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numColumns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  selectMultiple?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyIconResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyIcon'] = ResolversParentTypes['OdysseyIcon'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyInvalidWorkoutMutationErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyInvalidWorkoutMutationError'] = ResolversParentTypes['OdysseyInvalidWorkoutMutationError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workoutId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyItemNotFoundErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyItemNotFoundError'] = ResolversParentTypes['OdysseyItemNotFoundError'],
> = {
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyLeaderboardResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLeaderboard'] = ResolversParentTypes['OdysseyLeaderboard'],
> = {
  canFetchNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  extraWorkoutEntry?: Resolver<
    Maybe<ResolversTypes['LeaderboardEntry']>,
    ParentType,
    ContextType
  >;
  leaderboard?: Resolver<
    Array<ResolversTypes['LeaderboardEntry']>,
    ParentType,
    ContextType
  >;
  startRank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyLeaderboardGraphqlErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardGraphqlError'] = ResolversParentTypes['OdysseyLeaderboardGraphqlError'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyLeaderboardInvalidRequest',
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type OdysseyLeaderboardInvalidRequestResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardInvalidRequest'] = ResolversParentTypes['OdysseyLeaderboardInvalidRequest'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyLeaderboardListSuccessResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardListSuccessResponse'] = ResolversParentTypes['OdysseyLeaderboardListSuccessResponse'],
> = {
  leaderboard?: Resolver<ResolversTypes['OdysseyLeaderboard'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyLeaderboardQueryResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLeaderboardQueryResponse'] = ResolversParentTypes['OdysseyLeaderboardQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyLeaderboardInvalidRequest' | 'OdysseyLeaderboardListSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type OdysseyLevelAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLevelAssets'] = ResolversParentTypes['OdysseyLevelAssets'],
> = {
  detailImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unityBundleUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyLevelBrowseFilterResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLevelBrowseFilter'] = ResolversParentTypes['OdysseyLevelBrowseFilter'],
> = {
  info?: Resolver<ResolversTypes['OdysseyLevelFilterInfo'], ParentType, ContextType>;
  options?: Resolver<
    Array<ResolversTypes['OdysseyLevelFilterOption']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyLevelDifficultyDetailResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLevelDifficultyDetail'] = ResolversParentTypes['OdysseyLevelDifficultyDetail'],
> = {
  midiFileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyLevelFilterResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLevelFilter'] = ResolversParentTypes['OdysseyLevelFilter'],
> = {
  __resolveType: TypeResolveFn<
    'OdysseyAccordionLevelFilter' | 'OdysseyGridLevelFilter' | 'OdysseyToggleLevelFilter',
    ParentType,
    ContextType
  >;
};

export type OdysseyLevelFilterInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLevelFilterInfo'] = ResolversParentTypes['OdysseyLevelFilterInfo'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyAccordionLevelFilterInfo'
    | 'OdysseyGridLevelFilterInfo'
    | 'OdysseyToggleLevelFilterInfo',
    ParentType,
    ContextType
  >;
};

export type OdysseyLevelFilterOptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyLevelFilterOption'] = ResolversParentTypes['OdysseyLevelFilterOption'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyBasicLevelFilterOption'
    | 'OdysseyDurationLevelFilterOption'
    | 'OdysseyPlayedLevelFilterOption'
    | 'OdysseyStarsLevelFilterOption',
    ParentType,
    ContextType
  >;
};

export type OdysseyNotAuthorizedErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyNotAuthorizedError'] = ResolversParentTypes['OdysseyNotAuthorizedError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyPlayedLevelFilterOptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyPlayedLevelFilterOption'] = ResolversParentTypes['OdysseyPlayedLevelFilterOption'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyStarsLevelFilterOptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyStarsLevelFilterOption'] = ResolversParentTypes['OdysseyStarsLevelFilterOption'],
> = {
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  listOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyStarThresholdResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyStarThreshold'] = ResolversParentTypes['OdysseyStarThreshold'],
> = {
  completionPercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  starsEarned?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyToggleLevelFilterResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyToggleLevelFilter'] = ResolversParentTypes['OdysseyToggleLevelFilter'],
> = {
  defaultValue?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyToggleLevelFilterInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyToggleLevelFilterInfo'] = ResolversParentTypes['OdysseyToggleLevelFilterInfo'],
> = {
  defaultValue?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['OdysseyIcon'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyTreadConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyTreadConfiguration'] = ResolversParentTypes['OdysseyTreadConfiguration'],
> = {
  difficultyConfigs?: Resolver<
    Array<ResolversTypes['OdysseyTreadDifficultyConfiguration']>,
    ParentType,
    ContextType
  >;
  scoringConfig?: Resolver<
    ResolversTypes['OdysseyTreadScoringConfiguration'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyTreadDifficultyConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyTreadDifficultyConfiguration'] = ResolversParentTypes['OdysseyTreadDifficultyConfiguration'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  intensities?: Resolver<
    Array<ResolversTypes['OdysseyTreadDifficultyIntensity']>,
    ParentType,
    ContextType
  >;
  laneConfigs?: Resolver<
    Array<ResolversTypes['OdysseyTreadLaneConfiguration']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  speedModifier?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyTreadDifficultyIntensityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyTreadDifficultyIntensity'] = ResolversParentTypes['OdysseyTreadDifficultyIntensity'],
> = {
  key?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxSpeed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minSpeed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyTreadLaneConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyTreadLaneConfiguration'] = ResolversParentTypes['OdysseyTreadLaneConfiguration'],
> = {
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxIncline?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minIncline?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyTreadScoringConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyTreadScoringConfiguration'] = ResolversParentTypes['OdysseyTreadScoringConfiguration'],
> = {
  starThresholds?: Resolver<
    Array<ResolversTypes['OdysseyStarThreshold']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyWorkout'] = ResolversParentTypes['OdysseyWorkout'],
> = {
  beatCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blastCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breakerCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  completionPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  difficulty?: Resolver<ResolversTypes['OdysseyDifficulty'], ParentType, ContextType>;
  hillCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  streamCompletionPercent?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OdysseyWorkoutQueryResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyWorkoutQueryResponse'] = ResolversParentTypes['OdysseyWorkoutQueryResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'OdysseyItemNotFoundError'
    | 'OdysseyNotAuthorizedError'
    | 'OdysseyWorkoutSuccessResponse',
    ParentType,
    ContextType
  >;
};

export type OdysseyWorkoutSuccessResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OdysseyWorkoutSuccessResponse'] = ResolversParentTypes['OdysseyWorkoutSuccessResponse'],
> = {
  workout?: Resolver<ResolversTypes['OdysseyWorkout'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OffsetResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Offset'] = ResolversParentTypes['Offset'],
> = {
  start?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OffsetsCueResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OffsetsCue'] = ResolversParentTypes['OffsetsCue'],
> = {
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OnDemandInstructorClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OnDemandInstructorClass'] = ResolversParentTypes['OnDemandInstructorClass'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instructor?: Resolver<ResolversTypes['Instructor'], ParentType, ContextType>;
  airTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  originalAirTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  difficultyLevel?: Resolver<
    Maybe<ResolversTypes['DifficultyLevel']>,
    ParentType,
    ContextType
  >;
  contentFormat?: Resolver<ResolversTypes['ContentFormat'], ParentType, ContextType>;
  totalUserWorkouts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipment?: Resolver<Array<ResolversTypes['Equipment']>, ParentType, ContextType>;
  playlist?: Resolver<Maybe<ResolversTypes['Playlist']>, ParentType, ContextType>;
  instructorCues?: Resolver<
    Maybe<Array<ResolversTypes['InstructorCue']>>,
    ParentType,
    ContextType
  >;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  targetMetricsData?: Resolver<
    Maybe<ResolversTypes['TargetMetricsData']>,
    ParentType,
    ContextType
  >;
  captions?: Resolver<Maybe<ResolversTypes['Captions']>, ParentType, ContextType>;
  isFtpTest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isExplicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  explicitRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  topTags?: Resolver<
    Array<ResolversTypes['ClassTopTag']>,
    ParentType,
    ContextType,
    RequireFields<OnDemandInstructorClassTopTagsArgs, 'limit' | 'sampledFrom'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PaceIntensityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaceIntensity'] = ResolversParentTypes['PaceIntensity'],
> = {
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paceLevels?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaceLevel']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PaceLevelResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaceLevel'] = ResolversParentTypes['PaceLevel'],
> = {
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fastPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  slowPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PageInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['Cursor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaginatedList'] = ResolversParentTypes['PaginatedList'],
> = {
  __resolveType: TypeResolveFn<
    | 'UserPaginatedList'
    | 'FollowingUsersPaginatedList'
    | 'ActiveTagClassesPaginatedList'
    | 'EntertainmentChannelPaginatedList'
    | 'GymPlanPaginationResponse'
    | 'TagPaginatedList'
    | 'ActivityFeedEventPaginationResponse',
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
};

export type PauseInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PauseInfo'] = ResolversParentTypes['PauseInfo'],
> = {
  isPaused?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pauseTimeElapsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pauseTimeLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PayloadResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Payload'] = ResolversParentTypes['Payload'],
> = {
  __resolveType: TypeResolveFn<
    | 'AchievementAwardedEventPayload'
    | 'StreakAwardedEventPayload'
    | 'WorkoutFinalizedEventPayload',
    ParentType,
    ContextType
  >;
};

export type PelotonResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Peloton'] = ResolversParentTypes['Peloton'],
> = {
  pelotonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PelotonClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PelotonClass'] = ResolversParentTypes['PelotonClass'],
> = {
  __resolveType: TypeResolveFn<
    'OnDemandInstructorClass' | 'LiveClass' | 'ScenicClass',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
};

export type PelotonClassFeedWorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PelotonClassFeedWorkout'] = ResolversParentTypes['PelotonClassFeedWorkout'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSplitPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgStrokeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fitnessDiscipline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isOutdoor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pelotonWorkoutID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PelotonNotCreatedErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PelotonNotCreatedError'] = ResolversParentTypes['PelotonNotCreatedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PelotonResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PelotonResult'] = ResolversParentTypes['PelotonResult'],
> = {
  __resolveType: TypeResolveFn<
    'Peloton' | 'NoClassPelotonForPlatformError' | 'PelotonNotCreatedError',
    ParentType,
    ContextType
  >;
};

export type PendingInvitesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PendingInvites'] = ResolversParentTypes['PendingInvites'],
> = {
  countOfPendingInvites?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pendingInvitesList?: Resolver<
    Array<ResolversTypes['InviteDetails']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PendingInvitesResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PendingInvitesResponse'] = ResolversParentTypes['PendingInvitesResponse'],
> = {
  __resolveType: TypeResolveFn<
    'InviteFriendsItemNotFoundError' | 'PendingInvites',
    ParentType,
    ContextType
  >;
};

export type PermissionErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PermissionError'] = ResolversParentTypes['PermissionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PlaylistResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Playlist'] = ResolversParentTypes['Playlist'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  songs?: Resolver<Array<ResolversTypes['Song']>, ParentType, ContextType>;
  isInClassMusicShown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  playbackSongs?: Resolver<
    Array<ResolversTypes['PlaylistPlaybackSong']>,
    ParentType,
    ContextType
  >;
  topArtists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PlaylistMusicProviderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PlaylistMusicProvider'] = ResolversParentTypes['PlaylistMusicProvider'],
> = {
  playlist?: Resolver<ResolversTypes['StreamablePlaylist'], ParentType, ContextType>;
  isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PlaylistPlaybackSongResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PlaylistPlaybackSong'] = ResolversParentTypes['PlaylistPlaybackSong'],
> = {
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  streamOffset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cueOffset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  song?: Resolver<ResolversTypes['Song'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PopularTagClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PopularTagClass'] = ResolversParentTypes['PopularTagClass'],
> = {
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  countUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PopularTagClassesListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PopularTagClassesList'] = ResolversParentTypes['PopularTagClassesList'],
> = {
  popularClasses?: Resolver<
    Array<ResolversTypes['PopularTagClass']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Program'] = ResolversParentTypes['Program'],
> = {
  achievements?: Resolver<
    Array<ResolversTypes['ProgramAchievementTier']>,
    ParentType,
    ContextType
  >;
  assets?: Resolver<ResolversTypes['ProgramAssets'], ParentType, ContextType>;
  classes?: Resolver<Array<ResolversTypes['ProgramClass']>, ParentType, ContextType>;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  equipment?: Resolver<Array<ResolversTypes['Equipment']>, ParentType, ContextType>;
  fitnessDisciplines?: Resolver<
    Array<ResolversTypes['ProgramFitnessDiscipline']>,
    ParentType,
    ContextType
  >;
  flags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instructors?: Resolver<Array<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isLimitedContent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  programClassesLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  programMetaData?: Resolver<ResolversTypes['ProgramMetaData'], ParentType, ContextType>;
  programSchedule?: Resolver<ResolversTypes['ProgramSchedule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramAchievementAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramAchievementAssets'] = ResolversParentTypes['ProgramAchievementAssets'],
> = {
  badgeImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['Color'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramAchievementTierResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramAchievementTier'] = ResolversParentTypes['ProgramAchievementTier'],
> = {
  assets?: Resolver<ResolversTypes['ProgramAchievementAssets'], ParentType, ContextType>;
  badgeDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  badgeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  numClassesRequired?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tierName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramAssets'] = ResolversParentTypes['ProgramAssets'],
> = {
  heroImage?: Resolver<ResolversTypes['HeroImageAssets'], ParentType, ContextType>;
  multipleInstructorOnboardingImage?: Resolver<
    Maybe<ResolversTypes['Media']>,
    ParentType,
    ContextType
  >;
  thumbnailImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramClass'] = ResolversParentTypes['ProgramClass'],
> = {
  classNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  classProgress?: Resolver<
    Maybe<ResolversTypes['ProgramClassProgress']>,
    ParentType,
    ContextType,
    RequireFields<ProgramClassClassProgressArgs, 'programProgressId'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pelotonClassId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramClassProgressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramClassProgress'] = ResolversParentTypes['ProgramClassProgress'],
> = {
  classNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  programClass?: Resolver<ResolversTypes['ProgramClass'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['ProgramClassProgressStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramDayResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramDay'] = ResolversParentTypes['ProgramDay'],
> = {
  classes?: Resolver<Array<ResolversTypes['ProgramClass']>, ParentType, ContextType>;
  dayNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramFitnessDisciplineResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramFitnessDiscipline'] = ResolversParentTypes['ProgramFitnessDiscipline'],
> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramMetaDataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramMetaData'] = ResolversParentTypes['ProgramMetaData'],
> = {
  daysPerWeek?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  highMinsPerDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lowMinsPerDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalNumClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramProgressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramProgress'] = ResolversParentTypes['ProgramProgress'],
> = {
  awardedAchievement?: Resolver<
    ResolversTypes['AwardedProgramAchievementTier'],
    ParentType,
    ContextType
  >;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pauseDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  program?: Resolver<ResolversTypes['Program'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  weeklyProgress?: Resolver<
    Array<ResolversTypes['ProgramWeekProgress']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramProgressAlreadyExistsErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramProgressAlreadyExistsError'] = ResolversParentTypes['ProgramProgressAlreadyExistsError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramProgressNotFoundErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramProgressNotFoundError'] = ResolversParentTypes['ProgramProgressNotFoundError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramProgressResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramProgressResponse'] = ResolversParentTypes['ProgramProgressResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'ProgramProgressAlreadyExistsError'
    | 'ProgramProgressNotFoundError'
    | 'ProgramProgressResponseSuccess'
    | 'UserNotAuthorizedError',
    ParentType,
    ContextType
  >;
};

export type ProgramProgressResponseSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramProgressResponseSuccess'] = ResolversParentTypes['ProgramProgressResponseSuccess'],
> = {
  programProgress?: Resolver<
    Maybe<ResolversTypes['ProgramProgress']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramScheduleResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramSchedule'] = ResolversParentTypes['ProgramSchedule'],
> = {
  weeks?: Resolver<Array<ResolversTypes['ProgramWeek']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramWeekResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramWeek'] = ResolversParentTypes['ProgramWeek'],
> = {
  days?: Resolver<Array<ResolversTypes['ProgramDay']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  weekNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weekProgress?: Resolver<
    Maybe<ResolversTypes['ProgramWeekProgress']>,
    ParentType,
    ContextType,
    RequireFields<ProgramWeekWeekProgressArgs, 'programProgressId'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProgramWeekProgressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProgramWeekProgress'] = ResolversParentTypes['ProgramWeekProgress'],
> = {
  classesProgress?: Resolver<
    Array<Maybe<ResolversTypes['ProgramClassProgress']>>,
    ParentType,
    ContextType
  >;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  seen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ProgramWeekProgressStatus'], ParentType, ContextType>;
  weekNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type QueryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  checkDoesClassExistInUserStack?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<QueryCheckDoesClassExistInUserStackArgs, 'pelotonClassId'>
  >;
  completedPrograms?: Resolver<
    Array<ResolversTypes['ProgramProgress']>,
    ParentType,
    ContextType,
    RequireFields<QueryCompletedProgramsArgs, never>
  >;
  currentUserTags?: Resolver<ResolversTypes['UserTags'], ParentType, ContextType>;
  entertainmentChannels?: Resolver<
    ResolversTypes['EntertainmentChannelsQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryEntertainmentChannelsArgs, 'limit' | 'cursor'>
  >;
  filterPrograms?: Resolver<
    Array<ResolversTypes['Program']>,
    ParentType,
    ContextType,
    RequireFields<QueryFilterProgramsArgs, 'programParams'>
  >;
  freestyleMetadata?: Resolver<
    ResolversTypes['FreestyleMetadata'],
    ParentType,
    ContextType
  >;
  getEventDetails?: Resolver<
    ResolversTypes['EventDetailsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetEventDetailsArgs, 'activityFeedEventId'>
  >;
  getHighFiveDetailsForEvent?: Resolver<
    ResolversTypes['HighFiveDetailsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetHighFiveDetailsForEventArgs, 'eventCreatedAt' | 'eventUserId'>
  >;
  getNotificationDetails?: Resolver<
    ResolversTypes['NotificationDetailsResponse'],
    ParentType,
    ContextType
  >;
  getNumberOfUnreadNotifications?: Resolver<
    ResolversTypes['UnreadNotificationsResponse'],
    ParentType,
    ContextType
  >;
  gymPlan?: Resolver<
    ResolversTypes['GymPlanResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGymPlanArgs, 'gymPlanId' | 'userId'>
  >;
  gymPlanFilterChips?: Resolver<
    Array<ResolversTypes['GymPlanFilterChip']>,
    ParentType,
    ContextType,
    RequireFields<QueryGymPlanFilterChipsArgs, 'userId'>
  >;
  gymPlans?: Resolver<
    ResolversTypes['GymPlanResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGymPlansArgs, never>
  >;
  inviteDetails?: Resolver<
    ResolversTypes['YourScheduleItem'],
    ParentType,
    ContextType,
    RequireFields<QueryInviteDetailsArgs, 'inviteId'>
  >;
  joinableClass?: Resolver<
    ResolversTypes['JoinableClass'],
    ParentType,
    ContextType,
    RequireFields<QueryJoinableClassArgs, 'id'>
  >;
  lastTagSearch?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<
    ResolversTypes['LevelQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryLevelArgs, 'id'>
  >;
  levels?: Resolver<ResolversTypes['LevelListQueryResponse'], ParentType, ContextType>;
  modifyStacksView?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  occasions?: Resolver<
    ResolversTypes['OccasionsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOccasionsArgs, 'fitnessDiscipline'>
  >;
  odysseyConfiguration?: Resolver<
    ResolversTypes['OdysseyConfigurationResponse'],
    ParentType,
    ContextType
  >;
  odysseyDifficultyMetadata?: Resolver<
    Array<ResolversTypes['OdysseyDifficultyMetadata']>,
    ParentType,
    ContextType
  >;
  odysseyFriendsLeaderboard?: Resolver<
    ResolversTypes['OdysseyLeaderboardQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOdysseyFriendsLeaderboardArgs, 'leaderboardRequest'>
  >;
  odysseyLeaderboard?: Resolver<
    ResolversTypes['OdysseyLeaderboardQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOdysseyLeaderboardArgs, 'leaderboardRequest'>
  >;
  odysseyLevelBrowseFilters?: Resolver<
    ResolversTypes['LevelFiltersQueryResponse'],
    ParentType,
    ContextType
  >;
  odysseyUserLeaderboard?: Resolver<
    ResolversTypes['OdysseyLeaderboardQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOdysseyUserLeaderboardArgs, never>
  >;
  odysseyWorkout?: Resolver<
    ResolversTypes['OdysseyWorkoutQueryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryOdysseyWorkoutArgs, 'id'>
  >;
  pelotonClass?: Resolver<
    ResolversTypes['PelotonClass'],
    ParentType,
    ContextType,
    RequireFields<QueryPelotonClassArgs, 'joinToken'>
  >;
  program?: Resolver<
    ResolversTypes['Program'],
    ParentType,
    ContextType,
    RequireFields<QueryProgramArgs, 'programId'>
  >;
  programProgress?: Resolver<
    ResolversTypes['ProgramProgress'],
    ParentType,
    ContextType,
    RequireFields<QueryProgramProgressArgs, 'programProgressId'>
  >;
  programs?: Resolver<Array<ResolversTypes['Program']>, ParentType, ContextType>;
  programsById?: Resolver<
    Array<ResolversTypes['Program']>,
    ParentType,
    ContextType,
    RequireFields<QueryProgramsByIdArgs, 'programIds'>
  >;
  progressOfPrograms?: Resolver<
    Array<ResolversTypes['ProgramProgress']>,
    ParentType,
    ContextType,
    RequireFields<QueryProgressOfProgramsArgs, never>
  >;
  scheduledClass?: Resolver<
    ResolversTypes['ScheduledClassResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryScheduledClassArgs, 'scheduledClassId'>
  >;
  searchTag?: Resolver<
    ResolversTypes['TagPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<QuerySearchTagArgs, 'input'>
  >;
  selectedTagName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  session?: Resolver<
    Maybe<ResolversTypes['SessionClass']>,
    ParentType,
    ContextType,
    RequireFields<QuerySessionArgs, 'sessionInput'>
  >;
  streamablePlaylist?: Resolver<
    ResolversTypes['StreamablePlaylist'],
    ParentType,
    ContextType,
    RequireFields<QueryStreamablePlaylistArgs, 'playlistId'>
  >;
  tag?: Resolver<
    Maybe<ResolversTypes['Tag']>,
    ParentType,
    ContextType,
    RequireFields<QueryTagArgs, 'tagName'>
  >;
  tagBrowseCategory?: Resolver<
    ResolversTypes['TagBrowseCategory'],
    ParentType,
    ContextType,
    RequireFields<QueryTagBrowseCategoryArgs, 'categorySlug'>
  >;
  transitionScreen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserArgs, never>
  >;
  userActivityFeed?: Resolver<
    ResolversTypes['ActivityFeedEventResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryUserActivityFeedArgs, never>
  >;
  userProgramAchievements?: Resolver<
    Array<ResolversTypes['AwardedProgramAchievementTier']>,
    ParentType,
    ContextType
  >;
  userScheduledItemsList?: Resolver<
    ResolversTypes['YourScheduleListResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryUserScheduledItemsListArgs, 'endTime' | 'startTime'>
  >;
  viewUserStack?: Resolver<ResolversTypes['StackResponse'], ParentType, ContextType>;
  workoutPostClassDetails?: Resolver<
    ResolversTypes['WorkoutPostClassResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryWorkoutPostClassDetailsArgs, 'isWorkoutFromStack' | 'workoutId'>
  >;
  workoutProgramAchievements?: Resolver<
    Array<ResolversTypes['AwardedProgramAchievementTier']>,
    ParentType,
    ContextType,
    RequireFields<QueryWorkoutProgramAchievementsArgs, 'workoutId'>
  >;
};

export type RangeCueResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RangeCue'] = ResolversParentTypes['RangeCue'],
> = {
  lower?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  upper?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReadyVideoStreamResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReadyVideoStream'] = ResolversParentTypes['ReadyVideoStream'],
> = {
  videoStream?: Resolver<ResolversTypes['VideoStream'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RemoveTagFromUserResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RemoveTagFromUserResponse'] = ResolversParentTypes['RemoveTagFromUserResponse'],
> = {
  __resolveType: TypeResolveFn<
    'RemoveTagFromUserSuccess' | 'DoesNotExistError',
    ParentType,
    ContextType
  >;
};

export type RemoveTagFromUserSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RemoveTagFromUserSuccess'] = ResolversParentTypes['RemoveTagFromUserSuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReplaceTagResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReplaceTagResponse'] = ResolversParentTypes['ReplaceTagResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'ReplaceTagSuccess'
    | 'TagAlreadyExistsError'
    | 'DoesNotExistError'
    | 'TagNameInvalidCharacterError'
    | 'TagNameLengthError'
    | 'TagNameProhibitedError',
    ParentType,
    ContextType
  >;
};

export type ReplaceTagSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReplaceTagSuccess'] = ResolversParentTypes['ReplaceTagSuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RetryableErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RetryableError'] = ResolversParentTypes['RetryableError'],
> = {
  __resolveType: TypeResolveFn<'JoinContentNotLiveError', ParentType, ContextType>;
  retryAfterSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  retryMaxDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type ScenicClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ScenicClass'] = ResolversParentTypes['ScenicClass'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originLocale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  fitnessDiscipline?: Resolver<
    ResolversTypes['FitnessDiscipline'],
    ParentType,
    ContextType
  >;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['ClassAssets'], ParentType, ContextType>;
  playableOnPlatform?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeline?: Resolver<Maybe<ResolversTypes['ClassTimeline']>, ParentType, ContextType>;
  classTypes?: Resolver<Array<ResolversTypes['ClassType']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['MetricName']>, ParentType, ContextType>;
  instructor?: Resolver<Maybe<ResolversTypes['Instructor']>, ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalUserWorkouts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  videoEmbeddedPlaylist?: Resolver<
    Maybe<ResolversTypes['Playlist']>,
    ParentType,
    ContextType
  >;
  musicProviders?: Resolver<
    Array<Maybe<ResolversTypes['MusicProvider']>>,
    ParentType,
    ContextType
  >;
  isDynamicVideoEligible?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isFixedDistance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dynamicVideoRecordedSpeedInMph?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distanceUnit?: Resolver<Maybe<ResolversTypes['DistanceUnit']>, ParentType, ContextType>;
  thumbnailTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  captions?: Resolver<Maybe<ResolversTypes['Captions']>, ParentType, ContextType>;
  shopTheClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  classPreviewVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  targetMetricsData?: Resolver<
    Maybe<ResolversTypes['TargetMetricsData']>,
    ParentType,
    ContextType
  >;
  contentAvailability?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contentAvailabilityLevel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isLimitedRide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  freeForLimitedTime?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  excludedPlatforms?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  flags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  topTags?: Resolver<
    Array<ResolversTypes['ClassTopTag']>,
    ParentType,
    ContextType,
    RequireFields<ScenicClassTopTagsArgs, 'limit' | 'sampledFrom'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ScheduledClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ScheduledClass'] = ResolversParentTypes['ScheduledClass'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pelotonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  joinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  onDemandJoinToken?: Resolver<
    Maybe<ResolversTypes['JoinToken']>,
    ParentType,
    ContextType
  >;
  scheduledStartTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  scheduledEndTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  totalWorkouts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scheduledUsers?: Resolver<
    ResolversTypes['UserPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<ScheduledClassScheduledUsersArgs, 'limit'>
  >;
  isScheduled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  scheduleSource?: Resolver<ResolversTypes['ScheduleSource'], ParentType, ContextType>;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  totalUsersCountedIn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ScheduledClassDoesNotExistErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ScheduledClassDoesNotExistError'] = ResolversParentTypes['ScheduledClassDoesNotExistError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ScheduledClassListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ScheduledClassList'] = ResolversParentTypes['ScheduledClassList'],
> = {
  scheduledClasses?: Resolver<
    Array<ResolversTypes['ScheduledClass']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ScheduledClassListResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ScheduledClassListResponse'] = ResolversParentTypes['ScheduledClassListResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ScheduledClassList' | 'UserPrivacyError',
    ParentType,
    ContextType
  >;
};

export type ScheduledClassResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ScheduledClassResponse'] = ResolversParentTypes['ScheduledClassResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ScheduledClass' | 'ScheduledClassDoesNotExistError' | 'CannotScheduleClassError',
    ParentType,
    ContextType
  >;
};

export type SessionClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SessionClass'] = ResolversParentTypes['SessionClass'],
> = {
  liveClass?: Resolver<ResolversTypes['LiveClass'], ParentType, ContextType>;
  onDemandJoinToken?: Resolver<ResolversTypes['JoinToken'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SetTagAsPrimaryResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SetTagAsPrimaryResponse'] = ResolversParentTypes['SetTagAsPrimaryResponse'],
> = {
  __resolveType: TypeResolveFn<
    'SetTagAsPrimarySuccess' | 'TagDoesNotExistOnUserError',
    ParentType,
    ContextType
  >;
};

export type SetTagAsPrimarySuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SetTagAsPrimarySuccess'] = ResolversParentTypes['SetTagAsPrimarySuccess'],
> = {
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShareUrlResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShareURL'] = ResolversParentTypes['ShareURL'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SongResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Song'] = ResolversParentTypes['Song'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  artists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
  album?: Resolver<ResolversTypes['Album'], ParentType, ContextType>;
  explicitRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cueTimeOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startTimeOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackConnectionErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackConnectionError'] = ResolversParentTypes['StackConnectionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackDoesNotExistResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackDoesNotExist'] = ResolversParentTypes['StackDoesNotExist'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackedClassResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackedClass'] = ResolversParentTypes['StackedClass'],
> = {
  pelotonClassId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stackId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pelotonClass?: Resolver<ResolversTypes['PelotonClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackedClassAlreadyExistsErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackedClassAlreadyExistsError'] = ResolversParentTypes['StackedClassAlreadyExistsError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackedClassCannotBeAddedErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackedClassCannotBeAddedError'] = ResolversParentTypes['StackedClassCannotBeAddedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackedClassConstraintViolationErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackedClassConstraintViolationError'] = ResolversParentTypes['StackedClassConstraintViolationError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackedClassDoesNotExistInStackErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackedClassDoesNotExistInStackError'] = ResolversParentTypes['StackedClassDoesNotExistInStackError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackedClassInvalidPelotonClassIdErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackedClassInvalidPelotonClassIdError'] = ResolversParentTypes['StackedClassInvalidPelotonClassIdError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackedClassLimitReachedErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackedClassLimitReachedError'] = ResolversParentTypes['StackedClassLimitReachedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackError'] = ResolversParentTypes['StackError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackPermissionErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackPermissionError'] = ResolversParentTypes['StackPermissionError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StackResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackResponse'] = ResolversParentTypes['StackResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'StackConnectionError'
    | 'StackDoesNotExist'
    | 'StackError'
    | 'StackPermissionError'
    | 'StackResponseSuccess'
    | 'StackedClassAlreadyExistsError'
    | 'StackedClassCannotBeAddedError'
    | 'StackedClassConstraintViolationError'
    | 'StackedClassDoesNotExistInStackError'
    | 'StackedClassInvalidPelotonClassIdError'
    | 'StackedClassLimitReachedError',
    ParentType,
    ContextType
  >;
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
};

export type StackResponseSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StackResponseSuccess'] = ResolversParentTypes['StackResponseSuccess'],
> = {
  numClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userStack?: Resolver<ResolversTypes['UserStack'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StartFreestyleWorkoutResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StartFreestyleWorkoutResponse'] = ResolversParentTypes['StartFreestyleWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'StartFreestyleWorkoutResponseSuccess'
    | 'SubscriptionIdNotFoundError'
    | 'UserNotAuthorizedError',
    ParentType,
    ContextType
  >;
};

export type StartFreestyleWorkoutResponseSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StartFreestyleWorkoutResponseSuccess'] = ResolversParentTypes['StartFreestyleWorkoutResponseSuccess'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StartGymWorkoutResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StartGymWorkoutResponse'] = ResolversParentTypes['StartGymWorkoutResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GymErrorResponse' | 'StartGymWorkoutResponseSuccess',
    ParentType,
    ContextType
  >;
};

export type StartGymWorkoutResponseSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StartGymWorkoutResponseSuccess'] = ResolversParentTypes['StartGymWorkoutResponseSuccess'],
> = {
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workout?: Resolver<ResolversTypes['GymWorkout'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StreakAwardedEventPayloadResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StreakAwardedEventPayload'] = ResolversParentTypes['StreakAwardedEventPayload'],
> = {
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streakNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StreamablePlaylistResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StreamablePlaylist'] = ResolversParentTypes['StreamablePlaylist'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInClassMusicShown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  artImage?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  previewArtImage?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  stream?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  streamId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  playbackSongs?: Resolver<
    Array<ResolversTypes['PlaylistPlaybackSong']>,
    ParentType,
    ContextType
  >;
  topArtists?: Resolver<Array<ResolversTypes['Artist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SubscriptionIdNotFoundErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SubscriptionIdNotFoundError'] = ResolversParentTypes['SubscriptionIdNotFoundError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  users?: Resolver<
    ResolversTypes['UserPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<TagUsersArgs, 'limit' | 'after'>
  >;
  followingCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  metaTag?: Resolver<Maybe<ResolversTypes['MetaTag']>, ParentType, ContextType>;
  totalInProgressWorkouts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['TagAssets'], ParentType, ContextType>;
  hasAdded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  exists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  activeClasses?: Resolver<
    ResolversTypes['ActiveTagClassesPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<TagActiveClassesArgs, 'limit' | 'after'>
  >;
  popularClasses?: Resolver<
    ResolversTypes['PopularTagClassesList'],
    ParentType,
    ContextType,
    RequireFields<TagPopularClassesArgs, 'limit'>
  >;
  activeClass?: Resolver<
    ResolversTypes['ActiveTagClass'],
    ParentType,
    ContextType,
    RequireFields<TagActiveClassArgs, 'joinToken'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagAlreadyExistsErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagAlreadyExistsError'] = ResolversParentTypes['TagAlreadyExistsError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagAssets'] = ResolversParentTypes['TagAssets'],
> = {
  backgroundImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  detailBackgroundImage?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagBrowseCategoryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagBrowseCategory'] = ResolversParentTypes['TagBrowseCategory'],
> = {
  slug?: Resolver<ResolversTypes['TagBrowseCategorySlug'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['TagBrowseCategoryAssets'], ParentType, ContextType>;
  tags?: Resolver<
    ResolversTypes['TagPaginatedList'],
    ParentType,
    ContextType,
    RequireFields<TagBrowseCategoryTagsArgs, 'limit' | 'after'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagBrowseCategoryAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagBrowseCategoryAssets'] = ResolversParentTypes['TagBrowseCategoryAssets'],
> = {
  icon?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagCapacityOverLimitErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagCapacityOverLimitError'] = ResolversParentTypes['TagCapacityOverLimitError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagDoesNotExistOnUserErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagDoesNotExistOnUserError'] = ResolversParentTypes['TagDoesNotExistOnUserError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagEdgeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagEdge'] = ResolversParentTypes['TagEdge'],
> = {
  node?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagNameInvalidCharacterErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagNameInvalidCharacterError'] = ResolversParentTypes['TagNameInvalidCharacterError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagNameLengthErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagNameLengthError'] = ResolversParentTypes['TagNameLengthError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagNameProhibitedErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagNameProhibitedError'] = ResolversParentTypes['TagNameProhibitedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TagPaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TagPaginatedList'] = ResolversParentTypes['TagPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['TagEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TargetMetricsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TargetMetrics'] = ResolversParentTypes['TargetMetrics'],
> = {
  offsets?: Resolver<ResolversTypes['Offset'], ParentType, ContextType>;
  segmentType?: Resolver<Maybe<ResolversTypes['SegmentTypes']>, ParentType, ContextType>;
  metrics?: Resolver<Maybe<Array<ResolversTypes['Metric']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TargetMetricsDataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TargetMetricsData'] = ResolversParentTypes['TargetMetricsData'],
> = {
  targetMetrics?: Resolver<
    Maybe<Array<ResolversTypes['TargetMetrics']>>,
    ParentType,
    ContextType
  >;
  totalExpectedOutput?: Resolver<
    Maybe<ResolversTypes['TargetMetricsOutput']>,
    ParentType,
    ContextType
  >;
  paceIntensities?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaceIntensity']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TargetMetricsOutputResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TargetMetricsOutput'] = ResolversParentTypes['TargetMetricsOutput'],
> = {
  expectedUpperOutput?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expectedLowerOutput?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UnpauseWorkoutResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UnpauseWorkoutResponse'] = ResolversParentTypes['UnpauseWorkoutResponse'],
> = {
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  joinableClass?: Resolver<
    Maybe<ResolversTypes['JoinableClass']>,
    ParentType,
    ContextType
  >;
  pauseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pauseInfo?: Resolver<Maybe<ResolversTypes['PauseInfo']>, ParentType, ContextType>;
  pauseTimeRemaining?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UnreadNotificationsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UnreadNotifications'] = ResolversParentTypes['UnreadNotifications'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UnreadNotificationsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UnreadNotificationsResponse'] = ResolversParentTypes['UnreadNotificationsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ActivityFeedErrorResponse' | 'UnreadNotifications',
    ParentType,
    ContextType
  >;
};

export type UpdateRelationshipResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UpdateRelationshipResponse'] = ResolversParentTypes['UpdateRelationshipResponse'],
> = {
  __resolveType: TypeResolveFn<
    'AlreadyExists' | 'CantUnfollow' | 'UpdateRelationshipSuccess',
    ParentType,
    ContextType
  >;
};

export type UpdateRelationshipSuccessResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UpdateRelationshipSuccess'] = ResolversParentTypes['UpdateRelationshipSuccess'],
> = {
  meToUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userToMe?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface UrlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export type UserResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assets?: Resolver<Maybe<ResolversTypes['UserAssets']>, ParentType, ContextType>;
  isProfilePrivate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  followStatus?: Resolver<ResolversTypes['FollowStatus'], ParentType, ContextType>;
  protectedFields?: Resolver<
    Maybe<ResolversTypes['UserProtectedFieldsResult']>,
    ParentType,
    ContextType
  >;
  followingList?: Resolver<
    Maybe<ResolversTypes['FollowingUsersPaginatedList']>,
    ParentType,
    ContextType,
    RequireFields<UserFollowingListArgs, 'limit' | 'cursor'>
  >;
  remainingTagSlotCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userTags?: Resolver<ResolversTypes['UserTags'], ParentType, ContextType>;
  getInvitedUserStatus?: Resolver<
    ResolversTypes['InvitedUserStatus'],
    ParentType,
    ContextType,
    RequireFields<UserGetInvitedUserStatusArgs, never>
  >;
  scheduledClasses?: Resolver<
    ResolversTypes['ScheduledClassListResponse'],
    ParentType,
    ContextType,
    RequireFields<UserScheduledClassesArgs, 'input'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserAssetsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserAssets'] = ResolversParentTypes['UserAssets'],
> = {
  image?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserEdgeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge'],
> = {
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserNotAuthorizedErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserNotAuthorizedError'] = ResolversParentTypes['UserNotAuthorizedError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserPaginatedListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserPaginatedList'] = ResolversParentTypes['UserPaginatedList'],
> = {
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserPrivacyErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserPrivacyError'] = ResolversParentTypes['UserPrivacyError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserProtectedFieldsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserProtectedFields'] = ResolversParentTypes['UserProtectedFields'],
> = {
  totalWorkoutCounts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserProtectedFieldsResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserProtectedFieldsResult'] = ResolversParentTypes['UserProtectedFieldsResult'],
> = {
  __resolveType: TypeResolveFn<
    'UserProtectedFields' | 'UserPrivacyError',
    ParentType,
    ContextType
  >;
};

export type UserStackResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserStack'] = ResolversParentTypes['UserStack'],
> = {
  stackId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  stackedClassList?: Resolver<
    Array<ResolversTypes['StackedClass']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserTagResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserTag'] = ResolversParentTypes['UserTag'],
> = {
  tag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UserTagsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserTags'] = ResolversParentTypes['UserTags'],
> = {
  primary?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  allTags?: Resolver<Array<ResolversTypes['UserTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type VideoStreamResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['VideoStream'] = ResolversParentTypes['VideoStream'],
> = {
  multiChannel?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  singleChannel?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  streamHistoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type VideoStreamOrErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['VideoStreamOrError'] = ResolversParentTypes['VideoStreamOrError'],
> = {
  __resolveType: TypeResolveFn<
    'ErrorVideoStream' | 'ReadyVideoStream',
    ParentType,
    ContextType
  >;
};

export type WorkoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Workout'] = ResolversParentTypes['Workout'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type WorkoutFinalizedEventPayloadResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['WorkoutFinalizedEventPayload'] = ResolversParentTypes['WorkoutFinalizedEventPayload'],
> = {
  feedPrivacy?: Resolver<ResolversTypes['FeedPrivacy'], ParentType, ContextType>;
  feedWorkout?: Resolver<Maybe<ResolversTypes['FeedWorkout']>, ParentType, ContextType>;
  isClassInUserStack?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pelotonClassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workoutMetricsType?: Resolver<
    ResolversTypes['WorkoutMetricsType'],
    ParentType,
    ContextType
  >;
  pelotonClass?: Resolver<Maybe<ResolversTypes['PelotonClass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type WorkoutMetricDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['WorkoutMetricDetails'] = ResolversParentTypes['WorkoutMetricDetails'],
> = {
  avgHeartRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgPace?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  avgSpeed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calories?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  output?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  striveScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone1Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5Score?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type WorkoutPostClassResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['WorkoutPostClassResponse'] = ResolversParentTypes['WorkoutPostClassResponse'],
> = {
  programClass?: Resolver<Maybe<ResolversTypes['ProgramClass']>, ParentType, ContextType>;
  stackedClass?: Resolver<Maybe<ResolversTypes['StackedClass']>, ParentType, ContextType>;
  workoutDetails?: Resolver<
    ResolversTypes['WorkoutMetricDetails'],
    ParentType,
    ContextType
  >;
  workoutMetricsType?: Resolver<
    ResolversTypes['WorkoutMetricsType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type YourScheduleItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['YourScheduleItem'] = ResolversParentTypes['YourScheduleItem'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteDetails'
    | 'InviteFriendsHttpException'
    | 'InviteFriendsInvalidDataFormat'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNotAuthorized'
    | 'ScheduledClass',
    ParentType,
    ContextType
  >;
};

export type YourScheduleItemListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['YourScheduleItemList'] = ResolversParentTypes['YourScheduleItemList'],
> = {
  pendingInvites?: Resolver<
    ResolversTypes['PendingInvitesResponse'],
    ParentType,
    ContextType
  >;
  yourScheduleItems?: Resolver<
    Array<ResolversTypes['YourScheduleItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type YourScheduleListResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['YourScheduleListResponse'] = ResolversParentTypes['YourScheduleListResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'InviteFriendsBadRequest'
    | 'InviteFriendsItemNotFoundError'
    | 'InviteFriendsNotAuthorized'
    | 'YourScheduleItemList',
    ParentType,
    ContextType
  >;
};

export type ZoneScoreResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ZoneScore'] = ResolversParentTypes['ZoneScore'],
> = {
  zone1?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone2?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone3?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone4?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zone5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type Resolvers<ContextType = Context> = {
  _FieldSet?: GraphQLScalarType;
  AbandonOdysseyWorkoutResponse?: AbandonOdysseyWorkoutResponseResolvers<ContextType>;
  AchievementAwardedEventPayload?: AchievementAwardedEventPayloadResolvers<ContextType>;
  ActiveTagClass?: ActiveTagClassResolvers<ContextType>;
  ActiveTagClassEdge?: ActiveTagClassEdgeResolvers<ContextType>;
  ActiveTagClassesPaginatedList?: ActiveTagClassesPaginatedListResolvers<ContextType>;
  ActiveTagUserEdge?: ActiveTagUserEdgeResolvers<ContextType>;
  ActiveTagUserPaginatedList?: ActiveTagUserPaginatedListResolvers<ContextType>;
  ActiveWorkout?: ActiveWorkoutResolvers<ContextType>;
  ActivityFeedErrorResponse?: ActivityFeedErrorResponseResolvers<ContextType>;
  ActivityFeedEvent?: ActivityFeedEventResolvers<ContextType>;
  ActivityFeedEventEdge?: ActivityFeedEventEdgeResolvers<ContextType>;
  ActivityFeedEventPaginationResponse?: ActivityFeedEventPaginationResponseResolvers<ContextType>;
  ActivityFeedEventPayload?: ActivityFeedEventPayloadResolvers<ContextType>;
  ActivityFeedEventResponse?: ActivityFeedEventResponseResolvers<ContextType>;
  AddTagToUserResponse?: AddTagToUserResponseResolvers<ContextType>;
  AddTagToUserSuccess?: AddTagToUserSuccessResolvers<ContextType>;
  Album?: AlbumResolvers<ContextType>;
  AlreadyExists?: AlreadyExistsResolvers<ContextType>;
  Artist?: ArtistResolvers<ContextType>;
  AwardedProgramAchievementTier?: AwardedProgramAchievementTierResolvers<ContextType>;
  BasePlaylist?: BasePlaylistResolvers<ContextType>;
  BestRuns?: BestRunsResolvers<ContextType>;
  CannotScheduleClassError?: CannotScheduleClassErrorResolvers<ContextType>;
  CantUnfollow?: CantUnfollowResolvers<ContextType>;
  Captions?: CaptionsResolvers<ContextType>;
  ClassAssets?: ClassAssetsResolvers<ContextType>;
  ClassSegment?: ClassSegmentResolvers<ContextType>;
  ClassSubsegment?: ClassSubsegmentResolvers<ContextType>;
  ClassTimeline?: ClassTimelineResolvers<ContextType>;
  ClassTopTag?: ClassTopTagResolvers<ContextType>;
  ClassType?: ClassTypeResolvers<ContextType>;
  Color?: ColorResolvers<ContextType>;
  CompletedStats?: CompletedStatsResolvers<ContextType>;
  CompleteOdysseyWorkoutResponse?: CompleteOdysseyWorkoutResponseResolvers<ContextType>;
  CreateOdysseyWorkoutResponse?: CreateOdysseyWorkoutResponseResolvers<ContextType>;
  Cursor?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DifficultyLevel?: DifficultyLevelResolvers<ContextType>;
  DisplayTag?: DisplayTagResolvers<ContextType>;
  DoesNotExistError?: DoesNotExistErrorResolvers<ContextType>;
  EntertainmentChannel?: EntertainmentChannelResolvers<ContextType>;
  EntertainmentChannelPaginatedList?: EntertainmentChannelPaginatedListResolvers<ContextType>;
  EntertainmentChannelsQueryResponse?: EntertainmentChannelsQueryResponseResolvers<ContextType>;
  Equipment?: EquipmentResolvers<ContextType>;
  Error?: ErrorResolvers<ContextType>;
  ErrorVideoStream?: ErrorVideoStreamResolvers<ContextType>;
  EventDetails?: EventDetailsResolvers<ContextType>;
  EventDetailsResponse?: EventDetailsResponseResolvers<ContextType>;
  FeedWorkout?: FeedWorkoutResolvers<ContextType>;
  FitnessDiscipline?: FitnessDisciplineResolvers<ContextType>;
  FollowingUsersPaginatedList?: FollowingUsersPaginatedListResolvers<ContextType>;
  FreestyleFeedWorkout?: FreestyleFeedWorkoutResolvers<ContextType>;
  FreestyleMetadata?: FreestyleMetadataResolvers<ContextType>;
  GroupTags?: GroupTagsResolvers<ContextType>;
  GymErrorResponse?: GymErrorResponseResolvers<ContextType>;
  GymFeedWorkout?: GymFeedWorkoutResolvers<ContextType>;
  GymMovement?: GymMovementResolvers<ContextType>;
  GymMovementGroup?: GymMovementGroupResolvers<ContextType>;
  GymPlan?: GymPlanResolvers<ContextType>;
  GymPlanEdge?: GymPlanEdgeResolvers<ContextType>;
  GymPlanFilterChip?: GymPlanFilterChipResolvers<ContextType>;
  GymPlanPaginationResponse?: GymPlanPaginationResponseResolvers<ContextType>;
  GymPlanResponse?: GymPlanResponseResolvers<ContextType>;
  GymWorkout?: GymWorkoutResolvers<ContextType>;
  HeroImageAssets?: HeroImageAssetsResolvers<ContextType>;
  HighFiveDetails?: HighFiveDetailsResolvers<ContextType>;
  HighFiveDetailsResponse?: HighFiveDetailsResponseResolvers<ContextType>;
  IconDetails?: IconDetailsResolvers<ContextType>;
  Instructor?: InstructorResolvers<ContextType>;
  InstructorAssets?: InstructorAssetsResolvers<ContextType>;
  InstructorCue?: InstructorCueResolvers<ContextType>;
  InviteDetails?: InviteDetailsResolvers<ContextType>;
  InvitedUsersPaginatedList?: InvitedUsersPaginatedListResolvers<ContextType>;
  InvitedUsersPaginationResponse?: InvitedUsersPaginationResponseResolvers<ContextType>;
  InviteFriendsBadRequest?: InviteFriendsBadRequestResolvers<ContextType>;
  InviteFriendsCreateShareLinkDisabledError?: InviteFriendsCreateShareLinkDisabledErrorResolvers<ContextType>;
  InviteFriendsError?: InviteFriendsErrorResolvers<ContextType>;
  InviteFriendsHttpException?: InviteFriendsHttpExceptionResolvers<ContextType>;
  InviteFriendsInvalidDataFormat?: InviteFriendsInvalidDataFormatResolvers<ContextType>;
  InviteFriendsInvitedUserSizeLimitExceeded?: InviteFriendsInvitedUserSizeLimitExceededResolvers<ContextType>;
  InviteFriendsItemNotFoundError?: InviteFriendsItemNotFoundErrorResolvers<ContextType>;
  InviteFriendsNoPermissionToCreateShareLinkError?: InviteFriendsNoPermissionToCreateShareLinkErrorResolvers<ContextType>;
  InviteFriendsNotAuthorized?: InviteFriendsNotAuthorizedResolvers<ContextType>;
  InviteResponse?: InviteResponseResolvers<ContextType>;
  InviteShareURLResponse?: InviteShareUrlResponseResolvers<ContextType>;
  InviteUser?: InviteUserResolvers<ContextType>;
  InviteUserEdge?: InviteUserEdgeResolvers<ContextType>;
  JoinableClass?: JoinableClassResolvers<ContextType>;
  JoinAuthorizationError?: JoinAuthorizationErrorResolvers<ContextType>;
  JoinContentNotLiveError?: JoinContentNotLiveErrorResolvers<ContextType>;
  JoinError?: JoinErrorResolvers<ContextType>;
  JoinNoActiveSubscriptionError?: JoinNoActiveSubscriptionErrorResolvers<ContextType>;
  JoinStreamLimitError?: JoinStreamLimitErrorResolvers<ContextType>;
  JoinToken?: GraphQLScalarType;
  LeaderboardEntry?: LeaderboardEntryResolvers<ContextType>;
  LeaderboardWorkoutDetails?: LeaderboardWorkoutDetailsResolvers<ContextType>;
  Level?: LevelResolvers<ContextType>;
  LevelBrowseFiltersSuccessResponse?: LevelBrowseFiltersSuccessResponseResolvers<ContextType>;
  LevelFiltersQueryResponse?: LevelFiltersQueryResponseResolvers<ContextType>;
  LevelListQueryResponse?: LevelListQueryResponseResolvers<ContextType>;
  LevelListSuccessResponse?: LevelListSuccessResponseResolvers<ContextType>;
  LevelQueryResponse?: LevelQueryResponseResolvers<ContextType>;
  LevelSuccessResponse?: LevelSuccessResponseResolvers<ContextType>;
  LiveClass?: LiveClassResolvers<ContextType>;
  Locale?: LocaleResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  MetaTag?: MetaTagResolvers<ContextType>;
  Metric?: MetricResolvers<ContextType>;
  Movement?: MovementResolvers<ContextType>;
  MovementMuscleGroup?: MovementMuscleGroupResolvers<ContextType>;
  MovementVideo?: MovementVideoResolvers<ContextType>;
  MuscleGroupScore?: MuscleGroupScoreResolvers<ContextType>;
  MusicProvider?: MusicProviderResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NoClassPelotonForPlatformError?: NoClassPelotonForPlatformErrorResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationDetails?: NotificationDetailsResolvers<ContextType>;
  NotificationDetailsResponse?: NotificationDetailsResponseResolvers<ContextType>;
  Occasion?: OccasionResolvers<ContextType>;
  OccasionAssets?: OccasionAssetsResolvers<ContextType>;
  OccasionsList?: OccasionsListResolvers<ContextType>;
  OccasionsResponse?: OccasionsResponseResolvers<ContextType>;
  OdysseyAccordionLevelFilter?: OdysseyAccordionLevelFilterResolvers<ContextType>;
  OdysseyAccordionLevelFilterInfo?: OdysseyAccordionLevelFilterInfoResolvers<ContextType>;
  OdysseyBadRequestError?: OdysseyBadRequestErrorResolvers<ContextType>;
  OdysseyBasicLevelFilterOption?: OdysseyBasicLevelFilterOptionResolvers<ContextType>;
  OdysseyBikeConfiguration?: OdysseyBikeConfigurationResolvers<ContextType>;
  OdysseyBikeDifficultyConfiguration?: OdysseyBikeDifficultyConfigurationResolvers<ContextType>;
  OdysseyBikeLaneConfiguration?: OdysseyBikeLaneConfigurationResolvers<ContextType>;
  OdysseyBikeScoringConfiguration?: OdysseyBikeScoringConfigurationResolvers<ContextType>;
  OdysseyConfigurationResponse?: OdysseyConfigurationResponseResolvers<ContextType>;
  OdysseyDifficultyMetadata?: OdysseyDifficultyMetadataResolvers<ContextType>;
  OdysseyDurationLevelFilterOption?: OdysseyDurationLevelFilterOptionResolvers<ContextType>;
  OdysseyError?: OdysseyErrorResolvers<ContextType>;
  OdysseyFeedWorkout?: OdysseyFeedWorkoutResolvers<ContextType>;
  OdysseyGridLevelFilter?: OdysseyGridLevelFilterResolvers<ContextType>;
  OdysseyGridLevelFilterInfo?: OdysseyGridLevelFilterInfoResolvers<ContextType>;
  OdysseyIcon?: OdysseyIconResolvers<ContextType>;
  OdysseyInvalidWorkoutMutationError?: OdysseyInvalidWorkoutMutationErrorResolvers<ContextType>;
  OdysseyItemNotFoundError?: OdysseyItemNotFoundErrorResolvers<ContextType>;
  OdysseyLeaderboard?: OdysseyLeaderboardResolvers<ContextType>;
  OdysseyLeaderboardGraphqlError?: OdysseyLeaderboardGraphqlErrorResolvers<ContextType>;
  OdysseyLeaderboardInvalidRequest?: OdysseyLeaderboardInvalidRequestResolvers<ContextType>;
  OdysseyLeaderboardListSuccessResponse?: OdysseyLeaderboardListSuccessResponseResolvers<ContextType>;
  OdysseyLeaderboardQueryResponse?: OdysseyLeaderboardQueryResponseResolvers<ContextType>;
  OdysseyLevelAssets?: OdysseyLevelAssetsResolvers<ContextType>;
  OdysseyLevelBrowseFilter?: OdysseyLevelBrowseFilterResolvers<ContextType>;
  OdysseyLevelDifficultyDetail?: OdysseyLevelDifficultyDetailResolvers<ContextType>;
  OdysseyLevelFilter?: OdysseyLevelFilterResolvers<ContextType>;
  OdysseyLevelFilterInfo?: OdysseyLevelFilterInfoResolvers<ContextType>;
  OdysseyLevelFilterOption?: OdysseyLevelFilterOptionResolvers<ContextType>;
  OdysseyNotAuthorizedError?: OdysseyNotAuthorizedErrorResolvers<ContextType>;
  OdysseyPlayedLevelFilterOption?: OdysseyPlayedLevelFilterOptionResolvers<ContextType>;
  OdysseyStarsLevelFilterOption?: OdysseyStarsLevelFilterOptionResolvers<ContextType>;
  OdysseyStarThreshold?: OdysseyStarThresholdResolvers<ContextType>;
  OdysseyToggleLevelFilter?: OdysseyToggleLevelFilterResolvers<ContextType>;
  OdysseyToggleLevelFilterInfo?: OdysseyToggleLevelFilterInfoResolvers<ContextType>;
  OdysseyTreadConfiguration?: OdysseyTreadConfigurationResolvers<ContextType>;
  OdysseyTreadDifficultyConfiguration?: OdysseyTreadDifficultyConfigurationResolvers<ContextType>;
  OdysseyTreadDifficultyIntensity?: OdysseyTreadDifficultyIntensityResolvers<ContextType>;
  OdysseyTreadLaneConfiguration?: OdysseyTreadLaneConfigurationResolvers<ContextType>;
  OdysseyTreadScoringConfiguration?: OdysseyTreadScoringConfigurationResolvers<ContextType>;
  OdysseyWorkout?: OdysseyWorkoutResolvers<ContextType>;
  OdysseyWorkoutQueryResponse?: OdysseyWorkoutQueryResponseResolvers<ContextType>;
  OdysseyWorkoutSuccessResponse?: OdysseyWorkoutSuccessResponseResolvers<ContextType>;
  Offset?: OffsetResolvers<ContextType>;
  OffsetsCue?: OffsetsCueResolvers<ContextType>;
  OnDemandInstructorClass?: OnDemandInstructorClassResolvers<ContextType>;
  PaceIntensity?: PaceIntensityResolvers<ContextType>;
  PaceLevel?: PaceLevelResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaginatedList?: PaginatedListResolvers<ContextType>;
  PauseInfo?: PauseInfoResolvers<ContextType>;
  Payload?: PayloadResolvers<ContextType>;
  Peloton?: PelotonResolvers<ContextType>;
  PelotonClass?: PelotonClassResolvers<ContextType>;
  PelotonClassFeedWorkout?: PelotonClassFeedWorkoutResolvers<ContextType>;
  PelotonNotCreatedError?: PelotonNotCreatedErrorResolvers<ContextType>;
  PelotonResult?: PelotonResultResolvers<ContextType>;
  PendingInvites?: PendingInvitesResolvers<ContextType>;
  PendingInvitesResponse?: PendingInvitesResponseResolvers<ContextType>;
  PermissionError?: PermissionErrorResolvers<ContextType>;
  Playlist?: PlaylistResolvers<ContextType>;
  PlaylistMusicProvider?: PlaylistMusicProviderResolvers<ContextType>;
  PlaylistPlaybackSong?: PlaylistPlaybackSongResolvers<ContextType>;
  PopularTagClass?: PopularTagClassResolvers<ContextType>;
  PopularTagClassesList?: PopularTagClassesListResolvers<ContextType>;
  Program?: ProgramResolvers<ContextType>;
  ProgramAchievementAssets?: ProgramAchievementAssetsResolvers<ContextType>;
  ProgramAchievementTier?: ProgramAchievementTierResolvers<ContextType>;
  ProgramAssets?: ProgramAssetsResolvers<ContextType>;
  ProgramClass?: ProgramClassResolvers<ContextType>;
  ProgramClassProgress?: ProgramClassProgressResolvers<ContextType>;
  ProgramDay?: ProgramDayResolvers<ContextType>;
  ProgramFitnessDiscipline?: ProgramFitnessDisciplineResolvers<ContextType>;
  ProgramMetaData?: ProgramMetaDataResolvers<ContextType>;
  ProgramProgress?: ProgramProgressResolvers<ContextType>;
  ProgramProgressAlreadyExistsError?: ProgramProgressAlreadyExistsErrorResolvers<ContextType>;
  ProgramProgressNotFoundError?: ProgramProgressNotFoundErrorResolvers<ContextType>;
  ProgramProgressResponse?: ProgramProgressResponseResolvers<ContextType>;
  ProgramProgressResponseSuccess?: ProgramProgressResponseSuccessResolvers<ContextType>;
  ProgramSchedule?: ProgramScheduleResolvers<ContextType>;
  ProgramWeek?: ProgramWeekResolvers<ContextType>;
  ProgramWeekProgress?: ProgramWeekProgressResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RangeCue?: RangeCueResolvers<ContextType>;
  ReadyVideoStream?: ReadyVideoStreamResolvers<ContextType>;
  RemoveTagFromUserResponse?: RemoveTagFromUserResponseResolvers<ContextType>;
  RemoveTagFromUserSuccess?: RemoveTagFromUserSuccessResolvers<ContextType>;
  ReplaceTagResponse?: ReplaceTagResponseResolvers<ContextType>;
  ReplaceTagSuccess?: ReplaceTagSuccessResolvers<ContextType>;
  RetryableError?: RetryableErrorResolvers<ContextType>;
  ScenicClass?: ScenicClassResolvers<ContextType>;
  ScheduledClass?: ScheduledClassResolvers<ContextType>;
  ScheduledClassDoesNotExistError?: ScheduledClassDoesNotExistErrorResolvers<ContextType>;
  ScheduledClassList?: ScheduledClassListResolvers<ContextType>;
  ScheduledClassListResponse?: ScheduledClassListResponseResolvers<ContextType>;
  ScheduledClassResponse?: ScheduledClassResponseResolvers<ContextType>;
  SessionClass?: SessionClassResolvers<ContextType>;
  SetTagAsPrimaryResponse?: SetTagAsPrimaryResponseResolvers<ContextType>;
  SetTagAsPrimarySuccess?: SetTagAsPrimarySuccessResolvers<ContextType>;
  ShareURL?: ShareUrlResolvers<ContextType>;
  Song?: SongResolvers<ContextType>;
  StackConnectionError?: StackConnectionErrorResolvers<ContextType>;
  StackDoesNotExist?: StackDoesNotExistResolvers<ContextType>;
  StackedClass?: StackedClassResolvers<ContextType>;
  StackedClassAlreadyExistsError?: StackedClassAlreadyExistsErrorResolvers<ContextType>;
  StackedClassCannotBeAddedError?: StackedClassCannotBeAddedErrorResolvers<ContextType>;
  StackedClassConstraintViolationError?: StackedClassConstraintViolationErrorResolvers<ContextType>;
  StackedClassDoesNotExistInStackError?: StackedClassDoesNotExistInStackErrorResolvers<ContextType>;
  StackedClassInvalidPelotonClassIdError?: StackedClassInvalidPelotonClassIdErrorResolvers<ContextType>;
  StackedClassLimitReachedError?: StackedClassLimitReachedErrorResolvers<ContextType>;
  StackError?: StackErrorResolvers<ContextType>;
  StackPermissionError?: StackPermissionErrorResolvers<ContextType>;
  StackResponse?: StackResponseResolvers<ContextType>;
  StackResponseSuccess?: StackResponseSuccessResolvers<ContextType>;
  StartFreestyleWorkoutResponse?: StartFreestyleWorkoutResponseResolvers<ContextType>;
  StartFreestyleWorkoutResponseSuccess?: StartFreestyleWorkoutResponseSuccessResolvers<ContextType>;
  StartGymWorkoutResponse?: StartGymWorkoutResponseResolvers<ContextType>;
  StartGymWorkoutResponseSuccess?: StartGymWorkoutResponseSuccessResolvers<ContextType>;
  StreakAwardedEventPayload?: StreakAwardedEventPayloadResolvers<ContextType>;
  StreamablePlaylist?: StreamablePlaylistResolvers<ContextType>;
  SubscriptionIdNotFoundError?: SubscriptionIdNotFoundErrorResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  TagAlreadyExistsError?: TagAlreadyExistsErrorResolvers<ContextType>;
  TagAssets?: TagAssetsResolvers<ContextType>;
  TagBrowseCategory?: TagBrowseCategoryResolvers<ContextType>;
  TagBrowseCategoryAssets?: TagBrowseCategoryAssetsResolvers<ContextType>;
  TagCapacityOverLimitError?: TagCapacityOverLimitErrorResolvers<ContextType>;
  TagDoesNotExistOnUserError?: TagDoesNotExistOnUserErrorResolvers<ContextType>;
  TagEdge?: TagEdgeResolvers<ContextType>;
  TagNameInvalidCharacterError?: TagNameInvalidCharacterErrorResolvers<ContextType>;
  TagNameLengthError?: TagNameLengthErrorResolvers<ContextType>;
  TagNameProhibitedError?: TagNameProhibitedErrorResolvers<ContextType>;
  TagPaginatedList?: TagPaginatedListResolvers<ContextType>;
  TargetMetrics?: TargetMetricsResolvers<ContextType>;
  TargetMetricsData?: TargetMetricsDataResolvers<ContextType>;
  TargetMetricsOutput?: TargetMetricsOutputResolvers<ContextType>;
  UnpauseWorkoutResponse?: UnpauseWorkoutResponseResolvers<ContextType>;
  UnreadNotifications?: UnreadNotificationsResolvers<ContextType>;
  UnreadNotificationsResponse?: UnreadNotificationsResponseResolvers<ContextType>;
  UpdateRelationshipResponse?: UpdateRelationshipResponseResolvers<ContextType>;
  UpdateRelationshipSuccess?: UpdateRelationshipSuccessResolvers<ContextType>;
  URL?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserAssets?: UserAssetsResolvers<ContextType>;
  UserEdge?: UserEdgeResolvers<ContextType>;
  UserNotAuthorizedError?: UserNotAuthorizedErrorResolvers<ContextType>;
  UserPaginatedList?: UserPaginatedListResolvers<ContextType>;
  UserPrivacyError?: UserPrivacyErrorResolvers<ContextType>;
  UserProtectedFields?: UserProtectedFieldsResolvers<ContextType>;
  UserProtectedFieldsResult?: UserProtectedFieldsResultResolvers<ContextType>;
  UserStack?: UserStackResolvers<ContextType>;
  UserTag?: UserTagResolvers<ContextType>;
  UserTags?: UserTagsResolvers<ContextType>;
  VideoStream?: VideoStreamResolvers<ContextType>;
  VideoStreamOrError?: VideoStreamOrErrorResolvers<ContextType>;
  Workout?: WorkoutResolvers<ContextType>;
  WorkoutFinalizedEventPayload?: WorkoutFinalizedEventPayloadResolvers<ContextType>;
  WorkoutMetricDetails?: WorkoutMetricDetailsResolvers<ContextType>;
  WorkoutPostClassResponse?: WorkoutPostClassResponseResolvers<ContextType>;
  YourScheduleItem?: YourScheduleItemResolvers<ContextType>;
  YourScheduleItemList?: YourScheduleItemListResolvers<ContextType>;
  YourScheduleListResponse?: YourScheduleListResponseResolvers<ContextType>;
  ZoneScore?: ZoneScoreResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = Context> = Resolvers<ContextType>;
