import {
  checkUserExists,
  isUserOldEnough,
  getRemoteDeviceActivation,
  getUser,
  getRemoteAddSharedUser,
  checkRemoteLogin,
  getUserMonthOverview,
  getUserWorkoutCalendar,
  getUserOverview,
  getUserSettings,
  getUserAchievementsExtended,
  isBirthdayOldEnough,
  getMeExtended,
  membershipTieringInfo,
} from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type RequiredParams = {
  pelotonPlatform: Parameters<typeof getMeExtended>[1];
};

type RequiredUserParams = {
  id: Parameters<typeof getUser>[1];
  pelotonPlatform: Parameters<typeof getUser>[2];
};

type OptionalUserParams = {
  email?: string;
  username?: string;
};

type GetRemoteActivationParams = {
  userId: string;
  activationId: string;
};

type GetRemoteAddSharedUserParams = {
  userId: string;
  remoteAddSharedUserId: string;
};

type CheckRemoteLoginParams = {
  userCode: string;
};

type GetMonthOverviewParams = {
  id: Parameters<typeof getUserMonthOverview>[1];
  month: Parameters<typeof getUserMonthOverview>[2]; // format YYYY-MM
};

type RequiredWorkoutCalendarParams = {
  id: Parameters<typeof getUserWorkoutCalendar>[1];
  pelotonClientDate: Parameters<typeof getUserWorkoutCalendar>[2];
};

type GetOverviewParams = {
  id: Parameters<typeof getUserOverview>[1];
};

type GetUserSettingsParams = {
  id: Parameters<typeof getUserSettings>[1];
};

type GetUserAchievementsParams = {
  id: Parameters<typeof getUserAchievementsExtended>[1];
};

type GetIsBirthdayOldEnoughParams = {
  birthday: number;
  country?: string;
};

export const GetIsUserOldEnoughKey = `GetIsUserOldEnough`;

export const toUsersFetchers = (api: typeof engageApi = engageApi) => ({
  GetRemoteActivation: toFetcher(
    ({ activationId }) => `GetRemoteActivation[${activationId}]`,
    (req: GetRemoteActivationParams) =>
      unwrap(() => getRemoteDeviceActivation(api, req.userId, req.activationId)),
    {
      revalidateOnFocus: false,
    },
  ),
  GetRemoteAddSharedUser: toFetcher(
    ({ remoteAddSharedUserId }) => `GetRemoteAddSharedUser[${remoteAddSharedUserId}]`,
    (req: GetRemoteAddSharedUserParams) =>
      unwrap(() => getRemoteAddSharedUser(api, req.userId, req.remoteAddSharedUserId)),
    {
      revalidateOnFocus: false,
    },
  ),
  GetIsUserOldEnough: toFetcher(
    () => GetIsUserOldEnoughKey,
    (req: { shouldSkipFetch?: boolean }) =>
      unwrap(() =>
        !!req?.shouldSkipFetch
          ? (new Promise(() => null) as ReturnType<typeof isUserOldEnough>)
          : isUserOldEnough(api),
      ),
  ),
  GetMe: toFetcher(
    () => `GetMe`,
    (req: RequiredParams) => unwrap(() => getMeExtended(api, req.pelotonPlatform)),
  ),
  CheckRemoteLogin: toFetcher(
    ({ userCode }) => `CheckRemoteLogin[${userCode}]`,
    (req: CheckRemoteLoginParams) => unwrap(() => checkRemoteLogin(api, req.userCode)),
    {
      revalidateOnFocus: false,
    },
  ),
  GetUser: toFetcher(
    () => `GetUser`,
    (req: RequiredUserParams) =>
      unwrap(() =>
        getUser(
          api,
          req.id,
          req.pelotonPlatform,
          'tooltips, willy_nilly, first_analytics_session, wolfpack',
        ),
      ),
  ),
  GetMonthOverview: toFetcher(
    ({ id, month }) => `GetMonthOverview${id}${month}`,
    (req: GetMonthOverviewParams) =>
      unwrap(() => getUserMonthOverview(api, req.id, req.month)),
  ),
  GetWorkoutCalendar: toFetcher(
    ({ id }) => `GetCalendar${id}`,
    (req: RequiredWorkoutCalendarParams) =>
      unwrap(() => getUserWorkoutCalendar(api, req.id, req.pelotonClientDate)),
  ),
  GetOverview: toFetcher(
    ({ id }) => `GetOverview${id}`,
    (req: GetOverviewParams) =>
      unwrap(() => getUserOverview(api, req.id, 1, 'recent_workouts', 2)),
  ),
  CheckUserExists: toFetcher(
    ({ username, email }) => `CheckUserExists[${username}][${email}]`,
    (optParams: OptionalUserParams) =>
      unwrap(() => checkUserExists(api, optParams.email, optParams.username)),
  ),
  CheckFreeTrialEligibility: toFetcher(
    () => `CheckFreeTrialEligibility`,
    () =>
      unwrap(() =>
        api.axios.get<{ isEligibleForFreeTrial: boolean }>(
          '/api/user/free_trial_eligibility',
        ),
      ),
  ),
  GetUserSettings: toFetcher(
    () => `GetUserSettings`,
    (req: GetUserSettingsParams) => unwrap(() => getUserSettings(api, req.id)),
  ),
  GetUserAchievements: toFetcher(
    () => `GetUserAchievements`,
    (req: GetUserAchievementsParams) =>
      unwrap(() => getUserAchievementsExtended(api, req.id)),
  ),
  GetIsBirthdayOldEnough: toFetcher(
    ({ birthday, country }) => `GetIsBirthdayOldEnough${country}${birthday}`,
    (req: GetIsBirthdayOldEnoughParams) =>
      unwrap(() => isBirthdayOldEnough(api, req.birthday, req.country)),
  ),
  GetMembershipTieringInfo: toFetcher(
    () => `GetMembershipTieringInfo`,
    () => unwrap(() => membershipTieringInfo(api)),
  ),
});
