import React from 'react';
import type { Time } from '@peloton/time';
import {
  toDiffDuration,
  toNowTime,
  toTime,
  toAbsoluteTimerTime,
  toTimeFromString,
} from '@peloton/time';
import { Social, SocialCopy } from '@members/copy';
import { CLASS_STATUS } from '../constants';
import type { CLASS_TYPE_CATEGORY } from '../constants';

export const countdownToStartString = (startTime: string, hourString: string) => {
  const diff = toDiffDuration(toNowTime(), toTime(startTime));
  return diff.asDays() >= 1
    ? `${Math.round(diff.asDays())}`
    : diff.asHours() >= 2
    ? `${Math.round(diff.asHours())} ${hourString}`
    : `${toAbsoluteTimerTime(diff, 60, false)}`;
};

export const countdownSinceStartString = (startTime: string) => {
  const diff = toDiffDuration(toTime(startTime), toNowTime());
  return `${toAbsoluteTimerTime(diff, 60, false)}`;
};

const DaysWrapper: React.FC<React.PropsWithChildren<{ scheduledTime: string }>> = ({
  scheduledTime,
  children,
}) => {
  const countdownDays = toDiffDuration(
    toNowTime(),
    toTimeFromString(scheduledTime),
  ).asDays();
  return countdownDays >= 1 ? (
    <>
      {children}{' '}
      <SocialCopy id={Social.Days} values={{ daysCount: Math.round(countdownDays) }} />
    </>
  ) : (
    <>{children}</>
  );
};

export const onDemandCountdownText = (
  time: string,
  status: string,
  hourString: string,
  showShorterCopy?: boolean,
) => {
  const startCopyId = showShorterCopy ? Social.StartsIn : Social.ScheduledToStartIn;
  const countdownStrings = {
    [CLASS_STATUS.NotScheduled]: (
      <DaysWrapper scheduledTime={time}>
        <SocialCopy
          id={Social.StartingIn}
          values={{ timeString: countdownToStartString(time, hourString) }}
        />
      </DaysWrapper>
    ),
    [CLASS_STATUS.Scheduled]: (
      <DaysWrapper scheduledTime={time}>
        <SocialCopy
          id={startCopyId}
          values={{ timeString: countdownToStartString(time, hourString) }}
        />
      </DaysWrapper>
    ),
    [CLASS_STATUS.StartingSoon]: (
      <SocialCopy
        id={startCopyId}
        values={{ timeString: countdownToStartString(time, hourString) }}
      />
    ),
    [CLASS_STATUS.Elapsed]: (
      <SocialCopy
        id={Social.ScheduledForAgo}
        values={{ minutes: countdownSinceStartString(time) }}
      />
    ),
  };
  return countdownStrings[status];
};

export const liveCountdownText = (
  time: string,
  status: string,
  hourString: string,
  showShorterCopy?: boolean,
) => {
  const countdownStrings = {
    [CLASS_STATUS.NotScheduled]: (
      <DaysWrapper scheduledTime={time}>
        <SocialCopy
          id={Social.StartingIn}
          values={{ timeString: countdownToStartString(time, hourString) }}
        />
      </DaysWrapper>
    ),
    [CLASS_STATUS.Scheduled]: (
      <DaysWrapper scheduledTime={time}>
        <SocialCopy
          id={showShorterCopy ? Social.StartsIn : Social.ScheduledToStartIn}
          values={{ timeString: countdownToStartString(time, hourString) }}
        />
      </DaysWrapper>
    ),
    [CLASS_STATUS.StartingSoon]: (
      <SocialCopy
        id={showShorterCopy ? Social.StartsIn : Social.StartingIn}
        values={{ timeString: countdownToStartString(time, hourString) }}
      />
    ),
    [CLASS_STATUS.Elapsed]: (
      <SocialCopy
        id={Social.MinutesElapsed}
        values={{ minutes: countdownSinceStartString(time) }}
      />
    ),
  };
  return countdownStrings[status];
};

export const isBeforeNow = (startTime: string | Time) =>
  typeof startTime === 'string'
    ? toTime(startTime).isBefore(toNowTime())
    : startTime.isBefore(toNowTime());

export const isAfterNow = (startTime: string) => toTime(startTime).isAfter(toNowTime());

export const isStarting = (startTime: string) =>
  isAfterNow(startTime) && toTime(startTime).isBefore(toNowTime().add(5, 'minutes'));

export const isElapsed = (startTime: string, endTime: string) =>
  isBeforeNow(startTime) && isAfterNow(endTime);
export const determineClassStatusByTime = (
  isScheduled: boolean,
  startTime: string,
  endTime: string,
) => {
  if (isStarting(startTime)) {
    return CLASS_STATUS.StartingSoon;
  } else if (isElapsed(startTime, endTime)) {
    return CLASS_STATUS.Elapsed;
  } else if (isBeforeNow(endTime)) {
    return CLASS_STATUS.Ended;
  } else if (isScheduled && isAfterNow(startTime)) {
    return CLASS_STATUS.Scheduled;
  } else {
    return CLASS_STATUS.NotScheduled;
  }
};

export const useGetQueryTimeRange = (weeks: number = 2) => {
  const [startTime] = React.useState(
    toNowTime().startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.000Z'),
  );
  const [endTime] = React.useState(
    toNowTime()
      .startOf('day')
      .add(weeks, 'week')
      .subtract(1, 'day')
      .endOf('day')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.000Z'),
  );

  return { startTime, endTime };
};

const calculateOccassionType = (
  classTypeCategory: CLASS_TYPE_CATEGORY,
  occasionSlug?: string,
) => (occasionSlug === 'justbecause' || !occasionSlug ? classTypeCategory : 'OCCASION');

export const calculateTitleValues = (
  isHost: boolean,
  hostUsername: string,
  inviterUsername: string,
  classTypeCategory: CLASS_TYPE_CATEGORY,
  occasionSlug?: string,
  occasionTitle?: string,
) => {
  const occasionType = calculateOccassionType(classTypeCategory, occasionSlug);
  if (isHost) {
    return {
      copyName: Social.HostInviteTitle,
      values: {
        occasionTitle: occasionTitle ?? '',
        type: occasionType,
      },
    };
  }
  if (hostUsername === inviterUsername) {
    return {
      copyName: Social.HostToInviteeTitle,
      values: {
        hostName: inviterUsername,
        occasionTitle: occasionTitle ?? '',
        type: occasionType,
      },
    };
  } else {
    return {
      copyName: Social.InviteeToInviteeTitle,
      values: {
        hostName: hostUsername,
        invitee: inviterUsername,
        occasionTitle: occasionTitle ?? '',
        type: occasionType,
      },
    };
  }
};

export const calculatePublicTitleValues = (
  hostUsername: string,
  classTypeCategory: CLASS_TYPE_CATEGORY,
  occasionSlug?: string,
  occasionTitle?: string,
) => {
  const occasionType = calculateOccassionType(classTypeCategory, occasionSlug);
  return {
    copyName: Social.HostPublicShareOccasion,
    values: {
      hostname: hostUsername,
      occasionTitle,
      type: occasionType,
    },
  };
};

export const calculateCancelTitleValues = (
  occasionTitle: string,
  hostUsername: string,
  occasionSlug: string,
  classTypeCategory: CLASS_TYPE_CATEGORY,
) => {
  const occasionType = calculateOccassionType(classTypeCategory, occasionSlug);
  return {
    copyName: Social.HostCancelWithOccasion,
    values: {
      hostname: hostUsername,
      occasionTitle,
      type: occasionType,
    },
  };
};
