import type { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { updateNotificationSettings as update } from '../api';
import type { UpdateNotificationSettingsAction } from '../redux';

export default function* (
  client: Client,
  { presenter, settings }: UpdateNotificationSettingsAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  if (userId) {
    try {
      yield call(update, client, userId, settings);
      presenter.displaySuccessMessage();
    } catch {
      presenter.displayErrorMessage();
    }
  } else {
    presenter.redirectToLoginPage();
  }
}
