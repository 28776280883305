import React from 'react';
import styled from 'styled-components';
import { getUserId } from '@peloton/auth/redux';
import { media } from '@peloton/styles';
import { white, superRed, slate1, slate2 } from '@engage/colors';
import { useReduxState } from '@engage/redux/hooks';
import { spaces } from '@engage/styles/spaces';
import { body14, label12 } from '@engage/typography';
import { Button1Large } from '@members/buttons';
import { Checkbox } from '@members/checkbox/Checkbox';
import { useTiering, Tiering, TieringCopy } from '@members/copy';
import { useAllCopy } from '@members/copy/CopyProvider/CopyProvider';
import RightArrowIcon from '@members/icons/RightArrowIcon';
import { useRemainingClassesQuota } from '../hooks/useRemainingClassesQuota';
import { QUOTA_REMINDER_MODAL } from '../models';
import type { Modal, ModalClassHandler } from '../models';
import { useTierContext } from '../TierProvider';
import {
  convertStringListToConjunctString,
  getFitnessSlugDisplayName,
  getQuotaRefreshDate,
} from '../utils';
import TieringModalView from './TieringModalView';

const QuotaReminderModal: React.FC<
  React.PropsWithChildren<{
    modal: Modal;
    setModal: React.Dispatch<React.SetStateAction<Modal>>;
  }>
> = ({ modal, setModal }) => {
  const {
    tier,
    marketingData: { digitalLimitedDisciplinesMarketing },
    modalClassHandler,
    refetch,
  } = useTierContext();

  refetch();
  const quotaCount = useRemainingClassesQuota();

  const modalHeaderCopy = useTiering(Tiering.ThisUsesOneClass);

  const { quotaRefreshMonthNameShort, quotaRefreshDayOfMonth } = getQuotaRefreshDate(
    tier.limitedClassesResetDate,
  );

  const userId = useReduxState(getUserId);

  const setShowQuotaReminderModal = (shouldShow: boolean) =>
    window.localStorage?.setItem(
      `${QUOTA_REMINDER_MODAL}-${userId}`,
      shouldShow ? 'true' : 'false',
    );

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  return (
    <TieringModalView
      modal={modal}
      setModal={setModal}
      modalHeaderCopy={modalHeaderCopy}
      modalDescriptionChild={
        <QuotaAvailableDescription
          numLimitedClassesTotal={tier.numLimitedClassesTotal}
          quotaCount={quotaCount}
          quotaRefreshMonthNameShort={quotaRefreshMonthNameShort}
          quotaRefreshDayOfMonth={quotaRefreshDayOfMonth}
          digitalLimitedDisciplinesMarketing={digitalLimitedDisciplinesMarketing}
        />
      }
      bottomChildren={
        <>
          <ClassPlayButton
            modalClassHandler={modalClassHandler}
            closeModal={closeModal}
          />
          <CheckboxHandler setShowQuotaReminderModal={setShowQuotaReminderModal} />
        </>
      }
    />
  );
};

const QuotaAvailableDescription: React.FC<
  React.PropsWithChildren<{
    numLimitedClassesTotal: number;
    quotaCount: number;
    quotaRefreshMonthNameShort: string;
    quotaRefreshDayOfMonth: string;
    digitalLimitedDisciplinesMarketing: string[];
  }>
> = ({
  numLimitedClassesTotal,
  quotaCount,
  quotaRefreshMonthNameShort,
  quotaRefreshDayOfMonth,
  digitalLimitedDisciplinesMarketing,
}) => {
  const allCopy = useAllCopy();
  const localizedCardioEquipmentDisciplines = digitalLimitedDisciplinesMarketing.map(
    slug => getFitnessSlugDisplayName(slug, allCopy),
  );

  const conjuctCardioDisciplines = convertStringListToConjunctString(
    localizedCardioEquipmentDisciplines,
    useTiering(Tiering.And),
  );

  const classesUsedOfTotalCopy = useTiering(Tiering.ClassesUsedOfTotal, {
    classesUsed: quotaCount,
    classesTotal: numLimitedClassesTotal,
  });

  const youCurrentlyHaveSingularPluralCopy = useTiering(
    Tiering.YouCurrentlyHaveSingularPlural,
    {
      classes: quotaCount,
    },
  );

  const modalDescriptionCopy = useTiering(Tiering.ClassesUsedOfCardioEquipment, {
    youCurrentlyHave: youCurrentlyHaveSingularPluralCopy,
    classesUsedOfTotal: <LabelBold>{classesUsedOfTotalCopy}</LabelBold>,
    cardioEquipmentClasses: conjuctCardioDisciplines,
  });

  const limitRefreshCopy = useTiering(Tiering.QuotaLimitRefreshDate, {
    maxClasses: numLimitedClassesTotal,
    monthNameShort: <LabelBold>{quotaRefreshMonthNameShort}</LabelBold>,
    dayOfMonth: <LabelBold>{quotaRefreshDayOfMonth}</LabelBold>,
  });

  return (
    <QuotaAvailableDescriptionContainer>
      {modalDescriptionCopy}
      <Spacer marginTop={`${spaces.xSmall}`}>
        <TieringCopy id={Tiering.TakeClassesWithAnyEquipmentPlatform} />
      </Spacer>
      <Spacer marginTop={`${spaces.xLarge}`}>{limitRefreshCopy}</Spacer>
    </QuotaAvailableDescriptionContainer>
  );
};

const QuotaAvailableDescriptionContainer = styled.div`
  ${body14}
  color: ${slate2};
  margin-top: ${spaces.xLarge}px;
  margin-bottom: ${spaces.xLarge}px;
`;

const LabelBold = styled.span`
  font-weight: 900;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const Spacer = styled.div<{ marginTop?: string }>`
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}px;
`;

const ClassPlayButton: React.FC<
  React.PropsWithChildren<{
    modalClassHandler: ModalClassHandler;
    closeModal: () => void;
  }>
> = ({ modalClassHandler, closeModal }) => {
  const { isOnDemand, suggestedOnClick } = modalClassHandler;

  const handleOnCLick = () => {
    closeModal();
    suggestedOnClick();
  };

  return (
    <ClassPlayButtonContainer onClick={handleOnCLick}>
      <StyledRightArrowIcon />
      {isOnDemand ? (
        <TieringCopy id={Tiering.Start} />
      ) : (
        <TieringCopy id={Tiering.JoinClass} />
      )}
    </ClassPlayButtonContainer>
  );
};

const ClassPlayButtonContainer = styled(Button1Large)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${superRed};
  color: ${white};
  width: 280px;
  ${media.tablet`
  width: 320px;`}
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  margin-right: ${spaces.xSmall}px;
  height: ${spaces.medium}px;

  ${media.tablet`
      height: ${spaces.large}px;
    `}
`;

const CheckboxHandler: React.FC<
  React.PropsWithChildren<{
    setShowQuotaReminderModal: (shouldShow: boolean) => void;
  }>
> = ({ setShowQuotaReminderModal }) => {
  const [isChecked, setIsChecked] = React.useState(false); // change to use local storage
  const handleChange = () => {
    setIsChecked(!isChecked);
    setShowQuotaReminderModal(isChecked);
  };

  const checkboxLabelCopy = useTiering(Tiering.DontShowAgain);
  return (
    <CheckboxContainer>
      <Checkbox isChecked={isChecked} onClick={handleChange} />
      <CheckboxLabel>{checkboxLabelCopy}</CheckboxLabel>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${spaces.xxxLarge}px 0px ${spaces.xxxLarge}px;
`;

const CheckboxLabel = styled.p`
  ${label12}
  color: ${slate1};
  padding-left: 10px;
`;

export default QuotaReminderModal;
