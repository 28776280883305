import { css } from 'styled-components';

export const tiersGradient = css`
  background: linear-gradient(90deg, #ff3347 51.56%, #fc820f 100%);
`;

export const tiersLabelGradient = css`
  ${tiersGradient}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
