import type { Reducer } from 'react';
import type { Action } from './actions';
import { ActionType } from './actions';

export type State = boolean;
export const DEFAULT_STATE: State = false;

export const reducer: Reducer<State, Action> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionType.MergeToggles:
    case ActionType.MergeVariables:
      return true;
    default:
      return state;
  }
};
