import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate2, slate3, white } from '@engage/colors';
import { spaces } from '@engage/styles/spaces';
import { body14, title24, label16, title20, body16 } from '@engage/typography';
import { Modal as PageModal } from '@members/modal/Modal';
import CloseModalIcon from '../icons/CloseModalIcon';
import type { Modal } from '../models';

type TieringModalProps = {
  modal: Modal;
  setModal: React.Dispatch<React.SetStateAction<Modal>>;
  modalLabelCopy?: React.ReactNode;
  modalHeaderCopy?: string;
  modalHeaderChild?: React.ReactNode;
  modalDescriptionCopy?: string;
  modalDescriptionChild?: React.ReactNode;
  modalSubtitleCopy?: string;
  ctaChildren?: React.ReactNode;
  bottomChildren?: React.ReactNode;
  style?: ReactModal.Styles;
};

const TieringModalView: React.FC<React.PropsWithChildren<TieringModalProps>> = ({
  modal,
  setModal,
  modalLabelCopy,
  modalHeaderCopy,
  modalHeaderChild,
  modalDescriptionCopy,
  modalDescriptionChild,
  modalSubtitleCopy,
  ctaChildren,
  bottomChildren,
  style,
}) => {
  const isOpen = modal.isOpen;

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const overlayRef = (ref: HTMLDivElement) => {
    if (ref && ref?.style) {
      ref.style.zIndex = '5';
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel={`Tiering Information Modal`}
      closeHandler={closeModal}
      overlayRef={overlayRef}
      hideCloseButton={true}
      showMobileCloseButton={false}
      style={style}
    >
      <ModalContentContainer data-test-id="tieringInfoModal">
        <CloseoutContainer>
          <CloseoutButton onClick={closeModal} data-test-id="closeUpgradeModalButton">
            <StyledCloseModalIcon />
          </CloseoutButton>
        </CloseoutContainer>
        <ModalTextContainer data-test-id="modalText">
          {!!modalLabelCopy && <ModalTierLabel>{modalLabelCopy}</ModalTierLabel>}
          {!!modalHeaderCopy && <ModalHeader>{modalHeaderCopy}</ModalHeader>}
          {modalHeaderChild}
          {!!modalDescriptionCopy && (
            <ModalDescription>{modalDescriptionCopy}</ModalDescription>
          )}
          {modalDescriptionChild}
          {!!modalSubtitleCopy && <ModalSubtitle>{modalSubtitleCopy}</ModalSubtitle>}
        </ModalTextContainer>
        {!!ctaChildren && (
          <CTAContainer data-test-id="upgradeButton">{ctaChildren}</CTAContainer>
        )}
        {bottomChildren}
      </ModalContentContainer>
    </StyledModal>
  );
};

const StyledModal = styled(PageModal)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - ${spaces.xxxHuge}px);
  overflow: hidden;
  overflow-y: auto;
  background-color: ${white};
  border-radius: ${spaces.xSmall}px;
  text-align: center;
  height: auto;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${white};
`;

const CloseoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: ${spaces.medium}px;
  padding-right: ${spaces.medium}px;
  position: fixed;
`;

const CloseoutButton = styled.button`
  width: ${spaces.medium}px;
  height: ${spaces.medium}px;
`;

const StyledCloseModalIcon = styled(CloseModalIcon)`
  width: ${spaces.medium}px;
  height: ${spaces.medium}px;
  color: ${slate2};
`;

const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spaces.medium}px ${spaces.xxxLarge}px 0px;
`;

const ModalTierLabel = styled.div`
  ${label16}
  ${slate2}
  padding-top: ${spaces.medium}px;
  text-transform: capitalize;
`;

const ModalHeader = styled.p`
  ${title20}
  color: ${slate3};
  padding-top: ${spaces.xxxLarge}px;
  ${media.tablet`
  ${title24}`}
`;

const ModalDescription = styled.div`
  ${body14}
  color: ${slate2};
  text-align: center;
  padding-top: ${spaces.small}px;
`;

const ModalSubtitle = styled.div`
  padding-top: ${spaces.small}px;
  &::first-letter {
    text-transform: uppercase;
  }
  color: ${slate2};
  ${body14}
  ${media.tablet`
  ${body16}`}
`;

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 44px;
`;

export default TieringModalView;
