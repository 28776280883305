import { compose, sortBy, prop } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import type { BrowseCategory } from '@engage/browse-categories';
import {
  BrowseCategorySlug,
  browseCategorySlugToCaesarMapper,
  browseCategoryFromCaesarMapper,
} from '@engage/browse-categories';
import type { ApiClass, Class } from '@engage/classes';
import { mapRideToClass, ContentFormat } from '@engage/classes';
import type { AppliedFilter, SortValue } from '@engage/filters';
import type { PageNumber } from '@engage/pagination';
import { done } from '@engage/pagination';
import { config } from './config';

type Joinable = { [k: string]: string | number | boolean };
const queryStringify = (params: Joinable): string =>
  Object.keys(params)
    .map(k => `${k}=${params[k]}`)
    .join('&');

const appliedFiltersToJoinable = (applied: AppliedFilter[]): Joinable =>
  applied.reduce(
    (obj, { name, value }) => ({
      ...obj,
      [name === 'app-free' ? 'is_freemium' : name]: value,
    }),
    {},
  );

const toSortParams = (sortValue: SortValue | undefined): Joinable =>
  sortValue ? { sort_by: sortValue.sort, desc: sortValue.desc } : {};

export const audioAndVideoFormats = `${ContentFormat.Audio},${ContentFormat.Video}`;

export const fetchLibraryClassesByCategorySlug = (
  api: Client,
  categorySlug: BrowseCategorySlug,
  appliedFilters: AppliedFilter[],
  sortValue: SortValue,
  page: number,
  limit = config.libraryPageSize,
) =>
  api
    .get<ApiLibraryClasses>(
      [
        '/api/v2/ride/archived',
        queryStringify({
          ...(categorySlug !== BrowseCategorySlug.All &&
            categorySlug !== BrowseCategorySlug.Free && {
              browse_category: browseCategorySlugToCaesarMapper(categorySlug),
            }),
          ...(categorySlug === BrowseCategorySlug.Free && {
            is_freemium: true,
          }),
          content_format: audioAndVideoFormats,
          limit,
          page,
          ...appliedFiltersToJoinable(appliedFilters),
          ...toSortParams(sortValue),
        }),
      ].join('?'),
    )
    .then(pipeData<ApiLibraryClasses, LibraryData>(toLibraryData));

export const fetchBrowseCategories = (api: Client): Promise<BrowseCategory[]> =>
  api
    .get<ApiBrowseCategories>('/api/browse_categories?library_type=on_demand')
    .then(pipeData<ApiBrowseCategories, BrowseCategory[]>(toSortedBrowseCategories));

export const toLibraryData = (response: ApiLibraryClasses): LibraryData => ({
  classes: toClasses(response),
  browseCategories: toSortedBrowseCategories(response).map(
    browseCategoryFromCaesarMapper,
  ),
  totalClassesCount: response.total,
  pageCount: response.pageCount,
  nextPage: toNextPage(response),
});

const toClasses = (response: ApiLibraryClasses): Class[] =>
  response.data.map(mapRideToClass);

export const toSortedBrowseCategories = (
  response: ApiBrowseCategories,
): BrowseCategory[] =>
  compose<ApiBrowseCategory[], ApiBrowseCategory[], BrowseCategory[]>(
    toBrowseCategories,
    sortBy(prop('listOrder')),
  )(response.browseCategories);

const toBrowseCategories = (categories: ApiBrowseCategory[]): BrowseCategory[] =>
  categories.map(c => ({
    name: c.name,
    slug: c.slug,
    // portalImageUrl and iconUrl can be not specified in the CMS but practically we expect
    // them to be non-null
    portalImageUrl: c.portalImageUrl!,
    iconUrl: c.iconUrl!,
    hasLimitedRides: c.hasLimitedRides!,
  }));

export const toNextPage = (response: ApiNextPage): PageNumber =>
  response.showNext ? response.page + 1 : done;

export type LibraryData = {
  classes: Class[];
  browseCategories: BrowseCategory[];
  totalClassesCount: number;
  pageCount: number;
  nextPage: PageNumber;
};

export type ApiLibraryClasses = ApiBrowseCategories &
  ApiNextPage & {
    data: ApiClass[];
    total: number;
    pageCount: number;
  };

export type ApiBrowseCategories = {
  browseCategories: ApiBrowseCategory[];
};

export type ApiNextPage = {
  showNext: boolean;
  page: number;
};

type ApiBrowseCategory = {
  portalImageUrl?: string;
  name: string;
  listOrder: number;
  slug: BrowseCategorySlug;
  iconUrl?: string;
  hasLimitedRides: boolean;
};
