import styled from 'styled-components';
import { hover } from '@peloton/styles';
import { gray1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label14 } from '@engage/typography';
import {
  mainLinkStyles,
  makeLinkActivatable,
  layoutTextStyles,
  hoverLinkStyles,
} from '../../sharedLinkStyles';
import NavLink from '../NavLink';

export const StyledMainLink = styled(NavLink)`
  ${label14}
  ${hoverLinkStyles}
  ${mainLinkStyles}
  ${layoutTextStyles}
  padding: ${spaces.xxSmall}px ${spaces.small}px;
  pointer-events: auto;

  ${hover`
    background-color: ${gray1};
  `}
`;

const MainLink = makeLinkActivatable(StyledMainLink);

export default MainLink;
