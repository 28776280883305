import { toBaseProperties } from '@members/analytics';

export const trackBlockedUser = (source: string, otherUsername: string) => ({
  ...toBaseProperties(),
  event: 'Blocked User',
  ['Other Username']: otherUsername,
  ['Source']: source,
});

export const trackHiddenTag = (tag: string, source: string, otherUsername: string) => ({
  ...toBaseProperties(),
  event: 'Hid Tag',
  ['Tag']: tag,
  ['Other Username']: otherUsername,
  ['Source']: source,
});
