import type { Reducer } from 'redux';
import type { DataState } from '@peloton/redux-fetch/redux';

const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.REQUEST_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        hasSearched: true,
        isLoading: false,
      };
    case Actions.REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case Actions.CLEAR_RESULTS:
      return defaultState;
    default:
      return state;
  }
};

const defaultState: State = {
  isLoading: false,
  hasSearched: false,
};

type State = DataState<string[]> & { hasSearched: boolean };

// Actions
export type RequestAction = {
  type: Actions.REQUEST;
  payload: { usernamePrefix: string };
};
type SuccessAction = { type: Actions.REQUEST_SUCCESS; payload: string[] };
type FailureAction = { type: Actions.REQUEST_FAILURE; payload: Error };
type ClearAction = { type: Actions.CLEAR_RESULTS };

export enum Actions {
  REQUEST = 'pelo/memberSearch/REQUEST',
  REQUEST_SUCCESS = 'pelo/memberSearch/REQUEST_SUCCESS',
  REQUEST_FAILURE = 'pelo/memberSearch/RequestFailure',
  CLEAR_RESULTS = 'pelo/memberSearch/CLEAR_RESULTS',
}

// Action Creators
type Action = RequestAction | SuccessAction | FailureAction | ClearAction;

export const loadSearchResults = (usernamePrefix: string): RequestAction => ({
  type: Actions.REQUEST,
  payload: { usernamePrefix },
});

export const loadSearchResultsSuccess = (searchResults: string[]): SuccessAction => ({
  type: Actions.REQUEST_SUCCESS,
  payload: searchResults,
});

export const loadSearchResultsFailure = (error: Error): FailureAction => ({
  type: Actions.REQUEST_FAILURE,
  payload: error,
});

export const clearSearchResults = (): ClearAction => ({
  type: Actions.CLEAR_RESULTS,
});

// Selectors
export type MemberSearchSelectorState = {
  memberSearch: State;
};

export const getSearchResults = (state: MemberSearchSelectorState) =>
  state.memberSearch.entity;

export const hasSearched = (state: MemberSearchSelectorState) =>
  state.memberSearch.hasSearched;

export default reducer;
