import { transparentize } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { scrollableRowStyles } from '@peloton/styles';
import { black, white } from '@engage/colors';
import { useSite, Site } from '@members/copy';
import { navContainerStyles } from '../containers';

const MOBILE_PADDING = 3.5;
const TABLET_DESKTOP_PADDING = 6.5;
const MIN_HEIGHT = 31;

export const NAVBAR_HEIGHT = MIN_HEIGHT + TABLET_DESKTOP_PADDING * 2;

const Navbar: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => {
  const label = useSite(Site.UserNav);
  return (
    <NavbarBlock {...props} aria-label={label}>
      <NavContent>{children}</NavContent>
    </NavbarBlock>
  );
};

const NavContent = styled.div`
  ${navContainerStyles}
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: ${MIN_HEIGHT}px;
`;

const barStyles = css`
  background-color: ${white};
  box-shadow: 0 3px 5px 0 ${transparentize(0.9, black)};
`;

const NavbarBlock = styled.nav<Props>`
  ${barStyles}
  ${scrollableRowStyles}
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  padding: ${props =>
    props.height ? `${MOBILE_PADDING}px 0` : `${TABLET_DESKTOP_PADDING}px 0`};
`;

type Props = {
  id?: string;
  height?: number;
};

export default Navbar;
