import { path, values } from 'ramda';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { requestAction } from '@peloton/redux-fetch';
import { isDefined } from '@peloton/types';
import type { MemberSelectorState } from '@engage/members';
import { getMember } from '@engage/members';
import type { AchievementCategory } from './Achievement';
import { fetchAchievementsForMember } from './api';

export const loadAchievementsForMember = (userId: string) =>
  requestAction(`achievements/${userId}`, fetchAchievementsForMember(userId));

export const getAchievementsForMember = (
  state: AchievementsSelectorState,
  userIdOrUsername?: string,
) => {
  const member = getMember(state, userIdOrUsername);
  if (!isDefined(member)) {
    return undefined;
  }
  const achievementsStateFromId = path<Record<any, AchievementCategory> | undefined>(
    ['fetched', `achievements/${member.id}`, 'entity'],
    state,
  );
  return achievementsStateFromId ? values(achievementsStateFromId) : undefined;
};

export type AchievementsSelectorState = MemberSelectorState & FetcherSelectorState;
