import React from 'react';

const ShoppingCart: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#222529"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H2.84699C3.75981 0 4.46101 0.808482 4.33191 1.71213L4.0739 3.51825L18.6671 3.989L19.0228 2.85087C19.1052 2.5873 19.3856 2.44041 19.6492 2.52277C19.9127 2.60514 20.0596 2.88558 19.9773 3.14915L17.4773 11.1491C17.4166 11.3433 17.2443 11.4814 17.0415 11.4983L5.23928 12.4818C5.15502 12.5885 5.09839 12.6918 5.06122 12.7806C5.03513 12.8429 5.01914 12.8969 5.00997 12.9382C5.00169 12.9754 5.00028 12.9963 5.00005 12.9998C5.00005 13.2877 5.07049 13.4649 5.15109 13.5794C5.23382 13.697 5.35375 13.7862 5.50946 13.8529C5.84021 13.9947 6.24137 14 6.5 14H16.499L16.4999 14.5L16.4998 15H6.5L6.48419 15C6.2333 15.0001 5.64826 15.0004 5.11554 14.7721C4.83375 14.6513 4.54743 14.4593 4.33328 14.1549C4.11701 13.8476 4 13.4621 4 13C4 12.7392 4.11529 12.2862 4.46352 11.8507L3.01155 4.10685C2.99892 4.04914 2.99649 3.98904 3.00503 3.92929L3.34196 1.57071C3.385 1.26949 3.15126 1 2.84699 1H0.5C0.223858 1 0 0.776142 0 0.5ZM16.4998 15C16.4999 15 16.4999 15 16.4999 14.5C16.4999 14 16.499 14 16.499 14C16.7751 14 16.9999 14.2239 16.9999 14.5C16.9999 14.7761 16.776 15 16.4998 15ZM5.40849 11.4642L16.6232 10.5297L18.3576 4.97954L4.10641 4.51982L5.40849 11.4642ZM5.5 17C4.94772 17 4.5 17.4477 4.5 18C4.5 18.5523 4.94772 19 5.5 19C6.05228 19 6.5 18.5523 6.5 18C6.5 17.4477 6.05228 17 5.5 17ZM3.5 18C3.5 16.8954 4.39543 16 5.5 16C6.60457 16 7.5 16.8954 7.5 18C7.5 19.1046 6.60457 20 5.5 20C4.39543 20 3.5 19.1046 3.5 18ZM15.25 18C15.25 17.4477 15.6977 17 16.25 17C16.8023 17 17.25 17.4477 17.25 18C17.25 18.5523 16.8023 19 16.25 19C15.6977 19 15.25 18.5523 15.25 18ZM16.25 16C15.1454 16 14.25 16.8954 14.25 18C14.25 19.1046 15.1454 20 16.25 20C17.3546 20 18.25 19.1046 18.25 18C18.25 16.8954 17.3546 16 16.25 16Z"
    />
  </svg>
);

export { ShoppingCart };
