import type { ClassAnalyticsProperties } from '@engage/classes';
import { toClassAnalytics } from '@engage/classes';
import type { SearchSharedProperties } from '@engage/library';
import { isOk, pending } from '@engage/result';
import type { FilterAnalyticsProps } from './../filters/selectors';
import type { IsInStacks } from './models';

const toIsInStackAnalytics = (result: IsInStacks, eventName: ClassDetailsEventNames) =>
  isOk(result) && eventName === ClassDetailsEventNames.ViewedClassDetails
    ? { classInStack: result.value }
    : {};

type ClassGatedType = 'none' | 'Free Class' | 'Free Class - Limited time';

export const trackViewedClassDetails = (
  classProps: ClassAnalyticsProperties | undefined,
  source: string,
  isLive: boolean,
  hasPlaylist?: boolean,
  browseProps: FilterAnalyticsProps = {},
  stacks: IsInStacks = pending,
  programProps = {},
  searchSharedProperties?: SearchSharedProperties,
  featuredAnalytics = {},
  classGatedType?: ClassGatedType,
) => {
  const event = toEventName(isLive);

  return {
    event,
    ...browseProps,
    ...toClassAnalytics(classProps),
    source,
    hasPlaylist,
    ...toIsInStackAnalytics(stacks, event),
    ...programProps,
    ...searchSharedProperties,
    ...featuredAnalytics,
    classGatedType: classGatedType ?? 'none',
  };
};

const toEventName = (isLive: boolean) =>
  isLive
    ? ClassDetailsEventNames.ViewedLiveClassDetails
    : ClassDetailsEventNames.ViewedClassDetails;

export enum ClassDetailsEventNames {
  ViewedClassDetails = 'Viewed Class Details',
  ViewedLiveClassDetails = 'Viewed Live Class Details',
}
