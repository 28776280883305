// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Classes = {
  Free: 'free',
  FreeBrowse: 'freeBrowse',
  AddToCalendar: 'addToCalendar',
  All: 'all',
  AllDisciplines: 'allDisciplines',
  AriaBookmark: 'ariaBookmark',
  AudioLanguageLabel: 'audioLanguageLabel',
  AudioOnly: 'audioOnly',
  Bookmark: 'bookmark',
  Bookmarked: 'bookmarked',
  BrowseClasses: 'browseClasses',
  Calendar: 'calendar',
  CalendarApple: 'calendarApple',
  CalendarGoogle: 'calendarGoogle',
  CalendarMicrosoft: 'calendarMicrosoft',
  CalendarYahoo: 'calendarYahoo',
  CaptionLocales: 'captionLocales',
  CardioBreakdown: 'cardioBreakdown',
  Class: 'class',
  ClassBreakdown: 'classBreakdown',
  ClassBreakdownCardio: 'classBreakdownCardio',
  ClassBreakdownFloor: 'classBreakdownFloor',
  ClassEndedText: 'classEndedText',
  ClassLanguageSettingLink: 'classLanguageSettingLink',
  ClassPlan: 'classPlan',
  ClassSubtitle: 'classSubtitle',
  ClassTakenText: 'classTakenText',
  ClassTypeId: 'classTypeId',
  ClassUnavailbleText: 'classUnavailbleText',
  ClearCountScreenReader: 'clearCountScreenReader',
  ClearFilters: 'clearFilters',
  ClosedCaptions: 'closedCaptions',
  CountMeIn: 'countMeIn',
  CustomizeYourExperience: 'customizeYourExperience',
  Difficulty: 'difficulty',
  DifficultyAdvanced: 'difficultyAdvanced',
  DifficultyBeginner: 'difficultyBeginner',
  DifficultyIntermediate: 'difficultyIntermediate',
  DifficultyLevel: 'difficultyLevel',
  DontShow: 'dontShow',
  Duration: 'duration',
  Encore: 'encore',
  EnglishSubtitleFilterName: 'englishSubtitleFilterName',
  Equipment: 'equipment',
  EquipmentList: 'equipmentList',
  ErrorLoadingClass: 'errorLoadingClass',
  ExitFeedbackNotification: 'exitFeedbackNotification',
  Explicit: 'explicit',
  ExplicitLanguage: 'explicitLanguage',
  ExplicitLanguageSubtitle: 'explicitLanguageSubtitle',
  ExplicitWarning: 'explicitWarning',
  FeaturedTitle: 'featuredTitle',
  Filter: 'filter',
  FilterClose: 'filterClose',
  Filters: 'filters',
  FilterShowAllClasses: 'filterShowAllClasses',
  FilterSort: 'filterSort',
  FreeClass: 'freeClass',
  FreeLimitedTime: 'freeLimitedTime',
  SampleClassesTitle: 'sampleClassesTitle',
  FriendsHaveTakenClass: 'friendsHaveTakenClass',
  FriendsTookClass: 'friendsTookClass',
  GiveFeedback: 'giveFeedback',
  HasClosedCaptions: 'hasClosedCaptions',
  HasNoClosedCaptions: 'hasNoClosedCaptions',
  HasNoSubtitles: 'hasNoSubtitles',
  HasSubtitles: 'hasSubtitles',
  IconAltText: 'iconAltText',
  InstructorId: 'instructorId',
  JoinAnyway: 'joinAnyway',
  JoinClassCta: 'joinClassCta',
  LanguageSort: 'languageSort',
  MissingEquipment: 'missingEquipment',
  MissingEquipmentSubtitle: 'missingEquipmentSubtitle',
  Modalabel: 'modalabel',
  MoreInfo: 'moreInfo',
  Music: 'music',
  MusicHeader: 'musicHeader',
  MusicSubtitle: 'musicSubtitle',
  NoAlbumArt: 'noAlbumArt',
  NoClassesFound: 'noClassesFound',
  NoThanks: 'noThanks',
  NumMinutes: 'numMinutes',
  Other: 'other',
  OtherSubtitle: 'otherSubtitle',
  Playlist: 'playlist',
  Positive: 'positive',
  Ratings: 'ratings',
  Save: 'save',
  Saved: 'saved',
  Scenic: 'scenic',
  ScheduleModal: 'scheduleModal',
  ScheduleTimer: 'scheduleTimer',
  SearchBarLabel: 'searchBarLabel',
  Share: 'share',
  ShowAllClasses: 'showAllClasses',
  ShowNClasses: 'showNClasses',
  ShowNPlusClasses: 'showNPlusClasses',
  StartClassCta: 'startClassCta',
  StriveScore: 'striveScore',
  SubtitleOptions: 'subtitleOptions',
  Subtitles: 'subtitles',
  SummaryOfItems: 'summaryOfItems',
  SuperGenreId: 'superGenreId',
  TechnicalIssues: 'technicalIssues',
  TechnicalIssuesSubtitle: 'technicalIssuesSubtitle',
  ThanksForYourFeedback: 'thanksForYourFeedback',
  ThisClassIsFree: 'thisClassIsFree',
  FreeForALimitedTime: 'freeForALimitedTime',
  Title: 'title',
  TooDifficult: 'tooDifficult',
  TooDifficultSubtitle: 'tooDifficultSubtitle',
  TooEasy: 'tooEasy',
  TooEasySubtitle: 'tooEasySubtitle',
  UpcomingLive: 'upcomingLive',
  VeryExplicit: 'veryExplicit',
  ViewClassCta: 'viewClassCta',
  ViewMorePlaylist: 'viewMorePlaylist',
  ViewMorePlaylistScreenReader: 'viewMorePlaylistScreenReader',
  ViewSchedule: 'viewSchedule',
  YoureIn: 'youreIn',
  PlayClass: 'playClass',
  DidYouEnjoyClass: 'didYouEnjoyClass',
  RateClass: 'rateClass',
  HowDifficultWasThisClass: 'howDifficultWasThisClass',
  Submit: 'submit',
  Rate: 'rate',
  EarlyExitInputLabel: 'earlyExitInputLabel',
  EarlyExceedLimitErrorMessage: 'earlyExceedLimitErrorMessage',
  EarlyExitSubmitButton: 'earlyExitSubmitButton',
  EarlyExitBannerErrorMessage: 'earlyExitBannerErrorMessage',
  FreeMode: 'freeMode',
} as const;
