import {
  contentPreferencesApi,
  tooltipsApi,
  userPreferencesApi,
} from '@members/data-fetch';

export const setTooltipSeen = async (
  api: typeof tooltipsApi = tooltipsApi,
  userId: string,
  tooltipId: string,
) => {
  await api.axios.post(`/tooltips/${userId}/seen/${tooltipId}`, {
    userId: userId,
    tooltipId: tooltipId,
  });
};

export const setOnboardingQuizResults = async (
  api: typeof contentPreferencesApi = contentPreferencesApi,
  activityTypes: string[],
  difficultyLevel: string,
) => {
  await api.axios.post(`/preferences`, {
    activityTypes: activityTypes,
    difficultyLevel: difficultyLevel,
  });
};

export const setLocationPromptSubmission = async (
  api: typeof userPreferencesApi = userPreferencesApi,
  userId: string,
  country: string,
  subRegion: string,
) => {
  await api.axios.put(
    `/user_preferences/privacy_law_impact/questions?user_id=${userId}&country=${country}&sub_region=${subRegion}`,
  );
};
