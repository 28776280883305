export enum CLASS_PLAY_BUTTON_SOURCE {
  PROGRAMS = 'programs',
  HOMESCREEN_UPCOMING_LIVE = 'homescreen_upcoming_live',
  HOMESCREEN_EXTRAS_SCHEDULED_CLASSES = 'homescreen_extras_scheduled_classes',
  STACKS_TRANSITION = 'stacks_transition',
  STACKS_ACTIVE_STATE = 'stacks_active_state',
  ON_DEMAND_MODAL = 'on_demand_modal',
  SCHEDULED_CLASS_MODAL = 'scheduled_class_modal',
  POST_CLASS_RECOMMENDATIONS = 'Post Class Recommendations',
  POST_CLASS_UPSELL = 'Post Class Upsell',
  NONE = 'none',
}
