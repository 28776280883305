import { freeTrialEligibility } from '@engage/api-v2/generated/services/DigitalSubscriptionsService';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

export const toDigitalSubscriptionsFetchers = (api: typeof engageApi = engageApi) => ({
  GetFreeTrialEligibility: toFetcher(
    () => `GetFreeTrialEligibility`,
    () => unwrap(() => freeTrialEligibility(api)),
  ),
});
