import React from 'react';
import { slate2, gray1 } from '@engage/colors';

type Props = React.SVGProps<SVGSVGElement> & { checkColor?: string; bkgColor?: string };

export const Check: React.FC<React.PropsWithChildren<Props>> = ({
  checkColor = slate2,
  bkgColor = gray1,
  ...props
}) => (
  <svg viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill={bkgColor} fillOpacity={1} rx={16} />
      <path d="M9 9h14v14H9z" />
      <path
        fill={checkColor}
        d="M13.34 20.993L9.256 16.91a.875.875 0 111.237-1.237l3.465 3.464 7.548-7.547a.875.875 0 111.237 1.237l-8.167 8.166a.868.868 0 01-.618.257.868.868 0 01-.618-.257z"
      />
    </g>
  </svg>
);
