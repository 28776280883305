import type { VariableType, UnwrappedVariableType } from '@members/optimizely';

export const toType = <T extends VariableType>(
  variableType: T,
  inputValue: string,
): UnwrappedVariableType<T> | null => {
  if (inputValue === 'null') {
    return null;
  }

  switch (variableType) {
    case 'boolean':
      if (inputValue === 'false') {
        return false as UnwrappedVariableType<T>;
      }
      return Boolean(inputValue) as UnwrappedVariableType<T>;
    case 'double':
    case 'integer':
      return Number(inputValue) as UnwrappedVariableType<T>;
    default:
      return inputValue as UnwrappedVariableType<T>;
  }
};
