import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { white, gray1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { capsLabel1Small, label14Caps } from '@engage/typography';
import { FacebookCopy, Facebook, useCta, Cta } from '@members/copy';
import { Chevron, Orientation } from '@members/icons';
import { facebookBlue } from './colors';
import { FacebookIconSolid } from './icons';

export type MaybeBackHandler = {
  handleBackClick?: () => void;
  children?: React.ReactNode;
};

const FacebookModalTopBar: React.FC<React.PropsWithChildren<MaybeBackHandler>> = ({
  handleBackClick,
}) => (
  <>
    {handleBackClick ? (
      <TopBarWithBack handleBackClick={handleBackClick} />
    ) : (
      <TopBarWithoutBack />
    )}
  </>
);

const TopBarWithoutBack: React.FC<React.PropsWithChildren<unknown>> = () => (
  <TopBar data-test-id="facebookModalTopBar">
    <Icon />
    <h1>
      <FacebookCopy id={Facebook.Heading} />
    </h1>
  </TopBar>
);

const TopBarWithBack: React.FC<
  React.PropsWithChildren<{ handleBackClick: () => void }>
> = ({ handleBackClick }) => {
  const backCta = useCta(Cta.Back);
  return (
    <ContainerWithBack>
      <BackButton aria-label={backCta} onClick={handleBackClick}>
        <BackIcon orientation={Orientation.Left} />
      </BackButton>
      <Icon />
      <h1>
        <FacebookCopy id={Facebook.Heading} />
      </h1>
    </ContainerWithBack>
  );
};

const BackButton = styled.button`
  position: absolute;
  left: ${spaces.medium}px;
`;

const BackIcon = styled(Chevron)`
  width: ${spaces.medium}px;
  fill: ${white};
`;

const ContainerWithBack = styled.div`
  position: relative;
  ${capsLabel1Small}
  ${label14Caps}
  background-color: ${facebookBlue};
  color: ${gray1};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${spaces.xHuge}px;
  min-height: ${spaces.xHuge}px;
`;

const TopBar = styled.div`
  ${capsLabel1Small}
  background-color: ${facebookBlue};
  color: ${white};
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px; // don't shrink in flex container
  padding: 0 10px;
  ${media.tablet`
    height: 36px;
    min-height: 36px;
  `}
`;

export const Icon = styled(FacebookIconSolid)`
  width: ${spaces.medium}px;
  height: ${spaces.medium}px;
  margin-right: ${spaces.xSmall}px;
  fill: ${white};
`;

export default FacebookModalTopBar;
