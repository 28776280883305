import { toBaseProperties } from '@members/analytics';

export const MENU_SOURCE = 'More Menu';
export const PROFILE_SOURCE = 'Top Nav Profile';
export const NAV_MENU = 'Navigation';

export const MENU_ANALYTICS_OPTIONS = {
  source: MENU_SOURCE,
};
export const ANALYTICS_SOURCE = 'Top Nav';
export const ANALYTICS_SOURCE_TAPPED_TOP_NAV = 'Tapped Homescreen Top Navigation';

type Source = 'Home' | 'Navigation';
type Item = 'Bookmarks' | 'Stacks' | 'YourSchedule' | 'Upgrade' | '30 Day Trial';

const mapNavItemEventName = {
  ['Bookmarks']: 'Viewed Bookmarks Portal',
};

export const trackTappedHomescreenTopNavigation = (item: Item, source: Source) => ({
  event: 'Tapped Homescreen Top Navigation',
  ...toBaseProperties(),
  item,
  source,
});

export const trackViewedNavItem = (item: Item) => ({
  event: mapNavItemEventName[item],
  ...toBaseProperties(),
});
