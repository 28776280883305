import React from 'react';
import styled, { css } from 'styled-components';
import type {
  BreakpointOptions,
  BreakpointOptionsMap,
} from '@peloton/images/BreakpointTransforms';
import { gray2 } from '@engage/colors';
import { Image as SimpleImage } from './Image';

const SimpleAvatar: React.FC<React.PropsWithChildren<SimpleAvatarProps>> = ({
  url: src,
  size,
  ...props
}) =>
  src ? (
    <StyledSimpleImage
      breakpointOptions={toAvatarTransforms(size)}
      src={src}
      className="fs-exclude"
      {...props}
    />
  ) : (
    <NoImage size={getSize(size)} {...props} />
  );

const placeholderStyles = css`
  background-color: ${gray2};
  border-radius: 50%;
`;

const NoImage = styled.div<{ size: MaybeNumberOrBreakpoints }>`
  ${placeholderStyles}
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

const StyledSimpleImage = styled(SimpleImage)`
  ${placeholderStyles}
  overflow: hidden;
`;

const getSize = (size: MaybeNumberOrBreakpoints) => {
  if (typeof size === 'number') {
    return size;
  } else {
    return size?.[Object.keys(size)[0]]?.width;
  }
};

const toAvatarTransforms = (size: MaybeNumberOrBreakpoints): BreakpointOptions => {
  if (typeof size === 'number') {
    return {
      mobile: {
        aspectRatio: 1,
        gravity: 'face',
        height: size,
        width: size,
      },
    };
  } else {
    return size as BreakpointOptions;
  }
};

export type SimpleAvatarProps = React.HTMLAttributes<HTMLDivElement> & {
  size: MaybeNumberOrBreakpoints;
  alt: string;
  url?: string;
  loadingColor?: string;
};

type MaybeNumberOrBreakpoints = number | Partial<BreakpointOptionsMap>;

export { SimpleAvatar };
