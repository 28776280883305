// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const ActivityFeed = {
  ActivityFeed: 'activityFeed',
  AvgHeartRate: 'avgHeartRate',
  AvgPace: 'avgPace',
  AvgSpeed: 'avgSpeed',
  Calories: 'calories',
  Close: 'close',
  Connect: 'connect',
  Distance: 'distance',
  Duration: 'duration',
  ErrorSubtitle: 'errorSubtitle',
  ErrorTitle: 'errorTitle',
  ExploreFeed: 'exploreFeed',
  Feed: 'feed',
  FindFriends: 'findFriends',
  FindFriendsSubtitle: 'findFriendsSubtitle',
  FindMembers: 'findMembers',
  FindMembersSubtitle: 'findMembersSubtitle',
  HighFives: 'highFives',
  Invite: 'invite',
  InviteFriends: 'inviteFriends',
  InviteFriendsSubtitle: 'inviteFriendsSubtitle',
  NewFeature: 'newFeature',
  Output: 'output',
  Refresh: 'refresh',
  Search: 'search',
  StriveScore: 'striveScore',
  Subtitle: 'subtitle',
  Title: 'title',
  LockTitle: 'lockTitle',
  LockSubtitle: 'lockSubtitle',
  NoNewNotifications: 'noNewNotifications',
  BackToFeed: 'backToFeed',
  PrivacyText: 'privacyText',
  ViewPrivacySettings: 'viewPrivacySettings',
  HighFivedWorkout: 'highFivedWorkout',
  Follow: 'follow',
  Following: 'following',
  NewNotifications: 'newNotifications',
  HighFived: 'highFived',
  HighFivedCount: 'highFivedCount',
  NewLockTitle: 'newLockTitle',
  NewLockSubtitle: 'newLockSubtitle',
} as const;
