import React from 'react';

const ModifyShare: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.038 2.55a.417.417 0 0 1 .59 0l1.174 1.175a.625.625 0 0 1 0 .884l-1.26 1.26-1.911-1.91 1.407-1.408Zm-1.997 1.998 1.911 1.91L9.911 12.5H8v-1.91l6.041-6.042Zm3.176-2.587a1.25 1.25 0 0 0-1.768 0l-8.16 8.161a.417.417 0 0 0-.122.295v2.5c0 .23.186.416.416.416h2.5c.11 0 .217-.043.295-.122l8.013-8.013c.57-.57.57-1.493 0-2.062L17.217 1.96Zm-13.8.956c-.69 0-1.25.56-1.25 1.25v12.916c0 .69.56 1.25 1.25 1.25h12.916c.69 0 1.25-.56 1.25-1.25v-7.5a.417.417 0 0 0-.833 0v7.5c0 .23-.187.417-.417.417H3.417A.417.417 0 0 1 3 17.083V4.167c0-.23.186-.417.417-.417h7.5a.417.417 0 0 0 0-.833h-7.5Z"
      fill="#fff"
    />
  </svg>
);

export default ModifyShare;
