import { camelize } from 'humps';
import { keys, reduce, intersection, map } from 'ramda';
import type { Packet, PacketMetrics as PacketMetricsOptional } from '../models';

export enum MetricState {
  Active = 'active',
  Paused = 'paused',
}

export class MetricRepository<K extends keyof PacketMetrics> {
  private _collectors: Collectors<K>;
  private _metricKeys: K[];
  private _metricState: Record<K, any>;
  private _sequentialOffsetMap: Record<number, number>;
  private _offsetMetrics: Record<K, PacketMetrics[K][]>;
  private _sequentialMetrics: Record<K, PacketMetrics[K][]>;

  constructor(collectors: Collectors<K>, supportedMetrics: (keyof PacketMetrics)[]) {
    this._collectors = collectors;

    if (supportedMetrics.includes('heart_rate' as keyof PacketMetrics)) {
      supportedMetrics.push('avgHeartRate');
      supportedMetrics.push('maxHeartRate');
    }

    this._metricKeys = intersection(
      keys(collectors),
      supportedMetrics.map(camelize),
    ) as K[];
    this._metricState = reduce(
      (acc, metricKey) => ({
        ...acc,
        [metricKey]: MetricState.Active,
      }),
      {} as Record<K, string>,
      this._metricKeys,
    );
    this.resetMetrics();
  }

  /**
   * Add metrics collected at an offset to the metrics repository.
   * Existing offsets are replaced with newest value
   *
   * @param offset Offset from start of the class (some derivation of seconds).
   * @param metrics K/V map of metric name to value to record for offset.
   */
  addMetrics(offset: number, metrics: Partial<Record<K, PacketMetrics[K]>>) {
    for (const key of this._metricKeys) {
      if (this._metricState[key] === MetricState.Active) {
        this._offsetMetrics[key][Math.round(offset)] = metrics[key];
        this._sequentialMetrics[key].push(metrics[key]);
        this._sequentialOffsetMap[offset] = this._sequentialMetrics[key].length - 1;
      }
    }
  }

  /**
   * Returns a packet by running the `toPacket` method of each metric collector.
   *
   * @param offset Offset from start of the class (some derivation of seconds).
   */
  getPacket(offset: number): Packet {
    const roundedOffset = Math.round(offset);
    return reduce(
      (acc, metricKey) => ({
        ...acc,
        [metricKey]: this._toMetricValue(
          roundedOffset,
          metricKey,
          this._collectors[metricKey].toPacket,
        ),
      }),
      {
        secondsOffsetFromStart: roundedOffset,
      },
      this._metricKeys,
    );
  }

  /**
   * Resets all the metrics.
   */
  resetMetrics() {
    this._sequentialOffsetMap = {};
    this._sequentialMetrics = this._toMetricArrayMap();
    this._offsetMetrics = this._toMetricArrayMap();
  }

  /**
   * Pauses metrics.
   */
  pauseMetrics<P extends keyof PacketMetrics>(metricKeys?: string[]) {
    map(
      metricKey => {
        this._metricState[metricKey] = MetricState.Paused;
      },
      metricKeys && metricKeys.length ? metricKeys : this._metricKeys,
    );
  }

  /**
   * Resumes metrics.
   */
  resumeMetrics<P extends keyof PacketMetrics>(metricKeys?: string[]) {
    map(
      metricKey => {
        this._metricState[metricKey] = MetricState.Active;
      },
      metricKeys && metricKeys.length ? metricKeys : this._metricKeys,
    );
  }

  getMetricKeys() {
    return this._metricKeys;
  }

  /**
   * Returns K/V value set by running the `toValue` method of each metric collector.
   *
   * @param offset Offset from start of the class (some derivation of seconds).
   */
  getValues(offset: number): Record<K, PacketMetrics[K]> {
    const roundedOffset = Math.round(offset);

    return reduce(
      (acc, metricKey) => ({
        ...acc,
        [metricKey]: this._toMetricValue(
          roundedOffset,
          metricKey,
          this._collectors[metricKey].toValue,
        ),
      }),
      {} as Record<K, PacketMetrics[K]>,
      this._metricKeys,
    );
  }

  /**
   * Creates a map of metricKey to array of metrics i.e.,
   *
   * `{ calories: [], cadence: [] }`
   *
   */
  private _toMetricArrayMap() {
    return reduce(
      (acc, metricKey) => ({ ...acc, [metricKey]: [] }),
      {} as Record<K, PacketMetrics[K][]>,
      this._metricKeys,
    );
  }

  /**
   * Returns value of given operatorFunc, given OperatorData<K> as an input
   *
   * @param offset Offset from start of the class (some derivation of seconds)
   * @param metricKey MetricKey to calculate value for
   * @param operatorFunc Metric-specific operator function to apply to OperatorData
   *
   */
  private _toMetricValue(
    offset: number,
    metricKey: K,
    operatorFunc: (data: OperatorData<K>) => PacketMetrics[K],
  ) {
    return operatorFunc.call(undefined, {
      offset,
      offsetMetrics: this._offsetMetrics[metricKey],
      sequentialMetrics: this._sequentialMetrics[metricKey],
      sequentialOffsetMap: this._sequentialOffsetMap,
    });
  }
}

type PacketMetrics = PacketMetricsOptional;

type Collectors<K extends keyof PacketMetrics> = Record<K, MetricOperators<K>>;

export type OperatorData<K extends keyof PacketMetrics> = {
  offset: number;
  offsetMetrics: PacketMetrics[K][];
  sequentialMetrics: PacketMetrics[K][];
  sequentialOffsetMap: Record<number, number>;
};

type MetricOperators<K extends keyof PacketMetrics> = {
  toPacket: (data: OperatorData<K>) => PacketMetrics[K];
  toValue: (data: OperatorData<K>) => PacketMetrics[K];
};
