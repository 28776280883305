import type { AxiosPromise } from 'axios';
// eslint-disable-next-line no-restricted-imports
import { toFetcher } from '@engage/data-fetch-v2';
// eslint-disable-next-line no-restricted-imports
import { unwrap } from '@engage/data-fetch-v2/lib/unwrap';
import { engageApi } from '../singletons';

type Params = { acceptLanguage: string; userId?: string };

export type ModerationResponse = [];

function getBlockedTags(
  api: typeof engageApi,
  acceptLanguage: string,
  userId?: string,
): AxiosPromise<ModerationResponse> {
  return api.axios.get(`api/self-moderation/${userId}/blocked/tags`, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

function getBlockedMembers(
  api: typeof engageApi,
  acceptLanguage: string,
  userId?: string,
): AxiosPromise<ModerationResponse> {
  return api.axios.get(`api/self-moderation/${userId}/blocked_members`, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

export const toModerationFetchers = (api: typeof engageApi = engageApi) => ({
  GetBlockedUsers: toFetcher(
    () => 'BlockedUsers',
    (params: Params) =>
      unwrap(() => getBlockedMembers(api, params.acceptLanguage, params.userId)),
  ),
  GetBlockedTags: toFetcher(
    () => 'BlockedTags',
    (params: Params) =>
      unwrap(() => getBlockedTags(api, params.acceptLanguage, params.userId)),
  ),
});
