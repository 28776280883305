import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { fetchUserMeasurementPreferences } from '@engage/settings';
import { fetchBasicInfo } from '../api';
import type { BasicInfoRequestAction, BasicInfoPresenter } from '../redux';

const loadBasicInfo = function* (
  client: Client,
  { presenter, url }: BasicInfoRequestAction,
): SagaIterator {
  handleMessageQuery(presenter, url);

  try {
    const info = yield call(fetchBasicInfo, client);
    const userMeasurementPreferences = yield call(
      fetchUserMeasurementPreferences,
      client,
      info.id,
    );
    yield call(presenter.displayBasicInfo, { ...info, ...userMeasurementPreferences });
  } catch {
    yield call(presenter.redirectToLoginPage);
  }
};

const handleMessageQuery = (presenter: BasicInfoPresenter, url: string) => {
  if (/message=complete-profile/.test(url)) {
    presenter.displayCompleteProfileMessage();
  }
};

export default loadBasicInfo;
