// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Auth = {
  AccountExists: 'accountExists',
  AccountLockedError: 'accountLockedError',
  AccountNotActiveError: 'accountNotActiveError',
  AccountReactivatingTitle: 'accountReactivatingTitle',
  ChangePassword: 'changePassword',
  ClickToRestartActivation: 'clickToRestartActivation',
  CloseAndContinueActivation: 'closeAndContinueActivation',
  ConfirmPassword: 'confirmPassword',
  ConfirmPasswordMatch: 'confirmPasswordMatch',
  ConfirmPasswordRequired: 'confirmPasswordRequired',
  ContinueWithApple: 'continueWithApple',
  ContinueWithGoogle: 'continueWithGoogle',
  CreateAccount: 'createAccount',
  CurrentPassword: 'currentPassword',
  DefaultError: 'defaultError',
  Email: 'email',
  EmailNotValid: 'emailNotValid',
  EmailRequired: 'emailRequired',
  ErrorMessage: 'errorMessage',
  ForgotPasswordCta: 'forgotPasswordCta',
  GuestNavCta: 'guestNavCta',
  HidePassword: 'hidePassword',
  InvalidPassword: 'invalidPassword',
  LockedAccount: 'lockedAccount',
  LoggedInWithOtherMethod: 'loggedInWithOtherMethod',
  LoggedInWithOtherMethodApple: 'loggedInWithOtherMethodApple',
  LoggedInWithOtherMethodGoogle: 'loggedInWithOtherMethodGoogle',
  LogIn: 'logIn',
  LoginCta: 'loginCta',
  LoginDisclaimer: 'loginDisclaimer',
  LoginError: 'loginError',
  LoginWithApple: 'loginWithApple',
  LoginWithGoogle: 'loginWithGoogle',
  Logout: 'logout',
  NewPassword: 'newPassword',
  NewPasswordMessage: 'newPasswordMessage',
  NewPasswordRequired: 'newPasswordRequired',
  Or: 'or',
  Password: 'password',
  PasswordConfirmationDoesNotMatch: 'passwordConfirmationDoesNotMatch',
  PasswordEasy: 'passwordEasy',
  PasswordModerate: 'passwordModerate',
  PasswordMinLength: 'passwordMinLength',
  PasswordNotOnOtherSites: 'passwordNotOnOtherSites',
  PasswordMustBeUnique: 'passwordMustBeUnique',
  PasswordRequired: 'passwordRequired',
  PasswordResetSuccessTitle: 'passwordResetSuccessTitle',
  PasswordStrengthModerate: 'passwordStrengthModerate',
  PasswordStrengthStrong: 'passwordStrengthStrong',
  PasswordStrengthWeak: 'passwordStrengthWeak',
  PasswordStrengthTitle: 'passwordStrengthTitle',
  PasswordTooShort: 'passwordTooShort',
  ReactivateAccountFailureMessage: 'reactivateAccountFailureMessage',
  ReactivateAccountSuccessMessage: 'reactivateAccountSuccessMessage',
  ReactivateAccountTitle: 'reactivateAccountTitle',
  RegisterCta: 'registerCta',
  RegisterTeaser: 'registerTeaser',
  Required: 'required',
  ResetError: 'resetError',
  ResetPassword: 'resetPassword',
  ResetPasswordDetails: 'resetPasswordDetails',
  ResetSent: 'resetSent',
  ResetSentDetails: 'resetSentDetails',
  Send: 'send',
  StudioLogIn: 'studioLogIn',
  StudioUsernameOrEmail: 'studioUsernameOrEmail',
  SystemTimeError: 'systemTimeError',
  TokenExpired: 'tokenExpired',
  UpdatePassword: 'updatePassword',
  UserDoesNotExistError: 'userDoesNotExistError',
  UserExistsError: 'userExistsError',
  UsernameOrEmail: 'usernameOrEmail',
  UsernameOrEmailRequired: 'usernameOrEmailRequired',
  LinkYourAccount: 'linkYourAccount',
  LinkAccountInstructions: 'linkAccountInstructions',
  LinkAccountsConfirmCta: 'linkAccountsConfirmCta',
  LinkAccountsCancelCta: 'linkAccountsCancelCta',
  AccountLinkSuccessTitle: 'accountLinkSuccessTitle',
  AccountLinkSuccessBody: 'accountLinkSuccessBody',
  AccountLinkSuccessCta: 'accountLinkSuccessCta',
  AccountLinkDefaultError: 'accountLinkDefaultError',
  AccountLinkPasswordError: 'accountLinkPasswordError',
  EmailVerificationError: 'emailVerificationError',
  ResendEmail: 'resendEmail',
  SomethingWentWrong: 'somethingWentWrong',
  PleaseTryAgain: 'pleaseTryAgain',
  SentAnotherConfirmation: 'sentAnotherConfirmation',
} as const;
