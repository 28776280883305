import { omit, groupBy, takeLast, isNil } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import {
  takeEvery,
  select,
  spawn,
  call,
  getContext,
  put,
  delay,
  all,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { reportNewRelicEvent } from '@peloton/newrelic';
import { getLocation } from '@peloton/redux';
import { track } from '@engage/analytics';
import type {
  MetricReportV2,
  MetricV2,
  MetricCollectorResult,
  BatchesToSend,
} from '@engage/video';
import {
  getAllVideoAnalytics,
  bufferingMetricsV2Saga as sharedBufferingMetricsV2Saga,
  MAXIMUM_BATCHES_LENGTH,
  flushBatchesToSend,
  getBatchesToSend,
  clearBatchesToSend,
} from '@engage/video';
import { trackPlayerAction } from '../analytics';
import { getTabBackgrounded } from '../redux';
import type { bufferingMetrics } from './sagaActions';
import { VideoSagaActionTypes, bufferingMetricsV2 } from './sagaActions';

// Sagas
export const toTrackingSaga = function* (event: string, opts: object = {}): SagaIterator {
  const location = yield select(getLocation);
  // TODO: Add class kind to video reducer and set in initial saga in case url changes
  const isLiveUrl = location.pathname.includes('live');
  const { classInfo, workoutInfo, videoInfo, workoutClassInfo } = yield select(
    getAllVideoAnalytics,
    isLiveUrl,
  );
  const tabBackgrounded = yield select(getTabBackgrounded);
  yield put(
    track(
      trackPlayerAction(event, classInfo, workoutInfo, videoInfo, workoutClassInfo, {
        tabBackgrounded,
        ...opts,
      }),
    ),
  );
};

export const dedupTimestamp = (metrics: MetricV2[]) => {
  return metrics.reduce<MetricV2[]>((acc, curr) => {
    const elemDoesNotExist = isNil(
      acc.find(({ timestamp }) => timestamp === curr.timestamp),
    );

    return elemDoesNotExist ? acc.concat(curr) : acc;
  }, []);
};

export const generateMetricsReports = (
  batchesFromStore: BatchesToSend[],
): MetricReportV2[] => {
  const groupedBatches = groupBy(b => b.workoutId, batchesFromStore);

  return Object.entries(groupedBatches).map(([workoutId, batches]) => {
    const maxAmtBatches = takeLast(MAXIMUM_BATCHES_LENGTH, batches).map(
      omit(['workoutId']),
    );
    const filteredBatches = maxAmtBatches.map(b => {
      return {
        ...b,
        metrics: dedupTimestamp(
          b.metrics.filter(m => Number.isInteger(m.secondsSinceClassStart)),
        ),
      };
    });

    return {
      workoutId,
      batches: filteredBatches,
    };
  });
};

const reportSagaHelper = function* (
  client: Client,
  report: MetricReportV2,
  startBackoff: boolean,
) {
  try {
    const { workoutId } = report;
    const batchesToClear = report.batches.map(({ batchNumber }) => ({
      workoutId,
      batchNumber,
    }));

    yield call(sharedBufferingMetricsV2Saga, client, report);

    // on success, clear the batches we've sent
    yield put(flushBatchesToSend(batchesToClear));
  } catch (e) {
    // if it's time to start exponential backoff
    // rethrow error to get picked up in next catch block
    if (startBackoff) {
      throw e;
    }
  }
};

// 15 minutes to ms
// API no longer collects metrics after class is completed for 15 minutes
export const MAXIMUM_DELAY_TIME = 15 * 60 * 1000;

const bufferingMetricsSagaV2 = function* ({
  payload,
}: ReturnType<typeof bufferingMetricsV2>) {
  const batchesFromStore: BatchesToSend[] = yield select(getBatchesToSend);
  const reports = generateMetricsReports(batchesFromStore);
  const client: Client = yield getContext(CLIENT_CONTEXT);

  // only try to send if we have batches to send
  if (batchesFromStore.length > 0) {
    try {
      yield all(
        reports.map(report => reportSagaHelper(client, report, payload.startBackoff)),
      );
    } catch (e) {
      const { delayTime, startBackoff } = payload;

      if (startBackoff) {
        if (delayTime < MAXIMUM_DELAY_TIME) {
          yield delay(delayTime);
          // recursively call bufferingMetricsV2 with increased delayTime
          // after 15 minutes API will automatically return 200
          // preventing infinite calls
          yield put(bufferingMetricsV2(startBackoff, delayTime * 2));
        } else {
          try {
            // if delay time is too large
            // try one last time to send packets and then clear batches
            yield all(
              reports.map(report =>
                reportSagaHelper(client, report, payload.startBackoff),
              ),
            );
          } catch (e) {
            yield put(clearBatchesToSend());
          }
        }
      }
    }
  }
};

const newRelicActionSaga = function* ({ payload }: ReturnType<typeof bufferingMetrics>) {
  if (payload.metrics.isReportable()) {
    yield spawn(reportNewRelicEvent, 'video-playback', {
      playTimeInSeconds: (payload.metrics.results as MetricCollectorResult).playing,
    });
  }
};

// Watcher Saga
export const videoPageAnalyticsSaga = function* () {
  yield takeEvery(VideoSagaActionTypes.BufferMetricsV2, bufferingMetricsSagaV2);
  yield takeEvery(VideoSagaActionTypes.BufferMetrics, newRelicActionSaga);
};
