import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const Add: React.FC<React.PropsWithChildren<Props>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <defs>
      <path
        id="addIconA"
        d="M8 .571c.535 0 .969.565.969 1.242v4.83h5.485c.5 0 .912.371.968.856l.007.113a.967.967 0 01-.975.97L8.968 8.58v5.607c0 .686-.43 1.242-.968 1.242-.535 0-.969-.565-.969-1.242V8.58l-5.485.001a.97.97 0 110-1.938H7.03l.001-4.83c0-.686.43-1.242.969-1.242z"
      />
      <path id="addIconC" d="M0 0h16v16H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="addIconB" fill="#fff">
        <use xlinkHref="#addIconA" />
      </mask>
      <use fill="#697180" xlinkHref="#addIconA" />
      <g mask="url(#addIconB)">
        <use fill="#F5F7F9" transform="matrix(-1 0 0 1 16 0)" xlinkHref="#addIconC" />
      </g>
      <g mask="url(#addIconB)">
        <path d="M0 0h15.429v15.429H0z" />
      </g>
    </g>
  </svg>
);
