/* eslint-disable */
import * as Types from '../../../types.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CheckDoesClassExistInUserStackQueryVariables = Types.Exact<{
  input: Types.Scalars['ID']['input'];
}>;


export type CheckDoesClassExistInUserStackQuery = { __typename: 'Query', checkDoesClassExistInUserStack: boolean };


export const CheckDoesClassExistInUserStackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CheckDoesClassExistInUserStack"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkDoesClassExistInUserStack"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"pelotonClassId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<CheckDoesClassExistInUserStackQuery, CheckDoesClassExistInUserStackQueryVariables>;