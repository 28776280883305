import React from 'react';
import { Device } from '@peloton/browser';
import { useModalLabel, ModalLabel } from '@members/copy';
import { Modal } from '@members/modal';
import { useIsBreakpointLessThan, useIsBreakpointGreaterThan } from '@members/responsive';
import { default as GetAppFromDevice } from './GetAppFromDevice';
import { default as GetAppsFromGenericDevice } from './GetAppsFromGenericDevice';

export type Props = {
  device: Device;
  isOpen: boolean;
  closeHandler: () => void;
};

export const GetAppModal: React.FC<React.PropsWithChildren<Props>> = ({
  device,
  isOpen,
  closeHandler,
}) => {
  const isMobile = useIsBreakpointLessThan('tablet');
  const isDesktop = useIsBreakpointGreaterThan('desktop');
  const isGenericDevice = device === Device.Other;
  const isWide = isDesktop && isGenericDevice;

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={closeHandler}
      style={toOverriddenStyles(isMobile, isWide)}
      contentLabel={useModalLabel(ModalLabel.PelotonApps)}
    >
      {isGenericDevice ? (
        <GetAppsFromGenericDevice isOpen={isOpen} />
      ) : (
        <GetAppFromDevice device={device} isOpen={isOpen} />
      )}
    </Modal>
  );
};

const tabletStyleOverrides = {
  content: {
    maxWidth: '520px',
  },
};

const wideStyleOverrides = {
  content: {
    maxWidth: '960px',
  },
};

const toOverriddenStyles = (isMobile: boolean, isWide: boolean) => {
  if (isMobile) {
    return {};
  } else if (isWide) {
    return wideStyleOverrides;
  }

  return tabletStyleOverrides;
};
