import React from 'react';

export const Star: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg {...props} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <rect width="32" height="32" fill="#FFF" rx="16" />
      <path
        fill="#697180"
        d="M15.862 8.343a.973.973 0 0 0-.787.663l-1.442 4.471-4.66.001c-.422 0-.795.27-.925.67l-.034.134c-.063.36.082.73.383.952l3.778 2.765-1.444 4.477c-.13.402.015.84.356 1.086l.134.082a.971.971 0 0 0 1.01-.085L16 20.799l3.77 2.76a.971.971 0 0 0 1.142.005l.107-.09a.972.972 0 0 0 .25-.997L19.825 18l3.778-2.765c.34-.25.48-.688.35-1.088l-.053-.127a.972.972 0 0 0-.871-.542h-4.661l-1.441-4.47A.972.972 0 0 0 16 8.332l-.138.01z"
      />
    </g>
  </svg>
);
