import type { ClassAvailabilityProps, CLASS_ACCESS_TYPE } from '../models';
import { useClassAccessType } from './useClassAccessType';

export const useIsFreemiumClass = (
  classId: string,
  classAvailabilityProps?: ClassAvailabilityProps,
) => {
  const classAccessType = useClassAccessType(classId, classAvailabilityProps);
  const freemiumClasses = ['free', 'sample'] as CLASS_ACCESS_TYPE[];

  return freemiumClasses.includes(classAccessType);
};
