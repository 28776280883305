import React from 'react';
import { track as trackAction } from '@engage/analytics';
import { useReduxState, useReduxAction } from '@engage/redux';
import type { Subscription } from '@engage/subscriptions';
import { getDaysUntilNextBilling } from '@engage/subscriptions';
import { useSubscriptions, Subscriptions } from '@members/copy';
import { useUpgradeFromFreePath } from '@members/tiering/hooks/useUpgradeFromFreePath';
import BannerView, { animationDurationInSeconds } from '../Banner';
import {
  dismissLapsedBanner as dismissLapsedBannerAction,
  didDismissLapsedBanner,
} from '../redux';
import { toTrackViewed, toTrackClickUpgrade, toTrackDismissedBanner } from './analytics';

export const LapsedTrial: React.FC<
  React.PropsWithChildren<{ lapsedDigitalTrial: Subscription }>
> = ({ lapsedDigitalTrial }) => {
  const accountsMembershipPath = useUpgradeFromFreePath();
  const shopShowingAfterOneWeek =
    Math.abs(getDaysUntilNextBilling(lapsedDigitalTrial!)) > 7;
  const dismissed = useReduxState(didDismissLapsedBanner);
  const dismissLapsedBanner = useReduxAction(dismissLapsedBannerAction);
  const track = useReduxAction(trackAction);

  const message = useSubscriptions(Subscriptions.ExpiredCta);
  const ctaText = useSubscriptions(Subscriptions.SubscribeNowCta);

  const ctaAction = () => {
    track(toTrackClickUpgrade());
    window.location.href = accountsMembershipPath;
  };

  const closeHandler = () => {
    track(toTrackDismissedBanner());
    setTimeout(() => dismissLapsedBanner, animationDurationInSeconds * 1.5 * 1000);
  };

  const tracker = () => {
    track(toTrackViewed());
  };

  return shopShowingAfterOneWeek || dismissed ? null : (
    <BannerView
      message={message}
      ctaText={ctaText}
      tracker={tracker}
      ctaAction={ctaAction}
      closeHandler={closeHandler}
    />
  );
};
