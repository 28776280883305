import { flatten } from 'flat';
import { toDotPathValue } from '../models';

/*
  Based on some input from the Play team, these are being structured by domain
  rather than page, to help with deduplication and broader higher-level keys.

  The goal is to also keep the strings in a single file as long as necessary
  to increase discoverability and understanding of belonging.
*/

const localizedStrings = {
  preferences: {
    basicInfo: {
      title: 'Basic Info',
      fields: {
        height: {
          label: 'Height',
          units: {
            imperial: 'ft/in',
            metric: 'cm',
          },
        },
        weight: {
          label: 'Weight',
          units: {
            imperial: 'lb',
            metric: 'kg',
          },
        },
      },
    },
  },
};

export default flatten(localizedStrings);
export const localizedStringsKeypaths = toDotPathValue(localizedStrings);
