// eslint-disable-next-line no-restricted-imports
import type {
  AddHighFiveMutationVariables,
  AddHighFiveMutation,
} from '@members/graphql-swr/schemas/activity-feed/mutations/AddHighFive.generated';
import { AddHighFiveDocument } from '@members/graphql-swr/schemas/activity-feed/mutations/AddHighFive.generated';
import { RemoveHighFiveDocument } from '@members/graphql-swr/schemas/activity-feed/mutations/RemoveHighFive.generated';
import type {
  RemoveHighFiveMutationVariables,
  RemoveHighFiveMutation,
} from '@members/graphql-swr/schemas/activity-feed/mutations/RemoveHighFive.generated';
import type {
  GetEventDetailsQuery,
  GetEventDetailsQueryVariables,
} from '@members/graphql-swr/schemas/activity-feed/queries/GetEventDetails.generated';
import { GetEventDetailsDocument } from '@members/graphql-swr/schemas/activity-feed/queries/GetEventDetails.generated';
import type {
  GetNotificationDetailsQuery,
  GetNotificationDetailsQueryVariables,
} from '@members/graphql-swr/schemas/activity-feed/queries/GetNotificationDetails.generated';
import { GetNotificationDetailsDocument } from '@members/graphql-swr/schemas/activity-feed/queries/GetNotificationDetails.generated';
import type {
  GetNumberOfUnreadNotificationsQuery,
  GetNumberOfUnreadNotificationsQueryVariables,
} from '@members/graphql-swr/schemas/activity-feed/queries/GetNumberOfUnreadNotifications.generated';
import { GetNumberOfUnreadNotificationsDocument } from '@members/graphql-swr/schemas/activity-feed/queries/GetNumberOfUnreadNotifications.generated';
import type {
  GetHighFiveDetailsForEventQuery,
  GetHighFiveDetailsForEventQueryVariables,
} from '@members/graphql-swr/schemas/activity-feed/queries/HighFiveDetailsForEvent.generated';
import { GetHighFiveDetailsForEventDocument } from '@members/graphql-swr/schemas/activity-feed/queries/HighFiveDetailsForEvent.generated';
import type {
  UserActivityFeedQuery,
  UserActivityFeedQueryVariables,
} from '@members/graphql-swr/schemas/activity-feed/queries/UserActivityFeed.generated';
import { UserActivityFeedDocument } from '@members/graphql-swr/schemas/activity-feed/queries/UserActivityFeed.generated';
import { graphQlApi } from '../singletons';
import { unwrap, getDocumentKey, graphQLClient, toMutate, toFetcherV2 } from './utils';

export const toActivityFeedFetchersGQL = (api: typeof graphQlApi = graphQlApi) => ({
  UserActivityFeedQuery: toFetcherV2(
    (queryVariables: UserActivityFeedQueryVariables) =>
      getDocumentKey(UserActivityFeedDocument, queryVariables),
    (queryVariables: UserActivityFeedQueryVariables) =>
      unwrap(() =>
        graphQLClient<UserActivityFeedQuery, UserActivityFeedQueryVariables>(
          graphQlApi,
          UserActivityFeedDocument,
          queryVariables,
        ),
      ),
  ),
  UserActivityFeedPagination: toMutate(
    (queryVariables: UserActivityFeedQueryVariables) =>
      getDocumentKey(UserActivityFeedDocument, queryVariables),
    (queryVariables: UserActivityFeedQueryVariables) =>
      unwrap(() =>
        graphQLClient<UserActivityFeedQuery, UserActivityFeedQueryVariables>(
          graphQlApi,
          UserActivityFeedDocument,
          queryVariables,
        ),
      ),
  ),
  UserHighFivesQuery: toFetcherV2(
    (queryVariables: GetHighFiveDetailsForEventQueryVariables) =>
      getDocumentKey(GetHighFiveDetailsForEventDocument, queryVariables),
    (queryVariables: GetHighFiveDetailsForEventQueryVariables) =>
      unwrap(() =>
        graphQLClient<
          GetHighFiveDetailsForEventQuery,
          GetHighFiveDetailsForEventQueryVariables
        >(graphQlApi, GetHighFiveDetailsForEventDocument, queryVariables),
      ),
  ),
  GetEventDetailsQuery: toFetcherV2(
    (queryVariables: GetEventDetailsQueryVariables) =>
      getDocumentKey(GetEventDetailsDocument, queryVariables),
    (queryVariables: GetEventDetailsQueryVariables) =>
      unwrap(() =>
        graphQLClient<GetEventDetailsQuery, GetEventDetailsQueryVariables>(
          graphQlApi,
          GetEventDetailsDocument,
          queryVariables,
        ),
      ),
  ),
  AddHighFiveMutation: toMutate(
    (queryVariables: AddHighFiveMutationVariables) =>
      getDocumentKey(AddHighFiveDocument, queryVariables),
    (queryVariables: AddHighFiveMutationVariables) =>
      unwrap(() =>
        graphQLClient<AddHighFiveMutation, AddHighFiveMutationVariables>(
          graphQlApi,
          AddHighFiveDocument,
          queryVariables,
        ),
      ),
  ),
  RemoveHighFiveMutation: toMutate(
    (queryVariables: RemoveHighFiveMutationVariables) =>
      getDocumentKey(RemoveHighFiveDocument, queryVariables),
    (queryVariables: RemoveHighFiveMutationVariables) =>
      unwrap(() =>
        graphQLClient<RemoveHighFiveMutation, RemoveHighFiveMutationVariables>(
          graphQlApi,
          RemoveHighFiveDocument,
          queryVariables,
        ),
      ),
  ),
  GetNotificationDetailsQuery: toFetcherV2(
    (queryVariables: GetNotificationDetailsQueryVariables) =>
      getDocumentKey(GetNotificationDetailsDocument, queryVariables),
    (queryVariables: GetNotificationDetailsQueryVariables) =>
      unwrap(() =>
        graphQLClient<GetNotificationDetailsQuery, GetNotificationDetailsQueryVariables>(
          graphQlApi,
          GetNotificationDetailsDocument,
          queryVariables,
        ),
      ),
  ),
  GetNumberOfUnreadNotificationsQuery: toFetcherV2(
    (queryVariables: GetNumberOfUnreadNotificationsQueryVariables) =>
      getDocumentKey(GetNumberOfUnreadNotificationsDocument, queryVariables),
    (queryVariables: GetNumberOfUnreadNotificationsQueryVariables) =>
      unwrap(() =>
        graphQLClient<
          GetNumberOfUnreadNotificationsQuery,
          GetNumberOfUnreadNotificationsQueryVariables
        >(graphQlApi, GetNumberOfUnreadNotificationsDocument, queryVariables),
      ),
  ),
});
