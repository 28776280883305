import React from 'react';
import useSWR, { mutate } from 'swr';
import type { IsInStacks } from '@engage/class-detail/models';
import { error as wrapError, ok, pending } from '@engage/result';
import { StackedClassesFetchersGQL } from '@members/data-fetch';
import type { ModifyStackMutationVariables } from '@members/graphql-swr/schemas/stacked-classes/mutations/ModifyStack.generated';
import type { StackedOnDemandClass } from './Modal/ActiveState';

export const useIsInStacks = (classId: string): IsInStacks => {
  const [isInStack, setIsInStack] = React.useState<IsInStacks>(pending);

  const { data, error } = useSWR(
    ...StackedClassesFetchersGQL.CheckDoesClassExistInUserStackQuery({ input: classId }),
  );

  const loading = !data;

  React.useEffect(() => {
    if (!loading && data) {
      if (error) {
        setIsInStack(wrapError(error));
      } else {
        setIsInStack(ok(data!.checkDoesClassExistInUserStack));
      }
    }
  }, [loading]);

  return isInStack;
};

/* Helper function to close the remove confirmation menu
  if the user clicks anywhere outside of the remove button.*/
export const useOutsideAlerter = (
  ref: React.RefObject<HTMLElement>,
  buttonRef?: React.RefObject<HTMLElement>,
) => {
  const [removeClick, setRemoveClick] = React.useState(false);
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !buttonRef?.current?.contains(event.target as Node) &&
        removeClick
      ) {
        setRemoveClick(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [removeClick]);
  React.useEffect(() => {
    const handleTabOutside = (event: KeyboardEvent) => {
      if (
        ref.current &&
        ref.current.contains(event.target as Node) &&
        (!buttonRef || buttonRef?.current?.contains(event.target as Node)) &&
        event.code === 'Tab' &&
        removeClick
      ) {
        setRemoveClick(false);
      }
    };
    document.addEventListener('keydown', handleTabOutside);
    return () => {
      document.removeEventListener('keydown', handleTabOutside);
    };
  }, [removeClick]);
  return [removeClick, setRemoveClick] as const;
};

export const useUpdateStackedClasses = (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  initialClasses: StackedOnDemandClass[],
  refetch: () => Promise<void>,
) => {
  const updateStackOrder = (variables: ModifyStackMutationVariables) =>
    mutate(...StackedClassesFetchersGQL.ModifyStackMutation(variables));

  const [updatedClasses, updateClasses] = React.useState(initialClasses);
  const [removeAllError, setRemoveAllError] = React.useState(false);
  const updateStackedClasses = (
    classList: StackedOnDemandClass[],
    shouldSave: boolean = true,
    shouldRefetch: boolean = true,
    joinToken: string = '',
  ) => {
    updateClasses(classList);
    const refetchExists = () => {
      mutate(
        ...StackedClassesFetchersGQL.CheckDoesClassExistInUserStackMutateQuery({
          input: joinToken,
        }),
      );
    };
    if (shouldSave) {
      setLoading(true);
      updateStackOrder({
        input: {
          pelotonClassIdList: classList.map(stackedClass => stackedClass.joinToken),
        },
      })
        .then(() => {
          setLoading(false);
          if (shouldRefetch) refetch();
          refetchExists();
        })
        .catch(() => {
          setRemoveAllError(true);
        });
    }
  };
  return [
    updatedClasses,
    updateStackedClasses,
    removeAllError,
    setRemoveAllError,
  ] as const;
};

export const useOutsideNotificationAlerter = (ref: React.RefObject<HTMLElement>) => {
  const [showingNotification, setShowingNotification] = React.useState(true);
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.preventDefault();
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowingNotification(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return [showingNotification, setShowingNotification] as const;
};

const MODIFY_STACKS_VIEW = 'Modify-Stacks-View';
const STACKS_TRANSITION_VIEW = 'Stacks-Transition-View';

export const useIsModifyStacksView = () => {
  const defaultValue = false;
  const { data, mutate: setIsModifyStacksView } = useSWR(MODIFY_STACKS_VIEW, {
    fallbackData: defaultValue,
  });

  return { isModifyStacksView: data ?? defaultValue, setIsModifyStacksView };
};

export const useIsTransitionScreen = () => {
  const defaultValue = false;
  const { data, mutate: setIsTransitionScreen } = useSWR(STACKS_TRANSITION_VIEW, {
    fallbackData: defaultValue,
  });

  return { isTransitionScreen: data ?? defaultValue, setIsTransitionScreen };
};
