/* eslint-disable react-hooks/exhaustive-deps */
import { keys, values, any } from 'ramda';
import { useEffect, useCallback } from 'react';
import {
  theUserIsInGermany,
  theUserIsInTheUnitedStates,
  toCountry,
} from '@peloton/internationalize';
import { isDefined } from '@peloton/types';
import { useReduxAction, useReduxState } from '@engage/redux';
import { upsert, remove, StorageKeys, fromQueryParam } from '@members/env';
import type { OverridableToggle, Variables } from './models';
import { toType } from './Overlay/utils';
import { provideToggleGuard } from './provideToggleGuard';
import type {
  PlatformSpecificGetFeatureToggle,
  PlatformSpecificGetVariables,
  PlatformSpecificSetFeatureToggle,
  VariablesForFeature,
} from './redux';
import {
  getFeatureToggles,
  getVariables,
  getFeatureToggle as engageGetFeatureToggle,
  getVariablesForFeature as engageGetVariablesForFeature,
} from './redux';
import { setLocalToggleState, setLocalVariablesState } from './state';
import type { variables } from './toggles';
import { Feature, variables as optimizelyFeatureVariables } from './toggles';

export const getFeatureToggle: PlatformSpecificGetFeatureToggle<Feature> = engageGetFeatureToggle;
export const getVariablesForFeature: PlatformSpecificGetVariables<
  typeof variables
> = engageGetVariablesForFeature;

export const useSetFeatureToggle = () => {
  const set = useReduxAction(
    setLocalToggleState,
  ) as PlatformSpecificSetFeatureToggle<Feature>;

  return (toggle: Feature, state: boolean) => {
    upsert(StorageKeys.FeatureToggles, toggle as string, state);
    return set(toggle, state);
  };
};

export const useSetFeatureVariable = () => {
  const set = useReduxAction(setLocalVariablesState);

  // since this is being used dynamically in the dev overlay, making this more typesafe felt like it would add unnecessary complexity
  return (
    toggle: keyof typeof variables,
    key: string,
    value: string | number | boolean | null,
  ) => {
    upsert(StorageKeys.FeatureVariables, [toggle, key].join('.'), value);
    return set(toggle, key, value);
  };
};

export const useFeatureToggles = () =>
  useReduxState(getFeatureToggles) as Record<Feature, OverridableToggle | undefined>;

export const useFeatureToggle = (feature: Feature): boolean =>
  useReduxState(getFeatureToggle, feature);

/**
 * Returns a tuple where [0] is the enabled/disabled state of a feature and [1] is a key/value object of configured feature vaariables
 * @param feature - a feature with configured variables
 * @returns [toggleState, variables]
 */
export const useFeatureVariables = <F extends keyof typeof variables>(feature: F) =>
  [useFeatureToggle(feature), useReduxState(getVariablesForFeature, feature)] as [
    boolean,
    VariablesForFeature<typeof variables, F>,
  ];

export const ToggleGuard = provideToggleGuard(useFeatureToggle);

export const useLocalStorageFeatureToggleSync = () => {
  const setToggle = useSetFeatureToggle();
  const setVariable = useSetFeatureVariable();

  // Pull feature toggles set in the URL and store in local storage.
  useEffect(() => {
    values(Feature).forEach(feature => {
      const valueFromQueryParam = fromQueryParam(feature as string);

      if (!valueFromQueryParam) {
        return;
      }

      if (['true', 't', 'on', '1'].includes(valueFromQueryParam.toLowerCase())) {
        setToggle(feature, true);
      } else if (['false', 'f', 'off', '0'].includes(valueFromQueryParam.toLowerCase())) {
        setToggle(feature, false);
      }
    });

    keys(optimizelyFeatureVariables).forEach(toggle => {
      const feature = optimizelyFeatureVariables[toggle];

      feature.forEach(variableKey => {
        const variable = `${toggle}.${variableKey.key}`;
        const valueFromQueryParam = fromQueryParam(variable as string);

        if (!!valueFromQueryParam) {
          setVariable(
            toggle,
            variableKey.key,
            toType(variableKey.type, valueFromQueryParam),
          );
        }
      });
    });
  }, []);
};

export const useClearLocalStorageFeatureToggles = () => {
  const toggles = useReduxState(getFeatureToggles);
  const featureVariables = useReduxState(getVariables);
  const setToggle = useReduxAction(setLocalToggleState);
  const setVariable = useReduxAction(setLocalVariablesState);

  const clearStorage = useCallback(() => {
    keys(toggles).forEach(key => {
      const toggle = toggles[key];
      if (!isDefined(toggle)) {
        return;
      }
      if (toggle.value !== toggle.originalValue) {
        setToggle(key, toggle.originalValue);
      }
    });
    remove(StorageKeys.FeatureToggles);

    keys(featureVariables).forEach(toggle => {
      const feature = featureVariables[toggle];
      keys(feature).forEach(variableKey => {
        const variable = feature[variableKey];
        if (variable.value !== variable.originalValue) {
          setVariable(toggle as string, variableKey as string, variable.originalValue);
        }
      });
    });
    remove(StorageKeys.FeatureVariables);
  }, [toggles, featureVariables]);

  return clearStorage;
};

export const anyOverrides = (
  features: Record<Feature, OverridableToggle | undefined>,
  featureVariables: Variables,
) =>
  any(
    toggle => isDefined(toggle) && toggle.value !== toggle.originalValue,
    values(features),
  ) ||
  any(
    feature =>
      any(variable => variable.value !== variable.originalValue, values(feature)),
    values(featureVariables),
  );

// app pages
export const canShowAppPages = () => !theUserIsInGermany(toCountry());

export const useStravaEnabled = () => {
  const isEnabled = useFeatureToggle(Feature.Strava_account_linking);
  const stravaVariables = useFeatureVariables(Feature.Strava_account_linking);
  const TLDsSupported = stravaVariables[1].TLD_Support;
  const isCom = theUserIsInTheUnitedStates(toCountry());

  // return true if Strava is enabled and we're either on .com, or the feature variable for supporting TLDs is true.
  return isEnabled && (isCom || TLDsSupported);
};
