import React from 'react';
import styled from 'styled-components';
import { getSignedInUserId } from '@peloton/auth';
import { getTLD } from '@peloton/external-links';
import type { Language as LanguageType } from '@peloton/internationalize';
import { TLDSupportedLanguages, toLocale } from '@peloton/internationalize';
import { ieHideScroll, media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { putUserSettings } from '@engage/api-v2';
import { white, slate3 } from '@engage/colors';
import { useReduxAction, useReduxState } from '@engage/redux';
import {
  getDisplayLanguage,
  updateDisplayLanguage as updateDisplayLanguageAction,
} from '@engage/settings/redux';
import { spaces } from '@engage/styles';
import { Button5Large } from '@members/buttons';
import type { CopyIDs } from '@members/copy';
import {
  useModalLabel,
  ModalLabel,
  Language,
  LanguageCopy,
  SettingsCopy,
  CtaCopy,
  Cta,
} from '@members/copy';
import { engageApi } from '@members/data-fetch/singletons';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import {
  getPersistedOrBrowserDisplayLanguage,
  persistDualLocalePreferences,
  persist,
} from '@members/localization';
import { ConnectedModal, closeModal as closeModalAction } from '@members/modal';
import { toOptions } from '@members/pg-preferences/Settings/LanguageOptions';
import { LANGUAGE_MODAL } from '../shared';
import { trackTldLanguageModalViewed, trackTldLanguageModalExpanded } from './analytics';
import { default as Checkmark } from './images/Checkmark';
import { default as LanguageIcon } from './images/LanguageIcon';

export const LanguageModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const ariaLabelId = useModalLabel(ModalLabel.SelectLanguage) as CopyIDs<
    typeof ModalLabel
  >;

  return (
    <StyledModal
      modalNames={{
        [LANGUAGE_MODAL]: {
          ariaLabelId,
          component: <LanguageModalBody />,
        },
      }}
    />
  );
};

export const LanguageModalBody: React.FC<React.PropsWithChildren<unknown>> = () => {
  const enableFrenchCALanguage = useFeatureToggle(Feature.Enablefrcalanguage);
  const displayLanguageOptions = toOptions(enableFrenchCALanguage);
  const updateDisplayLanguage = useReduxAction(updateDisplayLanguageAction);
  const closeModal = useReduxAction(closeModalAction);
  const userId = useReduxState(getSignedInUserId);
  const trackAnalytics = useReduxAction(track);
  const persistedLanguage = getPersistedOrBrowserDisplayLanguage()(userId ?? '');
  const initialDisplayLanguage = useReduxState(getDisplayLanguage);
  const [displayLanguage, changeDisplayLanguage] = React.useState(
    initialDisplayLanguage ?? persistedLanguage,
  );
  const tld = useReduxState(getTLD);
  const [displayAllLanguages, setDisplayAllLanguages] = React.useState(false);
  const [displayNum, setDisplayANum] = React.useState(3);
  React.useEffect(() => {
    trackAnalytics(trackTldLanguageModalViewed(true, userId ?? undefined));
  }, [userId, trackAnalytics]);

  const handleDisplayPreferencesSave = () => {
    updateDisplayLanguage(displayLanguage);
    if (!!userId) {
      persistLanguage();
      persist(displayLanguage, userId);
      // at the moment, this PUT call is done only for analytics purposes, see https://pelotoncycle.atlassian.net/browse/WE-7566
      putUserSettings(engageApi, userId, 'web', undefined, {
        translationLanguage: toLocale(displayLanguage),
      });
    }
    closeModal();
  };

  const persistLanguage = () => {
    const localeHasDualLanguages = TLDSupportedLanguages[tld].length > 1;
    if (localeHasDualLanguages) {
      userId && persistDualLocalePreferences(userId);
    }
  };

  const displayAllLanguagesClicked = () => {
    setDisplayAllLanguages(true);
    setDisplayANum(Infinity);
    trackAnalytics(trackTldLanguageModalExpanded(!!userId, userId ?? undefined));
  };
  return (
    <Container data-test-id="languageModal">
      <ContentSection>
        <HeadLineSection>
          <StyledLanguageIcon />
          <CallToActionText data-test-id="selectLanguage">
            <LanguageCopy id={Language.SelectLanguage} />
          </CallToActionText>
        </HeadLineSection>
        <RadioButtonGroup
          name="displayLanguageModal"
          selectedValue={displayLanguage}
          options={displayLanguageOptions.slice(0, displayNum)}
          onLanguageSelect={changeDisplayLanguage}
          displayAllLanguages={displayAllLanguages}
        />
        {displayLanguageOptions.length > 3 && !displayAllLanguages ? (
          <LinkButton
            onClick={() => displayAllLanguagesClicked()}
            data-test-id="allLanguagesLink"
          >
            <LanguageCopy id={Language.AllLanguagesCTA} />
          </LinkButton>
        ) : null}
        <SaveButton
          onClick={handleDisplayPreferencesSave}
          data-test-id="displayPreferencesSaveButton"
        >
          <CtaCopy id={Cta.Continue} />
        </SaveButton>
      </ContentSection>
    </Container>
  );
};

const RadioButtonGroup = ({
  name,
  selectedValue,
  options,
  onLanguageSelect,
  displayAllLanguages,
}: RadioGroupProps) => (
  <RadioGroupContainer role="radiogroup" data-test-id="radiogroup">
    {options.map(option => (
      <RadioButton
        key={`languageModal-${option.value}`}
        option={option}
        name={name}
        selectedValue={selectedValue}
        onLanguageSelect={onLanguageSelect}
      />
    ))}
    {displayAllLanguages ? <StyledOverlay /> : null}
  </RadioGroupContainer>
);

type RadioGroupProps = {
  name: string;
  selectedValue: string;
  options: RadioOption[];
  onLanguageSelect: React.Dispatch<React.SetStateAction<LanguageType>>;
  displayAllLanguages: boolean;
};

type RadioOption = ReturnType<typeof toOptions>[0];

type RadioButtonProps = Pick<
  RadioGroupProps,
  'name' | 'selectedValue' | 'onLanguageSelect'
> & {
  option: RadioOption;
};

const RadioButton = ({
  name,
  selectedValue,
  option,
  onLanguageSelect,
}: RadioButtonProps) => {
  return (
    <>
      <StyledLabel
        onClick={() => onLanguageSelect(option.value)}
        data-test-id="radioButton"
      >
        <SettingsCopy id={option.labelId} />
      </StyledLabel>
      {option.value === selectedValue ? (
        <CheckmarkContainer data-test-id="checkmarkContainer">
          <StyledCheckMark />
        </CheckmarkContainer>
      ) : (
        <StyledRadioInput
          type="radio"
          name={name}
          id={`languageModal-${option.value}`}
          value={option.value}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            onLanguageSelect(e.currentTarget.value as LanguageType);
          }}
          checked={option.value === selectedValue}
        />
      )}
    </>
  );
};

const StyledModal = styled(ConnectedModal)`
  border-radius: 10px;
  background-color: ${slate3};
  ${media.tablet`
    height: 500px;
    margin-top: 25vh;
  `}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${slate3};
  overflow: auto;

  ${media.tablet`
    padding: 0;
    max-height: 700px;
  `}
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;

const ContentSection = styled.div`
  margin-top: ${spaces.xxxLarge}px;
  text-align: center;
  display: block;
`;

const HeadLineSection = styled.section`
  text-align: center;
  display: block;
`;

const CallToActionText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.002em;
  color: ${white};
  margin-top: ${spaces.xxxLarge}px;
`;

const StyledLanguageIcon = styled(LanguageIcon)``;

const CheckmarkContainer = styled.span`
  position: relative;
  top: -35px;
  right: -145px;
`;

const StyledCheckMark = styled(Checkmark)``;

const RadioGroupContainer = styled.div`
  margin-top: 30px;
  max-height: 250px;
  overflow-y: auto;
`;

export const StyledRadioInput = styled.input`
  appearance: none;
  position: relative;
  top: -35px;
  right: -145px;
  height: 20px;
  width: 20px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin-left: auto;
  margin-right: 0;
  outline: none;
  z-index: 3;
  border-radius: 50%;

  &:checked {
    display: none;
  }
`;

const StyledLabel = styled.button`
  color: white;
  display: flex;
  flex-direction: row;
  padding: ${spaces.medium}px;
  position: static;
  width: 360px;
  height: 56px;
  left: 0px;
  top: 0px;

  background: #5a6474;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px auto -5px;
`;

const SaveButton = styled(Button5Large)`
  display: block;
  margin: 20px auto;
  width: 75%;
`;

const LinkButton = styled.button`
  color: ${white};
`;

const StyledOverlay = styled.div`
  background-image: linear-gradient(to bottom, rgba(34, 37, 41, 0) 0%, ${slate3} 100%);
  width: 100%;
  height: 30px;
  position: absolute;
  top: 385px;
`;
