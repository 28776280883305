import { matchPath } from 'react-router-dom';

export const VIDEO_ON_DEMAND_ROUTE = '/classes/player/:classId';
export const LIVE_VIDEO_ROUTE = '/player/live/:pelotonId/:classId';

export const toVideoOnDemandUrl = (classId: string) => `/classes/player/${classId}`;

export const toLiveVideoUrl = (pelotonId: string, classId: string) =>
  `/player/live/${pelotonId}/${classId}`;

export const getIsVideoPagePath = (path: string): boolean => {
  const onDemandRoute = matchPath(path, { path: VIDEO_ON_DEMAND_ROUTE });
  const liveRoute = matchPath(path, { path: LIVE_VIDEO_ROUTE });

  return Boolean(onDemandRoute || liveRoute);
};
