import type { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { track } from '@engage/analytics';
import { trackUpdatedClassLanguageSettings } from '../analytics';
import { updateClassLanguagePreferences as update } from '../api';
import type { SettingsModel } from '../models';
import type { UpdateClassLanguagePreferencesAction } from '../redux';
import { setSettings } from '../redux';

export default function* (
  client: Client,
  { settings, previousSettings, presenter, source }: UpdateClassLanguagePreferencesAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  if (userId) {
    try {
      const res: { data: SettingsModel } = yield call(update, client, userId, settings);
      yield put(setSettings(res.data));

      yield put(
        track(
          trackUpdatedClassLanguageSettings(
            source,
            previousSettings.classLanguagePreferences,
            settings.classLanguagePreferences,
          ),
        ),
      );
      yield call(presenter.displaySuccessMessage);
    } catch {
      yield call(presenter.displayErrorMessage);
    }
  } else {
    yield call(presenter.redirectToLoginPage);
  }
}
