import type H from 'history';
import React, { useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { media, ieHideScroll } from '@peloton/styles';
import { isDefined } from '@peloton/types';
import { gray1 } from '@engage/colors';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { SlideoverStackTransition } from '@members/animations';
import { TagsCopy, Tags } from '@members/copy';
import { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import {
  StandardModalContainer,
  modalHeight,
  tagsModalMaxHeight,
  tagsModalMinHeight,
} from '@members/modal';
import { CustomizableToast, StyledMessageAndIcon } from '@members/notifications';
import { toAnalyticsTagView } from '../analytics';
import { MainView } from '../MainView';
import { PERSISTENT_NAV_BAR_VIEWS } from '../MainView/PersistentNavBar';
import { ReplaceTagView } from '../ReplaceTag';
import { getTagViewFromLocation } from '../selectors';
import { TagModalView } from '../shared';
import { TagDetails } from '../TagDetails';
import { ConfirmHideTagView } from '../TagDetails/ConfirmHideTagView';
import { TagsContext } from '../TagsContext';
import { toTagsModalQuery } from '../urls';
import { OtherMembersTagsView } from '../UserTags';

type Props = RouteComponentProps<{}> & {
  showErrorToast: boolean;
  setShowErrorToast: (b: boolean) => void;
};

type SourceProps = {
  tagView: TagModalView;
  category?: TagBrowseCategorySlug;
};

type QueryProps = {
  view: TagModalView;
  tagName?: string;
  browseCategory?: TagBrowseCategorySlug;
};

type ViewSetters = {
  setLastActiveBrowseCategory: (category: TagBrowseCategorySlug) => void;
  setLastActiveTagName: (tagName: string) => void;
  setViewStack: (stack: any) => void;
  setIsAutoHeight: (isAutoHeight: boolean) => void;
};

export const ConnectedTagsModalView: React.FC<
  React.PropsWithChildren<Props>
> = React.memo(({ history: locationHistory, showErrorToast, setShowErrorToast }) => {
  const {
    CONTEXT_PROPS,
    goBackStack,
    viewStack,
    setViewStack,
    goBackTo,
    view,
  } = useTagsModalProps(locationHistory, setShowErrorToast);

  useHistory(locationHistory, goBackStack, viewStack, setViewStack, goBackTo, view);

  return (
    <TagsContext.Provider value={CONTEXT_PROPS}>
      <Container isAutoHeight={CONTEXT_PROPS.isAutoHeight}>
        <StyledToast
          autoDismiss={false}
          hide={!showErrorToast}
          onClose={() => setShowErrorToast(false)}
        >
          <TagsCopy id={Tags.TagDoesNotMeetGuidelines} />{' '}
          <TagsCopy id={Tags.TagGuidelinesSupportMessage} />
        </StyledToast>
        <SlideoverStackTransition>
          {viewStack
            .map(toViewComponent)
            // eslint-disable-next-line react/display-name
            .map((View, idx) => (isEntered: boolean) => (
              <View key={idx} isEntered={isEntered} />
            ))}
        </SlideoverStackTransition>
      </Container>
    </TagsContext.Provider>
  );
});
ConnectedTagsModalView.displayName = 'ConnectedTagsModalView';

export const TagsModalView = withRouter(ConnectedTagsModalView);

export const setLocationHistory = (
  locationHistory: H.History,
  viewStack: TagModalView[],
  queryProps: QueryProps,
  sourceProps: SourceProps,
) => {
  locationHistory.push(
    toTagsModalQuery(queryProps.view, queryProps?.tagName, queryProps?.browseCategory),
    {
      viewStack,
      source: toAnalyticsTagView(sourceProps.tagView, sourceProps?.category),
    },
  );
};

export const clearLocationHistory = (locationHistory: H.History) =>
  locationHistory.replace(locationHistory.location.pathname, {
    search: undefined,
    viewStack: [],
  });

export const useHistory = (
  locationHistory: H.History,
  goBackStack: TagModalView[],
  viewStack: TagModalView[],
  setViewStack: (stack: TagModalView[]) => void,
  goBackTo?: TagModalView,
  currentView?: TagModalView,
) => {
  React.useEffect(() => {
    locationHistory.listen((_, action) => {
      if (action === 'POP') {
        if (goBackStack.length === 0) {
          clearLocationHistory(locationHistory);
        } else {
          const queryProps = { view: goBackTo! };
          const sourceProps = { tagView: currentView! };

          setViewStack([...goBackStack]);
          setLocationHistory(locationHistory, goBackStack, queryProps, sourceProps);
        }
      }
    });
  }, [viewStack]);
};

export const onSetView = (
  queryProps: QueryProps,
  lastActiveBrowseCategory: TagBrowseCategorySlug,
  locationHistory: H.History,
  viewStack: TagModalView[],
  viewSetters: ViewSetters,
  currentView?: TagModalView,
) => {
  if (isDefined(queryProps.browseCategory)) {
    viewSetters.setLastActiveBrowseCategory(queryProps.browseCategory);
  }
  if (isDefined(queryProps.tagName)) {
    viewSetters.setLastActiveTagName(queryProps.tagName);
  }
  const sourceProps = {
    tagView: currentView!,
    category: lastActiveBrowseCategory,
  };
  if (isPersistentNavBarView(currentView!) && isPersistentNavBarView(queryProps.view)) {
    viewSetters.setViewStack([...viewStack.slice(0, -1), queryProps.view]); // If navigating within main view, replace current view
  } else {
    viewSetters.setViewStack([...viewStack, queryProps.view]); // Add new view
  }
  if (isAutoHeightView(queryProps.view)) {
    viewSetters.setIsAutoHeight(true);
  }
  setLocationHistory(locationHistory, viewStack, queryProps, sourceProps);
};

export const onGoBack = (
  lastActiveBrowseCategory: TagBrowseCategorySlug,
  locationHistory: H.History,
  goBackStack: TagModalView[],
  setViewStack: (stack: any) => void,
  setIsAutoHeight: (isAutoHeight: boolean) => void,
  lastActiveTagName?: string,
  goBackTo?: TagModalView,
  currentView?: TagModalView,
) => {
  const queryProps = {
    view: goBackTo!,
    tagName: goBackTo === TagModalView.TagDetails ? lastActiveTagName : undefined,
    browseCategory:
      goBackTo === TagModalView.BrowseTags ? lastActiveBrowseCategory : undefined,
  };
  const sourceProps = {
    tagView: currentView!,
    category: lastActiveBrowseCategory,
  };

  setViewStack([...goBackStack]);
  setLocationHistory(locationHistory, goBackStack, queryProps, sourceProps);
  setIsAutoHeight(false);
};

export const toGoBackStack = (
  viewStack: TagModalView[],
  currentView: TagModalView = TagModalView.MyTags,
) => {
  const goBackStack = viewStack.slice(0, -1);
  while (
    goBackStack.slice(-1)?.[0] === TagModalView.ReplaceTag ||
    goBackStack.slice(-1)?.[0] === currentView
  ) {
    goBackStack.pop(); // Go back an extra view if the previous view was ReplaceTag or the same view
  }
  return goBackStack;
};

export const useTagsModalProps = (
  locationHistory: H.History,
  setShowErrorToast: (showError: boolean) => void,
) => {
  const previousViews = locationHistory.location.state?.viewStack ?? [];
  const view = useReduxState(getTagViewFromLocation);
  const [viewStack, setViewStack] = useState([...previousViews, view]);
  const [lastScroll, setLastScroll] = useState(0);
  const goBackStack = viewStack.length > 0 ? toGoBackStack(viewStack, view) : [];
  const goBackTo = goBackStack.slice(-1)?.[0];
  const [
    lastActiveBrowseCategory,
    setLastActiveBrowseCategory,
  ] = React.useState<TagBrowseCategorySlug>(TagBrowseCategorySlug.Trending);
  const [lastActiveTagName, setLastActiveTagName] = React.useState('');
  const [persistentNavOffset, setPersistentNavOffset] = React.useState(0);
  const [isAutoHeight, setIsAutoHeight] = React.useState(false);

  const CONTEXT_PROPS = {
    setView: (
      newView: TagModalView,
      tagName?: string,
      browseCategory?: TagBrowseCategorySlug,
    ) => {
      const queryProps = {
        view: newView,
        tagName,
        browseCategory,
      };
      const viewSetters = {
        setLastActiveBrowseCategory,
        setLastActiveTagName,
        setViewStack,
        setIsAutoHeight,
      };

      return onSetView(
        queryProps,
        lastActiveBrowseCategory,
        locationHistory,
        viewStack,
        viewSetters,
        view,
      );
    },
    goBack: () =>
      onGoBack(
        lastActiveBrowseCategory,
        locationHistory,
        goBackStack,
        setViewStack,
        setIsAutoHeight,
        lastActiveTagName,
        goBackTo,
        view,
      ),
    goBackTo,
    setShowErrorToast,
    showBackButton: goBackStack.length > 0,
    lastScroll,
    setLastScroll,
    lastActiveBrowseCategory,
    setLastActiveBrowseCategory,
    persistentNavOffset,
    setPersistentNavOffset,
    setLastActiveTagName,
    isAutoHeight,
    setIsAutoHeight,
  };

  return {
    CONTEXT_PROPS,
    viewStack,
    goBackStack,
    setViewStack,
    goBackTo,
    view,
  };
};

const toViewComponent = (
  view: TagModalView,
): React.ComponentType<React.PropsWithChildren<{ isEntered: boolean }>> => {
  switch (view) {
    case TagModalView.BrowseTags:
      return MainView;
    case TagModalView.SearchAndCreate:
      return MainView;
    case TagModalView.TagDetails:
      return TagDetails;
    case TagModalView.ReplaceTag:
      return ReplaceTagView;
    case TagModalView.OtherMembersTags:
      return OtherMembersTagsView;
    case TagModalView.ConfirmHideTag:
      return ConfirmHideTagView;
    case TagModalView.MyTags:
    default:
      return MainView;
  }
};

const isPersistentNavBarView = (view: TagModalView) =>
  PERSISTENT_NAV_BAR_VIEWS.includes(view);

const isAutoHeightView = (view?: TagModalView) =>
  view && view === TagModalView.ConfirmHideTag;

export const Container = styled(StandardModalContainer)<{ isAutoHeight?: boolean }>`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${gray1};
  height: 100vh;
  overflow-x: auto;
  ${media.tablet<{ isAutoHeight?: boolean }>`
    padding: 0;
    height: ${p => (p.isAutoHeight ? 'auto' : modalHeight)};
    max-height: ${tagsModalMaxHeight};
    min-height: ${tagsModalMinHeight};
  `}

  /* hide scrollbar*/
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;

const TOAST_WIDTH = 460;

const StyledToast = styled(CustomizableToast)`
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;

  ${media.tablet`
    width: ${TOAST_WIDTH}px;
    top: ${spaces.large}px;
    left: calc(50% - ${TOAST_WIDTH / 2}px);
  `}

  ${StyledMessageAndIcon} {
    padding: ${spaces.small}px 0;

    span {
      min-height: ${spaces.xLarge}px;
    }
  }
`;
StyledToast.displayName = 'StyledToast';
