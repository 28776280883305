import type { MemberRelationship } from './models';
import { RelationshipCategory } from './models';

enum AnalyticsRelationship {
  Self = 'Self',
  Following = 'Following',
  Follower = 'Follower',
  Friends = 'Friends',
  Other = 'Other',
}

export type AnalyticsMember = {
  username: string;
  id: string;
  relationship: MemberRelationship;
};

type SocialAnalytics = {
  userId: string;
  username: string;
  relationship: AnalyticsRelationship;
  sharedLeaderboardTag: string | null;
};

export const toSocialAnalytics = (
  { id, username, relationship }: AnalyticsMember,
  sharedLeaderboardTag: string | null = null,
): SocialAnalytics => ({
  userId: id,
  username,
  relationship: toRelationship(relationship),
  sharedLeaderboardTag,
});

const toRelationship = (relationship: MemberRelationship) => {
  const meToUser = relationship.meToUser;
  const isFollower = relationship.userToMe === RelationshipCategory.Following;

  switch (meToUser) {
    case RelationshipCategory.Self:
      return AnalyticsRelationship.Self;
    case RelationshipCategory.Following:
      return isFollower ? AnalyticsRelationship.Friends : AnalyticsRelationship.Following;
    default:
      return isFollower ? AnalyticsRelationship.Follower : AnalyticsRelationship.Other;
  }
};
