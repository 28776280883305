import React from 'react';
import { getExtLinkEnv, toHref } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { track as trackAction } from '@engage/analytics';
import { useReduxState, useReduxAction } from '@engage/redux';
import type { Subscription } from '@engage/subscriptions';
import { getDaysUntilNextBilling } from '@engage/subscriptions';
import { useSubscriptions, Subscriptions } from '@members/copy';
import BannerView, { animationDurationInSeconds } from '../Banner';
import {
  dismissExpiringSoonBanner as dismissExpiringSoonBannerAction,
  didDismissExpiringSoonBanner,
} from '../redux';
import { toTrackViewed, toTrackClickUpgrade, toTrackDismissedBanner } from './analytics';

export const ExpiringSoon: React.FC<
  React.PropsWithChildren<{ activePrimaryDigitalTrial: Subscription }>
> = ({ activePrimaryDigitalTrial }) => {
  const daysRemaining = getDaysUntilNextBilling(activePrimaryDigitalTrial);
  const dismissed = useReduxState(didDismissExpiringSoonBanner);
  const dismissExpiringSoonBanner = useReduxAction(dismissExpiringSoonBannerAction);
  const track = useReduxAction(trackAction);

  const linkEnv = useReduxState(getExtLinkEnv);
  const url = toHref(Links.digitalCheckoutMonthToMonth, linkEnv);

  const message = useSubscriptions(Subscriptions.ExpiringSoonCta, { daysRemaining });
  const ctaText = useSubscriptions(Subscriptions.UpgradeNowCta);

  const ctaAction = () => {
    track(toTrackClickUpgrade(daysRemaining));
    window.location.href = url;
  };
  const closeHandler = () => {
    track(toTrackDismissedBanner(daysRemaining));
    setTimeout(() => dismissExpiringSoonBanner, animationDurationInSeconds * 1.5 * 1000);
  };

  const tracker = () => {
    track(toTrackViewed(daysRemaining));
  };

  return daysRemaining > 3 || dismissed ? null : (
    <BannerView
      message={message}
      ctaText={ctaText}
      tracker={tracker}
      ctaAction={ctaAction}
      closeHandler={closeHandler}
    />
  );
};
