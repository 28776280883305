import React from 'react';
import styled from 'styled-components';
import { slate3 } from '@engage/colors';
import { title24 } from '@engage/typography';
import { Button3Large } from '@members/buttons';
import { SiteCopy, Site } from '@members/copy';
import { ScrollContext } from './MainView/ScrollContext';

type Props = {
  tryAgain: () => void;
};

const ErrorView: React.FC<React.PropsWithChildren<Props>> = ({ tryAgain }) => {
  const { setNavBarAnimation } = React.useContext(ScrollContext);

  return (
    <Container
      data-test-id="errorViewContainer"
      onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
        if (e.deltaY < 0) {
          setNavBarAnimation(e.deltaY);
        }
      }}
    >
      <Title>
        <SiteCopy id={Site.SomethingWentWrong} />
      </Title>
      <TryAgainButton onClick={tryAgain} data-test-id="tryAgainButton">
        <SiteCopy id={Site.TryAgain} />
      </TryAgainButton>
    </Container>
  );
};

export { ErrorView };

const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  ${title24}
  color: ${slate3};
  margin-bottom: 28px;
`;

const TryAgainButton = styled(Button3Large)`
  width: 240px;
  min-height: 50px;
`;
