// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const SimpleGoals = {
  SetAGoal: 'setAGoal',
  ActiveDaysGoal: 'activeDaysGoal',
  Time: 'time',
  Workouts: 'workouts',
  Calories: 'calories',
  TimeGoal: 'timeGoal',
  WorkoutsGoal: 'workoutsGoal',
  CaloriesGoal: 'caloriesGoal',
  SetWeeklyGoalCTA: 'setWeeklyGoalCTA',
  SetWeeklyGoalSubtitle: 'setWeeklyGoalSubtitle',
  SetGoal: 'setGoal',
  Goals: 'goals',
  WeeklyActivityGoal: 'weeklyActivityGoal',
  GoalTrackingSubtitle: 'goalTrackingSubtitle',
  ActivityGoals: 'activityGoals',
  Recommended: 'recommended',
} as const;
