import type { Me } from './models';

export const toMe = (apiMe: ApiMe): Me => ({
  facebookId: apiMe.facebookId,
  totalPendingFollowers: apiMe.totalPendingFollowers,
});

type ApiMe = {
  facebookId?: string;
  totalPendingFollowers: number;
};
