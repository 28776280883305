// TODO: blocked on @webtv/time
// import { toNowTimezoneString } from '@peloton/time';
import type { Locale } from '@peloton/internationalize';
import type {
  AnalyticsProps,
  createWorkout as engageCreateWorkout,
  CreateWorkoutRequest,
  StreamHistory,
} from '@engage/api-v2';
import {
  createWorkoutWithAnalytics,
  workoutDetailsWithMuscleGroupScore as workoutDetails,
  getUserWorkoutsWithMuscleGroupScore as getUserWorkouts,
  getWorkoutAchievements,
  getWorkoutHistoryCsv,
  getWorkoutSummary,
  getWorkoutPerformanceGraphWithHasAppleWatchMetrics as getWorkoutPerformanceGraph,
} from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type CreateWorkoutParams = {
  pelotonId: string;
  streamSubscriptionIdType: StreamHistory['streamSubscriptionIdType'];
  streamSubscriptionId: StreamHistory['streamSubscriptionId'];
  workoutType: 'class' | 'scenic' | 'freestyle';
  analyticsProps: AnalyticsProps;
};

type WorkoutDetailsParams = {
  workoutId: string;
  acceptLanguage?: Locale;
};

export type GetUserWorkoutsParams = {
  userId: string;
  to?: string;
  from?: string;
  statsTo?: string;
  statsFrom?: string;
  before?: string;
  after?: string;
  page?: number;
  limit?: number;
};
type GetWorkoutSummaryParams = {
  workoutId: string;
  options?: any;
};

type GetWorkoutAchievementsParams = {
  workoutId: string;
  acceptLanguage?: string;
  options?: any;
};

type GetWorkoutHistoryCsvParams = {
  userId: string;
  options?: any;
};

const createWorkout = (
  api: typeof engageApi = engageApi,
  pelotonPlatform: Parameters<typeof engageCreateWorkout>[3],
  { analyticsProps, ...params }: CreateWorkoutParams,
) =>
  createWorkoutWithAnalytics(
    api,
    analyticsProps,
    pelotonPlatform,
    {
      pelotonId: params.pelotonId,
      isDigital: true,
      deviceType: 'web',
      streamSubscriptionId: params.streamSubscriptionId,
      streamSubscriptionIdType: params.streamSubscriptionIdType as CreateWorkoutRequest['streamSubscriptionIdType'],
    },
    'en-us',
  );

export const toCreateWorkoutKey = (pelotonId: string) => `CreateWorkout${pelotonId}`;

export const toWorkoutFetchers = (
  api: typeof engageApi = engageApi,
  pelotonPlatform: Parameters<typeof engageCreateWorkout>[3],
) => ({
  CreateWorkout: toFetcher(
    (params: CreateWorkoutParams) => toCreateWorkoutKey(params.pelotonId),
    params => unwrap(() => createWorkout(api, pelotonPlatform, params)),
    { revalidateIfStale: true },
  ),
  WorkoutDetails: toFetcher(
    (params: WorkoutDetailsParams) => `WorkoutDetails${params.workoutId}`,
    params => unwrap(() => workoutDetails(api, params.workoutId)),
  ),
  GetWorkoutPerformanceGraph: toFetcher(
    (params: WorkoutDetailsParams) => `GetWorkoutPerformanceGraph${params.workoutId}`,
    params => unwrap(() => getWorkoutPerformanceGraph(api, params)),
  ),
  GetUserWorkouts: toFetcher(
    (params: GetUserWorkoutsParams) => `GetUserWorkouts${JSON.stringify(params)}`,
    params =>
      unwrap(() => {
        return getUserWorkouts(
          api,
          params.userId,
          undefined, // acceptLanguage
          pelotonPlatform,
          params.limit,
          params.page,
          params.before,
          params.after,
          undefined,
          params.to,
          params.from,
          params.statsTo,
          params.statsFrom,
          { query: { joins: 'ride' } },
        );
      }),
    { revalidateOnMount: true, revalidateOnFocus: false },
  ),
  GetWorkoutAchievements: toFetcher(
    (params: GetWorkoutAchievementsParams) => `GetWorkoutAchievements${params.workoutId}`,
    params =>
      unwrap(() => {
        return getWorkoutAchievements(
          api,
          params.workoutId,
          params.acceptLanguage,
          params.options,
        );
      }),
  ),
  GetWorkoutSummary: toFetcher(
    (params: GetWorkoutSummaryParams) => `GetWorkoutSummary${params.workoutId}`,
    params => unwrap(() => getWorkoutSummary(api, params.workoutId, params.options)),
  ),
  GetWorkoutHistoryCsv: toFetcher(
    (params: GetWorkoutHistoryCsvParams) => `GetWorkoutHistoryCsv${params.userId}`,
    params => unwrap(() => getWorkoutHistoryCsv(api, params.userId)),
  ),
});
