import React from 'react';
import styled from 'styled-components';
import { gray3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { buttonText } from '@members/buttons';
import { useShareTooltip } from '@members/share-modal';
import { Position } from '@members/tags/useTooltip';
import Share from '../icons/Share';

const ShareStacksButton: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLAnchorElement>>
> = props => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { toggle } = useShareTooltip(containerRef, Position.Top);

  return (
    <div ref={containerRef}>
      <ShareButton onClick={toggle} aria-label="share stack" data-test-id="shareButton">
        <Share />
        <ModifyTextShort data-test-id="shareStackButton">{'Share'}</ModifyTextShort>
      </ShareButton>
    </div>
  );
};

const ModifyTextShort = styled.p`
  margin-left: ${spaces.xxSmall}px;
`;
const ShareButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: 127px;
  height: 40px;
  border: 1px solid ${gray3};
  border-radius: 9999px;
  ${buttonText}
`;
export default ShareStacksButton;
