import type { Location, LocationState } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, useLocation, useParams } from 'react-router-dom';
import type { UserSelectorState } from '@peloton/auth';
import { getLocation } from '@peloton/redux';
import { BrowseCategorySlug } from '@engage/browse-categories';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import {
  SCHEDULE_CATEGORY_ROUTE,
  SCHEDULE_ROUTE,
  SCHEDULED_ROUTE,
  toScheduleCategoryRoute,
  YOUR_SCHEDULE_ROUTE,
  INVITED_ROUTE,
} from '@members/schedule/urls';
import { IS_SHARED_SESSION_STORAGE_KEY } from './constants';
import {
  SCHEDULE_LEGACY_ROUTE,
  toScheduleRouteFromLegacy,
} from './helpers/legacyScheduleUrls';

const LegacyRedirectView: React.FC<React.PropsWithChildren<{ location: Location }>> = ({
  location,
}) => (
  <Redirect
    key={SCHEDULE_LEGACY_ROUTE}
    path={SCHEDULE_LEGACY_ROUTE}
    to={toScheduleRouteFromLegacy(location)}
  />
);

const LegacyRedirect = connect((state: LocationState & UserSelectorState) => ({
  location: getLocation(state),
}))(LegacyRedirectView);

const ScheduleRedirectView: React.FC<React.PropsWithChildren<{ location: Location }>> = ({
  location,
}) => {
  const typedLocationState = location.state as { source: string } | null;

  return (
    <Redirect
      key={SCHEDULE_ROUTE}
      path={SCHEDULE_ROUTE}
      to={{
        pathname: toScheduleCategoryRoute(BrowseCategorySlug.All),
        state: { source: typedLocationState && typedLocationState.source },
      }}
    />
  );
};

const scheduleRedirect = connect((state: LocationState & UserSelectorState) => ({
  location: getLocation(state),
}))(ScheduleRedirectView);

const ScheduledClassRedirectView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const search = useLocation().search;
  return (
    <Redirect
      key={SCHEDULE_ROUTE}
      path={SCHEDULE_ROUTE}
      to={{
        pathname: YOUR_SCHEDULE_ROUTE,
        search,
      }}
    />
  );
};

const InvitedClassRedirectView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const params = useParams<{ inviteId: string }>();
  const urlParams = new URLSearchParams(useLocation().search);

  urlParams.append('invite_id', params.inviteId);
  sessionStorage.setItem(IS_SHARED_SESSION_STORAGE_KEY, JSON.stringify(true));

  return (
    <Redirect
      key={SCHEDULE_ROUTE}
      path={SCHEDULE_ROUTE}
      to={{
        pathname: YOUR_SCHEDULE_ROUTE,
        search: urlParams.toString(),
      }}
    />
  );
};

const YourScheduleWithInvitesLazy = lazyLoader(
  () =>
    import(
      './components/your-schedule/YourSchedulePageWithInvite' /* webpackChunkName: "YourSchedulePage" */
    ),
);

const YourSchedulePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  return <YourScheduleWithInvitesLazy />;
};

// ordering here matters, please double check before changing orders
const Routes = [
  <Route
    key={SCHEDULE_LEGACY_ROUTE}
    path={SCHEDULE_LEGACY_ROUTE}
    component={LegacyRedirect}
  />,
  <Route key={INVITED_ROUTE} path={INVITED_ROUTE} component={InvitedClassRedirectView} />,
  <Route
    key={SCHEDULED_ROUTE}
    path={SCHEDULED_ROUTE}
    component={ScheduledClassRedirectView}
  />,
  <Route key={YOUR_SCHEDULE_ROUTE} path={YOUR_SCHEDULE_ROUTE}>
    <YourSchedulePage />
  </Route>,
  <Route
    key={SCHEDULE_CATEGORY_ROUTE}
    path={SCHEDULE_CATEGORY_ROUTE}
    component={lazyLoader(
      () => import('./components/SchedulePage' /* webpackChunkName: "SchedulePage" */),
    )}
  />,
  <Route key={SCHEDULE_ROUTE} path={SCHEDULE_ROUTE} component={scheduleRedirect} />,
];

export default Routes;
