import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getUser, isSignedIn, UserReducerActionType } from '@peloton/auth';
import { fetchReferralEligibility } from './api';
import {
  loadReferralEligibility,
  loadReferralEligibilityFailure,
  loadReferralEligibilitySuccess,
} from './redux';

export const loadReferralEligibilitySaga = function* (
  client: Client,
  action: any,
): SagaIterator {
  yield put(loadReferralEligibility());
  const user = yield select(getUser);
  if (!isSignedIn(user)) {
    yield put(loadReferralEligibilityFailure(new Error('Not signed in')));
  } else {
    try {
      const canRefer = yield call(fetchReferralEligibility, client, user.id);
      yield put(loadReferralEligibilitySuccess(canRefer));
    } catch (e) {
      yield put(loadReferralEligibilityFailure(e));
    }
  }
};

export const referralsSaga = function* () {
  const client: Client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(
    UserReducerActionType.REQUEST_SUCCESS,
    loadReferralEligibilitySaga,
    client,
  );
};
