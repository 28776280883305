import type { ExtLinkEnv } from '@peloton/external-links';
import { toDigitalLink, toHref } from '@peloton/external-links';

/**
 * Given an env and path, this method constructs and returns a shareable url.
 * @param environment
 * @param path string path
 */
export const toShareUrl = (env: ExtLinkEnv, path: string) => {
  const link = toDigitalLink(path);
  return toHref(link, env);
};
