import type { AnyAction } from 'redux';
import type { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, takeLatest, getContext, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { track } from '@engage/analytics';
import type { FilterMenuAction } from '@engage/filters';
import {
  SortActionTypes,
  AppliedActionTypes,
  FilterMenuActionTypes,
  trackOpenFlyout,
  trackCloseFlyout,
  getAppliedFilters,
  getCurrentSort,
  getFilterAnalyticsProps,
  getFilterClosedAnalyticsProps,
  overrideAppliedFilters,
  overrideSort,
} from '@engage/filters';
import type { LoadLibraryPageAction } from '@engage/library';
import {
  LibraryActionTypes,
  loadFiltersSaga,
  sharedLoadLibraryPageSaga,
  sharedLoadNextPageSaga,
  getBrowseCategoryAnalyticsProps,
  loadClassesForLibrary,
  applyFilters,
  filterAnalytics,
} from '@engage/library';

import { getCurrentFiltersFromSearch, getCurrentSortFromSearch } from './redux';

const loadLibraryPageSaga = function* (
  client: Client,
  action: LoadLibraryPageAction,
): SagaIterator {
  yield call(loadFiltersSaga, client);
  const appliedFilters = yield select(getCurrentFiltersFromSearch);
  const currentSort = yield select(getCurrentSortFromSearch);
  yield put(overrideAppliedFilters(appliedFilters));
  yield put(overrideSort(currentSort));

  yield call(
    sharedLoadLibraryPageSaga,
    client,
    action.payload.categorySlug,
    appliedFilters,
    currentSort,
  );
};

const loadNextPageSaga = function* (client: Client, _: AnyAction) {
  const { appliedFilters, currentSort } = yield call(toSharedSagaValues);

  yield call(sharedLoadNextPageSaga, client, appliedFilters, currentSort);
};

export const filterMenuActionSaga = function* (
  client: Client,
  action: FilterMenuAction,
): SagaIterator {
  const browseCategoryAnalyticsProps: ReturnType<
    typeof getBrowseCategoryAnalyticsProps
  > = yield select(getBrowseCategoryAnalyticsProps);

  const tracker =
    action.type === FilterMenuActionTypes.Open ? trackOpenFlyout : trackCloseFlyout;

  const trackerProps =
    action.type === FilterMenuActionTypes.Open
      ? browseCategoryAnalyticsProps
      : {
          ...browseCategoryAnalyticsProps,
          ...(yield select(getFilterClosedAnalyticsProps)),
        };

  yield put(
    track(
      tracker({
        ...action.payload,
        ...trackerProps,
      }),
    ),
  );
};

const reloadSaga = function* (client: Client, _: AnyAction): SagaIterator {
  const { appliedFilters, currentSort } = yield call(toSharedSagaValues);

  yield call(loadClassesForLibrary, client, appliedFilters, currentSort);
};

const toSharedSagaValues = function* (): SagaIterator {
  const appliedFilters = yield select(getAppliedFilters);
  const currentSort = yield select(getCurrentSort);
  const analyticsProps = yield select(getFilterAnalyticsProps);

  return {
    appliedFilters,
    currentSort,
    analyticsProps,
  };
};

const filterActions = [
  SortActionTypes.Sort,
  AppliedActionTypes.ClearAll,
  AppliedActionTypes.Toggle,
];

const sendAnalyticsEventWithoutApplyingFilterActions = [
  AppliedActionTypes.MultiSelectToggle,
  AppliedActionTypes.SendAnalyticsBeforeClearAll,
];

// Actions

const filterMenuActions = [FilterMenuActionTypes.Open, FilterMenuActionTypes.Close];

const RELOAD = 'members/pg-library/reload';

export const reloadPage = () =>
  ({
    type: RELOAD,
  } as const);

export const libraryPageWatcherSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield takeLatest(LibraryActionTypes.RequestNewClasses, loadLibraryPageSaga, client);
  yield takeEvery(LibraryActionTypes.RequestMoreClasses, loadNextPageSaga, client);
  yield takeEvery(filterMenuActions, filterMenuActionSaga, client);
  yield takeEvery(filterActions, applyFilters, client);
  yield takeLatest(RELOAD, reloadSaga, client);
  yield takeEvery(sendAnalyticsEventWithoutApplyingFilterActions, filterAnalytics);
};
