import type { SagaIterator } from 'redux-saga';
import { call, put, select, spawn } from 'redux-saga/effects';
import { getScreenPropsSaga } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { toNowTime } from '@peloton/time';
import { getFeatureToggle } from '@engage/feature-toggles';
import type { SearchSharedProperties } from '@engage/library';
import { endWorkout, endWorkoutV2 } from '@engage/workouts';
import { destroyStream, heartbeatStream, toSendPackets, reportMetricsV2 } from '../api';
import type { MetricReportV2 } from '../models';
import type {
  LiveVideoPageAction,
  SendPacketAction,
  VODPageAction,
  WorkoutStreamState,
  videoHeartbeat,
} from '../redux';
import {
  VideoActiveViewState,
  seekOffsetByStartTime,
  updateVideoActiveView,
  videoError,
  videoStreamSuccess,
} from '../redux';
import { getScheduledClassForInProgressWorkout } from '../selectors';
import { initializeWorkout } from './initializeWorkout';
import { pollScheduledClassSagaManager } from './peloton';
import { pollTimelineSagaManager } from './timeline';

export const vodStreamSaga = function* (
  client: Client,
  action: Pick<VODPageAction, 'payload'>,
  startTime: number = toNowTimeValue(),
  cancelActionType: string,
  timelinePollDelay: number,
  pelotonPollDelay: number,
  workoutId?: string,
  programProgressId?: string,
  source?: string,
  featureModule?: string,
  rank?: number,
  total?: number,
  requireVODAvailability: boolean = false,
  searchSharedProperties?: SearchSharedProperties,
) {
  try {
    const stream: WorkoutStreamState | undefined = yield call(
      // @ts-expect-error
      initializeWorkout,
      {
        classId: action.payload.classId,
        deviceType: action.payload.deviceType,
        inProgressWorkoutId: workoutId,
        ...(source
          ? {
              analytics: {
                source,
                programProgressId,
                searchSharedProperties,
                featureModule,
                rank,
                total,
              },
            }
          : undefined),
        requireVODAvailability,
      },
      'on_demand',
    );

    if (stream) {
      yield put(videoStreamSuccess(stream, startTime));

      yield spawn(pollTimelineSagaManager, client, timelinePollDelay, cancelActionType);
      yield spawn(
        pollScheduledClassSagaManager,
        client,
        pelotonPollDelay,
        cancelActionType,
      );
    }
  } catch (e) {
    const maybeErrorCode = e?.response?.data?.errorCode;
    const maybeErrorMessage = e?.response?.data?.message;
    const uniqueErrorCodeOrFallback =
      maybeErrorCode && maybeErrorMessage
        ? `${maybeErrorCode}: ${maybeErrorMessage}`
        : e.message;
    yield put(updateVideoActiveView(VideoActiveViewState.Error));
    yield put(videoError({ message: uniqueErrorCodeOrFallback }));
  }
};

export const liveStreamSaga = function* (
  client: Client,
  action: Pick<LiveVideoPageAction, 'payload'>,
  startTime: number = toNowTimeValue(),
  cancelActionType: string,
  timelinePollDelay: number,
  pelotonPollDelay: number,
  contentType: string,
  onVideoEndSaga?: () => void,
  workoutId?: string,
  source?: string,
) {
  try {
    const stream: WorkoutStreamState | undefined = yield call(
      initializeWorkout,
      {
        classId: action.payload.classId,
        pelotonId: action.payload.pelotonId,
        deviceType: action.payload.deviceType,
        inProgressWorkoutId: workoutId,
        ...(source ? { analytics: { source } } : undefined),
      },
      contentType,
    );

    if (stream) {
      yield put(videoStreamSuccess(stream, startTime));
      yield call(seekToCurrentTimeIfEncore);

      yield spawn(
        pollTimelineSagaManager,
        client,
        timelinePollDelay,
        cancelActionType,
        onVideoEndSaga,
      );
      yield spawn(
        pollScheduledClassSagaManager,
        client,
        pelotonPollDelay,
        cancelActionType,
      );
    }
  } catch (e) {
    yield put(updateVideoActiveView(VideoActiveViewState.Error));
    yield put(videoError({ message: e.message }));
  }
};

export const seekToCurrentTimeIfEncore = function* () {
  const scheduledClass: ReturnType<
    typeof getScheduledClassForInProgressWorkout
  > = yield select(getScheduledClassForInProgressWorkout);

  if (scheduledClass && scheduledClass.isEncore) {
    yield put(seekOffsetByStartTime(scheduledClass.scheduledStartTime));
  }
};

const toNowTimeValue = () => toNowTime().valueOf();

export const heartbeatStreamSaga = function* (
  client: Client,
  action: ReturnType<typeof videoHeartbeat>,
): SagaIterator {
  try {
    return yield call(heartbeatStream, client, action.payload.streamHistoryId);
  } catch (e) {
    return;
  }
};

export const deleteStreamSaga = function* (client: Client, videoSubscriptionId: string) {
  try {
    yield call(destroyStream, client, videoSubscriptionId);
  } catch (err) {
    return;
  }
};

export const endWorkoutSaga = function* (
  client: Client,
  workoutId: string,
): SagaIterator {
  const isEndWorkoutV2Enabled = yield select(getFeatureToggle, 'end_of_important_stuff');
  const screenProps = yield call(getScreenPropsSaga);
  return yield call(
    isEndWorkoutV2Enabled ? endWorkoutV2 : endWorkout,
    client,
    workoutId,
    screenProps,
  );
};

export const sendPacketSaga = function* (client: Client, action: SendPacketAction) {
  try {
    const { id, packet } = action.payload;
    yield call(toSendPackets, client, id, packet);
  } catch (e) {
    return;
  }
};

export const bufferingMetricsV2Saga = function* (
  client: Client,
  metricReport: MetricReportV2,
) {
  yield call(reportMetricsV2, client, metricReport);
};
