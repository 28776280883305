/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import type { Time } from '@peloton/time';
import { DateTime } from '@engage/internationalize';
import { useLocaleGetter } from './useLocaleGetter';

export type Props = {
  date: Time;
  localeGetter?: DateTime.LocaleGetter;
};

export const LocalizedAbbreviatedLong: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedAbbreviatedLong(date, localeGetter)}</>;

export const LocalizedAbrevLongWithoutDay: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedAbrevLongWithoutDay(date, localeGetter)}</>;

export const LocalizedSlashedDate: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedSlashedDate(date, localeGetter)}</>;

export const LocalizedDateByCountryFormat: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => {
  return LocalizedDayNameMonthDate({ date, localeGetter });
};

export const LocalizedDayNameMonthDate: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedDayNameMonthDate(date, localeGetter)}</>;

export const LocalizedDayNameShortMonthDate: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedDayNameShortMonthDate(date, localeGetter)}</>;

export const LocalizedDayNameShort: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedDayNameShort(date, localeGetter)}</>;

export const LocalizedShortMonthDate: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedShortMonthDate(date, localeGetter)}</>;

export const LocalizedDayFirstLetter: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedDayFirstLetter(date, localeGetter)}</>;

export const LocalizedDayOfMonth: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedDayOfMonth(date, localeGetter)}</>;

export const LocalizedMonthYear: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedMonthYear(date, localeGetter)}</>;

export const LocalizedTime: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  localeGetter = useLocaleGetter(),
}) => <>{DateTime.toLocalizedTime(date, localeGetter)}</>;

export const LocalizedDayNameLongMonthSlashDay: React.FC<
  React.PropsWithChildren<Props>
> = ({ date, localeGetter = useLocaleGetter() }) => (
  <>{DateTime.toLocalizedDayNameLongMonthSlashDay(date, localeGetter)}</>
);
