import { propEq } from 'ramda';
import { useState } from 'react';
import { useClient } from '@peloton/api';
import type { UIState } from '@peloton/models';
import { toUIState, Status } from '@peloton/models';
import { fetchCards } from '../api';
import type { Card } from '../models/Card';

export const usePaymentMethods = () => {
  const [loadingState, setLoadingState] = useState<UIState>(toUIState(Status.Init));
  const [cards, setCards] = useState<Card[]>([]);
  const client = useClient();

  const loadPaymentMethods = async () => {
    try {
      setLoadingState(toUIState(Status.Loading));
      const fetchedCards = await fetchCards(client);
      setCards(fetchedCards);
      setLoadingState(toUIState(Status.Loaded));

      return {
        benefitHub: toBenefitHub(fetchedCards),
        fetchedCards,
        defaultCard: toDefaultCard(fetchedCards),
      };
    } catch (e) {
      setLoadingState(toUIState(Status.Failed, e));
      return null;
    }
  };

  return {
    actions: { loadPaymentMethods },
    loadingState,
    paymentMethods: {
      benefitHub: toBenefitHub(cards),
      cards,
      defaultCard: toDefaultCard(cards),
    },
  };
};

const toDefaultCard = (cards: Card[]): Card | undefined =>
  cards.find(propEq('isDefault', true));
const toBenefitHub = (cards: Card[]): Card | undefined =>
  cards.find(propEq('paymentType', 'benefit_hub'));
