// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Links = {
  Accessibility: 'accessibility',
  Blog: 'blog',
  Boutique: 'boutique',
  DeDELanguage: 'deDELanguage',
  DeLanguage: 'deLanguage',
  EngagementBanner: 'engagementBanner',
  EnLanguage: 'enLanguage',
  EnUSLanguage: 'enUSLanguage',
  EsLanguage: 'esLanguage',
  EsMXLanguage: 'esMXLanguage',
  GetApps: 'getApps',
  GetTheApp: 'getTheApp',
  Impressum: 'impressum',
  IpPolicy: 'ipPolicy',
  MembershipTerms: 'membershipTerms',
  MxLanguage: 'mxLanguage',
  OrderHistory: 'orderHistory',
  PelothonBanner: 'pelothonBanner',
  Privacy: 'privacy',
  PrivacyPolicy: 'privacyPolicy',
  ReferFriends: 'referFriends',
  ShopApparel: 'shopApparel',
  Studio: 'studio',
  Support: 'support',
  SupportAndFaqs: 'supportAndFaqs',
  TermsOfService: 'termsOfService',
  CookieSettings: 'cookieSettings',
  DoNotSellShare: 'doNotSellShare',
  FrLanguage: 'frLanguage',
  ConsumerHealthDataPolicy: 'consumerHealthDataPolicy',
} as const;
