/* eslint-disable react/display-name */
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';
import { ieHideScroll, media } from '@peloton/styles';
import { isDefined } from '@peloton/types';
import { If } from '@engage/conditional-render';
import { spaces } from '@engage/styles';
import type { SearchTagQuery } from '@members/graphql-swr/schemas/tags/queries/SearchTag.generated';
import type { Tag as TagType } from '@members/graphql-swr/types.generated';
import { Loading } from '../Loading';
import { ScrollContext } from '../MainView/ScrollContext';
import type { ClientTag } from '../models';
import { toClientTag } from '../models';
import { Tag } from '../Tag';
import { CreateTagView } from './CreateTagView';

export enum OtherCardType {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Loading,
}

const CREATE_TAG_HEIGHT = 154;
const TAG_CARD_HEIGHT = 110;
const LOADING_HEIGHT = 160;

type ScrollProps = {
  loadMore: () => void;
  searchResults: SearchTagQuery;
};

export const ScrollComponent: React.FC<React.PropsWithChildren<ScrollProps>> = ({
  loadMore,
  searchResults,
}: ScrollProps) => {
  const [lastLoadingIndex, setLastLoadingIndex] = React.useState<number | undefined>(0);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const isCreateMode = !searchResults?.tag?.exists;
  const hasNextPage = searchResults?.searchTag?.pageInfo?.hasNextPage;
  const tagList =
    searchResults?.searchTag?.edges.map(({ node }) => toClientTag(node)) ?? [];
  const resultsWithLoading = hasNextPage ? [...tagList, OtherCardType.Loading] : tagList;
  const list = isCreateMode
    ? [searchResults?.tag, ...resultsWithLoading]
    : resultsWithLoading;
  const listRef = React.useRef<VariableSizeList>(null);
  const { setNavBarAnimation } = React.useContext(ScrollContext);

  const getItemSize = React.useCallback(
    (index: number) => {
      if (resultsWithLoading[index] === OtherCardType.Loading) {
        setLastLoadingIndex(index);
        return LOADING_HEIGHT;
      } else if (isCreateMode && index === 0) {
        return CREATE_TAG_HEIGHT;
      } else {
        return TAG_CARD_HEIGHT + spaces.small;
      }
    },
    [resultsWithLoading],
  );

  React.useEffect(() => {
    if (isDefined(listRef.current) && isDefined(lastLoadingIndex)) {
      listRef.current.resetAfterIndex(lastLoadingIndex, false);
      setLastLoadingIndex(undefined);
    }
  }, [list.length]);

  return (
    <ListContainer
      data-test-id="scrollContainer"
      onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
        setNavBarAnimation(e.deltaY);
      }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            height={height}
            width={width}
            itemCount={list.length}
            itemSize={getItemSize}
            innerRef={scrollRef}
            ref={listRef}
            onItemsRendered={({ visibleStopIndex }) => {
              if (visibleStopIndex === list.length - 1) {
                loadMore();
              }
            }}
          >
            {toTagCard(list as TagsWithLoadingList, isCreateMode)}
          </VariableSizeList>
        )}
      </AutoSizer>
    </ListContainer>
  );
};

type TagsWithLoadingList = (Partial<TagType> | ClientTag | OtherCardType)[];

type ListItemProps = {
  index: number;
  style: React.CSSProperties;
};

export const toTagCard = (
  tagsWithLoading: TagsWithLoadingList,
  isCreateMode: boolean,
) => ({ index, style }: ListItemProps) => {
  const tag = tagsWithLoading[index];
  return (
    <div style={{ ...style, marginTop: '12px' }}>
      {tag === OtherCardType.Loading ? (
        <LoadingMore isLoading={true} size={40} />
      ) : isCreateMode && index === 0 ? (
        <CreateTagView tag={tag as Partial<TagType>} withBorder={true} />
      ) : (
        <If condition={index < tagsWithLoading.length}>
          <Tag
            viewingOwnTag={false}
            isPrimary={false}
            tagName={tag.name!}
            metaTag={(tag as ClientTag).metaTag!}
            numberOfMembers={(tag as ClientTag).numberOfMembers!}
            numberOfFollowing={(tag as ClientTag).numberOfFollowing!}
            backgroundImageUrl={(tag as ClientTag).backgroundImageUrl!}
            hasAdded={tag.hasAdded!}
            isCreatingTag={false}
          />
        </If>
      )}
    </div>
  );
};

export const LoadingMore = styled(Loading)`
  margin: ${spaces.small}px auto ${spaces.xLarge}px;
  ${media.smallScreen`
    margin: ${spaces.small}px auto ${spaces.xLarge}px;
  `}
  ${media.tablet`
    margin: ${spaces.small}px auto ${spaces.xLarge}px;
  `}
`;

const ListContainer = styled.div`
  height: 100%;
  flex: 1;
  padding: 0 ${spaces.small}px;
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;
