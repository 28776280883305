import type { FitnessDisciplineSlug } from '@engage/graphql/types.generated';

export enum BrowseCategorySlug {
  Cardio = 'cardio',
  Cycling = 'cycling',
  Outdoor = 'outdoor',
  Strength = 'strength',
  Stretching = 'stretching',
  TotalBody = 'total_body',
  Running = 'running',
  Yoga = 'yoga',
  Walking = 'walking',
  Bootcamp = 'bootcamp',
  Meditation = 'meditation',
  BikeBootcamp = 'bike_bootcamp',
  Caesar = 'caesar',
  CaesarBootcamp = 'caesar_bootcamp',
  Rowing = 'rowing',
  RowBootcamp = 'row_bootcamp',
  Free = 'free',
  All = 'all',
}

/**
 * with categories that don't have their own page (i.e. Beyond the Ride), we can
 * default to ALL
 */
export const fitnessDisciplineSlugToBrowseCategorySlug: Record<
  FitnessDisciplineSlug,
  BrowseCategorySlug
> = {
  BEYOND_THE_RIDE: BrowseCategorySlug.All,
  ['BIKE_BOOTCAMP']: BrowseCategorySlug.BikeBootcamp,
  CAESAR: BrowseCategorySlug.All,
  CAESAR_BOOTCAMP: BrowseCategorySlug.All,
  CARDIO: BrowseCategorySlug.Cardio,
  CIRCUIT: BrowseCategorySlug.Bootcamp,
  CYCLING: BrowseCategorySlug.Cycling,
  MEDITATION: BrowseCategorySlug.Meditation,
  RUNNING: BrowseCategorySlug.Running,
  STRENGTH: BrowseCategorySlug.Strength,
  STRETCHING: BrowseCategorySlug.Stretching,
  WALKING: BrowseCategorySlug.Walking,
  YOGA: BrowseCategorySlug.Yoga,
};

export type BrowseCategory = {
  name: string;
  slug: BrowseCategorySlug;
  portalImageUrl: string;
  iconUrl: string;
  hasLimitedRides: boolean;
};

export const browseCategorySlugToCaesarMapper = (
  browseCategorySlug: BrowseCategorySlug,
) => {
  if (browseCategorySlug === BrowseCategorySlug.Rowing) {
    return BrowseCategorySlug.Caesar;
  }

  if (browseCategorySlug === BrowseCategorySlug.RowBootcamp) {
    return BrowseCategorySlug.CaesarBootcamp;
  }

  return browseCategorySlug;
};

export const browseCategorySlugFromCaesarMapper = (
  browseCategorySlug: BrowseCategorySlug,
): BrowseCategorySlug => {
  if (browseCategorySlug === BrowseCategorySlug.Caesar) {
    return BrowseCategorySlug.Rowing;
  }

  if (browseCategorySlug === BrowseCategorySlug.CaesarBootcamp) {
    return BrowseCategorySlug.RowBootcamp;
  }

  return browseCategorySlug;
};

export const browseCategoryFromCaesarMapper = (
  browseCategory: BrowseCategory,
): BrowseCategory => {
  return {
    ...browseCategory,
    slug: browseCategorySlugFromCaesarMapper(browseCategory.slug),
  };
};
