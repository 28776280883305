import { datadogRum } from '@datadog/browser-rum';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { track } from '@peloton/analytics';
import { getUserId } from '@peloton/auth';
import { ellipsis, media } from '@peloton/styles';
import { slate1, white } from '@engage/colors';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { body16, label16Caps, title28 } from '@engage/typography';
import { Button1Large } from '@members/buttons';
import { Cta, CtaCopy, Members, MembersCopy } from '@members/copy';
import { engageApi } from '@members/data-fetch';
import { blockTag } from '@members/pg-overview/api';
import { trackHiddenTag } from '@members/pg-overview/ModerationModal/analytics';
import { TAGS_MODAL } from '@members/pg-overview/UserProfile/constants';
import { ErrorToast } from '../../pg-overview/ModerationModal/ErrorToast';
import { useSelectedTagName } from '../hooks';
import { TagsContext } from '../TagsContext';

export const ConfirmHideTagView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { goBack } = React.useContext(TagsContext);
  const history = useHistory();
  const userId = useReduxState(getUserId) ?? ''; //person doing the blocking
  const { selectedTagName } = useSelectedTagName();
  const tagToBlockId = selectedTagName.toLowerCase();

  const [showToast, setShowToast] = useState(false);

  const handleConfirmHideTag = async () => {
    try {
      await blockTag(engageApi, userId, tagToBlockId);
      history.push('/home', {
        entityType: 'tags',
      });
      track(trackHiddenTag(tagToBlockId, TAGS_MODAL, ''));
    } catch (e) {
      datadogRum.addError('selfModeration Hide Tag PUT error', e);
      setShowToast(true);
    }
  };

  const handleCancelHideTag = () => {
    goBack();
  };

  return (
    <>
      <ErrorToast
        showToast={showToast}
        onClose={() => setShowToast(false)}
        source={'confirmHide'}
      />
      <PaddingContainer>
        <Header data-test-id="hideTagConfirmation">
          <MembersCopy id={Members.HideTagConfirmation} />
        </Header>
        <Body data-test-id="hideTagWarning">
          <MembersCopy id={Members.HideTagWarning} />
        </Body>
        <HideButton data-test-id="hideButton" onClick={handleConfirmHideTag}>
          <MembersCopy id={Members.Hide} />
        </HideButton>
        <CancelButton data-test-id="cancelButton" onClick={handleCancelHideTag}>
          <CtaCopy id={Cta.Cancel} />
        </CancelButton>
      </PaddingContainer>
    </>
  );
};

const PaddingContainer = styled.div`
  padding: ${spaces.xxxLarge}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: -webkit-fill-available;
  height: -moz-available;

  ${media.tablet`
    height: auto;
  `}
`;

const Header = styled.h1`
  ${title28};
  ${ellipsis}
  margin-bottom: ${spaces.xSmall}px;
`;

const Body = styled.h1`
  ${body16};
  font-weight: 400;
  margin-bottom: ${spaces.medium}px;
`;

const HideButton = styled(Button1Large)`
  ${label16Caps};
  margin-top: ${spaces.medium}px;
  color: ${white};
  box-shadow: none;
  height: 48px;
  min-height: 48px;
  width: 100%;
  padding: 0 30px 0 30px;
`;

const CancelButton = styled(Button1Large)`
  ${label16Caps};
  margin-top: ${spaces.small}px;
  color: ${slate1};
  background-color: ${white};
  box-shadow: none;
  height: 48px;
  min-height: 48px;
  width: 100%;
  padding: 0 30px 0 30px;
`;
