import React from 'react';
import styled, { css } from 'styled-components';
import { media, BreakpointWidth } from '@peloton/styles';
import { getOkValue } from '@engage/result';
import { spaces } from '@engage/styles';
import { useRequiresAuth } from '@members/auth';
import type { SkipLinksProps } from '@members/layout';
import { PageTitle } from './DocumentTitle';
import { Footer } from './footer';
import { TOP_NAV_HEIGHT_PX } from './nav/containers';
import { TopNav } from './nav/TopNav';
import UserNav from './nav/UserNav';

type UserIdProps = {
  userId?: string;
};

type Props = SkipLinksProps &
  UserIdProps & {
    userId?: string;
    shouldShowFooter?: boolean;
    titleSegments: string[];
  };

const LayoutWithUserNav: React.FC<
  React.PropsWithChildren<SkipLinksProps & UserIdProps>
> = ({ userId, children, skipLinks }) => {
  // TODO: once isProtectionEnabled is baseline, we can remove useRequiresAuth from other places
  // since most pages use the navbar and auth will get covered here
  const pendingResult = useRequiresAuth();

  if (!getOkValue(pendingResult)) {
    return <></>;
  }

  return (
    <>
      <TopNav skipLinks={skipLinks} />
      <StickyHeader>
        <UserNav userId={userId} />
      </StickyHeader>
      <PageContainer>{children}</PageContainer>
      <Footer />
    </>
  );
};

const LayoutWithoutUserNav: React.FC<React.PropsWithChildren<Props>> = ({
  shouldShowFooter = true,
  skipLinks,
  ...props
}) => {
  // TODO: once isProtectionEnabled is baseline, we can remove useRequiresAuth from other places
  // since most pages use the navbar and auth will get covered here
  const pendingResult = useRequiresAuth();

  if (!getOkValue(pendingResult)) {
    return <></>;
  }

  return (
    <>
      <PageTitle titleSegments={props.titleSegments} />
      <header>
        <TopNav skipLinks={skipLinks} />
      </header>
      {props.children}
      {shouldShowFooter ? <Footer /> : null}
    </>
  );
};

const LayoutWithoutHeaderFooter: React.FC<React.PropsWithChildren<unknown>> = ({
  ...props
}) => <>{props.children}</>;

const PageContainer = styled.main`
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  padding-top: 44px;
`;

const PageInnerContainer = styled.div`
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
  ${media.tablet`
    padding: 0 ${spaces.large}px;
  `}
  ${media.desktop`
    padding: 0 30px;
    max-width: 1020px;
  `}
`;

const CardsContainer = styled(PageInnerContainer)`
  margin: 0;
  padding: 0 10px;
`;

const CenteredCardsContainer = styled(PageInnerContainer)`
  padding: 0 10px;
`;

const StickyHeader = styled.header`
  position: fixed;
  top: ${TOP_NAV_HEIGHT_PX}px; // Only want UserNav to stick
  z-index: 2;
  width: 100%;
`;

enum CONTENT_MAX_WIDTHS {
  mobile = 416,
  tabletLarge = 650,
  desktop = 1576,
}

const contentMaxWidth = css`
  max-width: ${CONTENT_MAX_WIDTHS.mobile}px;

  @media (min-width: ${BreakpointWidth.tabletLarge}px) {
    max-width: ${CONTENT_MAX_WIDTHS.tabletLarge}px;
  }

  @media (min-width: ${BreakpointWidth.desktop}px) {
    max-width: ${CONTENT_MAX_WIDTHS.desktop}px;
  }
`;

export {
  PageContainer,
  PageInnerContainer,
  LayoutWithUserNav,
  LayoutWithoutUserNav,
  LayoutWithoutHeaderFooter,
  CardsContainer,
  CenteredCardsContainer,
  CONTENT_MAX_WIDTHS,
  contentMaxWidth,
};
