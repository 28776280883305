export const copyToClipboard = (value: string, overrideIPhone: boolean = false) => {
  const tempInput = document.createElement('input');
  document.body.appendChild(tempInput);
  tempInput.value = value;

  if (Boolean(navigator.userAgent.match(/ipad|iphone/i)) && !overrideIPhone) {
    const range = document.createRange();
    range.selectNodeContents(tempInput);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    tempInput.setSelectionRange(0, 999999);
  } else {
    tempInput.select();
  }

  document.execCommand('copy');
  document.body.removeChild(tempInput);
};
