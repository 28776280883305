import React from 'react';

type Props = { tooltipId: string; isExpanded: boolean; children: React.ReactElement };

export const TooltipTarget: React.FC<React.PropsWithChildren<Props>> = ({
  tooltipId,
  isExpanded,
  children,
}) =>
  React.cloneElement(React.Children.only(children) as React.ReactElement, {
    'aria-describedby': tooltipId,
    'aria-controls': tooltipId,
    'aria-expanded': isExpanded,
    'data-for': tooltipId,
    'data-tip': '',
  });
TooltipTarget.displayName = 'TooltipTarget';
