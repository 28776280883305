import React from 'react';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';
import { mutate } from 'swr';
import type { KeyedMutator } from 'swr';
import {
  BreakpointEither,
  GreaterThan,
  getIsBreakpointLessThan,
} from '@peloton/responsive';
import { media, hover, ellipsis, defaultTransition } from '@peloton/styles';
import { toTime } from '@peloton/time';
import { track } from '@engage/analytics';
import { DifficultyLevel, isAudioClass } from '@engage/classes';
import { toCloudinaryUrl } from '@engage/cloudinary';
import {
  gray3,
  slate3,
  white,
  gray4,
  superRed,
  gray1,
  gray2,
  divider1,
} from '@engage/colors';
import { If } from '@engage/conditional-render';
import { FitnessDisciplineSlug } from '@engage/metadata';
import { useReduxAction } from '@engage/redux';
import { useReduxState } from '@engage/redux/hooks';
import { spaces } from '@engage/styles';
import {
  label14Caps,
  label18,
  label12Caps,
  label12,
  label14,
  label10,
  BulletSeparator,
} from '@engage/typography';
import { buttonText, Button1Medium } from '@members/buttons';
import { Stacks, StacksCopy } from '@members/copy';
import { StackedClassesFetchersGQL } from '@members/data-fetch';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import { CLASS_PLAY_BUTTON_SOURCE } from '@members/global-button-handlers/classPlayButtonSource';
import { useOnDemandButtonHandler } from '@members/global-button-handlers/useOnDemandButtonHandler';
import type {
  ClassDetails_LanebreakClass_Fragment,
  ClassDetails_OnDemandInstructorClass_Fragment,
  ClassDetails_ScenicClass_Fragment,
} from '@members/graphql-swr/schemas/stacked-classes/queries/shared-fragments.generated';
import type { ViewUserStackQuery } from '@members/graphql-swr/schemas/stacked-classes/queries/ViewUserStack.generated';
import type {
  DifficultyLevelSlug,
  OnDemandInstructorClass,
  ScenicClass,
} from '@members/graphql-swr/types.generated';
import { PlayIcon } from '@members/icons';
import { DifficultyLabel, DifficultyLabelSize, AudioLabel } from '@members/labels';
import { LocalizedAbbreviatedLong } from '@members/localization';
import { toVideoOnDemandUrl } from '@members/pg-video/urls';
import {
  ShareTooltipSource,
  ShareTooltipContextProvider,
  ShareClassTooltip,
} from '@members/share-modal';
import {
  ANALYTICS_SOURCE_STACKED_CLASSES,
  Source,
  trackTappedModifyStack,
} from '@members/stacks/analytics';
import { Position } from '@members/tags/useTooltip';
import type { ClassAvailabilityProps } from '@members/tiering/models';
import Modify from '../icons/Modify';
import ModifyShare from '../icons/ModifyShare';
import ShareStacksButton from './ShareStacksButton';

const thumbnail = require('@members/library/Classes/thumbnail_overlay.png');
const notPlayable = require('@members/stacks/icons/NotPlayable.svg');

export type StackedOnDemandClass =
  | ClassDetails_OnDemandInstructorClass_Fragment
  | ClassDetails_ScenicClass_Fragment
  | ClassDetails_LanebreakClass_Fragment;

type Props = {
  stackDuration: number;
  numberOfClasses: number;
  stackedClasses: StackedOnDemandClass[];
  setIsModifyStacksView: KeyedMutator<boolean>;
  refetchUserStack: KeyedMutator<ViewUserStackQuery>;
};

export const ActiveState: React.FC<React.PropsWithChildren<Props>> = ({
  stackDuration,
  numberOfClasses,
  stackedClasses,
  setIsModifyStacksView,
  refetchUserStack,
}) => {
  const trackAnalytics = useReduxAction(track);
  const sharedButtermilkToggled = useFeatureToggle(Feature.Sharedbuttermilk);
  const maskRef = React.useRef<HTMLDivElement>(null);

  const toggleModifyOn = () => {
    trackAnalytics(
      trackTappedModifyStack(stackDuration, Source.StackModal, numberOfClasses),
    );
    setIsModifyStacksView(true);
  };
  return (
    <ShareTooltipContextProvider originRef={maskRef}>
      <ScrollContainer>
        <SubHeader>
          <CTAComponent stackDuration={stackDuration} numberOfClasses={numberOfClasses} />
          {sharedButtermilkToggled ? null : (
            <ModifyButton onClick={toggleModifyOn}>
              <Modify />
              <BreakpointEither breakpoint="tablet">
                <>
                  <ModifyTextShort data-test-id="modifyStackButton">
                    <StacksCopy id={Stacks.ModifyStacksShort} />
                  </ModifyTextShort>
                </>
                <ModifyText data-test-id="modifyStackButton">
                  <StacksCopy id={Stacks.ModifyStack} />
                </ModifyText>
              </BreakpointEither>
            </ModifyButton>
          )}
        </SubHeader>
        <FirstClass
          classDetails={stackedClasses[0]}
          joinToken={stackedClasses[0].joinToken}
          showStart={true}
          refetchUserStack={refetchUserStack}
        />
        {stackedClasses.slice(1).map(stackedClass => (
          <div key={stackedClass.classId}>
            <Divider />
            <ThumbnailClass key={stackedClass.title} classDetails={stackedClass} />
          </div>
        ))}
        {sharedButtermilkToggled ? <div style={{ height: '72px' }} /> : null}
        <LastDivider />
      </ScrollContainer>
      {sharedButtermilkToggled ? (
        <BottomCTASection>
          <ModifyShareButton onClick={toggleModifyOn}>
            <ModifyShare />
            <ModifyTextShort data-test-id="modifyStackButton">
              <StacksCopy id={Stacks.ModifyStacksShort} />
            </ModifyTextShort>
          </ModifyShareButton>
          <ShareStacksButton />
          <ShareClassTooltip
            source={ShareTooltipSource.ClassDetailsShare}
            onDemandModalQuery={'test'}
            fitnessDisciplineSlug={FitnessDisciplineSlug.Cycling}
            position={Position.Top}
          />
        </BottomCTASection>
      ) : null}
    </ShareTooltipContextProvider>
  );
};

const getHours = (time: number) => Math.floor(time / 60);

const getMinutes = (time: number) => time % 60;

type CTAProps = {
  stackDuration: number;
  numberOfClasses: number;
};

export const CTAComponent: React.FC<React.PropsWithChildren<CTAProps>> = ({
  stackDuration,
  numberOfClasses,
}) => (
  <CTA data-test-id="classCountAndDuration">
    <If condition={numberOfClasses > 0}>
      <StacksCopy id={Stacks.Classes} values={{ CLASSES: numberOfClasses }} />
      <BulletSeparator />
      <StacksCopy
        id={Stacks.Hours}
        values={{
          HOURS: getHours(stackDuration),
          MINUTES: getMinutes(stackDuration),
        }}
      />
    </If>
  </CTA>
);

type FirstClassProps = RouteComponentProps<{}> & {
  classDetails: StackedOnDemandClass;
  joinToken: string;
  showStart: boolean;
  refetchUserStack: KeyedMutator<ViewUserStackQuery>;
};

export const toDifficultyLevel = (slug: DifficultyLevelSlug | undefined) => {
  switch (slug) {
    case 'ADVANCED':
      return DifficultyLevel.Advanced;
    case 'INTERMEDIATE':
      return DifficultyLevel.Intermediate;
    case 'BEGINNER':
    default:
      return DifficultyLevel.Beginner;
  }
};
export const ActiveOnDemandClass: React.FC<
  React.PropsWithChildren<{
    classDetails: ClassDetails_OnDemandInstructorClass_Fragment;
  }>
> = ({ classDetails }) => {
  return (
    <>
      <If condition={isAudioClass(classDetails.contentFormat)}>
        <FirstAudioLabelWrapper>
          <AudioLabel />
        </FirstAudioLabelWrapper>
      </If>
      <ClassDetails>
        <If condition={classDetails.difficultyLevel != undefined}>
          <StyledDifficultyLabel
            size={DifficultyLabelSize.Small}
            level={toDifficultyLevel(classDetails.difficultyLevel?.slug)}
          />
        </If>
        <Title data-test-id="onDemandFirstClassTitle">{classDetails.title}</Title>
        <Subtitle1 data-test-id="onDemandFirstClassSubtitle">
          {classDetails.instructor?.name}
          <BulletSeparator />
          {classDetails.fitnessDiscipline?.displayName}
        </Subtitle1>
        <TimeOfClass>
          <LocalizedAbbreviatedLong date={toTime(classDetails.airTime)} />
        </TimeOfClass>
      </ClassDetails>
    </>
  );
};

export const ActiveScenicClass: React.FC<
  React.PropsWithChildren<{
    classDetails: ClassDetails_ScenicClass_Fragment;
  }>
> = ({ classDetails }) => {
  return (
    <ClassDetails>
      <Title>{classDetails.title}</Title>
      <Subtitle1>
        {classDetails.classTypes[0].name}
        <BulletSeparator />
        {classDetails.fitnessDiscipline?.slug}
      </Subtitle1>
    </ClassDetails>
  );
};

const ClassContainerWrapper: React.FC<
  React.PropsWithChildren<{ showStart: boolean }>
> = ({ children, showStart }) => {
  if (!showStart) {
    return <>{children}</>;
  }
  return <ClassContainer>{children}</ClassContainer>;
};

const FirstClassView: React.FC<React.PropsWithChildren<FirstClassProps>> = ({
  classDetails,
  history,
  joinToken,
  showStart,
  refetchUserStack,
}) => {
  const playClassFromStack = () =>
    mutate(
      ...StackedClassesFetchersGQL.PlayClassFromStackMutation({
        input: {
          pelotonClassId: joinToken,
        },
      }),
    );

  const onClick = () => {
    playClassFromStack().then(() => {
      refetchUserStack();
    });
    const location = {
      pathname: toVideoOnDemandUrl(classDetails.classId),
      state: { source: ANALYTICS_SOURCE_STACKED_CLASSES },
    };
    history.push(location);
  };

  const classAvailabilityProps = {
    contentAvailability: classDetails?.contentAvailability,
    freeForLimitedTime: classDetails?.freeForLimitedTime,
    isLimitedRide: classDetails?.isLimitedRide,
  } as ClassAvailabilityProps;

  const {
    shouldShowPlayIcon,
    isDisabled,
    upgradeCopy,
    startHandler,
    backgroundStyles,
  } = useOnDemandButtonHandler(
    classDetails.classId,
    onClick,
    classDetails.fitnessDiscipline.slug,
    classAvailabilityProps,
    CLASS_PLAY_BUTTON_SOURCE.STACKS_ACTIVE_STATE,
  );

  const shouldShowNotPlayable = !classDetails.playableOnPlatform;
  const sharedButtermilkEnabled = useFeatureToggle(Feature.Sharedbuttermilk);
  const isMobile = useReduxState(getIsBreakpointLessThan, 'tablet');

  return (
    <ClassContainerWrapper showStart={showStart}>
      <Gradient
        backgroundImage={toCloudinaryUrl(classDetails.assets?.thumbnailImage.location)}
        showStart={showStart}
      >
        <If condition={classDetails.__typename === 'OnDemandInstructorClass'}>
          <ActiveOnDemandClass classDetails={classDetails as OnDemandInstructorClass} />
        </If>
        <If
          condition={
            classDetails.__typename === 'ScenicClass' ||
            classDetails.__typename === 'LanebreakClass'
          }
        >
          <ActiveScenicClass classDetails={classDetails as ScenicClass} />
        </If>
        {sharedButtermilkEnabled ? (
          <>
            <If condition={showStart && !shouldShowNotPlayable}>
              <UpdatedDesignStartButton
                data-test-id="startButton"
                onClick={startHandler}
                disabled={isDisabled}
                backgroundStyles={backgroundStyles}
              >
                {(shouldShowPlayIcon || isMobile) && <PlayIcon />}
              </UpdatedDesignStartButton>
            </If>
            <If condition={showStart && shouldShowNotPlayable}>
              <NotPlayableContainer data-test-id="notPlayableButton">
                <NotPlayableIcon alt={`Not Playable`} src={notPlayable}></NotPlayableIcon>
                <NotPlayableActiveLabel>
                  <StacksCopy id={Stacks.NotPlayable} />
                </NotPlayableActiveLabel>
              </NotPlayableContainer>
            </If>
          </>
        ) : (
          <>
            <If condition={showStart && !shouldShowNotPlayable}>
              <StartButton
                data-test-id="startButton"
                onClick={startHandler}
                disabled={isDisabled}
                backgroundStyles={backgroundStyles}
              >
                {(shouldShowPlayIcon || isMobile) && <PlayIcon />}
                <GreaterThan breakpoint="smallScreen">
                  <PlayText>{upgradeCopy ?? <StacksCopy id={Stacks.Start} />}</PlayText>
                </GreaterThan>
              </StartButton>
            </If>
            <If condition={showStart && shouldShowNotPlayable}>
              <NotPlayableContainer data-test-id="notPlayableButton">
                <NotPlayableIcon alt={`Not Playable`} src={notPlayable}></NotPlayableIcon>
                <NotPlayableActiveLabel>
                  <StacksCopy id={Stacks.NotPlayable} />
                </NotPlayableActiveLabel>
              </NotPlayableContainer>
            </If>
          </>
        )}
      </Gradient>
    </ClassContainerWrapper>
  );
};

export const FirstClass = withRouter(FirstClassView);

const ActiveOnDemandClassThumbnail: React.FC<
  React.PropsWithChildren<{
    classDetails: OnDemandInstructorClass;
  }>
> = ({ classDetails }) => {
  const shouldShowNotPlayable = !classDetails.playableOnPlatform;

  return (
    <>
      <ThumbnailImageContainer>
        <ThumbnailGradient
          backgroundImage={toCloudinaryUrl(classDetails.assets?.thumbnailImage.location)}
          shouldShowNotPlayable={shouldShowNotPlayable}
        />
        <If condition={isAudioClass(classDetails.contentFormat)}>
          <StyledAudioLabel />
        </If>
        <If condition={shouldShowNotPlayable}>
          <NotPlayableThumbnailContainer>
            <NotPlayableIcon alt={`Not Playable`} src={notPlayable}></NotPlayableIcon>
            <NotPlayableThumbnailLabel>
              <StacksCopy id={Stacks.NotPlayable} />
            </NotPlayableThumbnailLabel>
          </NotPlayableThumbnailContainer>
        </If>
      </ThumbnailImageContainer>
      <ThumbnailClassDetails>
        <If condition={classDetails.difficultyLevel != undefined}>
          <StyledDifficultyLabel
            size={DifficultyLabelSize.Small}
            level={toDifficultyLevel(classDetails.difficultyLevel?.slug)}
          />
        </If>
        <ThumbnailTitle>{classDetails.title}</ThumbnailTitle>
        <ThumbnailSubtitle1>
          {classDetails.instructor?.name}
          <BulletSeparator />
          {classDetails.fitnessDiscipline?.displayName}
        </ThumbnailSubtitle1>
        <ThumbnailTimeOfClass>
          <LocalizedAbbreviatedLong date={toTime(classDetails.airTime)} />
        </ThumbnailTimeOfClass>
      </ThumbnailClassDetails>
    </>
  );
};

const ActiveScenicClassThumbnail: React.FC<
  React.PropsWithChildren<{
    classDetails: ScenicClass;
  }>
> = ({ classDetails }) => {
  return (
    <>
      <ThumbnailImageContainer>
        <ThumbnailGradient
          backgroundImage={classDetails.assets?.thumbnailImage.location}
          shouldShowNotPlayable={true}
        />
        <NotPlayableThumbnailContainer>
          <NotPlayableIcon alt={`Not Playable`} src={notPlayable}></NotPlayableIcon>
          <NotPlayableThumbnailLabel>
            <StacksCopy id={Stacks.NotPlayable} />
          </NotPlayableThumbnailLabel>
        </NotPlayableThumbnailContainer>
      </ThumbnailImageContainer>
      <ThumbnailClassDetails>
        <ThumbnailTitle>{classDetails.title}</ThumbnailTitle>
        <ThumbnailSubtitle1>
          {classDetails.classTypes[0].name}
          <BulletSeparator />
          {classDetails.fitnessDiscipline?.slug}
        </ThumbnailSubtitle1>
      </ThumbnailClassDetails>
    </>
  );
};

export const ThumbnailClass: React.FC<
  React.PropsWithChildren<{ classDetails: StackedOnDemandClass }>
> = ({ classDetails }) => {
  return (
    <>
      <ThumbnailClassContainer>
        <If condition={classDetails.__typename === 'OnDemandInstructorClass'}>
          <ActiveOnDemandClassThumbnail
            classDetails={classDetails as OnDemandInstructorClass}
          />
        </If>
        <If
          condition={
            classDetails.__typename === 'ScenicClass' ||
            classDetails.__typename === 'LanebreakClass'
          }
        >
          <ActiveScenicClassThumbnail classDetails={classDetails as ScenicClass} />
        </If>
      </ThumbnailClassContainer>
    </>
  );
};

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

const BottomCTASection = styled.div`
  height: 72px;
  width: 100%;
  background-color: ${slate3};
  color: ${gray3};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: absolute;
  bottom: 0px;
`;
export const SubHeader = styled.div`
  height: 48px;
  width: 100%;
  background-color: ${slate3};
  color: ${gray3};
  margin-top: ${spaces.xxxHuge}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  align-items: center;
  svg {
    stroke: ${gray3};
    height: 16px;
    width: 16px;
  }
  z-index: 1;
  ${media.tablet`
    width: 560px;
    margin-top: 78px;
  `};
`;

export const ModifyButton = styled.button`
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${spaces.xSmall}px;
  padding-right: ${spaces.xSmall}px;
  margin-right: ${spaces.xSmall}px;

  ${defaultTransition('background-color')}
  ${hover`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: ${spaces.xxxxSmall}px;
  `};
  ${buttonText}
`;

const ModifyShareButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  width: 127px;
  height: 40px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 9999px;
  ${buttonText}
`;

const CTA = styled.p`
  padding-left: ${spaces.small}px;
  ${ellipsis}
  ${label14Caps}

  ${media.tablet`
    padding-left: ${spaces.medium}px;
  `};
`;

const ModifyTextShort = styled.p`
  margin-left: ${spaces.xxSmall}px;
`;

export const ModifyText = styled.p`
  margin-left: ${spaces.xxSmall}px;
`;

const ClassContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spaces.small}px;
  padding-left: ${spaces.small}px;
  padding-right: ${spaces.small}px;
  width: 100%;
  margin-top: ${spaces.xxGiant}px;
  ${media.tablet`
    padding-left: ${spaces.medium}px;
    padding-right: ${spaces.medium}px;
    padding-top: ${spaces.medium}px;
    margin-top: ${spaces.xxxGiant}px;
  `};
`;

export const Gradient = styled.div<{ backgroundImage: string; showStart: boolean }>`
  width: 100%;
  aspect-ratio: 16/9;
  min-height: ${props => (props.showStart ? '196px' : '166px')};
  margin-bottom: ${props => (props.showStart ? '16px' : '0px')};
  position: relative;
  border-radius: ${spaces.xxSmall}px;
  background-image: url('${thumbnail}'), url('${props => props.backgroundImage}');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  stroke: ${white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.tablet<{ showStart: boolean }>`
    height: ${props => (props.showStart ? '295px' : '246px')};
    margin: 0px ${spaces.medium}px ${spaces.medium}px ${spaces.medium}px;
    margin-bottom: ${props => (props.showStart ? '16px' : '0px')}
  `}
`;

const StartButton = styled(Button1Medium)<{
  backgroundStyles: FlattenSimpleInterpolation;
}>`
  margin-right: ${spaces.small}px;
  margin-bottom: ${spaces.small}px;
  margin-top: auto;
  bottom: 0;
  right: 0;
  position: absolute;
  ${props =>
    props.backgroundStyles ? props.backgroundStyles : `background-color: ${superRed}`}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 48px;
  min-width: 48px;
  width: 48px;
  border-radius: ${spaces.xLarge}px;
  svg {
    width: 16px;
    height: 16px;
    stroke: ${white};
    position: absolute;
    padding-left: 1px;
  }

  ${media.tablet`
    height: 40px;
    margin-right: ${spaces.medium}px;
    margin-bottom: ${spaces.medium}px;
    min-width: 160px;
    width: 160px;
    border-radius: ${spaces.large}px;

    svg {
      position: relative;
      padding-left: 0px;
    }
  `};
`;

const UpdatedDesignStartButton = styled(Button1Medium)<{
  backgroundStyles: FlattenSimpleInterpolation;
}>`
  ${props =>
    props.backgroundStyles ? props.backgroundStyles : `background-color: ${superRed}`}
  margin-right: ${spaces.small}px;
  margin-bottom: ${spaces.small}px;
  margin-top: auto;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: ${superRed};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 48px;
  min-width: 48px;
  width: 48px;
  border-radius: ${spaces.xLarge}px;
  svg {
    width: 16px;
    height: 16px;
    stroke: ${white};
    position: absolute;
    padding-left: 1px;
  }

  ${media.tablet`
    width: 56px;
    height: 56px;
    border-radius: 9999px;
    margin-right: ${spaces.medium}px;
    margin-bottom: ${spaces.medium}px;
    svg {
      height: 28px;
      width: 28px;
    }
  `};
`;

const PlayText = styled.p`
  margin-left: ${spaces.xSmall}px;
`;

const ClassDetails = styled.div`
  margin-left: ${spaces.medium}px;
  margin-bottom: ${spaces.medium}px;
  bottom: 0;
  left: 0;
  margin-top: auto;
  position: absolute;
  ${ellipsis}
`;

const Title = styled.p`
  color: ${gray1};
  ${label14}
  margin-right: ${spaces.xxSmall}px;
  ${ellipsis}
  ${media.tablet`
    ${label18}
  `};
`;

export const StyledDifficultyLabel = styled(DifficultyLabel)`
  color: ${gray2};
  margin-bottom: ${spaces.xxxSmall}px;
`;

const Subtitle1 = styled.p`
  color: ${gray1};
  margin-top: ${spaces.xxxSmall}px;
  margin-bottom: ${spaces.small}px;
  ${ellipsis}
  ${label12Caps}

  ${media.tablet`
    ${label12Caps}
  `};
`;
const TimeOfClass = styled.p`
  color: ${gray4};
  ${label10}

  ${media.tablet`
    ${label12}
  `};
`;

export const ThumbnailClassContainer = styled.div`
  height: 106px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: ${spaces.xSmall}px;
`;

const ThumbnailImageContainer = styled.div`
  position: relative;
  width: 85px;
  min-width: 85px;
  height: 48px;
  margin: ${spaces.small}px 0px ${spaces.small}px ${spaces.small}px;

  ${media.smallScreen`
    width: 127px;
    min-width: 127px;
    height: 72px;
    margin: ${spaces.medium}px 0px ${spaces.medium}px ${spaces.medium}px;
  `};
`;

export const ThumbnailGradient = styled.div<{
  backgroundImage: string;
  shouldShowNotPlayable: boolean;
}>`
  width: 100%;
  height: 100%;
  background-image: url('${thumbnail}'), url(${props => props.backgroundImage});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${spaces.xxxSmall}px;
  opacity: ${props => (props.shouldShowNotPlayable ? '48%' : '100%')};
`;

const NotPlayableThumbnailLabel = styled.p`
  text-align: center;
  color: ${white};
  ${label12}
`;

const NotPlayableActiveLabel = styled.p`
  color: ${white};
  ${label12}
`;

const NotPlayableContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const NotPlayableThumbnailContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 36px;
  vertical-align: middle;
`;

const NotPlayableIcon = styled.img`
  margin: auto;
  padding-bottom: 4px;
  display: block;
  width: 20px;
  height: 20px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${divider1};
  display: block;
  width: 100%;
`;

const LastDivider = styled.div`
  border-bottom: 1px solid ${divider1};
  display: block;
  width: 100%;

  ${media.tablet`
    display: none;
  `};
`;

export const ThumbnailClassDetails = styled.div`
  max-width: 250px;
  padding: ${spaces.medium}px;

  ${media.tablet`
    max-width: 400px;
  `};
`;

export const ThumbnailTitle = styled.p`
  color: ${gray1};
  ${ellipsis}
  ${label14}
`;

export const ThumbnailSubtitle1 = styled.p`
  color: ${gray1};
  margin-bottom: ${spaces.xxSmall}px;
  margin-top: ${spaces.xxSmall}px;
  ${ellipsis}
  ${label12Caps}
`;

export const ThumbnailTimeOfClass = styled.p`
  color: ${gray4};
  ${label10}
`;

const FirstAudioLabelWrapper = styled.div`
  margin-top: ${spaces.small}px;
  margin-left: ${spaces.small}px;
  position: absolute;
`;

const StyledAudioLabel = styled(AudioLabel)`
  border-radius: 2.5px 0px;
`;

export default ActiveState;
