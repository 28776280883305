import React from 'react';
import type { ExtLinkEnv } from '@peloton/external-links';
import { toDigitalLink, toHref, getExtLinkEnv } from '@peloton/external-links';
import { parseSearch } from '@peloton/redux';
import { useGenerateGuestPass } from '@engage/api-v2/extended/useGenerateGuestPass';
import type { FitnessDisciplineSlug } from '@engage/metadata';
import { fitnessDisciplineToBrowseCategory } from '@engage/metadata';
import { useReduxState } from '@engage/redux';
import { engageApi } from '@members/data-fetch';
import { Feature, useFeatureToggle } from '@members/feature-toggles';
import { toLibraryCategoryRoute } from '@members/pg-library/urls';
import { useIsFreemiumMember } from '@members/tiering/hooks/useIsFreemiumMember';
import { useTrackShareClassToClipboard, useTrackShareClassToFacebook } from './analytics';
import type { ShareTooltipProps } from './ShareTooltip';
import { ShareTooltip } from './ShareTooltip';

const getClassUrl = (shareUrl: string) => parseSearch({ search: shareUrl }).classId;

export const ShareClassTooltip: React.FC<
  React.PropsWithChildren<
    ShareTooltipProps & {
      onDemandModalQuery: string;
      fitnessDisciplineSlug: FitnessDisciplineSlug;
    }
  >
> = ({ source, onDemandModalQuery, fitnessDisciplineSlug, position }) => {
  const isFreemiumMember = useIsFreemiumMember();
  const guestPass = useGenerateGuestPass(engageApi, isFreemiumMember);
  const env = useReduxState(getExtLinkEnv);
  const isProspectsGuestPassEnabled = useFeatureToggle(
    Feature.IsProspectsGuestPassEnabled,
  );
  const shareUrl = toShareClassUrl(
    onDemandModalQuery,
    fitnessDisciplineSlug,
    isProspectsGuestPassEnabled,
    guestPass?.accessCode ?? null,
    env,
  );
  const trackShareClassToFacebook = useTrackShareClassToFacebook(getClassUrl(shareUrl));
  const trackShareClassToClipboard = useTrackShareClassToClipboard(getClassUrl(shareUrl));

  return (
    <ShareTooltip
      source={source}
      shareUrl={shareUrl}
      trackShareToFacebook={trackShareClassToFacebook}
      trackShareToClipboard={trackShareClassToClipboard}
      position={position}
    />
  );
};

/**
 * Adds a guest pass to the share url if the feature toggle is enabled.
 * @param shareUrl class share url
 * @param isProspectsGuestPassEnabled feature toggle state.
 * @param guestPassCode guest pass code generated from the api.
 * @returns share url with guest pass appended.
 */
const addGuestPassParam = (
  shareUrl: string,
  isProspectsGuestPassEnabled: boolean,
  guestPassCode: string | null,
) => {
  if (isProspectsGuestPassEnabled && guestPassCode) {
    return `${shareUrl}&code=${guestPassCode}`;
  }

  return shareUrl;
};

/**
 * Given the suffix of a class url, this method constructs and returns a shareable url.
 * @param onDemandModalQuery end part of url for classes, includes unique class id;
 * @param fitnessDisciplineSlug fitness discipline slug
 * @param isProspectsGuestPassEnabled is prospects guest pass enabled
 * @param guestPassCode guest pass code
 * @param env environment
 */
export const toShareClassUrl = (
  onDemandModalQuery: string,
  fitnessDisciplineSlug: FitnessDisciplineSlug,
  isProspectsGuestPassEnabled: boolean,
  guestPassCode: string | null,
  env: ExtLinkEnv,
) => {
  const browseCategorySlug = fitnessDisciplineToBrowseCategory(fitnessDisciplineSlug);
  const path = addGuestPassParam(
    toLibraryCategoryRoute(browseCategorySlug) + onDemandModalQuery,
    isProspectsGuestPassEnabled,
    guestPassCode,
  );
  const link = toDigitalLink(path);

  return toHref(link, env);
};
