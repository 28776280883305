import type { AllTagsTagCommonInfoFragment } from '@members/graphql-swr/schemas/tags/queries/shared-fragments.generated';
import type { Tag, Maybe } from '@members/graphql-swr/types.generated';

export enum MetaTag {
  TRENDING = 'TRENDING',
  FEATURED = 'FEATURED',
  FRIENDS = 'FRIENDS',
  POPULAR = 'POPULAR',
  NONE = '',
}

export enum APITypename {
  ADD_TAG_TO_USER_SUCCESS = 'AddTagToUserSuccess',
  REMOVE_TAG_FROM_USER_SUCCESS = 'RemoveTagFromUserSuccess',
  SET_TAG_AS_PRIMARY_SUCCESS = 'SetTagAsPrimarySuccess',
  TAG_NAME_PROHIBITED_ERROR = 'TagNameProhibitedError',
  TAG_CAPACITY_OVER_LIMIT_ERROR = 'TagCapacityOverLimitError',
  REPLACE_TAG_SUCCESS = 'ReplaceTagSuccess',
  TAG_ALREADY_EXISTS_ERROR = 'TagAlreadyExistsError',
}

export type ClientTag = {
  name: string;
  numberOfFollowing: number;
  numberOfMembers: number;
  metaTag: MetaTag;
  backgroundImageUrl: string;
  hasAdded: boolean;
};

type AllTag = AllTagsTagCommonInfoFragment & Maybe<Pick<Tag, 'metaTag'>>;

export const toClientTag = ({
  assets: { backgroundImage },
  followingCount,
  hasAdded,
  metaTag,
  name,
  users: { totalCount },
}: AllTag): ClientTag => ({
  name,
  numberOfFollowing: followingCount,
  numberOfMembers: totalCount,
  metaTag: (metaTag?.displayName as MetaTag) ?? MetaTag.NONE,
  backgroundImageUrl: backgroundImage.location,
  hasAdded: hasAdded ?? false,
});
