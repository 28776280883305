import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { CustomizableToast, StyledMessageAndIcon } from '@members/notifications';

export const BirthdateSubmitToast: React.FC<
  React.PropsWithChildren<{
    close(): void;
    message: string;
    hide?: boolean;
    autoDismiss: boolean;
  }>
> = ({ close, message, hide, autoDismiss }) => (
  <>
    {message ? (
      <BirthdateStyledToast onClose={close} autoDismiss={autoDismiss} hide={hide}>
        {message}
      </BirthdateStyledToast>
    ) : null}
  </>
);

const BirthdateStyledToast = styled(CustomizableToast)`
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;

  ${media.tablet`
    width: 460px;
    top: ${spaces.huge}px;
    left: calc(50% - 230px);
  `}

  ${StyledMessageAndIcon} {
    padding: ${spaces.small}px 0;

    span {
      min-height: ${spaces.xLarge}px;
    }
  }
`;
