import { pathOr } from 'ramda';
import { matchPath } from 'react-router';
import type { SagaIterator } from 'redux-saga';
import { takeEvery, select, put, take } from 'redux-saga/effects';
import { getLocation } from '@peloton/redux';
import { toUtcTime } from '@peloton/time';
import { track } from '@engage/analytics';
import type { openClassDetailAnalytic } from '@engage/class-detail';
import {
  ClassDetailActionType,
  getClassDetail,
  trackViewedClassDetails,
} from '@engage/class-detail';
import type { ClassAnalyticsProperties } from '@engage/classes';
import { getClassAnalyticsProperties } from '@engage/classes';
import { logError } from '@engage/error-reporting/logError';
import { getFilterAnalyticsProps } from '@engage/filters';
import type { Member } from '@engage/members/models';
import { getMember } from '@engage/members/selectors';
import { toSource } from '@members/analytics';
import {
  PROGRAMS_LOCATION_SOURCE,
  toProgramGroupProperties,
} from '@members/class-groups/analytics';
import { FEED_CLASS_DETAILS_SOURCE } from '@members/pg-feed/constants';
import { getProgramInfo } from '@members/pg-programs/redux';
import { PROGRAMS_ID_PARAM, PROGRAMS_ROUTE } from '@members/pg-programs/urls';

const getClassGatedType = (
  member: Member,
  contentAvailability: string,
  freeForLimitedTime: boolean,
) => {
  if (!member?.hasActiveDeviceSubscription && !member?.hasActiveDigitalSubscription) {
    if (contentAvailability === 'available' && freeForLimitedTime) {
      return 'Free Class - Limited time';
    } else if (contentAvailability === 'available') {
      return 'Free Class';
    }
  }

  return 'none';
};

const analyticsSaga = function* (
  action: ReturnType<typeof openClassDetailAnalytic>,
): SagaIterator {
  try {
    // Wait for class to load before sending track action
    yield take(ClassDetailActionType.RequestSuccess);
    const location = yield select(getLocation);
    const classInfo: ClassAnalyticsProperties | undefined = yield select(
      getClassAnalyticsProperties,
      action.payload.id,
    );
    const classDetails = yield select(getClassDetail);
    const member = yield select(getMember);
    const classGatedType = getClassGatedType(
      member,
      classDetails.ride.contentAvailability,
      classDetails.ride.freeForLimitedTime,
    );
    const browseInfo = yield select(getFilterAnalyticsProps);
    const hasPlaylist = classDetails.playlist.isPlaylistShown;

    type ProgramDetailsUrlParams = { programId?: string };
    const isViewedFromProgramDetails = matchPath<ProgramDetailsUrlParams>(
      location.pathname,
      {
        path: `${PROGRAMS_ROUTE}${PROGRAMS_ID_PARAM}`,
      },
    );

    if (isViewedFromProgramDetails) {
      const programInfo = yield select(getProgramInfo);
      const dateFormat = 'YYYY-MM-DD';
      const programAnalytics = {
        ...toProgramGroupProperties(programInfo.programClassGroup),
        '[Program Progress ID]': programInfo.programProgressId,
        programProgressStartDate: toUtcTime(programInfo.programProgressStartDate).format(
          dateFormat,
        ),
        programProgressEndDate: toUtcTime(programInfo.programProgressEndDate).format(
          dateFormat,
        ),
      };

      yield put(
        track(
          trackViewedClassDetails(
            classInfo,
            PROGRAMS_LOCATION_SOURCE,
            false,
            hasPlaylist,
            browseInfo,
            action.payload.isInStacks,
            programAnalytics,
            action.payload.searchSharedProperties,
            {},
            classGatedType,
          ),
        ),
      );
    } else if (location.pathname.includes('home')) {
      const featuredAnalytics = {
        ['Featured Name']: pathOr(undefined, ['state', 'featuredModule'], location),
        ['Rank']: pathOr(undefined, ['state', 'rank'], location),
        ['Featured Total Classes']: pathOr(undefined, ['state', 'total'], location),
      };
      yield put(
        track(
          trackViewedClassDetails(
            classInfo,
            toSource(location),
            false,
            hasPlaylist,
            browseInfo,
            action.payload.isInStacks,
            undefined,
            action.payload.searchSharedProperties,
            featuredAnalytics,
            classGatedType,
          ),
        ),
      );
    } else if (location.pathname.includes('feed')) {
      yield put(
        track(
          trackViewedClassDetails(
            classInfo,
            FEED_CLASS_DETAILS_SOURCE,
            false,
            hasPlaylist,
            browseInfo,
            action.payload.isInStacks,
            undefined,
            action.payload.searchSharedProperties,
            {},
            classGatedType,
          ),
        ),
      );
    } else {
      yield put(
        track(
          trackViewedClassDetails(
            classInfo,
            toSource(location),
            false,
            hasPlaylist,
            browseInfo,
            action.payload.isInStacks,
            undefined,
            action.payload.searchSharedProperties,
            {},
            classGatedType,
          ),
        ),
      );
    }
  } catch (e) {
    logError(e, 'analyticsSaga');
  }
};

export default function* () {
  yield takeEvery(ClassDetailActionType.OpenAnalytic, analyticsSaga);
}
