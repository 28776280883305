import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ScreenReaderText } from '@peloton/accessibility';
import { isEnterKey } from '@peloton/keyboard';
import { getIsBreakpointLessThan } from '@peloton/responsive';
import { defaultTransition, media } from '@peloton/styles';
import { toDiffDuration, toNowTime, toUtcTime } from '@peloton/time';
import { track } from '@engage/analytics';
import { gray1, gray2, gray3, slate1, slate3, slate4, white } from '@engage/colors';
import { DateTime } from '@engage/internationalize';
import { useReduxAction, useReduxState } from '@engage/redux';
import { getFeedPrivacy } from '@engage/settings';
import { spaces } from '@engage/styles';
import { body14, l1Bold, label12, label14, label16, title20 } from '@engage/typography';
import { button1Styles, button2Styles, mediumButtonStyles } from '@members/buttons';
import type { ModalLabel, CopyIDs } from '@members/copy';
import {
  ActivityFeedCopy,
  ActivityFeed,
  TopNav,
  useTopNav,
  TopNavCopy,
} from '@members/copy';
import { FeedPrivacy } from '@members/graphql-swr/types.generated';
import type { Notification } from '@members/graphql-swr/types.generated';
import { Cross } from '@members/icons';
import { SimpleAvatar } from '@members/images';
import { CheckedBell } from '@members/layout/nav/icons';
import { LoadingSpinner } from '@members/loading';
import { ConnectedModal } from '@members/modal';
import { FEED_ROUTE } from '@members/pg-feed/Routes';
import { toUpcomingLiveDurationInHoursOrDays } from '@members/pg-homescreen/components/upcoming-live/UpcomingLiveDuration';
import { trackViewedFeedNotifications } from '../analytics';
import { getNotificationSource } from '../helpers';
import { useGetNotificationDetails, useFeedNotificationsData } from '../hooks';
import { ChevronIcon } from '../icons';

type Props = {
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
};
const NOTIFICATIONS_MODAL_NAME = 'notificationsFromNav';
const FeedNotifications: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen = false,
  setIsOpen = () => null,
}) => {
  const isMobile = useReduxState(getIsBreakpointLessThan, 'tabletXLarge');
  const ariaLabelId = useTopNav(TopNav.Notifications) as CopyIDs<typeof ModalLabel>;
  const handleClose = () => {
    setIsOpen(false);
  };
  return isMobile ? (
    <StyledModal
      modalNames={{
        [NOTIFICATIONS_MODAL_NAME]: {
          ariaLabelId,
          component: (
            <FeedNotificationsContent
              handleClose={handleClose}
              isMobile={isMobile}
              isOpen={isOpen}
            />
          ),
        },
      }}
    ></StyledModal>
  ) : isOpen ? (
    <FeedNotificationsContainer isOpen={isOpen}>
      <FeedNotificationsContent
        handleClose={handleClose}
        isMobile={isMobile}
        isOpen={isOpen}
      />
    </FeedNotificationsContainer>
  ) : null;
};

const FeedNotificationsContent: React.FC<
  React.PropsWithChildren<{
    handleClose: () => void;
    isMobile: boolean;
    isOpen: boolean;
  }>
> = ({ handleClose, isMobile, isOpen }) => {
  const { notifications, loading, refetch } = useGetNotificationDetails();
  const { quantityOfNotifs } = useFeedNotificationsData();
  const unreadNotifications = quantityOfNotifs > 0 ? true : false;
  const trackAnalytics = useReduxAction(track);

  const history = useHistory();
  const source = getNotificationSource(history.location.pathname);

  useEffect(() => {
    if (isOpen && !!notifications) {
      refetch();
      trackAnalytics(
        trackViewedFeedNotifications(unreadNotifications, notifications?.length, source),
      );
    }
  }, [isOpen, notifications]);

  const notificationsQuantity = notifications?.length;
  const feedPrivacy = useReduxState(getFeedPrivacy);
  return loading ? (
    <LoadingContainer>
      <LoadingSpinner isLoading={true} size={40} />
    </LoadingContainer>
  ) : (
    <Container>
      <FlyoutContent>
        <TopContainer>
          <HeaderContainer>
            <HeaderText data-test-id="notificatiosHeader">
              <TopNavCopy id={TopNav.Notifications} />
            </HeaderText>
            {!isMobile ? (
              <CloseButton
                data-test-id="notificationsCloseButton"
                onClick={handleClose}
                onKeyDown={e => isEnterKey(e) && handleClose()}
                role="button"
                tabIndex={0}
              >
                <Cross width="12px" height="12px" />
                <ScreenReaderText>
                  <ActivityFeedCopy id={ActivityFeed.Close} />
                </ScreenReaderText>
              </CloseButton>
            ) : null}
          </HeaderContainer>
        </TopContainer>
        {unreadNotifications && feedPrivacy !== FeedPrivacy.OnlyMe ? (
          <NotificationCountContainer>
            <ActivityFeedCopy
              id={ActivityFeed.NewNotifications}
              values={{ newNotifications: quantityOfNotifs }}
            />
          </NotificationCountContainer>
        ) : null}
        <BodyContainer notifications={notificationsQuantity > 0}>
          {notificationsQuantity > 0 && feedPrivacy !== FeedPrivacy.OnlyMe ? (
            <Notifications notifications={notifications} />
          ) : (
            <NoNotifications>
              <CheckedBell />
              <NoNotificationsText data-test-id="noNotificationsText">
                <ActivityFeedCopy id={ActivityFeed.NoNewNotifications} />
              </NoNotificationsText>
              {feedPrivacy === FeedPrivacy.OnlyMe ? (
                <>
                  <PrivacyText data-test-id="privacyText">
                    <ActivityFeedCopy id={ActivityFeed.PrivacyText} />
                  </PrivacyText>
                  <LinkToPrivacySettings
                    data-test-id="viewPrivacySettingsButton"
                    to={{
                      pathname: '/preferences/settings',
                    }}
                    onClick={() => handleClose()}
                    isPrivate={feedPrivacy === FeedPrivacy.OnlyMe}
                  >
                    <ActivityFeedCopy id={ActivityFeed.ViewPrivacySettings} />
                  </LinkToPrivacySettings>
                </>
              ) : null}
              {isMobile ? (
                <LinkToFeed
                  to={{
                    pathname: FEED_ROUTE,
                  }}
                  onClick={() => handleClose()}
                  isPrivate={feedPrivacy === FeedPrivacy.OnlyMe}
                >
                  <ActivityFeedCopy id={ActivityFeed.BackToFeed} />
                </LinkToFeed>
              ) : null}
            </NoNotifications>
          )}
        </BodyContainer>
      </FlyoutContent>
    </Container>
  );
};

const Notifications: React.FC<
  React.PropsWithChildren<{ notifications: Notification[] }>
> = ({ notifications }) => {
  return (
    <NotificationsViewContainer>
      {notifications?.map((notification: Notification) => {
        const nowTime = toNowTime().startOf('second');
        const timeLeft = toDiffDuration(toUtcTime(notification.highFivedAt), nowTime);
        const [count, unit] = toUpcomingLiveDurationInHoursOrDays(timeLeft);
        const minutes = timeLeft.minutes();
        return (
          <React.Fragment key={`notification-${notification.highFivedAt}`}>
            <Link
              to={{
                pathname: `${FEED_ROUTE}/${notification.activityFeedEvent.id}`,
              }}
            >
              <NotificationContainer data-test-id="notificationContainer">
                <UserImage
                  data-test-id="userImage"
                  size={40}
                  url={notification.user.assets?.image.location ?? ''}
                  loadingColor={gray3}
                  alt={`notification-${notification.user.username}`}
                  className="fs-exclude"
                />
                <NotificationText>
                  <NotificationUsername data-test-id="notificationUsername">
                    {notification.user.username}
                  </NotificationUsername>
                  <NotificationCopy data-test-id="highFivedWorkout">
                    <ActivityFeedCopy id={ActivityFeed.HighFivedWorkout} />
                  </NotificationCopy>
                  <NotificationTime data-test-id="notificationTime">
                    {unit === 'days'
                      ? DateTime.toLocalizedShortMonthDate(
                          toUtcTime(notification.highFivedAt),
                        )
                      : count === 0
                      ? minutes + ' min'
                      : count + 'h'}
                  </NotificationTime>
                </NotificationText>
                {notification.isSeen === false ? (
                  <RedDot data-test-id="redDotIcon"></RedDot>
                ) : (
                  <NoDot></NoDot>
                )}
                <StyledChevron />
              </NotificationContainer>
            </Link>
          </React.Fragment>
        );
      })}
    </NotificationsViewContainer>
  );
};

const NotificationsViewContainer = styled.div``;

const NotificationContainer = styled.div`
  align-items: center;
  padding: 16px;
  height: 88px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 40px 1fr 12px 24px;
  ${media.tablet`
    grid-template-columns: 52px 227px 24px 24px;
  `};
`;

const StyledChevron = styled(ChevronIcon)`
  margin-bottom: 1px;
  margin-left: ${spaces.xxSmall}px;
  align-self: center;
  color: ${slate3};
  ${defaultTransition('transform')};
`;

const NotificationText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
  margin-left: 8px;
  ${media.tablet`
    margin-left: 0px;
  `};
`;

const Dot = styled.div`
  height: ${spaces.xSmall}px;
  width: ${spaces.xSmall}px;
  min-width: ${spaces.xSmall}px;
  min-height: ${spaces.xSmall}px;
  margin-left: ${spaces.xSmall}px;
  border-radius: 50%;
  align-self: center;
`;

const RedDot = styled(Dot)`
  background-color: #ff3347;
`;

const NoDot = styled(Dot)``;

const UserImage = styled(SimpleAvatar)`
  display: block;
  align-self: center;
`;

const NotificationUsername = styled.div`
  color: ${slate3};
  ${label14}
`;

const NotificationCopy = styled.div`
  color: ${slate1};
  ${label12}
`;

const NotificationTime = styled.div`
  color: ${slate1};
  font-size: 10px;
  line-height: 16px;
`;

const FlyoutContent = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const TopContainer = styled.div`
  box-shadow: inset 0px -1px 0px ${gray2};
  padding: 24px 24px 24px 16px;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-template-areas: 'text close';
`;

const HeaderText = styled.header`
  grid-area: text;
  text-align: left;
  ${label16}
`;

const NoNotifications = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  gap: 16px;
`;

const NoNotificationsText = styled.div`
  ${title20}
`;

const PrivacyText = styled.div`
  width: 320px;
  ${body14}
  text-align: center;
`;

const CloseButton = styled.div`
  grid-area: close;
  cursor: pointer;
  ${l1Bold}
  color: ${slate4};
  padding: ${spaces.xxSmall}px 0 0 ${spaces.xxSmall}px;
  svg {
    height: ${spaces.small}px;
    width: ${spaces.small}px;
  }
`;

const BodyContainer = styled.div<{ notifications: boolean }>`
  width: 100%;
  overflow: ${({ notifications }) => (notifications ? `scroll` : `hidden`)};
`;

const StyledModal = styled(ConnectedModal)`
  width: 100%;
  height: 100%;
  border-radius: ${spaces.small}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeedNotificationsContainer = styled.div<{ isOpen: boolean }>`
  ${props => (props.isOpen ? 'position: absolute' : 'display: none')};
  height: calc(100vh - 60px);
  background-color: ${white};
  top: 60px;
  right: 0px;
  width: 368px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  background-color: ${white};
  overflow-y: hidden;
  overflow-x: auto;

  ${media.tablet`
    width: 100%;
  `};
`;

const LinkToFeed = styled(Link)<{ isPrivate: boolean }>`
  ${mediumButtonStyles};
  font-size: ${spaces.medium};
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${props => (props.isPrivate ? 'height: 40px' : 'height: 48px')};

  ${props => (props.isPrivate ? button2Styles : button1Styles)};
  width: 279px;
  ${media.tablet`
    width: 311px;
  `};
`;

const LinkToPrivacySettings = styled(Link)<{ isPrivate: boolean }>`
  ${mediumButtonStyles};
  ${button1Styles};
  font-size: ${spaces.medium};
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 279px;
  ${props => (props.isPrivate ? 'height: 40px' : 'height: 48px')};

  ${media.tablet`
    width: 256px;
  `};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

const NotificationCountContainer = styled.div`
  height: 36px;
  background-color: ${gray1};
  color: ${slate1};
  display: flex;
  align-items: center;
  padding: ${spaces.xSmall}px ${spaces.medium}px;
  ${label14}
`;

export default FeedNotifications;
