import type { FormikProps } from 'formik';
import { Field, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { getExtLinkEnv, isCustomApiEnv } from '@peloton/external-links';
import { useReduxState } from '@engage/redux';
import { MediumSubmitButtonDark } from '@members/buttons';
import { StorageKeys, syncPipe } from '@members/env';
import { LightInputWithLabel } from '@members/form';
import { OptimizelyEnv } from '../../toggle-gate/ToggleGate';
import { ApiEnvironmentToggle, CUSTOM_OPTION } from './ApiEnvironmentToggle';
import { OptimizelyEnvironmentToggle } from './OptimizelyEnvironmentToggle';

enum FormField {
  OptimizelyEnvField = 'optimizelyEnv',
  ApiEnv = 'apiEnv',
  CustomApiEnv = 'customApiEnv',
}
type FormValues = Record<FormField, string>;

const Form: React.FC<React.PropsWithChildren<FormikProps<FormValues>>> = ({
  handleSubmit,
  isSubmitting,
  isValid,
  values,
}) => {
  const showCustomInput = isCustomApiEnv(values.apiEnv);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Field
        component={OptimizelyEnvironmentToggle}
        name={FormField.OptimizelyEnvField}
      />
      <Field component={ApiEnvironmentToggle} name={FormField.ApiEnv} />
      {showCustomInput ? (
        <Field component={LightInputWithLabel} name={FormField.CustomApiEnv} />
      ) : null}
      <br />
      <MediumSubmitButtonDark
        isValid={isValid}
        isSubmitting={isSubmitting}
        text="Save and Reload"
      />
    </StyledForm>
  );
};

export const EnvironmentConfiguration: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { api } = useReduxState(getExtLinkEnv);

  const handleSubmit = (formValues: FormValues) => {
    if (formValues.apiEnv === CUSTOM_OPTION) {
      syncPipe(StorageKeys.Api, formValues.customApiEnv);
    } else {
      syncPipe(StorageKeys.Api, formValues.apiEnv);
    }
    syncPipe(StorageKeys.OptimizelyEnv, formValues.optimizelyEnv);
    window.location.reload();
  };

  return (
    <Formik
      isInitialValid={true}
      initialValues={{
        [FormField.OptimizelyEnvField]: OptimizelyEnv,
        [FormField.ApiEnv]: isCustomApiEnv(api) ? CUSTOM_OPTION : api,
        [FormField.CustomApiEnv]: isCustomApiEnv(api) ? api : '',
      }}
      component={Form}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    />
  );
};

const StyledForm = styled.form`
  width: 100%;
`;
