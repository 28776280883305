import { prop, sortBy } from 'ramda';
import type { ScreenProps, ClientDetails } from '@peloton/analytics';
import { toClientDetailsHeader } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { toTime } from '@peloton/time';

import type { BrowseCategorySlug } from '@engage/browse-categories/models';
import type { ApiPeloton, Class } from '@engage/classes';
import { toClasses } from '@engage/classes';
import type { ScheduledClass } from './models';

const RESERVATION_URL = '/api/reservation';
const toCancelReservationUrl = (reservationId: string) =>
  `/api/reservation/${reservationId}`;
const toScheduledClassUrl = (id: string) => `/api/peloton/${id}`;

// peloton endpoint does not include reservationId
export const fetchScheduledClass = (api: Client, id: string) =>
  api.get(toScheduledClassUrl(id)).then(pipeData(toScheduledClassWithoutReservation));

export const createReservation = (
  api: Client,
  userId: string,
  pelotonId: string,
  analyticsProps: ClientDetails<ScreenProps>,
) =>
  api
    .post(
      RESERVATION_URL,
      {
        user_id: userId,
        peloton_id: pelotonId,
        location: 'remote',
      },
      toClientDetailsHeader(analyticsProps),
    )
    .then(pipeData((res: ApiReservation) => res.id));

export const cancelReservation = (api: Client, reservationId: string) =>
  api.delete(toCancelReservationUrl(reservationId));

// the type of the API response from useSwr vs from axios is subtly
// different, so for the sake of simplicity, we are typing the apiSchedule
// argument as any
export const toSchedule = (apiSchedule: any): Schedule => ({
  classes: toClasses(apiSchedule),
  scheduledClasses: apiSchedule.data.map(toScheduledClass),
  browseCategories: toBrowseCategories(apiSchedule.browseCategories),
});

export const toBrowseCategories = sortBy(prop('listOrder'));

const toScheduledClass = (apiPeloton: ApiPeloton): ScheduledClass => ({
  ...toScheduledClassWithoutReservation(apiPeloton),
  reservationId: apiPeloton.authedUserReservationId,
});

const toScheduledClassWithoutReservation = (apiPeloton: ApiPeloton): ScheduledClass => ({
  classId: apiPeloton.rideId,
  id: apiPeloton.id,
  isEncore: apiPeloton.isEncore,
  isCountedIn: apiPeloton.isCountedIn,
  pedalingStartTime: apiPeloton.pedalingStartTime
    ? toTime(apiPeloton.pedalingStartTime)
    : undefined,
  pedalingEndTime: apiPeloton.pedalingEndTime
    ? toTime(apiPeloton.pedalingEndTime)
    : undefined,
  scheduledStartTime: toTime(apiPeloton.scheduledStartTime),
  startTime: apiPeloton.startTime ? toTime(apiPeloton.startTime) : undefined,
  status: apiPeloton.status,
  joinToken: apiPeloton.joinToken,
  liveClassCategory: apiPeloton.liveClassCategory,
});

type BrowseCategory = {
  name: string;
  slug: BrowseCategorySlug;
};

export type Schedule = {
  classes: Class[];
  scheduledClasses: ScheduledClass[];
  browseCategories: BrowseCategory[];
};

type ApiReservation = {
  id: string;
  userId: string;
  pelotonId: string;
};
