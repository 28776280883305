import React from 'react';

const SearchIconSmall: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 17 16" {...props}>
    <path
      fillRule="evenodd"
      d="M2.896 9.552A4.67 4.67 0 0 1 1.5 6.217c0-1.26.497-2.444 1.396-3.335a4.79 4.79 0 0 1 3.38-1.384 4.79 4.79 0 0 1 3.379 1.383 4.669 4.669 0 0 1 1.395 3.336 4.668 4.668 0 0 1-1.395 3.335c-1.863 1.845-4.894 1.844-6.759 0m12.882 5.018l-4.567-4.52a6.127 6.127 0 0 0 1.339-3.833 6.148 6.148 0 0 0-1.841-4.4c-2.445-2.423-6.424-2.422-8.868 0A6.152 6.152 0 0 0 0 6.216c0 1.663.655 3.226 1.841 4.401a6.285 6.285 0 0 0 4.435 1.815 6.273 6.273 0 0 0 3.852-1.346l4.595 4.55a.75.75 0 0 0 1.055-1.066"
    />
  </svg>
);

export default SearchIconSmall;
