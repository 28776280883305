import type { AxiosTransformer, ResponseType } from 'axios';
import axios from 'axios';
import { camelizeKeys } from 'humps';
import {
  toDefaultRequestTransforms,
  toDefaultResponseTransforms,
} from '@peloton/api/client-transforms';
import { toApiLink, toExtLinkEnv, toHref, ApiEnvs } from '@peloton/external-links';
import { toMatchingTld } from '@peloton/internationalize';
import { toNowTime } from '@peloton/time';
// Allowed since this is the proxy
import { BaseAPI } from '@engage/api-v2';
import { toApiEnv } from '@members/env';

const WEB_PELOTON_PLATFORM = 'web';
const BASE_64_ENCODED_DEVICE_TYPE = btoa(JSON.stringify({ 'Device Type': 'Web' }));

const extLink = toExtLinkEnv({
  api: toApiEnv(),
});

const createApiClient = (responseType: ResponseType = 'json') => {
  const apiClient = axios.create({
    baseURL: toHref(toApiLink('/'), extLink),
    responseType,
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  apiClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;

    return config;
  });

  return apiClient;
};

const apiClient = createApiClient();
const apiClientNoAuthHeader = createApiClient();
const apiClientTextResponseType = createApiClient('text');

type PresenceEnv = 'stage' | 'test' | 'prod';

type ApiBase =
  | 'presence-leaderboard'
  | 'api-pages'
  | 'api-pages-backup'
  | 'graphql'
  | 'tooltips'
  | 'api-guidance'
  | 'progress-tracking'
  | 'api-content-preferences'
  | 'self-moderation'
  | 'user-preferences';

const toBaseURL = (apiEnv: ApiEnvs, apiBase: ApiBase) => {
  const url = (env: PresenceEnv) => `https://${apiBase}.${env}.k8s.onepeloton.com`;

  switch (apiEnv) {
    case ApiEnvs.Qa1:
      return url('stage');
    case ApiEnvs.Qa2:
      return url('test');
    case ApiEnvs.Prod:
    default:
      return url('prod');
  }
};

const toGraphQLEndpoint = () => {
  const apiEnv = toApiEnv();

  const graphqlTld = toMatchingTld(
    typeof window === 'undefined' ? '' : window.location.hostname,
  );

  const baseUrl = (env: PresenceEnv) =>
    `https://gql-graphql-gateway.${env}.k8s.onepeloton${graphqlTld}/graphql`;

  switch (apiEnv) {
    case ApiEnvs.Qa1:
      return baseUrl('stage');
    case ApiEnvs.Qa2:
      return baseUrl('test');
    case ApiEnvs.Prod:
    default:
      return baseUrl('prod');
  }
};

const presenceApiClient = axios.create({
  baseURL: toBaseURL(extLink.api, 'presence-leaderboard'),
  responseType: 'json',
  transformRequest: toDefaultRequestTransforms(),
  transformResponse: toDefaultResponseTransforms(),
});

const createPagesClient = () => {
  const pagesApiClient = axios.create({
    baseURL: toBaseURL(extLink.api, 'api-pages'),
    responseType: 'json',
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  pagesApiClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;
    config.headers['Peloton-Client-Details'] = BASE_64_ENCODED_DEVICE_TYPE;
    config.headers['Peloton-Client-Date'] = toNowTime().format();

    return config;
  });

  return pagesApiClient;
};

const createTooltipsClient = () => {
  const tooltipsApiClient = axios.create({
    baseURL: toBaseURL(extLink.api, 'tooltips'),
    responseType: 'json',
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  tooltipsApiClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;
    config.headers['Peloton-Client-Date'] = toNowTime().format();

    return config;
  });

  return tooltipsApiClient;
};

const createGuidanceClient = () => {
  const guidanceApiClient = axios.create({
    baseURL: toBaseURL(extLink.api, 'api-guidance'),
    responseType: 'json',
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  guidanceApiClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;
    config.headers['Peloton-Client-Date'] = toNowTime().format();

    return config;
  });

  return guidanceApiClient;
};

const createProgressTrackingClient = () => {
  const progressTrackingApiClient = axios.create({
    baseURL: toBaseURL(extLink.api, 'progress-tracking'),
    responseType: 'json',
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  progressTrackingApiClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;
    config.headers['Peloton-Client-Date'] = toNowTime().format();

    return config;
  });

  return progressTrackingApiClient;
};

const createContentPreferencesClient = () => {
  const contentPreferencesClient = axios.create({
    baseURL: toBaseURL(extLink.api, 'api-content-preferences'),
    responseType: 'json',
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  contentPreferencesClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;
    config.headers['Peloton-Client-Date'] = toNowTime().format();

    return config;
  });

  return contentPreferencesClient;
};

const createUserPreferencesClient = () => {
  const userPreferencesClient = axios.create({
    baseURL: toBaseURL(extLink.api, 'user-preferences'),
    responseType: 'json',
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  userPreferencesClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;
    config.headers['Peloton-Client-Date'] = toNowTime().format();

    return config;
  });

  return userPreferencesClient;
};

// Skip camelizing "__typename" key
export const toGraphQLResponseTransforms = (): AxiosTransformer[] => {
  return ([] as AxiosTransformer[]).concat(axios.defaults.transformResponse || [], obj =>
    camelizeKeys(obj, (key, convert) => {
      if (key === '__typename' || key === key.toUpperCase()) {
        return key;
      }
      return convert(key);
    }),
  );
};

const createGraphQLClient = () => {
  const graphQLApiClient = axios.create({
    baseURL: toGraphQLEndpoint(),
    transformResponse: toGraphQLResponseTransforms(),
  });

  graphQLApiClient.interceptors.request.use(function (config) {
    config.headers['Peloton-Platform'] = WEB_PELOTON_PLATFORM;
    config.headers['Peloton-Client-Date'] = toNowTime().format();

    return config;
  });

  return graphQLApiClient;
};

const pagesApiClient = createPagesClient();
const graphQLApiClient = createGraphQLClient();
const tooltipsApiClient = createTooltipsClient();
const guidanceApiClient = createGuidanceClient();
const progressTrackingApiClient = createProgressTrackingClient();
const contentPreferencesClient = createContentPreferencesClient();
const userPreferencesClient = createUserPreferencesClient();

const engageApi = new BaseAPI(apiClient);
const engageApiNoAuthHeader = new BaseAPI(apiClientNoAuthHeader);
const engageApiTextResponseType = new BaseAPI(apiClientTextResponseType);
const presenceApi = new BaseAPI(presenceApiClient);
const pagesApi = new BaseAPI(pagesApiClient);
const graphQlApi = new BaseAPI(graphQLApiClient);
const tooltipsApi = new BaseAPI(tooltipsApiClient);
const guidanceApi = new BaseAPI(guidanceApiClient);
const progressTrackingApi = new BaseAPI(progressTrackingApiClient);
const contentPreferencesApi = new BaseAPI(contentPreferencesClient);
const userPreferencesApi = new BaseAPI(userPreferencesClient);

export {
  engageApi,
  engageApiNoAuthHeader,
  engageApiTextResponseType,
  presenceApi,
  pagesApi,
  graphQlApi,
  tooltipsApi,
  guidanceApi,
  progressTrackingApi,
  contentPreferencesApi,
  userPreferencesApi,
};
