import React from 'react';
import ChallengesGatingModal from '../modals/ChallengesGatingModal';
import ExclusiveUpgradeModal from '../modals/ExclusiveUpgradeModal';
import NoQuotaAvailableModal from '../modals/NoQuotaAvailableModal';
import ProgramsGatingModal from '../modals/ProgramsGatingModal';
import QuotaAvailableModal from '../modals/QuotaAvailableModal';
import QuotaReminderModal from '../modals/QuotaReminderModal';
import UpgradeModal from '../modals/UpgradeModal';
import type { Modal } from '../models';
import { useTierContext } from '../TierProvider';

export const TieringModalViewController: React.FC<React.PropsWithChildren<{}>> = () => {
  const { modal, setModal } = useTierContext();
  const modalTypeViewController = (
    modalView: Modal,
    setModalView: React.Dispatch<React.SetStateAction<Modal>>,
  ) => {
    switch (modalView.modalType) {
      case 'quota_available_modal':
        return <QuotaAvailableModal modal={modalView} setModal={setModalView} />;
      case 'no_quota_available_modal':
        return <NoQuotaAvailableModal modal={modalView} setModal={setModalView} />;
      case 'upgrade_modal':
        return <UpgradeModal modal={modalView} setModal={setModalView} />;
      case 'quota_reminder_modal':
        return <QuotaReminderModal modal={modalView} setModal={setModalView} />;
      case 'exclusive_upgrade_modal':
        return <ExclusiveUpgradeModal modal={modalView} setModal={setModalView} />;
      case 'programs_gating_modal':
        return <ProgramsGatingModal modal={modalView} setModal={setModalView} />;
      case 'challenges_gating_modal':
        return <ChallengesGatingModal modal={modalView} setModal={setModalView} />;

      default:
        return null;
    }
  };

  return modalTypeViewController(modal, setModal);
};
