import type { Location } from 'history';
import { parseSearch } from '@peloton/redux';
import { BrowseCategorySlug } from '@engage/browse-categories';
import { toScheduleCategoryRoute } from '@members/schedule/urls';

export const SCHEDULE_LEGACY_ROUTE = '/schedule/legacy';

export const toScheduleRouteFromLegacy = (location: Location) => {
  const browseCategory = toBrowseCategory(location);

  return toScheduleCategoryRoute(browseCategory);
};

const toBrowseCategory = (location: Location): BrowseCategorySlug => {
  const params = parseSearch(location);
  const pathContainsCategory = (category: BrowseCategorySlug) =>
    location.pathname.split('/').includes(category);

  const category = Object.keys(BrowseCategorySlug).find(
    (key: BrowseCategorySlug) =>
      pathContainsCategory(BrowseCategorySlug[key]) ||
      BrowseCategorySlug[key] === params?.category,
  );

  return category ? BrowseCategorySlug[category] : BrowseCategorySlug.Cycling;
};
