import useSWR from 'swr';
import type { SWRResponse, BareFetcher, Key, SWRConfiguration } from 'swr/dist/types';
import { useOauth } from '@peloton/auth';

type DecoratedConfig = {
  shouldWaitForAuth?: boolean;
  shouldFetch?: boolean;
};

const determineIfShouldFetch = (
  isAuthenticated: boolean,
  decoratedConfig?: DecoratedConfig,
) => {
  if (decoratedConfig?.shouldFetch === false) {
    return false;
  }

  if (decoratedConfig?.shouldWaitForAuth !== false && !isAuthenticated) {
    return false;
  }

  return true;
};

/**
 * @description
 * Wraps useSWR to create additional functionality
 * For example, optionally waiting for authentication before fetching
 * The first three parameters here are generally created by the toFetcher function
 * @param key a function or string which transforms the parameters of the operation into a cache key
 * @param fetcher a fetcher function (see zeit/swr for a definition)
 * @param config optional config (see SWR docs)
 * @param decoratedConfig optional custom config used by the decorator
 */
export const useDecoratedSWR = <Data extends any, Error extends any>(
  key: Key,
  fetcher: BareFetcher<Data>,
  config?: SWRConfiguration<Data, Error>,
  decoratedConfig?: DecoratedConfig,
): SWRResponse<Data | null, Error> => {
  const { isAuthenticated } = useOauth();
  const shouldFetch = determineIfShouldFetch(isAuthenticated, decoratedConfig);

  return useSWR(shouldFetch ? key : null, fetcher, config) as SWRResponse<
    Data | null,
    Error
  >;
};
