import { replace } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { all, fork, call, getContext, put, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { toWorkoutHistoryPath } from '@engage/overview';
import type { DeleteWorkoutAction } from '@engage/workouts';
import {
  workoutsSaga as sharedWorkoutSaga,
  deleteWorkoutSaga as sharedDeleteWorkoutSaga,
  WorkoutActionType,
} from '@engage/workouts';

const ANALYTICS_SOURCE = 'Workout Details';

export const deleteWorkoutSaga = function* (client: Client, action: DeleteWorkoutAction) {
  yield call(sharedDeleteWorkoutSaga, client, action.payload.id, ANALYTICS_SOURCE);
  yield put(replace(toWorkoutHistoryPath()));
};

const workoutsWatcherSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(WorkoutActionType.DeleteRequest, deleteWorkoutSaga, client);
};

export default function* () {
  yield all([fork(workoutsWatcherSaga), fork(sharedWorkoutSaga)]);
}
