import { prop, compose } from 'ramda';
import type { RoutingState } from '@peloton/redux';
import { getQueryParams } from '@peloton/redux';

export const SHOW_MESSAGE_PARAM = 'showMessage';
export const EMAIL_PARAM = 'email';
export const REDIRECT_ACTION_PARAM = 'redirect_action';

export const getMessageFromLocation = compose<
  RoutingState,
  Record<string, string>,
  string
>(prop(SHOW_MESSAGE_PARAM), getQueryParams);

export const getEmailFromLocation = compose<RoutingState, Record<string, string>, string>(
  prop(EMAIL_PARAM),
  getQueryParams,
);

export const getRedirectActionFromLocation = compose<
  RoutingState,
  Record<string, string>,
  string
>(prop(REDIRECT_ACTION_PARAM), getQueryParams);
