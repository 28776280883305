import ttiPolyfill from 'tti-polyfill';
import { isNil } from '@peloton/helpers/isNil';
import { browserProvider } from '@peloton/newrelic';

ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {
  if (isNil(tti)) {
    return;
  }

  const actionReporter = browserProvider.getActionReporter();
  actionReporter('load-performance-metrics', { timeToInteractive: tti });
});
