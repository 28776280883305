import React from 'react';
import { browserProvider } from '@peloton/newrelic/browser-provider';
import { updateContractAgreements as updateContractService } from '@engage/api-v2';
import { logError } from '@engage/error-reporting/logError';
import type { ContractAgreement } from '@engage/members';
import { engageApi } from '@members/data-fetch';

export const useUpdatePolicyAcknowledgements = () => {
  const [hasSentAcknowledgement, setHasSentAcknowledgement] = React.useState(false);
  const actionReporter = browserProvider.getActionReporter();

  return React.useCallback(
    async (memberId: string, unacknowledgedContracts: ContractAgreement[]) => {
      if (!hasSentAcknowledgement) {
        setHasSentAcknowledgement(true);
        const unacknowledgedContractTypes = unacknowledgedContracts.map(c => ({
          contractId: c.contractId,
        }));
        try {
          await updateContractService(engageApi, memberId, {
            contractAgreements: unacknowledgedContractTypes,
            modalDisplayed: 'acknowledge',
          });
          actionReporter('Acknowledged Policies Successfully', { memberId });
        } catch (e) {
          actionReporter('Acknowledged Policies Failed', { memberId, error: e.message });
          logError(e, 'useUpdatePolicyAcknowledgements');
        }
      }
    },
    [hasSentAcknowledgement],
  );
};
