import type { Reducer } from 'redux';
import { combineReducers } from 'redux';
import { sharedReducers, VIDEO_REDUCER_NAMESPACE } from '@engage/video';
import type { toggleFullscreen } from './sagas/sagaActions';
import { VideoSagaActionTypes } from './sagas/sagaActions';

export const tabBackgroundedReducer: Reducer<boolean> = (
  state: boolean = false,
  action: TabBackgroundedAction,
) => {
  switch (action.type) {
    case VideoActions.TabBackgrounded:
      return true;
    default:
      return state;
  }
};

export const isFullscreenReducer: Reducer<boolean> = (
  state: boolean = false,
  action: ToggleFullscreenAction,
) => {
  switch (action.type) {
    case VideoSagaActionTypes.ToggleFullscreen:
      return action.payload.isFullscreen;
    default:
      return state;
  }
};

export const tabBackgrounded = () => ({
  type: VideoActions.TabBackgrounded,
});

enum VideoActions {
  TabBackgrounded = '/members/pg-video/TAB_BACKGROUNDED',
}

type TabBackgroundedAction = ReturnType<typeof tabBackgrounded>;
type ToggleFullscreenAction = ReturnType<typeof toggleFullscreen>;

export const getTabBackgrounded = (state: VideoPageSelectorState) =>
  state[VIDEO_REDUCER_NAMESPACE].tabBackgrounded;

export const getIsFullscreen = (state: VideoPageSelectorState) =>
  state[VIDEO_REDUCER_NAMESPACE].isFullscreen;

export type ReducerState = {
  isFullscreen: boolean;
  tabBackgrounded: boolean;
};

type VideoPageSelectorState = {
  [VIDEO_REDUCER_NAMESPACE]: ReducerState;
};

export default combineReducers({
  ...sharedReducers,
  tabBackgrounded: tabBackgroundedReducer,
  isFullscreen: isFullscreenReducer,
});
