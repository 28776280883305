import type { AchievementTemplate } from '@engage/achievement-template';
import type {
  MuscleGroupScoreResponse,
  Ride,
  Workout,
  MovementTrackerData,
} from '../generated';
import { getWorkout } from '../generated';
import type { AxiosPromise } from '../generated/core/request';

export const workoutDetailsWithMuscleGroupScore = (
  ...args: any[]
): AxiosPromise<
  Workout & {
    achievementTemplates: AchievementTemplate[];
    ride: Ride & {
      muscleGroupScore?: MuscleGroupScoreResponse;
    };
    movementTrackerData?: MovementTrackerData;
    movementTrackerTier: string;
  }
> => getWorkout.apply(this, args);
