import { not, isNil, isEmpty } from 'ramda';
import type { Fetcher, SWRConfiguration, Key } from 'swr/dist/types';
import type { Unpacked, Undefinable, ReRequire } from '@peloton/types';

const allPresent = <T extends Undefinable<object>>(params: T): params is ReRequire<T> =>
  Object.values(params).every(value => not(isNil(value)) && not(isEmpty(value)));

/**
 * @description
 * The most important function in this package.
 * It provides a unified interface for the creation of "Fetchers"
 * See README for more.
 * @param toKey a function which transforms the parameters of the operation into a cache key
 * @param toFetcherFn a function which returns a `fetcherFn` (see zeit/swr for a definition)
 * @param config optional config that will get mixed into the tuple
 */
export const toFetcher = <
  RequiredParams extends object,
  OptionalParams extends object,
  K extends Key,
  Data extends any,
  Z extends NonNullable<Fetcher<Data>>
>(
  toKey: (r: RequiredParams, o: Partial<OptionalParams>) => K,
  toFetcherFn: (r: RequiredParams, o: Partial<OptionalParams>) => Z,
  config?: SWRConfiguration<Unpacked<ReturnType<Z>>, any, Z>,
) => {
  return (
    req: Undefinable<RequiredParams>,
    opt: Partial<OptionalParams> = {},
    c?: typeof config,
  ): Readonly<[K, Z, typeof config]> => {
    const mergedConfig = { ...config, ...c };
    if (allPresent(req)) {
      return [toKey(req, opt), toFetcherFn(req, opt), mergedConfig] as const;
    } else {
      // returning a falsy value for the key will cause `swr` to defer execution
      return [
        null as K,
        toFetcherFn(req as ReRequire<RequiredParams>, opt),
        mergedConfig,
      ] as const;
    }
  };
};
