import { HOMESCREEN_ROUTE } from '@members/pg-homescreen/Routes';
import {
  WELCOME_PATH,
  PASSWORD_PATH,
  USERNAME_PATH,
  BIRTHDATE_PATH,
  LOCATION_PATH,
  PROFILE_IMAGE_PATH,
  COLLECT_PRODUCT_INTEREST_PATH,
  DOWNLOAD_APP_PATH_PREFIX,
  BENEFITS_WELCOME_PATH,
  QUIZ_PATH,
  PREFERENCES_PATH,
} from './urls';

const KEY = 'members/attemptedRoute';

// don't allow some paths to be returned
// in order to prevent infinite loop
const disallowedPaths = [
  WELCOME_PATH,
  PASSWORD_PATH,
  USERNAME_PATH,
  BIRTHDATE_PATH,
  LOCATION_PATH,
  PROFILE_IMAGE_PATH,
  COLLECT_PRODUCT_INTEREST_PATH,
  DOWNLOAD_APP_PATH_PREFIX,
  BENEFITS_WELCOME_PATH,
  QUIZ_PATH,
  PREFERENCES_PATH,
];

// using sessionStorage because this is not needed to persist longer than a single window session
export const persistAttemptedRoute = () => {
  if (!!window && !disallowedPaths.some(p => p.includes(window.location.pathname))) {
    const attemptedRoute = `${window.location.pathname}${window.location.search}`;

    window.sessionStorage.setItem(KEY, attemptedRoute);
  }
};

// this function will return the persisted route
// if no persisted route, use the provided default or go to home screen
export const getPersistedAttemptedRoute = (defaultRoute?: string) => {
  if (!!window) {
    const persistedAttemptedRoute =
      window.sessionStorage.getItem(KEY) ?? defaultRoute ?? HOMESCREEN_ROUTE;

    return persistedAttemptedRoute;
  }

  return defaultRoute ?? HOMESCREEN_ROUTE;
};
