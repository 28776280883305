export const TAGS_MODAL_NAME = 'tagsModal';

export const TAGS_VIEW_PARAM = 'tagsView';
export const TAG_NAME_PARAM = 'tagName';
export const TAG_BROWSE_CATEGORY_PARAM = 'category';

export enum TagModalView {
  MyTags = 'myTags',
  OtherMembersTags = 'otherMembersTags',
  BrowseTags = 'browseTags',
  SearchAndCreate = 'searchAndCreate',
  TagDetails = 'tagDetails',
  ReplaceTag = 'replaceTag',
  ConfirmHideTag = 'confirmHideTag',
}
