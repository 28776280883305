import { isNil, propOr } from 'ramda';
import { getMember } from '@engage/members/selectors';
import { useReduxState } from '@engage/redux';
import { isOverviewLoaded } from './redux/overview/overview';
import { getMemberBasicInfo, isFullBasicInfo } from './selectors';
import { getViewedMemberUsernameOrIdFromPath } from './urls';

export const useMemberId = (): string => {
  const memberIdOrUsername = getViewedMemberUsernameOrIdFromPath(location.pathname);
  const member = useReduxState(getMember, memberIdOrUsername);
  return propOr('', 'id', member);
};

export const useCanViewProfile = (memberId: string): boolean => {
  const basicInfo = useReduxState(getMemberBasicInfo, memberId);
  return !isNil(basicInfo) && isFullBasicInfo(basicInfo);
};

export const useIsPrivateProfile = (memberId: string): boolean => {
  const basicInfo = useReduxState(getMemberBasicInfo, memberId);
  return !isNil(basicInfo) && !isFullBasicInfo(basicInfo);
};

export const useIsMemberLoaded = (memberId: string): boolean => {
  const isPrivate = useIsPrivateProfile(memberId);
  const isLoaded = useReduxState(isOverviewLoaded, memberId);
  return isPrivate || isLoaded;
};
