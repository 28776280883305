import type { Reducer } from 'redux';

export const facebookModalReducer: Reducer<FacebookModalReducerState> = (
  state = {},
  action: FacebookModalUpdateAction,
) => {
  switch (action.type) {
    case FacebookModalActionTypes.Update:
      return {
        modalState: action.payload.modalState,
      };
    default:
      return state;
  }
};

export const updateFacebookModalState = (modalState: FacebookModalState) => ({
  type: FacebookModalActionTypes.Update,
  payload: { modalState },
});

export const getFacebookModalState = (state: FacebookModalSelectorState) =>
  state.facebookModal.modalState;

enum FacebookModalActionTypes {
  Update = 'pelo/facebookModal/update',
}

export type FacebookModalSelectorState = {
  facebookModal: FacebookModalReducerState;
};

export type FacebookModalUpdateAction = {
  type: FacebookModalActionTypes.Update;
  payload: { modalState: FacebookModalState };
};

export enum FacebookModalState {
  ConnectPrompt = 'ConnectPrompt',
  FBFriends = 'FBFriends',
  PermissionError = 'PermissionError',
  Error = 'Error',
}

export const FACEBOOK_MODAL_NAME = 'findFriends';

type FacebookModalReducerState = {
  modalState?: FacebookModalState;
};
