import { isDefined } from '@peloton/types';
import type { Result } from '@engage/result';
import { ok, error } from '@engage/result';
import type { CastSDK } from '../types';

/**
 * Safely check for CastSDK without needing to coerce Window types
 */
export const getCast = (): Result<CastSDK, undefined> =>
  isDefined(window?.cast) ? ok(window.cast) : error(undefined);
