import { css } from 'styled-components';
import { spaces } from '@engage/styles';
import { fontWeights } from '../shared';

// title
export const title14 = css`
  font-size: 14px;
  line-height: ${spaces.xLarge}px;
  font-weight: ${fontWeights.xxxBold};
  letter-spacing: 0;
`;

export const title18 = css`
  font-size: 18px;
  line-height: ${spaces.xxLarge}px;
  font-weight: ${fontWeights.xxxxBold};
  letter-spacing: 0;
`;

export const title20 = css`
  font-size: ${spaces.large}px;
  line-height: ${spaces.xLarge}px;
  font-weight: ${fontWeights.xxxBold};
  letter-spacing: 0;
`;

export const title20Bold = css`
  ${title20}
  font-weight: 900;
`;

export const title20Italic = css`
  ${title20Bold}
  font-style: italic;
`;

export const title24 = css`
  font-size: ${spaces.xLarge}px;
  line-height: 30px;
  font-weight: ${fontWeights.xxxBold};
  letter-spacing: 0;
`;

export const title24Bold = css`
  ${title24}
  font-weight: 900;
`;

export const title24Italic = css`
  font-style: italic;
  ${title24Bold}
`;

export const title28 = css`
  font-size: ${spaces.xxLarge}px;
  line-height: 36px;
  font-weight: ${fontWeights.xxxBold};
  letter-spacing: 0;
`;

export const title28Bold = css`
  ${title28}
  font-weight: 900;
`;

export const title28Italic = css`
  font-style: italic;
  ${title28Bold}
`;

export const title32 = css`
  font-size: ${spaces.xxxLarge}px;
  line-height: ${spaces.huge}px;
  font-weight: ${fontWeights.xxxBold};
  letter-spacing: 0;
`;

export const title32Bold = css`
  ${title32}
  font-weight: 600;
`;

export const title32Italic = css`
  font-style: italic;
  ${title32Bold}
`;

export const title36 = css`
  font-size: 36px;
  line-height: ${spaces.huge}px;
  font-weight: ${fontWeights.xxxBold};
  letter-spacing: 0;
`;

export const title36Bold = css`
  ${title36}
  font-weight: 900;
`;

export const title36Italic = css`
  font-style: italic;
  ${title36Bold}
`;

export const title40 = css`
  font-size: ${spaces.huge}px;
  line-height: 44px;
  font-weight: ${fontWeights.xxxBold};
  letter-spacing: 0;
`;

export const title40Bold = css`
  ${title40}
  font-weight: 900;
`;

export const title40Italic = css`
  font-style: italic;
  ${title40Bold}
`;
