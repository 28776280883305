import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import type { KeyedMutator } from 'swr';
import { getTLD } from '@peloton/external-links';
import { browserProvider } from '@peloton/newrelic/browser-provider';
import { media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { slate1, slate2, slate3, superRed, black, divider1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxAction, useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { link1, h8, body16, body14, label14, fontWeights } from '@engage/typography';
import { GenericBirthdayInput } from '@members/age-gating';
import {
  BirthdateErrorsContext,
  BirthdateErrorsProvider,
} from '@members/age-gating/BirthdateErrorsContext';
import DeclineModal from '@members/age-gating/DeclineModal';
import { useLogout } from '@members/auth/OauthProvider';
import { Button1Small, Button1Large } from '@members/buttons';
import {
  AcceptTermsCopy,
  AcceptTerms as AT,
  AccountSetupCopy,
  AccountSetup,
  useAcceptTerms,
} from '@members/copy';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import {
  viewedUpdatedTerms,
  tappedTermsLink,
  agreedToTerms,
  tappedOnepelotonLink,
} from './analytics';
import { isUnaccepted } from './selectors';
import type { Contract } from './TermButton';
import { TermButton } from './TermButton';
import updateContractAgreements from './updateContractAgreements';

type Props = {
  contracts: Contract[];
  onePelotonUrl: string;
  privacyUrl: string;
  membershipTerms: string;
  onAccept: () => void;
  memberId: string;
  showAgeGating: boolean;
  setParentIsOpen: (value: boolean) => void;
  updateOldEnoughCache: KeyedMutator<{
    oldEnough?: boolean | undefined;
    errorMessage?: string | undefined;
  }>;
  setDisplayParent?: (value: boolean) => void;
};

export const AcceptTerms: React.FC<React.PropsWithChildren<Props>> = ({
  contracts,
  onePelotonUrl,
  privacyUrl,
  membershipTerms,
  onAccept,
  memberId,
  showAgeGating,
  setParentIsOpen,
  updateOldEnoughCache,
  setDisplayParent,
}) => {
  const enablePrivacyAcknowledgement = useFeatureToggle(
    Feature.PrivacyPolicyAcknowledgement,
  );
  const [currentlyShowingAgeGating, setCurrentlyShowingAgeGating] = React.useState(
    contracts.length === 0 && showAgeGating,
  );
  const actionReporter = browserProvider.getActionReporter();
  const trackAnalytics = useReduxAction(track);
  const tld = useReduxState(getTLD);
  const onTermButtonClick = React.useCallback(
    (contract: Contract) => () =>
      trackAnalytics(tappedTermsLink(contract.type, contract.id)),
    [],
  );
  const continueCopy = useAcceptTerms(AT.Continue);
  const [isOpen, setIsOpen] = useState(false);

  const toTermButton: React.FC<React.PropsWithChildren<Contract>> = contract => (
    <TermButton
      key={contract.id}
      {...contract}
      handleClick={onTermButtonClick(contract)}
    />
  );
  const contractIds = contracts.map(contract => contract.id);
  const contractTypes = contracts.map(contract => contract.type);
  const TermButtons = contracts.map(toTermButton);

  React.useEffect(() => {
    trackAnalytics(viewedUpdatedTerms(contractTypes, contractIds));
  }, []);

  const isAcknowledgeAndAccept =
    enablePrivacyAcknowledgement &&
    contracts.some(c => c.requiredMemberAction === 'acknowledge_and_acceptance');

  const onContinueClick = React.useCallback(() => {
    actionReporter('Agreed to Terms', { memberId });
    onAccept();
    if (!showAgeGating) {
      setParentIsOpen(false);
    }
    trackAnalytics(agreedToTerms(contractTypes, contractIds));

    updateContractAgreements(
      memberId,
      contractIds,
      enablePrivacyAcknowledgement
        ? isAcknowledgeAndAccept
          ? 'acknowledge_and_acceptance'
          : 'acceptance'
        : undefined,
    );
  }, [memberId, contractIds, contractTypes, actionReporter, trackAnalytics, onAccept]);

  const onOnepelotonLinkClick = React.useCallback(() => {
    trackAnalytics(tappedOnepelotonLink());
  }, []);

  const pelotonUrl = `onepeloton${tld}`;
  const hideTempShowAcceptTerms = contracts.some(c => isUnaccepted(c));
  const isNewUser = false;
  const logout = useLogout();

  React.useEffect(() => {
    if (setDisplayParent) {
      isOpen ? setDisplayParent(false) : setDisplayParent(true);
    }
  }, [isOpen]);

  if (currentlyShowingAgeGating) {
    return (
      <Container data-test-id="birthdateModal">
        <BirthdayHeader data-test-id="birthdayHeader">
          <AccountSetupCopy id={AccountSetup.BirthdayHeader} />
        </BirthdayHeader>
        <Divider />
        <BirthdaySubHeader>
          <AccountSetupCopy id={AccountSetup.BirthdaySubHeader} />
        </BirthdaySubHeader>
        <BirthdateErrorsProvider>
          <BirthdateContainer>
            <GenericBirthdayInput
              isNewUser={isNewUser}
              handleNextStep={() => {
                updateOldEnoughCache();
                setParentIsOpen(false);
              }}
              submitButton={
                <StyledSubmitButton data-test-id="continueButton" type="submit">
                  {continueCopy}
                </StyledSubmitButton>
              }
            />
            <DeclineButton setIsOpen={setIsOpen} isNewUser={isNewUser} />
            <DeclineModal isNewUser={isNewUser} isOpen={isOpen} setIsOpen={setIsOpen} />
          </BirthdateContainer>
        </BirthdateErrorsProvider>
      </Container>
    );
  }

  return (
    <Container data-test-id="acceptTermsModal">
      <HeaderContainer data-test-id="updatedTermHeader">
        <AcceptTermsCopy
          id={
            hideTempShowAcceptTerms
              ? isAcknowledgeAndAccept
                ? AT.AcknowledgeAndAccept
                : AT.UpdatedTerms
              : AT.UpdatedMembershipTerms
          }
        />
      </HeaderContainer>
      <Divider />
      <AcceptTermsText data-test-id="acceptTermsText">
        {hideTempShowAcceptTerms ? (
          <AcceptTermsCopy
            id={isAcknowledgeAndAccept ? AT.UpdateToTermsAndPolicies : AT.UpdateToTerms}
            values={{
              privacyPoliciesUrl: (
                <Link href={privacyUrl}>
                  <AcceptTermsCopy id={AT.PrivacyPolicy} />
                </Link>
              ),
              termsOfUseLink: (
                <Link href={membershipTerms}>
                  <AcceptTermsCopy id={AT.TermsOfService} />
                </Link>
              ),
              membershipTermsLink: (
                <Link href={membershipTerms}>
                  <AcceptTermsCopy id={AT.MembershipTerms} />
                </Link>
              ),
            }}
          />
        ) : (
          <AcceptTermsCopy
            id={AT.AgreeToUpdatedMembershipTerms}
            values={{
              link: (
                <Link href={onePelotonUrl} handleClick={onOnepelotonLinkClick}>
                  {pelotonUrl}
                </Link>
              ),
            }}
          />
        )}
      </AcceptTermsText>
      <LinkContainer hideTempShowAcceptTerms={hideTempShowAcceptTerms}>
        {TermButtons}
      </LinkContainer>
      <LinkTextContainer>
        <If condition={hideTempShowAcceptTerms}>
          <AcceptTermsCopy
            id={AT.FindTerms}
            values={{
              link: (
                <Link href={onePelotonUrl} handleClick={onOnepelotonLinkClick}>
                  {pelotonUrl}
                </Link>
              ),
            }}
          />
        </If>
      </LinkTextContainer>
      <If condition={!hideTempShowAcceptTerms}>
        <Spacer />
      </If>
      <AcceptTermsButton
        data-test-id="acceptTermsButton"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();

          if (showAgeGating) {
            setCurrentlyShowingAgeGating(true);
          }

          onContinueClick();
        }}
      >
        <ButtonText>
          <AcceptTermsCopy
            id={isAcknowledgeAndAccept ? AT.AcknowledgeAndAccept : AT.Agree}
          />
        </ButtonText>
      </AcceptTermsButton>
      <LogoutButton
        data-test-id="logoutButton"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          logout();
        }}
      >
        <AcceptTermsCopy id={showAgeGating ? AT.Decline : AT.Logout} />
      </LogoutButton>
    </Container>
  );
};

interface DeclineButtonProps {
  setIsOpen: (val: boolean) => void;
  isNewUser: boolean;
}

const DeclineButton: React.FC<React.PropsWithChildren<DeclineButtonProps>> = ({
  setIsOpen,
  isNewUser,
}) => {
  const declineCopy = useAcceptTerms(AT.Decline);
  const { errorList, errorCount } = useContext(BirthdateErrorsContext);
  const trackAnalytics = useReduxAction(track);

  return (
    <StyledDeclineButton
      data-test-id="declineButton"
      onClick={() => {
        trackAnalytics({
          event: 'Tapped Decline Age Confirmation',
          ['Number of Errors']: errorCount,
          ['Errors Received']: errorList,
          ['Is New User']: isNewUser,
        });
        setIsOpen(true);
      }}
    >
      <StyledDeclineCopy>{declineCopy}</StyledDeclineCopy>
    </StyledDeclineButton>
  );
};

type LinkProps = {
  href: string;
  handleClick?(): void;
};

const BirthdateContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BirthdayHeader = styled.div`
  ${h8}
  color: ${black};
  margin-bottom: 14px;
`;

const BirthdaySubHeader = styled.div`
  ${label14}
  color: ${black};
  text-align: center;
  margin-bottom: ${spaces.xxxLarge}px;
  font-weight: ${fontWeights.xBold};
`;

const Spacer = styled.div`
  height: 156px;
`;

const StyledLink = styled.a`
  font-size: 13px;
  ${link1}
  color: ${slate1};
`;

const LinkWrapper = styled.div`
  display: inline-block;
  text-decoration: underline;
`;

const Link: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <LinkWrapper>
    <StyledLink
      href={props.href}
      target="_blank"
      rel="noopener"
      onClick={props.handleClick}
    >
      {props.children}
    </StyledLink>
  </LinkWrapper>
);

const LinkTextContainer = styled.div`
  ${body14}
  color: ${slate1};
  margin-top: ${spaces.medium}px;
  margin-bottom: ${spaces.medium}px;
  text-align: center;

  ${media.tablet`
    margin-top: ${spaces.xLarge}px;
  `}
`;

const HeaderContainer = styled.span`
  ${h8}
  color: ${slate3};
  text-align: center;
  margin-bottom: 14px;
`;

const AcceptTermsText = styled.span`
  ${body14};
  color: ${slate2};
  text-align: center;
  margin-bottom: ${spaces.xLarge}px;
  ${media.tablet`
    ${body16}
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkContainer = styled.div<{ hideTempShowAcceptTerms: boolean }>`
  width: 100%;
  min-height: ${({ hideTempShowAcceptTerms }) =>
    hideTempShowAcceptTerms ? '202px' : '100%'};
`;

const ButtonText = styled.span`
  ${label14};
  padding-bottom: ${spaces.tiny}px;
`;

const buttonStyles = css`
  height: 32px;
  width: 110px;
  ${media.tablet`
    width: 120px;
  `}
`;

const AcceptTermsButton = styled(Button1Small)`
  ${buttonStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 180px;
  height: 40px;
  ${media.tablet`
    width: 260px;
    height: 50px;
  `}
`;

const LogoutButton = styled.button`
  ${label14};
  color: ${superRed};
  margin-top: ${spaces.xLarge}px;
`;

const StyledSubmitButton = styled(Button1Large)`
  margin-top: ${spaces.xxHuge}px;
  width: 280px;

  ${media.desktopLarge`
    width: 320px;
    margin-top: ${spaces.xHuge}px;
  `}
`;

const StyledDeclineButton = styled(Button1Large)`
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  padding: 0px;
  margin-top: ${spaces.xLarge}px;
  background: rgba(255, 255, 255, 0.0001);
  color: ${slate1};
`;

const StyledDeclineCopy = styled.p`
  text-transform: capitalize;
`;

const Divider = styled.div`
  background-color: ${divider1};
  height: 1px;
  width: 100vw;
  margin-bottom: ${spaces.xLarge}px;
`;
