import { invertObj } from 'ramda';

const characterMap = {
  a: 'ȧ',
  A: 'Ȧ',
  b: 'ƀ',
  B: 'Ɓ',
  c: 'ƈ',
  C: 'Ƈ',
  d: 'ḓ',
  D: 'Ḓ',
  e: 'ḗ',
  E: 'Ḗ',
  f: 'ƒ',
  F: 'Ƒ',
  g: 'ɠ',
  G: 'Ɠ',
  h: 'ħ',
  H: 'Ħ',
  i: 'ī',
  I: 'Ī',
  j: 'ĵ',
  J: 'Ĵ',
  k: 'ķ',
  K: 'Ķ',
  l: 'ŀ',
  L: 'Ŀ',
  m: 'ḿ',
  M: 'Ḿ',
  n: 'ƞ',
  N: 'Ƞ',
  o: 'ǿ',
  O: 'Ǿ',
  p: 'ƥ',
  P: 'Ƥ',
  q: 'ɋ',
  Q: 'Ɋ',
  r: 'ř',
  R: 'Ř',
  s: 'ş',
  S: 'Ş',
  t: 'ŧ',
  T: 'Ŧ',
  v: 'ṽ',
  V: 'Ṽ',
  u: 'ŭ',
  U: 'Ŭ',
  w: 'ẇ',
  W: 'Ẇ',
  x: 'ẋ',
  X: 'Ẋ',
  y: 'ẏ',
  Y: 'Ẏ',
  z: 'ẑ',
  Z: 'Ẑ',
};

const reverseCharacterMap = invertObj(characterMap);

export const localizeString = (str: string) => {
  let pseudoLocalizedText = '';
  let wasChanged = false;

  for (const char of str) {
    if (characterMap[char]) {
      wasChanged = true;
      const letter = char.toLowerCase();

      if (
        letter === 'a' ||
        letter === 'e' ||
        letter === 'i' ||
        letter === 'o' ||
        letter === 'u' ||
        letter === 'y'
      ) {
        pseudoLocalizedText += characterMap[char] + characterMap[char];
      } else {
        pseudoLocalizedText += characterMap[char];
      }
    } else {
      pseudoLocalizedText += char;
    }
  }

  return wasChanged ? `[${pseudoLocalizedText}]` : pseudoLocalizedText;
};

export const unlocalizeString = (str: string) => {
  let originalText = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const letter = char.toLowerCase();

    if (reverseCharacterMap[char]) {
      originalText += reverseCharacterMap[char];

      if (
        letter === 'ȧ' ||
        letter === 'ḗ' ||
        letter === 'ī' ||
        letter === 'ǿ' ||
        letter === 'ŭ' ||
        letter === 'ẏ'
      ) {
        i++;
      }
    } else if (char !== '[' && char !== ']') {
      originalText += char;
    }
  }

  return originalText;
};
