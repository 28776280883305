import { normalize } from 'normalizr';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import type { Locale } from '@peloton/internationalize';
import type { DeviceType } from '@engage/workouts';
import { toInstructor } from './mappers';
import type { FitnessDisciplineSlug } from './models';
import {
  toClassTypeSchema,
  toDeviceTypeDisplayNamesSchema,
  toFitnessDisciplineSchema,
  toInstructorSchema,
  toEquipmentSchema,
  toLocaleSchema,
  toContentFocusLabelSchema,
} from './models';

export const fetchMetadata = (api: Client) =>
  api.get('/api/ride/metadata_mappings').then(pipeData(toMetadata));

const metadata = {
  classTypes: [toClassTypeSchema()],
  deviceTypeDisplayNames: [toDeviceTypeDisplayNamesSchema({ idAttribute: 'deviceType' })],
  equipment: [toEquipmentSchema()],
  fitnessDisciplines: [toFitnessDisciplineSchema()],
  instructors: [toInstructorSchema({ processStrategy: toInstructor })],
  locales: [toLocaleSchema()],
  contentFocusLabels: [toContentFocusLabelSchema()],
};

export const toMetadata = (apiMetadata: ApiMetadata) => normalize(apiMetadata, metadata);

export type ApiMetadata = {
  instructors: ApiInstructor[];
  fitnessDisciplines: ApiFitnessDiscipline[];
  classTypes: ApiClassType[];
  contentFocusLabels: ApiContentFocusLabel[];
  equipment: ApiEquipment[];
  deviceTypeDisplayNames: ApiDeviceTypeDisplayName[];
  locales: ApiLocale[];
};

type ApiContentFocusLabel = Record<string, string>;

type ApiFitnessDiscipline = {
  id: FitnessDisciplineSlug;
  name: string;
};

export type ApiInstructor = {
  aboutImageUrl: string;
  fitnessDisciplines: string[];
  id: string;
  imageUrl: string;
  isVisible: boolean;
  jumbotronUrlDark: string;
  name: string;
  username: string;
};

type ApiClassType = {
  id: string;
  displayName: string;
};

type ApiEquipment = {
  id: string;
  name: string;
  iconUrl: string;
  slug: string;
};

type ApiDeviceTypeDisplayName = {
  deviceName: string;
  deviceType: DeviceType;
};

type ApiLocale = {
  locale: Locale;
  displayName: string;
};
