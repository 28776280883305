import { useEffect, useState } from 'react';
import { Locale } from '@peloton/internationalize';
import type { PendingResult } from '@engage/result';
import { isPending, ok, pending } from '@engage/result';
import { useLocale } from '@members/localization/useLocale';
import { copyNamespaces } from '../config';
import type { CopyFile } from './models';

export type Result = PendingResult<CopyFile, Error>;
type State = Record<Locale, Result>;

/**
 * Merges multiple `CopyFile`'s into one `CopyFile`.
 *
 * @param copyFiles As generated by `packages/@members/copy/fetch.ts`
 */
export const mergeCopyFiles = (copyFiles: CopyFile[]): CopyFile =>
  copyFiles.reduce((previousValue, currentValue) => {
    return {
      ...previousValue,
      ...currentValue,
    };
  }, {} as CopyFile);

const enUS = ok(
  mergeCopyFiles(
    copyNamespaces().map(copyNamespace =>
      require(`../static/namespacedCopy/en-US.${copyNamespace}.generated.json`),
    ),
  ),
);

const DEFAULT_STATE: State = {
  [Locale.EnglishUnitedStates]: enUS,
  [Locale.EnglishCanada]: pending,
  [Locale.EnglishUnitedKingdom]: pending,
  [Locale.GermanGermany]: pending,
  [Locale.GermanAustria]: pending,
  [Locale.EnglishAustralia]: pending,
  [Locale.EnglishBeta]: pending,
  [Locale.SpanishMexico]: pending,
  [Locale.FrenchCanada]: pending,
};

const retry = (fn: any, attemptsLeft = 3, interval = 1000) =>
  new Promise((resolve, reject) => {
    attemptsLeft--;
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (attemptsLeft === 0) {
            reject(error);
            return;
          }
          retry(fn, attemptsLeft, interval).then(resolve, reject);
        }, interval);
      });
  });

const syntheticImport = (locale: Locale, simulateError = false) => {
  if (simulateError) {
    return Promise.reject(new Error('Simulated Error'));
  }

  const copyFilesPromise = copyNamespaces().map(
    copyNamespace =>
      import(
        /* webpackChunkName: "[request]" */
        `../static/namespacedCopy/${locale}.${copyNamespace}.generated.json`
      ),
  );

  return Promise.all(copyFilesPromise).then(copyFiles => mergeCopyFiles(copyFiles));
};

export const useCopyClient = (
  // eslint-disable-next-line react-hooks/rules-of-hooks
  locale: Locale = useLocale(),
  preloadedCopy: Partial<State> = {},
  simulateError = false,
): Result => {
  const [results, setResult] = useState<State>({ ...DEFAULT_STATE, ...preloadedCopy });

  useEffect(() => {
    if (isPending(results[locale])) {
      retry(() => syntheticImport(locale, simulateError))
        .then((file: CopyFile) => {
          setResult({
            ...results,
            [locale]: ok(file),
          });
        })
        .catch(() => {
          setResult({
            ...results,
            [locale]: enUS,
          });
        });
    }
  }, [locale]);

  return results[locale];
};
