import { useEffect } from 'react';
import { ApiErrors } from '@peloton/api';
import type { AuthClientErrors } from '@peloton/auth';
import {
  getIsUserLoading,
  getUser,
  getUserError,
  getUserErrorMessage,
  AccountForbiddenErrors,
} from '@peloton/auth';

import { isNil } from '@peloton/helpers/isNil';
import { useReduxAction, useReduxState } from '@engage/redux';
import { loadUser } from '@members/user-mapper';

export const useUserFetcher = () => {
  const load = useReduxAction(loadUser);
  const userLoaded = Boolean(useReduxState(getUser));
  const userIsLoading = useReduxState(getIsUserLoading);
  const isError = useReduxState(getUserError);
  const errorMessage = useReduxState(getUserErrorMessage);
  const ignorableErrors: AuthClientErrors[] = [
    AccountForbiddenErrors.AccountNotActive,
    AccountForbiddenErrors.AccountLocked,
    ApiErrors.UNAUTHORIZED,
  ];

  useEffect(() => {
    if (isError && !isNil(errorMessage) && !ignorableErrors.includes(errorMessage)) {
      throw new Error(errorMessage);
    }
  }, [isError, errorMessage]);

  return {
    isFetched: userLoaded || userIsLoading,
    load,
  };
};
