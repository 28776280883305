import React from 'react';
import ReactTooltip from 'react-tooltip';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  tooltipId: string;
  setIsExpanded(value: boolean): void;
  place?: 'bottom' | 'top' | 'right' | 'left' | undefined;
  getContent?: () => JSX.Element | string;
  globalEventOff?: string;
  event?: string;
  eventOff?: string;
};

export const Tooltip: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  tooltipId,
  setIsExpanded,
  place = 'bottom',
  children,
  getContent = () => children,
  globalEventOff = isTouchDevice ? 'click' : 'mouseup',
  event = isTouchDevice ? 'click' : 'mouseenter',
  eventOff = 'blur',
}) => {
  return (
    <ReactTooltip
      class="tooltip"
      effect="solid"
      place={place}
      id={tooltipId}
      getContent={getContent}
      event={event}
      eventOff={eventOff}
      globalEventOff={globalEventOff}
      afterHide={() => setIsExpanded(false)}
      afterShow={() => setIsExpanded(true)}
    />
  );
};

const isTouchDevice =
  typeof window !== 'undefined' &&
  ('ontouchstart' in window || navigator.maxTouchPoints > 0);
