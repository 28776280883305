import type { Locale, Language } from '@peloton/internationalize';
import { toLanguageFromLocale } from '@peloton/internationalize';
import { titleize } from '@peloton/text';
import type { ClassAnalyticsProperties } from '@engage/classes';
import type {
  OnDemandInstructorClass,
  ScenicClass,
} from '@members/graphql-swr/types.generated';
import type { StackedOnDemandClass } from '../Modal/ActiveState';

export const toStackedClassAnalyticsProperties = (
  stackedClass: OnDemandInstructorClass,
): ClassAnalyticsProperties => {
  const difficultyLevel = stackedClass.difficultyLevel?.slug;
  const startOffset = stackedClass.timeline?.startOffset;

  return {
    id: stackedClass.classId,
    title: stackedClass.title,
    scheduledStartTime: undefined,
    duration: stackedClass.duration,
    fitnessDiscipline: stackedClass.fitnessDiscipline?.slug,
    instructorName: stackedClass.instructor?.name,
    classType: stackedClass.classTypes.map(c => c.name).join(', '),
    contentFormat: titleize(stackedClass.contentFormat ?? 'scenic'),
    contentProvider: undefined,
    totalWorkouts: undefined,
    totalUserWorkouts: stackedClass.totalUserWorkouts,
    totalFollowingWorkouts: undefined,
    difficultyLevel: difficultyLevel ? titleize(difficultyLevel) : undefined,
    nativeLanguage: stackedClass.originLocale?.language,
    captionLanguages: stackedClass.captions?.locales.map(l =>
      toLanguageFromLocale(l.toLowerCase().replace('_', '-') as Locale),
    ) as Language[],
    classIntroLength: startOffset || null,
  };
};

export const toViewedStackedClassesAnalytics = (
  stackedClasses: StackedOnDemandClass[],
) => {
  const classAnalytics = stackedClasses.reduce(
    (
      acc: {
        ids: string[];
        fitnessDisciplines: string[];
        types: string[];
        lengths: number[];
      },
      klass,
    ) => {
      return {
        ids: acc.ids.concat(klass.classId),
        fitnessDisciplines: acc.fitnessDisciplines.concat(
          klass.__typename === 'OnDemandInstructorClass'
            ? (klass as OnDemandInstructorClass).fitnessDiscipline.displayName
            : (klass as ScenicClass).fitnessDiscipline.slug,
        ),
        types: acc.types.concat(klass.classTypes.map(c => c.name)),
        lengths: acc.lengths.concat(klass.duration),
      };
    },
    { ids: [], fitnessDisciplines: [], types: [], lengths: [] },
  );

  return {
    numberOfClassesInStack: stackedClasses.length,
    numberOfPlayableClasses: stackedClasses.filter(s => s.playableOnPlatform).length,
    '[Stacked Class IDs]': classAnalytics.ids,
    stackedClassFitnessDisciplines: classAnalytics.fitnessDisciplines.join(', '),
    stackedClassTypes: classAnalytics.types.join(', '),
    stackedClassLengths: classAnalytics.lengths.map(l => l / 60).join(', '),
  };
};
