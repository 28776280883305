import styled from 'styled-components';
import { media } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { header6Large } from '@engage/typography';
import { button1Styles } from '../buttons';

export const Message = styled.h1`
  ${header6Large};
  font-size: 18px;
  margin-top: ${spaces.xLarge}px;
  text-align: center;
  width: 280px;

  ${media.tabletLarge`
    font-size: 26px;
    width: 480px;
  `}
`;

export const DownloadCTA = styled.a`
  ${button1Styles};
  height: 50px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${spaces.xLarge}px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
