import { prop } from 'ramda';
import type { Time } from '@peloton/time';
import type {
  AchievementTemplate,
  WorkoutAchievement,
} from '@engage/achievement-template';
import type { FitnessDisciplineSlug } from '@engage/metadata/models';
import type { EffortZones } from './WorkoutPerformance';
// Nested types.

export enum DeviceType {
  BikeV1 = 'home_bike_v1',
  BikeV2 = 'home_bike_v2',
  StudioBike = 'studio_bike',
  IPhone = 'iPhone',
  IPad = 'iPad',
  Tread = 'aurora',
  HomeTread = 'home_tread',
  StudioTread = 'studio_tread',
  Web = 'web',
  Android = 'android',
  FireTV = 'fire_tv',
  FireTablet = 'fire_tablet',
  AndroidTV = 'android_tv',
}

// enum FitnessDiscipline {} // TODO: locate these values

export enum FtpSource {
  Manual = 'ftp_manual_source',
  Estimated = 'ftp_estimated_source',
  Workout = 'ftp_workout_source',
  Unknown = '',
}

export type FTPInfo = {
  /**
   * functional threshold power value -- 95% of 20 min max average output (watts)
   */
  ftp: number;
  /**
   * source of the ftp value
   */
  ftpSource: FtpSource;
  ftpWorkoutId: string;
};

export enum MetricsType {
  Cycling = 'cycling',
  Running = 'running',
  Uncaptured = '',
}

export enum WorkoutStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
}

export enum WorkoutType {
  Class = 'class',
  Freestyle = 'freestyle',
  Gym = 'gym',
}

// Top level types

type Identifiers = {
  id: string;
  pelotonId: string;
  userId: string;
};

type ClassDescription = {
  fitnessDiscipline: FitnessDisciplineSlug;
  name: string;
  status: WorkoutStatus;
  workoutType: WorkoutType;
};

type FreestyleDescription = ClassDescription & {
  title: string;
  instructorName?: string;
  imageUrl: string;
};

type DeviceInfo = {
  deviceType: DeviceType;
};

type PerformanceMetrics = {
  isTotalWorkPersonalRecord: boolean;
  metricsType: MetricsType;
  totalWork: number;
};

type DetailedPerformanceMetrics = {
  achievementTemplates: AchievementTemplate[];
  ftpInfo: FTPInfo;
};

type DetailedClassPerformanceMetrics = {
  isTotalWorkPersonalRecord: boolean;
  leaderboardRank: number;
  totalLeaderboardUsers: number;
};

type SocialInfo = {
  fitbitId: string;
  stravaId: string;
};

type TimeStamps = {
  deviceTimeCreatedAt: Time;
  endTime: Time;
  startTime: Time;
};

type SkipIntro = {
  isSkipIntroAvailable?: boolean;
};

type PauseInfo = {
  isPauseAvailable?: boolean;
  hasPaused?: boolean;
};

// Helper functions

export const isFreestyleWorkout = (workout: any): workout is FreestyleWorkout =>
  workout?.workoutType === WorkoutType.Freestyle ||
  workout?.workoutType === WorkoutType.Gym;

export const isClassWorkout = (workout: any): workout is ClassWorkout =>
  !isFreestyleWorkout(workout);

export const isDetailedWorkout = (workout: any): workout is DetailedWorkout =>
  prop('achievements', workout) && prop('ftpInfo', workout);

// Assembling models

type BaseWorkout = Identifiers &
  DeviceInfo &
  PerformanceMetrics &
  SocialInfo &
  EffortZones &
  TimeStamps &
  SkipIntro &
  PauseInfo;

export type ClassWorkout = BaseWorkout &
  ClassDescription & {
    classId: string;
    workoutType: WorkoutType.Class;
  };

export type FreestyleWorkout = BaseWorkout & {
  workoutType: WorkoutType.Freestyle;
  classId: string;
  title: string;
  gymTitle?: string;
  completedMovementGroupsCount?: number;
  totalMovementGroupsCount?: number;
} & FreestyleDescription;

export type Workout = ClassWorkout | FreestyleWorkout;

export type DetailedWorkout<T extends Workout = Workout> = T &
  DetailedPerformanceMetrics &
  (T extends ClassWorkout ? DetailedClassPerformanceMetrics : {});

export type EndedWorkout<T extends Workout = Workout> = T & {
  achievementTemplates?: WorkoutAchievement[];
};
