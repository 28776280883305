import { useProspectsRedirect } from '@prospects/guest-pass/hooks/useProspectsRedirect';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getIsUserLoading, useOauth } from '@peloton/auth';
import { toWWWLink, toHref, toExtLinkEnv } from '@peloton/external-links';
import { REDIRECT_URL_QUERY_PARAM } from '@peloton/external-links/urls';
import { toLocaleFromHostname } from '@peloton/internationalize';
import { getOauthFragment } from '@engage/auth/oauthFragment';
import { useReduxState } from '@engage/redux';
import { pending, ok, isOk } from '@engage/result';
import { useUtmParams } from '@members/analytics/helpers/hooks';
import { useGetSession } from '@members/auth/useGetSession';
import { useFeatureToggle, Feature } from '@members/feature-toggles';

export const toAppState = (redirectUrl: string) => ({
  [REDIRECT_URL_QUERY_PARAM]: btoa(redirectUrl),
});

export const useRequiresAuth = () => {
  const session = useGetSession();
  const isLoadingUser = useReduxState(getIsUserLoading);
  const history = useHistory();
  const { search } = useLocation();
  const isProspectsGuestPassEnabled = useFeatureToggle(
    Feature.IsProspectsGuestPassEnabled,
  );
  const prospectsRedirect = useProspectsRedirect(search, isProspectsGuestPassEnabled);
  const canRedirectToProspects =
    prospectsRedirect !== null && isProspectsGuestPassEnabled;

  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    loginWithRedirect,
    logout,
  } = useOauth();

  // user.id is the only nested user value shared across session/tokenized requests
  const hasFailedSessionCheck = isOk(session) && !session.value?.user?.id;
  const hasExpiredAuth = !isAuth0Loading && !isAuthenticated;
  const shouldReauthenticate = hasFailedSessionCheck || hasExpiredAuth;

  const utmParams = useUtmParams();

  useEffect(() => {
    if (shouldReauthenticate) {
      if (isAuthenticated) {
        logout(
          {
            logoutParams: {
              returnTo: `${window.origin}/login?redirectUrl=${btoa(
                window.location.pathname + window.location.search,
              )}`,
            },
          },
          true,
        );
      } else if (canRedirectToProspects) {
        window.location.href = `${toHref(toWWWLink(prospectsRedirect!), toExtLinkEnv())}`;
      } else {
        const url = window.location.pathname + window.location.search;

        loginWithRedirect({
          appState: toAppState(url),
          authorizationParams: {
            redirect_uri: `${window.location.origin}/callback`,
            ...utmParams,
          },
          // fragment is being used to dictate which login page to show
          fragment: JSON.stringify(
            getOauthFragment(history.location, '', toLocaleFromHostname()),
          ),
        });
      }
    }
  }, [isAuthenticated, loginWithRedirect, logout, shouldReauthenticate]);

  if (isLoadingUser || isAuth0Loading) {
    return pending;
  } else {
    return ok(isAuthenticated);
  }
};
