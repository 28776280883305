import axios from 'axios';
import {
  toDefaultRequestTransforms,
  toDefaultResponseTransforms,
} from '@peloton/api/client-transforms';
import { ApiEnvs, toExtLinkEnv, toHref, toApiLink } from '@peloton/external-links/models';
import { BaseAPI } from '@engage/api-v2';
import { toApiEnv } from '@engage/env/api';

const apiEnv =
  typeof window === 'undefined'
    ? ApiEnvs.Prod
    : toApiEnv(window.location.hostname, () => process.env.API_ENV ?? null);

const extLink = toExtLinkEnv({
  api: apiEnv,
});

// REMOVE THIS WHEN SWAGGER DOCS ARE UPDATED

const apiClient = axios.create({
  baseURL: toHref(toApiLink('/'), extLink),
  responseType: 'json',
  withCredentials: false,
  transformRequest: toDefaultRequestTransforms(),
  transformResponse: toDefaultResponseTransforms(),
});

type PresenceEnv = 'stage' | 'test' | 'prod';

const toPresenceBaseURL = (apiEnv1: ApiEnvs) => {
  const url = (env: PresenceEnv) =>
    `https://presence-leaderboard.${env}.k8s.onepeloton.com`;

  switch (apiEnv1) {
    case ApiEnvs.Qa1:
      return url('stage');
    case ApiEnvs.Qa2:
      return url('test');
    case ApiEnvs.Prod:
    default:
      return url('prod');
  }
};

const presenceApiClient = axios.create({
  baseURL: toPresenceBaseURL(apiEnv),
  responseType: 'json',
  withCredentials: false,
  transformRequest: toDefaultRequestTransforms(),
  transformResponse: toDefaultResponseTransforms(),
});

const toPagesBaseURL = () => {
  //TODO - make this accept all diff environments later

  return `https://api-pages.stage.k8s.onepeloton.com`;
};

const pagesApiClient = axios.create({
  baseURL: toPagesBaseURL(),
  responseType: 'json',
  withCredentials: false,
  transformRequest: toDefaultRequestTransforms(),
  transformResponse: toDefaultResponseTransforms(),
});
const engageApi = new BaseAPI(apiClient);
const presenceApi = new BaseAPI(presenceApiClient);
const pagesApi = new BaseAPI(pagesApiClient);

export { engageApi, presenceApi, pagesApi };
