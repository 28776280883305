import { toClientDetails } from '@peloton/analytics';
import type { ClassAnalyticsProperties } from '@engage/classes';
import { toClassAnalytics } from '@engage/classes';
import type { DetailedWorkout } from '@engage/workouts';
import { toAnalyticsWorkoutProperties } from '@engage/workouts';
import { toBaseProperties, toSource } from '@members/analytics';
import type { OnDemandInstructorClass } from '@members/graphql-swr/types.generated';
import type { StackedOnDemandClass } from '../Modal/ActiveState';
import {
  toViewedStackedClassesAnalytics,
  toStackedClassAnalyticsProperties,
} from './helpers';

export const ANALYTICS_SOURCE_STACKED_CLASSES = 'Stacked Classes';
export const ANALYTICS_SOURCE_STACKED_POST_CLASS = 'Post Class Stack Transition';

// This refers to the method in which a user adds a class on web. They do so in one
// of the following ways: 1) Clicking the stack icon in class details or 2) via hover
// method on thumbnail.
export const ANALYTICS_ADD_METHOD_CLASS_DETAILS = 'Class Details';

export enum Source {
  StackModal = 'Stack Modal',
  PostClass = 'Post Class',
  Notification = 'Notification',
  Navigation = 'Navigation',
  Home = 'Home',
  QuickActions = 'Feed Quick Actions',
}

export const trackTappedModifyStack = (
  stackLength: number,
  source: Source,
  numberOfClassesInStack: number,
) => ({
  event: 'Tapped Modify Stack',
  ...toBaseProperties(),
  stackLength,
  source,
  numberOfClassesInStack,
});

export const trackViewedStackedClasses = (
  stackedClasses: StackedOnDemandClass[],
  source: Source,
) => ({
  event: 'Viewed Stacked Classes',
  ...toBaseProperties(),
  ...toViewedStackedClassesAnalytics(stackedClasses),
  source,
});

export const trackTappedRemoveAllClassesFromStack = (
  stackLength: number,
  numberOfClassesInStack: number,
) => ({
  event: 'Tapped Remove All Classes From Stack',
  ...toBaseProperties(),
  stackLength,
  numberOfClassesInStack,
});

export const trackTappedDoneModifyingStack = (
  stackLength: number,
  numberOfClassesInStack: number,
) => ({
  event: 'Tapped Done Modifying Stack',
  ...toBaseProperties(),
  stackLength,
  numberOfClassesInStack,
});

export const trackFailedToModifyStack = () => ({
  event: 'Failed To Modify Stack',
  ...toBaseProperties(),
});

export const trackDisplayedTooltip = (classAdded: string, source: Source) => ({
  event: 'Displayed Tooltip',
  ...toBaseProperties(),
  classAdded,
  source,
});

export const trackDismissedTooltip = (classAdded: string, source: Source) => ({
  event: 'Dismissed Tooltip',
  ...toBaseProperties(),
  classAdded,
  source,
});

export const trackDisplayedStackNotification = (
  numberOfClassesInStack: number,
  notification: Notification,
  source: Source,
) => ({
  event: 'Displayed Stack Notification',
  ...toBaseProperties(),
  numberOfClassesInStack,
  notification,
  source,
});

export enum Notification {
  AddedClass = 'Added Class',
  MaxAmountInStack = 'Max Amount In Stack',
}

export enum ModifyType {
  Remove = 'Remove',
  Move = 'Move',
}

export enum Action {
  Remove = 'Remove',
  RemoveAll = 'Remove All',
  Drag = 'Drag',
  MoveToTop = 'Move To Top',
}

export type TrackModifiedClassInStackProps = {
  modifyType: ModifyType;
  class: StackedOnDemandClass;
  action: Action;
  previousPos: number | null;
  pos: number | null;
  numberOfClasses: number;
  length: number;
  previousIds: string[];
  currentIds: string[];
};

export const trackModifiedClassInStack = (p: TrackModifiedClassInStackProps) => ({
  event: 'Modified Class In Stack',
  ...toBaseProperties(),
  ...toClassAnalytics(
    toStackedClassAnalyticsProperties(p.class as OnDemandInstructorClass),
  ),
  modifyType: p.modifyType,
  action: p.action,
  previousPositionInStack: p.previousPos,
  positionInStack: p.pos,
  numberOfClassesInStack: p.numberOfClasses,
  stackLength: p.length,
  '[Previous Stacked Class IDs]': p.previousIds,
  '[Stacked Class IDs]': p.currentIds,
});

export const trackTappedContinueStackLater = (
  classProperties: ClassAnalyticsProperties,
  isPlayableOnDevice: boolean,
  workoutProperties: DetailedWorkout | undefined,
) => {
  if (workoutProperties) {
    return {
      ...toBaseProperties(),
      ...(classProperties ? toClassAnalytics(classProperties) : {}),
      ...toAnalyticsWorkoutProperties(workoutProperties),
      event: 'Tapped Continue Stack Later',
      isPlayableOnDevice,
    };
  } else
    return {
      ...toBaseProperties(),
      ...(classProperties ? toClassAnalytics(classProperties) : {}),
      event: 'Tapped Continue Stack Later',
      isPlayableOnDevice,
    };
};

export const toAddClassToStackHeaderProps = (
  addMethod: string,
  location: any,
  source: Source,
) => {
  const baseProps = toBaseProperties();
  return {
    ...toClientDetails(baseProps, toSource(location)),
    'Add Method': addMethod,
  };
};
