import React from 'react';
import { FocusOn } from 'react-focus-on';
import type { StyledProps } from 'styled-components';
import styled from 'styled-components';
import { useOnClickOutside } from '@peloton/react';
import { GreaterThan, getIsBreakpointGreaterThan } from '@peloton/responsive';
import { media, hover } from '@peloton/styles';
import { white } from '@engage/colors';
import type { TopNavUserInfo } from '@engage/members';
import { OVERVIEW_ROUTE } from '@engage/overview';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { useMembers, Members, TopNav, useTopNav } from '@members/copy';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import { MainLink, MAIN_LINK_PADDING, Separator } from '@members/layout/sharedLinkStyles';
import { TooltipTarget } from '@members/tooltips';
import { PROFILE_SOURCE } from '../../analytics';
import { NavAvatar } from '../TopNavLinks';
import { MiniNav } from './MiniNav';
import MiniNavLink from './MiniNavLink';
import { AvatarTooltip } from './ToolTip';

type Props = {
  isMenuOpen: boolean;
  user: TopNavUserInfo;
  onClick(): void;
  handleMenuClose(e?: Event): void;
  handleOpenGetApp(): void;
};

type NavAvatarLinkContainerProps = StyledProps<{ user: TopNavUserInfo }>;

export const MiniNavBlock = ({
  user,
  onClick,
  isMenuOpen,
  handleMenuClose,
  handleOpenGetApp,
}: Props) => {
  const navUpdatesEnabled = useFeatureToggle(Feature.Navupdates);
  const navBreakpoint = navUpdatesEnabled ? 'desktopLarge' : 'desktop';

  const containerRef = React.useRef<HTMLUListElement>(null);
  const miniNavLinkRef = React.useRef<HTMLButtonElement>(null);
  const targetsRef = React.useRef<Element[]>([]);
  const memberRef = React.useRef() as React.RefObject<any>;

  if (containerRef.current !== null && miniNavLinkRef.current !== null) {
    targetsRef.current = [containerRef.current, miniNavLinkRef.current];
  }

  const clickOutsideEnabled = useReduxState(getIsBreakpointGreaterThan, 'desktopLarge');
  useOnClickOutside(targetsRef, handleMenuClose, true, clickOutsideEnabled);

  return (
    <MiniNavBlockContainer>
      <AvatarContainer>
        {user.username ? <StyledNavAvatarLinkContainer user={user} /> : null}
      </AvatarContainer>
      <Separator />
      <MiniNavLink
        user={user}
        onClick={onClick}
        isMenuOpen={isMenuOpen}
        ref={miniNavLinkRef}
      />
      <GreaterThan breakpoint={navBreakpoint}>
        <FocusOn enabled={isMenuOpen} shards={[memberRef]}>
          <MiniNav
            handleMenuClose={handleMenuClose}
            handleOpenGetApp={handleOpenGetApp}
            ref={containerRef}
            isMenuOpen={isMenuOpen}
          />
        </FocusOn>
      </GreaterThan>
    </MiniNavBlockContainer>
  );
};

const MiniNavBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${media.desktop`
    &:first-child {
      margin-right: -10px;
    }
  `}
`;

const NavAvatarLinkContainer: React.FC<
  React.PropsWithChildren<NavAvatarLinkContainerProps>
> = ({ user, ...props }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <>
      <TooltipTarget tooltipId={useTopNav(TopNav.Profile)} isExpanded={isExpanded}>
        <MainLink
          exact
          to={{ pathname: OVERVIEW_ROUTE, state: { source: PROFILE_SOURCE } }}
          data-test-id="userInfo"
          {...props}
          aria-label={useTopNav(TopNav.Profile)}
        >
          <NavAvatar
            url={user.imageUrl}
            size={32}
            alt={useMembers(Members.AvatarAltText, { name: user.username })}
          />
        </MainLink>
      </TooltipTarget>
      <AvatarTooltip
        tooltipId={useTopNav(TopNav.Profile)}
        setIsExpanded={setIsExpanded}
      ></AvatarTooltip>
    </>
  );
};

const StyledNavAvatarLinkContainer = styled(NavAvatarLinkContainer)`
  margin-bottom: 0;
  padding-top: ${spaces.small}px;
  padding-bottom: ${spaces.small}px;
  ${hover`
    transition: background-color 250ms ease;
    background-color: ${white};
  `}
`;
const AvatarContainer = styled.span`
  min-width: ${2 * MAIN_LINK_PADDING + spaces.xxxLarge}px;
`;
