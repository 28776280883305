export enum ShareTooltipDestination {
  CopyLink = 'Copy Link',
  Facebook = 'Facebook',
}

export enum ShareTooltipSource {
  TagDetailShare = 'Tag Detail Share',
  TagDetailInviteOthers = 'Tag Detail Invite Others',
  ClassDetailsShare = 'Class Details Share',
  ScheduleClassModalShare = 'Schedule Class Modal Share',
  WorkoutOfTheDayShare = 'Workout of the Day Share',
  PostClass = 'Post Class',
}
