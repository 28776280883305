import { findLastIndex, isNil } from 'ramda';
import type { Timeline, Segment } from '../models';

type CurrentSegmentState = {
  currentSegmentIndex: number;
  nextSegmentIndex: number;
  isNextSegmentStarting: boolean;
  currentSegmentProgress?: number;
};

type CurrentSegmentDisplay = CurrentSegmentState & {
  isBeforeClassStart: boolean;
  isAfterClassEnd: boolean;
  segments: Segment[];
};

const ENDING_SOON_SECONDS = 7;

/* Helpers */

export const timelineIsEmpty = (timeline: Timeline) =>
  timeline.classEndOffset === undefined &&
  timeline.classStartOffset === undefined &&
  timeline.videoEndOffset === undefined &&
  timeline.segments.length === 0;

export const toCurrentSegmentDisplay = (
  currentVideoOffset: number,
  timeline: Timeline,
): CurrentSegmentDisplay => ({
  isBeforeClassStart: toIsBeforeClassStart({
    classStartOffset: timeline.classStartOffset,
    currentVideoOffset,
  }),
  isAfterClassEnd: toIsAfterClassEnd({
    classEndOffset: timeline.classEndOffset,
    currentVideoOffset,
  }),
  segments: timeline.segments,
  ...toCurrentSegmentState({
    currentVideoOffset,
    ...timeline,
  }),
});

export const toCurrentSegmentState = ({
  segments,
  currentVideoOffset,
  classStartOffset,
  classEndOffset,
}: Timeline & { currentVideoOffset: number }): CurrentSegmentState => {
  if (toIsAfterClassEnd({ currentVideoOffset, classEndOffset })) {
    return {
      currentSegmentIndex: segments.length - 1,
      currentSegmentProgress: 100,
      nextSegmentIndex: -1,
      isNextSegmentStarting: false,
    };
  }

  if (toIsBeforeClassStart({ currentVideoOffset, classStartOffset })) {
    return {
      currentSegmentIndex: -1,
      nextSegmentIndex: -1,
      isNextSegmentStarting: false,
    };
  }

  const currentSegmentIndex = findLastIndex(
    segment => currentVideoOffset > segment.startTimeOffset + classStartOffset!,
    segments,
  );
  const currentSegment = segments[currentSegmentIndex];
  const nextSegmentIndex =
    currentSegmentIndex + 1 < segments.length ? currentSegmentIndex + 1 : -1;

  const segmentTimeInfo = {
    segment: currentSegment,
    currentVideoOffset,
    classStartOffset,
  };

  return {
    currentSegmentIndex,
    currentSegmentProgress: toSegmentProgress(segmentTimeInfo),
    nextSegmentIndex,
    isNextSegmentStarting:
      nextSegmentIndex !== -1 && isCurrentSegmentEnding(segmentTimeInfo),
  };
};

type SegmentProgressProps = {
  segment?: Segment;
  currentVideoOffset: number;
  classStartOffset?: number;
};

export const toSegmentProgress = ({
  segment,
  currentVideoOffset,
  classStartOffset,
}: SegmentProgressProps) => {
  if (classStartOffset === undefined || segment === undefined) {
    return undefined;
  }

  const percent =
    (100 * (currentVideoOffset - segment.startTimeOffset - classStartOffset)) /
    segment.length;
  return Math.round(10 * percent) / 10;
};

export const isCurrentSegmentEnding = ({
  segment,
  currentVideoOffset,
  classStartOffset,
}: SegmentProgressProps) => {
  if (classStartOffset === undefined || segment === undefined) {
    return false;
  }

  return (
    segment.startTimeOffset + segment.length + classStartOffset - currentVideoOffset <=
    ENDING_SOON_SECONDS
  );
};

export const toIsBeforeClassStart = ({
  currentVideoOffset,
  classStartOffset,
}: {
  currentVideoOffset: number;
  classStartOffset?: number;
}) => isNil(classStartOffset) || currentVideoOffset < classStartOffset;

export const toIsAfterClassEnd = ({
  currentVideoOffset,
  classEndOffset,
}: {
  currentVideoOffset: number;
  classEndOffset?: number;
}) => (classEndOffset ? currentVideoOffset > classEndOffset : false);
