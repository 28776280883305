import type { SagaIterator } from 'redux-saga';
import { getContext, takeEvery } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import {
  loadUserSaga,
  logOutUserSaga,
  UserReducerActionType,
  logInUserSaga,
} from '@peloton/auth';
import { onForgotPasswordSaga, ForgotPasswordActionType } from '@peloton/forgot-password';

const authSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(UserReducerActionType.REQUEST, loadUserSaga, client);
  yield takeEvery(UserReducerActionType.LOGOUT, logOutUserSaga, client);
  yield takeEvery(UserReducerActionType.LOGIN, logInUserSaga, client);
  yield takeEvery(ForgotPasswordActionType.REQUEST, onForgotPasswordSaga, client);
};

export default authSaga;
