import { prop, curry } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { toApiLink } from '@peloton/external-links';
import { guessTimeZone } from '@peloton/time';
import type { Class } from '@engage/classes';
import { mapRideToClass } from '@engage/classes';
import type { PageNumber } from '@engage/pagination';
import { done } from '@engage/pagination';
import type { ApiWorkout, Workout } from '@engage/workouts';
import { toWorkout } from '@engage/workouts';

const NUM_WORKOUTS_PAGE = 10;

export const toDownloadWorkoutsLink = (userId: string) =>
  toApiLink(`/api/user/${userId}/workout_history_csv?timezone=${guessTimeZone()}`);

const toWorkoutsUrl = (
  userId: string,
  limit: number,
  page: number,
  fromDate?: string,
  toDate?: string,
) =>
  `/api/user/${userId}/workouts?joins=ride&limit=${limit}&page=${page}${
    !!fromDate && !!toDate ? `&from=${fromDate}&to=${toDate}` : ''
  }`;

export const fetchWorkouts = (
  api: Client,
  userId: string,
  page: number = 0,
  workoutsPerPage: number = NUM_WORKOUTS_PAGE,
  fromDate?: string,
  toDate?: string,
) =>
  api
    .get(toWorkoutsUrl(userId, workoutsPerPage, page, fromDate, toDate))
    .then(pipeData(toWorkoutHistoryData));

export const toWorkoutHistoryData = (
  response: ApiWorkoutHistory,
): WorkoutHistoryData => ({
  classes: toClasses(response.data),
  nextPage: toNextPage(response),
  pageCount: response.pageCount,
  total: response.total,
  workouts: toWorkouts(response.data),
  isPrivate: response.isPrivate,
});

export const toNextPage = (response: ApiNextPage): PageNumber =>
  response.showNext ? response.page + 1 : done;

export const toWorkouts = (apiWorkouts: ApiWorkout[]) => apiWorkouts.map(toWorkout);

export const toClasses = (apiWorkouts: ApiWorkout[]) =>
  apiWorkouts
    .map(curry(prop('ride')))
    .filter(Boolean)
    .map(mapRideToClass);

export type WorkoutHistoryData = {
  classes: Class[];
  nextPage: PageNumber;
  pageCount: number;
  total: number;
  workouts: Workout[];
  isPrivate?: boolean;
};

export type ApiNextPage = {
  showNext: boolean;
  page: number;
};

export type ApiWorkoutHistory = {
  data: ApiWorkout[];
  isPrivate?: boolean;
  pageCount: number;
  total: number;
} & ApiNextPage;
