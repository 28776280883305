/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Achievement } from '../../../api-v2-models/generated/Achievement';
import type { AchievementTemplate } from '../../../api-v2-models/generated/AchievementTemplate';
import type { PaginationResponse } from '../../../api-v2-models/generated/PaginationResponse';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Get Achievement Template
 * Get achievement template mapping, including name, image, and description
 * @param templateId ID for the achievement template
 * @param imageType achievement badge image type
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns AchievementTemplate Successful response
 * @throws ApiError
 */
export const getAchievementTemplate = (
    api: BaseAPI,
    templateId: string,
    imageType?: 'light_image' | 'dark_image',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<AchievementTemplate> => {
    const localVarPath = `/api/achievement_template/${templateId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof imageType !== 'undefined') {
        localVarQueryParameter['image_type'] = imageType;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get User Achievements
 * Get number of times user has earned each type of achievements, grouped by category. Shows some unearned achievements within the category, with different logic depending on the category. The order of the categories and the achievements within each category is specified in CMS. This endpoint will return an error is accessed for a private user by someone who does not follow them.
 * @param userId ID for the user
 * @param imageType achievement badge image type
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserAchievements = (
    api: BaseAPI,
    userId: string,
    imageType?: 'light_image' | 'dark_image',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    categories?: {
        /**
         * Name of the category
         */
        name?: string,
        /**
         * Immutable human readable name for the category
         */
        slug?: string,
        achievements?: Array<{
            template?: AchievementTemplate,
            /**
             * Number of times a user has been awarded this achievement. May be 0
             */
            count?: number,
        }>,
    },
}> => {
    const localVarPath = `/api/user/${userId}/achievements`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof imageType !== 'undefined') {
        localVarQueryParameter['image_type'] = imageType;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets workout's achievement list
 * Returns workout's achievement list.
 * @param workoutId
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getWorkoutAchievements = (
    api: BaseAPI,
    workoutId: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    data?: Array<(Achievement & PaginationResponse)>,
}> => {
    const localVarPath = `/api/workout/${workoutId}/achievements`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

