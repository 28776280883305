import React from 'react';
import styled from 'styled-components';

// Creates separator also for screen reader text
const BulletSeparator: React.FC<React.PropsWithChildren<unknown>> = props => (
  <>
    <Space children=" " />
    <span {...props} aria-hidden={true}>
      &#183;
    </span>
    <Space children=" " />
  </>
);

const Space = styled.span<{ children?: React.ReactNode }>`
  display: inline-block;
  width: 0.5em;
`;

export default BulletSeparator;
