import React from 'react';

const ChevronIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      data-test-id="forwardArrow"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.146 4.146a.5.5 0 0 1 .708 0l7.323 7.324a.75.75 0 0 1 0 1.06l-7.323 7.324a.5.5 0 0 1-.708-.708L16.293 12 9.146 4.854a.5.5 0 0 1 0-.708Z"
      fill="#5A6474"
    />
  </svg>
);

export default ChevronIcon;
