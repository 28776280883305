// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Tags = {
  AddCta: 'addCta',
  Added: 'added',
  AddTags: 'addTags',
  AddTagsLongMessage: 'addTagsLongMessage',
  AnnouncementModalDescription: 'announcementModalDescription',
  AnnouncementModalTitle: 'announcementModalTitle',
  BackCta: 'backCta',
  BrowseTags: 'browseTags',
  ConnectWithTags: 'connectWithTags',
  CopyLinkCta: 'copyLinkCta',
  Create: 'create',
  ExplicitTagError: 'explicitTagError',
  Explore: 'explore',
  ExploreTags: 'exploreTags',
  ExpressYourself: 'expressYourself',
  FindATag: 'findATag',
  FindATagDescription: 'findATagDescription',
  Following: 'following',
  InviteOthersCta: 'inviteOthersCta',
  JoinTag: 'joinTag',
  LeaderboardTag: 'leaderboardTag',
  LeaderboardTagDescription: 'leaderboardTagDescription',
  LinkCopiedCta: 'linkCopiedCta',
  MaximumTagsMessage: 'maximumTagsMessage',
  Members: 'members',
  MoreTagsCount: 'moreTagsCount',
  MyTags: 'myTags',
  NewFeature: 'newFeature',
  NoTagsFound: 'noTagsFound',
  NoTagsFoundCta: 'noTagsFoundCta',
  NoTagsFoundMessage: 'noTagsFoundMessage',
  NotAMember: 'notAMember',
  PrimaryTag: 'primaryTag',
  RemainingTagsMessage: 'remainingTagsMessage',
  RemoveCta: 'removeCta',
  ReplacePrompt: 'replacePrompt',
  ReplaceTag: 'replaceTag',
  ReplaceTagDescription: 'replaceTagDescription',
  RepresentYourself: 'representYourself',
  SearchForTagsDescription: 'searchForTagsDescription',
  SearchOrCreate: 'searchOrCreate',
  SearchOrCreateTags: 'searchOrCreateTags',
  SetPrimaryCta: 'setPrimaryCta',
  ShareCta: 'shareCta',
  ShareDescription: 'shareDescription',
  ShareOnFacebook: 'shareOnFacebook',
  ShareTitle: 'shareTitle',
  TagDoesNotMeetGuidelines: 'tagDoesNotMeetGuidelines',
  TagGuidelinesSupportMessage: 'tagGuidelinesSupportMessage',
  TagRequirements: 'tagRequirements',
  Tags: 'tags',
  TryForFree: 'tryForFree',
  UnusedTag: 'unusedTag',
  TrendingCategory: 'trendingCategory',
  FeaturedCategory: 'featuredCategory',
  FriendsCategory: 'friendsCategory',
  PopularCategory: 'popularCategory',
} as const;
