import { toMapStateToLinkProps } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { getLocation } from '@peloton/redux';
import { useReduxState } from '@engage/redux';

export const useReferralsUrlWithSource = (type: 'card' | 'nav') => {
  const { referralsHref } = useReduxState(
    toMapStateToLinkProps({ referralsHref: Links.referrals }),
  );
  const { pathname } = useReduxState(getLocation);
  return `${referralsHref}?source=${pathname}-${type}`;
};
