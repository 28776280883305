export const viewedTieringModal = (
  modalName: string,
  modalTitle: string,
  source: string,
) => ({
  event: 'Viewed Modal Prompt',
  modalName,
  modalTitle,
  source,
});
