import { invertObj } from 'ramda';
import { isPublic } from '../../@engage/env/ci';

export const URL = 'https://graphql.contentful.com/content/v1/spaces/3h9m9n7hjs9v';
export const TOKEN = 'XLflT6eT8xJki5SSPmxHWpSUgBlfT-lepZUf59Sq-2A';

/**
 * A copy namespace allows partitioning of Contentful content types into separate static copy files.
 * For example: packages/@members/copy/static/namespacedCopy/de-DE.production.generated.json and packages/@members/copy/static/namespacedCopy/de-DE.uat.generated.json
 */
const CopyNamespaces = ['production', 'uat'] as const;
export type CopyNamespace = typeof CopyNamespaces[number];

/**
 * Create separate copy namespaces (see `CopyNamespaces`) and map `query` (Contentful content type key) to `typename` (Typescript models).
 */
const QUERY_TYPENAME_MAP: Record<CopyNamespace, Record<string, string>> = {
  production: require('./queryTypenameMap.production.json'),
  uat: require('./queryTypenameMap.uat.json'),
};

const REVERSE_QUERY_TYPENAME_MAP: Record<CopyNamespace, Record<string, string>> = {
  production: invertObj(QUERY_TYPENAME_MAP['production']),
  uat: invertObj(QUERY_TYPENAME_MAP['uat']),
};

export const copyNamespaces = (): CopyNamespace[] =>
  isPublic() ? ['production'] : ['production', 'uat'];

/**
 * This is used in the members app (the copy client: packages/@members/copy/client.ts) and in the fetch script `packages/@members/copy/fetch.ts` to return the appropriate environments.
 *
 * @param forcePublic If true, only allows public environment queries.
 */
export const queryTypenameMap = (forcePublic = isPublic()) =>
  forcePublic
    ? QUERY_TYPENAME_MAP['production']
    : {
        ...QUERY_TYPENAME_MAP['production'],
        ...QUERY_TYPENAME_MAP['uat'],
      };

const copyNamespaceForTypename = (typename: string) => {
  const found = Object.keys(QUERY_TYPENAME_MAP).find(
    copyNamespace => REVERSE_QUERY_TYPENAME_MAP[copyNamespace][typename],
  );
  return found;
};

export const copyUatNamespaceForQuery = (query: string) => {
  return !!QUERY_TYPENAME_MAP.uat[query];
};

export const copyNamespaceForQuery = (query: string) => {
  const found = Object.keys(QUERY_TYPENAME_MAP).find(
    copyNamespace => QUERY_TYPENAME_MAP[copyNamespace][query],
  );
  return found;
};

export const queryForTypename = (typename: string) => {
  const copyNamespace = copyNamespaceForTypename(typename);
  return copyNamespace ? REVERSE_QUERY_TYPENAME_MAP[copyNamespace][typename] : undefined;
};
