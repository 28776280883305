import type { MusicPlatformInfo } from '@engage/api-v2';
import type { CorporateWellnessGroup } from '@engage/settings';

export type Member = MemberInfo & {
  contractAgreements?: ContractAgreement[];
  externalMusicAuthList?: MusicPlatformInfo[];
  relationship: MemberRelationship;
  listGroup: MemberListGroup;
  defaultHeartRateZones?: number[];
  defaultMaxHeartRate?: number;
  customizedHeartRateZones?: number[];
  customizedMaxHeartRate?: number;
  isEmailVerified?: boolean;
  isStravaAuthenticated?: boolean;
  isFitbitAuthenticated?: boolean;
  defaultImageUrl?: string;
};

export type MemberInfo = {
  id: string;
  username: string;
  location: string;
  imageUrl: string;
  isProfilePrivate: boolean;
  // Not really part of the Member model, but we have not implemented a mapper for the user/id api call yet
  totalFollowers: number;
  totalFollowing: number;
  totalWorkouts?: number;
  corporateWellnessGroup?: CorporateWellnessGroup;
  hasActiveDeviceSubscription?: boolean;
  hasActiveDigitalSubscription?: boolean;
};

export type RequiredMemberAction =
  | 'acknowledge'
  | 'acceptance'
  | 'acknowledge_and_acceptance'
  | 'none';

export type ContractAgreement = {
  bikeContractUrl: string;
  treadContractUrl: string;
  contractId: string;
  contractDisplayName: string;
  contractType: string;
  contractCreatedAt: number;
  agreedAt: number;
  requiredMemberAction: RequiredMemberAction;
};

export enum MemberListGroup {
  Self = 'self',
  Following = 'following',
  Pending = 'pendingFollowers',
  Other = 'others',
}

export type MemberRelationship = {
  meToUser: RelationshipCategory;
  userToMe?: RelationshipCategory;
};

export enum RelationshipCategory {
  Following = 'following',
  Pending = 'follow_pending',
  None = 'none',
  Self = 'self',
}

export enum RelationshipChange {
  Follow = 'follow',
  Request = 'request',
  Unfollow = 'unfollow',
  Remove = 'remove',
  Cancel = 'cancel',
  Approve = 'approve',
  Reject = 'reject',
}

export const toRelationshipChange = (member: Member): RelationshipChange => {
  return fromRelationshipToRelationshipChange(
    member.isProfilePrivate,
    member.relationship,
  ).relationshipChange;
};

type RelationshipChangeAndResult = {
  relationshipChange: RelationshipChange;
  relationshipCategory: RelationshipCategory;
};

export const fromRelationshipToRelationshipChange = (
  isProfilePrivate: boolean,
  relationship: MemberRelationship,
): RelationshipChangeAndResult => {
  if (!isProfilePrivate && relationship.meToUser === RelationshipCategory.None) {
    return {
      relationshipChange: RelationshipChange.Follow,
      relationshipCategory: RelationshipCategory.Following,
    };
  } else if (isProfilePrivate && relationship.meToUser === RelationshipCategory.None) {
    return {
      relationshipChange: RelationshipChange.Request,
      relationshipCategory: RelationshipCategory.Pending,
    };
  } else if (relationship.meToUser === RelationshipCategory.Following) {
    return {
      relationshipChange: RelationshipChange.Unfollow,
      relationshipCategory: RelationshipCategory.None,
    };
  } else {
    return {
      relationshipChange: RelationshipChange.Cancel,
      relationshipCategory: RelationshipCategory.None,
    };
  }
};

export const toMemberListGroup = (memberCategoryString: string): MemberListGroup => {
  switch (memberCategoryString) {
    case 'self':
      return MemberListGroup.Self;
    case 'following':
      return MemberListGroup.Following;
    case 'pending_followers':
      return MemberListGroup.Pending;
    case 'others':
      return MemberListGroup.Other;
    default:
      return MemberListGroup.Other;
  }
};

export const isFollowedByUser = (member: Member) =>
  member.relationship.meToUser === RelationshipCategory.Following;

export const isSelf = (member: Member) =>
  isSelfRelationship(member.relationship.meToUser);

export const shouldShowPrivateInfo = (member: Member) =>
  isSelf(member) || isFollowedByUser(member) || !member.isProfilePrivate;

export const isSelfRelationship = (relationship: RelationshipCategory) =>
  relationship === RelationshipCategory.Self;

export const isFollowingRelationship = (relationship: RelationshipCategory) =>
  relationship === RelationshipCategory.Following;

export const getMeToUserRelationship = (member: Member) => member.relationship.meToUser;

export const takeIds = (members: Member[]): string[] =>
  members.map((member: Member) => member.id);

export const ME = 'me';
