import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useOnClickOutside, usePrevious } from '@peloton/react';
import { defaultTransition, media } from '@peloton/styles';
import type { OptionalOnly } from '@peloton/types';
import { gray1, gray2, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { useIsBreakpointLessThan } from '@members/responsive';

export enum Position {
  Top = 'Top',
  Bottom = 'Bottom',
}

type Coordinate = {
  x: number;
  y: number;
  arrowX: number;
};

type StaticProps = {
  tooltipWidth: number;
  tooltipHeight: number;
  tooltipMargin?: number;
  tooltipRightSafetyMargin?: number;
  arrowWidth: number;
  arrowMargin?: number;
};

type OptionalStaticProps = Required<OptionalOnly<StaticProps>>;

const DEFAULT_STATIC_VALUES: OptionalStaticProps = {
  tooltipMargin: 10,
  tooltipRightSafetyMargin: 30,
  arrowMargin: 5,
};

type OuterProps = {
  originRef: React.RefObject<Element>;
};

type TooltipContextType = Required<StaticProps> &
  OuterProps & {
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    coordinate: Coordinate;
    setCoordinate: React.Dispatch<React.SetStateAction<Coordinate>>;
    targets: React.MutableRefObject<Element[]>;
    currentTargetRef: React.MutableRefObject<Element>;
  };

const DEFAULT_VALUES = {
  isExpanded: false,
  setIsExpanded: () => null,
  coordinate: { x: 0, y: 0, arrowX: 0 },
  setCoordinate: () => null,
  originRef: React.createRef<Element>(),
  targets: React.createRef<Element[]>() as React.MutableRefObject<Element[]>,
  currentTargetRef: React.createRef<Element>() as React.MutableRefObject<Element>,
};

/**
 * __createTooltip__
 *
 * Creates a Tooltip context, provider, and hooks that can be exported and distributed around the component tree.
 *
 * @example
 * export const {
 *   Tooltip: ShareTooltip,
 *   TooltipContextProvider: ShareTooltipContextProvider,
 *   useTooltip: useShareTooltip,
 * } = createTooltip({
 *   tooltipWidth: 300,
 *   tooltipHeight: 200,
 *   arrowWidth: 10,
 * });
 */
export const createTooltip = (staticProps: StaticProps) => {
  const TooltipContext = React.createContext<TooltipContextType>({
    ...DEFAULT_VALUES,
    ...DEFAULT_STATIC_VALUES,
    ...staticProps,
  });

  const TooltipContextProvider: React.FC<React.PropsWithChildren<OuterProps>> = ({
    originRef,
    children,
  }) => {
    return (
      <TooltipContext.Provider value={useInitialValue(originRef)}>
        {children}
      </TooltipContext.Provider>
    );
  };

  const useInitialValue = (originRef: React.RefObject<Element>): TooltipContextType => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    const [coordinate, setCoordinate] = React.useState<Coordinate>(
      DEFAULT_VALUES.coordinate,
    );
    const targets = React.useRef<Element[]>([]);

    return {
      ...DEFAULT_VALUES,
      ...DEFAULT_STATIC_VALUES,
      ...staticProps,
      isExpanded,
      setIsExpanded,
      coordinate,
      setCoordinate,
      originRef,
      targets,
    };
  };

  /**
   * __useTooltip__
   *
   * To use a component to toggle on/off a tooltip, call `useTooltip` with a ref to the component you want
   * the tooltip to be positioned at.
   *
   * @param targetRef The component to position to the tooltip at.
   * @param wrapperRef If not provided, is targetRef. If provided, indicates the component that will toggle on/off a  tooltip. By default, clicks outside the tooltip and wrapperRef will close the tooltip.
   *
   * @example
   * const targetRef = React.useRef<HTMLDivElement>(null);
   * const { toggle, isExpanded } = useTooltip(targetRef);
   */
  const useTooltip = (
    targetRef: React.RefObject<Element>,
    position: Position,
    wrapperRef?: React.RefObject<Element>,
    arrowXOffset?: number,
    xPositionOffset?: number,
  ) => {
    const {
      isExpanded,
      setIsExpanded,
      setCoordinate,
      originRef,
      tooltipWidth,
      tooltipHeight,
      tooltipMargin,
      tooltipRightSafetyMargin,
      arrowWidth,
      arrowMargin,
      targets,
      currentTargetRef,
    } = React.useContext(TooltipContext);

    const isMobile = useIsBreakpointLessThan('tablet');

    const positionTooltip = React.useCallback(
      (element: Element) => {
        if (originRef.current === undefined) {
          return;
        }

        const rect = element.getBoundingClientRect();
        const containerRect = originRef.current!.getBoundingClientRect();

        const relativeX = rect.x - containerRect.x;
        const relativeY = rect.y - containerRect.y;

        function getYPosition(positionY: Position) {
          if (positionY == Position.Top) {
            return relativeY - (tooltipHeight + tooltipMargin + arrowMargin);
          }

          const y = relativeY + (tooltipMargin + arrowMargin + 40);
          if (isMobile) {
            return y - 8;
          }

          return y;
        }

        function getXPosition() {
          const posX = relativeX - tooltipWidth / 2 + rect.width / 2;
          const posXMinusMargin = posX - tooltipMargin - arrowMargin;

          if (isMobile) {
            return posXMinusMargin - 40;
          }

          return posXMinusMargin;
        }

        const y = getYPosition(position);
        let x = getXPosition() + (xPositionOffset ?? 0);
        let arrowX = tooltipWidth / 2 - arrowWidth;

        const relativeRightX = x + tooltipWidth;
        const relativeContainerRightX = containerRect.width; // x is ignored

        if (x < 0) {
          x = tooltipMargin;
          arrowX = rect.width / 2;
        } else if (relativeRightX >= relativeContainerRightX - tooltipRightSafetyMargin) {
          const newX =
            relativeContainerRightX -
            tooltipWidth -
            tooltipRightSafetyMargin -
            tooltipMargin;
          arrowX = arrowX + (x - newX);
          x = newX;
        }

        setCoordinate({
          x: x,
          y,
          arrowX: arrowXOffset ?? arrowX,
        });
        currentTargetRef.current = element;
      },
      [originRef.current, position, isMobile],
    );

    const toggle = React.useCallback(() => {
      if (targetRef.current === undefined) {
        return;
      }

      if (isExpanded) {
        setIsExpanded(false);
      } else {
        setIsExpanded(true);
        positionTooltip(targetRef.current!);
      }
    }, [targetRef.current, isExpanded, position, positionTooltip]);

    const registerTarget = React.useCallback(
      (element: Element) => {
        targets.current.push(element);
      },
      [targets.current],
    );

    const unregisterTarget = React.useCallback(
      (element: Element) => {
        targets.current = targets.current.filter(
          registeredElement => registeredElement !== element,
        );
      },
      [targets.current],
    );

    const useRegisteredTarget = (ref: React.RefObject<Element> | undefined) => {
      React.useEffect(() => {
        if (ref && ref.current) {
          registerTarget(ref.current);
        }

        return () => {
          if (ref && ref.current) {
            unregisterTarget(ref.current);
          }
        };
      }, [ref]);
    };

    useRegisteredTarget(targetRef);
    useRegisteredTarget(wrapperRef);

    const isRefActive = React.useMemo(
      () => isExpanded && targetRef.current === currentTargetRef.current,
      [isExpanded, currentTargetRef.current],
    );

    return { toggle, isExpanded: isRefActive };
  };

  const Tooltip: React.FC<
    React.PropsWithChildren<{
      arrowHovered: boolean;
      position: Position;
    }>
  > = ({ children, arrowHovered, position }) => {
    const {
      isExpanded,
      setIsExpanded,
      coordinate,
      tooltipWidth,
      tooltipHeight,
      arrowWidth,
      targets,
    } = React.useContext(TooltipContext);
    const [isMounted, setIsMounted] = React.useState(false);
    const prevIsVisible = usePrevious(isExpanded);
    const isExpandedChanged = isMounted && prevIsVisible !== isExpanded;

    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const targetsRef = React.useRef<Element[]>([]);

    const handleClickOutside = React.useCallback(() => {
      setIsExpanded(false);
    }, [isExpanded]);

    useOnClickOutside(targetsRef, handleClickOutside);

    React.useEffect(() => {
      setIsMounted(true);
    }, []);

    React.useEffect(() => {
      if (wrapperRef.current !== null) {
        targetsRef.current = [...targets.current, wrapperRef.current];
      }
    }, [targets.current, wrapperRef.current]);

    return (
      <Wrapper
        ref={wrapperRef}
        isExpandedChanged={isExpandedChanged}
        isExpanded={isExpanded}
        coordinate={coordinate}
      >
        <Content
          arrowHovered={arrowHovered}
          tooltipWidth={tooltipWidth}
          tooltipHeight={tooltipHeight}
          coordinate={coordinate}
          arrowWidth={arrowWidth}
          position={position}
        >
          {children}
        </Content>
      </Wrapper>
    );
  };

  return {
    Tooltip,
    TooltipContextProvider,
    useTooltip,
  };
};

const Fadeout = keyframes`
  0% {
    opacity: 1;
  }
  33% {
    opacity: 66%;
  }
  66% {
    opacity: 33%;
  }
  100% {
    opacity: 0;
  }
`;

const Wrapper = styled.div<{
  isExpandedChanged: boolean;
  isExpanded: boolean;
  coordinate: Coordinate;
}>`
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: ${({ coordinate: { x } }) => x ?? 0}px;
  top: ${({ coordinate: { x } }) => x ?? 0}px;
  top: ${({ coordinate: { y } }) => y ?? 0}px;
  display: none;

  ${({ isExpandedChanged, isExpanded }) =>
    isExpandedChanged
      ? isExpanded
        ? css`
            display: flex;
            opacity: 1;
          `
        : css`
            display: flex;
            animation: ${Fadeout} 0.25s ease both;
          `
      : isExpanded
      ? css`
          display: flex;
          opacity: 1;
        `
      : null}}
`;

type ContentProps = Pick<
  TooltipContextType,
  'tooltipWidth' | 'tooltipHeight' | 'coordinate' | 'arrowWidth'
> & {
  arrowHovered: boolean;
  position: Position;
};

const getArrowPosition = ({ position, arrowWidth, coordinate }: ContentProps) => {
  if (position === Position.Bottom) {
    return css`
      left: ${`${coordinate.arrowX}px` ?? '70%'};
      ${media.tablet`
        left: ${`${coordinate.arrowX}px` ?? '60%'};
      `}
    `;
  }

  if (position === Position.Top) {
    return css`
      bottom: ${-arrowWidth}px;
      left: ${coordinate.arrowX ?? 0}px;
    `;
  }
  return css`
    top: 0px;
  `;
};

const Content = styled.div<ContentProps>`
  height: ${({ tooltipHeight }) => tooltipHeight}px;
  width: ${({ tooltipWidth }) => tooltipWidth}px;
  border-radius: ${spaces.xxxSmall}px;
  ${({ position }) =>
    position === Position.Bottom
      ? css`
          background-color: ${white};
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        `
      : css`
          background-color: ${gray1};
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        `}
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    ${getArrowPosition}
    box-sizing: border-box;
    border-bottom: ${({ arrowWidth }) => arrowWidth}px solid ${gray1};
    border-right: ${({ arrowWidth }) => arrowWidth}px solid ${gray1};
    border-color: ${({ arrowHovered }) => (arrowHovered ? gray2 : gray1)};
    transform-origin: 0 0;
    transform: ${p =>
      p.position === Position.Bottom ? 'rotate(135deg)' : 'rotate(-45deg)'};
    box-shadow: -3px 3px 5px 0 rgba(0, 0, 0, 0.1);
    ${defaultTransition('border-color')}
    z-index: 0;
  }
`;
