import React from 'react';
import { MemberSearchModal } from '@members/member-search';
import { openModal } from '@members/modal';

const SEARCH_MODAL_NAME_FROM_NAV = 'searchMembersFromNav';
const TOP_NAV = 'Top Nav';

export const openMemberSearchFromNav = () =>
  openModal(SEARCH_MODAL_NAME_FROM_NAV, TOP_NAV);

export const MemberSearchFromNav: React.FC<
  React.PropsWithChildren<{ focusElement: string }>
> = ({ focusElement }) => (
  <MemberSearchModal
    onCloseModal={focusOnClose(focusElement)}
    modalName={SEARCH_MODAL_NAME_FROM_NAV}
  />
);

const focusOnClose = (elementId: string) => () => {
  const moreNavLink = document.getElementById(elementId);
  if (moreNavLink) {
    moreNavLink.focus();
  }
};
