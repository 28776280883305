import React from 'react';
import styled from 'styled-components';
import { toSocialHref } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { gray4, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { link1, link3 } from '@engage/typography';
import { useFacebook, Facebook, useSite, Site } from '@members/copy';
import * as Icons from './icons';

const SocialLink: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <LinkWrapper>
    <StyledLink
      href={props.href}
      target="_blank"
      rel="noopener"
      darkTheme={props.darkTheme}
      aria-label={props.label}
    >
      {props.children}
    </StyledLink>
  </LinkWrapper>
);

const StyledLink = styled.a<{ darkTheme: boolean }>`
  ${props => (props.darkTheme ? link3 : link1)}
  fill:${props => (props.darkTheme ? gray4 : slate1)};
`;

const LinkWrapper = styled.li`
  display: inline-block;
  & + & {
    margin-left: ${spaces.large}px;
  }
`;

const SocialLinks: React.FC<React.PropsWithChildren<{ darkTheme?: boolean }>> = ({
  darkTheme = false,
}) => (
  <ul style={{ paddingBottom: '2px' }}>
    <SocialLink
      darkTheme={darkTheme}
      href={toSocialHref(Links.getFacebookLink())}
      label={useFacebook(Facebook.Label)}
    >
      <Icons.Facebook data-test-id="facebookSocialLink" />
    </SocialLink>
    <SocialLink
      darkTheme={darkTheme}
      href={toSocialHref(Links.instagram)}
      label={useSite(Site.Instagram)}
    >
      <Icons.Instagram data-test-id="instagramSocialLink" />
    </SocialLink>
    <SocialLink
      darkTheme={darkTheme}
      href={toSocialHref(Links.twitter)}
      label={useSite(Site.Twitter)}
    >
      <Icons.Twitter data-test-id="twitterSocialLink" />
    </SocialLink>
    <SocialLink
      darkTheme={darkTheme}
      href={toSocialHref(Links.youtube)}
      label={useSite(Site.Youtube)}
    >
      <Icons.Youtube data-test-id="youtubeSocialLink" />
    </SocialLink>
  </ul>
);

type LinkProps = {
  href: string;
  darkTheme: boolean;
  label: string;
};

export default SocialLinks;
