import type { Effect } from '@redux-saga/types';
import { createConfigurable } from '@peloton/config-singleton';

type Config = {
  getAnalyticsPropsForBookmark: (
    knownAnalyticsProps: Record<string, any>,
  ) => IterableIterator<Effect | Record<string, any>>;
};

export const { config, updateConfig } = createConfigurable<Config>({
  getAnalyticsPropsForBookmark: function* (props: Record<string, any>) {
    return props;
  },
});
