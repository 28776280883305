import { path, pathOr, pickAll, values } from 'ramda';
import type { Locale as LocaleSlug } from '@peloton/internationalize';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { getEntity, requestAction } from '@peloton/redux-fetch';
import type { DeviceType } from '@engage/workouts';
import { fetchMetadata } from './api';
import type {
  FitnessDiscipline,
  Instructor,
  ClassType,
  FitnessDisciplineSlug,
  Equipment,
  DeviceTypeDisplayName,
  Locale,
  ContentFocusLabel,
} from './models';

export const METADATA_NAMESPACE = 'metadata';

/**
 * @deprecated use cacheMetadataSaga instead
 */
export const loadMetadata = () => requestAction(METADATA_NAMESPACE, fetchMetadata);

export const getInstructor = (
  state: FetcherSelectorState,
  id?: string,
): Instructor | undefined => {
  const entity = getEntity<MetadataSelectorState>(state, METADATA_NAMESPACE);
  return id ? path<Instructor>(['entities', 'instructors', id], entity) : undefined;
};

export const getMetadata = (
  state: FetcherSelectorState,
): MetadataSelectorState['entities'] | undefined =>
  state.fetched[METADATA_NAMESPACE]?.entity?.entities;

export const getFitnessDiscipline = (state: FetcherSelectorState, id = 'cycling') => {
  const entity = getEntity<MetadataSelectorState>(state, METADATA_NAMESPACE);
  return entity?.entities.fitnessDisciplines[id];
};

export const getFitnessDisciplineName = (
  state: FetcherSelectorState,
  slug: FitnessDisciplineSlug,
) => path(['name'], getFitnessDiscipline(state, slug));

export const getClassType = (state: FetcherSelectorState, id?: string) => {
  const entity = getEntity<MetadataSelectorState>(state, METADATA_NAMESPACE);
  return id ? entity?.entities?.classTypes[id] : undefined;
};

export const getEquipment = <T extends string>(
  state: FetcherSelectorState,
  ids: T[] = [],
): Equipment[] => {
  const entity = getEntity<MetadataSelectorState>(state, METADATA_NAMESPACE);
  const equipmentState: Record<string, Equipment> | undefined = path(
    ['entities', 'equipment'],
    entity,
  );
  return equipmentState && ids.length
    ? (values(
        pickAll<Record<string, Equipment>, Record<T, Equipment>>(ids, equipmentState),
      ) as Equipment[])
    : [];
};

export const getDeviceType = (state: FetcherSelectorState, slug: DeviceType): string => {
  const entity = getEntity<MetadataSelectorState>(state, METADATA_NAMESPACE);
  return pathOr('', ['entities', 'deviceTypeDisplayNames', slug, 'displayName'], entity);
};

export const getLocale = (state: FetcherSelectorState, slug: LocaleSlug) => {
  const entity = getEntity<MetadataSelectorState>(state, METADATA_NAMESPACE);

  return pathOr('', ['entities', 'locales', slug, 'displayName'], entity);
};

export type MetadataSelectorState = {
  entities: {
    instructors: Record<string, Instructor>;
    fitnessDisciplines: Record<FitnessDisciplineSlug, FitnessDiscipline>;
    classTypes: Record<string, ClassType>;
    equipment: Record<string, Equipment>;
    deviceTypeDisplayNames: Record<DeviceType, DeviceTypeDisplayName>;
    locales: Record<LocaleSlug, Locale>;
    contentFocusLabels: Record<string, ContentFocusLabel>;
  };
};
