import type { Location as HistoryLocation } from 'history';
import React, { useEffect } from 'react';
import type { match } from 'react-router';
import { toOverviewUrl } from '@peloton/links/members';
import { getLocation } from '@peloton/redux';
import { isDefined } from '@peloton/types';
import type { Member } from '@engage/members';
import { getMember, isSelf, loadMember, toRelationshipInfo } from '@engage/members';
import {
  getViewedMemberUsernameOrIdFromPath,
  toWorkoutHistoryPath,
} from '@engage/overview';
import { useReduxAction, useReduxState } from '@engage/redux';
import { Feature, useFeatureToggle } from '@members/feature-toggles';
import { toAchievementsUrl } from '@members/pg-achievements/urls';
import { toGoalsUrl } from '@members/pg-goals/urls';
import { getViewedMemberIdFromPath, isWorkoutsPath } from '@members/pg-workouts/urls';
import type UserNavProps from './UserNavProps';
import UserNavView from './UserNavView';

const NOT_READY = 'notReady';

const isWorkoutsLinkActive = (_: match<any>, location: HistoryLocation) =>
  isWorkoutsPath(location.pathname);

export const useUserNav = (userId: string | undefined): UserNavProps | undefined => {
  const loadMemberAction = useReduxAction(loadMember);
  const path = useReduxState(getLocation)?.pathname;
  const currentUserId = userId ?? getUserIdFromPath(path);
  const member = useReduxState(getMember, currentUserId);
  const userNav = isDefined(member) ? toUserNav(member, currentUserId, path) : null;
  const simpleGoalsEnabled = useFeatureToggle(Feature.Simple_goals);

  useEffect(() => {
    if (
      isDefined(currentUserId) &&
      currentUserId !== NOT_READY &&
      (!isDefined(member) || currentUserId !== member.id)
    ) {
      loadMemberAction(currentUserId);
    }
  }, [currentUserId]);

  if (isDefined(userNav)) {
    return {
      ...userNav,
      isWorkoutsLinkActive,
      simpleGoalsEnabled,
    };
  } else {
    return undefined;
  }
};
const UserNav: React.FC<React.PropsWithChildren<OwnProps>> = ownProps => {
  const userNav = useUserNav(ownProps.userId);

  if (!isDefined(userNav)) {
    return null;
  }

  return <UserNavView {...userNav} />;
};

const toUserNav = (member: Member, namespace: string, currentPath: string) => ({
  ...member,
  ...toRelationshipInfo(member),
  namespace,
  currentPath,
  overviewPath: toOverviewUrl(member.username, isSelf(member)),
  workoutsPath: toWorkoutHistoryPath(isSelf(member), member.id),
  achievementsPath: toAchievementsUrl(member.id, isSelf(member)),
  goalsPath: toGoalsUrl(member.id, isSelf(member)),
});

// TODO: move url logic to packages
const getUserIdFromPath = (path: string) => {
  let viewedMemberId = getViewedMemberIdFromPath(path);
  if (!viewedMemberId) {
    viewedMemberId = getViewedMemberUsernameOrIdFromPath(path);
  }
  return viewedMemberId ? viewedMemberId : NOT_READY;
};

type OwnProps = { userId?: string };

export default UserNav;
