import React from 'react';
import styled from 'styled-components';
import { isDefined } from '@peloton/types';

const UnstyledChevron: React.FC<React.PropsWithChildren<ChevronProps>> = ({
  orientation,
  title,
  ...props
}) => (
  <svg viewBox="0 0 13 8" {...props}>
    {title && <title>{title}</title>}
    <path
      fillRule="evenodd"
      d="M11.992 1.744L6.744 6.992a.656.656 0 0 1-.928 0L.568 1.744a.656.656 0 1 1 .928-.928L6.28 5.601 11.064.816a.656.656 0 1 1 .928.928z"
    />
  </svg>
);

export type ChevronProps = React.SVGAttributes<SVGSVGElement> & {
  orientation?: Orientation;
  title?: string;
};

export enum Orientation {
  Left = 90,
  Right = 270,
  Up = 180,
  Down = 0,
}

const Chevron = styled(UnstyledChevron)`
  ${(props: ChevronProps) =>
    isDefined(props.orientation)
      ? `
    transform: rotate(${props.orientation}deg);`
      : ''}
`;

export { Chevron };
