import styled, { css } from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { gray4, white, gray5, slate3 } from '@engage/colors';

const sharedStyles = css`
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  height: 36px;
  min-width: 36px;
  position: absolute;
  bottom: 9px;
  right: 10px;
`;

const darkStyles = css`
  color: ${gray4};
  bottom: 10px;
  ${hoverTransition({
    property: 'color',
    to: white,
  })}
`;

const lightStyles = css`
  color: ${gray5};
  ${hoverTransition({
    property: 'color',
    to: slate3,
  })}
`;

const DarkToggleButton = styled.button`
  ${sharedStyles};
  ${darkStyles};
`;

const LightToggleButton = styled.button`
  ${sharedStyles};
  ${lightStyles};
`;

export { DarkToggleButton, LightToggleButton };
