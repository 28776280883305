/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeRelationshipRequest } from '../../../api-v2-models/generated/ChangeRelationshipRequest';
import type { PaginationResponse } from '../../../api-v2-models/generated/PaginationResponse';
import type { UserRelationship } from '../../../api-v2-models/generated/UserRelationship';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Follow user follow_id
 * Create a follow relationship from user user_id to user follow_id. If user follow_id is has a private profile, this endpoint will make a request to follow the user. This endpoint will always return an error if one user is a demo user and the other is not. Deprecated, use `POST /user/change_relationship` instead.
 * @param userId This must be the signed in user's id, or this endpoint will return an authentication failure
 * @param followId This is the id of the user who will be followed
 * @param fb If this is "True", this request is getting made to follow someone the user is friends with on facebook
 * @returns any Successful response
 * @throws ApiError
 */
export const postUserFollow = (
    api: BaseAPI,
    userId: string,
    followId: string,
    fb?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * Always true
     */
    success?: boolean,
    /**
     * The id of the user that is now a follower. Same as `user_id` from the path parameter
     */
    fromId?: string,
    /**
     * The id of the user that is now being followed. Same as `follow_id` from the path parameter
     */
    toId?: string,
}> => {
    const localVarPath = `/api/user/${userId}/follow/${followId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof fb !== 'undefined') {
        localVarQueryParameter['fb'] = fb;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets the relationship between the logged in user and another user
 * @param userId The user id
 * @returns string Successful response
 * @throws ApiError
 */
export const getUserRelationship = (
    api: BaseAPI,
    userId: string,
    options: any = {},
): AxiosPromise<Array<string>> => {
    const localVarPath = `/api/user/${userId}/relationship`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets the IDs for the users that the given user follows
 * Returns a list of UUIDs that represent the IDs for the users that the give user follows. This endpoint will return an error if accessed for a private user by someone who does not follow them.
 * @param id The user id
 * @returns string Successful response
 * @throws ApiError
 */
export const getUserFollowingIds = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<Array<string>> => {
    const localVarPath = `/api/user/${id}/following_ids`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get a list of users that follow the specific user
 * Returns a list of users that follow the request user.
 * The users include four types: depending on the relationship with
 * logged-in user, indicated by user's category:
 * * 'self' is the logged-in user,
 * * 'pending_followers' are users who have requested to follow the
 * logged-in user. This category
 * will only be returned if the request user is the logged-in user.
 * * 'following' are the users followed by the logged-in user,
 * * 'others' are the rest.
 *
 * If the request user is the logged-in user, the order of user list is:
 * 1. Users that are have requested to follow the logged-in user
 * 2. Users that are following the logged-in user, but are not followed by
 * the logged-in user
 * 3. Users that are following the logged-in user, and are followed by the
 * logged-in user
 *
 * If the request user is not the logged-in user, the order of user list
 * is:
 * 1. The logged-in user (if following the request user)
 * 2. Users that follow the logged-in user and the logged-in user follows.
 * 3. Users that the logged-in user follows, but that do not follow the
 * logged-in user.
 * 4. Users that follow the logged-in user, but that the logged-in user
 * does not follow.
 * 5. Everyone else.
 *
 * The users are sorted alphabetically by username in each of the above.
 * This endpoint will return an error if accessed for a private user by
 * someone who does not follow them.
 *
 * Special Behavior: If the request user is the logged-in user, the
 * relationship.user_to_me field will
 * indicate that this user follows the logged in user or is a pending
 * follower, rather than being null like in other user lists.
 * @param id The user id
 * @param limit If set, returns a limited number of data up to the limit specified. If not set, whole data will be returned.
 * @param page If set, retrieve data of this page number. Default to be 0.
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserFollowers = (
    api: BaseAPI,
    id: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${id}/followers`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Changes the relationship between the logged in user and another user
 * Changes the relationship between the logged in user and another user.
 *
 * The relationship is updated according to the action provided in the
 * body:
 * * follow - the logged in user follows the other user, or if the other
 * user has a private
 * profile, the logged in user requests to follow the other user
 * * unfollow - the logged in user removes their follow or request to
 * follow from the other user
 * * approve - the logged in user approves a request from the other user to
 * follow them
 * * remove - the logged in user removes a follower or pending follower
 *
 * This endpoint will return the status of the relationship between the
 * logged in user and the other user after the change is applied.
 *
 * This endpoint will always return an error if one user is a demo user and
 * the other is not.
 * @param requestBody
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @returns UserRelationship Successful response
 * @throws ApiError
 */
export const userChangeRelationship = (
    api: BaseAPI,
    requestBody: ChangeRelationshipRequest,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    options: any = {},
): AxiosPromise<UserRelationship> => {
    const localVarPath = `/api/user/change_relationship`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Follows all users you're friends with on facebook
 * Follow all users who you are friends with on facebook and have connected to facebook. If any of those users have a private profile, this will make a request to follow that user. This can only be called for users who have connected to facebook.
 * @param userId This must be the signed in user's id, or this endpoint will return an authentication failure
 * @returns any Successful response
 * @throws ApiError
 */
export const postUserFollowAllFacebookFriends = (
    api: BaseAPI,
    userId: string,
    options: any = {},
): AxiosPromise<{
    /**
     * Always true
     */
    success?: boolean,
}> => {
    const localVarPath = `/api/user/${userId}/follow_all_facebook_friends`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get a list of user IDs of users that follow the logged in user
 * Returns a list of UUIDs that represent the IDs for the users follow the
 * logged in user.
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserFollowerIds = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<({
    data?: Array<string>,
    /**
     * Total followers
     */
    total?: number,
})> => {
    const localVarPath = `/api/user/follower_ids`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get a list of users that are facebook friends of the specific user.
 * Returns a list of users that are facebook friends of the request user.
 * The users include three categories, depending on the relationship with
 * logged-in user:
 * * 'self' is the logged-in user,
 * * 'following' are the users followed by the logged-in user,
 * * 'others' are the rest.
 *
 * The order of user list is:
 * 1. Users that follow the logged-in user, but that the logged-in user
 * does not follow.
 * 2. Users that follow the logged-in user and the logged-in user follows.
 * 3. Users that the logged-in user follows, but that do not follow the
 * logged-in user.
 * 4. Everyone else.
 *
 * The users are sorted alphabetically by username in each of the above.
 * @param id The user id
 * @param limit If set, returns a limited number of data up to the limit specified. If not set, whole data will be returned.
 * @param page If set, retrieve data of this page number. Default to be 0.
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserFacebookFriends = (
    api: BaseAPI,
    id: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${id}/facebook_friends`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets the IDs for the users the logged in user follows
 * Returns a list of UUIDs that represent the IDs for the users the logged in user follows
 * @returns string Successful response
 * @throws ApiError
 */
export const getCurrentUserFollowingIds = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<Array<string>> => {
    const localVarPath = `/api/user/following_ids`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Unfollow user follow_id
 * Remove a follow relationship from user user_id to user follow_id. This endpoint will always return an error if one user is a demo user and the other is not. Deprecated, use `POST /user/change_relationship` instead.
 * @param userId This must be the signed in user's id, or this endpoint will return an authentication failure
 * @param followId This is the id of the user who will be unfollowed
 * @returns any Successful response
 * @throws ApiError
 */
export const postUserUnfollow = (
    api: BaseAPI,
    userId: string,
    followId: string,
    options: any = {},
): AxiosPromise<{
    /**
     * Always true
     */
    success?: boolean,
    /**
     * The id of the user that was a follower. Same as `user_id` from the path parameter
     */
    fromId?: string,
    /**
     * The id of the user that was followed. Same as `follow_id` from the path parameter
     */
    toId?: string,
}> => {
    const localVarPath = `/api/user/${userId}/unfollow/${followId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get a list of users followed by the specific user
 * Returns a list of users followed by the request user. The users include three types: depending on the relationship with logged-in user, indicated by user's category: * 'self' is the logged-in user, * 'following' are the users followed by the logged-in user, * 'others' are the rest. If the request user is the logged-in user, the order of user list is: 1. Users that follow the logged-in user and the logged-in user follows. 2. Users that the logged-in user follows, but that do not follow the logged-in user. If the request user is not the logged-in user, the order of user list is: 1. The logged-in user (if followed by the request user). 2. Users that follow the logged-in user and the logged-in user follows. 3. Users that the logged-in user follows, but that do not follow the logged-in user. 4. Users that follow the logged-in user, but that the logged-in user does not follow. 5. Everyone else. The users are sorted alphabetically by username in each of the above. This endpoint will return an error if accessed for a private user by someone who does not follow them.
 * @param id The user id
 * @param limit If set, returns a limited number of data up to the limit specified. If not set, whole data will be returned.
 * @param page If set, retrieve data of this page number. Default to be 0.
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserFollowing = (
    api: BaseAPI,
    id: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${id}/following`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

