import type { ReactElement } from 'react';
import type { PendingResult } from './pendingResult';
import { isOk, isError } from './pendingResult';

type RenderProps<R, E> = {
  renderOk?(result: R): ReactElement<any> | null;
  renderPending?(): ReactElement<any> | null;
  renderError?(error: E): ReactElement<any> | null;
};

const ResultBranch = <R, E>(
  props: RenderProps<R, E> & { pendingResult: PendingResult<R, E> },
) => {
  if (isOk(props.pendingResult)) {
    return props.renderOk ? props.renderOk(props.pendingResult.value) : null;
  }
  if (isError(props.pendingResult)) {
    return props.renderError ? props.renderError(props.pendingResult.value) : null;
  }
  return props.renderPending ? props.renderPending() : null;
};

export { ResultBranch };
