import { map, pathEq } from 'ramda';
import type { PendingResult } from '@engage/result';
import { ok, error, pending, isOk } from '@engage/result';
import type {
  Challenge,
  ChallengeStatus,
  ChallengeProgress,
  ChallengeParticipants,
} from '../models';

// Action Types

export enum ActionType {
  LoadChallengesRequest = '@engage/challenges/LOAD_CHALLENGES_REQUEST',
  LoadChallengesFailure = '@engage/challenges/LOAD_CHALLENGES_FAILURE',
  LoadChallengesSuccess = '@engage/challenges/LOAD_CHALLENGES_SUCCESS',
  ToggleJoinedRequest = '@engage/challenge/TOGGLE_JOINED_CHALLENGE_REQUEST',
  ToggleJoinedSuccess = '@engage/challenge/TOGGLE_JOINED_CHALLENGE_SUCCESS',
}

// Action Creators

export const loadChallengesRequest = (status: ChallengeStatus, userId: string) =>
  ({
    type: ActionType.LoadChallengesRequest,
    payload: { status, userId },
  } as const);

export const loadChallengesFailure = (status: ChallengeStatus) =>
  ({
    type: ActionType.LoadChallengesFailure,
    payload: { status },
  } as const);

export const loadChallengesSuccess = (status: ChallengeStatus, challenges: Challenge[]) =>
  ({
    type: ActionType.LoadChallengesSuccess,
    payload: { challenges, status },
  } as const);

export const toggleJoinedChallengeRequest = (
  status: ChallengeStatus,
  challengeId: string,
  joining: boolean,
  onError?: (e?: any) => void,
) =>
  ({
    type: ActionType.ToggleJoinedRequest,
    payload: { challengeId, joining, status, onError },
  } as const);

export const toggleJoinedChallengeSuccess = (
  status: ChallengeStatus,
  challengeId: string,
  progress: ChallengeProgress,
  participants: ChallengeParticipants,
) =>
  ({
    type: ActionType.ToggleJoinedSuccess,
    payload: { challengeId, progress, participants, status },
  } as const);

export type LoadChallengesRequest = ReturnType<typeof loadChallengesRequest>;
export type LoadChallengesFailure = ReturnType<typeof loadChallengesFailure>;
export type LoadChallengesSuccess = ReturnType<typeof loadChallengesSuccess>;
export type ToggleJoinedChallengeRequest = ReturnType<
  typeof toggleJoinedChallengeRequest
>;
export type ToggleJoinedChallengeSuccess = ReturnType<
  typeof toggleJoinedChallengeSuccess
>;

export type Action =
  | LoadChallengesRequest
  | LoadChallengesFailure
  | LoadChallengesSuccess
  | ToggleJoinedChallengeRequest
  | ToggleJoinedChallengeSuccess;

// Reducer

export type State = PendingResult<Challenge[], undefined>;
export const DEFAULT_STATE: State = pending;

export const toChallengeCollectionReducer = (challengeStatus: ChallengeStatus) => (
  state: State = DEFAULT_STATE,
  action: Action,
) => {
  switch (action.type) {
    case ActionType.LoadChallengesRequest:
      return action.payload.status === challengeStatus ? pending : state;
    case ActionType.LoadChallengesSuccess:
      return action.payload.status === challengeStatus
        ? ok(action.payload.challenges)
        : state;
    case ActionType.LoadChallengesFailure:
      return action.payload.status === challengeStatus ? error(undefined) : state;
    case ActionType.ToggleJoinedSuccess: {
      const { status, challengeId, progress, participants } = action.payload;
      if (status === challengeStatus && isOk(state)) {
        return ok(
          toChallengesAfterToggleJoined(state.value, challengeId, progress, participants),
        );
      }
      return state;
    }
    default:
      return state;
  }
};

const toChallengesAfterToggleJoined = (
  challenges: Challenge[],
  challengeId: string,
  progress: ChallengeProgress,
  participants: ChallengeParticipants,
) =>
  map(
    challenge =>
      pathEq(['challengeSummary', 'id'], challengeId)(challenge)
        ? { ...challenge, progress, participants }
        : challenge,
    challenges,
  );
