import React from 'react';
import { Language } from '@peloton/internationalize';
import { useReduxState } from '@engage/redux';
import { getDisplayLanguage } from '@engage/settings';
import { pseudoLocalization } from './pseudoLocalization';

export const PseudoProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [pseudoEnabled, setPseudoEnabled] = React.useState(false);
  const displayLanguage = useReduxState(getDisplayLanguage);

  React.useEffect(() => {
    if (displayLanguage === Language.PseudoLocalization) {
      pseudoLocalization.start();
      setPseudoEnabled(true);
    } else if (pseudoEnabled) {
      pseudoLocalization.stop();
      setPseudoEnabled(false);
    }
  }, [displayLanguage]);

  return <>{children}</>;
};
