import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import type { ErrorInfo } from 'react';
import type { ReportError } from '@peloton/error-reporting';
import { browserProvider } from '@peloton/newrelic/browser-provider';
import { ReportErrorContext, ERROR_REPORTER_FALLBACK } from './report-error-context';

type Props = {
  errorReporter?: ReportError;
  renderError: (props: RenderProps) => JSX.Element;
  children: React.ReactNode;
};

export type RenderProps = {
  error: Error;
  errorInfo?: ErrorInfo;
};

type State = Partial<RenderProps>;
export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {};

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const actionReporter = browserProvider.getActionReporter();
    this.setState({ error });

    const { errorReporter } = this.props;

    if (errorReporter) {
      actionReporter('Viewed error boundary', { message: error.message });
      errorReporter(error);
    }

    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
    const renderingError = new Error(error.message);
    renderingError.name = `OopsPageCrash`;
    renderingError.stack = errorInfo.componentStack ?? '';
    // Tyepscript doesn't like adding a standard .cause key to errors
    (renderingError as any).cause = error;
    datadogRum.addError(renderingError);
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children, errorReporter, renderError } = this.props;

    return (
      <ReportErrorContext.Provider value={errorReporter ?? ERROR_REPORTER_FALLBACK}>
        {error ? renderError({ error, errorInfo }) : children}
      </ReportErrorContext.Provider>
    );
  }
}
