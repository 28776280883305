import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1 } from '@engage/colors';
import { header5Large, header5Small } from '@engage/typography';
import { Button3Medium } from '@members/buttons';
import { FacebookCopy, Facebook } from '@members/copy';
import { CenteredContainer, FixedContainer } from './Containers';

const PermissionsErrorPrompt: React.FC<React.PropsWithChildren<ErrorProps>> = props => (
  <FixedContainer>
    <CenteredContainer>
      <MainText>
        <FacebookCopy id={Facebook.ErrorRetrieveFriends} />
      </MainText>
      <ErrorButton onClick={props.onCtaClick}>
        <FacebookCopy id={Facebook.ErrorRetrieveFriendsCta} />
      </ErrorButton>
    </CenteredContainer>
  </FixedContainer>
);

const ErrorPrompt: React.FC<React.PropsWithChildren<ErrorProps>> = props => (
  <FixedContainer>
    <CenteredContainer>
      <MainText>
        <FacebookCopy id={Facebook.ErrorLoading} />
      </MainText>
      <ErrorButton onClick={props.onCtaClick}>
        <FacebookCopy id={Facebook.ErrorLoadingCta} />
      </ErrorButton>
    </CenteredContainer>
  </FixedContainer>
);

const ErrorButton = styled(Button3Medium)`
  margin-top: 30px;
`;

const MainText = styled.div`
  ${header5Small}
  color: ${slate1};
  text-align: center;
  ${media.tablet`
    ${header5Large}
    color: ${slate1};
  `}
`;

type ErrorProps = {
  onCtaClick(): void;
};

export { ErrorPrompt, PermissionsErrorPrompt };
