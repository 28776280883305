import React from 'react';
import styled from 'styled-components';
import { slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { LoadingSpinner } from '@members/loading';

const LoadingState: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <LoadingSpinner isLoading size={40} fill={slate1} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: ${spaces.xxxHuge}px;
`;
export default LoadingState;
