import { createGlobalStyle } from 'styled-components';
import { reset } from '@peloton/css-reset';
import { oneTrustReset } from '@peloton/onetrust/styles';
import { visualRegressionHelpers } from '@peloton/styles';
import { membersReset } from './reset';

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${membersReset}
  ${visualRegressionHelpers}
  ${oneTrustReset}
`;
