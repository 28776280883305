import React from 'react';
import styled, { keyframes } from 'styled-components';
import useSWRImmutable from 'swr/immutable';
import { media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { toCloudinaryUrl } from '@engage/cloudinary';
import { slate2, white } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label14 } from '@engage/typography';
import { StacksCopy, Stacks } from '@members/copy';
import { StackedClassesFetchersGQL } from '@members/data-fetch';
import { Source, Notification, trackDisplayedStackNotification } from './analytics';
import { useOutsideNotificationAlerter } from './hooks';

export const StackNotification: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data } = useSWRImmutable(...StackedClassesFetchersGQL.ViewUserStackQuery({}));

  const numStackedClasses = data?.viewUserStack?.numClasses ?? -1;
  const [classAdded, setClassAdded] = React.useState(false);
  const [initNumStacked, setInit] = React.useState(numStackedClasses);
  const [backgroundImage, setBackgroundImage] = React.useState('');
  const [isDoneLoading, setIsDoneLoading] = React.useState(false);

  React.useEffect(() => {
    if (numStackedClasses === initNumStacked + 1) {
      if (
        data?.viewUserStack.__typename === 'StackResponseSuccess' &&
        numStackedClasses > 0
      ) {
        setBackgroundImage(
          data.viewUserStack.userStack.stackedClassList[numStackedClasses - 1]
            .pelotonClass.assets.thumbnailImage.location,
        );
      }
      setClassAdded(true);
    }
    setInit(numStackedClasses);
  }, [numStackedClasses]);

  React.useEffect(() => {
    if (isDoneLoading) {
      setTimeout(() => {
        setClassAdded(false);
        setIsDoneLoading(false);
      }, 3000);
    }
  }, [isDoneLoading]);

  return (
    <If condition={numStackedClasses > 0}>
      <If condition={classAdded}>
        <StackFabNotification
          numClassesInStack={numStackedClasses}
          backgroundImage={backgroundImage}
          isDoneLoading={isDoneLoading}
          setIsDoneLoading={setIsDoneLoading}
        />
      </If>
    </If>
  );
};

const StackFabNotification: React.FC<React.PropsWithChildren<NotifProps>> = ({
  backgroundImage,
  isDoneLoading,
  setIsDoneLoading,
  numClassesInStack,
}) => {
  const wrapperRef = React.useRef(null);
  const [isOpen] = useOutsideNotificationAlerter(wrapperRef);
  const trackAnalytics = useReduxAction(track);

  React.useEffect(() => {
    trackAnalytics(
      trackDisplayedStackNotification(
        numClassesInStack,
        Notification.AddedClass,
        Source.Navigation,
      ),
    );
  }, []);

  return (
    <If condition={isOpen}>
      <NotificationContainer
        ref={wrapperRef}
        data-test-id="stackFabNotificationTitle"
        style={isDoneLoading ? {} : { display: 'none' }}
      >
        <ThumbnailImage
          onLoad={() => {
            setIsDoneLoading(true);
          }}
          src={toCloudinaryUrl(backgroundImage)}
          alt=""
        />
        <NotificationText>
          <StacksCopy id={Stacks.ClassAdded} />.
        </NotificationText>
      </NotificationContainer>
    </If>
  );
};

const FadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const NotificationContainer = styled.div`
  height: 48px;
  width: 80%;
  right: 10%;
  background-color: ${slate2};
  display: flex;
  position: fixed;
  z-index: 1;
  top: 68px;
  border-radius: ${spaces.xSmall}px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: ${spaces.xSmall}px ${spaces.small}px ${spaces.xSmall}px ${spaces.small}px;
  gap: 10px;
  animation: ${FadeIn} 1s ease;
  ${media.tablet`
    right: 124px;
    top: 68px;
    width: fit-content;
    align-self: center;
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: -5px;
      right: 50%;
      background-color: ${slate2};
      box-sizing: border-box;
      border-bottom: 10px solid ${slate2};
      border-right: 10px solid ${slate2};
      transform: rotate(45deg);
      z-index: 98;
    }
    animation: ${FadeIn} 1s ease;
  `};
  ${media.desktopLarge`
    right: 148px;
    top: 68px;
  `};
`;

const NotificationText = styled.p`
  ${label14}
  color: ${white};
  overflow: hidden;
  white-space: nowrap;
`;

const ThumbnailImage = styled.img`
  height: 32px;
  width: 55.77px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  ${media.tablet`
    display: block;
  `};
`;

export type NotifProps = {
  numClassesInStack: number;
  backgroundImage: string;
  isDoneLoading: boolean;
  setIsDoneLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
