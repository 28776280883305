import type { ClassSelectorState } from '@engage/classes';
import { getClass } from '@engage/classes';
import { getClassId } from '@engage/video';

export const maybeClass = (
  state: ClassSelectorState & Parameters<typeof getClassId>[0],
) => {
  const classId = getClassId(state);
  return classId && getClass(state, classId);
};
