import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { fetchUserExists, successfulProfileUpdate, toUser } from '@peloton/auth';
import { BasicInfo as Message } from '@members/copy';
import { getFeatureToggle } from '@members/feature-toggles/redux';
import { updateBasicInfo as update } from '../api';
import type BasicInfo from '../models/BasicInfo';
import type { BasicInfoUpdateAction } from '../redux';

const updateBasicInfo = function* (
  client: Client,
  { presenter, info, changes, usernameChanged, emailChanged }: BasicInfoUpdateAction,
): SagaIterator {
  const isProfileUpdateEnabled = yield select(
    getFeatureToggle,
    'private_profile__preference_update',
  );

  const usernameExists = yield call(
    checkUsername,
    client,
    info,
    changes.includes('username') && usernameChanged,
  );

  const emailExists = yield call(
    checkEmail,
    client,
    info,
    changes.includes('email') && emailChanged,
  );

  const USERNAME_POLICY_VIOLATION_CODE = 3330;
  const LOCATION_POLICY_VIOLATION_CODE = 3331;
  const BIRTHDAY_ERROR_CODE = 3410;
  const MEETS_COMMUNITY_GUIDELINES_CODE = 3334;
  const INVALID_NAME_CODE = 153;

  const isInvalidField = (name: string) => {
    const specialCharacters = /[±!@£$%^*+§¡€#¢§¶•ªº«<>?:;|=,]/;

    return !!name.match(specialCharacters);
  };

  if (usernameExists) {
    presenter.addErrors('username', [Message.UsernameTakenError]);
  }
  if (emailExists) {
    presenter.addErrors('email', [Message.ExistingEmailAddressError]);
  }

  if (!usernameExists && !emailExists) {
    try {
      const updatedUser = yield call(update, client, info);
      yield put(successfulProfileUpdate(toUser(updatedUser.data)));
      presenter.displaySuccessMessage();
    } catch (error) {
      if (error.response?.data?.errorCode === BIRTHDAY_ERROR_CODE) {
        presenter.addApiError('birthday', error.response.data.message);
      } else if (error.response?.data?.errorCode === USERNAME_POLICY_VIOLATION_CODE) {
        presenter.displayUsernamePolicyViolationMessage();
      } else if (error.response?.data?.errorCode === LOCATION_POLICY_VIOLATION_CODE) {
        presenter.displayLocationPolicyViolationMessage();
      } else if (
        isProfileUpdateEnabled &&
        error.response?.data?.errorCode === MEETS_COMMUNITY_GUIDELINES_CODE
      ) {
        presenter.addErrors('firstName', [Message.MeetsCommunityGuidelinesError]);
        presenter.addErrors('lastName', [Message.MeetsCommunityGuidelinesError]);
      } else if (
        isProfileUpdateEnabled &&
        error.response?.data?.errorCode === INVALID_NAME_CODE &&
        isInvalidField(<string>info.firstName)
      ) {
        presenter.addErrors('firstName', [Message.InvalidFirstOrLastNameError]);
      } else if (
        isProfileUpdateEnabled &&
        error.response?.data?.errorCode === INVALID_NAME_CODE &&
        isInvalidField(<string>info.lastName)
      ) {
        presenter.addErrors('lastName', [Message.InvalidFirstOrLastNameError]);
      } else {
        presenter.displayErrorMessage();
      }
    }
  }
};

export const checkUsername = function* (
  client: Client,
  info: BasicInfo,
  changedUsernameOrEmail: boolean,
): SagaIterator {
  if (changedUsernameOrEmail) {
    return yield call(fetchUserExists, client, { username: info.username });
  } else {
    return false;
  }
};

export const checkEmail = function* (
  client: Client,
  info: BasicInfo,
  changedUsernameOrEmail: boolean,
): SagaIterator {
  if (changedUsernameOrEmail) {
    return yield call(fetchUserExists, client, { email: info.email });
  } else {
    return false;
  }
};

export default updateBasicInfo;
