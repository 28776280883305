import { Format, formatDate, toUtcTime } from '@peloton/time';
import type { CopyFile } from '@members/copy/CopyProvider/models';
import type {
  LiveClass,
  OnDemandInstructorClass,
  PelotonClass,
  ScenicClass,
} from '@members/graphql-swr/types.generated';
import type { ClassAvailabilityProps, TIERING_MODAL_TYPE } from './models';
import { QUOTA_REMINDER_MODAL } from './models';

export const shouldShowQuotaReminderModal = (userId?: string) => {
  if (!userId) {
    return false;
  }
  const maybeLocalStorage = window.localStorage?.getItem(
    `${QUOTA_REMINDER_MODAL}-${userId}`,
  );
  return maybeLocalStorage ? maybeLocalStorage === 'true' : true;
};

export const convertStringListToConjunctString = (
  words: string[],
  conjunction: string = 'and',
) => {
  const len = words.length;
  if (len === 0 || len === 1) {
    return words[0] ?? '';
  }
  const lastWord = words[len - 1];
  return words.slice(0, -1).join(', ') + ', ' + conjunction + ' ' + lastWord;
};

export const getFitnessSlugDisplayName = (slug: string, allCopy: CopyFile) => {
  return allCopy.fitnessDisciplineCollection?.[slug];
};

export const getClassAvailabilityProps = (
  classDetails: OnDemandInstructorClass | ScenicClass | LiveClass | PelotonClass,
) => {
  return !!classDetails?.contentAvailability
    ? ({
        contentAvailability: classDetails?.contentAvailability,
        freeForLimitedTime: classDetails?.freeForLimitedTime,
        isLimitedRide: classDetails?.isLimitedRide,
      } as ClassAvailabilityProps)
    : undefined;
};

export const getModalName = (modalType: TIERING_MODAL_TYPE) => {
  switch (modalType) {
    case 'quota_available_modal':
    case 'no_quota_available_modal':
      return 'Quota Modal';
    case 'upgrade_modal':
      return 'App Plus Gate';
    case 'quota_reminder_modal':
      return 'Quota Count Down';
    case 'exclusive_upgrade_modal':
      return 'Exclusive';
    case 'programs_gating_modal':
      return 'Program';
    case 'challenges_gating_modal':
      return 'Challenge';
    default:
      return 'No Title Found For Modal';
  }
};

export const getModalTitle = (modalType: TIERING_MODAL_TYPE) => {
  switch (modalType) {
    case 'quota_available_modal':
      return 'You have 3 cardio equipment classes left this month';
    case 'no_quota_available_modal':
      return 'You have 0 cardio equipment classes left this month';
    case 'upgrade_modal':
      return 'Upgrade to Peloton App+ for access to the entire experience';
    case 'quota_reminder_modal':
      return 'This uses 1 cardio equipment class';
    case 'exclusive_upgrade_modal':
      return 'Upgrade to take this class';
    case 'programs_gating_modal':
      return 'You may not be able to take every class in this program';
    case 'challenges_gating_modal':
      return 'You may not be able to complete the full challenge';
    default:
      return 'No Title Found For Modal';
  }
};

export const getQuotaRefreshDate = (limitedClassesResetDate: number) => {
  const quotaRefreshDayOfMonth = formatDate(
    toUtcTime(limitedClassesResetDate),
    Format.DayOfMonth,
  );

  const quotaRefreshMonthNameShortPeriod = formatDate(
    toUtcTime(limitedClassesResetDate),
    Format.MonthNameShort,
  );

  // The Month Name is returned with a period since it is abbreviated, we want to remove this
  // 3 is hardcoded here since the short month name should always be 3 chars
  const quotaRefreshMonthNameShort = quotaRefreshMonthNameShortPeriod.substring(0, 3);

  return { quotaRefreshMonthNameShort, quotaRefreshDayOfMonth };
};
