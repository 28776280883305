import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { defaultTransition, ellipsis } from '@peloton/styles';
import {
  inputErrorYellow,
  inputErrorRed,
  gray3,
  gray4,
  gray5,
  slate1,
} from '@engage/colors';
import { spaces } from '@engage/styles';
import { label16Caps, label6Large } from '@engage/typography';

const floatedLabelStyles = css`
  display: block;
  padding-top: ${spaces.xxSmall}px;
  ${label6Large}
`;

const emptyLabelStyles = css`
  align-items: center;
  display: flex;
`;

const sharedStyles = css<{ isEmptyField: boolean; transformText?: boolean }>`
  ${label16Caps}
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  ${defaultTransition('all', 200)}
  height: 100%;
  width: 100%;
  padding: 11px 15px;
  text-align: left;

  // floated label
  ${props => (props.isEmptyField ? emptyLabelStyles : floatedLabelStyles)}
  input:focus ~ & {
    ${floatedLabelStyles}
  }
  input:-webkit-autofill ~ & {
    ${floatedLabelStyles}
  }

  & > div {
    ${ellipsis}
    text-transform: ${props => (props.transformText ? 'lowercase' : 'none')};
    &::first-letter {
      text-transform: ${props => (props.transformText ? 'uppercase' : 'none')};
    }
  }
`;

type ColorMap = {
  disabled: string;
  error: string;
  floated: string;
  placeholder: string;
};

const LIGHT_COLORS: ColorMap = {
  disabled: transparentize(0.5, gray5),
  error: inputErrorRed,
  floated: slate1,
  placeholder: gray5,
};

const DARK_COLORS: ColorMap = {
  disabled: gray3,
  error: inputErrorYellow,
  floated: gray4,
  placeholder: gray3,
};

const toColor = (colorMap: ColorMap) => ({
  disabled,
  error,
  isEmptyField,
}: LabelProps) => {
  if (disabled) {
    return colorMap.disabled;
  }
  if (error) {
    return colorMap.error;
  }
  if (!isEmptyField) {
    return colorMap.floated;
  }
  return colorMap.placeholder;
};

const toThemedStyles = (colorMap: ColorMap) => css`
  color: ${toColor(colorMap)};
  input:-webkit-autofill ~ & {
    color: ${colorMap.placeholder};
  }
  input:focus ~ & {
    color: ${colorMap.floated};
  }
`;

const lightStyles = toThemedStyles(LIGHT_COLORS);
const darkStyles = toThemedStyles(DARK_COLORS);

const DarkLabel = styled.label<LabelProps>`
  ${sharedStyles}
  ${darkStyles}
`;

const LightLabel = styled.label<LabelProps>`
  ${sharedStyles}
  ${lightStyles}
`;

export type LabelProps = {
  disabled?: boolean;
  error?: boolean;
  isEmptyField: boolean;
  htmlFor: string;
  transformText?: boolean;
};

export { DarkLabel, LightLabel };
