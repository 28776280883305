import { transparentize } from 'polished';

export const superRed = '#FF3347';
export const superRedA11y = '#DF1C2F';
export const blue2 = '#4B99E9';
export const blue1 = '#406599';
export const blue = blue1;
export const darkFormScoreBlue = '#77BDFF';
export const black = '#000000';
export const dark = '#191b1e';
export const slate6 = '#131416';
export const slate5 = '#131416';
export const slate4 = '#111214';
export const slate3 = '#222529';
export const slate2pt5 = '#2F333A';
export const slate2 = '#4C525E';
export const slate1 = '#5A6474';
export const slateX = '#373B42';
export const gray6 = '#697180';
export const gray5 = '#8A8D91';
export const gray4 = '#A8ACB1';
export const gray3 = '#D2D5D9';
export const gray2 = '#E4E7EB';
export const gray1pt5 = ' #EDF0F2';
export const gray1 = '#F5F7F9';
export const backgroundGray = gray2; // Keeping this variable in case the background changes in the future
export const white = '#FFFFFF';
export const inputErrorYellow = '#FACB3E';
export const inputErrorRed = '#E60017';
export const backgroundInputGray = transparentize(0.9, white);
export const backgroundHoverInputGray = transparentize(0.8, white);
export const disabledInputGray = transparentize(0.5, slate3);
export const darkGrayMedium = transparentize(0.5, gray5);
export const offBlack = '#181a1d';
export const mediumGray = '#777a81';
export const freeLight = '#85e0cb';
export const freeDark = '#1f7a65';
export const customGray = '#f6f8fa';
export const disabledRed = '#ff959f';
