import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import {
  CALLBACK,
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  REACTIVATE_ACCOUNT_ROUTE,
  REMOTE_RESET_SUCCESS_ROUTE,
  INTERACTIVE_VOICE_RESPONSE_ROUTE,
} from './urls';

const Routes = [
  <Route
    key={LOGIN_ROUTE}
    exact={true}
    path={LOGIN_ROUTE}
    component={lazyLoader(() => import('./Login' /* webpackChunkName: "LoginPage" */))}
  />,
  <Route
    key={FORGOT_PASSWORD_ROUTE}
    exact={true}
    path={FORGOT_PASSWORD_ROUTE}
    component={lazyLoader(
      () => import('./ForgotPassword' /* webpackChunkName: "ForgotPasswordPage" */),
    )}
  />,
  <Route
    key={RESET_PASSWORD_ROUTE}
    path={RESET_PASSWORD_ROUTE}
    component={lazyLoader(
      () => import('./ResetPassword' /* webpackChunkName: "ResetPasswordPage" */),
    )}
  />,
  <Route
    key={REMOTE_RESET_SUCCESS_ROUTE}
    path={REMOTE_RESET_SUCCESS_ROUTE}
    component={lazyLoader(
      () =>
        import('./RemoteResetSuccess' /* webpackChunkName: "RemoteResetSuccessPage" */),
    )}
  />,
  <Route
    key={REACTIVATE_ACCOUNT_ROUTE}
    exact={true}
    path={REACTIVATE_ACCOUNT_ROUTE}
    component={lazyLoader(
      () => import('./ReactivateAccount' /* webpackChunkName: "ReactivateAccountPage" */),
    )}
  />,
  <Route
    key={CALLBACK}
    exact={true}
    path={CALLBACK}
    component={lazyLoader(
      () => import('./Callback' /* webpackChunkName: "OauthCallbackPage" */),
    )}
  />,
  <Route
    key={INTERACTIVE_VOICE_RESPONSE_ROUTE}
    exact={true}
    path={INTERACTIVE_VOICE_RESPONSE_ROUTE}
    component={lazyLoader(
      () =>
        import(
          './InteractiveVoiceResponse' /* webpackChunkName: "InteractiveVoiceResponsePage" */
        ),
    )}
  />,
];

export default Routes;
