import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { COLLECTIONS_ROUTE } from './urls';

const Routes = [
  <Route
    key={COLLECTIONS_ROUTE}
    path={COLLECTIONS_ROUTE}
    component={lazyLoader(
      () => import('./CollectionsPage' /* webpackChunkName: "CollectionsPage" */),
    )}
  />,
];

export default Routes;
