import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { label14 } from '@engage/typography';
import { Members, MembersCopy, Settings, SettingsCopy } from '@members/copy';
import { CustomizableToast } from '@members/notifications';
import ErrorIcon from '../icons/ErrorIcon';

export const ErrorToast: React.FC<
  React.PropsWithChildren<{
    showToast: boolean;
    onClose: () => void;
    source: string;
  }>
> = ({ showToast, onClose, source }) => {
  return showToast && source !== 'mod' ? (
    <StyledToast onClose={onClose} autoDismiss={true}>
      <ErrorIcon />
      <ToastTest>
        {source === 'block' && <MembersCopy id={Members.BlockError} />}
        {source === 'hide' && <SettingsCopy id={Settings.ErrorToast} />}
        {source === 'confirmHide' && <MembersCopy id={Members.HideError} />}
      </ToastTest>
    </StyledToast>
  ) : null;
};

const StyledToast = styled(CustomizableToast)`
  position: fixed;
  z-index: 5;
  width: 100%;
  ${media.tablet`
    width: 460px;
    height: 56px;
    top: 42px;
    left: 0;
    right: 0;
    margin: auto;
  `}
`;

const ToastTest = styled.div`
  padding-left: 8px;
  ${label14}
`;
