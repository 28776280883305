import type { Reducer } from 'redux';
import type {
  ApproveFollowerSuccessAction,
  RejectFollowerSuccessAction,
} from '@engage/me';
import { RelationshipsActionType } from '@engage/me';
import type { Me } from './models';

export const updateMe = (payload: Me) => ({
  type: MeActionTypes.Update,
  payload,
});

export const meReducer: Reducer<Me | {}> = (state = {}, action: Action) => {
  switch (action.type) {
    case MeActionTypes.Update:
      return {
        ...state,
        ...action.payload,
      };

    case RelationshipsActionType.ApproveSuccess:
    case RelationshipsActionType.RejectSuccess:
      return {
        ...state,
        totalPendingFollowers: (<Me>state).totalPendingFollowers - 1,
      };

    default:
      return state;
  }
};

type Action = MeUpdateAction | ApproveFollowerSuccessAction | RejectFollowerSuccessAction;

export const getMe = (state: MeSelectorState) => state.me;

export const getUserTotalPendingFollowers = (state: MeSelectorState) =>
  state.me?.totalPendingFollowers;

export type MeSelectorState = {
  me: Me;
};

type MeUpdateAction = {
  type: MeActionTypes.Update;
  payload: Me;
};

export enum MeActionTypes {
  Update = 'pelo/me/UPDATE',
}
