import React from 'react';
import { white } from '@engage/colors';

type Props = React.SVGProps<SVGSVGElement>;

export const Share: React.FC<React.PropsWithChildren<Props>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={props.stroke ?? white}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10.4 4.908h1.879a.666.666 0 0 1 .683.644v8.951a.666.666 0 0 1-.683.645H3.65a.665.665 0 0 1-.683-.645v-8.95a.665.665 0 0 1 .683-.645h1.737M10.323 2.938L7.95.681 5.58 2.938M7.951 1.021v8.72" />
    </g>
  </svg>
);
