// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Achievements = {
  EmptyState: 'emptyState',
  PageHeadingScreenReader: 'pageHeadingScreenReader',
  RecentAchievements: 'recentAchievements',
  TimesEarned: 'timesEarned',
  TimesAchieved: 'timesAchieved',
  Title: 'title',
  View: 'view',
} as const;
