import React from 'react';

const Modify: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
    >
      <path d="M7.371 2.493H2.823c-.717 0-1.299.624-1.299 1.395v9.767c0 .77.582 1.395 1.3 1.395h9.094c.718 0 1.3-.625 1.3-1.395V8.77" />
      <path d="M12.243 1.446a1.312 1.312 0 011.949 0 1.559 1.559 0 010 2.093L8.02 10.167l-2.598.697.65-2.79 6.17-6.628z" />
    </g>
  </svg>
);

export default Modify;
