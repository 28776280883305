import type { Locale } from '@peloton/internationalize';
import { getCollections, getPrograms, getClassGroupDetails } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

export const toClassGroupsFetchers = (api: typeof engageApi = engageApi) => ({
  GetCollections: toFetcher(
    () => `GetCollections`,
    (params: { acceptLanguage: Locale }) =>
      unwrap(() => getCollections(api, 'web', params.acceptLanguage)),
  ),
  GetPrograms: toFetcher(
    () => `GetPrograms`,
    () => unwrap(() => getPrograms(api, 'web')),
  ),
  GetClassGroupDetails: toFetcher(
    (params: { classGroupId: string; acceptLanguage: string }) =>
      `GetClassGroupDetails${params.classGroupId}`,
    params =>
      unwrap(() =>
        getClassGroupDetails(
          api,
          params.classGroupId,
          undefined,
          undefined,
          undefined,
          'web',
          params.acceptLanguage,
        ),
      ),
  ),
});
