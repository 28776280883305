/* eslint-disable @typescript-eslint/no-shadow */
import momentNamespace from 'moment';
// eslint-disable-next-line no-restricted-imports
import { pathOr } from 'ramda';
import type { BaseUser } from '@peloton/auth';
import type {
  LoggedInUserMapping,
  LoggedInUserMapping as LoggedInUserMappingV2,
} from '@engage/api-v2';

import type { Segment } from '@engage/class-detail';

export type VideoStream = {
  kind: StreamResultKind.VideoStream;
  token: string;
  streamHistoryId: string;
  streamSubscriptionId: string;
  streamSubscriptionIdType: string;
  url: string;
};

export type StreamHistory = {
  id: string;
  streamSubscriptionId: string;
  streamSubscriptionIdType: string;
};

export class StreamLimitReached {
  kind: StreamResultKind.StreamLimitReached;
}

export class NoActiveSubscription {
  kind: StreamResultKind.NoActiveSubscription;
}

export const toStreamLimitReached = (): StreamLimitReached => ({
  kind: StreamResultKind.StreamLimitReached,
});

export const toNoActiveSubscription = (): NoActiveSubscription => ({
  kind: StreamResultKind.NoActiveSubscription,
});

export const isVideoStream = (stream: StreamResult): stream is VideoStream =>
  stream.kind === StreamResultKind.VideoStream;

export enum StreamResultKind {
  VideoStream,
  StreamLimitReached,
  NoActiveSubscription,
}

export type StreamResult = VideoStream | StreamLimitReached | NoActiveSubscription;

export type Timeline = {
  classStartOffset?: number;
  classEndOffset?: number;
  videoEndOffset?: number;
  segments: Segment[];
};

const defaultWeight = 154;
const defaultIsFemale = true;
const defaultHeight = 67;
const defaultAge = 40;

export const calculateWithMETs = (
  mets: number,
  user?: BaseUser | LoggedInUserMapping | LoggedInUserMappingV2,
) => {
  const isFemale = user?.gender === 'male' ? false : defaultIsFemale;
  const weight = user?.weight || defaultWeight;
  const height = user?.height || defaultHeight;

  const USER_BIRTHDAY = user?.birthday && momentNamespace.unix(user?.birthday);

  const age =
    user && user.birthday ? momentNamespace().diff(USER_BIRTHDAY, 'years') : defaultAge;

  const weightMetricConverter = 0.453592;
  const heightMetricConverter = 2.54;

  let calorieReading: number;

  const convertedWeight = weight * weightMetricConverter;
  const convertedHeight = height * heightMetricConverter;

  if (isFemale) {
    calorieReading =
      ((9.65 * convertedWeight + 1.85 * convertedHeight - 4.68 * age + 655) / 86400) *
      mets;
  } else {
    calorieReading =
      ((13.75 * convertedWeight + 5 * convertedHeight - 6.76 * age + 66) / 86400) * mets;
  }
  return calorieReading;
};

export const getSegmentIntensityInMET = (segment: Segment): number =>
  pathOr(0, ['intensityInMets'], segment);

export const calculateWithHeartRateCalorieAlgorithm = (
  heartRate: number,
  user?: BaseUser | LoggedInUserMapping | LoggedInUserMappingV2,
) => {
  const isFemale = user?.gender === 'male' ? false : defaultIsFemale;
  const weight = user?.weight || defaultWeight;
  const USER_BIRTHDAY = user?.birthday && momentNamespace.unix(user?.birthday);

  const age =
    user && user.birthday ? momentNamespace().diff(USER_BIRTHDAY, 'years') : defaultAge;

  const constants = heartRateCalorieCalculationConstants(isFemale);

  const modifiedHeartRate = constants.heartRateMultiplier * heartRate;
  const vo2Max = getVO2Max(age, constants.VO2Max);
  const modifiedVO2 = constants.vo2Multiplier * vo2Max;
  const modifiedWeight =
    constants.weightMultiplier * weight * constants.weightMetricConverter;
  const modifiedAge = constants.ageMultiplier * age;

  const value =
    (constants.offset + modifiedHeartRate + modifiedVO2 + modifiedWeight + modifiedAge) /
    constants.normalizedDurationForOneSecond;

  return value;
};

const heartRateCalorieCalculationConstants = (isFemale: boolean) => {
  const normalizedDurationForOneSecond = 251.04;
  const weightMetricConverter = 0.453_592;
  const constantsObj = {
    normalizedDurationForOneSecond: normalizedDurationForOneSecond,
    weightMetricConverter: weightMetricConverter,
  };

  if (isFemale) {
    return {
      ...constantsObj,
      offset: -59.3954,
      VO2Max: [34.788, 34.788, 33.592, 32.656, 30.108, 29.068, 25.428],
      heartRateMultiplier: 0.48,
      vo2Multiplier: 0.38,
      weightMultiplier: 0.103,
      ageMultiplier: 0.274,
    };
  } else {
    return {
      ...constantsObj,
      offset: -95.7735,
      VO2Max: [47.268, 47.268, 44.408, 42.068, 40.664, 37.128, 33.644],
      heartRateMultiplier: 0.654,
      vo2Multiplier: 0.404,
      weightMultiplier: 0.394,
      ageMultiplier: 0.271,
    };
  }
};

const getVO2Max = (age: number, VO2Max: number[]) => {
  if (age <= 0) {
    return 0;
  }
  const idx = age < 60 ? Math.trunc(age / 10) : 6;
  return VO2Max[idx];
};

export type SuccessfulStreamDeletionResponse = {
  deleted: boolean;
  streamHistoryId: string;
};

export type Packet = PacketMetrics & {
  secondsOffsetFromStart: number;
};

// Packet type for the singular /stats/packet endpoint which is required for proper leaderboard ranking
export type LeaderboardPacket = PacketMetrics & {
  secondsSincePedalingStart: number;
  workoutId: string;
};

export type PacketMetrics = {
  calories?: number;
  heartRate?: number;
  avgHeartRate?: number;
  maxHeartRate?: number;
  cadence?: number;
  avgCadence?: number;
  maxCadence?: number;
  resistance?: number;
  avgResistance?: number;
  maxResistance?: number;
  power?: number;
  avgPower?: number;
  maxPower?: number;
  speed?: number;
  avgSpeed?: number;
  maxSpeed?: number;
  distance?: number;
  totalWork?: number;
  latitude?: number;
  longitude?: number;
  accuracy?: number;
  altitude?: number;
  pace?: number;
  avgPace?: number;
  bestPace?: number;
  elevation?: number;
  incline?: number;
  avgIncline?: number;
  maxIncline?: number;
  isManual?: boolean;
  subsegmentId?: string;
};

export type MetricCollectorResult = {
  playing: number;
  buffering: number;
  paused: number;
  idle: number;
};

export enum MetricV2State {
  Play = 'play',
  Buffer = 'buffer',
  Else = 'else',
}

export type MetricV2 = {
  state: MetricV2State;
  secondsSinceClassStart: number;
  timestamp: number;
};

export type MetricCollectorBatch = {
  batchNumber: number;
  isFinal: boolean;
  metrics: MetricV2[];
};

export type MetricReportV2 = {
  workoutId: string;
  batches: MetricCollectorBatch[];
};

export type BatchesToSend = Pick<MetricReportV2, 'workoutId'> & MetricCollectorBatch;

export type Casting = {
  isCasting: boolean;
  deviceNickname: string | null;
  timeCasting: number;
};

export enum CastProvider {
  Chromecast,
  AirPlay,
}
