import { put, take } from 'redux-saga/effects';
import type { FetchResultAction } from '@peloton/redux-fetch';
import { cacheAction } from '@peloton/redux-fetch';
import { fetchMetadata } from './api';
import { METADATA_NAMESPACE } from './redux';

export const cacheMetadataSaga = function* () {
  yield put(cacheAction(METADATA_NAMESPACE, fetchMetadata));
  yield take(isDone());
};

export const isDone = () => (action: FetchResultAction) =>
  action.meta?.namespace === METADATA_NAMESPACE;
