import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { LoadingSpinner } from '@members/loading';

export const Loading = styled(LoadingSpinner)`
  display: ${props => (props.isLoading ? 'block' : 'none')};
  fill: ${slate1};
  margin: auto;
  margin-bottom: 140px;
  margin-top: ${spaces.huge}px;
  ${media.tablet`
    margin-bottom: 200px;
  `}
`;
