import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & { plusColor?: string; bkgColor?: string };

export const Plus: React.FC<React.PropsWithChildren<Props>> = ({
  plusColor = 'white',
  bkgColor = 'black',
  fillOpacity = 0.15,
  ...props
}) => (
  <svg viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={32} fill={bkgColor} fillOpacity={fillOpacity} rx={16} />
      <path d="M9 9h14v14H9z" />
      <path
        fill={plusColor}
        d="M16.058 9.35c.484 0 .875.51.875 1.12v4.363h4.953c.45 0 .82.331.874.765l.007.11a.873.873 0 01-.88.875h-4.955v5.063c0 .619-.388 1.12-.875 1.12-.483 0-.875-.509-.875-1.12v-5.063H10.23a.876.876 0 110-1.75h4.953v-4.362c0-.62.389-1.121.875-1.121z"
      />
    </g>
  </svg>
);
