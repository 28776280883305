/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RideTimelineResponse } from '../../../api-v2-models/generated/RideTimelineResponse';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Ride Offsets and Segments
 * Return all of a ride's offsets and segments.  This includes offsets for when the class portion of the video begins, all of the segments within the class portion, the end time for the class, and the total duration of the class. If enabled, also includes subsegments of the segment. During a live class this endpoint will return every event that has happened so far.  For example, in the middle of the class portion of a live ride, this endpoint will include class_start_offset and one or more segments in the response, but class_end_offset and video_end_offset will not be included in the response for the live ride. Optionally, can return scheduled segments as well if query param provided.
 * @param id The ride id
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param showScheduledSegments whether or not to include scheduled_segments property
 * @returns RideTimelineResponse Successful response
 * @throws ApiError
 */
export const getRideTimeline = (
    api: BaseAPI,
    id: string,
    acceptLanguage?: string,
    showScheduledSegments?: boolean,
    options: any = {},
): AxiosPromise<RideTimelineResponse> => {
    const localVarPath = `/api/ride/${id}/timeline`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof showScheduledSegments !== 'undefined') {
        localVarQueryParameter['show_scheduled_segments'] = showScheduledSegments;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Ride Offsets and Segments for studio platforms
 * Return all of a ride's offsets and segments.  This includes offsets for when the class portion of the video begins, all of the segments within the class portion, the end time for the class, and the total duration of the class. If enabled, also includes subsegments of the segment. During a live class this endpoint will return every event that has happened so far.  For example, in the middle of the class portion of a live ride, this endpoint will include class_start_offset and one or more segments in the response, but class_end_offset and video_end_offset will not be included in the response for the live ride. Optionally, can return scheduled segments as well if query param provided.
 * @param id The ride id
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param showScheduledSegments whether or not to include scheduled_segments property
 * @returns RideTimelineResponse Successful response
 * @throws ApiError
 */
export const getStudioRideTimeline = (
    api: BaseAPI,
    id: string,
    acceptLanguage?: string,
    showScheduledSegments?: boolean,
    options: any = {},
): AxiosPromise<RideTimelineResponse> => {
    const localVarPath = `/api/studio/ride/${id}/timeline`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof showScheduledSegments !== 'undefined') {
        localVarQueryParameter['show_scheduled_segments'] = showScheduledSegments;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

