import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { isDefined } from '@peloton/types';
import { gray3, gray4, slate2 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import type { RelationshipChange } from '@engage/members';
import { spaces } from '@engage/styles';
import { TagModalView } from '@engage/tags';
import { label14, BulletSeparator } from '@engage/typography';
import { TagsCopy, Tags, useTags, Members, MembersCopy } from '@members/copy';
import { useLocale } from '@members/localization';
import {
  ShareTooltipSource,
  ShareTagTooltip,
  ShareTooltipContextProvider,
} from '@members/share-modal';
import { AddButton } from '../AddButton/AddButton';
import { HashTag } from '../HashTag';
import { useTagNameFromLocation } from '../hooks';
import { SharedHeader as Header } from '../Modal/TagsModalHeaderView';
import { ShareButton } from '../ShareButton';
import { TagsContext } from '../TagsContext';
import { Position } from '../useTooltip';

export type TagDetailHeaderViewProps = {
  isAdded: boolean;
  numberOfFollowing: number;
  numberOfMembers: number;
  detailBackgroundImageUrl: string | undefined;
  tagName: string;
  onMemberFollow(change: RelationshipChange): void;
};

export type TagDetailHeaderHeightProps = {
  headerHeight: number | undefined;
  setHeaderHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const STICKY_HEADER_HEIGHT = spaces.xxxHuge;
export const STICKY_HEADER_MOBILE_HEIGHT = spaces.xHuge;
const STICKY_TAG_HEIGHT = 42;

export const useHeaderHeight = (
  maskRef: React.RefObject<HTMLDivElement>,
  setHeaderHeight: (height: number) => void,
) => {
  React.useEffect(() => {
    if (isDefined(maskRef.current)) {
      const { height } = maskRef.current.getBoundingClientRect();
      setHeaderHeight(height);
    }
  }, [isDefined(maskRef.current)]);
};

export const TagDetailHeaderView: React.FC<
  React.PropsWithChildren<
    TagDetailHeaderViewProps & TagDetailHeaderHeightProps & { isSticky: boolean }
  >
> = ({
  isAdded,
  numberOfMembers,
  numberOfFollowing,
  detailBackgroundImageUrl,
  setHeaderHeight,
  tagName: tag,
  isSticky,
}) => {
  const { goBack, setView } = React.useContext(TagsContext);
  const tagName = useTagNameFromLocation(tag);
  const maskRef = React.useRef<HTMLDivElement>(null);
  const locale = useLocale();

  const handleClickHideTag = () => {
    setView(TagModalView.ConfirmHideTag);
  };

  useHeaderHeight(maskRef, setHeaderHeight);

  return (
    <ShareTooltipContextProvider originRef={maskRef}>
      <Mask
        backgroundImageUrl={detailBackgroundImageUrl}
        ref={maskRef}
        data-test-id="tagDetailsContainer"
      >
        <Header
          button={{
            content: useTags(Tags.BackCta),
            props: { onClick: goBack },
          }}
          overlayHeader
          data-test-id="backButton"
        />
        <HashTag tagName={tagName} isCreatingTag={false} isCentered={true} />
        <NumberOfMembers data-test-id="numberMembersInTagDetails">
          <TagsCopy
            id={Tags.Members}
            values={{ numberOfMembers: numberOfMembers.toLocaleString(locale) }}
          />
          <If condition={numberOfFollowing > 0}>
            <BulletSeparator />
            <TagsCopy
              id={Tags.Following}
              values={{ numberOfFollowing: numberOfFollowing.toLocaleString(locale) }}
            />
          </If>
        </NumberOfMembers>
        <Row isSticky={isSticky}>
          <AddButton isAdded={isAdded} />
          <ShareButton isDisabled={numberOfMembers === 0} />
          <ShareTagTooltip
            source={ShareTooltipSource.TagDetailShare}
            position={Position.Top}
          />
        </Row>
        <HideTagButton data-test-id="hideTagButton" onClick={handleClickHideTag}>
          <MembersCopy id={Members.HideTag} />
        </HideTagButton>
        <StickyHeader isSticky={isSticky}>
          <HashTag
            tagName={tagName}
            isCreatingTag={false}
            isCentered={true}
            isResponsive={false}
          />
        </StickyHeader>
      </Mask>
    </ShareTooltipContextProvider>
  );
};

const Mask = styled.div<{ backgroundImageUrl: string | undefined }>`
  width: 100%;
  background-color: ${slate2};
  background-image: ${props =>
    isDefined(props.backgroundImageUrl)
      ? `url(${props.backgroundImageUrl});`
      : 'linear-gradient(163deg, #5f6777 -7%, #1f2124 93%);'};
  background-position: top 50% left 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const HideTagButton = styled.button`
  all: unset;
  ${label14}
  color: ${gray4};
  margin-top: -${spaces.medium}px;
  margin-bottom: ${spaces.xLarge}px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: revert;
  }
`;

const NumberOfMembers = styled.div`
  ${label14}
  color: ${gray3};
  margin-top: ${spaces.small}px;
`;

const Row = styled.div<{ isSticky: boolean }>`
  padding-top: ${spaces.xxLarge}px;
  ${media.tablet`
    padding-top: ${spaces.xxxLarge}px;
  `}
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${spaces.small}px;
  padding-bottom: ${spaces.huge}px;
  ${media.tablet`
    padding-bottom: ${spaces.xHuge}px;
  `}
  opacity: ${({ isSticky }) => (isSticky ? 0 : 1)};
  transition: opacity 50ms ease-in-out;
`;

const StickyHeader = styled.div<{ isSticky: boolean }>`
  opacity: ${({ isSticky }) => (isSticky ? 1 : 0)};
  pointer-events: ${({ isSticky }) => (isSticky ? 'unset' : 'none')};
  transition: opacity 200ms ease-in-out;
  position: absolute;
  bottom: ${(STICKY_HEADER_MOBILE_HEIGHT - STICKY_TAG_HEIGHT) / 2}px;
  ${media.tablet`
    bottom: ${(STICKY_HEADER_HEIGHT - STICKY_TAG_HEIGHT) / 2}px;
  `}
`;
