import React from 'react';
import { slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';

const FBIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="2.08398"
      y="2.08331"
      width={spaces.medium}
      height={spaces.medium}
      rx="4"
      stroke={slate1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.623 14.2938L8.93151 14.2938V10.61H7.55664V9.08581H8.93151V7.9241C8.93151 6.603 9.73998 5.87277 10.9763 5.87277C11.5685 5.87277 12.1882 5.97582 12.1882 5.97582V7.2732H11.5053C10.8332 7.2732 10.623 7.67946 10.623 8.09683V9.08577H12.1242L11.8844 10.61H10.623L10.623 14.2938Z"
      fill={slate1}
    />
  </svg>
);

export default FBIcon;
