import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { hoverTransition, defaultTransition, hover, media } from '@peloton/styles';
import { slate1, gray3, white, superRed, slate2, gray5, gray1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label12Caps, label14Caps, label16Caps } from '@engage/typography';
import type { ActionButtonProps } from './SubmitButton';

export const BUTTON2_HOVER = '#596271';

export const buttonText = css`
  text-align: center;
  ${label14Caps}
`;

export const sharedButtonStyles = css`
  ${buttonText}
  border-radius: 50px;
  cursor: pointer;
  border: none;
`;

export const centerButtonContentStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const disabledButtonStyles = css`
  &:disabled {
    cursor: default;
    background-color: ${gray3};
    color: ${gray5};
    box-shadow: none;
    border: none;
    &:disabled {
      svg path {
        fill: ${gray5};
      }
    }
  }
`;

export const largeButtonStyles = css`
  ${label16Caps}
  height: ${spaces.xHuge}px;
  border-radius: ${spaces.xLarge}px;
  min-width: 200px;
  padding: 0 30px;
`;

export const mediumButtonStyles = css`
  ${label14Caps}
  height: ${spaces.huge}px;
  border-radius: ${spaces.large}px;
  min-width: 180px;
  padding: 0 ${spaces.medium}px;
`;

export const smallButtonStyles = css`
  height: ${spaces.xxxLarge}px;
  ${label12Caps}
  border-radius: ${spaces.medium}px;
  min-width: 96px;
  padding: 0 ${spaces.small}px;
`;

// Button 1
export const button1Styles = css`
  ${sharedButtonStyles}
  color: ${white};
  background-color: ${superRed};
  ${defaultTransition('filter, background-color, color')}
  ${hover`filter: brightness(85%);`}
&:active {
    filter: brightness(85%);
  }
  ${disabledButtonStyles}
`;

const Button1 = styled.button`
  ${button1Styles}
`;

const Button1Small = styled(Button1)`
  ${smallButtonStyles}
`;

const Button1Medium = styled(Button1)`
  ${mediumButtonStyles}
`;

const Button1Large = styled(Button1)`
  ${largeButtonStyles}
`;

// Button 2
export const button2Styles = css`
  ${sharedButtonStyles};
  background-color: ${slate1};
  color: ${white};
  ${defaultTransition('filter, background-color, color')}
  ${hover`filter: brightness(85%);`}
  &:active {
    filter: brightness(85%);
  }
  ${disabledButtonStyles};
`;

const Button2 = styled.button`
  ${button2Styles};
`;

const Button2Small = styled(Button2)`
  ${smallButtonStyles}
`;

const Button2Medium = styled(Button2)`
  ${mediumButtonStyles}
`;

const Button2Large = styled(Button2)`
  ${largeButtonStyles}
`;

// Button 3
export const button3Styles = css`
  ${sharedButtonStyles}
  border: 1px solid ${slate1};
  ${hoverTransition(
    {
      property: 'background-color',
      from: 'transparent',
      to: slate1,
    },
    {
      property: 'color',
      from: slate2,
      to: white,
    },
    {
      property: 'stroke',
      from: slate2,
      to: white,
    },
  )}
  &:active {
    background-color: ${slate1};
    color: white;
    filter: brightness(85%);
    stroke: white;
  }
  ${disabledButtonStyles}
`;

const Button3 = styled.button`
  ${button3Styles}
`;

const Button3Small = styled(Button3)`
  ${smallButtonStyles}
`;

const Button3Medium = styled(Button3)`
  ${mediumButtonStyles}
`;

const Button3Large = styled(Button3)`
  ${largeButtonStyles}
`;

// Button 4
export const button4Styles = css`
  ${sharedButtonStyles}
  color: ${white};
  border: 1px solid;
  ${hoverTransition(
    {
      property: 'background-color',
      from: 'transparent',
      to: slate1,
    },
    {
      property: 'color',
      from: slate2,
      to: white,
    },
    {
      property: 'border-color',
      from: gray3,
      to: slate1,
    },
  )}
  &:active {
    filter: brightness(85%);
    background-color: ${slate1};
    border-color: ${slate1};
    color: white;
  }
  ${disabledButtonStyles}
`;

const Button4 = styled.button`
  ${button4Styles}
`;

const Button4Small = styled(Button4)`
  ${smallButtonStyles}
`;

const Button4Medium = styled(Button4)`
  ${mediumButtonStyles}
`;

const Button4Large = styled(Button4)`
  ${largeButtonStyles}
`;

// Button 5
export const button5Styles = css`
  ${sharedButtonStyles}
  ${hoverTransition(
    {
      property: 'background-color',
      from: gray1,
      to: slate1,
    },
    {
      property: 'color',
      from: slate2,
      to: white,
    },
    {
      property: 'stroke',
      from: slate2,
      to: white,
    },
  )}

${defaultTransition('filter, background-color, color, stroke')}
&:active {
    filter: brightness(85%);
  }
  ${disabledButtonStyles}
`;

const Button5 = styled.button`
  ${button5Styles}
`;

const Button5Small = styled(Button5)`
  ${smallButtonStyles}
`;

const Button5Medium = styled(Button5)`
  ${mediumButtonStyles}
`;

const Button5Large = styled(Button5)`
  ${largeButtonStyles}
`;

// Button 6
export const button6Styles = css`
  ${sharedButtonStyles}
  ${hover`
  background-color: #d9d9d9;
  `};
  ${defaultTransition('filter, background-color, color')}
  &:active {
    background-color: #d9d9d9;
  }
  ${disabledButtonStyles}
`;

const ReloadButton = styled.button`
  ${button3Styles}
  background: transparent;
  border-radius: 22px;
  border: 1px solid ${slate1};
  color: ${slate1};
  height: 44px;
  margin-top: 10px;
  width: 180px;

  ${hover`
    background: ${slate1};
    border-color: ${slate1};
    color: white;
  `}

  ${media.tablet`
    margin-top: ${spaces.large}px;
  `}
`;

export const darkDisabled = css`
  :disabled {
    background-color: ${transparentize(0.9, white)};
    color: ${gray5};
    cursor: auto;
  }
`;

export const darkStyle1 = css`
  color: ${slate2};
  background-color: ${white};
  opacity: 0.8;
  ${hoverTransition({
    property: 'opacity',
    to: '0.95',
  })}
  ${defaultTransition('filter, opacity')}
  &:active {
    filter: brightness(120%);
  }
  ${darkDisabled} :disabled {
    opacity: 1;
  }
`;

export const disabledStyles = (color: string) => (props: ActionButtonProps) =>
  props.isSubmitting
    ? `:disabled {
        background-color: ${color};
        opacity: 1;
    }`
    : '';

// Dark Theme Button 1
const DarkButton1Large = styled.button<ActionButtonProps>`
  ${sharedButtonStyles}
  ${largeButtonStyles}
  ${darkStyle1}
  ${disabledStyles(white)}
`;

const DarkButton1Medium = styled.button<ActionButtonProps>`
  ${sharedButtonStyles}
  ${mediumButtonStyles}
  ${darkStyle1}
  ${disabledStyles(white)}
`;

const DarkButton1Small = styled.button<ActionButtonProps>`
  ${sharedButtonStyles}
  ${smallButtonStyles}
  ${darkStyle1}
  ${disabledStyles(white)}
`;

export const darkStyle2 = css`
  background-color: ${transparentize(0.85, white)};
  color: ${white};
  ${defaultTransition('filter')}
  &:active {
    filter: brightness(120%);
  }
  ${darkDisabled}
`;

// Dark Theme Button 2
const DarkButton2Large = styled.button<ActionButtonProps>`
  ${sharedButtonStyles}
  ${largeButtonStyles}
  ${darkStyle2}
  ${disabledStyles(transparentize(0.7, white))}
`;

const DarkButton2Medium = styled.button<ActionButtonProps>`
  ${sharedButtonStyles}
  ${mediumButtonStyles}
  ${darkStyle2}
  ${disabledStyles(transparentize(0.7, white))}
`;

const DarkButton2Small = styled.button<ActionButtonProps>`
  ${sharedButtonStyles}
  ${smallButtonStyles}
  ${darkStyle2}
  ${disabledStyles(transparentize(0.7, white))}
`;

export const darkStyle3 = css`
  border: 1px solid ${gray3};
  ${defaultTransition('filter')}
  &:active {
    filter: brightness(120%);
  }
  ${hoverTransition(
    {
      property: 'background-color',
      from: 'transparent',
      to: transparentize(0.05, white),
    },
    {
      property: 'color',
      from: white,
      to: slate2,
    },
    {
      property: 'border',
      to: 'none',
    },
  )}
  ${darkDisabled}
`;

// Dark Theme Button 3
const DarkButton3Large = styled(Button4Large)<ActionButtonProps>`
  ${darkStyle3}
  ${disabledStyles(white)}
`;

const DarkButton3Medium = styled(Button4Medium)<ActionButtonProps>`
  ${darkStyle3}
  ${disabledStyles(white)}
`;

const DarkButton3Small = styled(Button4Small)<ActionButtonProps>`
  ${darkStyle3}
  ${disabledStyles(white)}
`;

export {
  Button1Small,
  Button1Medium,
  Button1Large,
  Button2Small,
  Button2Medium,
  Button2Large,
  Button3Small,
  Button3Medium,
  Button3Large,
  Button4Small,
  Button4Medium,
  Button4Large,
  Button5Small,
  Button5Medium,
  Button5Large,
  ReloadButton,
  DarkButton1Large,
  DarkButton1Medium,
  DarkButton1Small,
  DarkButton2Large,
  DarkButton2Medium,
  DarkButton2Small,
  DarkButton3Large,
  DarkButton3Medium,
  DarkButton3Small,
};
