import { withState } from 'recompose';
import styled from 'styled-components';
import { media, hoverTransition } from '@peloton/styles';
import { slate3 } from '@engage/colors';
import { Cross } from '@members/icons';

export const StyledCross = styled(Cross)`
  fill: ${slate3};
  height: 10px;
  min-height: 10px;
  width: 10px;
  min-width: 10px;
  opacity: 1;
  ${media.tablet`
    opacity: 0.5;
    ${hoverTransition({
      property: 'opacity',
      to: '1',
    })}
  `}
`;

export const CloseEnhancer = withState('isClosed', 'didClose', false);
