import React from 'react';
import { useReduxAction } from '@engage/redux';
import type { PendingResult, TError } from '@engage/result';
import { isOk, isError, getOkValue } from '@engage/result';
import { flushCurrentBatches } from '@engage/video';
import { bufferingMetricsV2, MAXIMUM_DELAY_TIME } from '@members/pg-video/sagas';

type Props<T extends Record<string, PendingResult<any, Error>>> = {
  results: T;
  children: (props: T) => JSX.Element;
};

const hasError = (e: unknown): e is TError<Error> => Boolean(e);

export const ParallelDependencyFetcher = <
  T extends Record<string, PendingResult<any, Error>>
>({
  children,
  results,
}: Props<T>) => {
  const flushBatches = useReduxAction(flushCurrentBatches);
  const bufferMetrics = useReduxAction(bufferingMetricsV2);

  React.useEffect(() => {
    return () => {
      // attempt to send any old persisted metrics
      // set delay time to maximum so batches get cleared
      // regardless of success or failure
      flushBatches();
      bufferMetrics(true, MAXIMUM_DELAY_TIME);
    };
  }, []);

  const canRender = Object.values(results).every(isOk);
  const vals = Object.values(results).map(getOkValue);
  const err = Object.values(results).find(isError);

  if (hasError(err)) {
    throw err.value;
  }

  return React.useMemo(() => (canRender ? children(results) : null), [
    canRender,
    ...vals,
  ]);
};
