import type { OperatorData } from '@engage/video';

export default {
  toPacket: ({ offset, offsetMetrics }: OperatorData<'calories'>) =>
    offsetMetrics
      .filter((_: number, i: number) => i <= offset)
      .reduce((acc: number, v: number) => acc + v, 0),
  toValue: ({ offsetMetrics }: OperatorData<'calories'>) =>
    offsetMetrics.reduce((acc: number, v: number) => acc + v, 0),
};
