import React from 'react';

const UpsellIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1514 15.6062C23.059 14.5111 24 13.3756 24 12C24 10.6244 23.059 9.48888 22.1514 8.39375C21.6949 7.84294 21.2469 7.30234 20.9314 6.74657C20.5979 6.16241 20.3483 5.47166 20.0967 4.77537C19.611 3.4315 19.1179 2.067 18 1.40839C16.9211 0.775133 15.5475 1.0086 14.1593 1.24454C13.43 1.36849 12.6967 1.49313 12 1.49313C11.3033 1.49313 10.57 1.36849 9.84068 1.24454C8.45251 1.0086 7.0789 0.775133 6 1.40839C4.87868 2.0578 4.38598 3.4248 3.8989 4.77622C3.64877 5.4702 3.40012 6.16008 3.06859 6.74657C2.75306 7.30234 2.30506 7.84294 1.84858 8.39376C0.941034 9.48888 0 10.6244 0 12C0 13.3682 0.93095 14.4915 1.83395 15.5811C2.29547 16.1379 2.74969 16.686 3.06859 17.2534C3.40208 17.8376 3.6517 18.5283 3.90333 19.2246C4.38897 20.5685 4.88208 21.933 6 22.5916C7.0773 23.2239 8.4411 22.9921 9.82691 22.7565C10.5581 22.6322 11.2954 22.5069 12 22.5069C12.6967 22.5069 13.43 22.6315 14.1593 22.7555C15.5475 22.9914 16.9211 23.2249 18 22.5916C19.1213 21.9422 19.614 20.5752 20.1011 19.2238C20.3512 18.5298 20.5999 17.8399 20.9314 17.2534C21.2469 16.6977 21.6949 16.1571 22.1514 15.6062ZM15.9622 6.99112L15.0413 8.62283C16.5588 10.04 16.9673 12.3933 15.9103 14.2915C14.8403 16.1833 12.6353 17.0024 10.6639 16.3718L9.73655 18.0035C9.46418 18.4781 8.86756 18.6406 8.40064 18.3675C7.93372 18.0945 7.7716 17.4834 8.04397 17.0089L11.1633 11.5027C11.4356 11.0281 12.0322 10.8656 12.4992 11.1386C12.9661 11.4117 13.1282 12.0228 12.8558 12.4973C12.5835 12.9719 11.9868 13.1344 11.5199 12.8614L10.5407 14.5841C11.9414 15.4097 13.7378 14.9221 14.5484 13.4919C15.359 12.0683 14.8727 10.235 13.4719 9.40942C12.791 9.01287 11.9998 8.90236 11.2411 9.11039C10.4823 9.31841 9.8468 9.81247 9.4577 10.5016L7.75214 13.5114C7.3371 12.2958 7.42139 10.9111 8.10232 9.70846C9.17234 7.81672 11.3773 6.99762 13.3487 7.6282L14.2696 5.99649C14.5419 5.52193 15.1385 5.35941 15.6055 5.63245C16.0724 5.90548 16.2345 6.51656 15.9622 6.99112Z"
      fill="#ffffffff"
    />
  </svg>
);

export { UpsellIcon };
