import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { toClientDetailsHeader } from '@peloton/analytics';
import { TagsFetchersGQL } from '@members/data-fetch';
import type { Headers } from '@members/data-fetch/graphql-fetchers/utils';
import type { AddTagToUserMutation } from '@members/graphql-swr/schemas/tags/mutations/AddTagToUser.generated';
import type { RemoveTagFromUserMutation } from '@members/graphql-swr/schemas/tags/mutations/RemoveTagFromUser.generated';
import type { SetTagAsPrimaryMutation } from '@members/graphql-swr/schemas/tags/mutations/SetTagAsPrimary.generated';
import { LoadingSpinner } from '@members/loading';
import {
  useAddTagToUserMutationSWR,
  useRemoveTagFromUserMutationSWR,
} from '../AddButton/hooks';
import {
  useAnalyticsHeaders,
  useTrackReplaceTagFlow,
  toLeaderboardHeaderProps,
} from '../analytics';
import { useTagBrowseCategoryPaginatedQuery } from '../BrowseTags/hooks';
import { useSelectedTagName } from '../hooks';
import { Star } from '../icons';
import { APITypename } from '../models';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import {
  AddTagButton,
  CreateTagButton,
  ICON_SIZE,
  RemoveTagButton,
  SetPrimaryTagButton,
} from './Buttons';
import { LeaderboardTooltip } from './LeaderboardTooltip';
import { useSetTagAsPrimaryMutationSWR } from './useSetTagAsPrimaryMutationSWR';

export type CTAProps = {
  viewingOwnTag: boolean;
  isPrimary: boolean;
  isCreatingTag: boolean;
  hasAdded: boolean;
  tagName: string;
  currentIndex?: number;
  primaryTagIndex?: number;
  isOnlyTag?: boolean;
  setPrimaryTagIndex?: (index: number) => void;
};

export const onAddTagCompleted = (
  addTagResult: Pick<AddTagToUserMutation['addTagToUser'], '__typename'>,
  setShowCheck: (s: boolean) => void,
  setView: (v: TagModalView, t?: string) => void,
  setShowErrorToast: (e: boolean) => void,
  tagName: string,
  trackReplaceTag: () => void,
  setSelectedTagName: (name: string) => void,
) => {
  if (addTagResult.__typename === APITypename.ADD_TAG_TO_USER_SUCCESS) {
    setShowCheck(true);
    setView(TagModalView.TagDetails, tagName);
  } else if (addTagResult.__typename === APITypename.TAG_NAME_PROHIBITED_ERROR) {
    setShowErrorToast(true);
  } else if (addTagResult.__typename === APITypename.TAG_CAPACITY_OVER_LIMIT_ERROR) {
    setSelectedTagName(tagName);
    trackReplaceTag();
    setView(TagModalView.ReplaceTag);
  }
};

export const onRemoveTagCompleted = (
  removeTagResult: Pick<RemoveTagFromUserMutation['removeTagFromUser'], '__typename'>,
  setShowCheck: (s: boolean) => void,
) => {
  if (removeTagResult.__typename === APITypename.REMOVE_TAG_FROM_USER_SUCCESS) {
    setShowCheck(false);
  }
};

export const onSetTagCompleted = (
  setTagAsPrimaryResult: Pick<SetTagAsPrimaryMutation['setTagAsPrimary'], '__typename'>,
  currentIndex?: number,
  setPrimaryTagIndex?: (i?: number) => void,
) => {
  if (setTagAsPrimaryResult.__typename === APITypename.SET_TAG_AS_PRIMARY_SUCCESS) {
    setPrimaryTagIndex?.(currentIndex ?? 0);
  }
};

export const CTAButton: React.FC<React.PropsWithChildren<CTAProps>> = ({
  viewingOwnTag,
  isPrimary,
  isCreatingTag,
  hasAdded,
  tagName,
  isOnlyTag,
  currentIndex,
  setPrimaryTagIndex,
}) => {
  const [showCheck, setShowCheck] = React.useState(hasAdded);
  const { setView, setShowErrorToast, lastActiveBrowseCategory } = React.useContext(
    TagsContext,
  );
  const analyticsHeaderProps = useAnalyticsHeaders();
  const trackReplaceTag = useTrackReplaceTagFlow(tagName, analyticsHeaderProps);

  const { mutate: mutateTagBrowseCategory } = useTagBrowseCategoryPaginatedQuery({
    categorySlug: lastActiveBrowseCategory,
  });
  const { mutate: mutatePrimaryTag } = useSWR(
    ...TagsFetchersGQL.PrimaryTagQuery({ userId: undefined }),
  );

  const {
    mutate: removeTagFromUser,
    isLoading: loadingRemove,
  } = useRemoveTagFromUserMutationSWR(
    tagName,
    () => {
      mutateTagBrowseCategory();
      mutatePrimaryTag();
    },
    ({ removeTagFromUser: removeTagResult }) => {
      return onRemoveTagCompleted(removeTagResult, setShowCheck);
    },
    toClientDetailsHeader(analyticsHeaderProps) as Headers,
  );

  const { setSelectedTagName } = useSelectedTagName();

  const { mutate: addTagToUser, isLoading: loadingAdd } = useAddTagToUserMutationSWR(
    tagName,
    ({ addTagToUser: addTagResult }) => {
      return onAddTagCompleted(
        addTagResult,
        setShowCheck,
        setView,
        setShowErrorToast,
        tagName,
        trackReplaceTag,
        setSelectedTagName,
      );
    },
    () => {
      mutateTagBrowseCategory();
      mutatePrimaryTag();
    },
  );

  const setTagAsPrimaryMutationHeaders = toClientDetailsHeader(
    toLeaderboardHeaderProps(),
  ) as Headers;

  const {
    mutate: setTagAsPrimary,
    isLoading: loadingPrimary,
  } = useSetTagAsPrimaryMutationSWR(
    tagName,
    ({ setTagAsPrimary: setTagAsPrimaryResult }) => {
      return onSetTagCompleted(setTagAsPrimaryResult, currentIndex, setPrimaryTagIndex);
    },
    () => {
      mutatePrimaryTag();
    },
    setTagAsPrimaryMutationHeaders,
  );

  if (viewingOwnTag && isOnlyTag) {
    return null;
  }

  if (isCreatingTag) {
    return (
      <CreateTagButton
        addTagToUser={addTagToUser}
        tagName={tagName}
        isLoading={loadingAdd}
      />
    );
  }

  if (loadingAdd || loadingRemove || loadingPrimary) {
    return (
      <LoadingSpinnerContainer data-test-id="loadingSpinnerContainer">
        <LoadingSpinner isLoading={true} size={20} fill={transparentize(0.15, 'white')} />
      </LoadingSpinnerContainer>
    );
  }

  if (viewingOwnTag && !isOnlyTag) {
    return isPrimary ? (
      <TooltipContainer>
        <StarIcon data-test-id="currentLBTag" />
        <LeaderboardTooltip />
      </TooltipContainer>
    ) : (
      <SetPrimaryTagButton setTagAsPrimary={setTagAsPrimary} tagName={tagName} />
    );
  }

  if (showCheck) {
    return <RemoveTagButton removeTagFromUser={removeTagFromUser} tagName={tagName} />;
  }

  return <AddTagButton addTagToUser={addTagToUser} tagName={tagName} />;
};
const StarIcon = styled(Star)`
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;

const LoadingSpinnerContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
LoadingSpinnerContainer.displayName = 'LoadingSpinnerContainer';

const TooltipContainer = styled.div`
  position: relative;
  z-index: 2;
`;
TooltipContainer.displayName = 'TooltipContainer';
