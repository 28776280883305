import { call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { cancelDigitalSubscription } from '../api';
import type { SubsDigCancelRequestAction } from '../redux';
import { cancelDigSuccess, cancelDigFailure, loadSubscriptions } from '../redux';

const cancelSubscription = function* (
  client: Client,
  action: SubsDigCancelRequestAction,
) {
  try {
    const {
      sub: { id },
    } = action.payload;
    yield call(cancelDigitalSubscription, client, id);

    yield put(loadSubscriptions());
    yield put(cancelDigSuccess());
  } catch (e) {
    yield put(cancelDigFailure());
  }
};

export default cancelSubscription;
