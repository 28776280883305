import type { UserAttributes } from '@optimizely/optimizely-sdk';
import React from 'react';
import { ResultBranch } from '@engage/result';
import { useEnabledFeatures } from '@members/optimizely';
import type {
  provideVariableGate,
  Props as VariableGateProps,
} from './provideVariableGate';
import { useFeatureToggles } from './useFeatureToggles';

type VariableGate = ReturnType<typeof provideVariableGate>;

type ToggleGateProps = {
  userId: string;
  attributes?: UserAttributes;
  renderPending?: () => React.ReactElement<unknown>;
  renderError?: (error: unknown) => React.ReactElement<unknown>;
};
type OtherVariableGateProps = Omit<VariableGateProps, keyof ToggleGateProps>;

type Props<T extends VariableGate | undefined> = ToggleGateProps &
  (T extends VariableGate ? OtherVariableGateProps : {});

const Passthrough: React.FC<React.PropsWithChildren<VariableGateProps>> = ({
  children,
}) => <>{children}</>;

export const provideToggleGate = <T extends VariableGate>(
  variableGate?: T,
  // eslint-disable-next-line react/display-name
): React.FC<React.PropsWithChildren<Props<T>>> => ({
  children,
  userId,
  attributes,

  renderPending = () => null,
  renderError = () => <>{children}</>,
  ...vGateProps
}) => {
  const enabledFeatures = useEnabledFeatures(userId, attributes);
  useFeatureToggles(enabledFeatures);
  const VariableGateOrPassthrough = variableGate ?? Passthrough;

  return (
    <ResultBranch
      pendingResult={enabledFeatures}
      renderPending={renderPending}
      renderOk={() => (
        <VariableGateOrPassthrough
          userId={userId}
          attributes={attributes}
          renderPending={
            /* kindof a bummer this is required? 🤔 */
            renderPending as VariableGateProps['renderPending']
          }
          renderError={renderError as VariableGateProps['renderError']}
          {...vGateProps}
        >
          {children}
        </VariableGateOrPassthrough>
      )}
      renderError={renderError}
    />
  );
};
