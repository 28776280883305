import React from 'react';
import type { KeyedMutator } from 'swr';
import { isNil } from '@peloton/helpers/isNil';
import type { SearchTagQuery } from '@members/graphql-swr/schemas/tags/queries/SearchTag.generated';
import type { Tag } from '@members/graphql-swr/types.generated';
import { ErrorView } from '../ErrorView';
import { Loading } from '../Loading';
import { CreateTagView } from './CreateTagView';
import { EmptyState } from './EmptyStateView';
import { ScrollComponent } from './ScrollComponent';

type BodyProps = {
  isLoading: boolean;
  isTyping: boolean;
  inputIsEmpty: boolean;
  loadMore: () => void;
  refetch: KeyedMutator<SearchTagQuery>;
  showError: boolean;
  setShowError: (b: boolean) => void;
  searchResults?: SearchTagQuery | null;
};

const Body: React.FC<React.PropsWithChildren<BodyProps>> = ({
  isLoading,
  isTyping,
  inputIsEmpty,
  loadMore,
  refetch,
  showError,
  setShowError,
  searchResults,
}) => {
  const showLoading = isTyping || (isLoading && isNil(searchResults));
  const hasResults = (searchResults?.searchTag?.edges?.length ?? 0) > 0;
  const noResults = searchResults?.searchTag?.edges?.length === 0;
  const isCreateMode = !searchResults?.tag?.exists;

  if (showError) {
    return (
      <ErrorView
        tryAgain={() => {
          setShowError(false);
          refetch();
        }}
      />
    );
  } else if (showLoading) {
    return <Loading isLoading={showLoading} size={40} />;
  } else if (inputIsEmpty) {
    return <EmptyState />;
  } else if (noResults && isCreateMode) {
    return (
      <CreateTagView
        tag={searchResults?.tag as Partial<Tag> | undefined}
        withBorder={false}
      />
    );
  } else if (hasResults) {
    return <ScrollComponent loadMore={loadMore} searchResults={searchResults!} />;
  } else {
    return null;
  }
};

export { Body };
