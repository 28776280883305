import { isDefined } from '@peloton/types';
import type { RelationshipInfo } from '@engage/members/MemberCard';
import { toRelationshipInfo } from '@engage/members/MemberCard';
import type { Member } from '@engage/members/models/Member';
import {
  RelationshipCategory,
  shouldShowPrivateInfo,
} from '@engage/members/models/Member';
import type { MemberSelectorState } from '@engage/members/selectors';
import { getMember } from '@engage/members/selectors';
import type { FriendCounts, FriendType } from '@engage/overview';
import { isOk, isError, isPending } from '@engage/result';
import type { FriendCountsSelectorState } from './redux/friendCounts';
import { getFriendCounts } from './redux/friendCounts';
import type { FriendsSelectorState } from './redux/friends';
import { friendsNamespace } from './redux/friends';

export const toMemberBasicInfo = (
  member: Member,
  friendCounts: Maybe<FriendCounts>,
): MemberBasicInfo | undefined => {
  if (shouldShowPrivateInfo(member)) {
    return friendCounts ? toFullBasicInfo(member, friendCounts) : undefined;
  } else {
    return toPrivateBasicInfo(member);
  }
};

const toFullBasicInfo = (member: Member, friendCounts: FriendCounts): FullBasicInfo => ({
  ...toPreview(member),
  ...toRelationshipInfo(member),
  ...friendCounts,
  type: ProfileType.Full,
});

const toPrivateBasicInfo = (member: Member): PrivateBasicInfo => ({
  ...toPreview(member),
  type: ProfileType.Private,
  ...toRelationshipInfo(member),
});

const toPreview = (member: Member): MemberPreview => ({
  id: member.id,
  imageUrl: member.imageUrl,
  location: member.location,
  username: member.username,
});

export const getMemberBasicInfo = (
  state: BasicInfoSelectorState,
  idOrName: Maybe<string>,
): Maybe<MemberBasicInfo> => {
  const member = getMember(state, idOrName);
  const friendCounts = isDefined(member) ? getFriendCounts(state, member.id) : undefined;
  return isDefined(member) ? toMemberBasicInfo(member, friendCounts) : undefined;
};

export const isPendingFollower = (
  state: BasicInfoSelectorState,
  idOrName: Maybe<string>,
): Maybe<boolean> => {
  const memberInfo = getMemberBasicInfo(state, idOrName);
  return memberInfo && memberInfo.userToMeRelationship === RelationshipCategory.Pending;
};

export const toFollowerInfo = (
  member: Member,
  friendCounts: FriendCounts,
): FriendCountInfo => ({
  ...toFriendCountInfo(member),
  friendCount: friendCounts.totalFollowers,
});

export const toFollowingInfo = (
  member: Member,
  friendCounts: FriendCounts,
): FriendCountInfo => ({
  ...toFriendCountInfo(member),
  friendCount: friendCounts.totalFollowing,
});

const toFriendCountInfo = (member: Member) => ({
  id: member.id,
  username: member.username,
  ...toRelationshipInfo(member),
});

export const getFollowerInfo = (
  state: BasicInfoSelectorState,
  idOrName: Maybe<string>,
): Maybe<FriendCountInfo> => {
  const member = getMember(state, idOrName);
  if (!isDefined(member)) {
    return undefined;
  }
  const friendCounts = getFriendCounts(state, member.id);
  return friendCounts ? toFollowerInfo(member, friendCounts) : undefined;
};

export const getFollowingInfo = (
  state: BasicInfoSelectorState,
  idOrName: Maybe<string>,
): Maybe<FriendCountInfo> => {
  const member = getMember(state, idOrName);
  if (!isDefined(member)) {
    return undefined;
  }
  const friendCounts = getFriendCounts(state, member.id);
  return friendCounts ? toFollowingInfo(member, friendCounts) : undefined;
};

export const isFullBasicInfo = (info: MemberBasicInfo): info is FullBasicInfo =>
  info.type === ProfileType.Full;

export type MemberBasicInfo = PrivateBasicInfo | FullBasicInfo;

export type FullBasicInfo = RelationshipInfo &
  FriendCounts &
  MemberPreview & { type: ProfileType.Full };

export type PrivateBasicInfo = MemberPreview &
  RelationshipInfo & { type: ProfileType.Private };

type MemberPreview = {
  id: string;
  imageUrl: string;
  location?: string;
  username: string;
};

export enum ProfileType {
  Private = 'private',
  Full = 'full',
}
export type FriendCountInfo = RelationshipInfo & {
  friendCount: number;
  id: string;
  username: string;
};

export type BasicInfoSelectorState = MemberSelectorState & FriendCountsSelectorState;

type Maybe<T> = T | undefined;

// Friends
export const getFriends = (
  state: FriendsSelectorState,
  id: string,
  friendType: FriendType,
): string[] => {
  const namespace = state.friends[friendsNamespace(id, friendType)];
  return isDefined(namespace) && isOk(namespace) ? namespace.value.ids : [];
};

export const getNextPageToLoad = (
  state: FriendsSelectorState,
  id: string,
  friendType: FriendType,
): number | undefined => {
  const namespace = state.friends[friendsNamespace(id, friendType)];
  if (!isDefined(namespace)) {
    return 0;
  }
  if (isOk(namespace)) {
    const nextPage = namespace.value.nextPage;
    if (isOk(nextPage)) {
      return typeof nextPage.value === 'number' ? nextPage.value : undefined;
    } else {
      return isError(nextPage) ? nextPage.value.page : undefined;
    }
  } else {
    return 0;
  }
};

export const isLoadingNextPage = (
  state: FriendsSelectorState,
  id: string,
  friendType: FriendType,
): boolean => {
  const namespace = state.friends[friendsNamespace(id, friendType)];
  const nextPage =
    isDefined(namespace) && isOk(namespace) ? namespace.value.nextPage : undefined;
  const hasFriends =
    isDefined(namespace) && isOk(namespace) ? namespace.value.ids.length > 0 : false;
  return isDefined(nextPage) && hasFriends ? isPending(nextPage) : false;
};
