import React from 'react';

const Checkmark = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.4635 7.41928C14.6951 7.1633 14.6753 6.76807 14.4193 6.53651C14.1633 6.30494 13.7681 6.32474 13.5365 6.58072L8.08709 12.6047L5.93147 10.5478C5.68174 10.3095 5.28612 10.3188 5.04782 10.5685C4.80953 10.8183 4.8188 11.2139 5.06853 11.4522L7.68853 13.9522C7.81054 14.0686 7.97442 14.1308 8.14295 14.1246C8.31148 14.1184 8.47035 14.0443 8.58349 13.9193L14.4635 7.41928Z"
      fill="#50C4AA"
    />
  </svg>
);

export default Checkmark;
