import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { gray4, gray5, slate1, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label12 } from '@engage/typography';

const sharedStyles = css`
  ${label12}
  height: 32px;
  border-radius: 0px 0px 6px 6px;
  padding: 9px ${spaces.medium}px;
`;

const darkStyles = css`
  background-color: ${transparentize(0.9, gray5)};
  color: ${gray4};
`;

//Placeholder built from context, actual design should change this.
const lightStyles = css`
  background-color: ${transparentize(0.9, white)};
  color: ${slate1};
`;

const DarkHelptext = styled.div`
  ${sharedStyles}
  ${darkStyles}
`;

const LightHelptext = styled.div`
  ${sharedStyles}
  ${lightStyles}
`;

export { DarkHelptext, LightHelptext };
