import React from 'react';

const HamburgerMenu: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5C2 4.72386 2.22386 4.5 2.5 4.5H21.5C21.7761 4.5 22 4.72386 22 5C22 5.27614 21.7761 5.5 21.5 5.5H2.5C2.22386 5.5 2 5.27614 2 5ZM2 12C2 11.7239 2.22386 11.5 2.5 11.5H21.5C21.7761 11.5 22 11.7239 22 12C22 12.2761 21.7761 12.5 21.5 12.5H2.5C2.22386 12.5 2 12.2761 2 12ZM2.5 18.5C2.22386 18.5 2 18.7239 2 19C2 19.2761 2.22386 19.5 2.5 19.5H21.5C21.7761 19.5 22 19.2761 22 19C22 18.7239 21.7761 18.5 21.5 18.5H2.5Z"
    />
  </svg>
);

export { HamburgerMenu };
