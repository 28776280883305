import type { ClientDetails, ScreenProps } from '@peloton/analytics';
import { toClientDetailsHeader } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import { toTime, toUtcTime } from '@peloton/time';
import type { Omit } from '@peloton/types';
import type { ApiClass, Class } from '@engage/classes';
import { toClass } from '@engage/classes';
import type { ClassWorkout, FreestyleWorkout, Workout } from '../models';
import { isClassWorkout } from '../models';

// fetching

// const NUM_WORKOUTS_PAGE = 10;

const toWorkoutUrl = (workoutId: string) => `/api/workout/${workoutId}`;

export const fetchWorkoutDetails = (api: Client, workoutId: string) =>
  api
    .get(toWorkoutUrl(workoutId), toSkipErrorHandlingConfig({}, { 403: true }))
    .then(pipeData(toSplitResponse));

// Complete Workout
const toEndWorkoutUrl = (workoutId: string) => `api/workout/${workoutId}/end`;

export const endWorkout = (
  api: Client,
  workoutId: string,
  analytics: ClientDetails<ScreenProps>,
) =>
  api
    .post(toEndWorkoutUrl(workoutId), {}, toClientDetailsHeader(analytics))
    .then(pipeData(toSplitResponse));

// Complete Workout V2
const toEndWorkoutV2Url = (workoutId: string) => `api/v2/workout/${workoutId}/end`;

export const endWorkoutV2 = (
  api: Client,
  workoutId: string,
  analytics: ClientDetails<ScreenProps>,
  discard?: boolean,
) =>
  api
    .post(
      toEndWorkoutV2Url(workoutId),
      { discard: !!discard },
      toClientDetailsHeader(analytics),
    )
    .then(pipeData(toSplitResponse));

// Delete Workout
export const deleteWorkout = (
  api: Client,
  workoutId: string,
  analyticsProps: ClientDetails<ScreenProps>,
) => api.delete(toWorkoutUrl(workoutId), toClientDetailsHeader(analyticsProps));

// mappers

export type SplitResponse = {
  klass?: Class;
  workout: Workout;
};

export const toSplitResponse = (response: ApiWorkout): SplitResponse => ({
  klass: response?.ride ? toClass(response as { ride: ApiClass }) : undefined,
  workout: toWorkout(response),
});

export const toWorkout = (apiWorkout: ApiWorkout) =>
  isClassWorkout(apiWorkout)
    ? toClassWorkout(apiWorkout)
    : toFreestyleWorkout(apiWorkout);

export const toSharedWorkoutData = ({
  deviceTimeCreatedAt,
  endTime,
  startTime,
  ...apiWorkout
}: ApiWorkout) => ({
  ...apiWorkout,
  /*
   * device_time_created_at` is provided as a UTC timestamp, but it should be treated as “zoneless” by the client.
   * In other words, this timestamp should *not* be converted from UTC to the local time zone - it should be treated
   * as if it were already in the local time zone.
   */
  deviceTimeCreatedAt: toUtcTime(deviceTimeCreatedAt),
  endTime: toTime(endTime),
  startTime: toTime(startTime),
});

const toClassWorkout = (apiWorkout: ApiWorkout): ClassWorkout =>
  ({
    ...toSharedWorkoutData(apiWorkout),
    classId: apiWorkout?.ride?.id || apiWorkout?.rideId,
  } as ClassWorkout);

const toFreestyleWorkout = (apiWorkout: ApiWorkout): FreestyleWorkout =>
  ({
    ...toSharedWorkoutData(apiWorkout),
    instructorName: apiWorkout?.ride?.instructor?.name || '',
    imageUrl: apiWorkout?.ride?.instructor?.imageUrl || '',
    // TODO: there are a few places we are still relying on the just ride class as a class, but this should be removed
    classId: apiWorkout?.ride?.id || apiWorkout?.rideId,
    gymTitle: apiWorkout?.ride?.title,
  } as FreestyleWorkout);

// models

export type ApiWorkout = Omit<
  Workout,
  'deviceTimeCreatedAt' | 'endTime' | 'startTime' | 'classId'
> & {
  deviceTimeCreatedAt: number;
  endTime: number;
  ride?: ApiClass;
  startTime: number;
  rideId?: string;
};
