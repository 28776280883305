import type { SagaIterator } from 'redux-saga';
import { spawn, takeEvery, getContext } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import {
  fetcherSaga as subscriptionPlansFetcherSaga,
  PlanType,
} from '@peloton/subscription-plans';
import { BasicInfoActionType, PasswordChangeActionType } from '../redux';
import loadBasicInfo from './loadBasicInfo';
import updateBasicInfo from './updateBasicInfo';
import updatePassword from './updatePassword';

const settingsSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield spawn(subscriptionPlansFetcherSaga, PlanType.Digital);
  yield takeEvery(BasicInfoActionType.BasicInfoRequest, loadBasicInfo, client);
  yield takeEvery(BasicInfoActionType.BasicInfoUpdate, updateBasicInfo, client);
  yield takeEvery(PasswordChangeActionType.PasswordChange, updatePassword, client);
};

export default settingsSaga;
