export const gold = '#FDAB0B';
export const paleYellow = '#FFE28C';
export const teal = '#50C4AA';
export const lime = '#B6C95C';
export const yellow = '#FACB3E';
export const orange = '#FC800F';
export const watermelon = '#FF4759';
export const paleBlue2 = '#DBEBFB';
export const paleBlue1 = '#60ABD2';
export const paleBlue = paleBlue1;
