import React from 'react';

const FindMembersNew: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#222529"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1ZM0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 11.9435 18.0775 14.1714 16.5618 15.8547L19.8536 19.1465C20.0489 19.3417 20.0489 19.6583 19.8536 19.8536C19.6583 20.0489 19.3417 20.0489 19.1465 19.8536L15.8547 16.5618C14.1714 18.0775 11.9435 19 9.5 19C4.25329 19 0 14.7467 0 9.5ZM7.45852 4.98594C7.89879 4.41619 8.58163 4 9.5 4C10.4184 4 11.1012 4.41619 11.5415 4.98594C11.97 5.54056 12.1667 6.23425 12.1667 6.83333C12.1667 7.62574 12.0949 8.18432 11.9191 8.63541C11.7384 9.09919 11.4646 9.40381 11.1692 9.70379C11.1673 9.70571 11.1668 9.70715 11.1667 9.70761V10.1615L13.5571 11.1177C14.1266 11.3454 14.5 11.897 14.5 12.5104V13.5C14.5 13.7761 14.2761 14 14 14C13.7239 14 13.5 13.7761 13.5 13.5V12.5104C13.5 12.3059 13.3755 12.1221 13.1857 12.0461L10.7953 11.09C10.4156 10.9381 10.1667 10.5704 10.1667 10.1615V9.70711C10.1667 9.44017 10.2737 9.18802 10.4567 9.0022C10.7304 8.72413 10.8835 8.5389 10.9874 8.27234C11.0962 7.99311 11.1667 7.58088 11.1667 6.83333C11.1667 6.43241 11.03 5.95944 10.7502 5.59739C10.4821 5.25048 10.0816 5 9.5 5C8.91837 5 8.51788 5.25048 8.24981 5.59739C7.97005 5.95944 7.83333 6.43241 7.83333 6.83333C7.83333 7.41311 7.90063 7.82611 8.01802 8.15306C8.13358 8.4749 8.30712 8.73975 8.55766 9.01199C8.72447 9.19324 8.83333 9.43781 8.83333 9.70711V10.1615C8.83333 10.5704 8.58438 10.9381 8.20472 11.09L5.8143 12.0461C5.62448 12.1221 5.5 12.3059 5.5 12.5104V13.5C5.5 13.7761 5.27614 14 5 14C4.72386 14 4.5 13.7761 4.5 13.5V12.5104C4.5 11.897 4.87343 11.3454 5.44291 11.1177L7.83333 10.1615V9.70772C7.83329 9.70751 7.83321 9.70719 7.83307 9.70675C7.83292 9.70626 7.8327 9.70564 7.83239 9.70488C7.83095 9.70137 7.8278 9.69564 7.82184 9.68917C7.50144 9.34102 7.24677 8.96421 7.07685 8.491C6.90878 8.0229 6.83333 7.48746 6.83333 6.83333C6.83333 6.23425 7.02995 5.54056 7.45852 4.98594Z"
    />
  </svg>
);

export { FindMembersNew };
