import { transparentize } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { toCloudinaryUrl } from '@engage/cloudinary';
import { white, slate3, slate2, slate1, gray2 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { spaces } from '@engage/styles';
import { label14, label12Caps } from '@engage/typography';
import { hoverStyle } from '@members/animations';
import { Tags, TagsCopy, useTags } from '@members/copy';
import { useLocale } from '@members/localization';
import { HashTag } from '../HashTag';
import { useSelectedTagName } from '../hooks';
import { MetaTag } from '../models';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import type { CTAProps } from './CtaButton';
import { CTAButton } from './CtaButton';

export type Props = CTAProps & {
  tagName: string;
  metaTag: MetaTag;
  numberOfMembers: number;
  numberOfFollowing: number;
  backgroundImageUrl: string;
  viewingFromBrowse?: boolean;
};

export const Numbers: React.FC<
  React.PropsWithChildren<{
    isCreatingTag: boolean;
    numberOfMembers: number;
    numberOfFollowing: number;
  }>
> = ({ isCreatingTag, numberOfFollowing, numberOfMembers }) => {
  const numMembers = numberOfMembers.toLocaleString(useLocale());
  const numFollowing = numberOfFollowing.toLocaleString(useLocale());
  return (
    <NumbersContainer isCreatingTag={isCreatingTag}>
      <NumberMembersContainer data-test-id="numberMembersInTag">
        <TagsCopy id={Tags.Members} values={{ numberOfMembers: numMembers }} />
      </NumberMembersContainer>
      <If condition={numberOfFollowing > 0}>
        <NumberFollowingContainer data-test-id="numberFollowingContainer">
          ·
          <FollowingText>
            <TagsCopy id={Tags.Following} values={{ numberOfFollowing: numFollowing }} />
          </FollowingText>
        </NumberFollowingContainer>
      </If>
    </NumbersContainer>
  );
};

export const MetaTagInfo: React.FC<
  React.PropsWithChildren<{
    isPrimary: boolean;
    metaTag: MetaTag;
    viewingFromBrowse: boolean;
  }>
> = ({ isPrimary, metaTag, viewingFromBrowse }) => {
  const leaderboardTagCopy = useTags(Tags.LeaderboardTag);
  return (
    <If condition={!viewingFromBrowse}>
      {isPrimary ? (
        <MetaTagContainer data-test-id="metaLeaderboardTag">
          {leaderboardTagCopy}
        </MetaTagContainer>
      ) : (
        <If condition={metaTag !== MetaTag.NONE}>
          <MetaTagContainer>{metaTag}</MetaTagContainer>
        </If>
      )}
    </If>
  );
};

export const useClickHandler = () => {
  const { setView } = React.useContext(TagsContext);
  const { setSelectedTagName } = useSelectedTagName();
  const cb = React.useCallback(
    (tagName: string) => {
      setSelectedTagName(tagName);
      setView(TagModalView.TagDetails, tagName);
    },
    [setView, setSelectedTagName],
  );

  return cb;
};

export const Tag: React.FC<React.PropsWithChildren<Props>> = ({
  tagName,
  metaTag,
  backgroundImageUrl,
  numberOfMembers,
  numberOfFollowing,
  isCreatingTag,
  isPrimary,
  viewingFromBrowse = false,
  ...props
}) => {
  const onContainerClick = useClickHandler();
  return (
    <Wrapper
      bgImage={toCloudinaryUrl(backgroundImageUrl, { width: 560 })}
      isCreatingTag={isCreatingTag}
      data-test-id="tagWrapper"
    >
      <Container
        disabled={isCreatingTag}
        onClick={() => onContainerClick(tagName)}
        data-test-id="tagCard"
      >
        <TextContainer>
          <HashTag isCreatingTag={isCreatingTag} tagName={tagName} />
          <InfoContainer isCreatingTag={isCreatingTag}>
            <MetaTagInfo
              metaTag={metaTag}
              isPrimary={isPrimary}
              viewingFromBrowse={viewingFromBrowse}
            />
            <Numbers
              isCreatingTag={isCreatingTag}
              numberOfMembers={numberOfMembers}
              numberOfFollowing={numberOfFollowing}
            />
          </InfoContainer>
        </TextContainer>
      </Container>
      <CTAButtonContainer>
        <CTAButton
          isCreatingTag={isCreatingTag}
          tagName={tagName}
          isPrimary={isPrimary}
          {...props}
        />
      </CTAButtonContainer>
    </Wrapper>
  );
};

const MetaTagContainer = styled.p`
  color: ${gray2};
  width: fit-content;
  background-color: ${transparentize(0.6, slate3)};
  border-radius: ${spaces.xxxSmall}px;
  height: fit-content;
  ${label12Caps}
  margin-right: ${spaces.xxSmall}px;
  padding: ${spaces.xxxxSmall}px ${spaces.xSmall}px;

  ${media.tablet`
    margin-right: ${spaces.xSmall}px;
  `}
`;
MetaTagContainer.displayName = 'MetaTagContainer';

const NumberMembersContainer = styled.div`
  display: flex;
  margin-right: ${spaces.xxSmall}px;
`;

const NumberFollowingContainer = styled.div`
  display: flex;
`;
NumberFollowingContainer.displayName = 'NumberFollowingContainer';

const NumbersContainer = styled.div<{ isCreatingTag: boolean }>`
  display: flex;
  color: ${({ isCreatingTag }) => (isCreatingTag ? slate1 : gray2)};
  ${label14}
  margin-bottom: ${spaces.xSmall}px;
`;

const InfoContainer = styled.div<{ isCreatingTag: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  margin-top: ${spaces.xxSmall}px;

  ${media.tablet`
    flex-direction: row;
    margin-top: ${spaces.small}px;
  `}

  color: ${p => (p.isCreatingTag ? slate1 : white)};
`;

const FollowingText = styled.p`
  margin-left: ${spaces.xxSmall}px;
`;

const hoverStyleCss = css`
  ${hoverStyle}
  &::before {
    pointer-events: none;
    opacity: 0.05;
    z-index: 1;
    border-radius: ${spaces.xxSmall}px;
  }
`;

const Container = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  width: 100%;
  height: 110px;
  border-radius: ${spaces.xxSmall}px;
  padding: ${spaces.small}px 0 ${spaces.small}px ${spaces.small}px;
  position: relative;
  overflow: hidden;

  ${media.tablet`
    padding: ${spaces.xLarge + 2}px 0 ${spaces.xLarge + 2}px ${spaces.medium}px;
  `}
`;

const TextContainer = styled.div`
  padding-top: ${spaces.xxSmall}px;
  width: 100%;

  ${media.tablet`
    padding-top: 0;
  `}
`;

const CTAButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${spaces.medium}px 0 ${spaces.xSmall}px;
`;

const Wrapper = styled.li<{ bgImage: string; isCreatingTag: boolean }>`
  background: url(${p => p.bgImage}) center;
  background-color: ${p => (p.isCreatingTag ? white : slate2)};
  background-size: cover;
  border-radius: ${spaces.xxSmall}px;
  position: relative;
  width: 100%;
  height: 110px;
  list-style-type: none;

  display: flex;
  justify-content: space-between;

  ${hoverStyleCss}
`;
