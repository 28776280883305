import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { SubscriptionsRedirect } from './SubscriptionsRedirect';
import { PREFS_ROUTE, SUBS_ROUTE } from './urls';

const Routes = [
  <Route
    key={PREFS_ROUTE}
    path={PREFS_ROUTE}
    component={lazyLoader(
      () => import('./PreferencesPage' /* webpackChunkName: "PreferencesPage" */),
    )}
  />,
  <Route
    key={SUBS_ROUTE}
    exact={true}
    path={SUBS_ROUTE}
    render={() => <SubscriptionsRedirect />}
  />,
];

export default Routes;
