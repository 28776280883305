import React from 'react';

const CircledPlay: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM10.5241 8.04312C9.85783 7.63311 9 8.11246 9 8.89478V15.1052C9 15.8875 9.85783 16.3669 10.5241 15.9569L15.5701 12.8517C16.2046 12.4612 16.2046 11.5388 15.5701 11.1483L10.5241 8.04312ZM10 8.89478L15.046 12L10 15.1052V8.89478Z"
    />
  </svg>
);

export { CircledPlay };
