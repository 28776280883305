import React from 'react';
import styled from 'styled-components';

const Page: React.FC<React.PropsWithChildren<unknown>> = props => (
  <Wrapper>{props.children}</Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
`;

export default Page;
