import type { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { track } from '@engage/analytics';
import { PrivacyUpdateSource, trackUpdatedPrivacySettings } from '../analytics';
import { updateShareChallengeCompletion as update } from '../api';
import type { UpdateShareChallengeCompletionAction } from '../redux';

export default function* (
  client: Client,
  { presenter, corporateWellnessGroup }: UpdateShareChallengeCompletionAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  if (userId) {
    try {
      yield call(update, client, userId, corporateWellnessGroup);
      yield put(
        track(
          trackUpdatedPrivacySettings(
            PrivacyUpdateSource.Notification,
            { corporateWellnessGroup },
            {
              corporateWellnessGroup: {
                ...corporateWellnessGroup,
                shareCorporateWellnessCompletion: null,
              },
            },
          ),
        ),
      );
      presenter.displaySuccessMessage();
    } catch {
      presenter.displayErrorMessage();
    }
  } else {
    presenter.redirectToLoginPage();
  }
}
