import { Locale } from '@peloton/internationalize';
import { Format } from '@peloton/time';
import type { DateTimeInfo } from './dateTime';
import { SupportedFormat } from './SupportedFormat';

export enum TimeFormat {
  TwelveHour,
  TwentyFourHour,
}

export enum DateFormat {
  MonthDayYear,
  DayMonthYear,
}

export type FormatMap = Record<SupportedFormat, string>;

export const getFormatStringsForLocale = (locale: Locale): FormatMap =>
  getInfoForLocale(locale).formatStrings;

export const getInfoForLocale = (locale: Locale): DateTimeInfo => info[locale];

const enUS = {
  timeFormat: TimeFormat.TwelveHour,
  dateFormat: DateFormat.MonthDayYear,
  formatStrings: {
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameShort} ${Format.MonthFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.MonthFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.SlashedDate]: Format.MonthFirstSlashDate,
    [SupportedFormat.SlashedDateLongYear]: Format.MonthFirstSlashDateLongYear,
    [SupportedFormat.DayNameMonthDate]: `${Format.DayNameLong}, ${Format.MonthName} ${Format.DayOfMonth}`,
    [SupportedFormat.DayNameShortMonthDate]: `${Format.DayNameLong}, ${Format.MonthNameShort} ${Format.DayOfMonth}`,
    [SupportedFormat.DayNameShort]: Format.DayNameShort,
    [SupportedFormat.DayOfMonth]: Format.DayOfMonth,
    [SupportedFormat.MonthNameShortDate]: Format.MonthNameShortDate,
    [SupportedFormat.MonthYear]: `${Format.MonthNumberWithLeadingZero}/${Format.TwoDigitYear}`,
    [SupportedFormat.ShortMonthDate]: `${Format.MonthNameShort} ${Format.DayOfMonth}`,
    [SupportedFormat.Time]: Format.Time12Hour,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.TwoDigitMonthFirstSlashDate,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.MonthNumberWithoutLeadingZero}/${Format.DayOfMonth}`,
    [SupportedFormat.MonthNameYear]: `${Format.MonthName} ${Format.FourDigitYear}`,
    [SupportedFormat.MonthNameShort]: Format.MonthNameShort,
  },
};

const enGB = {
  timeFormat: TimeFormat.TwentyFourHour,
  dateFormat: DateFormat.DayMonthYear,
  formatStrings: {
    ...enUS.formatStrings,
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameShort} ${Format.DayFirstSlashDate} @ ${Format.Time24Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.DayFirstSlashDate} @ ${Format.Time24Hour}`,
    [SupportedFormat.MonthNameShortDate]: Format.DayFirstMonthNameShortDate,
    [SupportedFormat.SlashedDate]: Format.DayFirstSlashDate,
    [SupportedFormat.SlashedDateLongYear]: Format.DayFirstSlashDateLongYear,
    [SupportedFormat.Time]: Format.Time24Hour,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.DayFirstSlashDate,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.DayOfMonth}/${Format.MonthNumberWithoutLeadingZero}`,
  },
};

const enCA = {
  timeFormat: TimeFormat.TwelveHour,
  dateFormat: DateFormat.DayMonthYear,
  formatStrings: {
    ...enUS.formatStrings,
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameShort} ${Format.DayFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.DayFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.MonthNameShortDate]: Format.DayFirstMonthNameShortDate,
    [SupportedFormat.SlashedDate]: Format.DayFirstSlashDate,
    [SupportedFormat.SlashedDateLongYear]: Format.DayFirstSlashDateLongYear,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.DayFirstSlashDate,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.DayOfMonth}/${Format.MonthNumberWithoutLeadingZero}`,
  },
};

const deDE = {
  timeFormat: TimeFormat.TwentyFourHour,
  dateFormat: DateFormat.DayMonthYear,
  formatStrings: {
    ...enUS.formatStrings,
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameShort} ${Format.DayFirstDotDate} \\u\\m ${Format.Time24Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.DayFirstDotDate} \\u\\m ${Format.Time24Hour}`,
    [SupportedFormat.SlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.SlashedDateLongYear]: Format.DayFirstDotDateLongYear,
    [SupportedFormat.DayNameMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthName}`,
    [SupportedFormat.DayNameShortMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.MonthNameShortDate]: Format.DayFirstMonthNameShortDotDate,
    [SupportedFormat.Time]: Format.Time24Hour,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.ShortMonthDate]: `${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.DayOfMonth}.${Format.MonthNumberWithoutLeadingZero}`,
  },
};

// Copied from deDE
const deAT = {
  timeFormat: TimeFormat.TwentyFourHour,
  dateFormat: DateFormat.DayMonthYear,
  formatStrings: {
    ...enUS.formatStrings,
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameShort} ${Format.DayFirstDotDate} \\u\\m ${Format.Time24Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.DayFirstDotDate} \\u\\m ${Format.Time24Hour}`,
    [SupportedFormat.SlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.SlashedDateLongYear]: Format.DayFirstDotDateLongYear,
    [SupportedFormat.DayNameMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthName}`,
    [SupportedFormat.DayNameShortMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.MonthNameShortDate]: Format.DayFirstMonthNameShortDotDate,
    [SupportedFormat.Time]: Format.Time24Hour,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.ShortMonthDate]: `${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.DayOfMonth}.${Format.MonthNumberWithoutLeadingZero}`,
  },
};

const enAU = {
  timeFormat: TimeFormat.TwelveHour,
  dateFormat: DateFormat.DayMonthYear,
  formatStrings: {
    ...enUS.formatStrings,
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameShort} ${Format.DayFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.DayFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.MonthNameShortDate]: Format.DayFirstMonthNameShortDate,
    [SupportedFormat.SlashedDate]: Format.DayFirstSlashDate,
    [SupportedFormat.SlashedDateLongYear]: Format.DayFirstSlashDateLongYear,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.DayFirstSlashDate,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.DayOfMonth}/${Format.MonthNumberWithoutLeadingZero}`,
  },
};

const esMX = {
  timeFormat: TimeFormat.TwentyFourHour,
  dateFormat: DateFormat.DayMonthYear,
  formatStrings: {
    ...enUS.formatStrings,
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameLong} ${Format.DayFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.DayFirstDotDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.SlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.SlashedDateLongYear]: Format.DayFirstDotDateLongYear,
    [SupportedFormat.DayNameMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthName}`,
    [SupportedFormat.DayNameShortMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.MonthNameShortDate]: Format.DayFirstMonthNameShortDotDate,
    [SupportedFormat.Time]: Format.Time12Hour,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.ShortMonthDate]: `${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.DayOfMonth}/${Format.MonthNumberWithoutLeadingZero}`,
  },
};

const frCA = {
  timeFormat: TimeFormat.TwentyFourHour,
  dateFormat: DateFormat.DayMonthYear,
  formatStrings: {
    ...enUS.formatStrings,
    [SupportedFormat.AbbreviatedLong]: `${Format.DayNameLong} ${Format.DayFirstSlashDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.AbrevLongWithoutDay]: `${Format.DayFirstDotDate} @ ${Format.Time12Hour}`,
    [SupportedFormat.SlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.SlashedDateLongYear]: Format.DayFirstDotDateLongYear,
    [SupportedFormat.DayNameMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthName}`,
    [SupportedFormat.DayNameShortMonthDate]: `${Format.DayNameLong}, ${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.MonthNameShortDate]: Format.DayFirstMonthNameShortDotDate,
    [SupportedFormat.Time]: Format.Time12Hour,
    [SupportedFormat.TwoDigitMonthSlashedDate]: Format.DayFirstDotDate,
    [SupportedFormat.ShortMonthDate]: `${Format.DayOfMonth}. ${Format.MonthNameShort}`,
    [SupportedFormat.DayNameLongMonthSlashDay]: `${Format.DayNameLong}, ${Format.DayOfMonth}/${Format.MonthNumberWithoutLeadingZero}`,
  },
};

const info: Record<Locale, DateTimeInfo> = {
  [Locale.EnglishUnitedStates]: enUS,
  [Locale.EnglishUnitedKingdom]: enGB,
  [Locale.EnglishCanada]: enCA,
  [Locale.GermanGermany]: deDE,
  [Locale.GermanAustria]: deAT,
  [Locale.EnglishAustralia]: enAU,
  [Locale.EnglishBeta]: enUS,
  [Locale.SpanishMexico]: esMX,
  [Locale.FrenchCanada]: frCA,
};
