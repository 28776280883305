import { put } from 'redux-saga/effects';
import type { ClientError, HttpError } from '@peloton/api';
import { isHttpError } from '@peloton/api';
import { deAuthenticate, redirectToLogin } from '@peloton/auth';
import { redirectToNotFound } from '@peloton/navigation';

const isApiMeError = (error: ClientError) =>
  isHttpError(error) && error.request.url.indexOf('/api/me') !== -1;
const isLoginError = (error: ClientError) =>
  isHttpError(error) && error.request.url.indexOf('/login') !== -1;
const isApiUserError = (error: ClientError) =>
  isHttpError(error) && error.request.url.indexOf('/api/user') !== -1;

export const defaultHandleClientErrors = function* (error: ClientError) {
  if (isHttpError(error)) {
    if (error.status === 401) {
      yield put(deAuthenticate());
      if (!isApiMeError(error) && !isLoginError(error)) {
        yield put(redirectToLogin());
      }
    }

    if (error.status === 403) {
      yield put(deAuthenticate());
      yield put(redirectToLogin());
    }

    if ((resourceDoesNotExist(error) || error.status === 404) && !isApiUserError(error)) {
      yield put(redirectToNotFound());
    }
  }
};

export const handleBlockedUserError = function* (error: HttpError) {
  if (error.response?.data?.errorCode === 3333) {
    yield put(redirectToNotFound());
  }
};

const resourceDoesNotExist = (err: HttpError) =>
  err.responseBody &&
  (err.responseBody.errorCode === 110 ||
    err.responseBody.errorCode === 150 || // Temp fix for novalidate error leaking out of API
    err.responseBody.message === 'Resource does not exist.');
