import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { gray3, slate1, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { title24 } from '@engage/typography';
import { Button3Large } from '@members/buttons';
import { Stacks, StacksCopy } from '@members/copy';

type ErrorStateProps = {
  tryAgain: () => void;
};
const ErrorState: React.FC<React.PropsWithChildren<ErrorStateProps>> = ({ tryAgain }) => {
  return (
    <StackContainer>
      <ErrorText>
        <StacksCopy id={Stacks.ErrorStacks} />
      </ErrorText>
      <TryAgainButton onClick={tryAgain}>
        <StacksCopy id={Stacks.TryAgain} />
      </TryAgainButton>
    </StackContainer>
  );
};

const StackContainer = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: ${spaces.xHuge}px;
  padding-right: ${spaces.xHuge}px;
  padding-top: ${spaces.medium}px;
  margin-top: ${spaces.xxxHuge}px;

  svg {
    height: 64px;
    width: 64px;
    stroke: ${slate1};
  }

  ${media.tablet`
    margin-top: 78px;
  `};
`;

const ErrorText = styled.p`
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  color: ${gray3};
  ${title24}
`;

const TryAgainButton = styled(Button3Large)`
  width: 280px;
  height: 48px;
  margin-top: ${spaces.xxLarge}px;
  stroke: ${gray3};
  color: ${white};
`;

export default ErrorState;
