import * as React from 'react';
import styled from 'styled-components';
import { isEnterKey } from '@peloton/keyboard';
import { media } from '@peloton/styles';
import { slate3, slate1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { title32, body16, title24, body14 } from '@engage/typography';
import { largeButtonStyles, Button2Large, button3Styles } from '@members/buttons';
import type { CopyIDs } from '@members/copy';
import {
  GuestPass,
  Members,
  MembersCopy,
  GuestPassCopy,
  Facebook,
  FacebookCopy,
} from '@members/copy';
import { Search } from '@members/guest-pass/icons';
import { GUEST_PASS_SOURCE } from '@members/guest-pass/urls';
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from '@members/modal';
import {
  SEARCH_MODAL_NAME_FROM_PROFILE,
  PROFILE_OVERVIEW_SOURCE,
} from '@members/pg-overview/UserProfile/constants';
import { useReferralsUrlWithSource } from '@members/referrals';

export enum ContentType {
  NoFollowers,
  NoFollowing,
  MembersSearch,
  NoFacebookFriends,
}

type ModalContent = {
  title: CopyIDs<typeof Members> | CopyIDs<typeof Facebook>;
  desc: CopyIDs<typeof GuestPass>;
  source: GUEST_PASS_SOURCE;
  titleDataTestId?: string;
};

const CONTENT_MAP: { [key in ContentType]: ModalContent } = {
  [ContentType.NoFollowers]: {
    title: Members.NoFollowersYet,
    desc: GuestPass.GuestPassShare,
    source: GUEST_PASS_SOURCE.Followers,
    titleDataTestId: 'noFollowersText',
  },
  [ContentType.NoFollowing]: {
    title: Members.NotFollowingMembers,
    desc: GuestPass.GuestPassShare,
    source: GUEST_PASS_SOURCE.Following,
    titleDataTestId: 'notFollowingMembersText',
  },
  [ContentType.MembersSearch]: {
    title: Members.NoMembersFoundTitle,
    desc: GuestPass.GuestPassShare,
    source: GUEST_PASS_SOURCE.MemberSearch,
    titleDataTestId: 'noMembersFound',
  },
  [ContentType.NoFacebookFriends]: {
    title: Facebook.NoFriendsFound,
    desc: GuestPass.NoFriendsFoundInvite,
    source: GUEST_PASS_SOURCE.FindFacebookFriends,
  },
};

type InviteFriendsContentProps = {
  contentType: ContentType;
  onClose?: () => void;
};

export const InviteFriendsContent: React.FC<
  React.PropsWithChildren<InviteFriendsContentProps>
> = ({ contentType, onClose }) => {
  const openModal = useReduxAction(openModalAction);
  const closeModal = useReduxAction(closeModalAction);
  const handleFindMembersClick = () =>
    openModal(SEARCH_MODAL_NAME_FROM_PROFILE, PROFILE_OVERVIEW_SOURCE);
  const handleInviteClick = () => {
    onClose ? onClose() : null;
    closeModal();
  };
  const referralsUrlWithSource = useReferralsUrlWithSource('nav');
  return (
    <ContentContainer contentType={contentType}>
      {contentType === ContentType.NoFacebookFriends ? (
        <>
          <SearchIcon />
          <Title data-test-id={CONTENT_MAP[contentType].titleDataTestId}>
            <FacebookCopy
              id={CONTENT_MAP[contentType].title as CopyIDs<typeof Facebook>}
            />
          </Title>
        </>
      ) : (
        <Title data-test-id={CONTENT_MAP[contentType].titleDataTestId}>
          <MembersCopy id={CONTENT_MAP[contentType].title as CopyIDs<typeof Members>} />
        </Title>
      )}
      <Description data-test-id="inviteFriendsDesc">
        <GuestPassCopy id={CONTENT_MAP[contentType].desc} />
      </Description>
      <If condition={contentType === ContentType.NoFollowing}>
        <FindMembersButton
          onClick={() => handleFindMembersClick()}
          data-test-id="findMembersButton"
        >
          <MembersCopy id={Members.FindMembersCta} />
        </FindMembersButton>
      </If>
      <InviteButton
        onClick={handleInviteClick}
        onKeyDown={e => isEnterKey(e) && handleInviteClick()}
        data-test-id="inviteWithGuestPass"
        href={referralsUrlWithSource}
        target="_blank"
        rel="noreferrer"
      >
        <p>
          <GuestPassCopy id={GuestPass.InviteWithGuestPass} />
        </p>
      </InviteButton>
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{ contentType: ContentType }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${spaces.xHuge}px;
  ${({ contentType }) =>
    contentType === ContentType.MembersSearch ? `margin-bottom: ${spaces.xxxHuge}px` : ''}
`;

const Title = styled.div`
  ${title24}
  color: ${slate3};
  text-align: center;
  margin-bottom: ${spaces.large}px;
  ${media.tabletXLarge`
    ${title32}
  `}
`;

const Description = styled.div`
  ${body14}
  color: ${slate1};
  text-align: center;
  margin-bottom: ${spaces.huge}px;
  ${media.tabletXLarge`
    ${body16}
  `}
`;

const InviteButton = styled.a`
  ${button3Styles}
  ${largeButtonStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 279px;
  ${media.tabletXLarge`
    width: 320px;
  `}
`;

const FindMembersButton = styled(Button2Large)`
  width: 279px;
  margin-bottom: ${spaces.small}px;
  ${media.tabletXLarge`
    width: 320px;
  `}
`;

const SearchIcon = styled(Search)`
  width: 64px;
  height: 64px;
  margin-bottom: ${spaces.huge}px;
`;
