export function isEmpty(v: any) {
  if (Array.isArray(v) || typeof v === 'string') {
    return v.length === 0;
  }

  if (typeof v === 'object' && v !== null) {
    return Object.keys(v).length === 0;
  }

  return false;
}
