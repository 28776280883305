import type { UserNavRouteOrRedirect } from '@members/layout';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { GOALS_ROUTE } from './urls';

const Routes: UserNavRouteOrRedirect[] = [
  {
    route: {
      path: GOALS_ROUTE,
      component: lazyLoader(() => import('./Goals' /* webpackChunkName: "GoalsPage" */)),
    },
  },
];

export default Routes;
