import type { Reducer } from 'redux';
import type { FetcherSelectorState } from '@peloton/redux-fetch';

export const INVITES_NAMESPACE = 'classInvites';

type InviteState = {
  numOfPendingInvites: number;
};

// Reducer
export const invitesReducer: Reducer<InviteState, InviteAction> = (
  state: InviteState = { numOfPendingInvites: 0 },
  action,
) => {
  switch (action.type) {
    case InviteActionType.SetNumberOfPendingInvites: {
      const setNumOfPendingInvitesAction = action as ReturnType<
        typeof setNumOfPendingInvites
      >;
      return {
        numOfPendingInvites: setNumOfPendingInvitesAction.updatedNumOfInvites,
      };
    }
    default:
      return state;
  }
};

// Action Creators
export const setNumOfPendingInvites = (updatedNumOfInvites: number) => ({
  type: InviteActionType.SetNumberOfPendingInvites,
  updatedNumOfInvites,
});

type InviteAction = ReturnType<typeof setNumOfPendingInvites>;

// Actions
enum InviteActionType {
  SetNumberOfPendingInvites = 'invites/setNumOfPendingInvites',
}

// selectors
export const getNumOfPendingInvites = (state: InviteSelectorState) =>
  state.classInvites.numOfPendingInvites;
type InviteSelectorState = FetcherSelectorState & {
  [INVITES_NAMESPACE]: ReturnType<typeof invitesReducer>;
};
