import React from 'react';
import { Route } from 'react-router-dom';
import { CHALLENGES_ROUTE } from '@members/challenges';
import lazyLoader from '@members/lazy-loader/LazyLoader';

const Routes = [
  <Route
    key={CHALLENGES_ROUTE}
    path={CHALLENGES_ROUTE}
    component={lazyLoader(
      () => import('./ChallengesPage' /* webpackChunkName: "ChallengesPage" */),
    )}
  />,
];

export default Routes;
