/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasePerformanceGraphResponse } from '../../../api-v2-models/generated/BasePerformanceGraphResponse';
import type { EffortZones } from '../../../api-v2-models/generated/EffortZones';
import type { PaginationResponse } from '../../../api-v2-models/generated/PaginationResponse';
import type { PerformanceData } from '../../../api-v2-models/generated/PerformanceData';
import type { PerformanceGraphResponse } from '../../../api-v2-models/generated/PerformanceGraphResponse';
import type { UserMonthOverview } from '../../../api-v2-models/generated/UserMonthOverview';
import type { UserOverview } from '../../../api-v2-models/generated/UserOverview';
import type { UserPersonalRecords } from '../../../api-v2-models/generated/UserPersonalRecords';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Get a CSV file of a user's workout history
 * Gets the workout history for the specified user and downloads the history as CSV file. For each workout, the downloaded file shows the user's workout time, metrics, and various class details.
 * @param userId
 * @returns any Successful response. Downloads workout history CSV file.
 * @throws ApiError
 */
export const getWorkoutHistoryCsv = (
    api: BaseAPI,
    userId: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/${userId}/workout_history_csv`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get Workout Summary
 * Get summary statistics for specified workout. This includes statistics such as max power, avg resistance, calories, etc.
 * @param workoutId ID for the workout
 * @returns any Successful response. Shows metrics of the last packet for the workout, if the workout has them, otherwise returns all 0s
 * @throws ApiError
 */
export const getWorkoutSummary = (
    api: BaseAPI,
    workoutId: string,
    options: any = {},
): AxiosPromise<{
    /**
     * ID for workout packet
     */
    id?: string,
    /**
     * ID for user
     */
    userId?: string,
    /**
     * ID for workout
     */
    workoutId?: string,
    /**
     * ID for peloton
     */
    pelotonId?: string,
    /**
     * ID for ride
     */
    rideId?: string,
    instant?: number,
    secondsSincePedalingStart?: number,
    totalWork?: PerformanceData,
    power?: PerformanceData,
    maxPower?: PerformanceData,
    avgPower?: PerformanceData,
    cadence?: PerformanceData,
    maxCadence?: PerformanceData,
    avgCadence?: PerformanceData,
    resistance?: PerformanceData,
    maxResistance?: PerformanceData,
    avgResistance?: PerformanceData,
    speed?: PerformanceData,
    maxSpeed?: PerformanceData,
    avgSpeed?: PerformanceData,
    distance?: PerformanceData,
    calories?: PerformanceData,
    heartRate?: PerformanceData,
    maxHeartRate?: PerformanceData,
    avgHeartRate?: PerformanceData,
    strokeCount?: PerformanceData,
    avgStrokeRate?: PerformanceData,
    maxStrokeRate?: PerformanceData,
    avgSplitPace?: PerformanceData,
    /**
     * Will be null if the workout packets do not have effort zone data or if the user being requested is not the currently logged in user and the requested user has effort_zones_private set to true. Will also be null if the user has disabled effort score.
     */
    effortZones?: EffortZones,
    /**
     * form score of the workout
     */
    fmScore?: number | null,
    segments?: Array<{
        /**
         * type of the segment
         */
        type?: 'catch' | 'drive' | 'finish' | 'recovery',
        /**
         * percentage of good form for the specific segment
         */
        percentOfGoodForm?: number,
    }>,
}> => {
    const localVarPath = `/api/workout/${workoutId}/v2/summary`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets a user's time series
 * Returns (workout creation time, workout series value, workout ID) user's workouts sorted by descending creation time This endpoint will return an error is accessed for a private user by someone who does not follow them.
 * @param id
 * @param series Series values to return for each workout.
 * @param after If set, only returns workouts after this epoch date value
 * @param before If set, only returns workouts before this epoch date value
 * @param limit If set, returns a limited number  of workouts up to the limit specified. Default to be 20.
 * @returns any A list of workouts
 * @throws ApiError
 */
export const getPaginatedTimeseries = (
    api: BaseAPI,
    id: string,
    series: Array<'avg_heart_rate' | 'avg_power' | 'calories' | 'distance' | 'total_work' | 'hr_total_points'>,
    after?: number,
    before?: number,
    limit?: number,
    options: any = {},
): AxiosPromise<{
    /**
     * If previous values exist, returns a dictionary with query parameters that will get the previous workouts
     */
    previous?: any,
    /**
     * If next values exist, returns a dictionary with query parameters that will get the next workouts
     */
    next?: any,
    data?: Record<string, {
        /**
         * The maximum value of this metric on all of this user's workouts
         */
        max?: number,
        data?: Array<{
            createdAt?: number,
            value?: number,
            workoutId?: string,
            /**
             * What type of metrics were collected for this workout. May be null, indicidating no device metrics were collected, but metrics from other sensors (heart rate) may have been.
             */
            metricsType?: 'cycling' | 'running',
        }>,
    }>,
}> => {
    const localVarPath = `/api/user/${id}/v2/timeseries`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof series !== 'undefined') {
        localVarQueryParameter['series'] = series;
    }
    if(typeof after !== 'undefined') {
        localVarQueryParameter['after'] = after;
    }
    if(typeof before !== 'undefined') {
        localVarQueryParameter['before'] = before;
    }
    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets a user's time series
 * Returns (workout creation time, workout series value, workout ID) user's workouts sorted by descending creation time This endpoint will return an error is accessed for a private user by someone who does not follow them.
 * @param id
 * @param series Series values to return for each workout.
 * @param after If set, only returns workouts after this epoch date value
 * @param before If set, only returns workouts before this epoch date value
 * @param limit If set, returns a limited number  of workouts up to the limit specified. Default to be 20.
 * @returns any A list of workouts
 * @throws ApiError
 */
export const getPaginatedTimeseriesWithUnit = (
    api: BaseAPI,
    id: string,
    series: Array<'avg_heart_rate' | 'avg_power' | 'calories' | 'distance' | 'total_work' | 'hr_total_points'>,
    after?: number,
    before?: number,
    limit?: number,
    options: any = {},
): AxiosPromise<{
    /**
     * If previous values exist, returns a dictionary with query parameters that will get the previous workouts
     */
    previous?: any,
    /**
     * If next values exist, returns a dictionary with query parameters that will get the next workouts
     */
    next?: any,
    data?: Record<string, {
        /**
         * The maximum value of this metric on all of this user"s workouts
         */
        max?: number,
        displayName?: string,
        displayUnit?: string,
        data?: Array<{
            createdAt?: number,
            value?: number,
            workoutId?: string,
            /**
             * What type of metrics were collected for this workout. May be null, indicidating no device metrics were collected, but metrics from other sensors (heart rate) may have been.
             */
            metricsType?: 'cycling' | 'running',
        }>,
    }>,
}> => {
    const localVarPath = `/api/user/${id}/v3/timeseries`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof series !== 'undefined') {
        localVarQueryParameter['series'] = series;
    }
    if(typeof after !== 'undefined') {
        localVarQueryParameter['after'] = after;
    }
    if(typeof before !== 'undefined') {
        localVarQueryParameter['before'] = before;
    }
    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets workout's packet data formatted to display graphs
 * Returns summaries showing aggregate statistics for the workout, as well as a list of values for metrics over time. Also includes data about the segments of workout.
 * @param workoutId
 * @param everyN Sampling rate for the packets
 * @param maxGapMeters Maximum gap between GPS coordinates for location data
 * @param includeOutput An override for the iOS app to include output in the workout summaries. In older designs, output was shown in the workout details header. So, to prevent duplication, we removed it from iOS API responses. This flag allows newer clients to request that output be included.
 * @param durationSummaryInSeconds By default, the unit of the "duration" summary is based on the value. If the time is one minute or less, the summary is returned in seconds; if it is greater than a minute, it's returned as a decimal number of minutes. Some devices want to display duration in both minutes and seconds, which is not supported by the format of the display_unit field, so we allow them to have the units always returned in seconds and do the conversion on the client side.
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns PerformanceGraphResponse A list of workout performance graphs
 * @throws ApiError
 */
export const getPerformanceGraph = (
    api: BaseAPI,
    workoutId: string,
    everyN?: number,
    maxGapMeters?: number,
    includeOutput?: boolean,
    durationSummaryInSeconds?: boolean,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<PerformanceGraphResponse> => {
    const localVarPath = `/api/workout/${workoutId}/performance_graph`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof everyN !== 'undefined') {
        localVarQueryParameter['every_n'] = everyN;
    }
    if(typeof maxGapMeters !== 'undefined') {
        localVarQueryParameter['max_gap_meters'] = maxGapMeters;
    }
    if(typeof includeOutput !== 'undefined') {
        localVarQueryParameter['include_output'] = includeOutput;
    }
    if(typeof durationSummaryInSeconds !== 'undefined') {
        localVarQueryParameter['duration_summary_in_seconds'] = durationSummaryInSeconds;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets user's overview
 * Return user overview including workout counts by fitness discipline,
 * achievements and personal records.
 * 1. List of user's fitness discipline based workout counts, the order of
 * fitness discipline is:
 * Cycling, Running, Circuit. Workout count includes
 * finalized workouts as well as unfinalized ones.
 * If the user has no workouts of that discipline, it will not appear in
 * the list.
 * 2. User's achievements, includes total count and achievements list.
 * The ordered achivements list contains count by achievement templates and
 * achievement template mappings.
 * 3. List of user's fitness discipline based personal records, the order
 * of fitness discipline is:
 * [Aurora Platform] Running, Circuit, Cycling(if user has cycling personal
 * record)
 * [Non-aurora Platform] Cycling, Running(if user has running personal
 * record), Circuit(if user has circuit personal record).
 * Within each fitness discipline, the records are ordered by increasing
 * duration with wrap
 * around, with the first record being the first from the following list
 * that the user has a
 * personal record for: 45 min, 30 min, 15 min, 10 min, 60 min. The records
 * include all the
 * durations that user has a personal record for.
 * Empty list of records within the fitness discipline indicate that there
 * is no personal records for that specific fitness discipline,
 * and clients will decide whether to display that fitness discipline with
 * empty records based on the platform.
 * Notice All aurora content will be hidden if hide aurora toggle is ON.
 * This endpoint will return an error is accessed for a private user by
 * someone who does not follow them.
 * @param id
 * @param version If version is 1, inside the response will have "achievements" ordered by date with maximum 10 items. If not provided, response will have the achievement_count
 * @param include if value includes "recent workouts", indicates query should include recent workouts.
 * @param recentWorkoutsLimit Number of recent workouts to include
 * @param imageType achievement badge image type
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns UserOverview Successful response
 * @throws ApiError
 */
export const getUserOverview = (
    api: BaseAPI,
    id: string,
    version?: number,
    include?: string,
    recentWorkoutsLimit?: number,
    imageType?: 'light_image' | 'dark_image',
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<UserOverview> => {
    const localVarPath = `/api/user/${id}/overview`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof version !== 'undefined') {
        localVarQueryParameter['version'] = version;
    }
    if(typeof include !== 'undefined') {
        localVarQueryParameter['include'] = include;
    }
    if(typeof recentWorkoutsLimit !== 'undefined') {
        localVarQueryParameter['recent_workouts_limit'] = recentWorkoutsLimit;
    }
    if(typeof imageType !== 'undefined') {
        localVarQueryParameter['image_type'] = imageType;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets overview of user's activity for a given month
 * Return overview of a given month for a user including workout counts by
 * fitness discipline, aggregate stats and highlights.
 * 1. List of user's fitness discipline based workout counts for the month.
 * The order of fitness discipline is:
 * Cycling, Running, Circuit. Workout count includes
 * finalized workouts as well as unfinalized ones.
 * If the user has no workouts of that discipline, it will not appear in
 * the list.
 * If hide aurora toggle is on and viewed anywhere but on the treadmill,
 * Running and Circuit will not appear.
 * 2. List of user's aggregate stats for the month.
 * 3. List of highlights of the month for that user. Highlights are a list
 * of API-driven data about the selected month.
 * We will start with three: "Best Output", "Favorite Instructor", and
 * "Latest Badges".
 * The schema for a given highlight will always be the same, but which
 * highlights are returned may change.
 * Notice All aurora content will be hidden if hide aurora toggle is ON.
 * This endpoint will return an error is accessed for a private user by
 * someone who does not follow them.
 * @param id
 * @param month Month to get overview for in the format YYYY-MM
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns UserMonthOverview Successful response
 * @throws ApiError
 */
export const getUserMonthOverview = (
    api: BaseAPI,
    id: string,
    month: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<UserMonthOverview> => {
    const localVarPath = `/api/user/${id}/overview/month/${month}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets the ride's info used by clients to display a locally cached version of the performance graph
 * @param id
 * @param durationSummaryInSeconds By default, the unit of the "duration" summary is seconds. Some devices want to display duration in both minutes and seconds, which is not supported by the format of the display_unit field, so we allow them to have the units always returned in seconds and do the conversion on the client side.
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns BasePerformanceGraphResponse Ride segments and summary metrics info
 * @throws ApiError
 */
export const getPerformanceGraphInfo = (
    api: BaseAPI,
    id: string,
    durationSummaryInSeconds?: boolean,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<BasePerformanceGraphResponse> => {
    const localVarPath = `/api/ride/${id}/performance_graph_info`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof durationSummaryInSeconds !== 'undefined') {
        localVarQueryParameter['duration_summary_in_seconds'] = durationSummaryInSeconds;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets user's personal records
 * List of user's fitness discipline based personal records, the order of fitness discipline is: [Aurora Platform] Running, Circuit, Cycling(if user has cycling personal record) [Non-aurora Platform] Cycling, Running(if user has running personal record), Circuit(if user has circuit personal record).  Within each fitness discipline, the records are ordered by increasing duration with wrap around, with the first record being the first from the following list that the user has a personal record for: 45 min, 30 min, 15 min, 10 min, 60 min. The records include all the durations that user has a personal record for. Empty list of records within the fitness discipline indicate that there is no personal records for that specific fitness discipline, and clients will decide whether to display that fitness discipline with empty records based on the platform. Notice All aurora content will be hidden if hide aurora toggle is ON. This endpoint will return an error is accessed for a private user by someone who does not follow them.
 * @param id
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserPersonalRecords = (
    api: BaseAPI,
    id: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * List of user's fitness discipline based personal records.
     */
    personalRecords?: Array<UserPersonalRecords>,
}> => {
    const localVarPath = `/api/user/${id}/personal_records`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Updates user's personal records with a reset_date timestamp
 * User will select a date to reset PRs such that PRs will now be selected from workouts taken since this date rather than the user's entire workout history. Returns list of user's fitness discipline based personal records, the order of fitness discipline is: [Aurora Platform] Running, Circuit, Cycling(if user has cycling personal record) [Non-aurora Platform] Cycling, Running(if user has running personal record), Circuit(if user has circuit personal record). Within each fitness discipline, the records are ordered by increasing duration with wrap around, with the first record being the first from the following list that the user has a personal record for: 45 min, 30 min, 15 min, 10 min, 60 min. The records include all the durations that user has a personal record for. Empty list of records within the fitness discipline indicate that there is no personal records for that specific fitness discipline, and clients will decide whether to display that fitness discipline with empty records based on the platform. There may be empty values for each duration, indicating that a user has not taken a workout of that duration since the reset_date timestamp.
 * @param id
 * @param requestBody
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const updateUserPersonalRecords = (
    api: BaseAPI,
    id: string,
    requestBody: {
        /**
         * which fitness_discipline to reset the PR
         */
        fitnessDiscipline?: string,
        /**
         * ISO 8601 datetime string to reset PRs. null if user wishes to reset date to all workout history.
         */
        resetDate?: string,
    },
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * Update user's personal records based on how far back in workout history to select a personal record.
     */
    personalRecords?: Array<UserPersonalRecords>,
}> => {
    const localVarPath = `/api/user/${id}/personal_records/reset`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets a user's workouts
 * Returns a list containing all workouts. There are a few different ways to search this list First, you can search with to and from, which gets all workouts in that time range There are also two forms of pagination. The older style uses a page number and a limit. The newer style uses a limit along with a before or after parameter. This endpoint will return an error is accessed for a private user by someone who does not follow them. This endpoint is allowed to join with ride which will include ride information in response.
 * @param id
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param limit Number of workouts returned per page
 * @param page Page number
 * @param before URL-encoded JSON formatted string containing workout_id and optionally created_at. This will find workouts immediately before the workout specified by workout_id in sort order (which is descending creation time). (This means that it will return workouts cronologically after the one in the parameter.) created_at should be included where possible because it decreases the response time of the endpoint, but is not required. Without URL-encoding, this will look like '{"workout_id": "12312f537b9241caa3c519a5de945f3d", "created_at": "1484749509"}'
 * @param after URL-encoded JSON formatted string containing workout_id and optionally created_at. This will find workouts immediately after the workout specified by workout_id in sort order (which is descending creation time). (This means that it will return workouts cronologically before the one in the parameter.) created_at should be included where possible because it decreases the response time of the endpoint, but is not required. Without URL-encoding, this will look like '{"workout_id": "12312f537b9241caa3c519a5de945f3d", "created_at": "1484749509"}'
 * @param includeBoundary If used with `after` or `before`, this causes the workout_id from the parameter to be include in the response. This does count towards the pagination limit.
 * @param to Start date in ISO 8601[TZ] used to filter workouts. If `to` is provided, but `from` isn't, (or vice versa), it'll be ignored. If `from` is after `to`, a 400 will be returned. Cannot be used in conjunction with page or limit
 * @param from End date in ISO 8601[TZ] used to filter workouts If `to` is provided, but `from` isn't, (or vice versa), it'll be ignored. If `from` is after `to`, a 400 will be returned. Cannot be used in conjunction with page or limit
 * @param statsTo Start date in ISO 8601[TZ] used to filter for which workouts aggregate stats are returned. Can only be used in conjunction with the arguments `to` and `from` If `stats_to` is after `to`, a 400 will be returned
 * @param statsFrom End date in ISO 8601[TZ] used to filter for which workouts aggregate stats are returned. If `from` is after `to`, a 400 will be returned. Can only be used in conjunction with the arguments `to` and `from` If `stats_from` is before `from`, a 400 will be returned
 * @returns any A list of workouts
 * @throws ApiError
 */
export const getUserWorkouts = (
    api: BaseAPI,
    id: string,
    acceptLanguage?: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    limit?: number,
    page?: number,
    before?: string,
    after?: string,
    includeBoundary?: boolean,
    to?: string,
    from?: string,
    statsTo?: string,
    statsFrom?: string,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${id}/workouts`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }
    if(typeof before !== 'undefined') {
        localVarQueryParameter['before'] = before;
    }
    if(typeof after !== 'undefined') {
        localVarQueryParameter['after'] = after;
    }
    if(typeof includeBoundary !== 'undefined') {
        localVarQueryParameter['include_boundary'] = includeBoundary;
    }
    if(typeof to !== 'undefined') {
        localVarQueryParameter['to'] = to;
    }
    if(typeof from !== 'undefined') {
        localVarQueryParameter['from'] = from;
    }
    if(typeof statsTo !== 'undefined') {
        localVarQueryParameter['stats_to'] = statsTo;
    }
    if(typeof statsFrom !== 'undefined') {
        localVarQueryParameter['stats_from'] = statsFrom;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get a list of active days for every month in the user's workout history
 * Gets the workout history of the user in the form of a calendar of which days in each month the user has taken a workout. Months are listed in from the current month back to the month of the user's first workout. Months where the user did not take a workout are included with no active days. The day of the workout is based on the date on the user's device when taking the workout
 * @param id User id
 * @param pelotonClientDate The current time accord to the client when the calendar was requested, given in ISO8601 format
 * @returns any Successful response.
 * @throws ApiError
 */
export const getUserWorkoutCalendar = (
    api: BaseAPI,
    id: string,
    pelotonClientDate: string,
    options: any = {},
): AxiosPromise<{
    months?: Array<{
        year?: number,
        month?: number,
        /**
         * Days of the month the user has a workout. Sorted in increasing order
         */
        activeDays?: Array<number>,
    }>,
}> => {
    const localVarPath = `/api/user/${id}/calendar`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Client-Date'] = String(pelotonClientDate);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

