// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Stacks = {
  AddStack: 'addStack',
  BrowseClasses: 'browseClasses',
  Cancel: 'cancel',
  ClassAdded: 'classAdded',
  Classes: 'classes',
  Continue: 'continue',
  Done: 'done',
  EducationConfirm: 'educationConfirm',
  EducationDescription: 'educationDescription',
  EducationTitle: 'educationTitle',
  EmptyStack: 'emptyStack',
  ErrorNumClasses: 'errorNumClasses',
  ErrorStacks: 'errorStacks',
  Hours: 'hours',
  ModifyStack: 'modifyStack',
  ModifyStacksShort: 'modifyStacksShort',
  NotPlayable: 'notPlayable',
  Remove: 'remove',
  RemoveAll: 'removeAll',
  Stack: 'stack',
  Stacked: 'stacked',
  StackedClasses: 'stackedClasses',
  Start: 'start',
  StartCaps: 'startCaps',
  TryAgain: 'tryAgain',
} as const;
