import { capitalize } from '@peloton/text';
import type { BrowseCategorySlug } from '@engage/browse-categories';
import { toAnalyticsBrowseCategoryProp } from '@engage/browse-categories';
import type { FilterAnalyticsProps } from '@engage/filters';
import type { ClassLanguage } from '@engage/settings';
import { classLanguageSettingsToAnalyticsList } from '@engage/settings';

export enum EmptyLibrarySource {
  Library = 'Classes',
  Schedule = 'Schedule',
}

export enum EmptyStateType {
  NoClasses = 'No Classes',
  LanguageSettings = 'Language Settings',
}

export enum LibraryEventNames {
  ViewedOnDemandLibrary = 'Viewed On-Demand Library',
  ViewedEmptyLibrary = 'Viewed Empty Library State',
  FilteredOnDemandLibrary = 'Filtered On-Demand Library',
  SortedOnDemandLibrary = 'Sorted On-Demand Library',
  ClearedFilters = 'Cleared Filters',
}

export enum ViewedLibrarySource {
  TopNav = 'Top Nav',
  Home = 'Home',
  QuickFilters = 'Quick Filters',
}

export const trackViewedLibraryPage = (
  slug: BrowseCategorySlug,
  classLanguageSettings: ClassLanguage[],
  source: ViewedLibrarySource,
) => ({
  event: LibraryEventNames.ViewedOnDemandLibrary,
  classLanguageSettings: classLanguageSettingsToAnalyticsList(classLanguageSettings),
  ...toAnalyticsBrowseCategoryProp(slug),
  source,
});

export const trackViewedEmptyLibraryPage = (
  source: EmptyLibrarySource,
  emptyStateType: EmptyStateType,
  classLanguageSettings: ClassLanguage[],
  explicitBlocked: boolean,
  browseProps: FilterAnalyticsProps,
) => ({
  event: LibraryEventNames.ViewedEmptyLibrary,
  source,
  emptyStateType,
  classLanguageSettings: classLanguageSettingsToAnalyticsList(classLanguageSettings),
  '[Explicit Setting]': capitalize(explicitBlocked.toString()),
  ...browseProps,
});

export const trackFilteredLibrary = (
  props: FilterAnalyticsProps,
  classLanguageSettings: ClassLanguage[],
) => ({
  event: LibraryEventNames.FilteredOnDemandLibrary,
  classLanguageSettings: classLanguageSettingsToAnalyticsList(classLanguageSettings),
  ...props,
});

export const trackSortedLibrary = (sortValue: string) => ({
  event: LibraryEventNames.SortedOnDemandLibrary,
  sortValue,
});

export const trackClearedFilters = (props: FilterAnalyticsProps, source: string) => ({
  event: LibraryEventNames.ClearedFilters,
  source,
  ...props,
});

/**
 * The Search Shared Properties as defined in the Consolidated Schema in AirTable.
 */
export type SearchSharedProperties = {
  '[Search Artist ID]'?: string;
  '[Search Artist List Position]'?: number;
  '[Search Artist Name]'?: string;
  '[Search List Position]'?: number;
  '[Search Result Type]': 'Class Search' | 'Artist Search';
  '[Search Term]'?: string;
  '[Search Type]': 'Manual Search' | 'Popular Query';
};
