import React from 'react';
import styled from 'styled-components';
import { gray3, superRed } from '@engage/colors';

export const Checkbox: React.FC<
  React.PropsWithChildren<{ isChecked: boolean; onClick: () => void }>
> = ({ isChecked, onClick }) => {
  return (
    <CheckButton
      onClick={onClick}
      aria-label={isChecked ? 'Check' : 'UnCheck'}
      role="checkbox"
      aria-checked={isChecked}
    >
      {isChecked ? (
        <BoxContainer isChecked={isChecked}>
          <CheckIcon />
        </BoxContainer>
      ) : (
        <BoxContainer />
      )}
    </CheckButton>
  );
};

type Props = React.SVGProps<SVGSVGElement>;

const CheckIcon: React.FC<React.PropsWithChildren<Props>> = props => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7179 0.303855C14.1024 0.70034 14.0926 1.33343 13.6961 1.7179L5.44615 9.7179C5.05826 10.094 4.44174 10.094 4.05385 9.7179L0.303855 6.08154C-0.0926305 5.69707 -0.10237 5.06398 0.2821 4.66749C0.666571 4.27101 1.29966 4.26127 1.69615 4.64574L4.75 7.60705L12.3039 0.2821C12.7003 -0.10237 13.3334 -0.0926305 13.7179 0.303855Z"
        fill="white"
      />
    </svg>
  );
};

const BoxContainer = styled.div<{ isChecked?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid ${gray3};
  border-radius: 4px;
  background: ${props => (props.isChecked ? superRed : 'none')};
`;

const CheckButton = styled.button`
  cursor: pointer;
  display: block;
`;
