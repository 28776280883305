import type { Locale } from '@peloton/internationalize';
import type { Time } from '@peloton/time';
import type { RideWithRelevance, RideWithUserInfo } from '@engage/api-v2';
import type { DifficultyLevel } from '@engage/classes';
import type { ContentFormat } from '@engage/graphql/types.generated';
import type { SharedClassInfo, SharedOnDemand } from './shared';

export const isScenic = (klass: Class): klass is ScenicClass =>
  klass.kind === ClassCategory.Scenic;

export const isOnDemand = (klass: Class): klass is OnDemandClass =>
  klass.kind === ClassCategory.OnDemand;

export const isLive = (klass: Class): klass is LiveClass =>
  klass.kind === ClassCategory.Live;

export const isAired = (klass: Class): klass is AiredClass =>
  isLive(klass) || isOnDemand(klass);

export const isLibrary = (klass: Class): klass is LibraryClass =>
  isOnDemand(klass) || isScenic(klass);

export const hasRatings = (klass: LibraryClass) =>
  klass.overallRatingCount > 0 && klass.difficultyRatingCount > 0;

export const isAudioClass = (format: ContentFormat) => format === 'audio';

export type Class = ScenicClass | LiveClass | OnDemandClass;

export type AiredClass = LiveClass | OnDemandClass;

export type LibraryClass = (OnDemandClass | ScenicClass) & Partial<RelevantSongInfo>;

export type RelevantSongInfo = Omit<RideWithRelevance, keyof RideWithUserInfo>;

export type OnDemandClass = SharedClassInfo &
  Instructed &
  SharedOnDemand &
  Aired & {
    kind: ClassCategory.OnDemand;
  };

export type LiveClass = SharedClassInfo &
  Live &
  Instructed &
  Aired & {
    kind: ClassCategory.Live;
  };

export type ScenicClass = SharedClassInfo &
  SharedOnDemand & {
    kind: ClassCategory.Scenic;
  };

type Live = {
  liveStreamUrl: string;
  homePelotonId?: string;
  studioPelotonId?: string; // TODO: This should maybe be owned by the big clock app
  liveClassCategory?: string;
};

type Aired = {
  language?: string;
  originLocale: Locale;
  scheduledStartTime: Time;
  difficultyLevel?: DifficultyLevel;
};

type Instructed = {
  instructorId: string;
};

export enum ClassCategory { // TODO: can we think of a more descriptive name for this?
  Scenic = 'scenic',
  OnDemand = 'onDemand',
  Studio = 'studio',
  Live = 'live',
}
