import type { Reducer } from 'redux';
import type { FilterGroup, FilterOptions, Sort } from '../models';

// Actions

export enum AvailableActionTypes {
  Request = 'pelo/filters/Request',
  RequestSuccess = 'pelo/filters/RequestSuccess',
  RequestFailure = 'pelo/filters/RequestFailure',
}

export const fetchAllFilters = () => ({
  type: AvailableActionTypes.Request as AvailableActionTypes.Request,
});

export const fetchFilterSuccess = ({ filters, sorts }: FilterOptions) => ({
  type: AvailableActionTypes.RequestSuccess as AvailableActionTypes.RequestSuccess,
  payload: { filters, sorts },
});

export const fetchFilterFailure = (error: Error) => ({
  type: AvailableActionTypes.RequestFailure as AvailableActionTypes.RequestFailure,
  payload: { error },
});

type Actions =
  | ReturnType<typeof fetchAllFilters>
  | ReturnType<typeof fetchFilterSuccess>
  | ReturnType<typeof fetchFilterFailure>;

// State

export type AvailableState = {
  isLoading: boolean;
  error?: Error;
  filters: FilterGroup[];
  sorts: Sort[];
};

export const DEFAULT_AVAILABLE_STATE: AvailableState = {
  isLoading: false,
  filters: [],
  sorts: [],
};

// Reducer

export const availableReducer: Reducer<AvailableState> = (
  state = DEFAULT_AVAILABLE_STATE,
  action: Actions,
) => {
  switch (action.type) {
    case AvailableActionTypes.Request:
      return {
        error: undefined,
        isLoading: true,
        filters: [],
        sorts: [],
      };
    case AvailableActionTypes.RequestSuccess:
      return {
        isLoading: false,
        filters: action.payload.filters,
        sorts: action.payload.sorts,
      };
    case AvailableActionTypes.RequestFailure:
      return {
        isLoading: false,
        filters: [],
        sorts: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
};
