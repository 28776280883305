import styled, { css } from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import {
  backgroundHoverInputGray,
  backgroundInputGray,
  slate3,
  darkGrayMedium,
  disabledInputGray,
  inputErrorYellow,
  inputErrorRed,
  divider2,
  white,
} from '@engage/colors';
import { spaces } from '@engage/styles';
import { label16 } from '@engage/typography';

export const sharedStyles = css<SharedProps>`
  width: 100%;
  height: 100%;
  border: none;
  vertical-align: middle;
  outline: none;
  border-radius: 6px;
  ${label16}
  font-weight: 400;
  /* to remove browser 'x' and reveal buttons on IE 11 and below */
  ::-ms-clear,
  ::-ms-reveal {
    display: none;
  }
  padding: ${props => (props.isEmptyField ? '0 60px 0 15px' : '15px 60px 4px 15px')};
  :focus,
  :-webkit-autofill {
    padding-top: 15px;
    padding-bottom: ${spaces.xxxSmall}px;
  }
  &[type='password'] {
    font-family: arial;
    font-size: 16px;
  }
  /**
   * TODO: will no longer be needed with PR #4308
   * this is used for triggering eventListener 'transitioned' so we can programmatically
   * check if chrome autofill feature is triggered
   */
  :-webkit-autofill {
    transition: background-color 0.1s ease-in-out 0s;
  }
`;

export const darkStyles = css<ThemeProps>`
  color: ${props => (props.error ? inputErrorYellow : white)};
  background-color: ${backgroundInputGray};
  :focus {
    background-color: ${backgroundHoverInputGray};
  }
  :-webkit-autofill {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
    -webkit-text-fill-color: ${white};
  }
  ${props =>
    props.disabled
      ? `color: ${darkGrayMedium};
       background-color: ${disabledInputGray};
       border: dashed 1px ${darkGrayMedium};
       ${hoverTransition({
         property: 'background-color',
         to: slate3,
       })}`
      : ''}
`;

const lightStyles = css<ThemeProps>`
  border: 1px solid;
  background-color: ${white};
  border-color: ${props => (props.error ? inputErrorRed : divider2)};
  :focus,
  :active {
    border-color: ${props => (props.error ? inputErrorRed : slate3)};
  }
  color: ${props => (props.error ? inputErrorRed : slate3)};
  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    -webkit-text-fill-color: ${slate3};
  }
  height: 50px;
  ${props =>
    props.disabled
      ? `color: ${darkGrayMedium};
      border: dashed 1px ${darkGrayMedium};
      :focus, :active {
        border-color: ${darkGrayMedium};
      }`
      : ''}
`;

const DarkInput = styled.input<SharedProps & ThemeProps>`
  ${sharedStyles}
  ${darkStyles}
`;

const LightInput = styled.input<SharedProps & ThemeProps>`
  ${sharedStyles}
  ${lightStyles}
`;

export type SharedProps = { isEmptyField: boolean; value: string | number };
export type ThemeProps = { disabled: boolean; error: boolean };

export { DarkInput, LightInput };
