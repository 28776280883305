import type { Reducer } from 'redux';

const reducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.REQUEST_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isLoading: false,
      };
    case Actions.REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const defaultState: State = {
  isLoading: false,
};

type State = {
  entity?: { canRefer: boolean };
  isLoading: boolean;
  error?: Error;
};

// Actions
export type RequestAction = { type: Actions.REQUEST };
type SuccessAction = { type: Actions.REQUEST_SUCCESS; payload: { canRefer: boolean } };
type FailureAction = { type: Actions.REQUEST_FAILURE; payload: Error };

export enum Actions {
  REQUEST = 'pelo/referralEligibility/REQUEST',
  REQUEST_SUCCESS = 'pelo/referralEligibility/REQUEST_SUCCESS',
  REQUEST_FAILURE = 'pelo/referralEligibility/REQUEST_FAILURE',
}

// Action Creators
type Action = RequestAction | SuccessAction | FailureAction;

export const loadReferralEligibility = (): RequestAction => ({
  type: Actions.REQUEST,
});

export const loadReferralEligibilitySuccess = (canRefer: boolean): SuccessAction => ({
  type: Actions.REQUEST_SUCCESS,
  payload: { canRefer },
});

export const loadReferralEligibilityFailure = (error: Error): FailureAction => ({
  type: Actions.REQUEST_FAILURE,
  payload: error,
});

// Selectors
export type ReferralSelectorState = {
  referrals: State;
};

export const getReferralEligibility = (state: ReferralSelectorState): boolean =>
  Boolean(state.referrals.entity && state.referrals.entity.canRefer);

export default reducer;
