import parseHostname from '@peloton/env/parseHostname';
import { toKubernetesApi as toStageNKubernetesApi } from '@peloton/env/toApiEnv';
import { ApiEnvs } from '@peloton/external-links/models';
import { isDefined } from '@peloton/types';
import * as Env from './env';

const isApiEnv = (value: unknown): value is ApiEnvs =>
  isDefined(value) &&
  Object.keys(ApiEnvs)
    .map(k => ApiEnvs[k as keyof typeof ApiEnvs])
    .some(v => v === value);

export type ApiOverrideGetter = () => string | null;

export const DefaultApiOverrideGetter: ApiOverrideGetter = () => null;

export const toApiEnv = (
  hostname: string,
  getApiOverride: ApiOverrideGetter = DefaultApiOverrideGetter,
) => {
  const api = getApiOverride();
  const isStagingEnv = Env.isStagingEnv(hostname);
  const isUatEnv = Env.isUatEnv(hostname);

  const defaultApi = isUatEnv && !isStagingEnv ? ApiEnvs.Qa1 : ApiEnvs.Prod;

  // check for predefined api environment override
  if (isApiEnv(api)) {
    return api;
  } else if (isDefined(api)) {
    // check for kubernetes override
    const kubeApiEnv = toKubernetesApiFromHostname(api);
    if (kubeApiEnv) {
      return kubeApiEnv;
    }
  }

  // custom api flag is __api-<api machine address with _ instead of .>
  const hostnameMatch = /--.*__api-(.*)__.*\.(secret|test|uat|xtest)\.onepeloton/.exec(
    hostname,
  );
  // even though Stage N environments use K8s, they have slightly different schemes
  const stageNEnvMatch = /--api-core-(.*)--stage/.exec(hostname);

  if (stageNEnvMatch && stageNEnvMatch[1]) {
    return toStageNKubernetesApi(parseHostname(hostname)['api']) as ApiEnvs;
  }

  // TODO: remove when we stop using api-qa envs
  const hostMatchOldPattern = hostname.indexOf('api-qa') > -1 ? 'qa1' : false;

  const parsedHostname =
    hostnameMatch &&
    hostnameMatch[1]
      // Remove extra flags from greedy regex
      .split('__')[0];

  const apiServer =
    (parsedHostname &&
      (parsedHostname.includes('k8s--')
        ? toKubernetesApi(parsedHostname)
        : parsedHostname)) ||
    hostMatchOldPattern;

  return (apiServer ? apiServer : defaultApi) as ApiEnvs;
};

const toKubernetesApiFromHostname = (hostname: string) =>
  /(api(\.|-)(?:\w*|\w*-\w*)\.(dev|stage)\.k8s)\.pelotime\.com$/.exec(hostname)?.[1] as
    | ApiEnvs
    | undefined;

const toKubernetesApi = (api: string): ApiEnvs => {
  const [service, context = 'dev'] = api.replace('k8s--', '').split('--');
  return `api-${service}.${context}.k8s` as ApiEnvs;
};

const maybeHostname = typeof window === 'undefined' ? '' : window.location.hostname;

export const isQaApiEnv = (hostname = maybeHostname, getOverride?: ApiOverrideGetter) => {
  const apiEnv = toApiEnv(hostname, getOverride);
  return apiEnv === ApiEnvs.Qa1 || apiEnv === ApiEnvs.Qa2;
};

export const isProdApi = (getOverride?: ApiOverrideGetter) =>
  toApiEnv(maybeHostname, getOverride) === ApiEnvs.Prod;

export const isLocalApiEnv = (getOverride?: ApiOverrideGetter) =>
  toApiEnv(maybeHostname, getOverride) === ApiEnvs.Local;
