import React from 'react';

export const MyTagsIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 36 35" {...props}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeWidth="1.25">
      <path
        strokeLinejoin="round"
        d="M12.918 12.794c-.443-.257-.486-.358-.578-.652v-.944c.629-.638 1.124-1.528 1.418-2.571.705-.491.879-1.504.296-2.216V4.298c0-2.18-1.223-3.672-4.02-3.672-2.722 0-4.02 1.492-4.02 3.672V6.41c-.581.712-.407 1.724.298 2.216.294 1.043.79 1.933 1.418 2.57v.945c-.282.898-3.646 2.342-6.675 3.505-.26.1-.43.349-.43.628v2.36c0 .37.299.67.667.67h9.09"
        transform="translate(6.5 5.5)"
      />
      <path
        d="M13.71 20.503l9.527.047m-8.975-4.094l9.243-.061m-1.922-2.852l-1.652 10m-2.497-10l-1.755 10"
        transform="translate(6.5 5.5)"
      />
    </g>
  </svg>
);
