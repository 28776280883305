import mitt from 'mitt';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from '@peloton/helpers/isEmpty';
import { toLanguageFromLocale, Language } from '@peloton/internationalize';
import { defaultTransition, media } from '@peloton/styles';
import { isDefined } from '@peloton/types';
import { track } from '@engage/analytics';
import { gray1, gray3, gray4 } from '@engage/colors';
import { useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label16Italic } from '@engage/typography';
import { Cta, useCta } from '@members/copy';
import { CancelIcon, MiniHashtag } from '@members/icons';
import { useLocale } from '@members/localization';
import { trackLoadedSearchResults, useTrackViewedTagSearchError } from '../analytics';
import { useLastTagSearch, useSearchTagPaginatedQuery } from '../hooks';
import {
  ScrollContext,
  PERSISTENT_NAV_BAR_HEIGHT,
  HEADER_MOBILE_HEIGHT,
  HEADER_TABLET_HEIGHT,
  SEARCH_INPUT_CONTAINER_HEIGHT,
} from '../MainView/ScrollContext';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import { Body } from './Body';
import { InputField } from './InputField';

const getTopInputContainer = (lang: Language) => {
  if (lang === Language.German) {
    return HEADER_MOBILE_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT + 16;
  } else {
    return HEADER_MOBILE_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT;
  }
};

const SearchTagsView: React.FC<React.PropsWithChildren<{ isEntered: boolean }>> = ({
  isEntered,
}) => {
  const { searchInputRef, searchContainerRef } = React.useContext(ScrollContext);
  const { goBackTo } = React.useContext(TagsContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const trackAction = useReduxAction(track);
  const { lastTagSearch: lastSearch, setLastTagSearch } = useLastTagSearch();
  const [inputIsEmpty, setInputIsEmpty] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    data,
    mutate: refetch,
    isLoading,
    error,
    loadMore,
  } = useSearchTagPaginatedQuery(
    { query: lastSearch, tagName: lastSearch },
    setShowError,
  );

  useTrackViewedTagSearchError(lastSearch, showError);

  const locale = useLocale();
  const lang = toLanguageFromLocale(locale);

  const topInputContainer = React.useMemo(() => getTopInputContainer(lang), [lang]);

  React.useEffect(() => {
    const hasPrevSearchHistory =
      isDefined(lastSearch) &&
      isDefined(goBackTo) &&
      goBackTo === TagModalView.TagDetails;
    if (hasPrevSearchHistory) {
      refetch();
    }
  }, [lastSearch, goBackTo]);

  const fetchTags = React.useCallback((value: string) => {
    setIsTyping(false);

    if (isEmpty(value)) {
      return;
    }

    if (isDefined(value)) {
      refetch();
      setLastTagSearch(value);
    }
  }, []);

  const searchResults = !inputIsEmpty || isDefined(lastSearch) ? data : null;

  React.useEffect(() => {
    const resultsLength = searchResults?.searchTag?.edges?.length ?? 0;
    const searchTerm = searchResults?.tag?.name ?? '';

    if (!inputIsEmpty && !isLoading) {
      trackAction(trackLoadedSearchResults(searchTerm, resultsLength));
    }
  }, [searchResults]);

  const emitter = React.useRef(mitt());
  const theme = inputIsEmpty ? 'placeholder' : 'standard';

  return (
    <Container ref={searchContainerRef}>
      <ContentContainer ref={containerRef}>
        <InputContainer
          theme={theme}
          ref={searchInputRef}
          topInputContainer={topInputContainer}
        >
          <InnerWrapper>
            <StyledHashtag />
            <InputField
              error={error}
              setShowError={setShowError}
              isTyping={isTyping}
              onChange={fetchTags}
              setInputIsEmpty={setInputIsEmpty}
              clearEmitter={emitter.current}
              setIsTyping={setIsTyping}
              lastSearch={lastSearch}
              isEntered={isEntered}
            />
            <ClearButton
              onClick={() => emitter.current.emit('clear')}
              aria-label={useCta(Cta.InputClear)}
              data-test-id="clearButton"
            >
              <CancelIcon />
            </ClearButton>
          </InnerWrapper>
        </InputContainer>
        <Body
          inputIsEmpty={inputIsEmpty}
          isLoading={isLoading}
          isTyping={isTyping}
          loadMore={loadMore}
          refetch={refetch}
          showError={showError}
          setShowError={setShowError}
          searchResults={searchResults}
        />
      </ContentContainer>
    </Container>
  );
};

export { SearchTagsView };

const SEARCH_INPUT_CONTAINER_PADDING = 12;

const ClearButton = styled.button`
  position: absolute;
  cursor: pointer;
  width: ${spaces.large}px;
  height: ${spaces.large}px;
  fill: ${gray4};
  right: ${spaces.medium}px;
  top: ${spaces.medium + 2}px;
  ${defaultTransition('opacity')}
`;

const StyledHashtag = styled(MiniHashtag)`
  position: absolute;
  top: ${spaces.large}px;
  left: ${spaces.medium}px;
  fill: ${gray3};
`;

const InnerWrapper = styled.div`
  position: relative;
`;

const InputContainer = styled.div<{ theme: string; topInputContainer: number }>`
  ${label16Italic}
  position: absolute;
  top: ${({ topInputContainer }) => `${topInputContainer}px`};
  width: 100%;
  border-radius: 5px;
  height: ${SEARCH_INPUT_CONTAINER_HEIGHT}px;
  padding: ${SEARCH_INPUT_CONTAINER_PADDING}px;
  background: ${gray1};
  z-index: 3;
  ${ClearButton} {
    opacity: ${({ theme }) => (theme === 'placeholder' ? '0' : '1')};
    visibility: ${({ theme }) => (theme === 'placeholder' ? 'hidden' : 'visible')};
  }

  ${media.iPhone6`
    top: ${HEADER_MOBILE_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT}px;
  `}
  ${media.tablet`
    top: ${HEADER_TABLET_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT}px;
  `}
`;

const ContentContainer = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding-top: ${PERSISTENT_NAV_BAR_HEIGHT + SEARCH_INPUT_CONTAINER_HEIGHT}px;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
`;
