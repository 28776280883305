import { schema } from 'normalizr';
import type { ExtLink } from '@peloton/external-links';

export type Instructor = {
  aboutImageUrl: string;
  darkAboutImageUrl: string;
  id: string;
  imageUrl: string;
  isVisible: boolean;
  linkUrl: ExtLink;
  name: string;
  username: string;
};

export const toInstructorSchema = (options: schema.EntityOptions = {}) =>
  new schema.Entity('instructors', {}, options);
