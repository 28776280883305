import { compile } from 'path-to-regexp';

export const PROFILE_WORKOUTS_ROUTE = '/profile/workouts/:workoutId?';
export const MEMBER_WORKOUTS_ROUTE = '/members/:userId/workouts/:workoutId?';

export const toProfileWorkoutsPath = compile(PROFILE_WORKOUTS_ROUTE);
export const toMemberWorkoutsPath = compile(MEMBER_WORKOUTS_ROUTE);

export const toWorkoutHistoryPath = (isMe: boolean = true, userId?: string) =>
  isMe ? toProfileWorkoutsPath() : toMemberWorkoutsPath({ userId });
