import { schema } from 'normalizr';

export type ContentFocusLabel = {
  id: string;
  label: string;
};

export const toContentFocusLabelSchema = () =>
  new schema.Entity(
    'contentFocusLabels',
    {},
    {
      processStrategy: toContentFocusLabel,
      idAttribute: label => Object.keys(label)[0],
    },
  );

const toContentFocusLabel = (apiContentFocusLabel: any) => ({
  id: Object.keys(apiContentFocusLabel)[0],
  label: apiContentFocusLabel[Object.keys(apiContentFocusLabel)[0]],
});
