/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassGroup } from '../../../api-v2-models/generated/ClassGroup';
import type { ClassGroupDetailsResponse } from '../../../api-v2-models/generated/ClassGroupDetailsResponse';
import type { ContentLocaleEnum } from '../../../api-v2-models/generated/ContentLocaleEnum';
import type { FitnessDisciplineEnum } from '../../../api-v2-models/generated/FitnessDisciplineEnum';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Gets the class group details for the freemium collection
 * return the class group detail for the logged-in user. Unpublished (is_available==False) class groups will be hidden.
 * @param instructorDetails Whether instructor details should be fetched, these will be stored in the `instructor` key of each ride.
 * @param musicDetails Whether music details should be fetched, these will be stored in the `playlist` key of each ride. Please note that if the user that made the request is not authorized to take the ride then stub data will be returned instead.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns ClassGroupDetailsResponse Successful response
 * @throws ApiError
 */
export const getFreemiumClassGroupDetails = (
    api: BaseAPI,
    instructorDetails?: boolean,
    musicDetails?: boolean,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<ClassGroupDetailsResponse> => {
    const localVarPath = `/api/class_group/freemium`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof instructorDetails !== 'undefined') {
        localVarQueryParameter['instructor_details'] = instructorDetails;
    }
    if(typeof musicDetails !== 'undefined') {
        localVarQueryParameter['music_details'] = musicDetails;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets all programs
 * return all available programs for the logged-in user.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getPrograms = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    options: any = {},
): AxiosPromise<{
    /**
     * total number of the programs
     */
    total?: number,
    data?: Array<ClassGroup>,
}> => {
    const localVarPath = `/api/class_groups/program`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets class group detail
 * return the class group detail for the logged-in user. Unpublished (is_available==False) class groups will be hidden.
 * @param classGroupId The class group id
 * @param instructorDetails Whether instructor details should be fetched, these will be stored in the `instructor` key of each ride.
 * @param musicDetails Whether music details should be fetched, these will be stored in the `playlist` key of each ride. Please note that if the user that made the request is not authorized to take the ride then stub data will be returned instead.
 * @param sortBy Response to be sorted by this input value, "none" to exclude.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns ClassGroupDetailsResponse Successful response
 * @throws ApiError
 */
export const getClassGroupDetails = (
    api: BaseAPI,
    classGroupId: string,
    instructorDetails?: boolean,
    musicDetails?: boolean,
    sortBy?: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<ClassGroupDetailsResponse> => {
    const localVarPath = `/api/class_group/${classGroupId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof instructorDetails !== 'undefined') {
        localVarQueryParameter['instructor_details'] = instructorDetails;
    }
    if(typeof musicDetails !== 'undefined') {
        localVarQueryParameter['music_details'] = musicDetails;
    }
    if(typeof sortBy !== 'undefined') {
        localVarQueryParameter['sort_by'] = sortBy;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets all collections
 * return all available collections for the logged-in user.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param classTypeId Return classes of this specific class type.
 * @param classLanguages Return classes that has one of the class language from this list
 * @param duration Return classes that match this specific run time (seconds)
 * @param instructorId Return classes taught by this specific instructor
 * @param hasWorkout If set to True, return the classes that the currently logged-in user has workout
 * @param captionLocales Return classes that has one of the caption available from this list
 * @param isFavoriteRide If set to True, return the classes that the currently logged-in user bookmarked
 * @param isSessions Return classes that are sessions eligible
 * @param difficultyLevel Return classes that match this specific difficulty
 * @param superGenreId Return classes of this specific super_genre
 * @param discipline Return classes that match this specific fitness_discipline or browse_category There can be multiple fitness_disciplines, only one browse_category at a time
 * @param sortBy Return classes (rides) sorted by a certain criteria, original_air_time refers to the air time of live classes, for classes which are only offered as on-demand classes), it refers to video recorded time. top_rated refers to ride's overall_rating_avg, difficulty refers to ride's difficulty_rating_avg, trending refers to ride's current in-progress workouts.
 * @param desc Return classes in descending/ascending order, used with sort_by
 * @returns any Successful response
 * @throws ApiError
 */
export const getCollections = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    classTypeId?: string,
    classLanguages?: Array<ContentLocaleEnum>,
    duration?: 300 | 600 | 900 | 1200 | 1800 | 2700 | 3600 | 4500 | 5400 | 7200,
    instructorId?: string,
    hasWorkout?: boolean,
    captionLocales?: Array<ContentLocaleEnum>,
    isFavoriteRide?: boolean,
    isSessions?: boolean,
    difficultyLevel?: 'beginner' | 'intermediate' | 'advanced',
    superGenreId?: string,
    discipline?: Array<FitnessDisciplineEnum>,
    sortBy?: 'original_air_time' | 'popularity' | 'top_rated' | 'difficulty' | 'trending',
    desc?: boolean,
    options: any = {},
): AxiosPromise<{
    /**
     * total number of the collections
     */
    total?: number,
    data?: Array<ClassGroup>,
}> => {
    const localVarPath = `/api/class_groups/collection`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof classTypeId !== 'undefined') {
        localVarQueryParameter['class_type_id'] = classTypeId;
    }
    if(typeof classLanguages !== 'undefined') {
        localVarQueryParameter['class_languages'] = classLanguages;
    }
    if(typeof duration !== 'undefined') {
        localVarQueryParameter['duration'] = duration;
    }
    if(typeof instructorId !== 'undefined') {
        localVarQueryParameter['instructor_id'] = instructorId;
    }
    if(typeof hasWorkout !== 'undefined') {
        localVarQueryParameter['has_workout'] = hasWorkout;
    }
    if(typeof captionLocales !== 'undefined') {
        localVarQueryParameter['caption_locales'] = captionLocales;
    }
    if(typeof isFavoriteRide !== 'undefined') {
        localVarQueryParameter['is_favorite_ride'] = isFavoriteRide;
    }
    if(typeof isSessions !== 'undefined') {
        localVarQueryParameter['is_sessions'] = isSessions;
    }
    if(typeof difficultyLevel !== 'undefined') {
        localVarQueryParameter['difficulty_level'] = difficultyLevel;
    }
    if(typeof superGenreId !== 'undefined') {
        localVarQueryParameter['super_genre_id'] = superGenreId;
    }
    if(typeof discipline !== 'undefined') {
        localVarQueryParameter['discipline'] = discipline;
    }
    if(typeof sortBy !== 'undefined') {
        localVarQueryParameter['sort_by'] = sortBy;
    }
    if(typeof desc !== 'undefined') {
        localVarQueryParameter['desc'] = desc;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

