import { prop, indexBy, mergeWith } from 'ramda';
import type { Reducer } from 'redux';
import type { BrowseCategorySlug } from '@engage/browse-categories';
import type { ScheduledClass } from '../models';

export const SCHEDULE_NAMESPACE = 'schedule';

const DEFAULT_STATE: ScheduleReducerState = {
  classes: {},
};

type Action = ScheduleAction | LoadSchedulePageAction;

export const scheduleReducer: Reducer<ScheduleReducerState> = (
  state = DEFAULT_STATE,
  action: Action,
) => {
  switch (action.type) {
    case SchedulePageActionType.LoadSchedulePage:
      return {
        ...state,
        isLoading: true,
      };
    case ScheduleActionType.RequestSuccess:
      return {
        classes: mergeIndex(state.classes, action.payload),
        isLoading: false,
      };
    case ScheduleActionType.RequestFailure:
      return {
        ...state,
        classes: {},
        isLoading: false,
      };
    case ScheduleActionType.RequestClassSuccess:
      return {
        ...state,
        classes: mergeIndex(state.classes, [action.payload]),
      };
    case ScheduleActionType.UpdateCountedIn: {
      const updatedClass = {
        ...state.classes[action.payload.scheduledClassId],
        reservationId: action.payload.reservationId,
      };
      return {
        ...state,
        classes: mergeIndex(state.classes, [updatedClass]),
      };
    }

    default:
      return state;
  }
};

export const mergeIndex = (existing: Classes, classes: ScheduledClass[]): Classes =>
  mergeWith(
    (existingClass, klass) => ({ ...existingClass, ...klass }),
    existing,
    indexBy(prop('id'), classes),
  );

export const loadScheduleSuccess = (scheduledClasses: ScheduledClass[]) => ({
  type: ScheduleActionType.RequestSuccess as ScheduleActionType.RequestSuccess,
  payload: scheduledClasses,
});

export const loadScheduleFailure = () => ({
  type: ScheduleActionType.RequestFailure as const,
});

export const loadScheduledClassSuccess = (scheduledClass: ScheduledClass) => ({
  type: ScheduleActionType.RequestClassSuccess as ScheduleActionType.RequestClassSuccess,
  payload: scheduledClass,
});

export const updateCountedIn = (scheduledClassId: string, reservationId?: string) => ({
  type: ScheduleActionType.UpdateCountedIn as ScheduleActionType.UpdateCountedIn,
  payload: {
    scheduledClassId,
    reservationId,
  },
});

export const updateCountedInFailed = (scheduledClassId: string) => ({
  type: ScheduleActionType.UpdateCountedInFailed as ScheduleActionType.UpdateCountedInFailed,
  payload: {
    scheduledClassId,
  },
});

type Classes = Record<string, ScheduledClass>;

export type ScheduleReducerState = {
  classes: Classes;
  isLoading?: boolean;
};

export enum ScheduleActionType {
  RequestSuccess = 'pelo/schedule/RequestSuccess',
  RequestFailure = 'pelo/schedule/RequestFailure',
  RequestClassSuccess = 'pelo/schedule/RequestClassSuccess',
  UpdateCountedIn = 'pelo/schedule/UpdateCountedIn',
  UpdateCountedInFailed = 'pelo/schedule/UpdateCountedInFailed',
}

type ScheduleAction =
  | ReturnType<typeof loadScheduleSuccess>
  | ReturnType<typeof loadScheduleFailure>
  | ReturnType<typeof loadScheduledClassSuccess>
  | ReturnType<typeof updateCountedIn>;

export type ScheduleSelectorState = { [SCHEDULE_NAMESPACE]: ScheduleReducerState };

export enum SchedulePageActionType {
  LoadSchedulePage = 'pelo/schedulePage/LOAD',
  UpdateSchedulePage = 'pelo/schedulePage/UPDATE',
}

export enum UpcomingLiveActionType {
  LoadUpcomingLive = 'pelo/upcomingLive/LOAD',
  LoadUpcomingLiveNoLoading = 'pelo/upcomingLive/SEAMLESS_LOAD',
}

export type LoadSchedulePageAction = ReturnType<typeof loadSchedulePage>;

export const loadSchedulePage = (categorySlug: BrowseCategorySlug) => ({
  type: SchedulePageActionType.LoadSchedulePage,
  payload: { categorySlug },
});

export const updateSchedulePage = (categorySlug: BrowseCategorySlug) => ({
  type: SchedulePageActionType.UpdateSchedulePage,
  payload: { categorySlug },
});

export const loadUpcomingLiveSeamless = () => ({
  type: UpcomingLiveActionType.LoadUpcomingLiveNoLoading,
});
