// Autogenerated from `packages/@members/copy/copy.generated/__copy__.ts.hbs`

import { toCopyComponent, toFormattedText } from '@peloton/copy';
import type { CopyHook } from '@peloton/copy-generic';
import type { CopyIDs } from '../CopyIDs';
import { useAllCopy } from '../CopyProvider/CopyProvider';
import type { Classes } from '../models.generated';

export const useClasses: CopyHook<CopyIDs<typeof Classes>> = (id, values) => {
  const copy = useAllCopy();
  const value = copy?.classesCollection?.[id];

  if (!value) {
    console.error(`No copy found for id ${id}`);
    return '';
  }

  return toFormattedText(value, values) as string;
};

export const ClassesCopy = toCopyComponent(useClasses);
