// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const AcceptTerms = {
  AcceptTerms: 'acceptTerms',
  Acknowledge: 'acknowledge',
  AcknowledgeAndAccept: 'acknowledgeAndAccept',
  Agree: 'agree',
  AcknowledgeUpdatedPolicies: 'acknowledgeUpdatedPolicies',
  AgreeToUpdatedMembershipTerms: 'agreeToUpdatedMembershipTerms',
  AgreeToUpdatedTerms: 'agreeToUpdatedTerms',
  Continue: 'continue',
  Decline: 'decline',
  DeclineAgeConfirmation: 'declineAgeConfirmation',
  DeclineAgeTerms: 'declineAgeTerms',
  FindTerms: 'findTerms',
  GoBack: 'goBack',
  Logout: 'logout',
  MembershipTerms: 'membershipTerms',
  PrivacyPolicy: 'privacyPolicy',
  TermsOfService: 'termsOfService',
  Toast: 'toast',
  UpdatedMembershipTerms: 'updatedMembershipTerms',
  UpdatedTerms: 'updatedTerms',
  UpdateToTerms: 'updateToTerms',
  UpdateToTermsAndPolicies: 'updateToTermsAndPolicies',
  UpdatedPoliciesAndTerms: 'updatedPoliciesAndTerms',
  UpdatedPolicies: 'updatedPolicies',
} as const;
