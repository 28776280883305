import React from 'react';
import styled from 'styled-components';
import { slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { title36, body18, label14Caps } from '@engage/typography';
import { Site, SiteCopy } from '@members/copy';

const PageNotFound: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Wrapper>
    <H data-test-id="pageNotFound">
      <SiteCopy id={Site.PageNotFound} />
    </H>
    <P>
      <SiteCopy id={Site.PageNotFoundDetails} />
    </P>
    <Link href="/home">Go to homepage</Link>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const H = styled.h2`
  ${title36}
  text-align: center;
  margin-top: 156px;
`;

const P = styled.p`
  ${body18}
  margin-top: ${spaces.medium}px;
  margin-bottom: ${spaces.xLarge}px;
  text-align: center;
`;

const Link = styled.a`
  ${label14Caps}
  border-radius: 9999px;
  background: ${slate1};
  color: white;
  padding: 10px;
  width: 158px;
  height: 40px;
`;

export default PageNotFound;
