import { replace } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import { getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { isMe } from '@peloton/auth';
import { toOverviewUrl } from '@peloton/links/members';
import {
  getAchievementsForMember,
  loadAchievementsForMember,
} from '@engage/achievements';
import { track } from '@engage/analytics';
import {
  getMember,
  isMembersSuccessActionForMember,
  loadMember,
  ME,
  shouldShowPrivateInfo,
} from '@engage/members';
import { isMemberPath } from '@members/profile/urls';
import { trackViewedAchievementsPage } from './analytics';
import { toAchievementsUrl } from './urls';

export const loadAchievementsPageSaga = function* (
  client: Client,
  action: ReturnType<typeof loadAchievementsPage>,
): SagaIterator {
  const usernameOrId = action.payload.usernameOrId ?? ME;
  let member = yield select(getMember, usernameOrId);
  if (!member) {
    yield put(loadMember(usernameOrId));
    const membersAction = yield take(isMembersSuccessActionForMember(usernameOrId));
    member = membersAction.payload;
  }

  const isViewedMemberMe = yield select(isMe, member.id);
  if (isViewedMemberMe && isMemberPath(location.pathname)) {
    yield put(replace(toAchievementsUrl(member.id, isViewedMemberMe)));
  }

  if (!shouldShowPrivateInfo(member)) {
    yield put(replace(toOverviewUrl(member.username)));
  } else {
    const achievements = yield select(getAchievementsForMember, member.id);
    if (!achievements) {
      yield put(loadAchievementsForMember(member.id));
    }
  }
  yield put(
    track(
      trackViewedAchievementsPage({
        member,
        sharedLeaderboardTag: action.payload.sharedLeaderboardTag,
      }),
    ),
  );
};

export const achievementsPageSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(AchievementsPageActionType.Load, loadAchievementsPageSaga, client);
};

enum AchievementsPageActionType {
  Load = 'pelo/page/achievements/Load',
}

export const loadAchievementsPage = (
  sharedLeaderboardTag: string | null,
  usernameOrId?: string,
) => ({
  type: AchievementsPageActionType.Load,
  payload: { usernameOrId, sharedLeaderboardTag },
});
