import { css } from 'styled-components';
import { spaces } from '@engage/styles';

// body
export const body8 = css`
  font-size: ${spaces.xSmall}px;
  line-height: ${spaces.small}px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const body8Italic = css`
  font-style: italic;
  ${body8}
`;

export const body10 = css`
  font-size: ${spaces.xSmall}px;
  line-height: ${spaces.small}px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const body10Italic = css`
  font-style: italic;
  ${body10}
`;

export const body12 = css`
  font-size: ${spaces.small}px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const body12Italic = css`
  font-style: italic;
  ${body12}
`;

export const body14 = css`
  font-size: 14px;
  line-height: ${spaces.large}px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const body14Italic = css`
  font-style: italic;
  ${body14}
`;

export const body16 = css`
  font-size: ${spaces.medium}px;
  line-height: ${spaces.xLarge}px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const body16Italic = css`
  font-style: italic;
  ${body16}
`;

export const body18 = css`
  font-size: 18px;
  line-height: ${spaces.xxLarge}px;
  font-weight: 700;
  letter-spacing: 0;
`;

export const body18Italic = css`
  font-style: italic;
  ${body18}
`;
