import { stringify } from 'querystring';
import React, { useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { history } from '@peloton/redux';
import { LessThan } from '@peloton/responsive';
import { hover, media } from '@peloton/styles';
import { BrowseCategorySlug } from '@engage/browse-categories';
import { slate2, slate3, gray2, gray3, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { link2, label12 } from '@engage/typography';
import * as Analytics from '@members/analytics';
import { useLogout } from '@members/auth/OauthProvider';
import { CHALLENGES_ROUTE } from '@members/challenges';
import { TopNav, TopNavCopy } from '@members/copy';
import {
  ExploreTags as BrowseTags,
  FindMembersNew as FindMembers,
  ReferFriends as GiftIcon,
  Settings as SettingsIcon,
  ShoppingCart,
  Calendar,
  Bookmark,
  StackedPlay,
  LogOut,
} from '@members/icons';
import { useGetApps } from '@members/modal-get-app';
import { COLLECTIONS_ROUTE } from '@members/pg-collections';
import { FEED_ROUTE } from '@members/pg-feed/Routes';
import { HOMESCREEN_ROUTE } from '@members/pg-homescreen/Routes';
import {
  LIBRARY_CATEGORY_SELECTION_ROUTE,
  toLibraryCategoryRoute,
} from '@members/pg-library/urls';
import { PREFS_ROUTE } from '@members/pg-preferences/urls';
import { PROGRAMS_ROUTE } from '@members/pg-programs/urls';
import { SEARCH_ROUTE } from '@members/pg-search/urls';
import { useReferralsUrlWithSource } from '@members/referrals';
import { SCHEDULE_ROUTE, YOUR_SCHEDULE_ROUTE } from '@members/schedule/urls';

import { TagModalView } from '@members/tags/shared';
import { toTagsModalQuery } from '@members/tags/urls';
import { useIsFreemiumMember } from '@members/tiering/hooks/useIsFreemiumMember';
import {
  mobileNavMainLink,
  mobileNavSubLink,
  makeLinkActivatable,
} from '../../../sharedLinkStyles';
import { ANALYTICS_SOURCE, MENU_SOURCE, MENU_ANALYTICS_OPTIONS } from '../../analytics';
import NavLink from '../../NavLink';
import { NotificationsBadge } from '../MiniNavBlock/NotificationsBadge';
import { PendingInvitesBadge, InviteDigit, usePendingInviteSetup } from '../TopNavLinks';
import type { MobileNavViewProps } from './MobileNavDrawer';
import {
  SlideUpDrawerContainer,
  DRAWER_OPEN_CLASS,
  DRAWER_CLOSED_CLASS,
} from './SlideUpDrawerContainer';

export const MobileNavDrawerView: React.FC<
  React.PropsWithChildren<MobileNavViewProps>
> = ({
  handleSearchClick,
  handleOpenGetApp,
  handleStackClick,
  handleNotificationsClick,
  isOpen,
  track,
  ...props
}) => {
  const hasGetApps = useGetApps();
  useEffect(() => {
    if (isOpen) {
      setScrollDisabledOnBody(true);
    }

    return () => {
      if (isOpen) {
        setScrollDisabledOnBody(false);
      }
    };
  });

  useEffect(() => {
    // Do not allow menu animation on initial render
    document.body.classList.remove('allow-menu-animation');

    return () => {
      // Reset allow menu animation state when unmounted (>= tablet breakpoints)
      document.body.classList.remove('allow-menu-animation');
    };
  }, []);

  const goToBrowseTags = () =>
    history.push(toTagsModalQuery(TagModalView.BrowseTags), { source: MENU_SOURCE });

  const referralsUrlWithSource = useReferralsUrlWithSource('nav');
  const logout = useLogout();

  const onClick = (cb: () => void) => () => {
    props.handleMenuClose();
    cb();
  };
  const phoneBreakpoint = 'tablet';
  const bookmarkMap = { ['is_favorite_ride']: JSON.stringify(['true']) };
  const isFreemiumMember = useIsFreemiumMember();
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
  return (
    <SlideUpDrawerContainer>
      <Container
        isOpen={isOpen}
        data-test-id="mobileNavDrawer"
        className={isOpen ? DRAWER_OPEN_CLASS : DRAWER_CLOSED_CLASS}
      >
        <NoScrollGlobalStyle />
        <OrderedNavLinks />

        <LessThan breakpoint={phoneBreakpoint}>
          <MobileLinks>
            <MobileShortcutLink
              to={{
                pathname: toLibraryCategoryRoute(BrowseCategorySlug.All),
                search: `?${stringify(bookmarkMap)}`,
                state: { source: ANALYTICS_SOURCE },
              }}
              data-test-id="bookmarkLink"
            >
              <StyledBookmark />
              <TopNavCopy id={TopNav.Bookmarks} />
            </MobileShortcutLink>
            <MobileShortcutButton
              onClick={onClick(handleStackClick)}
              data-test-id="mobileStackLink"
            >
              <StyledStack />
              <TopNavCopy id={TopNav.StackedClasses} />
            </MobileShortcutButton>
            <MobileShortcutLink
              to={{ pathname: YOUR_SCHEDULE_ROUTE, state: { source: ANALYTICS_SOURCE } }}
              data-test-id="scheduleLink"
            >
              <StyledCalendar />
              <TopNavCopy id={TopNav.MySchedule} />
            </MobileShortcutLink>
            <MobileShortcutButton
              onClick={() => {
                handleNotificationsClick();
                setIsNotificationsOpen(true);
              }}
              data-test-id="mobileNotificationsLink"
            >
              <NotificationsBadge isNotificationsOpen={isNotificationsOpen} />
              <TopNavCopy id={TopNav.Notifications} />
            </MobileShortcutButton>
          </MobileLinks>
        </LessThan>

        <SubLinks data-test-id="mobileSubLinks">
          <SubButton
            onClick={onClick(handleSearchClick)}
            data-test-id="mobileFindMembersLink"
          >
            <StyledFindMembersIcon />
            <TopNavCopy id={TopNav.FindMembers} />
          </SubButton>
          {!isFreemiumMember ? (
            <SubLink
              href={referralsUrlWithSource}
              data-test-id="mobileReferralsLink"
              target="_blank"
              rel="noopener"
            >
              <StyledGiftIcon />
              <TopNavCopy id={TopNav.ReferFriends} />
            </SubLink>
          ) : null}
          <SubButton
            onClick={onClick(goToBrowseTags)}
            data-test-id="mobileExploreTagsLink"
          >
            <StyledBrowseTags />
            <TopNavCopy id={TopNav.ExploreTags} />
          </SubButton>
          <ActivatableSubLink
            to={PREFS_ROUTE}
            customProps={{
              handleClick: () => track(Analytics.trackClickPreferences(MENU_SOURCE)),
            }}
            data-test-id="mobileAccountLink"
          >
            <StyledSettingsIcon />
            <TopNavCopy id={TopNav.Preferences} />
          </ActivatableSubLink>
          <SubLinkWithClick
            href={props.orderHistoryAccountHref}
            onClick={onClick(() => track(Analytics.trackClickOrderHistory()))}
            target="_blank"
            rel="noopener"
          >
            <StyledOrderhistoryIcon />
            <TopNavCopy id={TopNav.OrderHistory} />
          </SubLinkWithClick>
          <SubButton
            onClick={onClick(() => logout(MENU_ANALYTICS_OPTIONS))}
            data-test-id="mobileLogoutLink"
          >
            <StyledLogoutIcon />
            <TopNavCopy id={TopNav.Logout} />
          </SubButton>
        </SubLinks>
        <SubLinks>
          {hasGetApps ? (
            <SubButton12 onClick={onClick(handleOpenGetApp)}>
              <TopNavCopy id={TopNav.PelotonApps} />
            </SubButton12>
          ) : null}

          <SubLinkWithClick12
            href={props.boutiqueHref}
            onClick={() =>
              onClick(() => track(Analytics.trackClickBoutique(MENU_SOURCE)))
            }
            data-test-id="mobileBoutiqueLink"
            target="_blank"
            rel="noopener"
          >
            <TopNavCopy id={TopNav.PelotonApparel} />
          </SubLinkWithClick12>

          <SubLinkWithClick12
            href={props.supportHref}
            onClick={onClick(() => track(Analytics.trackClickSupport(MENU_SOURCE)))}
            data-test-id="mobileSupportLink"
            target="_blank"
            rel="noopener"
          >
            <TopNavCopy id={TopNav.Support} />
          </SubLinkWithClick12>
          <Filler />
        </SubLinks>
      </Container>
    </SlideUpDrawerContainer>
  );
};

const NoScrollGlobalStyle = createGlobalStyle`
  .no-scroll {
    @supports (-webkit-overflow-scrolling: touch) {
      position: relative;
      overflow: hidden;
    }
  }
`;

const OrderedNavLinks = () => {
  return (
    <MainLinks>
      <HomepageLink />
      <ClassesLink />
      <ProgramsLink />
      <CollectionsLink />
      <ScheduleLink />
      <FeedLink />
      <ChallengesLink />
    </MainLinks>
  );
};

const ScheduleLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <ActivatableMainLink
      to={{ pathname: SCHEDULE_ROUTE, state: { source: ANALYTICS_SOURCE } }}
      data-test-id="mobileScheduleLink"
      isActive={(match, location) => {
        return match ? true : location.pathname === YOUR_SCHEDULE_ROUTE;
      }}
    >
      <TopNavCopy id={TopNav.Schedule} />
    </ActivatableMainLink>
  );
};

export const ScheduleLinkWithInvites: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { error, valueToShow } = usePendingInviteSetup();

  return (
    <ScheduleMobileMainLink
      to={{ pathname: SCHEDULE_ROUTE, state: { source: ANALYTICS_SOURCE } }}
      isActive={(match, location) => {
        return match ? true : location.pathname === YOUR_SCHEDULE_ROUTE;
      }}
      data-test-id="mobileScheduleLink"
    >
      <TopNavCopy id={TopNav.Schedule} />
      {!error && valueToShow > 0 ? (
        <PendingInvitesBadge numOfPendingInvites={valueToShow}>
          <InviteDigit>{valueToShow}</InviteDigit>
        </PendingInvitesBadge>
      ) : null}
    </ScheduleMobileMainLink>
  );
};

const HomepageLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActivatableMainLink
    to={HOMESCREEN_ROUTE}
    data-test-id="mobileHomeLink"
    isActive={(match, location) => {
      return match ? true : location.pathname === SEARCH_ROUTE;
    }}
  >
    <TopNavCopy id={TopNav.Home} />
  </ActivatableMainLink>
);

const FeedLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ScheduleMobileMainLink
    to={{ pathname: FEED_ROUTE, state: { source: ANALYTICS_SOURCE } }}
    data-test-id="mobileFeedLink"
  >
    <TopNavCopy id={TopNav.Feed} />
  </ScheduleMobileMainLink>
);

const ClassesLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <ActivatableMainLink
      to={LIBRARY_CATEGORY_SELECTION_ROUTE}
      data-test-id="mobileClassesLink"
      isActive={(match, location) => {
        return match ? true : location.pathname === SEARCH_ROUTE;
      }}
    >
      <TopNavCopy id={TopNav.Classes} />
    </ActivatableMainLink>
  );
};

const ChallengesLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActivatableMainLink
    to={{ pathname: CHALLENGES_ROUTE }}
    data-test-id="mobileChallengesLink"
  >
    <TopNavCopy id={TopNav.Challenges} />
  </ActivatableMainLink>
);

const CollectionsLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActivatableMainLink
    to={{ pathname: COLLECTIONS_ROUTE }}
    data-test-id="collectionsLink"
  >
    <TopNavCopy id={TopNav.Collections} />
  </ActivatableMainLink>
);

const ProgramsLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActivatableMainLink
    to={{ pathname: PROGRAMS_ROUTE }}
    data-test-id="mobileProgramsLink"
  >
    <TopNavCopy id={TopNav.Programs} />
  </ActivatableMainLink>
);

const setScrollDisabledOnBody = (disable: boolean) => {
  if (disable) {
    document.body.classList.add('no-scroll');
    document.documentElement.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
    document.documentElement.classList.remove('no-scroll');
  }
};

const iconStyle = css`
  fill: ${slate3} !important;
  width: ${spaces.large}px;
  height: ${spaces.large}px;
  margin-right: 10px;
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  ${iconStyle};
`;

const StyledLogoutIcon = styled(LogOut)`
  ${iconStyle};
  margin-right: ${spaces.xSmall}px;
  margin-left: ${spaces.tiny}px;
`;

const StyledFindMembersIcon = styled(FindMembers)`
  ${iconStyle};
`;

const StyledGiftIcon = styled(GiftIcon)`
  ${iconStyle};
`;

const StyledStack = styled(StackedPlay)`
  ${iconStyle};
`;

const StyledBookmark = styled(Bookmark)`
  ${iconStyle};
`;

const StyledCalendar = styled(Calendar)`
  ${iconStyle};
`;

const StyledOrderhistoryIcon = styled(ShoppingCart)`
  ${iconStyle};
`;

const StyledBrowseTags = styled(BrowseTags)`
  ${iconStyle};
`;

const MainLinks = styled.div`
  padding: 8px 0;
`;

const MainLinkStyles = css`
  ${mobileNavMainLink}
  color: ${slate2};
  display: flex;
  padding: ${spaces.small}px ${spaces.xxxLarge}px;
  align-items: center;
  font-weight: 600;

  ${media.tablet`
    padding: ${spaces.medium}px ${spaces.xxxLarge}px;
  `}
  ${hover`
    transition: background-color 250ms ease;
    background-color: ${gray2};
    color: ${slate2};
  `}
`;

const MainLink = styled(NavLink)<{ children?: React.ReactNode }>`
  ${MainLinkStyles}
`;

// Mobile Phone Only Shortcut Links - Bookmarks, stacks, calendar

const MobileLinks = styled.div`
  border-top: 1px solid ${gray3};
  border-bottom: 1px solid ${gray3};
  padding: ${spaces.medium}px 0px;
  margin: 0px ${spaces.xxxLarge}px;
`;

const MobileShortcutLink = styled(MainLink)`
  ${MainLinkStyles}
  padding: ${spaces.small}px 0px;
  width: 100%;
`;
const MobileShortcutButton = styled.button`
  ${MainLinkStyles}
  padding: ${spaces.small}px 0px;
  width: 100%;
`;

const SubLinks = styled.div`
  ${media.tablet`
    border-top: 1px solid ${gray3};
  `}
  padding: 8px 0px;
`;

const SubItemStyles = css`
  ${mobileNavSubLink}
  ${link2}
  display: flex;
  align-items: left;
  color: ${slate2};
  padding: ${spaces.medium}px ${spaces.xxxLarge}px;
  font-weight: 600;
`;

const SubLink = styled.a`
  ${SubItemStyles}
`;

const SubLinkWithClick = styled.a`
  ${SubItemStyles}
`;

const SubLinkWithClick12 = styled.a`
  ${SubItemStyles}
  ${label12}
  font-weight: 600;
  padding: ${spaces.xSmall}px ${spaces.xxxLarge}px;
`;

const SubRouterLink = styled(NavLink)`
  ${SubItemStyles}
`;

const SubButton = styled.button`
  ${SubItemStyles}
  width: 100%;
`;

const SubButton12 = styled.button`
  ${SubItemStyles}
  width: 100%;
  ${label12}
  font-weight: 600;
  padding: ${spaces.xSmall}px ${spaces.xxxLarge}px;
`;

const ActivatableMainLink = makeLinkActivatable(MainLink);
const ActivatableSubLink = makeLinkActivatable(SubRouterLink);

const ScheduleMobileMainLink = styled(ActivatableMainLink)`
  display: flex;
  align-items: center;
`;

const Container = styled.div<{ className: string; isOpen: boolean }>`
  background-color: ${white};
  height: calc(100vh - 60px);
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  z-index: -1;
`;

// Bottom navigation on mobile safari sits in front of the bottom of the page
// This fills 45px of nav plus 30px padding
const Filler = styled.div`
  height: 75px;
`;
