import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { LIVE_VIDEO_ROUTE, VIDEO_ON_DEMAND_ROUTE } from './urls';

const Routes = [
  <Route
    key={VIDEO_ON_DEMAND_ROUTE}
    exact={true}
    path={VIDEO_ON_DEMAND_ROUTE}
    component={lazyLoader(
      () => import('./PlayerVod' /* webpackChunkName: "PlayerVodPage" */),
    )}
  />,
  <Route
    key={LIVE_VIDEO_ROUTE}
    exact={true}
    path={LIVE_VIDEO_ROUTE}
    component={lazyLoader(
      () => import('./PlayerLive' /* webpackChunkName: "PlayerLivePage" */),
    )}
  />,
];

export default Routes;
