import { css } from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { slate3, superRed, white } from '@engage/colors';

const linkStyles = css`
  cursor: pointer;
`;

export const link1 = css`
  ${linkStyles}
  ${hoverTransition(
    {
      property: 'color',
      to: slate3,
    },
    {
      property: 'fill',
      to: slate3,
    },
  )}
`;

export const link2 = css`
  ${linkStyles}
  ${hoverTransition(
    {
      property: 'color',
      to: superRed,
    },
    {
      property: 'fill',
      to: superRed,
    },
  )}
`;

export const link3 = css`
  ${linkStyles}
  ${hoverTransition(
    {
      property: 'color',
      to: white,
    },
    {
      property: 'fill',
      to: white,
    },
  )}
`;
