import type { match } from 'react-router';
import { matchPath } from 'react-router';
import { MEMBERS_OVERVIEW_SUBPATH, OVERVIEW_SUBPATH } from '@peloton/links/members';
import { ME } from '@engage/members/models';
import { toProfileUrlTemplate } from './profile';

export type MatchPath = match<{ userId: string }>;

export const OVERVIEW_ROUTE = toProfileUrlTemplate(OVERVIEW_SUBPATH);

export const MEMBERS_OVERVIEW_ROUTE = toProfileUrlTemplate(MEMBERS_OVERVIEW_SUBPATH);

export const matchOverviewPath = (path: string): null | MatchPath =>
  matchPath(path, {
    path: MEMBERS_OVERVIEW_ROUTE,
  });

export const getViewedMemberUsernameOrIdFromPath = (path: string) => {
  const pathWithId = matchOverviewPath(path);
  return pathWithId ? decodeURIComponent(pathWithId.params.userId) : ME;
};
