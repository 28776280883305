import React from 'react';
import styled from 'styled-components';

type RequiredOrOptional<R, P> = R extends true ? P : Partial<P>;

/**
 * This helper generalizes the pattern of creating styled components with custom props
 * that should not be passed into the styled component.
 *
 * See https://styled-components.com/docs/api#typescript
 *
 * Usage: `makeStyledWithCustomProps<{ custom: Props }>()(ParentComponent)`
 */
export const makeStyledWithCustomProps = function <CustomProps, Required = true>() {
  function WrappedComponent<P extends object>(InferredComponent: React.ComponentType<P>) {
    return styled<
      React.FC<P & RequiredOrOptional<Required, { customProps: CustomProps }>>
    >(({ customProps, ...props }) => {
      type WrappedProps = JSX.LibraryManagedAttributes<React.ComponentType<P>, P>;
      return <InferredComponent {...((props as unknown) as WrappedProps)} />;
    });
  }

  return WrappedComponent;
};
