import type { TypedAction } from '@peloton/redux';

export enum WorkoutHistoryPageActionTypes {
  RequestMoreWorkouts = 'members/workoutHistory/workouts/REQUEST_NEXT_PAGE',
  RequestNewWorkouts = 'members/workoutHistory/INITIALIZE',
}

type Dates = {
  fromDate: string;
  toDate: string;
};

export const requestNewWorkouts = (
  { fromDate, toDate }: Dates,
  sharedLeaderboardTag: string | null,
) => ({
  type: WorkoutHistoryPageActionTypes.RequestNewWorkouts,
  payload: { sharedLeaderboardTag, fromDate, toDate },
});

export const requestMoreWorkouts = ({ fromDate, toDate }: Dates) => ({
  type: WorkoutHistoryPageActionTypes.RequestMoreWorkouts,
  payload: { fromDate, toDate },
});

export type WorkoutHistoryAction =
  | TypedAction<
      ReturnType<typeof requestNewWorkouts>,
      WorkoutHistoryPageActionTypes.RequestNewWorkouts
    >
  | TypedAction<
      ReturnType<typeof requestMoreWorkouts>,
      WorkoutHistoryPageActionTypes.RequestMoreWorkouts
    >;
