import type {
  AddClassToScheduleMutation,
  AddClassToScheduleMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/AddClassToSchedule.generated';
import { AddClassToScheduleDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/AddClassToSchedule.generated';
import type {
  AddClassToScheduleWithoutUserMutation,
  AddClassToScheduleWithoutUserMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/AddClassToScheduleWithoutUsers.generated';
import { AddClassToScheduleWithoutUserDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/AddClassToScheduleWithoutUsers.generated';
import type {
  AddLiveClassToScheduleMutation,
  AddLiveClassToScheduleMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/AddLiveClassToSchedule.generated';
import { AddLiveClassToScheduleDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/AddLiveClassToSchedule.generated';
import type {
  CancelInviteMutation,
  CancelInviteMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/CancelInvite.generated';
import { CancelInviteDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/CancelInvite.generated';
import type {
  CreateInviteShareLinkMutation,
  CreateInviteShareLinkMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/CreateInviteSharelink.generated';
import { CreateInviteShareLinkDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/CreateInviteSharelink.generated';
import type {
  RemoveClassFromScheduleMutation,
  RemoveClassFromScheduleMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/RemoveClassFromSchedule.generated';
import { RemoveClassFromScheduleDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/RemoveClassFromSchedule.generated';
import type {
  RescheduleClassMutation,
  RescheduleClassMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/RescheduleClass.generated';
import { RescheduleClassDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/RescheduleClass.generated';
import type {
  RespondToInviteMutation,
  RespondToInviteMutationVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/RespondToInvite.generated';
import { RespondToInviteDocument } from '@members/graphql-swr/schemas/scheduled-classes/mutations/RespondToInvite.generated';
import type {
  InviteDetailsQuery,
  InviteDetailsQueryVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/queries/InviteDetails.generated';
import { InviteDetailsDocument } from '@members/graphql-swr/schemas/scheduled-classes/queries/InviteDetails.generated';
import type {
  PendingInviteCountQuery,
  PendingInviteCountQueryVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/queries/PendingInviteCount.generated';
import { PendingInviteCountDocument } from '@members/graphql-swr/schemas/scheduled-classes/queries/PendingInviteCount.generated';
import type {
  ScheduledClassQuery,
  ScheduledClassQueryVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/queries/ScheduledClass.generated';
import { ScheduledClassDocument } from '@members/graphql-swr/schemas/scheduled-classes/queries/ScheduledClass.generated';
import type {
  UserScheduledClassesQuery,
  UserScheduledClassesQueryVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/queries/UserScheduledClasses.generated';
import { UserScheduledClassesDocument } from '@members/graphql-swr/schemas/scheduled-classes/queries/UserScheduledClasses.generated';
import type {
  YourScheduleItemsQuery,
  YourScheduleItemsQueryVariables,
} from '@members/graphql-swr/schemas/scheduled-classes/queries/YourScheduleItems.generated';
import { YourScheduleItemsDocument } from '@members/graphql-swr/schemas/scheduled-classes/queries/YourScheduleItems.generated';
import { graphQlApi } from '../singletons';
import { unwrap, getDocumentKey, graphQLClient, toFetcherV2, toMutate } from './utils';
import type { Headers } from './utils';

export const toScheduledClassFetchersGQL = (api: typeof graphQlApi = graphQlApi) => ({
  YourScheduleItemsQuery: toFetcherV2(
    (queryVariables: YourScheduleItemsQueryVariables) =>
      getDocumentKey(YourScheduleItemsDocument, queryVariables),
    (queryVariables: YourScheduleItemsQueryVariables) =>
      unwrap(() =>
        graphQLClient<YourScheduleItemsQuery, YourScheduleItemsQueryVariables>(
          graphQlApi,
          YourScheduleItemsDocument,
          queryVariables,
        ),
      ),
  ),
  UserScheduledClassesQuery: toFetcherV2(
    (queryVariables: UserScheduledClassesQueryVariables) =>
      getDocumentKey(UserScheduledClassesDocument, queryVariables),
    (queryVariables: UserScheduledClassesQueryVariables) =>
      unwrap(() =>
        graphQLClient<UserScheduledClassesQuery, UserScheduledClassesQueryVariables>(
          graphQlApi,
          UserScheduledClassesDocument,
          queryVariables,
        ),
      ),
  ),
  ScheduledClassQuery: toFetcherV2(
    (queryVariables: ScheduledClassQueryVariables) =>
      getDocumentKey(ScheduledClassDocument, queryVariables),
    (queryVariables: ScheduledClassQueryVariables) =>
      unwrap(() =>
        graphQLClient<ScheduledClassQuery, ScheduledClassQueryVariables>(
          graphQlApi,
          ScheduledClassDocument,
          queryVariables,
        ),
      ),
  ),
  PendingInviteCountQuery: toFetcherV2(
    (queryVariables: PendingInviteCountQueryVariables) =>
      getDocumentKey(PendingInviteCountDocument, queryVariables),
    (queryVariables: PendingInviteCountQueryVariables) =>
      unwrap(() =>
        graphQLClient<PendingInviteCountQuery, PendingInviteCountQueryVariables>(
          graphQlApi,
          PendingInviteCountDocument,
          queryVariables,
        ),
      ),
  ),
  InviteDetailsQuery: toFetcherV2(
    (queryVariables: InviteDetailsQueryVariables) =>
      queryVariables.inviteId !== ''
        ? getDocumentKey(InviteDetailsDocument, queryVariables)
        : null,
    (queryVariables: InviteDetailsQueryVariables) =>
      unwrap(() =>
        graphQLClient<InviteDetailsQuery, InviteDetailsQueryVariables>(
          graphQlApi,
          InviteDetailsDocument,
          queryVariables,
        ),
      ),
  ),
  RespondToInviteMutation: toMutate(
    (queryVariables: RespondToInviteMutationVariables) =>
      getDocumentKey(RespondToInviteDocument, queryVariables),
    (queryVariables: RespondToInviteMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<RespondToInviteMutation, RespondToInviteMutationVariables>(
          graphQlApi,
          RespondToInviteDocument,
          queryVariables,
          optional,
        ),
      ),
  ),
  RescheduleClassMutation: toMutate(
    (queryVariables: RescheduleClassMutationVariables) =>
      getDocumentKey(RescheduleClassDocument, queryVariables),
    (queryVariables: RescheduleClassMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<RescheduleClassMutation, RescheduleClassMutationVariables>(
          graphQlApi,
          RescheduleClassDocument,
          queryVariables,
          optional,
        ),
      ),
  ),
  RemoveClassFromScheduleMutation: toMutate(
    (queryVariables: RemoveClassFromScheduleMutationVariables) =>
      queryVariables.id !== ''
        ? getDocumentKey(RemoveClassFromScheduleDocument, queryVariables)
        : null,
    (queryVariables: RemoveClassFromScheduleMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<
          RemoveClassFromScheduleMutation,
          RemoveClassFromScheduleMutationVariables
        >(graphQlApi, RemoveClassFromScheduleDocument, queryVariables, optional),
      ),
  ),
  CreateInviteShareLinkMutation: toMutate(
    (queryVariables: CreateInviteShareLinkMutationVariables) =>
      getDocumentKey(CreateInviteShareLinkDocument, queryVariables),
    (queryVariables: CreateInviteShareLinkMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<
          CreateInviteShareLinkMutation,
          CreateInviteShareLinkMutationVariables
        >(graphQlApi, CreateInviteShareLinkDocument, queryVariables, optional),
      ),
  ),
  CancelInviteMutation: toMutate(
    (queryVariables: CancelInviteMutationVariables) =>
      getDocumentKey(CancelInviteDocument, queryVariables),
    (queryVariables: CancelInviteMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<CancelInviteMutation, CancelInviteMutationVariables>(
          graphQlApi,
          CancelInviteDocument,
          queryVariables,
          optional,
        ),
      ),
  ),
  AddLiveClassToScheduleMutation: toMutate(
    (queryVariables: AddLiveClassToScheduleMutationVariables) =>
      getDocumentKey(AddLiveClassToScheduleDocument, queryVariables),
    (queryVariables: AddLiveClassToScheduleMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<
          AddLiveClassToScheduleMutation,
          AddLiveClassToScheduleMutationVariables
        >(graphQlApi, AddLiveClassToScheduleDocument, queryVariables, optional),
      ),
  ),
  AddClassToScheduleWithoutUserMutation: toMutate(
    (queryVariables: AddClassToScheduleWithoutUserMutationVariables) =>
      getDocumentKey(AddClassToScheduleWithoutUserDocument, queryVariables),
    (queryVariables: AddClassToScheduleWithoutUserMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<
          AddClassToScheduleWithoutUserMutation,
          AddClassToScheduleWithoutUserMutationVariables
        >(graphQlApi, AddClassToScheduleWithoutUserDocument, queryVariables, optional),
      ),
  ),
  AddClassToScheduleMutation: toMutate(
    (queryVariables: AddClassToScheduleMutationVariables) =>
      queryVariables.id !== ''
        ? getDocumentKey(AddClassToScheduleDocument, queryVariables)
        : null,
    (queryVariables: AddClassToScheduleMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<AddClassToScheduleMutation, AddClassToScheduleMutationVariables>(
          graphQlApi,
          AddClassToScheduleDocument,
          queryVariables,
          optional,
        ),
      ),
  ),
});
