import React from 'react';
import { Redirect } from 'react-router-dom';
import { HOMESCREEN_ROUTE } from '@members/pg-homescreen/Routes';

const BASE_URL = '/';
const BASE_REDIRECT_URL = HOMESCREEN_ROUTE;

const RoutesWithHomescreen = [
  <Redirect exact to={BASE_REDIRECT_URL} key={BASE_URL} from={BASE_URL} />,
];

export default RoutesWithHomescreen;
