import { path } from 'ramda';
import type { Reducer } from 'redux';
import { isDefined } from '@peloton/types';
import type {
  RemoveFollowerSuccessAction,
  ApproveFollowerSuccessAction,
} from '@engage/me';
import { RelationshipsActionType } from '@engage/me';
import type { Member } from '@engage/members/models/Member';
import { RelationshipChange, shouldShowPrivateInfo } from '@engage/members/models/Member';
import type {
  ChangeRelationshipSuccessAction,
  MemberSuccessAction,
} from '@engage/members/redux';
import { MembersActionType } from '@engage/members/redux';
import type { FriendCounts } from '@engage/overview';

export const friendCountsReducer: Reducer<State> = (
  state: State = defaultState,
  action: Action,
) => {
  switch (action.type) {
    case MembersActionType.MemberSuccess:
      return { ...state, ...addFriendCounts(action.payload) };

    case MembersActionType.ToggleFollowSuccess:
      return {
        ...state,
        ...updateFollowing(
          state,
          action.payload.selfId,
          action.payload.relationshipChange,
        ),
        ...updateFollowers(
          state,
          action.payload.followedId,
          action.payload.relationshipChange,
        ),
      };

    case RelationshipsActionType.RemoveSuccess:
      return {
        ...state,
        ...updateFollowing(state, action.payload.followerId, RelationshipChange.Unfollow),
        ...updateFollowers(state, action.payload.userId, RelationshipChange.Remove),
      };

    case RelationshipsActionType.ApproveSuccess:
      return {
        ...state,
        ...updateFollowing(state, action.payload.followerId, RelationshipChange.Approve),
        ...updateFollowers(state, action.payload.userId, RelationshipChange.Approve),
      };

    default:
      return state;
  }
};

const addFriendCounts = (member: Member) =>
  shouldShowPrivateInfo(member)
    ? {
        [member.id]: {
          totalFollowers: member.totalFollowers,
          totalFollowing: member.totalFollowing,
        },
      }
    : {};

const updateFollowing = (
  state: State,
  id: string,
  relationshipChange: RelationshipChange,
) =>
  isDefined(state[id])
    ? {
        [id]: {
          ...state[id],
          totalFollowing:
            state[id].totalFollowing + toFriendCountChange(relationshipChange),
        },
      }
    : {};

const updateFollowers = (
  state: State,
  id: string,
  relationshipChange: RelationshipChange,
) =>
  isDefined(state[id])
    ? {
        [id]: {
          ...state[id],
          totalFollowers:
            state[id].totalFollowers + toFriendCountChange(relationshipChange),
        },
      }
    : {};

const toFriendCountChange = (relationshipChange: RelationshipChange) => {
  switch (relationshipChange) {
    case RelationshipChange.Follow:
    case RelationshipChange.Approve:
      return 1;
    case RelationshipChange.Unfollow:
    case RelationshipChange.Remove:
      return -1;
    default:
      return 0;
  }
};

export const getFriendCounts = (
  state: FriendCountsSelectorState,
  id: string,
): Maybe<FriendCounts> => path<FriendCounts>(['friendCounts', id], state);

const defaultState: State = {};

type State = Record<string, FriendCounts>;

type Action =
  | MemberSuccessAction
  | ChangeRelationshipSuccessAction
  | RemoveFollowerSuccessAction
  | ApproveFollowerSuccessAction;

export type FriendCountsSelectorState = { friendCounts: State };

type Maybe<T> = T | undefined;
