import useSWR from 'swr';
import { createDigitalGuestPass } from '../generated';
import type { BaseAPI, GuestPassAccessCode } from '../generated';

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
const GUEST_PASS_KEY = '/api/user/guestPass';

/**
 * createDigitalGuestPass wrapper with SWR applied. Fetches or restores a digital guest pass request from cache.
 * @param api BaseAPI instance
 * @param [isFreemiumMember] prevents the request if the user is a freemium member
 * @returns digital guest pass
 */
export const useGenerateGuestPass = (
  api: BaseAPI,
  isFreemiumMember?: boolean,
): GuestPassAccessCode | null => {
  const { data, error } = useSWR(
    isFreemiumMember ? null : GUEST_PASS_KEY,
    () => createDigitalGuestPass(api, 'web'),
    {
      dedupingInterval: ONE_DAY_IN_MS,
    },
  );

  if (error) {
    console.error(error);
  }

  return data?.data ?? null;
};
