import 'babel-polyfill';
import 'fullscreen-polyfill';
// must import GlobalStyles first to ensure correct css order
import { GlobalStyles } from '@members/global-styles'; // eslint-disable-line import/order
import React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { render } from 'react-snapshot';
import { SWRConfig } from 'swr';

import { load as loadAnalytics } from '@peloton/analytics';
import LogoutCookieProvider from '@peloton/auth/LogoutCookieProvider';

import { ErrorBoundary } from '@engage/error-reporting';
import { injectableFonts } from '@engage/typography';
import { makeAnalytics } from '@members/analytics';
import { ClientProvider } from '@members/api';
import OauthProvider from '@members/auth/OauthProvider';
import * as Env from '@members/env';
import { init } from '@members/errors/datadog';
import { getAjsUserId } from '@members/optimizely';
import { ToggleGate } from '@members/toggle-gate';
import App from './App';
import { env } from './env';
import { setupErrorHandler } from './errorHandlers';
import { ErrorPage } from './ErrorPage';
import { generateStore } from './store';
// Don't load Datadog during Cypress run
if (!Env.isCypressTest()) {
  init();
}
require('@members/staged-rollouts/newRelicConfig');
require('@members/bootstrapping/timeToInteractive');
require('./a11y');

// inject fonts globally to prevent reloading on styled-components recalculations
const injectedGlobalFonts = document.createElement('style');
injectedGlobalFonts.textContent = injectableFonts;
document.head.appendChild(injectedGlobalFonts);

const renderApp = (el: React.ReactElement<any>) =>
  render(el, document.getElementById('root'));

loadAnalytics({ key: env.segmentWriteKey }, makeAnalytics());

const errorHandler = setupErrorHandler(env);
const { store, client } = generateStore({ env, errorHandler });

renderApp(
  <ErrorBoundary
    renderError={() => <ErrorPage />}
    errorReporter={errorHandler.reportError}
  >
    <GlobalStyles />
    <Provider store={store} context={ReactReduxContext}>
      <OauthProvider apiEnv={env.extLink.api} reduxClient={client}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}
        >
          <ToggleGate userId={getAjsUserId()}>
            <ClientProvider client={client}>
              <LogoutCookieProvider>
                <App reduxStore={store} routerContext={ReactReduxContext} />
              </LogoutCookieProvider>
            </ClientProvider>
          </ToggleGate>
        </SWRConfig>
      </OauthProvider>
    </Provider>
  </ErrorBoundary>,
);
