import type { AxiosPromise } from 'axios';
// eslint-disable-next-line no-restricted-imports
import { toFetcher } from '@engage/data-fetch-v2';
// eslint-disable-next-line no-restricted-imports
import { unwrap } from '@engage/data-fetch-v2/lib/unwrap';
import { userPreferencesApi } from '../singletons';

type LocationPromptParams = { acceptLanguage: string; userId: string };

type PrivacyQuestionsRequiredParams = {
  acceptLanguage: string;
  userId: string;
};
type PrivacyQuestionsOptionalParams = {
  country?: string;
  subRegion?: string;
};

export type UserPreferencesQuestionsResponse = {
  questions: QuestionsData[];
  length: number;
};

export type QuestionsData = {
  title: string;
  subtitle: string;
  preferenceSlugs: string[];
  orderedOptions: OrderedOptions[];
};

export type OrderedOptions = {
  slug: string;
  displayText: string;
};

type PrivacyLawImpactResponse = {
  userId: string;
  isUnderage: boolean;
  showPrivacyLocationModal: boolean;
  showDismissButton: boolean;
  prefilledCountrySlug: string;
  prefilledSubRegionSlug: string;
  countries: CountryInfo[];
};

export type CountryInfo = {
  displayName: string;
  slug: string;
  impacted: boolean;
  subRegionType: {
    displayName: string;
    slug: string;
  };
  subRegions: SubRegionInfo[];
};

export type SubRegionInfo = {
  displayName: string;
  slug: string;
  impacted: boolean;
};

function getPrivacyQuestions(
  api: typeof userPreferencesApi,
  acceptLanguage: string,
  userId: string,
  country: string,
  subRegion: string,
): AxiosPromise<UserPreferencesQuestionsResponse> {
  return api.axios.get(
    `/user_preferences/privacy_law_impact/questions?user_id=${userId}&country=${country}&sub_region=${subRegion}`,
    {
      headers: { 'Accept-Language': acceptLanguage },
    },
  );
}

function getLocationPrompt(
  api: typeof userPreferencesApi,
  acceptLanguage: string,
  userId?: string,
): AxiosPromise<PrivacyLawImpactResponse> {
  return api.axios.get(`/user_preferences/privacy_law_impact/user/${userId}`, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

function getGenericLocationPrompt(
  api: typeof userPreferencesApi,
  acceptLanguage: string,
): AxiosPromise<PrivacyLawImpactResponse> {
  return api.axios.get(`/user_preferences/privacy_law_impact/countries`, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

export const toUserPreferencesFetchers = (
  api: typeof userPreferencesApi = userPreferencesApi,
) => ({
  getGenericLocationPrompt: toFetcher(
    () => 'GetGenericLocationPrompt',
    (params: { acceptLanguage: string }) =>
      unwrap(() => getGenericLocationPrompt(api, params.acceptLanguage)),
  ),
  GetLocationPrompt: toFetcher(
    () => 'GetLocationPrompt',
    (params: LocationPromptParams) =>
      unwrap(() => getLocationPrompt(api, params.acceptLanguage, params.userId)),
  ),
  GetPrivacyQuestions: toFetcher(
    () => 'GetPrivacyQuestions',
    (
      reqParams: PrivacyQuestionsRequiredParams,
      optParams: PrivacyQuestionsOptionalParams,
    ) =>
      unwrap(() =>
        getPrivacyQuestions(
          api,
          reqParams.acceptLanguage,
          reqParams.userId,
          optParams?.country ?? '',
          optParams?.subRegion ?? '',
        ),
      ),
  ),
});
