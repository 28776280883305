/* eslint-disable react/display-name */
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';
import { supportsPositionSticky } from '@peloton/browser';
import { isDefined } from '@peloton/types';
import type {
  MemberCard,
  RelationshipCategory,
  RelationshipChange,
} from '@engage/members';
import {
  fromRelationshipToRelationshipChange,
  toRelationshipChangeRequest,
} from '@engage/members';
import { spaces } from '@engage/styles';
import { MemberListCard } from '@members/members';
import { cardHeight } from '@members/members/shared';
import { useIsBreakpointLessThan } from '@members/responsive';
import { InviteOthersMemberCard } from '../InviteOthersMemberCard';
import { Loading } from '../Loading';
import type {
  TagDetailHeaderViewProps,
  TagDetailHeaderHeightProps,
} from './TagDetailHeaderView';
import {
  TagDetailHeaderView,
  STICKY_HEADER_MOBILE_HEIGHT,
  STICKY_HEADER_HEIGHT,
} from './TagDetailHeaderView';
import updateRelationshipRequest from './updateRelationshipRequest';

enum OtherCardType {
  Header,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Loading,
  InviteOthers,
}

export type TagDetailsMemberListProps = TagDetailHeaderViewProps & {
  hasNextPage: boolean;
  isLoadingMore: boolean;
  members: MemberCard[];
  loadMore(): void;
};

type ListItemProps = {
  index: number;
  style: React.CSSProperties;
  isSticky: boolean;
  data: TagDetailHeaderViewProps &
    TagDetailHeaderHeightProps & {
      isLoadingMore: boolean;
    };
};

type InitialLoadProps = {
  initialLoadComplete: boolean;
  setInitialLoadComplete: (i: boolean) => void;
};

type RelationshipMap = Record<number, RelationshipCategory>;

declare module 'react-window' {
  interface CommonProps {
    itemStyle?: (index: number, defaultStyle: Object, scrollOffset: number) => Object;
    firstItemSticky?: boolean;
    isFirstItemSticky?: (scrollOffset: number) => boolean;
  }
}

export const TagDetailsMemberList: React.FC<
  React.PropsWithChildren<
    TagDetailsMemberListProps & TagDetailHeaderHeightProps & InitialLoadProps
  >
> = ({ hasNextPage, isLoadingMore, members, loadMore, headerHeight, ...props }) => {
  const [relationshipMap, setRelationshipMap] = React.useState<RelationshipMap>({});
  const scrollRef = React.useRef(null);
  const listRef = React.useRef<VariableSizeList>(null);
  const [imageLoadedCount, setImageLoadedCount] = React.useState<number>(0);
  const isMobile = useIsBreakpointLessThan('tablet');

  const membersWithExtras = React.useMemo(
    () =>
      hasNextPage
        ? [OtherCardType.Header, ...members, OtherCardType.Loading]
        : [OtherCardType.Header, ...members, OtherCardType.InviteOthers],
    [hasNextPage, members],
  );

  const getItemSize = React.useCallback(
    (index: number) => (index === 0 ? headerHeight ?? 0 : cardHeight),
    [headerHeight],
  );

  React.useEffect(() => {
    if (isDefined(headerHeight) && isDefined(listRef.current)) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [headerHeight]);

  const incrementImageLoadedCount = React.useCallback(() => {
    if (imageLoadedCount === membersWithExtras.length - 2) {
      props.setInitialLoadComplete(true);
    }
    if (!props.initialLoadComplete && imageLoadedCount < membersWithExtras.length - 2) {
      setImageLoadedCount(imageLoadedCount + 1);
    }
  }, [imageLoadedCount]);

  const stickyHeaderHeight = React.useMemo(
    () => (isMobile ? STICKY_HEADER_MOBILE_HEIGHT : STICKY_HEADER_HEIGHT),
    [isMobile],
  );

  const getItemStyle = React.useCallback(
    (index: number, defaultStyle: object, scrollOffset: number) => {
      if (index === 0 && isDefined(headerHeight)) {
        const stickyHeaderOffset = headerHeight - stickyHeaderHeight;
        const stickyItemStyle = Object.assign({}, defaultStyle) as React.CSSProperties;
        stickyItemStyle.position = supportsPositionSticky() ? 'sticky' : '-webkit-sticky';
        stickyItemStyle.zIndex = 1;
        stickyItemStyle.top =
          scrollOffset < stickyHeaderOffset
            ? `-${scrollOffset}px`
            : `-${stickyHeaderOffset}px`;
        return stickyItemStyle;
      } else {
        return defaultStyle;
      }
    },
    [headerHeight, stickyHeaderHeight],
  );

  const getIsFirstItemSticky = React.useCallback(
    (scrollOffset: number) => {
      if (isDefined(headerHeight)) {
        return scrollOffset > headerHeight - stickyHeaderHeight;
      }
      return false;
    },
    [headerHeight, stickyHeaderHeight],
  );

  return (
    <ListContainer data-test-id="membersList">
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            height={height}
            width={width}
            itemCount={membersWithExtras.length}
            itemSize={getItemSize}
            innerRef={scrollRef}
            ref={listRef}
            itemData={{
              isLoadingMore,
              ...props,
            }}
            onItemsRendered={({ visibleStopIndex }) => {
              if (visibleStopIndex === membersWithExtras.length - 1) {
                loadMore();
              }
            }}
            firstItemSticky
            itemStyle={getItemStyle}
            isFirstItemSticky={getIsFirstItemSticky}
          >
            {toListItem(
              membersWithExtras,
              incrementImageLoadedCount,
              relationshipMap,
              setRelationshipMap,
              props.onMemberFollow,
            )}
          </VariableSizeList>
        )}
      </AutoSizer>
    </ListContainer>
  );
};

const isOtherCardType = (n: any): n is OtherCardType => typeof n === 'number';

const toListItem = (
  members: (MemberCard | OtherCardType)[],
  incrementImageLoadedCount: () => void,
  relationshipMap: RelationshipMap,
  setRelationshipMap: (map: RelationshipMap) => void,
  onMemberFollow: (change: RelationshipChange) => void,
) => ({ index, style, data, isSticky }: ListItemProps) => {
  const cardProps = members[index];

  return (
    <div style={style}>
      {toCard({
        cardProps,
        data,
        incrementImageLoadedCount,
        index,
        relationshipMap,
        setRelationshipMap,
        isSticky,
        onMemberFollow,
      })}
    </div>
  );
};

const toCard = ({
  cardProps,
  data: { isLoadingMore, ...headerProps },
  incrementImageLoadedCount,
  index,
  relationshipMap,
  setRelationshipMap,
  isSticky,
  onMemberFollow,
}: {
  cardProps: MemberCard | OtherCardType;
  data: ListItemProps['data'];
  incrementImageLoadedCount: () => void;
  index: number;
  relationshipMap: RelationshipMap;
  setRelationshipMap: (map: RelationshipMap) => void;
  isSticky: boolean;
  onMemberFollow: (change: RelationshipChange) => void;
}) => {
  if (isOtherCardType(cardProps)) {
    switch (cardProps) {
      case OtherCardType.Header:
        return <TagDetailHeaderView {...headerProps} isSticky={isSticky} />;
      case OtherCardType.Loading:
        return <LoadingMore isLoading={isLoadingMore} size={40} />;
      case OtherCardType.InviteOthers:
        return <InviteOthersMemberCard />;
      default:
        return null;
    }
  } else {
    return (
      <TagDetailsMemberListCard
        card={cardProps}
        incrementImageLoadedCount={incrementImageLoadedCount}
        index={index}
        relationshipMap={relationshipMap}
        setRelationshipMap={setRelationshipMap}
        onMemberFollow={headerProps.onMemberFollow}
      />
    );
  }
};

const TagDetailsMemberListCard: React.FC<
  React.PropsWithChildren<{
    card: MemberCard;
    incrementImageLoadedCount: () => void;
    index: number;
    relationshipMap: RelationshipMap;
    setRelationshipMap: (map: RelationshipMap) => void;
    onMemberFollow: (change: RelationshipChange) => void;
  }>
> = ({
  card,
  incrementImageLoadedCount,
  index,
  relationshipMap,
  setRelationshipMap,
  onMemberFollow,
}) => {
  const { isProfilePrivate, meToUserRelationship, id } = card;
  const localMeToUserRelationship = relationshipMap[index] ?? meToUserRelationship;
  const { relationshipChange } = fromRelationshipToRelationshipChange(
    isProfilePrivate ?? false,
    {
      meToUser: localMeToUserRelationship,
    },
  );

  const updateRelationship = () => {
    return updateRelationshipRequest({
      userId: id,
      action: toRelationshipChangeRequest(relationshipChange),
    });
  };

  const onFollowClick: MemberCard['onFollowClick'] = React.useCallback(e => {
    e.preventDefault();
    updateRelationship().then(data => {
      if (data?.__typename === 'UpdateRelationshipSuccess') {
        setRelationshipMap({
          ...relationshipMap,
          [index]: data?.meToUser as RelationshipCategory,
        });
        onMemberFollow(relationshipChange);
      }
    });
  }, []);

  return (
    <MemberListCard
      card={{
        ...card,
        meToUserRelationship: localMeToUserRelationship,
        onFollowClick,
      }}
      incrementImageLoadedCount={incrementImageLoadedCount}
      isVirtualizedList={true}
    />
  );
};

const LoadingMore = styled(Loading)`
  margin: ${spaces.large}px auto;
`;

const ListContainer = styled.div`
  height: 100%;
  flex: 1;
  position: relative;
`;
