import type { MemberCard, CardInfo } from '@engage/members';
import { toCardInfo } from '@engage/members';
import type { FacebookFriend } from './FacebookFriends';

export type FacebookFriendCards = {
  total: number;
  friends: MemberCard[];
};

export const toFacebookCardInfo = (friends: FacebookFriend[]): CardInfo[] =>
  friends.map((friend: FacebookFriend) => ({
    ...toCardInfo(friend),
    label: friend.facebookName,
  }));
