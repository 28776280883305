import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1 } from '@engage/colors';
import { spaces } from '@engage/styles/spaces';
import { label16 } from '@engage/typography';
import { Button1Large } from '@members/buttons';
import { useTiering, Tiering } from '@members/copy';
import type { Modal, TIERING_MODAL_TYPE } from '../models';
import { getModalName } from '../utils';
import TieringModalView from './TieringModalView';

const ChallengesGatingModal: React.FC<
  React.PropsWithChildren<{
    modal: Modal;
    setModal: React.Dispatch<React.SetStateAction<Modal>>;
  }>
> = ({ modal, setModal }) => {
  const modalHeaderCopy = useTiering(Tiering.NotAbleToTakeEveryClassChallenge);
  const modalDescriptionCopy = useTiering(
    Tiering.ChallengeIncludesCardioEquipmentClasses,
  );
  const buttonCopy = useTiering(Tiering.JoinChallenge);
  const upgradeCTACopy = useTiering(Tiering.UpgradeToAppPlus);

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const openUpgradeModal = () => {
    setModal({
      ...modal,
      modalType: 'upgrade_modal',
      isOpen: true,
      source: getModalName('challenges_gating_modal' as TIERING_MODAL_TYPE),
    });
  };

  const buttonHandler = () => {
    if (!!modal.suggestedClickHandler) {
      closeModal();
      modal.suggestedClickHandler();
    }
    closeModal;
  };

  return (
    <TieringModalView
      modal={modal}
      setModal={setModal}
      modalHeaderCopy={modalHeaderCopy}
      modalDescriptionCopy={modalDescriptionCopy}
      ctaChildren={
        <>
          <CTAButton onClick={buttonHandler}>{buttonCopy}</CTAButton>
          <CTALinkContainer>
            <UpgradeCTA onClick={openUpgradeModal}>{upgradeCTACopy}</UpgradeCTA>
          </CTALinkContainer>
        </>
      }
    />
  );
};

const CTAButton = styled(Button1Large)`
  margin: ${spaces.xLarge}px 0;
  background-color: ${slate1};
  width: 280px;
  ${media.tablet`
  width: 320px;`}
`;

const CTALinkContainer = styled.div`
  display: flex;
`;

const UpgradeCTA = styled.button`
  ${label16}
  color: ${slate1};
  text-align: center;
  width: 280px;
  ${media.tablet`
  width: auto;
  `}
`;

export default ChallengesGatingModal;
