// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Social = {
  AcceptInvite: 'acceptInvite',
  AddToSchedule: 'addToSchedule',
  AltTextCalendar: 'altTextCalendar',
  AriaSchedule: 'ariaSchedule',
  BackAltText: 'backAltText',
  BrowseClasses: 'browseClasses',
  Cancel: 'cancel',
  ChooseDateAndTime: 'chooseDateAndTime',
  ClassTypeHasEnded: 'classTypeHasEnded',
  ConnectToSocial: 'connectToSocial',
  Days: 'days',
  EditMenuAltText: 'editMenuAltText',
  EncoreClass: 'encoreClass',
  From: 'from',
  GotIt: 'gotIt',
  HostCancelWithOccasion: 'hostCancelWithOccasion',
  HostInviteTitle: 'hostInviteTitle',
  HostPublicShareNoOccasion: 'hostPublicShareNoOccasion',
  HostPublicShareOccasion: 'hostPublicShareOccasion',
  HostToInviteeTitle: 'hostToInviteeTitle',
  HostToInviteTitle: 'hostToInviteTitle',
  Hours: 'hours',
  Invite: 'invite',
  InvitedUserSizeLimitExceededError: 'invitedUserSizeLimitExceededError',
  InviteeToInviteeTitle: 'inviteeToInviteeTitle',
  JoinClass: 'joinClass',
  JoinScheduledClass: 'joinScheduledClass',
  LimitedTimeBeta: 'limitedTimeBeta',
  LimitedTimeBetaAndroidAndiOS: 'limitedTimeBetaAndroidAndiOS',
  Live: 'live',
  LiveAndEncore: 'liveAndEncore',
  LiveClass: 'liveClass',
  MembersInvited: 'membersInvited',
  MinutesElapsed: 'minutesElapsed',
  NoClasses: 'noClasses',
  NoClassesHelpText: 'noClassesHelpText',
  NoClassesScheduled: 'noClassesScheduled',
  Remove: 'remove',
  RemoveClassFromSchedule: 'removeClassFromSchedule',
  RemoveThisClass: 'removeThisClass',
  RemoveThisInviteClass: 'removeThisInviteClass',
  Reschedule: 'reschedule',
  Schedule: 'schedule',
  ScheduledClass: 'scheduledClass',
  ScheduledForAgo: 'scheduledForAgo',
  ScheduledToStartIn: 'scheduledToStartIn',
  ScreenReaderHeader: 'screenReaderHeader',
  ShareLinkHostDesc: 'shareLinkHostDesc',
  ShareLinkHostTitle: 'shareLinkHostTitle',
  ShareNoOccasion: 'shareNoOccasion',
  ShareWithOccasion: 'shareWithOccasion',
  SomethingWentWrong: 'somethingWentWrong',
  Start: 'start',
  StartingIn: 'startingIn',
  StartsIn: 'startsIn',
  StartNow: 'startNow',
  StayTuned: 'stayTuned',
  Today: 'today',
  TotalNumberMembersCountedIn: 'totalNumberMembersCountedIn',
  TryAgain: 'tryAgain',
  UsernameInvitedYou: 'usernameInvitedYou',
  ViewClass: 'viewClass',
  ViewLiveAndEncore: 'viewLiveAndEncore',
  ViewYourSchedule: 'viewYourSchedule',
  WithinTwoWeeks: 'withinTwoWeeks',
  YouAndTotalNumberMembersCountedIn: 'youAndTotalNumberMembersCountedIn',
  YouAreCountedIn: 'youAreCountedIn',
  YoureIn: 'youreIn',
  YourSchedule: 'yourSchedule',
  Premiere: 'premiere',
  PremiereMessageTitle: 'premiereMessageTitle',
  PremiereMessageSubtitle: 'premiereMessageSubtitle',
} as const;
