import { mergeWithKey, reduce } from 'ramda';
import { syncWithQueryParam, StorageKeys } from '@members/env';
import type { mergeVariables } from './state';

type State = Parameters<typeof mergeVariables>[0];
type Result = Parameters<typeof mergeVariables>[1];

export const mergeVariablesWithEnv = (
  state: State,
  result: Result,
  localStorageVariablesString = syncWithQueryParam(StorageKeys.FeatureVariables),
): ReturnType<typeof mergeVariables> => {
  let localVariableToggles: Record<string, string | undefined> = {};
  if (localStorageVariablesString) {
    localVariableToggles = JSON.parse(localStorageVariablesString);
  }

  return mergeWithKey(
    (sameKey, _fromState: State[string], fromOptimizely: Result[string]): State[string] =>
      reduce(
        (acc, { key, value }) => {
          const fullKey = `${sameKey}.${key}`;
          const overrideValue = localVariableToggles[fullKey] ?? value;
          acc[key] = {
            defaultValue: _fromState[key].defaultValue,
            originalValue: value,
            value: overrideValue,
          };
          return acc;
        },
        {} as State[string],
        fromOptimizely,
      ),
    state,
    result,
  );
};
