const SEARCH_FOR_MEMBER_EVENT_NAME = 'Searched For Member';
export const MEMBER_SEARCH_NAME = 'Member Search';

export const trackMemberSearch = (searchQuery: string, numberOfResults: number) => ({
  event: SEARCH_FOR_MEMBER_EVENT_NAME,
  searchQuery,
  '[Number of Results]': numberOfResults,
});

export const trackOpenMemberSearch = (source: string) => ({
  event: `Viewed ${MEMBER_SEARCH_NAME}`,
  source,
});
