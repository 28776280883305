// eslint-disable-next-line no-restricted-imports
import { prop, map, filter, pipe } from 'ramda';
import type { Locale } from '@peloton/internationalize';
import type { Omit } from '@peloton/types';
import type { ClassLanguage, Caption } from './models';

export type ApiClassLanguagePUT = Omit<ClassLanguage, 'captions'> & {
  captions: Locale[];
};

const enabledCaptions = pipe(
  filter<Caption, 'array'>(prop('enabled')),
  map(prop('locale')),
);

export const toApiClassLanguagePUT = ({
  captions,
  ...language
}: ClassLanguage): ApiClassLanguagePUT => ({
  ...language,
  captions: enabledCaptions(captions),
});
