import { transparentize } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { BreakpointEither } from '@peloton/responsive';
import { ellipsis, media } from '@peloton/styles';
import { black, gray4, white } from '@engage/colors';
import { RelationshipCategory, isSelfRelationship } from '@engage/members';
import { spaces } from '@engage/styles';
import { label4Large } from '@engage/typography';
import {
  Achievements,
  AchievementsCopy,
  Members,
  MembersCopy,
  Workouts,
  WorkoutsCopy,
  useMembers,
  SimpleGoals,
  SimpleGoalsCopy,
} from '@members/copy';
import { SimpleAvatar } from '@members/images';
import { layoutTextStyles } from '../sharedLinkStyles';
import Navbar, { MainNavLinks, MainLink } from './Navbar';
import type UserNavProps from './UserNavProps';

type UserLinkProps = {
  username: string;
  imageUrl: string;
  overviewPath: string;
};

const USERNAME_SOURCE = 'User Nav Username';
const WORKOUTS_SOURCE = 'User Nav Workouts';
const OVERVIEW_SOURCE = 'User Nav Overview';
const GOALS_SOURCE = 'User Nav Goals';
const ACHIEVEMENTS_SOURCE = 'User Nav Achievements';
const DEFAULT_NAV_HEIGHT_PX = 60;

const LOWER_NAV_MOBILE_HEIGHT_PX = 38;
const UPPER_NAV_MOBILE_HEIGHT_PX = 44;
export const USER_NAV_STICKY_HEIGHT_MOBILE_PX =
  LOWER_NAV_MOBILE_HEIGHT_PX + DEFAULT_NAV_HEIGHT_PX;
export const USER_NAV_HEIGHT_PX = DEFAULT_NAV_HEIGHT_PX + LOWER_NAV_MOBILE_HEIGHT_PX;

const UserNavView: React.FC<React.PropsWithChildren<UserNavProps>> = props => (
  <BreakpointEither breakpoint="tablet">
    <UserNavMobile {...props} />
    <UserNavStandard {...props} />
  </BreakpointEither>
);

export const userNavElementId = 'browse-usernav'; //

const UserNavStandard: React.FC<React.PropsWithChildren<UserNavProps>> = props => (
  <Navbar id={userNavElementId} height={UPPER_NAV_MOBILE_HEIGHT_PX}>
    <MaybeUserNavUserLink {...props} />
    {props.overviewPath &&
    props.workoutsPath &&
    props.achievementsPath &&
    props.goalsPath ? (
      <MainLinks {...props} />
    ) : null}
  </Navbar>
);

const UserNavMobile: React.FC<React.PropsWithChildren<UserNavProps>> = props => (
  <ContainerMobile>
    <MaybeMobileUpperNav {...props}>
      <MaybeUserNavUserLink {...props} />
    </MaybeMobileUpperNav>
    <Navbar id={userNavElementId} height={LOWER_NAV_MOBILE_HEIGHT_PX}>
      {props.overviewPath &&
      props.workoutsPath &&
      props.achievementsPath &&
      props.goalsPath ? (
        <MainLinks {...props} />
      ) : null}
    </Navbar>
  </ContainerMobile>
);

const MainLinks: React.FC<React.PropsWithChildren<UserNavProps>> = props => {
  return (
    <MainNavLinks>
      <MainLink
        data-test-id="overviewProfileLink"
        to={{ pathname: props.overviewPath, state: { source: OVERVIEW_SOURCE } }}
        exact
      >
        <MembersCopy id={Members.OverviewTitle} />
      </MainLink>
      <MainLink
        data-test-id="userWorkoutsLink"
        to={{ pathname: props.workoutsPath, state: { source: WORKOUTS_SOURCE } }}
        isActive={props.isWorkoutsLinkActive}
      >
        <WorkoutsCopy id={Workouts.Title} />
      </MainLink>
      {props.simpleGoalsEnabled &&
        props.meToUserRelationship === RelationshipCategory.Self && (
          <MainLink
            data-test-id="userGoalsLink"
            to={{ pathname: props.goalsPath, state: { source: GOALS_SOURCE } }}
          >
            <SimpleGoalsCopy id={SimpleGoals.Goals} />
          </MainLink>
        )}
      <MainLink
        data-test-id="userAchievementsLink"
        to={{ pathname: props.achievementsPath, state: { source: ACHIEVEMENTS_SOURCE } }}
      >
        <AchievementsCopy id={Achievements.Title} />
      </MainLink>
    </MainNavLinks>
  );
};

export const UserNavUserLink: React.FC<React.PropsWithChildren<UserLinkProps>> = ({
  imageUrl,
  overviewPath,
  username: name,
}) => (
  <UserLinkContainer>
    <UserLink
      to={{ pathname: overviewPath, state: { source: USERNAME_SOURCE } }}
      id="user-link"
      data-test-id="user-link"
    >
      <UserAvatar
        url={imageUrl}
        size={24}
        alt={useMembers(Members.AvatarAltText, { name })}
      />
      <Username data-test-id="activeUserName">{name}</Username>
    </UserLink>
  </UserLinkContainer>
);

export const shouldShowUserNavUserLink = ({
  username,
  imageUrl,
  meToUserRelationship,
  currentPath,
  overviewPath,
}: UserNavProps) =>
  Boolean(username) &&
  Boolean(imageUrl) &&
  !isSelfRelationship(meToUserRelationship) &&
  currentPath !== overviewPath;

const MaybeUserNavUserLink: React.FC<React.PropsWithChildren<UserNavProps>> = props =>
  shouldShowUserNavUserLink(props) ? (
    <UserNavUserLink
      username={props.username}
      overviewPath={props.overviewPath}
      imageUrl={props.imageUrl}
    />
  ) : null;

const UserLink = styled(Link)`
  display: flex;
  align-items: center;
  ${media.tablet`
    min-width: 0;
  `}
`;

const UserAvatar = styled(SimpleAvatar)`
  height: 24px;
  width: 24px;
  border: 1px solid ${transparentize(0.7, gray4)};
`;

const Username = styled.span`
  ${layoutTextStyles}
  ${label4Large}
  margin-left: 5px;
  margin-bottom: ${spaces.xxxxSmall}px;
  ${ellipsis}
`;

const containerStyles = css`
  background-color: ${white};
  box-shadow: 0 3px 5px 0 ${transparentize(0.9, black)};
`;

const UserLinkContainer = styled.div`
  ${media.tablet`
    position: absolute;
  `}
`;

// Mobile Size

const ContainerMobile = styled.nav`
  ${containerStyles}
`;

const MobileUpperNav = styled.div`
  width: 100%;
  height: ${DEFAULT_NAV_HEIGHT_PX}px;
  border-bottom: 1px solid ${transparentize(0.7, gray4)};
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

const MaybeMobileUpperNav: React.FC<React.PropsWithChildren<UserNavProps>> = ({
  children,
  ...props
}) =>
  shouldShowUserNavUserLink(props) ? <MobileUpperNav>{children}</MobileUpperNav> : null;

export default UserNavView;
