import { checkSession, getSession, reactivateAccountResetHashId } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

export const toAuthFetchers = (api: typeof engageApi = engageApi) => ({
  CheckSession: toFetcher(
    () => `CheckSession`,
    () => unwrap(() => checkSession(api)),
  ),
  GetIpAddress: toFetcher(
    () => `GetIpAddress`,
    () => unwrap(() => getSession(api)),
  ),
  GetSession: toFetcher(
    () => `GetSession`,
    () => unwrap(() => getSession(api)),
  ),
  ReactivateAccount: toFetcher(
    (params: { resetHashId: string }) => `ReactivateAccount${params.resetHashId}`,
    params => unwrap(() => reactivateAccountResetHashId(api, params.resetHashId)),
  ),
});
