import type { Location } from 'history';
import type { TrackTraits } from '@peloton/analytics';
import * as Analytics from '@peloton/analytics/analytics';

const DEFAULT_SOURCE = 'Direct Link';

export const identify = (
  { id, linkedSocialAccount, wodExperimentVariant }: IdentityTraits,
  analytics = Analytics,
) => {
  analytics.identify({
    userId: id,
    properties: {
      linkedSocialAccount,
      wodExperimentVariant,
    },
  });
};

export const track = <T extends object>(event: TrackTraits<T>, analytics = Analytics) => {
  analytics.track(event);
};

export const toSource = (location: Location) => location?.state?.source ?? DEFAULT_SOURCE;

export const toFeatureModule = (location: Location) =>
  location?.state?.['Featured Module'] ?? DEFAULT_SOURCE;

export const toRank = (location: Location) => location?.state?.Rank ?? DEFAULT_SOURCE;

export const toTotal = (location: Location) =>
  location?.state?.['Featured Total Classes'] ?? DEFAULT_SOURCE;

type IdentityTraits = {
  id: string;
  linkedSocialAccount?: string | null;
  wodExperimentVariant?: string | null;
};
