import { matchPath } from 'react-router';
import type { MatchPath } from '@engage/overview';
import { OVERVIEW_ROUTE } from '@engage/overview';

export const LEGACY_MEMBER_OVERVIEW_ROUTE = '/members/:userId';

export const matchSelfOverviewPath = (path: string): null | MatchPath =>
  matchPath(path, {
    path: OVERVIEW_ROUTE,
    exact: true,
  });
