import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';

type RouteCollection = React.ReactElement<Route>[];

const routes: RouteCollection = [
  <Route
    path="/complete"
    key="/complete"
    component={lazyLoader(
      () => import('./ProfileSetupRoutes' /* webpackChunkName: "ProfileSetupRoutes" */),
    )}
  />,
];

export default routes;
