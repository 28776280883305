/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrowseCategory } from '../../../api-v2-models/generated/BrowseCategory';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Browse Categories
 * Get all available browse categories that are associated with a class type
 * @param libraryType Which library to search.
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getBrowseCategories = (
    api: BaseAPI,
    libraryType: 'on_demand' | 'scheduled' | 'scenic',
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * List of browse categories.
     */
    browseCategories?: Array<BrowseCategory>,
}> => {
    const localVarPath = `/api/browse_categories`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof libraryType !== 'undefined') {
        localVarQueryParameter['library_type'] = libraryType;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

