import React from 'react';

const Stack: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> = props => (
  <svg viewBox="0 0 64 64" {...props}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M58.667 56H16c-2.21 0-4-1.79-4-4V24c0-2.21 1.79-4 4-4h42.667c2.21 0 4 1.79 4 4v28c0 2.21-1.79 4-4 4z" />
      <path d="M1.36 48l-.028-34.384c0-2.355 1.826-4.283 4.06-4.283h49.273M37.333 30v16m8-8h-16" />
    </g>
  </svg>
);

export default Stack;
