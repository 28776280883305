/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArtistDataItem } from '../../../api-v2-models/generated/ArtistDataItem';
import type { RideWithRelevance } from '../../../api-v2-models/generated/RideWithRelevance';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Return the artists that match the query
 * Return the artists that match the search query
 * @param query Search query to match artists on
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getArtistSearch = (
    api: BaseAPI,
    query?: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    options: any = {},
): AxiosPromise<{
    /**
     * All of the artists that match the query
     */
    data?: Array<ArtistDataItem>,
}> => {
    const localVarPath = `/api/search/artist`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof query !== 'undefined') {
        localVarQueryParameter['query'] = query;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Return the classes that match the query
 * Return the classes that match the search query. Note that in addition to the query parameter, this endpoint also accepts a specific artist_id, which will return rides and corresponding metadata to show such as "relevant_song_title" and "num_relevant_songs"
 * @param query Search query to match classes on
 * @param artistId Artist ID to search for classes on. Note that if this parameter is passed in, this endpoint will also return
 * @returns any Successful response
 * @throws ApiError
 */
export const getClassSearch = (
    api: BaseAPI,
    query?: string,
    artistId?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * All of the rides that match the query
     */
    data?: Array<RideWithRelevance>,
}> => {
    const localVarPath = `/api/search/class`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof query !== 'undefined') {
        localVarQueryParameter['query'] = query;
    }
    if(typeof artistId !== 'undefined') {
        localVarQueryParameter['artist_id'] = artistId;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Returns a list of popular search queries
 * This will be hard coded for the initial release; we will later decide whether the list will be created editorially or algorithmically, once we have enough query traffic to analyze.
 * @returns any Successful response
 * @throws ApiError
 */
export const getPopularSearchQueries = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    data?: Array<string>,
}> => {
    const localVarPath = `/api/search/class/popular_queries`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

