type LocalizedStringMap = { [k: string]: LocalizedStringMap | string };

/**
 * Given an `obj`, return the same object with the string leaf values replaces
 * with the dot path value to that leaf.
 *
 * @param {object} [obj] - The object to swap values for dot path value.
 * @param {string} parentPath - The dot path value of the parent to a node.
 */
export const toDotPathValue = <T extends LocalizedStringMap>(obj: T, parentPath: string = ''): T => {
  const pathPrefix = parentPath === '' ? '' : `${parentPath}.`;
  const result = Object.keys(obj).reduce(
    (acc, key) => {
      const path = `${pathPrefix}${key}`;
      const node = obj[key];
      if (typeof node === 'string') {
        acc[key] = path;
      } else {
        acc[key] = toDotPathValue(node, path);
      }
      return acc;
    },
    {},
  );

  // Making the cast explicit on the next line so that a potential type error in the
  // function above is preserved.
  return result as T;
};
