// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const AppDownload = {
  Android: 'android',
  AppMessage: 'appMessage',
  AppStoreAmazon: 'appStoreAmazon',
  AppStoreAndroid: 'appStoreAndroid',
  AppStoreAppleTV: 'appStoreAppleTV',
  AppStoreIOS: 'appStoreIOS',
  AppStoreRoku: 'appStoreRoku',
  ContinueCTA: 'continueCTA',
  DownloadCTA: 'downloadCTA',
  IOS: 'iOS',
  Message: 'message',
  MobileTablet: 'mobileTablet',
  MobileTabletMessage: 'mobileTabletMessage',
  PelotonGym: 'pelotonGym',
  Title: 'title',
  TitleGenericDevice: 'titleGenericDevice',
  Tv: 'tv',
  TvMessage: 'tvMessage',
  TvMessageGeneric: 'tvMessageGeneric',
} as const;
