import React from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { isNil } from '@peloton/helpers/isNil';
import { ieHideScroll, media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { gray1, gray2, slate3 } from '@engage/colors';
import { useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { body16, body14 } from '@engage/typography';
import { TagsCopy, useTags, Tags, useCta, Cta } from '@members/copy';
import { TagsFetchersGQL } from '@members/data-fetch';
import { useAnalyticsHeadersFromView, trackCancelReplaceTagFlow } from '../analytics';
import { ErrorView } from '../ErrorView';
import { useSelectedTagName } from '../hooks';
import { Loading } from '../Loading';
import { SharedHeader } from '../Modal/TagsModalHeaderView';
import { toClientTag } from '../models';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import { Tag } from './Tag';

export const ReplaceTagView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const trackAnalytics = useReduxAction(track);
  const { goBack } = React.useContext(TagsContext);
  const { data, error, mutate: refetch } = useSWR(...TagsFetchersGQL.MyTagsQuery({}));
  const loading = !data;
  const { selectedTagName } = useSelectedTagName();
  const analyticsHeaderProps = useAnalyticsHeadersFromView(TagModalView.OtherMembersTags);
  const tagsList = data?.user?.userTags?.allTags.map(userTag => ({
    tag: toClientTag(userTag.tag),
    isPrimary: userTag.isPrimary,
  }));
  const tagName = selectedTagName;
  const onCancelClick = React.useCallback(() => {
    trackAnalytics(trackCancelReplaceTagFlow(tagName, analyticsHeaderProps));
    goBack();
  }, [tagName, analyticsHeaderProps]);

  const cancelCtaContent = useCta(Cta.Cancel);

  if (!isNil(error)) {
    return <ErrorView tryAgain={() => refetch()} />;
  }

  return (
    <Container>
      <SharedHeader
        headerText={{ id: Tags.ReplaceTag, renderer: useTags }}
        button={{
          props: {
            onClick: onCancelClick,
          },
          content: cancelCtaContent,
        }}
      />
      {loading ? (
        <Loading isLoading={loading} size={40} />
      ) : (
        <Body>
          <ReplaceTagMessageContainer>
            <ReplaceTagMessage>
              <TagsCopy id={Tags.ReplaceTagDescription} values={{ tagName }} />
            </ReplaceTagMessage>
          </ReplaceTagMessageContainer>
          <TagsList data-test-id="tag-list">
            {tagsList?.map(({ tag }) => (
              <Tag
                key={tag.name}
                tagName={tag.name}
                numberOfMembers={tag.numberOfMembers}
                numberOfFollowing={tag.numberOfFollowing}
                backgroundImageUrl={tag.backgroundImageUrl}
              />
            ))}
          </TagsList>
        </Body>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${gray1};
  overflow-x: auto;
  /* hide scrollbar*/
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;

const Body = styled.div`
  padding: ${spaces.small}px;
`;
Body.displayName = 'Body';

const TagsList = styled.ul`
  > li:not(:first-child) {
    margin-top: ${spaces.small}px;
  }
`;
TagsList.displayName = 'TagsList';

const ReplaceTagMessageContainer = styled.div`
  background-color: ${gray2};
  border-radius: ${spaces.xxSmall}px;
  height: ${spaces.xxxHuge}px;
  margin-bottom: ${spaces.small}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReplaceTagMessage = styled.p`
  ${body14}
  color: ${slate3};
  font-weight: 300;

  ${media.tablet`
    ${body16};
  `}
`;
