import type { SagaIterator } from 'redux-saga';
import { takeEvery, getContext } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { SettingsActionType } from '../redux';
import loadSettingsSaga, { loadCaptionsSettings } from './loadSettings';
import updateBlockExplicitSettingsSaga from './updateBlockExplicitSettings';
import updateCaptionsSettings from './updateCaptionsSettings';
import updateClassLanguagePreferences from './updateClassLanguagePreferences';
import updateContentPreferences from './updateContentPreferences';
import updateDisplayLanguage from './updateDisplayLanguage';
import updateDisplaySettings from './updateDisplaySettings';
import updateFeaturesSettings from './updateFeaturesSettings';
import updateNotificationSettings from './updateNotificationSettings';
import updatePrivacySettings from './updatePrivacySettings';
import updateShareChallengeCompletion from './updateShareChallengeCompletion';

const settingsSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(SettingsActionType.SettingsRequest, loadSettingsSaga, client);
  yield takeEvery(
    SettingsActionType.CaptionsSettingsRequest,
    loadCaptionsSettings,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateNotificationSettings,
    updateNotificationSettings,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateShareChallengeCompletion,
    updateShareChallengeCompletion,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdatePrivacySettings,
    updatePrivacySettings,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateDisplayPreferences,
    updateDisplaySettings,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateCaptionsSettingsRequest,
    updateCaptionsSettings,
    client,
  );
  yield takeEvery(
    SettingsActionType.BlockExplicitSettingsRequest,
    updateBlockExplicitSettingsSaga,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateClassLanguagePreferences,
    updateClassLanguagePreferences,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateDisplayLanguage,
    updateDisplayLanguage,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateContentPreferences,
    updateContentPreferences,
    client,
  );
  yield takeEvery(
    SettingsActionType.UpdateFeaturesSettings,
    updateFeaturesSettings,
    client,
  );
};

export default settingsSaga;
export { default as updateBlockExplicitSettingsSaga } from './updateBlockExplicitSettings';
