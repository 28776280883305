// Autogenerated from `packages/@members/copy/copy.generated/__copy__.ts.hbs`

import { toCopyComponent, toFormattedText } from '@peloton/copy';
import type { CopyHook } from '@peloton/copy-generic';
import type { CopyIDs } from '../CopyIDs';
import { useAllCopy } from '../CopyProvider/CopyProvider';
import type { Language } from '../models.generated';

export const useLanguage: CopyHook<CopyIDs<typeof Language>> = (id, values) => {
  const copy = useAllCopy();
  const value = copy?.languageCollection?.[id];

  if (!value) {
    console.error(`No copy found for id ${id}`);
    return '';
  }

  return toFormattedText(value, values) as string;
};

export const LanguageCopy = toCopyComponent(useLanguage);
