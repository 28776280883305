import classNames from 'classnames';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { BreakpointEither } from '@peloton/responsive';
import { hoverTransition } from '@peloton/styles';
import { white, slate2, gray1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { spaces } from '@engage/styles';
import { DarkButton2Medium, DarkButton2Small } from '@members/buttons';
import { TagsCopy, Tags } from '@members/copy';
import { Add as AddMedium, CheckIcon } from '@members/icons';
import LoadingSpinner from '@members/loading/LoadingSpinner';
import { useIsAdded } from './hooks';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading: boolean;
  isAdded: boolean;
};

export type BaseProps = Props & {
  Button: React.ComponentType<
    React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>>
  >;
  Icon: React.ComponentType<React.PropsWithChildren<unknown>>;
  spinnerSize: number;
};

export const AddButtonBase: React.FC<React.PropsWithChildren<BaseProps>> = ({
  Button,
  Icon,
  spinnerSize,
  isLoading = false,
  isAdded = false,
  children,
  ...props
}) => (
  <Button className={classNames({ isAdded })} {...props}>
    <If condition={isLoading}>
      <StyledLoadingSpinner isLoading={isLoading} size={spinnerSize} />
    </If>
    <If condition={!isLoading}>
      <Icon />
      <Spacer />
      {children}
    </If>
  </Button>
);

export const AddButtonMedium: React.FC<React.PropsWithChildren<Props>> = props => (
  <AddButtonBase
    Button={StyledDarkButton2Medium}
    Icon={props.isAdded ? CheckIconMedium : AddMedium}
    spinnerSize={spaces.xLarge}
    {...props}
  />
);

export const AddButtonSmall: React.FC<React.PropsWithChildren<Props>> = props => (
  <AddButtonBase
    Button={StyledDarkButton2Small}
    Icon={props.isAdded ? CheckIconSmall : AddSmall}
    spinnerSize={spaces.large}
    {...props}
  />
);

type AddButtonProps = {
  isAdded: boolean;
};

export const AddButton: React.FC<React.PropsWithChildren<AddButtonProps>> = React.memo(
  ({ isAdded }) => {
    const { loading, mutation } = useIsAdded(isAdded);
    return (
      <BreakpointEither breakpoint="tablet">
        <AddButtonSmall
          isLoading={loading}
          isAdded={!loading && isAdded}
          onClick={mutation}
          data-test-id="addTagDetailsButton"
        >
          <TagsCopy id={isAdded ? Tags.Added : Tags.AddCta} />
        </AddButtonSmall>
        <AddButtonMedium
          isLoading={loading}
          isAdded={!loading && isAdded}
          onClick={mutation}
          data-test-id="addTagDetailsButton"
        >
          <TagsCopy id={isAdded ? Tags.Added : Tags.AddCta} />
        </AddButtonMedium>
      </BreakpointEither>
    );
  },
);

AddButton.displayName = 'AddButton';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  fill: ${white};
  margin-top: ${spaces.tiny}px;
`;
StyledLoadingSpinner.displayName = 'StyledLoadingSpinner';

export const StyledDarkButton2Medium = styled(DarkButton2Medium)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  height: 40px;
  min-width: 160px;

  &.isAdded {
    background-color: ${gray1};
    color: ${slate2};

    ${hoverTransition({
      property: 'background-color',
      to: transparentize(0.15, gray1),
    })}
    &:active {
      background-color: ${transparentize(0.15, gray1)};
    }
  }
`;

const StyledDarkButton2Small = styled(DarkButton2Small)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};

  &.isAdded {
    background-color: ${gray1};
    color: ${slate2};

    ${hoverTransition({
      property: 'background-color',
      to: transparentize(0.15, gray1),
    })}
    &:active {
      background-color: ${transparentize(0.15, gray1)};
    }
  }
`;

const CheckIconMedium = styled(CheckIcon)`
  fill: ${slate2};
  width: 14px;
  height: 14px;
  margin-top: ${spaces.tiny - 1}px;
  margin-right: ${spaces.tiny}px;
`;

const CheckIconSmall = styled(CheckIcon)`
  fill: ${slate2};
  width: 10px;
  height: 10px;
  margin-top: ${spaces.tiny - 1}px;
  margin-right: ${spaces.tiny}px;
`;

const AddSmall = styled(AddMedium)`
  width: 10px;
  height: 10px;
  margin-top: ${spaces.tiny - 1}px;
  margin-right: ${spaces.tiny - 1}px;
`;

const Spacer = styled.div`
  width: ${spaces.xSmall}px;
`;
