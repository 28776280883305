import { ascend, curry, prop } from 'ramda';
import type { Segment } from '@engage/class-detail';
import { toSegment } from '@engage/class-detail';
import type { Class } from '@engage/classes';
import { isLive, isOnDemand, isScenic } from '@engage/classes';
import type { ApiTimeline } from './api';

// TODO: this mapping won't be needed once an api update is in place to return the desired url.
export const toHttpsVodUrl = (vodUrl: string) =>
  vodUrl
    .replace(
      /http:\/\/secure-vh.akamaihd.net|http:\/\/pelotoncycle-vh.akamaihd.net/,
      'https://securevod-vh.akamaihd.net',
    )
    .replace(/http:\/\/audio-only.mdc.akamaized.net/, 'https://audio-only.akamaized.net');

export const toHttpsLiveOrVodUrl = (
  vod: typeof toHttpsVodUrl,
  klass: Class,
): string | undefined => {
  if (isLive(klass)) {
    return klass.liveStreamUrl;
  } else if (isOnDemand(klass) || isScenic(klass)) {
    /**
     * In `mapRideToClass` we default non-scenic and non-live classes to on-demand classes.
     * However, it is possible for classes to be non-on-demand and also not have a vod_stream_url.
     */
    if (klass.vodStreamUrl) {
      return vod(klass.vodStreamUrl);
    }
  }

  return;
};

export const sortSegments = (segments: Segment[]) =>
  segments.sort(ascend(prop('startTimeOffset')));

export const toTimeline = (apiTimeline: ApiTimeline) => ({
  classStartOffset: apiTimeline.classStartOffset,
  classEndOffset: apiTimeline.classEndOffset,
  videoEndOffset: apiTimeline.videoEndOffset,
  segments: sortSegments(apiTimeline.segments.map(toSegment)),
});

export default curry(toHttpsLiveOrVodUrl)(toHttpsVodUrl);
