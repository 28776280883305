import React from 'react';

const Twitter: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <path d="M17 4.538a6.432 6.432 0 0 1-1.885.515A3.28 3.28 0 0 0 16.56 3.24a6.557 6.557 0 0 1-2.084.795A3.275 3.275 0 0 0 12.082 3a3.281 3.281 0 0 0-3.196 4.03 9.329 9.329 0 0 1-6.768-3.428 3.278 3.278 0 0 0 1.019 4.38 3.285 3.285 0 0 1-1.487-.409v.041c0 1.59 1.13 2.912 2.634 3.216a3.261 3.261 0 0 1-1.487.059 3.292 3.292 0 0 0 3.068 2.28A6.613 6.613 0 0 1 1 14.527 9.306 9.306 0 0 0 6.035 16c6.041 0 9.343-5 9.343-9.333 0-.14-.006-.287-.011-.427A6.646 6.646 0 0 0 17 4.538z" />
  </svg>
);

export { Twitter };
