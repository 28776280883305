/* eslint-disable */
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type LastTagSearchQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LastTagSearchQuery = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'lastTagSearch'>
);


export const LastTagSearchDocument = gql`
    query LastTagSearch {
  lastTagSearch @client
}
    `;

/**
 * __useLastTagSearchQuery__
 *
 * To run a query within a React component, call `useLastTagSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastTagSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastTagSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastTagSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LastTagSearchQuery, LastTagSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<LastTagSearchQuery, LastTagSearchQueryVariables>(LastTagSearchDocument, baseOptions);
      }
export function useLastTagSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LastTagSearchQuery, LastTagSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LastTagSearchQuery, LastTagSearchQueryVariables>(LastTagSearchDocument, baseOptions);
        }
export type LastTagSearchQueryHookResult = ReturnType<typeof useLastTagSearchQuery>;
export type LastTagSearchLazyQueryHookResult = ReturnType<typeof useLastTagSearchLazyQuery>;
export type LastTagSearchQueryResult = ApolloReactCommon.QueryResult<LastTagSearchQuery, LastTagSearchQueryVariables>;