/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import { throttle } from 'frame-throttle';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';
import type { KeyedMutator } from 'swr';
import { isNil } from '@peloton/helpers/isNil';
import { ieHideScroll, media } from '@peloton/styles';
import { If } from '@engage/conditional-render';
import { spaces } from '@engage/styles';
import type { TagBrowseCategoryDisplayNamesQuery } from '@members/graphql-swr/schemas/tags/queries/TagBrowseCategoryDisplayNames.generated';
import type { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import { ErrorView } from '../ErrorView';
import { Loading as LoadingSpinner } from '../Loading';
import { LoadingMore } from '../LoadingMore';
import { ScrollContext } from '../MainView/ScrollContext';
import type { ClientTag } from '../models';
import { toClientTag } from '../models';
import { Tag } from '../Tag';
import type { CategoryOffsetsMap } from './BrowseTagsView';
import {
  useTrackViewedBrowseTags,
  useLastLoadingIndex,
  useTagBrowseCategoryPaginatedQuery,
} from './hooks';
import { NoTagsView } from './NoTagsView';

type Props = {
  category: TagBrowseCategorySlug;
  isActive: boolean;
  refetchNavBar: KeyedMutator<TagBrowseCategoryDisplayNamesQuery>;
  categoryOffsetsMap: CategoryOffsetsMap;
  setCategoryOffsetsMap: (map: CategoryOffsetsMap) => void;
};

export enum OtherCardType {
  Loading,
}

const TAG_CARD_HEIGHT = 110;
const LOADING_HEIGHT = 160;

export const Body: React.FC<React.PropsWithChildren<Props>> = ({
  category,
  isActive,
  refetchNavBar,
}) => {
  const [scrollOffset, setScrollOffset] = React.useState<number>(0);
  const [tryAgain, setTryAgain] = React.useState(false);
  const onSuccess = () => setTryAgain(false);
  const {
    data,
    isLoading: loading,
    error,
    loadMore: fetchMore,
    mutate: refetch,
  } = useTagBrowseCategoryPaginatedQuery({ categorySlug: category }, onSuccess);
  const tagList =
    data?.tagBrowseCategory?.tags?.edges?.map(({ node }) => toClientTag(node)) ?? [];

  React.useEffect(() => {
    // WE-10307 Ensure data loads when a user with Tags loads the modal
    refetch();
  }, []);

  const loadMore = React.useCallback(() => {
    fetchMore();
  }, [loading, data?.tagBrowseCategory?.tags?.pageInfo?.endCursor]);

  const [successfullyCalledTracker, setSuccessfullyCalledTracker] = React.useState(false);

  React.useEffect(() => {
    if (!loading) {
      setSuccessfullyCalledTracker(true);
    }
  }, [loading]);

  useTrackViewedBrowseTags(isActive, loading, category, successfullyCalledTracker, data);

  if (!isActive) {
    return null;
  }

  if (!isNil(error)) {
    return (
      <ErrorView
        tryAgain={() => {
          setTryAgain(true);
          refetch();
          refetchNavBar();
        }}
      />
    );
  }

  return (loading && isNil(data?.tagBrowseCategory?.tags)) || tryAgain ? (
    <LoadingSpinner isLoading={loading} size={40} />
  ) : (
    <>
      {tagList.length === 0 ? (
        <NoTagsView />
      ) : (
        <TagsList
          hasNextPage={data?.tagBrowseCategory?.tags?.pageInfo?.hasNextPage ?? false}
          loadMore={loadMore}
          tagList={tagList}
          scrollOffset={scrollOffset}
          setScrollOffset={setScrollOffset}
        />
      )}
    </>
  );
};

type TagsListProps = {
  tagList: ClientTag[];
  hasNextPage: boolean;
  scrollOffset: number;
  loadMore: () => void;
  setScrollOffset: (offset: number) => void;
};

export const TagsList: React.FC<React.PropsWithChildren<TagsListProps>> = ({
  tagList,
  hasNextPage,
  loadMore,
  scrollOffset,
  setScrollOffset,
}) => {
  const { setNavBarAnimation } = React.useContext(ScrollContext);
  const listRef = React.useRef<VariableSizeList>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const tagsWithLoading = React.useMemo(
    () => (hasNextPage ? [...tagList, OtherCardType.Loading] : [...tagList]),
    [hasNextPage, tagList],
  );
  const { setLastLoadingIndex } = useLastLoadingIndex(listRef, tagList);
  const throttledSetScrollOffset = throttle((offset: number) => setScrollOffset(offset));

  const getItemSize = React.useCallback(
    (index: number) => {
      if (tagsWithLoading[index] === OtherCardType.Loading) {
        setLastLoadingIndex(index);
        return LOADING_HEIGHT;
      } else {
        return TAG_CARD_HEIGHT + spaces.small;
      }
    },
    [tagsWithLoading],
  );

  // Without this, `loading` above is not updated in any subsequent `loadMore` calls, resulting in multiple fetches.
  const memoizedLoadMore = React.useCallback(() => loadMore(), [loadMore]);
  return (
    <ListContainer
      data-test-id="list-container"
      onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
        setNavBarAnimation(e.deltaY);
      }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            height={height}
            width={width}
            itemCount={tagsWithLoading.length}
            itemSize={getItemSize}
            ref={listRef}
            innerRef={scrollRef}
            initialScrollOffset={scrollOffset}
            onItemsRendered={({ visibleStopIndex }) => {
              if (visibleStopIndex === tagList.length) {
                memoizedLoadMore();
              }
            }}
            onScroll={({ scrollOffset: newScrollOffset }) => {
              throttledSetScrollOffset(newScrollOffset);
            }}
          >
            {toTagCard(tagsWithLoading)}
          </VariableSizeList>
        )}
      </AutoSizer>
    </ListContainer>
  );
};

type ListItemProps = {
  index: number;
  style: React.CSSProperties;
};

export const toTagCard = (tagsAndLoading: (ClientTag | OtherCardType)[]) => ({
  index,
  style,
}: ListItemProps) => {
  const tagProps = tagsAndLoading[index];
  return (
    <div style={{ ...style, marginTop: '12px' }}>
      {tagProps === OtherCardType.Loading ? (
        <LoadingMore isLoading={true} size={40} />
      ) : (
        <If condition={index < tagsAndLoading.length}>
          <Tag
            {...tagProps}
            tagName={tagProps?.name}
            viewingOwnTag={false}
            isPrimary={false}
            isCreatingTag={false}
            viewingFromBrowse={true}
          />
        </If>
      )}
    </div>
  );
};

const ListContainer = styled.div`
  height: 100%;
  flex: 1;
  padding: 0 ${spaces.small}px;
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  z-index: 2;
  pointer-events: none;

  ${media.tablet`
    height: 106px;
  `}
`;
ButtonContainer.displayName = 'ButtonContainer';
