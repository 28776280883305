export type RequestAction = {
  type: AvatarUploaderActions.Request;
  payload: { file: File };
};

export type AvatarUploaderSuccessAction = {
  type: AvatarUploaderActions.RequestSuccess;
  payload: { imageUrl: string };
};

export type ReceiveDefaultAvatarAction = {
  type: AvatarUploaderActions.Default;
  payload: { imageUrl: string };
};

export type RequestDefaultAvatarAction = {
  type: AvatarUploaderActions.RequestDefault;
};

export enum AvatarUploaderActions {
  Request = 'pelo/updateAvatar/Request',
  RequestSuccess = 'pelo/updateAvatar/Success',
  Default = 'pelo/updateAvatar/Default',
  RequestDefault = 'pelo/updateAvatar/RequestDefault',
}

export const updateAvatar = (file: File): RequestAction => ({
  type: AvatarUploaderActions.Request,
  payload: { file: file },
});

export const updateAvatarSuccess = (imageUrl: string): AvatarUploaderSuccessAction => ({
  type: AvatarUploaderActions.RequestSuccess,
  payload: { imageUrl: imageUrl },
});

export const receiveDefaultAvatar = (imageUrl: string): ReceiveDefaultAvatarAction => ({
  type: AvatarUploaderActions.Default,
  payload: { imageUrl: imageUrl },
});

export const getDefaultAvatar = (): RequestDefaultAvatarAction => ({
  type: AvatarUploaderActions.RequestDefault,
});
