import type { ResponseAction } from '@peloton/redux-fetch';

export enum RelationshipsActionType {
  Remove = 'pelo/friends/Remove',
  RemoveSuccess = 'pelo/friends/RemoveSuccess',
  Reject = 'pelo/friends/Reject',
  RejectSuccess = 'pelo/friends/RejectSuccess',
  Approve = 'pelo/friends/Approve',
  ApproveSuccess = 'pelo/friends/ApproveSuccess',
}

export const removeFollower = (id: string) => ({
  type: RelationshipsActionType.Remove,
  payload: { id },
});

export const rejectFollower = (id: string) => ({
  type: RelationshipsActionType.Reject,
  payload: { id },
});

export const approveFollower = (id: string) => ({
  type: RelationshipsActionType.Approve,
  payload: { id },
});

export type RemoveFollowerAction = ReturnType<typeof removeFollower>;

export type RejectFollowerAction = ReturnType<typeof rejectFollower>;

export type ApproveFollowerAction = ReturnType<typeof approveFollower>;

export type ApproveFollowerSuccessAction = ResponseAction<
  RelationshipsActionType.ApproveSuccess,
  { followerId: string; userId: string }
>;

export type RejectFollowerSuccessAction = ResponseAction<
  RelationshipsActionType.RejectSuccess,
  { followerId: string; userId: string }
>;

export type RemoveFollowerSuccessAction = ResponseAction<
  RelationshipsActionType.RemoveSuccess,
  { followerId: string; userId: string }
>;

export const approveFollowerSuccess = (userId: string, followerId: string) => ({
  type: RelationshipsActionType.ApproveSuccess,
  payload: { userId, followerId },
});

export const rejectFollowerSuccess = (userId: string, followerId: string) => ({
  type: RelationshipsActionType.RejectSuccess,
  payload: { userId, followerId },
});

export const removeFollowerSuccess = (userId: string, followerId: string) => ({
  type: RelationshipsActionType.RemoveSuccess,
  payload: { userId, followerId },
});
