import { isChrome } from '@peloton/browser';
import { toNowTime, toTime } from '@peloton/time';
import type { RideWithUserInfo } from '@engage/api-v2';
import type { ClassAnalyticsProperties } from '@engage/classes';
import { toClassAnalytics } from '@engage/classes';
import type { WorkoutClassInfo } from '@engage/video';
import {
  toCurrentPointInClass,
  toTotalTimeInWorkout,
  toVideoAnalytics,
  CastProvider,
} from '@engage/video';
import type { DetailedWorkout } from '@engage/workouts';
import { toAnalyticsWorkoutProperties } from '@engage/workouts';
import { toBaseProperties } from '@members/analytics';
import type {
  PersistedVideoProperties,
  VideoStateAnalyticsProperties,
} from './selectors';

export const trackViewedPlayer = (
  classInfo: ClassAnalyticsProperties | undefined,
  event: ViewEvents,
) => ({
  event,
  ...toClassAnalytics(classInfo),
});

export const trackPlayerAction = (
  event: string,
  classInfo: ClassAnalyticsProperties,
  workoutInfo: DetailedWorkout,
  videoInfo: VideoStateAnalyticsProperties,
  workoutClassInfo: WorkoutClassInfo,
  opts?: object,
) => ({
  event,
  ...toClassAnalytics(classInfo),
  ...toAnalyticsWorkoutProperties(workoutInfo),
  ...toVideoAnalytics(
    classInfo,
    videoInfo,
    workoutClassInfo,
    isChrome() ? CastProvider.Chromecast : CastProvider.AirPlay,
  ),
  ...opts,
});

export const trackRefreshedClass = (
  classInfo: ClassAnalyticsProperties,
  persistedInfo: PersistedVideoProperties,
) => ({
  event: ViewEvents.Refresh,
  ...toClassAnalytics(classInfo),
  ...toRefreshAnalytics(classInfo, persistedInfo),
});

const toRefreshAnalytics = (
  classInfo: ClassAnalyticsProperties,
  persistedInfo: PersistedVideoProperties,
) => {
  const { persistedStart, persistedTimecode } = persistedInfo;
  const now = toNowTime();
  const startTime = classInfo.scheduledStartTime ?? now.clone();

  return {
    pointInClass: toCurrentPointInClass(startTime, persistedTimecode, now),
    totalTimeInWorkout: toTotalTimeInWorkout(toTime(persistedStart, true), now),
  };
};

export const trackClickedGetDigital = () => ({
  event: ViewEvents.ClickGetDigital,
});

export const trackSelectedPostClassRecommendation = (
  recClass: RideWithUserInfo,
  index: number,
) => ({
  event: ViewEvents.SelectedPostClassRecommendation,
  ['RecoSet ID']: recClass.id,
  ['RecoSet Name']: recClass.title,
  ['RecoSet Fitness Discipline']: recClass.fitnessDiscipline,
  ['RecoSet Position']: index + 1,
  ['RecoSet Total']: 3,
  ...toBaseProperties(),
});

export const trackViewedPostClassRecommendationSet = (
  recClass: RideWithUserInfo,
  index: number,
) => ({
  event: ViewEvents.ViewedPostClassRecommendationSet,
  ['RecoSet ID']: recClass.id,
  ['RecoSet Name']: recClass.title,
  ['RecoSet Fitness Discipline']: recClass.fitnessDiscipline,
  ['RecoSet Position']: index + 1,
  ['RecoSet Total']: 3,
  ...toBaseProperties(),
});

export enum ViewEvents {
  Paywall = 'Viewed Paywall',
  ClickGetDigital = 'Clicked Get Peloton Digital',
  StreamLimit = 'Viewed Stream Limit Reached',
  Refresh = 'Refreshed Page During Class',
  SelectedPostClassRecommendation = 'Selected Post Class Recommendation',
  ViewedPostClassRecommendationSet = 'Viewed Post Class Recommendation Set',
}
