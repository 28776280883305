import type { AxiosPromise } from 'axios';
import type { LoggedInUserMappingExtended } from '@engage/api-v2-models/extended/LoggedInUserMappingExtended';
import type { BaseAPI } from '../generated';
import { getMe } from '../generated';

export const getMeExtended = (
  api: BaseAPI,
  pelotonPlatform?:
    | 'default'
    | 'commercial_bike'
    | 'home_bike'
    | 'aurora'
    | 'home_tread'
    | 'studio_tread'
    | 'iOS_app'
    | 'pos'
    | 'studio_bike'
    | 'web'
    | 'android'
    | 'fire_tv'
    | 'tiger'
    | 'android_tv'
    | 'fire_tablet'
    | 'apple_tv'
    | 'apple_watch'
    | 'roku'
    | 'comcast_x1'
    | 'sky_tv'
    | 'lg'
    | 'samsung',
  options: any = {},
): AxiosPromise<LoggedInUserMappingExtended> =>
  getMe.apply(this, [api, pelotonPlatform, options]);
