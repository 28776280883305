import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  topHashColor: string;
  bottomHashColor: string;
};

export const HashTag: React.FC<React.PropsWithChildren<Props>> = ({
  topHashColor,
  bottomHashColor,
  ...props
}) => (
  <svg viewBox="0 0 64 64" {...props}>
    <defs>
      <linearGradient id="prefix__c" x1="47.759%" x2="48.6%" y1="96.51%" y2="80.198%">
        <stop offset="0%" stopOpacity={0.2} />
        <stop offset="100%" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="prefix__d" x1="52.474%" x2="51.673%" y1="8.21%" y2="24.13%">
        <stop offset="0%" stopOpacity={0.25} />
        <stop offset="100%" stopOpacity={0} />
      </linearGradient>
      <path id="prefix__a" d="M.038.043h13.308V42.72H.037z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h64v64H0z" />
      <path
        fill={topHashColor}
        fillRule="nonzero"
        d="M16.123 63.668a3.702 3.702 0 01-3.64-4.4l1.637-9.02h7.398l-1.763 10.413a3.704 3.704 0 01-3.632 3.007"
      />
      <g transform="translate(16.172 .06)">
        <g fillRule="nonzero">
          <path
            fill={topHashColor}
            d="M3.745 42.72A3.703 3.703 0 01.09 38.405l5.9-35.27a3.703 3.703 0 017.303 1.222L7.392 39.628a3.704 3.704 0 01-3.647 3.092"
          />
          <path
            fill={topHashColor}
            d="M3.745 42.72A3.703 3.703 0 01.09 38.405l5.9-35.27a3.703 3.703 0 017.303 1.222L7.392 39.628a3.704 3.704 0 01-3.647 3.092"
          />
        </g>
      </g>
      <g fillRule="nonzero">
        <path
          fill={bottomHashColor}
          d="M38.79 63.668a3.702 3.702 0 01-3.65-4.355l7.21-40.291a3.703 3.703 0 017.29 1.305l-7.21 40.29a3.704 3.704 0 01-3.64 3.05"
        />
        <path
          fill={bottomHashColor}
          d="M3.795 48.246a3.702 3.702 0 01-3.648-4.355L7.357 3.6a3.703 3.703 0 017.289 1.304l-7.21 40.29a3.704 3.704 0 01-3.64 3.051"
          transform="translate(34.994 15.422)"
        />
      </g>
      <path
        fill={bottomHashColor}
        fillRule="nonzero"
        d="M43.303 11.92l1.571-8.762a3.702 3.702 0 117.29 1.295l-1.257 7.467h-7.604z"
      />
      <path
        fill={topHashColor}
        fillRule="nonzero"
        d="M21.16 22.915l1.336-7.501 36.27.045c2.195 0 3.974 1.658 3.974 3.702 0 2.045-1.78 3.703-3.974 3.703l-37.606.051zm-6.424-.05H7.773a3.702 3.702 0 010-7.406l8.299-.046-1.336 7.451z"
      />
      <path
        fill={bottomHashColor}
        fillRule="nonzero"
        d="M56.456 45.341l-6.782-.024 1.336-7.399 7.09.02a3.702 3.702 0 013.703 3.7c0 2.045-1.658 3.703-5.347 3.703m-12.846 0H5.032c-2.19 0-3.965-1.658-3.965-3.702 0-2.045 1.775-3.702 3.965-3.702h39.914l-1.336 7.404z"
      />
    </g>
  </svg>
);
