// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const AccountSetup = {
  AnalyticsBikeOption: 'analyticsBikeOption',
  AnalyticsCPIQuestion: 'analyticsCPIQuestion',
  UpdatedAnalyticsCPIQuestion: 'updatedAnalyticsCPIQuestion',
  AnalyticsMobileAppOption: 'analyticsMobileAppOption',
  AnalyticsTreadOption: 'analyticsTreadOption',
  AnalyticsTVAppOption: 'analyticsTVAppOption',
  BikeLabel: 'bikeLabel',
  BirthdayHeader: 'birthdayHeader',
  BirthdaySubHeader: 'birthdaySubHeader',
  CollectProductInterestQuestion: 'collectProductInterestQuestion',
  CollectProductInterestUpdatedQuestion: 'collectProductInterestUpdatedQuestion',
  CollectProductInterestSubHeader: 'collectProductInterestSubHeader',
  CollectProductInterestSkipButton: 'collectProductInterestSkipButton',
  CollectProductInterestSubmitButton: 'collectProductInterestSubmitButton',
  CompleteAccount: 'completeAccount',
  CompleteProfile: 'completeProfile',
  CompleteProfileCta: 'completeProfileCta',
  CompleteProfileDetails: 'completeProfileDetails',
  ContinueCta: 'continueCta',
  CreatePassword: 'createPassword',
  Decline: 'decline',
  GenericSignUpError: 'genericSignUpError',
  GuideLabel: 'guideLabel',
  HelpText: 'helpText',
  Location: 'location',
  LocationDetails: 'locationDetails',
  LocationHeader: 'locationHeader',
  LocationHelpText: 'locationHelpText',
  LocationSubheader: 'locationSubheader',
  LocationPrivacyAgeFootnote: 'locationPrivacyAgeFootnote',
  LocationPrivacyCountry: 'locationPrivacyCountry',
  LocationPrivacyLocationFootnote: 'locationPrivacyLocationFootnote',
  LocationPrivacyPageTitle: 'locationPrivacyPageTitle',
  LocationPrivacyHeaderNew: 'locationPrivacyHeaderNew',
  LocationPrivacyHeaderUpdate: 'locationPrivacyHeaderUpdate',
  LocationPrivacySubheader: 'locationPrivacySubheader',
  LocationPrivacyConfirmCTA: 'locationPrivacyConfirmCTA',
  LocationPrivacyNotNowCTA: 'locationPrivacyNotNowCTA',
  LocationPrivacyDismissCTA: 'locationPrivacyDismissCTA',
  MobileAppLabel: 'mobileAppLabel',
  OnboardingBirthdaySubHeader: 'onboardingBirthdaySubHeader',
  Password: 'password',
  PasswordError: 'passwordError',
  PasswordHeader: 'passwordHeader',
  PasswordHelpText: 'passwordHelpText',
  PasswordInvalid: 'passwordInvalid',
  PasswordMinError: 'passwordMinError',
  PasswordTooShort: 'passwordTooShort',
  PelotonAppLabel: 'pelotonAppLabel',
  PelotonBikeLabel: 'pelotonBikeLabel',
  PelotonRowLabel: 'pelotonRowLabel',
  PelotonTreadLabel: 'pelotonTreadLabel',
  ProfileImage: 'profileImage',
  ProfileImageSubheader: 'profileImageSubheader',
  RefreshedBirthdateLabel: 'refreshedBirthdateLabel',
  RefreshedBirthdatePlaceholder: 'refreshedBirthdatePlaceholder',
  RefreshedUsernameHeader: 'refreshedUsernameHeader',
  RefreshedUsernameSubheader: 'refreshedUsernameSubheader',
  RefreshedUsernameExistsError: 'refreshedUsernameExistsError',
  RefreshedUsernameSpecialCharactersError: 'refreshedUsernameSpecialCharactersError',
  RefreshedUsernameBadWordError: 'refreshedUsernameBadWordError',
  RefreshedUsernameConnectivityError: 'refreshedUsernameConnectivityError',
  RefreshedUsernameLengthError: 'refreshedUsernameLengthError',
  ReturnToDevice: 'returnToDevice',
  SignUpFieldRequiredError: 'signUpFieldRequiredError',
  SkipCta: 'skipCta',
  TreadLabel: 'treadLabel',
  TVAppLabel: 'tVAppLabel',
  Username: 'username',
  UsernameExistsError: 'usernameExistsError',
  UsernameHeader: 'usernameHeader',
  UsernameHelp: 'usernameHelp',
  UsernameHelpText: 'usernameHelpText',
  UsernameMaxError: 'usernameMaxError',
  UsernameMinError: 'usernameMinError',
  UsernamePolicyViolationError: 'usernamePolicyViolationError',
  UsernameRequired: 'usernameRequired',
  UsernameSpecialCharactersError: 'usernameSpecialCharactersError',
  UsernameSubheader: 'usernameSubheader',
  UsernameWithStar: 'usernameWithStar',
  Welcome: 'welcome',
  WelcomeButtonCta: 'welcomeButtonCta',
  WelcomeCorporateWellnessDetails: 'welcomeCorporateWellnessDetails',
  WelcomeCta: 'welcomeCta',
  WelcomeDetails: 'welcomeDetails',
  PrivacyPreferences: 'privacyPreferences',
} as const;
