import { AuthEnv } from '@peloton/auth/authClient';
// this module is the proxy, so it's okay for @members/env to import @engage/env
/* eslint-disable no-restricted-imports */
import { ApiEnvs } from '@peloton/external-links';
import {
  isLocalApiEnv as engageIsLocal,
  isProdApi as engageIsProd,
  isQaApiEnv as engageIsQa,
  toApiEnv as engageToApi,
} from '@engage/env/api';
import { isProdEnv } from '@engage/env/env';
import type { Shortcuts } from './localStorageEnv';
import { StorageKeys, syncedQueryParamToShortcutKey } from './localStorageEnv';

export const ApiShortcuts: Shortcuts = {
  [ApiEnvs.Qa1]: ['q', 'qa'],
  [ApiEnvs.Prod]: ['p', 'prod', 'production'],
};

const maybeHostname = typeof window === 'undefined' ? '' : window.location.hostname;

export const getUatApiOverrides = () =>
  syncedQueryParamToShortcutKey(StorageKeys.Api, ApiShortcuts);

export const toApiEnv = (hostname: string = maybeHostname) =>
  isProdEnv(hostname) ? ApiEnvs.Prod : engageToApi(hostname, getUatApiOverrides);

export const toAuthEnv = () => {
  const apiEnv = toApiEnv(maybeHostname);

  switch (apiEnv) {
    case ApiEnvs.Qa1:
      return AuthEnv.Stage;
    case ApiEnvs.Qa2:
      return AuthEnv.Test;
    case ApiEnvs.Prod:
    default:
      return AuthEnv.Prod;
  }
};

export const isLocalApiEnv = () => engageIsLocal(getUatApiOverrides);
export const isProdApiEnv = () => engageIsProd(getUatApiOverrides);
export const isQaApiEnv = (hostname: string = maybeHostname) =>
  engageIsQa(hostname, getUatApiOverrides);

export const isApiOverridden = (hostname: string = maybeHostname) =>
  engageToApi(hostname) !== toApiEnv(hostname);
