// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Members = {
  ApproveFollowRequest: 'approveFollowRequest',
  AvatarAltText: 'avatarAltText',
  ProfilePhoto: 'profilePhoto',
  UploadPhoto: 'uploadPhoto',
  RemovePhoto: 'removePhoto',
  Cancel: 'cancel',
  Save: 'save',
  FindMembersCta: 'findMembersCta',
  FollowCta: 'followCta',
  FollowDescription: 'followDescription',
  Followers: 'followers',
  FollowersLabel: 'followersLabel',
  Following: 'following',
  FollowingLabel: 'followingLabel',
  FollowingUser: 'followingUser',
  FollowRequest: 'followRequest',
  MemberActivity: 'memberActivity',
  MembersYouFollow: 'membersYouFollow',
  MoreLabel: 'moreLabel',
  NoFollowersYet: 'noFollowersYet',
  NoMembersFound: 'noMembersFound',
  NoMembersFoundTitle: 'noMembersFoundTitle',
  NotFollowingMembers: 'notFollowingMembers',
  OverviewTitle: 'overviewTitle',
  PendingFollowRequest: 'pendingFollowRequest',
  PopularWith: 'popularWith',
  ProfilePrivate: 'profilePrivate',
  ProfileTitle: 'profileTitle',
  RatingCount: 'ratingCount',
  RejectFollowRequest: 'rejectFollowRequest',
  RemoveFollowerCta: 'removeFollowerCta',
  RequestedToFollow: 'requestedToFollow',
  SearchFollowCta: 'searchFollowCta',
  SearchFollowing: 'searchFollowing',
  SearchInputLabel: 'searchInputLabel',
  SearchModal: 'searchModal',
  PersonalizationLoadingText: 'personalizationLoadingText',
  SearchPlaceholder: 'searchPlaceholder',
  SearchTitle: 'searchTitle',
  UnknownError: 'unknownError',
  UserFollowing: 'userFollowing',
  UsernameInvalid: 'usernameInvalid',
  UsernameTaken: 'usernameTaken',
  ViewDetails: 'viewDetails',
  Block: 'block',
  BlockMember: 'blockMember',
  HideTags: 'hideTags',
  BlockUsername: 'blockUsername',
  BlockWarning: 'blockWarning',
  BlockedAndHidden: 'blockedAndHidden',
  ManageBlockedandHidden: 'manageBlockedandHidden',
  Manage: 'manage',
  Members: 'members',
  Tags: 'tags',
  Unblock: 'unblock',
  ChangesSaved: 'changesSaved',
  BlockedMembers: 'blockedMembers',
  HiddenTags: 'hiddenTags',
  Hide: 'hide',
  Show: 'show',
  HideTag: 'hideTag',
  HideTagConfirmation: 'hideTagConfirmation',
  HideTagsConfirmation: 'hideTagsConfirmation',
  HideTagWarning: 'hideTagWarning',
  HideTagModalWarning: 'hideTagModalWarning',
  HideAll: 'hideAll',
  ShowAll: 'showAll',
  MemberBlocked: 'memberBlocked',
  TagsHidden: 'tagsHidden',
  NoBlockedMembers: 'noBlockedMembers',
  NoHiddenTags: 'noHiddenTags',
  GotIt: 'gotIt',
  Done: 'done',
  BookmarkPage: 'bookmarkPage',
  BlockError: 'blockError',
  HideError: 'hideError',
} as const;
