import type { Language } from '@peloton/internationalize';
import { updateDisplayLanguage } from '@engage/settings/redux';
import { toSupportedBrowserLanguage } from './toSupportedBrowserLanguage';

const SettingKey = 'members/displayLanguage';
const DidPersistKey = 'members/dualLanguagePreferencePersisted';

export const persist = (value: Language, userId: string) =>
  window.localStorage?.setItem(`${SettingKey}-${userId}`, value);

export const getPersistedOrBrowserDisplayLanguage = () => (userId: string) => {
  /* Vitality app is also using this persisted key https://pelotoncycle.atlassian.net/browse/we-4043 */
  const persisted = window.localStorage?.getItem(`${SettingKey}-${userId}`);

  if (languageExists(persisted)) {
    return persisted;
  } else {
    const browserLanguage = toSupportedBrowserLanguage();
    persist(browserLanguage, userId);
    updateDisplayLanguage(browserLanguage);
    return browserLanguage;
  }
};

export const persistDualLocalePreferences = (userId: string) =>
  window.localStorage?.setItem(`${DidPersistKey}-${userId}`, 'true');

export const dualLocalePreferencePersisted = (userId: string) =>
  'true' === window.localStorage?.getItem(`${DidPersistKey}-${userId}`);

const languageExists = (language: string | null): language is Language =>
  typeof language === 'string';
