// eslint-disable-next-line no-restricted-imports
import { path, pathOr, contains, propOr } from 'ramda';
import type { ExtLinkToHref } from '@peloton/external-links';
import type { Locale } from '@peloton/internationalize';
import { toLanguageFromLocale } from '@peloton/internationalize';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { titleize } from '@peloton/text';
import { isDefined } from '@peloton/types';
import type { ClassDetailSelectorState } from '@engage/class-detail/selectors';
import { getClassDetail } from '@engage/class-detail/selectors';
import type { FeatureToggleState } from '@engage/feature-toggles';
import type {
  ClassType,
  Equipment,
  FitnessDiscipline,
  Instructor,
} from '@engage/metadata';
import {
  getClassType,
  getFitnessDiscipline,
  getInstructor,
  getEquipment,
  getLocale,
} from '@engage/metadata';
import type { Song } from '@engage/video-ui';
import type { ClassAnalyticsProperties } from './analytics';
import type {
  Class,
  DenormalizedClass,
  DenormalizedProperties,
  InstructorAndImage,
  ClassPreview,
  InstructorProps,
} from './models';
import { isAired, isLibrary, isLive, isAudioClass, Metrics } from './models';
import type { ClassSelectorState } from './redux';
import { getClass } from './redux';
import { toMovementsClassInfo } from './toMovements';

export type DenormalizedClassState = ClassSelectorState & FetcherSelectorState;

/**
 * This requires metadata mappings to be loaded. See `getDenormalizedClass`.
 */
export const getClassAnalyticsProperties = (
  state: ClassSelectorState &
    FetcherSelectorState &
    ClassDetailSelectorState &
    FeatureToggleState,
  classId: string,
): ClassAnalyticsProperties | undefined => {
  const klass = getDenormalizedClass(state, classId);
  const classTypes = pathOr<string[]>([], ['classTypeIds'], klass)
    .map((id: string) => getClassType(state, id))
    .map((k: ClassType) => k?.name)
    .join(', ');
  const originLocale: Locale = propOr(undefined, 'originLocale', klass);
  const language = getLocale(state, originLocale);
  const classDetails = getClassDetail(state);
  return klass
    ? {
        id: klass.id,
        title: klass.title,
        scheduledStartTime: isAired(klass) ? klass.scheduledStartTime : undefined,
        duration: klass.duration,
        fitnessDiscipline: klass.fitnessDiscipline?.name,
        instructorName: klass?.instructor?.name,
        classType: classTypes,
        contentFormat: titleize(klass.contentFormat),
        contentProvider: isDefined(klass.contentProvider)
          ? titleize(klass.contentProvider)
          : undefined,
        totalWorkouts: isLibrary(klass) ? klass.totalWorkouts : undefined,
        totalUserWorkouts: isLibrary(klass) ? klass.totalUserWorkouts : undefined,
        totalFollowingWorkouts: isLibrary(klass)
          ? klass.totalFollowingWorkouts
          : undefined,
        difficultyLevel:
          isAired(klass) && isDefined(klass.difficultyLevel)
            ? titleize(klass.difficultyLevel)
            : undefined,
        nativeLanguage: isAired(klass) && language ? language : undefined,
        captionLanguages: klass.captionLocales.map(toLanguageFromLocale),
        classIntroLength: toClassIntroLength(klass.activityStartOffset),
        ...toMovementsClassInfo(classDetails),
        classExplicitRating: klass.explicitRating,
        explicitPercentage: toExplicitPercentage(classDetails?.playlist?.songs ?? []),
      }
    : undefined;
};

const toClassIntroLength = (offset: number) => (offset > 0 ? offset : null);

const toExplicitPercentage = (songs: Song[]) =>
  Math.round(
    (songs.reduce((acc, song) => acc + (song.explicitRating ?? 0), 0) / songs.length) *
      100,
  );
/**
 * This requires metadata mappings to be loaded, so ensure `cacheMetadataSaga` is being used.
 */
export const getDenormalizedClass = <T extends Class = Class>(
  state: DenormalizedClassState,
  classId: string,
  isClassType?: (klass: Class) => klass is T,
): DenormalizedClass<T> | undefined => {
  const klass = getClass(state, classId);
  const instructor =
    klass && isAired(klass)
      ? getInstructor(state, klass.instructorId)
      : ({} as Instructor) ?? ({} as Instructor);
  const fitnessDiscipline =
    getFitnessDiscipline(state, klass && klass.fitnessDisciplineSlug) ??
    ({} as FitnessDiscipline);
  const isLiveClass = klass && isLive(klass);
  const equipment =
    getEquipment(state, klass && klass.equipmentIds) ?? ([] as Equipment[]);
  const userCaptionLocales = klass?.userCaptionLocales;
  if (klass && fitnessDiscipline && (!isClassType || isClassType(klass))) {
    return Object.assign<DenormalizedProperties, T>(
      {
        instructor,
        fitnessDiscipline,
        isLiveClass,
        equipment,
        userCaptionLocales,
      },
      klass as T,
    );
  } else {
    return undefined;
  }
};

export const toInstructorInfo = (
  toHref: ExtLinkToHref,
  klass: DenormalizedClass,
): InstructorProps => ({
  instructorName: klass?.instructor?.name,
  imageUrl:
    klass.instructor && klass.instructor.imageUrl
      ? klass.instructor.imageUrl
      : klass.imageUrl,
  instructorLinkUrl:
    klass.instructor && klass.instructor.isVisible
      ? toHref(klass.instructor.linkUrl)
      : undefined,
});

export const toClassPreview = (klass: DenormalizedClass): ClassPreview => {
  return {
    ...toInstructorNameAndImage(klass),
    id: klass.id,
    classImageUrl: klass.imageUrl,
    fitnessDiscipline: klass?.fitnessDiscipline?.name,
    title: klass.title,
    classStartTime: isAired(klass) ? klass.scheduledStartTime : undefined,
    isLiveClass: klass.isLiveClass,
    isAudioOnly: isAudioClass(klass.contentFormat),
    supportsCalories: contains(Metrics.Calories, klass.supportedMetrics),
    originLocale: klass.originLocale,
  };
};

export const toInstructorNameAndImage = (
  klass: DenormalizedClass,
): InstructorAndImage => ({
  instructorOrScenic: path(['instructor', 'name'], klass) ?? 'Scenic',
  imageUrl:
    klass.instructor && klass.instructor.imageUrl
      ? klass.instructor.imageUrl
      : klass.imageUrl,
});

export const toMaybeInstructorName = (klass: DenormalizedClass): string | undefined =>
  path(['instructor', 'name'], klass);
