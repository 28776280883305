import { identity as noop } from 'ramda';
import React, { useEffect, useRef } from 'react';
import { compose } from 'recompose';
import styled, { keyframes, css } from 'styled-components';
import type { PropsOf } from '@peloton/react';
import { media, ie11 } from '@peloton/styles';
import { paleYellow, slate3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label5Large, label4Large } from '@engage/typography';
import { Button2Small } from '@members/buttons';
import { useCta, Cta } from '@members/copy';
import { StyledCross, CloseEnhancer } from './shared';

const SlideOut = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(400%);
  }
`;

const SlideIn = keyframes`
  0% {
    transform: translateY(300%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const animationDurationInSeconds = 1.35;
const slideInAnimationInSeconds = 0.5;

const Container = styled.div<Closed>`
  width: 100%;
  min-height: 60px;
  background-color: ${paleYellow};
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 35px;
  z-index: 10;

  ${({ isClosed }) =>
    isClosed
      ? css`
          animation: ${SlideOut} ${animationDurationInSeconds}s ease both;
        `
      : css`
          animation: ${SlideIn} ${slideInAnimationInSeconds}s ease both;
        `}

  ${media.tablet`
    padding: 15px;
  `}

  ${media.desktop`
    padding: 15px 30px;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  color: ${slate3};
  ${label5Large};
  padding-bottom: 5px;
  ${media.tablet`
    padding-bottom: 0;
    flex-direction: row;
    ${label4Large};
  `}

  ${ie11`
    &, & > p {
      width: 100%;
    }
  `}
`;

const ClickableArea = styled.button`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 15px;
  margin: -15px;

  ${media.tablet`
    position: initial;
  `}
`;

const CTAButton = styled(Button2Small)`
  white-space: nowrap;
  min-width: initial;
  margin: 10px 0 0;
  ${media.tablet`
    margin: 0 ${spaces.large}px 0 ${spaces.large}px;
  `}
`;

const BannerView: React.FC<React.PropsWithChildren<Props>> = props => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.tracker) {
      props.tracker();
    }
  }, []);

  return (
    <Container isClosed={props.isClosed} ref={containerRef}>
      {/* eslint-disable-next-line styled-components-a11y/no-noninteractive-tabindex */}
      <ContentContainer tabIndex={0}>
        <p role="alert" data-test-id="lapsedTrialText">
          {props.message}
        </p>
        {props.ctaText ? (
          <CTAButton onClick={props.ctaAction ?? noop}>{props.ctaText}</CTAButton>
        ) : null}
      </ContentContainer>
      <ClickableArea
        aria-label={useCta(Cta.CloseBanner)}
        onClick={() => {
          if (props.afterClose && containerRef.current) {
            containerRef.current.addEventListener('animationend', props.afterClose);
          }
          props.didClose(true);
          if (props.closeHandler) {
            props.closeHandler();
          }
        }}
      >
        <StyledCross />
      </ClickableArea>
    </Container>
  );
};

type OuterProps = {
  message: string | JSX.Element;
  ctaText?: string;
  tracker?(): void;
  ctaAction?(): void;
  closeHandler?(): void;
  afterClose?(): void;
};

type Props = OuterProps & PropsOf<typeof CloseEnhancer>;

type Closed = Pick<Props, 'isClosed'>;

export default compose<Props, OuterProps>(CloseEnhancer)(BannerView);
