import { browserProvider } from '@peloton/newrelic/browser-provider';
import { toNowTime } from '@peloton/time';
import type { ContractAgreementUpdate } from '@engage/api-v2';
import { updateContractAgreements as updateContractService } from '@engage/api-v2';
import { logError } from '@engage/error-reporting/logError';
import type { RequiredMemberAction } from '@engage/members/models/Member';
import { engageApi } from '@members/data-fetch';

const toContractAgreements = (
  contractIds: string[],
  currentTime: number,
): ContractAgreementUpdate[] =>
  contractIds.map(contractId => ({ contractId, agreedAt: currentTime }));

const updateContractAgreements = async (
  memberId: string,
  contractIds: string[],
  modalDisplayed:
    | Extract<RequiredMemberAction, 'acceptance' | 'acknowledge_and_acceptance'>
    | undefined,
) => {
  const actionReporter = browserProvider.getActionReporter();
  const currentTime = toNowTime().unix();
  const contractAgreements = toContractAgreements(contractIds, currentTime);

  try {
    await updateContractService(engageApi, memberId, {
      contractAgreements,
      ...(modalDisplayed && { modalDisplayed }),
    });
    actionReporter('Agreed to Terms Successfully', { memberId });
  } catch (e) {
    actionReporter('Agreed to Terms Failed', { memberId, error: e.message });
    logError(e, 'updateContractAgreements');
  }
};

export default updateContractAgreements;
