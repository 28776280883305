import { reduce, mergeWith, mergeDeepRight } from 'ramda';
import type { Reducer } from 'react';
import type { VariableType, VariableResult, VariableQuery } from '@members/optimizely';
import type { VariableConfig, Variables } from '../models';
import type { Action } from './actions';
import { ActionType } from './actions';

export type State = Variables;
export const DEFAULT_VALUE: State = {};

export const toVariableReducer = (
  variables: VariableConfig,
  mergeStrategy = mergeVariables,
): Reducer<State, Action> => (state = toDefaults(variables), action) => {
  switch (action.type) {
    case ActionType.MergeVariables:
      return mergeStrategy(state, action.payload);
    case ActionType.SetVariables:
      return setVariable(state, action.payload);
    default:
      return state;
  }
};

// utils

const toDefaults = (variables: VariableConfig) =>
  Object.keys(variables).reduce((state, feature) => {
    state[feature] = variables[feature].reduce((vars, query) => {
      const originalValue = query.defaultValue ?? null;
      vars[query.key] = {
        defaultValue: originalValue,
        originalValue,
        value: originalValue,
      };
      return vars;
    }, {});

    return state;
  }, DEFAULT_VALUE);

const mergeVariablesForFeature = reduce(
  (acc: State[string], { key, value }: VariableResult<VariableQuery<VariableType>>) => {
    const originalValue = value ?? acc[key].value;
    acc[key] = {
      defaultValue: acc[key].defaultValue,
      originalValue,
      value: originalValue,
    };
    return acc;
  },
);

export const mergeVariables = (
  state: State,
  result: Extract<Action, { type: ActionType.MergeVariables }>['payload'],
) => mergeWith(mergeVariablesForFeature, state, result);

const setVariable = (
  state: State,
  { toggle, key, value }: Extract<Action, { type: ActionType.SetVariables }>['payload'],
) => mergeDeepRight(state, { [toggle]: { [key]: { value } } });
