import { uniq } from 'ramda';
import type { MovementMuscleGroup, SubsegmentMovement } from '@engage/api-v2';
import type { ClassDetail } from '@engage/class-detail/models';

type MovementsAnalytics = {
  classMovements?: string[];
  classBodyFocus?: MovementMuscleGroup[];
  classMovementGroups?: string[];
};

export const toMovementsAnalytics = (movementsInfo: MovementsAnalytics) => ({
  classMovements: movementsInfo.classMovements,
  classBodyFocus: movementsInfo.classBodyFocus,
  classMovementGroups: movementsInfo.classMovementGroups,
});

export const toMovementsClassInfo = (classDetail: ClassDetail | undefined) => {
  if (!classDetail)
    return { classMovements: [], classBodyFocus: [], classMovementGroups: [] };
  return {
    classMovements: uniq(
      classDetail.segments
        ?.map(segment => {
          return (
            segment.subsegmentsV2?.map(subsegment => {
              return (
                subsegment.movements?.map((movement: SubsegmentMovement) => {
                  return movement.name ?? '';
                }) ?? []
              );
            }) ?? []
          );
        })
        .flat(3) ?? [],
    ),
    classBodyFocus: uniq(
      classDetail.segments
        ?.map(segment => {
          return (
            segment.subsegmentsV2?.map(subsegment => {
              return (
                (subsegment.movements?.map(subsegmentMovement => {
                  return (
                    subsegmentMovement.muscleGroups?.map(
                      (muscleGroup: MovementMuscleGroup) => {
                        return muscleGroup?.displayName ?? '';
                      },
                    ) ?? []
                  );
                }) as MovementMuscleGroup[]) ?? []
              );
            }) ?? []
          );
        })
        .flat(4) ?? [],
    ),
    classMovementGroups: uniq(
      classDetail.segments?.flatMap(segment => {
        return (
          segment.subsegmentsV2
            ?.filter(s => s.type != 'movement' && s.type != 'rest')
            .map(subsegment => {
              return subsegment?.displayName ?? '';
            }) ?? []
        );
      }) ?? [],
    ),
  };
};
