import type { Unit } from '../models';

export enum ApiSlug {
  HeartRate = 'heart_rate',
}

export enum ApiMetricsType {
  Cycling = 'cycling',
  Running = 'running',
  Rowing = 'caesar',
}

export type ApiZone = {
  displayName: string;
  duration: number;
  range: string;
  slug: string;
  minValue: number;
  maxValue: number;
};

export type ApiWorkoutMetric = {
  alternatives?: ApiWorkoutMetric[];
  averageValue?: number;
  displayName: string;
  displayUnit: Unit;
  maxValue?: number;
  missingDataDuration?: number;
  slug: ApiSlug | string;
  values: number[];
  zones?: ApiZone[];
  distance: number;
};

export type ApiSegment = {
  displayName: string;
  iconUrl: string;
  startTimeOffset: number;
  length: number;
  metricsType: ApiMetricsType | string;
  isDrill: boolean;
};

export type ApiWorkoutPerformance = {
  averageSummaries: ApiWorkoutSummary[];
  duration: number;
  metrics: ApiWorkoutMetric[];
  secondsSincePedalingStart: number[];
  summaries: ApiWorkoutSummary[];
  segmentList: ApiSegment[];
  /*
   * isLocationDataAccurate:
   * If true, the workout has location data and it is accurate.
   * If false, the workout has location data and it is inaccurate.
   * If null, the workout has no location data.
   * If the field does not exist, there may be location data, but the current user does not have permission to view it.
   */
  isLocationDataAccurate?: boolean | null;
  splitsData: [] | ApiSplits;
  locationData?: ApiLocationSeries[] | undefined;
  effortZones: ApiEffortZones | null;
};

export type ApiEffortZones = {
  totalEffortPoints: number;
  heartRateZoneDurations: {
    heartRateZ1Duration: number;
    heartRateZ2Duration: number;
    heartRateZ3Duration: number;
    heartRateZ4Duration: number;
    heartRateZ5Duration: number;
  };
};

type ApiLocationSeries = {
  coordinates: LocationCoordinate[];
  duration: number;
  isGap: boolean;
  segmentId: string;
};

type LocationCoordinate = {
  accuracy: number;
  distanceDisplayUnit: string;
  distance: number;
  latitude: number;
  longitude: number;
  secondsOffsetFromStart: number;
};

export type ApiWorkoutSummary = {
  displayName: string;
  displayUnit: Unit;
  value: number | null;
};

export type ApiSplit = {
  distanceMarker: number;
  elevationChange: number;
  isBest: boolean;
  order: number;
  seconds: number;
};

export type ApiSplits = {
  distanceMarkerDisplayUnit: string;
  elevationChangeDisplayUnit: string;
  splits: ApiSplit[];
};
