import React from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { usePrevious } from '@peloton/react';
import { TransitionState } from '@engage/animations';
import useComponentsWithTransition from './useComponentsWithTransition';

/**
 * Takes children and facillitates animations between them
 * the last appearing child in nthe array will be the visible "slide"
 * @example
 *  <SlideoverStackTransition>
 *    {elements.map((element, idx) => (
 *      <div key={idx}>
 *        <p>adding additional elements to this array will slide them onto this stack</p>
 *        <p>removing them will slide them off</p>
 *        {element}
 *      </div>
 *    )}
 *  </SlideoverStackTransition>
 */
export const SlideoverStackTransition: React.FC<{
  children: ((isEntered: boolean) => React.ReactNode)[];
}> = ({ children }) => {
  const [renderables, activeIndex] = useComponentsWithTransition(
    children,
    ANIMATION_DURATION_MS,
  );
  const [transitionMap, dispatch] = React.useReducer(transitionMapReducer, {});

  return (
    <>
      {renderables.map((child, i) => (
        <IndividualSlide
          activeIndex={activeIndex}
          myIndex={i}
          key={i}
          transitionMap={transitionMap}
          dispatch={dispatch}
        >
          {isEntered => child(isEntered)}
        </IndividualSlide>
      ))}
    </>
  );
};

const ANIMATION_DURATION_MS = 300;

type Dispatcher = React.Dispatch<{
  payload: {
    index: number;
    transitionState: TransitionState;
  };
}>;

const StateObserver: React.FC<
  React.PropsWithChildren<{
    state: TransitionState;
    dispatch: Dispatcher;
    index: number;
  }>
> = ({ state, index, dispatch }) => {
  React.useEffect(() => {
    dispatch(updateTransitionMap(index, state));
  }, [state]);

  return null;
};

const isPresent = <T extends any>(val: T | undefined): val is T => Boolean(val);

function useTransitionEventStatus(
  slideOverContainerRef: React.RefObject<HTMLDivElement>,
) {
  const [hasTransitioned, setHasTransitioned] = React.useState(false);

  React.useEffect(() => {
    const cb = (e: TransitionEvent) => {
      if (e.target === slideOverContainerRef.current && e.propertyName === 'transform') {
        setHasTransitioned(true);
      }
    };
    slideOverContainerRef.current?.addEventListener('transitionend', cb, true);
    return () => {
      slideOverContainerRef.current?.removeEventListener('transitionend', cb, true);
    };
  }, [Boolean(slideOverContainerRef.current)]);

  return hasTransitioned;
}

const IndividualSlide: React.FC<{
  children: (isEntered: boolean) => React.ReactNode;
  transitionMap: Record<number, TransitionState>;
  dispatch: Dispatcher;
  myIndex: number;
  activeIndex: number;
}> = ({ myIndex, activeIndex, children, transitionMap, dispatch }) => {
  const [isActive, setIsActive] = React.useState(myIndex === activeIndex);
  const mainContentEl = React.useRef<HTMLDivElement>(null);
  const previousActiveIndex = usePrevious(activeIndex);
  const [isReversing, setIsReversing] = React.useState(false);
  const wasActive = myIndex < activeIndex;

  React.useEffect(() => {
    const hasBecomeActive = myIndex === activeIndex;
    setIsActive(hasBecomeActive);
    if (isPresent(previousActiveIndex)) {
      setIsReversing(previousActiveIndex > activeIndex && hasBecomeActive);
    }
  }, [myIndex, activeIndex]);

  const nextCardState = transitionMap[myIndex + 1];
  const isIn = isActive || nextCardState === TransitionState.Entering;
  const minHeight = mainContentEl.current?.scrollHeight ?? null;

  const slideOverContainerRef = React.useRef<HTMLDivElement>(null);
  const hasTransitioned = useTransitionEventStatus(slideOverContainerRef);

  return (
    <Transition in={isIn} timeout={ANIMATION_DURATION_MS}>
      {(state: TransitionState) => (
        <MainContainer ref={mainContentEl}>
          <StateObserver index={myIndex} state={state} dispatch={dispatch} />
          <SlideoverContainer
            isIn={isIn}
            shouldAnimate={!isReversing}
            wasActive={wasActive}
            ref={slideOverContainerRef}
          >
            {!isIn && state !== TransitionState.Exiting ? (
              <PhantomContent minHeight={minHeight} />
            ) : (
              children(hasTransitioned && state === TransitionState.Entered)
            )}
          </SlideoverContainer>
        </MainContainer>
      )}
    </Transition>
  );
};

const updateTransitionMap = (index: number, transitionState: TransitionState) => ({
  payload: {
    index,
    transitionState,
  },
});

const transitionMapReducer: React.Reducer<
  Record<number, TransitionState>,
  ReturnType<typeof updateTransitionMap>
> = (state, action) => ({
  ...state,
  [action.payload.index]: action.payload.transitionState,
});

const PhantomContent = styled.div<{ minHeight: number | null }>`
  height: ${p => `${p.minHeight}px`};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SlideoverContainer = styled.div<{
  isIn: boolean;
  shouldAnimate: boolean;
  wasActive: boolean;
}>`
  background-color: whitesmoke;
  height: 100%;
  position: absolute;
  transition: transform ${p => (p.shouldAnimate ? ANIMATION_DURATION_MS : 0)}ms ease,
    filter ${ANIMATION_DURATION_MS}ms ease-in;
  transform: translate(${p => (p.isIn ? 0 : 100)}%);
  filter: brightness(${p => (p.wasActive ? 60 : 100)}%);
  width: 100%;
  top: 0;
  left: 0;
`;
