import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeEvery, select, all } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { isGuest, getUser } from '@peloton/auth';
import { redirectToNotFound } from '@peloton/navigation';
import { track } from '@engage/analytics';
import type { ClassAnalyticsProperties } from '@engage/classes';
import {
  loadClassSuccess,
  loadClassFailure,
  getClassAnalyticsProperties,
} from '@engage/classes';
import { logError } from '@engage/error-reporting/logError';
import { getFilterAnalyticsProps } from '@engage/filters';
import type { Member } from '@engage/members/models/Member';
import { membersListSuccess } from '@engage/members/redux';
import { cacheMetadataSaga } from '@engage/metadata';
import { trackViewedClassDetails } from './analytics';
import type { ClassDetailResponse } from './api';
import { fetchClassDetail, fetchFriendsWhoHaveTakenClass } from './api';
import type { openClassDetail } from './redux';
import {
  loadClassDetailSuccess,
  ClassDetailActionType,
  loadFriendsSuccess,
} from './redux';
import { getClassDetail } from './selectors';

export const loadClassDetailSaga = function* (classId: string): unknown {
  const client: Client = yield getContext(CLIENT_CONTEXT);
  try {
    const [classDetail, friendsWhoHaveTakenClass]: [
      ClassDetailResponse,
      Member[],
    ] = yield all([
      call(fetchClassDetail, client, classId),
      loadFriendsWhoHaveTakenClass(classId),
    ]);

    yield call(cacheMetadataSaga);
    yield put(loadClassSuccess(classDetail.class));
    yield put(
      loadClassDetailSuccess({
        ...classDetail.detail,
      }),
    );

    const user = yield select(getUser);

    if (!isGuest(user)) {
      yield put(loadFriendsSuccess(friendsWhoHaveTakenClass));
      yield put(membersListSuccess(friendsWhoHaveTakenClass));
    }
  } catch (e) {
    if (e.response?.status === 403) {
      yield put(redirectToNotFound());
    }
    logError(e, 'loadClassDetailSaga');
    yield put(loadClassFailure(classId, e));
  }
};

const loadFriendsWhoHaveTakenClass = function* (classId: string): SagaIterator {
  const client: Client = yield getContext(CLIENT_CONTEXT);

  try {
    const user = yield select(getUser);

    if (!isGuest(user)) {
      return yield call(fetchFriendsWhoHaveTakenClass, client, classId);
    }

    return [];
  } catch (e) {
    if (e.response?.status === 403) {
      yield put(redirectToNotFound());
    }
    logError(e, 'loadFriendsWhoHaveTakenClass');
    return [];
  }
};

const loadClassDetailByAction = function* (action: ReturnType<typeof openClassDetail>) {
  yield call(loadClassDetailSaga, action.payload.id);
};

const classDetailSaga = function* () {
  yield takeEvery(ClassDetailActionType.Open, loadClassDetailByAction);
};

export const trackViewedClassDetailSaga = function* (
  classId: string,
  source: string,
  isLive: boolean,
): SagaIterator {
  const classAnalyticsProps: ClassAnalyticsProperties | undefined = yield select(
    getClassAnalyticsProperties,
    classId,
  );
  const classDetails = yield select(getClassDetail);
  const browseInfo = yield select(getFilterAnalyticsProps);
  const hasPlaylist =
    classDetails && classDetails.playlist && classDetails.playlist.isPlaylistShown;
  yield put(
    track(
      trackViewedClassDetails(
        classAnalyticsProps,
        source,
        isLive,
        hasPlaylist,
        browseInfo,
      ),
    ),
  );
};

export default classDetailSaga;
