import { curry, propOr, toString, compose } from 'ramda';
import type { ClientDetails, ScreenProps } from '@peloton/analytics';
import { toClientDetailsHeader } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig, isNetworkError } from '@peloton/api';
import type { MusicPlatformInfo } from '@engage/api-v2';
import type {
  Member,
  MemberInfo,
  MemberRelationship,
  ContractAgreement,
} from './models/Member';
import {
  ME,
  MemberListGroup,
  RelationshipCategory,
  RelationshipChange,
  toMemberListGroup,
} from './models/Member';

const toProfileUrl = (id: string) => (id === ME ? `api/me` : `api/user/${id}`);

export const fetchProfile = curry(
  (api: Client, id: string): Promise<Member> =>
    api.get(toProfileUrl(id)).then(pipeData(toMemberFromProfile)),
);

export enum ErrorMessage {
  UsernameTaken = 'That username is taken',
  UsernameInvalid = 'Username invalid',
  Unknown = 'Unkown error occurred.',
  NetworkError = 'No internet connection. Please reconnect and try again.',
}

const subcodeMap = {
  10: ErrorMessage.UsernameTaken,
  11: ErrorMessage.UsernameInvalid,
};

const toErrorMessage = (err: any) => {
  if (isNetworkError(err)) {
    return ErrorMessage.NetworkError;
  }
  const response = err.responseBody || err.response.data;
  if (response.errorCode === 152) {
    return ErrorMessage.UsernameInvalid;
  }
  if (response.errorCode === 150) {
    return propOr<string, typeof subcodeMap, string>(
      ErrorMessage.Unknown,
      toString(response.subcode),
      subcodeMap,
    );
  }
  return ErrorMessage.Unknown;
};

const throwError = (errorMessage: string) => {
  const e = new Error();
  e.message = errorMessage;
  throw e;
};

export const updateMember = (
  api: Client,
  { id, ...member }: Partial<MemberInfo> & Pick<MemberInfo, 'id'>,
) =>
  api
    .put(toProfileUrl(id), { ...member }, toSkipErrorHandlingConfig())
    .then(pipeData(toMemberFromProfile))
    .catch(compose(throwError, toErrorMessage));

export const updateRelationship = (
  api: Client,
  otherUserId: string,
  change: RelationshipChange,
  analyticsProps: ClientDetails<ScreenProps>,
): Promise<MemberRelationship> =>
  api
    .post(
      '/api/user/change_relationship',
      { userId: otherUserId, action: toRelationshipChangeRequest(change) },
      toClientDetailsHeader(analyticsProps),
    )
    .then(pipeData(f => f));

export const toRelationshipChangeRequest = (
  action: RelationshipChange,
): RelationshipChangeRequest => {
  switch (action) {
    case RelationshipChange.Follow:
    case RelationshipChange.Request:
      return RelationshipChangeRequest.Follow;
    case RelationshipChange.Remove:
    case RelationshipChange.Reject:
      return RelationshipChangeRequest.Remove;
    case RelationshipChange.Approve:
      return RelationshipChangeRequest.Approve;
    default:
      // Cancel, Unfollow
      return RelationshipChangeRequest.Unfollow;
  }
};

export enum RelationshipChangeRequest {
  Follow = 'follow',
  Unfollow = 'unfollow',
  Approve = 'approve',
  Remove = 'remove',
}

export const toMember = ({ category, ...apiMember }: ApiMember): Member => ({
  listGroup: toMemberListGroup(category),
  ...apiMember,
  relationship: toMemberRelationshipFromMember({ category, ...apiMember }),
});

export const toMembersList = (response: ApiMembersResponse): Member[] =>
  response.data.map(toMember);

export const toMemberFromProfile = (apiProfile: ApiProfile): Member => ({
  id: apiProfile.id,
  username: apiProfile.username,
  location: apiProfile.location,
  imageUrl: apiProfile.imageUrl,
  isProfilePrivate: apiProfile.isProfilePrivate,
  totalFollowers: apiProfile.totalFollowers,
  totalFollowing: apiProfile.totalFollowing,
  totalWorkouts: apiProfile.totalWorkouts,
  listGroup: toMemberListGroupFromProfile(apiProfile),
  relationship: toMemberRelationship(apiProfile),
  ...(isApiMe(apiProfile) ? { contractAgreements: apiProfile.contractAgreements } : {}),
  externalMusicAuthList: apiProfile.externalMusicAuthList,
  defaultHeartRateZones: apiProfile.defaultHeartRateZones,
  defaultMaxHeartRate: apiProfile.defaultMaxHeartRate,
  customizedHeartRateZones: apiProfile.customizedHeartRateZones,
  customizedMaxHeartRate: apiProfile.customizedMaxHeartRate,
  corporateWellnessGroup: apiProfile.corporateWellnessGroup,
  isEmailVerified: apiProfile.isEmailVerified,
  isFitbitAuthenticated: apiProfile.isFitbitAuthenticated,
  isStravaAuthenticated: apiProfile.isStravaAuthenticated,
  hasActiveDeviceSubscription: apiProfile.hasActiveDeviceSubscription,
  hasActiveDigitalSubscription: apiProfile.hasActiveDigitalSubscription,
});

export const toMemberListGroupFromProfile = (apiProfile: ApiProfile): MemberListGroup =>
  isApiMe(apiProfile)
    ? MemberListGroup.Self
    : apiProfile.authedUserFollows
    ? MemberListGroup.Following
    : MemberListGroup.Other;

const toMemberRelationshipFromMember = (apiMember: ApiMember): MemberRelationship =>
  apiMember.category === 'self'
    ? { meToUser: RelationshipCategory.Self }
    : apiMember.relationship;

const toMemberRelationship = (apiProfile: ApiProfile): MemberRelationship =>
  isApiMe(apiProfile)
    ? { meToUser: RelationshipCategory.Self }
    : apiProfile.relationship ?? { meToUser: RelationshipCategory.None };

export const isApiMe = (apiProfile: ApiProfile): apiProfile is ApiUserProfile =>
  apiProfile.hasOwnProperty('email');

type ApiProfile = ApiMemberProfile | ApiUserProfile;

type ApiUserProfile = MemberInfo & {
  externalMusicAuthList?: MusicPlatformInfo[];
  contractAgreements: ContractAgreement[];
  email?: string;
  defaultHeartRateZones?: number[];
  defaultMaxHeartRate?: number;
  customizedHeartRateZones?: number[];
  customizedMaxHeartRate?: number;
  isEmailVerified?: boolean;
  isStravaAuthenticated?: boolean;
  isFitbitAuthenticated?: boolean;
};

export type ApiMemberProfile = MemberInfo & {
  externalMusicAuthList?: MusicPlatformInfo[];
  authedUserFollows?: boolean;
  relationship: MemberRelationship;
  defaultHeartRateZones?: number[];
  defaultMaxHeartRate?: number;
  customizedHeartRateZones?: number[];
  customizedMaxHeartRate?: number;
  isEmailVerified?: boolean;
  isStravaAuthenticated?: boolean;
  isFitbitAuthenticated?: boolean;
};

export type ApiMember = MemberInfo & {
  category: string;
  relationship: MemberRelationship;
};

export type ApiMembersResponse = {
  data: ApiMember[];
};
