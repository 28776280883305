import type { ApolloError } from 'apollo-client';
import type { Emitter } from 'mitt';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { isDefined } from '@peloton/types';
import { track } from '@engage/analytics';
import { gray3, slate3, white } from '@engage/colors';
import { useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { body16 } from '@engage/typography';
import { useTags, Tags } from '@members/copy';
import { trackSearchedTags } from '../analytics';
import { useLastTagSearch } from '../hooks';
import { maskInput } from './helpers';
import { useDebounce } from './useDebounce';

const MAX_LENGTH = 20;

const InputField: React.FC<
  React.PropsWithChildren<{
    error?: ApolloError;
    setShowError: (showError: boolean) => void;
    clearEmitter: Emitter;
    isEntered: boolean;
    isTyping: boolean;
    lastSearch: string;
    setInputIsEmpty(InputIsEmpty: boolean): void;
    onChange(value: string): void;
    setIsTyping(isTyping: boolean): void;
  }>
> = ({
  onChange,
  clearEmitter,
  error,
  setShowError,
  setIsTyping,
  setInputIsEmpty,
  lastSearch = '',
  isEntered,
}) => {
  const [inputValue, setInputValue] = useState(lastSearch);

  const debouncedSearchTerm = useDebounce(inputValue, 500);
  const { setLastTagSearch } = useLastTagSearch();
  const trackAction = useReduxAction(track);

  React.useEffect(() => {
    setInputIsEmpty(inputValue.length === 0);
  }, [inputValue]);

  React.useEffect(() => {
    const cb = () => {
      setIsTyping(false);
      setInputValue('');
      setLastTagSearch('');
    };

    clearEmitter.on('clear', cb);

    return () => {
      clearEmitter.off('clear', cb);
    };
  }, []);

  const handleInputChange = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setIsTyping(true);

    const maskedVal = maskInput(e.currentTarget.value);

    if (maskedVal.length === 0) {
      clearEmitter.emit('clear');
    } else if (maskedVal.length > MAX_LENGTH) {
      setInputValue(maskedVal.slice(0, MAX_LENGTH));
    } else {
      setInputValue(maskedVal);
    }
    setIsTyping(false);
  }, []);

  React.useEffect(() => {
    if (isDefined(error)) {
      setShowError(false);
    }
    onChange(debouncedSearchTerm);
    trackAction(trackSearchedTags(debouncedSearchTerm));
  }, [debouncedSearchTerm]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEntered && Boolean(inputRef.current) && inputValue.length === 0) {
      inputRef.current?.focus();
    }
  }, [isEntered, Boolean(inputRef.current)]);

  return (
    <Input
      type="text"
      ref={inputRef}
      value={inputValue}
      onChange={handleInputChange}
      aria-label={useTags(Tags.FindATag)}
      data-test-id="inputField"
    />
  );
};

export { InputField };

const Input = styled.input`
  ${body16}
  color: ${slate3};
  background-color: ${white};
  border: none;
  outline: none;
  width: 100%;
  border-radius: 5px;
  caret-color: ${gray3};
  height: ${spaces.xxHuge}px;
  background: ${white};
  border: 1px solid ${gray3};
  padding: ${spaces.medium}px ${spaces.huge}px;

  &::-ms-clear {
    // IE clear button
    display: none;
    width: 0;
    height: 0;
  }
`;
