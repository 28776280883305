import type { UserSelectorState } from '@peloton/auth';
import { getSignedInUsername } from '@peloton/auth';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import type { ClassDetailSelectorState } from '@engage/class-detail';
import type { ClassSelectorState } from '@engage/classes';
import { getClassAnalyticsProperties, isLive } from '@engage/classes';
import type { VideoSelectorState } from '@engage/video';
import { getClassId, getSeekTime, getStartTime } from '@engage/video';
import type { WorkoutSelectorState } from '@engage/workouts';
import type { FeatureToggleState } from '@members/feature-toggles/redux';
import { maybeClass } from './shared';

type VideoAnalyticsPropertiesState = VideoSelectorState &
  ClassSelectorState &
  FetcherSelectorState &
  WorkoutSelectorState;

export type VideoStateAnalyticsProperties = {
  currentTimecode: number;
  sessionStart: number;
  timePlaying: number;
  introTimePlaying: number;
  timeCasting: number;
  timeWithCaptions: number;
  timeWithAudio: number;
};

export type PersistedVideoProperties = {
  persistedTimecode: number;
  persistedStart: number;
};

export const getRefreshVideoAnalytics = (
  state: VideoSelectorState,
): PersistedVideoProperties => ({
  persistedTimecode: getSeekTime(state) ?? 0,
  persistedStart: getStartTime(state) ?? 0,
});

export const getAllRefreshAnalytics = (
  state: VideoAnalyticsPropertiesState & ClassDetailSelectorState & FeatureToggleState,
) => {
  const classId = getClassId(state) ?? '';

  return {
    classInfo: getClassAnalyticsProperties(state, classId),
    timeInfo: getRefreshVideoAnalytics(state),
  };
};

export const getPropertiesForYoubora = (
  state: Parameters<typeof maybeClass>[0] & UserSelectorState,
) => {
  const klass = maybeClass(state);
  return {
    username: getSignedInUsername(state),
    ...(klass
      ? {
          classId: klass.id,
          isLive: isLive(klass),
        }
      : {}),
  };
};
