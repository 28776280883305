import { last, clone, dropLast } from 'ramda';
import { isDefined } from '@peloton/types';
import type { MetricV2, MetricCollectorBatch, BatchesToSend } from './models';
import { MetricV2State } from './models';

// MAXIMUM_METRICS_LENGTH and MAXIMUM_BATCHES_LENGTH prescribed by API
// https://s3.amazonaws.com/swagger.dev.pelotoncycle.com/index.html#/Metrics/post_api_metrics_v2_video
export const MAXIMUM_METRICS_LENGTH = 30;
export const MAXIMUM_BATCHES_LENGTH = 20;

export const BUFFER_METRICS_NAMESPACE = 'bufferMetrics';

enum BufferMetricType {
  CreateMetric = '@engage/bufferMetrics/createMetric',
  FlushCurrentBatches = '@engage/bufferMetrics/flushCurrentBatches',
  FlushBatchesToSend = '@engage/bufferMetrics/flushBatchesToSend ',
  SetCurrentBatchAsFinal = '@engage/bufferMetrics/setCurrentBatchAsFinal',
  StartNewBufferMetrics = '@engage/bufferMetrics/startNewBufferMetrics',
  ClearBatchesToSend = '@engage/bufferMetrics/clearBatchesToSend',
}

export const createMetric = (
  metricState: MetricV2State,
  secondsSinceClassStart: number,
  timestamp: number,
) =>
  ({
    type: BufferMetricType.CreateMetric,
    payload: { metricState, secondsSinceClassStart, timestamp },
  } as const);

export const flushCurrentBatches = () =>
  ({
    type: BufferMetricType.FlushCurrentBatches,
  } as const);

export const flushBatchesToSend = (
  flushBatches: { workoutId: string; batchNumber: number }[],
) =>
  ({
    type: BufferMetricType.FlushBatchesToSend,
    payload: { flushBatches },
  } as const);

export const setCurrentBatchAsFinal = () =>
  ({
    type: BufferMetricType.SetCurrentBatchAsFinal,
  } as const);

export const startNewBufferMetrics = (workoutId: string) =>
  ({
    type: BufferMetricType.StartNewBufferMetrics,
    payload: { workoutId },
  } as const);

export const clearBatchesToSend = () =>
  ({
    type: BufferMetricType.ClearBatchesToSend,
  } as const);

export type BufferMetricAction =
  | ReturnType<typeof createMetric>
  | ReturnType<typeof flushCurrentBatches>
  | ReturnType<typeof flushBatchesToSend>
  | ReturnType<typeof setCurrentBatchAsFinal>
  | ReturnType<typeof startNewBufferMetrics>
  | ReturnType<typeof clearBatchesToSend>;

export type BufferMetricActionCreators =
  | typeof createMetric
  | typeof flushCurrentBatches
  | typeof flushBatchesToSend
  | typeof setCurrentBatchAsFinal
  | typeof startNewBufferMetrics
  | typeof clearBatchesToSend;

export const generateMetric = (
  state: MetricV2State,
  secondsSinceClassStart: number,
  timestamp: number,
): MetricV2 => ({
  state,
  secondsSinceClassStart,
  timestamp,
});

export const generateBatch = (
  batchNumber: number,
  metrics: MetricV2[] = [],
): MetricCollectorBatch => ({
  batchNumber,
  isFinal: false,
  metrics,
});

export type BufferMetricsState = {
  workoutId: string;
  currentBatches: MetricCollectorBatch[];
  batchesToSend: BatchesToSend[];
};

export const DEFAULT_BUFFER_METRICS_STATE: BufferMetricsState = Object.freeze({
  workoutId: '',
  currentBatches: [generateBatch(0)],
  batchesToSend: [],
});

export const bufferMetricsReducer = (
  state: BufferMetricsState = DEFAULT_BUFFER_METRICS_STATE,
  action: BufferMetricAction,
) => {
  switch (action.type) {
    case BufferMetricType.CreateMetric: {
      const { metricState, secondsSinceClassStart, timestamp } = action.payload;

      if (
        metricState !== MetricV2State.Else &&
        Number.isInteger(secondsSinceClassStart)
      ) {
        const metric = generateMetric(metricState, secondsSinceClassStart, timestamp);
        const currentBatch = clone(last(state.currentBatches)) ?? generateBatch(0);

        if (currentBatch.metrics.length >= MAXIMUM_METRICS_LENGTH) {
          const newBatch = generateBatch(currentBatch.batchNumber + 1, [metric]);

          return {
            ...state,
            currentBatches: state.currentBatches.concat(newBatch),
          };
        }

        currentBatch.metrics = currentBatch.metrics.concat(metric);
        const currentBatches = dropLast(1, state.currentBatches).concat(currentBatch);

        return {
          ...state,
          currentBatches,
        };
      }

      return state;
    }

    case BufferMetricType.FlushCurrentBatches: {
      const { batchesToSend, currentBatches, workoutId } = state;
      if (currentBatches.length > 0) {
        const lastBatch = last(currentBatches)!;
        const shouldSendLastBatch = lastBatch.metrics.length > 0;
        const lastBatchNumber = lastBatch.batchNumber;

        const batchesToMove = shouldSendLastBatch
          ? currentBatches
          : dropLast(1, currentBatches);

        const newBatchesToSend = batchesToSend.concat(
          batchesToMove.map(batch => ({ ...batch, workoutId })),
        );

        return {
          ...state,
          batchesToSend: newBatchesToSend,
          currentBatches: shouldSendLastBatch
            ? [generateBatch(lastBatchNumber + 1)]
            : [lastBatch],
        };
      }

      return state;
    }

    case BufferMetricType.FlushBatchesToSend: {
      const { batchesToSend } = state;
      const { flushBatches } = action.payload;
      const newBatchesToSend = batchesToSend.filter(
        ({ batchNumber, workoutId }) =>
          !isDefined(
            flushBatches.find(
              n => n.batchNumber === batchNumber && n.workoutId === workoutId,
            ),
          ),
      );

      return {
        ...state,
        batchesToSend: newBatchesToSend,
      };
    }

    case BufferMetricType.SetCurrentBatchAsFinal: {
      const { currentBatches } = state;
      if (currentBatches.length > 0) {
        const currentBatch = last(currentBatches)!;

        const newCurrentBatches = dropLast(1, currentBatches).concat({
          ...currentBatch,
          isFinal: true,
        });

        return {
          ...state,
          currentBatches: newCurrentBatches,
        };
      }

      return state;
    }

    case BufferMetricType.StartNewBufferMetrics: {
      const savedWorkoutId = state.workoutId;
      const { workoutId } = action.payload;

      if (savedWorkoutId !== workoutId) {
        return {
          ...state,
          workoutId,
          currentBatches: [generateBatch(0)],
        };
      }

      return state;
    }

    case BufferMetricType.ClearBatchesToSend: {
      return {
        ...state,
        batchesToSend: [],
      };
    }

    default:
      return state;
  }
};

export const getBatchesToSend = (state: {
  [BUFFER_METRICS_NAMESPACE]: BufferMetricsState;
}) => state[BUFFER_METRICS_NAMESPACE].batchesToSend;
