import React, { useEffect } from 'react';
import { deAuthenticate as deAuthenticateAction } from '@peloton/auth';
import { reportError as reportErrorAction } from '@peloton/error-reporting';
import { useReduxAction } from '@engage/redux';
import { isOk, isError } from '@engage/result';
import { useGetSession } from './useGetSession';
import { useUserFetcher } from './useUserFetcher';

const UserFetcher: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const result = useGetSession();
  const { isFetched, load } = useUserFetcher();
  const deAuthenticate = useReduxAction(deAuthenticateAction);
  const reportError = useReduxAction(reportErrorAction);

  useEffect(() => {
    if (isOk(result)) {
      if (result?.value?.user?.id) {
        load();
      } else {
        deAuthenticate();
      }
    } else if (isError(result)) {
      reportError({ error: result.value });
    }
  }, [result]);

  return isOk(result) || isError(result) || isFetched ? <>{children}</> : null;
};

export default UserFetcher;
