import React from 'react';

const CheckedBell: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    data-test-id="bellIcon"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 12.6667C8.00001 7.83289 11.6274 4.00008 16 4.00008C17.3239 4.00008 18.5725 4.34807 19.6737 4.96615C19.9948 5.14636 20.4011 5.03217 20.5814 4.7111C20.7616 4.39003 20.6474 3.98366 20.3263 3.80344C19.0341 3.07817 17.5618 2.66675 16 2.66675C10.7997 2.66675 6.66668 7.19129 6.66668 12.6667V15.9962C6.66668 17.1265 6.25639 18.2185 5.51203 19.0692L3.13242 21.7887C2.37807 22.6509 2.99031 24.0001 4.13586 24.0001H27.8642C29.0097 24.0001 29.6219 22.6509 28.8676 21.7887L26.488 19.0692C25.7436 18.2185 25.3333 17.1265 25.3333 15.9962V12.0001C25.3333 11.6319 25.0349 11.3334 24.6667 11.3334C24.2985 11.3334 24 11.6319 24 12.0001V15.9962C24 17.4495 24.5275 18.8534 25.4845 19.9472L27.8642 22.6667H4.13585L6.51547 19.9472C7.4725 18.8534 8.00001 17.4495 8.00001 15.9962V12.6667ZM13.3271 25.9093C13.277 25.5445 12.9407 25.2895 12.5759 25.3396C12.2111 25.3898 11.9561 25.7261 12.0062 26.0909C12.2642 27.9679 13.9998 29.3334 16 29.3334C18.0002 29.3334 19.7358 27.9679 19.9938 26.0909C20.0439 25.7261 19.7889 25.3898 19.4241 25.3396C19.0594 25.2895 18.723 25.5445 18.6729 25.9093C18.5176 27.039 17.426 28.0001 16 28.0001C14.574 28.0001 13.4824 27.039 13.3271 25.9093Z"
      fill="#222529"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6667 3.95893C26.9381 4.20772 26.9565 4.62943 26.7077 4.90085L21.8248 10.4506C21.7019 10.5846 21.5297 10.6626 21.3479 10.6666C21.1661 10.6705 20.9906 10.6001 20.862 10.4715L18.6667 8.27617C18.4063 8.01582 18.4063 7.59371 18.6667 7.33336C18.927 7.07301 19.3492 7.07301 19.6095 7.33336L21.3124 9.03632L25.7248 3.99988C25.9736 3.72847 26.3953 3.71013 26.6667 3.95893Z"
      fill="#222529"
    />
  </svg>
);

export { CheckedBell };
