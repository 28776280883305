// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Workouts = {
  Cancel: 'cancel',
  Count: 'count',
  CountWithDate: 'countWithDate',
  DataNotAvailable: 'dataNotAvailable',
  Delete: 'delete',
  DeleteCta: 'deleteCta',
  DistanceWithUnits: 'distanceWithUnits',
  DownloadWorkoutsCta: 'downloadWorkoutsCta',
  Elevation: 'elevation',
  EmptyText: 'emptyText',
  EmptyWorkoutText: 'emptyWorkoutText',
  FromStartTime: 'fromStartTime',
  GenericShareDescription: 'genericShareDescription',
  GraphsAverage: 'graphsAverage',
  GraphsBest: 'graphsBest',
  GraphsMissingData: 'graphsMissingData',
  KilojoulesAbbreviation: 'kilojoulesAbbreviation',
  LeaderboardRank: 'leaderboardRank',
  LiveWorkoutPaused: 'liveWorkoutPaused',
  MoreWorkoutsCta: 'moreWorkoutsCta',
  NoRunningMetrics: 'noRunningMetrics',
  RouteHeader: 'routeHeader',
  ShareClassTitle: 'shareClassTitle',
  ShareDescription: 'shareDescription',
  ShareDigitalDeviceDescription: 'shareDigitalDeviceDescription',
  ShareHardwareDeviceDescription: 'shareHardwareDeviceDescription',
  ShareInstructor: 'shareInstructor',
  ShareJustRideTitle: 'shareJustRideTitle',
  Splits: 'splits',
  Title: 'title',
  TotalWorkouts: 'totalWorkouts',
  ViewWorkouts: 'viewWorkouts',
  WorkedOutWithPeloton: 'workedOutWithPeloton',
  Workout: 'workout',
  WorkoutAchievements: 'workoutAchievements',
  Zone: 'zone',
  Blocks: 'blocks',
  PrivateWorkoutHistoryText: 'privateWorkoutHistoryText',
} as const;
