import { toEncodedClientDetails } from '@peloton/analytics/clientDetailsHeader';
import type { BaseAPI, AxiosPromise } from '@engage/api-v2/generated/core/request';

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE';

/**
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 */
export const axiosBase = <Response, RequestBody>({
  api,
  path,
  method,
  requestBody,
  pelotonClientDetails,
  overrides = {},
}: {
  api: BaseAPI;
  path: string;
  method: Method;
  requestBody?: RequestBody;
  pelotonClientDetails?: Record<string, any>;
  overrides?: Record<any, any>;
}): AxiosPromise<Response> => {
  const baseAxiosOptions = {
    url: `/api${path}`,
    method,
    ...(api?.configuration?.baseOptions ?? {}),
    headers: {
      'Peloton-Platform': 'web',
      ...(!!pelotonClientDetails
        ? { 'Peloton-Client-Details': toEncodedClientDetails(pelotonClientDetails) }
        : {}),
    },
    data: requestBody,
    ...overrides,
  };

  return api.axios.request(baseAxiosOptions);
};
