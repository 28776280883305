import { propOr, find } from 'ramda';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Redirect, matchPath, Route } from 'react-router-dom';
import { MEMBER_BASE_PATH, PROFILE_BASE_PATH } from '@peloton/links/members';
import type { ViewedMemberProps } from '@engage/members';
import { withViewedMemberId } from '@engage/members';
import { SkipLinks } from '@members/copy';
import { FOLLOW_BASE_PATH } from '@members/follow/urls';
import type {
  UserNavRouteOrRedirect,
  RedirectInfo,
  UserNavRouteInfo,
} from '@members/layout';
import { LayoutWithUserNav, userNavElementId, defaultContentId } from '@members/layout';

import { toRoutes, isRouteProps, filterRedirects, toRedirects } from './helpers';

type OwnProps = { userNavPagesProps: UserNavRouteOrRedirect[] };

const BaseUserNavHandler: React.FC<
  React.PropsWithChildren<OwnProps & ViewedMemberProps & RouteComponentProps<{}>>
> = ({ userNavPagesProps, viewedMemberId, location: { pathname } }) => (
  <LayoutWithUserNav
    userId={viewedMemberId}
    skipLinks={{
      first: {
        skipLinkId: SkipLinks.SkipToUserNav,
        contentElementId: userNavElementId,
      },
      second: {
        skipLinkId: SkipLinks.SkipToContent,
        contentElementId: defaultContentId,
      },
    }}
    {...toUserNavLayoutProps(userNavPagesProps, pathname)}
  >
    <Switch>
      {toRoutes(userNavPagesProps)}
      <Redirect to="/404" />
    </Switch>
  </LayoutWithUserNav>
);

export const toUserNavLayoutProps = (
  userNavPagesProps: UserNavRouteOrRedirect[],
  pathname: string,
): UserNavRouteInfo =>
  propOr(
    find(
      routeProps => matchPath(pathname, { path: routeProps.route.path }) !== null,
      userNavPagesProps.filter(isRouteProps),
    ),
    'layoutProps',
    {},
  );

const UserNavHandler = withViewedMemberId(BaseUserNavHandler) as React.ComponentType<
  React.PropsWithChildren<OwnProps & RouteComponentProps<{}>>
>;

const startsWithMembersOrProfile = ({ redirect: { from } }: RedirectInfo) =>
  from && (from.indexOf(MEMBER_BASE_PATH) === 0 || from.indexOf(PROFILE_BASE_PATH) === 0);

const toMemberOrProfileRouteRedirects = (routesOrRedirects: UserNavRouteOrRedirect[]) =>
  toRedirects(filterRedirects(routesOrRedirects).filter(startsWithMembersOrProfile));

const toNonMemberOrProfileRouteRedirects = (
  routesOrRedirects: UserNavRouteOrRedirect[],
) =>
  toRedirects(
    filterRedirects(routesOrRedirects).filter(
      redirect => !startsWithMembersOrProfile(redirect),
    ),
  );

const toUserNavRoutes = (userNavPagesProps: UserNavRouteOrRedirect[]) => [
  // handle profile/members redirects first
  ...toMemberOrProfileRouteRedirects(userNavPagesProps),
  <Route
    key={FOLLOW_BASE_PATH}
    path={`${FOLLOW_BASE_PATH}/:userId`}
    component={(props: RouteComponentProps<{}>) => (
      <UserNavHandler userNavPagesProps={userNavPagesProps} {...props} />
    )}
  />,
  <Route
    key={PROFILE_BASE_PATH}
    path={PROFILE_BASE_PATH}
    component={(props: RouteComponentProps<{}>) => (
      <UserNavHandler userNavPagesProps={userNavPagesProps} {...props} />
    )}
  />,
  <Route
    key={MEMBER_BASE_PATH}
    path={`${MEMBER_BASE_PATH}/:userId`}
    component={(props: RouteComponentProps<{}>) => (
      <UserNavHandler userNavPagesProps={userNavPagesProps} {...props} />
    )}
  />,
  // handle non-profile/non-members redirects last
  ...toNonMemberOrProfileRouteRedirects(userNavPagesProps),
];

export { toUserNavRoutes };
