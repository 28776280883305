import React from 'react';
import { isPending } from '@engage/result';
import enUS from '../static/namespacedCopy/en-US.production.generated.json';
import type { CopyFile } from './models';
import type { Result } from './useCopyClient';

const CONTEXT = React.createContext<CopyFile>(enUS);

const CopyProvider: React.FC<React.PropsWithChildren<{ copyClient: Result }>> = ({
  children,
  copyClient,
}) => {
  if (!copyClient || isPending(copyClient)) {
    return <></>;
  }

  return (
    <CONTEXT.Provider value={(copyClient?.value as CopyFile) ?? enUS}>
      {children}
    </CONTEXT.Provider>
  );
};

export const useAllCopy = () => React.useContext(CONTEXT);

export default CopyProvider;
