import type React from 'react';
import type { Dispatch } from 'redux';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { gray3, gray1 } from '@engage/colors';
import type { CardInfo, MemberCard } from '@engage/members/MemberCard';
import { changeRelationship } from '@engage/members/redux';
import { spaces } from '@engage/styles';

const CONTAINER_HEIGHT = 560;

export const MembersListModalContainer = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${gray1};

  ${media.tablet`
    height: ${`${CONTAINER_HEIGHT}px`};
  `}
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%; // needed for virtualized lists
`;

export const MembersListModalHeader = styled.div`
  text-align: center;
  border-bottom: 1px solid ${gray3};
  padding: 15px 10px;
  border-radius: 10px 10px 0 0;
  ${media.tablet`
    padding: ${spaces.large}px;
  `}
`;

export const mapMembersDispatchToProps = (dispatch: Dispatch, source: string) => ({
  toggleFollow: (namespace: string) => (
    e: React.MouseEvent<HTMLButtonElement | SVGElement>,
  ) => {
    e.preventDefault();
    dispatch(changeRelationship(source, namespace));
  },
});

export const withFollowClick = (
  toggleFollow: ToggleFollow,
  cardsList: CardInfo[],
  listName: string,
): MemberCard[] =>
  cardsList.map((cardProps: CardInfo) => ({
    ...cardProps,
    onFollowClick: toggleFollow(cardProps.username),
    listName,
  }));

export type FollowDispatchProps = {
  toggleFollow: ToggleFollow;
};

type ToggleFollow = (
  namespace?: string,
) => (e: React.MouseEvent<HTMLButtonElement | SVGElement | HTMLDivElement>) => void;
