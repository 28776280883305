import React from 'react';
import { mutate } from 'swr';
import { ScheduledClassFetchersGQL } from '@members/data-fetch';
import type { Headers } from '@members/data-fetch/graphql-fetchers/utils';
import type {
  RemoveClassFromScheduleMutationVariables,
  RemoveClassFromScheduleMutation,
} from '@members/graphql-swr/schemas/scheduled-classes/mutations/RemoveClassFromSchedule.generated';

export const useRemoveClassFromScheduleMutationSWR = (headers?: Headers) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [didError, setDidError] = React.useState(false);
  const [maybeData, setMaybeData] = React.useState<
    RemoveClassFromScheduleMutation | undefined
  >();
  const removeClassFromScheduleMutation = (
    input: RemoveClassFromScheduleMutationVariables,
  ) => {
    setIsLoading(true);
    return mutate(
      ...ScheduledClassFetchersGQL.RemoveClassFromScheduleMutation(input, headers),
    )
      .then(data => {
        if (data?.removeClassFromSchedule?.__typename !== 'ScheduledClass') {
          setDidError(true);
        }
        if (data?.removeClassFromSchedule?.__typename === 'ScheduledClass') {
          setMaybeData(data);
        }
      })
      .catch(err => {
        setDidError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { removeClassFromScheduleMutation, data: maybeData, isLoading, error: didError };
};
