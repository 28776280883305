import useSWR from 'swr';
import { toAllSubscriptions } from '@engage/subscriptions/api';
import { SubscriptionsFetchers, UsersFetchers } from '@members/data-fetch';

export const useAllSubscriptions = () => {
  const { data: subData } = useSWR(
    ...SubscriptionsFetchers.GetLoggedInUserAllSubscriptionsByType({}),
  );
  const { data: userData } = useSWR(...UsersFetchers.GetMe({ pelotonPlatform: 'web' }));
  if (!subData || !userData) {
    return null;
  }
  return toAllSubscriptions(userData.id ?? '')((subData as any) ?? []);
};
