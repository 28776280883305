import { compose, filter, map, chain, max, min } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import type {
  WorkoutMetric,
  WorkoutPerformance,
  WorkoutSummary,
  Segment,
  MetricValue,
} from '../models';
import { toSplitsData } from './splits';
import type {
  ApiSegment,
  ApiWorkoutPerformance,
  ApiZone,
  ApiWorkoutMetric,
} from './types';
import { ApiMetricsType, ApiSlug } from './types';

export const GRAPH_FREQ_SECONDS = 5;

const toPerformanceGraphUrl = (workoutId: string) =>
  `/api/workout/${workoutId}/performance_graph?every_n=${GRAPH_FREQ_SECONDS}`;

export const fetchPerformance = (api: Client, workoutId: string) =>
  api
    .get(toPerformanceGraphUrl(workoutId), toSkipErrorHandlingConfig({}, { 403: true }))
    .then(pipeData(toWorkoutPerformance));

export const toWorkoutPerformance = (data: ApiWorkoutPerformance): WorkoutPerformance => {
  const apiSegments = toTrimmedApiSegments(data.segmentList, data.duration);
  const splits = toSplitsData(data.splitsData, data.isLocationDataAccurate);

  return (
    /*
      TODO: This handles an API bug where they could return null metrics
      if secondsSincePedalingStart is empty (user cancelled ride before pedaling start)
     */
    data.secondsSincePedalingStart.length > 0
      ? {
          total: data.summaries.filter(shouldShowWorkoutSummary),
          average: data.averageSummaries.filter(shouldShowWorkoutSummary),
          metrics: toWorkoutMetrics(
            data.duration,
            data.secondsSincePedalingStart,
            data.metrics,
            apiSegments,
          ),
          segments: apiSegments.map((segment: ApiSegment) =>
            toSegment(segment, data.duration),
          ),
          locationData: data.locationData,
          duration: data.duration,
          isLocationDataInaccurate: data.isLocationDataAccurate === false,
          effortZones: data.effortZones,
          ...splits,
        }
      : {
          total: [],
          average: [],
          metrics: [],
          segments: [],
          locationData: [],
          duration: data.duration,
          isLocationDataInaccurate: false,
          effortZones: data.effortZones,
        }
  );
};

// api may return segments that extend past the workout duration.
// clients are currently trimming the data
export const toTrimmedApiSegments = (
  apiSegments: ApiSegment[],
  duration: number,
): ApiSegment[] =>
  apiSegments
    .filter(segment => segment.startTimeOffset < duration)
    .map((segment, i, filtered) =>
      i === filtered.length - 1
        ? { ...segment, length: duration - segment.startTimeOffset }
        : segment,
    );

const toSegment = (apiSegment: ApiSegment, duration: number): Segment => ({
  name: apiSegment.displayName,
  iconUrl: apiSegment.iconUrl,
  startPercent: toPercent(apiSegment.startTimeOffset, duration),
  lengthPercent: toPercent(apiSegment.length, duration),
  endPercent: toPercent(apiSegment.startTimeOffset + apiSegment.length, duration),
  isMetrics: isMetrics(apiSegment),
  startSeconds: apiSegment.startTimeOffset,
  lengthSeconds: apiSegment.length,
  endSeconds: apiSegment.startTimeOffset + apiSegment.length,
  isDrill: apiSegment.isDrill,
});

const isMetrics = (segment: ApiSegment) =>
  segment.metricsType === ApiMetricsType.Cycling ||
  segment.metricsType === ApiMetricsType.Running ||
  segment.metricsType === ApiMetricsType.Rowing;

const shouldShowWorkoutSummary = (summary: WorkoutSummary): summary is WorkoutSummary =>
  summary.value !== null;

const shouldDisplayWorkoutMetric = (metric: ApiWorkoutMetric) =>
  metric.values.some(value => value !== null);

const flattenAlternativeMetrics = ({
  alternatives = [],
  ...metric
}: ApiWorkoutMetric) => [metric, ...alternatives];

const toWorkoutMetrics = (
  workoutDuration: number,
  timesSinceStart: number[],
  metrics: ApiWorkoutMetric[],
  segments: ApiSegment[],
) =>
  compose<ApiWorkoutMetric[], ApiWorkoutMetric[], ApiWorkoutMetric[], WorkoutMetric[]>(
    map(toWorkoutMetric(workoutDuration, timesSinceStart, segments)),
    chain(flattenAlternativeMetrics),
    filter(shouldDisplayWorkoutMetric),
  )(metrics);

export const toWorkoutMetric = (
  workoutDuration: number,
  timesSinceStart: number[],
  segments: ApiSegment[],
) => (metric: ApiWorkoutMetric) => {
  const values = toValues(timesSinceStart, metric.values, segments, metric.slug);

  // API defines maxValue as "best" value, meaning that in some cases (i.e. pace), the best metric is not the max
  // numerical value. We will separate these two concepts and use the API provided value as our "bestValue", and
  // calculate maxValue ourselves.
  // Furthermore, sometimes there is no bestValue, so we will default the max to 0 as a starter value
  // Values have been filtered, so using the processed values allows all filtering logic to be contained in one place
  const maxValue = toMinOrMaxValue(values, metric.maxValue ?? 0, max);
  const minValue = toMinOrMaxValue(values, metric.maxValue ?? maxValue, min);

  const workoutMetric: WorkoutMetric = {
    slug: metric.slug,
    displayName: metric.displayName,
    displayUnit: metric.displayUnit,
    averageValue: metric.averageValue,
    bestValue: metric.maxValue,
    maxValue,
    minValue,
    values,
    duration: workoutDuration,
    distance: metric.distance,
  };

  if (metric.zones) {
    workoutMetric.zoneData = {
      zones: metric.zones.reverse().map(toZone(workoutDuration)),
    };

    if (metric.missingDataDuration) {
      workoutMetric.zoneData.missing = toMissing(
        metric.missingDataDuration,
        workoutDuration,
      );
    }
  }

  return workoutMetric;
};

const toMinOrMaxValue = (
  values: MetricValue[],
  best: number,
  compareFn: (a: number, b: number) => number,
): number =>
  values.reduce(
    (curMin: number, valueTuple: MetricValue) =>
      typeof valueTuple[1] === 'number'
        ? compareFn(valueTuple[1] as number, curMin)
        : curMin,
    best,
  );

export const isInSomeSegment = (x: number, segments: ApiSegment[]) =>
  segments.some(
    segment =>
      x >= segment.startTimeOffset && x < segment.startTimeOffset + segment.length,
  );

const toMetricsMarkers = (segments: ApiSegment[]) =>
  segments.map((segment: ApiSegment): [number, null] => [segment.startTimeOffset, null]);

/**
 * Slightly modifies HR Value tuples to change 0 values to null for display purposes
 *
 * @param pair
 * @returns {MetricValue}
 */
const toHRValue = (pair: [number, number]): MetricValue =>
  pair[1] === 0 ? [pair[0], null] : pair;

const toValuesForSegments = (pairs: [number, number][], segments: ApiSegment[]) => {
  const noMetricsSegments = segments.filter(segment => !isMetrics(segment));
  const metricsPairs: MetricValue[] = pairs;

  return (metricsPairs as [number, number | null][])
    .concat(toMetricsMarkers(noMetricsSegments))
    .sort((a, b) => a[0] - b[0]);
};

export const toValues = (
  timesSinceStart: number[],
  metricValues: number[],
  segments: ApiSegment[],
  slug: ApiSlug | string,
) => {
  const pairs = timesSinceStart.map((time: number, i: number): [number, number] => [
    time,
    metricValues[i],
  ]);
  return slug === ApiSlug.HeartRate
    ? pairs.map(toHRValue)
    : toValuesForSegments(pairs, segments);
};

const toMissing = (duration: number, workoutDuration: number) => ({
  duration,
  percent: toPercent(duration, workoutDuration),
});

const toPercent = (part: number, total: number) => Math.round((100 * part) / total);

const toZone = (workoutDuration: number) => (apiZone: ApiZone) => ({
  ...apiZone,
  durationPercent: toPercent(apiZone.duration, workoutDuration),
});
