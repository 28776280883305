import { propEq, find } from 'ramda';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import type { BrowseCategory } from '@engage/browse-categories';
import type {
  LibraryClass,
  AiredClass,
  DenormalizedClass,
  ClassSelectorState,
} from '@engage/classes';
import { isAired, getDenormalizedClass } from '@engage/classes';
import type { PageNumber } from '@engage/pagination';
import type { PendingResult } from '@engage/result';
import { Kind as Result, isOk, pending, isError } from '@engage/result';
import type { State as LibraryState } from './redux';
import type { LibraryState as LibraryReducerState } from './redux/classes';

export type ClassPreview = {
  subtitle1: string;
  subtitle2: string;
  userHasTakenClass: boolean;
} & Partial<
  Pick<DenormalizedClass<AiredClass>, 'difficultyLevel' | 'scheduledStartTime'>
> &
  DenormalizedClass<LibraryClass>;

export type LibrarySelectorState = FetcherSelectorState &
  ClassSelectorState & {
    library: LibraryState;
  };

export const classToClassPreview = (
  klass: DenormalizedClass<LibraryClass>,
): ClassPreview => ({
  ...klass,
  ...(isAired(klass)
    ? {
        difficultyLevel: klass.difficultyLevel,
        scheduledStartTime: klass.scheduledStartTime,
        subtitle1: klass.instructor?.name ?? '',
      }
    : {
        subtitle1: '',
      }),
  subtitle2: klass.fitnessDiscipline.name,
  userHasTakenClass: klass.totalUserWorkouts > 0,
});

export const getBrowseCategories = (state: LibrarySelectorState): BrowseCategory[] => {
  const categories = state.library.browseCategories;
  if (categories.type === Result.Ok) {
    return categories.value;
  }
  return [];
};

export const areBrowseCategoriesLoading = (state: LibrarySelectorState) =>
  state.library.browseCategories === pending;

const isClassPreview = (cp: ClassPreview | undefined): cp is ClassPreview => Boolean(cp);
export const getClassPreviews = (state: LibrarySelectorState): ClassPreview[] => {
  if (state.library.classIds.type === Result.Ok) {
    return state.library.classIds.value.classIds
      .map<ClassPreview | undefined>(id => {
        const klass = getDenormalizedClass(state, id);
        return klass
          ? classToClassPreview(klass as DenormalizedClass<LibraryClass>)
          : undefined;
      })
      .filter(isClassPreview); // Filter out undefined DenormalizedClasses
  }
  return [];
};

export const getTotalClassesCount = (state: LibrarySelectorState): number =>
  state.library.classIds.type === Result.Ok
    ? state.library.classIds.value.totalClassesCount
    : 0;

export const getActiveCategorySlug = (state: LibrarySelectorState) =>
  state.library.activeCategory;

export const getActiveCategory = (state: LibrarySelectorState) => {
  const predicate = propEq('slug', getActiveCategorySlug(state));
  return find<BrowseCategory>(predicate, getBrowseCategories(state));
};

export const getActiveCategoryName = (
  state: LibrarySelectorState,
): string | undefined => {
  const activeCategory = getActiveCategory(state);
  return activeCategory && activeCategory.name;
};

export const isEmptyState = (state: LibrarySelectorState) =>
  state.library.classIds.type === Result.Ok &&
  state.library.classIds.value.classIds.length === 0;

export const getClassIds = (state: LibrarySelectorState): string[] | undefined =>
  isOk(state.library.classIds) ? state.library.classIds.value.classIds : undefined;

export const getNextPage = (
  state: LibrarySelectorState,
): PendingResult<PageNumber, { page: number }> | undefined => {
  const classIds = state.library.classIds;
  return isOk(classIds) ? classIds.value.nextPage : undefined;
};

export const isLoading = (state: LibrarySelectorState) =>
  getLibraryState(state) === pending ||
  (isOk(getLibraryState(state)) && getNextPage(state) === pending);

export const isNextPageError = (state: LibrarySelectorState) => {
  const result = getLibraryState(state);
  return isOk(result) && isError(result.value.nextPage);
};

export const getNextPageToLoad = (state: LibrarySelectorState): number | undefined => {
  if (isOk(state.library.classIds)) {
    const nextPage = state.library.classIds.value.nextPage;
    if (isOk(nextPage)) {
      return typeof nextPage.value === 'number' ? nextPage.value : undefined;
    } else {
      return isError(nextPage) ? nextPage.value.page : undefined;
    }
  } else {
    return 0;
  }
};

// FIXME: This is incorrect. canFetchNextPage should return true, iif there's a next
// page to load, or loading a page for the first time. The second case cannot be handled
// correctly because the activeCategory state is managed separately from the classes state.
// What's more we should not depend on the ordering of side effects. I.e. for the web app
// it could probably be worked around by resetting the classes state to undefined after
// LoadCategories is done, but for mobile we call LoadCategories only once.
export const canFetchNextPage = (state: LibrarySelectorState): boolean =>
  typeof getNextPageToLoad(state) === 'number';

export const loadingFirstPage = (state: LibrarySelectorState): boolean =>
  !nextPageLoading(state) && isLoading(state);

const nextPageLoading = (state: LibrarySelectorState): boolean =>
  isOk(state.library.classIds) && state.library.classIds.value.nextPage === pending;

const getLibraryState = (state: LibrarySelectorState): LibraryReducerState =>
  state.library.classIds;

export const getBrowseCategoryAnalyticsProps = (state: LibrarySelectorState) => {
  const browseCategory = getBrowseCategories(state).find(
    (category: BrowseCategory) => category.slug === getActiveCategorySlug(state),
  );

  if (!browseCategory && window.location.pathname.includes('all')) {
    return { browseCategory: { name: 'All' } && 'All' };
  }

  return { browseCategory: browseCategory && browseCategory.name };
};
