import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { isDefined } from '@peloton/types';
import { If } from '@engage/conditional-render';
import { useReduxState } from '@engage/redux';
import { Tags } from '@members/copy';
import { TagsFetchersGQL } from '@members/data-fetch';
import type { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import {
  ScrollContext,
  PERSISTENT_NAV_BAR_HEIGHT,
  BROWSE_NAV_BAR_HEIGHT,
} from '../MainView/ScrollContext';
import { getTagBrowseCategoryFromLocation } from '../selectors';
import { TagsContext } from '../TagsContext';
import { Body } from './Body';
import { NavBar } from './NavBar';

export type CategoryOffsetsMap = Record<TagBrowseCategorySlug, number>;

export const BrowseTagsView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { browseContainerRef } = useContext(ScrollContext);
  const selectedCategory =
    (useReduxState(getTagBrowseCategoryFromLocation) as TagBrowseCategorySlug) ??
    'TRENDING';
  const { data, error, mutate: refetchNavBar } = useSWR(
    ...TagsFetchersGQL.TagBrowseCategoryDisplayNamesQuery({}),
  );

  const loading = !data;

  const categoryDisplayNames = [
    Tags.TrendingCategory,
    Tags.FeaturedCategory,
    Tags.FriendsCategory,
    Tags.PopularCategory,
  ];
  const initialCategoryOffsets = {
    TRENDING: 0,
    FEATURED: 0,
    FRIENDS: 0,
    POPULAR: 0,
  };
  const [categoryOffsetsMap, setCategoryOffsetsMap] = useState<CategoryOffsetsMap>(
    initialCategoryOffsets,
  );
  const { setPersistentNavOffset } = useContext(TagsContext);

  useEffect(() => {
    setPersistentNavOffset(0);
  }, []);

  return (
    <Container ref={browseContainerRef}>
      <If condition={!loading && !isDefined(error)}>
        <NavBar
          selectedCategory={selectedCategory}
          categoryDisplayNames={categoryDisplayNames}
          categoryOffsetsMap={categoryOffsetsMap}
        />
      </If>
      {(Object.keys(initialCategoryOffsets) as TagBrowseCategorySlug[]).map(slug => (
        <Body
          key={slug}
          isActive={selectedCategory === slug}
          category={slug}
          refetchNavBar={refetchNavBar}
          categoryOffsetsMap={categoryOffsetsMap}
          setCategoryOffsetsMap={setCategoryOffsetsMap}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding-top: ${PERSISTENT_NAV_BAR_HEIGHT + BROWSE_NAV_BAR_HEIGHT}px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
