import { has } from 'ramda';
import type { SearchSharedProperties } from '@engage/library';
import type { MetricCollector } from '@engage/video';
import { DeviceType } from '@engage/workouts';
import type { ViewEvents } from '../analytics';

export enum VideoSagaActionTypes {
  TimecodeUpdate = 'members/pg-video/timecode/UNTHROTTLED_UPDATE',
  OpenMCAControls = 'members/pg-video/OPEN_MCA_CONTROLS',
  ChangeAudioMix = 'members/pg-video/CHANGE_AUDIO_MIX',
  Exit = 'members/pg-video/EXIT',
  JWPlayerIdled = 'members/pg-video/JW_PLAYER_IDLED',
  Viewed = 'members/pg-video/VIEWED',
  ConfirmExit = 'members/pg-video/CONFIRM_EXIT',
  LeaveWorkout = 'members/pg-video/LEAVE_WORKOUT',
  LoadVOD = 'members/pg-video/LOAD_VOD',
  LoadLive = 'members/pg-video/LOAD_LIVE',
  ViewWorkoutHistory = 'members/pg-view/VIEW_WORKOUT_HISTORY',
  ResumeVideo = 'members/pg-video/RESUME_VIDEO',
  PauseVideo = 'members/pg-video/PAUSE_VIDEO',
  Seek = 'members/pg-video/SEEK',
  ChangeVideoQuality = 'members/pg-video/CHANGE_VIDEO_QUALITY',
  ToggleFullscreen = 'members/pg-video/TOGGLE_FULLSCREEN',
  BufferMetrics = 'members/pg-video/BUFFER_METRICS',
  BufferMetricsV2 = 'members/pg-video/BUFFER_METRICSV2',
  UsedQuickRewind = 'members/pg-video/USED_QUICK_REWIND',
  ToggleTimeline = 'members/pg-video/TOGGLE_TIMELINE',
}

export const loadVodPage = (
  classId: string,
  searchSharedProperties: SearchSharedProperties,
  isDigital: boolean = false,
  deviceType: DeviceType = DeviceType.Web,
  source?: string,
  requireVODAvailability: boolean = false,
) => ({
  type: VideoSagaActionTypes.LoadVOD,
  payload: {
    classId,
    isDigital,
    deviceType,
    source,
    requireVODAvailability,
    searchSharedProperties,
  },
});

export const loadLiveVideoPage = (
  pelotonId: string,
  classId: string,
  isDigital: boolean = false,
  deviceType: DeviceType = DeviceType.Web,
  source?: string,
  isEncore?: boolean,
) => ({
  type: VideoSagaActionTypes.LoadLive,
  payload: { pelotonId, classId, isDigital, deviceType, source, isEncore },
});

export type FrequentTimecodeUpdateAction = {
  payload: { timecode: number };
  type: VideoSagaActionTypes.TimecodeUpdate;
};

export const frequentTimecodeUpdate = (
  timecode: number,
): FrequentTimecodeUpdateAction => ({
  type: VideoSagaActionTypes.TimecodeUpdate,
  payload: { timecode },
});

export const exitVideo = () => ({
  type: VideoSagaActionTypes.Exit,
});

export const jwplayerIdled = () => ({
  type: VideoSagaActionTypes.JWPlayerIdled,
});

export const leaveWorkout = () => ({
  type: VideoSagaActionTypes.LeaveWorkout,
});

export const vodVideoPageViewed = (classId: string, viewEvent: ViewEvents) => ({
  type: VideoSagaActionTypes.Viewed,
  payload: { classId, viewEvent },
});

export const liveVideoPageViewed = (pelotonId: string, viewEvent: ViewEvents) => ({
  type: VideoSagaActionTypes.Viewed,
  payload: { pelotonId, viewEvent },
});

export const toggleFullscreen = (isFullscreen: boolean) => ({
  type: VideoSagaActionTypes.ToggleFullscreen,
  payload: { isFullscreen },
});

export type AnalyticsViewedAction =
  | typeof vodVideoPageViewed
  | typeof liveVideoPageViewed;

export const isVOD = (
  action: ReturnType<AnalyticsViewedAction>,
): action is ReturnType<typeof vodVideoPageViewed> => has('classId')(action.payload);

export const confirmExitVideo = () => ({
  type: VideoSagaActionTypes.ConfirmExit,
});

export const viewWorkoutHistory = () => ({
  type: VideoSagaActionTypes.ViewWorkoutHistory,
});

export const bufferingMetrics = (
  metrics: MetricCollector,
  resolutionHeight?: number,
) => ({
  type: VideoSagaActionTypes.BufferMetrics,
  payload: { metrics, resolutionHeight },
});

export const bufferingMetricsV2 = (
  startBackoff: boolean = false,
  delayTime: number = 30000,
) => ({
  type: VideoSagaActionTypes.BufferMetricsV2,
  payload: { startBackoff, delayTime },
});
