import { stringify } from 'querystring';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { omit, identity, ifElse, not } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getQueryParams } from '@peloton/redux';
import { SOURCE_PARAM } from '@members/on-demand-modal/constants';
import {
  closeModal,
  getModalNameFromLocation,
  ModalActionType,
  openModal,
} from './redux';
import { MODAL_PARAM } from './urls';

export const modalCloseSaga = function* (
  action: ReturnType<typeof closeModal>,
): SagaIterator {
  const queryParams = yield select(getQueryParams);

  yield put(
    push({
      search: toQueryParamsWithoutModal(queryParams, action.payload.additionalParams),
    }),
  );
};

export const modalOpenSaga = function* (): SagaIterator {
  const urlModal = yield select(getModalNameFromLocation);
  if (urlModal) {
    yield put(openModal(urlModal));
  }
};

export const toQueryParamsWithoutModal = (
  queryParams: ReturnType<typeof getQueryParams>,
  additionalModalParams: string[],
) =>
  ifElse(
    not,
    identity,
    s => `?${s}`,
  )(stringify(omit([MODAL_PARAM, SOURCE_PARAM, ...additionalModalParams], queryParams)));

export const modalWatcherSaga = function* () {
  yield takeEvery(ModalActionType.Close, modalCloseSaga);
  yield takeEvery(LOCATION_CHANGE, modalOpenSaga);
};

export const x = closeModal;
export const y = openModal;
