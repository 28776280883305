import type { Reducer } from 'redux';
import type { ApiErrors } from '@peloton/api';
import { getApiErrorType } from '@peloton/api';
import type { EmailAddress } from '@peloton/auth';

export type ForgotPasswordUser = {
  email: EmailAddress;
};

export const resetForgotPasswordState = (payload: {} = {}) => ({
  type: ForgotPasswordActionType.RESET,
  payload,
});

export const requestForgotPassword = (payload: ForgotPasswordUser) => ({
  type: ForgotPasswordActionType.REQUEST,
  payload,
});

export const successfulForgotPasswordRequest = (payload: ForgotPasswordUser) => ({
  type: ForgotPasswordActionType.REQUEST_SUCCESS,
  payload,
});

export const failedForgotPasswordRequest = (payload: any) => ({
  type: ForgotPasswordActionType.REQUEST_FAILURE,
  payload,
});

export enum ForgotPasswordActionType {
  REQUEST = 'pelo/forgot-password/REQUEST',
  REQUEST_SUCCESS = 'pelo/forgot-password/REQUEST_SUCCESS',
  REQUEST_FAILURE = 'pelo/forgot-password/REQUEST_FAILURE',
  RESET = 'pelo/forgot-password/RESET',
}

export type ForgotPasswordReducerState = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  email: string;
  error?: ApiErrors;
};

export type ForgotPasswordSelectorState = { forgotPassword: ForgotPasswordReducerState };

export const getIsForgotPasswordLoading = (state: ForgotPasswordSelectorState) =>
  state.forgotPassword.isLoading;
export const getForgotPasswordIsError = (state: ForgotPasswordSelectorState) =>
  state.forgotPassword.isError;
export const getForgotPasswordIsSuccess = (state: ForgotPasswordSelectorState) =>
  state.forgotPassword.isSuccess;
export const getEmail = (state: ForgotPasswordSelectorState) =>
  state.forgotPassword.email;

export type ResetForgotPasswordAction = ReturnType<typeof resetForgotPasswordState>;
export type RequestForgotPasswordAction = ReturnType<typeof requestForgotPassword>;
export type SuccessfulForgotPasswordAction = ReturnType<
  typeof successfulForgotPasswordRequest
>;
export type FailedForgotPasswordAction = ReturnType<typeof failedForgotPasswordRequest>;

export type ForgotPasswordAction =
  | RequestForgotPasswordAction
  | SuccessfulForgotPasswordAction
  | FailedForgotPasswordAction;

const defaultState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: undefined,
  email: '',
};

export const forgotPasswordReducer: Reducer<ForgotPasswordReducerState> = (
  state = defaultState,
  action: ForgotPasswordAction,
) => {
  switch (action.type) {
    case ForgotPasswordActionType.REQUEST:
      return {
        isLoading: true,
        isError: false,
        isSuccess: false,
        email: action.payload.email,
        error: undefined,
      };
    case ForgotPasswordActionType.REQUEST_SUCCESS:
      return {
        isLoading: false,
        isError: false,
        isSuccess: true,
        email: action.payload.email,
        error: undefined,
      };
    case ForgotPasswordActionType.REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        error: getApiErrorType(action.payload),
      };
    case ForgotPasswordActionType.RESET:
      return defaultState;
    default:
      return state;
  }
};
