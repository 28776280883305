import { values, reject, isNil } from 'ramda';

// We take the analytics props grouped by which set of shared props
// they refer to, because the expected keys in BrowseAnalyticsProperties
// depend on values returned by the API, we cannot specify the type more
// specifically than Record<string, string>, which interferes with checking
// the types in other shared props.  So, here we spread them out again.

export type AnalyticsProps = Record<string, object>;

export const spreadAnalytics = (analyticsProps: AnalyticsProps) =>
  values(analyticsProps).reduce((acc, curr) => ({ ...acc, ...reject(isNil, curr) }), {});
