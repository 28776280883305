// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const BasicInfo = {
  Birthdate: 'birthdate',
  Birthday: 'birthday',
  Cta: 'cta',
  DayPlaceholder: 'dayPlaceholder',
  Email: 'email',
  EmailChangeModalButtonNo: 'emailChangeModalButtonNo',
  EmailChangeModalButtonYes: 'emailChangeModalButtonYes',
  EmailChangeModalDescription: 'emailChangeModalDescription',
  EmailChangeModalTitle: 'emailChangeModalTitle',
  EmailVerificationTitle: 'emailVerificationTitle',
  EmailVerified: 'emailVerified',
  EmailUnverified: 'emailUnverified',
  EmailWithAsterisk: 'emailWithAsterisk',
  Error: 'error',
  ErrorToast: 'errorToast',
  ExistingEmailAddressError: 'existingEmailAddressError',
  Female: 'female',
  FirstName: 'firstName',
  FutureBirthdateError: 'futureBirthdateError',
  Gender: 'gender',
  Height: 'height',
  HeightImperialUnit: 'heightImperialUnit',
  HeightMetricUnit: 'heightMetricUnit',
  IncompleteToast: 'incompleteToast',
  InvalidDateError: 'invalidDateError',
  InvalidDateFormatError: 'invalidDateFormatError',
  InvalidEmailAddressError: 'invalidEmailAddressError',
  InvalidUsernameError: 'invalidUsernameError',
  MeetsCommunityGuidelinesError: 'meetsCommunityGuidelinesError',
  InvalidFirstOrLastNameError: 'invalidFirstOrLastNameError',
  LastName: 'lastName',
  Location: 'location',
  LocationPlaceholder: 'locationPlaceholder',
  Male: 'male',
  MiddleInitial: 'middleInitial',
  MonthPlaceholder: 'monthPlaceholder',
  NonBinary: 'nonBinary',
  UsernamePolicyViolationToast: 'usernamePolicyViolationToast',
  LocationPolicyViolationToast: 'locationPolicyViolationToast',
  EmailVerificationSentToast: 'emailVerificationSentToast',
  ProfileInfoTitle: 'profileInfoTitle',
  Required: 'required',
  RequiredFields: 'requiredFields',
  SelectGender: 'selectGender',
  SendVerificationCode: 'sendVerificationCode',
  Success: 'success',
  SuccessToast: 'successToast',
  Title: 'title',
  TooYoungError: 'tooYoungError',
  UserId: 'userId',
  UsernameError: 'usernameError',
  UsernameErrorDetails: 'usernameErrorDetails',
  UsernameTakenError: 'usernameTakenError',
  UsernameWithAsterisk: 'usernameWithAsterisk',
  VerificationCodeSent: 'verificationCodeSent',
  VerificationDetails: 'verificationDetails',
  Weight: 'weight',
  WeightImperialUnit: 'weightImperialUnit',
  WeightMetricUnit: 'weightMetricUnit',
  YearPlaceholder: 'yearPlaceholder',
} as const;
