import { toUtcTime } from '@peloton/time';
import type { ProgramClassGroup } from '@engage/class-groups';
import { useReduxState } from '@engage/redux';
import { getProgramInfo, getCurrProgramClassInfo } from '@members/pg-programs/redux';

export const PROGRAMS_LOCATION_SOURCE = 'Training Programs';

export type ProgramGroupProperties = {
  '[Program ID]': string;
  programName: string;
  programDuration: number;
  programInstructorName?: string;
  programInstructorList?: string[];
  programNumberOfInstructors: number;
  programFitnessDisciplines: string;
  programFitnessDisciplineList: string[];
  programNumberOfFitnessDisciplines: number;
  programTotalClasses: number;
};

export const toProgramGroupProperties = (
  program: ProgramClassGroup,
): ProgramGroupProperties => {
  const instructorNames = program.instructors.map(c => c.name);
  return {
    '[Program ID]': program.id,
    programName: program.name,
    programDuration: program.duration,
    programInstructorName: instructorNames.join(', '),
    programInstructorList: instructorNames,
    programNumberOfInstructors: instructorNames.length,
    programFitnessDisciplines: program.fitnessDisciplines.join(', '),
    programFitnessDisciplineList: program.fitnessDisciplines,
    programNumberOfFitnessDisciplines: program.fitnessDisciplines.length,
    programTotalClasses: program.totalNumClasses,
  };
};

/**
 * Gets programs analytics properties from program info and class info states
 * in the redux store.  This allows these programs analytics properties
 * to be used in a seperate area of the app (such as pg-video) where access
 * to program info or class info would be otherwise limited.
 * @returns
 */
export const useProgramsAnalyticsProps = () => {
  const programInfoState = useReduxState(getProgramInfo);
  const currProgramClassInfo = useReduxState(getCurrProgramClassInfo);
  const dateFormat = 'YYYY-MM-DD';

  return programInfoState && currProgramClassInfo
    ? {
        ...toProgramGroupProperties(programInfoState.programClassGroup),
        '[Program Progress ID]': programInfoState.programProgressId,
        programProgressStartDate: toUtcTime(
          programInfoState.programProgressStartDate,
        ).format(dateFormat),
        programProgressEndDate: toUtcTime(programInfoState.programProgressEndDate).format(
          dateFormat,
        ),
        programWeekNumber: programInfoState.programWeekNumber,
        programClassNumber: currProgramClassInfo.programClassNumber,
        source: PROGRAMS_LOCATION_SOURCE,
      }
    : {};
};
