import React from 'react';
import styled from 'styled-components';
import { slate1, superRed } from '@engage/colors';
import { default as DotsBackground } from './DotsBackground';
import LoadingSpinner from './LoadingSpinner';

const LoadingPage: React.FC<
  React.PropsWithChildren<{
    styledBackground?: boolean;
    isPersonalizationLoading?: boolean;
    loadingText?: string | React.ReactNode;
  }>
> = ({ styledBackground = true, isPersonalizationLoading = false, loadingText = '' }) => {
  return styledBackground ? (
    <StyledBackground>
      <LoadingContainer>
        <LoadingSpinner isLoading={true} size={40} fill={slate1} />
      </LoadingContainer>
    </StyledBackground>
  ) : (
    <PlainBackground>
      {isPersonalizationLoading ? (
        <PersonalizationLoadingContainer>
          <LoadingSpinner isLoading={true} size={40} fill={superRed} />
          {loadingText ? <TextContainer>{loadingText}</TextContainer> : null}
        </PersonalizationLoadingContainer>
      ) : (
        <LoadingContainer>
          <LoadingSpinner isLoading={true} size={40} fill={slate1} />
        </LoadingContainer>
      )}
    </PlainBackground>
  );
};

const StyledBackground = styled(DotsBackground)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PlainBackground = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30vh;
  width: fit-content;
`;

const PersonalizationLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30vh;
`;

const TextContainer = styled.div`
  padding-top: 12px;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  font-weight: bold;
`;

export default LoadingPage;
