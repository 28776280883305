import React from 'react';
import styled from 'styled-components';
import { spaces } from '@engage/styles';
import { header5Small } from '@engage/typography';
import { Button1Small } from '@members/buttons';
import { StorageKeys, upsert } from '@members/env';
import { LocalizedSelect as Select } from '@members/form';
import type {
  ExperimentVariation,
  ExperimentKey,
  ExperimentFeature,
} from '@members/optimizely/useExperimentVariation';
import { useExperimentVariation } from '@members/optimizely/useExperimentVariation';
import type { Feature } from '../toggles';
import { experiments } from '../toggles';

type Props = {
  for: Feature;
};

const isExperimentKey = (f: string): f is keyof typeof experiments =>
  Boolean(experiments[f]);

export const MaybeExperiments: React.FC<React.PropsWithChildren<Props>> = ({
  for: feature,
}) => (isExperimentKey(feature) ? <Experiments for={feature} /> : null);

const Experiments: React.FC<React.PropsWithChildren<{ for: ExperimentFeature }>> = ({
  for: feature,
}) => {
  const experimentsForFeature =
    (Object.keys(experiments).length > 0 && experiments[feature]) || [];
  return (
    <MultirowItem>
      {experimentsForFeature.map(({ key, variations }) => (
        <Experiment key={key} name={key} variations={variations} />
      ))}
    </MultirowItem>
  );
};
const Experiment = ({
  name,
  variations,
}: {
  name: ExperimentKey;
  variations: readonly ExperimentVariation[];
}) => {
  const [value, setValue] = React.useState('');
  const { originalValue, variationName } = useExperimentVariation(name);
  React.useEffect(() => {
    !!variationName && setValue(variationName);
  }, [variationName]);
  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      upsert(StorageKeys.FeatureExperiments, name, value);
      window.location.reload();
    },
    [name, value],
  );
  return (
    <form onSubmit={onSubmit}>
      <Row key={name}>
        <Name>
          {value && value !== originalValue ? ' 🚧 ' : ''}
          {name}
        </Name>
        <ValueSelect
          id={name}
          data-test-id={name}
          label="Choose variation"
          options={Array.from(variations)}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(e.target.value);
          }}
          renderer={v => v}
        />
        <SaveButton disabled={!value} type="submit">
          Save and Reload
        </SaveButton>
      </Row>
    </form>
  );
};
const MultirowItem = styled.li`
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 0 ${spaces.small}px;
  flex-direction: column;
`;
const Row = styled.div`
  width: 100%;
  height: 52px;
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(1fr);
  grid-template-areas: 'key input save';
`;
const Name = styled.label`
  ${header5Small}
  pointer-events: none;
  padding-left: 20px;
  align-self: center;
  grid-area: key;
`;
const ValueSelect = styled(Select)`
  grid-area: input;
`;
const SaveButton = styled(Button1Small)`
  margin-left: 10px;
  grid-area: save;
  align-self: center;
  padding: 2px;
`;
