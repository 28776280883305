import { join } from 'ramda';
import { getLeaderboardWorkoutsDetails } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type GetLeaderboardWorkoutsDetailsParams = {
  workoutIds: string[];
};

export const toPostclassLeaderboardFetchers = (api: typeof engageApi = engageApi) => ({
  GetLeaderboardWorkoutsDetails: toFetcher(
    ({ workoutIds }: GetLeaderboardWorkoutsDetailsParams) =>
      `GetLeaderboardWorkoutDetails${join(',', workoutIds)}`,
    ({ workoutIds }) =>
      unwrap(() => getLeaderboardWorkoutsDetails(api, { ids: workoutIds })),
  ),
});
