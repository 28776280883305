import React from 'react';
import { compose, lifecycle } from 'recompose';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { TrackingComponent } from '@engage/analytics';
import { trackEnhancer } from '@engage/analytics';
import { gray1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { toOpenFBModalEvent } from './analytics';
import type { MaybeBackHandler } from './FacebookModalTopBar';
import FacebookModalTopBar from './FacebookModalTopBar';

const CONTAINER_HEIGHT = 560;

const FixedContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 15px 10px 0;
  ${media.tablet`
    padding: 0 47px ${spaces.large}px;
  `}
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${media.tablet`
    height: ${`${CONTAINER_HEIGHT}px`};
  `}
`;

const Content = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

const FacebookModalContainerView: React.FC<React.PropsWithChildren<MaybeBackHandler>> = ({
  handleBackClick,
  children,
}) => (
  <Container data-test-id="modal">
    <FacebookModalTopBar handleBackClick={handleBackClick} />
    <Content>{children}</Content>
  </Container>
);

const FacebookModalContainer = compose<{}, MaybeBackHandler>(
  trackEnhancer,
  lifecycle<TrackingComponent<{}>, {}>({
    componentDidMount() {
      this.props.track(toOpenFBModalEvent());
    },
  }),
)(FacebookModalContainerView);

export { FixedContainer, CenteredContainer, FacebookModalContainer };
