import type { Reducer } from 'redux';
import type { GuestPassSKU } from '../models/GuestPassSku';

const guestPassSkuReducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Request:
      return {
        ...defaultState,
        isLoading: true,
        isElligibleUser: state.isElligibleUser,
      };

    case ActionType.RequestSuccess:
      return {
        ...state,
        sku: action.payload,
        isLoading: false,
        error: false,
        errorCode: null,
        errorMessage: '',
      };

    case ActionType.RequestFailure:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case ActionType.UpdateElligibleUser:
      return {
        ...state,
        isElligibleUser: action.payload,
      };
    default:
      return state;
  }
};

const defaultState: State = {
  sku: '',
  isLoading: false,
  error: false,
  isElligibleUser: false,
};

type State = {
  sku: string;
  isLoading: boolean;
  error: boolean;
  isElligibleUser: boolean;
};

// Actions

enum ActionType {
  Request = 'pelo/members/guestPass/sku/REQUEST',
  RequestSuccess = 'pelo/members/guestPass/sku/REQUEST_SUCCESS',
  RequestFailure = 'pelo/members/guestPass/sku/REQUEST_FAILURE',
  UpdateElligibleUser = 'pelo/members/guestPass/UPDATE_ELLIGIBILE_USER',
}

type Action =
  | ReturnType<typeof loadGuestPassSku>
  | ReturnType<typeof loadGuestPassSkuSuccess>
  | ReturnType<typeof loadGuestPassSkuFailure>
  | ReturnType<typeof updateElligibleUser>;

const loadGuestPassSku = () =>
  ({
    type: ActionType.Request,
  } as const);

const loadGuestPassSkuSuccess = (data: GuestPassSKU) =>
  ({
    type: ActionType.RequestSuccess,
    payload: data.sku,
  } as const);

const loadGuestPassSkuFailure = () =>
  ({
    type: ActionType.RequestFailure,
  } as const);

const updateElligibleUser = (isElligibleUser: boolean) =>
  ({
    type: ActionType.UpdateElligibleUser,
    payload: isElligibleUser,
  } as const);

// Selectors

const NS = 'memberGuestPassSKU';

export type SkuState = { [NS]: State };

const getGuestPassSku = (state: SkuState) => {
  return state[NS].sku;
};

const getGuestPassSkuError = (state: SkuState) => {
  return state[NS].error;
};

const getIsElligibleGuestPassUser = (state: SkuState) => {
  return state[NS].isElligibleUser ?? false;
};

export {
  NS as GUEST_PASS_SKU_NAMESPACE,
  getGuestPassSku,
  getGuestPassSkuError,
  getIsElligibleGuestPassUser,
  guestPassSkuReducer,
  ActionType as GuestPassSkuActionType,
  loadGuestPassSku,
  loadGuestPassSkuSuccess,
  loadGuestPassSkuFailure,
  updateElligibleUser,
};
