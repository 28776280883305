import type { SagaIterator } from 'redux-saga';
import { call, put, getContext, select, takeEvery, takeLatest } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getUser } from '@peloton/auth';
import {
  fetchChallenges,
  fetchChallengeDetail,
  fetchChallengeFriends,
  joinChallenge,
  leaveChallenge,
} from './api';
import type { Challenge } from './models';
import { ChallengeStatus } from './models';
import type {
  LoadChallengesRequest,
  ToggleJoinedChallengeRequest,
} from './redux/collection';
import {
  ActionType as CollectionActionType,
  loadChallengesFailure,
  loadChallengesSuccess,
  toggleJoinedChallengeSuccess,
} from './redux/collection';
import type { LoadChallengeDetailRequest } from './redux/detail';
import {
  ActionType as DetailActionType,
  loadChallengeDetailFailure,
  loadChallengeDetailSuccess,
} from './redux/detail';
import type { LoadChallengeFriendsRequest } from './redux/friends';
import {
  ActionType as FriendActionType,
  loadChallengeFriendsSuccess,
  loadChallengeFriendsFailure,
} from './redux/friends';

export const loadChallengesRequestSaga = function* (
  client: Client,
  { payload: { status, userId } }: LoadChallengesRequest,
) {
  try {
    const challenges: Challenge[] = yield call(
      fetchChallenges,
      client,
      userId,
      status,
      /**
       * In *most* cases we want to see all "available" challenges (so `hasJoined`, should be `false`)
       * The only instance where this is *not* the case, is `Completed` challenges (where we should only show you challenges you participated in)
       */
      status === ChallengeStatus.Completed,
    );
    yield put(loadChallengesSuccess(status, challenges));
  } catch {
    yield put(loadChallengesFailure(status));
  }
};

export const loadChallengeDetailSaga = function* (
  client: Client,
  { payload: { challengeId } }: LoadChallengeDetailRequest,
): SagaIterator {
  try {
    const { id: userId } = yield select(getUser);
    const detail = yield call(fetchChallengeDetail, client, userId, challengeId);
    yield put(loadChallengeDetailSuccess(detail));
  } catch {
    yield put(loadChallengeDetailFailure());
  }
};

export const loadChallengeFriendsSaga = function* (
  client: Client,
  { payload: { challengeId } }: LoadChallengeFriendsRequest,
): SagaIterator {
  try {
    const { id: userId } = yield select(getUser);
    const friends = yield call(fetchChallengeFriends, client, userId, challengeId);
    yield put(loadChallengeFriendsSuccess(friends));
  } catch (e) {
    yield put(loadChallengeFriendsFailure());
  }
};

export const toggleJoinedChallengeSaga = function* (
  client: Client,
  {
    payload: { challengeId, joining, status, onError = () => null },
  }: ToggleJoinedChallengeRequest,
) {
  try {
    const { id: userId } = yield select(getUser);
    const { progress, participants } = yield call(
      joining ? joinChallenge : leaveChallenge,
      client,
      userId,
      challengeId,
    );
    yield put(toggleJoinedChallengeSuccess(status, challengeId, progress, participants));
  } catch {
    yield call(onError);
  }
};

export const challengesWatcherSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield takeEvery(
    CollectionActionType.LoadChallengesRequest,
    loadChallengesRequestSaga,
    client,
  );

  yield takeLatest(
    DetailActionType.LoadChallengeDetailRequest,
    loadChallengeDetailSaga,
    client,
  );

  yield takeEvery(
    FriendActionType.LoadChallengeFriendsRequest,
    loadChallengeFriendsSaga,
    client,
  );

  yield takeEvery(
    CollectionActionType.ToggleJoinedRequest,
    toggleJoinedChallengeSaga,
    client,
  );
};
