import React from 'react';
import { isEscapeKey } from '@peloton/keyboard';
import { isProdEnv } from '@members/env';
import { useLocalStorageFeatureToggleSync } from '../featureToggles';
import View from './Overlay';
import useEventListener from './useEventListener';

const areAltAndTKeysPressed = (ev?: KeyboardEvent) => ev && ev.altKey && ev.which === 84; // 't' key
const isEscapeKeyPressed = (ev?: KeyboardEvent) => ev && isEscapeKey(ev);

const Overlay: React.FC<React.PropsWithChildren<unknown>> = () => {
  useLocalStorageFeatureToggleSync();
  const [visible, setVisibility] = React.useState(false);

  const keyPress = React.useCallback(
    (e: any) => {
      if (areAltAndTKeysPressed(e) || (isEscapeKeyPressed(e) && visible)) {
        setVisibility(!visible);
      }
    },
    [visible],
  );
  useEventListener('keyup', keyPress);

  return <View isOpen={visible} closeHandler={() => setVisibility(false)} />;
};

const MaybeOverlay: React.FC<React.PropsWithChildren<unknown>> = () =>
  !isProdEnv() ? <Overlay /> : null;

export default MaybeOverlay;
