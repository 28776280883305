import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { hoverTransition, media } from '@peloton/styles';
import { slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label12Caps } from '@engage/typography';
import { TagsCopy, Tags } from '@members/copy';
import { Follow } from '@members/icons';
import { CardContainer } from '@members/members/MemberList';
import { CARD_PADDING_MOBILE, CARD_PADDING } from '@members/members/shared';
import {
  ShareTooltipSource,
  useShareTooltip,
  ShareTagTooltip,
  ShareTooltipContextProvider,
  useTrackShareTag,
} from '@members/share-modal';
import { Position } from './useTooltip';

export const InviteOthersMemberCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const containerRef = React.useRef<HTMLLIElement>(null);
  return (
    <ShareTooltipContextProvider originRef={containerRef}>
      <CardContainer autoSizeColumn={true} isVirtualizedList={true} ref={containerRef}>
        <InviteOthersButton wrapperRef={containerRef} />
        <ShareTagTooltip
          source={ShareTooltipSource.TagDetailInviteOthers}
          position={Position.Top}
        />
      </CardContainer>
    </ShareTooltipContextProvider>
  );
};

export const onLinkClick = (
  isExpanded: boolean,
  trackShareTag: (source: ShareTooltipSource) => void,
  toggle: () => void,
) => {
  if (!isExpanded) {
    trackShareTag(ShareTooltipSource.TagDetailInviteOthers);
  }
  toggle();
};

export const InviteOthersButton: React.FC<
  React.PropsWithChildren<{
    wrapperRef: React.RefObject<Element>;
  }>
> = ({ wrapperRef }) => {
  const targetRef = React.useRef<HTMLDivElement>(null);
  const { toggle, isExpanded } = useShareTooltip(targetRef, Position.Top, wrapperRef);
  const trackShareTag = useTrackShareTag();
  const onClick = () => onLinkClick(isExpanded, trackShareTag, toggle);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid, styled-components-a11y/anchor-is-valid, styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions
    <Link onClick={onClick} data-test-id="inviteOthersCta">
      <FollowContainer ref={targetRef}>
        <StyledFollow />
      </FollowContainer>
      <Text>
        <TagsCopy id={Tags.InviteOthersCta} />
      </Text>
    </Link>
  );
};

const FollowContainer = styled.div``;

const Text = styled.div`
  margin-left: ${spaces.small}px;
  ${label12Caps};
  color: ${slate1};

  ${hoverTransition({
    property: 'color',
    to: darken(0.15, slate1),
  })}
`;

const Link = styled.a`
  width: 340px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${CARD_PADDING_MOBILE}px 0;
  ${media.tablet`
    padding: ${CARD_PADDING}px 0;
  `}
`;

const StyledFollow = styled(Follow)`
  & circle {
    fill-opacity: inherit;
  }

  ${Link} & use.overlay {
    transition-property: fill-opacity;
    transition-duration: 250ms;
    transition-timing-function: ease;
    fill-opacity: 0.15;
  }

  ${Link}:hover & use.overlay {
    fill-opacity: 0.3;
  }
`;
