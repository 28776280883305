import { useEffect } from 'react';
import { getUserId } from '@peloton/auth';
import { setDisplayLanguage } from '@peloton/time';
import { isDefined } from '@peloton/types';
import { useReduxState, useReduxAction } from '@engage/redux';
import { getDisplayLanguage, updateDisplayLanguage } from '@engage/settings';
import { getPersistedOrBrowserDisplayLanguage } from './persistLanguage';
import { toSupportedBrowserLanguage } from './toSupportedBrowserLanguage';

export const LocaleSetter = ({ children }: { children: JSX.Element }) => {
  const userId = useReduxState(getUserId);
  const displayLanguage = useReduxState(getDisplayLanguage);
  const updateDisplayLanguageAction = useReduxAction(updateDisplayLanguage);

  useEffect(() => {
    if (userId) {
      const storedDisplayLanguage = getPersistedOrBrowserDisplayLanguage()(userId);
      updateDisplayLanguageAction(storedDisplayLanguage);
    }
  }, [userId]);

  useEffect(() => {
    if (isDefined(displayLanguage)) {
      setDisplayLanguage(displayLanguage);
    } else if (!userId) {
      const supportedBrowserLanguage = toSupportedBrowserLanguage();
      updateDisplayLanguageAction(supportedBrowserLanguage);
      setDisplayLanguage(supportedBrowserLanguage);
    }
  }, [displayLanguage]);

  return children;
};
