/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Create a new user_partnership
 * Create a user_partnership using a valid partnership identifier.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const createUserPartnership = (
    api: BaseAPI,
    requestBody: {
        /**
         * The partner identifier.
         */
        partnerId?: 'vitality' | 'uhc',
        /**
         * The user's partner membership identifier.
         */
        partnerMembershipId?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * The peloton user identifier.
     */
    userId?: string,
    /**
     * The partner numeric enum constant.
     */
    partnerEnum?: 0 | 1,
}> => {
    const localVarPath = `/api/user_partnership/activate`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get a user's vitality membership number
 * Get a user's vitality membership number
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserPartnershipVitality = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * The user's vitality membership number.
     */
    vitalityMembershipId?: string,
}> => {
    const localVarPath = `/api/user_partnership/vitality`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create a vitality user_partnership
 * Create a user_partnership using vitality as the partner_enum.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const createUserPartnershipVitality = (
    api: BaseAPI,
    requestBody: {
        /**
         * The user's vitality membership number.
         */
        vitalityMembershipId?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * The user's vitality membership number.
     */
    vitalityMembershipId?: string,
}> => {
    const localVarPath = `/api/user_partnership/vitality/activate`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

