import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import type { Reducer } from 'redux';

const ViewedMemberSuccessActionType = 'pelo/viewedMember/Success';

export const viewedMemberReducer: Reducer<State> = (state = null, action: Action) => {
  switch (action.type) {
    case ViewedMemberSuccessActionType:
      return action.payload;
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

type State = string | null;

export type ViewedMemberSelectorState = {
  viewedMember: State;
};

type Action = UpdateViewedMemberAction | LocationChangeAction;

type UpdateViewedMemberAction = {
  type: typeof ViewedMemberSuccessActionType;
  payload: string;
};

export const updateViewedMember = (id: string) => ({
  type: ViewedMemberSuccessActionType,
  payload: id,
});

export const getViewedMember = (state: ViewedMemberSelectorState) => state.viewedMember;
