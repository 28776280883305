/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeaderboardWorkoutDetails } from '../../../api-v2-models/generated/LeaderboardWorkoutDetails';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Get details to display one page of workouts for ride
 * Return details needed to display one page of workouts for a ride. Only includes completed workouts. Can be called with at least one of (workout_id, before, or after) to determine what page of the leaderboard to get. If workout_id and before or after are provided, then the page will be centered by before or after and the workout will be inserted if it is missing from the leaderboard.
 * @param id The ride id
 * @param limit Size of page to return
 * @param workoutId Return a page including the workout with this id. If possible, the page will be centered on this workout. It may not be possible if the workout is too close to the beginning or the end of the leaderboard.
 * @param before Return a page of workouts before the current page (with smaller ranks). This value comes from the "previous" field on the response from the current page.
 * @param after Return a page of workouts after the current page (with larger ranks). This value comes from the "next" field on the response from the current page.
 * @returns any Successful response
 * @throws ApiError
 */
export const getRideLeaderboardWorkoutsDetails = (
    api: BaseAPI,
    id: string,
    limit?: number,
    workoutId?: string,
    before?: string,
    after?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * Details need to display the workouts on the leaderboard
     */
    data?: Array<(LeaderboardWorkoutDetails)>,
    /**
     * Total number of workouts on the leaderboard
     */
    total?: number,
    /**
     * A non-human readable value to pass as the "before" parameter of this endpoint to get the page of leaderboard workouts before this one. If this is null, the previous page cannot be fetched
     */
    previous?: string,
    /**
     * A non-human readable value to pass as the "after" parameter of this endpoint to get the page of leaderboard workouts after this one. If this is null, the next page cannot be fetched
     */
    next?: string,
}> => {
    const localVarPath = `/stats/v2/ride/${id}/leaderboard/summary`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof workoutId !== 'undefined') {
        localVarQueryParameter['workout_id'] = workoutId;
    }
    if(typeof before !== 'undefined') {
        localVarQueryParameter['before'] = before;
    }
    if(typeof after !== 'undefined') {
        localVarQueryParameter['after'] = after;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get leaderboard details for a list of workout ids
 * Get details to display for workouts on the leaderboard. A maximum of 250 workout ids at a time are supported.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const getLeaderboardWorkoutsDetails = (
    api: BaseAPI,
    requestBody: {
        /**
         * List of workout IDs
         */
        ids?: Array<string>,
        /**
         * Boolean string to determine whether or not to add authed_user_follows to each detail
         */
        addAuthedInfo?: boolean,
        /**
         * List of strings that correspond with metrics data to be included in the response
         */
        includedMetricTypes?: 'heart_rate_zones',
    },
    options: any = {},
): AxiosPromise<{
    /**
     * Details for all of the workouts keyed on workout id
     */
    data?: Record<string, LeaderboardWorkoutDetails>,
}> => {
    const localVarPath = `/stats/workouts/details`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

