/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstructorLeaderboardPresence } from '../../../api-v2-models/generated/InstructorLeaderboardPresence';
import type { PresenceWindowResponse } from '../../../api-v2-models/generated/PresenceWindowResponse';
import type { RidePresence } from '../../../api-v2-models/generated/RidePresence';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Returns presence roster for the class
 * Returns a window for the class' current roster. This is not in the monolith API, but in its own service
 * @param classId
 * @param requestBody
 * @returns PresenceWindowResponse Successful response
 * @throws ApiError
 */
export const getPresenceWindow = (
    api: BaseAPI,
    classId: string,
    requestBody: {
        /**
         * The id of the workout to center the window around
         */
        centerWorkoutId?: string,
        /**
         * Size of the window
         */
        windowSize?: number,
    },
    options: any = {},
): AxiosPromise<PresenceWindowResponse> => {
    const localVarPath = `/v1/class/${classId}/window`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Returns information for instructor leaderboard to display presence data
 * Returns information for instructor leaderboard to display presence data. The ride channel and group are used to display users by platform.  The other channels are used to apply the instructor leaderboard filters.
 * @param pelotonInstructorApiKey API key to access studio_details. See an API developer to get a valid key.
 * @param requestBody
 * @returns InstructorLeaderboardPresence Successful response
 * @throws ApiError
 */
export const getInstructorLeaderboardPresenceSession = (
    api: BaseAPI,
    pelotonInstructorApiKey: string,
    requestBody: {
        /**
         * The id of the ride
         */
        rideId?: string,
        /**
         * Shoudld a new auth token be requested
         */
        requestAuthToken?: boolean,
    },
    options: any = {},
): AxiosPromise<InstructorLeaderboardPresence> => {
    const localVarPath = `/api/presence/instructor_leaderboard_session`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Instructor-Api-Key'] = String(pelotonInstructorApiKey);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Rebuilds a user's presence following group
 * Rebuilds a user's presence following group. Following group is used to monitor the presence of people you follow
 * @param userId identifier for the user
 * @returns any Successful response
 * @throws ApiError
 */
export const getRebuildFollowingGroup = (
    api: BaseAPI,
    userId: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/presence/${userId}/rebuild_following_group`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Returns information to register with presence status
 * Returns information for registering presence status. The data includes a list of channels and an auth token with permission to subscribe to and read from the channels. auth_token will be null when PubNub service is unavailable.
 * @param requestBody
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @returns RidePresence Successful response
 * @throws ApiError
 */
export const getPresenceSession = (
    api: BaseAPI,
    requestBody: {
        /**
         * The id of the workout
         */
        workoutId?: string,
        /**
         * Should a new auth token be requested
         */
        requestAuthToken?: boolean,
    },
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    options: any = {},
): AxiosPromise<RidePresence> => {
    const localVarPath = `/api/presence/session`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

