import React from 'react';
import { slate3 } from '@engage/colors';

export const Search: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.3337 43.9998C35.643 43.9998 44.0003 35.6425 44.0003 25.3332C44.0003 15.0239 35.643 6.6665 25.3337 6.6665C15.0243 6.6665 6.66699 15.0239 6.66699 25.3332C6.66699 35.6425 15.0243 43.9998 25.3337 43.9998Z"
      stroke={slate3}
      strokeMiterlimit="10"
    />
    <path
      d="M36.0003 34.6668V31.9926C36.0003 30.6554 34.9641 30.1084 34.6594 29.9869C31.2156 28.6497 28.3203 27.7077 27.9851 26.6744V25.3373C28.7165 24.6079 28.9908 23.8786 29.326 22.663C30.667 21.9945 30.6671 20.8397 29.9966 19.9888V17.9831C29.9966 15.4911 28.4422 13.3335 25.3337 13.3335C22.2251 13.3335 20.6707 15.4911 20.6707 17.9831V19.9888C20.0002 20.8093 20.0003 21.9945 21.3413 22.663C21.6765 23.8482 21.9508 24.5776 22.6822 25.3373V26.6744C22.347 27.7077 19.4822 28.6497 16.008 29.9869C15.7032 30.1084 14.667 30.6554 14.667 31.9926V34.6668"
      stroke={slate3}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 41.3333L50.5858 55.9191C51.3668 56.7002 52.6332 56.7002 53.4142 55.9191L55.9191 53.4142C56.7002 52.6332 56.7002 51.3668 55.9191 50.5858L41.3333 36"
      stroke={slate3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
