import { toBaseProperties } from '@members/analytics';
import type { NotificationSource } from './constants';
import { FEED_SOURCE, FEED_NOTIFICATIONS_SOURCE } from './constants';

export interface TrackHighFiveParams {
  feedPostId: string;
  workoutId: string;
  fitnessDiscipline: string;
  workoutCategory: string;
  feedPostUser: string;
  numHighFives: number;
}

export const trackViewedFeed = (
  isEmpty: boolean,
  numberOfPosts: number,
  source: string,
) => ({
  event: 'Viewed Feed',
  source: source,
  '[Is Empty]': isEmpty,
  '[Number of Posts]': numberOfPosts,
  ...toBaseProperties(),
});

export const trackHighFived = ({
  feedPostId,
  feedPostUser,
  fitnessDiscipline,
  numHighFives,
  workoutCategory,
  workoutId,
}: TrackHighFiveParams) => ({
  event: 'High Fived Feed Post',
  source: FEED_SOURCE,
  '[Feed Post ID]': feedPostId,
  '[Feed Post Type]': 'Workout',
  '[Workout ID]': workoutId,
  '[Workout Fitness Discipline]': fitnessDiscipline,
  '[Workout Category]': workoutCategory,
  '[Feed Post User]': feedPostUser,
  '[Number of High Fives]': numHighFives,
  ...toBaseProperties(),
});

export const trackRemovedHighFive = ({
  feedPostId,
  feedPostUser,
  fitnessDiscipline,
  numHighFives,
  workoutCategory,
  workoutId,
}: TrackHighFiveParams) => ({
  event: 'Removed Feed High Five',
  source: FEED_SOURCE,
  '[Feed Post ID]': feedPostId,
  '[Feed Post Type]': 'Workout',
  '[Workout ID]': workoutId,
  '[Workout Fitness Discipline]': fitnessDiscipline,
  '[Workout Category]': workoutCategory,
  '[Feed Post User]': feedPostUser,
  '[Number of High Fives]': numHighFives,
  ...toBaseProperties(),
});

export const trackDisplayedTooltip = () => ({
  event: 'Displayed Tooltip',
  source: 'Classes',
  '[Tooltip Name]': 'Activity Feed',
  '[Tooltip View Count]': 1,
  ...toBaseProperties(),
});

export const trackLeftFeed = (timeSpent: number, totalHighFivesSent: number) => ({
  event: 'Left Feed',
  source: FEED_SOURCE,
  '[Time Spent on Feed]': timeSpent,
  '[Total Feed High Fives Sent]': totalHighFivesSent,
  ...toBaseProperties(),
});

export const trackViewedFeedHighFiveList = (source: string) => ({
  event: 'Viewed Feed High Five List',
  source: source,
  ...toBaseProperties(),
});

export const trackRefreshedFeed = (oldEdgesLength: number, newEdgesLength: number) => ({
  event: 'Refreshed Feed',
  '[Old Number of Posts]': oldEdgesLength,
  '[New Number of Posts]': newEdgesLength,
  source: FEED_SOURCE,
  ...toBaseProperties(),
});

export const trackViewedFeedNotifications = (
  unreadNotifications: boolean,
  numberOfNotifications: number,
  source: NotificationSource,
) => ({
  event: 'Viewed Feed Notifications',
  source: source,
  '[Unread Notifications]': unreadNotifications,
  '[Number of Notifications]': numberOfNotifications,
  ...toBaseProperties(),
});

export const trackViewedFeedPostDetails = (
  feedPostId: string,
  feedPostType: string,
  workoutId: string,
  workoutFitnessDiscipline: string,
  workoutCategory: string,
  feedPostUser: string,
  highFivedUsers: number,
  privateProfile: boolean,
) => ({
  event: 'Viewed Feed Post Details',
  source: FEED_NOTIFICATIONS_SOURCE,
  '[Feed Post ID]': feedPostId,
  '[Feed Post Type]': feedPostType,
  '[Workout ID]': workoutId,
  '[Workout Fitness Discipline]': workoutFitnessDiscipline,
  '[Workout Category]': workoutCategory,
  '[Feed Post User]': feedPostUser,
  '[Number of High Fives]': highFivedUsers,
  '[Private Profile]': privateProfile,
  ...toBaseProperties(),
});
