import type { Language } from '@peloton/internationalize';
import { languageCodeToName } from '@peloton/internationalize';
import type { Time } from '@peloton/time';
import {
  formatDurationMinutes,
  toIsoString,
  formatDateDashes,
  formatDateTime,
} from '@peloton/time';
import type { MovementMuscleGroup } from '@engage/api-v2';
import { toMovementsAnalytics } from './toMovements';

export type ClassAnalyticsProperties = {
  id: string;
  title: string;
  scheduledStartTime?: Time;
  duration: number;
  fitnessDiscipline: string;
  instructorName?: string;
  classType?: string;
  totalWorkouts?: number;
  contentFormat: string;
  contentProvider?: string;
  totalUserWorkouts?: number;
  totalFollowingWorkouts?: number;
  difficultyLevel?: string;
  nativeLanguage?: string;
  captionLanguages: Language[];
  classIntroLength: number | null;
  classMovements?: string[];
  classBodyFocus?: MovementMuscleGroup[];
  classMovementGroups?: string[];
  classExplicitRating?: number;
  explicitPercentage?: number;
};

type SegmentClassAnalyticsProperties = {
  '[Class ID]': string;
  classTitle: string;
  classInstructorName?: string;
  classFitnessDiscipline: string;
  classLength: number;
  '[Class Air Date/Time]'?: string;
  classAirDate?: string;
  classAirTime?: string;
  classTypes?: string;
  classTotalWorkouts?: number;
  contentFormat: string;
  contentProvider?: string;
  hasTakenClass: boolean;
  numberOfFollowerWorkouts?: number;
  classDifficulty?: string;
  classNativeLanguage?: string;
  classSubtitlesLanguages: string[];
  classIntroLength: number | null;
  classMovements?: string[];
  classBodyFocus?: MovementMuscleGroup[];
  classMovementGroups?: string[];
  classExplicitRating?: string;
  explicitPercentage?: number;
};

const toExplicitLevel = (classExplicitRating: number) =>
  classExplicitRating === 0
    ? 'Not Explicit'
    : classExplicitRating === 1
    ? 'Explicit'
    : 'Very Explicit';

const isExplicit = (classExplicitRating: number | undefined) =>
  classExplicitRating === 0 || !classExplicitRating ? false : true;

export const toClassAnalytics = (
  classInfo: ClassAnalyticsProperties | undefined,
): SegmentClassAnalyticsProperties | {} =>
  classInfo
    ? {
        '[Class ID]': classInfo.id,
        classTitle: classInfo.title,
        classInstructorName: classInfo.instructorName,
        classFitnessDiscipline: classInfo.fitnessDiscipline,
        classLength: formatDurationMinutes(classInfo.duration),
        '[Class Air Date/Time]':
          classInfo.scheduledStartTime && toIsoString(classInfo.scheduledStartTime),
        classAirDate:
          classInfo.scheduledStartTime && formatDateDashes(classInfo.scheduledStartTime),
        classAirTime:
          classInfo.scheduledStartTime &&
          formatDateTime(classInfo.scheduledStartTime).toUpperCase(),
        classIntroLength: classInfo.classIntroLength,
        classTypes: classInfo.classType,
        contentFormat: classInfo.contentFormat,
        contentProvider: classInfo.contentProvider,
        classTotalWorkouts: classInfo.totalWorkouts ? classInfo.totalWorkouts : 0,
        hasTakenClass: classInfo.totalUserWorkouts
          ? classInfo.totalUserWorkouts > 0
          : false,
        numberOfFollowerWorkouts: classInfo.totalFollowingWorkouts
          ? classInfo.totalFollowingWorkouts
          : 0,
        classDifficulty: classInfo.difficultyLevel,
        classNativeLanguage: classInfo.nativeLanguage,
        classSubtitlesLanguages: classInfo?.captionLanguages?.map(
          language => languageCodeToName[language],
        ),
        ...toMovementsAnalytics(classInfo),
        '[Explicit Level]': toExplicitLevel(classInfo.classExplicitRating ?? 0),
        '[Is Explicit]': isExplicit(classInfo.classExplicitRating),
        '[Percent Tracks Explicit]': classInfo.explicitPercentage,
      }
    : {};
