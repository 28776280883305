import { useEffect, useRef } from 'react';
import { isDefined } from '@peloton/types';

function useEventListener(
  eventName: string,
  handler: EventListener,
  element: Window | Document = window,
) {
  const savedHandler = useRef<EventListener | undefined>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (savedHandler.current) {
      const isSupported = element?.addEventListener;
      if (!isDefined(isSupported)) {
        return;
      }

      const eventListener = (event: Event) => savedHandler.current!(event);
      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    }

    return;
  }, [eventName, element]);
}

export default useEventListener;
