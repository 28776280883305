/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveWorkout } from '../../../api-v2-models/generated/ActiveWorkout';
import type { CreateWorkoutRequest } from '../../../api-v2-models/generated/CreateWorkoutRequest';
import type { FollowAlongScoreData } from '../../../api-v2-models/generated/FollowAlongScoreData';
import type { InProgressRide } from '../../../api-v2-models/generated/InProgressRide';
import type { InstagramStoryResponse } from '../../../api-v2-models/generated/InstagramStoryResponse';
import type { MovementData } from '../../../api-v2-models/generated/MovementData';
import type { PacketCreate } from '../../../api-v2-models/generated/PacketCreate';
import type { PaginationResponse } from '../../../api-v2-models/generated/PaginationResponse';
import type { Peloton } from '../../../api-v2-models/generated/Peloton';
import type { Ride } from '../../../api-v2-models/generated/Ride';
import type { Scorecard } from '../../../api-v2-models/generated/Scorecard';
import type { Workout } from '../../../api-v2-models/generated/Workout';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Get Workout Instagram Share Story Asset
 * Get story asset from workout for Instagram share
 * @param workoutId ID for the workout
 * @returns any Story asset url and type, and default image url
 * @throws ApiError
 */
export const workoutInstagramStoryAsset = (
    api: BaseAPI,
    workoutId: string,
    options: any = {},
): AxiosPromise<(InstagramStoryResponse)> => {
    const localVarPath = `/api/workout/${workoutId}/instagram_story_background`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Finalize Workout
 * Finalizes a workout and returns updated fields.
 * @param workoutId ID for the workout
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param requestBody
 * @returns any Workout queued for finalization
 * @throws ApiError
 */
export const finalizeWorkout = (
    api: BaseAPI,
    workoutId: string,
    acceptLanguage?: string,
    requestBody?: {
        /**
         * Length of the workout in seconds (ignored for digital workouts)
         */
        duration?: number,
        /**
         * for workouts with movements, the number of achieved trackable movements
         */
        followedAlongMovements?: number,
        /**
         * for workouts with movements, the number of subsegment ids for all movements where tracking was disabled
         */
        untrackedMovements?: number,
        finalPacket?: PacketCreate,
        /**
         * for workouts with movements, the follow along score data for each subsegment.
         */
        followAlongScoreData?: Array<FollowAlongScoreData>,
        /**
         * movements completed during workout.
         */
        movementsData?: Array<MovementData>,
    },
    options: any = {},
): AxiosPromise<(Workout)> => {
    const localVarPath = `/api/workout/${workoutId}/end`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Gets all in progress workouts of a user
 * This endpoint is marked as deprecated, but it will not be removed until we can make sure all
 * clients have stopped using it. All future usage should all point to /user/in_progress.
 * Returns information about the in progress workouts of the logged in user
 * and the users that user follows.
 *
 * This returns only workouts that can be taken on the
 * logged in user's current device, and only those that are visible on the
 * leaderboard for that class. In particular:
 * * Only Bike and Tread workouts are returned (digital don't show up on the leaderboard)
 * * No Just Ride/Run (don't have a leaderboard)
 * @param id
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param limit Number of workouts returned per page
 * @param page Page number
 * @returns any A list of workouts
 * @throws ApiError
 */
export const getInProgressWorkouts = (
    api: BaseAPI,
    id: string,
    acceptLanguage?: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${id}/in_progress`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get Workout Details
 * Get workout details, including mapping, ride mapping (for non-freestyle workouts), leaderboard rank, total leaderboard users, achievement templates and personal records associated with the workout
 * @param workoutId ID for the workout
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getWorkout = (
    api: BaseAPI,
    workoutId: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<(Workout)> => {
    const localVarPath = `/api/workout/${workoutId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Delete Workout
 * Delete workout by id.
 * Segment analytics Deleted Workout Event will be triggered by this endpoint
 * @param workoutId workout identifier
 * @returns any Successful response
 * @throws ApiError
 */
export const deleteWorkout = (
    api: BaseAPI,
    workoutId: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/workout/${workoutId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets all in progress workouts of a user
 * Returns user's workouts that are considered in-progress that were started less than the longest possible workout duration (longest class duration plus allowed paused time). Freestyle workouts that were started before the cutoff will not be returned. On hardware device, only workouts that can be taken on the logged in user's current device will be returned. On non-hardware device, workouts that can be taken or be visible on the logged in user's current device will be returned.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param limit Number of workouts returned per page
 * @param page Page number
 * @returns any A list of workouts
 * @throws ApiError
 */
export const getUserInProgressWorkouts = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/in_progress`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get scorecard for a user workout
 * Return the rank and data of the user workout
 * @param pelotonId
 * @param workoutId
 * @param unitType unit type to show on the scorecard
 * @returns Scorecard A dictionary of data inside the scorecard
 * @throws ApiError
 */
export const getPelotonWorkoutScorecard = (
    api: BaseAPI,
    pelotonId: string,
    workoutId: string,
    unitType?: 'imperial' | 'metric',
    options: any = {},
): AxiosPromise<Scorecard> => {
    const localVarPath = `/stats/peloton/${pelotonId}/workout/${workoutId}/v2/scorecard`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof unitType !== 'undefined') {
        localVarQueryParameter['unit_type'] = unitType;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Upload diagnostic data to save in S3
 * Allow client devices to upload diagnostic data
 * @param workoutId ID for the workout
 * @param requestBody Payload of metrics to be put in S3
 * @returns any Metrics successfully wrote to S3
 * @throws ApiError
 */
export const uploadRawDiagnosticData = (
    api: BaseAPI,
    workoutId: string,
    requestBody: any,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/workout/${workoutId}/diagnostics`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get workout data needed to service requests in other services
 * Returns subset of Workout + Peloton model for a list of IDs (internal use only)
 * @param pelotonTotpToken An 8-digit TOTP (time-based HMAC one-time password) using SHA1. Please contact the API team for
 * the shared secret.
 *
 * @param requestBody
 * @returns any Successful Response
 * @throws ApiError
 */
export const postWorkoutsService = (
    api: BaseAPI,
    pelotonTotpToken: string,
    requestBody?: {
        /**
         * List of workout IDs to fetch data for
         */
        workoutIds?: Array<string>,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * Details for all of the workouts keyed on workout id
     */
    workouts?: Record<string, {
        /**
         * unique identifier for the class a workout is associated with
         */
        classId?: string,
        /**
         * unique identifier for the user who created the workout
         */
        userId?: string,
        /**
         * epoch timestamp representing the workout creation time
         */
        createdAt?: number,
        /**
         * represents the type of class
         */
        workoutType?: 'class' | 'scenic' | 'freestyle',
        /**
         * indicates if this workout generates metrics applicable to a leaderboard
         */
        hasLeaderboardMetrics?: boolean,
        /**
         * the peloton id of the workout
         */
        pelotonId?: string,
        /**
         * the peloton id of the home peloton associated with a live studio workout. only populated if the workout is a live studio workout
         */
        homePelotonId?: string,
        /**
         * epoch timestamp of when the workout would be expected to start sending metrics
         */
        metricsStartTime?: number,
        /**
         * epoch timestamp of when the workout would be expected to stop sending metrics
         */
        metricsEndTime?: number,
        /**
         * epoch timestamp of when the workout started
         */
        startTime?: number,
        /**
         * epoch timestamp of when the workout is expected to or has ended
         */
        endTime?: number,
        /**
         * the duration (in seconds) that this workout is expected to send metrics
         */
        metricsDuration?: number,
        /**
         * the context in which a workout is taken
         */
        leaderboardType?: 'on_demand' | 'live_studio' | 'live_home' | 'encore' | 'session',
        /**
         * whether the workout is finalized or not
         */
        isFinalized?: boolean,
    }>,
    /**
     * List of workout ids passed in that were not found. This field being populated indicates application error in the requesting service
     */
    workoutIdsNotFound?: Array<string>,
}> => {
    const localVarPath = `/stats/internal/workouts`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-TOTP-Token'] = String(pelotonTotpToken);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get Workout Instagram Share Image
 * Get sticker image from workout for Instagram share
 * @param workoutId ID for the workout
 * @param shareDestination share to instagram story
 * @param stickerVersion version of instagram sticker to return
 * @returns any the image
 * @throws ApiError
 */
export const workoutInstagramImage = (
    api: BaseAPI,
    workoutId: string,
    shareDestination?: string,
    stickerVersion?: 'v1' | 'remix',
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/workout/${workoutId}/instagram_image`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof shareDestination !== 'undefined') {
        localVarQueryParameter['share_destination'] = shareDestination;
    }
    if(typeof stickerVersion !== 'undefined') {
        localVarQueryParameter['sticker_version'] = stickerVersion;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets all in progress workouts of a user and the users that user follows
 * Returns information about the in progress workouts of the logged in user
 * and the users that user follows.
 *
 * For this user's workouts, they are returned as an array with the
 * workout, ride, and peloton.
 *
 * For other users' workouts, they are grouped by ride. For each ride, we
 * return information about the ride and information about all of the users
 * the logged in user follows who are taking a workout for that ride.
 *
 * Additionally, we return the current live peloton for the ride, if there
 * is one, otherwise the field is null.
 *
 * This only returns a subset of the workouts of users the logged in user
 * follows. In general, it returns only workouts that can be taken on the
 * logged in user's current device, and only those that are visible on the
 * leaderboard for that class. In particular:
 * * Only Bike and Tread workouts are returned (digital don't show up on the leaderboard)
 * * No Just Ride/Run (don't have a leaderboard)
 *
 * The order of the rides returned is from most following users to fewest.
 *
 * The order of the users in a given ride is from earliest created workout
 * to latest.
 *
 * If this endpoint is called for anyone but the logged in user, returns an
 * error.
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserAndFollowingInProgress = (
    api: BaseAPI,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * All of this user's in progress workouts along with their ride and peloton.
     */
    self?: Array<{
        peloton?: Peloton,
        ride?: Ride,
        workout?: Workout,
    }>,
    all?: Array<InProgressRide>,
    /**
     * Total number of users who this user follows who have an in progress workout
     */
    totalFollowingUsers?: number,
}> => {
    const localVarPath = `/api/user/in_progress/all`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get Workout Details (Alias)
 * Note: This is an alias of GET /api/workout/{workout_id}. Get workout details, including mapping, ride mapping (for non-freestyle workouts), leaderboard rank, total leaderboard users, achievement templates and personal records associated with the workout
 * @param workoutId ID for the workout
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getWorkoutDetails = (
    api: BaseAPI,
    workoutId: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<(Workout)> => {
    const localVarPath = `/api/workout/${workoutId}/details`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get Workout Metric Image
 * Get workout metric image
 * @param workoutId ID for the workout
 * @param excludeMetrics comma separated list of metrics to exclude
 * @returns any the image
 * @throws ApiError
 */
export const workoutMetricGraphic = (
    api: BaseAPI,
    workoutId: string,
    excludeMetrics?: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/workout/metric_graphic/${workoutId}.png`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof excludeMetrics !== 'undefined') {
        localVarQueryParameter['exclude_metrics'] = excludeMetrics;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Active Workout
 * Returns the ID and Peloton ID of an active workout for the logged in user given a join token. If there is no active workout for the user and join token, returns null for workout ID. Only used internally by the Workouts service.
 * @param joinTokenStr identifier for active workout
 * @param pelotonTotpToken An 8-digit TOTP (time-based HMAC one-time password) using SHA1. Please contact the API team for
 * the shared secret.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @returns ActiveWorkout Successful response
 * @throws ApiError
 */
export const getActiveWorkout = (
    api: BaseAPI,
    joinTokenStr: string,
    pelotonTotpToken: string,
    pelotonClientDetails?: string,
    options: any = {},
): AxiosPromise<ActiveWorkout> => {
    const localVarPath = `/api/internal/active_workout/${joinTokenStr}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-TOTP-Token'] = String(pelotonTotpToken);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create Workout
 * Creates a workout for the logged in user for the specified peloton.
 * Notice iOS workouts has a 30 minutes buffer before finalization due to ability to pause workout on the platform.
 * Segment analytics Started Workout Event will be triggered by this endpoint.
 * This endpoint will restrict the creation of a workout for a ride which is outside a logged in user's availability zone.
 * @param pelotonClientDate Timestamp on the user's device in ISO-8601 format. It is recommended to add the IANA
 * time zone in addition to the time zone offset. i.e. the full TZD would be "+05:30[Asia/Kolkata]"
 * See "TZ database name" for IANA zone:
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * @param requestBody
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns Workout Successful response
 * @throws ApiError
 */
export const createWorkout = (
    api: BaseAPI,
    pelotonClientDate: string,
    requestBody: CreateWorkoutRequest,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<Workout> => {
    const localVarPath = `/api/workout`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Client-Date'] = String(pelotonClientDate);
    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

