import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { toMapStateToLinkProps } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { getIsBreakpointGreaterThan } from '@peloton/responsive';
import { gray1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { getMember, ME } from '@engage/members';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { useAcceptTerms, AcceptTerms } from '@members/copy';
import { UsersFetchers } from '@members/data-fetch';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import { isPathAllowed, Modal } from '@members/modal';
import { AcceptTerms as AcceptTermsContent } from './AcceptTerms';
import { getUnacceptedContracts, getUnacknowledgedContracts } from './selectors';
import { UnacknowledgedContent } from './UnacknowledgedContent';
import { useContracts } from './useContracts';
import { useUpdatePolicyAcknowledgements } from './useUpdatePolicyAcknowledgements';

export const AcceptTermsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const myself = useReduxState(getMember, ME);
  const memberId = myself?.id ?? '';

  const { data, mutate } = useSWR(
    ...UsersFetchers.GetIsUserOldEnough({ shouldSkipFetch: !memberId }),
  );

  const contentLabel = useAcceptTerms(AcceptTerms.AcceptTerms);

  const isLargeDesktop = useReduxState(getIsBreakpointGreaterThan, 'desktopLarge');
  const unacceptedContracts = useReduxState(getUnacceptedContracts);
  const unacknowledgedContracts = useReduxState(getUnacknowledgedContracts);
  const links = useReduxState(
    toMapStateToLinkProps({
      onePeloton: Links.peloton,
      privacy: Links.privacy,
      membershipTerms: Links.membershipTerms,
    }),
  );
  const contracts = useContracts(unacceptedContracts.concat(unacknowledgedContracts));
  const [isOpen, setIsOpen] = useState(false);
  const [isDisplay, setIsDisplay] = useState(true);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false); // redundant but if api contract update fails the user will still be able to proceed
  const shouldShowAgeGating = !!data && !data?.oldEnough;
  const disableAcceptTerms = useFeatureToggle(Feature.Disableacceptterms);
  const enablePrivacyAcknowledgement = useFeatureToggle(
    Feature.PrivacyPolicyAcknowledgement,
  );
  const [isUnacknowledgedOpen, setIsUnacknowledgedOpen] = useState(false);

  const updatePolicyAcknowledgements = useUpdatePolicyAcknowledgements();
  useEffect(() => {
    if (
      enablePrivacyAcknowledgement &&
      unacknowledgedContracts.length > 0 &&
      unacceptedContracts.length === 0 &&
      !disableAcceptTerms &&
      isPathAllowed(window.location.pathname)
    ) {
      // as soon as the acknowledge modal is shown
      // a PUT request should be sent to the backend
      // (regardless of whether the member clicks the acknowlege button)
      updatePolicyAcknowledgements(memberId, unacknowledgedContracts);
      setIsUnacknowledgedOpen(true);
    }
  }, [unacknowledgedContracts]);

  useEffect(() => {
    setIsOpen(
      !disableAcceptTerms &&
        ((unacceptedContracts.length > 0 && !hasAcceptedTerms && !!memberId) ||
          shouldShowAgeGating) &&
        isPathAllowed(window.location.pathname),
    );
  }, [unacceptedContracts, hasAcceptedTerms, memberId, shouldShowAgeGating]);

  // sets accept terms modal as the top layer (appears above other modals)
  const overlayRef = (ref: HTMLDivElement) => {
    if (ref && ref?.style) {
      ref.style.zIndex = '5';
    }
  };

  const onAcknowledgePolicies = () => {
    setIsUnacknowledgedOpen(false);
  };

  return (
    <Modal
      contentLabel={contentLabel}
      isOpen={isOpen || isUnacknowledgedOpen}
      closeHandler={onAcknowledgePolicies}
      hideCloseButton={!isUnacknowledgedOpen}
      contentContainerStyle={{ backgroundColor: gray1, display: isDisplay ? '' : 'none' }}
      style={{ maxWidth: isLargeDesktop ? '560px' : '480px' }}
      overlayRef={overlayRef}
    >
      <Container data-test-id="acceptTermContainer">
        <If condition={isUnacknowledgedOpen}>
          <UnacknowledgedContent
            privacyLink={links.privacy}
            contractAgreements={unacknowledgedContracts}
            onAcknowledge={onAcknowledgePolicies}
          />
        </If>
        <If condition={!isUnacknowledgedOpen}>
          <AcceptTermsContent
            updateOldEnoughCache={mutate}
            contracts={contracts}
            onePelotonUrl={links.onePeloton}
            privacyUrl={links.privacy}
            membershipTerms={links.membershipTerms}
            onAccept={() => setHasAcceptedTerms(true)}
            memberId={memberId}
            showAgeGating={shouldShowAgeGating}
            setParentIsOpen={setIsOpen}
            setDisplayParent={setIsDisplay}
          />
        </If>
      </Container>
    </Modal>
  );
};
AcceptTermsModal.displayName = 'AcceptTermsModal';

const Container = styled.div`
  padding: 14px ${spaces.xLarge}px ${spaces.xLarge}px ${spaces.xLarge}px;
`;
