import { combineReducers } from 'redux';
import type { AppliedState } from './applied';
import { appliedReducer } from './applied';
import type { AvailableState } from './available';
import { availableReducer } from './available';
import type { SortState } from './sort';
import { sortReducer } from './sort';

export const filtersReducer = combineReducers({
  applied: appliedReducer,
  sort: sortReducer,
  available: availableReducer,
});

export type FiltersReducerState = {
  available: AvailableState;
  applied: AppliedState;
  sort: SortState;
};
