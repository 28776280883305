import React from 'react';
import styled from 'styled-components';
import { label12 } from '@engage/typography';
import { track } from '@members/analytics';
import { button1Styles, smallButtonStyles } from '@members/buttons';
import { Tiering, useTiering } from '@members/copy';
import { Feature, useFeatureToggle, useFeatureVariables } from '@members/feature-toggles';
import { UpsellIcon } from '@members/icons';
import { useTierContext } from '@members/tiering';
import { useUpgradeFromFreePath } from '@members/tiering/hooks/useUpgradeFromFreePath';
import { trackTappedHomescreenTopNavigation } from '../analytics';

const TRACKING_PARAMS = {
  source: 'Upgrade Tab',
};

const UpsellCTA = () => {
  const { isEligibleForFreeTrial } = useTierContext();
  const upgradePath = useUpgradeFromFreePath(false, TRACKING_PARAMS);
  const [isStickyDogCycleEnabled, { trialLengthInDays }] = useFeatureVariables(
    Feature.Stickydogcycle,
  );
  const lengthOfTrial = isStickyDogCycleEnabled ? trialLengthInDays : 30;
  const isNyAppPromo = useFeatureToggle(Feature.Ny_app_promo);
  const trailCopy = useTiering(Tiering.StartTrail, {
    numberOfDays: lengthOfTrial,
  });
  let upsellText;
  if (isNyAppPromo) {
    upsellText = Tiering.Upgrade;
  } else if (isEligibleForFreeTrial) {
    upsellText = trailCopy;
  } else {
    upsellText = Tiering.Upgrade;
  }

  const onClick = () => {
    if (isEligibleForFreeTrial) {
      track(trackTappedHomescreenTopNavigation('30 Day Trial', 'Navigation'));
    } else {
      track(trackTappedHomescreenTopNavigation('Upgrade', 'Navigation'));
    }
  };
  return (
    <UpsellLink data-test-id="trialUpgradeButton" href={upgradePath} onClick={onClick}>
      <UpsellIcon />
      <UpsellText>{upsellText}</UpsellText>
    </UpsellLink>
  );
};

const UpsellLink = styled.a`
  ${button1Styles}
  ${smallButtonStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 5px;
`;

const UpsellText = styled.p`
  ${label12};
  white-space: nowrap;
`;

export { UpsellCTA };
