export type TimeLocale = string & {
  __: 'time';
};

export const setLocale = (locale: string) => (localeState.locale = locale as TimeLocale);

const localeState = {
  locale: 'en' as TimeLocale,
};

export const getLocale = (): TimeLocale => localeState.locale as TimeLocale;

export const resetLocale = () => setLocale('en');
