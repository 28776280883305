import type { FeatureModule } from '@engage/api-v2';
import { toBaseProperties } from '@members/analytics';

export const HOMESCREEN_ANALYTICS_SOURCE = 'Home';
export const QUICK_FILTERS_SOURCE = 'Quick Filters';
export const FIRST_CLASS_REC_SOURCE = 'First Class Recommendations';
export const trackFilteredFeaturedScreen = (fitnessDiscipline: string) => ({
  event: 'Filtered Featured Screen',
  '[Fitness Discipline]': fitnessDiscipline,
  ...toBaseProperties(),
});

export const trackViewedClassGroup = (
  classGroup: FeatureModule & { rankDigital: number },
  collection: any,
) => ({
  event: 'Viewed Class Group',
  '[Class Group Name]': collection.name,
  '[Class Group Type]': 'Collection',
  '[Number of Classes]': parseInt(collection.length.split(' ')[0]),
  source: 'Featured',
  '[Featured Module]': 'Featured Collections',
  '[Featured Module Position]': classGroup.rankDigital,
  '[Featured Module Total Tiles]': classGroup.data?.length,
  ...toBaseProperties(),
});

export const trackViewedClassGroupLibrary = (source: string) => ({
  event: 'Viewed Class Group Library',
  '[Class Group Type]': 'Collection',
  source: source,
  ...toBaseProperties(),
});

export const trackTappedSearch = () => ({
  event: 'Tapped Search',
  source: 'Featured',
  ...toBaseProperties(),
});

export const trackViewedFeatureModule = (
  classGroup: FeatureModule & { rankDigital: number },
  isFirstClassRec?: boolean,
  params?: Object,
) => ({
  event: 'Viewed Featured Module',
  ['Featured Module Position']: classGroup.rankDigital,
  ['Featured Module Total Tiles']: classGroup?.data?.length,
  ...params,
  ...toBaseProperties(),
  '[Featured Module]': isFirstClassRec ? FIRST_CLASS_REC_SOURCE : classGroup.name,
});

export const trackViewedRecommendationTile = (
  recommendationText: string,
  recommendationNumber: number,
) => ({
  event: 'Viewed Recommendation Tile',
  ['Recommendation Text']: recommendationText,
  ['Recommendation Number']: recommendationNumber,
  ...toBaseProperties(),
});

export const trackViewedFeatured = (params?: {
  utm_source: string;
  utm_medium: string;
  utm_content: string;
}) => ({
  event: 'Viewed Featured',
  source: 'Featured',
  ['Utm Source']: params?.utm_source,
  ['Utm Medium']: params?.utm_medium,
  ['Utm Content']: params?.utm_content,
  ...toBaseProperties(),
});

export const trackScrolledFeatured = () => ({
  event: 'Scrolled Featured',
  source: 'Featured',
  ...toBaseProperties(),
});

export const trackScrolledFeaturedToEnd = () => ({
  event: 'Scrolled Featured to the End',
  source: 'Featured',
  ...toBaseProperties(),
});

export const trackFilteredPersonalActivityTrends = (filterOption: string) => ({
  event: 'Filtered Personal Activity Trends',
  ['Time Frame Filter']: filterOption,
  ...toBaseProperties(),
});

export const trackViewedNotificationProductInterest = (notificationText: string) => ({
  event: 'Viewed Notification',
  source: 'Featured',
  ['Notification Text']: notificationText,
  ['Notification Title']: 'Product Interest',
  ...toBaseProperties(),
});

export const trackTappedNotificationOnProductInterest = (
  notificationText: string,
  itemTapped: string,
) => ({
  event: 'Tapped Notification',
  source: 'Featured',
  ['Notification Text']: notificationText,
  ['Notification Title']: 'Product Interest',
  ['Item Tapped']: itemTapped,
  ...toBaseProperties(),
});

export const trackViewedSurveyQuestionInProductInterest = (
  questionText: string,
  responseOptions: string[],
) => ({
  event: 'Viewed Survey Question',
  source: 'Featured',
  ['Survey Name']: 'Collect Product Interest',
  ['Survey Total Questions']: 1,
  ['Question Title']: 'Product Interest',
  ['Question Text']: questionText,
  ['Question Number']: 1,
  ['Response Options']: responseOptions,
  ['First Question']: true,
  ['Last Question']: true,
  ...toBaseProperties(),
});

export const trackViewedPrivacyPreferencesModal = () => ({
  event: 'Viewed Privacy Preferences Modal',
  ...toBaseProperties(),
});

export const SURVEY_SOURCE = 'Account Creation';
export const SURVEY_NAME = 'Onboarding Quiz';
export const trackViewedSurveyQuestion = (
  firstQuestion: boolean,
  lastQuestion: boolean,
  questionNumber: number,
  questionText: string,
  questionTitle: string,
  responseOptions: string[],
) => ({
  event: 'Viewed Survey Question',
  ['First Question']: firstQuestion,
  ['Last Question']: lastQuestion,
  ['Question Number']: questionNumber,
  ['Question Text']: questionText,
  ['Question Title']: questionTitle,
  ['Response Options']: responseOptions,
  ['Source']: SURVEY_SOURCE,
  ['Survey Name']: SURVEY_NAME,
  ['Survey Total Question']: 2,
  ...toBaseProperties(),
});

export const trackSubmittedSurveyQuestion = (
  firstQuestion: boolean,
  lastQuestion: boolean,
  questionNumber: number,
  questionText: string,
  questionTitle: string,
  responseOptions: string[],
  responseSubmitted: string[],
  responsesSubmittedCount: number,
) => ({
  event: 'Submitted Survey Response',
  ['First Question']: firstQuestion,
  ['Last Question']: lastQuestion,
  ['Question Number']: questionNumber,
  ['Question Text']: questionText,
  ['Question Title']: questionTitle,
  ['Response Options']: responseOptions,
  ['Response Submitted']: responseSubmitted,
  ['Responses Submitted Count']: responsesSubmittedCount,
  ['Source']: SURVEY_SOURCE,
  ['Survey Name']: SURVEY_NAME,
  ['Survey Total Question']: 2,
  ...toBaseProperties(),
});
