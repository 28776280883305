import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import { compose, prop } from 'ramda';
import type { Reducer } from 'redux';
import type { RoutingState } from '@peloton/redux';
import { getQueryParams, parseSearch } from '@peloton/redux';
import { isDefined } from '@peloton/types';

const initialState = {
  name: undefined as string | undefined,
  source: '',
};

export const modalReducer: Reducer<typeof initialState> = (
  state = initialState,
  action: ModalAction | LocationChangeAction,
) => {
  switch (action.type) {
    case ModalActionType.Open:
      return {
        name: action.payload.name,
        source: action.payload.source,
      };
    case LOCATION_CHANGE: {
      const modalName = parseSearch(action.payload.location).modal;
      if (isDefined(modalName)) {
        return {
          ...state,
          name: modalName,
        };
      } else {
        return initialState;
      }
    }
    case ModalActionType.Close:
      return initialState;
    default:
      return state;
  }
};

export const getActiveModalName = ({ modal }: ModalSelectorState) => modal.name;

export const getActiveModalSource = ({ modal }: ModalSelectorState) => modal.source;

export const getModalNameFromLocation = compose<
  RoutingState,
  Record<string, string>,
  string | undefined
>(prop('modal'), getQueryParams);

export const openModal = (name: ModalName, source: string = '') => ({
  type: ModalActionType.Open,
  payload: { name, source },
});

export const closeModal = (additionalParams: string[] = []) => ({
  type: ModalActionType.Close,
  payload: { additionalParams },
});

export type ModalAction = {
  type: ModalActionType;
  payload: {
    name: ModalName;
    source: string;
  };
};

export type ModalSelectorState = {
  modal: typeof initialState;
};

export enum ModalActionType {
  Open = 'pelo/modal/open',
  Close = 'pelo/modal/close',
}

export type ModalName = string;
