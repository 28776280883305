import type { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { isEmpty } from '@peloton/helpers/isEmpty';
import { isNil } from '@peloton/helpers/isNil';
import { redirect } from '@peloton/navigation';
import { getLocation } from '@peloton/redux';
import { toLoginRoute, toTagsLoginRoute } from '@members/pg-auth/urls';
import { getTagNameFromLocation } from '@members/tags/selectors';

export const loginRedirectSaga = function* (): SagaIterator {
  const tagName = yield select(getTagNameFromLocation);
  const location = yield select(getLocation);

  if (isNil(tagName) || isEmpty(tagName)) {
    yield call(redirect, toLoginRoute(location));
  } else {
    yield call(redirect, toTagsLoginRoute(tagName, location));
  }
};

export const homeRedirectSaga = function* () {
  yield call(redirect, '/');
};

export const notFoundRedirectSaga = function* () {
  yield call(redirect, '/404');
};
