import { toMapStateToLinkProps } from '@peloton/external-links';
import { Links } from '@peloton/links';
import type { ContractAgreement } from '@engage/members';
import { useReduxState } from '@engage/redux';
import { ContractType } from './acceptTermsEnums';
import type { Contract } from './TermButton';

export const useContracts = (contractAgreements: ContractAgreement[]) => {
  const links = useReduxState(
    toMapStateToLinkProps({
      tos: Links.tos,
      privacy: Links.privacy,
      membershipTerms: Links.membershipTerms,
      onePeloton: Links.peloton,
    }),
  );
  const contractLinks = {
    [ContractType.PrivacyPolicy]: links.privacy,
    [ContractType.MembershipTerms]: links.membershipTerms,
    [ContractType.TermsOfService]: links.tos,
  };

  const toContractLink = (contract: ContractAgreement): string => {
    return contractLinks[contract.contractType];
  };

  const toContract = (contract: ContractAgreement): Contract => {
    const href = toContractLink(contract);

    return {
      href,
      type: contract.contractType,
      displayName: contract.contractDisplayName,
      createdAt: contract.contractCreatedAt,
      id: contract.contractId,
      agreedAt: contract.agreedAt,
      requiredMemberAction: contract.requiredMemberAction,
    };
  };

  return contractAgreements.map(toContract);
};
