/* eslint-disable react/display-name */
import { transparentize } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as VirtualizedList } from 'react-window';
import styled from 'styled-components';
import { toOverviewUrl } from '@peloton/links/members';
import { media, ellipsis } from '@peloton/styles';
import { isDefined } from '@peloton/types';
import { gray1, gray5, slate1, gray4 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import type { MemberCard } from '@engage/members/MemberCard';
import { isSelfRelationship } from '@engage/members/models';
import { spaces } from '@engage/styles';
import { label14, label16 } from '@engage/typography';
import { Members, useMembers } from '@members/copy';
import { SimpleAvatar } from '@members/images';
import { LoadingSpinner } from '@members/loading';
import { useIsBreakpointLessThan } from '@members/responsive';
import FollowButton from './FollowButton';
import {
  cardHeightMobile,
  cardHeight,
  AVATAR_SIZE,
  CARD_PADDING_MOBILE,
  CARD_PADDING,
} from './shared';

enum ItemType {
  Loading = 'Loading',
}

const MemberList: React.FC<
  React.PropsWithChildren<
    MembersListProps & {
      membersYouFollow?: boolean;
    }
  >
> = ({ members, onScrollEnd, isLoadingNextPage, membersYouFollow }) => {
  const isMobile = useIsBreakpointLessThan('tablet');
  const itemSize = isMobile ? cardHeightMobile : cardHeight;

  const items = isLoadingNextPage ? [...members, ItemType.Loading] : members;
  const count = items.length;

  return (
    <ListContainer data-test-id="membersList">
      <AutoSizer>
        {({ height, width }) => (
          <VirtualizedList
            height={height}
            width={width}
            itemCount={count}
            itemSize={itemSize}
            onItemsRendered={({ visibleStopIndex }) => {
              if (isDefined(onScrollEnd) && visibleStopIndex === count - 1) {
                onScrollEnd();
              }
            }}
          >
            {membersYouFollow ? toMembersYouFollowCard(items) : toCard(items)}
          </VirtualizedList>
        )}
      </AutoSizer>
    </ListContainer>
  );
};

export type ListItemProps = {
  index: number;
  style: React.CSSProperties;
};

const isMemberType = (item: MemberCard | ItemType): item is MemberCard =>
  item.hasOwnProperty('id');

const toCard = (
  items: (MemberCard | ItemType)[],
  incrementImageLoadedCount?: () => void,
) => ({ index, style }: ListItemProps) => {
  const cardProps = items[index];

  return (
    <div style={style}>
      {isMemberType(cardProps) ? (
        <MemberListCard
          card={cardProps}
          isVirtualizedList={true}
          incrementImageLoadedCount={incrementImageLoadedCount}
        />
      ) : (
        <Loading isLoading={true} size={40} />
      )}
    </div>
  );
};

const toMembersYouFollowCard = (
  items: (MemberCard | ItemType)[],
  incrementImageLoadedCount?: () => void,
) => ({ index, style }: ListItemProps) => {
  const cardProps = items[index];

  return (
    <div style={style}>
      {isMemberType(cardProps) ? (
        <MemberYouFollowListCard
          card={cardProps}
          isVirtualizedList={true}
          incrementImageLoadedCount={incrementImageLoadedCount}
        />
      ) : (
        <Loading isLoading={true} size={40} />
      )}
    </div>
  );
};

const Loading = styled(LoadingSpinner)`
  display: ${props => (props.isLoading ? 'block' : 'none')};
  fill: ${slate1};
  margin: ${spaces.large}px auto;
`;

const ListContainer = styled.div`
  height: 100%;
`;

export const MemberListCard: React.FC<
  React.PropsWithChildren<{
    card: MemberCard;
    i?: number;
    isVirtualizedList?: boolean;
    incrementImageLoadedCount?: () => void;
  }>
> = ({ card, isVirtualizedList, i, incrementImageLoadedCount }) => {
  return (
    <Card
      key={i}
      isVirtualizedList={isVirtualizedList}
      {...card}
      autoSizeColumn={true}
      incrementImageLoadedCount={incrementImageLoadedCount}
    >
      <FollowButtonWrapper>
        <FollowButton
          meToUserRelationship={card.meToUserRelationship}
          onFollowClick={card.onFollowClick}
        />
      </FollowButtonWrapper>
    </Card>
  );
};

export const MemberYouFollowListCard: React.FC<
  React.PropsWithChildren<{
    card: MemberCard;
    i?: number;
    isVirtualizedList?: boolean;
    incrementImageLoadedCount?: () => void;
  }>
> = ({ card, isVirtualizedList, i, incrementImageLoadedCount }) => {
  return (
    <MembersYouFollowCard
      key={i}
      isVirtualizedList={isVirtualizedList}
      {...card}
      autoSizeColumn={true}
      incrementImageLoadedCount={incrementImageLoadedCount}
    ></MembersYouFollowCard>
  );
};

const Card: React.FC<
  React.PropsWithChildren<
    MemberCard & {
      autoSizeColumn?: boolean;
      isVirtualizedList?: boolean;
      incrementImageLoadedCount?: () => void;
    }
  >
> = ({
  autoSizeColumn = false,
  isVirtualizedList = false,
  incrementImageLoadedCount,
  ...props
}) => {
  return (
    <CardContainer
      data-test-id="memberCard"
      autoSizeColumn={autoSizeColumn}
      isVirtualizedList={isVirtualizedList}
    >
      <MemberInfoLink
        data-test-id="memberCardLink"
        to={{
          pathname: toOverviewUrl(
            props.username,
            isSelfRelationship(props.meToUserRelationship),
          ),
          state: { source: props.listName },
        }}
      >
        <Image
          url={props.imageUrl}
          size={AVATAR_SIZE}
          alt={useMembers(Members.AvatarAltText, { name: props.username })}
          onLoad={incrementImageLoadedCount}
          className="fs-exclude"
        />
        <TextBlock>
          <Header data-test-id="memberCardUsername" className="fs-exclude">
            {props.username}
          </Header>
          <Subheader>
            <If condition={isDefined(props.label)}>
              <Label key="label">{props.label}</Label>
            </If>
          </Subheader>
        </TextBlock>
      </MemberInfoLink>
      {props.children}
    </CardContainer>
  );
};

const MembersYouFollowCard: React.FC<
  React.PropsWithChildren<
    MemberCard & {
      autoSizeColumn?: boolean;
      isVirtualizedList?: boolean;
      incrementImageLoadedCount?: () => void;
    }
  >
> = ({
  autoSizeColumn = false,
  isVirtualizedList = false,
  incrementImageLoadedCount,
  ...props
}) => {
  return (
    <CardContainer
      data-test-id="memberCard"
      autoSizeColumn={autoSizeColumn}
      isVirtualizedList={isVirtualizedList}
    >
      <MemberInfoLink
        data-test-id="memberCardLink"
        to={{
          pathname: toOverviewUrl(
            props.username,
            isSelfRelationship(props.meToUserRelationship),
          ),
          state: { source: props.listName },
        }}
      >
        <Image
          url={props.imageUrl}
          size={AVATAR_SIZE}
          alt={useMembers(Members.AvatarAltText, { name: props.username })}
          onLoad={incrementImageLoadedCount}
          className="fs-exclude"
        />
        <TextBlock>
          <Header data-test-id="memberCardUsername" className="fs-exclude">
            {props.username}
          </Header>
          <Subheader>
            <If condition={isDefined(props.label)}>
              <Label key="label">{props.label}</Label>
            </If>
          </Subheader>
        </TextBlock>
      </MemberInfoLink>
    </CardContainer>
  );
};

const FollowButtonWrapper = styled.div`
  justify-self: end;
`;

export const CardContainer = styled.li<{
  autoSizeColumn: boolean;
  isVirtualizedList?: boolean;
}>`
  ${props => (props.isVirtualizedList ? 'height: 100%;' : '')}
  display: grid;
  grid-template-columns: 1fr ${props => (props.autoSizeColumn ? 'auto' : '165px')};
  align-items: center;
  width: 100%;
  padding: 0 10px;
  background-color: ${gray1};
  border-bottom: solid 1px ${transparentize(0.75, gray4)};
  ${media.tablet`
    padding: 0 ${spaces.large}px;
  `}
`;

export const MemberInfoLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: ${CARD_PADDING_MOBILE}px 0;
  overflow: hidden;
  ${media.tablet`
    padding: ${CARD_PADDING}px 0;
  `}
`;

export const TextBlock = styled.div`
  padding-left: ${spaces.small}px;
  padding-right: ${spaces.small}px;
  min-width: 0;
  ${media.tablet`
    padding-bottom: ${spaces.tiny}px;
  `}
`;

export const Header = styled.div`
  ${ellipsis}
  ${label16}
`;

export const Subheader = styled.div`
  ${ellipsis}
  ${label14}
  color: ${gray5};
`;

const Label = styled.span``;

export const Image = styled(SimpleAvatar)`
  display: inline-block;
  height: 40px;
  width: 40px;
  min-width: 40px; // for IE
`;

export type MembersListProps = {
  members: MemberCard[];
  scrollRef?: React.RefObject<HTMLDivElement>;
  onScrollEnd?: () => void;
  isLoadingNextPage?: boolean;
};

export default MemberList;
export { Card };
