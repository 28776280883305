import { loadAuthUserFailure } from '@peloton/auth';
import { ME } from '@engage/members';
import { loadMember } from './memberMapper';

export const loadUser = () => loadMember(ME, loadAuthUserFailure);

export const loadUserSuccess = () =>
  ({
    type: UserMapperActionType.Success,
  } as const);

export const loadUserRequestSuccess = (user: FetchedUser) =>
  ({
    type: UserMapperActionType.RequestSuccess,
    payload: user,
  } as const);

export enum UserMapperActionType {
  Request = 'pelo/user/Request',
  RequestSuccess = 'pelo/user/RequestSuccess',
  Success = 'pelo/user/Success',
}

type FetchedUser = any;
