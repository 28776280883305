/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import * as globalImportUrl from 'url';
import type { AxiosPromise, AxiosInstance } from 'axios';

export { globalImportUrl };
export type { AxiosPromise };


export interface ConfigurationParameters {
    apiKey?: string | ((name: string) => string);
    username?: string;
    password?: string;
    accessToken?: string | ((name?: string, scopes?: string[]) => string);
    basePath?: string;
    baseOptions?: any;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | ((name: string) => string);
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | ((name?: string, scopes?: string[]) => string);
    /**
     * override base path
     *
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;
    /**
     * base options for axios calls
     *
     * @type {any}
     * @memberof Configuration
     */
    baseOptions?: any;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.baseOptions = param.baseOptions;
    }
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    readonly configuration: Configuration | undefined;

    constructor(readonly axios: AxiosInstance, configuration?: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
    }
};