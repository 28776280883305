import type { SagaIterator } from 'redux-saga';
import { call, takeEvery, take } from 'redux-saga/effects';
import { UserReducerActionType } from '@peloton/auth';
import { NavigationActionType } from '@peloton/navigation';
import { analyticsReady } from '@engage/analytics';
import { UserMapperActionType, MemberMapperActionType } from '@members/user-mapper';
import { analyticsIdentifySaga } from './analytics';
import { homeRedirectSaga, loginRedirectSaga, notFoundRedirectSaga } from './redirects';

export const appSaga = function* (): SagaIterator {
  // Set up and honor redirect requests first.
  yield takeEvery(UserReducerActionType.LOGIN_REDIRECT, loginRedirectSaga);
  yield takeEvery(NavigationActionType.Home, homeRedirectSaga);
  yield takeEvery(NavigationActionType.NotFound, notFoundRedirectSaga);
  yield takeEvery(UserMapperActionType.Success, analyticsIdentifySaga);

  // Set up analytics *after* any above effects.
  // There could be redirect actions that should be honored before waiting for analytics ready.
  yield take(MemberMapperActionType.RequestSuccess);
  yield call(analyticsReady);
};
