import type { AxiosInstance } from 'axios';
import { useEffect, useRef } from 'react';
import { useOauth } from '@peloton/auth';

type CancellableInterceptor = [AxiosInstance, number];

const useAuthorizationHeader = (clients: AxiosInstance[]) => {
  // Use a ref for tracking errors so that we can immediately cancel token requests for all clients
  const hasError = useRef(false);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useOauth();

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    const interceptorList: CancellableInterceptor[] = [];
    clients.map(client => {
      const interceptorId = client.interceptors.request.use(async config => {
        if (isAuthenticated && !hasError.current) {
          try {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
              config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
          } catch {
            // There was an error getting the access token (probably either because of a timeout or the user not being logged in)
            // In either case, we will stop attempting to grab an authorization header and let other hooks control logout/login
            hasError.current = true;
          }
        }
        return config;
      });
      interceptorList.push([client, interceptorId]);
    });

    return () =>
      interceptorList.forEach(([client, interceptorId]) =>
        client.interceptors.request.eject(interceptorId),
      );
  }, [isAuthenticated, isLoading]);
};

export default useAuthorizationHeader;
