import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1, slate2 } from '@engage/colors';
import { spaces } from '@engage/styles/spaces';
import { label16, body14 } from '@engage/typography';
import { Button1Large } from '@members/buttons';
import { useTiering, Tiering, TieringCopy } from '@members/copy';
import { useAllCopy } from '@members/copy/CopyProvider/CopyProvider';
import { useRemainingClassesQuota } from '../hooks/useRemainingClassesQuota';
import type { Modal, TIERING_MODAL_TYPE } from '../models';
import { useTierContext } from '../TierProvider';
import {
  convertStringListToConjunctString,
  getFitnessSlugDisplayName,
  getModalName,
  getQuotaRefreshDate,
} from '../utils';
import TieringModalView from './TieringModalView';

const QuotaAvailableModal: React.FC<
  React.PropsWithChildren<{
    modal: Modal;
    setModal: React.Dispatch<React.SetStateAction<Modal>>;
  }>
> = ({ modal, setModal }) => {
  const {
    tier,
    marketingData: { digitalLimitedDisciplinesMarketing },
    refetch,
  } = useTierContext();

  refetch();
  const quotaCount = useRemainingClassesQuota();
  const cardioEquipmentClassCopy = useTiering(Tiering.CardioEquipmentCount, {
    numRemainingClasses: quotaCount,
  });

  const youHaveSingularPluralCopy = useTiering(Tiering.YouHaveSingularPlural, {
    classes: quotaCount,
  });

  const modalHeaderCopy = useTiering(Tiering.CardioEquipmentClassesAvailableMonth, {
    youHave: youHaveSingularPluralCopy,
    classes: cardioEquipmentClassCopy,
  });

  const { quotaRefreshMonthNameShort, quotaRefreshDayOfMonth } = getQuotaRefreshDate(
    tier.limitedClassesResetDate,
  );

  const buttonCopy = useTiering(Tiering.GotIt);
  const upgradeCTACopy = useTiering(Tiering.UpgradeToAppPlus);

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const openUpgradeModal = () => {
    setModal({
      ...modal,
      modalType: 'upgrade_modal',
      isOpen: true,
      source: getModalName('exclusive_upgrade_modal' as TIERING_MODAL_TYPE),
    });
  };

  return (
    <TieringModalView
      modal={modal}
      setModal={setModal}
      modalHeaderCopy={modalHeaderCopy}
      modalDescriptionChild={
        <QuotaAvailableDescription
          numLimitedClassesTotal={tier.numLimitedClassesTotal}
          quotaRefreshMonthNameShort={quotaRefreshMonthNameShort}
          quotaRefreshDayOfMonth={quotaRefreshDayOfMonth}
          digitalLimitedDisciplinesMarketing={digitalLimitedDisciplinesMarketing}
        />
      }
      ctaChildren={
        <>
          <CTAButton onClick={closeModal}>{buttonCopy}</CTAButton>
          <CTALinkContainer>
            <UpgradeCTA onClick={openUpgradeModal}>{upgradeCTACopy}</UpgradeCTA>
          </CTALinkContainer>
        </>
      }
    />
  );
};

const QuotaAvailableDescription: React.FC<
  React.PropsWithChildren<{
    numLimitedClassesTotal: number;
    quotaRefreshMonthNameShort: string;
    quotaRefreshDayOfMonth: string;
    digitalLimitedDisciplinesMarketing: string[];
  }>
> = ({
  numLimitedClassesTotal,
  quotaRefreshMonthNameShort,
  quotaRefreshDayOfMonth,
  digitalLimitedDisciplinesMarketing,
}) => {
  const allCopy = useAllCopy();
  const localizedCardioEquipmentDisciplines = digitalLimitedDisciplinesMarketing.map(
    slug => getFitnessSlugDisplayName(slug, allCopy),
  );

  const conjuctCardioDisciplines = convertStringListToConjunctString(
    localizedCardioEquipmentDisciplines,
    useTiering(Tiering.And),
  );
  const limitRefreshCopy = useTiering(Tiering.QuotaLimitRefreshDate, {
    maxClasses: numLimitedClassesTotal,
    monthNameShort: <LabelBold>{quotaRefreshMonthNameShort}</LabelBold>,
    dayOfMonth: <LabelBold>{quotaRefreshDayOfMonth}</LabelBold>,
  });

  return (
    <QuotaAvailableDescriptionContainer>
      <TieringCopy
        id={Tiering.CardioEquipmentClasses}
        values={{ cardioEquipmentClasses: conjuctCardioDisciplines }}
      />
      <Spacer marginTop={`${spaces.small}`}>
        <TieringCopy id={Tiering.TakeClassesWithAnyEquipmentPlatform} />
      </Spacer>
      <Spacer marginTop={`${spaces.xLarge}`}>{limitRefreshCopy}</Spacer>
    </QuotaAvailableDescriptionContainer>
  );
};

const QuotaAvailableDescriptionContainer = styled.div`
  ${body14}
  color: ${slate2};
  margin-top: ${spaces.xxxLarge}px;
  margin-bottom: ${spaces.xLarge}px;
`;

const Spacer = styled.div<{ marginTop?: string }>`
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}px;
`;

const LabelBold = styled.span`
  font-weight: 900;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const CTAButton = styled(Button1Large)`
  background-color: ${slate1};
  margin-bottom: ${spaces.xLarge}px;
  width: 280px;
  ${media.tablet`
  width: 320px;`}
`;

const CTALinkContainer = styled.div`
  display: flex;
`;

const UpgradeCTA = styled.button`
  ${label16}
  color: ${slate1};
  text-align: center;
  width: 280px;
  ${media.tablet`
  width: auto;
  `}
`;

export default QuotaAvailableModal;
