import type { AxiosPromise } from 'axios';
// eslint-disable-next-line no-restricted-imports
import { toFetcher } from '@engage/data-fetch-v2';
// eslint-disable-next-line no-restricted-imports
import { unwrap } from '@engage/data-fetch-v2/lib/unwrap';
import { progressTrackingApi } from '../singletons';

type Params = { acceptLanguage?: string; userId: string };

export type ProgressTrackingResponse = {
  data: {
    user_id: string;
    goals: [
      {
        id: string;
        user_id: string;
        type: string;
        target: number;
        progress: number;
        units: string;
        frequency: string;
        start_date: string;
        end_date: string;
      },
    ];
    has_goal_inactivity: boolean;
  };
};

function getProgressTracking(
  api: typeof progressTrackingApi,
  acceptLanguage?: string,
  userId?: string,
): AxiosPromise<ProgressTrackingResponse> {
  const getUrl = `api/users/${userId}/goals`;
  return api.axios.get(getUrl, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

export const toProgressTrackingFetchers = (
  api: typeof progressTrackingApi = progressTrackingApi,
) => ({
  GetUserGoals: toFetcher(
    () => `UserGoals`,
    (params: Params) =>
      unwrap(() => getProgressTracking(api, params.acceptLanguage, params.userId)),
  ),
});
