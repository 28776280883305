import type { Time, TimeUnit } from './time';
import { toNowTime } from './time';

export const getCalendarDifference = (
  earlierTime: Time,
  laterTime: Time,
  unitOfTime: TimeUnit = 'day',
) =>
  laterTime
    .clone()
    .startOf('day')
    .diff(earlierTime.clone().startOf('day'), unitOfTime, true);

export const getCalendarUTCDifference = (
  earlierTime: Time,
  laterTime: Time,
  unitOfTime: TimeUnit = 'day',
) => Math.ceil(laterTime.utc().clone().diff(earlierTime.utc().clone(), unitOfTime, true));

export const getWeeksFromToday = (date: Time) =>
  getCalendarDifference(toNowTime(), date, 'week');

export const getYearsToToday = (date: Time) =>
  getCalendarDifference(date, toNowTime(), 'year');

export const toDayOfWeek = (date: Time, day: number) => date.clone().isoWeekday(day);

export const getDayOfWeek = (date: Time) => date.clone().day();

export const toMonthAbbreviation = (date: Time) => date.clone().format('MMM');

// toDayOfMonth will return the day without leading zeros, e.g. the 5th will be 5 instead of 05
export const toDayOfMonth = (date: Time) => date.date();

export const addMinutes = (date: Time, minutes: number) => date.clone().add({ minutes });

export const addMonths = (date: Time, months: number) => date.clone().add({ months });

export const addDays = (date: Time, days: number) => date.clone().add({ days });

export const setHour = (date: Time, hour: number) => date.clone().hour(hour);

export const setMinute = (date: Time, minute: number) => date.clone().minute(minute);

export const subtractDays = (date: Time, days: number) => date.clone().subtract({ days });

export const toStartOfDay = (date: Time) => date.clone().startOf('day');

export const toEndOfDay = (date: Time) => date.clone().endOf('day');

export const toDayOfWeekAbbreviations = (): string[] =>
  Array(7)
    .fill(toNowTime().isoWeekday(1))
    .reduce<string[]>(
      (week: string[], day: Time, i: number) => [...week, addDays(day, i).format('ddd')],
      [],
    );

export const isToday = (day: Time) => day.isSame(toNowTime(), 'day');

export const isAfter = (a: Time, b: Time) => a.isAfter(b);
export const isDayAfter = (a: Time, b: Time) => a.isAfter(b, 'day');

export const isBefore = (a: Time, b: Time) => a.isBefore(b);

export const isBetween = (args: { windowStart: Time; windowEnd: Time; subject: Time }) =>
  args.subject.isBetween(args.windowStart, args.windowEnd);

export const isInFuture = (time: Time) => isAfter(time, toNowTime());

export const isDayInFuture = (time: Time) => isDayAfter(time, toNowTime());

export const getDifferenceIsLessThan = (
  firstTimestamp: Time,
  secondTimestamp: Time,
  difference: number,
  unitOfTime: TimeUnit = 'seconds',
) => {
  return Math.abs(firstTimestamp.diff(secondTimestamp, unitOfTime)) < difference;
};

export const toDayRange = (startDate: Time, totalDays: number): Time[] =>
  Array(totalDays)
    .fill(startDate)
    .reduce(
      (calendarDays, currentDay, i) => [...calendarDays, addDays(currentDay, i)],
      [],
    );
