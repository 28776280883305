import type { Reducer } from 'redux';

export const notificationReducer: Reducer<State, Action> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case ActionType.DismissExpiringSoonBanner:
      return {
        ...state,
        dismissedExpiringSoonBanner: true,
      };
    case ActionType.DismissLapsedBanner:
      return {
        ...state,
        dismissedLapsedBanner: true,
      };
    case ActionType.DismissChallengesTooltip:
      return {
        ...state,
        dismissedChallengesTooltip: true,
      };
    case ActionType.DismissChallengesModal:
      return {
        ...state,
        dismissedChallengesModal: true,
      };
    case ActionType.DismissTagsOverviewTooltip:
      return {
        ...state,
        dismissedTagsOverviewTooltip: true,
      };

    case ActionType.DismissBookmarkTooltip:
      if (state.dismissedBookmarks.includes(action.payload.bookmark)) {
        return state;
      }
      return {
        ...state,
        dismissedBookmarks: [...state.dismissedBookmarks, action.payload.bookmark],
      };

    case ActionType.DismissTagsLeaderboardTooltip:
      return {
        ...state,
        dismissedTagsLeaderboardTooltip: true,
      };
    case ActionType.DismissTagsAnnouncementModal:
      return {
        ...state,
        dismissedTagsAnnouncementModal: false,
        dismissedTagsAnnouncementModalByUserId: {
          ...state.dismissedTagsAnnouncementModalByUserId,
          [action.payload.userId]: true,
        },
      };
    case ActionType.DismissPostSignupTooltip:
      return {
        ...state,
        dismissedPostSignupTooltip: true,
      };
    default: {
      return state;
    }
  }
};

enum ActionType {
  DismissExpiringSoonBanner = 'members/notifications/DISMISS_EXPIRING_SOON_BANNER',
  DismissLapsedBanner = 'members/notifications/DISMISS_LAPSED_BANNER',
  DismissChallengesTooltip = 'members/notifications/DISMISS_CHALLENGES_TOOLTIP',
  DismissChallengesModal = 'members/notifications/DISMISS_CHALLENGES_MODAL',
  DismissTagsOverviewTooltip = 'members/notifications/DISMISS_TAGS_OVERVIEW_TOOLTIP',
  DismissTagsLeaderboardTooltip = 'members/notifications/DISMISS_TAGS_LEADERBOARD_TOOLTIP',
  DismissTagsAnnouncementModal = 'members/notifications/DISMISS_TAGS_ANNOUNCEMENT_MODAL',
  DismissPostSignupTooltip = 'members/notifications/DISMISS_POST_SIGNUP_TOOLTIP',
  DismissBookmarkTooltip = 'members/notifications/DISMISS_BOOKMARK_TOOLTIP',
}

const defaultState: State = {
  dismissedExpiringSoonBanner: false,
  dismissedLapsedBanner: false,
  dismissedChallengesTooltip: false,
  dismissedChallengesModal: false,
  dismissedTagsOverviewTooltip: true,
  dismissedTagsLeaderboardTooltip: false,
  dismissedTagsAnnouncementModal: false,
  dismissedTagsAnnouncementModalByUserId: {
    guest: false,
  },
  dismissedPostSignupTooltip: false,
  dismissedBookmarks: [],
};

export const dismissExpiringSoonBanner = () =>
  ({
    type: ActionType.DismissExpiringSoonBanner,
  } as const);

export const dismissLapsedBanner = () =>
  ({
    type: ActionType.DismissLapsedBanner,
  } as const);

export const dismissChallengesTooltip = () =>
  ({
    type: ActionType.DismissChallengesTooltip,
  } as const);

export const dismissChallengesModal = () =>
  ({
    type: ActionType.DismissChallengesModal,
  } as const);

export const dismissTagsOverviewTooltip = () =>
  ({ type: ActionType.DismissTagsOverviewTooltip } as const);

export const dismissTagsLeaderboardTooltip = () =>
  ({
    type: ActionType.DismissTagsLeaderboardTooltip,
  } as const);

export const dismissTagsAnnouncementModal = (userId: string | undefined = 'guest') =>
  ({
    type: ActionType.DismissTagsAnnouncementModal,
    payload: { userId },
  } as const);

export const dismissBookmark = (bookmark: string) => {
  return {
    type: ActionType.DismissBookmarkTooltip,
    payload: { bookmark },
  } as const;
};

export const dismissPostSignupTooltip = () =>
  ({ type: ActionType.DismissPostSignupTooltip } as const);

export const didDismissExpiringSoonBanner = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedExpiringSoonBanner;

export const didDismissLapsedBanner = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedLapsedBanner;

export const didDismissBookmarks = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedBookmarks;

export const didDismissChallengesTooltip = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedChallengesTooltip;

export const didDismissChallengesModal = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedChallengesModal;

export const didDismissTagsOverviewTooltip = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedTagsOverviewTooltip;

export const didDismissTagsLeaderboardTooltip = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedTagsLeaderboardTooltip;

export const didDismissTagsAnnouncementModal = (
  state: NotificationState,
  userId: string | undefined = 'guest',
) =>
  state[NOTIFICATION_NAMESPACE].dismissedTagsAnnouncementModalByUserId[userId] ??
  state[NOTIFICATION_NAMESPACE].dismissedTagsAnnouncementModal;

export const didDismissPostSignupTooltip = (state: NotificationState) =>
  state[NOTIFICATION_NAMESPACE].dismissedPostSignupTooltip;

export const NOTIFICATION_NAMESPACE = 'notifications';

type State = {
  dismissedExpiringSoonBanner: boolean;
  dismissedLapsedBanner: boolean;
  dismissedChallengesTooltip: boolean;
  dismissedChallengesModal: boolean;
  dismissedTagsOverviewTooltip: boolean;
  dismissedTagsLeaderboardTooltip: boolean;
  dismissedTagsAnnouncementModal: boolean;
  dismissedTagsAnnouncementModalByUserId: Record<string, boolean>;
  dismissedPostSignupTooltip: boolean;
  dismissedBookmarks: Array<string>;
};

type Action =
  | ReturnType<typeof dismissExpiringSoonBanner>
  | ReturnType<typeof dismissLapsedBanner>
  | ReturnType<typeof dismissChallengesTooltip>
  | ReturnType<typeof dismissChallengesModal>
  | ReturnType<typeof dismissTagsOverviewTooltip>
  | ReturnType<typeof dismissTagsLeaderboardTooltip>
  | ReturnType<typeof dismissTagsAnnouncementModal>
  | ReturnType<typeof dismissPostSignupTooltip>
  | ReturnType<typeof dismissBookmark>;

export type NotificationState = { [NOTIFICATION_NAMESPACE]: State };
