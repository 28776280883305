import { css } from 'styled-components';

export const capsStyle = css`
  text-transform: uppercase;
`;

export enum fontWeights {
  normal = '100',
  bold = '200',
  xBold = '300',
  xxBold = '400',
  xxxBold = '500',
  xxxxBold = '600',
}

export enum letterSpacing {
  xxTight = '-0.6px',
  xTight = '-0.4px',
  tight = '-0.2px',
  normal = '0px',
  wide = '0.2px',
  xWide = '0.8px',
  xxWide = '1px',
  xxxWide = '1.2px',
  xxxxWide = '1.4px',
}

export const interV = '"Inter V", sans-serif';
export const interVTab = '"Inter V Tab", "Inter V", sans-serif';
