import React from 'react';

const User: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
      transform="translate(-85.000000, 0.000000)"
    >
      <g transform="translate(85.000000, 0.000000)">
        <path
          d="M15.3704767,12.18133 C11.1901986,10.56533 10.4287289,9.88089 10.294939,9.66667 L10.294939,9.17244 C10.7947296,8.59361011 11.1632871,7.91344231 11.3752885,7.17867 C11.7431034,6.8584708 11.9537501,6.39433184 11.9525782,5.90667 C11.9534444,5.5762058 11.8554786,5.25303088 11.6712683,4.97867 L11.6712683,3.416 C11.6712683,1.24533 10.3334289,0 8.00046,0 C5.70139104,0 4.32782166,1.27733 4.32782166,3.416 L4.32782166,4.98044 C3.86140593,5.68345697 3.98800291,6.62324325 4.62382153,7.17778 C4.83612657,7.9128791 5.20497944,8.59333523 5.70506104,9.17244 L5.70506104,9.66578 C5.5694411,9.88178 4.80431144,10.56622 0.628603331,12.18133 C0.249939192,12.3311969 0.000888465508,12.6967581 3.61480873e-06,13.104 L3.61480873e-06,15.01244 C-0.00145256055,15.5556907 0.437226369,15.997542 0.980473172,16 L15.0195268,16 C15.5627736,15.997542 16.0014526,15.5556907 15.9999964,15.01244 L15.9999964,13.104 C15.9996113,12.6963289 15.7499264,12.3303724 15.3704767,12.18133 Z"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export { User };
