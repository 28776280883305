import { keys } from 'ramda';
import { isDefined } from '@peloton/types';
import { syncWithQueryParam, StorageKeys } from '@members/env';
import type { Toggles } from './models';

/**
 * We have a tri-state situation here:
 * 1. Toggles defined in packages/@members/feature-toggles/featureToggles.ts
 * 2. Toggles in Optimizely
 * 3. Toggles in local storage
 *
 * By default all toggles in #1 are disabled. That happens elsewhere (packages/@members/feature-toggles/state/toggles.ts), so
 * in this case we are merely trying to preserve the original state.
 * and we require a toggle to be in #1 for it to be referenced in code (e.g. `useFeatureToggle(Feature.UseRewrittenPlaylist)`).
 * Any toggles not in #1 we should ignore. And for toggles in #1, local storage state #4 takes precedence, and then Optimizely #3.
 */
export const mergeEnabledFeaturesWithEnv = (
  state: Toggles,
  enabledKeys: string[],
  localStorageTogglesString = syncWithQueryParam(StorageKeys.FeatureToggles),
): Toggles => {
  let localStorageToggles: Record<string, boolean> = {};
  if (localStorageTogglesString) {
    localStorageToggles = JSON.parse(localStorageTogglesString);
  }

  return keys(state).reduce((toggles: Toggles, key: string) => {
    const toggle = state[key];

    if (isDefined(toggle)) {
      let { value, originalValue } = toggle;

      if (enabledKeys.indexOf(key) > -1) {
        value = true;
        originalValue = true;
      }

      if (localStorageToggles.hasOwnProperty(key)) {
        value = localStorageToggles[key];
      }

      return {
        ...toggles,
        [key]: { value, originalValue },
      };
    }

    return toggles;
  }, {});
};
