import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CALLBACK } from '@members/pg-auth/urls';
import {
  BENEFITS_WELCOME_PATH,
  WELCOME_PATH,
  PASSWORD_PATH,
  USERNAME_PATH,
  BIRTHDATE_PATH,
  LOCATION_PATH,
  LOCATION_PRIVACY_PATH,
  PREFERENCES_PATH,
} from '@members/pg-profile-setup/urls';
import {
  REMOTE_ACTIVATION_ROUTE,
  ADD_ACCOUNT_ROUTE,
  REMOTE_ACTIVATION_PROFILE_ROUTE,
  REMOTE_ACTIVATION_CHECKOUT_ROUTE,
} from '@members/pg-remote-activation/urls';
import { useLocationPromptData } from '../../pg-homescreen/hooks/useUserPreferencesData';

const isAllowedPages = (locationPathname: string) =>
  [
    WELCOME_PATH,
    BIRTHDATE_PATH,
    LOCATION_PRIVACY_PATH,
    LOCATION_PATH,
    PREFERENCES_PATH,
    PASSWORD_PATH,
    USERNAME_PATH,
    BENEFITS_WELCOME_PATH,
    REMOTE_ACTIVATION_ROUTE,
    ADD_ACCOUNT_ROUTE,
    REMOTE_ACTIVATION_PROFILE_ROUTE,
    REMOTE_ACTIVATION_CHECKOUT_ROUTE,
    CALLBACK,
  ].includes(locationPathname);

export const LocationPrivacyRedirect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data: locationPromptData, isValidating } = useLocationPromptData();
  const history = useHistory();
  const location = useLocation().pathname;

  React.useEffect(() => {
    if (
      !isValidating &&
      locationPromptData?.showPrivacyLocationModal &&
      !isAllowedPages(location)
    ) {
      history.push('/complete/location-privacy', { from: location });
    }
  }, [history, isValidating, location, locationPromptData]);

  return null;
};
