import { pick } from 'ramda';
import type { Reducer } from 'redux';
import type { Locale } from '@peloton/internationalize';
import { Language } from '@peloton/internationalize';
import type { TypedAction } from '@peloton/redux';
import { FeedPrivacy } from '@engage/graphql';
import type { Source } from './analytics';
import type {
  ProfilePrivacySettings,
  NotificationSettings,
  DisplayPreferences,
  ClosedCaptionsSettings,
  SettingsModel,
  BlockExplicitSettings,
  BlockPrenatalSettings,
  ClassLanguage,
  ClassLanguagePreferences,
  ContentPreferences,
  FeaturesSettings,
  CorporateWellnessGroup,
  CorporateWellnessSettings,
} from './models';
import { MeasurementUnit } from './models';

export enum SettingsActionType {
  SettingsRequest = 'pelo/engage/settings/REQUEST',
  CaptionsSettingsRequest = 'pelo/engage/settings/CAPTIONS_REQUEST',
  UpdatePrivacySettings = 'pelo/engage/settings/UPDATE_PRIVACY_SETTINGS',
  UpdateNotificationSettings = 'pelo/engage/settings/UPDATE_NOTIFICATION_SETTINGS',
  UpdateDisplayPreferences = 'pelo/engage/settings/UPDATE_DISPLAY_PREFERENCES',
  UpdateFeaturesSettings = 'pelo/engage/settings/UPDATE_FEATURES_SETTINGS',
  UpdateClassLanguagePreferences = 'pelo/engage/settings/UPDATE_CLASS_LANGUAGE_PREFERENCES',
  UpdateContentPreferences = 'pelo/engage/settings/UPDATE_CONTENT_PREFERENCES',
  UpdateDisplayPreferencesSuccess = 'pelo/engage/settings/UPDATE_DISPLAY_PREFERENCES_SUCCESS',
  UpdateDisplayLanguage = 'pelo/engage/settings/UPDATE_DISPLAY_LANGUAGE',
  UpdateCaptionsSettingsRequest = 'pelo/engage/settings/UPDATE_CAPTIONS_SETTINGS_REQUEST',
  UpdateCaptionsSettingsSuccess = 'pelo/engage/settings/UPDATE_CAPTIONS_SETTINGS_SUCCESS',
  UpdateDisableExplicitWarning = 'pelo/engage/settings/UPDATE_DISABLE_EXPLICIT_WARNING',
  BlockExplicitSuccess = 'pelo/engage/settings/UPDATE_BLOCK_EXPLICIT_SETTINGS_SUCCESS',
  BlockExplicitFailure = 'pelo/engage/settings/UPDATE_BLOCK_EXPLICIT_SETTINGS_FAILURE',
  BlockExplicitSettingsRequest = 'pelo/engage/settings/UPDATE_BLOCK_EXPLICIT_SETTINGS_REQUEST',
  HidePrenatalSuccess = 'pelo/engage/settings/UPDATE_HIDE_PRENATAL_SETTINGS_SUCCESS',
  HidePrenatalFailure = 'pelo/engage/settings/UPDATE_HIDE_PRENATAL_SETTINGS_FAILURE',
  HidePrenatalSettingsRequest = 'pelo/engage/settings/UPDATE_PRENATAL_SETTINGS_SETTINGS_REQUEST',
  UpdateShareChallengeCompletion = 'pelo/engage/settings/UPDATE_SHARE_CHALLENGE_COMPLETION',
  SetSettings = 'pelo/engage/settings/SET_SETTINGS',
}

export const loadCaptionsSettings = () => ({
  type: SettingsActionType.CaptionsSettingsRequest,
});

export const updateCaptionsSettings = (settings: ClosedCaptionsSettings) => ({
  type: SettingsActionType.UpdateCaptionsSettingsSuccess,
  payload: settings,
});

export const updateDisplayLanguage = (displayLanguage: Language) => ({
  type: SettingsActionType.UpdateDisplayLanguage,
  payload: { displayLanguage },
});

export const updateClosedCaptionsSettingsRequest = (
  settings: ClosedCaptionsSettings,
) => ({
  type: SettingsActionType.UpdateCaptionsSettingsRequest,
  payload: settings,
});

export const updateBlockExplicitSettingsRequest = (value: boolean) => ({
  type: SettingsActionType.BlockExplicitSettingsRequest,
  payload: { blockExplicit: value },
});

export const updateBlockPrenatalSettingsRequest = (value: boolean) => ({
  type: SettingsActionType.HidePrenatalSettingsRequest,
  payload: { blockPrenatal: value },
});

export const updateBlockExplicitSettings = (settings: BlockExplicitSettings) => ({
  type: SettingsActionType.BlockExplicitSuccess,
  payload: settings,
});

export const updateHidePrenatalSettings = (settings: BlockPrenatalSettings) => ({
  type: SettingsActionType.HidePrenatalSuccess,
  payload: settings,
});

export const updateBlockExplicitFailure = () => ({
  type: SettingsActionType.BlockExplicitFailure,
});

export const updateHidePrenatalFailure = () => ({
  type: SettingsActionType.HidePrenatalFailure,
});

export const setSettings = (settings: SettingsModel) => ({
  type: SettingsActionType.SetSettings,
  payload: settings,
});

export const updateDisableExplicitWarning = (value: boolean) => ({
  type: SettingsActionType.UpdateDisableExplicitWarning,
  payload: value,
});

export type UpdateCaptionsSettingsAction = TypedAction<
  ReturnType<typeof updateCaptionsSettings>,
  SettingsActionType.UpdateCaptionsSettingsSuccess
>;

export type UpdateDisplayLanguageAction = TypedAction<
  ReturnType<typeof updateDisplayLanguage>,
  SettingsActionType.UpdateDisplayLanguage
>;

export type UpdateBlockExplicitSettingsAction = TypedAction<
  ReturnType<typeof updateBlockExplicitSettings>,
  SettingsActionType.BlockExplicitSuccess
>;

export type UpdateHidePrenatalSettingsAction = TypedAction<
  ReturnType<typeof updateHidePrenatalSettings>,
  SettingsActionType.HidePrenatalSuccess
>;

export type UpdateBlockExplicitSettingsRequestAction = TypedAction<
  ReturnType<typeof updateBlockExplicitSettingsRequest>,
  SettingsActionType.BlockExplicitSettingsRequest
>;

export type UpdateHidePrenatalSettingsRequestAction = TypedAction<
  ReturnType<typeof updateBlockPrenatalSettingsRequest>,
  SettingsActionType.HidePrenatalSettingsRequest
>;

export type SettingsRequestAction = TypedAction<
  ReturnType<typeof loadDetails>,
  SettingsActionType.SettingsRequest
>;

export type UpdateSettingsAction = TypedAction<
  ReturnType<typeof setSettings>,
  SettingsActionType.SetSettings
>;

export type UpdateDisableExplicitWarningAction = TypedAction<
  ReturnType<typeof updateDisableExplicitWarning>,
  SettingsActionType.UpdateDisableExplicitWarning
>;

type Actions =
  | UpdateCaptionsSettingsAction
  | UpdateBlockExplicitSettingsAction
  | UpdateBlockExplicitSettingsRequestAction
  | UpdateHidePrenatalSettingsRequestAction
  | UpdateHidePrenatalSettingsAction
  | UpdateDisplayLanguageAction
  | SettingsRequestAction
  | UpdateSettingsAction
  | UpdatePrivacySettingsAction
  | UpdateDisableExplicitWarningAction;

export const loadDetails = (
  presenter: SettingsPresenter = {
    displaySettings: (_: any) => null,
    getDisplayLanguage: () => Language.English,
    redirectToLoginPage: () => null,
  },
  toggle = false,
) => ({
  type: SettingsActionType.SettingsRequest,
  presenter,
  toggle,
});

type SettingsPresenter = {
  displaySettings(settings: SettingsModel): void;
  getDisplayLanguage(userId: string): Language;
  redirectToLoginPage(): void;
};

export type UpdateShareChallengeCompletionAction = TypedAction<
  ReturnType<typeof updateShareChallengeCompletion>,
  SettingsActionType.UpdateShareChallengeCompletion
>;

export const updateShareChallengeCompletion = (
  corporateWellnessGroup: CorporateWellnessGroup | null,
  presenter: SettingsUpdatedPresenter,
) => ({
  type: SettingsActionType.UpdateShareChallengeCompletion,
  presenter,
  corporateWellnessGroup,
});

export type UpdatePrivacySettingsAction = TypedAction<
  ReturnType<typeof updatePrivacySettings>,
  SettingsActionType.UpdatePrivacySettings
>;

// TODO: Update presenter pattern and possible FSA
export const updatePrivacySettings = (
  settings: ProfilePrivacySettings & CorporateWellnessSettings,
  previousSettings: Partial<ProfilePrivacySettings & CorporateWellnessSettings>,
  presenter: SettingsUpdatedPresenter,
) => ({
  type: SettingsActionType.UpdatePrivacySettings,
  presenter,
  settings,
  previousSettings,
});

export type UpdateNotificationSettingsAction = {
  type: SettingsActionType.UpdateNotificationSettings;
  presenter: SettingsUpdatedPresenter;
  settings: NotificationSettings;
};

export const updateNotificationSettings = (
  settings: NotificationSettings,
  presenter: SettingsUpdatedPresenter,
) => ({
  type: SettingsActionType.UpdateNotificationSettings,
  presenter,
  settings,
});

export type UpdateFeaturesSettingsAction = {
  type: SettingsActionType.UpdateFeaturesSettings;
  presenter: SettingsUpdatedPresenter;
  settings: FeaturesSettings;
};

export const updateFeaturesSettings = (
  settings: FeaturesSettings,
  presenter: SettingsUpdatedPresenter,
) => ({
  type: SettingsActionType.UpdateFeaturesSettings,
  presenter,
  settings,
});

export type UpdateDisplayPreferencesAction = {
  type: SettingsActionType.UpdateDisplayPreferences;
  presenter: SettingsUpdatedPresenter;
  settings: DisplayPreferences;
  source: Source;
};

export const updateDisplayPreferences = (
  settings: DisplayPreferences,
  presenter: SettingsUpdatedPresenter,
  source: Source,
) => ({
  type: SettingsActionType.UpdateDisplayPreferences,
  presenter,
  settings,
  source,
});

export type UpdateContentPreferencesAction = {
  type: SettingsActionType.UpdateContentPreferences;
  presenter: SettingsUpdatedPresenter;
  settings: ContentPreferences;
  source: Source;
};

export const updateContentPreferences = (
  settings: ContentPreferences,
  presenter: SettingsUpdatedPresenter,
  source: Source,
) => ({
  type: SettingsActionType.UpdateContentPreferences,
  presenter,
  settings,
  source,
});

export type UpdateClassLanguagePreferencesAction = {
  type: SettingsActionType.UpdateClassLanguagePreferences;
  presenter: SettingsUpdatedPresenter;
  settings: ClassLanguagePreferences;
  previousSettings: ClassLanguagePreferences;
  source: Source;
};

export const updateClassLanguagePreferences = (
  settings: ClassLanguagePreferences,
  previousSettings: ClassLanguagePreferences,
  presenter: SettingsUpdatedPresenter,
  source: Source,
) => ({
  type: SettingsActionType.UpdateClassLanguagePreferences,
  presenter,
  settings,
  previousSettings,
  source,
});

export type SettingsUpdatedPresenter = {
  displaySuccessMessage(): void;
  displayErrorMessage(): void;
  redirectToLoginPage(): void;
};

export type SettingsReducerState = {
  isClosedCaptionsOn: boolean;
  isStriveScorePrivate: boolean;
  isStriveScoreEnabled: boolean;
  closedCaptionsLanguagePreference: Locale | null;
  blockExplicit: boolean;
  blockPrenatal: boolean;
  distanceUnit: MeasurementUnit;
  classLanguagePreferences: ClassLanguage[];
  displayLanguage?: Language;
  workoutMapsPrivate: boolean;
  heightUnit: MeasurementUnit;
  weightUnit: MeasurementUnit;
  disableExplicitWarning: boolean;
  corporateWellnessGroup: {
    id: string;
    name: string;
    shareCorporateWellnessCompletion: boolean | null;
  } | null;
  feedPrivacy: FeedPrivacy;
};

export const SETTINGS_NAMESPACE = 'settings';

export type SettingsSelectorState = {
  [SETTINGS_NAMESPACE]: SettingsReducerState;
};

const DEFAULT_STATE: SettingsReducerState = {
  isClosedCaptionsOn: false,
  isStriveScoreEnabled: true,
  isStriveScorePrivate: true,
  closedCaptionsLanguagePreference: null,
  blockExplicit: false,
  blockPrenatal: false,
  classLanguagePreferences: [],
  distanceUnit: MeasurementUnit.Imperial,
  workoutMapsPrivate: true,
  heightUnit: MeasurementUnit.Imperial,
  weightUnit: MeasurementUnit.Imperial,
  disableExplicitWarning: false,
  corporateWellnessGroup: null,
  feedPrivacy: FeedPrivacy.MyFollowers,
};

export const isClosedCaptionsOn = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].isClosedCaptionsOn;

export const closedCaptionsLanguagePreference = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].closedCaptionsLanguagePreference;

export const getBlockExplicit = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].blockExplicit;

export const getHidePrenatal = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].blockPrenatal;

export const getClassLanguagePreferences = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].classLanguagePreferences;

export const getDisplayLanguage = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].displayLanguage;

export const getDistanceUnit = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].distanceUnit;

export const isWorkoutMapsPrivate = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].workoutMapsPrivate;

export const getIsStriveScorePrivate = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].isStriveScorePrivate;

export const getHeightUnit = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].heightUnit;

export const getWeightUnit = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].weightUnit;

export const getDisableExplicitWarning = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].disableExplicitWarning;

export const getFeedPrivacy = (state: SettingsSelectorState) =>
  state[SETTINGS_NAMESPACE].feedPrivacy;

export const settingsReducer: Reducer<ClosedCaptionsSettings> = (
  state: SettingsReducerState = DEFAULT_STATE,
  action: Actions,
) => {
  switch (action.type) {
    case SettingsActionType.UpdateCaptionsSettingsSuccess:
      return {
        ...state,
        isClosedCaptionsOn: action.payload.isClosedCaptionsOn,
        closedCaptionsLanguagePreference: action.payload.closedCaptionsLanguagePreference,
      };
    case SettingsActionType.UpdateDisplayLanguage:
      return { ...state, displayLanguage: action.payload.displayLanguage };
    case SettingsActionType.BlockExplicitSettingsRequest:
    case SettingsActionType.BlockExplicitSuccess:
      return {
        ...state,
        blockExplicit: action.payload.blockExplicit,
      };
    case SettingsActionType.HidePrenatalSettingsRequest:
    case SettingsActionType.HidePrenatalSuccess:
      return {
        ...state,
        blockPrenatal: action.payload.blockPrenatal,
      };
    case SettingsActionType.UpdatePrivacySettings:
      return {
        ...state,
        workoutMapsPrivate: action.settings.workoutMapsPrivate,
        corporateWellnessGroup: action.settings.corporateWellnessGroup,
        feedPrivacy: action.settings.feedPrivacy,
      };
    case SettingsActionType.SetSettings:
      return {
        ...state,
        ...pick(Object.keys(DEFAULT_STATE), action.payload),
      };
    case SettingsActionType.UpdateDisableExplicitWarning:
      return {
        ...state,
        disableExplicitWarning: action.payload,
      };
    default:
      return state;
  }
};
