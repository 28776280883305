import { formatDurationMinutesRound } from '@peloton/time';
import { ActivityFeed } from '@members/copy';
import type {
  ActivityFeedEvent,
  FeedWorkout,
  PelotonClass,
  PelotonClassFeedWorkout,
  WorkoutMetricsType,
} from '@members/graphql-swr/types.generated';
import { NotificationSource } from './constants';

const copyIdMappings = {
  avgHeartRate: ActivityFeed.AvgHeartRate,
  avgPace: ActivityFeed.AvgPace,
  avgSpeed: ActivityFeed.AvgSpeed,
  calories: ActivityFeed.Calories,
  distance: ActivityFeed.Distance,
  output: ActivityFeed.Output,
  striveScore: ActivityFeed.StriveScore,
  duration: ActivityFeed.Duration,
};

const metricsMapping = {
  CYCLE_BIKE: ['output', 'distance', 'striveScore', 'calories'],
  CYCLE_DIGITAL: ['calories', 'avgHeartRate', 'striveScore'],
  CYCLE_FREESTYLE: ['output', 'distance', 'duration'],
  FLOOR: ['calories', 'avgHeartRate', 'striveScore'],
  JUST_RIDE: ['avgSpeed', 'distance', 'duration'],
  JUST_RUN_WALK: ['avgPace', 'distance', 'duration'],
  OUTDOOR: ['avgPace', 'distance', 'striveScore', 'calories'],
  ROW_ROWER: ['output', 'avgPace', 'avgStrokeRate'],
  ROW_DIGITAL: ['calories', 'avgHeartRate', 'striveScore'],
  ROW_FREESTYLE: ['avgPace', 'distance', 'duration'],
  RUN_DIGITAL: ['calories', 'avgHeartRate', 'striveScore'],
  RUN_FREESTYLE: ['output', 'avgPace', 'duration'],
  RUN_TREAD: ['output', 'avgPace', 'striveScore', 'calories'],
  JUST_WORK_OUT_INDOOR: ['duration', 'avgHeartRate', 'striveScore'],
};

const shouldOmitCalories = (metricName: string) => {
  if (
    metricName === 'CYCLE_BIKE' ||
    metricName === 'OUTDOOR' ||
    metricName === 'RUN_TREAD'
  ) {
    return true;
  }
  return false;
};

// Format number of seconds (ex: 7.8) into MM:SS (ex: 7:48 )
const formatTime = (value: string) => {
  const minutes = value.split('.')[0];
  let seconds = value.split('.')[1];

  if (seconds && seconds.length === 1) {
    seconds = seconds + '0';
  }
  const roundedSeconds = Math.round((parseFloat(seconds ?? 0) / 100) * 60).toString();
  return minutes + ':' + roundedSeconds.padStart(2, '0');
};

const getValues = (
  statName: string,
  value: string,
  workoutMetrics: WorkoutMetricsType,
) => {
  if (statName === 'calories') {
    return [Math.round(parseFloat(value)).toString(), 'kcal'];
  } else if (statName === 'output') {
    return [Math.ceil(parseFloat(value) / 1000).toString(), 'kj'];
  } else if (statName === 'distance') {
    if (workoutMetrics.includes('ROW')) {
      return [value, 'm'];
    }
    return [value, 'mi'];
  } else if (statName === 'avgSpeed') {
    return [(Math.round(parseFloat(value) * 10) / 10).toString(), 'mph'];
  } else if (statName === 'avgPace') {
    if (workoutMetrics.includes('RUN') || workoutMetrics.includes('OUTDOOR')) {
      return [formatTime(value), 'min/mi'];
    } else if (workoutMetrics.includes('ROW')) {
      return [formatTime(value), 'min/500m'];
    } else {
      return [value, 'mph'];
    }
  } else if (statName === 'duration') {
    return [formatDurationMinutesRound(parseFloat(value)).toString(), 'min'];
  } else if (statName === 'striveScore') {
    const roundedValue = Math.round(parseFloat(value) * 10) / 10;
    return [roundedValue.toString(), ''];
  } else if (statName === 'avgHeartRate') {
    return [Math.round(parseFloat(value)).toString(), ''];
  }
  return [value, ''];
};

export const getHeaderName = (name: string) => {
  return copyIdMappings[name] ?? name;
};

export const getStatistics = (
  feedWorkout: PelotonClassFeedWorkout,
  workoutMetrics: WorkoutMetricsType,
) => {
  const hasStriveScore = feedWorkout?.striveScore !== 0 && feedWorkout?.striveScore;
  return Object.entries(feedWorkout).reduce(
    (acc: [string, string[]][], [stat, value]) => {
      if (metricsMapping[workoutMetrics]?.includes(stat) && (value as number) > 0) {
        const index = metricsMapping[workoutMetrics]?.indexOf(stat);
        if (
          stat !== 'calories' ||
          (stat === 'calories' && !hasStriveScore && !shouldOmitCalories(stat))
        ) {
          acc[index] = [
            getHeaderName(stat),
            getValues(stat, value.toString(), workoutMetrics),
          ];
        }
      }
      return acc;
    },
    [],
  );
};

export const getNotificationSource = (location: string): NotificationSource => {
  const locationMap: { [key: string]: NotificationSource } = {
    feed: NotificationSource.Feed,
    schedule: NotificationSource.Schedule,
    home: NotificationSource.Homescreen,
    challenges: NotificationSource.Challenges,
  };

  for (const key in locationMap) {
    if (location.includes(key)) {
      return locationMap[key];
    }
  }

  return NotificationSource.Workouts;
};

export const getEventType = (event: ActivityFeedEvent) => {
  switch (
    (event?.payload?.feedWorkout as FeedWorkout & { __typename: string })?.__typename
  ) {
    case 'OdysseyFeedWorkout':
      return 'Lanebreak';
    case 'GymFeedWorkout':
      return 'Gym';
    case 'FreestyleFeedWorkout':
      return 'Freestyle';
    case 'PelotonClassFeedWorkout':
      switch (
        (event?.payload?.pelotonClass as PelotonClass & {
          __typename: string;
        })?.__typename
      ) {
        case 'LiveClass':
          return 'Live Class';
        case 'OnDemandInstructorClass':
          return 'On Demand Class';
        case 'ScenicClass':
          return 'Scenic';
      }
  }
  return '';
};
