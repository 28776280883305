/**
 * This file run in the webpack environment (Netlify or Github Action) and gets its environment from the build configuration.
 *
 * `process.env.IS_RC` comes from `.github/workflows/build-and-deploy.yml` and is 'true' when the base ref / target branch of the pull request starts with production (specifically, Juno RCs)
 * `process.env.BRANCH` comes from Netlify (https://docs.netlify.com/configure-builds/environment-variables/) and is `production/members-onepeloton-com` or `production/tv-onepeloton-com` for the production build.
 *
 * So, as a result, both RCs and the production build are considered public environments.
 */
module.exports = {
  isPublic: () =>
    (!!process.env.IS_RC && process.env.IS_RC === 'true') ||
    !!process.env.BRANCH?.match(/^production\//),
};
