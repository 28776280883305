// eslint-disable-next-line no-restricted-imports
import { pathOr } from 'ramda';
import type { UserSelectorState } from '@peloton/auth';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import type { Time } from '@peloton/time';
import { toTime, toNowTime, getDifferenceIsLessThan } from '@peloton/time';
import { isDefined } from '@peloton/types';
import type { ClassDetailSelectorState } from '@engage/class-detail';
import type { ClassSelectorState, ClassPreview } from '@engage/classes';
import {
  getClassAnalyticsProperties,
  getClass,
  getDenormalizedClass,
  toClassPreview,
  isLive,
  isScenic,
  isAudioClass,
} from '@engage/classes';
import { getDeviceType } from '@engage/metadata';
import type { ScheduleSelectorState } from '@engage/scheduled-classes';
import { getScheduledClass } from '@engage/scheduled-classes';
import type { WorkoutSelectorState } from '@engage/workouts';
import { getWorkout, isFreestyleWorkout } from '@engage/workouts';
import type { FeatureToggleState } from '../feature-toggles';
import type { VideoSelectorState } from './redux';
import {
  getVideoActiveView,
  VideoActiveViewState,
  getStartTime,
  getCurrentTime,
  getWorkoutId,
  getTimePlaying,
  getIntroTimePlaying,
  getTimeCasting,
  getCaptions,
  getAudio,
} from './redux';
import {
  getCurrentVideoOffset,
  getClassEndOffset,
  getClassStartOffset,
  hasClassStarted,
} from './timeSelectors';

export const getClassId = (state: VideoSelectorState & WorkoutSelectorState) => {
  const workout = getInProgressWorkout(state);
  return workout && workout.classId;
};

export const getPelotonId = (state: VideoSelectorState & WorkoutSelectorState) => {
  const workout = getInProgressWorkout(state);
  return workout && workout.pelotonId;
};

export const getInProgressWorkout = (
  state: VideoSelectorState & WorkoutSelectorState,
) => {
  const workoutId = getWorkoutId(state);
  return isDefined(workoutId) ? getWorkout(state, workoutId) : undefined;
};

export const getScheduledClassForInProgressWorkout = (
  state: VideoClassSelectorState & ScheduleSelectorState,
) => {
  const workout = getInProgressWorkout(state);
  return workout && getScheduledClass(state, workout.pelotonId);
};

export const getWorkoutInfo = (
  state: VideoClassSelectorState,
): WorkoutInfo | undefined => {
  const startTime = getStartTime(state);
  const workoutId = getWorkoutId(state) ?? '';
  const workoutPlatform = pathOr<string>('', ['platform'], getWorkout(state, workoutId));

  return startTime
    ? {
        workoutStartTime: toTime(startTime, true),
        platform: getDeviceType(state, workoutPlatform),
      }
    : undefined;
};

export const getAllVideoAnalytics = (
  state: VideoClassSelectorState &
    ClassDetailSelectorState &
    FetcherSelectorState &
    FeatureToggleState &
    ScheduleSelectorState,
  isLivePlayer: boolean,
) => {
  const classId = getClassId(state) ?? '';
  const workoutId = getWorkoutId(state) ?? '';
  const klass = classId && getClass(state, classId);
  const isLiveClass = !!klass && isLive(klass);
  const isEncoreClass = isLivePlayer && !isLiveClass;
  const workoutInfo = getWorkout(state, workoutId);
  const pelotonId = getPelotonId(state);
  const scheduledClass = getScheduledClass(state, pelotonId ?? '');
  const isPremiere = scheduledClass?.liveClassCategory === 'premiere';
  return {
    classInfo: getClassAnalyticsProperties(state, classId),
    workoutInfo,
    videoInfo: getVideoAnalytics(state),
    workoutClassInfo: {
      isEncore: isEncoreClass,
      isLive: isLiveClass,
      isScenic: klass && isScenic(klass),
      isFreestyleWorkout: isFreestyleWorkout(workoutInfo),
      isPremiere: isPremiere,
    },
  };
};

export const getVideoAnalytics = (
  state: VideoClassSelectorState,
): VideoStateAnalyticsProperties => ({
  currentTimecode: getCurrentTime(state) ?? 0,
  sessionStart: getStartTime(state) ?? 0,
  timePlaying: getTimePlaying(state),
  introTimePlaying: getIntroTimePlaying(state),
  timeCasting: getTimeCasting(state),
  timeWithCaptions: getCaptions(state).timeWithCaptions,
  timeWithAudio: getAudio(state).timeWithAudio,
});

export const isExitingView = (state: VideoSelectorState) =>
  getVideoActiveView(state) === VideoActiveViewState.Exit;

export const isCompleteView = (state: VideoSelectorState) =>
  getVideoActiveView(state) === VideoActiveViewState.Complete;

export const isClassCompleted = (state: VideoClassSelectorState): boolean => {
  const scheduledClassId = getPelotonId(state) ?? '';
  const classEndOffset = getClassEndOffset(state, scheduledClassId);
  const currentVideoOffset = getCurrentVideoOffset(
    state,
    scheduledClassId,
    isClassLive(state),
  );

  /**
   * Classes return a END_OFFSET of DURATION + START_OFFSET.
   * For audio classes, many media files are only of DURATION lenth, so
   * the END_OFFSET will never be reachable.
   *
   * Ex. a 1200 minute class has a START_OFFSET of 1 and a END_OFFSET of 1201,
   * even though the media files is only 1200 minutes long.
   *
   * This adds the START_OFFSET to the CURRENT_OFFSET to properly return
   * true for this selector when an audio class is completed
   */
  let derivedCurrentVideoOffset = currentVideoOffset;

  if (classEndOffset !== undefined && isAudioOnlyClass(state)) {
    const classStartOffset = getClassStartOffset(state, scheduledClassId) ?? 0;
    derivedCurrentVideoOffset = Math.ceil(currentVideoOffset + classStartOffset);
  }

  return !!(
    classEndOffset !== undefined &&
    derivedCurrentVideoOffset !== undefined &&
    derivedCurrentVideoOffset >= classEndOffset
  );
};

export const isLessThan60SecondsFromNow = (timestamp: number) =>
  getDifferenceIsLessThan(toNowTime(), toTime(timestamp, true), 60, 'seconds');

export const isLessThan3MinutesFromNow = (timestamp: number) =>
  getDifferenceIsLessThan(toNowTime(), toTime(timestamp, true), 3, 'minutes');

export const getHasWatchedLessThan60Seconds = (state: VideoSelectorState) => {
  const videoStartTime = getStartTime(state);

  if (videoStartTime === null) {
    return true;
  }
  const videoPlayingTime = getTimePlaying(state);

  return videoPlayingTime < 60;
};

export const getIsLeavingEarly = (state: VideoClassSelectorState) => {
  const pelotonId = getPelotonId(state);
  const classIsLive = isClassLive(state);
  const hasStarted = !!pelotonId && hasClassStarted(state, pelotonId, classIsLive);
  return (classIsLive && !hasStarted) || getHasWatchedLessThan60Seconds(state);
};

export const isEncore = (
  state: VideoSelectorState & ScheduleSelectorState & WorkoutSelectorState,
): boolean => {
  const pelotonId = getPelotonId(state);
  const videoPeloton = pelotonId ? getScheduledClass(state, pelotonId) : undefined;
  return !!(videoPeloton && videoPeloton.isEncore);
};

export const isClassLive = (
  state: VideoSelectorState & ClassSelectorState & WorkoutSelectorState,
): boolean => {
  const videoClassId = getClassId(state);
  const videoClass = videoClassId ? getClass(state, videoClassId) : undefined;
  return videoClass ? isLive(videoClass) : false;
};

export const getVideoClassInfo = (
  state: VideoClassSelectorState,
): ClassPreview | undefined => {
  const klass = getDenormalizedClassForPlayingVideo(state);
  return klass ? toClassPreview(klass) : undefined;
};

export const hasClosedCaptions = (state: VideoClassSelectorState): boolean => {
  const klass = getDenormalizedClassForPlayingVideo(state);
  return !!klass && klass.captionLocales.length > 0;
};

export const getClosedCaptionLocalesInRide = (state: VideoClassSelectorState) => {
  const klass = getDenormalizedClassForPlayingVideo(state);
  return klass?.captionLocales;
};

export const getClosedCaptionLocalesInRideForUser = (state: VideoClassSelectorState) => {
  const klass = getDenormalizedClassForPlayingVideo(state);
  return klass?.userCaptionLocales;
};

export const isAudioOnlyClass = (state: VideoClassSelectorState): boolean => {
  const klass = getDenormalizedClassForPlayingVideo(state);
  return !!klass && isAudioClass(klass.contentFormat);
};

export const isSkipIntroAvailable = (
  state: VideoClassSelectorState & WorkoutSelectorState,
): boolean => {
  const workout = getInProgressWorkout(state);
  return workout?.isSkipIntroAvailable ?? false;
};

export const getCurrentOffsetForPlayingVideo = (
  state: ClassDetailSelectorState & VideoClassSelectorState,
) => {
  const pelotonId = getPelotonId(state);
  if (typeof pelotonId === 'undefined') {
    return 0;
  }
  const liveClass = isClassLive(state);
  return getCurrentVideoOffset(state, pelotonId, liveClass);
};

export const getDenormalizedClassForPlayingVideo = (state: VideoClassSelectorState) => {
  const classId = getClassId(state);
  return classId === undefined ? undefined : getDenormalizedClass(state, classId);
};

export type VideoClassInfo = Pick<
  ClassPreview,
  Exclude<keyof ClassPreview, 'classStartTime'>
>;

export type VideoClassSelectorState = VideoSelectorState &
  ClassSelectorState &
  FetcherSelectorState &
  WorkoutSelectorState &
  UserSelectorState &
  ScheduleSelectorState;

export type WorkoutInfo = {
  workoutStartTime: Time;
  platform: string;
};

export type VideoStateAnalyticsProperties = {
  currentTimecode: number;
  sessionStart: number;
  timePlaying: number;
  introTimePlaying: number;
  timeCasting: number;
  timeWithCaptions: number;
  timeWithAudio: number;
};

export type WorkoutClassInfo = {
  isEncore: boolean;
  isLive: boolean;
  isScenic: boolean;
  isFreestyleWorkout: boolean;
  isPremiere: boolean;
};
