export const trackClickBoutique = (source: string) => ({
  event: 'Clicked Boutique',
  source,
});

export const trackClickSupport = (source: string) => ({
  event: 'Clicked Support',
  source,
});

export const trackClickInstructor = (
  instructorName: string | undefined,
  source: string,
) => ({
  event: 'Viewed Instructor Detail',
  instructorName,
  source,
});

export const trackClickStudio = () => ({
  event: 'Clicked Studio',
});

export const trackClickBlog = () => ({
  event: 'Clicked Blog',
});

export const trackClickPreferences = (source: string) => ({
  event: 'Viewed Preferences',
  source,
});

export const trackClickOrderHistory = () => ({
  event: 'Clicked Order History',
});
