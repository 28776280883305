import type { Locale, Language } from '@peloton/internationalize';
import type { FeedPrivacy } from '@engage/graphql';

export enum ChatRestriction {
  all = 'ALL',
  following = 'FOLLOWING',
  none = 'NONE',
}

export enum MeasurementUnit {
  Imperial = 'imperial',
  Metric = 'metric',
}

export type SettingsModel = ProfilePrivacySettings &
  NotificationSettings &
  DisplayPreferences &
  UserMeasurementPreferences &
  ContentPreferences &
  ClassLanguagePreferences &
  StriveScoreSettings &
  CorporateWellnessSettings;

export type CorporateWellnessSettings = {
  corporateWellnessGroup: {
    id?: string;
    name?: string;
    shareCorporateWellnessCompletion: boolean | null;
  } | null;
};

export type CorporateWellnessGroup = {
  id?: string;
  name?: string;
  shareCorporateWellnessCompletion: boolean | null;
};

export type ProfilePrivacySettings = {
  chatRestriction: ChatRestriction;
  isProfilePrivate: boolean;
  isStriveScorePrivate: boolean;
  workoutMapsPrivate: boolean;
  gender: boolean;
  age: boolean;
  feedPrivacy: FeedPrivacy;
  allowMarketingGrowth: boolean;
  allowPersonalizedExperience: boolean;
  hideFromContactSearch: boolean;
  hideFromUserSearch: boolean;
  syncContacts: boolean;
};

export type NotificationSettings = {
  shouldSendFollowerEmailNotification: boolean;
  shouldSendFacebookEmailNotification: boolean;
};

export type FeaturesSettings = {
  isStriveScoreEnabled: boolean;
  isStriveScorePrivate: boolean;
};

export type DisplayPreferences = {
  distanceUnit: MeasurementUnit;
  displayLanguage: Language;
};

export type ContentPreferences = {
  blockExplicit: boolean;
  blockPrenatal: boolean;
};

export type UserMeasurementPreferences = {
  heightUnit: MeasurementUnit;
  weightUnit: MeasurementUnit;
};

export type ClosedCaptionsSettings = {
  isClosedCaptionsOn: boolean;
  closedCaptionsLanguagePreference: Locale | null;
};

export type BlockExplicitSettings = {
  blockExplicit: boolean;
};

export type BlockPrenatalSettings = {
  blockPrenatal: boolean;
};

export type Caption = {
  locale: Locale;
  enabled: boolean;
  localeName: string;
};

export type ClassLanguage = {
  locale: Locale;
  displayAllClasses: boolean;
  captions: Caption[];
  localeName: string;
};

export type ClassLanguagePreferences = {
  classLanguagePreferences: ClassLanguage[];
};

export type StriveScoreSettings = {
  isStriveScoreEnabled: boolean;
};
