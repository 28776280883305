import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import { gray3, superRed } from '@engage/colors';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { header5Small, label7Large } from '@engage/typography';
import { Button1Small } from '@members/buttons';
import type { OverridableVariable } from '@members/feature-toggles/models';
import type { VariablesForFeatures } from '@members/feature-toggles/redux';
import { getVariables } from '@members/feature-toggles/redux';
import { LightInput } from '@members/form/input';
import type { VariableQuery, VariableType } from '@members/optimizely';
import { useSetFeatureVariable } from '../featureToggles';
import type { Feature } from '../toggles';
import { variables } from '../toggles';

const { useCallback, useState } = React;

type Props = {
  for: Feature;
};

const isVariableKey = (f: string): f is keyof typeof variables => Boolean(variables[f]);

const MaybeVariables: React.FC<React.PropsWithChildren<Props>> = ({ for: feature }) =>
  isVariableKey(feature) ? <Variables for={feature} /> : null;

const Variables: React.FC<React.PropsWithChildren<{ for: keyof typeof variables }>> = ({
  for: feature,
}) => {
  const variablesForFeature = (variables[feature] as any) as VariablesForFeatures<
    typeof variables,
    typeof feature
  >[];

  return (
    <MultirowItem>
      {variablesForFeature.map((q, idx) => toRow(q, feature, idx))}
    </MultirowItem>
  );
};

const emoji = ({ value, originalValue }: OverridableVariable<any>) =>
  value !== originalValue ? ' 🚧 ' : '';

const toRow = (
  query: VariableQuery<VariableType>,
  feature: keyof typeof variables,
  idx: number,
) => <VariableRow key={[query.key, idx].join(':')} query={query} feature={feature} />;

type VRProps = {
  query: VariableQuery<VariableType>;
  feature: keyof typeof variables;
};
const VariableRow: React.FC<React.PropsWithChildren<VRProps>> = ({ query, feature }) => {
  const { defaultValue, value, originalValue } = useReduxState(getVariables)[feature][
    query.key
  ];
  const saveChange = useSetFeatureVariable();
  const [inputValue, setInputValue] = useState<boolean>(
    typeof value === 'string' ? value === 'true' : value,
  );
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(Boolean(JSON.parse(e.target.value)));
  const onToggle = () => {
    setInputValue(!inputValue);
    saveChange(feature, query.key, !inputValue);
  };
  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      saveChange(feature, query.key, inputValue);
    },
    [saveChange, inputValue],
  );
  const showToggle = query.type === 'boolean';
  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Key htmlFor={[feature, query.key].join('.')}>
          {emoji({ value, originalValue, defaultValue })}
          {query.key}
          <br />
          <Type>(type: {query.type})</Type>
        </Key>
        {showToggle ? (
          <Switch
            id={query.key}
            aria-checked={inputValue}
            checked={Boolean(inputValue)}
            role="switch"
            onChange={onToggle}
            offColor={gray3}
            onColor={superRed}
            uncheckedIcon={false}
            checkedIcon={false}
            height={25}
            width={40}
          />
        ) : (
          <Input
            name={[feature, query.key].join('.')}
            type="text"
            value={String(inputValue)}
            isEmptyField={false}
            disabled={false}
            error={false}
            onChange={onChange}
          />
        )}
        {!showToggle && <SaveButton type="submit">Save</SaveButton>}
      </Row>
    </form>
  );
};

const MultirowItem = styled.li`
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 0 ${spaces.small}px;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  height: 52px;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 10px 200px auto 130px;
  grid-template-areas:
    '. key input input save'
    '. type input input save';
`;

const Key = styled.label`
  ${header5Small}
  pointer-events: none;
  padding-left: 20px;
  grid-area: key;
`;

const Type = styled.span`
  ${label7Large}
  pointer-events: auto;
  grid-area: type;
`;

const Input = styled(LightInput)`
  margin: 0 10px;
  grid-area: input;
`;

const SaveButton = styled(Button1Small)`
  margin-left: 10px;
  grid-area: save;
  align-self: center;
`;

export default MaybeVariables;
