import type { Member } from '@engage/members';
import { toSocialAnalytics } from '@engage/members';

export const trackViewedAchievementsPage = ({
  member,
  sharedLeaderboardTag,
}: {
  member: Member;
  sharedLeaderboardTag: string | null;
}) => ({
  event: 'Viewed Achievements',
  ...toSocialAnalytics(member, sharedLeaderboardTag),
});
