import { isNil } from 'ramda';
import { toCurrentSegmentDisplay } from '@engage/video-domain';
import { getTimeline, isTimelineEmpty } from './redux';
import type { VideoClassSelectorState } from './selectors';
import { isClassLive, getPelotonId } from './selectors';
import { getCurrentVideoOffset } from './timeSelectors';

export type SegmentState = VideoClassSelectorState;

export const getCurrentSegment = (
  state: SegmentState,
  segmentDisplayGetter = getCurrentSegmentDisplay,
) => {
  const currentSegmentDisplay = segmentDisplayGetter(state);

  if (isNil(currentSegmentDisplay)) {
    return undefined;
  }

  const {
    isBeforeClassStart,
    isAfterClassEnd,
    segments,
    currentSegmentIndex,
  } = currentSegmentDisplay;

  if (isBeforeClassStart || isAfterClassEnd) {
    return undefined;
  }

  return segments[currentSegmentIndex];
};

export const getCurrentSegmentDisplay = (state: SegmentState) => {
  const pelotonId = getPelotonId(state) ?? '';
  const currentVideoOffset = getCurrentVideoOffset(state, pelotonId, isClassLive(state));
  const timeline = getTimeline(state);

  if (isTimelineEmpty(timeline)) {
    return undefined;
  }

  return toCurrentSegmentDisplay(currentVideoOffset, timeline);
};
