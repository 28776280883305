import type { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';
import { getSignedInUserId } from '@peloton/auth';
import type { UpdateDisplayPreferencesAction } from '@engage/settings/redux';
import { updateDisplayLanguage } from '@engage/settings/redux';
import { getPersistedOrBrowserDisplayLanguage, persist } from '@members/localization';

export default function* ({
  settings,
  source,
}: UpdateDisplayPreferencesAction): SagaIterator {
  try {
    const userId = yield select(getSignedInUserId);
    if (userId) {
      const oldDisplayLanguage = yield call(
        getPersistedOrBrowserDisplayLanguage(),
        userId,
      );
      if (oldDisplayLanguage !== settings.displayLanguage) {
        yield call(persist, settings.displayLanguage, userId);
        yield put(updateDisplayLanguage(settings.displayLanguage));
      }
    }
  } catch {
    // do nothing
  }
}
