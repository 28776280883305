import * as sdk from '@optimizely/optimizely-sdk';
import { useEffect } from 'react';
import useSWR from 'swr';
import type { PendingResult } from '@engage/result';
import { ok, error as engageError, pending } from '@engage/result';

type Datafile = object;

const fetchDataFile = async (dataFileUrl: string): Promise<Datafile> => {
  const res = await fetch(dataFileUrl, {
    method: 'GET',
  });
  return await res.json();
};

export const toClient = async (
  dataFilrUrl: string,
  defaultDatafile?: Object,
): Promise<sdk.Client> => {
  let datafile = null;
  try {
    datafile = await fetchDataFile(dataFilrUrl);
  } catch (e) {
    if (!defaultDatafile) {
      throw e;
    } else {
      datafile = defaultDatafile;
    }
  }

  return sdk.createInstance({ datafile });
};

export type PendingClient = PendingResult<sdk.Client, undefined>;
const MEMBERS_OPTIMIZELY = 'MembersOptimizely';
export const useClient = () => {
  const { data, mutate } = useSWR<PendingClient>(MEMBERS_OPTIMIZELY, {
    fallbackData: pending,
  });
  return [data as PendingClient, mutate] as const;
};
export const InitOptimizelyClient = ({
  dataFileUrl,
  defaultDatafile,
}: {
  dataFileUrl: string;
  defaultDatafile?: Object;
}) => {
  const [, setClient] = useClient();

  useEffect(() => {
    sdk.setLogLevel(sdk.enums.LOG_LEVEL.ERROR);
    toClient(dataFileUrl, defaultDatafile)
      .then(c => {
        setClient(ok(c));
      })
      .catch(_ => {
        setClient(engageError(undefined));
      });
  }, []);

  return null;
};
