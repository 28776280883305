import type { toEncodedClientDetails } from '@peloton/analytics/clientDetailsHeader';
import { getClassSearch, getPopularSearchQueries, getArtistSearch } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type Params = {
  pelotonPlatform: Parameters<typeof getArtistSearch>[2];
  query: string;
  headers?: {
    ['Peloton-Client-Details']?: ReturnType<typeof toEncodedClientDetails>;
    ['PELOTON-PLATFORM']?: string;
  };
};

export const toSearchFetchers = (api: typeof engageApi = engageApi) => ({
  ArtistSearch: toFetcher(
    (params: Params) => `ArtistSearch:${params.query}`,
    params =>
      unwrap(() =>
        getArtistSearch(api, params.query, params.pelotonPlatform, {
          headers: params?.headers,
        }),
      ),
  ),
  ClassSearch: toFetcher(
    (params: {
      query?: string;
      artistId?: string;
      cacheBuster: string;
      headers?: Params['headers'];
    }) => `ClassSearch:${params.artistId ?? params.query}:${params.cacheBuster}`,
    params =>
      unwrap(() =>
        getClassSearch(api, params.query, params.artistId, {
          headers: params?.headers,
        }),
      ),
  ),
  PopularQueries: toFetcher(
    () => `PopularQueries`,
    () => unwrap(() => getPopularSearchQueries(api)),
  ),
});
