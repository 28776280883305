import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { LIBRARY_CATEGORY_ROUTE, LIBRARY_CATEGORY_SELECTION_ROUTE } from './urls';

const Routes = [
  <Route
    key={LIBRARY_CATEGORY_SELECTION_ROUTE}
    exact={true}
    path={LIBRARY_CATEGORY_SELECTION_ROUTE}
    component={lazyLoader(
      () => import('./ClassesRedirectPage' /* webpackChunkName: "ClassesRedirectPage" */),
    )}
  />,
  <Route
    key={LIBRARY_CATEGORY_ROUTE}
    exact
    path={LIBRARY_CATEGORY_ROUTE}
    component={lazyLoader(
      () => import('./LibraryPage' /* webpackChunkName: "LibraryPage" */),
    )}
  />,
];

export default Routes;
