// eslint-disable-next-line no-restricted-imports
import { toFetcher } from '@engage/data-fetch-v2/fetchers/toFetcher';
import type {
  JoinProgramMutation,
  JoinProgramMutationVariables,
} from '@members/graphql-swr/schemas/programs/mutations/JoinProgram.generated';
import { JoinProgramDocument } from '@members/graphql-swr/schemas/programs/mutations/JoinProgram.generated';
import type {
  LeaveProgramMutation,
  LeaveProgramMutationVariables,
} from '@members/graphql-swr/schemas/programs/mutations/LeaveProgram.generated';
import { LeaveProgramDocument } from '@members/graphql-swr/schemas/programs/mutations/LeaveProgram.generated';
import type {
  ProgramWeekSeenMutation,
  ProgramWeekSeenMutationVariables,
} from '@members/graphql-swr/schemas/programs/mutations/ProgramWeekSeen.generated';
import { ProgramWeekSeenDocument } from '@members/graphql-swr/schemas/programs/mutations/ProgramWeekSeen.generated';
import type {
  SkipProgramClassMutation,
  SkipProgramClassMutationVariables,
} from '@members/graphql-swr/schemas/programs/mutations/SkipProgramClass.generated';
import { SkipProgramClassDocument } from '@members/graphql-swr/schemas/programs/mutations/SkipProgramClass.generated';
import type {
  StartProgramClassMutation,
  StartProgramClassMutationVariables,
} from '@members/graphql-swr/schemas/programs/mutations/StartProgramClass.generated';
import { StartProgramClassDocument } from '@members/graphql-swr/schemas/programs/mutations/StartProgramClass.generated';
import type {
  CompletedProgramsQuery,
  CompletedProgramsQueryVariables,
} from '@members/graphql-swr/schemas/programs/queries/CompletedPrograms.generated';
import { CompletedProgramsDocument } from '@members/graphql-swr/schemas/programs/queries/CompletedPrograms.generated';
import type {
  ProgramQuery,
  ProgramQueryVariables,
} from '@members/graphql-swr/schemas/programs/queries/Program.generated';
import { ProgramDocument } from '@members/graphql-swr/schemas/programs/queries/Program.generated';
import type {
  ProgramProgressQuery,
  ProgramProgressQueryVariables,
} from '@members/graphql-swr/schemas/programs/queries/ProgramProgress.generated';
import { ProgramProgressDocument } from '@members/graphql-swr/schemas/programs/queries/ProgramProgress.generated';
import type {
  ProgramsQuery,
  ProgramsQueryVariables,
} from '@members/graphql-swr/schemas/programs/queries/Programs.generated';
import { ProgramsDocument } from '@members/graphql-swr/schemas/programs/queries/Programs.generated';
import type {
  ProgressOfProgramsQuery,
  ProgressOfProgramsQueryVariables,
} from '@members/graphql-swr/schemas/programs/queries/ProgressOfPrograms.generated';
import { ProgressOfProgramsDocument } from '@members/graphql-swr/schemas/programs/queries/ProgressOfPrograms.generated';
import { graphQlApi } from '../singletons';
import { unwrap, getDocumentKey, graphQLClient, toMutate } from './utils';

export const toProgramsFetchersGQL = (api: typeof graphQlApi = graphQlApi) => ({
  ProgramsQuery: toFetcher(
    (queryVariables: ProgramsQueryVariables) =>
      getDocumentKey(ProgramsDocument, queryVariables),
    (queryVariables: ProgramsQueryVariables) =>
      unwrap(() =>
        graphQLClient<ProgramsQuery, ProgramsQueryVariables>(
          graphQlApi,
          ProgramsDocument,
          queryVariables,
        ),
      ),
  ),
  ProgressOfProgramsQuery: toFetcher(
    (queryVariables: ProgressOfProgramsQueryVariables) =>
      getDocumentKey(ProgressOfProgramsDocument, queryVariables),
    (queryVariables: ProgressOfProgramsQueryVariables) =>
      unwrap(() =>
        graphQLClient<ProgressOfProgramsQuery, ProgressOfProgramsQueryVariables>(
          graphQlApi,
          ProgressOfProgramsDocument,
          queryVariables,
        ),
      ),
  ),
  CompletedPrograms: toFetcher(
    (queryVariables: CompletedProgramsQueryVariables) =>
      getDocumentKey(CompletedProgramsDocument, queryVariables),
    (queryVariables: CompletedProgramsQueryVariables) =>
      unwrap(() =>
        graphQLClient<CompletedProgramsQuery, CompletedProgramsQueryVariables>(
          graphQlApi,
          CompletedProgramsDocument,
          queryVariables,
        ),
      ),
  ),
  ProgramQuery: toFetcher(
    (queryVariables: ProgramQueryVariables) =>
      getDocumentKey(ProgramDocument, queryVariables),
    (queryVariables: ProgramQueryVariables) =>
      unwrap(() =>
        graphQLClient<ProgramQuery, ProgramQueryVariables>(
          graphQlApi,
          ProgramDocument,
          queryVariables,
        ),
      ),
  ),
  ProgramProgressQuery: toFetcher(
    (queryVariables: ProgramProgressQueryVariables) =>
      getDocumentKey(ProgramProgressDocument, queryVariables),
    (queryVariables: ProgramProgressQueryVariables) =>
      unwrap(() =>
        graphQLClient<ProgramProgressQuery, ProgramProgressQueryVariables>(
          graphQlApi,
          ProgramProgressDocument,
          queryVariables,
        ),
      ),
  ),
  JoinProgramMutation: toMutate(
    (queryVariables: JoinProgramMutationVariables) =>
      getDocumentKey(JoinProgramDocument, queryVariables),
    (queryVariables: JoinProgramMutationVariables) =>
      unwrap(() =>
        graphQLClient<JoinProgramMutation, JoinProgramMutationVariables>(
          graphQlApi,
          JoinProgramDocument,
          queryVariables,
        ),
      ),
  ),
  LeaveProgramMutation: toMutate(
    (queryVariables: LeaveProgramMutationVariables) =>
      getDocumentKey(LeaveProgramDocument, queryVariables),
    (queryVariables: LeaveProgramMutationVariables) =>
      unwrap(() =>
        graphQLClient<LeaveProgramMutation, LeaveProgramMutationVariables>(
          graphQlApi,
          LeaveProgramDocument,
          queryVariables,
        ),
      ),
  ),
  ProgramWeekSeenMutation: toMutate(
    (queryVariables: ProgramWeekSeenMutationVariables) =>
      getDocumentKey(ProgramWeekSeenDocument, queryVariables),
    (queryVariables: ProgramWeekSeenMutationVariables) =>
      unwrap(() =>
        graphQLClient<ProgramWeekSeenMutation, ProgramWeekSeenMutationVariables>(
          graphQlApi,
          ProgramWeekSeenDocument,
          queryVariables,
        ),
      ),
  ),
  SkipProgramClassMutation: toMutate(
    (queryVariables: SkipProgramClassMutationVariables) =>
      getDocumentKey(SkipProgramClassDocument, queryVariables),
    (queryVariables: SkipProgramClassMutationVariables) =>
      unwrap(() =>
        graphQLClient<SkipProgramClassMutation, SkipProgramClassMutationVariables>(
          graphQlApi,
          SkipProgramClassDocument,
          queryVariables,
        ),
      ),
  ),
  StartProgramClassMutation: toMutate(
    (queryVariables: StartProgramClassMutationVariables) =>
      getDocumentKey(StartProgramClassDocument, queryVariables),
    (queryVariables: StartProgramClassMutationVariables) =>
      unwrap(() =>
        graphQLClient<StartProgramClassMutation, StartProgramClassMutationVariables>(
          graphQlApi,
          StartProgramClassDocument,
          queryVariables,
        ),
      ),
  ),
});
