import { throttle } from 'frame-throttle';
import React from 'react';
import { getIsBreakpointLessThan } from '@peloton/responsive';
import { isDefined } from '@peloton/types';
import { useReduxState } from '@engage/redux';

export const BROWSE_NAV_BAR_HEIGHT = 56;
export const HEADER_MOBILE_HEIGHT = 48;
export const HEADER_TABLET_HEIGHT = 64;
export const SEARCH_INPUT_CONTAINER_HEIGHT = 80;
export const PERSISTENT_NAV_BAR_HEIGHT = 80;

type Context = {
  navBarRef: React.MutableRefObject<HTMLDivElement>;
  searchInputRef: React.MutableRefObject<HTMLDivElement>;
  browseCategoriesRef: React.MutableRefObject<HTMLDivElement>;
  browseContainerRef: React.MutableRefObject<HTMLDivElement>;
  searchContainerRef: React.MutableRefObject<HTMLDivElement>;
  setNavBarAnimation: (offset: number) => void;
};

const makeContext = () => ({
  navBarRef: React.createRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>,
  searchInputRef: React.createRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>,
  browseCategoriesRef: React.createRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>,
  browseContainerRef: React.createRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>,
  searchContainerRef: React.createRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>,
  setNavBarAnimation: () => null,
});

export const ScrollContext = React.createContext<Context>(makeContext());

export const ScrollProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {
    navBarRef,
    searchInputRef,
    browseCategoriesRef,
    browseContainerRef,
    searchContainerRef,
  } = React.useMemo(() => makeContext(), []);

  const isMobile = useReduxState(getIsBreakpointLessThan, 'tablet');

  const setNavBarAnimation = React.useCallback(
    (offsetChange: number) => {
      const headerOffset = isMobile ? HEADER_MOBILE_HEIGHT : HEADER_TABLET_HEIGHT;

      const navBarEl = navBarRef.current;
      const minNavBarTop = headerOffset - PERSISTENT_NAV_BAR_HEIGHT;
      const maxNavBarTop = headerOffset;

      const searchInputEl = searchInputRef.current;
      const minSearchInputTop = headerOffset;
      const maxSearchInputTop = headerOffset + PERSISTENT_NAV_BAR_HEIGHT;

      const browseCategoriesEl = browseCategoriesRef.current;
      const minBrowseCategoriesTop = headerOffset;
      const maxBrowseCategoriesTop = headerOffset + PERSISTENT_NAV_BAR_HEIGHT;

      const browseContainerEl = browseContainerRef.current;
      const minBrowseContainerPadding = BROWSE_NAV_BAR_HEIGHT;
      const maxBrowseContainerPadding = BROWSE_NAV_BAR_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT;

      const searchContainerEl = searchContainerRef.current;
      const minSearchContainerPadding = SEARCH_INPUT_CONTAINER_HEIGHT;
      const maxSearchContainerPadding =
        SEARCH_INPUT_CONTAINER_HEIGHT + PERSISTENT_NAV_BAR_HEIGHT;

      setScrollChange(navBarEl, offsetChange, minNavBarTop, maxNavBarTop, 'top');
      setScrollChange(
        searchInputEl,
        offsetChange,
        minSearchInputTop,
        maxSearchInputTop,
        'top',
      );
      setScrollChange(
        browseCategoriesEl,
        offsetChange,
        minBrowseCategoriesTop,
        maxBrowseCategoriesTop,
        'top',
      );
      setScrollChange(
        browseContainerEl,
        offsetChange,
        minBrowseContainerPadding,
        maxBrowseContainerPadding,
        'padding-top',
      );
      setScrollChange(
        searchContainerEl,
        offsetChange,
        minSearchContainerPadding,
        maxSearchContainerPadding,
        'padding-top',
      );
    },
    [isMobile],
  );

  return (
    <ScrollContext.Provider
      value={{
        navBarRef,
        searchInputRef,
        browseCategoriesRef,
        browseContainerRef,
        searchContainerRef,
        setNavBarAnimation: throttle((offset: number) => {
          setNavBarAnimation(offset);
        }),
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

const setScrollChange = (
  element: HTMLDivElement | null,
  offsetChange: number,
  minValue: number,
  maxValue: number,
  styleProp: string,
) => {
  if (isDefined(element)) {
    const propValue = window.getComputedStyle(element)?.getPropertyValue(styleProp);
    element.style[styleProp] = `${Math.max(
      minValue,
      Math.min(maxValue, parseInt(propValue, 10) - offsetChange),
    )}px`;
  }
};
