import React from 'react';
import styled from 'styled-components';
import { slate2 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label14 } from '@engage/typography';
import { Tooltip } from '@members/tooltips';

type Props = {
  tooltipId: string;
  setIsExpanded(value: boolean): void;
};

const MiniNavTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  tooltipId,
  setIsExpanded,
}) => {
  return (
    <StyledIconTooltip
      backgroundColor={slate2}
      tooltipId={tooltipId}
      setIsExpanded={setIsExpanded}
      event="mouseenter"
      eventOff="mouseleave"
    >
      <Container>
        <Text>{tooltipId}</Text>
      </Container>
    </StyledIconTooltip>
  );
};

export const AvatarTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  tooltipId,
  setIsExpanded,
}) => {
  return (
    <StyledAvatarTooltip
      backgroundColor={slate2}
      tooltipId={tooltipId}
      setIsExpanded={setIsExpanded}
      event="mouseenter"
      eventOff="mouseleave"
    >
      <Container>
        <Text>{tooltipId}</Text>
      </Container>
    </StyledAvatarTooltip>
  );
};

const Container = styled.div`
  align-items: center;
`;

const StyledTooltip = `
  .tooltip {
    position: absolute;
    background-color: ${slate2};
    padding: ${spaces.xSmall}px;
    gap: ${spaces.xxxSmall}px;
    border-radius: ${spaces.xSmall}px;
    opacity: 1;
  }
`;

const StyledAvatarTooltip = styled(Tooltip)`
  ${StyledTooltip}
  .tooltip {
    margin-top: ${spaces.xSmall}px !important;
  }
`;

const StyledIconTooltip = styled(Tooltip)`
  ${StyledTooltip}
  .tooltip {
    margin-top: ${spaces.small}px !important;
  }
`;

const Text = styled.p`
  ${label14}
  color: white;
`;

export default MiniNavTooltip;
