import type { EventType, Identity, Load, AnalyticsInterface } from '@peloton/analytics';
import {
  toDeviceCategoryFromWindow,
  toAnalyticsKeysWithOverrides,
} from '@peloton/analytics';
import * as Segment from '@peloton/analytics/segment';

const segmentHasIdentity = (): Promise<boolean> =>
  new Promise(resolve =>
    (<any>window).analytics?.ready(() => {
      const user = (<any>window).analytics.user();
      resolve(Boolean(user && user.id()));
    }),
  );

export const Analytics = {
  Make: (
    track: typeof Segment.trackSegment,
    hasIdentity: typeof segmentHasIdentity,
  ): AnalyticsInterface => {
    class Internal {
      track<T extends object>(mixedEvent: EventType<T>): void {
        if (typeof mixedEvent === 'string') {
          console.warn(`Unhandled track event ${mixedEvent}`);
        } else {
          const { event, ...properties } = mixedEvent;
          hasIdentity().then(identity => {
            if (identity) {
              track({
                event,
                properties: toAnalyticsKeysWithOverrides({
                  ...properties,
                  platformType: 'Web',
                  formFactor: toDeviceCategoryFromWindow(),
                }),
              });
            }
          });
        }
      }

      group() {
        // no op - this is not used
      }

      identify<T extends object>(identity: Identity<T>) {
        Segment.identifySegment(identity);
      }

      reset(): void {
        Segment.resetSegment();
      }

      load(load: Load): void {
        Segment.loadSegment(load);
      }

      page<T extends object>(page: Segment.SegmentPage<T>): void {
        Segment.pageSegment(page);
      }

      ready(callback: () => void): void {
        Segment.readySegment(callback);
      }

      anonymousId(callback: (anonymousId: string) => void): void {
        Segment.anonymousIdSegment(callback);
      }
    }
    return new Internal();
  },
};

export const makeAnalytics = (
  track = Segment.trackSegment,
  hasIdentity = segmentHasIdentity,
) => Analytics.Make(track, hasIdentity);
