import { schema } from 'normalizr';

export type Locale = {
  locale: string;
  displayName: string;
};

export const toLocaleSchema = () =>
  new schema.Entity(
    'locales',
    {},
    {
      idAttribute: 'locale',
    },
  );
