import type { Toggles } from './models';

export const mergeEnabledFeatures = (state: Toggles, enabledKeys: string[]): Toggles =>
  enabledKeys.reduce((toggles: Toggles, key: string) => {
    if (toggles.hasOwnProperty(key)) {
      return { ...toggles, [key]: { value: true, originalValue: true } };
    } else {
      console.warn(`${key} is not listed and will not be used.`);
      return toggles;
    }
  }, state);
