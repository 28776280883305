import type { FieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { isEmpty } from '@peloton/helpers/isEmpty';
import { isNil } from '@peloton/helpers/isNil';
import { defaultTransition } from '@peloton/styles';
import { gray4, paleYellow } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label16Caps } from '@engage/typography';
import { Cta, useCta, useAuth, Auth } from '@members/copy';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import type { LabelProps } from './label';

// prettier-ignore
const DatePicker = lazyLoader(() => import('./DatePicker' /* webpackChunkName: "DatePickerLazy" */));

const isPresent = (r: any) => !isNil(r);

export const toInputProps = (
  hideFieldErrors: boolean,
  form: {
    errors: Record<string, string | undefined>;
    touched: Record<string, boolean | undefined>;
  },
  field: { value: string; name: string },
  label: string | undefined,
  disabled: boolean,
) => {
  const isError =
    !hideFieldErrors &&
    isPresent(form.errors[field.name]) &&
    Boolean(form.touched[field.name]);
  let labelText: string | undefined;

  if (disabled) {
    labelText = field.value;
  } else {
    labelText = isError ? form.errors[field.name] : label;
  }

  return {
    isEmptyField: isEmpty(field.value) || isNil(field.value),
    isError,
    labelText,
  };
};

const useOverriddenType = (type: string | undefined) => {
  const [isRevealed, setReveal] = React.useState(false);
  const overriddenType = (() => {
    if (type === 'password' && isRevealed) {
      return 'text';
    } else {
      return type;
    }
  })();
  const toggleReveal = React.useCallback(() => {
    setReveal(!isRevealed);
  }, [isRevealed]);

  return { overriddenType, toggleReveal, isRevealed };
};

const InputWithLabelView: React.FC<React.PropsWithChildren<Props>> = ({
  field,
  disabled = false,
  form,
  type,
  label,
  Input,
  Label,
  ClearButton,
  TogglePasswordButton,
  hideFieldErrors = false,
  noValidate = false,
  transformText = true,
  Helptext,
  helptextContent = '',
  datePlaceholder = '',
  handleKeyDown,
}) => {
  const { isError, isEmptyField, labelText } = toInputProps(
    hideFieldErrors,
    form,
    field,
    label,
    disabled,
  );
  const showErrorBorder = hideFieldErrors && !noValidate && !isNil(form.status);
  const clearStatus = () => form.setStatus();

  const clearLabel = useCta(Cta.InputClear);
  const { overriddenType, toggleReveal, isRevealed } = useOverriddenType(type);
  const passwordButtonText = useAuth(Auth.HidePassword, {
    hidePassword: !isRevealed,
  });
  const showHelpText = helptextContent.length > 0;

  const [inputFocused, setInputFocused] = React.useState(false);
  const dateMode = datePlaceholder.length > 0;
  const showDatePlaceholder = dateMode && (inputFocused || field.value.length > 0);
  const [showDayPicker, setShowDayPicker] = React.useState(false);

  const handleDayClick = (day: Date) => {
    form.setFieldValue(
      field.name,
      `${day.getMonth() <= 8 ? '0' : ''}${day.getMonth() + 1}/${
        day.getDate() <= 9 ? '0' : ''
      }${day.getDate()}/${day.getFullYear()}`,
    );
    setShowDayPicker(false);
  };

  return (
    <>
      <Container showErrorBorder={showErrorBorder} showHelpText={showHelpText}>
        {showDatePlaceholder ? (
          <DatePlaceholder>
            <InvisiblePlaceholder>{field.value}</InvisiblePlaceholder>
            <VisiblePlaceholder>
              {datePlaceholder.substring(field.value.length)}
            </VisiblePlaceholder>
          </DatePlaceholder>
        ) : undefined}
        <Input
          {...field}
          onChange={e => {
            if (hideFieldErrors && !isPresent(form.errors[field.name])) {
              clearStatus();
            }
            field.onChange(e);
          }}
          value={disabled ? '' : field.value}
          error={isError}
          isEmptyField={isEmptyField}
          disabled={disabled}
          type={overriddenType}
          data-test-id={field.name}
          id={field.name}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
          style={showHelpText ? { borderRadius: '6px 6px 0 0' } : {}}
          onFocus={() => {
            setInputFocused(true);
            if (dateMode) {
              setShowDayPicker(true);
            }
          }}
          onClick={() => {
            if (dateMode) {
              setShowDayPicker(true);
            }
          }}
          onBlur={() => setInputFocused(false)}
          onKeyDown={handleKeyDown}
        />
        <Label
          error={isError}
          isEmptyField={isEmptyField}
          disabled={disabled}
          htmlFor={field.name}
          transformText={transformText}
        >
          <div>{labelText}</div>
        </Label>
        {type === 'password' ? (
          <TogglePasswordButton
            data-test-id="showHidePassword"
            type="button"
            onClick={toggleReveal}
          >
            {passwordButtonText}
          </TogglePasswordButton>
        ) : null}
        {type !== 'password' && !isEmptyField && !disabled ? (
          <ClearButton
            aria-label={clearLabel}
            onClick={() => form.setFieldValue(field.name, '')}
          />
        ) : null}
      </Container>
      {showHelpText && Helptext && <Helptext>{helptextContent}</Helptext>}
      <DayPickerContainer showDayPicker={dateMode && showDayPicker}>
        <DatePicker
          onChange={(date: Date) => handleDayClick(date)}
          onClickOutside={() => setShowDayPicker(false)}
        />
      </DayPickerContainer>
    </>
  );
};

type InputProps = React.HTMLProps<HTMLInputElement> &
  FieldProps<Record<string, string | number>>;

type InputComponentProps = React.ComponentProps<'input'> & {
  error: boolean;
  isEmptyField: boolean;
};

type ComponentProps = {
  Input: React.ComponentType<React.PropsWithChildren<InputComponentProps>>;
  Label: React.ComponentType<React.PropsWithChildren<LabelProps>>;
  TogglePasswordButton: React.ComponentType<
    React.PropsWithChildren<React.ComponentProps<'button'>>
  >;
  ClearButton: React.ComponentType<
    React.PropsWithChildren<React.ComponentProps<'button'>>
  >;
};

type FeatureProps = {
  hideFieldErrors?: boolean;
  transformText?: boolean;
  datePlaceholder?: string;
  handleKeyDown?: (e: any) => void;
};

type HelpTextProps = {
  Helptext?: React.ComponentType<React.PropsWithChildren<unknown>>;
  helptextContent?: string;
};

export type Props = InputProps & ComponentProps & FeatureProps & HelpTextProps;

const Container = styled.div<{ showErrorBorder: boolean; showHelpText: boolean }>`
  ${defaultTransition('border', 400)}
  height: ${spaces.xxHuge}px;
  ${({ showErrorBorder, showHelpText }) =>
    showErrorBorder &&
    `
    border: solid 1px ${paleYellow};
    border-radius: 6px ${showHelpText ? ' 6px 0 0' : ''};
  `}
  position: relative;
  overflow: hidden;
`;

const DatePlaceholder = styled.div`
  ${label16Caps}
  font-weight: 400;
  position: absolute;
  left: 15px;
  top: 22px;
  content: attr(data-placeholder);
  pointer-events: none;
`;

const InvisiblePlaceholder = styled.span`
  display: inline-block;
  color: rgba(0, 0, 0, 0);
`;

const VisiblePlaceholder = styled.span`
  display: inline-block;
  color: ${gray4};
`;

const DayPickerContainer = styled.div<{ showDayPicker: boolean }>`
  ${({ showDayPicker }) => (showDayPicker ? 'display: block;' : 'display: none;')}
  position: absolute;
  background-color: white;
  z-index: 2;
`;

export default InputWithLabelView;
