import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { getEntity } from '@peloton/redux-fetch';
import { isDefined } from '@peloton/types';
import type { Segment, WorkoutPerformance } from '@engage/workouts';
import { workoutPerformanceNamespace } from '@engage/workouts';

const toDividerPercents = (segments: Segment[]) =>
  segments.slice(1).map(segment => segment.startPercent);

const toNoMetricsPercents = (segments: Segment[]) =>
  segments
    .filter((segment: Segment) => !segment.isMetrics)
    .map(({ startPercent, lengthPercent }) => ({ startPercent, lengthPercent }));

const toDividerSeconds = (segments: Segment[]) =>
  segments.slice(1).map(segment => segment.startSeconds);

const toNoMetricsSeconds = (segments: Segment[]) =>
  segments
    .filter((segment: Segment) => !segment.isMetrics)
    .map(({ startSeconds, lengthSeconds, endSeconds }) => ({
      startSeconds,
      lengthSeconds,
      endSeconds,
    }));

export const toSegmentPercentsAndSeconds = (segments: Segment[]) => ({
  dividerPercents: toDividerPercents(segments),
  noMetricsPercents: toNoMetricsPercents(segments),
  dividerSeconds: toDividerSeconds(segments),
  noMetricsSeconds: toNoMetricsSeconds(segments),
});

export const getSegmentPercentsAndSeconds = (
  state: FetcherSelectorState,
  workoutId: string,
) => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return isDefined(workout) ? toSegmentPercentsAndSeconds(workout.segments) : undefined;
};

export const toSegmentedTimelineProps = (workout: WorkoutPerformance) => {
  const segments = workout.segments;
  return {
    lineSegments: segments.map(({ isMetrics, startPercent, endPercent, isDrill }) => ({
      isMetrics,
      startPercent,
      endPercent,
      isDrill,
    })),
    icons: segments.map(({ iconUrl, name, startPercent }) => ({
      iconUrl,
      name,
      startPercent,
    })),
    dividerPercents: toDividerPercents(segments),
    duration: workout.duration,
    showSegments: segments.length > 1,
  };
};

export const getSegmentedTimelineProps = (
  state: FetcherSelectorState,
  workoutId: string,
) => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return isDefined(workout) ? toSegmentedTimelineProps(workout) : undefined;
};

// Page saga actions

export type WorkoutDetailsPageAction<
  T extends WorkoutDetailsPageActionType = WorkoutDetailsPageActionType
> = {
  type: T;
  payload: { id: string };
};

export enum WorkoutDetailsPageActionType {
  Load = 'pelo/page/workoutDetails/load',
}
