import { toBaseProperties } from '@members/analytics';

export enum Auth {
  LoggedIn = 'Logged In',
  LoggedOut = 'Logged Out',
}

export const trackTldLanguageModalViewed = (loggedIn: boolean, userId?: string) => ({
  event: 'Viewed TLD Language Modal',
  ...toBaseProperties(),
  loggedIn,
  userId,
});

export const trackTldLanguageModalExpanded = (loggedIn: boolean, userId?: string) => ({
  event: 'Expanded TLD Language Modal',
  ...toBaseProperties(),
  loggedIn,
  userId,
});
