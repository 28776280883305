import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { toMembersList } from '@engage/members';

const MAX_RESULTS = 40;

const toSearchUrl = (usernamePrefix: string) =>
  `api/user/search?user_query=${usernamePrefix}&limit=${MAX_RESULTS}`;

export const fetchSearchResults = (api: Client, usernamePrefix: string) =>
  api.get(toSearchUrl(usernamePrefix)).then(pipeData(toMembersList));
