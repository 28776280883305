import React from 'react';
import type { InjectedIntl as InjectedIntlType } from 'react-intl';
import { injectIntl } from 'react-intl';

export type InjectedIntl = InjectedIntlType;

export const IntlContext = React.createContext<InjectedIntl>({} as InjectedIntl);

const IntlProvider: React.FC<{ children?: React.ReactNode; intl: InjectedIntl }> = ({
  children,
  intl,
}) => <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;

export const InjectIntlContext = injectIntl(IntlProvider);
