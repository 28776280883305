import type {
  ChangeReducerState,
  SimpleProps,
  State,
} from '../BasicInfo/BasicInfoForm/concerns';
import type { MeasurementPreferences } from '../models/BasicInfo';
import type BasicInfo from '../models/BasicInfo';

enum BasicInfoActionType {
  BasicInfoRequest = 'pelo/preferences/basic-info/REQUEST',
  BasicInfoUpdate = 'pelo/preferences/basic-info/UPDATE',
}

export type BasicInfoRequestAction = {
  type: BasicInfoActionType.BasicInfoRequest;
  presenter: BasicInfoPresenter;
  url: string;
};

export type BasicInfoUpdateAction = ReturnType<typeof updateBasicInfo>;

const loadBasicInfo = (presenter: BasicInfoPresenter, url: string) => ({
  type: BasicInfoActionType.BasicInfoRequest,
  presenter,
  url,
});

const updateBasicInfo = (
  presenter: UpdatedBasicInfoPresenter,
  info: BasicInfo,
  changes: ChangeReducerState['changedProps'],
  usernameChanged: boolean,
  emailChanged: boolean,
) =>
  ({
    type: BasicInfoActionType.BasicInfoUpdate,
    presenter,
    info,
    changes,
    usernameChanged,
    emailChanged,
  } as const);

export type BasicInfoPresenter = {
  displayBasicInfo(info: BasicInfo & MeasurementPreferences): void;
  displayCompleteProfileMessage(): void;
  redirectToLoginPage(): void;
};

export type UpdatedBasicInfoPresenter = {
  addApiError(prop: keyof State, apiError: string): void;
  addErrors(prop: keyof SimpleProps, errors: string[]): void;
  redirectToLoginPage(): void;
  displaySuccessMessage(): void;
  displayErrorMessage(): void;
  displayUsernamePolicyViolationMessage(): void;
  displayLocationPolicyViolationMessage(): void;
};

export { BasicInfoActionType, loadBasicInfo, updateBasicInfo };
