/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveClass } from '../../../api-v2-models/generated/ActiveClass';
import type { ArchivedRidesResponse } from '../../../api-v2-models/generated/ArchivedRidesResponse';
import type { ContentLocaleEnum } from '../../../api-v2-models/generated/ContentLocaleEnum';
import type { FitnessDisciplineEnum } from '../../../api-v2-models/generated/FitnessDisciplineEnum';
import type { PlaylistMusicProvider } from '../../../api-v2-models/generated/PlaylistMusicProvider';
import type { Ride } from '../../../api-v2-models/generated/Ride';
import type { RideWithUserInfo } from '../../../api-v2-models/generated/RideWithUserInfo';
import type { ScheduledPelotonsResponse } from '../../../api-v2-models/generated/ScheduledPelotonsResponse';
import type { Workout } from '../../../api-v2-models/generated/Workout';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Get Ride Instagram Share Image
 * Get image from ride for Instagram share
 * @param rideId ID for ride
 * @param shareType
 * @returns any Successful response. Returns Ride Instagram Image
 * @throws ApiError
 */
export const rideInstagramShare = (
    api: BaseAPI,
    rideId: string,
    shareType: 'story',
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/ride/${rideId}/instagram_image`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof shareType !== 'undefined') {
        localVarQueryParameter['share_type'] = shareType;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * On Demand Classes
 * Returns a list of on-demand classes (rides)
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param classTypeId Return classes of this specific class type. This will replace ride_type_id
 * @param contentProvider Return classes of certain content_provider, for peloton classes, the content_provider is peloton, for scenic_ride, the content_provider is virtual_active
 * @param contentFormat Specify the content format of the class to return (video, audio). If not provided, will default to video.
 * @param duration Return classes that match this specific run time (seconds)
 * @param fitnessDiscipline Return the classes with certain fitness discipline. If multiple are provided, then return all rides with any of those fitness disciplines. If not provided, the default value is all fitness disciplines available on this platform, as set by the Peloton-Platform header
 * @param browseCategory Return the classes with certain browse category. Only one
 * browse category can be specified at a time.
 * @param hasWorkout If set to True, return the classes that the currently logged-in user has workout
 * @param instructorId Return classes taught by this specific instructor
 * @param isFavoriteRide If set to True, return the classes that the currently logged-in user bookmarked
 * @param isFollowingUserRide Return classes that have been taken by users that the currently logged-in user follows
 * @param isSessions Return classes that are sessions eligible
 * @param language Return the classes with certain language
 * @param rideTypeId Return classes of this specific ride type
 * @param superGenreId Return classes of this specific super_genre
 * @param limit Number of items to retrieve in a single page, can be used with page number
 * @param page Page number to retrieve, used with limit for pagination
 * @param hasCyclingArms Returns classes with cycling_arms(Attributes->Weights) tag if value is True, classes without that tag for False and all classes for None value.
 * @param sortBy Return classes (rides) sorted by a certain criteria, original_air_time refers to the air time of live classes, for classes which are only offered as on-demand classes), it refers to video recorded time. top_rated refers to ride's overall_rating_avg, difficulty refers to ride's difficulty_rating_avg, trending refers to ride's current in-progress workouts.
 * @param desc Return classes in descending/ascending order, used with sort_by
 * @param hasClosedCaptions Return classes that have captions available.
 * If a user's class preferences are to display all classes for a given locale, it will return any classes that have captions regardless of caption language.
 * If a user has also defined a caption language preference, only classes with captions in that language are returned.
 * If there is no Logged in user then return class that has any caption available.
 * We do not support filtering classes without captions.
 * @param captionLocales Return classes that has one of the caption available from this list
 * @param classLanguages Return classes that has one of the class language from this list
 * @param ignoreClassLanguagePreferences Ignore user class language preferences in returning classes. Intended to be used to handle empty state (when no classes with those particular class language preferences are found)
 * @param hasFreeMode If not specified, include all results. If true, only include classes with has_free_mode. If false, exclude all classes with has_free_mode.
 * @returns ArchivedRidesResponse An archived class response. Data is a list of rides. Extended data is instructors, ride types.
 * @throws ApiError
 */
export const getOnDemandClasses = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    classTypeId?: string,
    contentProvider?: 'peloton' | 'virtual_active',
    contentFormat?: string,
    duration?: 300 | 600 | 900 | 1200 | 1800 | 2700 | 3600 | 4500 | 5400 | 7200,
    fitnessDiscipline?: Array<FitnessDisciplineEnum>,
    browseCategory?: string,
    hasWorkout?: boolean,
    instructorId?: string,
    isFavoriteRide?: boolean,
    isFollowingUserRide?: boolean,
    isSessions?: boolean,
    language?: 'english' | 'spanish',
    rideTypeId?: string,
    superGenreId?: string,
    limit?: number,
    page?: number,
    hasCyclingArms?: boolean,
    sortBy?: 'original_air_time' | 'popularity' | 'top_rated' | 'difficulty' | 'trending',
    desc?: boolean,
    hasClosedCaptions?: boolean,
    captionLocales?: Array<ContentLocaleEnum>,
    classLanguages?: Array<ContentLocaleEnum>,
    ignoreClassLanguagePreferences?: boolean,
    hasFreeMode?: boolean,
    options: any = {},
): AxiosPromise<ArchivedRidesResponse> => {
    const localVarPath = `/api/v2/ride/archived`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof classTypeId !== 'undefined') {
        localVarQueryParameter['class_type_id'] = classTypeId;
    }
    if(typeof contentProvider !== 'undefined') {
        localVarQueryParameter['content_provider'] = contentProvider;
    }
    if(typeof contentFormat !== 'undefined') {
        localVarQueryParameter['content_format'] = contentFormat;
    }
    if(typeof duration !== 'undefined') {
        localVarQueryParameter['duration'] = duration;
    }
    if(typeof fitnessDiscipline !== 'undefined') {
        localVarQueryParameter['fitness_discipline'] = fitnessDiscipline;
    }
    if(typeof browseCategory !== 'undefined') {
        localVarQueryParameter['browse_category'] = browseCategory;
    }
    if(typeof hasWorkout !== 'undefined') {
        localVarQueryParameter['has_workout'] = hasWorkout;
    }
    if(typeof instructorId !== 'undefined') {
        localVarQueryParameter['instructor_id'] = instructorId;
    }
    if(typeof isFavoriteRide !== 'undefined') {
        localVarQueryParameter['is_favorite_ride'] = isFavoriteRide;
    }
    if(typeof isFollowingUserRide !== 'undefined') {
        localVarQueryParameter['is_following_user_ride'] = isFollowingUserRide;
    }
    if(typeof isSessions !== 'undefined') {
        localVarQueryParameter['is_sessions'] = isSessions;
    }
    if(typeof language !== 'undefined') {
        localVarQueryParameter['language'] = language;
    }
    if(typeof rideTypeId !== 'undefined') {
        localVarQueryParameter['ride_type_id'] = rideTypeId;
    }
    if(typeof superGenreId !== 'undefined') {
        localVarQueryParameter['super_genre_id'] = superGenreId;
    }
    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }
    if(typeof hasCyclingArms !== 'undefined') {
        localVarQueryParameter['has_cycling_arms'] = hasCyclingArms;
    }
    if(typeof sortBy !== 'undefined') {
        localVarQueryParameter['sort_by'] = sortBy;
    }
    if(typeof desc !== 'undefined') {
        localVarQueryParameter['desc'] = desc;
    }
    if(typeof hasClosedCaptions !== 'undefined') {
        localVarQueryParameter['has_closed_captions'] = hasClosedCaptions;
    }
    if(typeof captionLocales !== 'undefined') {
        localVarQueryParameter['caption_locales'] = captionLocales;
    }
    if(typeof classLanguages !== 'undefined') {
        localVarQueryParameter['class_languages'] = classLanguages;
    }
    if(typeof ignoreClassLanguagePreferences !== 'undefined') {
        localVarQueryParameter['ignore_class_language_preferences'] = ignoreClassLanguagePreferences;
    }
    if(typeof hasFreeMode !== 'undefined') {
        localVarQueryParameter['has_free_mode'] = hasFreeMode;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Scheduled Classes
 * Returns a list of scheduled classes (pelotons)
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param start Specify the start epoch time of search for the scheduled classes
 * @param end Specify the end epoch time of search for the scheduled classes
 * @param instructorId Return classes taught by this specific instructor
 * @param duration Return classes that match this specific run time (seconds)
 * @param location Return the classes for certain location. Both nyc and chicago will have scheduled classes, and will be shown in cms page, however, chicago production team has limited permission in cms, and chicago classes will never become on-demand classes
 * @param rideTypeId Return classes of this specific ride type
 * @param classTypeId Return classes of this specific class type. This will replace ride_type_id
 * @param limit Number of items to retrieve
 * @param excludeComplete Filter out already complete classes (pelotons with complete status)
 * @param isStudio Return a list of studio pelotons if true, return a list of home pelotons if false
 * @param scheduleType Specify the type of scheduled classes
 * @param fitnessDisciplines Filter the classes by fitness discipline. If multiple are provided, then return all classes with any of those fitness disciplines. If not provided, the default value is all fitness disciplines available on this platform, as set by the Peloton-Platform header. If the header isn't set, this has the value of the fitness_discipline parameter
 * @param fitnessDiscipline Filter the classes by fitness discipline. Used only if the fitness_disciplines query param and Peloton-Platform header are not set
 * @param browseCategory Return the classes with certain browse category. Only one
 * browse category can be specified at a time.
 * @param excludeLiveInStudioOnly The parameter is ignored.
 * In other words, this endpoint always filters out live in studio only classes, unless the user is a demo user, in which case those classes are _not_ filtered out by default.
 * Some rides are marked as live in studio only; these classes should not be shown shown to home riders.
 * @param forceExcludeLiveInStudioOnly The parameter is honored for demo users only.
 * Filter out the live in studio only classes even for demo users is set to "true".
 * Some rides are marked as live in studio only; these classes should not be shown shown to home riders under normal circumstances. We make an exception for demo users to allow testing private rides with live audience but we have some use-cases  (e.g., screen app) where a demo user doesn't want to see those rides. This param allows overwriting the default behavior for demo users.
 * @param ignoreClassLanguagePreferences Ignore user class language preferences in returning classes. Intended to be used to handle empty state (when no classes with those particular class language preferences are found)
 * @param hasFreeMode If not specified, include all results. If true, only include classes with has_free_mode. If false, exclude all classes with has_free_mode.
 * @param classLanguages Return classes that has one of the class language from this list
 * @returns ScheduledPelotonsResponse A scheduled classes response. Data is a list of pelotons. Extended data is instructors, rides and ride types.
 * @throws ApiError
 */
export const getScheduledClasses = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    start?: number,
    end?: number,
    instructorId?: string,
    duration?: 300 | 600 | 900 | 1200 | 1800 | 2700 | 3600 | 4500 | 5400 | 7200,
    location?: 'nyc' | 'chicago' | 'uk' | 'nyc-gw-tread',
    rideTypeId?: string,
    classTypeId?: string,
    limit?: number,
    excludeComplete?: boolean,
    isStudio?: boolean,
    scheduleType?: 'all' | 'live' | 'encore',
    fitnessDisciplines?: Array<FitnessDisciplineEnum>,
    fitnessDiscipline?: FitnessDisciplineEnum,
    browseCategory?: string,
    excludeLiveInStudioOnly?: boolean,
    forceExcludeLiveInStudioOnly?: boolean,
    ignoreClassLanguagePreferences?: boolean,
    hasFreeMode?: boolean,
    classLanguages?: Array<ContentLocaleEnum>,
    options: any = {},
): AxiosPromise<ScheduledPelotonsResponse> => {
    const localVarPath = `/api/v3/ride/live`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof start !== 'undefined') {
        localVarQueryParameter['start'] = start;
    }
    if(typeof end !== 'undefined') {
        localVarQueryParameter['end'] = end;
    }
    if(typeof instructorId !== 'undefined') {
        localVarQueryParameter['instructor_id'] = instructorId;
    }
    if(typeof duration !== 'undefined') {
        localVarQueryParameter['duration'] = duration;
    }
    if(typeof location !== 'undefined') {
        localVarQueryParameter['location'] = location;
    }
    if(typeof rideTypeId !== 'undefined') {
        localVarQueryParameter['ride_type_id'] = rideTypeId;
    }
    if(typeof classTypeId !== 'undefined') {
        localVarQueryParameter['class_type_id'] = classTypeId;
    }
    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof excludeComplete !== 'undefined') {
        localVarQueryParameter['exclude_complete'] = excludeComplete;
    }
    if(typeof isStudio !== 'undefined') {
        localVarQueryParameter['is_studio'] = isStudio;
    }
    if(typeof scheduleType !== 'undefined') {
        localVarQueryParameter['schedule_type'] = scheduleType;
    }
    if(typeof fitnessDisciplines !== 'undefined') {
        localVarQueryParameter['fitness_disciplines'] = fitnessDisciplines;
    }
    if(typeof fitnessDiscipline !== 'undefined') {
        localVarQueryParameter['fitness_discipline'] = fitnessDiscipline;
    }
    if(typeof browseCategory !== 'undefined') {
        localVarQueryParameter['browse_category'] = browseCategory;
    }
    if(typeof excludeLiveInStudioOnly !== 'undefined') {
        localVarQueryParameter['exclude_live_in_studio_only'] = excludeLiveInStudioOnly;
    }
    if(typeof forceExcludeLiveInStudioOnly !== 'undefined') {
        localVarQueryParameter['force_exclude_live_in_studio_only'] = forceExcludeLiveInStudioOnly;
    }
    if(typeof ignoreClassLanguagePreferences !== 'undefined') {
        localVarQueryParameter['ignore_class_language_preferences'] = ignoreClassLanguagePreferences;
    }
    if(typeof hasFreeMode !== 'undefined') {
        localVarQueryParameter['has_free_mode'] = hasFreeMode;
    }
    if(typeof classLanguages !== 'undefined') {
        localVarQueryParameter['class_languages'] = classLanguages;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get ride by id
 * Get ride by id. It also attaches additional properties to the ride, such as whether the logged in user bookmarked this ride, the number of times the user took this ride workout, and any equipment tags for this ride.
 * @param rideId Identifier for ride
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns RideWithUserInfo Successful response
 * @throws ApiError
 */
export const getRide = (
    api: BaseAPI,
    rideId: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<RideWithUserInfo> => {
    const localVarPath = `/api/ride/${rideId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get active class data for an on-demand, live, or encore ride specified by joinToken.
 * For a specified class, get its ride data (such as title, fitness discipline, instructor etc.), its schedule type (whether it is live, encore, or on demand), as well as its scheduled start time if it is a live or encore class. For studio platform, the returned peloton_id for a live class will be the studio_peloton_id. For all other platforms, it will be the home_peloton_id.
 * @param joinToken identifier for active class
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @returns ActiveClass Successful response
 * @throws ApiError
 */
export const getActiveClass = (
    api: BaseAPI,
    joinToken: string,
    acceptLanguage?: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    options: any = {},
): AxiosPromise<ActiveClass> => {
    const localVarPath = `/api/active_class/${joinToken}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get recent ride workouts for users that logged in user is following
 * Get recent workouts for the specified ride for users that logged in user is following
 * @param rideId Identifier for ride
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getRecentFollowingWorkouts = (
    api: BaseAPI,
    rideId: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * a list of workouts
     */
    workouts?: Array<Workout>,
}> => {
    const localVarPath = `/api/ride/${rideId}/recent_following_workouts`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets active class data for a batch of on-demand, live, or encore rides identified by joinTokens.
 * For all specified classes, get their ride data (such as title, fitness discipline, instructor etc.), their schedule type (whether it is live, encore, or on demand), as well as their scheduled start time if it is a live or encore class. For studio platform, the returned peloton_id for a live class will be the studio_peloton_id. For all other platforms, it will be the home_peloton_id. Limited to 100 joinTokens at a time.
 * @param joinTokens comma-separated list of active class joinTokens to get
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @returns ActiveClass Successful response
 * @throws ApiError
 */
export const getActiveClassBatch = (
    api: BaseAPI,
    joinTokens: string,
    acceptLanguage?: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    options: any = {},
): AxiosPromise<ActiveClass> => {
    const localVarPath = `/api/active_classes`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof joinTokens !== 'undefined') {
        localVarQueryParameter['join_tokens'] = joinTokens;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets music provider data for a batch of classes identified by joinTokens.
 * Return a list of music providers available for each of a list of classes. Currently, the only possible music provider is a playlist, as returned in the "playlist_ids" field.
 * @param joinTokens comma-separated list of active class joinTokens to get music providers for
 * @param pelotonTotpToken An 8-digit TOTP (time-based HMAC one-time password) using SHA1. Please contact the API team for
 * the shared secret.
 *
 * @returns PlaylistMusicProvider Successful response
 * @throws ApiError
 */
export const getClassMusicProviders = (
    api: BaseAPI,
    joinTokens: string,
    pelotonTotpToken: string,
    options: any = {},
): AxiosPromise<Record<string, PlaylistMusicProvider>> => {
    const localVarPath = `/api/internal/rides/music_providers`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof joinTokens !== 'undefined') {
        localVarQueryParameter['join_tokens'] = joinTokens;
    }

    localVarHeaderParameter['Peloton-TOTP-Token'] = String(pelotonTotpToken);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get all sample rides
 * Get all valid sample rides. All sample rides must have stream url. Sample rides are rides whose video content is available to all users, that can be shown as demo to non-logged in users. Return only cycling ride for home_bike, running and circuit ride for home_tread and cyling, running and circuit for all other platform in ascending order based on schedule_start_time.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param limit Returns a limited number of sample ride up based on scheduled time if the limit specified. If not, data will be returned based on some default limit.
 * @returns any Successful response
 * @throws ApiError
 */
export const getSampleRides = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    acceptLanguage?: string,
    limit?: number,
    options: any = {},
): AxiosPromise<{
    /**
     * a list of sample rides
     */
    rides?: Array<Ride>,
}> => {
    const localVarPath = `/api/ride/samples`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get minimal public ride data by id without authentication
 * Get ride by id. The only data returned is the ride title, description and image URL
 * @param rideId Identifier for ride
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getPublicRide = (
    api: BaseAPI,
    rideId: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * ride title
     */
    title?: string,
    /**
     * ride description
     */
    description?: string,
    /**
     * ride image URL
     */
    imageUrl?: string,
}> => {
    const localVarPath = `/api/public/ride/${rideId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

