import { isDefined } from '@peloton/types';
import type { MemberSelectorState, ContractAgreement } from '@engage/members';
import { getMember, ME } from '@engage/members';
import type { SubscriptionState, Subscription } from '@engage/subscriptions';
import { isDigitalSub } from '@engage/subscriptions';
import { FEATURE_TOGGLE_NAMESPACE } from '@members/feature-toggles/redux';
import type { FeatureToggleState } from '@members/feature-toggles/redux';
import { ContractType } from './acceptTermsEnums';

export const byContractType = (contract: ContractAgreement) =>
  Object.values(ContractType).indexOf(contract.contractType as ContractType);

export const getUnacceptedContracts = (
  state: MemberSelectorState & SubscriptionState & FeatureToggleState,
) => {
  const unacceptedContracts = getFilteredContracts(state, Object.values(ContractType));

  return unacceptedContracts.sort(byContractType);
};

export const getUnacknowledgedContracts = (
  state: MemberSelectorState & FeatureToggleState,
) => {
  const contractAgreements = getContractAgreements(state);
  const privacyPolicyAcknowledgementEnabled =
    state[FEATURE_TOGGLE_NAMESPACE]?.toggles?.privacyPolicyAcknowledgement?.value ??
    false;

  if (!privacyPolicyAcknowledgementEnabled) {
    return [];
  }

  return contractAgreements.filter(
    contract => contract.requiredMemberAction === 'acknowledge',
  );
};

export const isUnaccepted = (
  contract: Pick<ContractAgreement, 'requiredMemberAction' | 'agreedAt'>,
  isPrivacyPolicyAcknowledgementEnabled: boolean = false,
) => {
  if (!isPrivacyPolicyAcknowledgementEnabled) {
    return !isDefined(contract.agreedAt) || contract.agreedAt === 0;
  }

  switch (contract.requiredMemberAction) {
    case 'acceptance':
    case 'acknowledge_and_acceptance':
      return true;
    case 'acknowledge':
    case 'none':
    default:
      return false;
  }
};

export const isUnacceptedForDigitalSub = (
  contract: ContractAgreement,
  primarySub: Subscription | undefined,
  requireConsentAfterUnixTimestamp: number | null,
  state: MemberSelectorState & SubscriptionState,
) => {
  const unacceptedFromApi = isUnaccepted(
    contract,
    state[FEATURE_TOGGLE_NAMESPACE]?.toggles?.privacyPolicyAcknowledgement?.value ??
      false,
  );
  if (unacceptedFromApi) {
    return true;
  }

  return (
    isDefined(primarySub) &&
    isDigitalSub(primarySub) &&
    isDefined(contract.agreedAt) &&
    requireConsentAfterUnixTimestamp !== null &&
    contract.agreedAt < requireConsentAfterUnixTimestamp &&
    contract.contractType === ContractType.MembershipTerms
  );
};

const getFilteredContracts = (
  state: MemberSelectorState & SubscriptionState & FeatureToggleState,
  contractTypes: string[],
) => {
  const contractAgreements = getContractAgreements(state);
  return contractAgreements.filter(
    contract =>
      isUnaccepted(
        contract,
        state[FEATURE_TOGGLE_NAMESPACE]?.toggles?.privacyPolicyAcknowledgement?.value ??
          false,
      ) && contractTypes.includes(contract.contractType as ContractType),
  );
};

const getContractAgreements = (state: MemberSelectorState) =>
  getMember(state, ME)?.contractAgreements ?? [];
