import type { FieldProps } from 'formik';
import React from 'react';
import { shouldUpdate, shallowEqual } from 'recompose';
import styled from 'styled-components';
import { ApiEnvs } from '@peloton/external-links';
import { gray3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { header5Small, label7Large } from '@engage/typography';
import { getFullStorageKey, StorageKeys } from '@members/env';
import { LocalizedSelect as Select } from '@members/form';

export const CUSTOM_OPTION = 'Enter Custom Hostname';

type Props = FieldProps<Record<string, string | number>>;

export const ApiEnvironmentToggleView: React.FC<React.PropsWithChildren<Props>> = ({
  form,
  field: { name, value },
}) => (
  <MultirowItem key={name}>
    <Row>
      <Text htmlFor="API Environment">
        <b>API Environment</b>
        <br />
        <Subtext>
          (param: {StorageKeys.Api}; localStorage: {getFullStorageKey(StorageKeys.Api)})
        </Subtext>
      </Text>
      <ValueSelect
        id={name}
        data-test-id={name}
        label="Choose API Environment"
        options={[...Object.values(ApiEnvs), CUSTOM_OPTION]}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const newValue = e.target.value;
          form.setFieldValue(name, newValue);
        }}
        isDefault={value === ApiEnvs.Prod}
        renderer={v => v}
      />
    </Row>
  </MultirowItem>
);

const enhance = shouldUpdate<Props>(
  (props, nextProps) =>
    !shallowEqual(props.form.values.apiEnv, nextProps.form.values.apiEnv),
);

export const ApiEnvironmentToggle = enhance(ApiEnvironmentToggleView);

const MultirowItem = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 ${spaces.small}px;
`;

const Row = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.label`
  ${header5Small}
  pointer-events: none;
`;

const ValueSelect = styled(Select)`
  flex: 1;
  margin-left: ${spaces.large}px;

  select {
    ${props =>
      props.isDefault
        ? `
    color: ${gray3};
    color: rgba(0, 0, 0, 0);
    text-shadow: 0 0 0 ${gray3};
 `
        : `
    option[value=""] {
      display: none;
    }
  `}
  }
`;

const Subtext = styled.span`
  ${label7Large}
  pointer-events: auto;
`;
