/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoggedInUserMapping } from '../../../api-v2-models/generated/LoggedInUserMapping';
import type { PubSubSession } from '../../../api-v2-models/generated/PubSubSession';
import type { RemoteLogin } from '../../../api-v2-models/generated/RemoteLogin';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Check user code for availabilty
 * Checks the availability of a user code for remote login
 * @param userCode User code for the remote login
 * @returns any Successful response
 * @throws ApiError
 */
export const checkRemoteLogin = (
    api: BaseAPI,
    userCode: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/auth/remote_login`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof userCode !== 'undefined') {
        localVarQueryParameter['user_code'] = userCode;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Remote login with device code
 * Remote login with device code
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const remoteLogin = (
    api: BaseAPI,
    requestBody?: {
        /**
         * Device code associated with authorized remote login
         */
        deviceCode?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * Session id for the newly created login
     */
    sessionId?: string,
    /**
     * User id of the user who the session was created for
     */
    userId?: string,
}> => {
    const localVarPath = `/auth/remote_login`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * User Authentication endpoint for Auth vendor
 * Validates the user by username or email, password used for external  Auth service. Return user id, and user information if authentication is valid. Segment analytics Login Event will be triggered by this endpoint
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const credentialsValidate = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    requestBody?: {
        /**
         * Username or user's email address for the exist user.
         */
        usernameOrEmail?: string,
        /**
         * Current password for the exist user
         */
        password?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * user id
     */
    userId?: string,
    userData?: LoggedInUserMapping,
}> => {
    const localVarPath = `/auth/credentials/validate`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Answers an email verification challenge.
 * Answers an email verification challenge. The user corresponding to the email address being verified does not need to be authenticated.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const answerEmailVerificationChallenge = (
  api: BaseAPI,
  requestBody: {
    /**
     * The email address being verified.
     */
    email?: string;
    /**
     * The verification code that was sent in the challenge email.
     */
    verification?: string;
  },
  options: any = {},
): AxiosPromise<{
  /**
   * Sending the email verification challenge was successful.
   */
  success?: boolean;
}> => {
  const localVarPath = `/auth/email/answer_verification_challenge`;
  const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
  let baseOptions;
  if (api.configuration) {
    baseOptions = api.configuration.baseOptions;
  }
  const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;

  localVarUrlObj.query = {
    ...localVarUrlObj.query,
    ...localVarQueryParameter,
    ...options.query,
  };
  // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
  delete (localVarUrlObj as any).search;
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

  localVarRequestOptions.data = requestBody;

  const localVarAxiosArgs = {
    url: globalImportUrl.format(localVarUrlObj),
    options: localVarRequestOptions,
  };

  const axios = api.axios;

  const axiosRequestArgs = { ...localVarAxiosArgs.options, url: localVarAxiosArgs.url };
  return axios.request(axiosRequestArgs);
};

/**
 * User Authentication endpoint
 * Authenticate the user by username or email, password. Return session id, user id, pubnub session, and authenticated user information. Authenticated user information includes birthday, email, heart rate zones, subscriptions, etc.) Aurora content will be hidden unless aurora platform is specified in header. Segment analytics Login Event will be triggered by this endpoint
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const login = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    requestBody?: {
        /**
         * Username or user's email address for the exist user.
         */
        usernameOrEmail: string,
        /**
         * Current password for the exist user
         */
        password: string,
        /**
         * id(UUID) of a device
         */
        deviceId?: string,
        /**
         * PEM-encoded device certificate chain containing device serial and device type
         */
        deviceCert?: string,
        /**
         * JWT token with device serial and expiration time signed w. RS256 alg using device cert's private key and encoded in JWS Compact Serialization format. This will be used to validate that the sender has access to the private key in addition to the public device cert.
         */
        deviceToken?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * user id
     */
    userId?: string,
    /**
     * session id
     */
    sessionId?: string,
    /**
     * pubnub session, contains channels, and auth token, which will be {} when pubnub service is unavailable.
     */
    pubsubSession?: {
        channels?: Array<string>,
        authToken?: string,
    },
}> => {
    const localVarPath = `/auth/login`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Set forgotten password utilizing reset hash
 * Set forgotten password utilizing reset hash
 * @param resetHashId Identifier for reset hash id
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const forgotPasswordResetHashId = (
    api: BaseAPI,
    resetHashId: string,
    requestBody: {
        /**
         * new plain text password
         */
        password?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * was password reset successfully
     */
    success?: boolean,
}> => {
    const localVarPath = `/auth/forgot_password/${resetHashId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Sends an email verification challenge.
 * Sends an email verification challenge to the logged in user's email address.
 * @returns any Successful response
 * @throws ApiError
 */
export const sendEmailVerificationChallenge = (
  api: BaseAPI,
  options: any = {},
): AxiosPromise<{
  /**
   * Sending the email verification challenge was successful.
   */
  success?: boolean;
}> => {
  const localVarPath = `/auth/email/send_verification_challenge`;
  const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
  let baseOptions;
  if (api.configuration) {
    baseOptions = api.configuration.baseOptions;
  }
  const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;

  localVarUrlObj.query = {
    ...localVarUrlObj.query,
    ...localVarQueryParameter,
    ...options.query,
  };
  // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
  delete (localVarUrlObj as any).search;
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

  const localVarAxiosArgs = {
    url: globalImportUrl.format(localVarUrlObj),
    options: localVarRequestOptions,
  };

  const axios = api.axios;

  const axiosRequestArgs = { ...localVarAxiosArgs.options, url: localVarAxiosArgs.url };
  return axios.request(axiosRequestArgs);
};

/**
 * Check session status
 * Returns properties of the current session. If the current session is invalid or unauthenticated, the method does not throw an error. Instead it just returns with most values of the payload to Null
 * @returns any Successful response
 * @throws ApiError
 */
export const checkSession = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * session id
     */
    sessionId?: string,
    /**
     * is session valid
     */
    isValid?: boolean,
    /**
     * is session authenticated
     */
    isAuthed?: boolean,
    /**
     * session authentication expiration
     */
    authTtl?: number,
    user?: LoggedInUserMapping,
    /**
     * Access level
     */
    accessLevel?: string,
    /**
     * Always set to Null right now
     */
    referralCode?: string,
    /**
     * http header 'REMOTE_ADDR'
     */
    clientIpAddress?: string,
    /**
     * http header 'Accept-Language'
     */
    clientLanguage?: string,
}> => {
    const localVarPath = `/auth/check_session`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * cancel a remote login via user_code or device_code
 * Expire a remote login via user_code or device_code
 * @param requestBody
 * @returns any No content
 * @throws ApiError
 */
export const remoteLoginCancel = (
    api: BaseAPI,
    requestBody?: {
        /**
         * User code of remote login to expire
         */
        userCode?: string,
        /**
         * User code of remote login to expire
         */
        deviceCode?: string,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/auth/remote_login/cancel`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Authorizes a remote login
 * Authorize remote login
 * @param requestBody
 * @returns any Successfuly authozired remote login
 * @throws ApiError
 */
export const authorizeRemoteLogin = (
    api: BaseAPI,
    requestBody?: {
        /**
         * User code associated with remote login to authorize
         */
        userCode?: string,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/auth/remote_login/authorize`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Creates a new remote login
 * Create remote login
 * @param requestBody
 * @returns RemoteLogin Successful create response
 * @throws ApiError
 */
export const createRemoteLogin = (
    api: BaseAPI,
    requestBody?: {
        /**
         * id(UUID) of a device
         */
        deviceId?: string,
        /**
         * PEM-encoded device certificate chain containing device serial and device type
         */
        deviceCert?: string,
        /**
         * JWT token with device serial and expiration time signed w. RS256 alg using device cert's private key and encoded in JWS Compact Serialization format. This will be used to validate that the sender has access to the private key in addition to the public device cert.
         */
        deviceToken?: string,
        /**
         * Country code, e.g. US,GB,CA,DE,AU.  Will use current session locale if country_code not provided. For non-supported locales, defaults to US.
         */
        countryCode?: string,
        /**
         * String to determine verification_uri in response. Defaults to device_activation
         */
        action?: 'device_activation' | 'add_account',
    },
    options: any = {},
): AxiosPromise<RemoteLogin> => {
    const localVarPath = `/auth/remote_login/code`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Logout current user
 * Logout the user if a valid session exists otherwise return an error.
 * The method will return the session_id of the destroyed session.
 * @returns any Successful response
 * @throws ApiError
 */
export const logoutPost = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * session id
     */
    sessionId?: string,
}> => {
    const localVarPath = `/auth/logout`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Forgot password
 * forgot password
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const forgotPassword = (
    api: BaseAPI,
    requestBody: {
        /**
         * User's email address
         */
        email?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * the user's email address
     */
    email?: string,
    /**
     * the user's email address obfuscated
     */
    obfuscatedEmail?: string,
}> => {
    const localVarPath = `/auth/forgot_password`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * checks validity of reset hash
 * Check validity of reset hash by id.
 * @param resetHashId Identifier for reset hash id
 * @returns any Successful response
 * @throws ApiError
 */
export const validateResetHash = (
    api: BaseAPI,
    resetHashId: string,
    options: any = {},
): AxiosPromise<{
    valid?: boolean,
}> => {
    const localVarPath = `/auth/validate_reset_hash/${resetHashId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Returns a session for a Publish/Subscriber service object
 * Returns a session for a Publish/Subscriber service object.
 * Currently the service provider is PubNub (https://www.pubnub.com/)
 * @returns PubSubSession a Publish/Subscriber session
 * @throws ApiError
 */
export const pubSubSession = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<PubSubSession> => {
    const localVarPath = `/auth/pubsub_session`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Change password
 * Change password
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const changePassword = (
    api: BaseAPI,
    requestBody: {
        /**
         * Current password
         */
        oldPassword?: string,
        /**
         * Current password
         */
        newPassword?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * was password change successful
     */
    success?: boolean,
}> => {
    const localVarPath = `/auth/change_password`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Update user_code_state
 * Updates the state of a user code for remote login
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const updateUserCodeState = (
    api: BaseAPI,
    requestBody?: {
        /**
         * User code associated with remote login to authorize
         */
        userCode?: string,
        /**
         * The target state of this user code
         */
        state?: string,
        /**
         * The action that this remote login is intended for
         */
        action?: string,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/auth/remote_login/user_code/state`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Check user_code_state
 * Check what is the user_code_state of a remote login with device code
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const checkUserCodeState = (
    api: BaseAPI,
    requestBody?: {
        /**
         * Device code associated with authorized remote login
         */
        deviceCode?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * state of the user_code
     */
    userCodeState?: string,
}> => {
    const localVarPath = `/auth/remote_login/user_code/state`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Check session status for use with the GraphQL Gateway authorization process
 * Almost identical to /auth/check_session, but avoids overfetching user data
 * @returns any Successful response
 * @throws ApiError
 */
export const getSession = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * session id
     */
    sessionId?: string,
    /**
     * is session valid
     */
    isValid?: boolean,
    /**
     * is session authenticated
     */
    isAuthed?: boolean,
    /**
     * session authentication expiration
     */
    authTtl?: number,
    user?: {
        id?: string,
    },
    /**
     * Access level
     */
    accessLevel?: string,
    /**
     * Always set to Null right now
     */
    referralCode?: string,
    /**
     * http header 'REMOTE_ADDR'
     */
    clientIpAddress?: string,
    /**
     * http header 'Accept-Language'
     */
    clientLanguage?: string,
    /**
     * A replacement session ID, in the case where
     * a mismatched session cookie and auth token
     * were used simultaneously.
     */
    newSessionId?: string,
}> => {
    const localVarPath = `/auth/session`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * exchange auth_token for a legacy session
 * return a legacy session id if auth_token present in request otherwise, return 401 Unauthorized
 * @returns any Successful response
 * @throws ApiError
 */
export const postSession = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * session id
     */
    sessionId?: string,
}> => {
    const localVarPath = `/auth/session`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Reactivate account via reset hash
 * Reactivate account via reset hash
 * @param resetHashId Identifier for reset hash id
 * @returns any Successful response
 * @throws ApiError
 */
export const reactivateAccountResetHashId = (
    api: BaseAPI,
    resetHashId: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/auth/reactivate_account/${resetHashId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

