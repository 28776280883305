/* eslint-disable react/jsx-props-no-spreading */
import { transparentize } from 'polished';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';
import type { PropsOf } from '@peloton/react';
import { usePrevious } from '@peloton/react';
import { hoverTransition, media } from '@peloton/styles';
import { slate3, white, gray5 } from '@engage/colors';
import { interV } from '@engage/typography';
import { useCta, Cta } from '@members/copy';
import CloseIcon from './CloseIcon';
import { modalHeight } from './containers';

const MODAL_TRANSITION_LENGTH = 400;
export const MODAL_TABLET_MARGIN = 55;

const ModalGlobalStyles = createGlobalStyle`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Html--open #root {
    /* iOS only */
    @supports (-webkit-overflow-scrolling: touch) {
      overflow: hidden;
    }
  }

  .ReactModalPortal > * {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay {
    transition: opacity ${MODAL_TRANSITION_LENGTH}ms;
    /* iOS only */
    @supports (-webkit-overflow-scrolling: touch) {
      /* Reset transforms to fix an issue where modal content was hidden */
      -webkit-transform: translateZ(0px);
      -webkit-transform: translate3d(0,0,0);
      -webkit-perspective: 1000;
      -webkit-overflow-scrolling: touch;
    }
    overscroll-behavior: contain;
    scroll-behavior: smooth;
    pointer-events: all;
  }

  .ReactModalPortal .ReactModal__Content--after-open {
    opacity: 1;
    /* Rounded corners were not appearing on retina devices. For some insane reason, this works... */
    /* https://stackoverflow.com/questions/5736503/how-to-make-css3-rounded-corners-hide-overflow-in-chrome-opera */
    ${media.tablet`
      z-index: 1;
    `}
  }

  .ReactModalPortal .ReactModal__Content--before-close {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
    /* Rounded corners were not appearing on retina devices. For some insane reason, this works... */
    /* https://stackoverflow.com/questions/5736503/how-to-make-css3-rounded-corners-hide-overflow-in-chrome-opera */
    ${media.tablet`
      z-index: 1;
    `}
  }

  .ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

ReactModal.defaultStyles.overlay = {
  ...ReactModal.defaultStyles.overlay,
  backgroundColor: `${transparentize(0.25, slate3)}`,
};

type ModalExtensions = {
  aria?: ReactModal.Aria & { modal: boolean };
  width?: string;
};

const ReactModalWithExtensions: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof ReactModal> & ModalExtensions>
> = props => <ReactModal {...props} />;

type DimensionProps = {
  style?: {
    width?: number;
    height?: number;
    top?: string;
    left?: string;
    margin?: string;
    maxWidth?: string;
  };
};

const StyledModal = styled(ReactModalWithExtensions)<DimensionProps>`
  outline: none;
  height: 100%;
  position: relative;
  width: ${(p: DimensionProps) => (p?.style?.width ? `${p.style.width}px` : '100%')};
  left: ${(p: DimensionProps) => (p?.style?.left ? p.style.left : 0)};
  height: ${(p: DimensionProps) => (p?.style?.height ? `${p.style.height}px` : '100%')};
  top: ${(p: DimensionProps) => (p?.style?.top ? p.style.top : 0)};
  ${media.tablet<DimensionProps>`
        max-width: ${(p: DimensionProps) =>
          p?.style?.maxWidth ? p.style.maxWidth : '460px'};
    height: ${(p: DimensionProps) => (p?.style?.height ? `${p.style.height}px` : 'auto')};
    margin: ${(p: DimensionProps) =>
      p?.style?.margin ? p.style.margin : `${MODAL_TABLET_MARGIN}px auto`};
  `};
`;

type ContentContainerProps = {
  style?: {
    backgroundColor?: string;
    borderRadius?: number;
    maxHeight?: string;
  };
};

const ModalContentContainer: any = styled.div<ContentContainerProps>`
  -ms-overflow-style: none;
  overflow: auto;
  font-family: ${interV};
  height: 100%;
  position: relative;
  overflow-x: hidden;

  .no-scroll & {
    overflow: hidden;
  }

  ${media.tablet`
    border-radius: 10px;
    z-index: 0;
    background-color: transparent;
    max-height: ${(p: ContentContainerProps) =>
      p?.style?.maxHeight ? `${p.style.maxHeight}px` : modalHeight};
  `}
`;

export const StyledCloseIcon = styled(CloseIcon)`
  height: 16px;
  width: 16px;

  ${media.tablet`
    height: 20px;
    width: 20px;
  `}
`;

type CloseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  showMobileCloseButton: boolean;
};

const UnstyledCloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ showMobileCloseButton, ...props }, ref) => (
    <button {...props} ref={ref}>
      <StyledCloseIcon />
    </button>
  ),
);
UnstyledCloseButton.displayName = 'UnstyledCloseButton';

export const CloseButton = styled(UnstyledCloseButton)`
  cursor: pointer;
  fill: ${gray5};
  position: fixed;
  right: 15px;
  top: 15px;
  opacity: 0.8;
  z-index: 1;
  display: ${props => (props.showMobileCloseButton ? 'block' : 'none')};

  ${hoverTransition({
    property: 'opacity',
    to: '1',
  })}

  ${media.tablet`
    align-items: center;
    display: flex;
    fill: ${white};
    height: 30px;
    justify-content: center;
    right: 25px;
    top: 25px;
    width: 30px;
    z-index: 1;
  `}
`;

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  isOpen = true,
  contentLabel,
  closeHandler,
  restoreScrollPositionOnClose = true,
  style,
  showMobileCloseButton = true,
  children,
  contentContainerStyle,
  onAfterOpen,
  className,
  closeButtonRef,
  hideCloseButton,
  modalStyle,
  overlayRef,
  parentSelector,
  shouldCloseOnOverlayClick = true,
  isError = false,
  banner,
}) => {
  const closeButtonCopy = useCta(Cta.ModalClose);
  const wasOpen = usePrevious<boolean>(isOpen);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);

  const handleAfterOpen = () => {
    const rootElement = document.getElementById('root')!;
    rootElement.scrollTop = scrollPosition;
    if (onAfterOpen) {
      onAfterOpen();
    }
  };

  useEffect(() => {
    if (restoreScrollPositionOnClose) {
      if (isOpen) {
        setScrollPosition(window.scrollY);
      } else if (wasOpen) {
        enableScroll();
        window.scrollTo(0, scrollPosition);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]); // Only evaluate when isOpen changes, but use value of wasOpen to determine if scroll position ought to be restored

  ReactModal.defaultStyles.overlay = {
    ...ReactModal.defaultStyles.overlay,
    backgroundColor: `${transparentize(0.25, slate3)}`,
    ...modalStyle,
  };

  return (
    <StyledModal
      aria={{ modal: true }}
      ariaHideApp={false}
      role="dialog"
      htmlOpenClassName="ReactModal__Html--open"
      closeTimeoutMS={MODAL_TRANSITION_LENGTH}
      contentLabel={contentLabel}
      isOpen={isOpen!}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldReturnFocusAfterClose={true}
      style={style}
      onAfterOpen={handleAfterOpen}
      onRequestClose={closeHandler}
      className={className}
      overlayRef={overlayRef}
      parentSelector={parentSelector}
    >
      <CloseHandlerContext.Provider
        value={{
          closeHandler,
        }}
      >
        <ModalGlobalStyles />
        {isError && banner}
        <ModalContentContainer style={contentContainerStyle}>
          {children}
        </ModalContentContainer>
        {hideCloseButton ? null : (
          <CloseButton
            aria-label={closeButtonCopy}
            data-test-id="closeModalButton"
            onClick={closeHandler}
            showMobileCloseButton={showMobileCloseButton}
            style={style && style.closeButton}
            {...(closeButtonRef ? { ref: closeButtonRef } : {})}
          />
        )}
      </CloseHandlerContext.Provider>
    </StyledModal>
  );
};

// Enable scroll early to pop in sticky headers
const enableScroll = () => {
  document.querySelector('body')!.classList.remove('ReactModal__Body--open');
  document.documentElement!.classList.remove('ReactModal__Html--open');
};

type CloseHandlerProps = {
  closeHandler?: () => void;
  restoreScrollPositionOnClose?: boolean;
};

const DEFAULT_CLOSE_HANDLER_VALUE: CloseHandlerProps = {};

const CloseHandlerContext = React.createContext<CloseHandlerProps>(
  DEFAULT_CLOSE_HANDLER_VALUE,
);

export const useCloseHandler = () => {
  const { closeHandler } = React.useContext(CloseHandlerContext);
  return closeHandler;
};

const ALLOWED_PATH_PREFIXES = [
  '/classes',
  '/home',
  '/schedule',
  '/programs',
  '/collections',
  '/challenges',
  '/profile',
  '/preferences',
  '/members',
  '/feed',
];

export const isPathAllowed = (pathname: string) =>
  ALLOWED_PATH_PREFIXES.some(
    (pathPrefix: string) =>
      pathname.indexOf(pathPrefix) === 0 && !/\/classes\/player/.test(pathname),
  );

export type ModalProps = CloseHandlerProps & {
  className?: string;
  closeButtonRef?: React.RefObject<HTMLButtonElement>;
  contentLabel: string;
  isOpen: boolean;
  style?: ReactModal.Styles &
    DimensionProps['style'] & {
      closeButton?: any;
    };
  contentContainerStyle?: React.CSSProperties & ContentContainerProps;
  showMobileCloseButton?: boolean;
  hideCloseButton?: boolean;
  onAfterOpen?(): void;
  modalStyle?: Record<string, any>;
  overlayRef?: PropsOf<ReactModal>['overlayRef'];
  parentSelector?: PropsOf<ReactModal>['parentSelector'];
  shouldCloseOnOverlayClick?: boolean;
  isError?: boolean;
  banner?: React.ReactNode;
};

export { Modal };
