enum AnalyticsEventName {
  ViewedCompleteProfileScreen = 'Viewed Complete Profile Screen',
  ViewedWelcomeScreen = 'Viewed Welcome Screen',
  ViewedCreatePasswordScreen = 'Viewed Create Password Screen',
}

export enum AnalyticsSource {
  CreatePassword = 'Create Password',
  CorporateWellness = 'Corporate Wellness',
  DirectLink = 'Direct Link',
  WelcomeScreen = 'Welcome Screen',
  AccountSetup = 'Account Setup',
  Unknown = '',
}

const trackViewWithExtras = (
  event: AnalyticsEventName,
  source: AnalyticsSource,
  extra: object = {},
) => ({
  event,
  source,
  ...extra,
});

export const trackViewedCompleteProfileScreen = (source: AnalyticsSource) =>
  trackViewWithExtras(AnalyticsEventName.ViewedCompleteProfileScreen, source);

export const trackViewedWelcomeScreen = (
  source: AnalyticsSource,
  hasUsername: boolean,
  hasPassword: boolean,
) =>
  trackViewWithExtras(AnalyticsEventName.ViewedWelcomeScreen, source, {
    hasUsername,
    hasPassword,
  });

export const trackViewedCreatePasswordScreen = () => ({
  event: AnalyticsEventName.ViewedCreatePasswordScreen,
});
