import type { SagaIterator } from 'redux-saga';
import {
  race,
  call,
  take,
  takeEvery,
  takeLatest,
  select,
  getContext,
  put,
  spawn,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getLocation } from '@peloton/redux';
import { track } from '@engage/analytics';
import type { BrowseCategory, BrowseCategorySlug } from '@engage/browse-categories';
import { updateClasses as addClassesToStore } from '@engage/classes';
import { logError } from '@engage/error-reporting/logError';
import type {
  FilterAction,
  SortAction,
  FilterOptions,
  AppliedFilter,
  SortValue,
} from '@engage/filters';
import {
  getAppliedFilters,
  AppliedActionTypes,
  initializeFilters,
  resetSort,
  SortActionTypes,
  getCurrentSort,
  getFilterAnalyticsProps,
  fetchFilterFailure,
  fetchFilterSuccess,
  fetchFilters,
} from '@engage/filters';
import { fetchAllFilters } from '@engage/filters/redux';
import { getClassLanguagePreferences } from '@engage/settings';
import {
  trackViewedLibraryPage,
  trackClearedFilters,
  trackFilteredLibrary,
  trackSortedLibrary,
  ViewedLibrarySource,
} from './analytics';
import type { LibraryData } from './api';
import { fetchLibraryClassesByCategorySlug, fetchBrowseCategories } from './api';
import type { LoadLibraryPageAction } from './redux';
import {
  addClassesFailure,
  loadBrowseCategories,
  loadBrowseCategoriesFailure,
  LibraryActionTypes,
  addClasses as addClassesToLibrary,
  loadingMoreClasses,
} from './redux';
import type { LoadingMoreClassesAction } from './redux/classes';
import {
  canFetchNextPage,
  getActiveCategorySlug,
  getNextPageToLoad,
  getBrowseCategoryAnalyticsProps,
} from './selectors';

export const loadLibraryBrowseCategoriesSaga = function* (client: Client) {
  try {
    const browseCategories: BrowseCategory[] = yield call(fetchBrowseCategories, client);
    yield put(loadBrowseCategories(browseCategories));
  } catch (e) {
    logError(e, 'loadLibraryBrowseCategoriesSaga');
    yield put(loadBrowseCategoriesFailure());
  }
};

// Watched sagas (not used by Members app)
export const loadLibraryPageSaga = function* (
  client: Client,
  action: LoadLibraryPageAction,
) {
  yield put(resetSort());
  yield put(initializeFilters());
  yield call(loadFiltersSaga, client);

  const appliedFilters: ReturnType<typeof getAppliedFilters> = yield select(
    getAppliedFilters,
  );
  const currentSort: ReturnType<typeof getCurrentSort> = yield select(getCurrentSort);
  yield call(
    sharedLoadLibraryPageSaga,
    client,
    action.payload.categorySlug,
    appliedFilters,
    currentSort,
  );
};

export const loadNextPageSaga = function* (
  client: Client,
  action: LoadingMoreClassesAction,
) {
  const appliedFilters: ReturnType<typeof getAppliedFilters> = yield select(
    getAppliedFilters,
  );
  const currentSort: ReturnType<typeof getCurrentSort> = yield select(getCurrentSort);

  yield call(sharedLoadNextPageSaga, client, appliedFilters, currentSort);
};

export function* applyFilters(client: Client, action: FilterAction | SortAction) {
  const appliedFilters: ReturnType<typeof getAppliedFilters> = yield select(
    getAppliedFilters,
  );
  const currentSort: ReturnType<typeof getCurrentSort> = yield select(getCurrentSort);
  yield call(loadClassesForLibrary, client, appliedFilters, currentSort);
  yield spawn(filterAnalytics, action);
}

export const filterAnalytics = function* (
  action: FilterAction | SortAction,
): SagaIterator {
  const filterAnalyticsProps = yield select(getFilterAnalyticsProps);
  const browseCategoryAnalyticsProps = yield select(getBrowseCategoryAnalyticsProps);
  const classLanguagePreferences = yield select(getClassLanguagePreferences);
  if (action.type === AppliedActionTypes.SendAnalyticsBeforeClearAll) {
    yield put(
      track(
        trackClearedFilters(
          {
            ...filterAnalyticsProps,
            ...browseCategoryAnalyticsProps,
          },
          action.payload.source,
        ),
      ),
    );
  } else if (action.type === SortActionTypes.Sort) {
    yield put(track(trackSortedLibrary(filterAnalyticsProps.sort)));
  }
  if (
    action.type === AppliedActionTypes.MultiSelectToggle ||
    action.type === AppliedActionTypes.Toggle
  ) {
    yield put(
      track(
        trackFilteredLibrary(
          {
            ...filterAnalyticsProps,
            ...browseCategoryAnalyticsProps,
          },
          classLanguagePreferences,
        ),
      ),
    );
  }
};

export function* loadClassesForLibrary(
  client: Client,
  appliedFilters: AppliedFilter[],
  currentSort: SortValue,
): SagaIterator {
  // TODO: this should all be in a race with a loadNewClassesActions
  const nextPage: ReturnType<typeof getNextPageToLoad> = yield select(getNextPageToLoad);
  try {
    if (typeof nextPage !== 'number') {
      throw new Error('must have a page to pass to api'); // This should not happen
    }
    yield put(loadingMoreClasses());
    const categorySlug: ReturnType<typeof getActiveCategorySlug> = yield select(
      getActiveCategorySlug,
    );

    const libraryData: LibraryData = yield call(
      fetchLibraryClassesByCategorySlug,
      client,
      categorySlug,
      appliedFilters,
      currentSort,
      nextPage,
    );
    yield put(
      addClassesToStore(libraryData.classes, {
        browseCategories: libraryData.browseCategories,
      }),
    );
    yield put(
      addClassesToLibrary(
        libraryData.classes,
        libraryData.nextPage,
        libraryData.totalClassesCount,
        libraryData.pageCount,
      ),
    );
  } catch (e) {
    logError(e, 'loadClassesForLibrary');
    yield put(addClassesFailure(nextPage));
  }
}

export const loadFiltersSaga = function* (api: Client): SagaIterator {
  try {
    yield put(fetchAllFilters());
    const activeCategory = yield select(getActiveCategorySlug);
    const filterOptions: FilterOptions = yield call(fetchFilters, api, activeCategory);
    yield put(fetchFilterSuccess(filterOptions));
  } catch (e) {
    logError(e, 'loadFiltersSaga');
    yield put(fetchFilterFailure(e));
  }
};

// Shared implementations
export const sharedLoadLibraryPageSaga = function* (
  client: Client,
  categorySlug: BrowseCategorySlug,
  appliedFilters: AppliedFilter[],
  currentSort: SortValue,
): SagaIterator {
  const classLanguagePreferences = yield select(getClassLanguagePreferences);
  const { state } = yield select(getLocation);
  const source = state?.source ?? ViewedLibrarySource.TopNav;
  yield call(loadClassesForLibrary, client, appliedFilters, currentSort);
  yield put(
    track(trackViewedLibraryPage(categorySlug, classLanguagePreferences, source)),
  );
};

export const sharedLoadNextPageSaga = function* (
  client: Client,
  appliedFilters: AppliedFilter[],
  currentSort: SortValue,
): SagaIterator {
  const canFetch = yield select(canFetchNextPage);
  // Do not fetch next page if already loading (or loading for the first time) or if no next page
  if (!canFetch) {
    return;
  }

  yield race({
    task: call(loadClassesForLibrary, client, appliedFilters, currentSort),
    // cancel RequestMoreClasses if the user has begun loading a different set of classes
    cancel: take([LibraryActionTypes.RequestNewClasses, ...filterActions]),
  });
};

const filterActions = [
  SortActionTypes.Sort,
  AppliedActionTypes.ClearAll,
  AppliedActionTypes.Toggle,
  AppliedActionTypes.Override,
];

export default function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeLatest(LibraryActionTypes.RequestNewClasses, loadLibraryPageSaga, client);
  yield takeEvery(LibraryActionTypes.RequestMoreClasses, loadNextPageSaga, client);
  yield takeLatest(filterActions, applyFilters, client);
}
