import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeEvery, select } from 'redux-saga/effects';
import { loadAuthUserFailure, loadAuthUserSuccess, toUser } from '@peloton/auth';
import type { ExtLinkEnv } from '@peloton/external-links';
import { EXT_LINK_ENV_CONTEXT } from '@peloton/external-links';
import { getLocation } from '@peloton/redux';
import { toMe, updateMe } from '@members/me';
import { CALLBACK } from '@members/pg-auth/urls';
import { persistAttemptedRoute } from '@members/pg-profile-setup/persistAttemptedRoute';
import {
  BENEFITS_WELCOME_PATH,
  PASSWORD_PATH,
  USERNAME_PATH,
  WELCOME_PATH,
} from '@members/pg-profile-setup/urls';
import {
  REMOTE_ACTIVATION_ROUTE,
  ADD_ACCOUNT_ROUTE,
  REMOTE_ACTIVATION_PROFILE_ROUTE,
  REMOTE_ACTIVATION_CHECKOUT_ROUTE,
} from '@members/pg-remote-activation/urls';
import type { loadUserRequestSuccess } from '../redux';
import { loadUserSuccess, UserMapperActionType } from '../redux';

// TODO: Move to routing package
export const redirect = (url: string) => window.location.assign(url);

let userDidSucceed: boolean = false;

const isAllowedPages = (location: Location) =>
  location.pathname === WELCOME_PATH ||
  location.pathname === PASSWORD_PATH ||
  location.pathname === USERNAME_PATH ||
  location.pathname === BENEFITS_WELCOME_PATH ||
  location.pathname === REMOTE_ACTIVATION_ROUTE ||
  location.pathname === ADD_ACCOUNT_ROUTE ||
  location.pathname === REMOTE_ACTIVATION_PROFILE_ROUTE ||
  location.pathname === REMOTE_ACTIVATION_CHECKOUT_ROUTE ||
  location.pathname === CALLBACK;

export const userMapperSaga = function* (
  extLinkEnv: ExtLinkEnv,
  action: ReturnType<typeof loadUserRequestSuccess>,
): SagaIterator {
  try {
    const user = action.payload;
    const location = yield select(getLocation);
    // TODO: this logic should be owned by auth, and should be page-specific, but we are updating on-boarding flow soon
    if (!user.username && !isAllowedPages(location)) {
      persistAttemptedRoute();
      yield call(redirect, WELCOME_PATH);
    } else {
      // Individual mapped user types
      yield put(loadAuthUserSuccess(toUser(user)));
      yield put(updateMe(toMe(user)));
      // Mapping complete
      if (!userDidSucceed) {
        // this was running on every page change causing excessive segment analytics logging
        userDidSucceed = true;
        yield put(loadUserSuccess());
      }
    }
  } catch (e) {
    yield put(loadAuthUserFailure());
  }
};

export default function* (): SagaIterator {
  const extLinkEnv = yield getContext(EXT_LINK_ENV_CONTEXT);
  yield takeEvery(UserMapperActionType.RequestSuccess, userMapperSaga, extLinkEnv);
}
