/* eslint-disable */
import * as Types from '../../../types.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PrimaryTagQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type PrimaryTagQuery = { __typename: 'Query', user?: { __typename: 'User', userTags: { __typename: 'UserTags', primary?: { __typename: 'Tag', name: string } | undefined, allTags: Array<{ __typename: 'UserTag', isPrimary: boolean }> } } | undefined };


export const PrimaryTagDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PrimaryTag"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userTags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"primary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"allTags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isPrimary"}}]}}]}}]}}]}}]} as unknown as DocumentNode<PrimaryTagQuery, PrimaryTagQueryVariables>;