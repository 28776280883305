import { css } from 'styled-components';
import {
  divider2,
  disabledInputGray,
  gray4,
  offBlack as activeColor,
  gray1,
  superRed as errorColor,
  white,
} from '@engage/colors';
import { body14, body12, fontWeights } from '@engage/typography';

const inputBorder = `1px solid ${divider2}`;

const base = css`
  ${body14}
  font-weight: ${fontWeights.xxBold};
  background: ${white};
  border: ${inputBorder};
  border-radius: 2px;
  color: ${activeColor};
  height: 46px;
  padding: 0 12px;
  width: 100%;
`;

const active = css`
  border-color: ${activeColor};
`;

const placeholder = css`
  color: ${gray4};
`;

const disabled = css`
  background: ${gray1};
  border-color: ${divider2};
  color: ${gray4};
`;

const error = css`
  border-color: ${errorColor};
`;

export const inputStyles = {
  base,
  active,
  placeholder,
  disabled,
  error,
};

export const errorStyles = css`
  ${body12}
  font-weight: ${fontWeights.xxBold};
  background: ${errorColor};
  color: ${white};
  display: inline-block;
  padding: 4px 10px;
  margin-top: 5px;
`;

// The odd color and text-shadow combo is a little hack to keep firefox from
// rendering a little dotted outline around the select. The color of the outline
// is determined by the font color.
export const selectStyles = {
  ...inputStyles,
  base: css`
    ${base}
    appearance: none;

    &[data-value=''] {
      color: ${gray4};
      color: rgba(0, 0, 0, 0);
      text-shadow: 0 0 0 ${gray4};
    }

    option {
      color: ${activeColor};
    }

    color: ${activeColor};
    color: rgba(0, 0, 0, 0);
    text-shadow: 0 0 0 ${activeColor};

    &::-ms-expand {
      display: none;
    }
  `,
  error: css`
    ${error}

    &[data-user-focused="true"] + div {
      ${error}
    }

    &[data-user-focused='true'] + div > svg {
      fill: ${errorColor};
    }

    &[data-has-error='true'] + div {
      ${error}
    }

    &[data-has-error='true'] + div > svg {
      fill: ${errorColor};
    }
  `,
  active: css`
    ${active}

    &[data-user-focused="true"] + div {
      ${active}
    }

    &[data-user-focused='true'] + div > svg {
      fill: ${activeColor};
    }

    &::-ms-value {
      background: none;
      color: ${activeColor};
    }
  `,
  disabled: css`
    ${disabled}

    color: ${disabledInputGray};
    color: rgba(0, 0, 0, 0);
    text-shadow: 0 0 0 ${disabledInputGray};
    cursor: default;

    &::-ms-value {
      color: ${disabledInputGray};
    }
  `,
};

export const selectContainerStyles = css`
  cursor: pointer;
  position: relative;
`;

export const selectArrowStyles = css`
  align-items: center;
  bottom: 0;
  border-left: ${inputBorder};
  display: flex;
  height: 46px;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  background-color: ${white};
  border: 1px solid ${divider2};

  svg {
    fill: ${gray4};
  }
`;
