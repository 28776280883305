import React from 'react';

const Share: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> = props => (
  <svg width={13} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.794.789a.417.417 0 0 0-.589 0l-2.5 2.5a.417.417 0 1 0 .59.589l1.788-1.789v8.994a.417.417 0 0 0 .833 0V2.09l1.79 1.789a.417.417 0 0 0 .588-.59l-2.5-2.5ZM.667 6.5c0-.69.56-1.25 1.25-1.25H4a.417.417 0 0 1 0 .833H1.917A.417.417 0 0 0 1.5 6.5v9.583c0 .23.186.417.417.417h9.166c.23 0 .417-.187.417-.417V6.5a.417.417 0 0 0-.417-.417H9a.417.417 0 1 1 0-.833h2.083c.69 0 1.25.56 1.25 1.25v9.583c0 .69-.56 1.25-1.25 1.25H1.917c-.69 0-1.25-.56-1.25-1.25V6.5Z"
      fill="#fff"
    />
  </svg>
);

export default Share;
