import React from 'react';

const Notifications: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7.5C4 3.875 6.72 1 10 1s6 2.875 6 6.5v2.497a4.5 4.5 0 0 0 1.113 2.963L18.898 15H1.102l1.785-2.04A4.5 4.5 0 0 0 4 9.997V7.5ZM10 0C6.1 0 3 3.393 3 7.5v2.497a3.5 3.5 0 0 1-.866 2.305L.35 14.342C-.216 14.988.243 16 1.102 16h17.796c.86 0 1.319-1.012.753-1.659l-1.785-2.04A3.5 3.5 0 0 1 17 9.998V7.5C17 3.393 13.9 0 10 0ZM7.995 17.432a.5.5 0 1 0-.99.136C7.198 18.976 8.5 20 10 20s2.802-1.024 2.995-2.432a.5.5 0 0 0-.99-.136C11.888 18.279 11.07 19 10 19c-1.07 0-1.888-.72-2.005-1.568Z"
      fill="#4C525E"
    />
  </svg>
);

export { Notifications };
