import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { slate3, divider1, white, backgroundGray, gray1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { header6Large, header5Large } from '@engage/typography';

export const Title = styled.h1`
  ${header6Large}
  color: ${slate3};
  margin: 50px auto ${spaces.xLarge}px auto;

  ${media.tablet`
    ${header5Large}
    margin: ${spaces.large}px auto;
  `}
`;

export const Divider = styled.div`
  display: none;

  ${media.tablet`
    border-bottom: 1px solid ${divider1};
    display: block;
    width: 100%;
  `}
`;

export const cardStyles = css`
  background-color: ${white};
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  margin: 0 10px;
`;

export const backgroundStyles = css`
  align-items: center;
  background-color: ${backgroundGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  ${media.tablet`
    background-color: ${gray1};
  `}
`;
