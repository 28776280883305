import { pluck } from 'ramda';
import type { Reducer } from 'redux';
import type { IsInStacks } from '@engage/class-detail';
import type { SearchSharedProperties } from '@engage/library';
import type { Member } from '@engage/members';
import type { PendingResult } from '@engage/result';
import { pending, ok } from '@engage/result';
import type { ClassDetail } from './models';

export const CLASS_DETAIL_NAMESPACE = 'classDetail';
type State = {
  detail: PendingResult<ClassDetail, undefined>;
  friends: PendingResult<string[], undefined>;
};

// Reducer
export const classDetailReducer: Reducer<State> = (
  state: State = { detail: pending, friends: pending },
  action: ClassDetailAction,
) => {
  switch (action.type) {
    case ClassDetailActionType.Open:
    case ClassDetailActionType.Close:
      return { detail: pending, friends: pending };
    case ClassDetailActionType.RequestSuccess:
      return { detail: ok(action.payload), friends: state.friends };
    case ClassDetailActionType.FriendsSuccess:
      return { detail: state.detail, friends: ok(action.payload.ids) };
    case ClassDetailActionType.OpenAnalytic:
    default:
      return state;
  }
};

// Action Creators

// read only props on Open and OpenAnalytic, payload only used by sagas
export const openClassDetail = (id: string) =>
  ({
    type: ClassDetailActionType.Open,
    payload: { id },
  } as const);

export const openClassDetailAnalytic = (
  id: string,
  isInStacks: IsInStacks,
  searchSharedProperties?: SearchSharedProperties,
) =>
  ({
    type: ClassDetailActionType.OpenAnalytic,
    payload: { id, isInStacks, searchSharedProperties },
  } as const);

export const closeClassDetail = () => ({
  type: ClassDetailActionType.Close as ClassDetailActionType.Close,
});

export const loadClassDetailSuccess = (detail: ClassDetail) => ({
  type: ClassDetailActionType.RequestSuccess as ClassDetailActionType.RequestSuccess,
  payload: detail,
});

export const loadFriendsSuccess = (members: Member[]) => ({
  type: ClassDetailActionType.FriendsSuccess as ClassDetailActionType.FriendsSuccess,
  payload: { ids: pluck('id')(members) },
});

type Action =
  | ReturnType<typeof openClassDetail>
  | ReturnType<typeof openClassDetailAnalytic>
  | ReturnType<typeof closeClassDetail>
  | ReturnType<typeof loadClassDetailSuccess>
  | ReturnType<typeof loadFriendsSuccess>;

export enum ClassDetailActionType {
  Open = 'pelo/members/class-details/OPEN',
  OpenAnalytic = 'pelo/members/class-details/OPEN_ANALYTIC',
  Close = 'pelo/members/class-details/CLOSE',
  RequestSuccess = 'pelo/members/class-details/REQUEST_SUCCESS',
  FriendsSuccess = 'pelo/members/class-details/FRIENDS_SUCCESS',
}

export type ClassDetailAction = Action;
