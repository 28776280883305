import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
export const HOMESCREEN_ROUTE = '/home';

const HomescreenPage = lazyLoader(
  () => import('./HomescreenPage' /* webpackChunkName: "HomescreenPage" */),
);
const Routes = [
  <Route
    key={HOMESCREEN_ROUTE}
    exact={true}
    path={HOMESCREEN_ROUTE}
    component={HomescreenPage}
  />,
];

export default Routes;
