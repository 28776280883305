import { throttle } from 'frame-throttle';
import { compose, curry, flip } from 'ramda';
import React from 'react';
import type { Breakpoint, ResponsiveSelectorState } from '@peloton/responsive';
import {
  getIsBreakpointAt,
  getIsBreakpointGreaterThan,
  getIsBreakpointLessThan,
  getIsTouch,
} from '@peloton/responsive';
import { useReduxState } from '@engage/redux';

const swapArgumentOrder = compose<typeof getIsBreakpointAt, any, BreakpointSelector>(
  flip,
  curry,
);

type BreakpointSelector = (
  breakpoint: Breakpoint,
) => (state: ResponsiveSelectorState) => boolean;
type BreakpointHook = (breakpoint: Breakpoint) => boolean;

export const useIsBreakpointAt: BreakpointHook = breakpoint => {
  const selector = swapArgumentOrder(getIsBreakpointAt);
  return useReduxState(selector(breakpoint));
};

export const useIsBreakpointGreaterThan: BreakpointHook = breakpoint => {
  const selector = swapArgumentOrder(getIsBreakpointGreaterThan);
  return useReduxState(selector(breakpoint));
};

export const useIsBreakpointLessThan: BreakpointHook = breakpoint => {
  const selector = swapArgumentOrder(getIsBreakpointLessThan);
  return useReduxState(selector(breakpoint));
};

export const useIsTouch = (): boolean => {
  return useReduxState(getIsTouch);
};

export const useWindowWidth = () => {
  const isClient = typeof window !== 'undefined';
  const lastWidth = React.useRef(isClient ? window.innerWidth : 0);

  function getSize() {
    return isClient ? window.innerWidth : 0;
  }

  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    const throttledHandleResize = throttle(function handleResize() {
      if (window.innerWidth !== lastWidth.current) {
        const size = getSize();
        lastWidth.current = size;
        setWindowSize(size);
      }
    });

    window.addEventListener('resize', throttledHandleResize);

    return () => {
      window.removeEventListener('resize', throttledHandleResize);
      throttledHandleResize.cancel();
    };
  }, []);

  return windowSize;
};
