import type { PerformanceGraphResponse, BaseAPI } from '../generated';
import { getPerformanceGraph } from '../generated';
import type { AxiosPromise } from '../generated/core/request';

export const getWorkoutPerformanceGraphWithHasAppleWatchMetrics = (
  api: BaseAPI,
  {
    workoutId,
    everyN,
    maxGapMeters,
    includeOutput,
    durationSummaryInSeconds,
    acceptLanguage,
    options = {},
  }: {
    workoutId: string;
    everyN?: number;
    maxGapMeters?: number;
    includeOutput?: boolean;
    durationSummaryInSeconds?: boolean;
    acceptLanguage?: string;
    options?: any;
  },
): AxiosPromise<
  PerformanceGraphResponse & {
    hasAppleWatchMetrics: boolean;
    caesarMetrics: { detail: { segments: any[]; score: number } };
  }
> =>
  getPerformanceGraph.apply(this, [
    api,
    workoutId,
    everyN,
    maxGapMeters,
    includeOutput,
    durationSummaryInSeconds,
    acceptLanguage,
    options,
  ]);
