import type { ApolloError } from 'apollo-client';
import { transparentize } from 'polished';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useSWR, { mutate } from 'swr';
import type { KeyedMutator } from 'swr';
import { getUser, isSignedIn } from '@peloton/auth';
import type { SignedInUser } from '@peloton/auth/models/user';
import { ieHideScroll, media } from '@peloton/styles';
import { isDefined } from '@peloton/types';
import { track } from '@engage/analytics';
import { white, gray1, slate2, slate3 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxState, useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { title24Bold, title20Bold } from '@engage/typography';
import {
  VideoActiveViewState,
  updateVideoActiveView as updateVideoActiveViewAction,
} from '@engage/video/redux';
import type { CopyIDs } from '@members/copy';
import { Stacks, StacksCopy, useModalLabel, ModalLabel } from '@members/copy';
import { StackedClassesFetchersGQL } from '@members/data-fetch';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import type { ModifyStackMutationVariables } from '@members/graphql-swr/schemas/stacked-classes/mutations/ModifyStack.generated';
import type { ViewUserStackQuery } from '@members/graphql-swr/schemas/stacked-classes/queries/ViewUserStack.generated';
import { useUserNav } from '@members/layout/nav/UserNav';
import type UserNavProps from '@members/layout/nav/UserNavProps';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { ConnectedModal, modalHeight, getActiveModalSource } from '@members/modal';
import { Source, trackViewedStackedClasses } from '../analytics';
import { useIsModifyStacksView, useIsTransitionScreen } from '../hooks';
import type { StackedOnDemandClass } from './ActiveState';
import { ActiveState } from './ActiveState';
import EmptyStateRouted from './EmptyState';
import ErrorState from './ErrorState';
import LoadingState from './LoadingState';
import SharedStack from './SharedStack';

const STACK_MODAL_NAME = 'stackFromNav';
const SHARED_STACK_MODAL_NAME = 'sharedStack';

type ViewProps = {
  setClassListOnClose: React.Dispatch<React.SetStateAction<StackedOnDemandClass[]>>;
  modifyView: boolean;
  setIsModifyStacksView: KeyedMutator<boolean>;
  data: ViewUserStackQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: KeyedMutator<ViewUserStackQuery>;
  onCloseModal: () => void;
};

export const StackModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useReduxState(getUser);
  return (
    <If condition={isSignedIn(user)}>
      <StackModalBody />
    </If>
  );
};
const ModifyStateLazy = lazyLoader(
  () => import('./ModifyState' /* webpackChunkName: "ModifyStateStacks" */),
);

export const StackModalBody: React.FC<React.PropsWithChildren<unknown>> = () => {
  const ariaLabelId = useModalLabel(ModalLabel.StackedClasses) as CopyIDs<
    typeof ModalLabel
  >;
  const sharedButtermilk = useFeatureToggle(Feature.Sharedbuttermilk);
  const { data: viewStackData, error, mutate: refetch } = useSWR(
    ...StackedClassesFetchersGQL.ViewUserStackQuery({}),
  );
  const loading = !viewStackData;

  const updateVideoActiveView = useReduxAction(updateVideoActiveViewAction);

  const saveCurrentStack = (variables: ModifyStackMutationVariables) =>
    mutate(...StackedClassesFetchersGQL.ModifyStackMutation(variables));

  const classListState =
    viewStackData?.viewUserStack?.__typename === 'StackResponseSuccess' &&
    viewStackData?.viewUserStack?.userStack?.stackedClassList
      ? viewStackData?.viewUserStack.userStack.stackedClassList
      : undefined;

  const classList = classListState
    ? (classListState.map(
        stackedClass => stackedClass.pelotonClass,
      ) as StackedOnDemandClass[])
    : ([] as StackedOnDemandClass[]);

  const [classListOnClose, setClassListOnClose] = React.useState(classList);

  React.useEffect(() => {
    setClassListOnClose(classList);
  }, [viewStackData]);

  const classesByJoinToken = (classListOnClose as any[]).map(
    eachClass => eachClass.joinToken,
  );

  const maybeUser = useReduxState(getUser);
  const user = isSignedIn(maybeUser) ? (maybeUser as SignedInUser) : undefined;
  const maybeUserNav = useUserNav(user?.id);
  const userNav = maybeUserNav ?? ({} as UserNavProps);
  const { isModifyStacksView, setIsModifyStacksView } = useIsModifyStacksView();
  const { isTransitionScreen, setIsTransitionScreen } = useIsTransitionScreen();

  const history = useHistory();

  const onCloseModal = () => {
    if (isModifyStacksView && classListOnClose.length !== 0) {
      saveCurrentStack({
        input: {
          pelotonClassIdList: classesByJoinToken,
        },
      }).then(() => refetch());
      updateVideoActiveView(VideoActiveViewState.Complete, true);
    }
    setIsTransitionScreen(false);
    setIsModifyStacksView(false);
    if (isTransitionScreen && classListOnClose.length === 0) {
      const location = {
        pathname: userNav?.workoutsPath,
        state: { source: Source.PostClass },
      };
      history.push(location);
      updateVideoActiveView(VideoActiveViewState.Complete, true);
    }
  };

  return (
    <StyledModal
      modalNames={{
        [STACK_MODAL_NAME]: {
          ariaLabelId,
          component: (
            <StackModalView
              setClassListOnClose={setClassListOnClose}
              modifyView={isModifyStacksView}
              setIsModifyStacksView={setIsModifyStacksView}
              data={viewStackData}
              loading={loading}
              error={error}
              refetch={refetch}
              onCloseModal={onCloseModal}
            />
          ),
          onCloseModal,
        },
        [SHARED_STACK_MODAL_NAME]: {
          ariaLabelId,
          component: sharedButtermilk ? <SharedStack data={viewStackData} /> : <></>,
          onCloseModal,
        },
      }}
    ></StyledModal>
  );
};

export const StackModalView: React.FC<React.PropsWithChildren<ViewProps>> = ({
  setClassListOnClose,
  modifyView,
  setIsModifyStacksView,
  data,
  loading,
  error,
  refetch,
}) => {
  const onFeed = window.location.pathname.includes('feed');
  const modalSource = useReduxState(getActiveModalSource) as Source;
  const analyticsSource = onFeed ? Source.QuickActions : modalSource;
  const trackAnalytics = useReduxAction(track);

  const numClasses = data?.viewUserStack?.numClasses ?? 0;
  const stackDuration = data?.viewUserStack?.totalTime
    ? data?.viewUserStack?.totalTime / 60
    : 0;

  const stackList =
    (data?.viewUserStack.__typename === 'StackResponseSuccess' &&
      data?.viewUserStack.userStack.stackedClassList) ??
    undefined;

  const pelotonClassList = stackList
    ? (stackList.reduce((acc, stackedClass) => {
        if (
          stackedClass.pelotonClass.__typename === 'OnDemandInstructorClass' ||
          stackedClass.pelotonClass.__typename === 'ScenicClass' ||
          stackedClass.pelotonClass.__typename === 'LanebreakClass'
        )
          acc.push(stackedClass.pelotonClass);
        return acc;
      }, [] as StackedOnDemandClass[]) as StackedOnDemandClass[])
    : [];

  const refetchClasses = async () => {
    await refetch();
    setIsModifyStacksView(false);
  };

  React.useEffect(() => {
    if (!loading) {
      trackAnalytics(trackViewedStackedClasses(pelotonClassList, analyticsSource));
    }
  }, [loading]);

  return (
    <Container>
      <HeaderBackground>
        <Header>
          <HeaderText data-test-id="headerText">
            <StacksCopy id={Stacks.StackedClasses} />
          </HeaderText>
        </Header>
      </HeaderBackground>
      <If condition={loading}>
        <LoadingState />
      </If>
      <If condition={isDefined(error)}>
        <ErrorState tryAgain={() => refetch()} />
      </If>
      <If condition={!loading && !isDefined(error)}>
        {numClasses > 0 ? (
          modifyView ? (
            <ModifyStateLazy
              stackedClasses={pelotonClassList}
              refetch={refetchClasses}
              setClassListOnClose={setClassListOnClose}
            />
          ) : (
            <ActiveState
              stackDuration={stackDuration}
              numberOfClasses={numClasses}
              stackedClasses={pelotonClassList}
              setIsModifyStacksView={setIsModifyStacksView}
              refetchUserStack={refetch}
            />
          )
        ) : (
          <EmptyStateRouted />
        )}
      </If>
    </Container>
  );
};

const StyledModal = styled(ConnectedModal)`
  box-shadow: 0 0 32px 0 rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  ${media.tablet`
    max-width: 560px;
  `}
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #17191c;
  overflow-y: hidden;
  overflow-x: auto;

  ${media.tablet`
    padding: 0;
    height: ${modalHeight};
    max-height: 700px;
  `}
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;

// Non-opaque background for on-scroll behavior
const HeaderBackground = styled.div`
  height: 64px;
  width: 100%;
  background-color: ${slate3};
  position: absolute;
  margin-top: 0;
  z-index: 1;

  ${media.tablet`
    height: 78px;
  `}
`;

const Header = styled.div`
  height: 64px;
  width: 100%;
  background-color: ${transparentize(0.75, slate2)};
  padding-left: 0px;
  padding-right: 0px;
  padding-top: ${spaces.large}px;
  padding-bottom: ${spaces.large}px;
  text-align: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;

  ${media.tablet`
    height: 78px;
    width: 560px;
    position: absolute;
    padding-left: ${spaces.small}px;
    padding-right: ${spaces.small}px;
    padding-top: ${spaces.xLarge}px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `}
`;

const HeaderText = styled.p`
  color: ${gray1};
  ${title20Bold}

  ${media.tablet`
    height: 30px;
    color: ${white};
    ${title24Bold}
  `}
`;
