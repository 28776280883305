import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';

export const FEED_ROUTE = '/feed';

const Routes = [
  <Route
    key={FEED_ROUTE}
    path={FEED_ROUTE}
    component={lazyLoader(() => import('./FeedPage' /* webpackChunkName: "FeedPage" */))}
  />,
];

export default Routes;
