import { call, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getUserId } from '@peloton/auth';
import { toLocale } from '@peloton/internationalize';
import { loadRequestSaga } from '@peloton/redux-fetch';
import { loadMetadata } from '@engage/metadata';
import type { UpdateDisplayLanguageAction } from '@engage/settings';

export default function* (
  client: Client,
  { payload: { displayLanguage } }: UpdateDisplayLanguageAction,
) {
  client.defaults.headers.common['Accept-Language'] = toLocale(displayLanguage);
  const userId: string | undefined = yield select(getUserId);
  if (userId) {
    const action = loadMetadata();
    yield call(loadRequestSaga, client, action);
  }
}
