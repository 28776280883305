// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Facebook = {
  CardCta: 'cardCta',
  CardDescription: 'cardDescription',
  CardDetails: 'cardDetails',
  CardHeading: 'cardHeading',
  Connect: 'connect',
  ConnectDetails: 'connectDetails',
  ConnectPrivacy: 'connectPrivacy',
  ErrorLoading: 'errorLoading',
  ErrorLoadingCta: 'errorLoadingCta',
  ErrorRetrieveFriends: 'errorRetrieveFriends',
  ErrorRetrieveFriendsCta: 'errorRetrieveFriendsCta',
  FbCardHeading: 'fbCardHeading',
  FriendCount: 'friendCount',
  Heading: 'heading',
  Label: 'label',
  NewCardDescription: 'newCardDescription',
  NoFriends: 'noFriends',
  NoFriendsFound: 'noFriendsFound',
  SearchButtonCta: 'searchButtonCta',
  ShareCta: 'shareCta',
} as const;
