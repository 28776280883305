import type { Time } from '@peloton/time';
import type { Class } from '@engage/classes';
import type { Instructor } from '@engage/metadata';
import type {
  EffortZonesData,
  DeviceType,
  Workout,
  ClassWorkout,
  FreestyleWorkout,
} from '@engage/workouts';
import { isClassWorkout } from '@engage/workouts';

type DeviceTypeProviding = {
  deviceType: DeviceType;
};

type InstructorProviding = {
  instructor: Instructor;
};

export type Displayable<T extends {}> = T &
  (T extends DeviceTypeProviding ? { displayable: DeviceTypeProviding } : {});

export type HydratedWorkout = HydratedClassWorkout | HydratedFreestyleWorkout;
export type DisplayableWorkout = Displayable<Workout>;
export type WorkoutWithClass = ClassWorkout & { class: Class & InstructorProviding };
type HydratedFreestyleWorkout = DisplayableWorkout & Workout & FreestyleWorkout;
type HydratedClassWorkout = DisplayableWorkout & WorkoutWithClass;

export type WorkoutHistoryItem = {
  classTitle: string;
  fitnessDiscipline: string;
  hasMetrics: boolean;
  imageUrl: string;
  id: string;
  instructorName: string;
  isScenic: boolean;
  output: number;
  deviceType: string;
  scheduledStartTime?: Time;
  deviceTimeCreatedAt: Time;
  userId: string;
  effortZones: EffortZonesData | null;
};

export const hasClass = (workout: HydratedWorkout): workout is HydratedClassWorkout =>
  !!isClassWorkout(workout);
