/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractAgreementUpdate } from '../../../api-v2-models/generated/ContractAgreementUpdate';
import type { GuestPassAccessCode } from '../../../api-v2-models/generated/GuestPassAccessCode';
import type { GuestPassSku } from '../../../api-v2-models/generated/GuestPassSku';
import type { LoggedInUserMapping } from '../../../api-v2-models/generated/LoggedInUserMapping';
import type { MembershipTieringInfo } from '../../../api-v2-models/generated/MembershipTieringInfo';
import type { MusicPlatformInfo } from '../../../api-v2-models/generated/MusicPlatformInfo';
import type { PaginationResponse } from '../../../api-v2-models/generated/PaginationResponse';
import type { QuickHits } from '../../../api-v2-models/generated/QuickHits';
import type { RemoteAddSharedUser } from '../../../api-v2-models/generated/RemoteAddSharedUser';
import type { RemoteDeviceActivation } from '../../../api-v2-models/generated/RemoteDeviceActivation';
import type { RemoteUserOnboardingEvent } from '../../../api-v2-models/generated/RemoteUserOnboardingEvent';
import type { Streaks } from '../../../api-v2-models/generated/Streaks';
import type { Subscription } from '../../../api-v2-models/generated/Subscription';
import type { TagsInfo } from '../../../api-v2-models/generated/TagsInfo';
import type { UserBasicMapping } from '../../../api-v2-models/generated/UserBasicMapping';
import type { UserCaesarHardWareSettings } from '../../../api-v2-models/generated/UserCaesarHardWareSettings';
import type { UserMapping } from '../../../api-v2-models/generated/UserMapping';
import type { UserRelationship } from '../../../api-v2-models/generated/UserRelationship';
import type { UserSettingsRequestBody } from '../../../api-v2-models/generated/UserSettingsRequestBody';
import type { UserSettingsResponse } from '../../../api-v2-models/generated/UserSettingsResponse';
import type { UserTreadHardWareSettings } from '../../../api-v2-models/generated/UserTreadHardWareSettings';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Gets a user's settings
 * Gets a user's settings. Can only call this for the logged in user. API validate class_language_preference values that client send before saving it to database. If user selects display all classes without any condition then value of display_all_classes field will be True and there will no captions field for that setting. If user selects classes with any caption condition then value of display_all_classes will be False and under caption fields there will be all language that user selected for caption.
 * @param id
 * @returns UserSettingsResponse Successful response
 * @throws ApiError
 */
export const getUserSettings = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<UserSettingsResponse> => {
    const localVarPath = `/api/user/${id}/settings`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Sets an existing user's setting info
 * Updates the logged in user's settings and returns all of that user's settings. The peloton client details header is expected to contain `workout_id` when closed captions are toggled on or off in addition to the base properties.
 * @param id This must be the signed in user's id, or this endpoint will return an authentication failure
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @param requestBody
 * @returns UserSettingsResponse Successful response
 * @throws ApiError
 */
export const putUserSettings = (
    api: BaseAPI,
    id: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    requestBody?: UserSettingsRequestBody,
    options: any = {},
): AxiosPromise<UserSettingsResponse> => {
    const localVarPath = `/api/user/${id}/settings`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get url to upload image to
 * Gets a presigned url that can be PUT to in order to upload a new image for that user. Additionally returns new_image_url, which will be the url of the image uploaded with the presigned url. To Use: (1) Use this endpoint to get a presigned url, and a new image url. (2) Make a PUT request with the presigned url, with your new image file as the body. Use the header {Content-type: 'application/octet-stream'} to ensure request headers match those expected by the presigned url. (3) Update the user within 10 minutes of generating a presigned url by making a PUT request to /user/id with a message body that includes "image_url"=<new_image_url>, where new_image_url is the new image url you obtained in step (1).
 * @param id This must be the signed in user's id, or this endpoint will return an authentication failure
 * @returns any Successful response
 * @throws ApiError
 */
export const userImageUploadUrl = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<{
    /**
     * AWS S3 presigned url to upload a new profile image
     */
    uploadUrl?: string,
    /**
     * Permanent url for the uploaded image, which should be set as the user's image_url and is valid for 10 minutes
     */
    newImageUrl?: string,
}> => {
    const localVarPath = `/api/user/${id}/image_upload_url`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Updates a remote add shared users shared user added property
 * Updates a remote add shared users shared user added property
 * @param userId
 * @param remoteAddSharedUserId
 * @returns RemoteAddSharedUser Successful response. Returns Remote Add Shard User.
 * @throws ApiError
 */
export const updateRemoteAddSharedUserShareUserAdded = (
    api: BaseAPI,
    userId: string,
    remoteAddSharedUserId: string,
    options: any = {},
): AxiosPromise<RemoteAddSharedUser> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users/${remoteAddSharedUserId}/shared_user_added`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Sends user's watch token to Braze
 * Makes POST request to Braze's /users/track endpoint to associate user push watch token with the braze app id. Requires logged in user.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const watchToken = (
    api: BaseAPI,
    requestBody?: {
        /**
         * User's apple watch token
         */
        watchToken: string,
        /**
         * bundle identifier. If not passed in, will be default to 'com.Peloton.PelotonApp.Watch'.
         */
        bundleId?: 'com.Peloton.PelotonApp.Watch' | 'com.Peloton.Enterprise.PelotonApp.Watch',
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/watch_token`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Basic Peloton Account Information
 * Checks if an account exists for a given email and if it does returns some high level information about that account
 * @param email email address to check
 * @returns any Found an account and returned basic information including has_subscriptions, has_password_set, and has_payment_on_file. has_subscriptions will include cancelled subscriptions but not transferred subscriptions, since they can not be reactivated. has_payment_on_file will also return True if the user has one subscription associated with their account and it is a 39month financed subscription.
 * @throws ApiError
 */
export const basicAccountInfo = (
    api: BaseAPI,
    email: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/basic_account_info`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;
    const requestBody = {} as any;

    if(typeof email !== 'undefined') {
        requestBody['email'] = email;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Return Access Token for ThirdParty access to Peloton Endpoints
 * Returns Access Token which allows registered thirdparty users to access Peloton's endpoint.
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const getAccessToken = (
    api: BaseAPI,
    requestBody: {
        /**
         * username
         */
        username?: string,
        /**
         * password
         */
        password?: string,
    },
    options: any = {},
): AxiosPromise<{
    data?: string,
}> => {
    const localVarPath = `/public/login`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Deletes data associated with user
 * Deletes Engagement and Ecommerce data associated with specified user, except that which is required for us to keep for our records according to Peloton Legal. Used for integration with Boomi and requires the 'boomi:write' scope.
 * @param userId
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const deleteUserAccountData = (
    api: BaseAPI,
    userId: string,
    requestBody: {
        /**
         * when the user requested the deletion (ISO 8601 datetime string)
         */
        dateUserRequested: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * was deletion request successfully submitted, does not validate user ID corresponds to an existing user
     */
    success?: boolean,
}> => {
    const localVarPath = `/api/internal/user/${userId}/delete_account_data`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Ends the user onboarding process of a remote device activation
 * Ends User Onboarding by the client/device for the remote device activation
 * @param userId
 * @param remoteDeviceActivationId
 * @returns RemoteDeviceActivation Successful response. Returns Remote Device Activation
 * @throws ApiError
 */
export const endUserOnboardingRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    options: any = {},
): AxiosPromise<RemoteDeviceActivation> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/end_user_onboarding`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get all of the future reservations for the currently logged-in user
 * Returns a list reservations for the currently logged-in user. These reservations are for future or in-progress live classes.
 * @param id ID for the user.
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserReservations = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/${id}/reservations`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Indicates whether logged in user's birthday is confirmed old enough
 * Evaluates if the logged in user's birthday is specified and makes them old enough
 * @returns any Successful response
 * @throws ApiError
 */
export const isUserOldEnough = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * the logged in user's birthday is specified and makes them old enough
     */
    oldEnough?: boolean,
    /**
     * error message birthdate is too young, internationalized by Accept-Language header. Null if birthdate is valid
     */
    errorMessage?: string,
}> => {
    const localVarPath = `/api/user/old_enough`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Creates a remote device activation
 * Creates a new remote device activation underneath the user
 * @param userId
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param productModel Can be used to identify the different bike and tread models. If not provided then the product_model will default to:
 * - v1_bike for home_bike and commercial_bike.
 * - aurora for home_tread.
 *
 * @param requestBody
 * @returns RemoteDeviceActivation Successful response. Returns Remote Device Activation
 * @throws ApiError
 */
export const createRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    productModel?: 'v1_bike' | 'titan' | 'aurora' | 'prism',
    requestBody?: {
        deviceId?: string,
        firmwareVersion?: string,
    },
    options: any = {},
): AxiosPromise<RemoteDeviceActivation> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Product-Model'] = String(productModel);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets a user's information
 * Return user information. Private information will also be included if the user being requested is the currently logged-in user (Private information including birthday, email, heart rate zones, subscriptions, etc.) Aurora content will be hidden unless aurora platform is specified in header If this user's profile is private and the logged-in user does not follow them, most fields will be null. Not null fields are id, username, location, profile_image, is_profile_private and relationship. Note 1: this returns two fields that are not part of the user mapping (streaks and relationships) Note 2: Only required contracts are returned. To retrieve optional contracts use GET /api/user/{id}/contract_agreements
 * @param id
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param isOnboarded Name of the feature to check onboarding status on (example usage, "is_onboarded=hestia"). It will also accept a comma-separated list of features to get the onboarding status of each feature (ex. "is_onboarded=wolfpack,tooltips").
 * @returns any Successful response
 * @throws ApiError
 */
export const getUser = (
    api: BaseAPI,
    id: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    isOnboarded?: 'hestia' | 'movement_tracker_v2' | 'movement_tracker_v3' | 'rep_counting' | 'stacked_classes' | 'wolfpack' | 'app_onboarding' | 'tooltips' | 'tooltips, willy_nilly, first_analytics_session, wolfpack' | 'willy_nilly' | 'first_analytics_session',
    options: any = {},
): AxiosPromise<(LoggedInUserMapping & {
    relationship?: UserRelationship,
    streaks?: Streaks,
    externalMusicAuthList?: Array<MusicPlatformInfo>,
    tagsInfo?: TagsInfo,
})> => {
    const localVarPath = `/api/user/${id}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof isOnboarded !== 'undefined') {
        localVarQueryParameter['is_onboarded'] = isOnboarded;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Sets updated info for an existing user
 * Updates the logged in user's information and returns all of that user's information.
 * @param id This must be the signed in user's id, or this endpoint will return an authentication failure
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const putUser = (
    api: BaseAPI,
    id: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    requestBody?: {
        birthday?: number,
        customizedMaxHeartRate?: number,
        customizedHeartRateZones?: Array<number>,
        email?: string,
        facebookAccessToken?: string,
        facebookId?: string,
        facebookTokenExpiry?: number,
        firstName?: string,
        gender?: 'female' | 'male' | 'non_binary',
        hasSignedWaiver?: boolean,
        /**
         * height of the user (in height_unit)
         */
        height?: number,
        /**
         * Which unit this user prefers for height
         */
        heightUnit?: 'imperial' | 'metric',
        /**
         * url to user's profile image
         */
        imageUrl?: string,
        instructorId?: string,
        /**
         * location of the user
         */
        location?: string,
        lastName?: string,
        middleInitial?: string,
        phoneNumber?: string,
        /**
         * Not currently used
         */
        timezone?: string,
        /**
         * user's username
         */
        username?: string,
        /**
         * weight of the user (in weight_unit)
         */
        weight?: number,
        /**
         * Which unit this user prefers for weight
         */
        weightUnit?: 'imperial' | 'metric',
        /**
         * functional threshold power (ftp) value for bike
         */
        cyclingFtp?: number,
        /**
         * source for cycling_ftp value for bike. This can also be set to null.
         */
        cyclingFtpSource?: 'ftp_manual_source' | 'ftp_estimated_source' | 'ftp_workout_source',
        /**
         * last power zone workout id using which user updated cycling_workout_ftp
         */
        cyclingFtpWorkoutId?: string,
        /**
         * ftp_value of last power zone cycling workout (ftp_value of workout with cycling_ftp_workout_id)
         */
        cyclingWorkoutFtp?: number,
        /**
         * value indicating whether user has viewed onboarding materials for stacked classes
         */
        onboardedToStackedClasses?: boolean,
        /**
         * value indicating whether user has viewed onboarding materials for hestia
         */
        onboardedToHestia?: boolean,
        /**
         * value indicating whether user has viewed onboarding materials for wolfpack feature
         */
        onboardedToWolfpack?: boolean,
        /**
         * value indicating whether user has viewed onboarding materials for movement tracker V2
         */
        onboardedToMovementTrackerV2?: boolean,
        /**
         * value indicating whether user has viewed onboarding materials for movement tracker V3
         */
        onboardedToMovementTrackerV3?: boolean,
        /**
         * value indicating whether user has viewed onboarding materials for rep counting
         */
        onboardedToRepCounting?: boolean,
        /**
         * value indicating whether user has completed all the app onboarding checklist items
         */
        onboardedToAppOnboarding?: boolean,
        /**
         * value indicating whether user has viewed all the onboarding tooltips
         */
        onboardedToTooltips?: boolean,
        /**
         * value indicating whether user has viewed welcome drawer
         */
        onboardedToWillyNilly?: boolean,
        /**
         * value indicating whether analytics event for first freemium user session has been tracked
         */
        onboardedToFirstAnalyticsSession?: boolean,
    },
    options: any = {},
): AxiosPromise<(LoggedInUserMapping)> => {
    const localVarPath = `/api/user/${id}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Acknowledges the device attachment of a remote device activation
 * Acknowledges by the client/device that the remote device activation was attached
 * @param userId
 * @param remoteDeviceActivationId
 * @returns RemoteDeviceActivation Successful response. Returns Remote Device Activation
 * @throws ApiError
 */
export const acknowledgeRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    options: any = {},
): AxiosPromise<RemoteDeviceActivation> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/acknowledge_device_attachment`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Update a user's quick hits settings
 * This endpoint will update the backend to store quick hits (a user's custom set speed and incline shortcuts)
 * @param requestBody QuickHits
 * @returns QuickHits All quick hits entries are successfully saved in the database
 * @throws ApiError
 */
export const updateQuickHits = (
    api: BaseAPI,
    requestBody: QuickHits,
    options: any = {},
): AxiosPromise<QuickHits> => {
    const localVarPath = `/api/user/quick_hits`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get all of the future reservations for the currently logged-in user
 * Returns a list reservations for the currently logged-in user. These reservations are for future or in-progress live classes.
 * @returns any Successful response
 * @throws ApiError
 */
export const getOwnReservations = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/reservations`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get users tiering info
 * Get users tiering info
 * @returns any Successful response
 * @throws ApiError
 */
export const membershipTieringInfo = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * total number of exclusive classes allowed
     */
    digitalLimitedClassesTotal?: number,
    /**
     * list of all limited digital disciplines
     */
    digitalLimitedDisciplines?: Array<string>,
    /**
     * list of limited digital disciplines for marketing
     */
    digitalLimitedDisciplinesMarketing?: Array<string>,
    /**
     * Time when legacy digital users will lose access
     */
    digitalLegacyAccessExpireDate?: number,
    /**
     * User's subscription info related to tiers
     */
    membershipTieringInfo?: MembershipTieringInfo,
}> => {
    const localVarPath = `/api/membership/tiering_info`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Resets the expiration for a remote add shared users
 * Resets the expiration for a remote add shared users
 * @param userId
 * @param remoteAddSharedUserId
 * @returns RemoteAddSharedUser Successful response. Returns Remote Add Shared User.
 * @throws ApiError
 */
export const resetExpirationRemoteAddSharedUsers = (
    api: BaseAPI,
    userId: string,
    remoteAddSharedUserId: string,
    options: any = {},
): AxiosPromise<RemoteAddSharedUser> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users/${remoteAddSharedUserId}/reset_expiration`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Search for Users
 * Returns a list of users that match the search query.
 * The users include three categories, depending on the relationship with
 * logged-in-user:
 * * 'self' is the logged-in-user,
 * * 'following' are the users followed by the logged-in-user,
 * * 'others' are the rest.
 *
 * The order of user list is:
 * 1. The logged-in user
 * 2. Users that follow the logged-in user, but that the logged-in user
 * does not follow.
 * 3. Users that follow the logged-in user and the logged-in user follows.
 * 4. Users that the logged-in user follows, but that do not follow the
 * logged-in user.
 * 5. Everyone else.
 *
 * The users are sorted alphabetically by username in each of the above.
 * @param query The query to search by. It can have one of three forms, an email address, a username, or a first name and a last name separated by a space. In the case of a first and last name, these can also be reversed. In any case, this will return all users where the beginning of that field matches the query.
 * @param limit If set, returns a limited number of data up to the limit specified. If not set, whole data will be returned.
 * @param page If set, retrieve data of this page number. Default to be 0.
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserByQuery = (
    api: BaseAPI,
    query: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/search/${query}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Peloton Counted-in Users
 * Returns a list of counted-in users of the class, which includes three categories: * 'self' if the logged-in user is counted in, * 'following' if the users followed by the logged-in user are counted in, * 'others' for the rest of users that are counted in. The order of user list is: 1. The logged-in user (if following the request user) 2. Users that follow the logged-in user and the logged-in user follows. 3. Users that the logged-in user follows, but that do not follow the logged-in user. 4. Users that follow the logged-in user, but that the logged-in user does not follow. 5. Everyone else. The users are sorted alphabetically by username in each of the above.
 * @param id The peloton id
 * @param limit If set, returns a limited number of data up to the limit specified. If not set, whole data will be returned.
 * @param page If set, retrieve data of this page number. Default to be 0.
 * @returns any Successful response
 * @throws ApiError
 */
export const getCountedInUsers = (
    api: BaseAPI,
    id: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/peloton/${id}/counted_in_users`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Fetches the user onboarding events for a given remote device activation
 * Fetches the user onboarding events for a given remote device activation
 * @param userId
 * @param remoteDeviceActivationId
 * @param eventsAfterId filters resutls to only contain events after the given id (defaults to all if the id cannot be found)
 * @returns any Successful response. Returns Remote User Onboarding Events
 * @throws ApiError
 */
export const getRemoteDeviceActivationUserOnboardingEvents = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    eventsAfterId?: string,
    options: any = {},
): AxiosPromise<{
    remoteUserOnboardingEvents?: Array<RemoteUserOnboardingEvent>,
}> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/remote_user_onboarding_events`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof eventsAfterId !== 'undefined') {
        localVarQueryParameter['events_after_id'] = eventsAfterId;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Creates a user onboarding event for a given remote device activation
 * Creates a user onboarding event for a given remote device activation
 * @param userId
 * @param remoteDeviceActivationId
 * @param requestBody
 * @returns any Successful creation.
 * @throws ApiError
 */
export const createRemoteDeviceActivationUserOnboardingEvents = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    requestBody?: {
        eventType?: string,
        metadata?: any,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/remote_user_onboarding_events`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Updates a remote add shared users user onboarding ended property
 * Updates a remote add shared users user onboarding ended property
 * @param userId
 * @param remoteAddSharedUserId
 * @returns RemoteAddSharedUser Successful response. Returns Remote Add Shared User.
 * @throws ApiError
 */
export const updateRemoteAddSharedUserUserOnboardingEnded = (
    api: BaseAPI,
    userId: string,
    remoteAddSharedUserId: string,
    options: any = {},
): AxiosPromise<RemoteAddSharedUser> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users/${remoteAddSharedUserId}/end_user_onboarding`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Claims the next unclaimed remote add shared user
 * Claims the next unclaimed remote add shared user
 * @param userId
 * @returns RemoteAddSharedUser Successful response. Returns Remote Add Shared User.
 * @throws ApiError
 */
export const claimRemoteAddSharedUser = (
    api: BaseAPI,
    userId: string,
    options: any = {},
): AxiosPromise<RemoteAddSharedUser> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users/claim`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Creates a remote add shared user
 * Creates a new remote add shared user underneath the user
 * @param userId
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param productModel Can be used to identify the different bike and tread models. If not provided then the product_model will default to:
 * - v1_bike for home_bike and commercial_bike.
 * - aurora for home_tread.
 *
 * @param requestBody
 * @returns RemoteAddSharedUser Successful response. Returns Remote Add Shard User
 * @throws ApiError
 */
export const createRemoteAddSharedUser = (
    api: BaseAPI,
    userId: string,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    productModel?: 'v1_bike' | 'titan' | 'aurora' | 'prism',
    requestBody?: {
        deviceId?: string,
        subscriptionId?: string,
    },
    options: any = {},
): AxiosPromise<RemoteAddSharedUser> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Product-Model'] = String(productModel);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Claims the next unclaimed remote device activation
 * Claims the next unclaimed remote device activation
 * @param userId
 * @returns RemoteDeviceActivation Successful response. Returns Remote Device Activation
 * @throws ApiError
 */
export const claimRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    options: any = {},
): AxiosPromise<RemoteDeviceActivation> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/claim`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Verifies if given birthdate would make a user old enough
 * Verifies if given birthdate would make a user old enough given the passed in country, or the logged in user's subscription country or the session country Notes on passing in country: ---no need to pass country in if user is logged in and has a subscription ---Session country is not always accurate for all clients (e.g. for Web does not necessarily represent TLD, so relying on the session country is not preferred)
 * @param birthday epoch time in seconds of birthdate e.g. 955738116
 * @param country country code (e.g. 'US'). REQUIRED if country cannot be retrieved from logged in user's subscription or Session (via Netlify or Cloudflare country headers)
 * @returns any Successful response
 * @throws ApiError
 */
export const isBirthdayOldEnough = (
    api: BaseAPI,
    birthday: number,
    country?: string,
    options: any = {},
): AxiosPromise<{
    /**
     * given birthdate would make a user old enough
     */
    oldEnough?: boolean,
    /**
     * error message birthdate is too young, internationalized by Accept-Language header. Null if birthdate is valid
     */
    errorMessage?: string,
}> => {
    const localVarPath = `/api/user/birthday/${birthday}/old_enough`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof country !== 'undefined') {
        localVarQueryParameter['country'] = country;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Resets the expiration of a remote device activation
 * Resets the expiration of a remote device activation
 * @param userId
 * @param remoteDeviceActivationId
 * @returns RemoteDeviceActivation Successful response. Returns Remote Device Activation
 * @throws ApiError
 */
export const resetExpirationRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    options: any = {},
): AxiosPromise<RemoteDeviceActivation> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/reset_expiration`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Peloton Joined Users
 * Returns the TOTAL number of users that have joined the class and a list of details for a LIMITED number of users of three categories: * 'self' if the logged-in user has joined the class, * 'following' if the users followed by the logged-in user have joined the class, * 'others' that have joined the class (if self + following do not hit the limit of 5) The order of user list is: 1. The logged-in user (if following the request user) 2. Users that follow the logged-in user and the logged-in user follows. 3. Users that the logged-in user follows, but that do not follow the logged-in user. 4. Users that follow the logged-in user, but that the logged-in user does not follow. 5. Everyone else. The users are sorted alphabetically by username in each of the above.
 * @param id The peloton id
 * @param limit deprecated, any value passed in will be ignored. The limit is not editable (it is 5). parameter is retained for backwards-compatibility
 * @param page deprecated, any value passed in will be ignored. parameter is retained for backwards-compatibility
 * @returns any Successful response
 * @throws ApiError
 */
export const getJoinedPelotonUsers = (
    api: BaseAPI,
    id: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/peloton/${id}/joined_users`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Attaches a device using a remote device activation
 * Attaches a device using a subscription id through a remote device activation
 * @param userId
 * @param remoteDeviceActivationId
 * @param requestBody
 * @returns Subscription Activated subscription with user and attached to device
 * @throws ApiError
 */
export const attachDeviceRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    requestBody?: {
        subscriptionId?: string,
    },
    options: any = {},
): AxiosPromise<Subscription> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/attach_device`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create a new user
 * Creates a new user with specified email and password.
 * Can optionally specify contracts to which the user has agreed. The available
 * contracts can be found by requesting the api/docs endpoint.
 * Triggers Created Account event to be sent to analytics.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @param requestBody
 * @returns UserMapping Successful response
 * @throws ApiError
 */
export const createUser = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    acceptLanguage?: string,
    requestBody?: {
        /**
         * user\'s chosen password
         */
        password: string,
        /**
         * user\'s email address
         */
        email: string,
        /**
         * user\'s facebook id
         */
        facebookId?: string,
        /**
         * user\'s facebook access token
         */
        facebookAccessToken?: string,
        /**
         * expiration time for facebook access token
         */
        facebookTokenExpiry?: number,
        /**
         * username for user
         */
        username?: string,
        /**
         * user\'s first name
         */
        firstName?: string,
        /**
         * user\'s last name
         */
        lastName?: string,
        /**
         * override session header\'s to set the new user locale
         */
        country?: string,
        /**
         * User accepts current terms of service, Privacy Policy, and Membership Terms
         */
        hasAcceptedPolicy?: boolean,
        /**
         * User opt in to marketing emails
         */
        allowMarketing?: boolean,
        /**
         * optional parameter for specifying contracts that the user has agreed to
         */
        contractAgreements?: Array<ContractAgreementUpdate>,
        /**
         * An epoch timestamp representing the midnight of the user's birthday in UTC
         */
        birthday?: number,
        /**
         * the platform user is created from
         */
        createdFrom?: 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'cms' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1',
        createdFromSubscriptionId?: string,
        gender?: 'female' | 'male' | 'non_binary',
        height?: number,
        location?: string,
        weight?: number,
        /**
         * ID for the current subscription used to create the user, if known.
         */
        subscriptionId?: string,
        /**
         * indicates whether or not user is onboarded to feature. Example usage, 'onboarded_to_hestia=True'
         */
        onboardedToFeatureName?: boolean,
    },
    options: any = {},
): AxiosPromise<UserMapping> => {
    const localVarPath = `/api/user`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);
    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Checks if a user is able to view another user
 * @param userId
 * @param otherUserId
 * @param pelotonTotpToken An 8-digit TOTP (time-based HMAC one-time password) using SHA1. Please contact the API team for
 * the shared secret.
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const userCanViewUser = (
    api: BaseAPI,
    userId: string,
    otherUserId: string,
    pelotonTotpToken: string,
    options: any = {},
): AxiosPromise<{
    canView?: boolean,
}> => {
    const localVarPath = `/api/internal/user/can_view_user`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof userId !== 'undefined') {
        localVarQueryParameter['user_id'] = userId;
    }
    if(typeof otherUserId !== 'undefined') {
        localVarQueryParameter['other_user_id'] = otherUserId;
    }

    localVarHeaderParameter['Peloton-TOTP-Token'] = String(pelotonTotpToken);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Fetches a remote add shared user by id
 * Fetches a remote add shared user by id
 * @param userId
 * @param remoteAddSharedUserId
 * @returns RemoteAddSharedUser Successful response. Returns Remote Add Shard User.
 * @throws ApiError
 */
export const getRemoteAddSharedUser = (
    api: BaseAPI,
    userId: string,
    remoteAddSharedUserId: string,
    options: any = {},
): AxiosPromise<RemoteAddSharedUser> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users/${remoteAddSharedUserId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Search for Users
 * Returns a list of users that match the search query.
 * The users include three categories, depending on the relationship with
 * logged-in-user:
 * * 'self' is the logged-in-user,
 * * 'following' are the users followed by the logged-in-user,
 * * 'others' are the rest.
 *
 * The order of user list is:
 * 1. The logged-in user
 * 2. Users that follow the logged-in user, but that the logged-in user
 * does not follow.
 * 3. Users that follow the logged-in user and the logged-in user follows.
 * 4. Users that the logged-in user follows, but that do not follow the
 * logged-in user.
 * 5. Everyone else.
 *
 * The users are sorted alphabetically by username in each of the above.
 * @param userQuery The query to search by. It can have one of three forms, an email address, a username, or a first name and a last name separated by a space. In the case of a first and last name, these can also be reversed. In any case, this will return all users where the beginning of that field matches the query.
 * @param limit If set, returns a limited number of data up to the limit specified. If not set, whole data will be returned.
 * @param page If set, retrieve data of this page number. Default to be 0.
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserByQueryByParameter = (
    api: BaseAPI,
    userQuery: string,
    limit?: number,
    page?: number,
    options: any = {},
): AxiosPromise<(PaginationResponse)> => {
    const localVarPath = `/api/user/search`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof userQuery !== 'undefined') {
        localVarQueryParameter['user_query'] = userQuery;
    }
    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }
    if(typeof page !== 'undefined') {
        localVarQueryParameter['page'] = page;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create a Digital Guest Pass and Email it to the User
 * Creates and stores a new access code, and enqueues an email to be sent to the members with the access code. The member can then forward the access code so non-Peloton members can  get access to a (possibly extended) digital subscription free trial
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @returns any No content
 * @throws ApiError
 */
export const createDigitalGuestPassEmail = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/guestPass/email`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Cancels the remote device activation
 * Cancels the given remote device activation
 * @param userId
 * @param remoteDeviceActivationId
 * @param requestBody
 * @returns RemoteDeviceActivation Successful response. Returns Remote Device Activation
 * @throws ApiError
 */
export const cancelRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    requestBody: {
        cancelReason: string,
        cancelData: {
            email?: string,
        },
    },
    options: any = {},
): AxiosPromise<RemoteDeviceActivation> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/cancel`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Check if user exists
 * Checks whether user exists using one of their username or email. Returns True if user exists, False if not.
 * @param email
 * @param username
 * @returns any Successful response
 * @throws ApiError
 */
export const checkUserExists = (
    api: BaseAPI,
    email?: string,
    username?: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/exists`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;
    const requestBody = {} as any;

    if(typeof email !== 'undefined') {
        requestBody['email'] = email;
    }
    if(typeof username !== 'undefined') {
        requestBody['username'] = username;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets a remote device activation
 * Gets the remote device activation underneath the user given the remote activation id
 * @param userId
 * @param remoteDeviceActivationId
 * @returns RemoteDeviceActivation Successful response. Returns Remote Device Activation
 * @throws ApiError
 */
export const getRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    options: any = {},
): AxiosPromise<RemoteDeviceActivation> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets user ID from user account email
 * Used for deletion integration with Boomi so requires the 'boomi:write' scope.
 * @param email
 * @returns any User ID, if any, for email is found
 * @throws ApiError
 */
export const userIdFromEmail = (
    api: BaseAPI,
    email: string,
    options: any = {},
): AxiosPromise<{
    /**
     * null if user not found
     */
    userId?: string,
    email?: string,
    /**
     * details, if any, on why user ID not returned (e.g. user not found)
     */
    message?: string,
    /**
     * indicates user is high risk and should not be automatically deleted
     */
    isUserHighRisk?: boolean,
}> => {
    const localVarPath = `/api/internal/user_id_matching_email/${email}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Cancels a remote add shared user
 * Cancels a remote add shared user
 * @param userId
 * @param remoteAddSharedUserId
 * @param requestBody
 * @returns RemoteAddSharedUser Successful response. Returns Remote Add Shared User.
 * @throws ApiError
 */
export const cancelRemoteAddSharedUser = (
    api: BaseAPI,
    userId: string,
    remoteAddSharedUserId: string,
    requestBody?: {
        cancelReason: string,
        cancelData: {
            email?: string,
        },
    },
    options: any = {},
): AxiosPromise<RemoteAddSharedUser> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users/${remoteAddSharedUserId}/cancel`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Determine the Guest Pass sku for a given user
 * Returns the Guest Pass SKU/Trial length for a given user id. This endpoint will be used for the MVP until a specific sku/free trial length is chosen. Prior to the trial, this endpoint will default to a SKU for a 60 day trial
 * @returns GuestPassSku The sku for the requesting user
 * @throws ApiError
 */
export const getGuestPassSku = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<GuestPassSku> => {
    const localVarPath = `/api/user/guestPassSku`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Set the logged in user's password
 * Set the logged in user's password.
 * @param id ID for the user
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const setUserPassword = (
    api: BaseAPI,
    id: string,
    requestBody: {
        /**
         * old plain text password
         */
        oldPassword?: string,
        /**
         * new plain text password
         */
        newPassword?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * was the password set successfully
     */
    success?: boolean,
}> => {
    const localVarPath = `/api/user/${id}/set_password`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Attaches a device using a remote device activation
 * Attaches a device using an activation key through a remote device activation
 * @param userId
 * @param remoteDeviceActivationId
 * @param requestBody
 * @returns Subscription Activated subscription with user and attached to device
 * @throws ApiError
 */
export const attachDeviceWithActivationKeyRemoteDeviceActivation = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    requestBody?: {
        activationKey?: string,
    },
    options: any = {},
): AxiosPromise<Subscription> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/attach_device_with_activation_key`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets basic user information for a batch of users identified by ID
 * Return basic user properties for each user specified by ID. Private user information such as total_workouts will be set to null if the user's profile is private and the logged in user is null or not a follower. Limited to 100 user IDs at a time.
 * @param ids comma-separated list of user IDs to get
 * @returns UserBasicMapping Successful response
 * @throws ApiError
 */
export const getUserBatch = (
    api: BaseAPI,
    ids: string,
    options: any = {},
): AxiosPromise<Record<string, UserBasicMapping>> => {
    const localVarPath = `/api/internal/batch/users`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof ids !== 'undefined') {
        localVarQueryParameter['ids'] = ids;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets a logged in user's information
 * Return logged in user information. Information will not be sent if the user is not logged-in. (Private information including birthday, email, heart rate zones, subscriptions, etc.) Aurora content will be hidden unless aurora platform is specified in header. Urls for the required legal contracts are also returned with the time at which the user agreed to the contract and the time the contract was created. The correct contract will be returned based on the locale country. Optional contracts are not returned by this endpoint. To retrieve optional contracts use GET /api/user/{id}/contract_agreements
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getMe = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    options: any = {},
): AxiosPromise<(LoggedInUserMapping)> => {
    const localVarPath = `/api/me`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get the user feed
 * Gets the user message feed for the user identified by the given id. If specified, limits the number of results
 * @param id
 * @param limit
 * @returns any Successful response
 * @throws ApiError
 */
export const getUserFeed = (
    api: BaseAPI,
    id: string,
    limit?: number,
    options: any = {},
): AxiosPromise<Array<{
    id?: string,
    messageType?: string,
    meta?: string,
    created?: number,
    text?: string,
    payload?: any,
}>> => {
    const localVarPath = `/api/user/${id}/feed`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof limit !== 'undefined') {
        localVarQueryParameter['limit'] = limit;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Update a user's hardware settings.
 * This endpoint will update the backend to store user's hardware settings for a certain hardware type.
 * @param hardwareType Hardware type, tread or caesar.
 * @param requestBody Schema can be UserTreadHardWareSettings or UserCaesarHardWareSettings
 * @returns any Successful response. Content schema is UserTreadHardWareSettings, or UserCaesarHardWareSettings.
 * @throws ApiError
 */
export const updateUserHardwareSettings = (
    api: BaseAPI,
    hardwareType: string,
    requestBody: (UserTreadHardWareSettings | UserCaesarHardWareSettings),
    options: any = {},
): AxiosPromise<(UserTreadHardWareSettings | UserCaesarHardWareSettings)> => {
    const localVarPath = `/api/me/hardware_settings/${hardwareType}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Validates used equipment was purchased through peloton store
 * Gets the remote device activation underneath the user given the remote activation id and validates if the hardware was purchased by the provided email
 * @param userId
 * @param remoteDeviceActivationId
 * @param email
 * @param isGift
 * @returns any Successful response.
 * @throws ApiError
 */
export const getRemoteDeviceActivationEquipmentOrder = (
    api: BaseAPI,
    userId: string,
    remoteDeviceActivationId: string,
    email: string,
    isGift?: boolean,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/${userId}/remote_device_activations/${remoteDeviceActivationId}/used_equipment_order`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof email !== 'undefined') {
        localVarQueryParameter['email'] = email;
    }

    if(typeof isGift !== 'undefined') {
        localVarQueryParameter['is_gift'] = email;
    }

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Creates a user onboarding event for a given remote add shared user
 * Creates a user onboarding event for a given remote add shared user
 * @param userId
 * @param remoteAddSharedUserId
 * @param requestBody
 * @returns any Successful creation.
 * @throws ApiError
 */
export const createRemoteAddSharedUserUserOnboardingEvents = (
    api: BaseAPI,
    userId: string,
    remoteAddSharedUserId: string,
    requestBody?: {
        eventType?: string,
        metadata?: any,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/${userId}/remote_add_shared_users/${remoteAddSharedUserId}/remote_user_onboarding_events`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create a Digital Guest Pass
 * Creates, stores, and returns a new access code non-Peloton members can use  to get access to a (possibly extended) digital subscription free trial
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param pelotonClientDetails Client details that clients send to API for sending events to
 * Segment.
 *
 * These properties are sent as a mapping of key value pairs, where the
 * key is each expected Segment property name and its value is the client's
 * property value.
 *
 * Refer to
 * https://docs.google.com/spreadsheets/d/1NkMmgAqDERhzLJJ7E7gbgX2Mz8OBpSUOJ1n2xJS_2RI/edit#gid=1066590696
 * for more details on Segment events and schema.
 *
 * This mapping is first JSON-serialized and then base64-encoded. The
 * base64-encoding is set as the header's value.
 *
 * Below we outline some examples:
 *
 * Web Client
 * ~~~~
 * 'Browser': 'Chrome',
 * 'Form Factor': 'Desktop',
 * 'Operating System': 'Windows',
 * 'Screen Size': '1024x768',
 * 'Source': 'Member Search',
 * ~~~~
 *
 * Android Bike Client
 * ~~~~
 * 'Bike Frame Serial': 'T1710PL01059730',
 * 'OS Version': 11.3,
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * Android Tread Client
 * ~~~~
 * 'OS Version': 11.3
 * 'Touchscreen Generation': 'Ruby',
 * 'Touchscreen Serial': 'someSerialNumber',
 * 'MCB Serial': 'serialnumber',
 * 'Chassis Serial': 'serialnumber',
 * 'Smartcard Serial': 'serialnumber',
 * 'Toggles': ['here_now', 'high_fives'],
 * 'Source': 'Profile'
 * ~~~~
 *
 * iOS Client
 * ~~~~
 * 'Device Type': 'iPhone',
 * 'Device': 'iPhone X',
 * 'App Version': '8.6.0',
 * 'iOS Version': '10.0.2',
 * 'iOS Subscription ID': 'someIdNumber',
 * 'Source': 'Followers'
 * ~~~~
 *
 * @returns GuestPassAccessCode A new Guest Pass access code was created and saved in the database
 * @throws ApiError
 */
export const createDigitalGuestPass = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    pelotonClientDetails?: string,
    options: any = {},
): AxiosPromise<GuestPassAccessCode> => {
    const localVarPath = `/api/user/guestPass`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);
    localVarHeaderParameter['Peloton-Client-Details'] = String(pelotonClientDetails);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

