import type { PresenceWindowResponse } from '@engage/api-v2';
import { getPresenceWindow as engageGetPresenceWindow } from '@engage/api-v2';
import { presenceApi } from '../lib/singletons';
import { toFetcher } from './toFetcher';

type GetPresenceWindowRequiredParams = {
  accessToken: string;
  classId: string;
};

type GetPresenceWindowOptionalParams = {
  centerWorkoutId: string;
  windowSize: number;
};

type PresenceResponse = PresenceWindowResponse & { startIndex: number };

const getPresenceWindow = (
  api: typeof presenceApi = presenceApi,
  { accessToken, classId }: GetPresenceWindowRequiredParams,
  { centerWorkoutId, windowSize }: Partial<GetPresenceWindowOptionalParams>,
) =>
  engageGetPresenceWindow(
    api,
    classId,
    {
      centerWorkoutId,
      windowSize,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then(response => {
    return response.data as PresenceResponse;
  });

export const toPresenceFetchers = (api: typeof presenceApi = presenceApi) => ({
  GetPresenceWindow: toFetcher<
    GetPresenceWindowRequiredParams,
    GetPresenceWindowOptionalParams,
    [string, string],
    PresenceResponse,
    (key: string, centeredWorkoutId: string) => Promise<PresenceResponse>
  >(
    (
      { classId }: GetPresenceWindowRequiredParams,
      { centerWorkoutId }: Partial<GetPresenceWindowOptionalParams>,
    ) => {
      return [`GetPresenceWindow?classId=${classId}`, centerWorkoutId ?? ''];
    },
    (
      req: GetPresenceWindowRequiredParams,
      opt: Partial<GetPresenceWindowOptionalParams>,
    ) => () => getPresenceWindow(api, req, opt),
  ),
});
