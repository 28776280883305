import { toProfileUrl } from '@peloton/links/members';
import { toProfileUrlTemplate } from '@engage/overview';

const GOALS_SUBPATH = '/goals';
const MEMBERS_GOALS_SUBPATH = '/:userId/goals';

export const GOALS_ROUTE = toProfileUrlTemplate(GOALS_SUBPATH);

export const toGoalsUrl = (id: string, isMe: boolean) =>
  toProfileUrl(GOALS_SUBPATH, MEMBERS_GOALS_SUBPATH, id, isMe);
