import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { SEARCH_ROUTE } from './urls';

const Routes = [
  <Route
    key={SEARCH_ROUTE}
    path={SEARCH_ROUTE}
    component={lazyLoader(
      () =>
        import('./SearchPage' /* webpackChunkName: "9992f072835377be881afd3c30c680a3" */),
    )}
  />,
];

export default Routes;
