export enum SupportedFormat {
  AbbreviatedLong = 'ABBREVIATED_LONG',
  AbrevLongWithoutDay = 'ABBREVIATED_LONG_DATE_WITHOUT_DAY',
  SlashedDate = 'SLASHED_DATE',
  SlashedDateLongYear = 'SLASHED_DATE_LONG_YEAR',
  TwoDigitMonthSlashedDate = 'TWO_DIGIT_MONTH_SLASHED_DATE',
  DayNameMonthDate = 'DAY_NAME_MONTH_DATE',
  DayNameShortMonthDate = 'DAY_NAME_SHORT_MONTH_DATE',
  DayNameShort = 'DAY_NAME_SHORT',
  DayOfMonth = 'DAY_OF_MONTH',
  MonthNameShortDate = 'MONTH_NAME_SHORT_DATE',
  MonthYear = 'MONTH_YEAR',
  ShortMonthDate = 'SHORT_MONTH_DATE',
  Time = 'TIME',
  DayNameLongMonthSlashDay = 'DAY_NAME_LONG_MONTH_SLASH_DAY',
  MonthNameYear = 'MONTH_NAME_YEAR',
  MonthNameShort = 'MONTH_NAME_SHORT',
}
