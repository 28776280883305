import { replace } from 'connected-react-router';
import type { Location } from 'history';
import type { SagaIterator } from 'redux-saga';
import {
  call,
  getContext,
  put,
  select,
  take,
  takeEvery,
  spawn,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { isMe } from '@peloton/auth';
import { getLocation } from '@peloton/redux';
import type { FetcherSelectorState } from '@peloton/redux-fetch/redux';
import { track } from '@engage/analytics';
import type { ClassAnalyticsProperties } from '@engage/classes';
import { getClassAnalyticsProperties, getClass, ClassActionTypes } from '@engage/classes';
import { logError } from '@engage/error-reporting/logError';
import { getMember, isMembersSuccessActionForMember } from '@engage/members';
import { cacheMetadataSaga } from '@engage/metadata';
import type { Workout, DetailedWorkout } from '@engage/workouts';
import {
  loadWorkout,
  loadWorkoutSummary,
  WorkoutActionType,
  workoutPerformanceNamespace,
} from '@engage/workouts';
import type { TagsWorkoutAnalytics } from '@members/pg-video/useTagsAnalytics';
import { isMemberPath, isProfilePath } from '@members/profile/urls';
import { trackViewedWorkoutDetailsPage } from '../analytics';
import { toWorkoutDetailsPath } from '../urls';
import { WorkoutDetailsPageActionType } from './redux';

export const getUserWhenAvailable = function* (userId: string): SagaIterator {
  let user = yield select(getMember, userId);
  if (!user) {
    yield take(isMembersSuccessActionForMember(userId));
    user = yield select(getMember, userId);
  }
  return user;
};

export const ensureProfileUrlScheme = function* (
  workout: Workout,
  location: Location,
): SagaIterator {
  const isMeWorkout = yield select(isMe, workout.userId);
  if (
    (isMeWorkout && isMemberPath(location.pathname)) ||
    (!isMeWorkout && isProfilePath(location.pathname)) ||
    (!isProfilePath(location.pathname) && !isMemberPath(location.pathname))
  ) {
    yield put(replace(toWorkoutDetailsPath(isMeWorkout, workout.id, workout.userId)));
  }
};

const getWorkoutFetchStatus = (state: FetcherSelectorState, workoutId: string) =>
  Boolean(state?.fetched?.[workoutPerformanceNamespace(workoutId)]);

export const loadWorkoutDetailsPageSaga = function* (
  client: Client,
  action: ReturnType<typeof loadWorkoutDetailsPage>,
): SagaIterator {
  try {
    const hasFetchedWorkout = yield select(getWorkoutFetchStatus, action.payload.id);
    if (hasFetchedWorkout) return;

    yield put(loadWorkout(action.payload.id));
    yield put(loadWorkoutSummary(action.payload.id));

    const { payload: workout } = yield take(WorkoutActionType.RequestSuccess);
    const location = yield select(getLocation);
    const { tagsAnalyticsProps } = action.payload;

    yield call(ensureProfileUrlScheme, workout, location);
    yield spawn(trackViewedWorkoutDetailsPageSaga, location, workout, tagsAnalyticsProps);
  } catch (err) {
    logError(err, 'loadWorkoutDetailsPageSaga');
  }
};

export const trackViewedWorkoutDetailsPageSaga = function* (
  location: Location,
  workout: DetailedWorkout,
  tagsAnalyticsProps: TagsWorkoutAnalytics,
): SagaIterator {
  yield take(ClassActionTypes.REQUEST_SUCCESS);
  yield call(cacheMetadataSaga);
  const klass = yield select(getClass, workout.classId);
  const classInfo: ClassAnalyticsProperties | undefined = yield select(
    getClassAnalyticsProperties,
    klass.id,
  );
  const workoutUser = yield call(getUserWhenAvailable, workout.userId);

  yield put(
    track(
      trackViewedWorkoutDetailsPage({
        workout,
        workoutUser,
        location,
        classInfo,
        tagsAnalyticsProps,
      }),
    ),
  );
};

const workoutDetailsPageSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(WorkoutDetailsPageActionType.Load, loadWorkoutDetailsPageSaga, client);
};

export default workoutDetailsPageSaga;

export const loadWorkoutDetailsPage = (
  id: string,
  tagsAnalyticsProps: TagsWorkoutAnalytics,
) => ({
  type: WorkoutDetailsPageActionType.Load,
  payload: {
    id,
    tagsAnalyticsProps,
  },
});
