import type { AxiosPromise } from 'axios';
import type { ChallengeSummary, Peloton, Ride } from '@engage/api-v2';
import { pagesApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type ItemContentType =
  | 'greeting'
  | 'chip'
  | 'upcoming_ride'
  | 'ride'
  | 'image'
  | 'program_in_progress'
  | 'scheduled_class'
  | 'challenge';

type Params = { acceptLanguage?: string; isHomescreenViaPagesEnabled?: boolean };

export type PagesHomescreenResponse = {
  data: PageRow[];
  maxWeight: number;
  layoutName: 'web_full';
};

export type PageRow = {
  moduleViewType: string;
  id: string;
  slug: string;
  title: string;
  weight: number;
  data: any;
};

export type WelcomeRow = PageRow & {
  data: WelcomeItem[];
};

export type WelcomeItem = {
  itemViewType: string;
  id: string;
  data: WelcomeInfo;
};

type WelcomeInfo = {
  contentType: ItemContentType;
  imageUrl: string;
  username: string;
  header: string;
  subHeader: string;
  activityTrends: ActivityTrend[];
  activityItems: ActivityItem[];
};

export type PromotionRow = PageRow & {
  data: PromotionItem;
};

export type PromotionItem = {
  itemViewType: string;
  id: string;
  data: PromotionInfo[];
};

type PromotionInfo = {
  smallImageUrl: string;
  mediumImageUrl: string;
  largeImageUrl: string;
};

export type FitnessCategoriesRow = PageRow & {
  data: FitnessCategoryItem[];
};

export type FitnessCategoryItem = {
  itemViewType: string;
  id: string;
  data: FitnessCategoryInfo;
};

type FitnessCategoryInfo = {
  contentType: ItemContentType;
  id: string;
  text: string;
  destinationUrl: string;
  imageUrl: string;
};

export type LiveClassRow = PageRow & {
  data: LiveClassItem[];
};

export type LiveClassItem = {
  itemViewType: string;
  id: string;
  data: LiveClassInfo;
};

type LiveClassInfo = {
  contentType: ItemContentType;
  id: string;
  peloton: Peloton;
  ride: Ride & { instructorName: string; instructorImageUrl: string };
};

export type FreemiumRow = PageRow & {
  data: FreemiumClassItem[];
};

export type FreemiumClassItem = {
  itemViewType: string;
  id: string;
  data: FreemiumClassInfo;
  text?: string;
  text_en?: string;
  iconUrl?: string;
};

export type FreemiumClassInfo = {
  contentType: ItemContentType;
  id: string;
  ride: Ride & {
    previewStreamUrl: string;
    instructorName: string;
    instructorImageUrl: string;
    freeForALimitedTime: boolean;
  };
  music: {
    topAlbums: {
      id: string;
      imageUrl: string;
      name: string;
    }[];
    topArtists: {
      artistId: string;
      artistName: string;
    }[];
  };
};

export type ClassRow = PageRow & {
  data: ClassItem[];
};

export type ClassItem = {
  itemViewType: string;
  id: string;
  data: ClassInfo;
  text?: string;
  text_en?: string;
  iconUrl?: string;
};

export type ClassInfo = {
  contentType: ItemContentType;
  id: string;
  ride: Ride;
};

export type CollectionRow = PageRow & {
  data: CollectionItem[];
};

export type CollectionItem = {
  itemViewType: string;
  id: string;
  data: CollectionInfo;
  text: string;
};

export type CollectionInfo = {
  contentType: ItemContentType;
  id: string;
  imageUrl?: string;
  text?: string;
};

export type ProgramRow = PageRow & {
  data: ProgramItem[];
};

export type ProgramItem = {
  itemViewType: string;
  id: string;
  data: ProgramInfo;
};

export type ProgramInfo = {
  content_type: ItemContentType;
  id: string;
  nextProgramClass: {
    classId: string;
    classImageUrl: string;
    classTitle: string;
    instructorName: string;
    programClassId: string;
    programClassNumber: number;
  };
  program: {
    duration: number;
    id: string;
    imageUrl: string;
    isMfbt: boolean;
    name: string;
    totalProgramNumClasses: number;
  };
  programEndDate: string;
  totalWeekNumClasses: number;
  totalWeeks: number;
  weekId: string;
  weekEndDate: string;
  weekNumClassesCompleted: number;
  weekNumber: number;
  weekStartDate: string;
};

export type ActivityTrend = {
  displayName: string;
  slug: string;
  value: number;
};

export type ActivityItem = {
  type: string;
  data: {
    id: string;
    slug: string;
    progress: number;
    target: number;
  };
};

export type ActivityTrendItem = {
  type: string;
  data: {
    displayName: string;
    slug: string;
    value: number;
    destinationUrl: string;
  };
};

export type YourScheduleRow = PageRow & {
  data: ScheduleItem[];
};

export type ScheduleItem = {
  itemViewType: string;
  id: string;
  data: ScheduleItemInfo;
};

export type ScheduleItemInfo = {
  contentType: ItemContentType;
  eventScheduledEndTime: number;
  eventScheduledStartTime: number;
  freeForALimitedTime: false;
  instructorName: string;
  isExplicit: boolean;
  pelotonId: string;
  rideId: string;
  title: string;
  cta: {
    text: string;
  };
  inviteHeaderResponse?: { text: string };
  hostData?: { imageLocation: string };
};

export type ChallengeRow = {
  data: ChallengeItem[];
};

export type ChallengeItem = {
  itemViewType: string;
  id: string;
  data: ChallengeInfo;
};

export type ChallengeInfo = {
  contentType: ItemContentType;
  challengeSummary: ChallengeSummary;
};

export type AppDownloadRow = {
  data: AppDownloadItem[];
};

export type AppDownloadItem = {
  itemViewType: string;
  id: string;
  data: AppDownloadInfo;
};

export type AppDownloadInfo = {
  contentType: ItemContentType;
  imageUrl: string;
  imageAltText: string;
};

function getHomescreenPages(
  api: typeof pagesApi,
  acceptLanguage?: string,
): AxiosPromise<PagesHomescreenResponse> {
  const getUrl = '/page/homescreen';
  return api.axios.get(getUrl, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

function getHomescreenLiveModules(
  api: typeof pagesApi,
  acceptLanguage?: string,
): AxiosPromise<LiveClassRow> {
  const getUrl = '/page/homescreen/live_class';
  return api.axios.get(getUrl, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

export const toHomescreenFetchers = (api: typeof pagesApi = pagesApi) => ({
  HomescreenPages: toFetcher(
    () => `HomescreenPages`,
    (params: Params) => unwrap(() => getHomescreenPages(api, params.acceptLanguage)),
  ),
  HomescreenLiveModules: toFetcher(
    () => `HomescreenLiveModules`,
    (params: Params) =>
      unwrap(() => getHomescreenLiveModules(api, params.acceptLanguage)),
  ),
});
