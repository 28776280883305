import { toV2ConfigSchema } from '@peloton/auth/oauthConfigSchema';
import {
  LinkEnvs,
  ApiEnvs,
  AccountLinkEnvs,
  DigitalLinkEnvs,
} from '@peloton/external-links';
import { isProdEnv, isStagingEnv, isUatEnv, isLocalEnv, isDeployPreviewEnv } from './env';

export const DATABASE_CONNECTION = 'pelo-user-password';

export enum OauthEnvironment {
  Dev = 'DEVELOPMENT',
  Stage = 'STAGE',
  Test = 'TEST',
  Prod = 'PRODUCTION',
  Unknown = 'UNKNOWN',
}

const DEV_CONFIG = {
  domain: 'auth-dev.onepeloton.com',
  audience: 'https://api-dev.onepeloton.com/',
  api: 'api-api-core.dev.k8s.pelotime.com',
  client_id: '92t3gq4xSU8ak7pbx9kV9LdA94Y3k2LV',
};

export const oauthConfigMap = {
  [OauthEnvironment.Dev]: DEV_CONFIG,
  [OauthEnvironment.Stage]: {
    domain: 'auth-stage.onepeloton.com',
    audience: 'https://api-stage.onepeloton.com/',
    api: 'qa1.onepeloton.com',
    client_id: 'RFmOq5KIfev1LSc4kvStH3geXmIdOYBb',
  },
  [OauthEnvironment.Test]: {
    domain: 'auth-test.onepeloton.com',
    audience: 'https://api-test.onepeloton.com/',
    api: 'qa2.onepeloton.com',
    client_id: '2XkZrzvQ7d7Ov72Fvtuuu7ASVq2XTLg5',
  },
  [OauthEnvironment.Prod]: {
    domain: 'auth.onepeloton.com',
    audience: 'https://api.onepeloton.com/',
    api: 'api.onepeloton.com',
    client_id: 'WVoJxVDdPoFx4RNewvvg6ch2mZ7bwnsM',
  },
};

const DEV_OPTS = {
  www: LinkEnvs.UatQa,
  account: AccountLinkEnvs.UatQa,
  digital: DigitalLinkEnvs.UatQa,
};

const envMap = {
  [OauthEnvironment.Dev]: DEV_OPTS,
  [OauthEnvironment.Stage]: {
    www: LinkEnvs.UatQa,
    account: AccountLinkEnvs.UatQa,
    digital: DigitalLinkEnvs.UatQa,
  },
  [OauthEnvironment.Test]: {
    www: LinkEnvs.Qa2,
    account: AccountLinkEnvs.UatQa,
    digital: DigitalLinkEnvs.UatQa,
  },
  [OauthEnvironment.Prod]: {
    www: LinkEnvs.Prod,
    account: AccountLinkEnvs.Prod,
    digital: DigitalLinkEnvs.Prod,
  },
};

export const apiEnvToOauthEnv = {
  [ApiEnvs.Prod]: OauthEnvironment.Prod,
  [ApiEnvs.Qa2]: OauthEnvironment.Test,
  [ApiEnvs.Qa1]: OauthEnvironment.Stage,
  'api.api-core.dev.k8s': OauthEnvironment.Dev,
};

export const toEnv = (envString: unknown) => {
  switch (envString) {
    case 'PRODUCTION':
      return OauthEnvironment.Prod;
    case 'TEST':
      return OauthEnvironment.Test;
    case 'STAGE':
      return OauthEnvironment.Stage;
    case 'DEVELOPMENT':
      return OauthEnvironment.Dev;
    default:
      return OauthEnvironment.Unknown;
  }
};

const isOauthProd = (hostname: string) =>
  /^auth\.onepeloton\.com/.test(hostname) || isProdEnv(hostname);
const isOauthTest = (hostname: string) =>
  /^auth-test\.onepeloton\.com/.test(hostname) || isStagingEnv(hostname);
const isOauthStage = (hostname: string) =>
  /^auth-stage\.onepeloton\.com/.test(hostname) ||
  isUatEnv(hostname) ||
  isDeployPreviewEnv(hostname) ||
  isNetlifyStage(hostname);

const isNetlifyStage = (hostname: string) =>
  /^universal-login-stage-onepeloton-com/.test(hostname);

const isOauthDev = (hostname: string) =>
  /^auth-dev\.onepeloton\.com/.test(hostname) || isLocalEnv(hostname);

export const getEnvFromWindowIfAvailable = () => {
  if (typeof window !== 'undefined' && window.location) {
    if (isOauthProd(window.location.hostname)) {
      return OauthEnvironment.Prod;
    }

    if (isOauthTest(window.location.hostname)) {
      return OauthEnvironment.Test;
    }

    if (isOauthStage(window.location.hostname)) {
      return OauthEnvironment.Stage;
    }

    if (isOauthDev(window.location.hostname)) {
      return OauthEnvironment.Dev;
    }
  }

  return undefined;
};

export const OAUTH_ENVIRONMENT: OauthEnvironment =
  getEnvFromWindowIfAvailable() ?? toEnv(process.env.ENVIRONMENT);

export const toOauthConfig = (env: OauthEnvironment) => {
  const oauthConfigEnv = oauthConfigMap[env] ?? DEV_CONFIG;
  const oauthConfig = toV2ConfigSchema(oauthConfigEnv);
  return oauthConfig;
};

export const toExtLinkEnvOpts = (env: OauthEnvironment) => envMap[env] ?? DEV_OPTS;
