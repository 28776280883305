import { toLocale, Locale } from '@peloton/internationalize';
import { isDefined } from '@peloton/types';
import { useReduxState } from '@engage/redux';
import { getDisplayLanguage } from '@engage/settings';
import { toSupportedBrowserLanguage } from './toSupportedBrowserLanguage';

export const useLocale = () => {
  try {
    const displayLanguage = useReduxState(getDisplayLanguage);

    return isDefined(displayLanguage)
      ? toLocale(displayLanguage)
      : toLocale(toSupportedBrowserLanguage());
  } catch (_) {
    return Locale.Default;
  }
};
