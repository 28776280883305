import { transparentize } from 'polished';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { getIsBreakpointGreaterThan } from '@peloton/responsive';
import { media, defaultTransition } from '@peloton/styles';
import { white, gray4, black, slate3 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxState, useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label16 } from '@engage/typography';
import { MembersCopy, Members, useMembers, useFacebook, Facebook } from '@members/copy';
import { FacebookIconBlue, findFacebookFriends } from '@members/facebook';
import { LightLabel } from '@members/form/label';
import { CancelIcon } from '@members/icons';
import { MembersListModalHeader } from '@members/members';

import { SearchIconSimple } from './icons';

export type SearchControlProps = {
  inputValue: string;
  handleInputChange(e: React.FormEvent<HTMLInputElement>): void;
  handleClickClear(): void;
  handleFbClick?(): void;
};

const isEmpty = (s: string) => (s?.length ?? 0) === 0;

export const MemberSearchControls: React.FC<
  React.PropsWithChildren<SearchControlProps>
> = props => {
  const inputRef = useRef<HTMLInputElement>(null);
  const facebookLabel = useFacebook(Facebook.SearchButtonCta);
  const findFbFriends = useReduxAction(findFacebookFriends);

  return (
    <>
      <Header />
      <Controls>
        <InputContainer
          data-test-id="searchForm"
          theme={props.inputValue.length === 0 ? 'placeholder' : 'standard'}
        >
          <If condition={isEmpty(props.inputValue)}>
            <SearchInputIcon />
          </If>
          <Input
            autoComplete="off"
            name="searchTerm"
            data-test-id="userSearchInput"
            type="text"
            value={props.inputValue}
            onChange={props.handleInputChange}
            aria-label={useMembers(Members.SearchInputLabel)}
            ref={inputRef}
          />
          <StyledLabel
            data-test-id="userSearchPlaceholder"
            isEmptyField={isEmpty(props.inputValue)}
            htmlFor={useMembers(Members.SearchInputLabel)}
          >
            <div>{useMembers(Members.SearchPlaceholder)}</div>
          </StyledLabel>
          <ClearButton
            onClick={() => {
              inputRef.current?.focus();
              props.handleClickClear();
            }}
          >
            <CancelIcon data-test-id="clearIcon" />
          </ClearButton>
        </InputContainer>
        <FacebookButton
          aria-label={facebookLabel}
          onClick={() => {
            (props.handleFbClick as () => void)();
            findFbFriends();
          }}
        >
          <FacebookIcon />
        </FacebookButton>
      </Controls>
    </>
  );
};

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const showPelotonInTitle = useReduxState(getIsBreakpointGreaterThan, 'smallScreen');
  return (
    <MembersListModalHeader>
      <Title data-test-id="searchTitle">
        <MembersCopy
          id={Members.SearchTitle}
          values={{
            showPelotonInTitle,
          }}
        />
      </Title>
    </MembersListModalHeader>
  );
};

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${spaces.medium}px;
  padding-bottom: ${spaces.xHuge}px;

  ${media.tablet`
    padding: ${spaces.large}px;
  `}
`;

const Title = styled.h1`
  color: ${slate3};
  ${label16}
`;

const Input = styled.input`
  ${label16}
  background-color: ${white};
  outline: none;
  border: none;
  width: 100%;
  box-shadow: inset 0 1px 0 0 ${transparentize(0.9, black)};
  border-radius: 5px;
  height: 50px;
  padding: ${spaces.xLarge}px 15px 10px 15px;
  &::-ms-clear {
    // IE clear button
    display: none;
    width: 0;
    height: 0;
  }
`;

const StyledLabel = styled(LightLabel)`
  border: solid 1px ${transparentize(0.4, gray4)};
  border-radius: 5px;
  padding-top: ${spaces.xxxSmall}px;
`;

const SearchInputIcon = styled(SearchIconSimple)`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 18px;
  right: 15px;
  ${defaultTransition('fill')}
`;

const ClearButton = styled.button`
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: ${gray4};
  right: 10px;
  top: ${spaces.medium}px;
  ${defaultTransition('opacity')}
  ${media.tablet`
    right: 13px;
    top: ${spaces.medium}px;
  `}
`;

const InputContainer = styled.div`
  ${label16}
  position: relative;
  flex: 1;

  ${defaultTransition('border', 400)}
  height: 50px;
  ${ClearButton} {
    opacity: ${(props: { theme: string }) => (props.theme === 'placeholder' ? '0' : '1')};
    visibility: ${(props: { theme: string }) =>
      props.theme === 'placeholder' ? 'hidden' : 'visible'};
  }
  ${SearchInputIcon} {
    fill: ${(props: { theme: string }) =>
      props.theme === 'placeholder' ? gray4 : slate3};
  }
`;

const FacebookButton = styled.button`
  margin-left: ${spaces.large}px;
`;

const FacebookIcon = styled(FacebookIconBlue)`
  height: ${spaces.large}px;
  width: ${spaces.large}px;
`;
