import { combineReducers } from 'redux';
import { ChallengeStatus } from '../models';
import { toChallengeCollectionReducer } from './collection';
import { challengeDetailReducer as detail } from './detail';
import { challengeFriendsReducer as friends } from './friends';

export const challengesReducer = combineReducers({
  [ChallengeStatus.Active]: toChallengeCollectionReducer(ChallengeStatus.Active),
  [ChallengeStatus.Upcoming]: toChallengeCollectionReducer(ChallengeStatus.Upcoming),
  [ChallengeStatus.Completed]: toChallengeCollectionReducer(ChallengeStatus.Completed),
  friends,
  detail,
});

export type State = ReturnType<typeof challengesReducer>;
export type ChallengesSelectorState = { challenges: State };
