// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const RemoteActivation = {
  AccountCreatedSubtitle: 'accountCreatedSubtitle',
  AccountCreatedTitle: 'accountCreatedTitle',
  AccountTabTitle: 'accountTabTitle',
  Activate: 'activate',
  AddAccountCodeSubtitle: 'addAccountCodeSubtitle',
  AgreeToTermsError: 'agreeToTermsError',
  AgreeToTermsRequired: 'agreeToTermsRequired',
  BasicInfoFormLabelGender: 'basicInfoFormLabelGender',
  BasicInfoFormLabelHeight: 'basicInfoFormLabelHeight',
  BasicInfoFormLabelWeight: 'basicInfoFormLabelWeight',
  BasicInfoSubtitle: 'basicInfoSubtitle',
  BasicInfoTitle: 'basicInfoTitle',
  CancelledButtonCopy: 'cancelledButtonCopy',
  CancelledSubtitle: 'cancelledSubtitle',
  CancelledTitle: 'cancelledTitle',
  ConfirmPageHeader: 'confirmPageHeader',
  ContinueActivationText: 'continueActivationText',
  ContinueLabel: 'continueLabel',
  CreateAccountTitle: 'createAccountTitle',
  DeclineAgeConfirmation: 'declineAgeConfirmation',
  DifferentAccountLabel: 'differentAccountLabel',
  DiffMembership: 'diffMembership',
  EmailFieldRequired: 'emailFieldRequired',
  EmailLabel: 'emailLabel',
  EmailValidationRegex: 'emailValidationRegex',
  EmptyBirthdayError: 'emptyBirthdayError',
  EnterAgeNow: 'enterAgeNow',
  EnterCodeInvalid: 'enterCodeInvalid',
  EnterCodeMismatch: 'enterCodeMismatch',
  EnterCodeOnDeviceSubtitle: 'enterCodeOnDeviceSubtitle',
  EnterCodeSubmit: 'enterCodeSubmit',
  EnterCodeSubtitle: 'enterCodeSubtitle',
  EnterCodeTitle: 'enterCodeTitle',
  EnterCodeToAddAccountSubtitle: 'enterCodeToAddAccountSubtitle',
  EnterEmail: 'enterEmail',
  ExistingUsernameError: 'existingUsernameError',
  ForgotPassword: 'forgotPassword',
  GeneralErrorMessage: 'generalErrorMessage',
  GetStartedLabel: 'getStartedLabel',
  GuideTabTitle: 'guideTabTitle',
  InvalidBirthday: 'invalidBirthday',
  InvalidPassword: 'invalidPassword',
  LocationHelptext: 'locationHelptext',
  LocationLabel: 'locationLabel',
  LocationSubtitle: 'locationSubtitle',
  LocationTitle: 'locationTitle',
  Login: 'login',
  LogInError: 'logInError',
  LogInLabel: 'logInLabel',
  MarketingPolicy: 'marketingPolicy',
  MaxCharacterError: 'maxCharacterError',
  MinCharacterError: 'minCharacterError',
  NoSpecialCharacters: 'noSpecialCharacters',
  OwnerCaps: 'ownerCaps',
  PasswordFieldRequired: 'passwordFieldRequired',
  PasswordHelpText: 'passwordHelpText',
  PasswordLabel: 'passwordLabel',
  PasswordMinimum: 'passwordMinimum',
  PleaseConfirmCode: 'pleaseConfirmCode',
  PrivacyPolicyInfo: 'privacyPolicyInfo',
  ReadAndAgreeToTerms: 'readAndAgreeToTerms',
  ReEnterCodeOnDeviceSubtitle: 'reEnterCodeOnDeviceSubtitle',
  ReEnterCodeSubtitle: 'reEnterCodeSubtitle',
  ReEnterCodeTitle: 'reEnterCodeTitle',
  RequiredUsernameError: 'requiredUsernameError',
  ResetPasswordModalTitle: 'resetPasswordModalTitle',
  SentInstructionsMessage: 'sentInstructionsMessage',
  SetUpProfileLabel: 'setUpProfileLabel',
  SkipLabel: 'skipLabel',
  SpecialCharacterError: 'specialCharacterError',
  SuccessOnDeviceSubtitle: 'successOnDeviceSubtitle',
  SuccessSubtitle: 'successSubtitle',
  SuccessTitle: 'successTitle',
  SwitchUsers: 'switchUsers',
  TimeoutBody: 'timeoutBody',
  TimeoutCancelLabel: 'timeoutCancelLabel',
  TimeoutTitle: 'timeoutTitle',
  UnderAgeError: 'underAgeError',
  UploadSubtitle: 'uploadSubtitle',
  UploadTitle: 'uploadTitle',
  UsernameSubtitle: 'usernameSubtitle',
  UsernameTitle: 'usernameTitle',
  WelcomePelotonDeviceTitle: 'welcomePelotonDeviceTitle',
  WelcomeSubtitle: 'welcomeSubtitle',
  WelcomeTitle: 'welcomeTitle',
} as const;
