import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type { PageNumber } from '@engage/pagination';
import { done } from '@engage/pagination';
import type { PendingResult } from '@engage/result';
import { error, isOk, ok, isPending, pending } from '@engage/result';
import type { Workout } from '@engage/workouts';

export const workoutHistoryReducer: Reducer<WorkoutHistoryState, WorkoutHistoryAction> = (
  state = pending,
  action,
) => {
  switch (action.type) {
    case WorkoutHistoryActionTypes.AddWorkouts: {
      const { nextPage, ids, pageCount } = action.payload;
      if (isPending(state) || nextPage === 1 || (nextPage === done && pageCount === 1)) {
        return ok({
          ...action.payload,
          nextPage: ok(nextPage),
        });
      } else if (isOk(state)) {
        return ok({
          ...action.payload,
          ids: [...state.value.ids, ...ids],
          nextPage: ok(nextPage),
        });
      }

      return state;
    }
    case WorkoutHistoryActionTypes.AddWorkoutsFailure:
      if (state === pending) {
        return error(undefined);
      } else if (isOk(state)) {
        if (typeof action.payload.pageNumber !== 'number') {
          throw new Error(
            'Page number must be provided if there is a failure loading next classes',
          );
        }
        return ok({
          ...state.value,
          nextPage: error({ page: action.payload.pageNumber }),
        });
      }
      return state;
    case WorkoutHistoryActionTypes.LoadingWorkouts:
      if (isOk(state)) {
        return ok({
          ...state.value,
          nextPage: pending,
        });
      }

      return pending;
    case WorkoutHistoryActionTypes.ResetWorkouts:
      if (action.payload.keepCache && isOk(state)) {
        return ok({
          ...state.value,
          nextPage: ok(0),
        });
      }
      return pending;

    default:
      return state;
  }
};

export enum WorkoutHistoryActionTypes {
  AddWorkouts = 'engage/workoutHistory/workouts/ADD',
  AddWorkoutsFailure = 'engage/workoutHistory/workouts/ADD_FAILURE',
  LoadingWorkouts = 'engage/workoutHistory/workouts/LOADING_NEXT_PAGE',
  ResetWorkouts = 'engage/workoutHistory/workouts/RESET',
  RequestNextWorkoutsPage = 'engage/workoutHistory/workouts/REQUEST_NEXT_PAGE',
}

export const addWorkouts = (
  workouts: Workout[],
  nextPage: PageNumber,
  entityCount: number,
  pageCount: number,
  isPrivate?: boolean,
) => ({
  type: WorkoutHistoryActionTypes.AddWorkouts,
  payload: { ids: workouts.map(w => w.id), nextPage, entityCount, pageCount, isPrivate },
});

export const addWorkoutsFailure = (pageNumber: number | undefined) => ({
  type: WorkoutHistoryActionTypes.AddWorkoutsFailure,
  payload: { pageNumber },
});

export const loadingWorkouts = () => ({
  type: WorkoutHistoryActionTypes.LoadingWorkouts,
});

type RequestNextWorkoutsInput = {
  workoutsPerPage?: number;
  fromDate?: string;
  toDate?: string;
};

export const requestNextWorkoutsPage = (
  userId: string,
  { workoutsPerPage, fromDate, toDate }: RequestNextWorkoutsInput,
) => ({
  type: WorkoutHistoryActionTypes.RequestNextWorkoutsPage,
  payload: { userId, workoutsPerPage, fromDate, toDate },
});

export const resetWorkouts = (keepCache: boolean = false) => ({
  type: WorkoutHistoryActionTypes.ResetWorkouts,
  payload: { keepCache },
});

export type RequestNextWorkoutsPageAction = TypedAction<
  ReturnType<typeof requestNextWorkoutsPage>,
  WorkoutHistoryActionTypes.RequestNextWorkoutsPage
>;

export type WorkoutHistoryAction =
  | TypedAction<ReturnType<typeof addWorkouts>, WorkoutHistoryActionTypes.AddWorkouts>
  | TypedAction<
      ReturnType<typeof addWorkoutsFailure>,
      WorkoutHistoryActionTypes.AddWorkoutsFailure
    >
  | TypedAction<
      ReturnType<typeof loadingWorkouts>,
      WorkoutHistoryActionTypes.LoadingWorkouts
    >
  | RequestNextWorkoutsPageAction
  | TypedAction<
      ReturnType<typeof resetWorkouts>,
      WorkoutHistoryActionTypes.ResetWorkouts
    >;

export type WorkoutHistoryState = PendingResult<
  {
    entityCount: number;
    ids: string[];
    nextPage: PendingResult<PageNumber, { page: number }>;
    pageCount: number;
    isPrivate?: boolean;
  },
  undefined
>;
