import React from 'react';
import styled, { css } from 'styled-components';
import { toClientDetailsHeader } from '@peloton/analytics';
import { media, defaultTransition } from '@peloton/styles';
import { FadeInOut } from '@engage/animations';
import { toCloudinaryUrl } from '@engage/cloudinary';
import { white, slate2, slate1, gray1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { spaces } from '@engage/styles';
import { label14, body16, truncate } from '@engage/typography';
import { hoverStyle } from '@members/animations';
import { DarkButton1Small, DarkButton3Small } from '@members/buttons';
import { CtaCopy, Cta, Tags, TagsCopy } from '@members/copy';
import type { Headers } from '@members/graphql';
import type { ReplaceTagMutation } from '@members/graphql-swr/schemas/tags/mutations/ReplaceTag.generated';
import { Cross } from '@members/icons';
import { useLocale } from '@members/localization';
import { useIsBreakpointLessThan } from '@members/responsive';
import { useAnalyticsHeadersFromReplace } from '../analytics';
import { HashTag } from '../HashTag';
import { useSelectedTagName } from '../hooks';
import { Replace } from '../icons';
import { APITypename } from '../models';
import { TagModalView } from '../shared';
import { ICON_SIZE } from '../Tag/Buttons';
import type { CTAProps } from '../Tag/CtaButton';
import { TagsContext } from '../TagsContext';
import { useReplaceTagMutationSWR } from './useReplaceTagMutationSWR';

type Props = Omit<
  CTAProps,
  'viewingOwnTag' | 'isPrimary' | 'isCreatingTag' | 'hasAdded'
> & {
  tagName: string;
  numberOfMembers: number;
  numberOfFollowing: number;
  backgroundImageUrl: string;
};

export const Tag: React.FC<React.PropsWithChildren<Props>> = ({
  tagName,
  backgroundImageUrl,
  numberOfMembers,
  numberOfFollowing,
}) => {
  const [showCancelConfirm, setCancelConfirm] = React.useState(false);

  return (
    <FadeInOutContainer
      bgImage={toCloudinaryUrl(backgroundImageUrl, { width: 560 })}
      data-test-id="replaceTagWrapper"
    >
      <FadeInOut
        isFirstShown={!showCancelConfirm}
        renderFirst={React.useCallback(
          () => (
            <PromptWrapper>
              <ReplacePrompt
                tagName={tagName}
                numberOfFollowing={numberOfFollowing}
                numberOfMembers={numberOfMembers}
                setCancelConfirm={setCancelConfirm}
              />
            </PromptWrapper>
          ),
          [showCancelConfirm],
        )}
        renderSecond={React.useCallback(
          () => (
            <ConfirmWrapper>
              <ReplaceConfirm tagName={tagName} setCancelConfirm={setCancelConfirm} />
            </ConfirmWrapper>
          ),
          [showCancelConfirm],
        )}
      />
    </FadeInOutContainer>
  );
};

const hoverStyleCss = css`
  ${hoverStyle}
  &::before {
    pointer-events: none;
    opacity: 0.05;
    z-index: 1;
    border-radius: ${spaces.xxSmall}px;
  }
`;

const FadeInOutContainer = styled.li<{ bgImage: string }>`
  position: relative;
  background: url(${p => p.bgImage}) center;
  background-color: ${slate2};
  background-size: cover;
  border-radius: ${spaces.xxSmall}px;
  position: relative;
  width: 100%;
  height: 110px;
  list-style-type: none;
  display: flex;
  align-items: center;

  & > div {
    height: 100%;
  }

  ${hoverStyleCss}
`;

export const onReplaceCompleted = (
  replaceTagResult: Pick<ReplaceTagMutation['replaceTag'], '__typename'>,
  setView: (view: TagModalView, tagName?: string) => void,
  tagNameToAdd: string,
  setShowErrorToast: (s: boolean) => void,
) => {
  if (replaceTagResult.__typename === APITypename.REPLACE_TAG_SUCCESS) {
    setView(TagModalView.TagDetails, tagNameToAdd);
  } else if (replaceTagResult.__typename === APITypename.TAG_NAME_PROHIBITED_ERROR) {
    setShowErrorToast(true);
  } else {
    setView(TagModalView.MyTags);
  }
};

export const ReplaceConfirm: React.FC<
  React.PropsWithChildren<
    Pick<Props, 'tagName'> & {
      setCancelConfirm(v: boolean): void;
    }
  >
> = ({ tagName, setCancelConfirm }) => {
  const { selectedTagName } = useSelectedTagName();
  const { setView, setShowErrorToast } = React.useContext(TagsContext);
  const isMobile = useIsBreakpointLessThan('tablet');
  const analyticsHeaderProps = useAnalyticsHeadersFromReplace();
  const { mutate: replaceTag } = useReplaceTagMutationSWR(
    ({ replaceTag: replaceTagResult }) =>
      onReplaceCompleted(replaceTagResult, setView, selectedTagName, setShowErrorToast),
    () => {},
    toClientDetailsHeader(analyticsHeaderProps) as Headers,
  );

  return (
    <>
      <ReplaceConfirmContainer data-test-id="replaceText">
        <TagsCopy id={Tags.ReplacePrompt} values={{ tagName }} />
      </ReplaceConfirmContainer>
      <CancelConfirmContainer>
        <ReplaceButton
          onClick={() =>
            replaceTag({
              input: { oldTagName: tagName, newTagName: selectedTagName },
            })
          }
          data-test-id="replaceButton"
        >
          <CtaCopy id={Cta.Replace} />
        </ReplaceButton>
        {isMobile ? (
          <MobileCancel
            onClick={() => {
              setCancelConfirm?.(false);
            }}
            data-test-id="mobileCancelReplaceButton"
          >
            <StyledCross />
          </MobileCancel>
        ) : (
          <CancelButton
            onClick={() => {
              setCancelConfirm?.(false);
            }}
            data-test-id="cancelReplaceButton"
          >
            <CtaCopy id={Cta.Cancel} />
          </CancelButton>
        )}
      </CancelConfirmContainer>
    </>
  );
};

export const ReplacePrompt: React.FC<
  React.PropsWithChildren<
    Pick<Props, 'tagName' | 'numberOfFollowing' | 'numberOfMembers'> & {
      setCancelConfirm(v: boolean): void;
    }
  >
> = ({ tagName, numberOfFollowing, numberOfMembers, setCancelConfirm }) => {
  const numMembers = numberOfMembers.toLocaleString(useLocale());
  const numFollowing = numberOfFollowing.toLocaleString(useLocale());

  return (
    <>
      <TextContainer>
        <HashTag tagName={tagName} />
        <InfoContainer>
          <NumbersContainer>
            <NumberMembersContainer>
              <TagsCopy id={Tags.Members} values={{ numberOfMembers: numMembers }} />
            </NumberMembersContainer>
            <If condition={numberOfFollowing > 0}>
              <NumberFollowingContainer>
                ·
                <FollowingText>
                  <TagsCopy
                    id={Tags.Following}
                    values={{ numberOfFollowing: numFollowing }}
                  />
                </FollowingText>
              </NumberFollowingContainer>
            </If>
          </NumbersContainer>
        </InfoContainer>
      </TextContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            setCancelConfirm?.(true);
          }}
          data-test-id="replaceTagCardButton"
        >
          <ReplaceIcon />
        </Button>
      </ButtonContainer>
    </>
  );
};

const CancelButton = styled(DarkButton3Small)`
  background-color: transparent;
  cursor: pointer;
  margin-left: ${spaces.small}px;
`;
CancelButton.displayName = 'CancelButton';

const StyledCross = styled(Cross)`
  fill: ${white};

  height: ${spaces.small}px;
  width: ${spaces.small}px;
`;

const MobileCancel = styled(DarkButton3Small)`
  border-radius: 50%;
  margin-left: ${spaces.xSmall}px;
  cursor: pointer;
  width: ${spaces.xxxLarge}px;
  height: ${spaces.xxxLarge}px;
  min-width: auto;
  padding: 0;

  &:hover > ${StyledCross} {
    fill: ${slate2};
  }
`;
MobileCancel.displayName = 'MobileCancel';

const ReplaceButton = styled(DarkButton1Small)`
  cursor: pointer;
`;

const CancelConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
`;

const ReplaceIcon = styled(Replace)`
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;

  rect {
    fill: ${gray1};
    fill-opacity: 1;
  }
  path {
    stroke: ${slate1};
  }

  ${defaultTransition('filter')}

  &:hover {
    filter: brightness(0.85);
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  top: -${spaces.xxxSmall}px;
`;

const Button = styled.button`
  cursor: pointer;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  z-index: 1;
`;

const NumberMembersContainer = styled.div`
  display: flex;
  margin-right: ${spaces.xxSmall}px;
`;

const NumberFollowingContainer = styled.div`
  display: flex;
`;

const NumbersContainer = styled.div`
  display: flex;
  ${label14}
  margin-bottom: ${spaces.xSmall}px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: ${spaces.xxSmall}px;

  ${media.tablet`
    flex-direction: row;
    margin-top: ${spaces.small}px;
  `}

  color: ${white};
`;

const FollowingText = styled.p`
  margin-left: ${spaces.xxSmall}px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const PromptWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-right: ${spaces.small}px;
  grid-gap: ${spaces.xSmall}px;
  height: 100%;
  align-items: center;

  color: ${white};
  padding: ${spaces.small}px;
  overflow: hidden;

  ${media.tablet`
    padding: ${spaces.xLarge + 2}px ${spaces.medium}px;
  `}
`;

const ConfirmWrapper = styled(PromptWrapper)`
  background-color: ${slate2};
  border-radius: ${spaces.xxSmall}px;
`;

const ReplaceConfirmContainer = styled.p`
  color: ${gray1};
  ${body16};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  overflow-wrap: break-word;
  ${truncate(3)};
`;
