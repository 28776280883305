// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const SkipLinks = {
  BackToCategories: 'backToCategories',
  BackToDay: 'backToDay',
  SkipToBrowse: 'skipToBrowse',
  SkipToCategories: 'skipToCategories',
  SkipToChallengesNav: 'skipToChallengesNav',
  SkipToClassSelection: 'skipToClassSelection',
  SkipToContent: 'skipToContent',
  SkipToDay: 'skipToDay',
  SkipToFeatured: 'skipToFeatured',
  SkipToFitnessCategories: 'skipToFitnessCategories',
  SkipToLive: 'skipToLive',
  SkipToUserNav: 'skipToUserNav',
} as const;
