import type {
  AddClassToStackMutation,
  AddClassToStackMutationVariables,
} from '@members/graphql-swr/schemas/stacked-classes/mutations/AddClassToStack.generated';
import { AddClassToStackDocument } from '@members/graphql-swr/schemas/stacked-classes/mutations/AddClassToStack.generated';
import type {
  ModifyStackMutation,
  ModifyStackMutationVariables,
} from '@members/graphql-swr/schemas/stacked-classes/mutations/ModifyStack.generated';
import { ModifyStackDocument } from '@members/graphql-swr/schemas/stacked-classes/mutations/ModifyStack.generated';
import type {
  PlayClassFromStackMutation,
  PlayClassFromStackMutationVariables,
} from '@members/graphql-swr/schemas/stacked-classes/mutations/PlayClassFromStack.generated';
import { PlayClassFromStackDocument } from '@members/graphql-swr/schemas/stacked-classes/mutations/PlayClassFromStack.generated';
import type {
  StartPelotonClassMutation,
  StartPelotonClassMutationVariables,
} from '@members/graphql-swr/schemas/stacked-classes/mutations/StartPelotonClass.generated';
import { StartPelotonClassDocument } from '@members/graphql-swr/schemas/stacked-classes/mutations/StartPelotonClass.generated';
import type {
  CheckDoesClassExistInUserStackQuery,
  CheckDoesClassExistInUserStackQueryVariables,
} from '@members/graphql-swr/schemas/stacked-classes/queries/CheckDoesClassExistInUserStack.generated';
import { CheckDoesClassExistInUserStackDocument } from '@members/graphql-swr/schemas/stacked-classes/queries/CheckDoesClassExistInUserStack.generated';
import type {
  JoinableClassQuery,
  JoinableClassQueryVariables,
} from '@members/graphql-swr/schemas/stacked-classes/queries/JoinableClass.generated';
import { JoinableClassDocument } from '@members/graphql-swr/schemas/stacked-classes/queries/JoinableClass.generated';
import type {
  ViewUserStackQuery,
  ViewUserStackQueryVariables,
} from '@members/graphql-swr/schemas/stacked-classes/queries/ViewUserStack.generated';
import { ViewUserStackDocument } from '@members/graphql-swr/schemas/stacked-classes/queries/ViewUserStack.generated';
import { graphQlApi } from '../singletons';
import { unwrap, getDocumentKey, graphQLClient, toMutate, toFetcherV2 } from './utils';
import type { Headers } from './utils';

export const toStackedClassesFetchersGQL = (api: typeof graphQlApi = graphQlApi) => ({
  CheckDoesClassExistInUserStackQuery: toFetcherV2(
    (queryVariables: CheckDoesClassExistInUserStackQueryVariables) =>
      queryVariables?.input
        ? getDocumentKey(CheckDoesClassExistInUserStackDocument, queryVariables)
        : null,
    (queryVariables: CheckDoesClassExistInUserStackQueryVariables) =>
      unwrap(() =>
        graphQLClient<
          CheckDoesClassExistInUserStackQuery,
          CheckDoesClassExistInUserStackQueryVariables
        >(graphQlApi, CheckDoesClassExistInUserStackDocument, queryVariables),
      ),
  ),
  CheckDoesClassExistInUserStackMutateQuery: toMutate(
    (queryVariables: CheckDoesClassExistInUserStackQueryVariables) =>
      getDocumentKey(CheckDoesClassExistInUserStackDocument, queryVariables),
    (queryVariables: CheckDoesClassExistInUserStackQueryVariables) =>
      unwrap(() =>
        graphQLClient<
          CheckDoesClassExistInUserStackQuery,
          CheckDoesClassExistInUserStackQueryVariables
        >(graphQlApi, CheckDoesClassExistInUserStackDocument, queryVariables),
      ),
  ),
  JoinableClassQuery: toFetcherV2(
    (queryVariables: JoinableClassQueryVariables) =>
      getDocumentKey(JoinableClassDocument, queryVariables),
    (queryVariables: JoinableClassQueryVariables) =>
      unwrap(() =>
        graphQLClient<JoinableClassQuery, JoinableClassQueryVariables>(
          graphQlApi,
          JoinableClassDocument,
          queryVariables,
        ),
      ),
  ),
  ViewUserStackQuery: toFetcherV2(
    (queryVariables: ViewUserStackQueryVariables) =>
      getDocumentKey(ViewUserStackDocument, queryVariables),
    (queryVariables: ViewUserStackQueryVariables) =>
      unwrap(() =>
        graphQLClient<ViewUserStackQuery, ViewUserStackQueryVariables>(
          graphQlApi,
          ViewUserStackDocument,
          queryVariables,
        ),
      ),
  ),
  AddClassToStackMutation: toMutate(
    (queryVariables: AddClassToStackMutationVariables) =>
      getDocumentKey(AddClassToStackDocument, queryVariables),
    (queryVariables: AddClassToStackMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<AddClassToStackMutation, AddClassToStackMutationVariables>(
          graphQlApi,
          AddClassToStackDocument,
          queryVariables,
          optional,
        ),
      ),
  ),
  ModifyStackMutation: toMutate(
    (queryVariables: ModifyStackMutationVariables) =>
      getDocumentKey(ModifyStackDocument, queryVariables),
    (queryVariables: ModifyStackMutationVariables) =>
      unwrap(() =>
        graphQLClient<ModifyStackMutation, ModifyStackMutationVariables>(
          graphQlApi,
          ModifyStackDocument,
          queryVariables,
        ),
      ),
  ),
  PlayClassFromStackMutation: toMutate(
    (queryVariables: PlayClassFromStackMutationVariables) =>
      getDocumentKey(PlayClassFromStackDocument, queryVariables),
    (queryVariables: PlayClassFromStackMutationVariables) =>
      unwrap(() =>
        graphQLClient<PlayClassFromStackMutation, PlayClassFromStackMutationVariables>(
          graphQlApi,
          PlayClassFromStackDocument,
          queryVariables,
        ),
      ),
  ),
  StartPelotonClassMutation: toMutate(
    (queryVariables: StartPelotonClassMutationVariables) =>
      getDocumentKey(StartPelotonClassDocument, queryVariables),
    (queryVariables: StartPelotonClassMutationVariables) =>
      unwrap(() =>
        graphQLClient<StartPelotonClassMutation, StartPelotonClassMutationVariables>(
          graphQlApi,
          StartPelotonClassDocument,
          queryVariables,
        ),
      ),
  ),
});
