import type { Reducer } from 'react';
import { isDefined } from '@peloton/types';
import { mergeEnabledFeatures } from '../mergeEnabledFeatures';
import type { Toggles } from '../models';
import type { Action } from './actions';
import { ActionType } from './actions';

export type State = Toggles;

export const toTogglesReducer = (
  features: (keyof State)[],
  mergeStrategy = mergeEnabledFeatures,
): Reducer<State, Action> => (state = toDefaults(features), action) => {
  switch (action.type) {
    case ActionType.MergeToggles:
      return mergeStrategy(state, action.payload);
    case ActionType.SetToggles: {
      const { toggle, value } = action.payload;
      const overridableToggle = state[toggle];

      if (!isDefined(overridableToggle) || overridableToggle.value === value) {
        return state;
      }

      return {
        ...state,
        ...{
          [toggle]: {
            ...overridableToggle,
            value,
          },
        },
      };
    }
    default:
      return state;
  }
};

// utils

const toDefaults = (features: string[]) =>
  features.reduce(
    (obj, f) => ({ ...obj, [f]: { value: false, originalValue: false } }),
    {},
  ) as State;
