import { toNowTime } from '@peloton/time';
import { getClass } from '@engage/classes';
import { getScheduledClass } from '@engage/scheduled-classes';
import type { VideoSelectorState } from './redux';
import { getTimeline, getCurrentTime } from './redux';
import type { VideoClassSelectorState } from './selectors';

const HOME_PELOTON_OFFSET_SEC = 50;

export const getVideoStartTime = (state: VideoTimeState, scheduledClassId: string) => {
  const scheduledClass = getScheduledClass(state, scheduledClassId);
  return scheduledClass?.startTime?.clone();
};

export const getClassStartOffset = (state: VideoTimeState, scheduledClassId: string) => {
  const videoStartTime = getVideoStartTime(state, scheduledClassId);
  const scheduledClass = getScheduledClass(state, scheduledClassId);
  const timeline = getTimeline(state);

  if (timeline?.classStartOffset) {
    return timeline.classStartOffset;
  } else if (videoStartTime) {
    return (
      scheduledClass &&
      scheduledClass.scheduledStartTime
        .clone()
        .add(HOME_PELOTON_OFFSET_SEC, 's')
        .diff(videoStartTime, 's')
    );
  }
  return undefined;
};

const getVideoEndOffset = (state: VideoSelectorState): number | undefined =>
  state.video.timeline?.videoEndOffset;

export const isLiveVideoEnded = (
  state: VideoTimeState,
  scheduledClassId: string,
): boolean => {
  const currentOffset = getLiveCurrentVideoOffset(state, scheduledClassId);
  const endOffset = getVideoEndOffset(state);
  return !!(currentOffset && endOffset && currentOffset >= endOffset);
};

export const isVideoEnded = (
  state: VideoTimeState,
  scheduledClassId: string,
  isLive: boolean,
): boolean => {
  const currentOffset = Math.ceil(getCurrentVideoOffset(state, scheduledClassId, isLive));
  const endOffset = getVideoEndOffset(state);
  return !!(currentOffset && endOffset && currentOffset >= endOffset);
};

export const getClassStartTime = (state: VideoTimeState, scheduledClassId: string) => {
  const videoStartTime = getVideoStartTime(state, scheduledClassId);
  if (videoStartTime === undefined) {
    return undefined;
  }
  return videoStartTime.add(getClassStartOffset(state, scheduledClassId), 's');
};

export const getClassEndOffset = (state: VideoTimeState, scheduledClassId: string) => {
  const classStartOffset = getClassStartOffset(state, scheduledClassId);
  if (classStartOffset === undefined) {
    return undefined;
  }
  const scheduledClass = getScheduledClass(state, scheduledClassId);
  const klass = scheduledClass && getClass(state, scheduledClass.classId);
  const timeline = getTimeline(state);
  if (timeline?.classEndOffset) {
    return timeline.classEndOffset;
  } else if (klass) {
    return classStartOffset + klass.duration;
  } else {
    return undefined;
  }
};

const getLiveCurrentVideoOffset = (
  state: VideoTimeState,
  scheduledClassId: string,
): number | undefined =>
  getVideoStartTime(state, scheduledClassId) &&
  toNowTime().diff(getVideoStartTime(state, scheduledClassId), 's');

// Defaults to 0 if unavailable
export const getCurrentVideoOffset = (
  state: VideoTimeState,
  scheduledClassId: string,
  isLiveClass: boolean,
) =>
  isLiveClass
    ? getLiveCurrentVideoOffset(state, scheduledClassId) ?? 0
    : getCurrentTime(state) ?? 0;

export const hasClassStarted = (
  state: VideoTimeState,
  scheduledClassId: string,
  isLiveClass: boolean,
) => {
  const currentTimeOffset = getCurrentVideoOffset(state, scheduledClassId, isLiveClass);
  const timeline = getTimeline(state);
  // DOES NOT RELY ON CLASS START OFFSET SELECTOR BECAUSE WE WANT TO KNOW THAT THE PROPERTY HAS ACTUALLY BEEN LOADED
  if (timeline?.classStartOffset) {
    return currentTimeOffset >= timeline.classStartOffset;
  } else {
    return false;
  }
};

export type VideoTimeState = VideoClassSelectorState;
