import { splitEvery, values } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { isHttpError, fromAxiosError } from '@peloton/api';
import { sendStatPackets } from '../api';
import type { Packet } from '../models';

export const API_PACKET_LENGTH_MAX = 200;

export const batchSendPackets = function* (
  client: Client,
  workoutId: string,
  packets: Packet[],
): SagaIterator {
  const uniquePackets = packets.reduce((acc, cur) => {
    acc[cur.secondsOffsetFromStart] = cur;
    return acc;
  }, {});

  const batchedPackets = splitEvery(API_PACKET_LENGTH_MAX, values(uniquePackets));
  let unprocessedPackets: Packet[] = [];
  try {
    for (const batch of batchedPackets) {
      unprocessedPackets = [
        ...unprocessedPackets,
        ...(yield call(sendPackets, client, workoutId, batch)),
      ];
    }
  } catch (_) {
    // Early-exit on HTTP status code failures
  }

  return unprocessedPackets;
};

const sendPackets = function* (client: Client, workoutId: string, packets: Packet[]) {
  try {
    yield call(sendStatPackets, client, workoutId, packets);
  } catch (e) {
    if (isHttpError(e) || isHttpError(fromAxiosError(e))) {
      throw e;
    } else {
      return packets;
    }
  }

  return [];
};
