import type { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { logError } from '@engage/error-reporting/logError';
import { fetchUserSettings, fetchCaptionsSettings } from '../api';
import type { SettingsRequestAction } from '../redux';
import { updateCaptionsSettings, setSettings } from '../redux';

export const loadCaptionsSettings = function* (
  client: Client,
  action: any,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  if (userId) {
    try {
      const settings = yield call(fetchCaptionsSettings, client, userId);
      yield put(updateCaptionsSettings(settings));
    } catch (e) {
      // TODO: error reporting
      logError(e, 'loadCaptionsSettings');
    }
  }
};

export default function* (
  client: Client,
  { presenter, toggle }: SettingsRequestAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  try {
    if (userId) {
      const displayLanguage = presenter.getDisplayLanguage(userId);
      const settings = yield call(fetchUserSettings, client, userId, toggle);
      yield call(presenter.displaySettings, {
        ...settings,
        displayLanguage,
      });
      yield put(
        setSettings({
          ...settings,
          displayLanguage,
        }),
      );
    } else {
      yield call(presenter.redirectToLoginPage);
    }
  } catch (e) {
    logError(e, 'SettingsRequestAction');
  }
}
