import { getSubscription, getDigitalSubscriptionId } from '@engage/api-v2';
import { error } from '@engage/result';
import { engageApi } from '@members/data-fetch';

export const getSubscriptionDetails = async (
  kind: string | undefined,
  subId: string | undefined,
) => {
  if (kind && subId) {
    if (kind === 'Digital' || kind === 'iOS') {
      const digitalDetails = await getDigitalSubscriptionId(engageApi, subId);
      return digitalDetails.data;
    }
    const subDetails = await getSubscription(engageApi, subId);
    return subDetails.data;
  }
  return error(undefined);
};
