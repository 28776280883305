import type { UserAchievements } from '@engage/api-v2-models';
import type { BaseAPI } from '../generated';
import { getUserAchievements as getUserAchievement } from '../generated';
import type { AxiosPromise } from '../generated/core/request';

export const getUserAchievementsExtended = (
  api: BaseAPI,
  userId: string,
  imageType?: 'light_image' | 'dark_image',
  acceptLanguage?: string,
  options: any = {},
): AxiosPromise<UserAchievements> =>
  getUserAchievement.apply(this, [api, userId, imageType, acceptLanguage, options]);
