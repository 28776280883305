import type { Reducer } from 'redux';
import type { ClassAnalyticsProperties } from '@engage/classes';
import type { ClassWorkout, DetailedWorkout, Workout } from '@engage/workouts';

const initialState = {
  classInfo: {},
  workoutInfo: {},
  workoutPercentComplete: 0,
};

export const earlyExitReducer: Reducer<typeof initialState> = (
  state = initialState,
  action: EarlyExitAction,
) => {
  switch (action.type) {
    case EarlyExitActionType.Open:
      return {
        classInfo: action.payload.classInfo ?? {},
        workoutInfo: action.payload.workoutInfo ?? {},
        workoutPercentComplete: action.payload.workoutPercentComplete,
      };
    case EarlyExitActionType.Clear:
      return initialState;
    default:
      return state;
  }
};

export const getClassInfo = ({ earlyExit }: EarlyExitSelectorState) =>
  earlyExit.classInfo;
export const getWorkoutInfo = ({ earlyExit }: EarlyExitSelectorState) =>
  earlyExit.workoutInfo;
export const getWorkoutComplete = ({ earlyExit }: EarlyExitSelectorState) =>
  earlyExit.workoutPercentComplete;

export const openEarlyExit = (
  classInfo: ClassAnalyticsProperties | undefined,
  workoutInfo: Workout | DetailedWorkout<ClassWorkout> | undefined,
  workoutPercentComplete: number,
) => ({
  type: EarlyExitActionType.Open,
  payload: { classInfo, workoutInfo, workoutPercentComplete },
});

export const clearEarlyExit = () => ({
  type: EarlyExitActionType.Clear,
});

export type EarlyExitAction = {
  type: EarlyExitActionType;
  payload: {
    classInfo: ClassAnalyticsProperties;
    workoutInfo: DetailedWorkout;
    workoutPercentComplete: number;
  };
};

export type EarlyExitSelectorState = {
  earlyExit: typeof initialState;
};

export enum EarlyExitActionType {
  Open = '@members/earlyExit/open',
  Clear = '@members/earlyExit/clear',
}
