import type { SagaIterator } from 'redux-saga';
import { select, spawn, call, takeEvery, take, put } from 'redux-saga/effects';
import type { TrackTraits, ScreenPropsGetters } from '@peloton/analytics';
import { track, getScreenPropsSaga } from '@peloton/analytics';
import { isUserSignedIn } from '@peloton/auth';
import type { track as trackAction } from '@engage/analytics';
import { TrackActionType } from '@engage/analytics';
import { logError } from '@engage/error-reporting/logError';
import type { Subscription, SubscriptionAnalyticsProps } from '@engage/subscriptions';
import {
  getPrimarySub,
  toSubscriptionAnalytics,
  SubscriptionsActionType,
  loadSubscriptions,
} from '@engage/subscriptions';
import type { LanguageAnalyticsProps } from './getLanguageAnalyticsPropsSaga';
import { getLanguageAnalyticsPropsSaga } from './getLanguageAnalyticsPropsSaga';

const trackWithBaseProps = function* (
  action: ReturnType<typeof trackAction>,
): SagaIterator {
  try {
    const screenProps = yield call(getScreenPropsSaga);
    let subProps = yield select(getPrimarySub);
    const isSignedIn = yield select(isUserSignedIn);
    if (!subProps && isSignedIn) {
      yield put(loadSubscriptions());
      yield take([
        SubscriptionsActionType.SubsRequestSuccess,
        SubscriptionsActionType.SubsRequestFailure,
      ]);
      subProps = yield select(getPrimarySub);
    }
    const languageProps = yield call(getLanguageAnalyticsPropsSaga);

    const eventWithBaseProps = toEventWithBaseProps(
      action.payload,
      screenProps,
      subProps,
      languageProps,
    );
    yield spawn(track, eventWithBaseProps);
  } catch (e) {
    logError(e, 'trackWithBaseProps');
  }
};

const toEventWithBaseProps = (
  event: TrackTraits<any>,
  screenProps?: ScreenPropsGetters,
  sub?: Subscription,
  languageProps?: LanguageAnalyticsProps,
): EventWithBaseProps => ({
  ...languageProps,
  ...(sub ? toSubscriptionAnalytics(sub) : {}),
  ...screenProps,
  ...event,
});

type EventWithBaseProps = TrackTraits<BaseProperties & Record<string, string>>;
type BaseProperties = ScreenPropsGetters &
  SubscriptionAnalyticsProps &
  LanguageAnalyticsProps;

export default function* () {
  yield takeEvery(TrackActionType, trackWithBaseProps);
}
