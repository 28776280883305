import React from 'react';

const LogOut: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#222529"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H13.5C13.7761 0 14 0.223858 14 0.5V6C14 6.27614 13.7761 6.5 13.5 6.5C13.2239 6.5 13 6.27614 13 6V1H2.38278L7.74807 4.06588C7.90386 4.1549 8 4.32057 8 4.5V15.5H13V11C13 10.7239 13.2239 10.5 13.5 10.5C13.7761 10.5 14 10.7239 14 11V16C14 16.2761 13.7761 16.5 13.5 16.5H8V19.5C8 19.6782 7.90512 19.843 7.75096 19.9325C7.59681 20.0219 7.40668 20.0226 7.25193 19.9341L0.251931 15.9341C0.096143 15.8451 0 15.6794 0 15.5V0.5ZM7 16V4.79016L1 1.36159V15.2098L7 18.6384V16ZM17.8536 6.14645C17.6583 5.95118 17.3417 5.95118 17.1464 6.14645C16.9512 6.34171 16.9512 6.65829 17.1464 6.85355L18.2929 8H11.5C11.2239 8 11 8.22386 11 8.5C11 8.77614 11.2239 9 11.5 9H18.2929L17.1464 10.1464C16.9512 10.3417 16.9512 10.6583 17.1464 10.8536C17.3417 11.0488 17.6583 11.0488 17.8536 10.8536L19.8536 8.85355C19.9015 8.80562 19.9377 8.75036 19.9621 8.69139C19.9842 8.63777 19.9967 8.58106 19.9994 8.52398C19.9998 8.516 20 8.508 20 8.5C20 8.43221 19.9865 8.36756 19.9621 8.30861C19.9377 8.24964 19.9015 8.19438 19.8536 8.14645L17.8536 6.14645Z"
    />
  </svg>
);

export { LogOut };
