import type { Location } from 'history';
import type { ClassAnalyticsProperties } from '@engage/classes';
import { toClassAnalytics } from '@engage/classes';
import type { Member } from '@engage/members';
import { toSocialAnalytics } from '@engage/members';
import type { DetailedWorkout } from '@engage/workouts';
import { toAnalyticsWorkoutProperties } from '@engage/workouts';
import { toBaseProperties, toSource } from '@members/analytics';
import type { TagsWorkoutAnalytics } from '@members/pg-video/useTagsAnalytics';

export const trackViewedWorkoutDetailsPage = ({
  workout,
  location,
  workoutUser,
  classInfo,
  tagsAnalyticsProps,
}: {
  workout: DetailedWorkout;
  location: Location;
  workoutUser: Member;
  classInfo: ClassAnalyticsProperties | undefined;
  tagsAnalyticsProps?: TagsWorkoutAnalytics;
}) => ({
  event: 'Viewed Workout Details',
  ...toAnalyticsWorkoutProperties(workout),
  ...toSocialAnalytics(workoutUser, tagsAnalyticsProps?.sharedLeaderboardTag),
  ...toClassAnalytics(classInfo),
  ...tagsAnalyticsProps,
  source: toSource(location),
  '[User ID Viewed]': workoutUser.id,
  usernameViewed: workoutUser.username,
});

export const trackShareWorkout = (
  workout: DetailedWorkout,
  classInfo: ClassAnalyticsProperties,
  location: Location,
  tagsAnalyticsProps?: TagsWorkoutAnalytics,
) => {
  return {
    event: 'Shared Workout',
    Destination: 'Facebook',
    source: toSource(location),
    ...toAnalyticsWorkoutProperties(workout),
    ...toClassAnalytics(classInfo),
    ...tagsAnalyticsProps,
  };
};

export const trackViewedWorkoutHistoryPage = (
  member: Member,
  location: Location,
  totalWorkouts: number,
  sharedLeaderboardTag?: string,
) => ({
  event: 'Viewed Workout List',
  ...toSocialAnalytics(member, sharedLeaderboardTag),
  source: toSource(location),
  totalWorkouts,
  '[User ID Viewed]': member.id,
  usernameViewed: member.username,
});

export const trackScrolledWorkoutDetails = (
  classInfo: ClassAnalyticsProperties,
  workout: DetailedWorkout,
  location: Location,
  relationship: string | undefined,
  userIDViewed: string | undefined,
  usernameViewed: string | undefined,
) => ({
  event: 'Scrolled Workout Details',
  ...toBaseProperties(),
  ...toClassAnalytics(classInfo),
  ...toAnalyticsWorkoutProperties(workout),
  source: toSource(location),
  relationship,
  '[User ID Viewed]': userIDViewed,
  usernameViewed,
});

export const trackScrolledProfileOverview = (
  location: Location,
  relationship: string | undefined,
  userIDViewed: string | undefined,
  usernameViewed: string | undefined,
) => ({
  event: 'Scrolled Profile Overview',
  ...toBaseProperties(),
  source: toSource(location),
  relationship,
  '[User ID Viewed]': userIDViewed,
  usernameViewed,
});
