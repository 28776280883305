import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { PROGRAMS_ROUTE } from './urls';

const Routes = [
  <Route
    key={PROGRAMS_ROUTE}
    path={PROGRAMS_ROUTE}
    component={lazyLoader(
      () => import('./ProgramsRoutes' /* webpackChunkName: "ProgramsRoutes" */),
    )}
  />,
];

export default Routes;
