import { parse, print, visit, Kind } from 'graphql';
import type { DocumentNode, OperationDefinitionNode } from 'graphql';
import type { Fetcher, SWRConfiguration, Key } from 'swr/dist/types';
import type { Unpacked } from '@peloton/types';
import type { BaseAPI } from '@engage/api-v2';
import type { AxiosPromise } from '@engage/api-v2/generated/core/request';

export type Headers = { headers: Record<string, string> };

const TYPENAME_FIELD = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: '__typename',
  },
};

const isField = (selection: any) => {
  return selection.kind === 'Field';
};

const addTypenameToDocument = (doc: any) => {
  return visit(doc, {
    SelectionSet: {
      enter: function (node, _key, parent: any) {
        if (parent && parent.kind === Kind.OPERATION_DEFINITION) {
          return;
        }
        const selections = node.selections;
        if (!selections) {
          return;
        }
        const skip = selections.some(function (selection: any) {
          return (
            isField(selection) &&
            (selection.name.value === '__typename' ||
              selection.name.value.lastIndexOf('__', 0) === 0)
          );
        });
        if (skip) {
          return;
        }
        const field = parent;
        if (
          isField(field) &&
          field.directives &&
          field.directives.some(function (d: any) {
            return d.name.value === 'export';
          })
        ) {
          return;
        }
        return {
          ...node,
          selections: [...selections, TYPENAME_FIELD],
        };
      },
    },
  });
};

const extractOperationName = (document: DocumentNode): string | undefined => {
  let operationName: string | undefined;

  const operationDefinitions = document.definitions.filter(
    definition => definition.kind === `OperationDefinition`,
  ) as OperationDefinitionNode[];

  if (operationDefinitions.length === 1) {
    operationName = operationDefinitions[0]?.name?.value;
  }

  return operationName;
};

export const resolveRequestDocument = (
  incomingDocument: DocumentNode | string,
): { query: string; operationName?: string } => {
  const document = addTypenameToDocument(incomingDocument);
  if (typeof document === `string`) {
    let operationName: string | undefined;

    try {
      const parsedDocument = parse(document);
      operationName = extractOperationName(parsedDocument) ?? '';
    } catch (err) {
      // Failed parsing the document, the operationName will be undefined
    }

    return { query: document, operationName };
  }

  const operationName = extractOperationName(document);
  return { query: print(document), operationName };
};

const getOperationName = (document: DocumentNode) => {
  const operationName = (document.definitions[0] as OperationDefinitionNode)?.name?.value;
  const actionType = (document.definitions[0] as OperationDefinitionNode)?.operation;
  return { operationName, actionType };
};

export const getDocumentKey = (document: DocumentNode, variables: any) => {
  const { operationName, actionType } = getOperationName(document);
  return `${operationName} ${actionType} ${JSON.stringify(variables)}`;
};

export const graphQLClient = <R, V>(
  api: BaseAPI,
  document: DocumentNode,
  queryVariables: V,
  optional: Headers = { headers: {} },
): AxiosPromise<{ data: R }> => {
  const { operationName, actionType } = getOperationName(document);

  return api.axios.post(
    '',
    {
      ...resolveRequestDocument(document),
      variables: queryVariables,
    },
    { params: { [actionType]: operationName }, ...optional },
  );
};

export const unwrap = <T>(req: () => AxiosPromise<{ data: T }>) => async () =>
  (await req()).data?.data;

// toMutate is required to conform to the types for global 'mutate' function
// exposed by swr.
export const toMutate = <
  QueryParams extends object,
  OptionalParams extends object,
  K extends Key,
  Z
>(
  toKey: (r: QueryParams, o: Partial<OptionalParams>) => K,
  toMutateFn: (r: QueryParams, o: Partial<OptionalParams>) => Z,
) => {
  return (req: QueryParams, opt: Partial<OptionalParams> = {}): Readonly<[K, Z]> => {
    return [toKey(req, opt), toMutateFn(req, opt)] as const;
  };
};

export const toFetcherV2 = <
  RequiredParams extends object,
  OptionalParams extends object,
  K extends Key,
  Data extends any,
  Z extends NonNullable<Fetcher<Data>>
>(
  toKey: (r: RequiredParams, o: Partial<OptionalParams>) => K,
  toFetcherFn: (r: RequiredParams, o: Partial<OptionalParams>) => Z,
  config?: SWRConfiguration<Unpacked<ReturnType<Z>>, any, Z>,
) => {
  return (
    req: RequiredParams,
    opt: Partial<OptionalParams> = {},
    c?: typeof config,
  ): Readonly<[K, Z, typeof config]> => {
    const mergedConfig = { ...config, ...c };
    return [toKey(req, opt), toFetcherFn(req, opt), mergedConfig] as const;
  };
};
