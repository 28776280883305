import { stringify } from 'querystring';
import { createBrowserHistory } from 'history';
import { parseSearch } from '@peloton/redux';

const browserHistory = typeof window === 'undefined' ? undefined : createBrowserHistory();

const toPath = (location: Location, params: Record<string, string>): string => {
  const url = new URL(location.href);
  url.search = stringify(params);
  return url.toString().replace(new RegExp(`^${location.origin}`), '');
};

/**
 * This is evaluated on code load because multiple providers (e.g. `GraphqlProvider`, `OauthProvider`) evaluate API
 * overrides on page load. And each time `fromQueryParam` runs, the `key` is removed from the URL search params. So when
 * the second provider runs, the value for `key` is missing.
 *
 * Storing this on code load ensures each provider will get the same value for `key` whilst keeping URL search params in
 * sync with what's been evaluated already.
 */
const staticParams = typeof window === 'undefined' ? {} : parseSearch(window.location);

/**a
 * Removes detected keys from search params of `location` and updates browser history accordingly.
 * @param key The search param key to remove
 * @param location (for tests only) window.location
 * @param history (for tests only) for managing browser history
 * @returns value of key in search params
 */
export const fromQueryParam = (
  key: string,
  // Parameters for dependency injection in jest. Use defaults in implementation.
  location?: any,
  history = browserHistory,
  params = staticParams,
): string | null => {
  const value = params[key];

  if (Boolean(value)) {
    delete params[key];

    /**
     * Using `history.push({ search: stringify(params) })` has a bug where if the pathname in
     * `window.location` has changed since page load (e.g. in the case of redirecting to 404),
     * then `history.push` will use the old pathname (e.g. `/wrongPath`) instead of `/404`.
     *
     * We work around this by including the latest pathname and passing that into `history.push`.
     *
     * `history.push` is preferred over `history.replace` so the user can follow which query params were removed via the
     * search bar / browser history.
     */
    history?.push(toPath(location ?? window.location, params));

    return value;
  }

  return null;
};
