import { stringify } from 'querystring';
import { isDefined } from '@peloton/types';
import {
  TAGS_MODAL_NAME,
  TAGS_VIEW_PARAM,
  TAG_NAME_PARAM,
  TAG_BROWSE_CATEGORY_PARAM,
} from '@engage/tags';
import type { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import { toModalOpenRecord } from '@members/modal';
import { TagModalView } from './shared';

export const toTagsModalQuery = (
  view: TagModalView,
  tagName?: string,
  browseCategory?: TagBrowseCategorySlug,
) => {
  const queryParams = {
    ...toModalOpenRecord(TAGS_MODAL_NAME),
    [TAGS_VIEW_PARAM]: view,
    ...(isDefined(browseCategory) && view === TagModalView.BrowseTags
      ? { [TAG_BROWSE_CATEGORY_PARAM]: browseCategory }
      : {}),
    ...(isDefined(tagName) && view === TagModalView.TagDetails
      ? { [TAG_NAME_PARAM]: tagName }
      : {}),
  };
  return `?${stringify(queryParams)}`;
};
