import React from 'react';

const Programs: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.5C7 2.22386 7.22386 2 7.5 2H16.5C16.7761 2 17 2.22386 17 2.5C17 2.77614 16.7761 3 16.5 3H7.5C7.22386 3 7 2.77614 7 2.5ZM5 5.5C5 5.22386 5.22386 5 5.5 5H18.5C18.7761 5 19 5.22386 19 5.5C19 5.77614 18.7761 6 18.5 6H5.5C5.22386 6 5 5.77614 5 5.5ZM4.5 8C3.11929 8 2 9.11929 2 10.5V19.5C2 20.8807 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V10.5C22 9.11929 20.8807 8 19.5 8H4.5ZM3 10.5C3 9.67157 3.67157 9 4.5 9H19.5C20.3284 9 21 9.67157 21 10.5V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V10.5Z"
    />
  </svg>
);

export { Programs };
