import { getBrowseCategories } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type Params = {
  acceptLanguage?: string;
  pelotonPlatform?: string;
};

export const toBrowseCategoryFetchers = (api: typeof engageApi = engageApi) => ({
  BrowseCategories: toFetcher(
    () => `BrowseCategories`,
    (params: Params) =>
      unwrap(() =>
        getBrowseCategories(api, 'on_demand', params.acceptLanguage, {
          headers: { 'Peloton-Platform': params.pelotonPlatform },
        }),
      ),
  ),
});
