import { Language } from '@peloton/internationalize';
import { Settings, useSettings as toSettingsCopy } from '@members/copy';
import { isUatEnv, isLocalEnv } from '@members/env';

const DISPLAY_KEYS_PROD = [Language.English, Language.German, Language.Spanish];
const DISPLAY_KEYS_DEV = [...DISPLAY_KEYS_PROD, Language.PseudoLocalization];

export const toOptions = (enableFrenchCALanguage: boolean = false) => {
  const options = isUatEnv() || isLocalEnv() ? DISPLAY_KEYS_DEV : DISPLAY_KEYS_PROD;

  return options
    .concat(enableFrenchCALanguage ? [Language.French] : [])
    .map(toLanguageOption);
};

const toLanguageOption = (language: Language) => ({
  label: toSettingsCopy(toLabelId(language)) as any,
  labelId: toLabelId(language) as any,
  value: language,
});

export const toLabelId = (language: Language) => {
  switch (language) {
    case Language.German:
      return Settings.DisplayLanguageGerman;
    case Language.Spanish:
      return Settings.DisplayLanguageSpanishMexico;
    case Language.French:
      return Settings.DisplayLanguageCanadianFrench;
    case Language.PseudoLocalization:
      return Settings.PseudoLocalization;
    default:
      return Settings.DisplayLanguageEnglish;
  }
};
