import type { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { logError } from '@engage/error-reporting/logError';
import { updateCaptionsSettings as apiUpdate } from '../api';
import type { UpdateCaptionsSettingsAction } from '../redux';
import { updateCaptionsSettings } from '../redux';

export default function* (
  client: Client,
  action: UpdateCaptionsSettingsAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  if (userId) {
    try {
      const settings = yield call(apiUpdate, client, userId, action.payload);
      yield put(updateCaptionsSettings(settings));
    } catch (e) {
      logError(e, 'UpdateCaptionsSettings');
    }
  }
}
