import React from 'react';
import useSWR, { mutate as swrMutate } from 'swr';
import { isDefined } from '@peloton/types';
import { TagsFetchersGQL } from '@members/data-fetch';
import type { Headers } from '@members/data-fetch/graphql-fetchers/utils';
import type {
  AddTagToUserMutation,
  AddTagToUserMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/AddTagToUser.generated';
import type {
  RemoveTagFromUserMutation,
  RemoveTagFromUserMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/RemoveTagFromUser.generated';
import { useTagDetailsPaginatedQuery, useTagNameFromLocation } from '../hooks';
import { APITypename } from '../models';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';

export const useAddTagToUserMutationSWR = (
  tagName: string,
  onComplete: (data: AddTagToUserMutation) => void,
  refetchQueries: () => void,
  headers?: Headers,
) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const wrappedMutate = (input?: AddTagToUserMutationVariables) => {
    setIsLoading(true);
    return swrMutate(
      ...TagsFetchersGQL.AddTagToUserMutation(input ?? { input: { tagName } }, headers),
    )
      .then(data => {
        if (data) {
          onComplete(data);
        }
      })
      .finally(() => {
        refetchQueries();
        setIsLoading(false);
      });
  };

  return { isLoading, mutate: wrappedMutate };
};

export const useRemoveTagFromUserMutationSWR = (
  tagName: string,
  refetchQueries: () => void,
  onComplete?: (data: RemoveTagFromUserMutation) => void,
  headers?: Headers,
) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const wrappedMutate = (input?: RemoveTagFromUserMutationVariables) => {
    setIsLoading(true);
    return swrMutate(
      ...TagsFetchersGQL.RemoveTagFromUserMutation(
        input ?? { input: { tagName } },
        headers,
      ),
    )
      .then(data => {
        if (data && isDefined(onComplete)) {
          onComplete(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
        refetchQueries();
      });
  };

  return { isLoading, mutate: wrappedMutate };
};

export const useOnAddTagToUserCompleted = () => {
  const { setView, setShowErrorToast } = React.useContext(TagsContext);

  return React.useCallback((data: AddTagToUserMutation) => {
    if (data.addTagToUser.__typename === APITypename.TAG_NAME_PROHIBITED_ERROR) {
      setShowErrorToast(true);
    } else if (
      data.addTagToUser.__typename === APITypename.TAG_CAPACITY_OVER_LIMIT_ERROR
    ) {
      setView(TagModalView.ReplaceTag);
    }
  }, []);
};

export const useIsAdded = (isAdded: boolean) => {
  const tagName = useTagNameFromLocation();
  const onAddTagToUserCompleted = useOnAddTagToUserCompleted();

  const { mutate: mutateTagDetails } = useTagDetailsPaginatedQuery({ tagName });
  const { mutate: mutatePrimaryTag } = useSWR(
    ...TagsFetchersGQL.PrimaryTagQuery({ userId: undefined }),
  );

  const {
    mutate: addTagToUserMutation,
    isLoading: addTagToUserLoading,
  } = useAddTagToUserMutationSWR(
    tagName,
    (data: AddTagToUserMutation) => onAddTagToUserCompleted(data),
    () => {
      mutateTagDetails();
      mutatePrimaryTag();
    },
  );

  const {
    mutate: removeTagFromUserMutation,
    isLoading: removeTagFromUserLoading,
  } = useRemoveTagFromUserMutationSWR(tagName, () => {
    mutateTagDetails();
    mutatePrimaryTag();
  });

  const loading = isAdded ? removeTagFromUserLoading : addTagToUserLoading;
  const mutation = React.useCallback(
    () => (isAdded ? removeTagFromUserMutation() : addTagToUserMutation()),
    [isAdded],
  );

  return {
    loading,
    mutation,
  };
};
