import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { updateClasses as addClassesToStore } from '@engage/classes';
import { logError } from '@engage/error-reporting/logError';
import { updateWorkouts as addWorkoutToStore } from '@engage/workouts';
import type { WorkoutHistoryData } from './api';
import { fetchWorkouts } from './api';
import type { RequestNextWorkoutsPageAction } from './redux';
import {
  WorkoutHistoryActionTypes,
  addWorkouts as addWorkoutsToWorkoutHistory,
  addWorkoutsFailure,
  loadingWorkouts,
} from './redux';
import { getNextPageToLoad } from './selectors';

export function* loadWorkoutNextHistoryPage(
  client: Client,
  action: RequestNextWorkoutsPageAction,
) {
  // TODO: this should all be in a race with a loadNewClassesActions
  const nextPage: ReturnType<typeof getNextPageToLoad> = yield select(getNextPageToLoad);

  try {
    if (typeof nextPage !== 'number') {
      throw new Error('must have a page to pass to api'); // This should not happen
    }

    yield put(loadingWorkouts());

    const data: WorkoutHistoryData = yield call(
      fetchWorkouts,
      client,
      action.payload.userId,
      nextPage,
      action.payload.workoutsPerPage,
      action.payload.fromDate,
      action.payload.toDate,
    );

    yield put(addClassesToStore(data.classes));
    yield put(addWorkoutToStore(data.workouts));
    yield put(
      addWorkoutsToWorkoutHistory(
        data.workouts,
        data.nextPage,
        data.total,
        data.pageCount,
        data.isPrivate,
      ),
    );
  } catch (e) {
    yield put(addWorkoutsFailure(nextPage));
    logError(e, 'loadWorkoutNextHistoryPage');
  }
}

export const workoutHistorySaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeLatest(
    WorkoutHistoryActionTypes.RequestNextWorkoutsPage,
    loadWorkoutNextHistoryPage,
    client,
  );
};
