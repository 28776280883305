import type { AxiosPromise } from 'axios';
import { toNowTime } from '@peloton/time';
import {
  getClassDetails,
  getFeatureModules,
  getOnDemandClasses,
  getRideTimeline,
  getScheduledClasses,
} from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

/**
 * This is the time bound for fetching live classes. We are assuming the longest
 * possible class is 120 minutes.
 */
const MAX_COURSE_LENGTH = 120;

const toScheduleStartTime = () =>
  toNowTime().clone().subtract(MAX_COURSE_LENGTH, 'm').unix();
const toScheduleEndTime = () => toNowTime().clone().add(MAX_COURSE_LENGTH, 'm').unix();

type SearchClassParams = {
  acceptLanguage?: string;
  contentFormat?: string;
  classTypeId?: string;
  duration?: Parameters<typeof getOnDemandClasses>[6];
  fitnessDiscipline?: Parameters<typeof getOnDemandClasses>[7];
  browseCategory?: string;
  hasWorkout?: boolean;
  instructorId?: string;
  isFavoriteRide?: boolean;
  isFollowingUserRide?: boolean;
  language?: Parameters<typeof getOnDemandClasses>[14];
  rideTypeId?: string;
  superGenreId?: string;
  limit?: number;
  page?: number;
  hasCyclingArms?: boolean;
  sortBy?: Parameters<typeof getOnDemandClasses>[20];
  desc?: boolean;
  hasClosedCaptions?: boolean;
  captionLocales?: Parameters<typeof getOnDemandClasses>[23];
  classLanguages?: Parameters<typeof getOnDemandClasses>[24];
  ignoreClassLanguagePreferences?: boolean;
  hasFreeMode?: boolean;
};

const searchClassKeyPrefix = 'SearchClasses';

export const extractParamsFromSearchClassKey = (key: string) => {
  const paramsAsJSON = key.split(searchClassKeyPrefix)[1];

  try {
    const params = JSON.parse(paramsAsJSON);
    return params as SearchClassParams;
  } catch (e) {
    return {};
  }
};
export const classFetcherFeaturedClassesKey = 'FeaturedClasses';
type ClassByIdParams = { classId: string; shouldSkipFetch?: boolean };
export const toClassFetchers = (api: typeof engageApi = engageApi) => ({
  GetClassById: toFetcher(
    (params: ClassByIdParams) => `GetClass${params.classId}`,
    params =>
      unwrap(() =>
        !!params?.shouldSkipFetch
          ? (new Promise(() => null) as AxiosPromise)
          : getClassDetails(api, params.classId),
      ),
  ),
  GetClassDetails: toFetcher(
    (params: ClassByIdParams) => `GetClassDetails${params.classId}`,
    params => unwrap(() => getClassDetails(api, params.classId)),
  ),
  SearchClasses: toFetcher(
    (_: {}, params: SearchClassParams) =>
      `${searchClassKeyPrefix}${JSON.stringify(params)}`,
    (_, params) =>
      unwrap(() =>
        getOnDemandClasses(
          api,
          'web',
          params.acceptLanguage || 'en-us',
          params.classTypeId,
          'peloton',
          params.contentFormat,
          params.duration,
          params.fitnessDiscipline,
          params.browseCategory,
          params.hasWorkout,
          params.instructorId,
          params.isFavoriteRide,
          params.isFollowingUserRide,
          undefined,
          params.language,
          params.rideTypeId,
          params.superGenreId,
          params.limit,
          params.page,
          params.hasCyclingArms,
          params.sortBy,
          params.desc,
          params.hasClosedCaptions,
          params.captionLocales,
          params.classLanguages,
          params.ignoreClassLanguagePreferences,
          params.hasFreeMode,
          {
            query: {
              ['difficulty_level']: (params as any).difficultyLevel, // Temporary fix until API adds difficulty_level in swagger. https://pelotoncycle.atlassian.net/browse/API-7943
            },
          },
        ),
      ),
  ),
  GetTimelineByClassId: toFetcher(
    (params: { classId: string }) => `GetTimelineByClassId${params.classId}`,
    params => unwrap(() => getRideTimeline(api, params.classId)),
    {
      dedupingInterval: 30 * 1000, // 30 Seconds
    },
  ),
  GetFeaturedClasses: toFetcher(
    (_: {
      pelotonPlatform: Parameters<typeof getFeatureModules>[1];
      acceptLanguage?: string;
      pelotonClientDate?: string;
    }) => classFetcherFeaturedClassesKey,
    req =>
      unwrap(() =>
        getFeatureModules(
          api,
          req.pelotonPlatform,
          req.acceptLanguage,
          req.pelotonClientDate
            ? {
                headers: { 'Peloton-Client-Date': req.pelotonClientDate },
              }
            : undefined,
        ),
      ),
  ),
  // keeping this separate for WebTV to limit changes for potential bugs
  GetUpcomingLiveClassesWebTV: toFetcher(
    (_: { pelotonPlatform: Parameters<typeof getScheduledClasses>[1] }) =>
      'UpcomingLiveClasses',
    req =>
      unwrap(() =>
        getScheduledClasses(
          api,
          req.pelotonPlatform,
          undefined,
          toScheduleStartTime(),
          toScheduleEndTime(),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        ),
      ),
  ),
  GetUpcomingLiveClassesHome: toFetcher(
    (_: {
      start: Parameters<typeof getScheduledClasses>[3];
      limit?: Parameters<typeof getScheduledClasses>[10];
      excludeComplete?: Parameters<typeof getScheduledClasses>[11];
      excludeLiveInStudioOnly?: Parameters<typeof getScheduledClasses>[17];
    }) => 'UpcomingLiveClasses',
    req =>
      unwrap(() =>
        getScheduledClasses(
          api,
          'web',
          undefined,
          req.start,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          req.limit,
          req.excludeComplete,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          req.excludeLiveInStudioOnly,
        ),
      ),
  ),
  GetUpcomingLiveClassesSchedule: toFetcher(
    (params: {
      start: Parameters<typeof getScheduledClasses>[3];
      end?: Parameters<typeof getScheduledClasses>[4];
      excludeComplete: Parameters<typeof getScheduledClasses>[11];
      browseCategory?: Parameters<typeof getScheduledClasses>[16];
      excludeLiveInStudioOnly?: Parameters<typeof getScheduledClasses>[17];
      ignoreClassLanguagePreferences?: Parameters<typeof getScheduledClasses>[19];
    }) => `UpcomingLiveClassesSchedule-${params.browseCategory}`,
    req =>
      unwrap(() =>
        getScheduledClasses(
          api,
          'web',
          undefined,
          req.start,
          req.end,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          req.excludeComplete,
          undefined,
          undefined,
          undefined,
          undefined,
          req.browseCategory,
          req.excludeLiveInStudioOnly,
          undefined,
          req.ignoreClassLanguagePreferences,
        ),
      ),
  ),
});
