import type { SagaIterator } from 'redux-saga';
import { call, delay, fork, take, cancel, select, spawn, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { fetchClassTimeline } from '../api';
import type { Timeline } from '../models';
import { getTimeline, loadTimelineSuccess } from '../redux';
import { getInProgressWorkout } from '../selectors';

const EXCEEDED_END_OFFSET_POLL_MS = 1000;

export const pollVideoEndedSaga = function* (
  onVideoEndSaga: () => void,
  pollingDelay: number,
) {
  while (true) {
    yield call(onVideoEndSaga);
    yield delay(pollingDelay);
  }
};

export const pollVideoEndedSagaManager = function* (
  onVideoEndSaga: () => void,
  cancelActionType: string,
): SagaIterator {
  const pollTask = yield fork(
    pollVideoEndedSaga,
    onVideoEndSaga,
    EXCEEDED_END_OFFSET_POLL_MS,
  );
  yield take(cancelActionType);
  yield cancel(pollTask);
};

export const loadTimelineSaga = function* (
  client: Client,
  classId: string,
): SagaIterator {
  try {
    const klass = yield call(fetchClassTimeline, client, classId);
    yield put(loadTimelineSuccess(klass));
  } catch (e) {
    // Log
  }
};

export const pollTimelineSaga = function* (
  client: Client,
  classId: string,
  pollingDelay: number,
  cancelActionType: string,
  onVideoEndSaga?: () => void,
) {
  while (true) {
    try {
      yield call(loadTimelineSaga, client, classId);
      const { videoEndOffset }: Timeline = yield select(getTimeline);

      if (videoEndOffset) {
        if (onVideoEndSaga) {
          yield spawn(pollVideoEndedSagaManager, onVideoEndSaga, cancelActionType);
        }

        yield cancel();
      }
    } catch (e) {
      // swallow errors
    }

    yield delay(pollingDelay);
  }
};

export const pollTimelineSagaManager = function* (
  client: Client,
  pollingDelay: number,
  cancelActionType: string,
  onVideoEndSaga?: () => void,
): SagaIterator {
  const workout: ReturnType<typeof getInProgressWorkout> = yield select(
    getInProgressWorkout,
  );
  if (!workout) {
    return;
  }
  const pollTask = yield fork(
    pollTimelineSaga,
    client,
    workout.classId,
    pollingDelay,
    cancelActionType,
    onVideoEndSaga,
  );
  yield take(cancelActionType);
  yield cancel(pollTask);
};
