import type { match } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { ME } from '@engage/members/models';
import {
  toProfileWorkoutsPath,
  toMemberWorkoutsPath,
  PROFILE_WORKOUTS_ROUTE,
  MEMBER_WORKOUTS_ROUTE,
} from '@engage/overview';

export { PROFILE_WORKOUTS_ROUTE, MEMBER_WORKOUTS_ROUTE };

export const LEGACY_WORKOUTS_ROUTE = '/workouts/:workoutId?';
export const SHAREABLE_WORKOUTS_ROUTES = '/share/workouts/:shareWorkoutId';

export const toWorkoutDetailsPath = (isMe: boolean, workoutId: string, userId?: string) =>
  isMe
    ? toProfileWorkoutsPath({ workoutId })
    : toMemberWorkoutsPath({ userId, workoutId });

type ProfileUrlParams = { workoutId?: string };
type MemberUrlParams = { userId: string; workoutId?: string };
const matchWorkoutsPath = (
  path: string,
): match<ProfileUrlParams> | match<MemberUrlParams> | null =>
  matchPath<ProfileUrlParams>(path, { path: PROFILE_WORKOUTS_ROUTE }) ||
  matchPath<MemberUrlParams>(path, { path: MEMBER_WORKOUTS_ROUTE });

export const getViewedMemberIdFromPath = (path: string) => {
  const pathMatch = matchWorkoutsPath(path);
  if (pathMatch) {
    return (pathMatch.params as MemberUrlParams).userId ?? ME;
  } else {
    return undefined;
  }
};

export const isWorkoutsPath = (path: string) => matchWorkoutsPath(path) !== null;

export const isWorkoutHistoryPath = (path: string) => {
  const pathMatch = matchWorkoutsPath(path);
  return pathMatch !== null && !pathMatch.params.workoutId;
};
