import { datadogRum } from '@datadog/browser-rum';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import {
  call,
  getContext,
  takeEvery,
  select,
  take,
  put,
  throttle,
  spawn,
} from 'redux-saga/effects';
import { getScreenPropsSaga } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getLocation } from '@peloton/redux';
import { track } from '@engage/analytics';
import { getCast } from '@engage/cast/sender';
import { loadClassDetailSaga } from '@engage/class-detail';
import type { Peloton, ClassAnalyticsProperties } from '@engage/classes';
import { getClassAnalyticsProperties, fetchPeloton, getClass } from '@engage/classes';
import { getFeatureToggle } from '@engage/feature-toggles';
import { loadMember, ME } from '@engage/members';
import { toWorkoutHistoryPath } from '@engage/overview';
import { isOk } from '@engage/result';
import type { videoHeartbeat } from '@engage/video';
import {
  getWorkoutId,
  updateTimecode,
  getTimeline,
  updateVideoActiveView,
  VideoActiveViewState,
  getStreamHistoryId,
  endWorkoutSaga,
  deleteStreamSaga,
  liveStreamSaga,
  vodStreamSaga,
  VideoLoadActionType,
  heartbeatStreamSaga,
  clearWorkoutStream,
  getPelotonId,
  VideoActiveViewActionType,
  videoError,
  isLiveVideoEnded,
  isClassCompleted,
  getStartTime,
  getTimePlaying,
} from '@engage/video';
import { deleteWorkout, endWorkoutV2 } from '@engage/workouts';
import { toSource, toTotal, toRank, toFeatureModule } from '@members/analytics';
import { LIBRARY_CATEGORY_SELECTION_ROUTE } from '@members/pg-library/urls';
import {
  getProgramsVideoReturnLocation,
  getProgramInfo,
} from '@members/pg-programs/redux';
import { trackViewedPlayer } from '../analytics';
import { getHasWatchedLessThan3Minutes } from '../selectors/time';
import { getIsVideoPagePath } from '../urls';
import type { ContinuedWorkout } from './continueWorkout';
import { continueLiveWorkoutSaga, continueVODWorkoutSaga } from './continueWorkout';
import type {
  AnalyticsViewedAction,
  loadVodPage,
  FrequentTimecodeUpdateAction,
  loadLiveVideoPage,
  confirmExitVideo,
} from './sagaActions';
import { VideoSagaActionTypes, isVOD, leaveWorkout } from './sagaActions';

export const GENERIC_ERROR = 'There was a problem loading your class.';
export const TIMELINE_POLL_WAIT_IN_MS = 30000;
export const SCHEDULED_CLASS_POLL_WAIT_IN_MS = 20000;
export const WORKOUT_HISTORY_SOURCE = 'In-Class Workout Summary';

export const tryDisconnectCast = function* () {
  const result = getCast();
  if (isOk(result)) {
    const castState = result.value.framework.CastContext.getInstance().getCastState();
    if (castState === result.value.framework.CastState.CONNECTED) {
      result.value.framework.CastContext.getInstance().endCurrentSession(true);
      // waiting for JWPlayer to idle, which implies a cast disconnect
      yield take(VideoSagaActionTypes.JWPlayerIdled);
    }
  }
};

export const isTransitionAwayFromVideoPage = (
  currentPathname: string,
  nextPathname: string,
): unknown => getIsVideoPagePath(currentPathname) && !getIsVideoPagePath(nextPathname);

const exitBeforeMinimumWatchTimeSaga = function* (): SagaIterator {
  const programsPushLocation = yield select(getProgramsVideoReturnLocation);

  yield call(tryDisconnectCast);
  if (programsPushLocation) {
    yield put(push(programsPushLocation));
  } else {
    yield put(push(LIBRARY_CATEGORY_SELECTION_ROUTE));
  }
  yield put(loadMember(ME));
};

const exitBeforeActivityCompletionSaga = function* () {
  yield put(updateVideoActiveView(VideoActiveViewState.Exit));
};

const exitVideoSaga = function* (): unknown {
  if (yield select(getHasWatchedLessThan3Minutes)) {
    yield call(exitBeforeMinimumWatchTimeSaga);
  } else if (yield select(isClassCompleted)) {
    yield put(updateVideoActiveView(VideoActiveViewState.Complete));
  } else {
    yield call(exitBeforeActivityCompletionSaga);
  }
};

export const exitLiveVideoIfEndedSaga = function* (): SagaIterator {
  const pelotonId = yield select(getPelotonId);
  const isVideoEnded = yield select(isLiveVideoEnded, pelotonId);
  if (isVideoEnded) {
    if (yield select(getHasWatchedLessThan3Minutes)) {
      yield call(exitBeforeMinimumWatchTimeSaga);
    } else {
      yield put(updateVideoActiveView(VideoActiveViewState.Complete));
    }
  }
};

const completeWorkoutSaga = function* (
  client: Client,
  action: ReturnType<typeof confirmExitVideo>,
): SagaIterator {
  const streamHistoryId = yield select(getStreamHistoryId);
  const programsPushLocation = yield select(getProgramsVideoReturnLocation);

  yield put(leaveWorkout());
  yield call(tryDisconnectCast);
  if (programsPushLocation) {
    yield put(push(programsPushLocation));
  } else {
    yield put(updateVideoActiveView(VideoActiveViewState.Complete));
  }
  yield spawn(deleteStreamSaga, client, streamHistoryId);
};

const viewWorkoutHistorySaga = function* () {
  yield put(push(toWorkoutHistoryPath(), { source: WORKOUT_HISTORY_SOURCE }));
};

export const transitionAwayFromVideoPageSaga = function* (
  client: Client,
  action: any,
): SagaIterator {
  const location = yield select(getLocation);
  const locationChangeAction = yield take(LOCATION_CHANGE);
  if (
    isTransitionAwayFromVideoPage(
      location.pathname,
      locationChangeAction.payload.pathname,
    )
  ) {
    yield put(leaveWorkout());

    const workoutId = yield select(getWorkoutId);
    const streamHistoryId = yield select(getStreamHistoryId);
    const isLessThanMinWatchTime = yield select(getHasWatchedLessThan3Minutes);

    if (isLessThanMinWatchTime && !!workoutId) {
      // Delete workout and stream if user leaves video under 60 seconds from joining
      if (!!streamHistoryId) yield spawn(deleteStreamSaga, client, streamHistoryId);

      const startTime = yield select(getStartTime);
      const timePlaying = yield select(getTimePlaying);
      // Synchronous to pull updated workouts
      const screenProps = yield call(getScreenPropsSaga);
      datadogRum.addAction('pg-video > sagas > video | deleteWorkout', {
        workoutId: workoutId,
        startTime: startTime,
        timePlaying: timePlaying,
      });
      const isEndWorkoutV2Enabled = yield select(
        getFeatureToggle,
        'end_of_important_stuff',
      );

      if (isEndWorkoutV2Enabled) {
        // pass discard=true to v2 endpoint for workouts abandoned by user
        yield call(endWorkoutV2, client, workoutId, screenProps, true);
      } else {
        yield call(deleteWorkout, client, workoutId, screenProps);
      }

      yield put(loadMember(ME));
    }

    // Clear workout stream on all exits from video pages
    yield put(clearWorkoutStream());
  }
};

export const viewedPlayerSaga = function* (
  client: Client,
  action: ReturnType<AnalyticsViewedAction>,
): SagaIterator {
  let classId: string;

  if (isVOD(action)) {
    classId = action.payload.classId;
  } else {
    const peloton: Peloton = yield call(fetchPeloton, client, action.payload.pelotonId);
    classId = peloton.rideId;
  }

  yield call(loadClassDetailSaga, classId);
  const classInfo: ClassAnalyticsProperties | undefined = yield select(
    getClassAnalyticsProperties,
    classId,
  );
  yield put(track(trackViewedPlayer(classInfo, action.payload.viewEvent)));
};

const vodWithPersistSaga = function* (
  client: Client,
  action: ReturnType<typeof loadVodPage>,
): SagaIterator {
  const { workoutId, startTime }: ContinuedWorkout = yield call(
    continueVODWorkoutSaga,
    action.payload.classId,
  );
  const location = yield select(getLocation);
  const programInfo = yield select(getProgramInfo);
  const programProgressId = programInfo?.programProgressId;
  yield call(
    vodStreamSaga,
    client,
    action,
    startTime,
    VideoSagaActionTypes.LeaveWorkout,
    TIMELINE_POLL_WAIT_IN_MS,
    SCHEDULED_CLASS_POLL_WAIT_IN_MS,
    workoutId,
    programProgressId,
    toSource(location),
    toFeatureModule(location),
    toRank(location),
    toTotal(location),
    action.payload.requireVODAvailability,
    action.payload.searchSharedProperties,
  );
};

const liveWithPersistSaga = function* (
  client: Client,
  action: ReturnType<typeof loadLiveVideoPage>,
): SagaIterator {
  const { workoutId, startTime }: ContinuedWorkout = yield call(
    continueLiveWorkoutSaga,
    action.payload.pelotonId,
  );
  const location = yield select(getLocation);
  yield call(loadClassDetailSaga, action.payload.classId);
  const klass = yield select(getClass, action.payload.classId);
  const contentType = klass.kind === 'live' ? 'live' : 'encore';
  yield call(
    liveStreamSaga,
    client,
    action,
    startTime,
    VideoSagaActionTypes.LeaveWorkout,
    TIMELINE_POLL_WAIT_IN_MS,
    SCHEDULED_CLASS_POLL_WAIT_IN_MS,
    contentType,
    exitLiveVideoIfEndedSaga,
    workoutId,
    toSource(location),
  );
};

const putTimecode = function* (action: FrequentTimecodeUpdateAction): SagaIterator {
  const timeline = yield select(getTimeline);
  yield put(updateTimecode(action.payload.timecode, timeline));
};

export const endStreamAndWorkoutOnCompleteSaga = function* (
  client: Client,
  action: ReturnType<typeof updateVideoActiveView>,
): SagaIterator {
  if (
    action.payload.activeView === VideoActiveViewState.Complete &&
    !action.payload.isStackModalClosing
  ) {
    yield put(leaveWorkout());
    const streamHistoryId = yield select(getStreamHistoryId);
    const workoutId = yield select(getWorkoutId);
    yield spawn(endWorkoutSaga, client, workoutId);
    yield spawn(deleteStreamSaga, client, streamHistoryId);
  }
};

export const heartbeatSaga = function* (
  client: Client,
  action: ReturnType<typeof videoHeartbeat>,
) {
  try {
    yield call(heartbeatStreamSaga, client, action);
  } catch (e) {
    yield put(videoError({ message: GENERIC_ERROR }));
  }
};

export const videoPageSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(LOCATION_CHANGE, transitionAwayFromVideoPageSaga, client);
  yield takeEvery(VideoSagaActionTypes.Exit, exitVideoSaga);
  yield takeEvery(VideoSagaActionTypes.Viewed, viewedPlayerSaga, client);
  yield takeEvery(VideoSagaActionTypes.ConfirmExit, completeWorkoutSaga, client);
  yield takeEvery(VideoSagaActionTypes.ViewWorkoutHistory, viewWorkoutHistorySaga);
  yield takeEvery(VideoSagaActionTypes.LoadVOD, vodWithPersistSaga, client);
  yield takeEvery(VideoSagaActionTypes.LoadLive, liveWithPersistSaga, client);
  yield takeEvery(VideoLoadActionType.Heartbeat, heartbeatSaga, client);
  yield takeEvery(
    VideoActiveViewActionType.Update,
    endStreamAndWorkoutOnCompleteSaga,
    client,
  );
  yield throttle(1000, VideoSagaActionTypes.TimecodeUpdate, putTimecode);
};
