import type { AxiosPromise } from 'axios';
import { pagesApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

type Params = { acceptLanguage?: string };

export type GuidanceResponse = {
  survey: SurveyData[];
};

export type SurveyData = {
  label: null;
  numberOfGridColumns: number;
  options: OptionsData[];
  order: number;
  questionTemplate: string;
  questionType: string;
  slug: string;
  subLabel: null;
  subtitle: string;
  title: string;
};

export type OptionsData = {
  iconUrl: string;
  response: string;
  selected: boolean;
  slug: string;
  subtext: string;
  text: string;
};

function getGuidance(
  api: typeof pagesApi,
  acceptLanguage?: string,
): AxiosPromise<GuidanceResponse> {
  return api.axios.get(`/guidance/onboarding?survey_slug=new_app_user_onboarding`, {
    headers: { 'Accept-Language': acceptLanguage },
  });
}

export const toGuidanceFetchers = (api: typeof pagesApi = pagesApi) => ({
  Guidance: toFetcher(
    () => `Guidance`,
    (params: Params) => unwrap(() => getGuidance(api, params.acceptLanguage)),
  ),
});
