import React from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { TransitionState } from '@engage/animations';

type Props = {
  mainContent: (setIsIn: (b: boolean) => void) => JSX.Element;
  slideOverContent: (setIsIn: (b: boolean) => void) => JSX.Element;
  maxHeightCss: string;
};

export const ContentWithSlideover: React.FC<React.PropsWithChildren<Props>> = ({
  mainContent,
  slideOverContent,
  maxHeightCss,
}) => {
  const [isIn, setIsIn] = React.useState(false);
  const mainContentEl = React.useRef<HTMLDivElement>(null);
  const minHeight = mainContentEl.current && mainContentEl.current.scrollHeight;

  return (
    <Transition in={isIn} timeout={ANIMATION_DURATION_MS}>
      {(state: TransitionState) => (
        <>
          <MainContainer
            children={
              state === TransitionState.Entered ? (
                <PhantomContent maxHeightCss={maxHeightCss} minHeight={minHeight} />
              ) : (
                mainContent(setIsIn)
              )
            }
            ref={mainContentEl}
          />
          <SlideoverContainer
            isIn={isIn}
            maxHeightCss={maxHeightCss}
            children={state === TransitionState.Exited ? null : slideOverContent(setIsIn)}
          />
        </>
      )}
    </Transition>
  );
};

type Content = {
  maxHeightCss: string;
  minHeight: number | null;
};

const PhantomContent = styled.div<Content>`
  max-height: ${p => p.maxHeightCss};
  height: ${p => (p.minHeight ? `${p.minHeight}px` : p.maxHeightCss)};
`;

const ANIMATION_DURATION_MS = 300;

const MainContainer = styled.div`
  min-height: 100%;
  height: 100%; // Makes a difference when mainContent contains virtualized list
  display: flex;
  flex-direction: column;
`;

type Container = {
  isIn: boolean;
  maxHeightCss: string;
};

const SlideoverContainer = styled.div<Container>`
  position: absolute;
  transition: ${ANIMATION_DURATION_MS}ms ease;
  transform: translate(${p => (p.isIn ? 0 : 100)}%);
  z-index: 5;
  height: 100%;
  ${media.tablet`
    max-height: ${(p: Container) => p.maxHeightCss};
  `}
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
`;
