import React from 'react';
import { reportFocusMethod } from '@peloton/accessibility';
import * as Env from '@members/env';

reportFocusMethod(document);

const isLocalEnv = Env.isLocalEnv();
const isUatEnv = Env.isUatEnv();
// accessibility tool for non prod env
if (isLocalEnv || isUatEnv) {
  require.ensure(
    ['react-axe', 'react-dom'],
    require => {
      const axe = require('react-axe');
      const ReactDOM = require('react-dom');
      axe(React, ReactDOM, 1000);
    },
    _error => undefined,
    'vendors~react-axe',
  );
}
