import React from 'react';
import { CopyProvider, useCopyClient } from '@members/copy';
import { Oops } from '@members/errors';
import { GlobalStyles } from '@members/global-styles';

export const ErrorPage = () => {
  const copyClient = useCopyClient();
  return (
    <CopyProvider copyClient={copyClient}>
      <GlobalStyles />
      <Oops />
    </CopyProvider>
  );
};
