// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Site = {
  AccountCreated: 'accountCreated',
  CarouselDotLabel: 'carouselDotLabel',
  Copyright: 'copyright',
  CopyrightAU: 'copyrightAU',
  CopyrightCA: 'copyrightCA',
  CopyrightDE: 'copyrightDE',
  CopyrightGB: 'copyrightGB',
  Error: 'error',
  ErrorDetails: 'errorDetails',
  FieldRequiredError: 'fieldRequiredError',
  GoToHomepage: 'goToHomepage',
  HomeAriaLabel: 'homeAriaLabel',
  IeElevenDeprecation: 'ieElevenDeprecation',
  Instagram: 'instagram',
  Loading: 'loading',
  MaxLengthError: 'maxLengthError',
  MinLengthError: 'minLengthError',
  MoreNav: 'moreNav',
  NetworkError: 'networkError',
  OutageHeader: 'outageHeader',
  OutageStatus: 'outageStatus',
  OutageWarning: 'outageWarning',
  PageNotFound: 'pageNotFound',
  PageNotFoundDetails: 'pageNotFoundDetails',
  Peloton: 'peloton',
  PelotonLogoTitle: 'pelotonLogoTitle',
  SomethingWentWrong: 'somethingWentWrong',
  SupportEmail: 'supportEmail',
  Title: 'title',
  TryAgain: 'tryAgain',
  Twitter: 'twitter',
  UnknownError: 'unknownError',
  UserNav: 'userNav',
  Youtube: 'youtube',
} as const;
