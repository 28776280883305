import { propOr } from 'ramda';
import { Locale } from '@peloton/internationalize';
import enUS from './en-US';

const localizedStrings = {
  [Locale.EnglishUnitedStates]: enUS,
};

const getLocalizedStrings = (locale: Locale) => (
  propOr(localizedStrings[Locale.EnglishUnitedStates], locale, localizedStrings)
);

export { localizedStringsKeypaths } from './en-US';
export { getLocalizedStrings };
