import type { BaseAPI, AxiosPromise } from '@engage/api-v2/generated/core/request';
import { axiosBase } from '../axiosBase';

const path = '/started_client_session';

/**
 * GET send started client session client details to API for analytics
 * @param api base axios api
 * @returns void
 */
export const getStartedClientSession = (
  api: BaseAPI,
  pelotonClientDetails: Record<string, any>,
): AxiosPromise<void> => axiosBase({ api, path, method: 'GET', pelotonClientDetails });
