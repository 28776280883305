export enum TransitionState {
  Entering = 'entering',
  Entered = 'entered',
  Exiting = 'exiting',
  Exited = 'exited',
}

export const isFadeGone = (transitionState: TransitionState) =>
  transitionState === TransitionState.Exited;

export const isFadeShown = (transitionState: TransitionState) =>
  transitionState === TransitionState.Entered ||
  transitionState === TransitionState.Exiting;

export const isAnimating = (transitionState: TransitionState) =>
  transitionState === TransitionState.Entering ||
  transitionState === TransitionState.Exiting;

export const isEntering = (transitionState: TransitionState) =>
  transitionState === TransitionState.Entering;
