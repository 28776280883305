import type {
  AddTagToUserMutation,
  AddTagToUserMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/AddTagToUser.generated';
import { AddTagToUserDocument } from '@members/graphql-swr/schemas/tags/mutations/AddTagToUser.generated';
import type {
  RemoveTagFromUserMutation,
  RemoveTagFromUserMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/RemoveTagFromUser.generated';
import { RemoveTagFromUserDocument } from '@members/graphql-swr/schemas/tags/mutations/RemoveTagFromUser.generated';
import type {
  ReplaceTagMutation,
  ReplaceTagMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/ReplaceTag.generated';
import { ReplaceTagDocument } from '@members/graphql-swr/schemas/tags/mutations/ReplaceTag.generated';
import type {
  SetTagAsPrimaryMutation,
  SetTagAsPrimaryMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/SetTagAsPrimary.generated';
import { SetTagAsPrimaryDocument } from '@members/graphql-swr/schemas/tags/mutations/SetTagAsPrimary.generated';
import type {
  MyTagsQuery,
  MyTagsQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/MyTags.generated';
import { MyTagsDocument } from '@members/graphql-swr/schemas/tags/queries/MyTags.generated';
import type {
  PrimaryTagQuery,
  PrimaryTagQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/PrimaryTag.generated';
import { PrimaryTagDocument } from '@members/graphql-swr/schemas/tags/queries/PrimaryTag.generated';
import type {
  SearchTagQuery,
  SearchTagQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/SearchTag.generated';
import { SearchTagDocument } from '@members/graphql-swr/schemas/tags/queries/SearchTag.generated';
import type {
  TagBrowseCategoryQuery,
  TagBrowseCategoryQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/TagBrowseCategory.generated';
import { TagBrowseCategoryDocument } from '@members/graphql-swr/schemas/tags/queries/TagBrowseCategory.generated';

import type {
  TagBrowseCategoryDisplayNamesQuery,
  TagBrowseCategoryDisplayNamesQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/TagBrowseCategoryDisplayNames.generated';
import { TagBrowseCategoryDisplayNamesDocument } from '@members/graphql-swr/schemas/tags/queries/TagBrowseCategoryDisplayNames.generated';
import type {
  TagDetailQuery,
  TagDetailQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/TagDetail.generated';
import { TagDetailDocument } from '@members/graphql-swr/schemas/tags/queries/TagDetail.generated';
import type {
  UserIdQuery,
  UserIdQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/UserId.generated';
import { UserIdDocument } from '@members/graphql-swr/schemas/tags/queries/UserId.generated';
import type {
  UserTagsQuery,
  UserTagsQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/UserTags.generated';
import { UserTagsDocument } from '@members/graphql-swr/schemas/tags/queries/UserTags.generated';
import { graphQlApi } from '../singletons';
import { unwrap, getDocumentKey, graphQLClient, toMutate, toFetcherV2 } from './utils';
import type { Headers } from './utils';

export const toTagsFetchersGQL = (api: typeof graphQlApi = graphQlApi) => ({
  MyTagsQuery: toFetcherV2(
    (queryVariables: MyTagsQueryVariables) =>
      getDocumentKey(MyTagsDocument, queryVariables),
    (queryVariables: MyTagsQueryVariables) =>
      unwrap(() =>
        graphQLClient<MyTagsQuery, MyTagsQueryVariables>(
          graphQlApi,
          MyTagsDocument,
          queryVariables,
        ),
      ),
  ),
  PrimaryTagQuery: toFetcherV2(
    (queryVariables: PrimaryTagQueryVariables) =>
      getDocumentKey(PrimaryTagDocument, queryVariables),
    (queryVariables: PrimaryTagQueryVariables) =>
      unwrap(() =>
        graphQLClient<PrimaryTagQuery, PrimaryTagQueryVariables>(
          graphQlApi,
          PrimaryTagDocument,
          queryVariables,
        ),
      ),
  ),
  SearchTagQuery: toFetcherV2(
    (queryVariables: SearchTagQueryVariables) =>
      getDocumentKey(SearchTagDocument, queryVariables),
    (queryVariables: SearchTagQueryVariables) =>
      unwrap(() =>
        graphQLClient<SearchTagQuery, SearchTagQueryVariables>(
          graphQlApi,
          SearchTagDocument,
          queryVariables,
        ),
      ),
  ),
  TagBrowseCategoryQuery: toFetcherV2(
    (queryVariables: TagBrowseCategoryQueryVariables) =>
      getDocumentKey(TagBrowseCategoryDocument, queryVariables),
    (queryVariables: TagBrowseCategoryQueryVariables) =>
      unwrap(() =>
        graphQLClient<TagBrowseCategoryQuery, TagBrowseCategoryQueryVariables>(
          graphQlApi,
          TagBrowseCategoryDocument,
          queryVariables,
        ),
      ),
  ),
  TagBrowseCategoryDisplayNamesQuery: toFetcherV2(
    (queryVariables: TagBrowseCategoryDisplayNamesQueryVariables) =>
      getDocumentKey(TagBrowseCategoryDisplayNamesDocument, queryVariables),
    (queryVariables: TagBrowseCategoryDisplayNamesQueryVariables) =>
      unwrap(() =>
        graphQLClient<
          TagBrowseCategoryDisplayNamesQuery,
          TagBrowseCategoryDisplayNamesQueryVariables
        >(graphQlApi, TagBrowseCategoryDisplayNamesDocument, queryVariables),
      ),
  ),
  TagDetailQuery: toFetcherV2(
    (queryVariables: TagDetailQueryVariables) =>
      getDocumentKey(TagDetailDocument, queryVariables),
    (queryVariables: TagDetailQueryVariables) =>
      unwrap(() =>
        graphQLClient<TagDetailQuery, TagDetailQueryVariables>(
          graphQlApi,
          TagDetailDocument,
          queryVariables,
        ),
      ),
  ),
  UserIdQuery: toFetcherV2(
    (queryVariables: UserIdQueryVariables) =>
      getDocumentKey(UserIdDocument, queryVariables),
    (queryVariables: UserIdQueryVariables) =>
      unwrap(() =>
        graphQLClient<UserIdQuery, UserIdQueryVariables>(
          graphQlApi,
          UserIdDocument,
          queryVariables,
        ),
      ),
  ),
  UserTagsQuery: toFetcherV2(
    (queryVariables: UserTagsQueryVariables) =>
      getDocumentKey(UserTagsDocument, queryVariables),
    (queryVariables: UserTagsQueryVariables) =>
      unwrap(() =>
        graphQLClient<UserTagsQuery, UserTagsQueryVariables>(
          graphQlApi,
          UserTagsDocument,
          queryVariables,
        ),
      ),
  ),
  SearchTagPagination: toMutate(
    (queryVariables: SearchTagQueryVariables) =>
      getDocumentKey(SearchTagDocument, queryVariables),
    (queryVariables: SearchTagQueryVariables) =>
      unwrap(() =>
        graphQLClient<SearchTagQuery, SearchTagQueryVariables>(
          graphQlApi,
          SearchTagDocument,
          queryVariables,
        ),
      ),
  ),
  TagBrowseCategoryPagination: toMutate(
    (queryVariables: TagBrowseCategoryQueryVariables) =>
      getDocumentKey(TagBrowseCategoryDocument, queryVariables),
    (queryVariables: TagBrowseCategoryQueryVariables) =>
      unwrap(() =>
        graphQLClient<TagBrowseCategoryQuery, TagBrowseCategoryQueryVariables>(
          graphQlApi,
          TagBrowseCategoryDocument,
          queryVariables,
        ),
      ),
  ),
  TagDetailPagination: toMutate(
    (queryVariables: TagDetailQueryVariables) =>
      getDocumentKey(TagDetailDocument, queryVariables),
    (queryVariables: TagDetailQueryVariables) =>
      unwrap(() =>
        graphQLClient<TagDetailQuery, TagDetailQueryVariables>(
          graphQlApi,
          TagDetailDocument,
          queryVariables,
        ),
      ),
  ),
  AddTagToUserMutation: toMutate(
    (queryVariables: AddTagToUserMutationVariables) =>
      getDocumentKey(AddTagToUserDocument, queryVariables),
    (queryVariables: AddTagToUserMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<AddTagToUserMutation, AddTagToUserMutationVariables>(
          graphQlApi,
          AddTagToUserDocument,
          queryVariables,
          optional,
        ),
      ),
  ),
  RemoveTagFromUserMutation: toMutate(
    (queryVariables: RemoveTagFromUserMutationVariables) =>
      getDocumentKey(RemoveTagFromUserDocument, queryVariables),
    (queryVariables: RemoveTagFromUserMutationVariables, optional: Headers) =>
      unwrap(() =>
        graphQLClient<RemoveTagFromUserMutation, RemoveTagFromUserMutationVariables>(
          graphQlApi,
          RemoveTagFromUserDocument,
          queryVariables,
          optional,
        ),
      ),
  ),
  ReplaceTagMutation: toMutate(
    (queryVariables: ReplaceTagMutationVariables) =>
      getDocumentKey(ReplaceTagDocument, queryVariables),
    (queryVariables: ReplaceTagMutationVariables) =>
      unwrap(() =>
        graphQLClient<ReplaceTagMutation, ReplaceTagMutationVariables>(
          graphQlApi,
          ReplaceTagDocument,
          queryVariables,
        ),
      ),
  ),
  SetTagAsPrimaryMutation: toMutate(
    (queryVariables: SetTagAsPrimaryMutationVariables) =>
      getDocumentKey(SetTagAsPrimaryDocument, queryVariables),
    (queryVariables: SetTagAsPrimaryMutationVariables) =>
      unwrap(() =>
        graphQLClient<SetTagAsPrimaryMutation, SetTagAsPrimaryMutationVariables>(
          graphQlApi,
          SetTagAsPrimaryDocument,
          queryVariables,
        ),
      ),
  ),
});
