import { sagas as driftSagas } from '@peloton/drift';
import { errorReportingSaga } from '@peloton/error-reporting';
import { fetcherSaga } from '@peloton/redux-fetch';
import { trackSaga } from '@engage/analytics-middleware';
import { challengesWatcherSaga } from '@engage/challenges';
import { classDetailSaga } from '@engage/class-detail';
import { memberSaga } from '@engage/members';
import { settingsSaga } from '@engage/settings';
import { subscriptionSaga } from '@engage/subscriptions';
import { workoutHistorySaga } from '@engage/workout-history';
import { authSaga } from '@members/auth';
import { avatarUpdaterSaga } from '@members/avatar-updater';
import { classesSaga } from '@members/classes';
import { facebookSaga } from '@members/facebook';
import { guestPassSaga } from '@members/guest-pass';
import { memberSearchSaga } from '@members/member-search';
import { modalWatcherSaga } from '@members/modal';
import { onDemandSaga } from '@members/on-demand-modal';
import { achievementsPageSaga } from '@members/pg-achievements';
import { forgotPasswordFormSaga } from '@members/pg-auth/ForgotPassword';
import { resetPasswordFormSaga } from '@members/pg-auth/ResetPassword';
import { completeProfileSaga } from '@members/pg-complete-profile';
import { libraryPageWatcherSaga } from '@members/pg-library/Filters';
import { overviewPageSaga } from '@members/pg-overview/sagas';
import { preferencesSaga } from '@members/pg-preferences';
import { combinedVideoPageSaga as videoPageSaga } from '@members/pg-video';
import { workoutsSaga } from '@members/pg-workouts';
import { workoutDetailsPageSaga } from '@members/pg-workouts/workout-details';
import { workoutHistoryPageSaga } from '@members/pg-workouts/workout-history';
import { referralsSaga } from '@members/referrals';
import { membersSettingsSaga } from '@members/settings';
import { memberMapperSaga, userMapperSaga } from '@members/user-mapper';

import { appSaga } from './appSaga';

export const toStoreSagas = () => [
  appSaga,
  achievementsPageSaga,
  authSaga,
  avatarUpdaterSaga,
  challengesWatcherSaga,
  classDetailSaga,
  classesSaga,
  completeProfileSaga,
  driftSagas,
  errorReportingSaga,
  facebookSaga,
  fetcherSaga,
  forgotPasswordFormSaga,
  guestPassSaga,
  libraryPageWatcherSaga,
  memberMapperSaga,
  memberSaga,
  membersSettingsSaga,
  memberSearchSaga,
  modalWatcherSaga,
  onDemandSaga,
  overviewPageSaga,
  preferencesSaga,
  referralsSaga,
  resetPasswordFormSaga,
  settingsSaga,
  subscriptionSaga,
  trackSaga,
  userMapperSaga,
  videoPageSaga,
  workoutDetailsPageSaga,
  workoutHistoryPageSaga,
  workoutHistorySaga,
  workoutsSaga,
];
