import React from 'react';

const Bookmark: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#4C525E"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 3.5C5.5 2.67157 6.17157 2 7 2H17C17.8284 2 18.5 2.67157 18.5 3.5V20.4423C18.5 21.8078 16.824 22.4631 15.8978 21.4598L12 17.2372L8.1022 21.4598C7.17603 22.4631 5.5 21.8078 5.5 20.4423V3.5ZM7 3C6.72386 3 6.5 3.22386 6.5 3.5V20.4423C6.5 20.8975 7.05868 21.1159 7.3674 20.7815L11.2652 16.5589C11.6612 16.1299 12.3388 16.1299 12.7348 16.5589L16.6326 20.7815C16.9413 21.1159 17.5 20.8975 17.5 20.4423V3.5C17.5 3.22386 17.2761 3 17 3H7Z"
    />
  </svg>
);

export { Bookmark };
