// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const TopNav = {
  Home: 'home',
  Classes: 'classes',
  Workouts: 'workouts',
  Programs: 'programs',
  Collections: 'collections',
  Schedule: 'schedule',
  Feed: 'feed',
  Challenges: 'challenges',
  Bookmarks: 'bookmarks',
  StackedClasses: 'stackedClasses',
  YourSchedule: 'yourSchedule',
  MySchedule: 'mySchedule',
  Profile: 'profile',
  FindMembers: 'findMembers',
  InviteFriends: 'inviteFriends',
  ReferFriends: 'referFriends',
  ExploreTags: 'exploreTags',
  Preferences: 'preferences',
  OrderHistory: 'orderHistory',
  Logout: 'logout',
  PelotonApps: 'pelotonApps',
  PelotonApparel: 'pelotonApparel',
  Support: 'support',
  SkipToFitness: 'skipToFitness',
  SkipTolive: 'skipTolive',
  SkipToFeatured: 'skipToFeatured',
  Notifications: 'notifications',
} as const;
