import { PROFILE_BASE_PATH } from '@peloton/links/members';
import { MEMBERS_OVERVIEW_ROUTE, OVERVIEW_ROUTE } from '@engage/overview';
import type { UserNavRouteOrRedirect } from '@members/layout';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { LEGACY_MEMBER_OVERVIEW_ROUTE } from './urls';

const Routes: UserNavRouteOrRedirect[] = [
  {
    route: {
      path: OVERVIEW_ROUTE,
      component: lazyLoader(
        () => import('./Overview' /* webpackChunkName: "OverviewPage" */),
      ),
    },
  },
  {
    route: {
      path: MEMBERS_OVERVIEW_ROUTE,
      component: lazyLoader(
        () => import('./Overview' /* webpackChunkName: "OverviewPage" */),
      ),
    },
  },
  {
    redirect: {
      exact: true,
      from: PROFILE_BASE_PATH,
      to: OVERVIEW_ROUTE,
    },
  },
  {
    redirect: {
      exact: true,
      from: LEGACY_MEMBER_OVERVIEW_ROUTE,
      to: MEMBERS_OVERVIEW_ROUTE,
    },
  },
];

export default Routes;
