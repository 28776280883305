import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { getScreenPropsSaga } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import type { SignedInUser } from '@peloton/auth';
import { getUser } from '@peloton/auth';
import { defaultHandleClientErrors } from '@engage/api-config';
import type {
  RemoveFollowerAction,
  ApproveFollowerAction,
  RejectFollowerAction,
} from '@engage/me';
import {
  removeFollowerSuccess,
  rejectFollowerSuccess,
  approveFollowerSuccess,
} from '@engage/me';
import {
  membersListSuccess,
  RelationshipChange,
  updateRelationship,
} from '@engage/members';
import type { Overview } from '@engage/overview';
import { fetchOverview, loadOverviewSuccess, fetchFriends } from '@engage/overview';
import type { RequestFriendsAction } from './redux';
import { addFriends, addFriendsFailure, loadingFriends } from './redux';
import { getNextPageToLoad } from './selectors';

export const loadFriendsSaga = function* (
  client: Client,
  action: RequestFriendsAction,
): SagaIterator {
  const page = yield select(
    getNextPageToLoad,
    action.payload.id,
    action.payload.friendType,
  );
  if (typeof page !== 'number' && !action.payload.initialLoad) {
    return;
  }
  yield put(loadingFriends(action.payload.id, action.payload.friendType));
  try {
    const { friends, nextPage } = yield call(
      fetchFriends(action.payload.id, action.payload.friendType, page),
      client,
    );
    yield put(membersListSuccess(friends));
    yield put(
      addFriends(action.payload.id, action.payload.friendType, friends, nextPage),
    );
  } catch (e) {
    yield put(addFriendsFailure(action.payload.id, action.payload.friendType, page));
  }
};

export const removeFollowerSaga = function* (
  client: Client,
  action: RemoveFollowerAction,
): SagaIterator {
  try {
    const followerId = action.payload.id;
    const screenProps = yield call(getScreenPropsSaga);
    yield call(
      updateRelationship,
      client,
      followerId,
      RelationshipChange.Remove,
      screenProps,
    );
    const user: SignedInUser = yield select(getUser);
    yield put(removeFollowerSuccess(user.id, followerId));
  } catch (e) {
    // Failed. Attempted to remove a member that was not following you?
  }
};

export const rejectFollowerSaga = function* (
  client: Client,
  action: RejectFollowerAction,
): SagaIterator {
  try {
    const followerId = action.payload.id;
    const screenProps = yield call(getScreenPropsSaga);
    yield call(
      updateRelationship,
      client,
      followerId,
      RelationshipChange.Reject,
      screenProps,
    );
    const user: SignedInUser = yield select(getUser);
    yield put(rejectFollowerSuccess(user.id, followerId));
  } catch (e) {
    // Failed.
  }
};

export const approveFollowerSaga = function* (
  client: Client,
  action: ApproveFollowerAction,
): SagaIterator {
  try {
    const followerId = action.payload.id;
    const screenProps = yield call(getScreenPropsSaga);
    yield call(
      updateRelationship,
      client,
      followerId,
      RelationshipChange.Approve,
      screenProps,
    );
    const user: SignedInUser = yield select(getUser);
    yield put(approveFollowerSuccess(user.id, followerId));
  } catch (e) {
    // Failed.
  }
};

export const loadOverviewSaga = function* (client: Client, id: string) {
  try {
    const overview: Overview = yield call(fetchOverview, client, id);
    yield put(loadOverviewSuccess(overview));
  } catch (error) {
    yield call(defaultHandleClientErrors, error);
  }
};
