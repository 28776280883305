export enum spaces {
  tiny = 2,
  xxxxSmall = 3,
  xxxSmall = 4,
  xxSmall = 6,
  xSmall = 8,
  small = 12,
  medium = 16,
  large = 20,
  xLarge = 24,
  xxLarge = 28,
  xxxLarge = 32,
  xxxxLarge = 36,
  huge = 40,
  xHuge = 48,
  xxHuge = 56,
  xxxHuge = 64,
  giant = 80,
  xGiant = 96,
  xxGiant = 112,
  xxxGiant = 128,
}
