import { Device } from '@peloton/browser';

export const BENEFITS_WELCOME_PATH = '/benefits/corporate/welcome';
export const WELCOME_PATH = '/complete/welcome';
export const PASSWORD_PATH = '/complete/password';
export const USERNAME_PATH = '/complete/username';
export const BIRTHDATE_PATH = '/complete/birthdate';
export const LOCATION_PATH = '/complete/location';
export const LOCATION_PRIVACY_PATH = '/complete/location-privacy';
export const PROFILE_IMAGE_PATH = '/complete/profile-image';
export const COLLECT_PRODUCT_INTEREST_PATH = '/complete/product-interest';
export const DOWNLOAD_APP_PATH_PREFIX = '/complete/apps';
export const DOWNLOAD_APP_PATH = `${DOWNLOAD_APP_PATH_PREFIX}/:device(${Device.Android}|${Device.IOS})`;
export const QUIZ_PATH = '/complete/quiz';
export const PREFERENCES_PATH = '/complete/preferences';
