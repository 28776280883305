/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassDetailsResponse } from '../../../api-v2-models/generated/ClassDetailsResponse';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Details about a class for clients to use
 * This endpoint will be used to get all class details (including music,
 * segments, class
 * averages, etc.):
 * 1. All information regularly included in the ride mapping, including
 * Class Name,
 * Instructor, Class type, time, whether it includes explicit content, and
 * the url to
 * the video stream, equipment_ids.
 * 2. The playlist field contains the class music information. This data is only returned
 * for on-demand classes. It includes the following fields:
 * * The songs field will be a list of songs in the order they were played.
 * For each song, we returned whether the logged in user liked the song. Songs contain
 * album art information, an image_url for the image with largest available size. The
 * response also includes the default image urls for both in-class and class-details
 * album image views. If there are no images for this album the album image_url field
 * will be null, so clients should then use these provided default images.
 * * The top_artists field will be an array of top artists based on class's playlist.
 * The criteria for determining who are the top artists are: (1) if the
 * artist is not "blacklisted" within our system, then based on (2) the number
 * of songs the artist has in that particular playlist, then based on (3) the
 * number of playlists that that artist is present in within the Peloton system.
 * * The top_albums field will be an array of albums with album art based on the class's
 * playlist. The criteria for determining the top albums are: (1) The album of one song
 * in the playlist for each of the top artists. (2) All duplicates are removed and albums
 * with no album art are removed. (3) Albums with album art from other songs in the
 * playlist are added up to the desired number.
 * 3. The average of each of the following metrics over all completed
 * workouts for the class.
 * For aurora classes: Total output (kj), Distance (mi) Elevation (ft),
 * Calories (kcal),
 * Avg output (watts), Avg speed (mph), Avg pace (min/mi), Avg incline (%).
 * For bike classes: Total output (kj), Distance (mi), Calories (kcal), Avg
 * output (watts),
 * Avg cadence (rpm), Avg speed (mph), Avg resistance (%).
 * Different set of metrics will be returned based on the ride's
 * fitness_discipline:
 * * cycling classes: bike metrics,
 * * aurora classes: aurora metrics,
 * If there is no accumulated metrics (no finalized workouts) yet, all
 * average metrics will be null.
 * 4. The Segments information of the class, includes segment list, segment
 * category and body focuses distributions.
 * Segment list is a list of ride segments associated with the ride, each
 * ride segment contains information for segment category and body focuses:
 * one ride segment has a single segment category and one or multiple body
 * focus. The distributions indicates the duration percentage of a segment
 * category / body focus during a class.
 * The segments information will be hidden(as empty) if ride's
 * is_class_shown flag is False / not set (null).
 * 5. The list of instructor cues for the ride. Each cue indicates a start and
 * end time offset since workout start that the cue is for. The cue also contains the
 * range for resistance and cadence.
 * 6. List of excluded platforms for the ride.
 * 7. Which leaderboard filter types should be disabled for this class.
 * 8. The VOD URLs will be returned as None for unauthenticated users and users for whom this ride lies
 * outside their availability zone.
 *
 * Additionally, some optional parameters are only included if their field is included in
 * the "include" parameter. Otherwise, they are null.
 * 1. A sampling of the top tags in the class. Null if the class is a live class.
 * @param rideId The ride id
 * @param streamSource Return primary or multichannel live_stream_url and vod_stream_url relatively if client is sending primary/multichannel as value. Replace vod_stream_url with local_url if client send local. API will send primary live_stream_url and vod_stream_url if client don't send stream_source value and we don't have multichannel url in our database.
 * @param include Comma separated list of optional fields to include. Possible values are ["sampled_top_tags", "is_freemium"].
 * @param acceptLanguage Accept language(s) that the user choose on the device.
 * If not included or unsupported, the value will default to en-US.
 * Can be weighted with the quality value syntax.
 *
 * Examples:
 * Accept-Language: de
 * Accept-Language: en-US, en;q=0.5
 * Accept-Language: de-DE, de;q=0.9, en-US, en;q=0.7
 *
 * Specification: https://tools.ietf.org/html/rfc7231#section-5.3.5
 *
 * @returns any Successful response
 * @throws ApiError
 */
export const getClassDetails = (
    api: BaseAPI,
    rideId: string,
    streamSource?: 'primary' | 'multichannel' | 'local',
    include?: string,
    acceptLanguage?: string,
    options: any = {},
): AxiosPromise<(ClassDetailsResponse & {
    excludedPlatforms?: Array<string>,
    isFtpTest?: boolean,
    allowedDigitalMetrics?: Array<'cadence' | 'calories' | 'heart_rate'>,
})> => {
    const localVarPath = `/api/ride/${rideId}/details`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof streamSource !== 'undefined') {
        localVarQueryParameter['stream_source'] = streamSource;
    }
    if(typeof include !== 'undefined') {
        localVarQueryParameter['include'] = include;
    }

    localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

