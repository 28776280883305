import React from 'react';
import styled from 'styled-components';
import '@peloton/polyfills/padStart';
import { media } from '@peloton/styles';
import type { Time, Duration } from '@peloton/time';
import {
  toTimerTime,
  toDiffDuration,
  isAfter,
  formatDurationByUnit,
} from '@peloton/time';
import { label3Large, label2Large, label10 } from '@engage/typography';
import { ScheduleCopy, Schedule } from '@members/copy';

export const UpcomingLiveDuration: React.FC<React.PropsWithChildren<Props>> = ({
  nowTime,
  startTime,
  isOnHomescreen = false,
}) => {
  const duration = isAfter(startTime, nowTime)
    ? toDiffDuration(nowTime, startTime)
    : toDiffDuration(startTime, nowTime);
  const [count, unit] = toUpcomingLiveDurationInHoursOrDays(duration);
  const isHoursOrDays = isOutsideCountdownTimerWindow(duration);

  return (
    <StyledTimer isOnHomescreen={isOnHomescreen}>
      {isHoursOrDays ? (
        <ScheduleCopy id={Schedule.UpcomingLiveDuration} values={{ count, unit }} />
      ) : (
        toTimerTime(duration, 60)
      )}
    </StyledTimer>
  );
};

export const toUpcomingLiveDurationInHoursOrDays = (duration: Duration) => {
  const days = formatDurationByUnit(duration, 'days');
  if (days >= 1) {
    return [days, 'days'];
  }

  const hours = Math.floor(formatDurationByUnit(duration, 'hours'));
  return [hours, 'hours'];
};

export const isOutsideCountdownTimerWindow = (
  duration: Duration,
  countdownTimerWindowHours: number = 4,
) => Math.floor(duration.asHours()) >= countdownTimerWindowHours;

const StyledTimer = styled.div<{ isOnHomescreen: boolean }>`
  ${p => (p.isOnHomescreen ? `${label10}` : `${label3Large}`)};

  ${media.desktopXLarge<{ isOnHomescreen: boolean }>`
  ${p => (p.isOnHomescreen ? `${label10}` : `${label2Large}`)};
  `}
`;

type Props = {
  nowTime: Time;
  startTime: Time;
  isOnHomescreen?: boolean;
};
