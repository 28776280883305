import useSWR from 'swr';
import { getUserId } from '@peloton/auth';
import { useReduxState } from '@engage/redux';
import { UserPreferencesFetchers } from '@members/data-fetch';
import { useLocale } from '@members/localization';

export const usePrivacyQuestionsData = (country: string, subRegion: string) => {
  const locale = useLocale();
  const userId = useReduxState(getUserId);
  return useSWR(
    ...UserPreferencesFetchers.GetPrivacyQuestions(
      {
        acceptLanguage: locale,
        userId,
      },
      {
        country,
        subRegion,
      },
    ),
  );
};

export const useLocationPromptData = () => {
  const locale = useLocale();
  const userId = useReduxState(getUserId);
  return useSWR(
    ...UserPreferencesFetchers.GetLocationPrompt({
      acceptLanguage: locale,
      userId,
    }),
  );
};

export const useGenericPromptData = () => {
  const locale = useLocale();
  return useSWR(
    ...UserPreferencesFetchers.getGenericLocationPrompt({
      acceptLanguage: locale,
    }),
  );
};
