import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { OVERVIEW_ROUTE } from '@engage/overview';
import { TagModalView } from './shared';
import { toTagsModalQuery } from './urls';

const SHARE_TAG_ROUTE = '/tags/:tagName';

const Routes = [
  <Route
    key={SHARE_TAG_ROUTE}
    path={SHARE_TAG_ROUTE}
    render={({ match }) => (
      <Redirect
        to={{
          pathname: OVERVIEW_ROUTE,
          search: toTagsModalQuery(TagModalView.TagDetails, match.params.tagName),
        }}
      />
    )}
  />,
];

export default Routes;
