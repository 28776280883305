import type { Reducer } from 'redux';
import type { BrowseCategorySlug } from '@engage/browse-categories';
import type { LoadLibraryPageAction } from './classes';
import { LibraryActionTypes } from './classes';

export type ActiveCategoryState = BrowseCategorySlug;
export const DEFAULT_STATE: ActiveCategoryState = '' as BrowseCategorySlug;

export const activeCategoryReducer: Reducer<ActiveCategoryState> = (
  state = DEFAULT_STATE,
  action: LoadLibraryPageAction | ActiveCategoryAction,
) => {
  switch (action.type) {
    case ActiveCategoryActionTypes.SetActiveCategorySlug:
    case LibraryActionTypes.RequestNewClasses:
      return action.payload.categorySlug;
    default:
      return state;
  }
};

export enum ActiveCategoryActionTypes {
  SetActiveCategorySlug = 'pelo/library/activeCategory/SET',
}

export const setActiveCategorySlug = (categorySlug: BrowseCategorySlug) => ({
  type: ActiveCategoryActionTypes.SetActiveCategorySlug,
  payload: { categorySlug },
});

type ActiveCategoryAction = ReturnType<typeof setActiveCategorySlug>;
