import { not } from 'ramda';
import React from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { TransitionState, isFadeGone, isFadeShown } from './utils';

const TRANSITION_DURATION_MS = 300;

const Fade: React.FC<React.PropsWithChildren<FadeProps>> = ({
  duration = TRANSITION_DURATION_MS,
  ...props
}) => {
  return (
    <Transition in={props.in} timeout={duration}>
      {(transitionState: TransitionState) =>
        isFadeGone(transitionState) ? null : (
          <FadeContent
            transitionState={transitionState}
            aria-hidden={not(isFadeShown(transitionState))}
          >
            {props.children}
          </FadeContent>
        )
      }
    </Transition>
  );
};

const toAnimationDelay = (props: { transitionState: TransitionState }) =>
  props.transitionState === TransitionState.Entered
    ? `${TRANSITION_DURATION_MS}ms`
    : '0s';

const FadeContent = styled.div`
  /* Set display to none to stop focus on exited content. */

  transition: opacity ${TRANSITION_DURATION_MS}ms ease-in-out;
  opacity: ${(props: { transitionState: TransitionState }) =>
    isFadeShown(props.transitionState) ? 1 : 0};
  z-index: ${(props: { transitionState: TransitionState }) =>
    isFadeShown(props.transitionState) ? 1 : 0};
  transition-delay: ${toAnimationDelay};
  position: absolute;
  width: 100%;
`;

export const FadeInOut: React.FC<React.PropsWithChildren<Props>> = props => (
  <>
    <Fade in={props.isFirstShown} duration={props.duration}>
      {props.renderFirst()}
    </Fade>
    <Fade in={!props.isFirstShown} duration={props.duration}>
      {props.renderSecond()}
    </Fade>
  </>
);

type Props = {
  isFirstShown: boolean;
  duration?: number;
  renderFirst(): React.ReactElement<any>;
  renderSecond(): React.ReactElement<any>;
};

type FadeProps = {
  in: boolean;
  duration?: number;
};
