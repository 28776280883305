import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const Follow: React.FC<React.PropsWithChildren<Props>> = props => (
  <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs>
      <circle id="followIcon" cx="20" cy="20" r="20" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#697180" xlinkHref="#followIcon" />
      <use className="overlay" fill="#000" fillOpacity="0.15" xlinkHref="#followIcon" />
      <path
        stroke="#F5F7F9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M23.814 27.666V26.42c0-.219-.146-.438-.292-.511-2.193-.877-4.678-1.974-4.897-2.705v-.73a5.05 5.05 0 001.023-1.974c.512-.366.658-1.17.22-1.681v-1.608c0-1.681-.878-2.85-2.924-2.85-1.974 0-2.924 1.169-2.924 2.85v1.608c-.439.511-.292 1.315.22 1.68a5.05 5.05 0 001.023 1.974v.731c-.293.731-2.778 1.828-4.97 2.705-.22.073-.293.292-.293.511v1.245m12.703-12.527h7.222m-3.61-3.611v7.222"
      />
    </g>
  </svg>
);
