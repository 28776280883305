import React from 'react';
import { Route } from 'react-router';
import lazyLoader from '@members/lazy-loader/LazyLoader';

export const PATH = '/benefits/corporate/welcome';

const route = [
  <Route
    exact
    key={PATH}
    path={PATH}
    component={lazyLoader(
      () => import('./Handler' /* webpackChunkName: "pg-setup-account handler" */),
    )}
  />,
];

export default route;
