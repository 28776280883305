import { curry, omit, filter, equals } from 'ramda';
import type { Reducer } from 'redux';
import type { Omit } from '@peloton/types';
import type {
  Class,
  ClassReducerState,
  UpdateClassesActionWithOptionalPayload,
} from '@engage/classes';
import { isLibrary, ClassActionTypes, ClassesActionTypes } from '@engage/classes';

export const classesReducer: Reducer<ClassReducerState> = (
  state = {},
  action:
    | ClassAction
    | ClearClassesAction
    | BookmarkToggleSuccessAction
    | UpdateClassesActionWithOptionalPayload<{}>
    | BookmarkToggleRequestAction,
) => {
  switch (action.type) {
    case ClassActionTypes.REQUEST_SUCCESS: {
      const newClassState = {
        entity: action.payload,
        isLoading: false,
        error: undefined,
      };

      if (action.payload.id in state && equals(state[action.payload.id], newClassState)) {
        return state;
      }

      return {
        ...state,
        [action.payload.id]: newClassState,
      };
    }
    case ClassActionTypes.REQUEST_FAILURE:
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          isLoading: false,
          error: action.payload,
        },
      };
    case ClassesActionTypes.Update:
      return {
        ...state,
        ...indexByClassId(state, action.payload),
      };
    case ClassesActionTypes.Clear:
      return {};
    case BookmarkActionTypes.TOGGLE: {
      const klass = state[action.payload.classId].entity;
      if (klass && isLibrary(klass)) {
        return {
          ...state,
          [action.payload.classId]: {
            ...state[action.payload.classId],
            entity: {
              ...klass,
              isBookmarked: !klass.isBookmarked,
            },
          },
        };
      } else {
        return state;
      }
    }
    case BookmarkActionTypes.TOGGLE_SUCCESS:
      if (state[action.meta.classId]?.entity) {
        return {
          ...state,
          [action.meta.classId]: {
            ...state[action.meta.classId],
            entity: {
              ...state[action.meta.classId].entity!,
              isBookmarked: action.payload.isBookmarked,
            },
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const indexByClassId = (
  state: ClassReducerState,
  classes: Class[],
): ClassReducerState =>
  classes.reduce(
    (acc, klass) => ({
      ...acc,
      [klass.id]: {
        entity: {
          ...state[klass.id]?.entity,
          // on some api call, user specific data such as isBookmarked is missing
          ...omitNil(
            // @ts-expect-error some of the props are not on the base Class type
            ['isBookmarked', 'totalFollowingWorkouts', 'totalUserWorkouts'],
            klass,
          ),
        },
        isLoading: false,
        error: undefined,
      },
    }),
    {},
  );

type StringKeyHelper<T, K> = K extends keyof T ? (K extends string ? K : never) : never;
type StringKey<T> = StringKeyHelper<T, keyof T>;

export const omitNil = <T>(
  names: readonly StringKey<T>[],
  obj: T,
): Omit<T, StringKey<T>> => {
  const undefinedNames = filter(
    key => obj[key] === undefined || obj[key] === null,
    names,
  );
  return omit(undefinedNames, obj);
};

export const toggleBookmarkClass = curry(
  (
    source: string,
    sourceDetail: string,
    classId: string,
    isCurrentlyBookmarked: boolean,
  ) => ({
    type: BookmarkActionTypes.TOGGLE as BookmarkActionTypes.TOGGLE,
    payload: { classId, isCurrentlyBookmarked, source, sourceDetail },
  }),
);

export const toggleBookmarkClassSuccess = (
  classId: string,
  isBookmarked: boolean,
): BookmarkToggleSuccessAction => ({
  type: BookmarkActionTypes.TOGGLE_SUCCESS,
  payload: { isBookmarked },
  meta: { classId },
});

export const toggleBookmarkClassFailure = (): BookmarkToggleFailureAction => ({
  type: BookmarkActionTypes.TOGGLE_FAILURE,
});

type ClassAction = ClassSuccessAction | ClassFailureAction;

export type ClassSuccessAction = {
  type: ClassActionTypes.REQUEST_SUCCESS;
  payload: Class;
};

export type ClassFailureAction = {
  type: ClassActionTypes.REQUEST_FAILURE;
  payload: Error;
  meta: {
    id: string;
  };
};

export type ClearClassesAction = {
  type: ClassesActionTypes.Clear;
};

export const clearClasses = () => ({ type: ClassesActionTypes.Clear });

export type BookmarkToggleRequestAction = ReturnType<typeof toggleBookmarkClass>;
export type BookmarkToggleSuccessAction = {
  type: BookmarkActionTypes.TOGGLE_SUCCESS;
  payload: { isBookmarked: boolean };
  meta: { classId: string };
};
type BookmarkToggleFailureAction = { type: BookmarkActionTypes.TOGGLE_FAILURE };

export enum BookmarkActionTypes {
  TOGGLE = 'pelo/class/bookmark/TOGGLE',
  TOGGLE_SUCCESS = 'pelo/class/bookmark/TOGGLE_SUCCESS',
  TOGGLE_FAILURE = 'pelo/class/bookmark/TOGGLE_FAILURE',
}
