/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassMediaStreamResponse } from '../../../api-v2-models/generated/ClassMediaStreamResponse';
import type { ClassStream } from '../../../api-v2-models/generated/ClassStream';
import type { InternalStreamResponse } from '../../../api-v2-models/generated/InternalStreamResponse';
import type { IOSAkamaiToken } from '../../../api-v2-models/generated/IOSAkamaiToken';
import type { StreamHistory } from '../../../api-v2-models/generated/StreamHistory';
import type { StreamResponse } from '../../../api-v2-models/generated/StreamResponse';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Updates heartbeat of an active stream
 * Updates the heartbeat of an active stream
 * @param streamHistoryId Identifier for stream
 * @returns StreamHistory Successful response
 * @throws ApiError
 */
export const streamHistoryHeartbeat = (
    api: BaseAPI,
    streamHistoryId: string,
    options: any = {},
): AxiosPromise<StreamHistory> => {
    const localVarPath = `/api/stream_history/${streamHistoryId}/heartbeat`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get URL Appended With Token To Access Non Class Video Conetnt From CDN
 * Return Token which give user's permission to access Video Content
 * * Does user is logged in
 * * Does user has valid subscription which allow them to access this content
 * * Does user is eligible to access beta program content
 * @param id Id for which we need to generate token to access it's video content
 * @param mediaType Type of Media
 * @param mediaFormat File format of media (only used for short movement videos). WebM is default
 * @param requestBody
 * @returns StreamResponse Successful Response
 * @throws ApiError
 */
export const postVideoAssetService = (
    api: BaseAPI,
    id: string,
    mediaType: 'onboarding' | 'device_video' | 'movement_video',
    mediaFormat?: 'webm' | 'm3u8',
    requestBody?: {
        /**
         * device id; mandatory if called from device and OAuth is used
         */
        deviceId?: string,
        /**
         * device cert; PEM-encoded device certificate chain containing device serial and device type
         */
        deviceCert?: string,
        /**
         * device_token; WT token with device serial and expiration time signed w. RS256 alg using device cert's private key and encoded in JWS Compact Serialization format. This will be used to validate that the sender has access to the private key in addition to the public device cert.
         */
        deviceToken?: string,
    },
    options: any = {},
): AxiosPromise<StreamResponse> => {
    const localVarPath = `/api/media/${id}/stream`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof mediaType !== 'undefined') {
        localVarQueryParameter['media_type'] = mediaType;
    }
    if(typeof mediaFormat !== 'undefined') {
        localVarQueryParameter['media_format'] = mediaFormat;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Get an Akamai token for iOS
 * Get an Akamai token for iOS based on the logged in user's subscription
 * @returns IOSAkamaiToken Successful response
 * @throws ApiError
 */
export const getIosAkamaiToken = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<IOSAkamaiToken> => {
    const localVarPath = `/video/ios/akamai_token`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get URL Appended With Token To Access Ride Video Conetnt From CDN For internal services
 * Return Token which give user's permission to access Video Content of Requested Ride for
 * 1 minutes if all following validation passed
 * * Does user is logged in
 * * Does user has valid subscription which allow them to access this content
 * * Does ride is available in user’s availability zone
 * * Does ride is on-demand or not while generating token for on-demand ride
 * * Does ride is live while generating token for live ride
 * * Does user is eligible to access beta program content
 * @param pelotonTotpToken An 8-digit TOTP (time-based HMAC one-time password) using SHA1. Please contact the API team for
 * the shared secret.
 *
 * @param joinToken active class identifier
 * @param rideType Type of Ride
 * @param requestBody
 * @returns InternalStreamResponse Successful Response
 * @throws ApiError
 */
export const postVideoAssetService1 = (
    api: BaseAPI,
    pelotonTotpToken: string,
    joinToken: string,
    rideType?: 'live' | 'on_demand' | 'encore' | 'session',
    requestBody?: {
        /**
         * device id; mandatory if called from device and OAuth is used
         */
        deviceId?: string,
    },
    options: any = {},
): AxiosPromise<InternalStreamResponse> => {
    const localVarPath = `/api/internal/ride/${joinToken}/stream`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof rideType !== 'undefined') {
        localVarQueryParameter['ride_type'] = rideType;
    }

    localVarHeaderParameter['Peloton-TOTP-Token'] = String(pelotonTotpToken);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Get an Akamai token for Peloton Hardware
 * Get an Akamai token for Peloton Hardware Based on logged in user's
 * subscription and device from session
 * @returns IOSAkamaiToken Successful response
 * @throws ApiError
 */
export const getVideoAssetService = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<IOSAkamaiToken> => {
    const localVarPath = `/video/bike/akamai_token`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get URL Appended With Token To Access Ride Video Conetnt From CDN
 * Return Token which give user's permission to access Video Content of Requested Ride for
 * 1 minutes if all following validation passed
 * * Does user is logged in
 * * Does user has valid subscription which allow them to access this content
 * * Does ride is available in user’s availability zone
 * * Does ride is on-demand or ride has premiere in progress while generating token for on-demand ride
 * * Does ride is live while generating token for live ride
 * * Does user is eligible to access beta program content
 * @param rideId Id of ride for which we need to generate token to access it's video content
 * @param contentType Type of Ride
 * @param streamSource Type of Stream
 * @param streamUrlType Use default or Lamina url
 * @param requestBody
 * @returns ClassMediaStreamResponse Successful Response
 * @throws ApiError
 */
export const postVideoAssetService2 = (
    api: BaseAPI,
    rideId: string,
    contentType?: 'live' | 'on_demand' | 'encore' | 'preview',
    streamSource?: 'primary' | 'multichannel',
    streamUrlType?: 'lamina',
    requestBody?: {
        /**
         * device id; mandatory if called from device and OAuth is used
         */
        deviceId?: string,
    },
    options: any = {},
): AxiosPromise<ClassMediaStreamResponse> => {
    const localVarPath = `/api/ride/${rideId}/stream`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof contentType !== 'undefined') {
        localVarQueryParameter['content_type'] = contentType;
    }
    if(typeof streamSource !== 'undefined') {
        localVarQueryParameter['stream_source'] = streamSource;
    }
    if(typeof streamUrlType !== 'undefined') {
        localVarQueryParameter['stream_url_type'] = streamUrlType;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get URL Appended With Token To Access Playback Video Conetnt From CDN For internal services
 * Return Token which give user's permission to access Content of Requested Ride for
 * 1 minutes if all following validation passed
 * * Does user is logged in
 * * Does user has valid subscription which allow them to access this content
 * @param pelotonTotpToken An 8-digit TOTP (time-based HMAC one-time password) using SHA1. Please contact the API team for
 * the shared secret.
 *
 * @param playbackId Id of Playback for which we need to generate token to access its video content
 * @param requestBody
 * @returns StreamResponse Successful Response
 * @throws ApiError
 */
export const postVideoAssetService3 = (
    api: BaseAPI,
    pelotonTotpToken: string,
    playbackId: string,
    requestBody?: {
        /**
         * device id; mandatory if called from device and OAuth is used
         */
        deviceId?: string,
    },
    options: any = {},
): AxiosPromise<StreamResponse> => {
    const localVarPath = `/api/internal/playback/${playbackId}/stream`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-TOTP-Token'] = String(pelotonTotpToken);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Returns specific device video
 * Returns specific device video. Uses user auth or device certs
 * for authentication.
 * @param deviceVideoId Identifier for device_video
 * @param requestBody
 * @returns StreamResponse Successful Response
 * @throws ApiError
 */
export const postVideoAssetService4 = (
    api: BaseAPI,
    deviceVideoId: string,
    requestBody?: {
        /**
         * device cert; PEM-encoded device certificate chain containing device serial and device type
         */
        deviceCert?: string,
        /**
         * device_token; WT token with device serial and expiration time signed w. RS256 alg using device cert's private key and encoded in JWS Compact Serialization format. This will be used to validate that the sender has access to the private key in addition to the public device cert.
         */
        deviceToken?: string,
    },
    options: any = {},
): AxiosPromise<StreamResponse> => {
    const localVarPath = `/api/device_videos/${deviceVideoId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get URL Appended With Token To Access Ride Video Conetnt From CDN
 * Return Tokennized URL which give studio application permission to access Live Stream of Requested Ride for
 * 1 minutes if all following validation passed
 * * Does ride is live while generating token for ride
 * * Does oauth token have studio read scope
 * @param rideId Id of ride for which we need to generate token to access it's video content
 * @returns StreamResponse Successful Response
 * @throws ApiError
 */
export const postVideoAssetService5 = (
    api: BaseAPI,
    rideId: string,
    options: any = {},
): AxiosPromise<StreamResponse> => {
    const localVarPath = `/api/studio/ride/${rideId}/stream`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Gets the stream URL for the class by ID
 * Gets the stream URL for the class. The ID is "<class_id>-<class_type>".
 * @param id ID for the class' stream
 * @returns ClassStream Class stream object
 * @throws ApiError
 */
export const getStream = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<ClassStream> => {
    const localVarPath = `/api/stream/<id>`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof id !== 'undefined') {
        localVarQueryParameter['id'] = id;
    }


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Get an authentication token used for initiating a new movements stream
 * Get an authentication token used for initiating a new movements stream.
 * @returns any Successful response
 * @throws ApiError
 */
export const getMovementsStreamToken = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<{
    /**
     * movements stream authentication token
     */
    token?: string,
    streamHistory?: StreamHistory,
}> => {
    const localVarPath = `/api/subscription/movements_stream`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Get an authentication token used for initiating a new stream
 * Get an authentication token used for initiating a new stream.  The
 * endpoint will validate that the user has a valid device or
 * subscription and enforce stream limits
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const getSubscriptionStreamToken = (
    api: BaseAPI,
    requestBody?: {
        /**
         * device id; mandatory if called from device and OAuth is used
         */
        deviceId?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * streaming authentication token
     */
    token?: string,
    streamHistory?: StreamHistory,
}> => {
    const localVarPath = `/api/subscription/stream`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Delete stream history by id
 * Delete a stream history record by id. When client ends a stream, the client would call this to free up the available stream associated to the subscription
 * @param streamHistoryId Identifier for stream
 * @returns any Successful response
 * @throws ApiError
 */
export const deleteStreamHistory = (
    api: BaseAPI,
    streamHistoryId: string,
    options: any = {},
): AxiosPromise<{
    /**
     * when successful, always set to true
     */
    deleted?: boolean,
    /**
     * the deleted stream history id
     */
    streamHistoryId?: string,
}> => {
    const localVarPath = `/api/stream_history/${streamHistoryId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Returns device videos for a specific platfom.
 * Returns device videos for a specific platform. Uses user auth or device certs
 * for authentication.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param contentType Content type of the device videos
 * @param requestBody
 * @returns StreamResponse Successful Response
 * @throws ApiError
 */
export const postVideoAssetService6 = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    contentType?: 'tiger_onboarding' | 'screensaver' | 'background' | 'caesar_calibration_form' | 'caesar_pre_class' | 'caesar_segment' | 'caesar_segment_error' | 'caesar_form_helper',
    requestBody?: {
        /**
         * device cert; PEM-encoded device certificate chain containing device serial and device type
         */
        deviceCert?: string,
        /**
         * device_token; WT token with device serial and expiration time signed w. RS256 alg using device cert's private key and encoded in JWS Compact Serialization format. This will be used to validate that the sender has access to the private key in addition to the public device cert.
         */
        deviceToken?: string,
    },
    options: any = {},
): AxiosPromise<StreamResponse> => {
    const localVarPath = `/api/device_videos`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    if(typeof contentType !== 'undefined') {
        localVarQueryParameter['content_type'] = contentType;
    }

    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

