import type { SagaIterator } from 'redux-saga';
import { getContext, takeEvery, call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { forgotPassword } from '@peloton/auth';
import type { ForgotPasswordAction } from './redux';
import {
  ForgotPasswordActionType,
  failedForgotPasswordRequest,
  successfulForgotPasswordRequest,
} from './redux';

export const onForgotPasswordSaga = function* (
  client: Client,
  action: ForgotPasswordAction,
): SagaIterator {
  try {
    const response = yield call(forgotPassword, client, action.payload.email);
    yield put(successfulForgotPasswordRequest({ email: response }));
  } catch (error) {
    yield put(failedForgotPasswordRequest(error));
  }
};

export const forgotPasswordSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(ForgotPasswordActionType.REQUEST, onForgotPasswordSaga, client);
};
