import styled from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { gray2, gray3, white } from '@engage/colors';
import { spaces } from '@engage/styles';

const IconBadge = styled.div<{ url: string }>`
  background-image: url(${({ url }) => url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: ${spaces.xxSmall}px;
  border: 1px solid ${gray2};
  width: 90px;
  height: 40px;
  ${hoverTransition({
    property: 'background-color',
    from: white,
    to: gray2,
  })}
  a:focus > & {
    background-color: ${gray3};
  }
`;

export default IconBadge;
