import { isUndefined } from 'util';
import { isDefined } from '@peloton/types';
import type { VideoTimeState } from '@engage/video';
import {
  getClassEndOffset,
  getClassStartOffset,
  getCurrentVideoOffset,
  getPelotonId,
  isClassLive,
} from '@engage/video';
import { ClassStatus, toClassStatus } from '@engage/video-ui';
import type { VideoSelectorState } from '@engage/video/redux';
import { getStartTime, getTimePlaying } from '@engage/video/redux';
import type { VideoClassSelectorState } from '@engage/video/selectors';
import { hasClassStarted } from '@engage/video/timeSelectors';
import { Feature } from '@members/feature-toggles';
import type { FeatureToggleState } from '@members/feature-toggles/redux';
import { getVariablesForFeature, getFeatureToggle } from '@members/feature-toggles/redux';
import { SECONDS_BEFORE_START } from './constants';

type MaybeLoadedOffsets = {
  currentVideoOffset: number | undefined;
  classStartOffset: number | undefined;
  workoutCompleteOffset: number | undefined;
};

export type OffsetProps = {
  currentVideoOffset: number;
  classStartOffset: number;
  workoutCompleteOffset: number;
};

export const hasOffsets = (offsets: MaybeLoadedOffsets): offsets is OffsetProps =>
  !isUndefined(offsets.classStartOffset) &&
  !isUndefined(offsets.currentVideoOffset) &&
  !isUndefined(offsets.workoutCompleteOffset);

const toIsSkippableTime = (current: number, start: number) =>
  current < start - SECONDS_BEFORE_START;

export const isSkippableTime = (state: VideoTimeState) => {
  const { currentVideoOffset, classStartOffset } = getTimerOffsets(state);

  return isDefined(currentVideoOffset) && isDefined(classStartOffset)
    ? toIsSkippableTime(currentVideoOffset, classStartOffset)
    : undefined;
};

export const getTimerOffsets = (state: VideoTimeState): MaybeLoadedOffsets => {
  const scheduledClassId = getPelotonId(state) ?? '';
  const currentVideoOffset = getCurrentVideoOffset(
    state,
    scheduledClassId,
    isClassLive(state),
  );
  const classStartOffset = getClassStartOffset(state, scheduledClassId);
  const workoutCompleteOffset = getClassEndOffset(state, scheduledClassId);

  return {
    currentVideoOffset,
    classStartOffset,
    workoutCompleteOffset,
  };
};

const toSkipIntroTime = (seconds: number) => seconds - SECONDS_BEFORE_START;

export const getSkipIntroTime = (state: VideoTimeState) => {
  const scheduledClassId = getPelotonId(state) ?? '';
  const classStartOffset = getClassStartOffset(state, scheduledClassId);

  return isDefined(classStartOffset) && classStartOffset >= SECONDS_BEFORE_START
    ? toSkipIntroTime(classStartOffset)
    : undefined;
};

export const getWorkoutComplete = (state: VideoTimeState): boolean => {
  const offsets = getTimerOffsets(state);
  return hasOffsets(offsets) && toClassStatus(offsets) === ClassStatus.Complete;
};

export const getHasWatchedLessThan3Minutes = (
  state: VideoSelectorState & FeatureToggleState,
) => {
  const videoStartTime = getStartTime(state);
  if (videoStartTime === null) {
    return true;
  }
  const videoPlayingTime = getTimePlaying(state);

  const completionTimeEnabled = getFeatureToggle(state, Feature.Completiontime);
  const { time } = getVariablesForFeature(state, Feature.Completiontime);
  const completionTime = completionTimeEnabled && time ? time : 180;
  return videoPlayingTime < completionTime;
};
/**
 * We define credited for programs if the user has watched 180 seconds of the video.
 * Does not matter if they paused it, or the tab is not in focus.
 * @param state
 * @returns
 */
export const getIsClassCredited = (
  state: VideoClassSelectorState & FeatureToggleState,
) => {
  const videoStartTime = getStartTime(state);
  const pelotonId = getPelotonId(state);
  const classIsLive = isClassLive(state);
  const hasStarted = !!pelotonId && hasClassStarted(state, pelotonId, classIsLive);

  return hasStarted && videoStartTime !== null && !getHasWatchedLessThan3Minutes(state);
};
