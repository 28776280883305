import React from 'react';
import {
  getSignedInUsername,
  isMe,
  getUser,
  isSignedIn,
  MemberGroup,
} from '@peloton/auth';
import type { Location } from '@peloton/redux';
import { getLocation } from '@peloton/redux';
import { isDefined } from '@peloton/types';
import { track } from '@engage/analytics';
import { useMemberId } from '@engage/overview';
import { useReduxAction, useReduxState } from '@engage/redux';
import { toBaseProperties } from '@members/analytics';
import type { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import type { ShareTooltipSource } from '@members/share-modal';
import { ShareTooltipDestination } from '@members/share-modal';
import { usePrimaryTag, useSharedLeaderboardTag, useTagNameFromLocation } from '../hooks';
import {
  getLastTagView,
  getTagBrowseCategoryFromLocation,
  getTagViewFromLocation,
} from '../selectors';
import { TagModalView } from '../shared';
import {
  toAnalyticsTagView,
  toOtherUserIdProps,
  trackReplaceTagFlow,
  trackSelectedShareTagDestination,
  trackTappedShareTag,
  trackViewedMemberTags,
  trackViewedTagDetail,
  trackViewedTagSearchError,
} from './events';

export const useTrackViewedTagList = (
  fetched: boolean,
  isSelf: boolean,
  viewedMemberId?: string,
) => {
  const trackAnalytics = useReduxAction(track);
  const view = useReduxState(getTagViewFromLocation);
  const { primaryTagName: leaderboardTag, numberOfTags } = usePrimaryTag(viewedMemberId);
  const { sharedLeaderboardTag } = useSharedLeaderboardTag(viewedMemberId);

  React.useEffect(() => {
    const isUserTagsView =
      view === TagModalView.MyTags || view === TagModalView.OtherMembersTags;
    if (fetched && isUserTagsView) {
      trackAnalytics(
        trackViewedMemberTags(numberOfTags, leaderboardTag, isSelf, sharedLeaderboardTag),
      );
    }
  }, [fetched, numberOfTags, leaderboardTag, sharedLeaderboardTag]);
};

export const useTrackViewedTagSearchError = (searchTerm: string, showError: boolean) => {
  const trackAnalytics = useReduxAction(track);
  React.useEffect(() => {
    if (showError) {
      trackAnalytics(trackViewedTagSearchError(searchTerm, searchTerm.length));
    }
  }, [showError]);
};

export const useTrackViewedDetails = (fetched: boolean, numberOfMembers: number) => {
  const trackAnalytics = useReduxAction(track);
  const tagName = useTagNameFromLocation();
  const location: Location = useReduxState(getLocation);
  const source = location.state?.source ?? 'Direct Link';
  const view = useReduxState(getTagViewFromLocation);

  const signedInUsername = useReduxState(getSignedInUsername);
  const memberId = useMemberId();
  const isSelf = useReduxState(isMe, memberId);

  React.useEffect(() => {
    if (fetched && view === TagModalView.TagDetails) {
      trackAnalytics(
        trackViewedTagDetail(
          tagName,
          numberOfMembers,
          isSelf,
          source,
          signedInUsername,
          memberId,
        ),
      );
    }
  }, [fetched]);
};

export const useAnalyticsHeadersFromReplace = () => {
  const view = useReduxState(getLastTagView);
  return useAnalyticsHeadersFromView(view);
};

export const useAnalyticsHeaders = () => {
  const view = useReduxState(getTagViewFromLocation);
  return useAnalyticsHeadersFromView(view);
};

export const useAnalyticsHeadersFromView = (view?: TagModalView) => {
  const category = useReduxState(
    getTagBrowseCategoryFromLocation,
  ) as TagBrowseCategorySlug;

  const memberId = useMemberId();
  const maybeOtherUserProps =
    view === TagModalView.OtherMembersTags ? toOtherUserIdProps(memberId) : {};

  if (isDefined(view)) {
    return {
      Source: toAnalyticsTagView(view, category),
      ...toBaseProperties(),
      ...maybeOtherUserProps,
    };
  } else {
    return {};
  }
};

export const useShareTagInfo = () => {
  const user = useReduxState(getUser);
  const isInTagsGroup =
    isSignedIn(user) && user?.memberGroups.includes(MemberGroup.TagsViralLaunch);
  const tagName = useTagNameFromLocation();

  return { tagName, isInTagsGroup };
};

export const useTrackReplaceTagFlow = (
  tagName: string,
  analyticsHeaderProps: ReturnType<typeof useAnalyticsHeadersFromView>,
) => {
  const trackAnalytics = useReduxAction(track);
  return React.useCallback(
    () => trackAnalytics(trackReplaceTagFlow(tagName, analyticsHeaderProps)),
    [],
  );
};

export const useTrackShareTag = () => {
  const trackAction = useReduxAction(track);
  const { tagName, isInTagsGroup } = useShareTagInfo();
  return React.useCallback(
    (source: ShareTooltipSource) =>
      trackAction(trackTappedShareTag(tagName, source, isInTagsGroup)),
    [isInTagsGroup, tagName],
  );
};

export const useTrackShareTagToFacebook = () => {
  const trackAction = useReduxAction(track);
  const { tagName, isInTagsGroup } = useShareTagInfo();
  return React.useCallback(
    (source: ShareTooltipSource) =>
      trackAction(
        trackSelectedShareTagDestination(
          tagName,
          source,
          isInTagsGroup,
          ShareTooltipDestination.Facebook,
        ),
      ),
    [isInTagsGroup, tagName],
  );
};

export const useTrackTagCopyLink = () => {
  const trackAction = useReduxAction(track);
  const { tagName, isInTagsGroup } = useShareTagInfo();
  return React.useCallback(
    (source: ShareTooltipSource) =>
      trackAction(
        trackSelectedShareTagDestination(
          tagName,
          source,
          isInTagsGroup,
          ShareTooltipDestination.CopyLink,
        ),
      ),
    [isInTagsGroup, tagName],
  );
};
