import { schema } from 'normalizr';
import { BrowseCategorySlug } from '@engage/browse-categories';

export enum FitnessDisciplineSlug {
  Cycling = 'cycling',
  BTR = 'beyond_the_ride',
  Circuit = 'circuit',
  Running = 'running',
  Walking = 'walking',
  Strength = 'strength',
  Stretching = 'stretching',
  Yoga = 'yoga',
  Meditation = 'meditation',
  Cardio = 'cardio',
  BikeBootcamp = 'bike_bootcamp',
  Caesar = 'caesar',
  CaesarBootcamp = 'caesar_bootcamp',
}

/** There is some domain confusion here, but there is a relationship between
 * Fitness Disciplines and Browse Categories. It is documented here
 */
export const fitnessDisciplineToBrowseCategory = (
  slug: FitnessDisciplineSlug,
): BrowseCategorySlug => {
  switch (slug) {
    case FitnessDisciplineSlug.Cycling:
      return BrowseCategorySlug.Cycling;
    case FitnessDisciplineSlug.Cardio:
      return BrowseCategorySlug.Cardio;
    case FitnessDisciplineSlug.Strength:
      return BrowseCategorySlug.Strength;
    case FitnessDisciplineSlug.Stretching:
      return BrowseCategorySlug.Stretching;
    case FitnessDisciplineSlug.Yoga:
      return BrowseCategorySlug.Yoga;
    case FitnessDisciplineSlug.Meditation:
      return BrowseCategorySlug.Meditation;
    case FitnessDisciplineSlug.Walking:
      return BrowseCategorySlug.Walking;
    case FitnessDisciplineSlug.Circuit:
      return BrowseCategorySlug.Bootcamp;
    case FitnessDisciplineSlug.Running:
      return BrowseCategorySlug.Running;
    case FitnessDisciplineSlug.BikeBootcamp:
      return BrowseCategorySlug.BikeBootcamp;
    case FitnessDisciplineSlug.Caesar:
      return BrowseCategorySlug.Rowing;
    case FitnessDisciplineSlug.CaesarBootcamp:
      return BrowseCategorySlug.RowBootcamp;
    default:
      return BrowseCategorySlug.TotalBody;
  }
};

export type FitnessDiscipline = {
  slug: FitnessDisciplineSlug;
  name: string;
};

export const toFitnessDisciplineSchema = () =>
  new schema.Entity(
    'fitnessDisciplines',
    {},
    {
      processStrategy: toFitnessDiscipline,
    },
  );

const toFitnessDiscipline = (apiFitnessDiscipline: any) => ({
  ...apiFitnessDiscipline,
  slug: apiFitnessDiscipline.id,
});
