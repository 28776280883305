import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1, white, tiersGradient, slate3, slate2 } from '@engage/colors';
import { spaces } from '@engage/styles/spaces';
import { body14, body16, label16, title20 } from '@engage/typography';
import { sharedButtonStyles, largeButtonStyles } from '@members/buttons';
import { useTiering, Tiering } from '@members/copy';
import type { Modal, TIERING_MODAL_TYPE } from '../models';
import { useTierContext } from '../TierProvider';
import { getModalName, getQuotaRefreshDate } from '../utils';
import TieringModalView from './TieringModalView';

const NoQuotaAvailableModal: React.FC<
  React.PropsWithChildren<{
    modal: Modal;
    setModal: React.Dispatch<React.SetStateAction<Modal>>;
  }>
> = ({ modal, setModal }) => {
  const { tier } = useTierContext();

  const modalHeaderCopy = useTiering(Tiering.ZeroClassesLeftThisMonth);

  const { quotaRefreshMonthNameShort, quotaRefreshDayOfMonth } = getQuotaRefreshDate(
    tier.limitedClassesResetDate,
  );

  const modalDescriptionCopy = useTiering(Tiering.NoQuotaLimitRefreshDate, {
    maxClasses: <LabelBold>{tier.numLimitedClassesTotal}</LabelBold>,
    monthNameShort: <LabelBold>{quotaRefreshMonthNameShort}</LabelBold>,
    dayOfMonth: <LabelBold>{quotaRefreshDayOfMonth}</LabelBold>,
  });

  const modalSubtitleCopy = useTiering(Tiering.UpgradeToAppPlusNoLimit);
  const upgradeCTACopy = useTiering(Tiering.Upgrade);
  const buttonCopy = useTiering(Tiering.Close);

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const openUpgradeModal = () => {
    setModal({
      ...modal,
      modalType: 'upgrade_modal',
      isOpen: true,
      source: getModalName('quota_available_modal' as TIERING_MODAL_TYPE),
    });
  };

  return (
    <TieringModalView
      modal={modal}
      setModal={setModal}
      modalHeaderChild={<ModalHeader>{modalHeaderCopy}</ModalHeader>}
      modalDescriptionChild={<ModalDescription>{modalDescriptionCopy}</ModalDescription>}
      modalSubtitleCopy={modalSubtitleCopy}
      ctaChildren={
        <>
          <UpgradeCTA onClick={openUpgradeModal}>{upgradeCTACopy}</UpgradeCTA>
          <CTAButton onClick={closeModal}>{buttonCopy}</CTAButton>
        </>
      }
    />
  );
};

const ModalDescription = styled.div`
  ${body14}
  color: ${slate2};
  padding-top: ${spaces.xLarge}px;
  margin-bottom: ${spaces.xLarge}px;
  ${media.tablet`
  ${body16}`}
`;

const ModalHeader = styled.p`
  ${title20}
  color: ${slate3};
  padding-top: ${spaces.xxxLarge}px;
`;

const LabelBold = styled.span`
  font-weight: 900;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const UpgradeCTA = styled.button`
  ${tiersGradient}
  ${sharedButtonStyles}
  ${largeButtonStyles}
  color: ${white};
  margin-top: ${spaces.xLarge}px;
  width: 280px;
  ${media.tablet`
  width: 320px;`}
`;

const CTAButton = styled.button`
  ${label16}
  margin-top: ${spaces.xLarge}px;
  color: ${slate1};
  text-transform: capitalize;
`;

export default NoQuotaAvailableModal;
