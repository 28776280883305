import React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { withExtHrefs } from '@peloton/external-links';
import {
  toCountry,
  Language,
  theUserIsInTheUnitedStates,
  theUserIsInGermany,
} from '@peloton/internationalize';
import { Links } from '@peloton/links';
import { history } from '@peloton/redux';
import { media } from '@peloton/styles';
import { toNowTime } from '@peloton/time';
import type { TrackingComponent } from '@engage/analytics';
import { trackEnhancer } from '@engage/analytics';
import { gray4, slate1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxState } from '@engage/redux';
import { getDisplayLanguage } from '@engage/settings';
import { spaces } from '@engage/styles';
import { link3, link1, label12, label14 } from '@engage/typography';
import * as Analytics from '@members/analytics';
import { SiteCopy, Site, Links as LinksCopyEnum, LinksCopy } from '@members/copy';
import { isProdApiEnv } from '@members/env';
import { Feature, useFeatureToggle } from '@members/feature-toggles';
import { toLanguageModalQuery, LANGUAGE_MODAL } from '@members/modals/LanguageModal';
import { default as LanguageIconSmall } from '@members/modals/LanguageModal/images/LanguageIconSmall';
import { useOneTrust } from '@members/onetrust/useOneTrust';
import SocialLinks from './SocialLinks';

const ANALYTICS_SOURCE = 'Footer';

export const FooterView: React.FC<React.PropsWithChildren<Props>> = ({
  track,
  darkTheme = false,
  ...props
}) => {
  const displayLanguage = useReduxState(getDisplayLanguage);
  const dictLang = `${
    displayLanguage!.charAt(0).toUpperCase() + displayLanguage!.slice(1)
  }Language`;
  const { toggleInfoDisplay, isDoNotSellEnabled } = useOneTrust();

  const openLanguageModal = () => {
    history.push(toLanguageModalQuery(), {
      source: LANGUAGE_MODAL,
    });
  };
  const isLocationPrivacyUpdateEnabled = useFeatureToggle(Feature.FreeSnowDen);
  const onetrustLinkUpdatesEnabled = useFeatureToggle(Feature.Onetrust_link_updates);
  const DNS_INFO_LINK = onetrustLinkUpdatesEnabled
    ? 'https://preferences.onepeloton.com/'
    : 'https://privacyportal.onetrust.com/webform/18f92a28-d2ae-4a6a-8f99-85b4455e22c0/0b31d071-cc72-404b-84d3-6601efbbba18';

  const consumerHealthDataHref =
    isLocationPrivacyUpdateEnabled && isProdApiEnv()
      ? props.healthDataPolicyHref
      : props.healthDataPolicyHref?.replace('.com/', '.com/sandbox/');

  return (
    <Container>
      <SocialLinks darkTheme={darkTheme} />
      <MainLinks
        isGerman={displayLanguage === Language.German}
        data-test-id="footerMainLinks"
      >
        <Link darkTheme={darkTheme} href={props.pelotonHref} needsTopPadding={true}>
          <SiteCopy id={Site.Peloton} />
        </Link>
        <Link
          darkTheme={darkTheme}
          href={props.boutiqueHref}
          handleClick={() => track(Analytics.trackClickBoutique(ANALYTICS_SOURCE))}
          needsTopPadding={true}
        >
          <LinksCopy id={LinksCopyEnum.Boutique} />
        </Link>
        <Link
          darkTheme={darkTheme}
          href={props.studioHref}
          handleClick={() => track(Analytics.trackClickStudio())}
          needsTopPadding={true}
        >
          <LinksCopy id={LinksCopyEnum.Studio} />
        </Link>
        <Link
          darkTheme={darkTheme}
          href={props.blogHref}
          handleClick={() => track(Analytics.trackClickBlog())}
          needsTopPadding={true}
        >
          <LinksCopy id={LinksCopyEnum.Blog} />
        </Link>
        <Link
          darkTheme={darkTheme}
          href={props.supportHref}
          handleClick={() => track(Analytics.trackClickSupport(ANALYTICS_SOURCE))}
          needsTopPadding={true}
        >
          <LinksCopy id={LinksCopyEnum.Support} />
        </Link>
      </MainLinks>
      <SubLinks
        isGerman={displayLanguage === Language.German}
        data-test-id="footerSubLinks"
      >
        <Link darkTheme={darkTheme} href={props.tosHref}>
          <LinksCopy id={LinksCopyEnum.TermsOfService} />
        </Link>
        <Link darkTheme={darkTheme} href={props.membershipTermsHref}>
          <LinksCopy id={LinksCopyEnum.MembershipTerms} />
        </Link>
        {isLocationPrivacyUpdateEnabled && theUserIsInTheUnitedStates(toCountry()) && (
          <Link darkTheme={darkTheme} href={consumerHealthDataHref}>
            <LinksCopy id={LinksCopyEnum.ConsumerHealthDataPolicy} />
          </Link>
        )}
      </SubLinks>
      <SubLinks data-test-id="footerSubLinks2">
        <Link darkTheme={darkTheme} href={props.privacyHref}>
          <LinksCopy id={LinksCopyEnum.PrivacyPolicy} />
        </Link>
        <LinkButton darkTheme={darkTheme} handleClick={toggleInfoDisplay}>
          <LinksCopy id={LinksCopyEnum.CookieSettings} />
        </LinkButton>
        <If condition={isDoNotSellEnabled}>
          <Link darkTheme={darkTheme} href={DNS_INFO_LINK} target="_self">
            <LinksCopy id={LinksCopyEnum.DoNotSellShare} />
          </Link>
        </If>
        {theUserIsInTheUnitedStates(toCountry()) ? (
          <Link darkTheme={darkTheme} href={props.ipPolicyHref}>
            <LinksCopy id={LinksCopyEnum.IpPolicy} />
          </Link>
        ) : null}
        {theUserIsInGermany(toCountry()) ? (
          <Link darkTheme={darkTheme} href={props.impressumHref}>
            <LinksCopy id={LinksCopyEnum.Impressum} />
          </Link>
        ) : null}
        <Link darkTheme={darkTheme} href={props.accessibilityHref}>
          <LinksCopy id={LinksCopyEnum.Accessibility} />
        </Link>
        <LinkButton darkTheme={darkTheme} handleClick={() => openLanguageModal()}>
          <LanguageIconSmall />
          <LinksCopy id={LinksCopyEnum[dictLang]} />
        </LinkButton>
      </SubLinks>
      <Copyright darkTheme={darkTheme}>
        <SiteCopy
          id={toCopyrightId()}
          values={{
            startYear: 2018,
            endYear: toNowTime().year(),
          }}
        />
      </Copyright>
    </Container>
  );
};

const toCopyrightId = () => {
  const country = toCountry();
  return theUserIsInTheUnitedStates(country)
    ? Site.Copyright
    : Site[`Copyright${country.toUpperCase()}`];
};

const Container = styled.footer`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: ${spaces.huge}px;
  padding-bottom: 80px;
  width: 100%;
  ${media.desktop`
    margin-top: 60px;
  `}
`;

const MainLinks = styled.ul<{ isGerman?: boolean }>`
  ${label14}
  text-align: center;
  padding: 0px 10px 0px 10px;

  && li:nth-last-child(2) {
    margin-right: 0px;
  }
  && li:last-child {
    display: block;
  }
  ${media.smallScreen`
    && li:last-child {
      display: inline-block;
    }
    && li:nth-last-child(2) {
      margin-right: ${spaces.large}px;
    }
  `};
`;

const SubLinks = styled.ul<{ isGerman?: boolean }>`
  ${label12}
  margin-top: 10px;
  text-align: center;
  ${p => (p.isGerman ? 'display: flex; flex-direction:column' : '')};
  && li:last-child {
    padding-top: ${p => (p.isGerman ? '10px' : '0px')};
    margin-right: 0px;
  }
  && li {
    margin-right: ${p => (p.isGerman ? '0px' : `${spaces.large}px`)};
  }
  ${media.tablet<{ isGerman?: boolean }>`
    ${p => (p.isGerman ? 'display: block;' : '')};
    && li {
      margin-right: ${spaces.large}px;
    }
  `}
`;

const Link: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <LinkWrapper needsTopPadding={props.needsTopPadding}>
    <StyledLink
      href={props.href}
      target={props.target ?? '_blank'}
      rel="noopener"
      darkTheme={props.darkTheme}
      onClick={props.handleClick}
    >
      {props.children}
    </StyledLink>
  </LinkWrapper>
);

const LinkButton: React.FC<React.PropsWithChildren<LinkButtonProps>> = props => (
  <LinkWrapper>
    <StyledLinkText
      darkTheme={props.darkTheme}
      onClick={props.handleClick}
      data-test-id="footerLinkButton"
    >
      {props.children}
    </StyledLinkText>
  </LinkWrapper>
);

const StyledLink = styled.a<{ darkTheme: boolean }>`
  ${props => (props.darkTheme ? link3 : link1)}
  color: ${props => (props.darkTheme ? gray4 : slate1)};
`;

const StyledLinkText = styled.button<{ darkTheme: boolean }>`
  ${label12}
  ${props => (props.darkTheme ? link3 : link1)}
  color: ${props => (props.darkTheme ? gray4 : slate1)};
`;

const LinkWrapper = styled.li<{ needsTopPadding?: boolean }>`
  display: inline-block;
  padding-top: ${props => (props.needsTopPadding ? '10px' : '0px')};
  &:not(:last-child) {
    margin-right: ${spaces.large}px;
  }
`;

const Copyright = styled.p<{ darkTheme: boolean }>`
  ${label12}
  color: ${props => (props.darkTheme ? gray4 : slate1)};
  padding-top: 13px;
`;

type Props = TrackingComponent<{ source?: string }> & {
  pelotonHref: string;
  boutiqueHref: string;
  studioHref: string;
  blogHref: string;
  privacyHref: string;
  tosHref: string;
  supportHref: string;
  impressumHref: string;
  membershipTermsHref: string;
  ipPolicyHref: string;
  accessibilityHref: string;
  healthDataPolicyHref: string;
  darkTheme?: boolean;
};

type LinkProps = {
  href: string;
  darkTheme: boolean;
  handleClick?(): void;
  needsTopPadding?: boolean;
  target?: string;
};

type LinkButtonProps = {
  darkTheme: boolean;
  handleClick?(): void;
  needsTopPadding?: boolean;
};

export default compose<Props, { darkTheme?: boolean }>(
  withExtHrefs({
    pelotonHref: Links.peloton,
    boutiqueHref: Links.boutique,
    studioHref: Links.oldStudioHome,
    blogHref: Links.blog,
    privacyHref: Links.privacy,
    tosHref: Links.tos,
    supportHref: Links.support,
    impressumHref: Links.impressum,
    membershipTermsHref: Links.membershipTerms,
    ipPolicyHref: Links.ipPolicy,
    accessibilityHref: Links.accessibility,
    healthDataPolicyHref: Links.healthDataPolicy,
  }),
  trackEnhancer,
)(FooterView);
