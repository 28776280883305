import { engageApi } from '@members/data-fetch';

export const blockAllTags = async (
  api: typeof engageApi = engageApi,
  userId: string,
  tagsToBlock: string[],
) =>
  await api.axios.post(`api/self-moderation/${userId}/blocked/tags/block`, tagsToBlock);

export const blockUser = async (
  api: typeof engageApi = engageApi,
  userId: string,
  userToBlockId: string,
) => {
  await api.axios.put(
    `api/self-moderation/${userId}/blocked/users/${userToBlockId}/block`,
  );
};

export const unblockUser = async (
  api: typeof engageApi = engageApi,
  userId: string,
  userToUnblockId: string,
) => {
  await api.axios.put(
    `api/self-moderation/${userId}/blocked/users/${userToUnblockId}/unblock`,
  );
};

export const batchUnblockUsers = async (
  api: typeof engageApi = engageApi,
  userId: string,
  userToUnblockIds: string[],
) => {
  await api.axios.post(
    `api/self-moderation/${userId}/blocked/users/unblock`,
    userToUnblockIds,
  );
};

export const blockTag = async (
  api: typeof engageApi = engageApi,
  userId: string,
  tagToBlockId: string,
) => {
  await api.axios.put(`api/self-moderation/${userId}/blocked/tags/${tagToBlockId}/block`);
};

export const unblockTag = async (
  api: typeof engageApi = engageApi,
  userId: string,
  tagToUnblockId: string,
) => {
  await api.axios.put(
    `api/self-moderation/${userId}/blocked/tags/${tagToUnblockId}/unblock`,
  );
};

export const batchUnblockTags = async (
  api: typeof engageApi = engageApi,
  userId: string,
  tagToUnblockIds: string[],
) => {
  await api.axios.post(
    `api/self-moderation/${userId}/blocked/tags/unblock`,
    tagToUnblockIds,
  );
};
