export const toTrackViewed = (freeTrialDaysLeft: string | number = 'Expired') => ({
  event: 'Viewed Free Trial Notification',
  freeTrialDaysLeft,
});

export enum UpgradeSource {
  Global = 'Global Banner',
  Settings = 'Settings Banner',
}

export const toTrackClickUpgrade = (
  freeTrialDaysLeft: string | number = 'Expired',
  source: UpgradeSource = UpgradeSource.Global,
) => ({
  event: 'Clicked Upgrade Now',
  source,
  freeTrialDaysLeft,
});

export const toTrackDismissedBanner = (
  freeTrialDaysLeft: string | number = 'Expired',
) => ({
  event: 'Dismissed Free Trial Notification',
  freeTrialDaysLeft,
});
