import { css } from 'styled-components';
import { backgroundGray, slate3 } from '@engage/colors';
import { interV } from '@engage/typography';

export const membersTypographyReset = css`
  font-family: ${interV};
  color: ${slate3};
`;

const disableTextSizeAdjustment = css`
  html {
    -webkit-text-size-adjust: 100%;
  }
`;

export const membersReset = css`
  ${disableTextSizeAdjustment}
  body {
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    background-color: ${backgroundGray};
    ${membersTypographyReset}
  }

  html,
  body,
  #root {
    height: 100%;
  }

  input,
  input[type='text'] {
    appearance: none;
    font: inherit;
  }
  button {
    cursor: pointer;
  }
`;
