// Logic for targeting envs is duplicated in the index.html for new relic.
// If you are changing something here, you might have to change it there as well
declare global {
  interface Window {
    Cypress: any;
  }
}
const maybeHostname = typeof window === 'undefined' ? '' : window.location.hostname;

export const isStagingEnv = (hostname = maybeHostname) =>
  /__env-stg__/.test(hostname) || /^stg/.test(hostname);
export const isProdEnv = (hostname?: string) => {
  if (typeof window === 'undefined') return true;

  return /^members\.onepeloton/.test(hostname ?? maybeHostname);
};
export const isUatEnv = (hostname = maybeHostname) =>
  /((secret|test|uat|xtest)\.onepeloton|(--)?members-onepeloton-com.netlify.app)/.test(
    hostname,
  );
export const isLocalEnv = (hostname = maybeHostname) => /^local/.test(hostname);
export const isDeployPreviewEnv = (hostname = maybeHostname) =>
  /^deploy-preview/.test(hostname);

export const isLightFeatureToggleState = (hostname = maybeHostname) =>
  isStagingEnv(hostname) ||
  hostname.indexOf('toggle-light') > -1 ||
  hostname.indexOf('--lit') > -1;

export const isCypressTest = () => {
  if (typeof window === 'undefined') return false;
  return !!window.Cypress;
};
