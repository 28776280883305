import React from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { getUserId } from '@peloton/auth';
import { history } from '@peloton/redux';
import { media } from '@peloton/styles/breakpoints';
import { track } from '@engage/analytics';
import { white, slate3 } from '@engage/colors';
import { useReduxAction, useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { title24, body16 } from '@engage/typography';
import { button1Styles, largeButtonStyles } from '@members/buttons';
import { SettingsCopy, Settings } from '@members/copy';
import { UsersFetchers, tooltipsApi } from '@members/data-fetch';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import { isPathAllowed, Modal } from '@members/modal';
import { trackViewedPrivacyPreferencesModal } from '@members/pg-homescreen/analytics';
import { setTooltipSeen } from '@members/pg-homescreen/api';
import { useTooltipsData } from '@members/pg-homescreen/hooks/useTooltipsData';
import { useIsBreakpointLessThan } from '@members/responsive';

const tooltipId = 'vppa_onboarding_tooltip';

const PrivacyPreferencesModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isMobile = useIsBreakpointLessThan('tablet');
  const userId = useReduxState(getUserId);
  const privacyUpdatesEnabled = useFeatureToggle(Feature.Vppa);
  const { data: seenTooltip, mutate } = useTooltipsData('vppa_onboarding_tooltip');
  const showTooltip = seenTooltip === false && privacyUpdatesEnabled;
  const [isOpen, setOpen] = React.useState(false);
  const trackAnalytics = useReduxAction(track);
  const { data: user, isValidating } = useSWR(
    ...UsersFetchers.GetUser({
      id: userId,
      pelotonPlatform: 'web',
    }),
  );

  const shouldShowModal = !!user && isPathAllowed(window.location.pathname);

  const onClose = async () => {
    setOpen(false);
    if (userId) {
      await setTooltipSeen(tooltipsApi, userId, tooltipId);
      mutate();
    }
  };

  React.useEffect(() => {
    if (!isValidating && shouldShowModal && showTooltip) {
      setOpen(true);
      trackAnalytics(trackViewedPrivacyPreferencesModal());
    }
  }, [shouldShowModal, isValidating, showTooltip]);

  return (
    <Modal
      isOpen={isOpen}
      closeHandler={() => onClose()}
      style={toOverriddenStyles(isMobile)}
      contentLabel="PrivacyPreferences"
    >
      <BackgroundContainer>
        <ContentContainer>
          <Title data-test-id="vppaToolTipHeader">
            <SettingsCopy id={Settings.VppaToolTipHeader} />
          </Title>
          <Description data-test-id="vppaToolTipDetails">
            <SettingsCopy id={Settings.VppaToolTipDetailsOne} />
            <br />
            <br />
            <SettingsCopy id={Settings.VppaToolTipDetailsTwo} />
          </Description>
        </ContentContainer>
        <DoneButtonContainer>
          <DoneButton
            data-test-id="vppaToolTipCta"
            onClick={() => {
              onClose();
              history.push('/preferences/settings');
            }}
          >
            <SettingsCopy id={Settings.VppaToolTipCta} />
          </DoneButton>
        </DoneButtonContainer>
      </BackgroundContainer>
    </Modal>
  );
};

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  z-index: 4;
  padding: 32px;

  ${media.tablet`
    width: 480px;
    border-radius: ${spaces.small}px;
    max-height: 450px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
  color: ${slate3};
`;

const ContentContainer = styled.div`
  ${media.tablet`
    overflow: scroll;
    overflow-x: hidden;
  `}
`;

const Title = styled.h1`
  ${title24};
  margin-bottom: ${spaces.small}px;
`;

const Description = styled.h1`
  ${body16};
  font-weight: 500;
`;

const DoneButtonContainer = styled.div`
  background: linear-gradient(rgba(255, 255, 255, 0), ${white});
  border-radius: 0 0 ${spaces.small}px ${spaces.small}px;
  position: relative;
`;

const DoneButton = styled.button`
  ${button1Styles}
  ${largeButtonStyles}
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${spaces.xxxLarge}px auto 0px auto;
`;

const tabletStyleOverrides = {
  content: {
    maxWidth: '520px',
  },
};

const toOverriddenStyles = (isMobile: boolean) => {
  if (isMobile) {
    return {};
  }

  return tabletStyleOverrides;
};
export default PrivacyPreferencesModal;
