import React from 'react';
import styled from 'styled-components';
import { paleBlue, blue, slate3, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { title24, body14 } from '@engage/typography';
import { TagsCopy, Tags } from '@members/copy';
import { HashTag } from '../icons';
import { ScrollContext, BROWSE_NAV_BAR_HEIGHT } from '../MainView/ScrollContext';

export const NoTagsView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setNavBarAnimation } = React.useContext(ScrollContext);
  return (
    <Container
      onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
        if (e.deltaY < 0) {
          setNavBarAnimation(e.deltaY);
        }
      }}
    >
      <ContentContainer>
        <HashIcon
          topHashColor={paleBlue}
          bottomHashColor={blue}
          data-test-id="noTagsViewHashIcon"
        />
        <MainText data-test-id="noTagsMainText">
          <TagsCopy id={Tags.NoTagsFound} unorphanCount={2} />
        </MainText>
        <Description data-test-id="noTagsDescriptionText">
          <TagsCopy id={Tags.NoTagsFoundMessage} unorphanCount={2} />
        </Description>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${spaces.huge}px 0;
`;

const HashIcon = styled(HashTag)`
  height: 64px;
  width: 64px;
`;

const MainText = styled.h2`
  ${title24}
  font-weight: 100;
  color: ${slate3};
  margin: ${spaces.medium}px ${spaces.large}px 0;
  max-width: 400px;
`;

const Description = styled.p`
  max-width: 400px;
  ${body14}
  color: ${slate1};
  margin: ${spaces.medium}px ${spaces.large}px 0;
`;

const ContentContainer = styled.div`
  padding-bottom: ${BROWSE_NAV_BAR_HEIGHT}px;
`;
