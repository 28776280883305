import { pluck } from 'ramda';
import type { Reducer } from 'redux';
import type { BrowseCategorySlug } from '@engage/browse-categories';
import type { Class } from '@engage/classes';
import type { FilterAction, SortAction } from '@engage/filters';
import { AppliedActionTypes, SortActionTypes } from '@engage/filters';
import type { PageNumber } from '@engage/pagination';
import type { PendingResult } from '@engage/result';
import { pending, ok, isOk, error as engageError } from '@engage/result';

export const classIdsReducer: Reducer<LibraryState> = (
  state: LibraryState = pending,
  action: LibraryAction,
) => {
  switch (action.type) {
    case LibraryActionTypes.AddClassesFailure:
      if (state === pending) {
        return engageError(undefined);
      } else if (isOk(state)) {
        if (typeof action.pageNumber !== 'number') {
          throw new Error(
            'Page number must be provided if there is a failure loading next classes',
          );
        }
        return ok({
          ...state.value,
          nextPage: engageError({ page: action.pageNumber }),
        });
      }
      return state;

    case LibraryActionTypes.AddClasses:
      if (state === pending) {
        return ok({
          classIds: action.payload.ids,
          nextPage: ok(action.payload.nextPage),
          pageCount: action.payload.pageCount,
          totalClassesCount: action.payload.totalClassesCount,
        });
      } else if (isOk(state)) {
        return ok({
          classIds: state.value.classIds.concat(action.payload.ids),
          nextPage: ok(action.payload.nextPage),
          pageCount: action.payload.pageCount,
          totalClassesCount: action.payload.totalClassesCount,
        });
      }
      return state;

    case LibraryActionTypes.LoadingMoreClasses:
      if (isOk(state)) {
        return ok({
          ...state.value,
          nextPage: pending,
        });
      }
      return pending;

    case LibraryActionTypes.RequestNewClasses:
    case AppliedActionTypes.Toggle:
    case AppliedActionTypes.ClearAll:
    case AppliedActionTypes.Override:
    case SortActionTypes.Sort:
      return pending;

    default:
      return state;
  }
};

// Actions

export enum LibraryActionTypes {
  RequestNewClasses = 'pelo/library/INITIALIZE',
  AddClasses = 'pelo/page/library/classes/ADD',
  AddClassesFailure = 'pelo/page/library/classes/ADD_FAILURE',
  RequestMoreClasses = 'pelo/page/library/classes/REQUEST_NEXT_PAGE',
  LoadingMoreClasses = 'pelo/page/library/classes/LOADING_NEXT_PAGE',
}

export const requestNewClasses = (categorySlug: BrowseCategorySlug) => ({
  type: LibraryActionTypes.RequestNewClasses,
  payload: { categorySlug },
});

export type LoadLibraryPageAction = {
  type: LibraryActionTypes.RequestNewClasses;
  payload: { categorySlug: BrowseCategorySlug };
};

export const requestMoreClasses = () => ({
  type: LibraryActionTypes.RequestMoreClasses,
});

export type RequestMoreClassesAction = {
  type: LibraryActionTypes.RequestMoreClasses;
};

export const loadingMoreClasses = () => ({
  type: LibraryActionTypes.LoadingMoreClasses,
});

export type LoadingMoreClassesAction = {
  type: LibraryActionTypes.LoadingMoreClasses;
};

export const addClasses = (
  classes: Class[],
  nextPage: PageNumber,
  totalClassesCount: number,
  pageCount: number,
) => ({
  type: LibraryActionTypes.AddClasses,
  payload: { ids: pluck('id')(classes), nextPage, totalClassesCount, pageCount },
});

type SuccessAction = {
  type: LibraryActionTypes.AddClasses;
  payload: {
    ids: string[];
    nextPage: PageNumber;
    pageCount: number;
    totalClassesCount: number;
  };
};

export const addClassesFailure = (pageNumber: number | undefined) => ({
  type: LibraryActionTypes.AddClassesFailure,
  pageNumber,
});

type FailureAction = {
  type: LibraryActionTypes.AddClassesFailure;
  pageNumber: number | undefined;
};

type LibraryAction =
  | FailureAction
  | FilterAction
  | SortAction
  | RequestMoreClassesAction
  | LoadingMoreClassesAction
  | SuccessAction
  | LoadLibraryPageAction;

export type LibraryState = PendingResult<
  {
    classIds: string[];
    totalClassesCount: number;
    pageCount: number;
    nextPage: PendingResult<PageNumber, { page: number }>;
  },
  undefined
>;
