import React from 'react';

const FacebookIconSolid: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      d="M10.43059,16V10.3008h1.83872l.44352-2.37568h-2.2624V6.183a.50922.50922,0,0,1,.45235-.56036q.024-.00256.04813-.00284h1.30816V3.2H9.81555a2.468,2.468,0,0,0-2.087,2.21632v2.4416H6.31667v2.40576l1.42208.0096V16H3.91667a4,4,0,0,1-4-4V4a4,4,0,0,1,4-4h8a4,4,0,0,1,4,4v8a4,4,0,0,1-4,4Z"
      transform="translate(0.08333 0)"
    />
  </svg>
);

export default FacebookIconSolid;
