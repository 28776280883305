import type { App } from './App';

enum AnalyticsEventName {
  ViewedGetAppPage = 'Viewed Download Our Apps',
  TappedDownloadOurApp = 'Tapped Download Our App',
  DismissedDownloadOutApp = 'Dismissed Download Our App',
}

export const trackViewedGetAppPage = (appsDisplayed: App[], source: string) => ({
  event: AnalyticsEventName.ViewedGetAppPage,
  source,
  appsDisplayed,
});

export const trackTappedDownloadOurApp = (
  appsDisplayed: App[],
  app: App,
  source: string,
) => ({
  event: AnalyticsEventName.TappedDownloadOurApp,
  source,
  appsDisplayed,
  app,
});

export const trackDismissedDownloadOurApp = (appsDisplayed: App[], source: string) => ({
  event: AnalyticsEventName.DismissedDownloadOutApp,
  source,
  appsDisplayed,
});
