import React from 'react';
import { getSignedInUsername } from '@peloton/auth';
import { isNil } from '@peloton/helpers/isNil';
import { isDefined } from '@peloton/types';
import type { MemberCard } from '@engage/members';
import { RelationshipCategory, MemberListGroup } from '@engage/members';
import { useReduxState } from '@engage/redux';
import { Oops } from '@members/errors';
import type { TagDetailQuery } from '@members/graphql-swr/schemas/tags/queries/TagDetail.generated';
import type { FollowStatus } from '@members/graphql-swr/types.generated';
import { useTrackViewedDetails } from '../analytics';
import {
  useTagNameFromLocation,
  useOnMemberFollow,
  useTagDetailsPaginatedQuery,
} from '../hooks';
import { getTagViewFromLocation } from '../selectors';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import { TagDetailsView } from './TagDetailsView';

export const TagDetails: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setLastActiveTagName } = React.useContext(TagsContext);
  const tagName = decodeURI(useTagNameFromLocation());
  const view = useReduxState(getTagViewFromLocation);
  const {
    called,
    data,
    error,
    loadMore,
    mutate: refetch,
    isLoading: loading,
    isLoadingMore,
  } = useTagDetailsPaginatedQuery({
    tagName,
  });

  const members = fromUserEdgesToMemberCard(data?.tag?.users?.edges ?? []);
  const signedInUsername = useReduxState(getSignedInUsername);
  const isAdded = !isNil(
    data?.tag?.users?.edges?.find(edge => edge.node.username === signedInUsername),
  );
  const correctedTagName = data?.tag?.name ?? tagName;
  const hasNextPage = data?.tag?.users.pageInfo.hasNextPage ?? false;
  const numberOfMembers = data?.tag?.users?.edges?.length ?? -1; // Prevent body from setting initialLoadComplete to true when still loading numberOfMembers
  const { numberOfFollowing, onMemberFollow } = useOnMemberFollow(
    data?.tag?.followingCount,
  );

  React.useEffect(() => {
    if (!called && view === TagModalView.TagDetails && isValidTagName(tagName)) {
      refetch();
    }
  }, [view, tagName]);

  // For analytics
  const fetched = called && !loading;
  useTrackViewedDetails(fetched, numberOfMembers);

  React.useEffect(() => {
    if (isValidTagName(tagName)) {
      setLastActiveTagName(tagName);
    }
  }, []);

  if (data?.tag === null && !loading && !error) {
    return <Oops />;
  }

  return (
    <TagDetailsView
      onMemberFollow={onMemberFollow}
      error={!isNil(error)}
      hasNextPage={hasNextPage}
      isAdded={isAdded}
      isLoadingMore={isLoadingMore}
      loading={loading}
      loadMore={loadMore}
      members={members}
      numberOfFollowing={numberOfFollowing}
      numberOfMembers={numberOfMembers}
      refetch={refetch}
      tagName={correctedTagName}
      detailBackgroundImageUrl={
        data?.tag?.assets.detailBackgroundImage.location ?? undefined
      }
    />
  );
};

const followStatusToRelationshipCategory = (
  followStatus: FollowStatus,
): RelationshipCategory => {
  switch (followStatus) {
    case 'FOLLOWING':
      return RelationshipCategory.Following;
    case 'FOLLOW_PENDING':
      return RelationshipCategory.Pending;
    case 'SELF':
      return RelationshipCategory.Self;
    case 'NONE':
    default:
      return RelationshipCategory.None;
  }
};

type UserEdges = NonNullable<TagDetailQuery['tag']>['users']['edges'];

export const fromUserEdgesToMemberCard = (userEdges: UserEdges): MemberCard[] => {
  return userEdges.map(userEdge => {
    const meToUserRelationship = followStatusToRelationshipCategory(
      userEdge.node.followStatus,
    );

    const isProfilePrivate =
      userEdge.node.protectedFields?.__typename === 'UserPrivacyError';

    return {
      id: userEdge.node.id,
      username: userEdge.node.username,
      imageUrl: userEdge.node.assets?.image?.location ?? '',
      meToUserRelationship,
      listGroup: MemberListGroup.Other,
      listName: 'Tag Detail',
      isProfilePrivate,
      onFollowClick: () => null,
    };
  });
};

const isValidTagName = (tagName: string) => isDefined(tagName) && tagName !== '';
