import { prop, compose } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { isDefined } from '@peloton/types';
import { DifficultyLevel } from '@engage/classes';
import { yellow, slate1, teal, orange } from '@engage/colors';
import { label12Caps } from '@engage/typography';
import { Classes, ClassesCopy } from '@members/copy';

const DifficultyLabel: React.FC<React.PropsWithChildren<Props>> = ({
  size,
  level,
  ...elementProps
}) => {
  if (isDefined(level)) {
    return (
      <Wrapper {...elementProps} size={size}>
        <Indicator level={level!} />
        <ClassesCopy id={toLevelKey(level)} />
      </Wrapper>
    );
  } else {
    return null;
  }
};

const toLevelKey = (level: DifficultyLevel) => {
  switch (level) {
    case DifficultyLevel.Advanced:
      return Classes.DifficultyAdvanced;
    case DifficultyLevel.Intermediate:
      return Classes.DifficultyIntermediate;
    case DifficultyLevel.Beginner:
    default:
      return Classes.DifficultyBeginner;
  }
};

const indicatorDimensions = (size: Size) => {
  switch (size) {
    case Size.Small:
      return 7;
    case Size.Medium:
      return 7.5;
    case Size.Large:
    default:
      return 9;
  }
};

const indicatorColor = (level: DifficultyLevel) => {
  switch (level) {
    case DifficultyLevel.Beginner:
      return teal;
    case DifficultyLevel.Intermediate:
      return yellow;
    case DifficultyLevel.Advanced:
    default:
      return orange;
  }
};

export const Indicator = styled.span<{ level: DifficultyLevel }>`
  display: inline-block;
  background-color: ${compose(indicatorColor, prop('level'))};
  border-radius: 1.5px;
  margin-right: 5px;
`;

const Wrapper = styled.p<{ size: Size }>`
  color: ${slate1};
  ${label12Caps};
  & ${Indicator} {
    height: ${compose(indicatorDimensions, prop('size'))}px;
    width: ${compose(indicatorDimensions, prop('size'))}px;
  }
`;

export enum Size {
  Small,
  Medium,
  Large,
}

type Props = {
  level?: DifficultyLevel;
  size: Size;
} & React.HTMLAttributes<HTMLParagraphElement>;

export default DifficultyLabel;
