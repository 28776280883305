import type { PendingResult } from '@engage/result';
import { ok, error, pending } from '@engage/result';
import type { ChallengeFriend } from '../models';

// Action Types

export enum ActionType {
  LoadChallengeFriendsRequest = '@engage/challenges/LOAD_CHALLENGE_FRIENDS_REQUEST',
  LoadChallengeFriendsFailure = '@engage/challenges/LOAD_CHALLENGE_FRIENDS_FAILURE',
  LoadChallengeFriendsSuccess = '@engage/challenges/LOAD_CHALLENGE_FRIENDS_SUCCESS',
}

// Action Creators

export const loadChallengeFriendsRequest = (challengeId: string) =>
  ({
    type: ActionType.LoadChallengeFriendsRequest,
    payload: { challengeId },
  } as const);

export const loadChallengeFriendsFailure = () =>
  ({
    type: ActionType.LoadChallengeFriendsFailure,
  } as const);

export const loadChallengeFriendsSuccess = (friends: ChallengeFriend[]) =>
  ({
    type: ActionType.LoadChallengeFriendsSuccess,
    payload: { friends },
  } as const);

export type LoadChallengeFriendsRequest = ReturnType<typeof loadChallengeFriendsRequest>;
export type LoadChallengeFriendsFailure = ReturnType<typeof loadChallengeFriendsFailure>;
export type LoadChallengeFriendsSuccess = ReturnType<typeof loadChallengeFriendsSuccess>;

export type Action =
  | LoadChallengeFriendsRequest
  | LoadChallengeFriendsFailure
  | LoadChallengeFriendsSuccess;

// Reducer

export type State = PendingResult<ChallengeFriend[], undefined>;
export const DEFAULT_STATE: State = pending;

export const challengeFriendsReducer = (state: State = DEFAULT_STATE, action: Action) => {
  switch (action.type) {
    case ActionType.LoadChallengeFriendsRequest:
      return pending;
    case ActionType.LoadChallengeFriendsSuccess:
      return ok(action.payload.friends);
    case ActionType.LoadChallengeFriendsFailure:
      return error(undefined);
    default:
      return state;
  }
};
