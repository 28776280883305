import styled, { keyframes } from 'styled-components';

const slideUpAndDisappear = keyframes`
  0% {
    top: 60px;
    visibility: initial;
  }

  99.99% {
    top: -100vh;
    visibility: initial;
  }

  100% {
    top: -100vh;
    visibility: hidden;
  }
`;

const appearAndSlideIn = keyframes`
   0% {
    top: -100vh;
    visibility: hidden;
  }

  00.01% {
    top: -100vh;
    visibility: initial;
  }

  100% {
    top: 60px;
    visibility: initial;
  }
`;

export const DRAWER_OPEN_CLASS = 'mobile-nav-drawer--open';
export const DRAWER_CLOSED_CLASS = 'mobile-nav-drawer--closed';

export const SlideUpDrawerContainer = styled.div`
  body.allow-menu-animation & .${DRAWER_OPEN_CLASS} {
    animation: 0.25s ${appearAndSlideIn} ease;
    animation-play-state: running;
    top: 60px;
    visibility: initial;
  }

  body:not(.allow-menu-animation) & .${DRAWER_OPEN_CLASS} {
    animation-play-state: paused;
    top: 60px;
    visibility: initial;
  }

  body.allow-menu-animation & .${DRAWER_CLOSED_CLASS} {
    animation: 0.25s ${slideUpAndDisappear} ease;
    animation-play-state: running;
    top: -100vh;
    visibility: hidden;
  }

  body:not(.allow-menu-animation) & .${DRAWER_CLOSED_CLASS} {
    animation-play-state: paused;
    top: -100vh;
    visibility: hidden;
  }
  // TODO: figure out how to fix this bug with iPad without disabling the drawer.
  // Prevent z-index stacking issues from other 3d translated elements on iPad OS
  //transform: translate3d(0px, 0px, 0px);
`;
