import { createStatsToken } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

export const toStatsTokenFetchers = (api: typeof engageApi = engageApi) => ({
  CreateStatsToken: toFetcher(
    () => 'CreateStatsToken',
    () => unwrap(() => createStatsToken(api)),
  ),
});
