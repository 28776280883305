import React from 'react';

const PlayIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 28 28" {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <path
        d="M23.3870324,12.1022967 C25.1944355,13.1466985 25.1944355,14.8547015 23.3870324,15.8977033 L8.04720582,24.7541186 C6.23840269,25.7985204 4.76000013,24.9445189 4.76000013,22.8571153 L4.76000013,5.14288467 C4.76000013,3.05548105 6.23840269,2.20147957 8.04720582,3.24588138 L23.3870324,12.1022967 Z"
        fill="#ffffff"
      />
    </g>
  </svg>
);

export default PlayIcon;
