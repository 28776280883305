import React from 'react';
import styled from 'styled-components';
import { toLanguageFromLocale, Language } from '@peloton/internationalize';
import { getIsBreakpointGreaterThan } from '@peloton/responsive';
import { media } from '@peloton/styles';
import { toTime } from '@peloton/time';
import { slate1, gray5, slate3, white } from '@engage/colors';
import type { ContractAgreement } from '@engage/members/models/Member';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { link1, body14, label16Caps, label14Caps } from '@engage/typography';
import { LocalizedSlashedDate, useLocale } from '@members/localization';

export type Contract = {
  href: string;
  type: string;
  displayName: string;
  createdAt: number;
  id: string;
  agreedAt: number;
} & Pick<ContractAgreement, 'requiredMemberAction'>;

type LinkProps = {
  href: string;
  reorientText: boolean;
  handleClick?(): void;
};

const shouldReorientText = (lang: Language, isLargeScreen: boolean) =>
  lang === Language.German && !isLargeScreen;

export const TermButton: React.FC<
  React.PropsWithChildren<Contract & Pick<LinkProps, 'handleClick'>>
> = ({ href, displayName, createdAt, handleClick }) => {
  const locale = useLocale();
  const lang = toLanguageFromLocale(locale);
  const isLargeScreen = useReduxState(getIsBreakpointGreaterThan, 'desktopLarge');
  const reorientText = React.useMemo(() => shouldReorientText(lang, isLargeScreen), [
    lang,
    isLargeScreen,
  ]);

  return (
    <Link href={href} handleClick={handleClick} reorientText={reorientText}>
      <TermText reorientText={reorientText}>{displayName}</TermText>
      <DateContainer reorientText={reorientText}>
        <LocalizedSlashedDate date={toTime(createdAt)} />
      </DateContainer>
    </Link>
  );
};

const StyledLink = styled.a<{ reorientText: boolean }>`
  background-color: ${white};
  padding: ${spaces.large}px;
  border-radius: ${spaces.xxxSmall + 1}px;
  font-size: 13px;
  ${link1}
  color: ${slate1};
  display: flex;
  flex-direction: ${p => (p.reorientText ? 'column' : 'row')};
  justify-content: space-between;
  align-items: ${p => (p.reorientText ? '' : 'center')};
  &:not(:last-child) {
    margin-bottom: ${spaces.xSmall}px;
  }

  width: 100%;
`;

const Link: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <StyledLink
    href={props.href}
    target="_blank"
    rel="noopener"
    onClick={props.handleClick}
    reorientText={props.reorientText}
  >
    {props.children}
  </StyledLink>
);

const TermText = styled.p<{ reorientText: boolean }>`
  ${label14Caps}
  color: ${slate3};
  width: ${p => (p.reorientText ? '164px' : '100%')};
  margin-right: ${spaces.xxSmall}px;
  hyphens: auto;

  ${media.tablet`
    ${label16Caps}
  `};
`;

const DateContainer = styled.div<{ reorientText: boolean }>`
  ${body14}
  color: ${gray5};
  margin-top: ${p => (p.reorientText ? spaces.xxxSmall : 0)}px;
`;
