// TODO: this should potentially be owned analytics-middleware
import { call, select } from 'redux-saga/effects';
import type { User } from '@peloton/auth';
import { isSignedIn, getUser } from '@peloton/auth';
import { identify } from '@members/analytics';

const toFormattedLinkedAccountName = (socialProviderName?: string) => {
  if (socialProviderName === 'apple') {
    return 'Apple';
  } else if (socialProviderName === 'google-oauth2') {
    return 'Google';
  }
  return '';
};

export const analyticsIdentifySaga = function* () {
  const user: User = yield select(getUser);

  if (isSignedIn(user)) {
    yield call(identify, {
      id: user.id,
      linkedSocialAccount: toFormattedLinkedAccountName(
        (user as any)?.socialConnections?.[0]?.socialProviderName,
      ),
    });
  }
};
