import { filter } from 'ramda';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSite, Site } from '@members/copy';
import { useLocale } from '@members/localization';

type Props = { titleSegments?: string[] };

const DocumentTitle: React.FC<React.PropsWithChildren<{ title: string }>> = ({
  title,
}) => <Helmet htmlAttributes={{ lang: useLocale() }} title={title} />;

export const PageTitle: React.FC<React.PropsWithChildren<Props>> = ({
  titleSegments = [],
}) => <DocumentTitle title={toJoinedTitle(titleSegments.concat(useSite(Site.Title)))} />;

export const toJoinedTitle = (sections: string[]) =>
  filter(Boolean, sections).join(' | ');

export default DocumentTitle;
