import React from 'react';
import { mutate as swrMutate } from 'swr';
import { TagsFetchersGQL } from '@members/data-fetch/fetchers.production';
import type { Headers } from '@members/data-fetch/graphql-fetchers/utils';
import type {
  SetTagAsPrimaryMutation,
  SetTagAsPrimaryMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/SetTagAsPrimary.generated';

export const useSetTagAsPrimaryMutationSWR = (
  tagName: string,
  onComplete: (data: SetTagAsPrimaryMutation) => void,
  refetchQueries: () => void,
  headers?: Headers,
) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const wrappedMutate = (input?: SetTagAsPrimaryMutationVariables) => {
    setIsLoading(true);
    return swrMutate(
      ...TagsFetchersGQL.SetTagAsPrimaryMutation(
        input ?? { input: { tagName } },
        headers,
      ),
    )
      .then(data => {
        if (data) {
          onComplete(data);
        }
      })
      .finally(() => {
        refetchQueries();
        setIsLoading(false);
      });
  };

  return { isLoading, mutate: wrappedMutate };
};
