/* eslint-disable */
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type ModifyStacksViewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModifyStacksViewQuery = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'modifyStacksView'>
);


export const ModifyStacksViewDocument = gql`
    query ModifyStacksView {
  modifyStacksView @client
}
    `;

/**
 * __useModifyStacksViewQuery__
 *
 * To run a query within a React component, call `useModifyStacksViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useModifyStacksViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModifyStacksViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useModifyStacksViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModifyStacksViewQuery, ModifyStacksViewQueryVariables>) {
        return ApolloReactHooks.useQuery<ModifyStacksViewQuery, ModifyStacksViewQueryVariables>(ModifyStacksViewDocument, baseOptions);
      }
export function useModifyStacksViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModifyStacksViewQuery, ModifyStacksViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModifyStacksViewQuery, ModifyStacksViewQueryVariables>(ModifyStacksViewDocument, baseOptions);
        }
export type ModifyStacksViewQueryHookResult = ReturnType<typeof useModifyStacksViewQuery>;
export type ModifyStacksViewLazyQueryHookResult = ReturnType<typeof useModifyStacksViewLazyQuery>;
export type ModifyStacksViewQueryResult = ApolloReactCommon.QueryResult<ModifyStacksViewQuery, ModifyStacksViewQueryVariables>;