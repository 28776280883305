import React, { createContext, useState } from 'react';

type BirthdateErrorsContextData = {
  errorCount: number;
  setErrorCount: (callback: (prev: number) => number) => void;
  errorList: string[];
  setErrorList: (callback: (prev: string[]) => string[]) => void;
};

const initialState = {
  errorCount: 0,
  setErrorCount: () => {},
  errorList: [] as string[],
  setErrorList: () => {},
};

export const BirthdateErrorsContext = createContext<BirthdateErrorsContextData>(
  initialState,
);

type Props = {
  children: React.ReactNode;
};

export const BirthdateErrorsProvider = ({ children }: Props) => {
  const [errorList, setErrorList] = useState<string[]>([]);
  const [errorCount, setErrorCount] = useState<number>(0);

  return (
    <BirthdateErrorsContext.Provider
      value={{ errorList, setErrorList, errorCount, setErrorCount }}
    >
      {children}
    </BirthdateErrorsContext.Provider>
  );
};
