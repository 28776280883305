import type { LibraryType, ClosedBySource } from '../analytics';

// types

export enum FilterMenuActionTypes {
  Open = 'pelo/filterMenu/Open',
  Close = 'pelo/filterMenu/Close',
}

export type FilterMenuAction = ReturnType<typeof open> | ReturnType<typeof close>;

// action creator

export const open = (libraryType: LibraryType) => ({
  type: FilterMenuActionTypes.Open,
  payload: { libraryType },
});

export const close = (libraryType: LibraryType, closedBy: ClosedBySource) => ({
  type: FilterMenuActionTypes.Close,
  payload: { libraryType, closedBy },
});
