import type { UserNavRouteOrRedirect } from '@members/layout';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import {
  ACHIEVEMENTS_ROUTE,
  LEGACY_ACHIEVEMENTS_ROUTE,
  LEGACY_MEMBERS_ACHIEVEMENTS_ROUTE,
  MEMBERS_ACHIEVEMENTS_ROUTE,
} from './urls';

const Routes: UserNavRouteOrRedirect[] = [
  {
    route: {
      path: ACHIEVEMENTS_ROUTE,
      component: lazyLoader(
        () => import('./Achievements' /* webpackChunkName: "AchievementsPage" */),
      ),
    },
  },
  {
    route: {
      path: MEMBERS_ACHIEVEMENTS_ROUTE,
      component: lazyLoader(
        () => import('./Achievements' /* webpackChunkName: "AchievementsPage" */),
      ),
    },
  },
  {
    redirect: {
      exact: true,
      from: LEGACY_ACHIEVEMENTS_ROUTE,
      to: ACHIEVEMENTS_ROUTE,
    },
  },
  {
    redirect: {
      exact: true,
      from: LEGACY_MEMBERS_ACHIEVEMENTS_ROUTE,
      to: MEMBERS_ACHIEVEMENTS_ROUTE,
    },
  },
];

export default Routes;
