import type { SagaIterator } from 'redux-saga';
import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { track } from '@engage/analytics';
import { membersListSuccess, takeIds } from '@engage/members';
import { trackMemberSearch } from './analytics';
import { fetchSearchResults } from './api';
import type { RequestAction } from './redux';
import {
  Actions,
  hasSearched,
  loadSearchResultsFailure,
  loadSearchResultsSuccess,
} from './redux';

export const loadSearchResultsSaga = function* (
  client: Client,
  action: RequestAction,
): SagaIterator {
  yield delay(250);
  try {
    const hasAlreadySearched = yield select(hasSearched);
    const { searchResults } = yield race({
      searchResults: call(fetchSearchResults, client, action.payload.usernamePrefix),
      clear: take(Actions.CLEAR_RESULTS),
    });

    if (searchResults) {
      yield put(membersListSuccess(searchResults));
      yield put(loadSearchResultsSuccess(takeIds(searchResults)));
      // Only track when hasAlreadySearched is false to prevent extraneous calls when
      // searching on input change, rather than triggering with enter
      if (!hasAlreadySearched) {
        yield put(
          track(trackMemberSearch(action.payload.usernamePrefix, searchResults.length)),
        );
      }
    }
  } catch (e) {
    yield put(loadSearchResultsFailure(e));
  }
};

export const memberSearchSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeLatest(Actions.REQUEST, loadSearchResultsSaga, client);
};
