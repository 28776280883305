import React from 'react';

export const AddTagsPlus: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg {...props} viewBox="0 0 20 20">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="add-tag" transform="translate(-221.000000, -34.000000)">
        <g transform="translate(221.000000, 34.000000)" id="btn-plus">
          <g>
            <g id="blue-plus-button">
              <rect
                id="background"
                fill="#60ABD2"
                x="0"
                y="0"
                width="20"
                height="20"
                rx="10"
              />
              <g id="icon/plus" transform="translate(4.000000, 4.000000)" fill="#FFFFFF">
                <path
                  d="M6.05000019,0.300000012 C6.46421375,0.300000012 6.80000019,0.736770928 6.80000019,1.26070359 L6.80000001,4.99900001 L11.0454529,5 C11.4274507,5 11.7431484,5.27972731 11.7931119,5.6476228 L11.8,5.75 C11.8,6.16421356 11.4682296,6.5 11.0454529,6.5 L6.80000001,6.49900001 L6.80000019,10.8392964 C6.80000019,11.3698784 6.46710157,11.8 6.05000019,11.8 C5.63578663,11.8 5.30000019,11.3632291 5.30000019,10.8392964 L5.30000001,6.49900001 L1.05454713,6.5 C0.637822264,6.5 0.300000012,6.16710138 0.300000012,5.75 C0.300000012,5.33578644 0.631770432,5 1.05454713,5 L5.30000001,4.99900001 L5.30000019,1.26070359 C5.30000019,0.730121653 5.63289881,0.300000012 6.05000019,0.300000012 Z"
                  id="plus"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
