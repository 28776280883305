import { stringify } from 'querystring';
import { toModalOpenRecord } from '@members/modal';
import { LANGUAGE_MODAL } from '../shared';

export const toLanguageModalQuery = () => {
  const queryParams = {
    ...toModalOpenRecord(LANGUAGE_MODAL),
  };
  return `?${stringify(queryParams)}`;
};
