import { css } from 'styled-components';
import { spaces } from '@engage/styles';
import { capsStyle, fontWeights, letterSpacing } from '../shared';

//functional type stack labels
export const label18 = css`
  font-size: 18px;
  font-weight: 900;
  line-height: ${spaces.xxLarge}px;
  letter-spacing: 0;
`;

export const label18Italic = css`
  font-size: 18px;
  line-height: ${spaces.xxLarge}px;
  font-weight: 950;
  letter-spacing: 0;
  font-style: italic;
`;

export const label18Caps = css`
  ${label18}
  ${capsStyle}
  letter-spacing: 0.5px;
`;

export const label16 = css`
  font-size: ${spaces.medium}px;
  font-weight: 900;
  line-height: ${spaces.xLarge}px;
  letter-spacing: 0;
`;

export const label16Italic = css`
  font-size: ${spaces.medium}px;
  line-height: ${spaces.xLarge}px;
  font-weight: 950;
  letter-spacing: 0;
  font-style: italic;
`;

export const label16Caps = css`
  ${label16}
  ${capsStyle}
  letter-spacing: 0.5px;
`;

export const label14 = css`
  font-size: 14px;
  font-weight: 900;
  line-height: ${spaces.large}px;
  letter-spacing: 0;
`;

export const label14Italic = css`
  font-size: 14px;
  line-height: ${spaces.large}px;
  font-weight: 950;
  letter-spacing: 0;
  font-style: italic;
`;

export const label14Caps = css`
  ${label14}
  ${capsStyle}
  letter-spacing: 0.5px;
`;

export const label12 = css`
  font-size: ${spaces.small}px;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 0;
`;

export const label12Italic = css`
  font-size: ${spaces.small}px;
  line-height: 18px;
  font-weight: 950;
  letter-spacing: 0;
  font-style: italic;
`;

export const label12Caps = css`
  ${label12}
  ${capsStyle}
  letter-spacing: 0.5px;
`;

export const label10 = css`
  font-size: 10px;
  font-weight: 900;
  line-height: ${spaces.medium}px;
  letter-spacing: 0;
`;

export const label10Italic = css`
  font-size: 10px;
  line-height: ${spaces.medium}px;
  font-weight: 950;
  letter-spacing: 0;
  font-style: italic;
`;

export const label10Caps = css`
  ${label10}
  ${capsStyle}
  letter-spacing: 0.5px;
`;

export const label10CapsBold = css`
  ${label10}
  ${capsStyle}
  fontWeight: ${fontWeights.xxxxBold};
  letterspacing: ${letterSpacing.xWide};
`;

export const label8 = css`
  font-size: ${spaces.xSmall}px;
  line-height: ${spaces.small}px;
  font-weight: 900;
  letter-spacing: 0;
`;

export const label8Italic = css`
  font-size: ${spaces.xSmall}px;
  line-height: ${spaces.small}px;
  font-weight: 950;
  letter-spacing: 0;
  font-style: italic;
`;

export const label8Caps = css`
  ${label8}
  ${capsStyle}
  letter-spacing: 0.5px;
`;

// labels
export const l1 = css`
  font-size: ${spaces.small}px;
  font-weight: ${fontWeights.xxxBold};
  line-height: ${spaces.medium}px;
  letter-spacing: ${letterSpacing.wide};
`;

export const l2 = css`
  font-size: 11px;
  font-weight: ${fontWeights.xxxBold};
  line-height: 14px;
  letter-spacing: ${letterSpacing.wide};
`;

export const l3 = css`
  font-size: 10px;
  font-weight: ${fontWeights.xxxBold};
  line-height: ${spaces.small}px;
  letter-spacing: ${letterSpacing.wide};
`;

export const l4 = css`
  font-size: 9px;
  font-weight: ${fontWeights.xxxBold};
  line-height: ${spaces.small}px;
  letter-spacing: ${letterSpacing.wide};
`;

export const l5 = css`
  font-size: ${spaces.xSmall}px;
  font-weight: ${fontWeights.xxxBold};
  line-height: 10px;
  letter-spacing: ${letterSpacing.wide};
`;

// cap labels
export const l1c = css`
  ${capsStyle}
  ${l1}
  letter-spacing: ${letterSpacing.xxWide};
`;

export const l2c = css`
  ${capsStyle}
  ${l2}
  letter-spacing: ${letterSpacing.xxWide};
`;
export const l3c = css`
  ${capsStyle}
  ${l3}
  letter-spacing: ${letterSpacing.xWide};
`;
export const l5c = css`
  ${capsStyle}
  ${l5}
  letter-spacing: ${letterSpacing.xWide};
`;

// bold labels
export const l1Bold = css`
  ${l1}
  font-weight: ${fontWeights.xxxxBold};
`;

export const l2Bold = css`
  ${l2}
  font-weight: ${fontWeights.xxxxBold};
`;

export const l3Bold = css`
  ${l3}
  font-weight: ${fontWeights.xxxxBold};
`;

// cap bold labels
export const l1CapBold = css`
  ${capsStyle}
  ${l1Bold}
  letter-spacing: ${letterSpacing.xxWide};
`;

export const l2CapBold = css`
  ${capsStyle}
  ${l2Bold}
  letter-spacing: ${letterSpacing.xxWide};
`;

export const l3CapBold = css`
  ${capsStyle}
  ${l3Bold}
  letter-spacing: ${letterSpacing.xWide};
`;
