import React from 'react';

export const StarOutline: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = ({ ...props }) => (
  <svg {...props} viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <rect
        width={31}
        height={31}
        x={0.5}
        y={0.5}
        stroke="#FFF"
        strokeOpacity={0.35}
        rx={15.5}
      />
      <path
        fill="#FFF"
        d="M15.862 8.343a.975.975 0 00-.787.663l-1.442 4.471-4.66.001a.97.97 0 00-.925.67l-.034.134c-.063.36.082.73.383.952l3.778 2.765-1.444 4.477a.97.97 0 00.356 1.086l.134.082a.97.97 0 001.01-.085L16 20.799l3.77 2.76a.969.969 0 001.142.005l.107-.09a.969.969 0 00.25-.997L19.825 18l3.778-2.765c.34-.25.48-.688.35-1.088l-.053-.127a.97.97 0 00-.871-.542h-4.661l-1.441-4.47a.972.972 0 00-.926-.675l-.138.01z"
        opacity={0.35}
      />
    </g>
  </svg>
);
