import { omit } from 'ramda';
import type { ComponentType } from 'react';
import { createElement } from 'react';

type CT<T = {}> = ComponentType<React.PropsWithChildren<T>>;

export const stripProps = <T extends CT>(
  Component: T,
  propsToStrip: string | string[],
) => (props: T extends CT<infer P> ? P : {}) => {
  const blacklist = Array.isArray(propsToStrip) ? propsToStrip : [propsToStrip];
  return createElement(Component, omit(blacklist, props));
};
