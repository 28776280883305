import type { Time } from '@peloton/time';
import { toNowTime, toTime, formatDurationBetween } from '@peloton/time';
import type { ClassAnalyticsProperties } from '@engage/classes';
import { CastProvider } from './models';
import type { VideoStateAnalyticsProperties, WorkoutClassInfo } from './selectors';

export const toVideoAnalytics = (
  classInfo: ClassAnalyticsProperties,
  videoInfo: VideoStateAnalyticsProperties,
  workoutClassInfo: WorkoutClassInfo,
  castProvider: CastProvider,
): VideoAnalyticsProperties => {
  const {
    currentTimecode,
    sessionStart,
    timePlaying,
    timeCasting,
    timeWithCaptions,
    timeWithAudio,
    introTimePlaying,
  } = videoInfo;
  const { scheduledStartTime, duration } = classInfo;

  const now = toNowTime();
  const startTime = scheduledStartTime ?? now.clone();

  return {
    workoutState: toWorkoutState(workoutClassInfo),
    pointInClass: toCurrentPointInClass(startTime, currentTimecode, now),
    totalTimeInWorkout: toTotalTimeInWorkout(toTime(sessionStart, true), now),
    ['%WorkoutCompleted']: toWorkoutComplete(timePlaying, introTimePlaying, duration),
    ['%WorkoutPlayedOverAirplay']: toWorkoutPercentOverAirplay(
      timeCasting,
      timePlaying,
      castProvider,
    ),
    ['%WorkoutPlayedOverChromecast']: toWorkoutPercentOverChromecast(
      timeCasting,
      timePlaying,
      castProvider,
    ),
    ['%WorkoutWithCaptions']: toPercentAnalytics(timeWithCaptions, timePlaying),
    ['%WorkoutWithAudio']: toPercentAnalytics(timeWithAudio, timePlaying),
  };
};

const toWorkoutState = (classInfo: WorkoutClassInfo) => {
  if (classInfo.isLive) {
    return 'Live';
  } else if (classInfo.isPremiere) {
    return 'Premiere';
  } else if (classInfo.isEncore) {
    return 'Encore';
  } else if (classInfo.isFreestyleWorkout) {
    return 'Freestyle';
  } else if (classInfo.isScenic) {
    return 'Scenic';
  } else {
    return 'On Demand';
  }
};

const toWorkoutPercentOverAirplay = (
  timeCasting: number,
  timePlaying: number,
  castProvider: CastProvider,
) =>
  castProvider === CastProvider.AirPlay
    ? toPercentAnalytics(timeCasting, timePlaying)
    : 0;

const toWorkoutPercentOverChromecast = (
  timeCasting: number,
  timePlaying: number,
  castProvider: CastProvider,
) =>
  castProvider === CastProvider.Chromecast
    ? toPercentAnalytics(timeCasting, timePlaying)
    : 0;

export const toCurrentPointInClass = (
  startTime: Time,
  currentTimecode: number,
  now: Time,
): number =>
  // Current timecode is negative for live classes, and tells us how far away from
  // live the user is. For live, we have to measure the time from start to now, minus
  // the buffer to the moment they experience in class.
  currentTimecode >= 0
    ? currentTimecode
    : Math.abs(formatDurationBetween(startTime, now, 'seconds')) + currentTimecode;

export const toTotalTimeInWorkout = (sessionStart: Time, now: Time): number =>
  Math.max(0, formatDurationBetween(sessionStart, now, 'seconds'));

// Percentage as a number, capped at 100 (i.e., 53)
export const toWorkoutComplete = (
  timePlaying: number,
  introTimePlaying: number,
  totalDuration: number,
): number => {
  const timePlayedMinusIntro = Math.max(0, timePlaying - introTimePlaying);
  const percentOfCompleted = Math.round((timePlayedMinusIntro / totalDuration) * 100);

  return Math.min(100, percentOfCompleted);
};

const toPercentAnalytics = (timeTracked: number, timePlaying: number) =>
  Math.round((timeTracked / timePlaying) * 100);

type VideoAnalyticsProperties = {
  pointInClass: number;
  totalTimeInWorkout: number;
  ['%WorkoutCompleted']: number;
  ['%WorkoutPlayedOverAirplay']: number;
  ['%WorkoutPlayedOverChromecast']: number;
  ['%WorkoutWithCaptions']: number;
  ['%WorkoutWithAudio']: number;
  workoutState: string;
};
