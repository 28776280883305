import type { SagaIterator } from 'redux-saga';
import { getContext, takeEvery, takeLeading } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { SubscriptionsActionType, SubscriptionUpdatesActionType } from '../redux';
import cancelSubscription from './cancelSubscription';
import detachSubscription from './detachSubscription';
import { loadSubscription } from './loadSubscription';
import loadSubscriptions from './loadSubscriptions';
import { addUserSaga, removeUserSaga } from './manageSharedUser';
import pauseSubscription from './pauseSubscription';
import reactivateSubscription from './reactivateSubscription';
import unpauseSubscription from './unpauseSubscription';

export const subscriptionSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(SubscriptionsActionType.AddSharedUserRequest, addUserSaga, client);
  yield takeEvery(
    SubscriptionsActionType.RemoveSharedUserRequest,
    removeUserSaga,
    client,
  );
  yield takeEvery(SubscriptionsActionType.DetachSubRequest, detachSubscription, client);
  yield takeEvery(SubscriptionsActionType.SubsRequest, loadSubscriptions, client);
  yield takeEvery(SubscriptionsActionType.SubDetailsRequest, loadSubscription, client);
  yield takeEvery(
    SubscriptionUpdatesActionType.ReactivateRequest,
    reactivateSubscription,
    client,
  );
  yield takeEvery(
    SubscriptionUpdatesActionType.SubsDigCancelRequest,
    cancelSubscription,
    client,
  );
  yield takeLeading(
    SubscriptionsActionType.PauseSubscriptionRequest,
    pauseSubscription,
    client,
  );
  yield takeLeading(
    SubscriptionsActionType.UnpauseSubscriptionRequest,
    unpauseSubscription,
    client,
  );
};

export { loadSubscriptions as loadSubscriptionsSaga };
export { loadSubscription as loadSubscriptionSaga };
export { addUserSaga, removeUserSaga };
