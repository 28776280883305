import React from 'react';

export type Props = { title?: React.ReactNode } & React.SVGProps<SVGSVGElement>;

const StaticSpinner: React.FC<React.PropsWithChildren<Props>> = ({ title, ...props }) => (
  <svg
    aria-labelledby="loading-spinner"
    data-test-id="loading-spinner"
    viewBox="0 0 28 28"
    {...props}
  >
    <title id="loading-spinner">{title}</title>
    <path
      d="M14.047 1c.651 0 1.21.564 1.21 1.222s-.559 1.222-1.21 1.222c-.652 0-1.21-.564-1.21-1.222C12.742 1.47 13.301 1 14.046 1zm0 0c.651 0 1.21.564 1.21 1.222s-.559 1.222-1.21 1.222c-.652 0-1.21-.564-1.21-1.222C12.742 1.47 13.301 1 14.046 1zM5.574 4.102c.466-.47 1.303-.376 1.676.094.465.564.372 1.316-.093 1.786-.466.47-1.304.376-1.77-.094-.465-.564-.372-1.316.187-1.786zm-4.562 7.896c.093-.658.745-1.128 1.396-1.034.652.094 1.118.752 1.025 1.41-.094.657-.745 1.127-1.397 1.033-.652 0-1.117-.658-1.024-1.41zm1.955 7.331c.559-.376 1.304-.094 1.676.47.372.564.093 1.316-.466 1.692-.558.376-1.303.094-1.675-.47-.28-.564-.094-1.316.465-1.692zm5.866 5.922c.279-.658.93-.94 1.582-.752.652.282.932.94.745 1.598-.279.658-.93.94-1.582.752-.652-.282-1.025-.94-.745-1.598zm8.845-.658a1.173 1.173 0 0 1 1.582.752c.28.658-.093 1.41-.744 1.598-.652.188-1.397-.094-1.583-.752-.28-.658.093-1.41.745-1.598zm5.679-4.888c.372-.564 1.117-.846 1.676-.47.559.376.838 1.128.465 1.692-.372.564-1.117.846-1.675.47-.652-.282-.838-1.034-.466-1.692zm2.235-8.647c.651-.094 1.303.376 1.396 1.033.093.658-.372 1.316-1.024 1.41-.652.094-1.303-.376-1.397-1.034-.093-.657.373-1.315 1.025-1.41zM20.75 4.29c.466-.564 1.21-.564 1.77-.188.558.47.558 1.222.185 1.786-.465.564-1.303.564-1.769.188-.558-.47-.651-1.316-.186-1.786z"
      id="a"
    />
  </svg>
);

export default StaticSpinner;
