import type { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { track } from '@engage/analytics';
import { PrivacyUpdateSource, trackUpdatedPrivacySettings } from '../analytics';
import { updatePrivacySettings as update } from '../api';
import type { UpdatePrivacySettingsAction } from '../redux';

export default function* (
  client: Client,
  { presenter, settings, previousSettings }: UpdatePrivacySettingsAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  if (userId) {
    try {
      yield call(update, client, userId, settings);

      yield put(
        track(
          trackUpdatedPrivacySettings(
            PrivacyUpdateSource.PreferencesSettings,
            settings,
            previousSettings ?? {},
          ),
        ),
      );
      presenter.displaySuccessMessage();
    } catch {
      presenter.displayErrorMessage();
    }
  } else {
    presenter.redirectToLoginPage();
  }
}
