import React from 'react';
import styled from 'styled-components';
import IconBadge from './IconBadge';

const Badge: React.FC<React.PropsWithChildren<unknown>> = props => (
  <StyledIconBadge url={require('../img/androidtv.png')} {...props} />
);

const StyledIconBadge = styled(IconBadge)`
  width: 100px;
`;

export default Badge;
