import React from 'react';

const CrossIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 10 10" {...props}>
    <path
      fillRule="evenodd"
      d="M5.707 5L9.854.854a.5.5 0 1 0-.707-.707L5 4.293.854.147a.5.5 0 1 0-.707.707L4.293 5 .147 9.147a.5.5 0 1 0 .707.707L5 5.707l4.147 4.147a.499.499 0 0 0 .707 0 .5.5 0 0 0 0-.707L5.707 5z"
    />
  </svg>
);

export default CrossIcon;
