import { CloudinaryContext } from 'cloudinary-react';
import React from 'react';

export const CDNContext: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = props => (
  <CloudinaryContext
    cloudName="peloton-cycle"
    fetchFormat="auto"
    type="fetch"
    dpr="auto"
    quality="auto"
    responsive={true}
    {...props}
  />
);
