import { find, values } from 'ramda';
import { toLanguage, Language } from '@peloton/internationalize';
import { isDefined } from '@peloton/types';

export const toSupportedBrowserLanguage = (): Language => {
  const supportedLanguages = values(Language);
  const browserLanguages = isDefined(navigator.languages)
    ? navigator.languages.map(language => language.substr(0, 2))
    : [navigator.language.substr(0, 2)];

  return (
    (find(isSupportedLanguage(supportedLanguages))(browserLanguages) as Language) ??
    toLanguage()
  );
};

const isSupportedLanguage = (supportedLanguages: Language[]) => (
  language: string,
): language is Language =>
  Boolean(find(supported => supported === language, supportedLanguages));
