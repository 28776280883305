import React from 'react';

const FacebookIconTransparent: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 60 60" {...props}>
    <g fillRule="evenodd">
      <path d="M58.5 49.328c0 4.858-3.952 8.81-8.811 8.81h-7.825V37.125h6.854a.75.75 0 0 0 .744-.653l1.139-8.774a.752.752 0 0 0-.745-.847h-7.992v-4.852c0-2.336.561-3.522 3.625-3.522l4.676-.002a.75.75 0 0 0 .75-.75V9.877a.75.75 0 0 0-.652-.744c-.874-.115-3.712-.352-6.91-.352-7.467 0-12.106 4.732-12.106 12.348v5.72h-6.874a.75.75 0 0 0-.75.75v8.775c0 .414.336.75.75.75h6.874v21.015H10.311c-4.859 0-8.811-3.953-8.811-8.811V10.31C1.5 5.453 5.452 1.5 10.311 1.5h39.378c4.859 0 8.811 3.953 8.811 8.81v39.018zm-25.753 8.81V36.375a.75.75 0 0 0-.75-.75h-6.874V28.35h6.874a.75.75 0 0 0 .75-.75v-6.471c0-6.793 3.965-10.848 10.606-10.848 2.546 0 4.843.152 6.062.268v6.426h-3.926c-4.515 0-5.125 2.54-5.125 5.023V27.6c0 .414.336.75.75.75h7.889l-.944 7.274h-6.945a.75.75 0 0 0-.75.75v21.765h-7.617zM49.689 0H10.311C4.625 0 0 4.626 0 10.31v39.018c0 5.685 4.625 10.31 10.311 10.31h39.378c5.686 0 10.311-4.625 10.311-10.31V10.31C60 4.626 55.375 0 49.689 0z" />
    </g>
  </svg>
);

export default FacebookIconTransparent;
