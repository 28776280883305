import { schema } from 'normalizr';

export type Equipment = {
  slug: string;
  name: string;
  id: string;
  iconUrl: string;
};

export const toEquipmentSchema = (options: schema.EntityOptions = {}) =>
  new schema.Entity('equipment', {}, options);
