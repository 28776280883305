import { css } from 'styled-components';

export default css`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
`;
