import type { RelationshipCategory, Member, MemberListGroup } from './models';

export type CardInfo = RelationshipInfo & {
  username: string;
  label?: string;
  imageUrl: string;
  id: string;
  listGroup: MemberListGroup;
  isProfilePrivate?: boolean;
};

export type RelationshipInfo = {
  meToUserRelationship: RelationshipCategory;
  userToMeRelationship?: RelationshipCategory;
};

export type MemberCard = CardInfo & {
  onFollowClick: (e: React.MouseEvent<HTMLButtonElement | SVGElement>) => void;
  listName: string;
};

export const toCardInfoList = (members: Member[]): CardInfo[] => members.map(toCardInfo);

export const toCardInfo = (member: Member): CardInfo => ({
  ...toRelationshipInfo(member),
  username: member.username,
  imageUrl: member.imageUrl,
  label: member.location,
  id: member.id,
  listGroup: member.listGroup,
});

export const toRelationshipInfo = (member: Member): RelationshipInfo => ({
  meToUserRelationship: member.relationship.meToUser,
  userToMeRelationship: member.relationship.userToMe,
});
