import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import type { Dispatch } from 'redux';
import { isDefined } from '@peloton/types';

import type { MemberSelectorState } from '@engage/members';
import { getMembersList, toCardInfoList } from '@engage/members';
import { ModalLabel } from '@members/copy';
import { mapMembersDispatchToProps, withFollowClick } from '@members/members';
import { LegacyConnectedModal } from '@members/modal';
import { MEMBER_SEARCH_NAME } from './analytics';
import { ContentWithFacebookSlideover } from './ContentWithFacebookSlideover';
import type { MemberSearchProps } from './MemberSearch';
import type { MemberSearchSelectorState } from './redux';
import { clearSearchResults, getSearchResults, loadSearchResults } from './redux';

const MIN_SEARCH_LENGTH = 3;

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  modalName,
  ...props
}) => (
  <LegacyConnectedModal
    modalNames={{
      [modalName]: toModalConfig(props),
    }}
  />
);

type ConfigProps = MemberSearchProps & {
  setInputValue: SetInputValue;
  onCloseModal?: () => void;
  toClear: (fn: SetInputValue) => () => void;
  handleSearchRequest: (inputValue: string) => void;
};

type ModalProps = ConfigProps & { modalName: string };

const toModalConfig = ({
  inputValue,
  setInputValue,
  handleSearchRequest,
  onCloseModal,
  searchResults,
  toClear,
}: ConfigProps) => ({
  ariaLabelId: ModalLabel.MembersSearch,
  component: (
    <ContentWithFacebookSlideover
      handleInputChange={(e: React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setInputValue(val);
        handleSearchRequest(val);
      }}
      handleClickClear={toClear(setInputValue)}
      searchResults={searchResults}
      inputValue={inputValue}
    />
  ),
  onCloseModal: () => {
    toClear(setInputValue)();
    if (isDefined(onCloseModal)) {
      onCloseModal();
    }
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...mapMembersDispatchToProps(dispatch, MEMBER_SEARCH_NAME),
  handleSearchRequest: (inputValue: string) => {
    if (isValidInput(inputValue)) {
      dispatch(loadSearchResults(inputValue));
    } else {
      dispatch(clearSearchResults());
    }
  },
  toClear: (setInputValue: (value: string) => void) => () => {
    setInputValue('');
    dispatch(clearSearchResults());
  },
});

const isValidInput = (input: string) => input.length >= MIN_SEARCH_LENGTH;

const MemberSearchModal = compose<
  ModalProps,
  { onCloseModal?: () => void; modalName: string }
>(
  connect(
    (state: State) => ({
      searchResults: getSearchResults(state)
        ? toCardInfoList(getMembersList(state, getSearchResults(state)))
        : null,
    }),
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
      searchResults: stateProps.searchResults
        ? withFollowClick(
            dispatchProps.toggleFollow,
            stateProps.searchResults,
            MEMBER_SEARCH_NAME,
          )
        : null,
      ...dispatchProps,
      ...ownProps,
    }),
  ),
  withState('inputValue', 'setInputValue', ''),
)(Modal);

type State = MemberSearchSelectorState & MemberSelectorState;

type SetInputValue = (value: string) => void;

export default MemberSearchModal;
