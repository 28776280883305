import { propOr } from 'ramda';
import type { UserSelectorState, SignedInUser } from '@peloton/auth';
import { getUser, isSignedIn } from '@peloton/auth';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import type { ClassSelectorState } from '@engage/classes';
import { getDenormalizedClass } from '@engage/classes';
import type { ScheduleSelectorState } from '@engage/scheduled-classes';
import type { WorkoutSelectorState } from '@engage/workouts';
import type { PacketMetrics } from './models';
import type { VideoSelectorState } from './redux';
import { getWorkoutId } from './redux';
import { isClassLive, getPelotonId, getClassId } from './selectors';
import {
  getCurrentVideoOffset,
  getClassStartOffset,
  getClassEndOffset,
} from './timeSelectors';

export const getPacketTimingInfo = (
  state: PacketSelectorState,
  videoOffsetGetter = getVideoOffsets,
) => {
  const { currentVideoOffset, classStartOffset, classEndOffset } = videoOffsetGetter(
    state,
  );

  const secondsOffsetFromStart = currentVideoOffset - classStartOffset;
  const shouldAddMetrics =
    secondsOffsetFromStart > 0 && currentVideoOffset < classEndOffset;

  return {
    secondsOffsetFromStart,
    shouldAddMetrics,
  };
};

export const getPacketWorkoutAndUserInfo = (
  state: PacketSelectorState,
): UserWorkoutInfo => {
  // Get supported metrics
  const classId = getClassId(state);
  const denormalizedClass = getDenormalizedClass(state, classId ?? '');
  const supportedMetrics = propOr<
    Metric[],
    ReturnType<typeof getDenormalizedClass>,
    Metric[]
  >([], 'supportedMetrics', denormalizedClass);

  // Get user information
  const userFromState = getUser(state);
  const user = isSignedIn(userFromState) ? userFromState : undefined;

  return {
    user,
    workoutId: getWorkoutId(state) ?? '',
    supportedMetrics,
  };
};

const getVideoOffsets = (state: PacketSelectorState) => {
  const pelotonId = getPelotonId(state) ?? '';

  return {
    currentVideoOffset: getCurrentVideoOffset(state, pelotonId, isClassLive(state)),
    classStartOffset: getClassStartOffset(state, pelotonId) ?? 0,
    classEndOffset: getClassEndOffset(state, pelotonId) ?? 0,
  };
};

export type PacketSelectorState = VideoSelectorState &
  ClassSelectorState &
  ScheduleSelectorState &
  FetcherSelectorState &
  UserSelectorState &
  WorkoutSelectorState;

type UserWorkoutInfo = {
  user: SignedInUser | undefined;
  workoutId: string;
  supportedMetrics: Metric[];
};

type Metric = keyof PacketMetrics;
