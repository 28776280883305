import { css } from 'styled-components';
import { media } from '@peloton/styles';
import { white, slate2 } from '@engage/colors';
import { spaces } from '@engage/styles';

export const TOP_NAV_HEIGHT_PX = 60;

// left padding should be 16px smaller than right padding
// to account for padding on logo button
export const navContainerStyles = css`
  padding: 0 ${spaces.medium}px 0 0;
  margin: 0 auto;
  width: 100%;
  ${media.tablet`
    padding-left: ${spaces.xxxLarge - spaces.medium}px;
    padding-right: ${spaces.xxxLarge}px;
  `}
  ${media.desktopLarge`
    padding-left: ${spaces.xxHuge - spaces.medium}px;
    padding-right: ${spaces.xxHuge}px;

  `}
`;

export const topNavContainerStyles = css`
  ${navContainerStyles}
  display: flex;
  justify-content: space-between;
  position: fixed;
  height: ${TOP_NAV_HEIGHT_PX}px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); //lights/shadow
  background-color: ${white};
`;

export const skipLinksContainerStyles = css`
  ${topNavContainerStyles}
  background-color: ${slate2};
`;
