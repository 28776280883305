import { isStageNEnv } from '@peloton/env/models';
import {
  LinkEnvs,
  DigitalLinkEnvs,
  toExtLinkEnv,
  withDigitalUatPrefix,
  AccountLinkEnvs,
  HomecomingLinkEnvs,
  StudioLinkEnvs,
} from '@peloton/external-links';
import { SEGMENT_KEYS_FOR_ANALYTICS } from '@peloton/internationalize';
import { toMatchingTld } from '@peloton/internationalize/models/locale';
import * as Env from '@members/env';
import { toApiEnv } from '@members/env';
const isDeployPreviewEnv = Env.isDeployPreviewEnv();
const isLocalEnv = Env.isLocalEnv();
const isProdEnv = Env.isProdEnv();
const isQaApiEnv = Env.isQaApiEnv();
const isStagingEnv = Env.isStagingEnv();
const isUatEnv = Env.isUatEnv();

const apiEnv = toApiEnv();

const maybeHostname =
  typeof window === 'undefined'
    ? 'local-members.onepeloton.com'
    : window.location.hostname;

const toErrorEnv = () => {
  if (isProdEnv) {
    return 'production';
  } else if (isDeployPreviewEnv) {
    return 'deploy-preview';
  } else if (isLocalEnv) {
    return 'local';
  }

  return 'uat';
};

const toHomecomingLink = () => {
  if (isProdEnv) {
    return HomecomingLinkEnvs.Prod;
  }

  return HomecomingLinkEnvs.Dev;
};

const toAccountLinkEnv = () => {
  if ((isProdEnv || isStagingEnv) && !isQaApiEnv) {
    return AccountLinkEnvs.Prod;
  } else if (isQaApiEnv) {
    return AccountLinkEnvs.UatQa;
  } else if (isStageNEnv(maybeHostname)) {
    return AccountLinkEnvs.StageN;
  } else {
    return AccountLinkEnvs.UatProd;
  }
};

const toSegmentWriteKey = () => {
  const tld = toMatchingTld(maybeHostname);

  if (isProdEnv) {
    return SEGMENT_KEYS_FOR_ANALYTICS.prod[tld];
  } else if (isStagingEnv) {
    return SEGMENT_KEYS_FOR_ANALYTICS.qa[tld];
  } else if (isUatEnv) {
    return SEGMENT_KEYS_FOR_ANALYTICS.qa[tld];
  } else if (isLocalEnv) {
    return SEGMENT_KEYS_FOR_ANALYTICS.dev[tld];
  } else {
    return SEGMENT_KEYS_FOR_ANALYTICS.dev[tld];
  }
};

const toDigitalLinkEnv = () => {
  if (isLocalEnv) {
    return DigitalLinkEnvs.Local;
  } else if (isQaApiEnv) {
    return withDigitalUatPrefix(DigitalLinkEnvs.UatProd);
  } else if (isProdEnv || isStagingEnv) {
    return DigitalLinkEnvs.Prod;
  } else if (isStageNEnv(maybeHostname)) {
    return DigitalLinkEnvs.StageN;
  } else {
    return withDigitalUatPrefix(DigitalLinkEnvs.UatProd);
  }
};

const toExternalLinkEnv = () => {
  if (isQaApiEnv && isStagingEnv) {
    return LinkEnvs.Qa1;
  } else if (isQaApiEnv) {
    return LinkEnvs.UatQa;
  } else if ((isProdEnv || isStagingEnv) && !isQaApiEnv) {
    return LinkEnvs.Prod;
  } else {
    return LinkEnvs.UatQa;
  }
};

const toStudioLinkEnv = ({
  isProd,
  isStaging,
  isLocal,
}: {
  isProd: boolean;
  isStaging: boolean;
  isLocal: boolean;
}): typeof StudioLinkEnvs[keyof typeof StudioLinkEnvs] =>
  (isProd && StudioLinkEnvs.Prod) ||
  (isStaging && StudioLinkEnvs.Staging) ||
  (isLocal && StudioLinkEnvs.Local) ||
  StudioLinkEnvs.UatQa;

export const env = {
  extLink: toExtLinkEnv({
    digital: toDigitalLinkEnv(),
    www: toExternalLinkEnv(),
    ecomm: toExternalLinkEnv(),
    account: toAccountLinkEnv(),
    studio: toStudioLinkEnv({
      isProd: isProdEnv,
      isStaging: isStagingEnv,
      isLocal: isLocalEnv,
    }),
    homecoming: toHomecomingLink(),
    api: apiEnv,
  }),
  segmentWriteKey: toSegmentWriteKey(),
  error: toErrorEnv(),
};
