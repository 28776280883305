export enum CLASS_TYPE_NAME {
  Encore = 'Encore Class',
  Live = 'Live Class',
  OnDemand = 'Scheduled Class',
  Session = 'Session',
  Premiere = 'Premiere',
}

export enum CLASS_TYPE_CATEGORY {
  OnDemand = 'OnDemandInstructorClass',
  Encore = 'ENCORE',
  Live = 'LIVE',
  Session = 'SESSION',
  Premiere = 'Premiere',
}

export enum CLASS_STATUS {
  Ended = 'ENDED',
  StartingSoon = 'STARTING_SOON',
  Elapsed = 'ELAPSED',
  Scheduled = 'SCHEDULED',
  NotScheduled = 'NOT_SCHEDULED',
  Remove = 'REMOVE',
}
