import React from 'react';
import { DarkClearButton, LightClearButton } from './clearButton';
import { DarkHelptext, LightHelptext } from './helptext';
import { DarkInput, LightInput } from './input';
import type { Props as InputWithLabelViewProps } from './InputWithLabelView';
import InputWithLabelView from './InputWithLabelView';
import { DarkLabel, LightLabel } from './label';
import { DarkToggleButton, LightToggleButton } from './togglePasswordButton';

type PropsWithDefaults = 'Input' | 'Label' | 'ClearButton' | 'TogglePasswordButton';
type Props = Omit<InputWithLabelViewProps, PropsWithDefaults> &
  Partial<Pick<InputWithLabelViewProps, PropsWithDefaults>>;

const DarkInputWithLabel: React.FC<React.PropsWithChildren<Props>> = props => (
  <InputWithLabelView
    Input={DarkInput}
    Label={DarkLabel}
    ClearButton={DarkClearButton}
    TogglePasswordButton={DarkToggleButton}
    hideFieldErrors={true}
    {...props}
    Helptext={DarkHelptext}
  />
);

const LightInputWithLabel: React.FC<React.PropsWithChildren<Props>> = props => (
  <InputWithLabelView
    Input={LightInput}
    Label={LightLabel}
    ClearButton={LightClearButton}
    TogglePasswordButton={LightToggleButton}
    {...props}
    Helptext={LightHelptext}
  />
);

export { DarkInputWithLabel, LightInputWithLabel };
