import { capitalize } from '@peloton/text';
import type { BrowseCategorySlug } from './models';

enum BrowseCategoryName {
  Cardio = 'Cardio',
  Cycling = 'Cycling',
  Outdoor = 'Outdoor',
  Strength = 'Strength',
  Stretching = 'Stretching',
  TotalBody = 'Total Body',
  Running = 'Running',
  Yoga = 'Yoga',
  Walking = 'Walking',
  Bootcamp = 'Bootcamp',
  Meditation = 'Meditation',
  Bike_bootcamp = 'Bike Bootcamp',
  Caesar = 'Rowing',
  Caesar_bootcamp = 'Row Bootcamp',
  All = 'All',
  Free = 'Free',
}

export const toAnalyticsBrowseCategoryProp = (slug: BrowseCategorySlug) => ({
  browseCategory: BrowseCategoryName[capitalize(slug as string)],
});
