import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import { isDefined } from '@peloton/types';
import { gray3, superRed } from '@engage/colors';
import { spaces } from '@engage/styles';
import { header5Small, label7Large } from '@engage/typography';
import { useSetFeatureToggle } from '../featureToggles';
import type { OverridableToggle } from '../models';
import type { Feature } from '../toggles';

type Props = {
  feature: Feature;
  toggle: OverridableToggle | undefined;
};

const prettyPrint = (str: string) =>
  str
    .replace(/([A-Z])/g, ' $1') // add a space in front of each capital letter
    .replace(/^./, (s: string) => s.toUpperCase()); // uppercase first letter

const boxShadowStyle =
  '0 4px 9px 2px rgba(0, 0, 0, 0.08), 0 3.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0.5px rgba(0, 0, 0, 0.11), 0 0.5px 1px 0 rgba(0, 0, 0, 0.07)';

const Container = styled.div`
  height: 25px;
  width: 25px;
  position: relative;
  display: inline-block;
`;

const Centered = styled.label`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const OverridenIndicator: React.FC<
  React.PropsWithChildren<{ isOverridden: boolean }>
> = ({ isOverridden }) => {
  if (!isOverridden) {
    return null;
  }

  return (
    <Container>
      <Centered htmlFor="img">
        <span aria-labelledby="changed" role="img">
          🚧
        </span>
      </Centered>
    </Container>
  );
};

const Toggle: React.FC<React.PropsWithChildren<Props>> = ({ feature, toggle }) => {
  const set = useSetFeatureToggle();
  const onChange = React.useCallback(() => {
    set(feature, !enabled);
  }, [toggle]);

  if (!isDefined(toggle)) {
    return null;
  }
  const { value: enabled, originalValue } = toggle;

  const isOverridden = originalValue !== enabled;

  return (
    <Item key={feature}>
      <div>
        <Text htmlFor={feature}>{prettyPrint(feature)}</Text>
        <br />
        <Subtext>(param: {feature})</Subtext>
      </div>
      <div>
        <OverridenIndicator isOverridden={isOverridden} />
        <Switch
          id={feature}
          aria-checked={enabled}
          checked={enabled}
          role="switch"
          onChange={onChange}
          offColor={gray3}
          onColor={superRed}
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}
          width={40}
          boxShadow={boxShadowStyle}
        />
      </div>
    </Item>
  );
};

const Item = styled.li`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 ${spaces.small}px;
`;

const Text = styled.label`
  ${header5Small}
  pointer-events: none;
`;

const Subtext = styled.span`
  ${label7Large}
  pointer-events: auto;
`;

export default Toggle;
