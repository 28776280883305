import type { Reducer } from 'redux';
import type { SortValue } from '../models';
import type { clearAll } from './applied';

// types

export enum SortActionTypes {
  Sort = 'pelo/filters/SORT',
  Override = 'pelo/filters/SORT_OVERRIDE',
  Reset = 'pelo/filters/RESET',
}

export type SortAction = ReturnType<typeof sort> | ReturnType<typeof clearAll>;

// action creator

export const sort = (sortValue: SortValue) => ({
  type: SortActionTypes.Sort,
  payload: sortValue,
});

// intended _not_ to be listened to by a saga
export const overrideSort = (sortValue: SortValue) =>
  ({
    type: SortActionTypes.Override,
    payload: sortValue,
  } as const);

export const resetSort = () => ({
  type: SortActionTypes.Reset,
});

// reducer

export type SortState = SortValue;
export const DEFAULT_SORT_STATE: SortState = { sort: 'original_air_time', desc: true };

export const sortReducer: Reducer<SortState> = (
  state = DEFAULT_SORT_STATE,
  action: SortAction,
) => {
  switch (action.type) {
    case SortActionTypes.Sort:
    case SortActionTypes.Override:
      return action.payload;
    case SortActionTypes.Reset:
      return DEFAULT_SORT_STATE;
    default:
      return state;
  }
};
