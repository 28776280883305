// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Schedule = {
  FirstAired: 'firstAired',
  NextWeek: 'nextWeek',
  NoClasses: 'noClasses',
  PreviousWeek: 'previousWeek',
  ScreenReaderHeader: 'screenReaderHeader',
  Title: 'title',
  UpcomingLiveDuration: 'upcomingLiveDuration',
  Weeks: 'weeks',
} as const;
