import { find, pipe, propEq, filter, anyPass } from 'ramda';
import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type { Card } from '../models/Card';

const cardsReducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Type.Request:
      return {
        ...state,
        isLoading: true,
      };
    case Type.RequestSuccess:
      return {
        entities: action.payload,
        isLoading: false,
        error: false,
      };
    case Type.RequestFailure:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
};

const defaultState: State = {
  entities: undefined,
  isLoading: false,
  error: false,
};

type State = {
  entities?: Card[];
  isLoading: boolean;
  error: boolean;
};

// Actions

type Action =
  | TypedAction<ReturnType<typeof loadCards>, Type.Request>
  | TypedAction<ReturnType<typeof loadCardsSuccess>, Type.RequestSuccess>
  | TypedAction<ReturnType<typeof loadCardsFailure>, Type.RequestFailure>;

enum Type {
  Request = 'pelo/preferences/cards/REQUEST',
  RequestSuccess = 'pelo/preferences/cards/REQUEST_SUCCESS',
  RequestFailure = 'pelo/preferences/cards/REQUEST_FAILURE',
}

const loadCards = () => ({
  type: Type.Request,
});

const loadCardsSuccess = (cards: Card[]) => ({
  type: Type.RequestSuccess,
  payload: cards,
});

const loadCardsFailure = () => ({
  type: Type.RequestFailure,
});

// Selectors

export type CardsState = { cards: State };

const getCards = (state: CardsState) => state.cards.entities ?? [];

const isLoadingCards = (state: CardsState) => state.cards.isLoading;

const getDefaultCard = pipe<CardsState, Card[], Card | undefined>(
  getCards,
  find(propEq('isDefault', true)),
);

const getBenefitHubPaymentMethod = pipe<CardsState, Card[], Card | undefined>(
  getCards,
  find(propEq('paymentType', 'benefit_hub')),
);

const getMembershipDetailsCards = pipe<CardsState, Card[], Card[]>(
  getCards,
  filter(anyPass([propEq('paymentType', 'benefit_hub'), propEq('isDefault', true)])),
);

export {
  cardsReducer,
  getCards,
  getDefaultCard,
  getBenefitHubPaymentMethod,
  getMembershipDetailsCards,
  isLoadingCards,
  loadCards,
  loadCardsSuccess,
  loadCardsFailure,
  Type as CardsActionType,
};
