import type { OauthProviderProps } from '@peloton/auth';
import type { ApiEnvs } from '@peloton/external-links/models';
// eslint-disable-next-line no-restricted-imports
import {
  toOauthConfig,
  apiEnvToOauthEnv,
  OauthEnvironment,
  toEnv,
} from '@engage/env/oauthEnv';
import { toApiEnv } from '@members/env/api';

const Auth0EnvConfigs = Object.entries(apiEnvToOauthEnv).reduce(
  (auth0EnvConfig, [apiEnv, oauthEnv]) => ({
    ...auth0EnvConfig,
    [apiEnv]: toOauthConfig(oauthEnv),
  }),
  {} as Partial<Record<ApiEnvs, OauthProviderProps>>,
);

export const apiToMaybeOauthEnv = (str?: string): OauthEnvironment | undefined => {
  const api = str ?? toApiEnv();
  const env = toEnv(apiEnvToOauthEnv[api]);

  return env === OauthEnvironment.Unknown ? undefined : env;
};

export const toAuth0Config = (
  apiEnv: ApiEnvs,
  oauthEnv?: OauthEnvironment,
): OauthProviderProps => {
  if (oauthEnv) {
    return toOauthConfig(oauthEnv);
  }

  return Auth0EnvConfigs[apiEnv] ?? toOauthConfig(OauthEnvironment.Stage);
};
