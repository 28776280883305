import { combineReducers } from 'redux';
import type { State as IsRemoteLoadedState } from './isRemoteLoaded';
import { reducer as isRemoteLoaded } from './isRemoteLoaded';
import type { State as TogglesState } from './toggles';
import { toTogglesReducer } from './toggles';
import type { State as VariableState } from './variables';
import { toVariableReducer } from './variables';

export const toFeatureToggleReducer = (
  toggles: Parameters<typeof toTogglesReducer>[0],
  variables: Parameters<typeof toVariableReducer>[0] = {},
  togglesMergeStrategy?: Parameters<typeof toTogglesReducer>[1],
  variablesMergeStrategy?: Parameters<typeof toVariableReducer>[1],
) =>
  combineReducers({
    isRemoteLoaded,
    toggles: toTogglesReducer(toggles, togglesMergeStrategy),
    variables: toVariableReducer(variables, variablesMergeStrategy),
  });

export type State = {
  isRemoteLoaded: IsRemoteLoadedState;
  toggles: TogglesState;
  variables: VariableState;
};
