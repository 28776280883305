import { reduce } from 'ramda';
import type {
  ClassLanguage,
  CorporateWellnessSettings,
  ProfilePrivacySettings,
} from './models';

export enum Source {
  ProfileSettings = 'Profile Settings',
  FiltersMenu = 'Filter Menu',
  Footer = 'Footer Link',
  ScheduleFiltersMenu = 'Schedule Filter Menu',
  LibraryLimitedResults = 'Library Limited Results',
  ScheduleLimitedResults = 'Schedule Limited Results',
  LanguageModal = 'TLD Language Modal',
}

export enum PrivacyUpdateSource {
  Notification = 'Notification',
  PreferencesSettings = 'PreferencesSettings',
}

export const trackViewedClassLanguageModal = (source: Source) => ({
  event: 'Viewed Class Language Settings',
  source,
});

export const classLanguageSettingsToAnalyticsList = (settings: ClassLanguage[]) =>
  reduce<ClassLanguage, string[]>(
    (acc, setting) => {
      if (setting.displayAllClasses) {
        acc.push(`All ${setting.localeName} Classes`);
      } else {
        setting.captions.map(caption => {
          if (caption.enabled) {
            acc.push(`${setting.localeName} with ${caption.localeName} Subtitles`);
          }
        });
      }
      return acc;
    },
    [],
    settings,
  );

export const trackUpdatedClassLanguageSettings = (
  source: Source,
  previousClassLanguageSettings: ClassLanguage[],
  classLanguageSettings: ClassLanguage[],
) => ({
  event: 'Updated Class Language Settings',
  source,
  previousClassLanguageSettings: classLanguageSettingsToAnalyticsList(
    previousClassLanguageSettings,
  ),
  classLanguageSettings: classLanguageSettingsToAnalyticsList(classLanguageSettings),
});

export const trackUpdatedPrivacySettings = (
  source: PrivacyUpdateSource,
  newPrivacySettings: Partial<ProfilePrivacySettings & CorporateWellnessSettings>,
  previousPrivacySettings: Partial<ProfilePrivacySettings & CorporateWellnessSettings>,
) => ({
  event: 'Updated Privacy Settings',
  source,
  ageNew: newPrivacySettings.age,
  ageOld: previousPrivacySettings.age,
  effortZonesPrivacyNew: newPrivacySettings.isStriveScorePrivate,
  effortZonesPrivacyOld: previousPrivacySettings.isStriveScorePrivate,
  genderNew: newPrivacySettings.gender,
  genderOld: previousPrivacySettings.gender,
  privateProfileNew: newPrivacySettings.isProfilePrivate,
  privateProfileOld: previousPrivacySettings.isProfilePrivate,
  routeTrackingPrivacyNew: newPrivacySettings.workoutMapsPrivate,
  routeTrackingPrivacyOld: previousPrivacySettings.workoutMapsPrivate,
  videoChatNew: newPrivacySettings.chatRestriction,
  videoChatOld: previousPrivacySettings.chatRestriction,
  companyChallengePrivacyNew: !newPrivacySettings.corporateWellnessGroup
    ?.shareCorporateWellnessCompletion,
  companyChallengePrivacyOld: !previousPrivacySettings.corporateWellnessGroup
    ?.shareCorporateWellnessCompletion,
  feedPrivacyNew: formatFeedPrivacy(newPrivacySettings.feedPrivacy),
  feedPrivacyOld: formatFeedPrivacy(previousPrivacySettings.feedPrivacy),
});

const formatFeedPrivacy = (feedPrivacy: string | undefined) => {
  if (feedPrivacy === 'my_followers') {
    return 'My Followers';
  } else if (feedPrivacy === 'only_me') {
    return 'Just Me';
  }
  return undefined;
};
