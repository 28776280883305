import { stringify } from 'querystring';
import type { BrowseCategorySlug } from '@engage/browse-categories';

export const SCHEDULE_ROUTE = '/schedule';

export const SCHEDULE_CATEGORY_ROUTE = '/schedule/:categorySlug';

export const toScheduleCategoryRoute = (categorySlug: BrowseCategorySlug) =>
  `/schedule/${categorySlug}`;

export const YOUR_SCHEDULE_ROUTE = '/schedule/yourschedule';

export const SCHEDULED_ROUTE = '/scheduled/class/:classId/:pelotonId';

export const INVITED_ROUTE = '/invite/:inviteId';

export const toConfirmScheduleModalQuery = (id: string) => {
  const queryParams = {
    ['id']: id,
  };
  return `?${stringify(queryParams)}`;
};

export const toInviteModalQuery = (inviteId: string) => {
  const queryParams = {
    ['invite_id']: inviteId,
  };
  return `?${stringify(queryParams)}`;
};
