/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import type { KeyedMutator } from 'swr';
import { gray1, slate1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { spaces } from '@engage/styles';
import { title20 } from '@engage/typography';
import { TagsCopy, Tags, useTags } from '@members/copy';
import type { TagDetailQuery } from '@members/graphql-swr/schemas/tags/queries/TagDetail.generated';
import { ErrorView } from '../ErrorView';
import { Loading } from '../Loading';
import { SharedHeader as Header } from '../Modal/TagsModalHeaderView';
import { TagsContext } from '../TagsContext';
import type { TagDetailHeaderViewProps } from './TagDetailHeaderView';
import { TagDetailHeaderView } from './TagDetailHeaderView';
import type { TagDetailsMemberListProps } from './TagDetailsMemberList';
import { TagDetailsMemberList } from './TagDetailsMemberList';

type TagDetailsViewProps = {
  error: boolean;
  loading: boolean;
  refetch: KeyedMutator<TagDetailQuery>;
};

export type OuterProps = TagDetailHeaderViewProps &
  TagDetailsMemberListProps &
  TagDetailsViewProps;

export const useInitialLoadComplete = (numberOfMembers: number) => {
  const [initialLoadComplete, setInitialLoadComplete] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (numberOfMembers === 0) {
      setInitialLoadComplete(true);
    }
  }, [numberOfMembers]);

  return {
    initialLoadComplete,
    setInitialLoadComplete,
  };
};

export const TagDetailsView: React.FC<React.PropsWithChildren<OuterProps>> = ({
  error,
  loading,
  refetch,
  ...props
}) => {
  const { numberOfMembers } = props;
  const containerRef = React.useRef(null);
  const { goBack } = React.useContext(TagsContext);
  const [headerHeight, setHeaderHeight] = React.useState<number | undefined>(undefined);
  const headerHeightProps = { headerHeight, setHeaderHeight };
  const { initialLoadComplete, setInitialLoadComplete } = useInitialLoadComplete(
    numberOfMembers,
  );
  const initialLoadProps = { initialLoadComplete, setInitialLoadComplete };
  const showLoading = loading || !initialLoadComplete;

  return (
    <Container ref={containerRef}>
      <If condition={showLoading && !error}>
        <LoadingContainer>
          <Loading isLoading={showLoading} size={40} />
        </LoadingContainer>
      </If>
      <If condition={error}>
        <Header
          button={{
            content: useTags(Tags.BackCta),
            props: { onClick: goBack },
          }}
        />
        <ErrorView tryAgain={() => refetch()} />
      </If>
      <If condition={!loading && !error}>
        {/* This needs to mount when loading spinner is showing in order to call setInitialLoadComplete */}
        <Body hide={!initialLoadComplete} data-test-id="tagDetailsBody">
          {numberOfMembers === 0 ? (
            <>
              <TagDetailHeaderView {...props} {...headerHeightProps} isSticky={false} />
              <UnusedTag data-test-id="unusedTagText">
                <TagsCopy id={Tags.UnusedTag} unorphanCount={2} />
              </UnusedTag>
            </>
          ) : (
            <TagDetailsMemberList
              {...props}
              {...headerHeightProps}
              {...initialLoadProps}
            />
          )}
        </Body>
      </If>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${gray1};
  height: 100%;
  overflow-x: auto;
`;

const Body = styled.div<{ hide: boolean }>`
  opacity: ${({ hide }) => (hide ? '0' : '1')};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: left;
`;
Body.displayName = 'Body';

const UnusedTag = styled.div`
  ${title20}
  color: ${slate1};
  margin: ${spaces.xHuge}px ${spaces.large}px 0;
  text-align: center;
`;
UnusedTag.displayName = 'UnusedTag';

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;
LoadingContainer.displayName = 'LoadingContainer';
