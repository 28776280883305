import React from 'react';

export const SearchAndCreateIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 36 35" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
    >
      <path
        d="M17.87 17.87L23.609 23.609M17.575 3.556c3.871 3.87 3.871 10.147 0 14.02-3.871 3.872-10.149 3.872-14.02 0-3.871-3.873-3.871-10.15 0-14.02 3.871-3.871 10.149-3.871 14.02 0zM10.565 6.392L10.565 14.74M14.739 10.566L6.391 10.566"
        transform="translate(6 5.5)"
      />
    </g>
  </svg>
);
