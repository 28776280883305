import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { white, gray3, slate3, slate2 } from '@engage/colors';
import { useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { title24Bold, interV, label16, fontWeights } from '@engage/typography';
import { useLogout } from '@members/auth/OauthProvider';
import { Button1Large, DarkButton2Large } from '@members/buttons';
import {
  useAcceptTerms,
  AcceptTerms,
  AcceptTermsCopy,
  RemoteActivationCopy,
  RemoteActivation,
} from '@members/copy';
import { Modal } from '@members/modal';
import { BirthdateErrorsContext } from './BirthdateErrorsContext';

type Props = {
  darkMode?: boolean;
  isRemoteActivation?: boolean;
  remoteActivationSkipHandler?: () => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  isNewUser: boolean;
};

const Container = styled.div<Pick<Props, 'darkMode'>>`
  padding: ${spaces.xxxLarge}px ${spaces.large}px;

  ${media.tablet`
    padding: ${spaces.huge}px;
  `}

  ${media.iPhone6`
    height: 100%;
  `}

  font-family: ${interV};
  display: flex;
  border-style: hidden;
  flex-direction: column;
  align-items: center;
  background-color: ${p => (p.darkMode ? slate3 : white)};
`;

const DeclineAgeHeader = styled.div<Pick<Props, 'darkMode'>>`
  ${title24Bold}
  color: ${p => (p.darkMode ? white : slate3)};
`;

const DeclineAgeTerms = styled.div<Pick<Props, 'darkMode'>>`
  color: ${p => (p.darkMode ? gray3 : slate2)};
  ${label16}
  text-align: center;
  margin-top: ${spaces.small}px;
  margin-bottom: ${spaces.xxxLarge}px;
  font-weight: ${fontWeights.xBold};
`;

const GoBackButton = styled(Button1Large)`
  width: 263px;
  margin-bottom: ${spaces.small}px;

  ${media.iPhone6`
    width: 320px;
  `}
`;

const DeclineButton = styled.button<Pick<Props, 'darkMode'>>`
  color: ${p => (p.darkMode ? white : slate2)};
  padding: ${spaces.small}px;
  ${label16}
`;

const EnterAgeButton = styled(DarkButton2Large)`
  width: 263px;
  margin-bottom: ${spaces.small}px;

  ${media.iPhone6`
    width: 320px;
  `}
`;

const DeclineModal: React.FC<React.PropsWithChildren<Props>> = ({
  darkMode = false,
  isRemoteActivation = false,
  isOpen,
  setIsOpen,
  remoteActivationSkipHandler,
  isNewUser,
}) => {
  const logout = useLogout();
  const trackAnalytics = useReduxAction(track);
  const { errorList, errorCount } = useContext(BirthdateErrorsContext);

  // sets decline modal as the top layer (appears above other modals)
  const overlayRef = (ref: HTMLDivElement) => {
    if (ref && ref?.style) {
      ref.style.zIndex = '6';
    }
  };

  useEffect(() => {
    if (isOpen) {
      trackAnalytics({
        event: 'Viewed Decline Age Confirmation Modal',
        '[Is New User]': isNewUser,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      contentLabel={useAcceptTerms(AcceptTerms.DeclineAgeConfirmation)}
      isOpen={isOpen}
      hideCloseButton={true}
      style={{ maxWidth: '560px' }}
      modalStyle={{
        background: 'rgba(0, 0, 0, 0.48)',
        display: 'flex',
        alignItems: 'center',
      }}
      overlayRef={overlayRef}
    >
      <StyledContainer
        data-test-id="declineAgeModal"
        darkMode={darkMode || isRemoteActivation}
      >
        <DeclineAgeHeader darkMode={darkMode || isRemoteActivation}>
          <AcceptTermsCopy id={AcceptTerms.DeclineAgeConfirmation} />
        </DeclineAgeHeader>
        {isRemoteActivation ? (
          <DeclineAgeTerms data-test-id="declineAgeConfirmation" darkMode={darkMode}>
            <RemoteActivationCopy id={RemoteActivation.DeclineAgeConfirmation} />
          </DeclineAgeTerms>
        ) : (
          <DeclineAgeTerms darkMode={darkMode}>
            <AcceptTermsCopy id={AcceptTerms.DeclineAgeTerms} />
          </DeclineAgeTerms>
        )}

        {isRemoteActivation && remoteActivationSkipHandler ? (
          <>
            <EnterAgeButton
              data-test-id="enterAgeNowButton"
              onClick={() => setIsOpen(false)}
            >
              <RemoteActivationCopy id={RemoteActivation.EnterAgeNow} />
            </EnterAgeButton>
            <DeclineButton
              data-test-id="skipLink"
              darkMode={true}
              onClick={() => {
                trackAnalytics({
                  event: 'Declined Age Confirmation',
                  ['Number of Errors']: errorCount,
                  ['Errors Received']: errorList,
                  ['Is New User']: isNewUser,
                });
                remoteActivationSkipHandler();
              }}
            >
              <RemoteActivationCopy id={RemoteActivation.SkipLabel} />
            </DeclineButton>
          </>
        ) : (
          <>
            <GoBackButton data-test-id="goBackButton" onClick={() => setIsOpen(false)}>
              <AcceptTermsCopy id={AcceptTerms.GoBack} />
            </GoBackButton>
            <DeclineButton
              data-test-id="declineModalButton"
              darkMode={darkMode}
              onClick={() => {
                trackAnalytics({
                  event: 'Declined Age Confirmation',
                  ['Number of Errors']: errorCount,
                  ['Errors Received']: errorList,
                  ['Is New User']: isNewUser,
                });
                logout();
              }}
            >
              <AcceptTermsCopy id={AcceptTerms.Decline} />
            </DeclineButton>
          </>
        )}
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled(Container)``;

export default DeclineModal;
