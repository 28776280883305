import React from 'react';

const SearchIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#4C525E"
      fillRule="evenodd"
      d="M3 10a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-8a8 8 0 1 0 5.292 14l5.854 5.854a.5.5 0 1 0 .708-.707l-5.855-5.855A8 8 0 0 0 10 2Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SearchIcon;
