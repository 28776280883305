import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useOnClickOutside } from '@peloton/react';
import { slate1, gray1, gray4 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxState, useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label14, body14 } from '@engage/typography';
import { TagsCopy, Tags } from '@members/copy';
import {
  didDismissTagsLeaderboardTooltip,
  dismissTagsLeaderboardTooltip,
} from '@members/notifications/redux';

const ANIMATION_DURATION = 250;

export const useTooltipVisibility = (dismissTooltip: () => void) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(true);

  const handleClick = React.useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      dismissTooltip();
    }, ANIMATION_DURATION);
  }, [dismissTooltip, setIsVisible]);

  return {
    handleClick,
    isVisible,
  };
};

export const LeaderboardTooltip: React.FC<React.PropsWithChildren<unknown>> = () => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const dismissTooltip = useReduxAction(dismissTagsLeaderboardTooltip);
  const dismissedTooltip = useReduxState(didDismissTagsLeaderboardTooltip);
  const showTooltip = !dismissedTooltip;

  const { handleClick, isVisible } = useTooltipVisibility(dismissTooltip);
  useOnClickOutside(wrapperRef, handleClick);

  return (
    <If condition={showTooltip}>
      <ScreenOverlay data-test-id="screenOverlay" />
      <Wrapper ref={wrapperRef} isVisible={isVisible}>
        <UpArrow />
        <Content>
          <Header data-test-id="leaderboardTooltipHeader">
            <TagsCopy id={Tags.LeaderboardTag} />
          </Header>
          <WhiteLine />
          <Description data-test-id="leaderboardTooltipDescription">
            <TagsCopy id={Tags.LeaderboardTagDescription} unorphanCount={2} />
          </Description>
        </Content>
      </Wrapper>
    </If>
  );
};

const ScreenOverlay = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;
ScreenOverlay.displayName = 'ScreenOverlay';
const Wrapper = styled.div<{ isVisible: boolean }>`
  top: 44px;
  right: -${spaces.medium}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;

  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
        `
      : css`
          animation: ${Fadeout} 0.25s ease both;
        `}}
`;
Wrapper.displayName = 'Wrapper';

const Fadeout = keyframes`
  0% {
    opacity: 1;
  }
  33% {
    opacity: 66%;
  }
  66% {
    opacity: 33%;
  }
  100% {
    opacity: 0;
  }
`;

const UpArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${slate1};
  transform: translateX(108px);
`;

const Content = styled.div`
  min-width: 280px;
  background-color: ${slate1};
  border-radius: ${spaces.xxSmall}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spaces.xLarge}px ${spaces.large}px;
`;

const Header = styled.span`
  ${label14}
  color: ${gray1};
`;

const Description = styled.p`
  ${body14}
  color: ${gray1};
  margin-top: ${spaces.medium}px;
`;

const WhiteLine = styled.div`
  height: 1px;
  width: ${spaces.xLarge}px;
  background-color: ${gray4};
  margin-top: ${spaces.large}px;
`;
