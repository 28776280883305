import { sortBy, indexOf, contains, compose, filter } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { isDefined } from '@peloton/types';
import {
  BrowseCategorySlug,
  browseCategorySlugToCaesarMapper,
} from '@engage/browse-categories';
import type { FilterGroup, ApiFilterGroup, ApiResponse, FilterOptions } from './models';
import { FilterName } from './models';

const toOrderedFilterNames = (): FilterName[] => {
  return [
    FilterName.Duration,
    FilterName.InstructorId,
    FilterName.ClassTypeId,
    FilterName.BodyActivity,
    FilterName.ClassLanguages,
    FilterName.MusicGenre,
    FilterName.DifficultyLevel,
    FilterName.Subtitles,
    FilterName.IsFavoriteRide,
    FilterName.IsFreemium,
    FilterName.HasWorkout,
    FilterName.HasCyclingArms,
  ];
};
// legal requirement: free and is_freemium must actually be called app-free
// so we map is_freemium to app-free until the API supports app-free
export const toMapper = () => ({ filters, sorts }: ApiResponse): FilterOptions => ({
  filters: toFilterGroups(toOrderedFilterNames())(
    filters.map(f => ({
      ...f,
      name: f.name === 'is_freemium' ? FilterName.IsFreemium : f.name,
    })),
  ),
  sorts,
});

const toFilterGroups = (orderedFilterNames: FilterName[]) =>
  compose<ApiFilterGroup[], any, FilterGroup[]>(
    sortBy((f: FilterGroup) => indexOf(f.name, orderedFilterNames)),
    filter<ApiFilterGroup>(f => contains(f.name, orderedFilterNames)),
  );

const toFiltersUrl = (browseCategory?: BrowseCategorySlug) =>
  `/api/ride/filters?include_icon_images=true&library_type=on_demand${
    isDefined(browseCategory) &&
    browseCategory !== BrowseCategorySlug.All &&
    browseCategory !== BrowseCategorySlug.Free
      ? `&browse_category=${browseCategorySlugToCaesarMapper(browseCategory)}`
      : ''
  }`;

export const fetchFilters = (api: Client, browseCategory?: BrowseCategorySlug) =>
  api.get(toFiltersUrl(browseCategory)).then(pipeData(toMapper()));
