import { getUserPartnershipVitality } from '@engage/api-v2';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

// 60 seconds
const HEARTBEAT = 60 * 1000;

export const toPartnershipFetchers = (api: typeof engageApi = engageApi) => ({
  GetPartnership: toFetcher(
    () => `GetPartnerships`,
    () => unwrap(() => getUserPartnershipVitality(api)),
    {
      // refreshInterval: 0,
      dedupingInterval: HEARTBEAT,
      shouldRetryOnError: false,
    },
  ),
});
