// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const GuestPass = {
  CallToAction: 'callToAction',
  CardDetails: 'cardDetails',
  CardHeading: 'cardHeading',
  CtaButton: 'ctaButton',
  DigitalGuestPassFortyFive: 'digitalGuestPassFortyFive',
  DigitalGuestPassNinety: 'digitalGuestPassNinety',
  DigitalGuestPassSixty: 'digitalGuestPassSixty',
  DigitalGuestPassThirty: 'digitalGuestPassThirty',
  GeneralError: 'generalError',
  GuestPassEmailBody: 'guestPassEmailBody',
  GuestPassEmailSubject: 'guestPassEmailSubject',
  GuestPassShare: 'guestPassShare',
  GuestPassTextBody: 'guestPassTextBody',
  InviteFriends: 'inviteFriends',
  InviteWithGuestPass: 'inviteWithGuestPass',
  LinkCopied: 'linkCopied',
  NoFriendsFoundInvite: 'noFriendsFoundInvite',
  TermsOfServiceCta: 'termsOfServiceCta',
  TermsOfServiceHeader: 'termsOfServiceHeader',
  TermsOfServiceLinkText: 'termsOfServiceLinkText',
  TermsOfServiceText: 'termsOfServiceText',
  TooManyAttemptsError: 'tooManyAttemptsError',
} as const;
