import React from 'react';
import styled from 'styled-components';
import { slate1, slate3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { title24, body14 } from '@engage/typography';
import { TagsCopy, Tags } from '@members/copy';
import { ScrollContext } from '../MainView/ScrollContext';

const EmptyState: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setNavBarAnimation } = React.useContext(ScrollContext);
  return (
    <EmptyStateContainer
      data-test-id="emptyState"
      onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
        if (e.deltaY < 0) {
          setNavBarAnimation(e.deltaY);
        }
      }}
    >
      <Header data-test-id="emptyStateHeader">
        <TagsCopy id={Tags.SearchForTagsDescription} unorphanCount={2} />
      </Header>
      <Subheader data-test-id="emptyStateDescription">
        <TagsCopy id={Tags.TagRequirements} unorphanCount={2} />
      </Subheader>
    </EmptyStateContainer>
  );
};

export { EmptyState };

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Header = styled.span`
  ${title24}
  font-weight: 100;
  color: ${slate3};
  margin: 0 ${spaces.large}px 0;
`;

const Subheader = styled.span`
  ${body14}
  color: ${slate1};
  margin: ${spaces.medium}px ${spaces.large}px 0;
`;
