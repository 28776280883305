import React from 'react';

const CloseIconNew: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#222529"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.146446 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146446 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853554 0.146447L8 7.29289L15.1464 0.146447C15.3417 -0.0488155 15.6583 -0.0488155 15.8536 0.146447C16.0488 0.341709 16.0488 0.658291 15.8536 0.853553L8.70711 8L15.8536 15.1464C16.0488 15.3417 16.0488 15.6583 15.8536 15.8536C15.6583 16.0488 15.3417 16.0488 15.1464 15.8536L8 8.70711L0.853554 15.8536C0.658292 16.0488 0.341709 16.0488 0.146447 15.8536C-0.0488151 15.6583 -0.0488151 15.3417 0.146447 15.1464L7.29289 8L0.146446 0.853553Z"
    />
  </svg>
);

export { CloseIconNew };
