import { prop, sortBy } from 'ramda';
import { isDefined } from '@peloton/types';
import type { Splits } from '../models/WorkoutPerformance';
import type { ApiSplits, ApiSplit } from './types';
export const toSplitsData = (
  apiSplitsData?: [] | ApiSplits,
  isLocationDataAccurate?: boolean | null,
) => {
  const isLocationDataInaccurate = isLocationDataAccurate === false;
  const hasSplits =
    isDefined(apiSplitsData) &&
    apiSplitsData.hasOwnProperty('splits') &&
    (apiSplitsData as ApiSplits).splits.length > 0;

  if (isLocationDataInaccurate || !hasSplits) {
    return {};
  }

  return {
    splits: toSplits(apiSplitsData as ApiSplits),
  };
};

export const toSplits = ({
  distanceMarkerDisplayUnit,
  elevationChangeDisplayUnit,
  splits,
}: ApiSplits): Splits => ({
  distanceMarkerDisplayUnit,
  elevationChangeDisplayUnit,
  splits: toSplitsArray(splits),
});

// returns pace as a percentage of the fastest split,
// so that the fastest is 100, the slowest is 0,
// and there is a little tweaking (the buffer) for aesthetics.
const toPacePercent = (s: number, slowest: number, fastest: number) => {
  if (slowest === fastest) {
    return 100;
  }
  const range = slowest - fastest;
  const buffer = range / 2;
  return Math.round((100 * (slowest - s + buffer)) / (range + buffer));
};

export const toSplitsArray = (apiSplits: ApiSplit[]) => {
  if (apiSplits.length === 0) {
    return [];
  }

  const sortedSeconds = sortBy(prop('seconds'))(apiSplits);
  const fastest = sortedSeconds[0].seconds;
  const slowest = sortedSeconds[sortedSeconds.length - 1].seconds;

  return sortBy(prop('order'))(apiSplits).map(
    ({ distanceMarker, elevationChange, isBest, seconds }) => ({
      distanceMarker,
      elevationChange,
      isBest,
      seconds,
      pacePercent: toPacePercent(seconds, slowest, fastest),
    }),
  );
};
