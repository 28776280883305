import type { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { updateContentPreferencesApi as update } from '../api';
import type { UpdateContentPreferencesAction } from '../redux';

export default function* (
  client: Client,
  { presenter, settings }: UpdateContentPreferencesAction,
): SagaIterator {
  try {
    const userId = yield select(getSignedInUserId);

    if (userId) {
      yield call(update, client, userId, settings);
      yield call(presenter.displaySuccessMessage);
    } else {
      yield call(presenter.redirectToLoginPage);
    }
  } catch {
    yield call(presenter.displayErrorMessage);
  }
}
