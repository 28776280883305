import { replace } from 'connected-react-router';
import type { SagaIterator } from 'redux-saga';
import {
  call,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery,
  throttle,
} from 'redux-saga/effects';
import type { ScreenProps } from '@peloton/analytics';
import { getScreenPropsSaga, toClientDetails } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getUserId } from '@peloton/auth';
import { toOverviewUrl } from '@peloton/links/members';
import { isDefined } from '@peloton/types';
import { handleBlockedUserError } from '@engage/api-config/sagas';
import { fetchProfile, updateMember, updateRelationship } from './api';
import type { MemberRelationship } from './models';
import { isSelf, ME, toRelationshipChange, RelationshipChange } from './models';
import type {
  ChangeRelationshipRequestAction,
  MemberRequestAction,
  MemberFollowAction,
  MemberSuccessAction,
  MemberUpdateAction,
} from './redux';
import {
  changeRelationshipSuccess,
  memberFail,
  MembersActionType,
  memberSuccess,
  updateMemberInfo,
} from './redux';
import { getMember } from './selectors';

export enum CompleteProfileActions {
  CompleteProfile = 'pelo/@members/COMPLETE_PROFILE',
}

type Callbacks = {
  onSuccess(): void;
  onError(msg: string): void;
};

type CompleteProfilePayload = {
  callbacks: Callbacks;
  profile: {
    username: string;
    location: string;
  };
};

export const loadMemberSaga = function* (
  client: Client,
  action: MemberRequestAction,
): SagaIterator {
  try {
    const payload = yield call(fetchProfile, client, action.payload.userIdOrUsername);
    yield put(memberSuccess(payload));
    return payload;
  } catch (e) {
    yield put(memberFail(action.payload.userIdOrUsername, e));
    yield call(handleBlockedUserError, e);
    return undefined;
  }
};

const updateMemberSaga = function* (
  client: Client,
  action: MemberUpdateAction,
): SagaIterator {
  try {
    const response = yield call(updateMember, client, action.payload);
    yield put(memberSuccess(response));
  } catch (e) {
    yield put(memberFail(action.payload.id, e));
  }
};

export const completeProfile = (payload: CompleteProfilePayload) => ({
  type: CompleteProfileActions.CompleteProfile,
  payload,
});

export const completeProfileSaga = function* (
  action: ReturnType<typeof completeProfile>,
): SagaIterator {
  const id = yield select(getUserId);
  yield put(updateMemberInfo({ id, ...action.payload.profile }));

  const {
    success,
    failure,
  }: {
    success: ReturnType<typeof memberSuccess>;
    failure: ReturnType<typeof memberFail>;
  } = yield race({
    success: take(MembersActionType.MemberSuccess),
    failure: take(MembersActionType.RequestFailure),
  });

  if (isDefined(success)) {
    yield call(action.payload.callbacks.onSuccess);
  } else {
    yield call(action.payload.callbacks.onError, failure.payload.message);
  }
};

export const followMemberSaga = function* (client: Client, action: MemberFollowAction) {
  const { userId } = action.payload;

  try {
    const screenProps: ScreenProps = yield call(getScreenPropsSaga);

    yield call(
      updateRelationship,
      client,
      userId,
      RelationshipChange.Follow,
      toClientDetails<ScreenProps>(screenProps, 'Following'),
    );
  } catch (e) {
    yield put(memberFail(userId, e));
  } finally {
    // redirect to overview regardless of success or failure, as:
    //   - a) if request 'failed' due to already following the user, just show the user overview,
    //        as the follow state is reflected there
    //   - b) the overview page has a mechanism for following, so it allows retries for *other* failures,
    //        and provides error handling out of the box
    yield put(replace(toOverviewUrl(userId)));
  }
};

export const toggleFollowMemberSaga = function* (
  client: Client,
  action: ChangeRelationshipRequestAction,
): SagaIterator {
  const member = yield select(getMember, action.payload.namespace);
  const relationshipChange = toRelationshipChange(member);
  try {
    const screenProps: ScreenProps = yield call(getScreenPropsSaga);
    const newRelationship: MemberRelationship = yield call(
      updateRelationship,
      client,
      member.id,
      relationshipChange,
      toClientDetails<ScreenProps>(screenProps, action.meta.source),
    );
    const self = yield select(getMember);
    yield put(
      changeRelationshipSuccess(member.id, self.id, newRelationship, relationshipChange),
    );
  } catch (e) {
    yield put(memberFail(member ? member.id : action.payload.namespace, e));
  }
};

export const memberSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(MembersActionType.MemberRequest, loadMemberSaga, client);
  yield takeEvery(MembersActionType.MemberUpdate, updateMemberSaga, client);
  yield takeEvery(MembersActionType.FollowRequest, followMemberSaga, client);
  yield throttle(
    250,
    MembersActionType.ToggleFollowRequest,
    toggleFollowMemberSaga,
    client,
  );
};

// Checks whether action is a members success action for a specified member
export const isMembersSuccessActionForMember = (usernameOrId: string) => (
  membersAction: MemberSuccessAction,
) =>
  membersAction.type === MembersActionType.MemberSuccess &&
  (membersAction.payload.id === usernameOrId ||
    membersAction.payload.username.toLowerCase() === usernameOrId.toLowerCase() ||
    (usernameOrId === ME && isSelf(membersAction.payload)));
