import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';

const toReferralHistoryUrl = (userId: string) => `api/user/${userId}/referral_history`;

export const fetchReferralEligibility = (api: Client, userId: string) =>
  api
    .get(toReferralHistoryUrl(userId), toSkipErrorHandlingConfig({}, { 404: true }))
    .then(pipeData(toReferralEligibility));

export const toReferralEligibility = ({ isActive }: ApiReferralHistory): boolean =>
  isActive;

export type ApiReferralHistory = {
  isActive: boolean;
};
