import React from 'react';
import styled from 'styled-components';
import { Format, formatDate, toUtcTime } from '@peloton/time';
import { gray2, gray3, slate1, slate6 } from '@engage/colors';
import { useReduxState } from '@engage/redux';
import { getSubscriptionsResult, toPrimarySub } from '@engage/subscriptions';
import { body14, label14, label16, title20 } from '@engage/typography';
import { useTiering } from '@members/copy';
import { UsersFetchers } from '@members/data-fetch';
import { useDecoratedSWR } from '@members/data-fetch/useDecoratedSWR';
import { computeUserTierData } from '@members/tiering/hooks/useMembershipTieringInfo';
import type { TierType } from '@members/tiering/models';

const MembershipDetails: React.FC<React.PropsWithChildren<{}>> = () => {
  const { data: rawTieringData, error: fetchError } = useDecoratedSWR(
    ...UsersFetchers.GetMembershipTieringInfo({}),
  );
  const membershipData = rawTieringData?.membershipTieringInfo;

  const isFreeMember = membershipData?.tierType === 'no_membership';
  const tier = computeUserTierData(membershipData, isFreeMember);

  const tierDisplayName = useTiering(tier.tierLabelID);

  const quotaExpiryDate =
    formatDate(toUtcTime(tier.limitedClassesResetDate), Format.MonthNameShortDate) +
    ' @' +
    formatDate(toUtcTime(tier.limitedClassesResetDate), Format.Time24Hour) +
    ' UTC';

  const subs = useReduxState(getSubscriptionsResult);
  const primarySub = toPrimarySub(subs.value) as any;
  const shouldShowInfo = tier.tierType === ('APP' as TierType);

  return fetchError ? null : (
    <MembershipDetailsContainer>
      <InfoHeader>Membership Details</InfoHeader>
      <InfoLabel>{tierDisplayName}</InfoLabel>
      {shouldShowInfo && (
        <InfoText>
          Quota Resets: <InfoBold>{quotaExpiryDate}</InfoBold>
        </InfoText>
      )}
      <InfoText>
        Billing Status: <InfoBold>{primarySub?.status.text ?? 'unavailable'}</InfoBold> |
        Platform: <InfoBold>{primarySub?.platform ?? 'unavailable'}</InfoBold> |
        Frequency: <InfoBold>{primarySub?.billingFrequency ?? 'unavailable'}</InfoBold> |
        Type: <InfoBold>{primarySub?.billingType ?? 'unavailable'}</InfoBold> | Kind:{' '}
        <InfoBold>{primarySub?.kind ?? 'unavailable'}</InfoBold>
      </InfoText>
      <MembershipTableContainer>
        <MembershipTable>
          <TableHead>
            <tr>
              <th></th>
              <TableField id="center">API Tier Data</TableField>
              <TableField id="center">Acting Tier Info</TableField>
            </tr>
          </TableHead>
          <tbody>
            <tr>
              <TableField>Tier Type</TableField>
              <td>{membershipData?.tierType ?? 'unavailable'}</td>
              <td>{tier.tierType}</td>
            </tr>
            {shouldShowInfo && (
              <>
                <tr>
                  <TableField>CFX Classes Total</TableField>
                  <td>{membershipData?.limitedClassesTotal ?? 'unavailable'}</td>
                  <td>{tier.numLimitedClassesTotal}</td>
                </tr>
                <tr>
                  <TableField>CFX Classes Taken</TableField>
                  <td>{membershipData?.limitedClassesTaken ?? 'unavailable'}</td>
                  <td>{tier.numLimitedClassesTaken}</td>
                </tr>
              </>
            )}
          </tbody>
        </MembershipTable>
      </MembershipTableContainer>
    </MembershipDetailsContainer>
  );
};

const MembershipDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 12px;
`;

const InfoHeader = styled.div`
  ${title20}
  padding-bottom: 12px;
`;

const InfoLabel = styled.p`
  ${label16}
`;

const InfoText = styled.p`
  ${body14}
  color: ${slate1};
  text-transform: capitalize;
`;

const InfoBold = styled.strong`
  ${label14}
  color: ${slate6};
`;

const MembershipTableContainer = styled.div`
  padding: 16px;
  margin-top: 12px;
  background: #ffffff;
  border: 1px solid ${gray2};
  border-radius: 9px;
  width: 100%;
`;

const MembershipTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  #center {
    text-align: center;
  }

  * > td {
    text-align: center;
    ${label14}
  }
`;

const TableHead = styled.thead`
  border-bottom: 1px ${gray3} solid;
`;

const TableField = styled.th`
  ${body14}
  color: ${slate1};
  text-align: left;
`;

export default MembershipDetails;
