import type { Location } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import type { RoutingState } from '@peloton/redux';
import { getLocation } from '@peloton/redux';

const NavLinkView: React.FC<React.PropsWithChildren<Props & { dispatch?: any }>> = ({
  isExternal,
  to,
  activeClassName,
  location,
  className,
  dispatch,
  style,
  ...props
}) =>
  isExternal ? (
    // eslint-disable-next-line
    <a
      target="_blank"
      {...props}
      href={to as string}
      rel="noopener"
      style={typeof style === 'function' ? style?.(false) : style}
      className={typeof className === 'function' ? className?.(false) : className}
    />
  ) : (
    <ReactRouterNavLink
      {...props}
      className={className}
      style={style}
      to={to}
      activeClassName={activeClassName}
      location={location}
    />
  );

type StateProps = {
  location: Location;
};

type OwnProps = NavLinkProps & {
  isExternal?: boolean;
};

type Props = StateProps & OwnProps;

const NavLink = connect<StateProps, {}, OwnProps, RoutingState>(state => ({
  location: getLocation(state),
}))(NavLinkView);

export default NavLink;
