import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label14, h4Bold, h2Bold, label14Caps } from '@engage/typography';
import { useSite, Site } from '@members/copy';

const Oops: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Wrapper data-test-id="oops">
    <H>{useSite(Site.Error)}</H>
    <P>{useSite(Site.ErrorDetails)}</P>
    <Link href="/home">Go to homepage</Link>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const H = styled.h2`
  ${h4Bold}
  margin: ${spaces.giant}px ${spaces.huge}px 0 ${spaces.huge}px;
  text-align: center;
  ${media.tablet`
    ${h2Bold}
  `}
`;

const P = styled.p`
  ${label14}
  margin: ${spaces.large}px ${spaces.huge}px ${spaces.huge}px ${spaces.huge}px;
  text-align: center;
  ${media.tablet`
    margin-top: 30px;
  `}
`;

const Link = styled.a`
  ${label14Caps}
  border-radius: 9999px;
  background: ${slate1};
  color: white;
  padding: 10px;
  width: 158px;
  height: 40px;
`;
export default Oops;
