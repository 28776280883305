import type {
  MuscleGroupScoreResponse,
  PerformanceStat,
  PaginationResponse,
  Workout,
  Ride,
} from '../generated';
import { getUserWorkouts } from '../generated';
import type { AxiosPromise } from '../generated/core/request';

export const getUserWorkoutsWithMuscleGroupScore = (
  ...args: any[]
): AxiosPromise<
  PaginationResponse & {
    data: (Workout & { ride?: Ride })[];
    summary: Record<string, number>;
  } & {
    muscleGroupScore?: MuscleGroupScoreResponse;
    aggregateStats?: Array<PerformanceStat>;
    totalHeartRateZoneDurations?: {
      heartRateZ1Duration?: number;
      heartRateZ2Duration?: number;
      heartRateZ3Duration?: number;
      heartRateZ4Duration?: number;
      heartRateZ5Duration?: number;
    };
  }
> => getUserWorkouts.apply(this, args);
