/* eslint-disable */
import * as Types from '../../../types.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateInviteShareLinkMutationVariables = Types.Exact<{
  inviteIdToShare: Types.Scalars['ID']['input'];
}>;


export type CreateInviteShareLinkMutation = { __typename: 'Mutation', createInviteShareLink: { __typename: 'InviteFriendsCreateShareLinkDisabledError', message?: string | undefined } | { __typename: 'InviteFriendsInvalidDataFormat', message?: string | undefined } | { __typename: 'InviteFriendsItemNotFoundError', message?: string | undefined } | { __typename: 'InviteFriendsNoPermissionToCreateShareLinkError', message?: string | undefined } | { __typename: 'ShareURL', url: string } };


export const CreateInviteShareLinkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateInviteShareLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"inviteIdToShare"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createInviteShareLink"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"inviteId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"inviteIdToShare"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"InviteFriendsError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShareURL"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode<CreateInviteShareLinkMutation, CreateInviteShareLinkMutationVariables>;