import type { Client, UserAttributes } from '@optimizely/optimizely-sdk';
import { useEffect, useState } from 'react';
import type { PendingResult } from '@engage/result';
import { ok, isOk, isError, pending, error as engageError } from '@engage/result';
import { useClient } from './client';
import type {
  VariableType,
  VariableQuery,
  VariableResult,
  VariableCallback,
} from './models';

const getVariableValue = <T extends VariableQuery<VariableType>>(
  client: Client,
  userId: string,
  featureKey: string,
  query: T,
  attributes?: UserAttributes,
): VariableResult<T>['value'] | null => {
  type TReturn = VariableResult<T>['value'] | null;
  switch (query.type) {
    case 'boolean':
      return client.getFeatureVariableBoolean(
        featureKey,
        query.key,
        userId,
        attributes,
      ) as TReturn;
    case 'double':
      return client.getFeatureVariableDouble(
        featureKey,
        query.key,
        userId,
        attributes,
      ) as TReturn;
    case 'integer':
      return client.getFeatureVariableInteger(
        featureKey,
        query.key,
        userId,
        attributes,
      ) as TReturn;
    case 'string':
      return client.getFeatureVariableString(
        featureKey,
        query.key,
        userId,
        attributes,
      ) as TReturn;
    default:
      return undefined as never;
  }
};

export const useFeatureVariables = (userId: string, attributes?: UserAttributes) => {
  const [result, setResult] = useState<PendingResult<VariableCallback, undefined>>(
    pending,
  );
  const [client] = useClient();

  useEffect(() => {
    if (isOk(client)) {
      const callback: VariableCallback = <T extends VariableQuery<VariableType>>(
        featureKey: string,
        query: T,
      ): VariableResult<T> => ({
        key: query.key,
        value: (getVariableValue(client.value, userId, featureKey, query, attributes) ??
          query.defaultValue ??
          null) as any, // TODO: use a conditional type here
      });
      setResult(ok(callback));
    } else if (isError(client)) {
      setResult(engageError(undefined));
    }
  }, [client, userId]);

  return result;
};
