import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import type { ForgotPasswordUser } from '@peloton/auth';
import {
  requestForgotPassword,
  ForgotPasswordActionType,
} from '@peloton/forgot-password';

export const onForgotPasswordFormSaga = function* (
  action: ReturnType<typeof submitForgotPasswordForm>,
) {
  yield put(requestForgotPassword(action.payload));

  const { failure } = yield race({
    success: take(ForgotPasswordActionType.REQUEST_SUCCESS),
    failure: take(ForgotPasswordActionType.REQUEST_FAILURE),
  });

  if (failure && action.onError) {
    yield call(action.onError, failure.payload);
  } else if (action.onSuccess) {
    yield call(action.onSuccess);
  }
};

export const forgotPasswordFormSaga = function* () {
  yield takeEvery(ForgotPasswordFormActionType.Submit, onForgotPasswordFormSaga);
};

enum ForgotPasswordFormActionType {
  Submit = 'pelo/@members/pg-auth/ForgotPasswordFormSubmit',
}

export const submitForgotPasswordForm = (
  payload: ForgotPasswordUser,
  callbacks: Partial<ForgotPasswordFormCallbacks> = {},
) => ({
  type: ForgotPasswordFormActionType.Submit as ForgotPasswordFormActionType.Submit,
  payload,
  ...callbacks,
});

type ForgotPasswordFormCallbacks = {
  onError(error?: Error): void;
  onSuccess?(): void;
};
