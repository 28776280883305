import { stringify } from 'querystring';
import { toModalOpenRecord } from '@members/modal';
import { GUEST_PASS_MODAL_NAME } from './shared';

export enum GUEST_PASS_SOURCE {
  ProfileOverview = 'Profile Overview',
  Followers = 'Followers',
  Following = 'Following',
  MemberSearch = 'Member Search',
  FindFacebookFriends = 'Find Facebook Friends',
}

export const UTM_SOURCE_PARAM = 'utm_source';

export const toGuestPassModalQuery = (source: string) => {
  const queryParams = {
    ...toModalOpenRecord(GUEST_PASS_MODAL_NAME),
    [UTM_SOURCE_PARAM]: source,
  };
  return `?${stringify(queryParams)}`;
};
