import type { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put } from 'redux-saga/effects';
import { getUserEmail } from '@peloton/auth';
import { SubscriptionsActionType } from '@engage/subscriptions/redux/subscriptions';
import { getSubscriptionDetails, getSubscriptionsResult } from '@members/subscriptions';
import { updateElligibleUser } from '../redux';

const loadSubs = function* (): SagaIterator {
  try {
    const subscriptionResults = yield select(getSubscriptionsResult);
    // we're making an assumption here that the user Email WILL be avaliable
    // since this is after bootstrapping I think it should be fine
    const userEmail = yield select(getUserEmail);
    const kind = subscriptionResults.value?.[0]?.kind;
    const subId = subscriptionResults.value?.[0]?.id;
    if (userEmail && kind && subId) {
      const subs = yield call(getSubscriptionDetails, kind, subId);
      // either the user is an employee (with a onepeloton email) or
      // a QA user (with a yopmail.) email.
      yield put(
        updateElligibleUser(
          subs['costInCents'] > 0 ||
            userEmail?.includes('onepeloton.') ||
            (userEmail?.includes('@yopmail.') && subs['costInCents'] > 0),
        ),
      );
    } else {
      yield put(updateElligibleUser(false));
    }
  } catch (e) {
    yield put(updateElligibleUser(false));
  }
};

const settingsSaga = function* (): SagaIterator {
  yield takeEvery(SubscriptionsActionType.SubsRequestSuccess, loadSubs);
};

export default settingsSaga;
