import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { VITALITY_ROUTE } from './urls';

const Routes = [
  <Route
    key={VITALITY_ROUTE}
    exact={true}
    path={VITALITY_ROUTE}
    component={lazyLoader(
      () => import('./VitalityPage' /* webpackChunkName: "VitalityPage" */),
    )}
  />,
];

export default Routes;
