export enum FilterName {
  ClassTypeId = 'class_type_id',
  Duration = 'duration',
  HasClosedCaptions = 'has_closed_captions',
  HasWorkout = 'has_workout',
  InstructorId = 'instructor_id',
  IsFavoriteRide = 'is_favorite_ride',
  MusicGenre = 'super_genre_id',
  HasCyclingArms = 'has_cycling_arms',
  DifficultyLevel = 'difficulty_level',
  IgnoreClassLanguagePreferences = 'ignore_class_language_preferences',
  Subtitles = 'caption_locales',
  BodyActivity = 'muscle_group',
  ClassLanguages = 'class_languages',
  IsFreemium = 'app-free',
}

export enum FilterType {
  Collection = 'collection',
  Toggle = 'toggle',
}

export type ToggleFilterGroup = {
  type: FilterType.Toggle;
  name: FilterName;
  displayName: string;
  values: FilterValue[] & FilterValueWithImage[];
};

export type CollectionFilterGroup = {
  type: FilterType.Collection;
  name: FilterName;
  displayName: string;
  values: FilterValue[];
};

export type FilterGroup = ToggleFilterGroup | CollectionFilterGroup;

export type InstructorFilterGroup = {
  type: FilterType.Collection;
  name: FilterName.InstructorId;
  displayName: string;
  values: InstructorFilterValue[];
};

export type FilterValueWithImage = FilterValue & {
  displayImageUrl: string;
};

export type InstructorFilterValue = FilterValueWithImage;

export type FilterValue = {
  displayName: string;
  value: string;
  listOrder: number | null;
  group?: string;
};

export type Selectable<T extends object> = T & {
  selected: boolean;
};

export type Sort = {
  displayName: string;
  value: SortValue;
  slug: string;
};

export type SortValue = {
  sort: string;
  desc: boolean;
};

export type FilterOptions = {
  filters: FilterGroup[];
  sorts: Sort[];
};

export type AppliedFilter = Pick<FilterGroup, 'name'> &
  Partial<Pick<FilterGroup, 'displayName'>> & {
    value: string[];
  };

export type ApiFilterGroup = {
  type: FilterType;
  name: FilterName | string;
  displayName: string;
  values: FilterValue[];
};

export type ApiResponse = {
  filters: ApiFilterGroup[];
  sorts: Sort[];
};
