import type { BrowseCategory } from '@engage/browse-categories';
import type { FilterAnalyticsProps } from './selectors';

export enum Source {
  OnDemandLibrary = 'On-Demand Library',
  FiltersMenu = 'Filters Menu',
  ClassSearch = 'Class Search',
  ArtistSearch = 'Artist Search',
  Featured = 'Featured',
}

export enum LibraryType {
  OnDemandLibrary = 'On-Demand Library',
  Schedule = 'Schedule',
}

export type FilterMenuAnalyticsProps = {
  libraryType: LibraryType;
  browseCategory?: BrowseCategory['name'];
};

export enum FilterMenuEventNames {
  OpenedFilterMenu = 'Opened Filter Menu',
  ClosedFilterMenu = 'Closed Filter Menu',
}

export enum ClosedBySource {
  Cancel = 'Cancel',
  ShowClassesButton = 'Show Classes Button',
  LanguageSettings = 'Language Settings',
  TapOutside = 'Tap Outside',
}

export const trackOpenFlyout = (props: FilterMenuAnalyticsProps) => ({
  event: FilterMenuEventNames.OpenedFilterMenu,
  ...props,
});

export const trackCloseFlyout = (
  props: FilterMenuAnalyticsProps & FilterAnalyticsProps,
) => ({
  event: FilterMenuEventNames.ClosedFilterMenu,
  ...props,
});
