export const bookmarkParentClassName = 'navBookmarkIconContainer';
export const bookmarkButtonClassName = 'navBookmarkIconButton';

export const workoutsParentClassName = 'navWorkoutsContainer';
export const workoutsButtonClassName = 'navWorkoutsButton';

export const scheduleParentClassName = 'navScheduleIconContainer';
export const scheduleButtonClassName = 'navScheduleIconButton';

export const stacksParentClassName = 'navStacksIconContainer';
export const stacksButtonClassName = 'navStacksIconButton';

export const searchParentClassName = 'navSearchIconContainer';
export const searchButtonClassName = 'navSearchIconButton';
