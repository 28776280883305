import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import type {
  UserNavRouteOrRedirect,
  UserNavRouteInfo,
  RedirectInfo,
} from '@members/layout';

export const isRedirectProps = (props: UserNavRouteOrRedirect): props is RedirectInfo =>
  'redirect' in props;

export const isRouteProps = (props: UserNavRouteOrRedirect): props is UserNavRouteInfo =>
  'route' in props;

export const toRoutes = (routesOrRedirects: UserNavRouteOrRedirect[]) =>
  routesOrRedirects
    .filter(isRouteProps)
    .map(props => <Route key={toRouteKey(props.route)} {...props.route} />);

const toRouteKey = (route: RouteProps) =>
  route.path instanceof Array ? route.path.join('::') : route.path;

export const filterRedirects = (routesOrRedirects: UserNavRouteOrRedirect[]) =>
  routesOrRedirects.filter(isRedirectProps);

export const toRedirects = (redirects: RedirectInfo[]) =>
  redirects.map(props => <Redirect key={props.redirect.from} {...props.redirect} />);
