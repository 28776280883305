import React from 'react';
import styled from 'styled-components';
import { slate3, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label16, label14 } from '@engage/typography';
import { Facebook, FacebookCopy } from '@members/copy';
import { FacebookButton } from './buttons';
import { CenteredContainer, FixedContainer } from './Containers';
import { FacebookIconTransparent } from './icons';

export const ConnectPrompt: React.FC<React.PropsWithChildren<Props>> = props => (
  <FixedContainer>
    <CenteredContainer>
      <Icon data-test-id="facebookIcon" />
      <Paragraph data-test-id="facebookDetailsText">
        <FacebookCopy id={Facebook.ConnectDetails} />
      </Paragraph>
      <FacebookButton onClick={props.onCtaClick} data-test-id="facebookConnectButton">
        <FacebookCopy id={Facebook.Connect} />
      </FacebookButton>
      <PrivacyText data-test-id="facebookPrivacyText">
        <FacebookCopy id={Facebook.ConnectPrivacy} />
      </PrivacyText>
    </CenteredContainer>
  </FixedContainer>
);

const Icon = styled(FacebookIconTransparent)`
  width: 60px;
  height: 60px;
  fill: ${slate3};
`;

const Paragraph = styled.p`
  align-self: center;
  text-align: center;
  ${label16}
  padding-top: ${spaces.medium}px;
  padding-bottom: 25px;
  max-width: 400px;
`;

const PrivacyText = styled.div`
  ${label14}
  text-align: center;
  color: ${slate1};
  font-style: italic;
  padding-top: 30px;
`;

type Props = {
  onCtaClick(): void;
};

export default ConnectPrompt;
