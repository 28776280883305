import { useEffect, useState } from 'react';
import { track } from '@engage/analytics';
import { useReduxAction } from '@engage/redux';
import {
  trackViewedGetAppPage,
  trackDismissedDownloadOurApp,
  trackTappedDownloadOurApp,
} from './analytics';
import type { App } from './App';

export const useAnalytics = (
  appsDisplayed: App[],
  source: string,
  isVisibleToUser: boolean = true,
) => {
  const dispatchTrack = useReduxAction(track);
  const [viewedAppStore, setViewedAppStore] = useState(false);

  useEffect(() => {
    if (isVisibleToUser) {
      dispatchTrack(trackViewedGetAppPage(appsDisplayed, source));
    }
  }, [isVisibleToUser]);

  const clickedDownloadApp = (app: App) => {
    setViewedAppStore(true);
    dispatchTrack(trackTappedDownloadOurApp(appsDisplayed, app, source));
  };

  const clickedDismiss = () => {
    if (!viewedAppStore) {
      dispatchTrack(trackDismissedDownloadOurApp(appsDisplayed, source));
    }
  };

  return [clickedDownloadApp, clickedDismiss] as ((app?: App) => void)[];
};
