import React from 'react';
import styled from 'styled-components';
import type { KeyedMutator } from 'swr';
import { getUser, isSignedIn } from '@peloton/auth';
import { media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { If } from '@engage/conditional-render';
import { useReduxAction, useReduxState } from '@engage/redux';
import {
  TAGS_MODAL_NAME,
  TAG_NAME_PARAM,
  TAGS_VIEW_PARAM,
  TAG_BROWSE_CATEGORY_PARAM,
} from '@engage/tags';
import type { CopyIDs } from '@members/copy';
import { ModalLabel, useModalLabel } from '@members/copy';
import { ConnectedModal, closeModal } from '@members/modal';
import { useAnalyticsHeadersFromView, trackCancelReplaceTagFlow } from '../analytics';
import { useLastTagSearch, useSelectedTagName } from '../hooks';
import { getTagViewFromLocation, getLastTagView } from '../selectors';
import { TagModalView } from '../shared';
import { TagsModalView } from './TagsModalView';

export const closeModalHandler = (
  onClose: () => void,
  clearLastTagSearch: KeyedMutator<string>,
  sendAnalytics: () => void,
) => {
  sendAnalytics();
  onClose();
  clearLastTagSearch('');
};

export const TagsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const trackAnalytics = useReduxAction(track);
  const onClose = useReduxAction(closeModal);
  const { setLastTagSearch } = useLastTagSearch();
  const { selectedTagName } = useSelectedTagName();
  const tagName = selectedTagName;
  const view = useReduxState(getTagViewFromLocation);
  const lastView = useReduxState(getLastTagView);
  const analyticsHeaderProps = useAnalyticsHeadersFromView(lastView);

  const onCloseAnalytics = React.useCallback(() => {
    if (view === TagModalView.ReplaceTag) {
      trackAnalytics(trackCancelReplaceTagFlow(tagName, analyticsHeaderProps));
    }
  }, [view, tagName]);
  const onCloseModal = React.useCallback(
    () => closeModalHandler(onClose, setLastTagSearch, onCloseAnalytics),
    [onCloseAnalytics],
  );
  const ariaLabelId = useModalLabel(ModalLabel.Tags) as CopyIDs<typeof ModalLabel>;
  const user = useReduxState(getUser);
  const [showErrorToast, setShowErrorToast] = React.useState(false);
  const showMobileCloseButton = view !== TagModalView.TagDetails && !showErrorToast;

  return (
    <If condition={isSignedIn(user)}>
      <StyledModal
        modalNames={{
          [TAGS_MODAL_NAME]: {
            ariaLabelId,
            component: (
              <TagsModalView
                showErrorToast={showErrorToast}
                setShowErrorToast={setShowErrorToast}
              />
            ),
            showMobileCloseButton,
            modelIdentifiers: [
              TAGS_VIEW_PARAM,
              TAG_NAME_PARAM,
              TAG_BROWSE_CATEGORY_PARAM,
            ],
            onCloseModal,
          },
        }}
      />
    </If>
  );
};

// eslint:disable:inline-spaces-values
const StyledModal = styled(ConnectedModal)`
  ${media.tablet`
    max-width: 560px;
    max-height: 792px;
  `}
`;
// eslint:enable:inline-spaces-values
StyledModal.displayName = 'StyledModal';
