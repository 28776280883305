import equal from 'react-fast-compare';
import { isProdEnv } from '@members/env';

const debug = (...args: any[]) => {
  if (!isProdEnv()) {
    console.log(...args);
  }
};

/**
 * This is almost an exact copy of autoMergeLevel2 (with a few exceptions)
 * - logging is automatically enabled in non-prod encironments
 * - we are no longer relying on "===" for state equality checks
 *
 * original source: https://github.com/rt2zz/redux-persist/blob/master/src/stateReconciler/autoMergeLevel2.js
 */
export const reconcileState = (
  inboundState: any,
  originalState: any,
  reducedState: any,
) => {
  const newState = { ...reducedState };
  // only rehydrate if inboundState exists and is an object
  if (inboundState && typeof inboundState === 'object') {
    Object.keys(inboundState).forEach(key => {
      // ignore _persist data
      if (key === '_persist') {
        return;
      }

      // Using react-fast-compare for checking object equality
      if (!equal(originalState[key], reducedState[key])) {
        if (!isProdEnv()) {
          debug(
            'redux-persist/stateReconciler: sub state for key `%s` modified, skipping.',
            key,
          );
          debug(originalState[key], reducedState[key]);
        }

        return;
      }
      if (isPlainEnoughObject(reducedState[key])) {
        // if object is plain enough shallow merge the new values (hence "Level2")
        newState[key] = { ...newState[key], ...inboundState[key] };
        return;
      }
      // otherwise hard set
      newState[key] = inboundState[key];
    });
  }

  if (!isProdEnv() && inboundState && typeof inboundState === 'object') {
    debug(
      `redux-persist/stateReconciler: rehydrated keys '${Object.keys(inboundState).join(
        ', ',
      )}'`,
    );
  }

  return newState;
};

const isPlainEnoughObject = (o: any) => {
  return o !== null && !Array.isArray(o) && typeof o === 'object';
};
