import React from 'react';

const Facebook: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <path d="M11.857 16v-5.343h1.724l.415-2.227h-2.12V6.797c0-.227.115-.528.469-.528h1.226V4h-2.29c-.79 0-1.957 1.007-1.957 2.078v2.289H8v2.255l1.333.01V16H6a4 4 0 0 1-4-4V5a4 4 0 0 1 4-4h7a4 4 0 0 1 4 4v7a4 4 0 0 1-4 4h-1.143z" />
  </svg>
);

export { Facebook };
