import { Device, toDevice } from '@peloton/browser';
import type { CopyIDs } from '@members/copy';
import { AppDownload, Links, useAppDownload } from '@members/copy';

const map = {
  [Device.IOS]: AppDownload.IOS,
  [Device.Android]: AppDownload.Android,
};

export const toDownloadAppsProps = (device?: string) => ({
  id: device ? Links.GetTheApp : Links.GetApps,
  ...(device ? { values: { device } } : {}),
});

export const toDeviceNameId = (): CopyIDs<typeof AppDownload> => {
  const deviceType = toDevice();
  return map[deviceType];
};

export const useMaybeAppDownload = (id?: CopyIDs<typeof AppDownload>) => {
  const idOrDefault = id ?? AppDownload.Android;
  const device = useAppDownload(idOrDefault);
  return id ? device : '';
};
