import type { ApolloLink } from '@apollo/client';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import 'isomorphic-fetch';
import { toEncodedClientDetails } from '@peloton/analytics';
import { toError, isHttpError, getApiErrorType, ApiErrors } from '@peloton/api';
import { ApiEnvs } from '@peloton/external-links';
import { toMatchingTld } from '@peloton/internationalize';
import { toUtcTimeFromDate } from '@peloton/time';
import type { BaseAPI, ChangeRelationshipRequest } from '@engage/api-v2';
import { userChangeRelationship } from '@engage/api-v2';
import type { ApiOverrideGetter } from '@engage/env';
import { toApiEnv, DefaultApiOverrideGetter } from '@engage/env';
import type { Context } from './Context';
import type {
  ModifyStacksViewQuery,
  TransitionScreenQuery,
} from './stacked-classes/queries';
import {
  ModifyStacksViewDocument,
  TransitionScreenDocument,
} from './stacked-classes/queries';
import type { LastTagSearchQuery, SelectedTagNameQuery } from './tags/queries';
import { LastTagSearchDocument, SelectedTagNameDocument } from './tags/queries';

import type {
  Resolvers,
  ResolversTypes,
  ActivityFeedEventPaginationResponse,
} from './types.generated';

const platformHeader = { 'Peloton-Platform': 'web' };

const graphqlTld = toMatchingTld(
  typeof window === 'undefined' ? '' : window.location.hostname,
);

export type Headers = { headers: Record<string, string> };

const toHttpLink = (getApiOverride: ApiOverrideGetter, headers?: Headers) => {
  const graphqlEnv =
    toApiEnv(window.location.hostname, getApiOverride) === ApiEnvs.Prod
      ? 'prod'
      : 'stage';

  return createHttpLink({
    uri: `https://gql-graphql-gateway.${graphqlEnv}.k8s.onepeloton${graphqlTld}/graphql`,
    credentials: 'include',
    headers: {
      ...platformHeader,
      ...{ 'Peloton-Client-Date': toUtcTimeFromDate(new Date()).toJSON() },
      ...(headers?.headers ?? {}),
    },
  });
};
const fragments = require('./fragmentTypes.json');
const typePolicies = {
  Query: {
    fields: {
      userActivityFeed: {
        keyArgs: [],
        merge: (
          existing: ActivityFeedEventPaginationResponse,
          incoming: ActivityFeedEventPaginationResponse,
        ) => {
          if (Boolean(!existing)) {
            return incoming;
          }
          return {
            ...existing,
            edges: [...existing.edges, ...incoming.edges],
            pageInfo: incoming.pageInfo,
          };
        },
      },
    },
  },
};
const localCache = new InMemoryCache({ ...fragments, typePolicies });

const writeTagName = (cache: Context['cache'], tagName: string) => {
  cache.writeQuery<SelectedTagNameQuery>({
    query: SelectedTagNameDocument,
    data: {
      selectedTagName: tagName,
      __typename: 'Query',
    },
  });
};
const writeLastTagSearch = (cache: Context['cache'], searchTerm: string) => {
  cache.writeQuery<LastTagSearchQuery>({
    query: LastTagSearchDocument,
    data: {
      lastTagSearch: searchTerm,
      __typename: 'Query',
    },
  });
};
const writeModifyStacksView = (cache: Context['cache'], isModify: boolean) => {
  cache.writeQuery<ModifyStacksViewQuery>({
    query: ModifyStacksViewDocument,
    data: {
      modifyStacksView: isModify,
      __typename: 'Query',
    },
  });
};
const writeTransitionScreen = (cache: Context['cache'], isTransition: boolean) => {
  cache.writeQuery<TransitionScreenQuery>({
    query: TransitionScreenDocument,
    data: {
      transitionScreen: isTransition,
      __typename: 'Query',
    },
  });
};

const resolvers = (baseApi: BaseAPI): Resolvers => {
  const source = window.location.pathname.includes('feed')
    ? 'Feed Post Details'
    : 'Tag Details';
  const clientDetailsHeader = toEncodedClientDetails({ Source: source });

  return {
    Mutation: {
      setSelectedTagName: (_, { input }, { cache }) => {
        writeTagName(cache, input.tagName);

        return true;
      },
      setLastTagSearch: (_, { searchTerm }, { cache }) => {
        writeLastTagSearch(cache, searchTerm);

        return true;
      },
      setTransitionScreen: (_, { isTransition }, { cache }) => {
        writeTransitionScreen(cache, isTransition);

        return true;
      },
      setModifyStacksView: (_, { isModify }, { cache }) => {
        writeModifyStacksView(cache, isModify);

        return true;
      },
      updateRelationship: async (
        _,
        { input },
      ): Promise<ResolversTypes['UpdateRelationshipResponse']> => {
        try {
          const { data } = await userChangeRelationship(
            baseApi,
            {
              userId: input.userId,
              action: input.action as ChangeRelationshipRequest['action'],
            },
            'web',
            clientDetailsHeader,
          );

          return {
            __typename: 'UpdateRelationshipSuccess',
            meToUser: data.meToUser as string,
            userToMe: data.userToMe as string,
          };
        } catch (axiosError) {
          const error = toError(axiosError);

          if (isHttpError(error)) {
            const errorType = getApiErrorType(error);
            if (errorType === ApiErrors.BAD_REQUEST) {
              const errorData = error.response.data;
              if (errorData.errorCode === 150) {
                return { __typename: 'CantUnfollow' };
              }
              if (errorData.errorCode === 151) {
                return { __typename: 'AlreadyExists' };
              }
            }
          }

          throw axiosError;
        }
      },
    },
  };
};

const toAuthLink = (getAuthHeader: () => Promise<string>) =>
  (setContext(async (_, { headers }) => {
    const token = await getAuthHeader();

    return {
      headers: {
        ...headers,
        ...(!!token && { authorization: `Bearer ${token}` }),
      },
    };
  }) as any) as ApolloLink; // Resolves type difference between @apollo/client and @apollo/link-context

export const toClient = (
  axiosClient: BaseAPI,
  headers?: Headers,
  getApiOverride: ApiOverrideGetter = DefaultApiOverrideGetter,
  getAuthHeader = () => Promise.resolve(''),
) =>
  new ApolloClient({
    cache: localCache,
    link: toAuthLink(getAuthHeader).concat(toHttpLink(getApiOverride, headers)),
    resolvers: resolvers(axiosClient) as any, // temp fix
  });
