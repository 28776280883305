import type { UserAttributes } from '@optimizely/optimizely-sdk';
import React from 'react';
import { useReduxAction, useReduxState } from '@engage/redux';
import type { PendingResult } from '@engage/result';
import { ResultBranch, isOk, pending, ok, isError } from '@engage/result';
import { useFeatureVariables } from '@members/optimizely';
import type { VariableConfig } from './models';
import { isRemoteTogglesLoaded, getEnabledFeatures } from './redux';
import { mergeVariablesFromOptimizely } from './state/actions';

const { useCallback, useEffect, useState } = React;

type Config = {
  variables: VariableConfig;

  useIsRemoteLoaded?: () => ReturnType<typeof isRemoteTogglesLoaded>;
  useEnabledFeatures?: () => ReturnType<typeof getEnabledFeatures>;
};

export type Props = {
  userId: string;
  attributes?: UserAttributes;
  handleVariableUpdate?: (
    ...params: Parameters<typeof mergeVariablesFromOptimizely>
  ) => unknown;
  renderPending?: () => React.ReactElement<unknown>;
  renderError?: (error: unknown) => React.ReactElement<unknown>;
};

export const provideVariableGate = ({
  variables,
  useIsRemoteLoaded = () => useReduxState(isRemoteTogglesLoaded),
  useEnabledFeatures = () => useReduxState(getEnabledFeatures),
}: // eslint-disable-next-line react/display-name
Config): React.FC<React.PropsWithChildren<Props>> => ({
  children,
  userId,
  attributes,
  handleVariableUpdate = useReduxAction(mergeVariablesFromOptimizely),

  renderPending = () => null,
  renderError = () => <>{children}</>,
}) => {
  const remoteIsLoaded = useIsRemoteLoaded();
  const enabledFeatures = useEnabledFeatures();
  const variableCallback = useFeatureVariables(userId, attributes);
  const [result, setResult] = useState<PendingResult<undefined, unknown>>(pending); // TODO: do we need to provide anything here?
  const renderOk = useCallback(() => <>{children}</>, [children]);

  useEffect(() => {
    if (isOk(variableCallback) && remoteIsLoaded) {
      const results = Object.keys(variables).reduce((obj, feature) => {
        obj[feature] = variables[feature].map(query =>
          variableCallback.value(feature, query),
        );
        return obj;
      }, {});
      handleVariableUpdate(results);
      setResult(ok(undefined));
    } else if (isError(variableCallback)) {
      // TODO: evaluate other error triggers
      setResult(variableCallback);
    }
  }, [
    remoteIsLoaded,
    enabledFeatures,
    variableCallback,
    setResult,
    handleVariableUpdate,
  ]);

  return (
    <ResultBranch
      pendingResult={result}
      renderOk={renderOk}
      renderPending={renderPending}
      renderError={renderError}
    />
  );
};
