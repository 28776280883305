import { map } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { toTimeWith } from '@peloton/time';
import type { Card } from '../models/Card';

const CARD_URL = 'ecomm/card';

const fetchCards = (api: Client) =>
  api.get(CARD_URL).then(pipeData(pipeData(map(toCard)))) as Promise<Card[]>;

const toCard = (apiCard: ApiCard): Card => ({
  name: apiCard.name,
  postalCode: apiCard.addressZip,
  type: apiCard.cardType,
  paymentType: apiCard.paymentType,
  expiresAt: toTimeWith({ month: apiCard.expMonth - 1, year: apiCard.expYear }),
  isDefault: apiCard.isDefault,
  last4: apiCard.last4,
});

type ApiCard = {
  name: string;
  addressZip: string;
  cardType: string;
  expMonth: number;
  expYear: number;
  isDefault: boolean;
  last4: number;
  paymentType: string;
};

export { fetchCards, toCard };
