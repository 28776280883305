import React, { useEffect, useState } from 'react';
import type { VariableSizeList } from 'react-window';
import useSWR, { mutate } from 'swr';
import type { Location } from '@peloton/redux';
import { getLocation } from '@peloton/redux';
import { isDefined } from '@peloton/types';
import { track } from '@engage/analytics';
import { useReduxAction, useReduxState } from '@engage/redux';
import { TagsFetchersGQL } from '@members/data-fetch/fetchers.production';
import type {
  TagBrowseCategoryQuery,
  TagBrowseCategoryQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/TagBrowseCategory.generated';
import type { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import { trackViewedBrowseTags, analyticsCategoryMap } from '../analytics';
import type { ClientTag } from '../models';
import { getTagViewFromLocation } from '../selectors';
import { TagModalView } from '../shared';

export const useTrackViewedBrowseTags = (
  isActive: boolean,
  loading: boolean,
  category: TagBrowseCategorySlug,
  alreadyCalled: boolean,
  data?: TagBrowseCategoryQuery,
) => {
  const trackAnalytics = useReduxAction(track);
  const location: Location = useReduxState(getLocation);
  const source = location?.state?.source ?? 'Direct Link';
  const view = useReduxState(getTagViewFromLocation);

  useEffect(() => {
    if (!loading && isActive && view === TagModalView.BrowseTags && !alreadyCalled) {
      trackAnalytics(
        trackViewedBrowseTags(
          source,
          analyticsCategoryMap[category as string],
          data?.tagBrowseCategory?.tags?.edges.length ?? 0,
        ),
      );
    }
  }, [isActive, loading]);
};

export const useLastLoadingIndex = (
  listRef: React.RefObject<VariableSizeList>,
  tagList: ClientTag[],
) => {
  // Since page size may change, we should keep track where the last Loading card was.
  const [lastLoadingIndex, setLastLoadingIndex] = useState<number | undefined>();

  // After loading more, reset sizes starting from the Loading card.
  // Otherwise any card taking its place will have `LOADING_HEIGHT` height.
  useEffect(() => {
    if (isDefined(listRef.current) && isDefined(lastLoadingIndex)) {
      listRef.current.resetAfterIndex(lastLoadingIndex, false);
      setLastLoadingIndex(undefined);
    }
  }, [tagList.length]);

  return { setLastLoadingIndex };
};

export const useTagBrowseCategoryPaginatedQuery = (
  variables: TagBrowseCategoryQueryVariables,
  onSuccessHandler?: () => void,
) => {
  const [allData, setAllData] = React.useState<TagBrowseCategoryQuery>(
    {} as TagBrowseCategoryQuery,
  );
  const [isSyntheticLoading, setIsSyntheticLoading] = React.useState(true);
  const { error, mutate: refetch } = useSWR(
    ...TagsFetchersGQL.TagBrowseCategoryQuery(variables, undefined, {
      fallbackData: {} as TagBrowseCategoryQuery,
      onSuccess: succData => {
        if (typeof onSuccessHandler === 'function') {
          onSuccessHandler();
        }
        setAllData(succData);
        setIsSyntheticLoading(false);
      },
    }),
  );

  const loadMore = () => {
    if (allData?.tagBrowseCategory?.tags?.pageInfo?.hasNextPage && !isSyntheticLoading) {
      const cursor = allData?.tagBrowseCategory?.tags?.pageInfo?.endCursor;
      setIsSyntheticLoading(true);
      mutate(
        ...TagsFetchersGQL.TagBrowseCategoryPagination({
          after: cursor,
          ...variables,
        }),
      ).then(moreData => {
        setAllData({
          ...allData,
          tagBrowseCategory: {
            tags: {
              edges: [
                ...allData.tagBrowseCategory.tags.edges,
                ...moreData.tagBrowseCategory.tags.edges,
              ],
              pageInfo: moreData.tagBrowseCategory.tags.pageInfo,
              ...moreData.tagBrowseCategory.tags.__typename,
            },
            ...moreData.tagBrowseCategory.__typename,
          },
        });
        setIsSyntheticLoading(false);
      });
    }
  };

  return {
    data: allData,
    mutate: refetch,
    isLoading: isSyntheticLoading,
    error,
    loadMore,
  };
};
