import type { match } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import type { BrowseCategorySlug } from '@engage/browse-categories';

export const LIBRARY_CATEGORY_SELECTION_ROUTE = '/classes';
export const LIBRARY_CATEGORY_ROUTE = '/classes/:categorySlug';

export const getLibraryPageCategory = (path: string): undefined | BrowseCategorySlug => {
  const matched = <MatchPath>matchPath(path, { path: LIBRARY_CATEGORY_ROUTE });
  return matched?.params?.categorySlug;
};

export const toLibraryCategoryRoute = (categorySlug: BrowseCategorySlug) =>
  `/classes/${categorySlug}`;

type MatchPath = match<{ categorySlug: BrowseCategorySlug }>;
