import React from 'react';

const Instagram: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <path d="M12.229 2.49c.78.034 1.203.164 1.485.275.375.146.642.318.92.597.28.28.455.547.598.921.108.283.241.705.276 1.486.038.845.048 1.096.048 3.233s-.01 2.387-.048 3.232c-.035.781-.165 1.203-.276 1.486a2.483 2.483 0 0 1-.597.92c-.28.28-.546.455-.92.598-.283.108-.705.241-1.486.276-.845.038-1.096.048-3.232.048-2.137 0-2.387-.01-3.232-.048-.78-.035-1.203-.165-1.486-.276a2.482 2.482 0 0 1-.92-.597 2.444 2.444 0 0 1-.597-.921c-.108-.283-.241-.705-.276-1.486-.038-.845-.048-1.095-.048-3.232 0-2.137.01-2.388.048-3.233.035-.78.165-1.203.276-1.486.146-.374.317-.641.597-.92.28-.28.546-.455.92-.598.283-.108.705-.24 1.486-.276.845-.041 1.095-.047 3.232-.047 2.136 0 2.387.01 3.232.047zm-6.53-1.442c-.851.038-1.432.174-1.94.371-.527.203-.972.48-1.416.924a3.968 3.968 0 0 0-.924 1.416c-.197.508-.333 1.09-.371 1.944C1.01 6.553 1 6.827 1 8.998c0 2.172.01 2.445.048 3.3.038.85.174 1.432.371 1.943.203.527.48.971.924 1.416.444.444.892.717 1.416.924.508.197 1.089.333 1.943.371C6.556 16.99 6.825 17 9 17s2.444-.01 3.298-.048c.851-.038 1.432-.174 1.943-.371.527-.203.972-.48 1.416-.924.445-.445.718-.892.924-1.416.197-.508.333-1.09.371-1.944.038-.854.048-1.124.048-3.299s-.01-2.445-.048-3.299c-.038-.85-.174-1.432-.371-1.943a3.899 3.899 0 0 0-.924-1.416 3.967 3.967 0 0 0-1.416-.924c-.508-.197-1.089-.333-1.943-.372C11.441 1.01 11.168 1 8.997 1c-2.172 0-2.445.01-3.299.048zM8.996 4.89a4.109 4.109 0 0 0 0 8.217 4.111 4.111 0 0 0 4.108-4.109A4.111 4.111 0 0 0 8.997 4.89zm0 6.776a2.667 2.667 0 1 1 0-5.334 2.667 2.667 0 0 1 0 5.334zm3.872-5.02a.959.959 0 1 1 0-1.918.959.959 0 0 1 0 1.917z" />
  </svg>
);

export { Instagram };
