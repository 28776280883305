import type { AxiosInstance } from 'axios';
import { getLoggedInUserAllSubscriptionsByTypeV2 } from '@engage/api-v2';
import { getPrimarySubscription } from '@engage/api-v2/primary-subscription';
import { engageApi } from '../lib/singletons';
import { unwrap } from '../lib/unwrap';
import { toFetcher } from './toFetcher';

export const toSubscriptionsFetchers = (client: AxiosInstance) => ({
  GetPrimarySubscription: toFetcher(
    () => `GetPrimarySubscription`,
    () => unwrap(() => getPrimarySubscription(client)),
  ),
});
export const toSubscriptionV2Fetchers = (api: typeof engageApi = engageApi) => ({
  GetLoggedInUserAllSubscriptionsByType: toFetcher(
    () => 'GetLoggedInUserAllSubscriptionsByTypeV2',
    () => unwrap(() => getLoggedInUserAllSubscriptionsByTypeV2(api)),
  ),
});
