import { css } from 'styled-components';

export const labelLineHeightEm = 1.4; // extracted for use in determining container heights

const labelStyles = css`
  line-height: ${`${labelLineHeightEm}em`};
  letter-spacing: 0.02em;
`;

const capsLabelStyles = css`
  font-weight: 500;
  line-height: 1.35em;
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

/**
 * @deprecated prefer l1 in @engage/typography/font-styles
 *
 */
export const label0Large = css`
  ${labelStyles}
  font-size: 20px;
  font-weight: 400;
`;

/**
 * @deprecated prefer l1 in @engage/typography/font-styles
 *
 */
export const label1Large = css`
  ${labelStyles}
  font-size: 18px;
  font-weight: 400;
`;

/**
 * @deprecated prefer l1Bold in @engage/typography/font-styles
 *
 */
export const label1Small = css`
  ${labelStyles}
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
`;

/**
 * @deprecated prefer l1c in @engage/typography/font-styles
 *
 */
export const capsLabel1Large = css`
  ${capsLabelStyles}
  font-size: 17px;
`;

/**
 * @deprecated prefer l1CapBold in @engage/typography/font-styles
 *
 */
export const capsLabel1Small = css`
  ${capsLabelStyles}
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
`;

/**
 * @deprecated prefer l1Bold in @engage/typography/font-styles
 *
 */
export const label2Large = css`
  ${labelStyles}
  font-size: 16px;
  font-weight: 500;
`;

/**
 * @deprecated prefer l1c in @engage/typography/font-styles
 *
 */
export const capsLabel2Large = css`
  ${capsLabelStyles}
  font-size: 15px;
`;

/**
 * @deprecated prefer l2CapBold in @engage/typography/font-styles
 *
 */
export const capsLabel2Small = css`
  ${capsLabelStyles}
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.25px;
`;

/**
 * @deprecated prefer l1Bold in @engage/typography/font-styles
 *
 */
export const label3Large = css`
  ${labelStyles}
  font-size: 14px;
  font-weight: 500;
`;

/**
 * @deprecated prefer l3 in @engage/typography/font-styles
 *
 */
export const label3Small = css`
  ${labelStyles}
  font-size: 10px;
  letter-spacing: 0.2px;
`;

/**
 * @deprecated prefer l1c in @engage/typography/font-styles
 *
 */
export const capsLabel3Large = css`
  ${capsLabelStyles}
  font-size: 13px;
`;

/**
 * @deprecated prefer l3CapBold in @engage/typography/font-styles
 *
 */
export const capsLabel3Small = css`
  ${capsLabelStyles}
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
`;

/**
 * @deprecated prefer l1Bold in @engage/typography/font-styles
 *
 */
export const label4Large = css`
  ${labelStyles}
  font-size: 13px;
  font-weight: 500;
`;

/**
 * @deprecated prefer l1c in @engage/typography/font-styles
 *
 */
export const capsLabel4Large = css`
  ${capsLabelStyles}
  font-size: 12px;
`;

/**
 * @deprecated prefer l1Bold in @engage/typography/font-styles
 *
 */
export const label5Large = css`
  ${labelStyles}
  font-size: 12px;
  font-weight: 500;
`;

/**
 * @deprecated prefer l2c in @engage/typography/font-styles
 *
 */
export const capsLabel5Large = css`
  ${capsLabelStyles}
  font-size: 11px;
`;

/**
 * @deprecated prefer l2Bold in @engage/typography/font-styles
 *
 */
export const label6Large = css`
  ${labelStyles}
  font-size: 11px;
  font-weight: 500;
`;

/**
 * @deprecated prefer l3c in @engage/typography/font-styles
 *
 */
export const capsLabel6Large = css`
  ${capsLabelStyles}
  font-size: 10px;
`;

/**
 * @deprecated prefer l3 in @engage/typography/font-styles
 *
 */
export const label7Large = css`
  ${labelStyles}
  font-size: 10px;
  font-weight: 500;
`;

export const AlternateLabelNames = {
  'L1-L': label1Large,
  'L1-S': label1Small,
  'L1C-L': capsLabel1Large,
  'L1C-S': capsLabel1Small,
  'L2C-L': capsLabel2Large,
  'L2C-S': capsLabel2Small,
  'L3-L': label3Large,
  'L3-S': label3Small,
  'L3C-L': capsLabel3Large,
  'L3C-S': capsLabel3Small,
  'L4-L': label4Large,
  'L4C-L': capsLabel4Large,
  'L5-L': label5Large,
  'L5C-L': capsLabel5Large,
  'L6-L': label6Large,
  'L6C-L': capsLabel6Large,
  'L7-L': label7Large,
};
