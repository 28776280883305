import { map } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { toNowTimezoneString, toTime } from '@peloton/time';
import type {
  Challenge,
  ChallengeDetail,
  ChallengeSummary,
  ChallengeProgress,
  ChallengeParticipants,
  ChallengeFriend,
} from './models';
import { ChallengeStatus } from './models';

const toChallengesUrl = (userId: string, status: ChallengeStatus, hasJoined: boolean) =>
  `api/user/${userId}/challenges/${toAPIChallengeStatus(status)}?has_joined=${hasJoined}`;

const toChallengeDetailsUrl = (userId: string, challengeId: string) =>
  `api/user/${userId}/challenge/${challengeId}`;

const toChallengeFriendsUrl = (userId: string, challengeId: string) =>
  `api/user/${userId}/challenge/${challengeId}/friends`;

const toJoinChallengeUrl = (userId: string, challengeId: string) =>
  `api/user/${userId}/challenge/${challengeId}/join`;

const toLeaveChallengeUrl = (userId: string, challengeId: string) =>
  `api/user/${userId}/challenge/${challengeId}/leave`;

export const fetchChallenges = (
  api: Client,
  userId: string,
  status: ChallengeStatus,
  hasJoined: boolean,
) =>
  api
    .get<ApiChallengeResponse>(toChallengesUrl(userId, status, hasJoined), {
      headers: { 'Peloton-Client-Date': toNowTimezoneString() },
    })
    .then(pipeData(toChallenges));

export const fetchChallengeDetail = (api: Client, userId: string, challengeId: string) =>
  api
    .get<ApiChallengeDetailResponse>(toChallengeDetailsUrl(userId, challengeId), {
      headers: { 'Peloton-Client-Date': toNowTimezoneString() },
    })
    .then(pipeData(toChallengeDetail));

export const fetchChallengeFriends = (api: Client, userId: string, challengeId: string) =>
  api
    .get<ApiChallengeFriendsResponse>(toChallengeFriendsUrl(userId, challengeId))
    .then(pipeData(toChallengeFriends));

export const joinChallenge = (api: Client, userId: string, challengeId: string) =>
  api
    .post(toJoinChallengeUrl(userId, challengeId))
    .then(pipeData(toToggleJoinedChallengeResponse));

export const leaveChallenge = (api: Client, userId: string, challengeId: string) =>
  api
    .post(toLeaveChallengeUrl(userId, challengeId))
    .then(pipeData(toToggleJoinedChallengeResponse));

const toChallenges = ({ challenges }: ApiChallengeResponse) =>
  map(
    challenge => ({
      ...challenge,
      challengeSummary: {
        ...challenge.challengeSummary,
        status: toChallengeStatus(challenge.challengeSummary.status),
        startTime: toTime(challenge.challengeSummary.startTime),
        endTime: toTime(challenge.challengeSummary.endTime),
      },
    }),
    challenges,
  );

const toChallengeStatus = (apiStatus: APIChallengeStatus) => {
  switch (apiStatus) {
    case APIChallengeStatus.Current:
      return ChallengeStatus.Active;
    case APIChallengeStatus.Past:
      return ChallengeStatus.Completed;
    case APIChallengeStatus.Upcoming:
      return ChallengeStatus.Upcoming;
    default:
      throw new Error(`Unrecognized challenge status: ${apiStatus}`);
  }
};

const toAPIChallengeStatus = (status: ChallengeStatus) => {
  switch (status) {
    case ChallengeStatus.Active:
      return APIChallengeStatus.Current;
    case ChallengeStatus.Completed:
      return APIChallengeStatus.Past;
    case ChallengeStatus.Upcoming:
      return APIChallengeStatus.Upcoming;
    default:
      throw new Error(`Unrecognized challenge status: ${status}`);
  }
};

const toChallengeDetail = (challengeDetail: ApiChallengeWithDetail) => ({
  ...challengeDetail,
  challengeSummary: {
    ...challengeDetail.challengeSummary,
    status: toChallengeStatus(challengeDetail.challengeSummary.status),
    startTime: toTime(challengeDetail.challengeSummary.startTime),
    endTime: toTime(challengeDetail.challengeSummary.endTime),
  },
});

const toChallengeFriends = ({ challengeFriends }: ApiChallengeFriendsResponse) =>
  challengeFriends;

const toToggleJoinedChallengeResponse = ({
  progress,
  participants,
}: ApiToggleJoinedChallengeResponse) => ({ progress, participants });

enum APIChallengeStatus {
  Upcoming = 'upcoming',
  Current = 'current',
  Past = 'past',
}

type APIChallengeSummary = Omit<ChallengeSummary, 'status'> & {
  status: APIChallengeStatus;
};

type ApiChallengeResponse = {
  challenges: Challenge & { challengeSummary: APIChallengeSummary }[];
};

type ApiChallengeDetailResponse = Omit<Challenge, 'challengeDetail'> & {
  challengeDetail: ChallengeDetail;
  challengeSummary: APIChallengeSummary;
};

type ApiChallengeFriendsResponse = {
  challengeFriends: ChallengeFriend[];
};

type ApiToggleJoinedChallengeResponse = {
  progress: ChallengeProgress;
  participants: ChallengeParticipants;
};

type ApiChallengeWithDetail = Omit<Challenge, 'challengeSummary' | 'challengeDetail'> & {
  challengeSummary: APIChallengeSummary;
  challengeDetail: ChallengeDetail;
};
