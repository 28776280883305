import React from 'react';
import { mutate as swrMutate } from 'swr';
import { TagsFetchersGQL } from '@members/data-fetch/fetchers.production';
import type { Headers } from '@members/data-fetch/graphql-fetchers/utils';
import type {
  ReplaceTagMutation,
  ReplaceTagMutationVariables,
} from '@members/graphql-swr/schemas/tags/mutations/ReplaceTag.generated';

export const useReplaceTagMutationSWR = (
  onComplete: (data: ReplaceTagMutation) => void,
  refetchQueries: () => void,
  headers?: Headers,
) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const wrappedMutate = (input: ReplaceTagMutationVariables) => {
    setIsLoading(true);
    return swrMutate(...TagsFetchersGQL.ReplaceTagMutation(input, headers))
      .then(data => {
        if (data) {
          onComplete(data);
        }
      })
      .finally(() => {
        refetchQueries();
        setIsLoading(false);
      });
  };

  return { isLoading, mutate: wrappedMutate };
};
