import type { SagaIterator } from 'redux-saga';
import { call, delay, put, fork, take, select, cancel } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import {
  fetchScheduledClass,
  loadScheduledClassSuccess,
} from '@engage/scheduled-classes';
import { getInProgressWorkout } from '../selectors';

type PollScheduledClassParams = {
  client: Client;
  pollingDelayInMs: number;
  scheduledClassId: string;
};

const scheduledClassPollSaga = function* (
  params: PollScheduledClassParams,
): SagaIterator {
  while (true) {
    try {
      const scheduledClass = yield call(
        fetchScheduledClass,
        params.client,
        params.scheduledClassId,
      );
      yield put(loadScheduledClassSuccess(scheduledClass));
      if (scheduledClass.startTime) {
        yield cancel();
      } else {
        yield delay(params.pollingDelayInMs);
      }
    } catch (error) {
      // Swallow
    }
  }
};

export const pollScheduledClassByIdSagaManager = function* (
  cancelActionType: string,
  params: PollScheduledClassParams,
): SagaIterator {
  const pollTask = yield fork(scheduledClassPollSaga, params);
  yield take(cancelActionType);
  yield cancel(pollTask);
};

export const pollScheduledClassSagaManager = function* (
  client: Client,
  pollingDelay: number,
  cancelActionType: string,
) {
  const workout: ReturnType<typeof getInProgressWorkout> = yield select(
    getInProgressWorkout,
  );
  if (!workout) {
    return;
  }
  yield call(pollScheduledClassByIdSagaManager, cancelActionType, {
    client,
    pollingDelayInMs: pollingDelay,
    scheduledClassId: workout.pelotonId,
  });
};
