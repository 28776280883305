import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { slate1, slate2, slate3, divider1 } from '@engage/colors';
import type { ContractAgreement } from '@engage/members';
import { spaces } from '@engage/styles';
import { link1, h8, body16, body14, label14 } from '@engage/typography';
import { Button1Small } from '@members/buttons';
import { AcceptTermsCopy, AcceptTerms as AT } from '@members/copy';
import type { Contract } from './TermButton';
import { TermButton } from './TermButton';
import { useContracts } from './useContracts';

type Props = {
  contractAgreements: ContractAgreement[];
  privacyLink: string;
  onAcknowledge: () => void;
};

export const UnacknowledgedContent: React.FC<React.PropsWithChildren<Props>> = ({
  contractAgreements,
  privacyLink,
  onAcknowledge,
}) => {
  const contractsRef = useRef<Contract[]>([]);
  const toTermButton: React.FC<React.PropsWithChildren<Contract>> = contract => (
    <TermButton key={contract.id} {...contract} handleClick={() => null} />
  );

  const contracts = useContracts(contractAgreements);

  useEffect(() => {
    if (contracts.length > 0 && contractsRef.current.length === 0) {
      contractsRef.current = contracts;
    }
  }, [contracts]);

  // make a copy of the contracts because the "acknowledge" contracts get marked as acknowledged without intervention
  // which would otherwise cause this component to re-render and we lose the term buttons
  const TermButtons = contractsRef.current.map(toTermButton);

  return (
    <Container>
      <HeaderContainer data-test-id="updatedPoliciesHeader">
        <AcceptTermsCopy id={AT.UpdatedPolicies} />
      </HeaderContainer>
      <Divider />
      <AcknowledgePolciesText data-test-id="updatedPoliciesText">
        <AcceptTermsCopy
          id={AT.AcknowledgeUpdatedPolicies}
          values={{
            link: <Link href={privacyLink}>{privacyLink}</Link>,
          }}
        />
      </AcknowledgePolciesText>
      <LinkContainer>{TermButtons}</LinkContainer>
      <Spacer />
      <AcknowledgePoliciesButton
        data-test-id="acknowledgePoliciesButton"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          onAcknowledge();
        }}
      >
        <ButtonText>
          <AcceptTermsCopy id={AT.Acknowledge} />
        </ButtonText>
      </AcknowledgePoliciesButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderContainer = styled.span`
  ${h8}
  color: ${slate3};
  text-align: center;
  margin-bottom: 14px;
`;

const Divider = styled.div`
  background-color: ${divider1};
  height: 1px;
  width: 100vw;
  margin-bottom: ${spaces.xLarge}px;
`;

const AcknowledgePolciesText = styled.span`
  ${body14};
  color: ${slate2};
  text-align: center;
  margin-bottom: ${spaces.xLarge}px;
  ${media.tablet`
    ${body16}
  `}
`;

const LinkContainer = styled.div`
  width: 100%;
  min-height: 100%;
`;

const Spacer = styled.div`
  height: 156px;
`;

const StyledLink = styled.a`
  font-size: 13px;
  ${link1}
  color: ${slate1};
`;

const LinkWrapper = styled.div`
  display: inline-block;
  text-decoration: underline;
  &:not(:last-child) {
    margin-right: ${spaces.large}px;
  }
`;

type LinkProps = {
  href: string;
  handleClick?(): void;
};

const Link: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <LinkWrapper>
    <StyledLink
      href={props.href}
      target="_blank"
      rel="noopener"
      onClick={props.handleClick}
    >
      {props.children}
    </StyledLink>
  </LinkWrapper>
);

const buttonStyles = css`
  height: 32px;
  width: 110px;
  ${media.tablet`
    width: 120px;
  `}
`;

const AcknowledgePoliciesButton = styled(Button1Small)`
  ${buttonStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 180px;
  height: 40px;
  ${media.tablet`
    width: 260px;
    height: 50px;
  `}
`;

const ButtonText = styled.span`
  ${label14};
  padding-bottom: ${spaces.tiny}px;
`;
