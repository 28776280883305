import { find, contains, keys, values, any, forEach, reduce } from 'ramda';
import { ApiEnvs } from '@peloton/external-links';
import { isDefined } from '@peloton/types';
import { fromQueryParam } from './queryParamEnv';

const MEMBERS_ENV_KEY = 'members/env';

export enum StorageKeys {
  Api = 'api',
  OptimizelyEnv = 'optimizelyEnv',
  FeatureToggles = 'featureToggles',
  FeatureVariables = 'featureVariables',
  FeatureExperiments = 'featureExperiments',
}

enum Environment {
  QA = 'QA',
  Production = 'Production',
}

export const getFullStorageKey = (storageKey: StorageKeys) =>
  `${MEMBERS_ENV_KEY}/${storageKey}`;

// If `upstreamValue` is truthy, store that value under `storageKey` and return it.
// Otherwise, return what is currently stored under `storageKey`.
export const syncPipe = (
  storageKey: StorageKeys,
  upstreamValue: string | null,
): string | null => {
  if (typeof window === 'undefined') return null;

  const fullStorageKey = getFullStorageKey(storageKey);
  if (Boolean(upstreamValue)) {
    window.localStorage.setItem(fullStorageKey, String(upstreamValue));
    return upstreamValue;
  } else {
    return window.localStorage.getItem(fullStorageKey);
  }
};

// Stores values under the same localStorage namespace as a key: value pair.
export const upsert = (storageKey: StorageKeys, key: string, value: any) => {
  if (typeof window === 'undefined') return;

  const fullStorageKey = getFullStorageKey(storageKey);
  const itemString = window.localStorage.getItem(fullStorageKey);
  if (itemString) {
    const item = JSON.parse(itemString);
    item[key] = value;
    window.localStorage.setItem(fullStorageKey, JSON.stringify(item));
  } else {
    window.localStorage.setItem(fullStorageKey, JSON.stringify({ [key]: value }));
  }
};

export const remove = (storageKey: StorageKeys) => {
  if (typeof window === 'undefined') return;
  window.localStorage.removeItem(getFullStorageKey(storageKey));
};

export const syncWithQueryParam = (storageKey: StorageKeys): string | null =>
  syncPipe(storageKey, fromQueryParam(storageKey));

export type Shortcuts = Record<string, string[]>;

export const syncedQueryParamToShortcutKey = (
  queryParamKey: StorageKeys,
  shortcuts: Shortcuts,
) => {
  const shortcut = syncWithQueryParam(queryParamKey);

  return (
    find(
      shortcutKey => contains(shortcut?.toLowerCase(), shortcuts[shortcutKey]),
      keys(shortcuts),
    ) ?? shortcut
  );
};

export const anyOverrides = () =>
  any(
    key => Boolean(window.localStorage.getItem(getFullStorageKey(key))),
    values(StorageKeys),
  );

export const clearAll = () => {
  forEach(
    key => window.localStorage.removeItem(getFullStorageKey(key)),
    values(StorageKeys),
  );
  window.location.reload();
};

export const getEnv = () => {
  const defaultEnv = {
    [StorageKeys.Api]: ApiEnvs.Prod,
    [StorageKeys.OptimizelyEnv]: Environment.Production,
  };

  const env = reduce(
    (acc, key) => {
      const value = window.localStorage.getItem(getFullStorageKey(key as StorageKeys));
      if (isDefined(value)) {
        acc[key] = value;
      }
      return acc;
    },
    defaultEnv,
    Object.keys(defaultEnv),
  );

  const featureTogglesString = window.localStorage.getItem(
    getFullStorageKey(StorageKeys.FeatureToggles),
  );
  if (isDefined(featureTogglesString)) {
    const featureToggles = JSON.parse(featureTogglesString);
    forEach(key => (env[key] = featureToggles[key]), keys(featureToggles));
  }

  return env;
};
