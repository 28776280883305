import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isDefined } from '@peloton/types';

enum TransitionType {
  Push,
  Pop,
}

enum StepType {
  UpdateRenderables,
  UpdateIndex,
}
type RenderFunctionsArray = ((isEntered: boolean) => ReactNode)[];

const updateRenderables = (
  renderables: RenderFunctionsArray,
  immediate: boolean = true,
) => ({ type: StepType.UpdateRenderables, payload: { renderables, immediate } } as const);
const updateIndex = (activeIndex: number) =>
  ({ type: StepType.UpdateIndex, payload: { activeIndex } } as const);

type Step = ReturnType<typeof updateRenderables | typeof updateIndex>;

const useComponentsWithTransition = (
  components: RenderFunctionsArray,
  animationDuration: number,
) => {
  const [renderables, setRenderables] = useState(components);
  const [activeIndex, setActiveIndex] = useState(0);
  const [firstRun, setFirstRun] = useState(true);
  const popTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [steps, setSteps] = useState<Step[]>([]);

  const pushSteps = useCallback(
    (...nextSteps: Step[]) => setSteps(s => s.concat(nextSteps)),
    [],
  );

  const transition = useCallback(
    (type: TransitionType) => {
      switch (type) {
        case TransitionType.Push:
          pushSteps(updateRenderables(components), updateIndex(components.length - 1));
          break;
        case TransitionType.Pop:
          pushSteps(
            updateIndex(components.length - 1),
            updateRenderables(components, false),
          );
          break;
      }
    },
    [components],
  );

  useEffect(() => {
    // signal first pass has completed
    setFirstRun(false);

    // clear lingering timeout on unmount
    return () => clearTimeout(popTimeout.current!);
  }, []);

  // Add steps to queue
  useEffect(() => {
    if (
      // trigger initial push on first pass
      firstRun ||
      // push if new elements are detected
      components.length > renderables.length
    ) {
      transition(TransitionType.Push);
    } else if (components.length < renderables.length) {
      transition(TransitionType.Pop);
    } else {
      // Keep renderables internal props in sync with components
      setRenderables(components);
    }
  }, [components, renderables]);

  // Process step queue
  useEffect(() => {
    const step = steps[0];
    if (isDefined(step)) {
      switch (step.type) {
        case StepType.UpdateRenderables:
          if (step.payload.immediate) {
            setRenderables(step.payload.renderables);
          } else {
            popTimeout.current = setTimeout(() => {
              setRenderables(step.payload.renderables);
            }, animationDuration);
          }
          break;
        case StepType.UpdateIndex:
          setActiveIndex(step.payload.activeIndex);
          break;
      }
      setSteps(steps.slice(1));
    }
  }, [steps]);

  return [renderables, activeIndex] as const;
};

export default useComponentsWithTransition;
