import type { LocationState } from 'history';
import {
  keys,
  intersection,
  contains,
  isEmpty,
  symmetricDifferenceWith,
  eqBy,
} from 'ramda';
import { getQueryParams } from '@peloton/redux/selectors';
import type {
  AvailableState,
  AppliedFilter,
  FilterName,
  SortValue,
} from '@engage/filters';
import {
  getAllAvailableFilters,
  getAvalilableSorts,
  DEFAULT_SORT_STATE,
  filtersReducer,
} from '@engage/filters';

export default filtersReducer;

const safelyParseQueryParam = (queryParams: Record<string, string>, name: string) => {
  try {
    return JSON.parse(queryParams[name]);
  } catch (e) {
    return [];
  }
};

// Selectors
export const getCurrentFiltersFromSearch = (state: SelectorState): AppliedFilter[] => {
  const queryParams = getQueryParams(state);
  const proxyQueryParams = supportIsFreemiumParam(queryParams);
  const availableFilterNames = getAllAvailableFilters(state).map(n => n.name);
  const filterNamesInSearch = intersection(keys(proxyQueryParams), availableFilterNames);

  return filterNamesInSearch.reduce((acc, filterName) => {
    const parsedValue = safelyParseQueryParam(proxyQueryParams, filterName);
    // Prevent malformed URL query params from crashing the UI
    if (Array.isArray(parsedValue) && parsedValue.length > 0) {
      acc.push({
        name: filterName as FilterName,
        value: parsedValue,
      });
    }
    return acc;
  }, [] as AppliedFilter[]);
};

// maps is_freemium query param to app-free to support both deeplinks
const supportIsFreemiumParam = (queryParams: Record<string, string>) => {
  const queryParam = {};
  for (const key in queryParams) {
    if (key === 'is_freemium') {
      if (queryParams[key] === 'true') {
        queryParam['app-free'] = '["true"]';
      } else {
        queryParam['app-free'] = queryParams[key];
      }
    } else {
      queryParam[key] = queryParams[key];
    }
  }

  return queryParam;
};

export const getCurrentSortFromSearch = (state: SelectorState): SortValue => {
  const { sort, desc } = getQueryParams(state);
  const availableSorts = getAvalilableSorts(state);
  const availableSortValues = availableSorts.map(n => n.value.sort);

  if (!contains(sort, availableSortValues)) {
    return DEFAULT_SORT_STATE;
  }

  return { sort, desc: toBoolValue(desc) };
};

const toBoolValue = (bool: string) => bool.toLowerCase() === 'true';

type SelectorState = LocationState & {
  filters: {
    available: AvailableState;
  };
};

const filterToString = (filter: AppliedFilter) => `${filter.name}${filter.value}`;
const eqByString = eqBy(filterToString);

export const filtersEqual = (a: AppliedFilter[], b: AppliedFilter[]) => {
  const diff = symmetricDifferenceWith(eqByString, a, b);
  return isEmpty(diff);
};
