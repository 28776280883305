import React from 'react';
import styled from 'styled-components';
import { rawStyles } from '@peloton/forms/styleInput';
import { Chevron } from '@members/icons';
import { selectStyles, selectContainerStyles, selectArrowStyles } from './styles';

export const Container = styled.div`
  ${selectContainerStyles}
`;

const DropDownArrow = styled.div`
  ${selectArrowStyles}
`;

export const Arrow: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  className,
}) => {
  return (
    <DropDownArrow className={className}>
      <Chevron width="12px" height="7px" />
    </DropDownArrow>
  );
};

const StyledContainer: React.FC<
  React.PropsWithChildren<{
    children: JSX.Element;
    className?: string;
    customSelectStyles?: Partial<typeof selectStyles>;
  }>
> = ({ children, className, customSelectStyles }) => {
  const inputComponent = (props: any) => React.cloneElement(children, props);

  return (
    <Container className={className}>
      <StyledInput
        as={inputComponent}
        styles={{ ...selectStyles, ...customSelectStyles }}
      />
      <Arrow />
    </Container>
  );
};

const StyledInput = styled.input<{ styles: typeof selectStyles }>`
  ${({ styles }) => rawStyles(styles)}
`;

export default StyledContainer;
