/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { BreakpointEither } from '@peloton/responsive';
import { media } from '@peloton/styles';
import { slate2 } from '@engage/colors';
import type { TopNavUserInfo } from '@engage/members';
import { spaces } from '@engage/styles';
import { Site, useSite } from '@members/copy';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import { HamburgerMenu, CloseIconNew as CloseIcon } from '@members/icons';
import { hoverLinkStyles, focusLinkStyles } from '@members/layout/sharedLinkStyles';

export const miniNavLinkId = 'moreNavLink';

const MiniNavLink = React.forwardRef<HTMLButtonElement, Props>(
  ({ user, onClick, isMenuOpen }, ref) => {
    const navUpdatesEnabled = useFeatureToggle(Feature.Navupdates);
    const mobileBreakpoint = navUpdatesEnabled ? 'desktopXLarge' : 'desktopLarge';
    return (
      <Container
        id={miniNavLinkId}
        data-test-id={miniNavLinkId}
        onClick={onClick}
        aria-label={useSite(Site.MoreNav)}
        aria-expanded={isMenuOpen ? 'true' : 'false'}
        aria-controls="miniNav"
        ref={ref}
      >
        <BreakpointEither breakpoint={mobileBreakpoint}>
          {isMenuOpen ? <CloseMenu /> : <HamburgerMenuLink />}
          <HamburgerMenuLink />
        </BreakpointEither>
      </Container>
    );
  },
);

const Container = styled.button`
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-right: 0px;
  padding-left: ${spaces.small}px;
  ${media.tablet`
  ${hoverLinkStyles}
  transition: background-color 250ms ease;
    border-radius: 9999px;
    padding: ${spaces.small}px;
  `}
  ${media.desktop`
    ${hoverLinkStyles}
    ${focusLinkStyles}
    transition: background-color 250ms ease;
    border-radius: 9999px;
    padding: ${spaces.small}px;
  `}
`;

const HamburgerMenuLink = styled(HamburgerMenu)`
  fill: ${slate2};
`;

const CloseMenu = styled(CloseIcon)`
  fill: ${slate2};
  height: 24px;
  width: 24px;
  padding: 4px;
`;

export default MiniNavLink;

type Props = ContainerProps & {
  user: TopNavUserInfo;
};

type ContainerProps = {
  isMenuOpen: boolean;
  onClick(): void;
};
