/* eslint-disable */
import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type SelectedTagNameQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectedTagNameQuery = (
  { __typename: 'Query' }
  & Pick<Types.Query, 'selectedTagName'>
);


export const SelectedTagNameDocument = gql`
    query SelectedTagName {
  selectedTagName @client
}
    `;

/**
 * __useSelectedTagNameQuery__
 *
 * To run a query within a React component, call `useSelectedTagNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedTagNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedTagNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedTagNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedTagNameQuery, SelectedTagNameQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedTagNameQuery, SelectedTagNameQueryVariables>(SelectedTagNameDocument, baseOptions);
      }
export function useSelectedTagNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedTagNameQuery, SelectedTagNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedTagNameQuery, SelectedTagNameQueryVariables>(SelectedTagNameDocument, baseOptions);
        }
export type SelectedTagNameQueryHookResult = ReturnType<typeof useSelectedTagNameQuery>;
export type SelectedTagNameLazyQueryHookResult = ReturnType<typeof useSelectedTagNameLazyQuery>;
export type SelectedTagNameQueryResult = ApolloReactCommon.QueryResult<SelectedTagNameQuery, SelectedTagNameQueryVariables>;