import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT, isHttpError } from '@peloton/api';
import { logError } from '@engage/error-reporting/logError';
import { memberSuccess, toMemberFromProfile, memberFail, isApiMe } from '@engage/members';
import { fetchMember } from '../api';
import type { loadMember } from '../redux';
import {
  loadMemberRequestSuccess,
  loadMemberSuccess,
  loadUserRequestSuccess,
  MemberMapperActionType,
} from '../redux';

export const memberMapperSaga = function* (
  client: Client,
  action: ReturnType<typeof loadMemberRequestSuccess>,
) {
  const member = action.payload;

  try {
    // Individual mapped member types
    yield put(memberSuccess(toMemberFromProfile(member)));

    // Mapping complete
    yield put(loadMemberSuccess());
  } catch (e) {
    logError(e, 'memberMapperSaga');
    yield put(memberFail(member.id, e));
  }
};

const is401 = (e: any) => isHttpError(e) && e.status === 401;

export const fetchMemberSaga = function* (
  client: Client,
  action: ReturnType<typeof loadMember>,
): SagaIterator {
  try {
    const member = yield call(fetchMember, client, action.payload.userIdOrUsername);
    yield put(loadMemberRequestSuccess(member));
    if (isApiMe(member)) {
      yield put(loadUserRequestSuccess(member));
    }
  } catch (e) {
    yield put(memberFail(action.payload.userIdOrUsername, e));
    !is401(e) && logError(e, 'fetchMemberSaga');

    // 401s are already handled, don't report those
    if (action.payload.onError && !is401(e)) {
      yield put(action.payload.onError(e));
    }
  }
};

export default function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(MemberMapperActionType.Request, fetchMemberSaga, client);
  yield takeEvery(MemberMapperActionType.RequestSuccess, memberMapperSaga, client);
}
