import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { useOnClickOutside } from '@peloton/react';
import { media } from '@peloton/styles';
import { black, white, superRed } from '@engage/colors';
import { OVERVIEW_ROUTE } from '@engage/overview';
import { spaces } from '@engage/styles';
import { label14, interVTab } from '@engage/typography';
import { CHALLENGES_ROUTE } from '@members/challenges';
import { TopNavCopy, TopNav } from '@members/copy';
import { ScheduledClassFetchersGQL } from '@members/data-fetch';
import { Chevron } from '@members/icons';
import { SimpleAvatar } from '@members/images';
import {
  workoutsButtonClassName,
  workoutsParentClassName,
} from '@members/onboarding-tooltips/classNames';
import { FEED_ROUTE } from '@members/pg-feed/Routes';
import { useManagePendingInvites } from '@members/pg-schedule/helpers';
import { SCHEDULE_ROUTE, YOUR_SCHEDULE_ROUTE } from '@members/schedule/urls';
import { useGetQueryTimeRange } from '@members/schedule/utils/helpers';
import {
  MainLink,
  makeLinkActivatable,
  hoverLinkStyles,
  mainLinkStyles,
  focusLinkStyles,
} from '../../sharedLinkStyles';
import { ANALYTICS_SOURCE } from '../analytics';
import { WorkoutsDropdown } from './WorkoutsDropdown';

const TopNavLinks: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <LinkContainer>
      <WorkoutsLink />
      <ScheduleLink />
      <FeedLink />
      <ChallengesLink />
      {<ProfileLink />}
    </LinkContainer>
  );
};

const WorkoutsLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  const clickOutsideEnabled = true;

  const workoutsRef = React.useRef<Element[]>([]);
  const containerRef = React.useRef<HTMLUListElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  if (containerRef.current !== null && buttonRef.current !== null) {
    workoutsRef.current = [containerRef.current, buttonRef.current];
  }

  const [isWorkoutsOpen, setIsWorkoutsOpen] = React.useState(false);

  const handleWorkoutsOpen = React.useCallback(
    (e?: Event) => {
      if (e) {
        e.stopPropagation();
      }
      window.document.body.classList.add('allow-menu-animation');
      setIsWorkoutsOpen(!isWorkoutsOpen);
    },
    [isWorkoutsOpen],
  );

  const handleWorkoutsClose = React.useCallback((e?: Event) => {
    if (e) {
      e.stopPropagation();
    }
    window.document.body.classList.add('allow-menu-animation');
    setIsWorkoutsOpen(false);
  }, []);

  const toggleWorkoutsOpen = () => {
    isWorkoutsOpen ? handleWorkoutsClose() : handleWorkoutsOpen();
  };

  useOnClickOutside(workoutsRef, handleWorkoutsClose, true, clickOutsideEnabled);
  return (
    <>
      <WorkoutsButtonContainer
        className={workoutsParentClassName}
        data-test-id="navWorkoutsContainer"
      >
        <WorkoutsButton
          onClick={toggleWorkoutsOpen}
          aria-expanded={isWorkoutsOpen}
          aria-controls="workoutsButton"
          ref={buttonRef}
          data-test-id="workoutsLink"
          className={workoutsButtonClassName}
        >
          <TopNavCopy id={TopNav.Workouts} />
          <WorkoutsChevron />
        </WorkoutsButton>
      </WorkoutsButtonContainer>
      <WorkoutsDropdown isMenuOpen={isWorkoutsOpen} ref={containerRef} />
    </>
  );
};

const FeedLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActivatableMainLink
    to={{
      pathname: FEED_ROUTE,
      state: { source: ANALYTICS_SOURCE },
    }}
    data-test-id="feedLink"
    id="feedLink"
  >
    <TopNavCopy id={TopNav.Feed} />
  </ActivatableMainLink>
);

const ScheduleLink = () => {
  return (
    <ActivatableMainLink
      to={{ pathname: SCHEDULE_ROUTE, state: { source: ANALYTICS_SOURCE } }}
      isActive={(match, location) => {
        return match ? true : location.pathname === YOUR_SCHEDULE_ROUTE;
      }}
      data-test-id="scheduleLink"
    >
      <TopNavCopy id={TopNav.Schedule} />
    </ActivatableMainLink>
  );
};

export const usePendingInviteSetup = () => {
  const { startTime, endTime } = useGetQueryTimeRange();

  const { data, error } = useSWR(
    ...ScheduledClassFetchersGQL.PendingInviteCountQuery({
      startTime,
      endTime,
    }),
  );

  const { valueToShow } = useManagePendingInvites(data);

  return {
    error,
    valueToShow,
  };
};

export const ScheduleLinkWithInvites: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { error, valueToShow } = usePendingInviteSetup();

  return (
    <ActivatableMainLink
      to={{ pathname: SCHEDULE_ROUTE, state: { source: ANALYTICS_SOURCE } }}
      isActive={(match, location) => {
        return match ? true : location.pathname === YOUR_SCHEDULE_ROUTE;
      }}
      data-test-id="scheduleLink"
    >
      <TopNavCopy id={TopNav.Schedule} />
      {!error && valueToShow > 0 ? (
        <PendingInvitesBadge numOfPendingInvites={valueToShow}>
          <InviteDigit>{valueToShow}</InviteDigit>
        </PendingInvitesBadge>
      ) : null}
    </ActivatableMainLink>
  );
};

const ChallengesLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActivatableMainLink
    to={{
      pathname: CHALLENGES_ROUTE,
      state: { source: ANALYTICS_SOURCE },
    }}
    data-test-id="challengesLink"
  >
    <LinkAndTooltipWrapper>
      <TopNavCopy id={TopNav.Challenges} />
    </LinkAndTooltipWrapper>
  </ActivatableMainLink>
);

const ProfileLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ActivatableMainLink
    to={{
      pathname: OVERVIEW_ROUTE,
      state: { source: ANALYTICS_SOURCE },
    }}
    data-test-id="profileLink"
  >
    <LinkAndTooltipWrapper>
      <TopNavCopy id={TopNav.Profile} />
    </LinkAndTooltipWrapper>
  </ActivatableMainLink>
);

const topLinkStyle = `
  ${label14}
  margin-bottom: 0px;
  padding: 14px ${spaces.medium}px;
  border-radius: 4px;

  ${media.tablet`
    & + a {
      margin-left: 10px;
    }
  `}

  ${media.desktop`
    & + a {
      margin-left: ${spaces.xSmall}px;
    }
  `}
`;

export const TopTextLink = styled(MainLink)`
  ${topLinkStyle}
`;

const ActivatableMainLink = makeLinkActivatable(TopTextLink);

const WorkoutsChevron = styled(Chevron)`
  margin-left: ${spaces.xSmall}px;
  width: 13.33px;
  height: 7px;
  top: 3px;
  left: 3.33px;
`;

const NavAvatar = styled(SimpleAvatar)`
  box-shadow: 0 0 3px ${transparentize(0.8, black)};
  height: ${spaces.xxxLarge}px;
  width: ${spaces.xxxLarge}px;
  padding: 0px;
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  position: relative;
`;

const WorkoutsButton = styled.button`
  ${mainLinkStyles}
  ${hoverLinkStyles}
  ${topLinkStyle}
  ${focusLinkStyles}
`;

export const PendingInvitesBadge = styled.div<{ numOfPendingInvites: number }>`
  background-color: ${superRed};
  color: ${white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spaces.xxxSmall};
  height: ${spaces.medium}px;

  margin-left: 3px;
  ${props =>
    props.numOfPendingInvites > 9
      ? `
      border-radius: 25px;
      width: ${spaces.xLarge}px;
      `
      : `
      border-radius: 50%;
      width: ${spaces.medium}px;
    `}

  ${media.tabletXLarge`
    margin-top: 1px;
  `}
`;

export const InviteDigit = styled.span`
  font-family: ${interVTab};
  font-size: 12px;
  line-height: 1px;
  padding-bottom: 1px;
  ${media.tabletXLarge`
    font-size: 10px;
    line-height: unset;
    padding-bottom: unset;
  `}
`;

const LinkAndTooltipWrapper = styled.div`
  position: relative;
`;

const WorkoutsButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { MainLink, NavAvatar };
export default TopNavLinks;
