import React from 'react';
import useSWR, { mutate } from 'swr';
import { track } from '@engage/analytics';
import { useReduxAction } from '@engage/redux';
import { ActivityFeedFetchersGQL } from '@members/data-fetch';
import type {
  UserActivityFeedQuery,
  UserActivityFeedQueryVariables,
} from '@members/graphql-swr/schemas/activity-feed/queries/UserActivityFeed.generated';
import type {
  ActivityFeedEventEdge,
  EventDetails,
  NotificationDetails,
  UnreadNotifications,
  ActivityFeedEventPaginationResponse,
} from '@members/graphql-swr/types.generated';
import { trackRefreshedFeed } from './analytics';

export const useTrackActivityFeed = () => {
  const trackAnalytics = useReduxAction(track);

  const trackEdges = (
    prevousEdges: ActivityFeedEventEdge[],
    incomingEdges: ActivityFeedEventEdge[],
  ) => {
    if (prevousEdges.length > 9) {
      trackAnalytics(trackRefreshedFeed(prevousEdges.length, incomingEdges.length));
    }
  };

  return trackEdges;
};

export const useFeedNotificationsData = () => {
  const { data, mutate: refetch } = useSWR(
    ...ActivityFeedFetchersGQL.GetNumberOfUnreadNotificationsQuery({}),
  );

  const quantityOfNotifs = (data?.getNumberOfUnreadNotifications as UnreadNotifications)
    ?.count;

  return { quantityOfNotifs, refetch };
};

export const useGetNotificationDetails = () => {
  const { data, mutate: refetch } = useSWR(
    ...ActivityFeedFetchersGQL.GetNotificationDetailsQuery({}),
  );

  const loading = !data;

  const notifications = (data?.getNotificationDetails as NotificationDetails)
    ?.notifications;

  return { notifications, loading, refetch };
};

export const useGetEventDetails = (eventID: string) => {
  const { data } = useSWR(
    ...ActivityFeedFetchersGQL.GetEventDetailsQuery({
      activityFeedEventId: eventID,
    }),
  );

  const loading = !data;

  const eventInfo = data?.getEventDetails as EventDetails;

  return { eventInfo, loading };
};

export const useGetActivityFeedDataQuery = (
  variables: UserActivityFeedQueryVariables,
) => {
  const [allData, setAllData] = React.useState<UserActivityFeedQuery>(
    {} as UserActivityFeedQuery,
  );
  const [isSyntheticLoading, setIsSyntheticLoading] = React.useState(true);
  const { error, mutate: refetch } = useSWR(
    ...ActivityFeedFetchersGQL.UserActivityFeedQuery(variables, undefined, {
      fallbackData: {} as UserActivityFeedQuery,
      // onError: () => setShowError(true),
      onSuccess: succData => {
        setAllData(succData);
        setIsSyntheticLoading(false);
      },
    }),
  );

  const fetchMore = () => {
    if (
      (allData?.userActivityFeed as ActivityFeedEventPaginationResponse)?.pageInfo
        .hasNextPage &&
      !isSyntheticLoading
    ) {
      const cursor = (allData?.userActivityFeed as ActivityFeedEventPaginationResponse)
        ?.pageInfo.endCursor;
      const limit = variables.limit;
      setIsSyntheticLoading(true);
      mutate(
        ...ActivityFeedFetchersGQL.UserActivityFeedPagination({
          cursor: cursor,
          limit: limit,
        }),
      ).then(moreData => {
        setAllData({
          ...allData,
          ...moreData,
          userActivityFeed: {
            edges: [
              ...(allData.userActivityFeed as ActivityFeedEventPaginationResponse).edges,
              ...(moreData.userActivityFeed as ActivityFeedEventPaginationResponse).edges,
            ],
            pageInfo: (moreData.userActivityFeed as ActivityFeedEventPaginationResponse)
              .pageInfo,
            ...moreData.userActivityFeed.__typename,
          },
        });
        setIsSyntheticLoading(false);
      });
    }
  };

  return {
    data: allData,
    setAllData: setAllData,
    mutate: refetch,
    loading: isSyntheticLoading,
    error,
    fetchMore,
  };
};
