import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { getIsError, getEntity, getError } from '@peloton/redux-fetch';
import type { FacebookFriendInfo } from '../models';

// Facebook Connect and Auth

// Open Modal + Find Friends
export const facebookFlow = () => ({
  type: FacebookActionTypes.FacebookFlow,
});

export const findFacebookFriends = () => ({
  type: FacebookActionTypes.FindFriends,
});

export const facebookRerequest = () => ({
  type: FacebookActionTypes.Rerequest,
});

export enum FacebookActionTypes {
  Connect = 'pelo/facebook/Connect',
  Rerequest = 'pelo/facebook/Rerequest',
  FacebookFlow = 'pelo/facebook/FacebookFlow',
  FindFriends = 'pelo/facebook/FindFriends',
  Disconnect = 'pelo/facebook/Disconnect',
}

// Facebook Friends

export const facebookNamespace = 'facebook';

export const connectToFacebook = () => ({
  type: FacebookActionTypes.Connect,
});

export const disconnectFromFacebook = () => ({
  type: FacebookActionTypes.Disconnect,
});

export const getFriendsList = (
  state: FetcherSelectorState,
): FacebookFriendInfo[] | undefined => {
  const entity = getEntity<FacebookFriendsSummary>(state, facebookNamespace);
  return entity?.friends;
};

export const getTotalFriends = (state: FetcherSelectorState) => {
  const entity = getEntity<FacebookFriendsSummary>(state, facebookNamespace);
  return entity?.total;
};

export const isFacebookFriendsPermissionError = (state: FetcherSelectorState) =>
  getIsError(state, facebookNamespace) &&
  isPermissionError(getError(state, facebookNamespace) as any);

const isPermissionError = (error: any) =>
  error.responseBody &&
  error.responseBody.message &&
  error.responseBody.message.indexOf('permission') !== -1;

type FacebookFriendsSummary = {
  total: number;
  friends: FacebookFriendInfo[];
};
