import axios from 'axios';
// eslint-disable-next-line no-restricted-imports
import { identity, prop } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';

const toImageUploadUrl = (userId: string) => `api/user/${userId}/image_upload_url`;

const toUpdateUserUrl = (userId: string) => `api/user/${userId}`;

const toDefaultUserUrl = () => `api/user/default_profile_image`;

export const getImageUploadUrl = (api: Client, userId: string) =>
  api.get(toImageUploadUrl(userId)).then(pipeData(identity));

export const updateUserImage = (api: Client, userId: string, imageUrl: string) =>
  api
    .put(toUpdateUserUrl(userId), { imageUrl: imageUrl })
    .then(pipeData(prop('imageUrl')));

export const uploadToS3 = (file: File, uploadUrl: string) =>
  axios.put(uploadUrl, file, {
    headers: { 'Content-Type': 'application/octet-stream' }, // Ensure headers match those expected by signed upload url
  });

export const getDefaultImage = (api: Client) =>
  api.get(toDefaultUserUrl()).then(pipeData(prop('defaultImageUrl')));
