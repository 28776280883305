import React from 'react';

export const BrowseTagsIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 36 35" {...props}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeWidth="1.25">
      <path
        d="M22.502 16.778H1.76c-.61 0-1.106-.496-1.106-1.107V8.493c0-.611.495-1.107 1.106-1.107h20.743c.61 0 1.107.496 1.107 1.107v7.178c0 .611-.496 1.107-1.107 1.107zM23.609.652V2.74c0 .576-.468 1.044-1.044 1.044H1.695c-.575 0-1.043-.468-1.043-1.044V.652M.652 23.609v-2.087c0-.576.468-1.044 1.044-1.044h20.87c.575 0 1.043.468 1.043 1.044v2.087"
        transform="translate(6.5 5.5)"
      />
    </g>
  </svg>
);
