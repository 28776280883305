import type { PendingResult } from '@engage/result';
import { ok, error, pending, isOk } from '@engage/result';
import type { ChallengeWithDetail } from '../models';
import type { ToggleJoinedChallengeSuccess } from './collection';
import { ActionType as CollectionActionType } from './collection';

// Action Types

export enum ActionType {
  LoadChallengeDetailRequest = '@engage/challenges/LOAD_CHALLENGE_DETAILS_REQUEST',
  LoadChallengeDetailFailure = '@engage/challenges/LOAD_CHALLENGE_DETAILS_FAILURE',
  LoadChallengeDetailSuccess = '@engage/challenges/LOAD_CHALLENGE_DETAILS_SUCCESS',
}

// Action Creators

export const loadChallengeDetailRequest = (challengeId: string) =>
  ({
    type: ActionType.LoadChallengeDetailRequest,
    payload: { challengeId },
  } as const);

export const loadChallengeDetailFailure = () =>
  ({
    type: ActionType.LoadChallengeDetailFailure,
  } as const);

export const loadChallengeDetailSuccess = (detail: ChallengeWithDetail) =>
  ({
    type: ActionType.LoadChallengeDetailSuccess,
    payload: { detail },
  } as const);

export type LoadChallengeDetailRequest = ReturnType<typeof loadChallengeDetailRequest>;
export type LoadChallengeDetailFailure = ReturnType<typeof loadChallengeDetailFailure>;
export type LoadChallengeDetailSuccess = ReturnType<typeof loadChallengeDetailSuccess>;

export type Action =
  | LoadChallengeDetailRequest
  | LoadChallengeDetailFailure
  | LoadChallengeDetailSuccess
  | ToggleJoinedChallengeSuccess;

// Reducer

export type State = PendingResult<ChallengeWithDetail, undefined>;
export const DEFAULT_STATE: State = pending;

export const challengeDetailReducer = (state: State = DEFAULT_STATE, action: Action) => {
  switch (action.type) {
    case ActionType.LoadChallengeDetailRequest:
      return pending;
    case ActionType.LoadChallengeDetailSuccess:
      return ok(action.payload.detail);
    case ActionType.LoadChallengeDetailFailure:
      return error(undefined);
    case CollectionActionType.ToggleJoinedSuccess:
      if (isOk(state)) {
        return ok({
          ...state.value,
          progress: action.payload.progress,
          participants: action.payload.participants,
        });
      }
      return state;
    default:
      return state;
  }
};
