import type { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { updateBlockExplicitSettings as apiUpdate } from '../api';
import type { UpdateBlockExplicitSettingsRequestAction } from '../redux';
import { updateBlockExplicitSettings, updateBlockExplicitFailure } from '../redux';

export default function* (
  client: Client,
  action: UpdateBlockExplicitSettingsRequestAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);

  if (userId) {
    try {
      const settings = yield call(apiUpdate, client, userId, action.payload);
      yield put(updateBlockExplicitSettings(settings));
    } catch (e) {
      yield put(updateBlockExplicitFailure());
    }
  }
}
