/* eslint-disable react/display-name */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { toMapStateToLinkProps } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { history } from '@peloton/redux';
import { hoverTransition, media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { slate2, gray2, gray3, white } from '@engage/colors';
import { useReduxAction, useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label12, link1 } from '@engage/typography';
import * as Analytics from '@members/analytics';
import { useLogout } from '@members/auth/OauthProvider';
import { LinksCopy, TopNav, TopNavCopy } from '@members/copy';
import {
  ExploreTags as BrowseTags,
  FindMembersNew as FindMembers,
  ReferFriends as GiftIcon,
  Settings as SettingsIcon,
  ShoppingCart,
  LogOut,
} from '@members/icons';
import {
  toDeviceNameId,
  toDownloadAppsProps,
  useGetApps,
  useMaybeAppDownload,
} from '@members/modal-get-app';
import { PREFS_ROUTE } from '@members/pg-preferences/urls';
import { useReferralsUrlWithSource } from '@members/referrals';
import { TagModalView } from '@members/tags/shared';
import { toTagsModalQuery } from '@members/tags/urls';
import { useIsFreemiumMember } from '@members/tiering/hooks/useIsFreemiumMember';
import { navMainLink } from '../../../sharedLinkStyles';
import { MENU_SOURCE, MENU_ANALYTICS_OPTIONS } from '../../analytics';
import { openMemberSearchFromNav } from '../MemberSearchFromNav';

export const MiniNav = React.forwardRef<HTMLUListElement, MiniNavViewProps>(
  (props, ref) => {
    const deviceId = toDeviceNameId();
    const device = useMaybeAppDownload(deviceId);
    const hasGetApps = useGetApps();

    const links = useReduxState(
      toMapStateToLinkProps({
        boutiqueHref: Links.boutique,
        supportHref: Links.support,
        orderhistoryHref: Links.orderhistory,
        orderhistoryAccountHref: Links.orderHistoryAccount,
      }),
    );

    const trackAction = useReduxAction(track);
    const handleSearchClick = useReduxAction(openMemberSearchFromNav);

    const referralsUrlWithSource = useReferralsUrlWithSource('nav');

    const goToBrowseTags = () =>
      history.push(toTagsModalQuery(TagModalView.BrowseTags), { source: MENU_SOURCE });
    const logout = useLogout();

    const onClick = (cb: () => void) => () => {
      props.handleMenuClose();
      cb();
    };

    const isFreemiumMember = useIsFreemiumMember();

    return (
      <Container
        data-test-id="miniNav"
        id="miniNav"
        ref={ref}
        isMenuOpen={props.isMenuOpen}
      >
        <li>
          <SearchButton onClick={onClick(handleSearchClick)}>
            <StyledFindMembersIcon />
            <LinkText data-test-id="findMembersButton">
              <TopNavCopy id={TopNav.FindMembers} />
            </LinkText>
          </SearchButton>
        </li>
        {!isFreemiumMember ? (
          <li>
            <MiniNavLink
              data-test-id="referFriendsButton"
              href={referralsUrlWithSource}
              target="_blank"
              rel="noopener"
            >
              <StyledGiftIcon />
              <LinkText>
                <TopNavCopy id={TopNav.ReferFriends} />
              </LinkText>
            </MiniNavLink>
          </li>
        ) : null}
        <li>
          <StyledButton onClick={onClick(goToBrowseTags)}>
            <StyledBrowseTags />
            <LinkText data-test-id="exploreTagsNavButton">
              <TopNavCopy id={TopNav.ExploreTags} />
            </LinkText>
          </StyledButton>
        </li>
        <li>
          <MiniNavRouterLink to={PREFS_ROUTE}>
            <StyledSettingsIcon />
            <LinkText data-test-id="accountLink">
              <TopNavCopy id={TopNav.Preferences} />
            </LinkText>
          </MiniNavRouterLink>
        </li>
        <li>
          <MiniNavLink
            href={links.orderhistoryAccountHref}
            onClick={onClick(() => trackAction(Analytics.trackClickOrderHistory()))}
            data-test-id="orderHistory"
            target="_blank"
            rel="noopener"
          >
            <StyledOrderHistoryIcon />
            <LinkText>
              <TopNavCopy id={TopNav.OrderHistory} />
            </LinkText>
          </MiniNavLink>
        </li>
        <li>
          <StyledButton onClick={onClick(() => logout(MENU_ANALYTICS_OPTIONS))}>
            <StyledLogoutIcon />
            <LinkText data-test-id="logoutButton">
              <TopNavCopy id={TopNav.Logout} />
            </LinkText>
          </StyledButton>
        </li>
        <li>
          <SecondaryLinksContainer>
            {hasGetApps ? (
              <SecondaryButton onClick={onClick(props.handleOpenGetApp)}>
                <LinksCopy {...toDownloadAppsProps(device)} />
              </SecondaryButton>
            ) : null}
            <li>
              <SecondaryLink
                href={links.boutiqueHref}
                onClick={onClick(() =>
                  trackAction(Analytics.trackClickBoutique(MENU_SOURCE)),
                )}
                data-test-id="boutiqueLink"
                target="_blank"
                rel="noreferrer"
              >
                <TopNavCopy id={TopNav.PelotonApparel} />
              </SecondaryLink>
            </li>
            <li>
              <SecondaryLink
                href={links.supportHref}
                onClick={onClick(() =>
                  trackAction(Analytics.trackClickSupport(MENU_SOURCE)),
                )}
                data-test-id="supportLink"
                target="_blank"
                rel="noreferrer"
              >
                <TopNavCopy id={TopNav.Support} />
              </SecondaryLink>
            </li>
          </SecondaryLinksContainer>
        </li>
      </Container>
    );
  },
);

const MiniNavItemStyles = css`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  height: 46px;
  padding: 0 ${spaces.large}px;
  ${hoverTransition({
    property: 'background-color',
    from: white,
    to: gray2,
  })}
`;

const MiniNavLink = styled.a`
  ${MiniNavItemStyles}
`;

const SearchButton = styled.button`
  border-radius: ${spaces.xxxSmall}px ${spaces.xxxSmall}px 0px 0px;
  ${MiniNavItemStyles}
  cursor: pointer;
  width: 100%;
`;

const StyledButton = styled.button`
  ${MiniNavItemStyles}
  cursor: pointer;
  width: 100%;
`;

const MiniNavRouterLink = styled(RouterLink)`
  ${MiniNavItemStyles}
`;

const SecondaryLinksContainer = styled.ul`
  border-radius: 0px 0px ${spaces.xxxSmall}px ${spaces.xxxSmall}px;
  background-color: ${white};
  border-top: 1px solid ${gray3};
  display: flex;
  flex-direction: column;
  padding: 15px ${spaces.large}px;
`;

const secondaryLinkStyles = css`
  ${label12}
  color: ${slate2};
  display: block;
  padding: 5px 0;
  ${link1}
`;

const SecondaryLink = styled.a`
  ${secondaryLinkStyles}
`;

const SecondaryButton = styled.button`
  ${secondaryLinkStyles}
`;

const iconStyle = css`
  fill: ${slate2};
  width: ${spaces.large}px;
  margin-right: 10px;
`;

const LinkText = styled.span`
  ${navMainLink}
  color: ${slate2};
`;

const Container = styled.ul<{ isMenuOpen: boolean }>`
  border-radius: ${spaces.xxxSmall}px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
  position: absolute;
  right: 10px;
  top: 51px;
  width: 280px;

  ${media.desktop`
    right: 56px;
  `}

  ${p => !p.isMenuOpen && `display: none;`}
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  ${iconStyle}
`;

const StyledLogoutIcon = styled(LogOut)`
  fill: ${slate2};
  width: ${spaces.large}px;
  margin-right: ${spaces.xSmall}px;
  margin-left: ${spaces.tiny}px;
`;

const StyledOrderHistoryIcon = styled(ShoppingCart)`
  ${iconStyle}
`;

const StyledFindMembersIcon = styled(FindMembers)`
  ${iconStyle}
`;

const StyledBrowseTags = styled(BrowseTags)`
  ${iconStyle}
`;

const StyledGiftIcon = styled(GiftIcon)`
  ${iconStyle}
`;

export default MiniNav;

type MiniNavViewProps = {
  handleOpenGetApp(): void;
  handleMenuClose(e?: Event): void;
  isMenuOpen: boolean;
};
