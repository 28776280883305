import React from 'react';

const Collections: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2328 3.07148C12.087 2.99374 11.9128 2.99374 11.767 3.07148L3.57353 7.44071L11.767 11.8099C11.9128 11.8877 12.087 11.8877 12.2328 11.8099L20.4263 7.44071L12.2328 3.07148ZM11.3013 2.1749C11.7386 1.9417 12.2612 1.9417 12.6985 2.1749L21.7327 6.99242C21.8969 7.08002 21.9998 7.25262 21.9998 7.44071C21.9998 7.6288 21.8969 7.8014 21.7327 7.889L12.6985 12.7065C12.2612 12.9397 11.7386 12.9397 11.3013 12.7065L2.26712 7.889C2.10285 7.8014 2 7.6288 2 7.44071C2 7.25262 2.10285 7.08002 2.26712 6.99242L11.3013 2.1749ZM2.05764 11.7641C2.18625 11.5165 2.48861 11.4214 2.73297 11.5517L11.7671 16.3692C11.9129 16.447 12.0871 16.447 12.2329 16.3692L21.267 11.5517C21.5114 11.4214 21.8137 11.5165 21.9424 11.7641C22.071 12.0116 21.9771 12.318 21.7328 12.4483L12.6986 17.2658C12.2613 17.499 11.7387 17.499 11.3014 17.2658L2.26723 12.4483C2.02286 12.318 1.92903 12.0116 2.05764 11.7641ZM2.73297 16.111C2.48861 15.9807 2.18625 16.0758 2.05764 16.3233C1.92903 16.5709 2.02286 16.8773 2.26723 17.0076L11.3014 21.8251C11.7387 22.0583 12.2613 22.0583 12.6986 21.8251L21.7328 17.0076C21.9771 16.8773 22.071 16.5709 21.9424 16.3233C21.8137 16.0758 21.5114 15.9807 21.267 16.111L12.2329 20.9285C12.0871 21.0063 11.9129 21.0063 11.7671 20.9285L2.73297 16.111Z"
    />
  </svg>
);

export { Collections };
