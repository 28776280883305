import { isDefined } from '@peloton/types';
import type { RideSubsegment } from '@engage/api-v2';

export default function getLastCompletedSubsegment(
  subsegments: RideSubsegment[] | undefined,
  rawOffset: number,
): RideSubsegment | null {
  if (!subsegments) {
    return null;
  }

  const validSubsegments = subsegments.filter(s => s.length && isDefined(s.offset));
  const offset = Math.ceil(rawOffset);

  if (validSubsegments.length === 0) {
    return null;
  }

  for (let i = validSubsegments.length - 1; i > -1; i--) {
    const candidateSubsegment = validSubsegments[i];
    const subsegmentElapsedTime =
      (candidateSubsegment?.offset ?? 0) + (candidateSubsegment?.length ?? 0);

    if (subsegmentElapsedTime <= offset) {
      return candidateSubsegment;
    }
  }

  return null;
}
