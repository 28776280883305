import { getExtLinkEnv, toHref, toWWWLink } from '@peloton/external-links';
import { MYMEMBERSHIP_ROUTE } from '@peloton/links/account';
import { useReduxState } from '@engage/redux';

export const PROFILE_INFO_ROUTE = '/profile-info';
export const SETTINGS_ROUTE = '/settings';
export const SUBS_ROUTE = '/subscriptions';
export const SUB_DETAILS_ROUTE = '/subscriptions/:id';
export const SUB_MANAGE_ROUTE = '/manage';
export const SUB_MANAGE_SUCESS_ROUTE = '/success';
export const PREFS_ROUTE = '/preferences';
export const TRANSACTION_ROUTE =
  PREFS_ROUTE + SUB_DETAILS_ROUTE + '/transactions/:transactionId';

export const toSubDetailsUrl = (id: string) =>
  PREFS_ROUTE + SUB_DETAILS_ROUTE.replace(':id', id);

export const toSubManageSuccessUrl = (id: string) =>
  toSubDetailsUrl(id) + SUB_MANAGE_ROUTE + SUB_MANAGE_SUCESS_ROUTE;

export const toSubscriptionsUrl = () => PREFS_ROUTE + SUBS_ROUTE;

export const toNewSubscriptionsUrl = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  toHref(toWWWLink(MYMEMBERSHIP_ROUTE), useReduxState(getExtLinkEnv));

export const toTransactionUrl = (subscriptionId: string, transactionId: string) =>
  TRANSACTION_ROUTE.replace(':id', subscriptionId).replace(
    ':transactionId',
    transactionId,
  );

export const toSettingsUrl = () => PREFS_ROUTE + SETTINGS_ROUTE;
