import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import {
  getActivePrimaryDigitalTrial,
  getLapsedDigitalTrial,
} from '@engage/subscriptions';
import { toSubscriptionsUrl } from '@members/pg-preferences/urls';
import { WELCOME_PATH, PASSWORD_PATH } from '@members/pg-profile-setup/urls';
import { LIVE_VIDEO_ROUTE, VIDEO_ON_DEMAND_ROUTE } from '@members/pg-video/urls';
import { useAllSubscriptions } from '@members/subscriptions/hooks/useAllSubscriptions';
import { ExpiringSoon } from './ExpiringSoon';
import { LapsedTrial } from './LapsedTrial';

const BLACKLIST_PATHS = [
  VIDEO_ON_DEMAND_ROUTE,
  LIVE_VIDEO_ROUTE,
  toSubscriptionsUrl(),
  WELCOME_PATH,
  PASSWORD_PATH,
];

const isBlacklisted = (pathname: string) =>
  BLACKLIST_PATHS.some(path => matchPath(pathname, { path }));

export const GlobalFreeTrialBanner = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const subscriptions = useAllSubscriptions();
  if (isBlacklisted(pathname)) {
    return null;
  }
  if (!subscriptions) {
    return null;
  }
  const lapsedDigitalTrial = getLapsedDigitalTrial(subscriptions);
  const activePrimaryDigitalTrial = getActivePrimaryDigitalTrial(subscriptions);
  if (lapsedDigitalTrial) {
    return <LapsedTrial lapsedDigitalTrial={lapsedDigitalTrial} />;
  }
  if (activePrimaryDigitalTrial) {
    return <ExpiringSoon activePrimaryDigitalTrial={activePrimaryDigitalTrial} />;
  }
  return null;
};
