import React from 'react';

export const Save: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0832 2.08317V13.9939L6.6278 10.5385C6.46508 10.3758 6.20126 10.3758 6.03854 10.5385C5.87582 10.7013 5.87583 10.9651 6.03854 11.1278L10.2052 15.2945C10.2834 15.3726 10.3893 15.4165 10.4998 15.4165C10.6103 15.4165 10.7163 15.3726 10.7945 15.2945L14.9611 11.1278C15.1239 10.9651 15.1239 10.7013 14.9611 10.5385C14.7984 10.3758 14.5346 10.3758 14.3719 10.5385L10.9165 13.9939V2.08317C10.9165 1.85305 10.73 1.6665 10.4998 1.6665C10.2697 1.6665 10.0832 1.85305 10.0832 2.08317ZM18.4165 18.3332C18.527 18.3332 18.633 18.2893 18.7111 18.2111C18.7893 18.133 18.8332 18.027 18.8332 17.9165V14.5832C18.8332 14.3531 18.6466 14.1665 18.4165 14.1665C18.1864 14.1665 17.9998 14.3531 17.9998 14.5832V17.4998H2.99984L2.99984 14.5832C2.99984 14.3531 2.81329 14.1665 2.58317 14.1665C2.35305 14.1665 2.1665 14.3531 2.1665 14.5832V17.9165C2.1665 18.1466 2.35305 18.3332 2.58317 18.3332H18.4165Z"
      fill="white"
    />
  </svg>
);
