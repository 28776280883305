import React from 'react';
import type { StyledComponent } from 'styled-components';
import styled from 'styled-components';
import { slate3 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label12Caps } from '@engage/typography';
import type { SkipLinks as SkipLinksText, CopyIDs } from '@members/copy';
import { SkipLinksCopy } from '@members/copy';

// Shared element IDs
export const categoriesElementId = 'class-categories';
export const defaultContentId = 'content';
export const classSelectionElementId = 'class-selection';
export const liveElementId = 'live-content';
export const browseElementId = 'browse-content';
export const featuredId = 'featured-content';

type SkipLinkProps = {
  skipLinkId: CopyIDs<typeof SkipLinksText>;
  contentElementId: string;
  StyledLink?: StyledComponent<'a', any>;
  SkipContainer?: StyledComponent<'div', any>;
};

// Limit to two skip links, because more would be poor accessibility: https://hub.accessible360.com/issues/11168
export type SkipLinksProps = {
  skipLinks?: {
    first: SkipLinkProps;
    second?: SkipLinkProps;
    third?: SkipLinkProps;
  };
  StyledLink?: StyledComponent<'a', any>;
  SkipContainer?: StyledComponent<'div', any>;
};

export const SkipLinks: React.FC<React.PropsWithChildren<SkipLinksProps>> = ({
  skipLinks,
  StyledLink = StyledSkipLink,
  SkipContainer = StyledContainer,
}) =>
  skipLinks ? (
    <SkipContainer>
      <SkipLink {...skipLinks.first} StyledLink={StyledLink} />
      {skipLinks.second ? (
        <SkipLink {...skipLinks.second} StyledLink={StyledLink} />
      ) : null}
      {skipLinks.third ? <SkipLink {...skipLinks.third} StyledLink={StyledLink} /> : null}
    </SkipContainer>
  ) : null;

const SkipLink: React.FC<React.PropsWithChildren<SkipLinkProps>> = ({
  skipLinkId,
  contentElementId,
  StyledLink = StyledSkipLink,
}: SkipLinkProps) => (
  <StyledLink href={`#${contentElementId}`}>
    <SkipLinksCopy id={skipLinkId} />
  </StyledLink>
);

/**
 * the left attribute and width here is necessary for making sure the StyledSkipLink when focused
 * looks nice when it's back on the screen. Can't use padding since we're using position relative
 * in this case. The calc is for making sure that this relative div does not push the page's width
 * and overflow when the skip container's width is the width of the full page. Refer to WE-7444
 * Pull Request for more detailed write up and history.
 */
const StyledContainer = styled.div`
  position: relative;
  left: ${spaces.large}px;
  top: 0;
  width: calc(100% - ${spaces.large}px);
`;

const StyledSkipLink = styled.a`
  ${label12Caps}
  color: ${slate3};
  font-weight: 400;
  position: absolute;
  padding: ${spaces.xxSmall}px;
  left: -100vw;
  z-index: 100;

  &:focus {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;
