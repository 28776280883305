import { ChallengeStatus } from '@engage/challenges';

export const CHALLENGES_ID_PARAM = '/:challengeId?';
export const CHALLENGES_ROUTE = '/challenges';
export const CHALLENGES_ROUTE_ACTIVE = `/active`;
export const CHALLENGES_ROUTE_UPCOMING = `/upcoming`;
export const CHALLENGES_ROUTE_COMPLETED = `/completed`;
export const CHALLENGE_MODAL_ROUTE = `/challenges/:status(${Object.values(
  ChallengeStatus,
).join('|')})${CHALLENGES_ID_PARAM}`;
