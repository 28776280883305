import { isDefined } from '@peloton/types';
import { toBaseProperties } from '@members/analytics';
import type { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import type { ShareTooltipDestination } from '@members/share-modal';
import { TagModalView } from '../shared';

const DEFAULT_SOURCE = 'Direct Link';
const PROFILE_SOURCE = 'Profile';
const DETAIL_SOURCE = 'Tag Detail';

export const trackTappedShareTag = (
  tagName: string,
  source: string,
  isTagMember: boolean,
) => ({
  event: 'Tapped Share Tag',
  tag: tagName,
  source,
  isTagMember,
});

export const trackSelectedShareTagDestination = (
  tagName: string,
  source: string,
  isTagMember: boolean,
  destination: ShareTooltipDestination,
) => ({
  event: 'Selected Share Tag Destination',
  tag: tagName,
  source,
  isTagMember,
  destination,
});

export const trackViewedBrowseTags = (
  source: string,
  category: TagBrowseCategorySlug,
  numberTagsDisplayed: number,
) => ({
  event: 'Viewed Browse Tags',
  source,
  tagBrowseCategory: category,
  numberTagsDisplayed,
});

export const trackReplaceTagFlow = (
  tag: string,
  analyticsHeaders: Record<string, any>,
) => ({
  event: 'Viewed Replace Tag Flow',
  ...analyticsHeaders,
  tag,
});

export const trackCancelReplaceTagFlow = (
  tag: string,
  analyticsHeaders: Record<string, any>,
) => ({
  event: 'Cancelled Replace Tag',
  ...analyticsHeaders,
  tag,
});

export const trackSearchedTags = (input: string) => ({
  event: 'Searched Tags',
  source: toAnalyticsTagView(TagModalView.SearchAndCreate),
  searchTerm: input,
  searchTermLength: input.length,
});

export const trackLoadedSearchResults = (
  searchTerm: string,
  tagResultsCount: number,
) => ({
  event: 'Tag Search Results Loaded',
  tagResultsCount,
  searchTerm,
  searchTermLength: searchTerm.length,
});

export const trackInitiatedTagSearch = (source: string) => ({
  event: 'Initiated Tag Search',
  source,
});

export const analyticsCategoryMap = {
  FEATURED: 'Featured',
  FRIENDS: 'Friends',
  POPULAR: 'Popular',
  TRENDING: 'Trending',
};

export const toAnalyticsTagView = (
  tagView: TagModalView,
  category?: TagBrowseCategorySlug,
) => {
  switch (tagView) {
    case TagModalView.BrowseTags:
      return isDefined(category)
        ? `Browse Tags - ${analyticsCategoryMap[category]}`
        : 'Browse Tags';
    case TagModalView.MyTags:
      return 'My Tags';
    case TagModalView.OtherMembersTags:
      return 'Other Member Tags';
    case TagModalView.SearchAndCreate:
      return 'Search Tags';
    case TagModalView.TagDetails:
      return DETAIL_SOURCE;
    case TagModalView.ReplaceTag:
      return 'Replace Tag';
    default:
      return DEFAULT_SOURCE;
  }
};

export const toOtherUserIdProps = (id?: string) => ({ '[Other Member User ID]': id });

export const trackViewedTagDetail = (
  tag: string,
  numberOfTagMembers: number,
  isSelf: boolean,
  source: string,
  signedInUsername?: string,
  memberIdFromLocation?: string,
) => {
  const loggedIn = typeof signedInUsername === 'string';
  const otherUserId = isSelf ? undefined : memberIdFromLocation;
  const isOtherUser = typeof otherUserId === 'string';

  return {
    event: 'Viewed Tag Detail',
    source,
    tag,
    numberOfTagMembers,
    loggedIn,
    ...(isOtherUser ? toOtherUserIdProps(otherUserId) : {}),
  };
};

export const trackViewedMemberTags = (
  numberOfTags?: number,
  leaderboardTag?: string,
  isSelf?: boolean,
  sharedLeaderboardTag: string | null = null,
) => ({
  event: 'Viewed Member Tags List',
  source: PROFILE_SOURCE,
  numberOfTags,
  leaderboardTag,
  isSelf,
  sharedLeaderboardTag,
});

export const trackViewedTagSearchError = (
  searchTerm: string,
  searchTermLength: number,
) => ({
  event: 'Viewed Tag Search Error State',
  searchTerm,
  searchTermLength,
});

export const toLeaderboardHeaderProps = () => {
  const baseProps = toBaseProperties();
  return { Source: PROFILE_SOURCE, ...baseProps };
};

export const toMutateFromDetailsHeaderProps = () => {
  const baseProps = toBaseProperties();
  return { Source: DETAIL_SOURCE, ...baseProps };
};
