import { toEncodedClientDetails } from '@peloton/analytics';
import { toNowTimezoneString } from '@peloton/time';
import type { BaseAPI, CreateWorkoutRequest } from '../generated';
import { createWorkout } from '../generated';
import type { AnalyticsProps } from './spreadAnalytics';
import { spreadAnalytics } from './spreadAnalytics';

export const createWorkoutWithAnalytics = (
  api: BaseAPI,
  analyticsProps: AnalyticsProps,
  pelotonPlatform: Parameters<typeof createWorkout>[3],
  createWorkoutRequest: CreateWorkoutRequest,
  acceptLanguage?: string,
  options?: any,
) => {
  const encodedClientDetails = toEncodedClientDetails(spreadAnalytics(analyticsProps));

  return createWorkout(
    api,
    toNowTimezoneString(),
    createWorkoutRequest,
    pelotonPlatform,
    encodedClientDetails,
    acceptLanguage,
    options,
  );
};
