import { css } from 'styled-components';

export const injectableFonts = `
  @font-face {
    font-family: 'Inter V';
    src: url('${require('./fonts/Inter.var.woff2')}') format('woff2');
    font-weight: normal;
    font-variant: unset;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter V Tab';
    src: url('${require('./fonts/Inter.var.woff2')}') format('woff2');
    font-feature-settings: "tnum";
    font-weight: normal;
    font-display: swap;
  }
`;

export const fonts = css`
  ${injectableFonts}
`;
