import { curry, findIndex } from 'ramda';
import type { Reducer } from 'redux';
import type { AppliedFilter, FilterName } from '../models';

type ToggleSelection = {
  name: FilterName;
  value: string;
  displayName?: string;
  clearExistingValues?: boolean;
};
// utils

const doesMatchName = curry(
  (f1: AppliedFilter, f2: AppliedFilter) => f1.name === f2.name,
);
const doesMatchNameAndValue = curry(
  (f1: ToggleSelection, f2: AppliedFilter) =>
    f1.name === f2.name && f2.value.includes(f1.value),
);

const hasExactMatch = (f: ToggleSelection, state: AppliedState): boolean =>
  findIndex(doesMatchNameAndValue(f), state) > -1;

const remove = (f: ToggleSelection, state: AppliedState): AppliedState => {
  const matchedFilterIndex = state.findIndex(doesMatchNameAndValue(f));
  const matchedFilter = state[matchedFilterIndex];
  const updatedFilter = {
    ...matchedFilter,
    value: matchedFilter.value.filter(v => v !== f.value),
  };

  return state
    .slice(0, matchedFilterIndex)
    .concat(
      updatedFilter.value.length > 0 ? updatedFilter : [],
      state.slice(matchedFilterIndex + 1),
    );
};

const update = (f: ToggleSelection, state: AppliedState): AppliedState => {
  const { clearExistingValues = false, ...toggleSelection } = f;
  const appliedFilter = { ...toggleSelection, value: [toggleSelection.value] };
  const appliedFilterIndex = state.findIndex(doesMatchName(appliedFilter));
  const getFilterValue = (clearValues: boolean, stateFilter: AppliedFilter) => {
    if (!clearValues) {
      return [...stateFilter.value, ...appliedFilter.value];
    }

    return stateFilter.value.includes(f.value) ? stateFilter.value : appliedFilter.value;
  };
  if (appliedFilterIndex < 0) {
    return [...state, appliedFilter];
  }

  const stateFilter = state[appliedFilterIndex];
  const updatedFilter = {
    ...stateFilter,
    value: getFilterValue(clearExistingValues, stateFilter),
  };

  return state
    .slice(0, appliedFilterIndex)
    .concat(updatedFilter, state.slice(appliedFilterIndex + 1));
};

// types

export enum AppliedActionTypes {
  Toggle = 'pelo/filters/Toggle',
  ClearAll = 'pelo/filters/ClearAll',
  Initialize = 'pelo/filters/Initialize',
  Override = 'pelo/filters/Override',
  MultiSelectToggle = 'pelo/filters/MultiSelectToggle',
  SendAnalyticsBeforeClearAll = 'pelo/filters/SendAnalyticsBeforeClearAll',
}

export type FilterAction =
  | ReturnType<typeof toggle>
  | ReturnType<typeof clearAll>
  | ReturnType<typeof initializeFilters>
  | ReturnType<typeof overrideAppliedFilters>
  | ReturnType<typeof sendAnalyticsBeforeClearAll>
  | ReturnType<typeof multiSelectToggle>;

// action creators

export const toggle = (
  name: FilterName,
  value: string,
  displayName?: string,
  clearExistingValues?: boolean,
) => ({
  type: AppliedActionTypes.Toggle as AppliedActionTypes.Toggle,
  payload: { name, value, displayName, clearExistingValues } as ToggleSelection,
});

export const clearAll = (source: string) => ({
  type: AppliedActionTypes.ClearAll as AppliedActionTypes.ClearAll,
  payload: { source },
});

export const sendAnalyticsBeforeClearAll = (source: string) => ({
  type: AppliedActionTypes.SendAnalyticsBeforeClearAll as AppliedActionTypes.SendAnalyticsBeforeClearAll,
  payload: { source },
});

export const initializeFilters = () => ({
  type: AppliedActionTypes.Initialize as AppliedActionTypes.Initialize,
});

export const overrideAppliedFilters = (appliedFilters: AppliedFilter[]) => ({
  type: AppliedActionTypes.Override as AppliedActionTypes.Override,
  payload: { appliedFilters },
});

export const multiSelectToggle = () => ({
  type: AppliedActionTypes.MultiSelectToggle as AppliedActionTypes.MultiSelectToggle,
});

// reducer

export type AppliedState = AppliedFilter[];
const DEFAULT_STATE: AppliedState = [];

export const appliedReducer: Reducer<AppliedState> = (
  state = DEFAULT_STATE,
  action: FilterAction,
) => {
  switch (action.type) {
    case AppliedActionTypes.Toggle: {
      const newState = hasExactMatch(action.payload, state)
        ? remove(action.payload, state)
        : update(action.payload, state);
      return newState;
    }
    case AppliedActionTypes.Override:
      return action.payload.appliedFilters;
    case AppliedActionTypes.SendAnalyticsBeforeClearAll:
      return state;
    case AppliedActionTypes.ClearAll:
    case AppliedActionTypes.Initialize:
      return DEFAULT_STATE;
    case AppliedActionTypes.MultiSelectToggle:
      return state;
    default:
      return state;
  }
};
