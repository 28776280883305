import type { UserSelectorState } from '@peloton/auth';
import { isMe } from '@peloton/auth';
import { isDefined } from '@peloton/types';
import type { MemberSelectorState } from '@engage/members';
import { getMember, isFollowedByUser, isMemberPrivate } from '@engage/members';

export enum OverviewPageActionTypes {
  Load = 'pelo/page/overview/Load',
}

export type OverviewPageLoadAction = {
  type: OverviewPageActionTypes.Load;
  payload: {
    meOrUserId: string;
  };
};

export const loadOverviewPage = (meOrUserId: string) => ({
  type: OverviewPageActionTypes.Load,
  payload: {
    meOrUserId,
  },
});

export const shouldShowProfile = (
  state: UserSelectorState & MemberSelectorState,
  usernameOrId: any,
): boolean => {
  const member = getMember(state, usernameOrId);
  if (!isDefined(member)) {
    return false;
  }
  return (
    isMe(state, member.id) ||
    isFollowedByUser(member) ||
    !isMemberPrivate(state, member.id)
  );
};
