import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate3 } from '@engage/colors';

const dotsLeft = require('../layout/DarkLayout/images/dotsLeft.svg');
const dotsRight = require('../layout/DarkLayout/images/dotsRight.svg');

export default styled.section<{}>`
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-repeat: no-repeat;
  background-position: top left, bottom right;
  background-size: auto 300px;
  background-image: url(${dotsLeft}), url(${dotsRight});
  background-color: ${slate3};

  ${media.tablet`
    background-size: auto 400px;
  `}
`;
