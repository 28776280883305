/* eslint-disable */
import * as Types from '../../../types.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetNumberOfUnreadNotificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetNumberOfUnreadNotificationsQuery = { __typename: 'Query', getNumberOfUnreadNotifications: { __typename: 'ActivityFeedErrorResponse', message: string } | { __typename: 'UnreadNotifications', count: number } };


export const GetNumberOfUnreadNotificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNumberOfUnreadNotifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNumberOfUnreadNotifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UnreadNotifications"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ActivityFeedErrorResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]} as unknown as DocumentNode<GetNumberOfUnreadNotificationsQuery, GetNumberOfUnreadNotificationsQueryVariables>;