import { Device } from '@peloton/browser';

export enum App {
  IOS = 'IOS',
  Android = 'Android',
  FireTV = 'FireTV',
  AppleTV = 'AppleTV',
  AndroidTV = 'AndroidTV',
  Roku = 'Roku',
}

const map = {
  [Device.IOS]: App.IOS,
  [Device.Android]: App.Android,
};

export const toApp = (device: Device) => map[device];
