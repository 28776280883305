import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Redirect, Route } from 'react-router-dom';

const WithoutUserNavHandler: React.FC<
  React.PropsWithChildren<{ noUserNavRoutes: JSX.Element[] }>
> = ({ noUserNavRoutes }) => (
  <Switch>
    {...noUserNavRoutes}
    <Redirect to="/404" />
  </Switch>
);

const toWithoutUserNavRoutes = (noUserNavRoutes: JSX.Element[]) => [
  <Route
    key="noUserNav"
    component={(props: RouteComponentProps<{}>) => (
      <WithoutUserNavHandler noUserNavRoutes={noUserNavRoutes} {...props} />
    )}
  />,
];

export { toWithoutUserNavRoutes };
