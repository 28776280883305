import { APPS } from './meta';

type SubSubdomainComponents = {
  instance: string | undefined;
  app: string;
  features: string | undefined;
  api: string | undefined;
  urlApp?: string | undefined;
  isUATProdApp?: boolean | undefined; // Are we rendering an unpublished prod deploy in private UAT
};

/**
 * `instance` tests for a string of characters, possibly with
 * single dashes interspersed. Double dashes are not allowed.
 * Neither are dashes at the beginning or end.
 */
const instance = '[a-z0-9]+(?:-[a-z0-9]+)*';
const prodApps = APPS.map(app => `prod-${app}`);
const vercelApps = APPS.map(app => `vercel-${app}`);
const app = [...APPS, 'cra-www', ...prodApps, ...vercelApps].join('|');
const features = 'lit';
/**
 * `api` tests for a string of characters, possibly with single
 * or double dashes interspersed. Dashes at the beginning or end
 * are not allowed.
 */
const api = '[a-z0-9]+(?:--?[a-z0-9]+)*';

const subSubdomainRegex = `^(?:(${instance})--)?(${app})(?:--(${features}))?(?:--(${api}))?$`;

/**
 * **NOTE: This is an internal method. Do not use it outside @peloton/urls**
 *
 * Converts a sub-subdomain into its parts according to our conventions
 * for UAT domains.
 *
 * @param subSubdomain A sub-subdomain for a UAT website, or `undefined`
 */
export const toSubSubdomainComponents = (
  subSubdomain: string | undefined,
): SubSubdomainComponents | undefined => {
  const match = subSubdomain?.match(subSubdomainRegex);

  if (!match) {
    return undefined;
  }

  const [, matchInstance, matchApp, matchFeatures, matchAPI] = match;

  if (matchApp.startsWith('prod-')) {
    return {
      instance: matchInstance,
      app: matchApp.slice(5), // remove 'prod-' prefix
      features: matchFeatures,
      api: matchAPI,
      urlApp: matchApp,
      isUATProdApp: true,
    };
  }

  if (matchApp.startsWith('vercel-')) {
    return {
      instance: matchInstance,
      app: matchApp.slice(7), // remove 'vercel-' prefix
      features: matchFeatures,
      api: matchAPI,
      urlApp: matchApp,
    };
  }

  if (matchApp === 'cra-www') {
    return {
      instance: matchInstance,
      app: 'www',
      features: matchFeatures,
      api: matchAPI,
      urlApp: matchApp,
    };
  }

  return {
    instance: matchInstance,
    app: matchApp,
    features: matchFeatures,
    api: matchAPI,
  };
};
