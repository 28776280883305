import type { SagaIterator } from 'redux-saga';
import { select, put, spawn, call, getContext, take } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { track } from '@engage/analytics';
import {
  getWorkoutId,
  getStartTime,
  clearWorkoutStream,
  getStreamHistoryId,
  deleteStreamSaga,
  getPelotonId,
  getClassId,
} from '@engage/video';
import { WorkoutActionType, loadWorkout } from '@engage/workouts';
import { trackRefreshedClass } from '../analytics';
import { getAllRefreshAnalytics } from '../selectors';

export type ContinuedWorkout = Partial<{
  workoutId: string;
  startTime: number;
}>;

export const continueLiveWorkoutSaga = function* (pelotonId: string): unknown {
  return yield continueWorkoutSaga(shouldContinueLiveWorkout(pelotonId));
};

export const continueVODWorkoutSaga = function* (classId: string): unknown {
  return yield continueWorkoutSaga(shouldContinueVODWorkout(classId));
};

export const continueWorkoutSaga = function* (
  isContinuingWorkout: () => IterableIterator<any>,
): unknown {
  const client: Client = yield getContext(CLIENT_CONTEXT);
  const workoutId: ReturnType<typeof getWorkoutId> = yield select(getWorkoutId);

  if (workoutId) {
    yield put(loadWorkout(workoutId));
    yield take(WorkoutActionType.RequestSuccess);
  }

  if (yield isContinuingWorkout()) {
    const startTime = yield select(getStartTime);
    yield call(releasePreviousStreamSaga, client);
    yield spawn(trackContinueWorkoutSaga);
    const continuedWorkout: ContinuedWorkout = { workoutId, startTime };
    return continuedWorkout;
  } else {
    yield put(clearWorkoutStream());
    return {};
  }
};

export const shouldContinueLiveWorkout = (requestedPelotonId: string) => {
  return function* (): IterableIterator<any> {
    const previouslyPlayedPelotonId = yield select(getPelotonId);
    return previouslyPlayedPelotonId === requestedPelotonId;
  };
};

export const shouldContinueVODWorkout = (requestedClassId: string) => {
  return function* (): IterableIterator<any> {
    const previouslyPlayedClassId = yield select(getClassId);
    return previouslyPlayedClassId === requestedClassId;
  };
};

export const releasePreviousStreamSaga = function* (client: Client): SagaIterator {
  const streamHistoryId = yield select(getStreamHistoryId);
  if (streamHistoryId) {
    yield call(deleteStreamSaga, client, streamHistoryId);
  }
};

export const trackContinueWorkoutSaga = function* () {
  const { classInfo, timeInfo } = yield select(getAllRefreshAnalytics);
  yield put(track(trackRefreshedClass(classInfo, timeInfo)));
};
