// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const ModalLabel = {
  ClassDetails: 'classDetails',
  ClassLanguages: 'classLanguages',
  EarlyExitSurvey: 'earlyExitSurvey',
  FindFacebookFriends: 'findFacebookFriends',
  Followers: 'followers',
  Following: 'following',
  InviteFriends: 'inviteFriends',
  LiveClassDetails: 'liveClassDetails',
  MembersSearch: 'membersSearch',
  PelotonApps: 'pelotonApps',
  RoutesMapInfo: 'routesMapInfo',
  ScheduledEvents: 'scheduledEvents',
  Scheduling: 'scheduling',
  SelectLanguage: 'selectLanguage',
  StackedClasses: 'stackedClasses',
  Share: 'Share',
  Tags: 'tags',
  SelfModeration: 'selfModeration',
} as const;
