import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { BreakpointEither } from '@peloton/responsive';
import { white, slate1, slate2, gray5 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { DarkButton3Medium, DarkButton3Small } from '@members/buttons';
import { TagsCopy, Tags } from '@members/copy';
import { Share } from '@members/icons';
import { ShareTooltipSource, useShareTooltip } from '@members/share-modal';
import { useTrackShareTag } from './analytics';
import { Position } from './useTooltip';

type BaseProps = {
  Button: React.ComponentType<
    React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>>
  >;
  Icon: React.ComponentType<React.PropsWithChildren<unknown>>;
};

export const ShareButtonBase: React.FC<React.PropsWithChildren<BaseProps>> = ({
  Button,
  Icon,
  children,
  ...props
}) => {
  return (
    <Button {...props}>
      <Icon />
      <Spacer />
      {children}
    </Button>
  );
};

export const ShareButtonMedium: React.FC<
  React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>
> = props => (
  <ShareButtonBase
    Button={StyledDarkButton3Medium}
    Icon={() => <StyledShareMedium isDisabled={props.disabled} />}
    {...props}
  />
);

export const ShareButtonSmall: React.FC<
  React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>
> = props => (
  <ShareButtonBase
    Button={StyledDarkButton3Small}
    Icon={() => <StyledShareSmall isDisabled={props.disabled} />}
    {...props}
  />
);

export const onButtonClick = (
  active: boolean,
  trackShareTag: (source: ShareTooltipSource) => void,
  toggle: () => void,
) => {
  if (!active) {
    trackShareTag(ShareTooltipSource.TagDetailShare);
  }
  toggle();
};

export const ShareButton: React.FC<React.PropsWithChildren<{ isDisabled: boolean }>> = ({
  isDisabled,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { toggle, isExpanded: active } = useShareTooltip(containerRef, Position.Top);
  const trackShareTag = useTrackShareTag();
  const onClick = () => onButtonClick(active, trackShareTag, toggle);

  return (
    <Container ref={containerRef}>
      <BreakpointEither breakpoint="tablet">
        <ShareButtonSmall
          disabled={isDisabled}
          onClick={onClick}
          className={classNames({ active })}
          data-test-id="shareTagButtonSmall"
        >
          <TagsCopy id={Tags.ShareCta} />
        </ShareButtonSmall>
        <ShareButtonMedium
          disabled={isDisabled}
          onClick={onClick}
          className={classNames({ active })}
          data-test-id="shareTagButtonMedium"
        >
          <TagsCopy id={Tags.ShareCta} />
        </ShareButtonMedium>
      </BreakpointEither>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Spacer = styled.div`
  width: ${spaces.xSmall}px;
`;

export const StyledDarkButton3Medium = styled(DarkButton3Medium)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  height: 40px;
  min-width: 160px;
  background-color: transparent;

  &.active,
  &:not([data-focus-method='touch']):hover {
    background-color: ${white};
    color: ${slate2};
    border-color: ${slate1};
  }
`;

const StyledShareMedium = styled(Share)<{ isDisabled: boolean | undefined }>`
  width: ${spaces.large}px;
  height: ${spaces.large}px;
  margin-top: ${spaces.tiny - 1}px;

  g {
    ${({ isDisabled }) => (isDisabled ? `stroke: ${gray5}` : '')};
  }

  ${StyledDarkButton3Medium}:not([data-focus-method='touch']):hover & g,
  ${StyledDarkButton3Medium}.active & g {
    stroke: ${({ isDisabled }) => (isDisabled ? gray5 : slate1)};
  }
`;

const StyledDarkButton3Small = styled(DarkButton3Small)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  background-color: transparent;

  &.active,
  &:not([data-focus-method='touch']):hover {
    background-color: ${white};
    color: ${slate2};
    border-color: ${slate1};
  }
`;

const StyledShareSmall = styled(Share)<{ isDisabled: boolean | undefined }>`
  width: ${spaces.medium}px;
  height: ${spaces.medium}px;
  margin-top: ${spaces.tiny - 1}px;

  g {
    ${({ isDisabled }) => (isDisabled ? `stroke: ${gray5}` : '')};
  }

  ${StyledDarkButton3Small}:not([data-focus-method='touch']):hover & g,
  ${StyledDarkButton3Small}.active & g {
    stroke: ${({ isDisabled }) => (isDisabled ? gray5 : slate1)};
  }
`;
