import React from 'react';
import styled from 'styled-components';
import { usePaymentMethods } from '@peloton/payment-methods';
import { ieHideScroll, media } from '@peloton/styles';
import { slate3, tiersGradient } from '@engage/colors';
import { spaces } from '@engage/styles/spaces';
import { body16, title24, title20 } from '@engage/typography';
import { largeButtonStyles, button1Styles } from '@members/buttons';
import { TieringCopy, Tiering, useTiering } from '@members/copy';
import { useIsBreakpointLessThan } from '@members/responsive';
import { useUpgradeMembershipPath } from '../hooks/useUpgradeMembershipPath';

import {
  CyclingIcon,
  StrengthIcon,
  StarSquareIcon,
  MetricsIcon,
} from '../icons/UpgradeModalIcons';
import type { Modal } from '../models';
import TieringModalView from './TieringModalView';

const UpgradeModal: React.FC<
  React.PropsWithChildren<{
    modal: Modal;
    setModal: React.Dispatch<React.SetStateAction<Modal>>;
  }>
> = ({ modal, setModal }) => {
  const {
    actions: { loadPaymentMethods },
    paymentMethods: { benefitHub },
  } = usePaymentMethods();

  const accountsMembershipPath = useUpgradeMembershipPath(Boolean(benefitHub));

  const closeModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const isMobile = useIsBreakpointLessThan('tablet');

  React.useEffect(() => {
    loadPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TieringModalView
      modal={modal}
      setModal={setModal}
      modalDescriptionChild={<ModalFeatureList />}
      ctaChildren={
        <CTALink
          href={accountsMembershipPath}
          target="_blank"
          rel="noopener"
          onClick={closeModal}
        >
          <TieringCopy id={Tiering.Upgrade} />
        </CTALink>
      }
      style={isMobile ? styleOverrides : {}}
    />
  );
};

const ModalFeatureList: React.FC<React.PropsWithChildren<{}>> = () => {
  return (
    <StyledModalFeatureList>
      <ModalHeader>{useTiering(Tiering.UpgradeToUnlockEntireExperience)}</ModalHeader>

      <ModalFeaturesContainer>
        <CyclingIcon style={{ height: '20px', width: '20px', color: slate3 }} />
        <FeatureText>
          <TieringCopy id={Tiering.UpgradeUpsellCycling} />
        </FeatureText>
        <StrengthIcon style={{ height: '20px', width: '20px', color: slate3 }} />
        <FeatureText>
          <TieringCopy id={Tiering.UpgradeUpsellStrength} />
        </FeatureText>
        <StarSquareIcon style={{ height: '20px', width: '20px', color: slate3 }} />
        <FeatureText>
          <TieringCopy id={Tiering.UpgradeUpsellExclusive} />
        </FeatureText>
        <MetricsIcon style={{ height: '20px', width: '20px', color: slate3 }} />
        <FeatureText>
          <TieringCopy id={Tiering.UpgradeUpsellMetrics} />
        </FeatureText>
      </ModalFeaturesContainer>
    </StyledModalFeatureList>
  );
};

const StyledModalFeatureList = styled.div`
  height: 343px;
  overflow: scroll;
  margin-top: ${spaces.xxxLarge}px;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}

  ${media.tablet`
    height: auto;`}
`;

const ModalFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-row-gap: 26px;
  grid-column-gap: 14px;
  align-items: top;
  margin-top: ${spaces.xxxLarge}px;
  margin-bottom: ${spaces.xLarge}px;
  & > *:last-child {
    padding-bottom: 0px;
  }
`;

const FeatureText = styled.p`
  ${body16}
  color: ${slate3};
  text-align: left;
`;

const CTALink = styled.a`
  ${button1Styles}
  ${largeButtonStyles}
  ${tiersGradient}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  ${media.tablet`
    width: 320px;`}
`;

const styleOverrides = {
  content: {
    height: '480px',
    maxHeight: '480px',
    overflow: 'hidden',
  },
};

const ModalHeader = styled.p`
  ${title20}
  color: ${slate3};
  ${media.tablet`
  ${title24}`}
`;

export default UpgradeModal;
