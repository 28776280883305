import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import {
  REMOTE_ACTIVATION_ROUTE,
  ADD_ACCOUNT_ROUTE,
  REMOTE_ACTIVATION_PROFILE_ROUTE,
} from './urls';

const LazyRemoteActivationPage = lazyLoader(
  () =>
    import(
      './RemoteActivationPageWizard' /* webpackChunkName: "RemoteActivationPageWizard" */
    ),
);

const LazyAddAccountPage = lazyLoader(
  () => import('./AddAccountPage' /* webpackChunkName: "AddAccountPage" */),
);

const LazyRemoteDeviceProfileSetupPage = lazyLoader(
  () =>
    import(
      './RemoteDeviceProfileSetupPage' /* webpackChunkName: "RemoteDeviceProfileSetupPage" */
    ),
);

const Routes = [
  <Route
    key={REMOTE_ACTIVATION_ROUTE}
    path={REMOTE_ACTIVATION_ROUTE}
    component={LazyRemoteActivationPage}
  />,
  <Route
    key={ADD_ACCOUNT_ROUTE}
    path={ADD_ACCOUNT_ROUTE}
    component={LazyAddAccountPage}
  />,
  <Route
    key={REMOTE_ACTIVATION_PROFILE_ROUTE}
    path={REMOTE_ACTIVATION_PROFILE_ROUTE}
    component={LazyRemoteDeviceProfileSetupPage}
  />,
];

export default Routes;
