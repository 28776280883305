import React from 'react';
import styled, { css } from 'styled-components';
import type { PropsOf } from '@peloton/react';
import { defaultTransition, hover, media } from '@peloton/styles';
import { gray5, slate1, gray2 } from '@engage/colors';
import { RelationshipCategory } from '@engage/members/models';
import { label12Caps } from '@engage/typography';
import { button3Styles, smallButtonStyles, button4Styles } from '@members/buttons';
import { MembersCopy, Members } from '@members/copy';
import { CheckIcon } from '@members/icons';
import { PlusIcon } from './icons';

const FollowButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      onFollowClick,
      meToUserRelationship,
      FollowComponent = Follow,
      FollowingComponent = UpdatedFollowing,
      RequestedComponent = Requested,
      hideIcons,
      ...htmlProps
    },
    ref,
  ) => {
    switch (meToUserRelationship) {
      case RelationshipCategory.Self:
        return null;
      case RelationshipCategory.Pending:
        return (
          <button
            ref={ref}
            onClick={onFollowClick}
            {...htmlProps}
            data-test-id="requestedButton"
          >
            <RequestedComponent />
          </button>
        );
      case RelationshipCategory.Following:
        return (
          <button
            ref={ref}
            onClick={onFollowClick}
            {...htmlProps}
            data-test-id="unfollowButton"
          >
            <FollowingComponent hideIcons={hideIcons} />
          </button>
        );
      default:
        return (
          <button
            ref={ref}
            onClick={onFollowClick}
            {...htmlProps}
            data-test-id="followButton"
          >
            <FollowComponent hideIcons={hideIcons} />
          </button>
        );
    }
  },
);

FollowButton.displayName = 'FollowButton';

const Follow: React.FC<React.PropsWithChildren<{ hideIcons?: boolean }>> = ({
  hideIcons,
}) => (
  <FollowWrapper>
    {!hideIcons && <StyledPlusIcon />}
    <ButtonText>
      <MembersCopy id={Members.SearchFollowCta} />
    </ButtonText>
  </FollowWrapper>
);

const UpdatedFollowing: React.FC<React.PropsWithChildren<{ hideIcons?: boolean }>> = ({
  hideIcons,
}) => (
  <FollowingWrapper>
    {!hideIcons && <StyledCheckIcon />}
    <ButtonText>
      <MembersCopy id={Members.Following} />
    </ButtonText>
  </FollowingWrapper>
);

const Requested: React.FC<React.PropsWithChildren<unknown>> = () => (
  <RequestedWrapper>
    <ButtonText>
      <MembersCopy id={Members.RequestedToFollow} />
    </ButtonText>
  </RequestedWrapper>
);

const iconStyles = css`
  display: none;
  ${media.tablet`
    display: inline;
    height: 12px;
    width: 12px;
    margin-right: 5px;
    vertical-align: bottom;
    ${defaultTransition('fill')}
  `}
`;

const StyledPlusIcon = styled(PlusIcon)`
  ${iconStyles}
`;

const StyledCheckIcon = styled(CheckIcon)`
  ${iconStyles}
`;

const ButtonText = styled.span`
  ${label12Caps}
`;

const buttonCss = css`
  fill: ${slate1};
  min-width: 100px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${hover`
    ${StyledPlusIcon}, ${StyledCheckIcon} {
      fill: white;
    }
  `}
  ${media.tablet`
    min-width: 120px;
  `}
`;

const FollowWrapper = styled.div`
  ${button3Styles}
  ${smallButtonStyles}
  ${buttonCss}
`;

const FollowingWrapper = styled.div`
  ${button4Styles}
  ${smallButtonStyles}
  ${buttonCss}
`;

const RequestedWrapper = styled.div`
  ${button3Styles}
  ${smallButtonStyles}
  ${buttonCss}
  background-color: ${gray2};
  border: none;
  color: ${gray5};
  fill: ${gray5};
`;

export type Props = {
  meToUserRelationship: RelationshipCategory;
  FollowComponent?: React.ComponentType<React.PropsWithChildren<PropsOf<typeof Follow>>>;
  FollowingComponent?: React.ComponentType<
    React.PropsWithChildren<PropsOf<typeof Follow>>
  >;
  RequestedComponent?: React.ComponentType<
    React.PropsWithChildren<PropsOf<typeof Follow>>
  >;
  onFollowClick(
    e: React.MouseEvent<HTMLButtonElement | SVGElement | HTMLDivElement>,
  ): void;
  hideIcons?: boolean;
};

export default FollowButton;
