import { useMemo } from 'react';
import useSWR from 'swr';
import { isDefined } from '@peloton/types';
import { ok, error, pending } from '@engage/result';
import { AuthFetchers, AuthFetchersNoAuthHeader } from '@members/data-fetch';

export const useGetSession = () => {
  // First check if there is a valid session
  const { data, error: err } = useSWR(...AuthFetchers.GetSession({}));

  // When `data` or `error` is defined, the request is done
  return useMemo(() => {
    if (isDefined(data)) {
      return ok(data);
    } else if (isDefined(err)) {
      return error(err);
    } else {
      return pending;
    }
  }, [data, err]);
};

export const useGetSessionNoAuthHeader = () => {
  // First check if there is a valid session
  const { data, error: err } = useSWR(...AuthFetchersNoAuthHeader.GetSession({}));
  // When `data` or `error` is defined, the request is done
  return useMemo(() => {
    if (isDefined(data)) {
      return ok(data);
    } else if (isDefined(err)) {
      return error(err);
    } else {
      return pending;
    }
  }, [data, err]);
};
