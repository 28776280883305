import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import type { CopyHook, CopyValues } from '@peloton/copy-generic';
import { isNil } from '@peloton/helpers/isNil';
import { media, hoverTransition } from '@peloton/styles';
import { slate1, white, slate3, gray1 } from '@engage/colors';
import { If } from '@engage/conditional-render';
import { useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label18Caps, label14, label12, label14Caps } from '@engage/typography';
import { useCta, Cta, TagsCopy, Tags } from '@members/copy';
import { Chevron, Orientation } from '@members/icons';
import { CloseButton, useCloseHandler } from '@members/modal';
import { useIsBreakpointLessThan } from '@members/responsive';
import { HEADER_MOBILE_HEIGHT, HEADER_TABLET_HEIGHT } from '../MainView/ScrollContext';
import { getTagViewFromLocation } from '../selectors';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';

type ButtonProps = {
  content?: string;
  props?: { onClick: () => void };
};

type Localizable<T extends string> = {
  renderer: CopyHook<T>;
  id: T;
  variables?: CopyValues;
};

export type HeaderProps = React.HTMLProps<HTMLDivElement> &
  React.HTMLProps<HTMLButtonElement> & {
    headerText?: Localizable<any>;
    username?: string;
    button?: ButtonProps;
    overlayHeader?: boolean;
  };

const Close: React.FC<React.PropsWithChildren<unknown>> = () => {
  const closeHandler = useCloseHandler();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <CloseButton
        aria-label={useCta(Cta.ModalClose)}
        data-test-id="closeModalButton"
        onClick={closeHandler}
        showMobileCloseButton={true}
        style={{
          position: 'sticky',
          fill: gray1,
          width: 'inherit',
        }}
      />
    </div>
  );
};

const BackButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  content,
  props,
}) => {
  const { showBackButton } = React.useContext(TagsContext);
  const isMobile = useIsBreakpointLessThan('tablet');

  return (
    <div>
      <StyledButton
        {...props}
        showBackButton={showBackButton}
        data-test-id="backButton"
        disabled={!showBackButton}
      >
        <StyledChevron orientation={Orientation.Left} />
        <If condition={!isMobile}>
          <ButtonText>{content}</ButtonText>
        </If>
      </StyledButton>
    </div>
  );
};

const MyTagsButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setView } = React.useContext(TagsContext);
  return (
    <MyTagsButtonWrapper>
      <StyledMyTagsButton
        data-test-id="myTagsButton"
        onClick={() => setView(TagModalView.MyTags)}
      >
        <ButtonText>
          <TagsCopy id={Tags.MyTags} />
        </ButtonText>
      </StyledMyTagsButton>
    </MyTagsButtonWrapper>
  );
};

const SharedHeader: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  button,
  headerText,
  username,
  overlayHeader = false,
}) => {
  const isMobile = useIsBreakpointLessThan('tablet');
  const view = useReduxState(getTagViewFromLocation);

  return (
    <HeaderContainer
      data-test-id="tagsModalHeader"
      className={classNames({ overlayHeader })}
      {...button}
    >
      <If condition={!isNil(button)}>
        <BackButton {...button} />
      </If>
      <TextContainer>
        <If condition={!isNil(headerText)}>
          <HeaderText data-test-id="modalHeaderText">
            {headerText?.renderer(headerText.id, headerText.variables)}
          </HeaderText>
        </If>
        <If condition={!isNil(username)}>
          <UsernameText data-test-id="modalUsernameText" className="fs-exclude">
            {username}
          </UsernameText>
        </If>
      </TextContainer>
      <If condition={isMobile && overlayHeader}>
        <Close />
      </If>
      <If condition={!isMobile && view === TagModalView.TagDetails}>
        <MyTagsButton />
      </If>
    </HeaderContainer>
  );
};

export { BackButton, MyTagsButton, Close, SharedHeader };

const HeaderContainer = styled.div<{ content?: string }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ content }: ButtonProps) =>
    content ? '1fr minmax(min-content, auto) 1fr' : '1fr'};
  align-items: center;
  background: ${white};
  height: ${HEADER_MOBILE_HEIGHT}px;
  min-height: ${HEADER_MOBILE_HEIGHT}px;
  z-index: 4;
  ${media.tablet`
    height: ${HEADER_TABLET_HEIGHT}px;
    min-height: ${HEADER_TABLET_HEIGHT}px;
    grid-template-columns: ${({ content }: ButtonProps) =>
      content ? '1fr minmax(min-content, auto) 1fr' : '1fr'};
  `}

  &.overlayHeader {
    background: transparent;
    border-bottom: none;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.h1`
  ${label14Caps}
  color: ${slate3};

  ${media.tablet`
     ${label18Caps}
  `}
`;
HeaderText.displayName = 'HeaderText';

const UsernameText = styled.span`
  ${label12}
  color: ${slate1};
  margin-top: ${spaces.tiny}px;

  ${media.tablet`
    ${label12}
  `}
`;
UsernameText.displayName = 'UsernameText';

const tagsHeaderButtonStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: ${spaces.large}px;
  color: ${slate1};
  fill: ${slate1};

  ${media.tablet`
    height: 60px;
  `}

  ${hoverTransition({
    property: 'color, fill',
    to: slate3,
  })}

  .overlayHeader & {
    opacity: 0.8;
    fill: ${white};
    color: ${white};

    ${hoverTransition({
      property: 'opacity',
      to: '1',
    })}
  }
`;

const StyledButton = styled.button<{ showBackButton: boolean }>`
  visibility: ${({ showBackButton }) => (showBackButton ? 'visible' : 'hidden')};
  justify-content: flex-start;
  margin-left: ${spaces.xSmall}px;
  ${tagsHeaderButtonStyle}
`;
StyledButton.displayName = 'StyledButton';

const StyledMyTagsButton = styled.button`
  ${tagsHeaderButtonStyle}
  ${label14};
  margin-right: ${spaces.small}px;
`;

const MyTagsButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledChevron = styled(Chevron)`
  width: ${spaces.large}px;
  height: ${spaces.xSmall}px;
`;

const ButtonText = styled.span`
  ${label14};
  margin-bottom: ${spaces.tiny}px;
`;
ButtonText.displayName = 'ButtonText';
