import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoader from '@members/lazy-loader/LazyLoader';
import { SHAREABLE_WORKOUTS_ROUTES } from './urls';

const ShareWorkoutRoutes = [
  <Route
    key={SHAREABLE_WORKOUTS_ROUTES}
    exact={true}
    path={SHAREABLE_WORKOUTS_ROUTES}
    component={lazyLoader(
      () =>
        import('./ShareableWorkoutsPage' /* webpackChunkName: "ShareableWorkoutsPage" */),
    )}
  />,
];

export default ShareWorkoutRoutes;
