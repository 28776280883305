import { useEffect } from 'react';
import { useReduxAction } from '@engage/redux';
import type { PendingResult } from '@engage/result';
import { isOk } from '@engage/result';
import { mergeTogglesFromOptimizely } from './state/actions';

export const useFeatureToggles = (
  enabledFeatures: PendingResult<string[], undefined>,
) => {
  const doUpdate = useReduxAction(mergeTogglesFromOptimizely);
  useEffect(() => {
    if (isOk(enabledFeatures)) {
      doUpdate(enabledFeatures.value);
    }
  }, [enabledFeatures]);
};
