import React from 'react';

const CancelIcon: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z m.67-8l2.394-2.402a.463.463 0 0 0-.009-.665.488.488 0 0 0-.679.009L8 7.327 5.624 4.942a.487.487 0 0 0-.679-.009.463.463 0 0 0-.009.665L7.33 8l-2.393 2.402a.463.463 0 0 0 .01.665.484.484 0 0 0 .678-.01L8 8.674l2.376 2.385a.485.485 0 0 0 .68.009.463.463 0 0 0 .008-.665L8.67 8z"
      fillRule="evenodd"
    />
  </svg>
);

export default CancelIcon;
