import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { getUserId } from '@peloton/auth';
import { isDefined } from '@peloton/types';
import { putUser } from '@engage/api-v2';
import { logError } from '@engage/error-reporting/logError';
import { useReduxState } from '@engage/redux';
import { track, toBaseProperties } from '@members/analytics';
import {
  UsersFetchers,
  engageApi,
  DigitalSubscriptionsFetchers,
} from '@members/data-fetch';
import { useDecoratedSWR } from '@members/data-fetch/useDecoratedSWR';
import { useIsFreemiumMember } from '@members/tiering/hooks/useIsFreemiumMember';

const useTrackFirstFreemiumSession = () => {
  const isFreemiumMember = useIsFreemiumMember();
  const userId = useReduxState(getUserId);

  const { data: user, mutate } = useSWR(
    ...UsersFetchers.GetUser({
      id: userId,
      pelotonPlatform: 'web',
    }),
  );

  const isOnboardedToFirstFreemiumSession = user?.isOnboarded?.firstAnalyticsSession;

  const location = useLocation();
  // all urls in pg-profile-setup begin with '/complete', so don't count these pages as part of the first session
  const isSettingUpProfile = location.pathname.includes('/complete');

  const { data: isFreeTrialEligibleResponse } = useDecoratedSWR(
    ...DigitalSubscriptionsFetchers.GetFreeTrialEligibility({}),
  );

  const hasFreeTrial = isFreeTrialEligibleResponse?.isEligibleForFreeTrial;

  const canSetFlag = isDefined(user) && isDefined(isFreeTrialEligibleResponse);

  useEffect(() => {
    if (
      canSetFlag &&
      isFreemiumMember &&
      !isOnboardedToFirstFreemiumSession &&
      !isSettingUpProfile &&
      userId
    ) {
      const joinedFreeTierData = {
        event: 'Joined Free Tier',
        ['Free Trial Available']: hasFreeTrial,
        ...toBaseProperties(),
      };

      track(joinedFreeTierData);
      updateUser(userId, mutate);
    }
  }, [
    hasFreeTrial,
    isFreemiumMember,
    isOnboardedToFirstFreemiumSession,
    isSettingUpProfile,
    canSetFlag,
    userId,
    mutate,
  ]);
};

const updateUser = async (userId: string, mutate: Function) => {
  try {
    await putUser(engageApi, userId as string, 'web', {
      onboardedToFirstAnalyticsSession: true,
    });
    mutate();
  } catch (err) {
    logError(err, 'updateUser');
  }
};

export default useTrackFirstFreemiumSession;
