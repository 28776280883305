import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { ME } from '@engage/members';

const toProfileUrl = (id: string) => (id === ME ? `api/me` : `api/user/${id}`);

export const fetchMember = (api: Client, id: string): Promise<FetchedMember> =>
  api.get(toProfileUrl(id)).then(pipeData(x => x));

type FetchedMember = any;
