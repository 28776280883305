import type { Reducer } from 'redux';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import type { ProgramClassGroup } from '@engage/class-groups';

export const PROGRAMS_NAMESPACE = 'programs';

type ProgramInfo = {
  programProgressId: string;
  programId: string;
  programClassGroup: ProgramClassGroup;
  programProgressStartDate: string;
  programProgressEndDate: string;
  programWeekNumber: number;
};

type ProgramsState = {
  programInfo?: ProgramInfo;
  isCompleted?: boolean;
  pushLocation?: string;
  currProgramClassInfo?: {
    programClassId?: string;
    isTaken: boolean;
    isLastClass: boolean;
    programClassNumber: number;
  };
};

// Reducer
export const programsReducer: Reducer<ProgramsState, ProgramAction> = (
  state: ProgramsState = {},
  action,
) => {
  switch (action.type) {
    case ProgramActionType.SetProgramInfo: {
      const setProgramInfoAction = action as ReturnType<typeof setProgramInfo>;
      return {
        programInfo: setProgramInfoAction.payload.programInfo,
        isCompleted: setProgramInfoAction.payload.isCompleted,
      };
    }
    case ProgramActionType.SetCurrClassInfo: {
      const setCurrClassInfoAction = action as ReturnType<typeof setCurrClassInfo>;
      const {
        isTaken,
        programClassNumber,
        isLastClass,
        programClassId,
      } = setCurrClassInfoAction.payload;
      const { isCompleted } = state;
      return {
        ...state,
        pushLocation: isCompleted
          ? `/programs/${state?.programInfo?.programId}/completed/${state?.programInfo?.programProgressId}`
          : `/programs/${state?.programInfo?.programId}`,
        currProgramClassInfo: {
          isTaken,
          programClassNumber,
          isLastClass,
          // don't need to send off programClassId for mutation needs if
          // we don't want to give credit if the class is already taken
          programClassId: isTaken ? undefined : programClassId,
        },
      };
    }
    case ProgramActionType.ClearProgramState:
      return {};
    case ProgramActionType.ClearCurrProgramClassInfo:
      return {
        ...state,
        currProgramClassInfo: undefined,
      };
    case ProgramActionType.SetProgramPushLocation: {
      const setProgramPushLocationAction = action as ReturnType<
        typeof setProgramPushLocation
      >;
      return {
        ...state,
        pushLocation: setProgramPushLocationAction.payload.pushLocation,
      };
    }
    default:
      return state;
  }
};

// Action Creators
export const setProgramInfo = (programInfo: ProgramInfo, isCompleted: boolean) => ({
  type: ProgramActionType.SetProgramInfo,
  payload: { programInfo, isCompleted },
});

type SetCurrClassInfoParams = {
  isTaken: boolean;
  isLastClass: boolean;
  programClassNumber: number;
  programClassId: string;
};

export const setCurrClassInfo = (params: SetCurrClassInfoParams) => ({
  type: ProgramActionType.SetCurrClassInfo,
  payload: {
    ...params,
  },
});

export const clearCurrClassInfo = () => ({
  type: ProgramActionType.ClearCurrProgramClassInfo,
});

export const clearProgramState = () => ({
  type: ProgramActionType.ClearProgramState,
});

export const setProgramPushLocation = (pushLocation: string) => ({
  type: ProgramActionType.SetProgramPushLocation,
  payload: { pushLocation },
});

export type ProgramAction =
  | ReturnType<typeof setProgramInfo>
  | ReturnType<typeof setCurrClassInfo>
  | ReturnType<typeof setProgramPushLocation>;

// Actions
export enum ProgramActionType {
  SetProgramInfo = 'programs/setProgramInfo',
  SetCurrClassInfo = 'programs/setCurrClassInfo',
  ClearCurrProgramClassInfo = 'programs/clearCurrProgramClassInfo',
  ClearProgramState = 'programs/clearProgramState',
  SetProgramPushLocation = 'programs/setProgramPushLocation',
}

// Selectors
export const getProgramInfo = (state: ProgramSelectorState) =>
  state.programs?.programInfo;
export const getCurrProgramClassInfo = (state: ProgramSelectorState) =>
  state.programs?.currProgramClassInfo;
export const getProgramsVideoReturnLocation = (state: ProgramSelectorState) =>
  state.programs?.pushLocation;
export type ProgramSelectorState = FetcherSelectorState & {
  programs: ReturnType<typeof programsReducer>;
};
