import lazyLoader from '@members/lazy-loader/LazyLoader';

export default {
  route: {
    path: '/follow/:userId',
    component: lazyLoader(
      () => import('./Follow' /* webpackChunkName: "OverviewPage" */),
    ),
  },
};
