/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Peloton } from '../../../api-v2-models/generated/Peloton';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Update Peloton
 * Update fields for an on-demand peloton
 * @param id The peloton id
 * @param requestBody
 * @returns Peloton Successful response
 * @throws ApiError
 */
export const updatePeloton = (
    api: BaseAPI,
    id: string,
    requestBody?: {
        /**
         * Epoch time representing when user skips intro on a workout
         */
        skipIntroTime?: number,
    },
    options: any = {},
): AxiosPromise<Peloton> => {
    const localVarPath = `/api/peloton/${id}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create Peloton
 * Create a Peloton for a specified ride
 * @param requestBody
 * @returns Peloton Successful response
 * @throws ApiError
 */
export const createPeloton = (
    api: BaseAPI,
    requestBody?: {
        /**
         * ID for the ride this peloton is created in
         */
        rideId?: string,
        showLeaderboard?: boolean,
        /**
         * Identifier to check for ride_id and type to create specified peloton
         */
        joinToken?: string,
    },
    options: any = {},
): AxiosPromise<Peloton> => {
    const localVarPath = `/api/peloton`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

