import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

export const Link: React.FC<React.PropsWithChildren<Props>> = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke="#697180"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
      d="M15 7h3a5 5 0 010 10h-3m-6 0H6A5 5 0 116 7h3m-1 5h8"
    />
  </svg>
);
