import type { SagaIterator } from 'redux-saga';
import { call, getContext, select, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { validateResetHash, resetForgotPassword } from '@peloton/auth';
import { redirect } from '@peloton/navigation';
import { getLocation } from '@peloton/redux';
import { MessageSource } from '../messageSource';
import {
  SHOW_MESSAGE_PARAM,
  EMAIL_PARAM,
  getEmailFromLocation,
  getRedirectActionFromLocation,
} from '../selectors';
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, REMOTE_RESET_SUCCESS_ROUTE } from '../urls';

const QUERY_PARAM = `?${SHOW_MESSAGE_PARAM}=${MessageSource.ResetPassword}`;
const REMOTE_ACTIVATION_ACTION = 'remote_activation';

export const resetTokenValidateSaga = function* (
  client: Client,
  action: ReturnType<typeof checkTokenValidity>,
): SagaIterator {
  const loc = yield select(getLocation);
  const token = loc.pathname.split('/')[2];
  const email = yield select(getEmailFromLocation);
  const isTokenValid = yield call(validateResetHash, client, token);
  if (!isTokenValid) {
    yield call(
      redirect,
      FORGOT_PASSWORD_ROUTE + `${QUERY_PARAM}&${EMAIL_PARAM}=${email}`,
    );
  }
};

export const resetPasswordSubmitSaga = function* (
  client: Client,
  action: ReturnType<typeof submitResetPasswordForm>,
): SagaIterator {
  try {
    yield call(resetTokenValidateSaga, client, checkTokenValidity());
    yield call(
      resetForgotPassword,
      client,
      action.payload.token!,
      action.payload.newPassword!,
    );
    const redirectAction = yield select(getRedirectActionFromLocation);
    if (redirectAction === REMOTE_ACTIVATION_ACTION) {
      yield call(redirect, REMOTE_RESET_SUCCESS_ROUTE);
    } else {
      yield call(redirect, LOGIN_ROUTE + QUERY_PARAM);
    }
  } catch (e) {
    if (action.onError) {
      yield call(action.onError);
    }
  }
};

export const resetPasswordFormSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(ResetPasswordActionType.Submit, resetPasswordSubmitSaga, client);
  yield takeEvery(ResetPasswordActionType.Validate, resetTokenValidateSaga, client);
};

enum ResetPasswordActionType {
  Submit = 'pelo/@members/pg-auth/ResetPassword/SUBMIT',
  Validate = 'pelo/@members/pg-auth/ResetPassword/VALIDATE',
}

export const submitResetPasswordForm = (
  payload: Partial<ResetPassword>,
  callbacks: Partial<ResetPasswordFormCallbacks> = {},
) => ({
  type: ResetPasswordActionType.Submit as ResetPasswordActionType.Submit,
  payload,
  ...callbacks,
});

export const checkTokenValidity = () => ({
  type: ResetPasswordActionType.Validate as ResetPasswordActionType.Validate,
});

type ResetPasswordFormCallbacks = {
  onError(error?: Error): void;
};

type ResetPassword = {
  newPassword: string;
  confirmPassword: string;
  token: string | undefined;
};
