import type { SagaIterator } from 'redux-saga';
import { call, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { logError } from '@engage/error-reporting/logError';
import { RelationshipsActionType } from '@engage/me';
import { isMembersSuccessActionForMember, ME } from '@engage/members';
import type { OverviewPageLoadAction } from '@engage/overview';
import {
  fetchActiveDayHistory,
  loadActiveDayHistorySuccess,
  FriendsActionType,
  OverviewPageActionTypes,
  shouldShowProfile,
} from '@engage/overview';
import type { ActiveDayHistory } from '@engage/overview-ui';
import {
  approveFollowerSaga,
  loadFriendsSaga,
  removeFollowerSaga,
  rejectFollowerSaga,
  loadOverviewSaga,
} from '@engage/overview/sagas';
import { FACEBOOK_MODAL_NAME, facebookFlow } from '@members/facebook';
import { getModalNameFromLocation } from '@members/modal';
import { loadMember } from '@members/user-mapper';

export const openModalsSaga = function* (usernameOrId: string): SagaIterator {
  if (usernameOrId !== ME) {
    return;
  }
  const modalName = yield select(getModalNameFromLocation);
  if (modalName === FACEBOOK_MODAL_NAME) {
    yield put(facebookFlow());
  }
};

export const loadOverviewPageSaga = function* (
  client: Client,
  action: OverviewPageLoadAction,
): SagaIterator {
  const meOrUserId = action.payload.meOrUserId;
  yield put(loadMember(meOrUserId));
  const membersAction = yield take(isMembersSuccessActionForMember(meOrUserId));
  const id = membersAction.payload.id;
  yield call(loadActivityCalendarSaga, client, id);
  const showProfile = yield select(shouldShowProfile, id);
  if (showProfile) {
    yield call(loadOverviewSaga, client, id);
  }
  yield call(openModalsSaga, id);
};

export const loadActivityCalendarSaga = function* (client: Client, userId: string) {
  try {
    const activeDayHistory: ActiveDayHistory = yield call(
      fetchActiveDayHistory,
      client,
      userId,
    );
    yield put(loadActiveDayHistorySuccess(userId, activeDayHistory));
  } catch (e) {
    // fail silently I guess?
    logError(e, 'loadActivityCalendarSaga');
  }
};

export const overviewPageSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(FriendsActionType.RequestFriends, loadFriendsSaga, client);
  yield takeEvery(OverviewPageActionTypes.Load, loadOverviewPageSaga, client);
  yield takeEvery(RelationshipsActionType.Remove, removeFollowerSaga, client);
  yield takeEvery(RelationshipsActionType.Reject, rejectFollowerSaga, client);
  yield takeEvery(RelationshipsActionType.Approve, approveFollowerSaga, client);
};
