// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Cta = {
  Back: 'back',
  Cancel: 'cancel',
  Close: 'close',
  CloseBanner: 'closeBanner',
  CloseErrorMessage: 'closeErrorMessage',
  Continue: 'continue',
  InputClear: 'inputClear',
  Modalcancel: 'modalcancel',
  ModalClose: 'modalClose',
  Reload: 'reload',
  Replace: 'replace',
} as const;
