import styled from 'styled-components';
import { media } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { Loading } from '@members/library/Classes/Loading';

export const LoadingMore = styled(Loading)`
  margin: ${spaces.small}px auto ${spaces.xLarge}px;
  ${media.smallScreen`
    margin: ${spaces.small}px auto ${spaces.xLarge}px;
  `}
  ${media.tablet`
    margin: ${spaces.small}px auto ${spaces.xLarge}px;
  `}
`;
LoadingMore.displayName = 'LoadingMore';
