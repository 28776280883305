import { compose, curry, map, prop } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import type {
  Achievement,
  AchievementCategory,
  AchievementTemplate,
} from './Achievement';

const toMemberAchievementsUrl = (id: string) => `/api/user/${id}/achievements`;

export const fetchAchievementsForMember = curry((id: string, api: Client) =>
  api
    .get(toMemberAchievementsUrl(id), {
      meta: { skipErrorHandlers: true },
    } as any)
    .then(pipeData(toAchievements)),
);

const toAchievementCategory = ({
  name,
  achievements,
}: ApiCategory): AchievementCategory => ({
  name,
  achievements: map(toAchievement, achievements),
});

const toAchievement = ({ template, count }: ApiAchievement): Achievement => ({
  ...template,
  count,
});

export const toAchievements = compose(map(toAchievementCategory), prop('categories'));

type ApiCategory = {
  name: string;
  achievements: ApiAchievement[];
};

type ApiAchievement = {
  count: number;
  template: AchievementTemplate;
};
