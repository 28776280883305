import React from 'react';
import { getUser, isGuest, getIsUserLoading } from '@peloton/auth';
import type { Language } from '@peloton/internationalize';
import { useReduxState, useReduxAction } from '@engage/redux';
import type { PendingResult } from '@engage/result';
import { ok, pending } from '@engage/result';
import {
  getClassLanguagePreferences,
  loadDetails,
  getDisplayLanguage,
} from '@engage/settings';

type ClassLanguageResult = PendingResult<undefined, Error>;

export const useClassLanguage = () => {
  const classLanguagePreferences = useReduxState(getClassLanguagePreferences);
  const displayLanguage = useReduxState(getDisplayLanguage);
  const loadDetailsAction = useReduxAction(loadDetails);
  const [result, setResult] = React.useState<ClassLanguageResult>(pending);
  const user = useReduxState(getUser);
  const userIsLoading = useReduxState(getIsUserLoading);

  React.useEffect(() => {
    if (!userIsLoading && !!user) {
      if (isGuest(user)) {
        setResult(ok(undefined)); // User is guest, nothing to fetch
      } else {
        loadDetailsAction({
          getDisplayLanguage: () => displayLanguage as Language,
          displaySettings: () => null,
          redirectToLoginPage: () => null,
        });
      }
    }
  }, [userIsLoading, !!user, displayLanguage]);

  React.useEffect(() => {
    if (classLanguagePreferences.length) {
      setResult(ok(undefined));
    }
  }, [classLanguagePreferences.length]);

  return result;
};
