// Autogenerated from `packages/@members/copy/models.generated/__model__.ts.hbs`

export const Subscriptions = {
  AccountNotFound: 'accountNotFound',
  ActivationKey: 'activationKey',
  ActivationKeyDescription: 'activationKeyDescription',
  Active: 'active',
  AddAccount: 'addAccount',
  AddAccountError: 'addAccountError',
  AddAccountSuccess: 'addAccountSuccess',
  Amazon: 'amazon',
  Apple: 'apple',
  AttachedDevices: 'attachedDevices',
  AttachedDevicesDescription: 'attachedDevicesDescription',
  CancelConfirm: 'cancelConfirm',
  CancelError: 'cancelError',
  CancelErrorDescription: 'cancelErrorDescription',
  CancelErrorTitle: 'cancelErrorTitle',
  CancelLabel: 'cancelLabel',
  Cancelled: 'cancelled',
  CancelOption: 'cancelOption',
  CancelReasonExpensive: 'cancelReasonExpensive',
  CancelReasonLabel: 'cancelReasonLabel',
  CancelReasonLifeEvent: 'cancelReasonLifeEvent',
  CancelReasonNotEnjoying: 'cancelReasonNotEnjoying',
  CancelReasonNotExercising: 'cancelReasonNotExercising',
  CancelReasonOther: 'cancelReasonOther',
  CancelReasonPregnancy: 'cancelReasonPregnancy',
  CancelReasonSeasonalUse: 'cancelReasonSeasonalUse',
  CancelReasonSoftwareIssues: 'cancelReasonSoftwareIssues',
  CancelScheduled: 'cancelScheduled',
  CancelSub: 'cancelSub',
  CancelSuccess: 'cancelSuccess',
  CancelSuccessDescription: 'cancelSuccessDescription',
  CancelSupport: 'cancelSupport',
  CannotAddUser: 'cannotAddUser',
  CardNumber: 'cardNumber',
  CardNumberRequired: 'cardNumberRequired',
  CardUpdateError: 'cardUpdateError',
  CardUpdateSuccess: 'cardUpdateSuccess',
  CompleteTitle: 'completeTitle',
  ConfirmCancelCta: 'confirmCancelCta',
  ConfirmLabel: 'confirmLabel',
  ConfirmNotCancelCta: 'confirmNotCancelCta',
  ConfirmSupportCta: 'confirmSupportCta',
  CorporateWellnessProgramEnrolled: 'corporateWellnessProgramEnrolled',
  Cost: 'cost',
  CountSharedWithNUsers: 'countSharedWithNUsers',
  Cvc: 'cvc',
  CvcRequired: 'cvcRequired',
  Date: 'date',
  Description: 'description',
  Detach: 'detach',
  DetachConfirmationCta: 'detachConfirmationCta',
  DetachConfirmationDescription: 'detachConfirmationDescription',
  DigitalSubName: 'digitalSubName',
  EndingIn: 'endingIn',
  Error: 'error',
  ErrorMessage: 'errorMessage',
  ErrorTitle: 'errorTitle',
  ErrorToast: 'errorToast',
  Expiration: 'expiration',
  ExpirationPlaceholder: 'expirationPlaceholder',
  ExpirationRequired: 'expirationRequired',
  Expired: 'expired',
  ExpiredCta: 'expiredCta',
  Expires: 'expires',
  ExpiringSoonCta: 'expiringSoonCta',
  FirstActivated: 'firstActivated',
  FirstActivatedDescription: 'firstActivatedDescription',
  FreeTrialDurationCta: 'freeTrialDurationCta',
  FreeTrialExpiringOn: 'freeTrialExpiringOn',
  FreeTrialUntil: 'freeTrialUntil',
  Google: 'google',
  Inactive: 'inactive',
  Invoice: 'invoice',
  Item: 'item',
  ITunesStoreName: 'iTunesStoreName',
  ITunesSubName: 'iTunesSubName',
  LastAttached: 'lastAttached',
  LastAttachedDescription: 'lastAttachedDescription',
  MySubscriptions: 'mySubscriptions',
  Name: 'name',
  NameOnCard: 'nameOnCard',
  NativeCancelLabel: 'nativeCancelLabel',
  NativeCancelText: 'nativeCancelText',
  NativeCancelTitle: 'nativeCancelTitle',
  NextBill: 'nextBill',
  NextBillDate: 'nextBillDate',
  NextBillDateDescription: 'nextBillDateDescription',
  NextBillDescription: 'nextBillDescription',
  NextBillMonthly: 'nextBillMonthly',
  NextBillWaivers: 'nextBillWaivers',
  NoSubs: 'noSubs',
  NotAvailable: 'notAvailable',
  Owner: 'owner',
  Pause: 'pause',
  Paused: 'paused',
  PauseDates: 'pauseDates',
  PauseDescription: 'pauseDescription',
  PauseError: 'pauseError',
  PauseLink: 'pauseLink',
  PauseOption: 'pauseOption',
  PauseOrCancelCta: 'pauseOrCancelCta',
  PauseScheduled: 'pauseScheduled',
  PauseScheduledDescription: 'pauseScheduledDescription',
  PauseSuccess: 'pauseSuccess',
  PauseTime: 'pauseTime',
  PaymentDescription: 'paymentDescription',
  PaymentExpired: 'paymentExpired',
  PaymentInfo: 'paymentInfo',
  PaymentMethod: 'paymentMethod',
  PaymentMethodDescription: 'paymentMethodDescription',
  PaymentMethodTitle: 'paymentMethodTitle',
  PaymentNotFound: 'paymentNotFound',
  PaymentNotFoundOnAccount: 'paymentNotFoundOnAccount',
  PaymentRequired: 'paymentRequired',
  PaymentShortUpdateCta: 'paymentShortUpdateCta',
  PaymentUpdateCta: 'paymentUpdateCta',
  PaymentUpdateCtaAlt: 'paymentUpdateCtaAlt',
  PaymentUpdateCtaWithLink: 'paymentUpdateCtaWithLink',
  PaymentUpdateDescription: 'paymentUpdateDescription',
  PostalCode: 'postalCode',
  PreDeliveryDurationCta: 'preDeliveryDurationCta',
  PrepaidMonths: 'prepaidMonths',
  PrepaidMonthsDescription: 'prepaidMonthsDescription',
  Price: 'price',
  Purchased: 'purchased',
  PurchaseDate: 'purchaseDate',
  PurchaseDateWithOrderId: 'purchaseDateWithOrderId',
  QuantityShort: 'quantityShort',
  ReactivateConfirmationCta: 'reactivateConfirmationCta',
  ReactivateConfirmationDescription: 'reactivateConfirmationDescription',
  ReactivateCta: 'reactivateCta',
  ReactivateError: 'reactivateError',
  ReactivateShortCta: 'reactivateShortCta',
  ReactivateSuccess: 'reactivateSuccess',
  ReactivateTitle: 'reactivateTitle',
  RemainingCommitment: 'remainingCommitment',
  RemainingCommitmentDescription: 'remainingCommitmentDescription',
  Remove: 'remove',
  RemoveConfirm: 'removeConfirm',
  RemoveError: 'removeError',
  RemoveSuccess: 'removeSuccess',
  Required: 'required',
  ResumeButton: 'resumeButton',
  ResumeLink: 'resumeLink',
  ResumeSuccess: 'resumeSuccess',
  ResumeSuccessMessage: 'resumeSuccessMessage',
  ResumeSuccessTitle: 'resumeSuccessTitle',
  ShareDescription: 'shareDescription',
  SharedWithMe: 'sharedWithMe',
  ShareSubscription: 'shareSubscription',
  Status: 'status',
  SubIdDescription: 'subIdDescription',
  SubscribeNowCta: 'subscribeNowCta',
  SubscriptionDetailsViewHeader: 'subscriptionDetailsViewHeader',
  SubscriptionId: 'subscriptionId',
  SubscriptionIdDescription: 'subscriptionIdDescription',
  SubscriptionStatus: 'subscriptionStatus',
  SubTotal: 'subTotal',
  SuccessTitle: 'successTitle',
  SuccessToast: 'successToast',
  Support: 'support',
  TaxWithColon: 'taxWithColon',
  Title: 'title',
  Total: 'total',
  TotalWithColon: 'totalWithColon',
  TransactionDescription: 'transactionDescription',
  TransactionDisclaimer: 'transactionDisclaimer',
  TransactionMonthsCount: 'transactionMonthsCount',
  UpgradeNowCta: 'upgradeNowCta',
  Waiver: 'waiver',
  WaiverDescription: 'waiverDescription',
  Upgrade: 'upgrade',
} as const;
