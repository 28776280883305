import { call, put } from 'redux-saga/effects';
import { redirectToNotFound } from '@peloton/navigation';
import type { Peloton, ScenicClass, LiveClass, OnDemandClass } from '@engage/classes';
import { isLive } from '@engage/classes';
import { loadCaptionsSettings } from '@engage/settings';
import type { VideoStream } from '../../models';
import type { WorkoutStreamState } from '../../redux';
import type { InitializeWorkoutParams } from './helpers';
import {
  obtainStream,
  getOrCreatePeloton,
  toWorkoutStream,
  hasClassId,
  loadClassById,
} from './helpers';

export const initializeWorkout = function* (
  params: InitializeWorkoutParams,
  contentType: string,
) {
  const streamResult: VideoStream | undefined = yield call(
    obtainStream,
    (params as InitializeWorkoutParams & { classId: string }).classId,
    contentType,
  );

  if (streamResult) {
    /**
     * Only run this check if:
     * - requireVODAvailability Optimizely flag is enabled
     * - Attempting to stream a VOD class (VOD classes will be initialized with a classId)
     * - We were able to obtain a valid streamResult (since users with NoActiveSubscription will not get a valid vod_stream_url)
     */
    if (params.requireVODAvailability && hasClassId(params)) {
      const klass: ScenicClass | LiveClass | OnDemandClass | undefined = yield call(
        loadClassById,
        params.classId,
      );

      // But looks like a live class (means it's not on-demand and hence not in the VOD library)
      if (klass) {
        if (isLive(klass) || !streamResult.url) {
          yield put(redirectToNotFound());
          return undefined;
        }
      }
    }

    const peloton: Peloton = yield call(getOrCreatePeloton, params);
    const stream: WorkoutStreamState | undefined = yield call(toWorkoutStream, {
      ...params,
      pelotonId: peloton.id,
      classId: peloton.rideId,
      streamResult,
    });

    if (!stream) {
      return undefined;
    }

    yield put(loadCaptionsSettings());
    return stream;
  }

  return undefined;
};
export { updateConfig } from './config';
