import type { VariableQuery, VariableResult, VariableType } from '@members/optimizely';
import type { Toggles } from '../models';

export enum ActionType {
  MergeToggles = 'engage/feature-toggles/MERGE_TOGGLES',
  SetToggles = 'engage/feature-toggles/SET_TOGGLES',

  MergeVariables = 'engage/feature-toggles/MERGE_VARIABLES',
  SetVariables = 'engage/feature-toggles/SET_VARIABLES',
}

// Toggles

export const mergeTogglesFromOptimizely = (toggles: (keyof Toggles)[]) =>
  ({
    type: ActionType.MergeToggles,
    payload: toggles,
  } as const);

export const setLocalToggleState = (toggle: keyof Toggles, value: boolean) =>
  ({
    type: ActionType.SetToggles,
    payload: { toggle, value },
  } as const);

// Variables

// TODO
export const mergeVariablesFromOptimizely = (
  variables: Record<keyof Toggles, VariableResult<VariableQuery<VariableType>>[]>,
) =>
  ({
    type: ActionType.MergeVariables,
    payload: variables,
  } as const);

// TODO: tighten these types
export const setLocalVariablesState = (
  toggle: keyof Toggles,
  key: string,
  value: string | number | boolean | null,
) =>
  ({
    type: ActionType.SetVariables,
    payload: { toggle, key, value },
  } as const);

export type Action = ReturnType<
  | typeof mergeTogglesFromOptimizely
  | typeof setLocalToggleState
  | typeof mergeVariablesFromOptimizely
  | typeof setLocalVariablesState
>;
