import type { OperatorData } from '@engage/video';

export default {
  // return the Subsegment ID for the given offset
  toPacket: ({ offset, offsetMetrics }: OperatorData<'subsegmentId'>) =>
    offsetMetrics[offset],
  // return the last Subsegment ID from the list
  toValue: ({ offsetMetrics }: OperatorData<'subsegmentId'>) =>
    offsetMetrics[offsetMetrics.length - 1],
};
