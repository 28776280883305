import { prop, compose } from 'ramda';
import React from 'react';
import { isSignedIn, MemberGroup, getUser } from '@peloton/auth';
import type { RoutingState } from '@peloton/redux';
import { getLocation, getQueryParams } from '@peloton/redux';
import { track } from '@engage/analytics';
import type { ClassAnalyticsProperties } from '@engage/classes';
import { getClassAnalyticsProperties, toClassAnalytics } from '@engage/classes';
import { useReduxAction, useReduxState } from '@engage/redux';
import { toBaseProperties, toSource } from '@members/analytics';
import type { ShareTooltipSource } from './shared';
import { ShareTooltipDestination } from './shared';

/**
 * Analytics functions for ShareToolTip component. Most of the methods were taken from @members/tags where the ShareTooltip originally lived.
 * Refactoring (still in progress) has been done to make the ShareTooltip re-useable across the codebase. As more refactoring is done to decouple
 * the ShareTooltip from @tags, the more self-contained this package will get.
 */

export const getTagNameFromLocation = compose<
  RoutingState,
  Record<string, string>,
  string
>(prop('tagName'), getQueryParams);

export const useTagNameFromLocation = (tag?: string) => {
  const tagNameFromRedux = useReduxState(getTagNameFromLocation);
  const maybeEncodedTagName = tag ?? tagNameFromRedux ?? '';
  const tagName = decodeURI(maybeEncodedTagName);
  return tagName;
};

export const trackTappedShareTag = (
  tagName: string,
  source: string,
  isTagMember: boolean,
) => ({
  event: 'Tapped Share Tag',
  tag: tagName,
  source,
  isTagMember,
});

export const trackSelectedShareTagDestination = (
  tagName: string,
  source: string,
  isTagMember: boolean,
  destination: ShareTooltipDestination,
) => ({
  event: 'Selected Share Tag Destination',
  tag: tagName,
  source,
  isTagMember,
  destination,
});

/**
 * Helper function for useTrackShareTag(), returns tagName and whether or not this member is in this tag.
 */
export const useShareTagInfo = () => {
  const user = useReduxState(getUser);
  const isInTagsGroup =
    isSignedIn(user) && user?.memberGroups.includes(MemberGroup.TagsViralLaunch);
  const tagName = useTagNameFromLocation();

  return { tagName, isInTagsGroup };
};

/**
 * Analytics call for tracking when a tag is shared.
 */
export const useTrackShareTag = () => {
  const trackAction = useReduxAction(track);
  const { tagName, isInTagsGroup } = useShareTagInfo();
  return React.useCallback(
    (source: ShareTooltipSource) =>
      trackAction(trackTappedShareTag(tagName, source, isInTagsGroup)),
    [isInTagsGroup, tagName],
  );
};

/**
 * Analytics call for when a tag is shared to Facebook accessed from the ShareTooltip by clicking 'Share to Facebook'.
 */
export const useTrackShareTagToFacebook = () => {
  const trackAction = useReduxAction(track);
  const { tagName, isInTagsGroup } = useShareTagInfo();
  return React.useCallback(
    (source: ShareTooltipSource) =>
      trackAction(
        trackSelectedShareTagDestination(
          tagName,
          source,
          isInTagsGroup,
          ShareTooltipDestination.Facebook,
        ),
      ),
    [isInTagsGroup, tagName],
  );
};
/**
 * Analytics call for when a tag link is shared (accessed from the ShareTooltip by clicking 'Copy link').
 */
export const useTrackTagCopyLink = () => {
  const trackAction = useReduxAction(track);
  const { tagName, isInTagsGroup } = useShareTagInfo();
  return React.useCallback(
    (source: ShareTooltipSource) =>
      trackAction(
        trackSelectedShareTagDestination(
          tagName,
          source,
          isInTagsGroup,
          ShareTooltipDestination.CopyLink,
        ),
      ),
    [isInTagsGroup, tagName],
  );
};

const sharedClassEvent = (
  destination: string,
  classProperties: ClassAnalyticsProperties,
  source: string,
) => ({
  ...toBaseProperties(),
  ...toClassAnalytics(classProperties),
  destination,
  source,
  event: 'Shared Class',
});

export const useTrackShareClassToFacebook = (classId: string) => {
  const trackAction = useReduxAction(track);
  const classAnalyticsProperties = useReduxState(
    getClassAnalyticsProperties,
    classId,
  ) as ClassAnalyticsProperties;
  const location = useReduxState(getLocation);

  return React.useCallback(
    (source?: ShareTooltipSource) =>
      trackAction(
        sharedClassEvent(
          'Facebook',
          classAnalyticsProperties,
          source ?? toSource(location),
        ),
      ),
    [classId],
  );
};

export const useTrackShareClassToClipboard = (classId: string) => {
  const trackAction = useReduxAction(track);
  const classAnalyticsProperties = useReduxState(
    getClassAnalyticsProperties,
    classId,
  ) as ClassAnalyticsProperties;
  const location = useReduxState(getLocation);

  return React.useCallback(
    (source?: ShareTooltipSource) =>
      trackAction(
        sharedClassEvent(
          'Copy URL',
          classAnalyticsProperties,
          source ?? toSource(location),
        ),
      ),
    [classId],
  );
};
