import type { Transformation } from 'cloudinary-core';
import { Cloudinary } from 'cloudinary-core';
// eslint-disable-next-line no-restricted-imports
import { CLOUDINARY_ACCOUNT } from '@peloton/app-config';
// eslint-disable-next-line no-restricted-imports
import { toCloudinaryPrefix } from '@peloton/images/toCloudinarySrc';

const cloudinaryInstance = Cloudinary.new({
  cloud_name: CLOUDINARY_ACCOUNT,
  type: 'fetch',
  secure: true,
});

/**
 * DO NOT use both width and height (i.e. { width: 100, height: 100} )
 * as transformation options or else aspect ratio may be wrong for the
 * returned image
 * @param imageUrl string url
 * @param transformations set of cloudinary options
 * @param blur
 * @returns string url from cloudinary
 */
export const toCloudinaryUrl = (
  imageUrl: string,
  transformations: Transformation.Options = {},
  blur: number | null = null,
) => {
  // TODO: handle sending the correct DPR (device pixel ratio) value to cloudinary for retina displays
  const options = {
    fetchFormat: 'auto',
    quality: 'auto',
    crop: 'scale',
    format: 'auto',
    ...transformations,
  };

  // devicePixelRatio is not defined in RN, setting dpr to `undefined` leads to weird behavior in cloudinary
  if (typeof window !== 'undefined' && window.devicePixelRatio && !options.dpr) {
    options.dpr = window.devicePixelRatio;
  }
  let url = cloudinaryInstance.url(imageUrl, options); //

  /**
   * This is a work around for this github issue. https://github.com/cloudinary/pkg-cloudinary-core/issues/22
   * The cloudinary library does not support adding a blur to an image, but the cloudinary service does so we
   * have to add the property to the url manually.
   */

  if (blur !== null) {
    const cloudinaryPrefix = toCloudinaryPrefix();
    const urlSplit = url.split(cloudinaryPrefix);
    url = cloudinaryPrefix + `/e_blur:${blur}` + urlSplit[1];
  }
  return url;
};
