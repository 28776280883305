import React from 'react';
import useSWR, { mutate } from 'swr';
import { isMe as getIsMe } from '@peloton/auth';
import { isDefined } from '@peloton/types';
import { RelationshipChange } from '@engage/members';
import { useReduxState } from '@engage/redux';
import { TagsFetchersGQL } from '@members/data-fetch';
import type {
  SearchTagQuery,
  SearchTagQueryVariables,
} from '@members/graphql-swr/schemas/tags/queries/SearchTag.generated';
import type {
  TagDetailQueryVariables,
  TagDetailQuery,
} from '@members/graphql-swr/schemas/tags/queries/TagDetail.generated';
import { getTagNameFromLocation } from './selectors';

const SELECTED_TAG_NAME = 'Selected-Tag-Name';
const LAST_TAG_SEARCH = 'Last-Tag-Search';

export const useTagNameFromLocation = (tag?: string) => {
  const tagNameFromLocation = useReduxState(getTagNameFromLocation);
  const maybeEncodedTagName = tag ?? tagNameFromLocation ?? '';
  const tagName = decodeURI(maybeEncodedTagName);
  return tagName;
};

export const usePrimaryTag = (userId?: string) => {
  const { data, error } = useSWR(
    ...TagsFetchersGQL.PrimaryTagQuery({
      userId: isDefined(userId) ? userId : undefined,
    }),
  );

  const loading = !data;

  const primaryTagName = data?.user?.userTags?.primary?.name ?? '';
  const numberOfTags = data?.user?.userTags?.allTags?.length ?? 0;

  return { primaryTagName, numberOfTags, loading, error };
};

export const useSharedLeaderboardTag = (viewedMemberId?: string) => {
  const isSelf = useReduxState(getIsMe, viewedMemberId ?? '');
  const { primaryTagName: myPrimaryTag, loading: myQueryLoading } = usePrimaryTag();
  const {
    primaryTagName: otherUserPrimaryTag,
    loading: otherUserQueryLoading,
  } = usePrimaryTag(viewedMemberId);

  const fetched = !myQueryLoading && !otherUserQueryLoading;

  const sharedLeaderboardTag = isSelf
    ? null
    : myPrimaryTag === otherUserPrimaryTag
    ? myPrimaryTag
    : null;

  return { fetched, sharedLeaderboardTag };
};

export const useSelectedTagName = () => {
  const defaultValue = '';
  const { data, mutate: setSelectedTagName } = useSWR(SELECTED_TAG_NAME, {
    fallbackData: defaultValue,
  });

  return { selectedTagName: data ?? defaultValue, setSelectedTagName };
};

export const useLastTagSearch = () => {
  const defaultValue = '';
  const { data, mutate: setLastTagSearch } = useSWR(LAST_TAG_SEARCH, {
    fallbackData: defaultValue,
  });
  return { lastTagSearch: data ?? defaultValue, setLastTagSearch };
};

export const useOnMemberFollow = (followingCount = 0) => {
  const [numberOfFollowing, setNumberOfFollowing] = React.useState(followingCount);

  React.useEffect(() => {
    setNumberOfFollowing(followingCount ?? 0);
  }, [followingCount]);

  const onMemberFollow = (change: RelationshipChange) => {
    if (change === RelationshipChange.Unfollow) {
      setNumberOfFollowing(numberOfFollowing - 1);
    } else if (change === RelationshipChange.Follow) {
      setNumberOfFollowing(numberOfFollowing + 1);
    }
  };
  return { numberOfFollowing, onMemberFollow };
};

export const useSearchTagPaginatedQuery = (
  variables: SearchTagQueryVariables,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [allData, setAllData] = React.useState<SearchTagQuery>({} as SearchTagQuery);
  const [isSyntheticLoading, setIsSyntheticLoading] = React.useState(true);
  const { error, mutate: refetch } = useSWR(
    ...TagsFetchersGQL.SearchTagQuery(variables, undefined, {
      fallbackData: {} as SearchTagQuery,
      onError: () => setShowError(true),
      onSuccess: succData => {
        setAllData(succData);
        setIsSyntheticLoading(false);
      },
    }),
  );

  const loadMore = () => {
    if (allData?.searchTag?.pageInfo?.hasNextPage && !isSyntheticLoading) {
      const cursor = allData?.searchTag?.pageInfo?.endCursor;
      setIsSyntheticLoading(true);
      mutate(
        ...TagsFetchersGQL.SearchTagPagination({
          after: cursor,
          ...variables,
        }),
      ).then(moreData => {
        setAllData({
          ...allData,
          searchTag: {
            edges: [...allData.searchTag.edges, ...moreData.searchTag.edges],
            pageInfo: moreData.searchTag.pageInfo,
            ...moreData.searchTag.__typename,
          },
        });
        setIsSyntheticLoading(false);
      });
    }
  };

  return {
    data: allData,
    mutate: refetch,
    isLoading: isSyntheticLoading,
    error,
    loadMore,
  };
};

export const useTagDetailsPaginatedQuery = (variables: TagDetailQueryVariables) => {
  const [allData, setAllData] = React.useState<TagDetailQuery>({} as TagDetailQuery);
  const [called, setCalled] = React.useState(false);
  const [isSyntheticLoading, setIsSyntheticLoading] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const { error, mutate: refetch } = useSWR(
    ...TagsFetchersGQL.TagDetailQuery(variables, undefined, {
      onSuccess: succData => {
        setAllData(succData);
        setIsSyntheticLoading(false);
        setCalled(true);
      },
    }),
  );

  const loadMore = () => {
    if (allData?.tag?.users?.pageInfo?.hasNextPage && !isSyntheticLoading) {
      const cursor = allData?.tag?.users.pageInfo.endCursor;
      setIsLoadingMore(true);
      mutate(
        ...TagsFetchersGQL.TagDetailPagination({
          after: cursor,
          ...variables,
        }),
      ).then(moreData => {
        const edges = [...allData.tag!.users.edges, ...moreData.tag.users.edges];
        setAllData({
          ...allData,
          tag: {
            ...moreData.tag,
            users: {
              ...moreData.tag.users,
              edges,
              pageInfo: moreData.tag.users.pageInfo,
            },
          },
        });
        setIsLoadingMore(false);
      });
    }
  };

  return {
    data: allData,
    mutate: refetch,
    isLoading: isSyntheticLoading,
    isLoadingMore,
    error,
    loadMore,
    called,
  };
};
