/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractAgreement } from '../../../api-v2-models/generated/ContractAgreement';
import type { ContractAgreementUpdate } from '../../../api-v2-models/generated/ContractAgreementUpdate';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * List of active contracts for the specified platform.
 * This endpoint retrieves a list of active contracts and the urls to retrieve them from S3 for a specific platform.
 * @param pelotonPlatform Platform the endpoint is called on. This may hide information from the response (class types, fitness disciplines, classes of certain fitness disciplines, etc.) that should not display on this platform. If this has any other effect on the endpoint, it will be noted in the description.
 *
 * @param requestBody
 * @returns any ContractAgreements
 * @throws ApiError
 */
export const postContractsService = (
    api: BaseAPI,
    pelotonPlatform?: 'default' | 'commercial_bike' | 'home_bike' | 'aurora' | 'home_tread' | 'studio_tread' | 'iOS_app' | 'pos' | 'studio_bike' | 'web' | 'android' | 'fire_tv' | 'tiger' | 'android_tv' | 'fire_tablet' | 'apple_tv' | 'apple_watch' | 'roku' | 'comcast_x1' | 'sky_tv' | 'lg' | 'samsung',
    requestBody?: {
        /**
         * Locale for the contract. Defaults to 'en-us'
         */
        locale?: string,
        /**
         * device cert; PEM-encoded device certificate chain containing device serial and device type
         */
        deviceCert?: string,
        /**
         * device_token; WT token with device serial and expiration time signed w. RS256 alg using device cert's private key and encoded in JWS Compact Serialization format. This will be used to validate that the sender has access to the private key in addition to the public device cert.
         */
        deviceToken?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * List of contracts
     */
    contractAgreements?: Array<ContractAgreement>,
}> => {
    const localVarPath = `/api/contract_agreements`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Peloton-Platform'] = String(pelotonPlatform);

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Update which contracts a user has already acknowledged and/or agreed to
 * This endpoint will update the backend to store information about which contracts the user has already acknowledged or agreed to as specified in the request body and update the timestamp at which they acknowledged/agreed to each contract .
 * @param id User id
 * @param requestBody ContractAgreement
 * @returns any ContractAgreement entries are created and saved in the database
 * @throws ApiError
 */
export const updateContractAgreements = (
    api: BaseAPI,
    id: string,
    requestBody: {
        /**
         * Name of modal that was displayed to member (acknowledge, acceptance, acknowledge_and_acceptance, or none).
         */
        modalDisplayed?: string,
        /**
         * List of contracts that was acknowledged and/or agreed to by member.
         */
        contractAgreements?: Array<ContractAgreementUpdate>,
    },
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/user/${id}/contract_agreements`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * List of active contracts and what member action is required for each contract
 * This endpoint retrieves a list of active contracts and the urls to retrieve them from S3. Each item will contain information about the contract and what action is required of the member (e.g none, acknowledge, accept, or acknowledge+accept) if the contract was updated in CMS. User must be logged in.
 * @param id User id
 * @returns any ContractAgreements
 * @throws ApiError
 */
export const getContractAgreements = (
    api: BaseAPI,
    id: string,
    options: any = {},
): AxiosPromise<{
    /**
     * List of contracts
     */
    contractAgreements?: Array<ContractAgreement>,
}> => {
    const localVarPath = `/api/user/${id}/contract_agreements`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

