import Cookies from 'cookies-js';
import { pick, identity } from 'ramda';
import type { Reducer, StoreCreator, StoreEnhancer, ReducersMapObject } from 'redux';
import { createStore } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import storage from 'redux-persist/lib/storage';
import { toRootReducer } from '@peloton/redux';
import { BUFFER_METRICS_NAMESPACE } from '@engage/video/bufferMetrics';
import type { State as VideoReducerState } from '@engage/video/redux';
import { VIDEO_REDUCER_NAMESPACE } from '@engage/video/redux';
import { NOTIFICATION_NAMESPACE } from '@members/notifications';
import type { ReducerState as PageVideoReducerState } from '@members/pg-video/redux';
import { reconcileState } from './reconcileState';

// How long data will be persisted since latest update,
// including currentTimecode (playback point in video).
const VIDEO_COOKIE_EXPIRY_SECONDS = 60 * 60;
const VIDEO_PERSIST_KEY = 'video';

const videoReducerTransformer = createTransform<
  VideoReducerPersistState,
  VideoReducerPersistState
>(
  (inboundState, _) => ({
    workoutStream: pick(['workoutId', 'streamHistoryId'])(inboundState.workoutStream),
    seekTimecode: inboundState.currentTimecode,
    timePlaying: inboundState.timePlaying,
    introTimePlaying: inboundState.introTimePlaying,
    startTime: inboundState.startTime,
    tabBackgrounded: inboundState.tabBackgrounded,
    metrics: inboundState.metrics,
    isFullscreen: inboundState.isFullscreen,
  }),
  identity,
  { whitelist: [VIDEO_REDUCER_NAMESPACE] },
);

// Video persist config is used at entire store level
// so that the reducer transform persists seek timecode as current timecode.
const videoPersistConfig = {
  key: VIDEO_PERSIST_KEY,
  storage: new CookieStorage(Cookies, {
    expiration: { [`persist:${VIDEO_PERSIST_KEY}`]: VIDEO_COOKIE_EXPIRY_SECONDS },
  }),
  stateReconciler: reconcileState,
  whitelist: [VIDEO_REDUCER_NAMESPACE],
  transforms: [videoReducerTransformer],
};

// Clear out legacy persisted notifications state from cookies
// Cookie header was too large for presence LB request https://pelotoncycle.atlassian.net/browse/WE-5554
if (typeof window !== 'undefined') {
  Cookies.expire('persist:notifications');
}

const persistNotificationsConfig = {
  key: 'notifications',
  storage: storage,
  stateReconciler: reconcileState,
};

const persistBufferMetricsConfig = {
  key: BUFFER_METRICS_NAMESPACE,
  storage: storage,
  stateReconciler: reconcileState,
};

const createPersistentStore = (
  reducers: Reducer,
  initialState: {},
  middleware: StoreEnhancer,
) => {
  const persistedReducer = persistReducer(videoPersistConfig, reducers);
  return createStore(persistedReducer, initialState, middleware);
};

export const toPersistentRootReducer = (reducers: ReducersMapObject) => {
  const reducersWithPersist = {
    ...reducers,
    [NOTIFICATION_NAMESPACE]: persistReducer(
      persistNotificationsConfig,
      reducers[NOTIFICATION_NAMESPACE],
    ),
    [BUFFER_METRICS_NAMESPACE]: persistReducer(
      persistBufferMetricsConfig,
      reducers[BUFFER_METRICS_NAMESPACE],
    ),
  };
  return toRootReducer(reducersWithPersist);
};

type VideoReducerPersistState = Partial<VideoReducerState> & PageVideoReducerState;

export default createPersistentStore as StoreCreator;
