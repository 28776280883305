import useSWR from 'swr';
import type { DenormalizedClass } from '@engage/classes/models/DenormalizedClass';
import { getDenormalizedClass } from '@engage/classes/selectors';
import { useReduxState } from '@engage/redux/hooks';
import { ClassFetchers } from '@members/data-fetch/fetchers.production';
import type { TierType, ClassAvailabilityProps } from '../models';
import { CLASS_ACCESS_TYPE } from '../models';
import { useTierContext } from '../TierProvider';

// Return CLASS_ACCESS_TYPE for a given classId
export const useClassAccessType = (
  classId: string,
  classAvailabilityProps?: ClassAvailabilityProps,
) => {
  const {
    tier: { tierType },
  } = useTierContext();
  const klass = useReduxState(getDenormalizedClass, classId) as DenormalizedClass;
  const { data: fetchedClass, error } = useSWR(
    ...ClassFetchers.GetClassById({
      classId: classId,
      shouldSkipFetch: !!classAvailabilityProps || !!klass,
    }),
  );

  if (!!classAvailabilityProps) {
    return getClassAccessType(
      tierType,
      classAvailabilityProps.contentAvailability,
      classAvailabilityProps.freeForLimitedTime,
      classAvailabilityProps.isLimitedRide,
    );
  }

  if (!!klass) {
    return getClassAccessType(
      tierType,
      klass.contentAvailability!,
      klass.freeForLimitedTime!,
      klass.isLimitedRide!,
    );
  }

  return fetchedClass && !error
    ? getClassAccessType(
        tierType,
        fetchedClass.ride.contentAvailability!,
        fetchedClass.ride.freeForLimitedTime!,
        fetchedClass.ride.isLimitedRide!,
      )
    : CLASS_ACCESS_TYPE.APP;
};

// Return the CLASS_ACCESS_TYPE given tierType, contentAvailability, isFreeForLimitedTime and isLimitedRide
export const getClassAccessType = (
  tierType: TierType,
  contentAvailability: string,
  isFreeForLimitedTime: boolean,
  isLimitedRide: boolean,
) => {
  switch (contentAvailability) {
    case 'available':
      switch (tierType) {
        case 'FREE':
          switch (isFreeForLimitedTime) {
            case true:
              return CLASS_ACCESS_TYPE.SAMPLE;
            case false:
            default:
              return CLASS_ACCESS_TYPE.FREE;
          }
        case 'APP':
          switch (isLimitedRide) {
            case true:
              return CLASS_ACCESS_TYPE.PREMIUM;
            case false:
            default:
              return CLASS_ACCESS_TYPE.APP;
          }
        default:
          return CLASS_ACCESS_TYPE.APP;
      }
    case 'digital_and_above':
      switch (tierType) {
        case 'FREE':
          return CLASS_ACCESS_TYPE.ALL_ACCESS_ONLY;
        default:
          return CLASS_ACCESS_TYPE.APP;
      }
    case 'digital_plus_and_above':
      switch (tierType) {
        case 'FREE':
        case 'APP':
          return CLASS_ACCESS_TYPE.EXCLUSIVE;
        default:
          return CLASS_ACCESS_TYPE.APP;
      }
    case 'all_access_only':
      switch (tierType) {
        case 'FREE':
        case 'APP':
        case 'APP_PLUS':
        case 'GUIDE':
          return CLASS_ACCESS_TYPE.ALL_ACCESS_ONLY;
        default:
          return CLASS_ACCESS_TYPE.APP;
      }
    default:
      return CLASS_ACCESS_TYPE.APP;
  }
};
