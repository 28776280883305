import { curry } from 'ramda';
import type { Client } from '@peloton/api';

export const toRequest = <S, T>(
  type: S,
  gateway: Gateway<T>,
  namespace: Namespace,
  ...gatewayParams: any[]
): RequestAction<S, T> => ({
  type,
  payload: {
    namespace,
    gateway,
    gatewayParams,
  },
});

export const toSuccess = curry(
  <S, T>(type: S, namespace: Namespace, payload: T): ResponseAction<S, T> => ({
    type,
    meta: {
      namespace,
    },
    payload,
  }),
);

export const toFail = curry(
  <T>(type: T, namespace: Namespace, payload: Error): FailAction<T> => ({
    type,
    meta: {
      namespace,
    },
    payload,
  }),
);

export type RequestAction<S, T> = {
  type: S;
  payload: {
    namespace: Namespace;
    gateway: Gateway<T>;
    gatewayParams: any[];
  };
};

export type ResponseAction<S, T> = {
  type: S;
  payload: T;
  meta: {
    namespace: Namespace;
  };
};

export type FailAction<T> = ResponseAction<T, Error>;

export type SuccessActionCreator<T, U> = (
  namespace: Namespace,
  payload: T,
) => ResponseAction<U, T>;
export type FailActionCreator<T> = (namespace: Namespace, error: Error) => FailAction<T>;

export type Namespace = string;

export type Gateway<T> = (client: Client, ...args: any[]) => Promise<T>;
