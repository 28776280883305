import React from 'react';

const CalendarAlert: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C8.27614 2 8.5 2.22386 8.5 2.5V3.5H15.5C15.7761 3.5 16 3.72386 16 4C16 4.27614 15.7761 4.5 15.5 4.5H8.5V5.5C8.5 5.77614 8.27614 6 8 6C7.72386 6 7.5 5.77614 7.5 5.5V4.5H4C3.44772 4.5 3 4.94772 3 5.5V8.5H21V8C21 7.72386 21.2239 7.5 21.5 7.5C21.7761 7.5 22 7.72386 22 8V9V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V9V5.5C2 4.39543 2.89543 3.5 4 3.5H7.5V2.5C7.5 2.22386 7.72386 2 8 2ZM3 9.5V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V9.5H3Z"
      fill="#4C525E"
    />
    <path
      data-test-id="redDotIcon"
      d="M19.5 7C20.8807 7 22 5.88071 22 4.5C22 3.11929 20.8807 2 19.5 2C18.1193 2 17 3.11929 17 4.5C17 5.88071 18.1193 7 19.5 7Z"
      fill="#FF3347"
    />
  </svg>
);

export { CalendarAlert };
