/* eslint-disable */
import * as Types from '../../../types.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TagBrowseCategoryDisplayNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TagBrowseCategoryDisplayNamesQuery = { __typename: 'Query', TRENDING: { __typename: 'TagBrowseCategory', displayName: string }, FEATURED: { __typename: 'TagBrowseCategory', displayName: string }, FRIENDS: { __typename: 'TagBrowseCategory', displayName: string }, POPULAR: { __typename: 'TagBrowseCategory', displayName: string } };


export const TagBrowseCategoryDisplayNamesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TagBrowseCategoryDisplayNames"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"TRENDING"},"name":{"kind":"Name","value":"tagBrowseCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categorySlug"},"value":{"kind":"EnumValue","value":"TRENDING"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"FEATURED"},"name":{"kind":"Name","value":"tagBrowseCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categorySlug"},"value":{"kind":"EnumValue","value":"FEATURED"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"FRIENDS"},"name":{"kind":"Name","value":"tagBrowseCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categorySlug"},"value":{"kind":"EnumValue","value":"FRIENDS"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"POPULAR"},"name":{"kind":"Name","value":"tagBrowseCategory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categorySlug"},"value":{"kind":"EnumValue","value":"POPULAR"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]} as unknown as DocumentNode<TagBrowseCategoryDisplayNamesQuery, TagBrowseCategoryDisplayNamesQueryVariables>;