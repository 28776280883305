/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppleProductInfo } from '../../../api-v2-models/generated/AppleProductInfo';
import type { DigitalSubscription } from '../../../api-v2-models/generated/DigitalSubscription';
import type { FreeTrialEligibilityResponse } from '../../../api-v2-models/generated/FreeTrialEligibilityResponse';
import type { FreeTrialLengthResponse } from '../../../api-v2-models/generated/FreeTrialLengthResponse';
import type { UserPrivateBasicMapping } from '../../../api-v2-models/generated/UserPrivateBasicMapping';
import { BaseAPI, globalImportUrl, AxiosPromise } from '../core/request';

/**
 * Create a monthly digital subscription using Google billing
 * Create a monthly digital subscription using Google as the billing provider for the currently logged in user. The process will validate that the subscription info against Google's Purchase API
 * @param requestBody
 * @returns DigitalSubscription Successful response
 * @throws ApiError
 */
export const getDigitalSubscriptionGoogle = (
    api: BaseAPI,
    requestBody: {
        /**
         * The package name of the application for which this subscription was purchased. This field is the product id
         */
        packageName?: string,
        /**
         * The purchased Google subscription ID
         */
        googleSubscriptionId?: string,
        /**
         * The token provided to the user's device when the subscription was purchased.
         */
        purchaseToken?: string,
    },
    options: any = {},
): AxiosPromise<DigitalSubscription> => {
    const localVarPath = `/api/subscription/digital/google`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create a monthly digital subscription using Amazon billing
 * Create a monthly digital subscription using Amazon as the billing provider for the currently logged in user. The process will validate that the subscription info against Amazon's Receipt Verification Service
 * @param requestBody
 * @returns DigitalSubscription Successful response
 * @throws ApiError
 */
export const getDigitalSubscriptionAmazon = (
    api: BaseAPI,
    requestBody: {
        /**
         * ID representing a distinct Amazon customer: purchaseResponse->userData->userId
         */
        amazonUserId?: string,
        /**
         * Unique ID for the purchase: purchaseResponse->receipt->receiptId or purchaseUpdatesResponse->receipts->receipt->receiptId
         */
        receiptId?: string,
        /**
         * The SKU defined for the subscription: purchaseResponse->receipt->sku or purchaseUpdatesResponse->receipts->receipt->sku
         */
        productId?: string,
        /**
         * The decimal price defined for the subscription: productDataResponse->getProductData->sku->getPrice
         */
        price?: string,
        /**
         * The 2 letter country code for the user's marketplace: purchaseResponse->getUserData->marketplace or purchaseUpdatesResponse->getUserData->marketplace
         */
        countryCode?: string,
    },
    options: any = {},
): AxiosPromise<DigitalSubscription> => {
    const localVarPath = `/api/subscription/digital/amazon`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * @deprecated
 * Add a shared user to the digital subscription
 * Add a shared user to the digital subscription.
 * @param digitalSubscriptionId digital subscription identifier
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const addDigitalSubscriptionSharedUser = (
    api: BaseAPI,
    digitalSubscriptionId: string,
    requestBody: {
        /**
         * username or email to add
         */
        usernameOrEmail?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * digital subscription identifier
     */
    digitalSubscriptionId?: string,
    addedUser?: UserPrivateBasicMapping,
}> => {
    const localVarPath = `/api/subscription/digital/${digitalSubscriptionId}/shared_users`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Remove a shared user from the digital subscription
 * Remove a shared user from the digital subscription.
 * @param digitalSubscriptionId digital subscription identifier
 * @param userId user identifier
 * @returns any Successful response
 * @throws ApiError
 */
export const removeDigitalSubscriptionSharedUser = (
    api: BaseAPI,
    digitalSubscriptionId: string,
    userId: string,
    options: any = {},
): AxiosPromise<{
    /**
     * digital subscription identifier
     */
    digitalSubscriptionId?: string,
    /**
     * identifier of user who was removed
     */
    removedUserId?: string,
}> => {
    const localVarPath = `/api/subscription/digital/${digitalSubscriptionId}/shared_users/${userId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create a monthly digital subscription using Apple billing
 * Create a monthly digital subscription using Apple as the billing provider for the currently logged in user. The process will validate that the subscription info against Apple's Receipt Verification Service
 * @param requestBody
 * @returns DigitalSubscription Successful response
 * @throws ApiError
 */
export const getDigitalSubscriptionApple = (
    api: BaseAPI,
    requestBody: {
        /**
         * The apple receipt data. [NSData dataWithContentsOfURL:[[NSBundle mainBundle] appStoreReceiptURL]];
         */
        receipt?: string,
        /**
         * A map of Product identifiers (SKProduct.productIdentifier) to AppleProductInfo The iOS app builds this map based on all products returned by the storekit
         */
        productInfo?: Record<string, AppleProductInfo>,
    },
    options: any = {},
): AxiosPromise<DigitalSubscription> => {
    const localVarPath = `/api/subscription/digital/apple`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Reactivate a digital subscription
 * Reactivate a digital subscription. Only supports reactivating peloton internal digital subscriptions
 * @param digitalSubscriptionId digital subscription identifier
 * @returns DigitalSubscription Successful response
 * @throws ApiError
 */
export const reactivateDigitalSubscription = (
    api: BaseAPI,
    digitalSubscriptionId: string,
    options: any = {},
): AxiosPromise<DigitalSubscription> => {
    const localVarPath = `/api/subscription/digital/${digitalSubscriptionId}/reactivate`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Cancel a digital subscription
 * Cancel a digital subscription. Only supports cancelling peloton internal digital subscription
 * @param digitalSubscriptionId digital subscription identifier
 * @returns any Successful response
 * @throws ApiError
 */
export const cancelDigitalSubscription = (
    api: BaseAPI,
    digitalSubscriptionId: string,
    options: any = {},
): AxiosPromise<any> => {
    const localVarPath = `/api/subscription/digital/${digitalSubscriptionId}/cancel`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create and validate subscription purchased through Roku pay
 * Create a monthly digital subscription using Roku as the billing provider. Validate a purchased subscription's transaction_id against the Roku web service API using the returned isEntitled, cancelled, and expirationDate parameters
 * @param requestBody
 * @returns any Successful response
 * @throws ApiError
 */
export const getDigitalSubscriptionRoku = (
    api: BaseAPI,
    requestBody: {
        /**
         * The transaction id provided to the user's device when the subscription was purchased.
         */
        transactionId?: string,
    },
    options: any = {},
): AxiosPromise<{
    /**
     * A boolean indicating whether or not to entitle the user based on the validity of the transaction
     */
    isEntitled?: boolean,
}> => {
    const localVarPath = `/api/subscription/digital/roku`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get digital subscription free trial length for a country.
 * Get digital subscription free trial length for a given country code. Returns the results in number of days, requires logged in user.
 * @param countryCode Country code, e.g. US,GB,CA,DE,AU
 * @returns FreeTrialLengthResponse Successful response
 * @throws ApiError
 */
export const freeTrialLength = (
    api: BaseAPI,
    countryCode: 'US' | 'GB' | 'CA' | 'DE' | 'AU',
    options: any = {},
): AxiosPromise<FreeTrialLengthResponse> => {
    const localVarPath = `/api/subscription/digital/free_trial_length/${countryCode}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get users eligibility for a free trial
 * Get eligibility for a free trial for a given user/email Returns the boolean for eligibility.
 * @returns FreeTrialEligibilityResponse Successful response
 * @throws ApiError
 */
export const freeTrialEligibility = (
    api: BaseAPI,
    options: any = {},
): AxiosPromise<FreeTrialEligibilityResponse> => {
    const localVarPath = `/api/user/free_trial_eligibility`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Get a digital subscription by its id
 * Get a digital subscription by its id. The process will validate that
 * the logged in user has permission to access the subscription.
 * @param digitalSubscriptionId digital subscription identifier
 * @returns DigitalSubscription Successful response
 * @throws ApiError
 */
export const getDigitalSubscriptionId = (
    api: BaseAPI,
    digitalSubscriptionId: string,
    options: any = {},
): AxiosPromise<DigitalSubscription> => {
    const localVarPath = `/api/subscription/digital/${digitalSubscriptionId}`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};


    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

/**
 * Create a digital subscription trial for the logged in user
 * Create a digital subscription trial for the currently logged in user.
 * The process will validate that the user has never had a trial before.
 * @param requestBody
 * @returns DigitalSubscription Successful response
 * @throws ApiError
 */
export const getDigitalSubscriptionTrial = (
    api: BaseAPI,
    requestBody?: {
        /**
         * Optional parameter indicating the length of trial in days. If not specified, the trial will default to a value of two weeks.
         */
        days?: number,
    },
    options: any = {},
): AxiosPromise<DigitalSubscription> => {
    const localVarPath = `/api/subscription/digital/trial`;
    const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
    let baseOptions;
    if (api.configuration) {
        baseOptions = api.configuration.baseOptions;
    }
    const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;



    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    delete (localVarUrlObj as any).search;
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

    localVarRequestOptions.data = requestBody;

    const localVarAxiosArgs = {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };

    const axios = api.axios;

    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);

}

