import type { PageNumber } from './PageNumber';
import { done } from './PageNumber';

export const toNextPage = (response: ApiNextPage): PageNumber =>
  response.showNext ? response.page + 1 : done;

export type ApiNextPage = {
  showNext: boolean;
  page: number;
};
