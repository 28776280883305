import { range } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { stripProps } from '@peloton/react';
import { gray3, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label5Large } from '@engage/typography';
import { Star } from './Star';

type Props = {
  darkStars?: boolean;
};

const starCount = 5;

export const Rating: React.FC<React.PropsWithChildren<Props>> = ({ darkStars }) => (
  <Container>
    {range(0, starCount).map(idx => (
      <StyledStar key={`star_${idx}`} darkStars={darkStars} />
    ))}
    <NumberRating darkStars={darkStars}>4.9/5</NumberRating>
  </Container>
);

const Container = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledStar = styled(stripProps(Star, 'darkStars'))<Props>`
  width: 10px;
  path {
    fill: ${({ darkStars }: Props) => (darkStars ? slate1 : gray3)};
  }
  & + & {
    margin-left: ${spaces.xxxSmall}px;
  }
`;

const NumberRating = styled.p<Props>`
  ${label5Large};
  ${({ darkStars }: Props) => (darkStars ? `color: ${slate1};` : ``)}
  margin-left: 10px;
`;
